import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { View, Image, StyleSheet } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
// import LinearGradient from 'react-native-linear-gradient'

import DetailGroup from "@ui/detailGroup"
import PeopleTooltip from "@ui/peopleTooltip"
import { AppColors, Units } from "@theme"
import { images } from "@utils/imageUtils"
import SlimScroll from "@ui/slimScroll"
import { setModalPeopleNudge } from "@actions/commonActions"

const styles = StyleSheet.create({
  participantList: {
    flex: 1,
    overflowX: "hidden",
  },
  leftShadow: {
    position: "absolute",
    top: 12,
    left: 12,
    right: 0,
    bottom: 0,
    height: 74,
    width: 8,
    zIndex: 100,
  },
  rightShadow: {
    position: "absolute",
    top: 12,
    right: 12,
    bottom: 0,
    height: 74,
    width: 8,
    zIndex: 100,
  },
  activeTint: {
    tintColor: AppColors.brand.black2,
  },
  actionIcon: {
    height: 20,
    width: 20,
    tintColor: AppColors.brand.grey144,
  },
})

const partipantListWrapper = (isExpanded) => ({
  paddingHorizontal: Units.responsiveValue(10),
  paddingTop: Units.responsiveValue(isExpanded ? 0 : 8),
  // paddingTop: Units.responsiveValue(isExpanded ? 12 : 8),
  paddingBottom: Units.responsiveValue(5),
  height: isExpanded ? "100%" : "auto",
})

const Arrow = ({ left, onClick }) => {
  const styles = {
    left: left ? 0 : "initial",
    right: left ? "initial" : 0,
  }
  return (
    <div
      className="activity-stream__arrow-wrapper"
      style={styles}
      onClick={onClick}
    >
      <div className="activity-stream__arrow">
        <img
          src={images.arrowsOptions}
          style={{ transform: left && "scaleX(-1)" }}
        />
      </div>
    </div>
  )
}

const ActivityStreamBlock = (props) => {
  const {
    isExpanded,
    setExpandedGroupIndex,
    participantsData,
    extraData,
    renderAvatarItem,
    renderFilterBlock,
    showNudgeActionSheet,
    showMentionsSelectParticipantScreen,
    setModalPeopleNudge,
  } = props
  const [isShowLeftShadow, setIsShowLeftShadow] = useState(false)
  const [isShowRightShadow, setIsShowRightShadow] = useState(true)
  const activityList = document.getElementById("activity-list")
  const isRightArrowVisible = activityList?.scrollLeft > 0
  const isLeftArrowVisible =
    activityList?.scrollLeft !==
    activityList?.scrollWidth - activityList?.clientWidth

  useEffect(() => {
    console.log(participantsData, 'participantsData')
  }, [participantsData])

  useEffect(() => {
    setIsShowLeftShadow(false)
    setIsShowRightShadow(true)
  }, [isExpanded])

  const headerActions = [
    {
      onPress: () => setModalPeopleNudge(true, undefined, "mentionPeople"),
      name: "mentions",
      image: <Image source={images.mentionsNew} style={styles.actionIcon} />,
      activeImage: (
        <Image
          source={images.mentionsNew}
          style={[styles.actionIcon, styles.activeTint]}
        />
      ),
    },
    {
      onPress: () => {},
      name: "nudges",
      image: <Image source={images.nudgesNew} style={styles.actionIcon} />,
      activeImage: (
        <Image
          source={images.nudgesNew}
          style={[styles.actionIcon, styles.activeTint]}
        />
      ),
    },
  ]

  const handleParticipantListScroll = (e) => {
    const {
      nativeEvent: { contentOffset, layoutMeasurement, contentSize },
    } = e
    const scrollOffset =
      contentOffset.x + layoutMeasurement.width - contentSize.width

    if (scrollOffset <= 0 && scrollOffset > -1 && isShowRightShadow) {
      setIsShowRightShadow(false)
    } else {
      if (!isShowRightShadow) {
        setIsShowRightShadow(true)
      }
    }

    if (contentOffset.x === 0 && isShowLeftShadow) {
      setIsShowLeftShadow(false)
    } else {
      if (!isShowLeftShadow) {
        setIsShowLeftShadow(true)
      }
    }
  }

  const renderView = ({ style, ...props }) => {
    return (
      <div
        id="activity-list"
        className="activity-stream__list"
        style={{
          ...style,
          flexDirection: isExpanded ? "column" : "row",
          position: "none",
        }}
        {...props}
      />
    )
  }
  return (
    <>
      <PeopleTooltip />
      <DetailGroup
        title="ACTIVITY STREAM"
        expanded={isExpanded}
        onExpand={() => setExpandedGroupIndex(isExpanded ? -1 : 0)}
        showDivider
        headerActions={headerActions}
        noPadding={true}
      >
        <View style={partipantListWrapper(isExpanded)}>
          {/* {isExpanded && renderFilterBlock()} */}
          {/* {!isExpanded && isShowLeftShadow && (
                    <LinearGradient
                        colors={['rgba(255,255,255,1)', 'rgba(255,255,255,0)']}
                        style={styles.leftShadow}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                    />
                )} */}
          {/* <View style={styles.participantList}>

                </View> */}
          {/* <div className="activity-stream" style={{height: isExpanded ? 350 : 90}}> */}
          <div
            className="activity-stream"
            style={{ height: isExpanded ? "auto" : 90 }}
          >
            {!isExpanded && isRightArrowVisible && (
              <Arrow
                onClick={() => {
                  activityList.scrollLeft -= 50
                }}
                left={true}
              />
            )}
            <SlimScroll renderView={renderView}>
              {participantsData.map((item, index) =>
                renderAvatarItem({ item, index })
              )}
            </SlimScroll>
            {!isExpanded && isLeftArrowVisible && (
              <Arrow
                onClick={() => {
                  activityList.scrollLeft += 50
                }}
              />
            )}

            {/* <FlatList
                    style={styles.participantList}
                    key={'horizontal'}
                    data={participantsData}
                    initialListSize={6}
                    extraData={extraData}
                    renderItem={renderAvatarItem}
                    keyExtractor={(item, index) => '' + index}
                    horizontal={!isExpanded}
                    scrollEnabled={!isExpanded}
                    onScroll={handleParticipantListScroll}
                /> */}
          </div>
          {/* {!isExpanded && isShowRightShadow && (
                    <LinearGradient
                        colors={['rgba(255,255,255,1)', 'rgba(255,255,255,0)']}
                        style={styles.rightShadow}
                        start={{ x: 1, y: 0 }}
                        end={{ x: 0, y: 0 }}
                    />
                )} */}
        </View>
      </DetailGroup>
    </>
  )
}

ActivityStreamBlock.propTypes = {
  setExpandedGroupIndex: PropTypes.func,
  isExpanded: PropTypes.bool,
  extraData: PropTypes.any,
  participantsData: PropTypes.array,
  renderAvatarItem: PropTypes.func,
  renderFilterBlock: PropTypes.func,
  showNudgeActionSheet: PropTypes.func,
  showMentionsSelectParticipantScreen: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  setModalPeopleNudge: bindActionCreators(setModalPeopleNudge, dispatch),
})

export default connect(null, mapDispatchToProps)(ActivityStreamBlock)
