import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

const ViewStyles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 17,
  },
  addIcon: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
  },
  addText: {
    ...AppFonts.smallDemibold,
    color: AppColors.bgGradient2,
    lineHeight: Units.responsiveValue(17),
    letterSpacing: -Units.responsiveValue(0.24),
    marginLeft: Units.responsiveValue(4),
    fontSize: "13px",
  },
  button: {
    padding: 13,
    backgroundColor: "#ffffff",
    borderRadius: 20,
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: 12,
  },
})

export default ViewStyles
