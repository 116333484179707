import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { View, Text, BackHandler } from "react-native"
import _ from "lodash"
// import { bindActionCreators } from 'redux'

import {
  addDayAtStartOfDay,
  dayDifferenceBetweenDates,
  formatComingUpHeader,
  isToday,
  isTomorrow,
} from "@utils/dateUtils"
// import teeupActions from '@actions/teeupActions'
import { Units, AppSizes, AppColors } from "@theme/"
import { teeupStatusKeys, teeupUserStatusKeys } from "@configs/enums"
import {
  selectLatestTeeupEventDate,
  selectTeeupEventsByDay,
  selectTeeupEvents,
} from "@selectors/calendar"
import TeeupItem from "@components/teeupList/TeeupItem"
import {
  selectTeeupsParticipants,
  selectTeeupsPeople,
  selectTeeupsGameplans,
  selectActiveTeeups,
  selectArchivedTeeups,
} from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"
import { selectTeeupsStateTeeups } from "@selectors/teeupsState"
import { setTeeupActive } from "@actions/activeTeeupActions"
// import { routeTeeup } from '@nav'

import CalendarCardView from "./calendarCardView"
import { CalendarStyles } from "./calendarCardStyles"
import { selectUserId } from "@selectors/user"

const sizes = {
  dragger: Units.responsiveValue(22),
  midContainer: Units.responsiveValue(81),
}

const generateHeaderDays = (latestDayEvent) => {
  let days = []
  const daysFromToday = dayDifferenceBetweenDates(latestDayEvent)
  let dayCount = daysFromToday > 6 ? daysFromToday : 6
  for (let i = 0; i <= dayCount; i++) {
    days.push(addDayAtStartOfDay(undefined, i))
  }
  return days
}

const formatSections = (headerDays, teeupEventsByDay, teeups, teeupEvents) => {
  let sections = []
  sections = headerDays.map((day) => {
    let allset = false
    let planning = false
    let happening = false
    let data = []
    if (teeupEventsByDay[day]) {
      data = teeupEventsByDay[day]
        .map((teeupEventId) => {
          const itemTeeup = teeups.find(
            (singleTeeup) => singleTeeup.id === teeupEventId
          )
          const itemTeeupCalendar = teeupEvents[teeupEventId]

          if (itemTeeup) {
            if (itemTeeup?.status === teeupStatusKeys.planning) {
              planning = true
            } else if (itemTeeup?.status === teeupStatusKeys.allset) {
              allset = true
            } else if (itemTeeup?.status === teeupStatusKeys.happening) {
              happening = true
            }
            return { ...itemTeeupCalendar, ...itemTeeup }
          }
        })
        .filter((i) => i)
        .sort((teeup1, teeup2) => teeup1.startDate > teeup2.startDate)
    }
    return {
      id: day,
      title: formatTitle(day),
      data,
      status: {
        allset,
        planning,
        happening,
      },
    }
  })
  return sections
}

const formatTitle = (day) => {
  if (isToday(day)) {
    return "Today"
  } else if (isTomorrow(day)) {
    return "Tomorrow"
  } else {
    return formatComingUpHeader(day)
  }
}

class CalendarCardContainer extends Component {
  constructor(props) {
    super(props)
    const headerDays = generateHeaderDays(props.latestEventDate)
    this.TOGGLE_THRESHOLD = 500 / 11
    const topPositionThreshold =
      AppSizes.statusBarHeight + Units.responsiveValue(17)
    this.comingUpHeaderRef = React.createRef()

    this.BOTTOM_POSITION = {
      x: 0,
      y: props.contentHeight,
    }

    this.UP_POSITION = {
      x: 0,
      y: topPositionThreshold,
    }

    this.MIDDLE_POSITION = {
      x: 0,
      y: props.contentHeight - sizes.dragger - sizes.midContainer,
    }

    this.state = {
      headerDays,
      currentPosition: props?.newSuggestionProps
        ? this.BOTTOM_POSITION
        : this.MIDDLE_POSITION,
      sections: [
        {
          title: "",
          data: [],
          status: {},
        },
      ],
      activeDayHeader: "",
      teeupEvents: {},
      teeups: props.teeups,
    }
  }

  setCurrentPosition = (position) => {
    const { onCloseCalendarCard } = this.props
    this.setState({ currentPosition: position })

    if (position === this.UP_POSITION) {
      this.toggleNavBar("top")
    } else {
      this.toggleNavBar()
    }
    if (position === this.BOTTOM_POSITION && onCloseCalendarCard) {
      onCloseCalendarCard()
    }
  }

  componentDidUpdate(prevProps) {
    const { isCalendarShown } = this.props
    if (prevProps.isCalendarShown !== isCalendarShown) {
      this.setCurrentPosition(
        isCalendarShown ? this.UP_POSITION : this.BOTTOM_POSITION
      )
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let updates = null
    if (
      nextProps.teeupEvents !== state.teeupEvents ||
      nextProps.teeups !== state.teeups
    ) {
      const sections = formatSections(
        state.headerDays,
        nextProps.teeupEventsByDay,
        nextProps.teeups,
        nextProps.teeupEvents
      )
      updates = {
        teeupEvents: nextProps.teeupEvents,
        sections,
        activeDayHeader: sections[0].id,
      }
    }
    return updates
  }

  renderSectionHeader = ({ section: { title, data } }) => {
    return (
      <View style={CalendarStyles.comingUpListHeaderContainer}>
        <Text
          style={[
            CalendarStyles.comingUpListHeaderText,
            data.length === 0 && CalendarStyles.comingUpListEmptyHeaderText,
          ]}
        >
          {title}{" "}
        </Text>
        <View style={CalendarStyles.comingUpListHeaderSeparator} />
      </View>
    )
  }

  renderItem = ({ item, index }) => {
    const {
      teeupsState,
      teeupParticipants,
      teeupPeople,
      teeupGameplans,
      userId,
      teeups,
    } = this.props

    if (!item) {
      return null
    }
    const { id } = item

    return (
      <View
        key={`calendar-teeupItem-${id}`}
        style={CalendarStyles.teeupContainer}
      >
        <TeeupItem
          currentUserId={userId}
          teeup={item}
          teeupState={teeupsState[id]}
          participants={_.get(teeupParticipants, id, [])}
          people={_.get(teeupPeople, id, {})}
          gameplans={_.get(teeupGameplans, id, [])}
          onPress={() => this.onTeeupPressed(item)}
          testID={`teeup-${id}`}
          isLast={index + 1 === teeups.length}
          isComingUp
        />
      </View>
    )
  }

  onTeeupPressed = (item = {}) => {
    // const { teeupPeople, userInfo } = this.props

    // const { id } = item
    // const people = _.get(teeupPeople, id, {})
    // const user = _.get(people, userInfo.id, {})
    // if (
    //     user?.status !== teeupUserStatusKeys.droppedout &&
    //     user?.status !== teeupUserStatusKeys.skipped
    // ) {
    //     this.props.setTeeupActive(item)
    //     routeTeeup()
    // }
    setTeeupActive(item)
  }

  onViewableItemsChanged = ({ viewableItems }) => {
    if (!viewableItems[0]) {
      return
    }

    this.setState({
      activeDayHeader: viewableItems[0].section.id,
    })
  }

  scrollToDay = (day) => {
    let sectionIndex = this.state.sections.findIndex(
      (section) => section.id === day
    )
    const { current: refCurrent } = this.comingUpHeaderRef || {}
    if (refCurrent && sectionIndex > -1) {
      refCurrent.scrollToLocation({
        sectionIndex,
        itemIndex: 0,
        viewOffset: -Units.responsiveValue(16),
      })
    }
  }

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleAndroidBackPress
    )
  }

  handleAndroidBackPress = () => {
    const { newSuggestionProps } = this.props
    if (this.state.currentPosition === this.UP_POSITION) {
      this.setCurrentPosition(
        newSuggestionProps ? this.BOTTOM_POSITION : this.MIDDLE_POSITION
      )
      return true
    } else {
      return false
    }
  }

  componentWillUnmount() {
    this.backHandler.remove()
  }

  toggleNavBar = (position) => {
    // if (position === 'top') {
    //     Navigation.mergeOptions(this.props.parentComponentId, {
    //         statusBar: {
    //             backgroundColor: AppColors.brand.black40,
    //         },
    //     })
    // } else {
    //     Navigation.mergeOptions(this.props.parentComponentId, {
    //         statusBar: {
    //             backgroundColor: AppColors.brand.white,
    //         },
    //     })
    // }
  }

  togglePosition = () => {
    const { newSuggestionProps, onCloseCalendarCard } = this.props
    const { currentPosition } = this.state
    let newPosition = newSuggestionProps
      ? this.BOTTOM_POSITION
      : this.MIDDLE_POSITION
    if (currentPosition === this.UP_POSITION) {
      newPosition = newSuggestionProps
        ? this.BOTTOM_POSITION
        : this.MIDDLE_POSITION
    } else if (currentPosition === this.MIDDLE_POSITION) {
      newPosition = this.UP_POSITION
    }
    this.setCurrentPosition(newPosition, () => {
      if (newPosition === this.BOTTOM_POSITION && onCloseCalendarCard) {
        onCloseCalendarCard()
      }
    })

    return newPosition
  }

  toggleUp = () => {
    const { onCloseCalendarCard } = this.props
    const newPosition = this.UP_POSITION

    this.setCurrentPosition(newPosition, () => {
      if (newPosition === this.BOTTOM_POSITION && onCloseCalendarCard) {
        onCloseCalendarCard()
      }
    })

    return newPosition
  }

  render() {
    const { headerDays, currentPosition, sections, activeDayHeader } =
      this.state

    const {
      contentHeight,
      newSuggestionProps,
      onCloseCalendarCard,
      activeScreen,
      bottomMargin = 0,
    } = this.props
    const isFromSugggestionDetails =
      !!newSuggestionProps?.isFromSugggestionDetails
    return (
      <CalendarCardView
        headerDays={headerDays}
        bottomMargin={bottomMargin}
        setCurrentPosition={this.setCurrentPosition}
        currentPosition={currentPosition}
        upPosition={this.UP_POSITION}
        middlePosition={this.MIDDLE_POSITION}
        bottomPosition={this.BOTTOM_POSITION}
        toggleThreshold={this.TOGGLE_THRESHOLD}
        sections={sections}
        renderSectionHeader={this.renderSectionHeader}
        renderItem={this.renderItem}
        onViewableItemsChanged={this.onViewableItemsChanged}
        activeDayHeader={activeDayHeader}
        activeScreen={activeScreen}
        contentHeight={contentHeight}
        scrollToDay={this.scrollToDay}
        comingUpHeaderRef={this.comingUpHeaderRef}
        togglePosition={this.togglePosition}
        toggleUp={this.toggleUp}
        newSuggestionProps={newSuggestionProps}
        onCloseCalendarCard={onCloseCalendarCard}
        isFromSugggestionDetails={isFromSugggestionDetails}
        openSettings={this.props.openSettings}
      />
    )
  }
}

CalendarCardContainer.propTypes = {
  // parentComponentId: PropTypes.string.isRequired,
  // setTeeupActive: PropTypes.func.isRequired,
  onCloseCalendarCard: PropTypes.func,
  userInfo: PropTypes.object.isRequired,
  // contentHeight: PropTypes.number.isRequired,
  isCalendarShown: PropTypes.bool,
  bottomMargin: PropTypes.number,

  teeupsState: PropTypes.object,
  teeupParticipants: PropTypes.object,
  teeupPeople: PropTypes.object,
  teeupGameplans: PropTypes.object,
  teeups: PropTypes.array,
  teeupList: PropTypes.array,
  latestEventDate: PropTypes.string,
  teeupEvents: PropTypes.object,
  teeupEventsByDay: PropTypes.object,
  activeScreen: PropTypes.string,
  // newSuggestionProps: PropTypes.oneOfType([
  //     PropTypes.string,
  //     PropTypes.shape({
  //         duration: PropTypes.number,
  //         passedEvent: PropTypes.object,
  //         isFromCreateTeeup: PropTypes.bool,
  //         selectedDate: PropTypes.oneOfType([
  //             PropTypes.string,
  //             PropTypes.object,
  //         ]),
  //         onDatesSelected: PropTypes.func,
  //         onPickTimerangeFromCalendar: PropTypes.func,
  //     }),
  // ]),
}

const mapStateToProps = (state) => {
  const latestEventDate = selectLatestTeeupEventDate(state)

  const teeupEvents = selectTeeupEvents(state)
  const teeupEventsByDay = selectTeeupEventsByDay(state)

  const teeupParticipants = selectTeeupsParticipants(state)
  const teeupPeople = selectTeeupsPeople(state)
  const teeupGameplans = selectTeeupsGameplans(state)
  const teeupsState = selectTeeupsStateTeeups(state)
  const userInfo = selectUserInfo(state)
  const teeups = selectActiveTeeups(state)
  const archivedTeeups = selectArchivedTeeups(state)
  const userId = selectUserId(state)

  return {
    teeupParticipants,
    teeupPeople,
    teeupGameplans,
    teeupsState,
    userInfo,
    teeups: [...teeups, ...archivedTeeups],
    latestEventDate,
    teeupEvents,
    teeupEventsByDay,
    userId,
  }
}

export default connect(mapStateToProps, null)(CalendarCardContainer)
