import React from "react"
import propTypes from "prop-types"
import { View, Text } from "react-native"

import { getInitials } from "@utils/dataUtils"
import Avatar from "@ui/avatar"
import {
  styles,
  withoutTimezoneWrapper,
  unknownTimezone,
  unknownTimezoneAvatar,
} from "./styles"

const UsersWithoutTimezones = ({ participants, withDarkTheme }) => {
  const firstUserName = participants[0].name || participants[0].label
  const otherUsersCount = participants.length - 1
  const usersWithoutTimezones = participants
    .map(({ id, name, avatar }, index) => {
      const initials = getInitials(name)
      const isFirstUser = index === 0
      return (
        <Avatar
          key={`unknownTimezone-${id}`}
          style={unknownTimezoneAvatar({
            isFirstUser,
          })}
          size={32}
          imageUrl={avatar}
          initials={initials}
        />
      )
    })
    .reverse()
  return (
    <View style={withoutTimezoneWrapper({ withDarkTheme })}>
      <View style={styles.usersWithoutTimezones}>{usersWithoutTimezones}</View>
      <View style={styles.marginLeft10}>
        <View style={styles.row}>
          <Text style={styles.withoutTimezoneTitle}>
            {firstUserName}
            {otherUsersCount ? ` and ${otherUsersCount} others` : null}
          </Text>
        </View>
        <Text style={unknownTimezone({ withDarkTheme })}>Unknown Timezone</Text>
      </View>
    </View>
  )
}

UsersWithoutTimezones.propTypes = {
  participants: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      label: propTypes.string,
    })
  ).isRequired,
  withDarkTheme: propTypes.bool,
}

export default UsersWithoutTimezones
