import React from "react"
import GoogleMap from "../../../CreateTeeUp/GoogleMap"
import ZonesContainer from "../../../timezones/zones/zonesContainer"

const TimeZoneDetails = ({
  address,
  participants,
  people,
  endDateHoursInMinutes,
  duration,
}) => {
  return (
    <div>
      {address ? (
        <GoogleMap fromSuggestionDetails address={address} />
      ) : (
        <ZonesContainer
          participants={participants}
          onZonesScrolled={() => {}}
          people={people}
          duration={duration}
          endDateHoursInMinutes={endDateHoursInMinutes}
          fromSuggestionDetails
        />
      )}
    </div>
  )
}

export default TimeZoneDetails
