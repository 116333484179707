import React from "react"
import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"
import { images } from "@utils/imageUtils"

import { EventsStyles as styles } from "./styles"

const TeeupBlock = ({ title, hasConflist = false }) => {
  return (
    <View
      style={[
        styles.teeupEventContainer,
        hasConflist
          ? styles.conflictedTeeupEventContainer
          : styles.basicTeeupEventContainer,
      ]}
    >
      <Text style={[styles.teeupEventText]}>
        <Image source={images.teeupSmall} style={styles.teeupEventIcon} />{" "}
        {title || "(Unnamed teeup)"}
      </Text>
    </View>
  )
}

TeeupBlock.propTypes = {
  title: PropTypes.string,
  hasConflist: PropTypes.bool,
}

export default TeeupBlock
