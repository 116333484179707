import React, { Component, createRef } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { View, Text, FlatList, TouchableOpacity, Image } from "react-native"
import ParticipantsCriticalMassBar from "./participantsCriticalMassBar"
import StartsWhenBar from "./startsWhenBar"
import RedirectButton from "../../../redirectButton"
import { teeupUserStatusKeys, teeupUserStatus } from "../../../../config/enums"
import { userStatusTypes } from "@config/mappings"

import strings from "@i18n"
import { images } from "@utils/imageUtils"
import { filterParticipants } from "@utils/teeupUtils"

import StatsBlocks from "./statsBlocks"
import ActivityStreamBlock from "./activityStreamBlock"
import SyncUpBlock from "./syncUpBlock"

import NotImplementedYet from "../../../more/timeZones/NotImplementedYet"

import {
  ParticipantsStyles,
  CriticalMassButtonStyles,
  titleContainer,
} from "./participantsStyles"
// import { CardStyles, ViewStyles, UserStatusButtonProps } from '../teeupStyles'
// import { Units } from '@theme/'
import { PARTICIPANTS } from "@utils/filters"
// import { showActionsheet } from '@nav'
// import WhenWorksBar from '@components/teeup/whenWorksBar'

import { AppColors } from "@theme"
import ExpandedPeopleStatusBlock from "./ExpandedPeopleStatusBlock"

const UserStatusButtonProps = [
  {
    title: teeupUserStatus.joined,
    titleColor: AppColors.brand.darkGrey2,
    refTitle: teeupUserStatus.onmyway,
    topTitle: "TEEUP REACTION",
    image: images.joinedBig,
    color: AppColors.status.user.joined,
    border: AppColors.brand.grey2,
    right: true,
    testID: "teeupUserJoinedBtn",
    status: teeupUserStatusKeys.joined,
  },
  {
    title: teeupUserStatus.coolIdea,
    refTitle: teeupUserStatus.onmyway,
    image: images.snowflake,
    color: AppColors.status.user.coolIdea,
    right: false,
    testID: "teeupUserCoolideaBtn",
    status: teeupUserStatusKeys.coolIdea,
  },
  {
    title: teeupUserStatus.invited,
    refTitle: teeupUserStatus.onmyway,
    image: images.envelope,
    color: AppColors.status.user.invited,
    right: true,
    testID: "teeupUserInvitedBtn",
    status: teeupUserStatusKeys.invited,
  },
  {
    title: teeupUserStatus.going,
    refTitle: teeupUserStatus.onmyway,
    image: images.arrowRight,
    color: AppColors.status.user.going,
    right: true,
    testID: "teeupGoingBtn",
    status: teeupUserStatusKeys.going,
  },
  {
    title: teeupUserStatus.mightgo,
    refTitle: teeupUserStatus.onmyway,
    image: images.question,
    color: AppColors.status.user.mightgo,
    right: true,
    testID: "teeupMightGoBtn",
    status: teeupUserStatusKeys.mightgo,
  },
  {
    title: teeupUserStatus.notgoing,
    refTitle: teeupUserStatus.onmyway,
    image: images.times,
    color: AppColors.status.user.notgoing,
    right: true,
    testID: "teeupUserNotGoingBtn",
    status: teeupUserStatusKeys.notgoing,
  },
  {
    title: teeupUserStatus.onmyway,
    refTitle: teeupUserStatus.onmyway,
    image: images.rocket,
    color: AppColors.status.user.onmyway,
    right: false,
    testID: "teeupUserOnMyWayBtn",
    status: teeupUserStatusKeys.onmyway,
  },
  {
    title: teeupUserStatus.attended,
    refTitle: teeupUserStatus.onmyway,
    image: images.flagCheckered,
    color: AppColors.status.user.attended,
    right: true,
    testID: "teeupUserAttendedBtn",
    status: teeupUserStatusKeys.attended,
  },
  {
    topTitle: "HEADS UP",
    title: teeupUserStatus.onmyway,
    refTitle: teeupUserStatus.going,
    image: images.arrowRight,
    color: AppColors.status.user.onmyway,
    right: true,
    testID: "teeupUserGoingBtn",
    status: teeupUserStatusKeys.onmyway,
  },
  {
    title: teeupUserStatus.runningLate,
    refTitle: teeupUserStatus.droppedout,
    image: images.runningLateOrange,
    color: AppColors.status.user.runningLate,
    right: true,
    testID: "teeupUserRunningLateBtn",
    status: teeupUserStatusKeys.runningLate,
  },
  {
    title: teeupUserStatus.arrived,
    refTitle: teeupUserStatus.onmyway,
    image: images.flag,
    color: AppColors.status.user.arrived,
    right: true,
    testID: "teeupUserArrivedBtn",
    status: teeupUserStatusKeys.arrived,
  },
  {
    title: teeupUserStatus.backedOut,
    refTitle: teeupUserStatus.droppedout,
    image: images.backedoutRed,
    color: AppColors.status.user.backedOut,
    right: false,
    testID: "teeupUserBackedoutBtn",
    status: teeupUserStatusKeys.backedOut,
  },
]

export class ParticipantsCardView extends Component {
  constructor(props) {
    super(props)
    this.dropdownRef = React.createRef()

    this.state = {
      initialStatus: "",
      isPeopleManagement: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      if (this.props.currentUser.status)
        this.setState({
          initialStatus: this.props.currentUser.status,
        })
    }
  }

  renderStatus = () => {
    const {
      onMyStatusPress,
      userStatus,
      isExpanded,
      // myAdditionalCount,
      renderStautsOverlay,
      currentUser,
    } = this.props

    let userStatusButtonProps
    if (currentUser.status)
      userStatusButtonProps =
        UserStatusButtonProps.find(
          (prop) => prop.status === currentUser.status
        ) || {}
    else
      userStatusButtonProps =
        UserStatusButtonProps.find(
          (prop) => prop.status === userStatusTypes[this.state.initialStatus]
        ) || {}

    // if(isNaN(userStatus)) userStatusButtonProps = UserStatusButtonProps.find(prop => prop.status === userStatus) || {}
    // else userStatusButtonProps = UserStatusButtonProps.find(prop => prop.status === userStatusTypes[userStatus]) || {}

    // const userStatusButtonProps =
    //     UserStatusButtonProps.find(prop => prop.status === userStatus) || {}

    return (
      <View
        style={
          isExpanded
            ? ParticipantsStyles.statusButtonContainerExpanded
            : ParticipantsStyles.statusButtonContainerCollapsed
        }
      >
        {userStatusButtonProps.status === teeupUserStatusKeys.joined ? (
          <TouchableOpacity
            style={ParticipantsStyles.setStatusButton}
            onPress={onMyStatusPress}
            ref={this.dropdownRef}
          >
            <Text style={ParticipantsStyles.setStatusButtonText}>
              SET STATUS
            </Text>
            {renderStautsOverlay(this.dropdownRef)}
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={[
              ParticipantsStyles.statusButton,
              { backgroundColor: userStatusButtonProps.color },
            ]}
            onPress={onMyStatusPress}
            ref={this.dropdownRef}
          >
            <Image
              source={userStatusButtonProps.image}
              resizeMode="contain"
              style={ParticipantsStyles.rightImage}
            />
            <Text style={ParticipantsStyles.myStatusButtonText}>
              {userStatusButtonProps.title}
            </Text>
            {/* {!!myAdditionalCount && (
                            <Text style={ViewStyles.rightCount}>
                                +{myAdditionalCount}
                            </Text>
                        )} */}
            {renderStautsOverlay(this.dropdownRef)}
          </TouchableOpacity>
        )}
      </View>
    )
  }

  renderFilterBlock = () => {
    const { participantFilter, showFilterOptions, changeParticipantsFilter } =
      this.props
    const filterKey =
      participantFilter === PARTICIPANTS.LAST_ACTIVITY
        ? "participantsFilter.lastActivity"
        : participantFilter === PARTICIPANTS.STATUS
        ? "participantsFilter.status"
        : "participantsFilter.name"
    return (
      <View style={ParticipantsStyles.filtersContainer}>
        <TouchableOpacity
          style={ParticipantsStyles.filters}
          activeOpacity={1}
          // hitSlop={Units.hitSlop()}
          onPress={() =>
            showFilterOptions(participantFilter, changeParticipantsFilter)
          }
        >
          <Image source={images.sort} style={ParticipantsStyles.sort} />
          <Text style={ParticipantsStyles.filterText}>
            {strings[filterKey]}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  // renderFilterBlock = (participantFilter, changeParticipantsFilter) => {
  //     const filterKey =
  //         participantFilter === PARTICIPANTS.LATEST_UPDATE
  //             ? 'Latest Update'
  //             : 'Status'
  //     const { showFilterOptions, renderDropDown, isMenuButtonModalOpen } = this.props;
  //     return (
  //         <View style={ParticipantsStyles.filtersContainer}>
  //             <TouchableOpacity
  //                 style={[ParticipantsStyles.filters, {position: 'relative'}]}
  //                 activeOpacity={1}
  //                 onPress={() => {
  //                     showFilterOptions()
  //                 }}
  //             >
  //                 <Image
  //                     source={images.sort}
  //                     style={ParticipantsStyles.sort}
  //                 />
  //                 <Text style={ParticipantsStyles.filterText}>
  //                     {filterKey}
  //                 </Text>
  //                 <Image
  //                     source={images.caretDown}
  //                     style={[ParticipantsStyles.caretDown, isMenuButtonModalOpen && {transform: 'rotate(180deg'}]}
  //                 />
  //                 {renderDropDown()}
  //             </TouchableOpacity>
  //             { this.renderStatus()}
  //         </View>
  //     )
  // }

  getParticipantsData = (isForExpandedState) => {
    const { participants, avatarsAmount, people, teeup } = this.props
    let participantsData = [1]
    if (participants?.length > 0 && avatarsAmount) {
      // Make sure there's always at least 1 participant - for dynamic height calculating
      participantsData = [
        ...filterParticipants(participants, people, {
          filter: PARTICIPANTS.STATUS_CHANGE,
        }),
      ]
    }

    if (isForExpandedState) {
      participantsData = [
        ...filterParticipants(participantsData, people, teeup),
      ]
    }

    participantsData = participantsData.map((item) => ({
      itemId: item,
    }))
    if (participantsData && avatarsAmount) {
      if (!isForExpandedState) {
        if (participantsData.length > avatarsAmount) {
          const lastItem = {
            ...participantsData[avatarsAmount - 1],
            hiddenCount: participantsData.length - (avatarsAmount - 1),
          }
          participantsData = [
            ...participantsData.slice(0, avatarsAmount - 1),
            lastItem,
          ]
        }
      }
    }
    return participantsData
  }

  render() {
    const {
      // participantsReactions,
      // participants,
      participantsData,
      teeupStatus,
      participantsCount,
      goingCount,
      arrivedCount,
      onMyWayCount,
      mightGoCount,
      notGoingCount,
      runningLateCount,
      backedOutCount,
      joinedCount,
      showGoingCount,
      critmassGoingCount,
      isCriticalMassEnabled,
      criticalMass,
      renderAvatarItem,
      renderInviteButton,
      extraData,
      toggleExpansion,
      isExpanded,
      startsWhenGameplan,
      participantFilter,
      changeParticipantsFilter,
      // isShowAll,
      // showAllParticipants,
      onPermissionsClick,
      handleCritmassClick,
      isStartsWhenEnabled,
      // isWhenWorksEnabled,
      calculateCollapsedAvatarAmount,
      expandCard,
      people,
      formattedTimezones,
      isMultiTimezones,
      setExpandedGroupIndex,
      expandedGroupIndex,
      linkParticipantDetail,

      userStatus,
      currentUser,
    } = this.props

    return (
      <View pointerEvents="box-none" style={{ flex: 1 }}>
        <View style={titleContainer(isExpanded)} pointerEvents="box-none">
          <View
            style={ParticipantsStyles.titleWrapper}
            pointerEvents="box-none"
          >
            <Text style={ParticipantsStyles.title}>People</Text>
            {!isExpanded && (
              <>
                <Image
                  style={[
                    ParticipantsStyles.userIcon,
                    { width: 11, height: 12 },
                  ]}
                  source={images.people}
                />
                <Text style={ParticipantsStyles.number}>
                  {participantsCount}
                </Text>
                {showGoingCount && (
                  <>
                    <Image
                      style={ParticipantsStyles.arrowIcon}
                      source={images.arrow}
                    />
                    <Text style={ParticipantsStyles.number}>{goingCount}</Text>
                  </>
                )}
              </>
            )}
            <View style={ParticipantsStyles.spacer} />
          </View>
          {!isExpanded && renderInviteButton()}
        </View>
        {isCriticalMassEnabled && (
          <ParticipantsCriticalMassBar
            currentAmount={critmassGoingCount}
            criticalMass={criticalMass}
          />
        )}
        {/* {isStartsWhenEnabled && (
                    <StartsWhenBar startsWhenGameplan={startsWhenGameplan} />
                )} */}
        {!isExpanded && (
          <View style={ParticipantsStyles.participantListContainer}>
            <TouchableOpacity
              activeOpacity={1}
              onPress={expandCard}
              style={ParticipantsStyles.participantList}
              onLayout={calculateCollapsedAvatarAmount}
            >
              <FlatList
                style={[
                  ParticipantsStyles.participantList,
                  isExpanded && ParticipantsStyles.participantListGap,
                ]}
                data={participantsData}
                initialListSize={4}
                extraData={extraData}
                renderItem={renderAvatarItem(false)}
                keyExtractor={(item, index) => "" + index}
                numColumns={4}
                contentContainerStyle={[
                  ParticipantsStyles.collapsedContentContainer,
                ]}
              />
            </TouchableOpacity>
            {!isExpanded && this.renderStatus()}
          </View>
        )}
        {isExpanded && (
          <>
            <TouchableOpacity>
              <img
                src={images.manageParticipants}
                style={{
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  right: "80px",
                  top: "-35px",
                }}
                onClick={() =>
                  this.setState({
                    isPeopleManagement: !this.state.isPeopleManagement,
                  })
                }
              />
            </TouchableOpacity>
            {this.state.isPeopleManagement &&
              ReactDOM.createPortal(
                <NotImplementedYet
                  closeSelect={() =>
                    this.setState({ isPeopleManagement: false })
                  }
                />,
                document.getElementById("modal-root")
              )}
            {renderInviteButton(true)}
            <ExpandedPeopleStatusBlock renderStatus={this.renderStatus} />
            <StatsBlocks
              participantsCount={participantsCount}
              joinedCount={joinedCount}
              goingCount={goingCount}
              arrivedCount={arrivedCount}
              onMyWayCount={onMyWayCount}
              mightGoCount={mightGoCount}
              notGoingCount={notGoingCount}
              runningLateCount={runningLateCount}
              backedOutCount={backedOutCount}
              teeupStatus={teeupStatus}
            />
            <ActivityStreamBlock
              setExpandedGroupIndex={setExpandedGroupIndex}
              isExpanded={expandedGroupIndex === 0}
              participantsData={this.getParticipantsData(true)}
              extraData={extraData}
              renderAvatarItem={renderAvatarItem(true)}
              renderFilterBlock={this.renderFilterBlock}
              // showNudgeActionSheet={showNudgeActionSheet}
              // showMentionsSelectParticipantScreen={
              //     showMentionsSelectParticipantScreen
              // }
            />
            {isMultiTimezones && (
              <SyncUpBlock
                setExpandedGroupIndex={setExpandedGroupIndex}
                isExpanded={expandedGroupIndex === 1}
                participantsData={this.getParticipantsData(true)}
                formattedTimezones={formattedTimezones}
                linkParticipantDetail={linkParticipantDetail}
                people={people}
              />
            )}
          </>
        )}
      </View>
    )
  }
}

ParticipantsCardView.propTypes = {
  extraData: PropTypes.any,
  participants: PropTypes.array,
  participantsData: PropTypes.array,
  participantsReactions: PropTypes.object,
  participantsCount: PropTypes.number,
  goingCount: PropTypes.number,
  critmassGoingCount: PropTypes.number,
  isCriticalMassEnabled: PropTypes.bool,
  criticalMass: PropTypes.number,
  isTeeupDisabled: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  startsWhenGameplan: PropTypes.object,
  participantFilter: PropTypes.string,
  isShowAll: PropTypes.bool,
  handleCritmassClick: PropTypes.func,
  renderAvatarItem: PropTypes.func.isRequired,
  renderInviteButton: PropTypes.func.isRequired,
  // toggleExpansion: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
  changeParticipantsFilter: PropTypes.func,
  showAllParticipants: PropTypes.func,
  onPermissionsClick: PropTypes.func,
  isStartsWhenEnabled: PropTypes.bool,
  isWhenWorksEnabled: PropTypes.bool,
  showGoingCount: PropTypes.bool,
  userInfo: PropTypes.object,
  onMyStatusPress: PropTypes.func,
  userStatus: PropTypes.string,
  myAdditionalCount: PropTypes.number,
  calculateCollapsedAvatarAmount: PropTypes.func,
}

export default ParticipantsCardView
