import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import MoreContainer from "../components/more/moreContainer"
import { getUserContactMechanisms, getUserSettings } from "@actions/userActions"
import { getContacts } from "@actions/createTeeupActions"
import { fetchTeeups } from "@actions/teeupActions"
import { setTitle } from "@actions/commonActions"
import { fetchRequests } from "@actions/requestActions"
import { titles } from "../config/enums"
import { selectActiveTeeups } from "@selectors/teeups"
import { selectRequestsList } from "@selectors/requests"
import { selectUserId } from "@selectors/user"
import { useLocation } from "react-router-dom"

const SettingsPage = (props) => {
  const location = useLocation()
  const [activeScreen, setActiveScreen] = useState()

  useEffect(() => {
    getUserContactMechanisms()
    getUserSettings()
    props.setTitle(titles.defaultTitle)
    props.getContacts()
    if (props.teeups.length === 0) {
      props.fetchTeeups()
    }
    if (Object.keys(props.requests).length === 0) {
      props.fetchRequests(props.userId)
    }
  }, [])

  useEffect(() => {
    if (location.state) {
      setActiveScreen(location.state.activeScreen)
    }
  }, [location])

  return (
    <div className="page">
      <div className="settings__wrapper">
        <MoreContainer activeScreen={activeScreen} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userId: selectUserId(state),
  teeups: selectActiveTeeups(state),
  requests: selectRequestsList(state),
})

export default connect(mapStateToProps, {
  fetchTeeups,
  getContacts,
  fetchRequests,
  setTitle,
})(SettingsPage)
