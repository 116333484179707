import React from "react"

import './index.scss'

const Pinboard = ({ suggestInfo }) => {
  return (
    <div className="suggest-pinboard-online">
      {suggestInfo && suggestInfo.title !== "Other"
        ? suggestInfo.title
          ? <div className="suggest-pinboard-online__app">
              <img
                src={suggestInfo.icon}
                alt="icon"
                className="suggest-pinboard-online__icon"
              />
              {suggestInfo.title}
            </div>
          : <p className="suggest-pinboard-online__placeholder">Meeting App</p>
        : suggestInfo && suggestInfo.appName
          ? suggestInfo.appName
          : <p className="suggest-pinboard-online__placeholder">Meeting App</p>
      }
      <div>
        {suggestInfo && suggestInfo.details
          ? suggestInfo.details.length > 25 ? suggestInfo.details.slice(0, 40) + "..." : suggestInfo.details
          : <p className="suggest-pinboard-online__placeholder">Meeting details</p>
        }
      </div>
    </div>
  )
}

export default Pinboard
