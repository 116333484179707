import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import ReactDOM from "react-dom"
import LocationMapChart from "../LocationMapChart";
import LocationDetector from "@components/LocationDetector";
import { getUserLocation, postUserLocation } from "@actions/userActions";

import './index.scss'

const LocationMap = () => {
  const [pressedCount, setPressedCount] = useState(0)
  const [primaryLocation, setPrimaryLocation] = useState({
    longitude: null,
    latitude: null,
  })
  const userLocation = useSelector(state => state.user.primaryLocation)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    getUserLocation()
  }, [])

  useEffect(() => {
    if (primaryLocation.longitude) {
      (async () => {
        await postUserLocation(primaryLocation)
        getUserLocation()
      })()
    }
  }, [primaryLocation])

  useEffect(() => {
    userLocation ? setIsOpen(false) : setIsOpen(true)
  }, [userLocation])

  return (
    <div className="location-map">
      {isOpen && ReactDOM.createPortal(
        <LocationDetector
          pressedCount={pressedCount}
          setPressedCount={setPressedCount}
          primaryLocation={primaryLocation}
          setPrimaryLocation={setPrimaryLocation}
          close={() => setIsOpen(false)}
        />,
        document.getElementById("modal-root")
      )}
      <LocationMapChart />
      <div className="location-map__overlap"></div>
    </div>
  )
}

export default LocationMap
