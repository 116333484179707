import React from "react"
import PropTypes from "prop-types"
import { userPollingStatusImage } from "@utils/teeupUtils"
// import { ImageWithDisabled } from '@utils/imageUtils'
import { View } from "react-native"
import { PollingAccesoryStyles as styles } from "./styles"

const PollingAccesory = ({
  avatarSize,
  // accesoryWidth,
  accesoryHeight,
  pollingReaction,
  disabled,
  accesoryStyle = {},
}) => {
  const icon = userPollingStatusImage(Number(pollingReaction))

  if (!icon || !avatarSize || !accesoryHeight) {
    return null
  }

  const accesoryWidth = accesoryHeight
  return (
    <View
      style={[
        styles.accesory,
        { top: avatarSize - accesoryHeight },
        accesoryStyle,
      ]}
    >
      {/* {ImageWithDisabled(
                icon,
                accesoryWidth && {
                    width: accesoryWidth,
                    height: accesoryHeight,
                },
                {
                    style: accesoryWidth && {
                        width: accesoryWidth,
                        height: accesoryHeight,
                    },
                },
                disabled
            )} */}
    </View>
  )
}

PollingAccesory.propTypes = {
  avatarSize: PropTypes.number,
  accesoryWidth: PropTypes.number,
  accesoryHeight: PropTypes.number,
  disabled: PropTypes.bool,
  pollingReaction: PropTypes.string,
  accesoryStyle: PropTypes.object,
}

export default PollingAccesory
