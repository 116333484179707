const PREFIX = "native.calendar"

module.exports = {
  CHANGE_MONTH_LEFT_ARROW: `${PREFIX}.CHANGE_MONTH_LEFT_ARROW`,
  CHANGE_MONTH_RIGHT_ARROW: `${PREFIX}.CHANGE_MONTH_RIGHT_ARROW`,
  SELECT_DATE_SLOT: `${PREFIX}.SELECT_DATE_SLOT`,
  CALENDAR_KNOB: `${PREFIX}.CALENDAR_KNOB`,
  STATIC_HEADER: "STATIC_HEADER",
  AGENDA_CALENDAR_KNOB: `${PREFIX}.AGENDA_CALENDAR_KNOB`,
}
