import React, { useEffect, useState } from "react"
import ReactTooltip from "react-tooltip"
import endpoints from "@config/endpoints"
import api from "../../../api"
import { markAsDecided } from "../../../../src/middlewares/backendMiddleware"
import { images } from "@utils/imageUtils"

import "./index.scss"

const GamePlanTooltip = ({ activeTeeupId }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <ReactTooltip
      id="decided-tooltip"
      class="extraClass"
      backgroundColor="#ffffff"
      place="top"
      effect="solid"
      // afterHide={() => setIsOpen(true) }
      delayHide={500}
      // delayShow={ 500 }
      getContent={(id) => {
        if (!id || !isOpen) {
          return null
        }
        return (
          <div
            className="decided__title"
            onClick={() => markAsDecided(id, true)}
          >
            <img src={images.lock} />
            <p>Mark as Decided</p>
          </div>
        )
      }}
    />
  )
}

export default GamePlanTooltip
