import React from "react"
import PropTypes from "prop-types"
import { View, TouchableOpacity, Text } from "react-native"

import styles from "./ToggleButtonStyles"

const ToggleButton = (props) => {
  const { options, value, onChange } = props

  return (
    <View style={styles.container}>
      {options.map((option) => {
        const isActive = option.value === value
        return (
          <TouchableOpacity
            key={`option-${option.value}`}
            style={[styles.button, isActive && styles.buttonActive]}
            onPress={() => onChange(option.value)}
          >
            <Text style={[styles.label, isActive && styles.labelActive]}>
              {option.label}
            </Text>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

export default ToggleButton
