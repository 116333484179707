import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import I18n from '@i18n'

import TopBarManageNudgeItem from "./TopBarManageNudgeItem"

import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

import { messageTypeIds } from '@configs/mappings'
import { teeupListNudgeTypes } from '@configs/enums'
import { images } from "@utils/imageUtils"

import { sendNudge } from '@middlewares/backendMiddleware'
import { selectActiveTeeupId } from "@selectors/activeTeeup";
import { setActiveTextMessage }  from "@actions/teeupCardActions";

import './index.scss'

const TopBarManageNudge = ({ activePopUp, title, buttonText, close }) => {
  const { people, participants } = useTeeUpPage()
  const [selectedPeople, setSelectedPeople] = useState([])
  const [selectedPeopleIds, setSelectedPeopleIds] = useState([])
  const [isSelectedEverybody, setIsSelectedEverybody] = useState(false)

  const activeTeeupId = useSelector(selectActiveTeeupId)
  const dispatch = useDispatch()

  let onNudge

  useEffect(() => {
    setSelectedPeopleIds(selectedPeople.map(item => item.id))
  }, [selectedPeople])

  const handleSelect = (userInfo) => {
    if (selectedPeople.some(item => item.id === userInfo.id)) {
      setSelectedPeople(selectedPeople.filter(item => item.id !== userInfo.id))
    } else {
      setSelectedPeople(state => ([...state, userInfo]))
    }
  }

  const handleSelectEverybody = () => {
    if (!isSelectedEverybody) {
      participants.map(item => setSelectedPeople(state => ([...state, people[item]])))
      setIsSelectedEverybody(true)
    } else {
      setSelectedPeople([])
      setIsSelectedEverybody(false)
    }
  }

  const nudgeAreYouGoing = async () => {
    await sendNudge({
        message: I18n.nudgePromptContent.areYouGoing.action,
        messageTypeId: messageTypeIds.nudge,
        mentionIds: selectedPeopleIds,
        subTypeId: teeupListNudgeTypes.areYouGoing,
        teeupId: activeTeeupId
    })
  }

  const nudgeWhereAreYou = async () => {
    await sendNudge({
        message: I18n.nudgePromptContent.whereAreYou.action,
        messageTypeId: messageTypeIds.nudge,
        mentionIds: selectedPeopleIds,
        subTypeId: teeupListNudgeTypes.whereAreYou,
        teeupId: activeTeeupId
    })
  }

  const mentionPeople = () => {
    const mentionMessage =
    selectedPeople?.length === participants?.length
            ? '@Everybody '
            : selectedPeople
                  .map(
                      (participant) =>
                          `@${participant.username || participant.name}`
                  )
                  .join(' ') + ' '
    
    dispatch(setActiveTextMessage(mentionMessage))
}

  switch (activePopUp) {
    case 2:
      onNudge = nudgeWhereAreYou
      break;
    case 3: 
      onNudge = nudgeAreYouGoing
      break;
    case 4:
      onNudge = mentionPeople
      break;
    default:
      break;
  }


  return (
    <div
      className="manage-nudge__wrapper"
      onClick={() => close()}
    >
      <div
        className="manage-nudge"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="manage-nudge__title">{title}</div>
        <div
          className="manage-nudge__cross"
          onClick={() => close()}
        >
          <div className="manage-nudge__cross--icon"></div>
        </div>
        <div className="manage-nudge__content">
          <div
            className="manage-nudge-item"
            onClick={() => handleSelectEverybody()}
          >
            <div className="manage-nudge-item__avatar--wrapper">
              <img src={images.ndTeeUpSelectEverybody} />
            </div>
            <h4 className="manage-nudge-item__title">Select Everybody</h4>
            <div className="manage-nudge-item__select">
              <img
                src={isSelectedEverybody ? images.ndTeeUpSelected : images.ndTeeUpNotSelected}
                alt="Not Selected"
              />
            </div>
          </div>
          {participants && participants.map((id, index) => (
            <TopBarManageNudgeItem
              data={people[id]}
              selectedPeople={selectedPeople}
              handleSelect={() => handleSelect(people[id])}
              key={id}
            />
          ))}
        </div>
        <div className="manage-nudge__bottom">
          <button
            className="manage-nudge__button"
            type="button"
            onClick={() => {
              onNudge()
              close()
            }}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TopBarManageNudge;
