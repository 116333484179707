import React from "react"
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native"
import PropTypes from "prop-types"

import { AppColors, AppFonts, Units } from "@theme/"

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  tagContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: Units.responsiveValue(16),
    paddingBottom: Units.responsiveValue(12),
    borderBottomWidth: 1,
    borderBottomColor: AppColors.brand.lightGrey,
    backgroundColor: AppColors.brand.white,
  },
  tagIcon: {
    alignSelf: "flex-start",
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
    marginRight: Units.responsiveValue(6),
    tintColor: AppColors.brand.blackTwo,
  },
  deleteIcon: {
    width: Units.responsiveValue(22),
    height: Units.responsiveValue(22),
    marginRight: Units.responsiveValue(18),
  },
  tagValue: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.blackTwo,
  },
  taggedUserCount: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.warmGrey2,
  },
})

const TagItem = ({
  tag,
  isTaggingEnabled,
  isEditMode,
  onTagItemPress,
  onDeletePress,
}) => {
  let taggedUserCount = "Nobody tagged yet"
  if (tag.count === 1) {
    taggedUserCount = `${tag.count} person tagged`
  } else if (tag.count > 1) {
    taggedUserCount = `${tag.count} people tagged`
  }

  if (isEditMode) {
    return (
      <View style={styles.tagContainer}>
        <TouchableOpacity onPress={onDeletePress}>
          <Image
            source={require("../assets/delete.png")}
            style={[styles.deleteIcon]}
          />
        </TouchableOpacity>
        <View>
          <Text style={styles.tagValue}>{tag.value}</Text>
        </View>
      </View>
    )
  }
  if (isTaggingEnabled) {
    return (
      <TouchableOpacity onPress={onTagItemPress} style={styles.tagContainer}>
        <View>
          <Image source={require("../assets/tag.png")} style={styles.tagIcon} />
        </View>
        <View style={styles.flex}>
          <Text style={styles.tagValue}>{tag.value}</Text>
          <Text style={styles.taggedUserCount}>{taggedUserCount}</Text>
        </View>
      </TouchableOpacity>
    )
  }
  if (tag) {
    return (
      <View style={styles.tagContainer}>
        <Image source={require("../assets/tag.png")} style={styles.tagIcon} />
        <View style={styles.flex}>
          <Text style={styles.tagValue}>{tag.value}</Text>
          <Text style={styles.taggedUserCount}>{taggedUserCount}</Text>
        </View>
      </View>
    )
  }
  return null
}

TagItem.propTypes = {
  tag: PropTypes.object.isRequired,
  isTaggingEnabled: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  onTagItemPress: PropTypes.func.isRequired,
  onDeletePress: PropTypes.func.isRequired,
}

export default TagItem
