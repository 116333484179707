// Directly from state: present, maxId. From update: updates
// Warning: updates might be modified here
import React from "react"
import { Linking, Platform, Text } from "react-native"
// import RNFS from 'react-native-fs'
// import FileViewer from 'react-native-file-viewer'
// import AndroidOpenSettings from 'react-native-android-open-settings'

import { AppColors } from "@theme"

const openMap = (coords, placeName = "") => {
  var url = ""

  Platform.select({
    ios: () => {
      url = "http://maps.apple.com/maps?daddr="
    },
    android: () => {
      url = "http://maps.google.com/maps?daddr="
    },
  })()

  url += coords

  // Prioritize google maps
  var schema = "comgooglemaps://"
  Linking.canOpenURL(schema)
    .then((supported) => {
      if (!supported) {
        Linking.openURL(url)
      } else {
        const markerLabel = Platform.OS === "ios" ? placeName : ""
        Linking.openURL(
          schema +
            "?center=" +
            coords +
            "&zoom=14&views=traffic&daddr=" +
            markerLabel +
            coords
        )
      }
    })
    .catch((err) => console.error("An error occurred", err))
}

const openEmail = (email) => {
  Linking.openURL("mailto:" + email)
}

const openPhone = (phone) => {
  Linking.openURL("tel:" + phone)
}

const openNotificationSettings = () => {
  // if (Platform.OS === 'android') {
  //     AndroidOpenSettings.appNotificationSettings()
  // }
  // if (Platform.OS === 'ios') {
  //     Linking.openURL('app-settings:')
  // }
}

const openTwitter = (url) => {
  openUrl(url)
}

const openFacebook = (url) => {
  openUrl(url)
}

// const share = url => {
//     let shareOptions = {
//         url,
//     }

//     console.log('sharing not available?')
//     // Share.open(shareOptions);
// }

const openUrl = (url) => {
  let prefixTest = "http" // might be https, so can't check for http://, but rather http to match both
  let prefix = "https://"
  // debugger
  if (url.indexOf(prefixTest) === -1) {
    url = prefix + url
  }
  Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        console.log("Can't handle url: " + url)
      } else {
        return Linking.openURL(url)
      }
    })
    .catch((err) => console.error("An error occurred", err))
}

const downloadAndOpenFile = (
  { url, options = { showOpenWithDialog: true } },
  onFilePress
) => {
  const filename = url.split("/").pop()
  // feel free to change main path according to your requirements
  // const localPath = `${RNFS.DocumentDirectoryPath}/${filename}`

  // const downloadOption = {
  //     fromUrl: url,
  //     toFile: localPath,
  // }
  // RNFS.downloadFile(downloadOption)
  //     .promise.then(() => {
  //         FileViewer.open(localPath, options)
  //             .then((openResult) => console.log('openResult: ', openResult))
  //             .catch((openError) => console.log('openError: ', openError))
  //             .finally(() => setTimeout(() => onFilePress(false), 300))
  //     })
  //     .catch((error) => {
  //         onFilePress(false)
  //         console.log('downloadFile Error: ', error)
  //     })
}

const urlPattern =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

const renderLink = (matchingString) => (
  <Text style={linkStyle}>{matchingString}</Text>
)

const linkStyle = {
  color: AppColors.brand.pink,
}
export {
  openUrl,
  openTwitter,
  openFacebook,
  openMap,
  openNotificationSettings,
  openEmail,
  openPhone,
  downloadAndOpenFile,
  urlPattern,
  renderLink,
}
