import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export const ViewStyles = StyleSheet.create({
  navbar: {
    ...AppFonts.headerSmallDemibold,
    color: AppColors.brand.blackTwo,
  },
  settingsSidebar: {
    paddingTop: Units.responsiveValue(16),
    paddingLeft: Units.responsiveValue(16),
    width: 228,
    height: "100vh",
    backgroundColor: "white",
    marginLeft: "144px",
  },
  background: {
    //  backgroundColor: AppColors.brand.lightGrey,
  },
  backBtnStyle: {
    paddingLeft: Units.responsiveValue(13),
  },
  buttonClusters: {
    // paddingTop: Units.rem(0.75),
  },
  buttonContainer: {
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Units.rem(1),
    paddingTop: Units.rem(0.9375),
    paddingBottom: Units.rem(1.1875),
    borderBottomWidth: 1,
    // borderColor: AppColors.brand.lightGrey,
  },
  buttonTitle: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.blackTwo,
  },
  buttonImage: {
    width: Units.rem(0.4375),
    height: Units.rem(0.75),
  },
  updateTextContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  updateIndicatorContainer: {
    paddingTop: Units.responsiveValue(3),
    paddingBottom: Units.responsiveValue(6),
    paddingHorizontal: Units.responsiveValue(12),
    backgroundColor: AppColors.brand.blue2,
    borderRadius: Units.responsiveValue(12),
    marginRight: Units.responsiveValue(8),
  },
  updateIndicatorText: {
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.white,
  },
  versionText: {
    color: AppColors.brand.warmGrey2,
    ...AppFonts.biggerDemibold,
  },
  versionTextNewVersion: {
    color: AppColors.brand.red,
  },
  buildVersionText: {
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.warmGrey2,
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  stubNavigationButton: {
    paddingHorizontal: Units.rem(1.5),
  },
})

export const ServiceStyles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    paddingTop: Units.responsiveValue(20),
    paddingHorizontal: Units.responsiveValue(20),
    marginBottom: "150px",
  },
  title: {
    ...AppFonts.header22,
    paddingBottom: Units.responsiveValue(16),
  },
  contentText: {
    fontFamily: "greycliffcf-regular-salt",
    fontSize: "15px",
    color: "#222222",
    letterSpacing: "-0.3px",
    lineHeight: "22px",
    paddingVertical: Units.responsiveValue(25),
  },
  contentHeading: {
    fontFamily: "greycliffcf-bold-salt",
    fontSize: "15px",
    letterSpacing: "-0.3px",
    lineHeight: "22px",
    color: "#222222",
  },
})

export const commonScreenStyles = StyleSheet.create({
  background: {
    //backgroundColor: AppColors.brand.lightGrey,
  },
  container: {
    paddingTop: 12,
  },
  header: {
    color: AppColors.brand.darkGrey2,
    textTransform: "uppercase",
    ...AppFonts.smallishDemibold,
    paddingBottom: 10,
    paddingTop: 15,
    paddingHorizontal: 16,
  },
  sectionTail: {
    marginBottom: 24,
  },
  caution: {
    color: AppColors.brand.darkGrey2_75,
    marginTop: 4,
    paddingHorizontal: 16,
    ...AppFonts.smallish,
  },
})
