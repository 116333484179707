import React, { useEffect, useMemo, useState } from "react"
import slideImgWebp from "../../../assets/images/newDesign/ndLoginSlider1.webp"
import slideImg from "../../../assets/images/newDesign/ndLoginSlider1.png"
import googlePlay from "../../../assets/images/newDesign/ndLoginGooglePlay.png"
import googlePlayWebp from "../../../assets/images/newDesign/ndLoginGooglePlay.webp"
import appStore from "../../../assets/images/newDesign/ndLoginAppStore.png"
import appStoreWebp from "../../../assets/images/newDesign/ndLoginAppStore.webp"

import Logo from "../../../assets/images/newDesign/ndLoginLogo.png"
import "./index.scss"
import { TEMPLATE_TYPES } from "./constants"
import { useHistory } from "react-router-dom"

const SLIDE_SECONDS = 2
const SLIDE_TOTAL = 5

/**
 * This template is for 3 pages: Sign in with email, Sign in with social medias and Sign Up
 * @param type {string} represents the 3 pages: 'signin-email', 'signin-social' and 'signup'
 *
 */

const CustomTemplateSign = ({ type, children }) => {
  const [actualSlide, setActualSlide] = useState(0)
  const history = useHistory()
  const activeTab = useMemo(() => {
    if (type === TEMPLATE_TYPES.signup) return 1
    else return 0
  }, [type])

  // useEffect(() => {
  //   const interval = setInterval(
  //     () => setActualSlide((actualSlide + 1) % SLIDE_TOTAL),
  //     SLIDE_SECONDS * 1000
  //   )
  //   return () => clearInterval(interval)
  // }, [actualSlide])

  const handleNavigate = (tab) => {
    const path = tab === 0 ? "/sign-in" : "/sign-up"
    if (activeTab !== tab) history.push(path)
  }

  const renderFooter = () => (
    <div className="ctsign__bottom">
      <a
        href="https://play.google.com/store/apps/details?id=com.app.cooe"
        target="_blank"
        rel="noopener noreferrer"
      >
        <picture>
          <source srcSet={googlePlayWebp} type="image/webp" />
          <img
            src={googlePlay}
            className="ctsign__bottom-img"
            alt="googlePlay img"
          />
        </picture>
      </a>
      <a
        href="https://apps.apple.com/app/coo-e-coordinator/id1507948580"
        target="_blank"
        rel="noopener noreferrer"
      >
        <picture>
          <source srcSet={appStoreWebp} type="image/webp" />
          <img
            src={appStore}
            className="ctsign__bottom-img"
            alt="appStore img"
          />
        </picture>
      </a>
    </div>
  )

  return (
    <div className="ctsign">
      <div className="ctsign__left">
        <img src={Logo} alt="logo cooWe" className="ctsign__logo" />
        <ul className="ctsign__left__tabs">
          <li
            className={`ctsign__left__tabs-item ${
              activeTab === 0 ? "item-active" : ""
            }`}
            onClick={() => handleNavigate(0)}
          >
            LOG IN
          </li>
          <li
            className={`ctsign__left__tabs-item ${
              activeTab === 1 ? "item-active" : ""
            }`}
            onClick={() => handleNavigate(1)}
          >
            SIGN UP
          </li>
        </ul>
        {children}
        {renderFooter()}
      </div>
      <div className="ctsign__right">
        <div className="ctsign__right-card">
          <picture>
            <source srcSet={slideImgWebp} type="image/webp" />
            <img
              src={slideImg}
              alt="thumb slide"
              className="ctsign__right-img"
            />
          </picture>
          <h1 className="ctsign__right-title">Invite Others to Coordinate</h1>
          <p className="ctsign__right-text">
            It's easy to invite people via SMS, emails, or messengers
          </p>
          <ul className="ctsign__right-slides">
            {Array(SLIDE_TOTAL)
              .fill(0)
              .map((_, index) => (
                <li
                  key={index}
                  onClick={() => setActualSlide(index)}
                  className={`${actualSlide === index ? "active-slide" : ""}`}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CustomTemplateSign
