import React, { useState } from "react"
import PropTypes from "prop-types"
import { TouchableOpacity, Text, View, Image } from "react-native"
import { images } from "@utils/imageUtils"
import { Units } from "@theme/"

import {
  BaseDialogButtonStyles as styles,
  dialogButtonContainer,
  borderOnHover,
} from "./styles"

export const CenteredDialogButton = ({
  title,
  onClick,
  containerStyles,
  textStyles,
  disabled,
  isActive,
}) => {
  return (
    <TouchableOpacity
      hitSlop={Units.hitSlop()}
      style={[dialogButtonContainer(), styles.centerAligned, containerStyles]}
      disabled={disabled}
      onPress={onClick}
    >
      <Text
        style={[
          styles.buttonText,
          styles.doneButtonText,
          isActive && styles.activeButtonText,
          textStyles,
        ]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  )
}

CenteredDialogButton.defaultProps = {
  containerStyles: {},
  textStyles: {},
}

CenteredDialogButton.propTypes = {
  title: PropTypes.string,
  containerStyles: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  textStyles: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
}

export const DialogButtonWithIcon = ({
  title,
  primaryIcon,
  useIconComponent,
  secondaryIcon,
  onClickPrimary,
  onClickSecondary,
  containerStyles,
  textStyles,
  primaryIconStyles,
  disabled,
  isActive,
  includesAlreadyResponded,
  icon,
  borderColor,
}) => {
  const [isItemHovered, setIsItemHovered] = useState(false)
  const renderPrimaryIcon = () => {
    if (useIconComponent) {
      return icon
    }
    return (
      <Image
        source={primaryIcon}
        resizeMode="contain"
        style={[
          styles.primaryIcon,
          isActive && styles.activeIconColor,
          primaryIconStyles,
        ]}
      />
    )
  }

  return (
    <TouchableOpacity
      onMouseOver={() => {
        setIsItemHovered(true)
      }}
      onMouseOut={() => {
        setIsItemHovered(false)
      }}
      hitSlop={Units.hitSlop()}
      style={[
        dialogButtonContainer(includesAlreadyResponded),
        styles.widthAligned,
        containerStyles,
        borderOnHover(borderColor, isItemHovered),
      ]}
      disabled={disabled}
      onPress={onClickPrimary}
    >
      <View style={styles.primaryIconContainer}>{renderPrimaryIcon()}</View>

      <View style={styles.flex}>
        <Text
          style={[
            styles.buttonText,
            isActive && styles.activeButtonText,
            textStyles,
          ]}
        >
          {title}
        </Text>
      </View>
      {secondaryIcon && (
        <>
          <View
            style={[styles.separator, isActive && styles.activeSeparator]}
          />
          <TouchableOpacity disabled={disabled} onPress={onClickSecondary}>
            <Image
              style={[styles.secondaryIcon, isActive && styles.activeIconColor]}
              source={secondaryIcon}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </>
      )}
    </TouchableOpacity>
  )
}

DialogButtonWithIcon.defaultProps = {
  containerStyles: {},
  textStyles: {},
  primaryIconStyles: {},
}

DialogButtonWithIcon.propTypes = {
  title: PropTypes.string,
  primaryIcon: PropTypes.string,
  secondaryIcon: PropTypes.string,

  containerStyles: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  textStyles: PropTypes.object,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  primaryIconProps: PropTypes.object,
  useIconComponent: PropTypes.bool,

  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  includesAlreadyResponded: PropTypes.bool,
}

export const AmountButton = ({
  number,
  containerStyles,
  iconStyles,
  textStyles,
  decreaseDisabled,
  increaseDisabled,
  onClickDecrease,
  onClickIncrease,
}) => {
  return (
    <View
      style={[
        dialogButtonContainer(),
        styles.amountButtonContainer,
        styles.widthAligned,
        containerStyles,
      ]}
    >
      <TouchableOpacity
        style={styles.amountChangingButton}
        hitSlop={Units.hitSlop()}
        disabled={decreaseDisabled}
        onPress={onClickDecrease}
      >
        <Image
          source={images.decrement}
          resizeMode="contain"
          style={[styles.amountChangingIcon, iconStyles]}
        />
      </TouchableOpacity>
      <View style={[styles.amountTextContainer, styles.flex]}>
        <Text style={[styles.amountText, textStyles]}>{number}</Text>
      </View>
      <TouchableOpacity
        style={styles.amountChangingButton}
        hitSlop={Units.hitSlop()}
        disabled={increaseDisabled}
        onPress={onClickIncrease}
      >
        <Image
          source={images.increment}
          resizeMode="contain"
          style={[styles.amountChangingIcon, iconStyles]}
        />
      </TouchableOpacity>
    </View>
  )
}

AmountButton.defaultProps = {
  containerStyles: {},
  textStyles: {},
  iconStyles: {},
}

AmountButton.propTypes = {
  number: PropTypes.number,
  containerStyles: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  iconStyles: PropTypes.object,
  textStyles: PropTypes.object,
  decreaseDisabled: PropTypes.bool,
  increaseDisabled: PropTypes.bool,
  onClickDecrease: PropTypes.func,
  onClickIncrease: PropTypes.func,
}
