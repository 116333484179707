import React from "react"
import propTypes from "prop-types"
import { View, FlatList, Image, Text } from "react-native"
import { images } from "@utils/imageUtils"

import { styles } from "./PinnedMessagesScreenStyles"
import { ReactSVG } from "react-svg"

import "./pinnedMessagesScreenComponent.scss"

const keyExtractor = (item, index) => `${item.id} + ${index}`

const ListEmptyComponent = () => (
  <View style={styles.emptyComponent}>
    <Image
      source={images.pinboardPlaceholder}
      style={styles.placeholderImage}
    />
    <Text style={styles.placeholderTitle}>No pinned messages yet</Text>
    <Text style={styles.placeholderText}>
      Go to the TeeUp conversation and pin something to get it here.
    </Text>
  </View>
)

const EmptyPinBoard = () => (
  <div className="empty-pin-board">
    <ReactSVG src={images.ndEmptyPinBoardImage} />
    <h2 className="empty-pin-board__title">No pinned messages yet</h2>
    <p className="empty-pin-board__description">
      To pin any part of the conversation, simply click on the ellipsis icon on
      a chat bubble and select Pin from the menu
    </p>
  </div>
)

ListEmptyComponent.propTypes = {
  insideTabContainer: propTypes.bool,
}

const PinnedMessagesScreenComponent = ({
  messages,
  onMessageItemRender,
  suggestions,
  insideTabContainer,
  newDesign,
}) => {
  if (!messages.length)
    return newDesign ? (
      <EmptyPinBoard />
    ) : (
      <ListEmptyComponent insideTabContainer={insideTabContainer} />
    )
  return (
    <View
      style={[
        styles.container,
        newDesign && { backgroundColor: "transparent" },
      ]}
    >
      <FlatList
        contentContainerStyle={styles.listPadding}
        data={messages}
        extraData={suggestions}
        initialListSize={4}
        renderItem={onMessageItemRender}
        keyExtractor={keyExtractor}
      />
    </View>
  )
}
PinnedMessagesScreenComponent.propTypes = {
  messages: propTypes.arrayOf(propTypes.shape({})),
  onMessageItemRender: propTypes.func.isRequired,
  suggestions: propTypes.shape({}),
  insideTabContainer: propTypes.bool,
}

export default PinnedMessagesScreenComponent
