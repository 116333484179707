import React from "react"
import PropTypes from "prop-types"
import { ScrollView, View, Text, TouchableOpacity } from "react-native"

import { Units } from "@theme"
import CalendarTimetable from "../calendarComponents/CalendarTimetable"
import CurrentTimeLine from "../calendarComponents/CurrentTimeLine"
import DayCalendarEvents from "./DayCalendarEvents"
import SelectedDateBlockComponent, { ROW_HEIGHT } from "../common"

import {
  DayCalendarTimetableStyles as styles,
  selectedTimeBlockContainer,
  selectedTimeBlock,
} from "./styles"

const DayCalendarTimetableView = ({
  eventsList,
  onCreateTeeup,
  allowCreateTeeup,
  columnsAmount,
  totalWidth,
  calculateBlocksWidth,
  isToday,
  onSelectTimeSquare,
  scrollViewRef,
  onScroll,
  hasNewSuggestionProps,
  onPickTimerangeFromCalendar,
  onDurationChanged,
  selectedDate,
  duration,
}) => {
  return (
    <ScrollView
      ref={scrollViewRef}
      scrollEventThrottle={16}
      onMomentumScrollEnd={onScroll}
    >
      <View style={styles.topPadding} />
      <CalendarTimetable onSelectTimeSquare={onSelectTimeSquare} />
      <View style={styles.eventsOverlayContainer} pointerEvents="box-none">
        <View
          style={styles.eventsOverlay}
          onLayout={calculateBlocksWidth}
          pointerEvents="box-none"
        >
          {totalWidth !== 0 && (
            <DayCalendarEvents
              columnsAmount={columnsAmount}
              totalWidth={totalWidth}
              calculateBlocksWidth={calculateBlocksWidth}
              eventsList={eventsList}
              onCreateTeeup={onCreateTeeup}
              allowCreateTeeup={allowCreateTeeup}
            />
          )}
          {hasNewSuggestionProps && (
            <SelectedTimeBlock
              onSelectTimeSquare={onSelectTimeSquare}
              onPickTimerangeFromCalendar={onPickTimerangeFromCalendar}
              selectedDate={selectedDate}
              duration={duration}
              onDurationChanged={onDurationChanged}
            />
          )}
          {isToday && <CurrentTimeLine totalWidth={totalWidth} />}
        </View>
      </View>
    </ScrollView>
  )
}

class SelectedTimeBlock extends SelectedDateBlockComponent {
  render() {
    const { position, multiplyIndex } = this.getPosition()
    const { onPickTimerangeFromCalendar } = this.props

    return (
      <TouchableOpacity
        style={selectedTimeBlockContainer({
          position,
          ROW_HEIGHT,
        })}
        onPress={onPickTimerangeFromCalendar}
        hitSlop={Units.hitSlop({
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        })}
      >
        {/* <View
                    {...this._panResponderTop.panHandlers}  //Temp remove of drag func
                    style={styles.suggestionDragBox(KNOB_HEIGHT)}
                /> */}
        <View
          style={selectedTimeBlock({
            ROW_HEIGHT,
            multiplyIndex,
          })}
        >
          <Text style={styles.suggestionText}>Your Suggestion</Text>
        </View>
        {/* <View
                    {...this._panResponderBottom.panHandlers}  //Temp remove of drag func
                    style={styles.suggestionDragBox(KNOB_HEIGHT)}
                /> */}
      </TouchableOpacity>
    )
  }
}

SelectedTimeBlock.propTypes = {
  onPickTimerangeFromCalendar: PropTypes.func,
  totalWidth: PropTypes.number,
  duration: PropTypes.number,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onDurationChanged: PropTypes.func,
}

DayCalendarTimetableView.propTypes = {
  eventsList: PropTypes.array,
  onCreateTeeup: PropTypes.func,
  onSelectTimeSquare: PropTypes.func,
  allowCreateTeeup: PropTypes.bool,
  columnsAmount: PropTypes.number,
  totalWidth: PropTypes.number,
  calculateBlocksWidth: PropTypes.func,
  isToday: PropTypes.bool,
  hasNewSuggestionProps: PropTypes.bool,
  onPickTimerangeFromCalendar: PropTypes.func,
  duration: PropTypes.number,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  scrollViewRef: PropTypes.object,
  onDurationChanged: PropTypes.func,
  onScroll: PropTypes.func,
}

export default DayCalendarTimetableView
