import React, { useEffect, useRef, useState } from "react"
import _ from "lodash"
import { View, Text, SectionList } from "react-native"
import PropTypes from "prop-types"
import {
  getAlphabeticalListHeaders,
  getContactsArray,
  sections,
} from "@utils/contacts"
import { getContactList } from "@utils/contactUtils"
import { frequentSorting, recentSorting } from "@utils/teeupUtils"
import AlphabeticalListColumn from "../../ui/alphabeticalListColumn"
import ContactsFilterModalNd from "../ContactsFilterModalNd"

import LinkButton from "../LinkButton"
import ContactItemNd from "../ContactItem/ContactItemNd"
import { styles } from "./ContactListStyles"
import userReducer from "reducers/userReducer"
import { createTableMultiSort } from "react-virtualized"

const ROW_HEIGHT = 64
const SECTION_HEADER_HEIGHT = 34

class ContactListNd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formattedContactsList: [],
      sections: [],
      activeLetterIndex: null,
      activeLetter: null,
    }

    this.isDragging = false
    this.lastOffset = 0
  }

  componentDidMount() {
    this.formatAllContactList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allContacts !== this.props.allContacts)
      this.formatAllContactList()
  }

  getSectionsData = (contactsList, sortingType) => {
    if (sortingType) {
      return contactsList.map((c) => ({ data: [c] }))
    }
    const sectionsArray = getAlphabeticalListHeaders(
      contactsList.map((contact) => {
        const contactList = getContactList(contact)

        return { ...contact, contactList }
      })
    )

    return sectionsArray
  }

  sortContacts = (sortingType, mergedContacts) => {
    if (sortingType === "Frequent") {
      return frequentSorting(mergedContacts)
    }

    if (sortingType === "Recent") {
      return recentSorting(mergedContacts)
    }

    return mergedContacts
  }

  formatAllContactList = () => {
    // Such formatting/sorting should be done in AddInviteesContainer. ONCE
    const { allContacts, sortingType } = this.props
    const mergedContacts = getContactsArray(allContacts)
    const sortedContacts = this.sortContacts(sortingType, mergedContacts)
    let sections = this.getSectionsData(sortedContacts, sortingType)
    const verticalAlphabet = sections.map((section) => section.name)
    this.setState({
      formattedContactsList: sortedContacts,
      sections,
      verticalAlphabet,
    })
  }

  renderItem = ({ item, index, section: { name } }) => {
    const {
      invitees,
      sectionOpenItemCount,
      expandedContactItemId,
      onToggleExpand,
      onToggleContact,
      onChangeContactType,
      openPreviousTeeupParticipants,
      searchText,
      fromContactsBook,
      openInviteToTeeupModal,
      handleOnInviteesDone,
    } = this.props
    const openItemCount = sectionOpenItemCount[name]
    if (openItemCount && openItemCount < index + 1) {
      return null
    }

    if (name === sections.PREVIOUS) {
      return (
        <LinkButton
          key={item.title}
          title={item.name}
          subTitle={`${item.users.length} participant${
            item.users.length > 1 ? "s" : ""
          }`}
          onPress={openPreviousTeeupParticipants(item)}
        />
      )
    }

    const invited = Boolean(
      invitees.filter(
        (invitee) =>
          (invitee.userType === item.userType ||
            invitee.userType === item.usertype) &&
          invitee.id === item.id
      ).length
    )

    const expanded = expandedContactItemId[item.id]

    return (
      <ContactItemNd
        searchText={searchText}
        invited={invited}
        expanded={expanded}
        data={item}
        onToggleContact={onToggleContact}
        onToggleExpand={onToggleExpand(item.id)}
        onChangeContactType={onChangeContactType}
        fromContactsBook={fromContactsBook}
        openInviteToTeeupModal={openInviteToTeeupModal}
        handleOnInviteesDone={handleOnInviteesDone}
      />
    )
  }

  renderSectionHeader = ({ section: { name, data = [] } }) => {
    if (this.props.isSearching) {
      return null
    }
    if (!data.length) {
      return null
    }
    return (
      <div className="contact-itemNd__section">
        {name}
      </div>
    )
  }

  keyExtractor = (item) => `${item.id}`

  onVerticalAlphabetIndex = (letter) => {
    const { sections, activeLetter: prevActiveLetter } = this.state
    const { isSearching, renderContactItem } = this.props

    if (!this.flatListRef) {
      return
    }
    let sectionIndex = _.findIndex(
      sections,
      (section) => section.name === letter
    )

    this.setState(
      { activeLetterIndex: sectionIndex, activeLetter: letter },
      () => {
        const extraSpace = sectionIndex > 4 ? 0 : 18
        const listHeaderHeight = isSearching || renderContactItem ? 0 : 64
        this.flatListRef.scrollToLocation({
          sectionIndex,
          itemIndex: -sectionIndex,
          viewOffset:
            (sectionIndex + 1) * SECTION_HEADER_HEIGHT +
            extraSpace -
            listHeaderHeight,
          animated: false,
        })

        setTimeout(() => {
          if (prevActiveLetter === letter) {
            this.setState({
              activeLetterIndex: null,
              activeLetter: null,
            })
          }
        }, 250)
      }
    )
  }

  getItemLayout = (data, index) => ({
    length: ROW_HEIGHT,
    offset: ROW_HEIGHT * index,
    index,
  })

  onScrollToIndexFailed = () => {
    // console.log('onScrollToIndexFailed ContactList')
  }

  onScrollEndDrag = ({ nativeEvent }) => {
    const newOffset = nativeEvent.contentOffset.y

    const scrolledUp = newOffset < this.lastOffset
    this.props.onContactsScrolled(scrolledUp)
    this.isDragging = false
    this.lastOffset = newOffset
  }

  onScrollBeginDrag = () => {
    this.isDragging = true
  }

  onScroll = ({ nativeEvent }) => {
    if (!this.isDragging) {
      const newOffset = nativeEvent.contentOffset.y
      this.lastOffset = newOffset
    }
  }

  setSectionListRef = (ref) => {
    this.flatListRef = ref
  }

  onViewChange = () => {
    this.setState({
      activeLetterIndex: null,
      activeLetter: null,
    })
  }

  render() {
    const {
      allContacts,
      sortingType,
      isSearching,
      renderContactItem,
      fromCreateTeeup,
      isContactFilterOpened,
      selectedTag,
      tags,
      handleSearch,
      contacts,
      rememberSelectedTag,
      currentTag,
      closeContactsFilterModal,
      fromContactsBook,
      handleSearchByCustomTags,
      selectedTags,
      setSelectedTags
    } = this.props
    const { sections, verticalAlphabet, activeLetterIndex, activeLetter } =
      this.state

    const ifDataExists = sections.length || allContacts.length
    return (
      <>
        {isContactFilterOpened && (
          <ContactsFilterModalNd
            selectedTag={selectedTag}
            tags={tags}
            handleSearch={handleSearch}
            contacts={contacts}
            rememberSelectedTag={rememberSelectedTag}
            currentTag={currentTag}
            closeContactsFilterModal={closeContactsFilterModal}
            handleSearchByCustomTags={handleSearchByCustomTags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        )}
        {ifDataExists ? (
          <div>
            <SectionList
              width={400}
              heigh={400}
              ref={this.setSectionListRef}
              sections={sections}
              maxToRenderPerBatch={20}
              viewabilityConfig={{
                waitForInteraction: false,
                viewAreaCoveragePercentThreshold: 95,
              }}
              extraData={this.props}
              renderItem={renderContactItem ? renderContactItem : this.renderItem}
              renderSectionHeader={this.renderSectionHeader}
              keyExtractor={this.keyExtractor}
              getItemLayout={this.getItemLayout}
              keyboardShouldPersistTaps="handled"
              onViewableItemsChanged={_.debounce(this.onViewChange, 250)}
            />
          </div>
        ) : (
          <>
            {!fromCreateTeeup && (
              <div
                className="inviteNd-contacts__notFound"
                style={{
                  minHeight: fromContactsBook ? "100vh" : "505px"
                }}
              >
                <p className="inviteNd-contacts__notFound--text">No Contacts Found</p>
              </div>
            )}
          </>
        )}
      </>
    )
      
  }
}

ContactListNd.propTypes = {
  allContacts: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  searchText: PropTypes.string,
  invitees: PropTypes.array.isRequired,
  sectionOpenItemCount: PropTypes.object.isRequired,
  expandedContactItemId: PropTypes.object.isRequired,
  onViewMore: PropTypes.func.isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  openPreviousTeeupParticipants: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
  onToggleContact: PropTypes.func.isRequired,
  onChangeContactType: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  onContactsScrolled: PropTypes.func.isRequired,
  sortingType: PropTypes.string,
  renderContactItem: PropTypes.func,
}

export default ContactListNd