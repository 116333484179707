import { Alert, Platform, NativeModules } from "react-native"
// import { PERMISSIONS, RESULTS } from 'react-native-permissions'
import * as RNLocalize from "react-native-localize"
// import Contacts from 'react-native-contacts'
// import SendSMS from 'react-native-sms'
// import Mailer from 'react-native-mail'
// import Instabug from 'instabug-reactnative'
import { uniqBy, isString } from "lodash"
import { parsePhoneNumber } from "libphonenumber-js"
import { parsePhoneNumberFromString } from "libphonenumber-js/max"

import { userTypes as userTypeIds } from "../config/enums"
import { checkModulePermission } from "@utils/permissionUtils"
import { validateEmail } from "./dataUtils.js"
import { getUserNameForAlphabeticalSorting } from "./contacts"

const isIOS = Platform.OS === "ios"

export const CONTACT_PERMISSION_DENIED = "CONTACT_PERMISSION_DENIED"

export const parsedPhoneNumber = (number) => {
  const phone = parsePhoneNumberFromString(number)
  if (phone) return phone
  else return null
}

export const getCountryCodeFromPhoneNumber = (number) => {
  const phone = parsePhoneNumberFromString(number)
  if (phone) {
    return (phone.country || "us").toLowerCase()
  }

  return "us"
}

// Country code in lowercase CCA2
export const getCountryCode = () =>
  new Promise((resolve) => {
    if (isIOS) {
      resolve((RNLocalize.getCountry() || "us").toLowerCase())
    } else {
      NativeModules.NativeUtils.getDeviceCountry((cca2) => {
        resolve((cca2 || "us").toLowerCase())
      })
    }
  })

export const formatToInternational = (number) => {
  const phone = parsedPhoneNumber(number)
  if (phone) return phone.formatInternational()
  else return number
}

export const validatePhoneNumber = (phoneNumber) => {
  const phone = parsedPhoneNumber(phoneNumber)
  if (phone) {
    return phone.isValid()
  }
  return false
}

export const countryCodeFromDialingCode = (dialingCode) => {
  const fakeNumber = `+${dialingCode} 2345678900`
  try {
    return parsePhoneNumberFromString(fakeNumber).country
  } catch (e) {
    //
  }

  return null
}

export const requestTypes = {
  joinGroup: "join_group",
  inviteToGroup: "invite_to_group",
  inviteToConnect: "invite_to_connect",
}

export const inviteTypes = {
  inviteToGroup: "INVITE_TO_GROUP",
  inviteToTeeup: "INVITE_TO_TEEUP",
}

export const requestStatusId = {
  pending: 1,
  accepted: 2,
  declined: 3,
  canceled: 4,
}

export const userTypes = {
  COO_E: "cooeUser",
  PHONE_BOOK: "phoneBookContact",
  MANUAL: "manual",
}

export const contactTypes = {
  PHONE: "phone",
  EMAIL: "email",
  USER: "user",
}

export const userContactTypes = {
  PRIMARY_EMAIL: 1,
  EMAIL: 2,
  PHONE: 3,
  PRIMARY_PHONE: 4,
  LOCATION: 5,
  URL: 6,
}

const contactSimilarityType = {
  DIFFERENT: "DIFFERENT",
  EDITED: "EDITED",
  EQUAL: "EQUAL",
}

export const removePhoneSymbol = (phone) => {
  if (phone.number) {
    let phoneNumber = { ...phone }
    phoneNumber.number = phoneNumber.number.replace(/[^+\d]+/g, "")

    return phoneNumber
  } else if (isString(phone)) {
    return phone.replace(/[^+\d]+/g, "")
  }

  // Something's wrong
  return ""
}

export const getContactId = (contact) => {
  return contact.cooeId || contact.id
}

export const isCooeUser = (contact) => {
  let yesNo =
    contact &&
    contact.type === userTypes.COO_E &&
    (contact.userType === userTypeIds.normal ||
      contact.userType === userTypeIds.betaFull ||
      contact.usertype === userTypeIds.normal ||
      contact.usertype === userTypeIds.betaFull) // need to talk with BE, because from invitee's suggestions we receive all in lowercase
  return yesNo
}

export const getFormattedPhoneBook = async () => {
  let phoneBookContacts
  try {
    phoneBookContacts = await getPhoneBookContacts()
  } catch (err) {
    console.log(err)
    phoneBookContacts = []
  }

  const hasNameAndInfo = (contact) =>
    (contact.givenName || contact.familyName) &&
    (contact.emailAddresses.length > 0 || contact.phoneNumbers.length > 0)

  let contacts = []
  for (let i = 0, len = phoneBookContacts.length; i < len; i++) {
    const contact = phoneBookContacts[i]
    if (!hasNameAndInfo(contact)) {
      continue
    }

    const { phoneNumbers, recordID } = contact

    contacts.push({
      ...contact,
      phoneNumbers: phoneNumbers.map(removePhoneSymbol),
      recordId: recordID,
    })
  }

  // let timeProcessedContacts = new Date().getTime()

  // console.log(
  //     'processed (including fetch) contacts in ' +
  //         (new Date().getTime() - startTime) +
  //         ' milliseconds'
  // )
  // Instabug.reportJSException(
  //     Error(
  //         'NOT ERROR. fetched contacts in ' +
  //             (timeFetchedContacts - startTime) +
  //             ' ms. Processed in ' +
  //             (timeProcessedContacts - timeFetchedContacts) +
  //             ' ms'
  //     )
  // )

  return contacts
}

export const formatInvitees = (invitees = []) => {
  const formatted = invitees
    .map((invitee) => {
      if (!invitee) return
      if (invitee.userType === userTypes.MANUAL) {
        let formattedObj = {}
        if (invitee.contactType === contactTypes.EMAIL) {
          formattedObj = {
            email: invitee.value,
            firstName: getEmailLabel(invitee.value),
            lastName: "",
          }
        } else {
          formattedObj = {
            phone: invitee.value,
            firstName: getPhoneLabel(invitee.value),
            lastName: "",
          }
        }
        // Country code isn't allowed on the BE side
        // if (invitee.countryCode) {
        //     formattedObj.countryCode = invitee.countryCode
        // }
        return formattedObj
      }

      if (isCooeUser(invitee)) {
        return { id: getContactId(invitee) }
      }

      // Use specified invitee contact mechanism OR first contact mechanism
      // In the future, only pass contactId
      if (invitee.contactList && invitee.contactList.length > 0) {
        const inviteeObject = {
          firstName: invitee.firstName || "",
          lastName: invitee.lastName || "",

          // Warning: probably unnecessary
          timezone: "America/New_York",
        }

        let contactMechanism =
          invitee.contactType && invitee.value
            ? invitee
            : invitee.contactList[0] || {}
        if (contactMechanism.contactType === contactTypes.PHONE) {
          try {
            const phoneObject = parsePhoneNumber(
              !contactMechanism.value.startsWith("+")
                ? `+${contactMechanism.value}`
                : contactMechanism.value
            )
            if (phoneObject && phoneObject.country) {
              inviteeObject[contactMechanism.contactType] =
                contactMechanism.value

              // Country code isn't allowed on the BE side
              // inviteeObject.countryCode =
              // phoneObject.countryCallingCode
            }
          } catch (e) {
            console.log("phoneNumberEROR: ", { e })
            if (!invitee.contactList[1]) return
            contactMechanism = invitee.contactList[1] || {}
            inviteeObject[contactMechanism.contactType] = contactMechanism.value
            //
          }
        } else {
          inviteeObject[contactMechanism.contactType] = contactMechanism.value
        }
        return inviteeObject
      }

      if (invitee.outlet) {
        let value =
          invitee.outlet.type === contactTypes.PHONE
            ? removePhoneSymbol(invitee.outlet.value)
            : invitee.outlet.value
        return {
          [invitee.outlet.type]: value,
          firstName: invitee.givenName || "",
          lastName: invitee.familyName || "",

          // Warning: probably unnecessary
          timezone: "America/New_York",
        }
      }

      return { id: invitee.id }

      // : { contactId: invitee.contactId }

      // return invitee.cooeId
      //     ? { id: invitee.cooeId }
      //     : { contactId: invitee.contactId }
    })
    .filter((invitee) => !!invitee)
  return formatted
}

export const getPhoneBookContacts = async () => {
  // const permission = await checkModulePermission(
  //     isIOS ? PERMISSIONS.IOS.CONTACTS : PERMISSIONS.ANDROID.READ_CONTACTS
  // )
  // return new Promise((resolve) => {
  //     if (permission !== RESULTS.GRANTED) {
  //         Alert.alert(
  //             'Please check Contacts permissions for Coo-e app via device settings.'
  //         )
  //         return resolve([])
  //     }
  // Contacts.getAll((error, bookContacts) => {
  //     if (!error) {
  //         return resolve(bookContacts)
  //     } else {
  //         console.log(error)
  //     }
  // })
  // })
}

const getUniqueNumbers = (numbers) => {
  return Array.from(new Set(numbers.map((n) => n.number))).map((number) =>
    numbers.find((n) => n.number === number)
  )
}

export const formatContacts = (contacts) => {
  const uniqContacts = uniqBy(contacts, "id")
  return uniqContacts
    .filter((contact) => contact.phoneNumbers.length || contact.emails.length)
    .map((contact) => formatContact(contact))
    .sort((a, b) => {
      const aName = getUserNameForAlphabeticalSorting(a)
      const bName = getUserNameForAlphabeticalSorting(b)

      const aIsLetter = aName[0].match(/[a-z]/i)
      const bIsLetter = bName[0].match(/[a-z]/i)

      if ((aIsLetter && bIsLetter) || (!aIsLetter && !bIsLetter)) {
        if (aName < bName) {
          return -1
        }
        if (aName > bName) {
          return 1
        }
        return 0
      } else if (aIsLetter) {
        return -1
      } else if (bIsLetter) {
        return 1
      }
    })
}

export const formatContact = (contact) => {
  if (isCooeUser(contact)) {
    return { ...contact, contactType: contactTypes.USER }
  }

  const firstName = contact.firstName || contact.givenName
  const lastName = contact.lastName || contact.familyName
  const userType = contact.userType || userTypes.PHONE_BOOK
  const id = contact.id || contact.contactId
  const phoneList = getUniqueNumbers(contact.phoneNumbers).map((data) => ({
    contactType: contactTypes.PHONE,
    label: data.label,
    value: data.number,
  }))
  const emailList = (contact.emails ? contact.emails : contact.email).map(
    (data) => ({
      contactType: contactTypes.EMAIL,
      label: data.label,
      value: data.email,
    })
  )
  const contactList = phoneList.concat(emailList)
  const mobilePhoneIndex = contactList.findIndex((c) => c.label === "mobile")
  const selectedIndex = mobilePhoneIndex === -1 ? 0 : mobilePhoneIndex
  const firstContactData = contactList[selectedIndex] || {}

  return {
    ...contact,
    id,
    firstName,
    lastName,
    userType,
    contactList,
    selectedIndex,
    contactType: firstContactData.contactType,
    contactLabel: firstContactData.label,
    value: firstContactData.value,
  }
}

export const getInviteesWithoutDuplicates = ({
  formattedInvitees = [],
  alreadyInvitedIds = [],
  showToastAboutDuplicates = () => {},
}) => {
  let inviteesWithoutDuplicates = {}

  formattedInvitees.forEach((invitee) => {
    if (invitee.userType !== userTypes.MANUAL) {
      const isDuplicate =
        `${invitee.id}` in inviteesWithoutDuplicates ||
        alreadyInvitedIds.includes(invitee.id)
      if (!isDuplicate) {
        inviteesWithoutDuplicates[invitee.id] = invitee
      } else {
        showToastAboutDuplicates()
      }
    } else {
      // if user invite a synced contact which wasn't invited before
      // and than add the same contact mechanism as manual contact
      // we can't identify is it a duplicate or not just by id
      let isDuplicate = false

      for (
        let i = 0;
        i < formattedInvitees.length &&
        formattedInvitees[i].id !== invitee.id &&
        !isDuplicate;
        i++
      ) {
        const inviteeToCompare = formattedInvitees[i]

        const valueToCompare =
          invitee.contactType === contactTypes.PHONE
            ? removePhoneSymbol(invitee.value.split(" ").join(""))
            : invitee.value

        if (
          inviteeToCompare.value &&
          inviteeToCompare.value === valueToCompare
        ) {
          isDuplicate = true
        } else if (
          inviteeToCompare.contactList &&
          inviteeToCompare.contactList
            .map((contact) => contact.value)
            .includes(valueToCompare)
        ) {
          isDuplicate = true
        }
      }

      if (!isDuplicate) {
        inviteesWithoutDuplicates[`${userTypes.MANUAL}${invitee.id}`] = invitee
      } else {
        showToastAboutDuplicates()
      }
    }
  })

  return Object.values(inviteesWithoutDuplicates)
}

export const findContactByValue = ({ contacts, contactType, value }) => {
  return contacts.find((contact) => {
    if (contactType === contactTypes.PHONE) {
      return contact.phoneNumbers.some((number) => number.number === value)
    }
    if (contactType === contactTypes.EMAIL) {
      return contact.emails.some((email) => email.email === value)
    }
    return false
  })
}

export const prepareManualContact = ({
  manualContactId,
  contactType,
  value,
}) => {
  const userObj = {
    id: manualContactId,
    userType: userTypes.MANUAL,
    label:
      contactType === contactTypes.PHONE
        ? getPhoneLabel(value)
        : getEmailLabel(value),
    value:
      contactType === contactTypes.PHONE ? removePhoneSymbol(value) : value,
    contactType,
  }
  // post country code from phone number
  if (contactType === contactTypes.PHONE) {
    try {
      const phoneObject = parsePhoneNumber(value)
      if (phoneObject.country) {
        userObj.countryCode = phoneObject.countryCallingCode
      }
    } catch (e) {
      console.warn(e)
    }
  }
  return userObj
}

export const sendEmailsAndSMSToInvitees = (
  invitees = [],
  title,
  link,
  type,
  inviterName,
  callback
) => {
  let validContactMechanisms = invitees
    .filter(
      (invitee) =>
        // Filter out cooe users and people without contact mechanisms
        invitee &&
        (invitee.contactType || invitee.contactList) &&
        !isCooeUser(invitee)
    )
    .map((invitee) => {
      if (invitee) {
        if (invitee.contactType) {
          // Either MANUAL user type OR specific selected contact
          return invitee
        } else {
          return invitee.contactList[0]
        }
      }
    })

  let smsRecipients = validContactMechanisms
    .filter(
      (contactMechanism) =>
        contactMechanism && contactMechanism.contactType === contactTypes.PHONE
    )
    .map((contactMechanism) => contactMechanism && contactMechanism.value)

  let emailRecipients = validContactMechanisms
    .filter(
      (contactMechanism) =>
        contactMechanism && contactMechanism.contactType === contactTypes.EMAIL
    )
    .map((contactMechanism) => contactMechanism && contactMechanism.value)

  let sendEmailsRequest = () =>
    sendEmailToInvitees(type, emailRecipients, title, link, inviterName)

  if (smsRecipients.length > 0) {
    sendSMSToInvitees(
      type,
      smsRecipients,
      title,
      link,
      inviterName,
      sendEmailsRequest
    )
  } else {
    sendEmailsRequest()
  }
  callback()
}

export const getInvitationHeader = (type, title, inviterName) => {
  if (type === inviteTypes.inviteToGroup) {
    return `You’re invited to the “${title}” group on Coo-e!`
  }
  return `${inviterName} invited you to "${title}"`
}

export const getNameOrUsername = (user) => {
  if (user) {
    if (user.name) return user.name
    return user?.username ? user.username : null
  }
  return null
}

const sendEmailToInvitees = (type, recipients, title, link, inviterName) => {
  const subject = getInvitationHeader(type, title, inviterName)

  let body = subject + " using Coo-e: " + link

  // if (recipients.length > 0) {
  //     Mailer.mail(
  //         {
  //             subject,
  //             recipients,
  //             body,
  //             isHTML: true,
  //         },
  //         () => {
  //             // Alert.alert(
  //             //     error,
  //             //     event,
  //             //     [
  //             //         {
  //             //             text: 'Ok',
  //             //             onPress: () =>
  //             //                 console.log('OK: Email Error Response'),
  //             //         },
  //             //         // { text: 'Cancel', onPress: () => console.log('CANCEL: Email Error Response') }
  //             //     ],
  //             //     { cancelable: true }
  //             // )
  //         }
  //     )
  // }
}

const sendSMSToInvitees = async (
  type,
  recipients,
  title,
  link,
  inviterName,
  callback
) => {
  const subject = getInvitationHeader(type, title, inviterName)
  let body = subject + " using Coo-e: " + link

  // console.log("recipients: " + recipients.length + " sms body: " + body)

  // If no unregistered users, don't show SMS screen
  if (recipients.length > 0) {
    // Manually invite users
    // SendSMS.send(
    //     {
    //         body,
    //         recipients,
    //         successTypes: ['sent', 'queued'],
    //         allowAndroidSendWithoutReadPermission: true,
    //     },
    //     (completed, cancelled, error) => {
    //         // If error, don't create teeup at all
    //         // if (error || cancelled) {
    //         if (error) {
    //             Alert.alert(
    //                 'An error occured while sending message, please try again'
    //             )
    //         }
    //         if (callback) {
    //             callback()
    //         }
    //         // console.log(
    //         //     'SMS Callback: completed: ' +
    //         //         completed +
    //         //         ' cancelled: ' +
    //         //         cancelled +
    //         //         ' error: ' +
    //         //         error
    //         // )
    //     }
    // )
  }
}

export const getPhoneLabel = (phone) =>
  `***${removePhoneSymbol(phone).slice(-4)}`
export const getEmailLabel = (email) => email.replace(/@[^@]+$/, "")

export const isSyncedContact = (contact) => {
  if (contact) {
    return (
      contact.contactType === contactTypes.PHONE ||
      contact.contactType === contactTypes.EMAIL
    )
  }
  return false
}

export const getContactList = ({ contactList, phoneNumbers, emails }) => {
  if (contactList && contactList.length > 0) {
    return contactList
  }
  let contactArray = []
  if (phoneNumbers && phoneNumbers.length > 0) {
    contactArray = phoneNumbers.map(({ number, label }) => ({
      contactType: contactTypes.PHONE,
      label: label || "phone",
      value: number,
    }))
  }
  if (emails && emails.length > 0) {
    contactArray = [
      ...contactArray,
      ...emails.map(({ email, label }) => ({
        contactType: contactTypes.EMAIL,
        label: label || "email",
        value: email,
      })),
    ]
  }

  return contactArray
}

export const getUsernameOrName = (user) => {
  if (user) {
    if (user.username) return user.username
    return user.name ? user.name.split(" ")[0] : null
  }
  return null
}

export const formatOldContactNames = (name) => {
  // added for old contacts
  // which used to be created with names as 'User null' or full email/phone numbers
  // when it wasn't handled in formatInvitees
  if (validateEmail(name)) {
    return getEmailLabel(name)
  }
  // if (validatePhone(name)) { // wait untill we have proper validation for phone numbers
  //     return getPhoneLabel(name)
  // }
  if (name.includes("null")) {
    return name.split("null").join("")
  }
  return name
}

export const isNamesEqual = (contactName1, contactName2) => {
  const name1 = contactName1 || ""
  const name2 = contactName2 || ""

  return name1 === name2
}

export const isEmailListsEqual = (emailList1, emailList2) => {
  if (emailList1.length !== emailList2.length) {
    return false
  }
  let equal = true
  for (let i = 0, len = emailList1.length; i < len; i++) {
    if (
      !emailList1[i].email ||
      !emailList2[i].email ||
      emailList1[i].email.toLowerCase() !== emailList2[i].email.toLowerCase()
    ) {
      equal = false
      break
    }
  }
  return equal
}

export const isPhoneNumberListsEqual = (phoneNumberList1, phoneNumberList2) => {
  if (phoneNumberList1.length !== phoneNumberList2.length) {
    return false
  }

  // Not comparing actualy phone numbers due to the fact that country code might be the difference.
  // TODO: solve country code issue and compare properly/fully
  return true

  // let equal = true
  // for (let i = 0, len = phoneNumberList1.length; i < len; i++) {
  //     const number1 = phoneNumberList1[i].number
  //     const number2 = phoneNumberList2[i].number

  //     const parsedNumber1 = parsePhoneNumberFromString(number1)
  //     const parsedNumber2 = parsePhoneNumberFromString(number2)

  //     if (parsedNumber1 && parsedNumber2) {
  //         if (
  //             formatNumber(number1, 'International') !==
  //             formatNumber(number2, 'International')
  //         ) {
  //             equal = false
  //             break
  //         }
  //     } else if (!parsedNumber1 && !parsedNumber2) {
  //         if (!(number1 === number2 || number2.includes(number1))) {
  //             equal = false
  //             break
  //         }
  //     } else {
  //         equal = false
  //         break
  //     }
  // }
  // return equal
}

const checkContactsEquality = (phoneBookContact, syncedContact) => {
  let similarityIndex = 0
  const maxIndex = 4

  if (isCooeUser(syncedContact)) {
    // A registered coo-e user, don't compare
    return contactSimilarityType.EQUAL
  }

  if (isNamesEqual(syncedContact.firstName, phoneBookContact.givenName)) {
    similarityIndex++
  }
  if (isNamesEqual(syncedContact.lastName, phoneBookContact.familyName)) {
    similarityIndex++
  }
  if (
    isEmailListsEqual(phoneBookContact.emailAddresses, syncedContact.emails)
  ) {
    similarityIndex++
  }
  if (
    isPhoneNumberListsEqual(
      phoneBookContact.phoneNumbers,
      syncedContact.phoneNumbers
    )
  ) {
    similarityIndex++
  }

  if (similarityIndex === maxIndex) {
    return contactSimilarityType.EQUAL
  }
  if (similarityIndex < maxIndex && similarityIndex > 0) {
    return contactSimilarityType.EDITED
  }
  return contactSimilarityType.DIFFERENT
}

export const getContactsToResync = (currentPhoneBook, savedContacts) => {
  const contactsRecordIds = preparePhoneContactsMapping(savedContacts)
  const syncedContacts = [...savedContacts]
  const newContacts = []
  const editedContacts = []

  currentPhoneBook.forEach((phoneBookContact) => {
    let syncedIndex = -1

    if (`${phoneBookContact.recordId}` in contactsRecordIds) {
      syncedIndex = contactsRecordIds[phoneBookContact.recordId]
    }

    if (syncedIndex > -1) {
      const equality = checkContactsEquality(
        phoneBookContact,
        syncedContacts[syncedIndex]
      )

      if (equality === contactSimilarityType.EQUAL) {
        // don't use this contact in checking anymore
        // remove it so in the end syncedContacts will only include deleted contacts
        syncedContacts[syncedIndex] = {}
      }

      if (equality === contactSimilarityType.EDITED) {
        editedContacts.push({
          ...phoneBookContact,
          givenName: phoneBookContact.givenName || "",
          familyName: phoneBookContact.familyName || "",
          recordId: syncedContacts[syncedIndex].recordId,
        })
        syncedContacts[syncedIndex] = {}
      }
    } else {
      newContacts.push({
        ...phoneBookContact,
        givenName: phoneBookContact.givenName || "",
        familyName: phoneBookContact.familyName || "",
      })
    }
  })

  const deletedContacts =
    syncedContacts.filter(
      (contact) => "id" in contact && "recordId" in contact
    ) || []

  return {
    newContacts,
    editedContacts,
    deletedContacts,
  }
}

export const preparePhoneContactsMapping = (contacts) => {
  let syncedContacts = {}
  contacts.forEach((contact, index) => {
    if ("recordId" in contact) {
      syncedContacts[contact.recordId] = index
    }
  })
  return syncedContacts
}
