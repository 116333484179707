import React, { Component } from "react"
import ReactDOM from "react-dom"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import propTypes from "prop-types"
import get from "lodash/get"
import moment from "moment"

import { images } from "@utils/imageUtils"
import {
  selectUserInfo,
  selectUserInterests,
  selectMechanismsLocations,
  selectContactMechanisms,
  selectCalendarSyncSettings,
} from "@selectors/user"
import { selectAvaliableCalendars } from "@selectors/calendar"
import { selectAllContacts, selectTags } from "@selectors/contacts"
import { selectGroups } from "@selectors/group"
import { selectTeeups, selectArchivedTeeups } from "@selectors/teeups"
import { selectAcceptedConnectionRequests } from "@selectors/requests"
// import { getSpecificCalendarByIdWithCalendars } from '@utils/calendarSyncUtils'
import * as contactActions from "@actions/contactActions"
import interestActions from "@actions/interestActions"
import { getDefaultTimezone } from "@utils/dateUtils"

// import MeView from './meView'
import { selectMatchings } from "@selectors/community"
import { userContactTypes, formatInvitees } from "@utils/contactUtils"
import MoreContainer from "../components/more/moreContainer"
import CalendarSettingsContainer from "../components/more/calendarSettings"
// import closeIcon from '../../assets/images/close.svg';
import Contacts from "../components/me/Contacts"
import {
  fetchTeeups,
  addTeeupInvitees,
  gotTeeupParts,
  fetchTeeupUsersById,
} from "@actions/teeupActions"
import { getContacts } from "@actions/createTeeupActions"
import { fetchRequests } from "@actions/requestActions"
import SideTab from "../components/SideTab"
import ViewByTeeups from "../components/ViewByTeeups"
import SelectTeeupModal from "../components/selectTeeupModal"
import ConfirmUsersModal from "../components/selectTeeupModal/ConfirmUsersModal"
import InvitationSentModal from "../components/selectTeeupModal/InvitationsSentModal"
import TagUsersModal from "../components/TagUsersModal"
import CooEVerse from "../components/CooEVerse"
import PropTypes from "prop-types"
import { withRouter } from "react-router"

class ContactsPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      content: null,
      animation: "",
      activeTab: 0,
      activeTeeup: {},
      onDone: () => {},
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props

    if (location.state && prevState.activeTab !== location.state.activeItem) {
      this.setState({ activeTab: location.state.activeItem })
    }
  }

  componentDidMount() {
    const { fetchTeeups, getContacts, fetchRequests } = this.props
    fetchTeeups()
    getContacts()
    fetchRequests()
  }

  changeActiveTab = (id) => {
    this.setState({ activeTab: id })
  }

  closeWithAnimations = () => {
    document.body.style.overflow = "auto"
    this.setState({ animation: "right-slide" })

    setTimeout(() => {
      this.close()
      this.setState({ animation: "" })
    }, 250)
  }

  // onPressContacts = () => openContacts()

  // onPressCalendar = () => openMyCalendar()

  // onPressTimezone = () => routeCalendarSettings()

  // onPressSettings = () => openSettings()

  // handleProfileOpen = () => openMyProfileOverview()

  onPressContacts = () => {}
  onPressCalendar = () => {}
  onPressTimezone = () => {
    this.setState({
      isOpen: true,
      content: <CalendarSettingsContainer closeOption={this.close} />,
    })
  }
  // onPressSettings = () => {
  //     this.setState({ isOpen: true, content: (
  //         <>
  //             <div className="settings__top">
  //                 <span>Settings</span>
  //                 <img src={closeIcon} alt="close" onClick={this.closeWithAnimations} />
  //             </div>
  //             <MoreContainer close={this.close} />
  //         </>
  //     )})
  // }

  setContent = (content) => {
    this.setState({ isOpen: true, content })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  handleProfileOpen = () => {}

  getMenuOptions = () => {
    const { contactsLastSynced, calendarTitle, timeZone } = this.props

    const menuOptions = [
      // {
      //     id: 'calendar',
      //     icon: images.meCalendar,
      //     title: 'Calendar',
      //     subTitle: calendarTitle || 'Connect your external calendar',
      //     alert: !calendarTitle,
      //     onPress: calendarTitle
      //         ? this.onPressCalendar
      //         : this.onPressTimezone,
      // },
      // {
      //     id: 'contacts',
      //     icon: images.addressBook,
      //     title: 'Contacts',
      //     subTitle: contactsLastSynced
      //         ? `Last Synced ${contactsLastSynced}`
      //         : 'Sync contacts',
      //     alert: !contactsLastSynced,
      //     onPress: contactsLastSynced
      //         ? this.onPressContacts
      //         : this.onPressSettings,
      // },
      {
        id: "timezone",
        icon: images.earth,
        title: "Time Zone",
        subTitle: timeZone,
        onPress: this.onPressTimezone,
      },
      {
        id: "settings",
        icon: images.settings,
        title: "Settings",
        onPress: this.onPressSettings,
      },
    ]

    return menuOptions.map((option) => {
      return {
        ...option,
        // totalNumber: this.getMenuOptionNumber(option.id),
        // hasNewActivity: false,
      }
    })
  }

  getMenuOptionNumber = (optionId) => {
    switch (optionId) {
      case "interests":
        return this.props.amountOfInterests
      case "contacts":
        return this.props.amountOfContacts
      case "groups":
        return this.props.amountOfGroups
      case "locations":
        return this.props.amountOfLocations
      case "cooes":
        return this.props.matchings
          ? this.props.matchings.filter(
              (m) => m.createdById === this.props.userInfo.id
            ).length
          : 0
      case "calendar":
        return 0 //amountOfCalendarEvents
      default:
        return 0
    }
  }

  getPrimaryPhone = () => {
    const {
      contacts: { phone },
    } = this.props

    // const primaryPhoneContact = phone.find(
    //     (item) => item.typeid === userContactTypes.PRIMARY_PHONE
    // )

    const primaryPhoneContact = null
    return primaryPhoneContact ? primaryPhoneContact.value : ""
  }

  modalValues = (invitees, onDone, tagUsers) => {
    if (tagUsers)
      this.setState({
        isTagUsersModalOpened: true,
        invitees,
        onDone,
      })

    if (!tagUsers)
      this.setState({
        isModalOpened: true,
        icConfirmUsersModalOpened: false,
        invitationsSent: false,
        invitees,
        onDone,
      })
  }

  closeModal = () => {
    this.setState({ isModalOpened: false })
  }

  openModal = () => {
    this.setState({ isModalOpened: true })
  }

  openConfirmUsersModal = () => {
    this.setState({
      icConfirmUsersModalOpened: true,
      isModalOpened: false,
    })
  }

  closeConfirmUsersModal = () => {
    this.setState({ icConfirmUsersModalOpened: false })
  }

  openSentInvitationsModal = () => {
    this.setState({ invitationsSent: true })
  }

  closeSentInvitationsModal = () => {
    this.setState({ invitationsSent: false })
  }

  openTagUsersModal = () => {
    this.setState({ isTagUsersModalOpened: true })
  }

  closeTagUsersModal = () => {
    this.setState({ isTagUsersModalOpened: false })
  }

  setActiveTeeup = (teeup) => {
    this.setState({ activeTeeup: teeup })
  }

  render() {
    const { userInfo, tags, allContacts, contacts, getContacts } = this.props
    const {
      isOpen,
      content,
      animation,
      activeTab,
      isModalOpened,
      icConfirmUsersModalOpened,
      invitationsSent,
      isTagUsersModalOpened,
    } = this.state
    const wrapperStyles = {
      width: 405,
      left: "calc(100vw - 405px)",
      zIndex: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      top: 83,
      overflow: "hidden",
    }

    return (
      <>
        <div className="page">
          {isOpen && (
            <div
              className="settings"
              style={wrapperStyles}
              onClick={this.closeWithAnimations}
            >
              <div
                style={{ animationName: animation, padding: 0 }}
                className="people__overlay"
                onClick={(event) => event.stopPropagation()}
              >
                {content}
              </div>
            </div>
          )}
          {isModalOpened &&
            ReactDOM.createPortal(
              <SelectTeeupModal
                closeModal={this.closeModal}
                setActiveTeeup={this.setActiveTeeup}
                openConfirmUsersModal={this.openConfirmUsersModal}
                closeConfirmUsersModal={this.closeConfirmUsersModal}
              />,
              document.getElementById("modal-root")
            )}

          {icConfirmUsersModalOpened &&
            ReactDOM.createPortal(
              <ConfirmUsersModal
                closeModal={this.closeModal}
                setActiveTeeup={this.setActiveTeeup}
                openConfirmUsersModal={this.openConfirmUsersModal}
                closeConfirmUsersModal={this.closeConfirmUsersModal}
                openSentInvitationsModal={this.openSentInvitationsModal}
                invitees={this.state.invitees}
                openModal={this.openModal}
                activeTeeup={this.state.activeTeeup}
                onDone={this.state.onDone}
              />,
              document.getElementById("modal-root")
            )}

          {invitationsSent &&
            ReactDOM.createPortal(
              <InvitationSentModal
                closeSentInvitationsModal={this.closeSentInvitationsModal}
              />,
              document.getElementById("modal-root")
            )}

          {isTagUsersModalOpened &&
            ReactDOM.createPortal(
              <TagUsersModal
                invitees={this.state.invitees}
                closeTagUsersModal={this.closeTagUsersModal}
                allContacts={allContacts}
                tags={tags}
                getContacts={getContacts}
              />,
              document.getElementById("modal-root")
            )}
        </div>
        <div className="page__wrapper">
          {/* <div className="sidetabs">
            <h1 className="page__title">My People</h1>
            <SideTab
              isActive={activeTab === 0}
              title={"Contact Book"}
              icon={images.contacts}
              onChange={() => this.changeActiveTab(0)}
            />
            <SideTab
              isActive={activeTab === 1}
              title={"Coo-e-verse Connections"}
              icon={images.verse}
              onChange={() => this.changeActiveTab(1)}
            />
            <SideTab
              isActive={activeTab === 2}
              title={"View by TeeUps"}
              icon={images.teeup}
              onChange={() => this.changeActiveTab(2)}
            />
          </div> */}
          {activeTab === 0 && (
            <Contacts
              handleOnInviteesDone={this.modalValues}
              setContent={this.setContent}
              close={this.close}
              fromContact={true}
            />
          )}
          {activeTab === 1 && (
            <CooEVerse
              handleOnInviteesDone={this.modalValues}
              setContent={this.setContent}
              close={this.close}
              fromContact={true}
              cooEVerse
            />
          )}
          {activeTab === 2 && (
            <ViewByTeeups
              handleOnInviteesDone={this.modalValues}
              setContent={this.setContent}
            />
          )}
        </div>
      </>
    )
  }
}

ContactsPage.propTypes = {
  // componentId: propTypes.string.isRequired,
  userInfo: propTypes.object,
  amountOfInterests: propTypes.number,
  amountOfGroups: propTypes.number,
  amountOfContacts: propTypes.number,
  amountOfTeeups: propTypes.number,
  amountOfConnections: propTypes.number,
  amountOfLocations: propTypes.number,
  matchings: propTypes.arrayOf(propTypes.shape({})),
  getInterests: propTypes.func,
  contacts: propTypes.object,
  contactsLastSynced: propTypes.string,
  calendarTitle: propTypes.string,
  timeZone: propTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  const userInfo = selectUserInfo(state)
  const contactsSyncedAt = userInfo.contactsSyncedAt
  const amountOfInterests = selectUserInterests(state).length
  const amountOfGroups = selectGroups(state).length
  const amountOfContacts = selectAllContacts(state).length
  const teeups = selectTeeups(state)
  const tags = selectTags(state)
  const archivedTeeups = selectArchivedTeeups(state)
  const amountOfTeeups = teeups.length + archivedTeeups.length
  const amountOfConnections = selectAcceptedConnectionRequests(state).length
  const amountOfLocations = selectMechanismsLocations(state).length
  const matchings = selectMatchings(state)
  const contacts = selectContactMechanisms(state)
  const calendarSyncSettings = selectCalendarSyncSettings(state)
  const calendars = selectAvaliableCalendars(state)
  const calendar = get(calendarSyncSettings, "calendar")
  const syncTeeups = get(calendarSyncSettings, "syncTeeups")
  const timeZone =
    get(calendarSyncSettings, "customTimezone") || `${getDefaultTimezone()}`
  let calendarTitle = null
  const allContacts = selectAllContacts(state)
  // if (calendar && syncTeeups) {
  //     let cal = getSpecificCalendarByIdWithCalendars(calendar, calendars)
  //     if (cal) {
  //         calendarTitle = cal.title
  //     }
  // }

  return {
    userInfo,
    contactsLastSynced: contactsSyncedAt
      ? moment(contactsSyncedAt).format("MMM D")
      : null,
    calendarTitle,
    amountOfInterests,
    amountOfGroups,
    amountOfContacts,
    amountOfTeeups,
    amountOfConnections,
    amountOfLocations,
    matchings,
    contacts,
    timeZone,
    allContacts,
    tags,
  }
}

export default connect(mapStateToProps, {
  fetchTeeups,
  getContacts,
  fetchRequests,
})(withRouter(ContactsPage))
