import React from "react"

import { useTeeUpPage } from "../../TeeUpPageContext"
import GamePlanWhen from "./GamePlanWhen"
import GamePlanWhere from "./GamePlanWhere"

import "./teeupSuggestionsInteractionArea.scss"

const TeeupSuggestionsInteractionArea = () => {
  const { interactionArea } = useTeeUpPage()
  return (
    <div className="suggestions-interaction-area">
      {interactionArea === "when" && <GamePlanWhen />}
      {interactionArea === "where" && <GamePlanWhere />}
    </div>
  )
}

export default TeeupSuggestionsInteractionArea
