import React from "react"
import Modal from "react-modal"

import closeIcon from "../../assets/images/closeDialog.png"
import logo from "../../assets/images/teeUpsPink.svg"

const modalStyles = {
  content: {
    backgroundColor: "#ffffff",
    width: 676,
    height: 590,
    borderRadius: "10px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.25)",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 40,
  },
  overlay: {
    backgroundColor: "#00000080",
  },
}

const faqs = [
  {
    title: "Do you have another Coo-e account?",
    desc: "We recommend signing into the other account and removing the contact method from it if you wish to add it to this account.",
  },
  {
    title: "Does this contact method belong to someone else?",
    desc: "If you do not own this contact method, then you will not be able to add it to your account as contact methods must be verified by their owners.",
  },
  {
    title: "Suspicious activity?",
    desc: "If you own this contact method and suspect it has been compromised or claimed by another fraudulently, please contact support for additional assistance.",
  },
]

const LearnMoreModal = ({ isOpen, closeModal }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={modalStyles}
    ariaHideApp={false}
  >
    <img
      className="learn_more_modal__close_icon"
      onClick={closeModal}
      src={closeIcon}
      alt=""
    />
    <img className="learn_more_modal__logo" src={logo} alt="" />
    <span className="learn_more_modal__title">
      Contact Method Is Linked to Another Account
    </span>
    <p className="learn_more_modal__subtitle"> Frequently Asked Questions</p>
    <hr
      style={{
        color: "#dbdbdb",
        backgroundColor: "#dbdbdb",
        borderColor: "#dbdbdb",
        width: "100%",
        opacity: 0.5,
      }}
    />
    {faqs.map((question, index) => (
      <div style={{ width: "100%", margin: 10 }} key={index}>
        <span className="learn_more_modal__faq_title">{question.title}</span>
        <p className="learn_more_modal__faq_desc">{question.desc}</p>
      </div>
    ))}
    <p className="learn_more_modal__additional_support">
      Get Additional Support
    </p>
  </Modal>
)

export default LearnMoreModal
