import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import "./index.scss"
import ReactDOM from "react-dom"

import _ from "lodash"
import { selectPeopleSideBar, selectSideBarContent } from "@selectors/common"
import { selectTeeupsPeople } from "@selectors/teeups"
import { teeupUserStatusPriorities, initialTeeup } from "@config/enums"
import { userStatusTypes } from "@config/mappings"
import { readTeeup, setPeopleSideBar } from "@actions/commonActions"
import { loadNotifications } from "@actions/notificationActions"
import OverviewHeader from "./OverviewHeader"
import OverviewConversation from "./OverviewConversation"
import TeeUpsSettings from "../TeeUpsSettings"
import RightSide from "./RightSide"
import UserStatusModal from "@components/teeupUserResponseDialogs/userStatusModal"
import { updateUserStatus } from "@actions/requestActions"
import {
  setTeeupActive,
  updateActiveTeeup,
  toggleParticipantDetails,
} from "@actions/activeTeeupActions"
import { selectDetailsOpen, selectDetailsUser } from "@selectors/activeTeeup"
import {
  fetchTeeupUsersById,
  fetchGameplanOptions,
  updateTeeupArchiveStatus,
  gotTeeupParts,
  updateGameplan,
  archiveTeeup,
} from "@actions/teeupActions"
import closeIcon from "../../assets/images/close.svg"
import ParticipantDetailsContainer from "./RightSide/participantsCard/participantDetails/ParticipantDetailsContainer"
import { images } from "@utils/imageUtils"

import cn from "classnames"
import SuggestionDetails from "./SuggestionDetails"

const TeeUpsOverview = ({
  currentTeeUp,
  peopleSideBar,
  teeupPeople,
  updateUserStatus,
  updateActiveTeeup,
  gotTeeupParts,
  updateGameplan,
  archiveTeeup,
  setPeopleSideBar,
  sideBarContent,
  isDetailsOpen,
  detailsUserId,
  toggleParticipantDetails,
  handleOpenSuggestion,
  content,
  setContent,
  suggestionListContent,
  setSuggestionListContent,
  isSuggestionDetailsOpened,
  currentWindowParams,
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [showMyStatusOverlay, setShowMyStatusOverlay] = useState(false)
  const [animation, setAnimation] = useState("left-slide-modal")
  const [teeUp, setTeeUp] = useState(currentTeeUp)

  useEffect(() => {
    readTeeup(teeUp.id)
    setTimeout(loadNotifications, 1000)
    setTeeUp(currentTeeUp)
  }, [currentTeeUp])

  const closeDetails = (e) => {
    e.stopPropagation()
    setAnimation("right-slide-modal")
    setPeopleSideBar(false)

    setTimeout(() => {
      toggleParticipantDetails(false)
      setAnimation("")
    }, 400)
  }

  const handleRespondToInvitation = (teeup) => (response) => {
    const { id: teeupId } = teeup
    const statusId = teeupUserStatusPriorities.joined

    if (response) {
      updateUserStatus(teeupId, statusId)
        .then(async (error) => {
          setTeeupActive(teeup)
          updateActiveTeeup({
            id: teeupId,
            userStatus: userStatusTypes[statusId],
          })
          const teeupUsers = await fetchTeeupUsersById(teeupId)
          gotTeeupParts([teeupUsers])
          updateGameplan(teeup.id, await fetchGameplanOptions(teeupId))

          if (error) {
            console.log("handleRespondToInvitation Error: ", {
              error,
            })
          }
        })
        .catch((error) => {
          console.log("handleRespondToInvitation catch ERROR: ", {
            error,
          })
        })
    } else {
      setTeeupActive(initialTeeup)
      updateTeeupArchiveStatus(teeupId)
      archiveTeeup(teeupId)
    }
  }

  if (isSettingsOpen) {
    document.body.style.overflow = "hidden"
  }
  const handleMyStatusPress = () => {
    setShowMyStatusOverlay(true)
  }

  const renderStautsOverlay = (dropdownRef) => {
    let position = { x: 0, y: 0 }

    if (dropdownRef.current) {
      position = dropdownRef.current.getBoundingClientRect()
    }

    return showMyStatusOverlay ? (
      <UserStatusModal
        modalPosition={position}
        teeup={teeUp}
        onClose={() => setShowMyStatusOverlay(false)}
      />
    ) : null
  }

  return (
    <>
      <div
        className={cn("overview", {
          overview__expanded: peopleSideBar,
        })}
        style={{
          marginLeft:
            isSuggestionDetailsOpened &&
            currentWindowParams.width <= 1630 &&
            "-250px",
        }}
      >
        {isSettingsOpen &&
          ReactDOM.createPortal(
            <TeeUpsSettings closeSettings={() => setIsSettingsOpen(false)} />,
            document.getElementById("modal-root")
          )}
        <div className="overview__content">
          <div className="overview__content_leftSide">
            <OverviewHeader
              teeUp={teeUp}
              handleMyStatusPress={handleMyStatusPress}
              renderStautsOverlay={renderStautsOverlay}
              people={_.get(teeupPeople, teeUp.id, {})}
              onDecline={() => handleRespondToInvitation(teeUp)(false)}
              onAccept={() => handleRespondToInvitation(teeUp)(true)}
              openSettings={() => setIsSettingsOpen(true)}
            />
            <RightSide
              setPeopleSideBar={setPeopleSideBar}
              sideBarContent={sideBarContent}
              peopleSideBar={peopleSideBar}
              teeup={teeUp}
              handleOpenSuggestion={handleOpenSuggestion}
              content={content}
              setContent={setContent}
              suggestionListContent={suggestionListContent}
              setSuggestionListContent={setSuggestionListContent}
            />
          </div>
          <OverviewConversation handleOpenSuggestion={handleOpenSuggestion} />
        </div>
      </div>
      {isDetailsOpen && (
        <div
          className="settings"
          style={{
            backgroundColor: "none",
            width: 375,
            height: "auto",
            position: "unset",
            top: 80,
            background: "none",
          }}
          onClick={closeDetails}
        >
          <div
            style={{
              animationName: animation || "left-slide-modal",
              padding: 0,
              width: 375,
            }}
            className="people__overlay"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="settings__top">
              <span>Participant Details</span>
              <img
                style={{ top: "initial" }}
                src={images.closeSettings}
                alt="close"
                onClick={closeDetails}
              />
            </div>
            <ParticipantDetailsContainer userId={detailsUserId} />
          </div>
        </div>
      )}
      {isSuggestionDetailsOpened && <SuggestionDetails />}
    </>
  )
}

const mapStateToProps = (state) => ({
  peopleSideBar: selectPeopleSideBar(state),
  teeupPeople: selectTeeupsPeople(state),
  sideBarContent: selectSideBarContent(state),
  isDetailsOpen: selectDetailsOpen(state),
  detailsUserId: selectDetailsUser(state),
  isSuggestionDetailsOpened: state.common.isSuggestionDetailsOpened,
  currentWindowParams: state.common.currentWindowParams,
  // teeUp: selectActiveTeeup(state),
})

const mapMethodsToProps = {
  updateUserStatus,
  updateActiveTeeup,
  gotTeeupParts,
  updateGameplan,
  archiveTeeup,
  setPeopleSideBar,
  toggleParticipantDetails,
  setTeeupActive,
}

export default connect(mapStateToProps, mapMethodsToProps)(TeeUpsOverview)
