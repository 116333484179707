import React from "react"
import { styles } from "./calendarSettingsStyles"
import { TouchableOpacity } from "react-native"

const NotImplementedYet = ({ closeSelect }) => {
  const wrapperStyles = {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  }

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "400px",
    height: "150px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
  }

  const textStyles = {
    paddingTop: "15px",
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: "17px",
  }

  return (
    <div style={wrapperStyles}>
      <div style={containerStyles}>
        <p style={textStyles}>Not Implemented Yet</p>

        <TouchableOpacity style={styles.timezoneConfirm} onPress={closeSelect}>
          Cancel
        </TouchableOpacity>
      </div>
    </div>
  )
}

export default NotImplementedYet
