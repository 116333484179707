import React from "react"
import PropTypes from "prop-types"
import { momentUniversal } from "@utils/dateUtils"
import { View, Text } from "react-native"
import styles from "./separatorMessageStyles"

export const SeparatorMessage = ({ timestamp }) => {
  if (!timestamp) return null

  return (
    <View style={styles.dateMessageContainer}>
      <View style={styles.dateMessageLine} />
      <Text style={styles.dateMessageDate}>
        New From {momentUniversal(timestamp).format("MMM D, h:mm A")}
      </Text>
      <View style={styles.dateMessageLine} />
    </View>
  )
}

SeparatorMessage.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
