import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"

export const styles = StyleSheet.create({
  container: {},
  splitter: {
    height: Units.responsiveValue(16),
  },
  // header: {
  //     backgroundColor: AppColors.brand.lightGrey,
  //     color: AppColors.brand.darkGrey2,
  //     paddingTop: Units.responsiveValue(22),
  //     paddingBottom: Units.responsiveValue(8),
  //     ...AppFonts.mediumDemibold,
  //     paddingHorizontal: Units.responsiveValue(16),
  // },
  header: {
    backgroundColor: AppColors.brand.lightGrey,
    color: AppColors.brand.darkGrey2,
    paddingTop: Units.responsiveValue(22),
    paddingBottom: Units.responsiveValue(8),
    ...AppFonts.biggerMedium,
    paddingHorizontal: Units.responsiveValue(16),
    paddingLeft: 0,
  },
  ask: {
    fontFamily: "greycliff-cf-medium",
    fontSize: "13px",
    letterSpacing: "-0.17px",
    color: "#9d9d9d",
    marginTop: "10px",
    marginBottom: "10px",
  },
  headerBold: {
    ...AppFonts.biggerBold,
    color: AppColors.brand.black2,
  },
  sectionHeaderContainer: {
    backgroundColor: AppColors.brand.grey243,
    paddingTop: Units.responsiveValue(13),
    paddingBottom: Units.responsiveValue(10),
    paddingHorizontal: Units.responsiveValue(16),
  },
  sectionHeaderText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.darkGrey2,
  },
  contentContainer: {
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: Units.responsiveValue(17),
    paddingBottom: Units.responsiveValue(19),
    paddingHorizontal: Units.responsiveValue(16),
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderTopColor: AppColors.brand.lightGrey,
  },
  timezoneContainer: {
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: Units.responsiveValue(14),
    paddingBottom: Units.responsiveValue(14),
    paddingHorizontal: Units.responsiveValue(16),
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderTopColor: AppColors.brand.lightGrey,
  },
  timezoneIconContainer: {
    width: Units.responsiveValue(22),
    height: Units.responsiveValue(22),
    marginRight: Units.responsiveValue(3.5),
  },
  timezoneIconContainerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  messageContainer: {
    padding: Units.responsiveValue(16),
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
  },
  text: {
    // flex: 1,
    // ...AppFonts.biggerDemibold,
    // color: AppColors.brand.black2,
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: "17px",
    color: "#222222",
    letterSpacing: "-0.34px",
  },
  buttonImage: {
    width: Units.responsiveValue(7),
    height: Units.responsiveValue(12),
    tintColor: AppColors.brand.warmGrey2,
  },
  cancelBtnImage: {
    tintColor: "black",
    height: Units.responsiveValue(20),
    width: Units.responsiveValue(20),
    marginRight: 10,
  },
  checkImage: {
    height: "17px",
    width: "17px",
    tintColor: "#f42862",
  },
  value: {
    color: AppColors.brand.warmGrey2,
    ...AppFonts.biggerDemibold,
    marginRight: Units.responsiveValue(8),
  },
  timezonesSelectContainer: {
    backgroundColor: AppColors.brand.white,
    flex: 1,
    paddingTop: Units.responsiveValue(14),
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  timezoneConfirm: {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: "17px",
    color: "#f42862",
    width: "160px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "38px",
    border: "solid 2px #f42862",
    backgroundColor: "#ffffff",
    marginVertical: "0",
    marginHorizontal: "auto",
    marginBottom: "5px",
    marginTop: "15px",
  },
  searchBarContainer: {
    padding: Units.responsiveValue(12),
    paddingTop: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.grey243,
  },
  searchBarHeader: {
    // ...AppFonts.biggerMedium,
    // color: AppColors.brand.darkGrey2,
    // letterSpacing: -Units.responsiveValue(0.34),
    marginBottom: Units.responsiveValue(8),
    marginLeft: Units.responsiveValue(4),
    fontFamily: "greycliff-cf-medium",
    fontSize: "13px",
    letterSpacing: "-0.26px",
    color: "#767676",
    textTransform: "uppercase",
  },
  selectetTimezoneContainer: {
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: Units.responsiveValue(14),
    paddingBottom: Units.responsiveValue(14),
    paddingHorizontal: Units.responsiveValue(16),
  },
  selectetTimezoneInfo: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    // height: Units.responsiveValue(42),
  },
  selectedTZtext: {
    // color: AppColors.brand.grey157,
  },
  selectedTZsubtext: {
    ...AppFonts.smallishMedium,
  },
  selectedTZicon: {
    tintColor: AppColors.brand.grey157,
  },
})
