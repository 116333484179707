import MyContactItem from "@components/Contacts/MyContactItem"
import React from "react"
import { images } from "../../../utils/imageUtils.js"

import "@utils/customArrayPrototypes"

const ExpandedUserList = ({
  expanded,
  setExpanded,
  people,
  contacts,
  handleToggleContact,
  invitees,
  setInvitees,
  handleOnInviteesDone,
  noHover,
}) => {
  const inviteesId = invitees.map((item) => item.id)
  let peopleId = people.map((item) => item.id)

  let correctInvitees = contacts.filter((contact) =>
    peopleId.includes(contact.id)
  )
  peopleId = peopleId.filter(
    (id) => !correctInvitees.map((invitee) => invitee.id).includes(id)
  )
  correctInvitees = [
    ...correctInvitees,
    ...people.filter((person) => peopleId.includes(person.id)),
  ]

  let correctInviteesId = correctInvitees.map((invitee) => invitee.id)

  const selectAll = () => {
    const uniqueInvitees = [
      ...invitees.filter((invitee) => !peopleId.includes(invitee.id)),
      ...people,
    ].uniqualizeArrayElements()
    setInvitees(uniqueInvitees)
    // setInvitees([...invitees.filter(invitee => !peopleId.includes(invitee.id)), ...people]);
  }
  const unselectAll = () =>
    setInvitees(
      invitees.filter((invitee) => !correctInviteesId.includes(invitee.id))
    )
  // const unselectAll = () => setInvitees(invitees.filter(invitee => !peopleId.includes(invitee.id)));

  return (
    <div className="expanded__container">
      <div className="expanded_top" onClick={() => setExpanded(!expanded)}>
        <h2 className="expanded_top__header">People</h2>
        {people.every((person) => inviteesId.includes(person.id)) ? (
          <button
            onClick={(e) => {
              e.stopPropagation()
              unselectAll()
            }}
          >
            Unselect All
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.stopPropagation()
              selectAll()
            }}
          >
            Select All
          </button>
        )}
        <img
          className="teeupItem_hideUsersArrow"
          src={images.arrowsOptions}
          onClick={() => setExpanded(!expanded)}
        />
      </div>
      <div className="expanded_users__container">
        <div className="user__container">
          {/* {correctInvitees.map(item => contacts.map(contact => item.id===contact.id && ( */}
          {correctInvitees.map((item) => (
            <MyContactItem
              key={item.id}
              setContent={() => {}}
              item={item}
              // item={contact}
              invitees={invitees}
              onRemoveInvitee={handleToggleContact({ data: item })}
              handleOnInviteesDone={handleOnInviteesDone}
              onDone={() => setInvitees([])}
              noHover={noHover}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ExpandedUserList
