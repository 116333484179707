/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"
import PropTypes from "prop-types"
// import PhoneInput from 'react-native-phone-input'
import { Platform, Text, View, StyleSheet, TextInput } from "react-native"

import { getCountryCode } from "../../utils/contactUtils"
import { AppFonts, Units } from "@theme/"

const countriesMap = require("../countrySelector/countries.json")

class PhoneInputField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      countryCode: "1",
      cca2: "us",
      value: "",
    }

    props.refFunc(this)
  }

  populateDeviceCountryCode = async () => {
    // const countryCode = await getCountryCode()
    // const cca2 = Platform.select({
    //     ios: this.props.defaultCountry || countryCode,
    //     android: countryCode,
    // })
    // const countryInfo = countriesMap[cca2.toUpperCase()]
    const cca2 = null
    const countryInfo = null

    if (!countryInfo || !countryInfo.callingCode) {
      return this.selectCountry({ callingCode: "1", cca2: "us" })
    }

    this.selectCountry({ callingCode: countryInfo.callingCode, cca2 })

    if (this.fieldRef && this.props.allowFormatOnInit) {
      this.fieldRef.onChangePhoneNumber(`+${countryInfo.callingCode} `)
    }
  }

  handleChangePhoneNumber = (number) => {
    // TODO: make sure user doesn't delete the country code
    const { countryCode, cca2 } = this.state

    if (number.length < countryCode.length + 1) {
      // Deleted part of country code
      this.fieldRef.setState({ inputValue: "+" + countryCode })
      this.fieldRef.selectCountry(cca2)
      return
    }

    this.props.onChangePhoneNumber(number, cca2)
  }

  handlePhoneInput = (value) => {
    const { countryCode, cca2 } = this.state
    this.setState({ value })
    this.props.onChangePhoneNumber(value, cca2)
  }

  componentDidMount() {
    this.populateDeviceCountryCode()
  }

  selectCountry = (country) => {
    const { disabled } = this.props
    const { cca2, callingCode } = country
    const formattedCca2 = cca2.toLowerCase()
    // this.setState({ countryCode: callingCode, cca2: formattedCca2 }, () => {
    //     if (!disabled) {
    //         this.fieldRef.setState({ inputValue: '+' + callingCode })
    //         this.fieldRef.selectCountry(formattedCca2)
    //     }
    // })
    this.setState({ countryCode: callingCode, cca2: formattedCca2 })
  }

  getCountryCode = () => {
    return this.state.countryCode
    // return this.fieldRef.getCountryCode()
  }

  focus = () => {
    return this.fieldRef.focus()
  }

  getValue = () => {
    return this.fieldRef.value
  }

  render() {
    const {
      label,
      style,
      textStyle,
      customInputStyle,
      customContainerStyle,
      customInputTextStyle,
      customLabelStyle,
      ...otherProps
    } = this.props

    const field = (
      <TextInput
        style={[style, customInputStyle]}
        // textStyle={[textStyle, customInputTextStyle]}
        ref={(ref) => (this.fieldRef = ref)}
        // initialCountry="us"
        value={this.state.value}
        // {...otherProps}
        // onChange={this.handleChangePhoneNumber}
        onChange={({ target }) => this.handlePhoneInput(target.value)}
      />
    )

    return (
      <View style={[styles.container, customContainerStyle]}>
        {label ? (
          <Text style={[styles.label, customLabelStyle]}>{label}</Text>
        ) : null}
        {field}
      </View>
    )
  }
}

const fontStyles =
  Platform.OS === "ios" ? AppFonts.headerDemibold : AppFonts.headerMedium

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Units.rem(0.5),
  },
  label: {
    ...AppFonts.bigDemibold,
    color: "white",
    paddingHorizontal: Units.rem(1.5),
    paddingVertical: Units.rem(0.5),
  },
  input: {
    backgroundColor: "white",
    borderRadius: Units.rem(1.75),
    paddingHorizontal: Units.rem(1.5),
    paddingTop: Units.rem(0.9375),
    paddingBottom: Units.rem(1),

    shadowColor: "black",
    shadowOffset: { height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 8,
  },
  inputText: {
    ...fontStyles,
    lineHeight: Units.responsiveValue(25),
    height: Units.responsiveValue(25),
  },
})

PhoneInputField.propTypes = {
  label: PropTypes.string,
  refFunc: PropTypes.func.isRequired,
  onChangePhoneNumber: PropTypes.func.isRequired,
  defaultCountry: PropTypes.string,
  style: PropTypes.object,
  textStyle: PropTypes.object,
  customContainerStyle: PropTypes.object,
  customInputStyle: PropTypes.object,
  customInputTextStyle: PropTypes.object,
  customLabelStyle: PropTypes.object,
  allowFormatOnInit: PropTypes.bool,
  disabled: PropTypes.bool,
}

PhoneInputField.defaultProps = {
  style: styles.input,
  textStyle: styles.inputText,
  customContainerStyle: {},
  customInputStyle: {},
  customInputTextStyle: {},
  customLabelStyle: {},
  allowFormatOnInit: true,
  disabled: false,
}

export default PhoneInputField
