import React, { PureComponent, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import cn from 'classnames'

import { images } from "@utils/imageUtils"
import { getContactInitials, getContactName } from "@utils/dataUtils"
import {
  isCooeUser as checkIsCooeUser,
} from "@utils/contactUtils"

import Tag from "./Tag"
import { selectConnectionRequests } from "@selectors/requests"


import { selectUserId } from "../../../selectors/user"

import "./index.scss"

const MyContactItemNd = ({
  item,
  selectedTags,
  isInvitees,
  onRemoveInvitee,
  invitees,
  fromContactsBook,
  openInviteToTeeupModal
}) => {

  //hover or selected effects
  const [isHover, setIsHover] = useState(false)
  const [buttonIndex, setButtonIndex] = useState(0)
  //---

  const renderTag = (tag, index, data) => (
    <Tag
      key={tag.id}
      data={tag}
      isSelected={false}
      isLast={index === data.length - 1}
    />
  )

  const { avatar, firstName, lastName, isConnected, tags } = item
  const initial = getContactInitials(item)
  const name = getContactName(item)
  const maxLength = 20
  let wordLength = 0
  const hiddenTags =
    tags && tags.length
      ? tags.filter((tag) => {
          wordLength += tag.value.length
          if (wordLength >= maxLength) {
            return true
          }
          return false
        })
      : []
  const tagsToShow = (tag, index) => index < tags.length - hiddenTags.length

  const isCooeUser = checkIsCooeUser(item)

  const showMessageButton = isConnected

  const invited = Boolean(
    invitees.filter(
      (invitee) =>
        (invitee.userType === item.userType ||
          invitee.userType === item.usertype) &&
        invitee.id === item.id
    ).length
  )

  return (
    <div
      className={cn("contact-itemNd", {
        "from-invite": !fromContactsBook,
      })}
      onClick={() => !fromContactsBook && onRemoveInvitee()}
      onMouseOver={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
    >
      {avatar ? (
          <img src={avatar} className="contact-itemNd__avatar" />
        ) : (
        <div className="contact-itemNd__avatar--empty">
          {initial}
        </div>
      )}
      <div className="contact-itemNd__content">
        <div className="contact-itemNd__name--wrapper">
          <h5
            className="contact-itemNd__name"
          >
            {name}
          </h5>
          {isCooeUser && (
            <img
              src={images.ndIsCooWeUser}
              style={{
                width: "13px",
                marginLeft: "4px"
              }}
            />
          )}
        </div>
          <div className="contact-itemNd__tags">
            {selectedTags && selectedTags.length ? (
              <div className="mycontact-tag__wrapper">
                {selectedTags.map(renderTag)}
              </div>
            ) : (
              <div className="mycontact-tag__wrapper">
                {tags && tags.filter(tagsToShow).map(renderTag)}
                {hiddenTags.length ? (
                  <p className="mycontact-tag">{`, +${hiddenTags.length}`}</p>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div
          className="contact-itemNd__checkbox"
          onClick={() => !fromContactsBook && onRemoveInvitee()}
        >
          {/* {!fromContactsBook && isHover && !invited && <img src={images.ndCheckbox} className="contact-itemNd__chose" />}
          {!fromContactsBook && invited && <img src={images.ndCheckboxActive} className="contact-itemNd__chose" />} */}

          {fromContactsBook && isHover && (
            <div className="contact-itemNd__buttons">
              <div
                className="contact-itemNd__button"
                onClick={() => {
                  openInviteToTeeupModal()
                  onRemoveInvitee()
                }}
                onMouseOver={() => setButtonIndex(1)}
                onMouseLeave={() => setButtonIndex(0)}
              >
                <img
                  src={buttonIndex === 1 ? images.ndInviteBlankActive : images.ndInviteBlank}
                  style={{ marginRight: 4 }}
                />
                Invite
              </div>
              <div
                className="contact-itemNd__button"
                onMouseOver={() => setButtonIndex(2)}
                onMouseLeave={() => setButtonIndex(0)}
              >
                <img
                  src={buttonIndex === 2 ? images.ndInviteTagActive : images.ndInviteTag}
                  style={{ marginRight: 4 }}
                />
                Tag
              </div>
              <div
                className="contact-itemNd__button"
                onClick={onRemoveInvitee}
                onMouseOver={() => setButtonIndex(3)}
                onMouseLeave={() => setButtonIndex(0)}
              >
                <img
                  src={buttonIndex === 3
                    ? invited ? images.ndInviteSelectedActive : images.ndInviteSelectActive
                    : invited ? images.ndInviteSelected : images.ndInviteSelect
                  }
                  style={{ marginRight: 4 }}
                />
                {invited ? "Unselect" : "Select"}
              </div>
            </div>
          )}
          {fromContactsBook && invited && !isHover && (
            <img
              className="contact-itemNd__selected"
              src={images.ndInviteSelected}
            />
          )}
        </div>
    </div>
  )

}

const mapStateToProps = (state) => {
  const requests = selectConnectionRequests(state)
  const userId = selectUserId(state)

  return {
    userId,
    requests,
  }
}

export default connect(mapStateToProps)(MyContactItemNd)
