import React, { createContext, useContext, useReducer, useEffect } from "react"
import { useParams } from "react-router-dom"

import { TeeUpPageReducer } from "./reducer"
import { getTeeupById } from "../../../actions/activeTeeupActions"

const TeeUpPageStateContext = createContext({})
const TeeUpPageDispatchContext = createContext(undefined)

const TeeUpPageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TeeUpPageReducer, {
    interactionArea: null, // area that opens for example when suggestions are clicked. it pushes the conversation to the right, and right side area gets closed.
    openPeopleArea: false,
    isSuggestWhenModalOpen: false,
    isSuggestWhereModalOpen: false,
  })
  const param = useParams()

  // fetch teeup by id on teeup overview load
  useEffect(() => {
    if (param.id) {
      getTeeupById(param.id)
    }
  }, [param])

  return (
    <TeeUpPageStateContext.Provider value={state}>
      <TeeUpPageDispatchContext.Provider value={dispatch}>
        {children}
      </TeeUpPageDispatchContext.Provider>
    </TeeUpPageStateContext.Provider>
  )
}

const useTeeUpPageState = () => {
  const context = useContext(TeeUpPageStateContext)

  if (context === undefined) {
    throw new Error("useTeeUpPageState must be used within a TeeUpPageProvider")
  }
  return context
}

const useTeeUpPageDispatch = () => {
  const context = useContext(TeeUpPageDispatchContext)

  if (context === undefined) {
    throw new Error(
      "useTeeUpPageDispatch must be used within a TeeUpPageProvider"
    )
  }
  return context
}

export { TeeUpPageProvider, useTeeUpPageState, useTeeUpPageDispatch }
