import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: AppColors.brand.white,
    paddingLeft: Units.rem(1),
    paddingRight: Units.rem(1.2),
    paddingVertical: Units.rem(0.9),
  },
  participantInfo: {
    flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    height: Units.rem(2.5),
    width: Units.rem(2.5),
    borderRadius: Units.rem(2.5) / 2,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    marginRight: Units.rem(1),
  },
  participantIcon: {
    backgroundColor: AppColors.brand.grey2,
  },
  participantInitial: {
    ...AppFonts.medium,
    color: AppColors.brand.darkGrey2,
  },
  titleText: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.blackTwo,
  },
  checkboxContainer: {
    justifyContent: "center",
  },
  userContent: {
    flex: 1,
    marginRight: Units.responsiveValue(16),
  },
  username: {
    ...AppFonts.medium,
    color: AppColors.brand.darkGrey2,
    height: Units.responsiveValue(20),
    alignItems: "center",
    flexDirection: "row",
  },
})

export default styles
