import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export const styles = StyleSheet.create({
  usersWithoutTimezones: { flexDirection: "row-reverse" },
  marginLeft10: { marginLeft: Units.responsiveValue(10) },
  row: { flexDirection: "row" },
  withoutTimezoneTitle: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.warmGrey,
  },
})

export const unknownTimezone = ({ withDarkTheme }) => ({
  ...AppFonts.mediumBold,
  color: withDarkTheme ? AppColors.brand.white : AppColors.brand.black,
})

export const unknownTimezoneAvatar = ({ isFirstUser }) => ({
  marginLeft: isFirstUser ? 0 : -Units.rem(1),
})

export const withoutTimezoneWrapper = ({ withDarkTheme }) => ({
  flexDirection: "row",
  paddingVertical: Units.responsiveValue(10),
  paddingLeft: Units.responsiveValue(10),
  marginBottom: Units.responsiveValue(10),
  backgroundColor: withDarkTheme ? AppColors.transparent : AppColors.brand.grey,
})
