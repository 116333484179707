import { StyleSheet } from "react-native"

import { AppColors, Units } from "@theme/"

export const ModalStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  overlayContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: AppColors.transparentOverlay,
  },
  dialogWrapper: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: Units.responsiveValue(64),
  },
  dialogContainer: {
    width: Units.responsiveValue(320),
    borderRadius: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.grey238,
    overflow: "hidden",
    shadowColor: "black",
    shadowOpacity: 0.4,
    shadowRadius: 20,
    elevation: 10,
  },
})
