import { createSelector } from "reselect"

import { invitationTypes, isConnected } from "@utils/requestsUtils"
import { requestStatusId } from "@utils/contactUtils"

export const selectRequests = (state) => state.requests || {}

export const selectRequestsList = createSelector(
  selectRequests,
  (requests) => requests.requests || {}
)

export const selectRequestsArray = createSelector(
  selectRequestsList,
  (requests) => Object.values(requests) || []
)

export const selectConnectionRequests = createSelector(
  selectRequestsArray,
  (requests) =>
    requests.filter(
      (request) => request.inviteType === invitationTypes.connection
    ) || []
)

export const selectTeeupInvitationsRequests = createSelector(
  selectRequestsArray,
  (requests) =>
    requests.filter(
      (request) =>
        request.inviteType === invitationTypes.teeup &&
        !request.fromMe &&
        request.statusId === requestStatusId.pending
    ) || []
)

export const selectAcceptedConnectionRequests = createSelector(
  selectConnectionRequests,
  (requests) => requests.filter((request) => isConnected(request)) || []
)

export const selectNewRequestsCount = createSelector(
  selectRequestsArray,
  (requests) =>
    requests.filter(
      (request) => request.isNew && !request.fromMe && request.statusId === 1
    ).length || 0
)

export const selectRequestMessages = (id) =>
  createSelector(selectRequestsList, (requests) => requests[id].messages)

export const selectRequest = (id) =>
  createSelector(selectRequestsList, (requests) => requests[id])

export const selectRequestsBetweenUsersList = (userId) =>
  createSelector(selectConnectionRequests, (requestsList) => {
    return (
      requestsList.filter(
        (request) => request.sentTo === userId || request.createdBy === userId
      ) || []
    )
  })
