import { images } from "@utils/imageUtils"

export const apps = [
  {
    id: 0,
    title: "Discord",
    image: images.ndSuggestDiscord,
    imageInactive: images.ndSuggestDiscordInactive,
    icon: images.ndSuggestDiscordIcon,
    name: "discord"
  },
  {
    id: 1,
    title: "Messenger",
    image: images.ndSuggestFacebook,
    imageInactive: images.ndSuggestFacebookInactive,
    icon: images.ndSuggestFacebookIcon,
    name: "messenger"
  },
  {
    id: 2,
    title: "Meet",
    image: images.ndSuggestGoogleMeet,
    imageInactive: images.ndSuggestGoogleMeetInactive,
    icon: images.ndSuggestGoogleMeetIcon,
    name: "meet.google"
  },
  {
    id: 3,
    title: "GoToMee...",
    image: images.ndSuggestGoToMeeting,
    imageInactive: images.ndSuggestGoToMeetingInactive,
    icon: images.ndSuggestGoToMeetingIcon,
    name: "meet.goto"
  },
  {
    id: 4,
    title: "Teams",
    image: images.ndSuggestTeams,
    imageInactive: images.ndSuggestTeamsInactive,
    icon: images.ndSuggestTeamsIcon,
    name: "teams"
  },
  {
    id: 5,
    title: "Phone",
    image: images.ndSuggestPhone,
    imageInactive: images.ndSuggestPhoneInactive,
    icon: images.ndSuggestPhoneIcon,
    name: null
  },
  {
    id: 6,
    title: "Slack",
    image: images.ndSuggestSlack,
    imageInactive: images.ndSuggestSlackInactive,
    icon: images.ndSuggestSlackIcon,
    name: "slack"
  },
  {
    id: 7,
    title: "WebEx",
    image: images.ndSuggestWebEx,
    imageInactive: images.ndSuggestWebExInactive,
    icon: images.ndSuggestWebExIcon,
    name: "webex"
  },
  {
    id: 8,
    title: "WhatsApp",
    image: images.ndSuggestWhatsApp,
    imageInactive: images.ndSuggestWhatsAppInactive,
    icon: images.ndSuggestWhatsAppIcon,
    name: null
  },
  {
    id: 9,
    title: "Zoom",
    image: images.ndSuggestZoom,
    imageInactive: images.ndSuggestZoomInactive,
    icon: images.ndSuggestZoomIcon,
    name: "zoom"
  },
  {
    id: 10,
    title: "Other",
    image: images.ndSuggestOther,
    imageInactive: images.ndSuggestOtherInactive,
    name: null
  },
]
