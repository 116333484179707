import React, { Component } from "react"
import { bindActionCreators } from "redux"

import PropTypes from "prop-types"
import { Animated, Easing } from "react-native"
import { connect } from "react-redux"
import _ from "lodash"
import moment from "moment"
import { setBeforeCalendarDate } from "../../../actions/calendarActions"

import {
  getLocaleTime,
  isDateBeforeNow,
  getDateWithTimezone,
  dateToFormatTimezone,
  timeDifferenceSeconds,
  adjustMinuteRange,
  addMinutes,
  composeDatetime,
  getDuration,
} from "@utils/dateUtils"
import {
  calendarConflictsForTimeWheel,
  selectSelectedCalendarTitle,
  selectBeforeCalendarDate,
} from "@selectors/calendar"
import { calendarDateChangeMethod } from "@configs/enums"
import { GAMEPLAN_OPTIONS } from "@utils/gamePlanUtils"
import { selectUserInfo } from "@selectors/user"
import {
  selectActiveTeeupGameplans,
  selectActiveTeeupId,
} from "@selectors/activeTeeup"
import { selectSelectedCalendarDay } from "@selectors/common"
import { AppSizes } from "@theme"
import { formatHours, minTime, formatZones } from "@utils/timezones"

import ZonesView from "./zonesView"
import ZonesItem, { ConflictsItem } from "./zonesItem"

const halfScreenWidth = AppSizes.screen.widthHalf
const INITIAL_ZONE_WIDTH = 56
const INITIAL_ZONE_HEIGHT = 44

// Multiple synchronized scrollable rows and static "section headers" in between
// Base scroll view (vertical)
//  1) horizontal scroll view with your header, time row
//  2) get these 2 layouts

// 2 layers:
//  1) horizontal scroll view with all time rows and empty spaces of correct height between them (for headers)
//  2) view with headers with empty spaces for time rows

const skeletonAnimationSpeed = 2000

class ZonesContainer extends Component {
  constructor(props) {
    super(props)

    this.animatedValue = new Animated.Value(0)
    this.containerRef = React.createRef()
    this.hourRef = React.createRef()

    let {
      participants,
      people,
      refFunction,
      userInfo,
      startDate,
      endDate,
      selectedDate,
    } = props

    if (refFunction) {
      refFunction(this)
    }

    const { zones, myZoneShift, myZoneShiftExact, peopleWithUnknownTimeZones } =
      formatZones(participants, people, userInfo, startDate, endDate)
    const { hourElements, items } = formatHours()

    this.screenCenterShift = 0 //
    // this.listRef

    let selectedTime = this.getInitialSelectedTime()
    this.initialSelectedTime = selectedTime

    let duration = 0
    if (endDate) {
      duration = timeDifferenceSeconds(startDate, endDate) / 60
    }

    this.state = {
      contentPadding: 0,
      myZoneShift,
      myZoneShiftExact,
      zones,
      items,
      initialItems: items,
      selectedIndex: 0,
      hourElements,
      headerHeight: null,
      zoneHeight: null,
      zoneWidth: null,
      selectedTime,
      selectedDate,
      duration,
      peopleWithUnknownTimeZones,
      isReady: false,
      initScrollFixed: false,
      isSkeletonEnabled: true,
      offset: 0,
      end: true,
      nowIndex: 0,
      offsetIndexDiff: 0,
      nowTimeIndex: null,
      isTimePickedFromTimePicker: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let updates = null
    let updatedZones
    // console.log("zonesContainer", nextProps.startDate, state.startDate)
    if (
      nextProps.duration ||
      nextProps.endDate !== state.endDate ||
      nextProps.startDate !== state.startDate
    ) {
      updatedZones = state.zones.map((zone) => {
        let time = dateToFormatTimezone(nextProps.startDate, zone.timezoneName)
        if (nextProps.duration && nextProps.endDate) {
          time +=
            " - " + dateToFormatTimezone(nextProps.endDate, zone.timezoneName)
        }
        zone.time = time
        return zone
      })

      updates = {
        zones: updatedZones,
        duration: nextProps.duration,
      }
    }
    if (!nextProps.duration && state.duration && !nextProps.endDate) {
      updatedZones = state.zones.map((zone) => {
        let time = dateToFormatTimezone(nextProps.startDate, zone.timezoneName)
        zone.time = time
        return zone
      })

      updates = {
        zones: updatedZones,
        duration: null,
      }
    }

    if (
      nextProps.selectedDate &&
      (nextProps.endDate !== state.endDate ||
        nextProps.selectedDate !== state.selectedDate)
    ) {
      updatedZones = state.zones.map((zone) => {
        let time = dateToFormatTimezone(
          nextProps.selectedDate,
          zone.timezoneName
        )

        let day = dateToFormatTimezone(
          nextProps.selectedDate,
          zone.timezoneName,
          "dddd MMM DD"
        )
        if (nextProps.duration && nextProps.endDate) {
          time +=
            " - " + dateToFormatTimezone(nextProps.endDate, zone.timezoneName)
        }
        zone.day = day
        zone.time = time
        return zone
      })

      updates = {
        ...updates,
        zones: updatedZones,
      }
    }

    return updates
  }

  componentDidMount() {
    // console.log("mount")
    this.isMount = true
    setTimeout(this.handleSetTimeOnInit, 600)
    this.handleAnimateSkeleton()
  }

  skeletonAnimation = null

  handleAnimateSkeleton = () => {
    if (this.skeletonAnimation) {
      this.skeletonAnimation.stop()
      this.skeletonAnimation = null
    }
    this.skeletonAnimation = Animated.loop(
      Animated.sequence([
        Animated.timing(this.animatedValue, {
          toValue: 1,
          duration: skeletonAnimationSpeed,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
        Animated.timing(this.animatedValue, {
          toValue: 0,
          delay: skeletonAnimationSpeed * 2,
          duration: 0,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
      ])
    )
    this.skeletonAnimation.start()
  }

  handleSetTimeOnInit = () => {
    this.setState(
      (prevState) => ({
        items: [...prevState.items, ...prevState.initialItems],
      }),
      () => {
        const { startDate } = this.props
        const { items, initialItems, myZoneShift, myZoneShiftExact } =
          this.state
        let indexToScroll = initialItems?.length
        // console.log(
        //   "handleSetTimeOnInit",
        //   indexToScroll,
        //   initialItems?.length,
        //   myZoneShiftExact,
        //   myZoneShift
        // )
        const now = getDateWithTimezone()
        for (let i = 0; i < items.length; i++) {
          const dayBefore = getDateWithTimezone(startDate)
            .startOf("date")
            .add(-23 + myZoneShift + i, "hours")

          if (now.isBefore(dayBefore)) {
            indexToScroll = i
            break
          }
        }
        // console.log(
        //   "handleSetTimeOnInit 2",
        //   indexToScroll,
        //   initialItems?.length,
        //   myZoneShiftExact,
        //   myZoneShift
        // )

        const nowIndex = indexToScroll - myZoneShiftExact

        this.setState(
          {
            nowIndex: nowIndex,
          }
          // () => this.scrollToSelectedTime(() => {}, true, true)
        )
      }
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      duration,
      startDate,
      fromSuggestionPopup,
      endDateHoursInMinutes,
      fromSuggestionDetails,
      selectedCalendarDay,
      isTemporalCustom,
    } = this.props
    const { selectedTime, nowIndex, myZoneShiftExact } = this.state
    if (prevProps.duration !== duration) {
      const { zoneWidth } = this.state
      const sevenHoursInMinutes = 7 * 60
      const fullZoneWidth = zoneWidth + 20
      const decreasedZoneWidth = zoneWidth - 20
      if (duration >= sevenHoursInMinutes) {
        if (prevProps.duration < sevenHoursInMinutes) {
          this.changeZoneWidth(decreasedZoneWidth, true)
        }
      } else {
        if (prevProps.duration >= sevenHoursInMinutes) {
          this.changeZoneWidth(fullZoneWidth, false)
        }
      }
      const startObj = getDateWithTimezone(startDate)
      const selectedTimeObj = getDateWithTimezone(selectedTime)
      if (
        startObj.isSame(selectedTimeObj, "hour") &&
        startObj.isSame(selectedTimeObj, "minute")
      ) {
        this.scrollToSelectedTime(() => {}, true)
      }
    }
    const currentMinutes = new Date().getMinutes()
    if (selectedCalendarDay !== prevProps.selectedCalendarDay) {
      const hoursDifference = moment(selectedCalendarDay).diff(
        moment(startDate),
        "hours"
      )
      this.containerRef.current.scrollLeft +=
        INITIAL_ZONE_WIDTH * hoursDifference + 2 * INITIAL_ZONE_WIDTH
    }
    if (nowIndex !== prevState.nowIndex) {
      let indexToDelete = myZoneShiftExact < 0 ? myZoneShiftExact : 0
      let moveLeft =
        INITIAL_ZONE_WIDTH * (nowIndex - indexToDelete) + 5 + currentMinutes
      // console.log("pt3 enter here", nowIndex, indexToDelete, moveLeft)

      this.containerRef.current.scrollLeft = moveLeft
    } else if (fromSuggestionPopup) {
      if (duration < 120) {
        this.containerRef.current.scrollLeft =
          INITIAL_ZONE_WIDTH * nowIndex +
          endDateHoursInMinutes -
          new Date().getHours() * 60 -
          new Date().getMinutes() +
          15
      } else {
        this.containerRef.current.scrollLeft =
          INITIAL_ZONE_WIDTH * nowIndex +
          endDateHoursInMinutes -
          new Date().getHours() * 60 -
          new Date().getMinutes() -
          duration / 4 +
          15
      }
    } else if (fromSuggestionDetails) {
      if (duration < 120) {
        this.containerRef.current.scrollLeft =
          INITIAL_ZONE_WIDTH * nowIndex +
          endDateHoursInMinutes -
          new Date().getHours() * 60 -
          new Date().getMinutes() -
          60
      } else {
        this.containerRef.current.scrollLeft =
          INITIAL_ZONE_WIDTH * nowIndex +
          endDateHoursInMinutes -
          new Date().getHours() * 60 -
          new Date().getMinutes() -
          duration / 2 -
          15
      }
    }
  }

  getInitialSelectedTime() {
    const { startDate } = this.props
    let selectedTime = adjustMinuteRange(startDate)

    return selectedTime
  }

  changeZoneWidth = (width, isDecreasedZoneWidth) => {
    this.setState({ zoneWidth: width, isDecreasedZoneWidth })
  }

  onZoneLayout = () => {
    if (!this.state.zoneHeight) {
      this.onListReady(INITIAL_ZONE_WIDTH)

      this.setState({
        zoneHeight: INITIAL_ZONE_HEIGHT,
        zoneWidth: INITIAL_ZONE_WIDTH,
      })
    }
  }

  initialTimeIncreased = false

  checkDateChangeMethod = (dayChangeMethod) => {
    if (
      dayChangeMethod === calendarDateChangeMethod.dayPress ||
      dayChangeMethod === calendarDateChangeMethod.pageScroll
    ) {
      this.setState({ isSkeletonEnabled: true }, this.handleAnimateSkeleton)
    } else if (
      dayChangeMethod === calendarDateChangeMethod.hideSkeletonAfterScroll
    ) {
      setTimeout(() => {
        this.setState({ isSkeletonEnabled: false })
      }, 100)
    }
  }

  onListLayout = () => {
    const { isFromSuggestionDetails, isInitialSuggestionTimeIncreased } =
      this.props
    this.onListReady(INITIAL_ZONE_WIDTH)
    this.setState(
      ({ selectedTime }) => ({
        ...(!isFromSuggestionDetails &&
          !this.isInitialTimeWasAlreadyIncreased &&
          isInitialSuggestionTimeIncreased && {
            selectedTime: getDateWithTimezone(selectedTime).add(1, "hours"),
          }),
      }),
      () => {
        if (!this.isInitialTimeWasAlreadyIncreased) {
          this.isInitialTimeWasAlreadyIncreased = true
        }
        setTimeout(
          () =>
            this.setState({
              isReady: true,
            }),
          0
        )
      }
    )
  }

  onHeaderLayout = (event) => {
    if (!this.state.headerHeight) {
      let { height } = event.nativeEvent.layout
      this.setState({ headerHeight: height })
    }
  }

  getItemLayout = (data, index) => {
    const width = this.state.zoneWidth || INITIAL_ZONE_WIDTH
    return { length: width, offset: width * index, index }
  }

  onListRef = (ref) => {
    this.listRef = ref
  }

  getHourOffset = (itemWidth, hour) => {
    const { myZoneShiftExact } = this.state
    const hoursWithShift = 24 - myZoneShiftExact + hour
    //TODO

    const hoursOffset = itemWidth * hoursWithShift
    return hoursOffset
  }

  getMinutesFromIndex = (scrolled) => {
    switch (true) {
      case scrolled % 56 >= 0 && scrolled % 56 < 56 / 4:
        return 0
      case scrolled % 56 >= 56 / 4 && scrolled % 56 < 56 / 2:
        return 15
      case scrolled % 56 >= 56 / 2 && scrolled % 56 < (56 * 3) / 4:
        return 30
      case scrolled % 56 >= (56 * 3) / 4 && scrolled % 56 < 56:
        return 45
    }
  }

  getDateFromIndex = (index) => {
    const { duration, itemWidth } = this.state
    const { fromCreate } = this.props

    // const ovarlayWidth = fromCreate ? 400 : document.documentElement.clientWidth/2;
    // const distanceToArea = ovarlayWidth/2 - itemWidth/2 - itemWidth/4;
    // let days = ~~(
    //     (index + Math.floor(distanceToArea/itemWidth) - duration / minTime / 2 / 4) /
    //     24
    // )

    let days = ~~((index + 6 - duration / minTime / 2 / 4) / 24)
    return getDateWithTimezone(this.initialSelectedTime)
      .add(days - 1, "days")
      .startOf("date")
  }

  getHourFromIndex = (index) => {
    const { myZoneShiftExact } = this.state
    let hour = (index + myZoneShiftExact) % 24

    return hour
  }

  onListReady = (itemWidth) => {
    // Because of separator, need to add +1 to width
    const { duration } = this.props
    const isInitialDuration = !!duration
    itemWidth += 1
    const pickerWidth = 6

    let itemsPerHalf = Math.floor(halfScreenWidth / itemWidth)
    let itemsPerHalfLeftover = halfScreenWidth % itemWidth
    this.screenCenterShift = Math.abs(itemWidth / 2 - itemsPerHalfLeftover)
    const contentPadding =
      itemsPerHalf * itemWidth + itemsPerHalfLeftover + pickerWidth
    console.log("onListReady", contentPadding)
    this.setState(
      {
        contentPadding,
      },
      () => this.scrollToSelectedTime(() => {}, isInitialDuration)
    )
  }

  handleCalculateOffsetFromTime = (hour, minutes) => {
    const { duration } = this.props
    const { zoneWidth } = this.state
    let itemWidth = this.getItemWidth(zoneWidth)
    let offset = Math.ceil(
      this.getHourOffset(itemWidth, hour) + (itemWidth * minutes) / 60
    )
    if (duration) {
      offset = Math.floor(
        this.getHourOffset(itemWidth, hour) + (itemWidth * minutes) / 60
      )
    }
    return offset
  }

  getOffsetFromTime = (selectedTime) => {
    const { zoneWidth } = this.state
    const date = getDateWithTimezone(selectedTime)

    const month = date.month()
    const hour = date.hour()
    const minutes = date.minute()
    const monthDiff =
      month - getDateWithTimezone(this.initialSelectedTime).month()
    const dafeDiff =
      (monthDiff
        ? getDateWithTimezone(this.initialSelectedTime).daysInMonth() //add days when select new month from calendar
        : 0) +
      date.date() -
      getDateWithTimezone(this.initialSelectedTime).date()

    return (
      (dafeDiff * 24 * zoneWidth || 0) +
      this.handleCalculateOffsetFromTime(hour, minutes)
    )
  }

  scrollToIndexN = (
    selectedTime,
    checkDuration,
    isInitialDuration,
    forceScroll,
    ignoreScroll = false
  ) => {
    const { duration } = this.props
    const { isScrollOnPastTime, offset: offsetFromState, isReady } = this.state
    let updatedDate = null
    if (isInitialDuration) {
      updatedDate = addMinutes(selectedTime, duration / 2)
    }

    const dateForOffset = updatedDate
      ? updatedDate
      : checkDuration
      ? addMinutes(selectedTime, checkDuration / 2)
      : selectedTime

    let offset = this.getOffsetFromTime(dateForOffset)
    if (offset > this.contentWidth && isReady) {
      this.scrollToIndexCall = () =>
        this.scrollToIndex(
          selectedTime,
          ignoreScroll,
          checkDuration,
          isInitialDuration
        )
      this.setState({
        items: [...this.state.items, ...this.state.items],
      })
    } else if (
      forceScroll ||
      isScrollOnPastTime ||
      !offsetFromState ||
      (offsetFromState !== offset && !this.manualScrolling)
    ) {
      this.setState(
        {
          selectedTime,
          offset,
        },
        () => {
          if (!isReady) {
            this.setState(
              {
                initScrollFixed: true,
              }
              // () => this.scrollToSelectedTime(() => {}, true, true)
            )
          }
          this.checkDateChangeMethod(
            calendarDateChangeMethod.hideSkeletonAfterScroll
          )
        }
      )
    }
  }

  scrollToIndex = _.debounce(this.scrollToIndexN, 1000)

  renderItem = (item, index, style) => {
    const {
      useConflicts,
      conflicts,
      columnsAmount,
      overlappingEvents,
      selectedDate,
      handleCalendar,
      isFromSuggestionDetails,
      startDate,
      mappedEventsList,
    } = this.props
    const {
      myZoneShift,
      myZoneShiftExact,
      hourElements,
      zones,
      headerHeight,
      zoneHeight,
      zoneWidth,
      nowIndex,
    } = this.state

    let myZoneHour = item + myZoneShift
    if (myZoneHour < 0) {
      myZoneHour += 24
    } else if (myZoneHour >= hourElements.length) {
      myZoneHour -= hourElements.length
    }
    // const hourConflicts = conflicts[myZoneHour]
    const date = getDateWithTimezone(this.initialSelectedTime)
    date.add(index - 23 + myZoneShift, "hour")
    const hourConflicts = mappedEventsList
      ? mappedEventsList.filter((event) => {
          const startDate = getDateWithTimezone(event.startDate)
          const endDate = getDateWithTimezone(event.endDate)
          return (
            startDate.isSame(date, "day") &&
            startDate.hour() <= myZoneHour &&
            endDate.hour() >= myZoneHour
          )
        })
      : []
    return (
      <React.Fragment key={date}>
        <ZonesItem
          itemDate={date}
          index={index}
          hourRef={this.hourRef}
          hourElements={hourElements}
          zones={headerHeight && zoneHeight ? zones : [zones[0]]}
          onLayout={index === 0 ? this.onZoneLayout : undefined}
          weekday={item.weekday}
          number={item.number}
          hour={item}
          nowIndex={nowIndex}
          selectedDate={selectedDate}
          startDate={startDate}
          headerHeight={headerHeight}
          zoneHeight={zoneHeight}
          zoneWidth={zoneWidth}
          useConflicts={useConflicts}
          conflicts={hourConflicts}
          // conflicts={[]}
          handleCalendar={handleCalendar}
          isFromSuggestionDetails={isFromSuggestionDetails}
          myZoneShiftExact={myZoneShiftExact}
          style={style}
        />
        <ConflictsItem
          useConflicts={useConflicts}
          conflicts={hourConflicts}
          // conflicts={[]}
          zoneHeight={zoneHeight}
          zoneWidth={zoneWidth}
          headerHeight={headerHeight}
          overlappingEvents={overlappingEvents}
          columnsAmount={columnsAmount}
          isFullTZ={myZoneShift === myZoneShiftExact}
        />
      </React.Fragment>
    )
  }

  getTimeAndIndexFromScrolledOffset = (scrolled, pickedHour, pickedMinute) => {
    const { selectedDate, fromCreate } = this.props
    const { zoneWidth: itemWidth, isDecreasedZoneWidth, duration } = this.state
    // 1 index = 15 minutes interval
    const roundMethod = isDecreasedZoneWidth ? "floor" : "round"

    const durationShift = (((duration - 60) / 2) * INITIAL_ZONE_WIDTH) / 60

    const ovarlayWidth = fromCreate
      ? document.documentElement.clientWidth / 2
      : 400

    const distanceToArea = ovarlayWidth / 2 - itemWidth / 2 - itemWidth / 4

    const newIndex = Math[roundMethod](
      (scrolled - 18 + distanceToArea - durationShift) / itemWidth
    )
    let hour = pickedHour ?? this.getHourFromIndex(newIndex)
    const date = this.getDateFromIndex(newIndex)

    let minutes =
      pickedMinute ?? this.getMinutesFromIndex(scrolled - durationShift)
    let selectedMoment = getDateWithTimezone().hour(hour).minute(minutes).utc()

    let selectedTime = composeDatetime(selectedDate, selectedMoment)

    return { newIndex, selectedTime, date, durationShift }
  }

  onScrollBeginDrag = () => {
    this.setState({ initScrollFixed: true })
    this.manualScrolling = true
  }

  scrollToSelectedTime = (callback, isInitialDuration, forceScroll = false) => {
    let date = getDateWithTimezone(this.state.selectedTime)

    setTimeout(() => {
      this.scrollToIndex(date, false, isInitialDuration, forceScroll, false)

      if (callback) {
        callback()
      }
    }, 0)
  }

  invokeScrollToIndexCall = _.debounce(() => {
    this.scrollToIndexCall()
    this.scrollToIndexCall = null
  }, 1000)

  onContentWidthChange = (width) => {
    if (this.contentWidth !== width && this.scrollToIndexCall) {
      this.invokeScrollToIndexCall()
    }
    this.contentWidth = width
  }

  handleCheckScrollData = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const { items, initialItems, end } = this.state
    const initialLength = initialItems.length
    let updates = {}
    if (items.length >= initialLength * 3) {
      updates = (prevState) => ({
        items: prevState.items.slice(initialLength * 2),
      })
    }
    if (contentOffset.x <= 20) {
      updates = (prevState) => ({
        items: [...prevState.items, ...initialItems],
      })
    }
    if (
      layoutMeasurement.width + contentOffset.x >= contentSize.width - 20 &&
      end
    ) {
      updates = (prevState) => ({
        items: [...prevState.items, ...initialItems],
        end: false,
      })
    } else {
      updates = { end: true }
    }
    this.setState(updates)
  }

  //core function => helpfull
  handleTimePick = (_, pickedHour, pickedMinute) => {
    const { onZonesScrolled } = this.props

    let scrolled = this.state.scrolled

    const selectedTime = getDateWithTimezone(this.props.startDate)

    let { selectedTime: newSelectedTime, date } =
      this.getTimeAndIndexFromScrolledOffset(scrolled, pickedHour, pickedMinute)
    let updates = { offset: scrolled }

    const newSelectedTimeObject = getDateWithTimezone(newSelectedTime)
    const newTime = newSelectedTimeObject.date(date.date()).month(date.month())

    const lastDuration = this.state.duration
    let callback = () => {
      onZonesScrolled(newTime)
      const hoursDifference = moment(newSelectedTimeObject).diff(
        moment(selectedTime),
        "hours"
      )

      const minutesDifference = moment(newSelectedTimeObject).diff(
        moment(selectedTime),
        "minutes"
      )

      const minutesShift = (INITIAL_ZONE_WIDTH * (minutesDifference % 60)) / 60

      const durationDiff = this.state.duration - lastDuration

      let durationShift = 0
      if (durationDiff) {
        durationShift += INITIAL_ZONE_WIDTH * (durationDiff / 120)
      }

      this.containerRef.current.scrollLeft +=
        INITIAL_ZONE_WIDTH * hoursDifference + minutesShift + durationShift
    }

    this.setState({ isTimePickedFromTimePicker: true })

    this.updateDataAfterScroll(updates, callback)
  }

  onScroll = (nativeEvent) => {
    const { offset, duration } = this.state

    const { onZonesScrolled } = this.props
    let scrolled = nativeEvent.current?.scrollLeft

    this.setState({ scrolled })

    let { selectedTime, date, durationShift } =
      this.getTimeAndIndexFromScrolledOffset(scrolled)

    let updates = { offset: scrolled }

    const selectedTimeObject = getDateWithTimezone(selectedTime)

    const newTime = selectedTimeObject.date(date.date()).month(date.month())

    const isSelectedTimeBefore = isDateBeforeNow(newTime)
    this.props.setBeforeCalendarDate(isDateBeforeNow(newTime))

    const currentTimeWidth = this.state.nowIndex * this.state.zoneWidth - 50 //its width from first to current time item for timewheel

    if (this.props.isBeforeCalendarDate) {
      setTimeout(() => {
        nativeEvent.current.scrollTo({
          top: 0,
          left: currentTimeWidth + durationShift,
          behavior: "smooth",
        })
      }, 100)
    }

    let callback = () => {
      onZonesScrolled(newTime)
    }

    if (isSelectedTimeBefore && this.manualScrolling) {
      const currentTime = getDateWithTimezone()
      const scrolledPastTime = getDuration(selectedTime, currentTime)
      if (scrolledPastTime > 10) {
        updates = {
          offset,
          isScrollOnPastTime: true,
          selectedTime: currentTime.format(),
        }
        this.ignoreScroll = true

        callback = () => {
          setTimeout(() => {
            this.ignoreScroll = false
            this.setState({ isScrollOnPastTime: false })
          }, 1000)
        }
      }
    }

    if (!this.state.isTimePickedFromTimePicker) {
      this.updateDataAfterScroll(updates, callback)
    }
    this.setState({ isTimePickedFromTimePicker: false })
  }

  updateDataAfterScroll = _.debounce(
    (updates, callback) => this.setState(updates, callback),
    250
  )

  onMomentumScrollEnd = () => {
    this.ignoreScroll = false
    this.manualScrolling = false
    // this.onScroll({ nativeEvent })
  }

  getItemWidth = (height) => {
    return height
  }

  render() {
    const {
      items,
      zones,
      contentPadding,
      headerHeight,
      zoneHeight,
      zoneWidth,
      selectedTime,
      isReady,
      peopleWithUnknownTimeZones,
      isScrollOnPastTime,
      isSkeletonEnabled,
    } = this.state
    const {
      isTemporalCustom,
      disableScroll,
      darkTheme,
      timeIndicatorLabel,
      isTimeZoneEnabled,
      startDate,
      endDate,
      duration,
      hasConflict,
      selectedCalendarTitle,
      isVisible,
      isFromSuggestionDetails,
      suggestionValues,
      handleDurationPick,
      fromSuggestionPopup,
    } = this.props

    let initial = headerHeight && zoneHeight ? false : true

    let durationCalculated = timeDifferenceSeconds(startDate, endDate) / 60
    let durationWidth = duration
      ? (durationCalculated / 60) * INITIAL_ZONE_WIDTH
      : null

    // ad hoc solution for durations when half duration is float and duration is 15 mins
    // if no duration margin must be 0, because Android crashes for null values
    const halfDurationAdditionalPadding = {
      marginLeft: zoneWidth / (60 / minTime),
    }
    // console.log("info", items.length, INITIAL_ZONE_WIDTH)
    return (
      <ZonesView
        isTemporalCustom={isTemporalCustom}
        containerRef={this.containerRef}
        onListLayout={this.onListLayout}
        renderItem={this.renderItem}
        getItemLayout={this.getItemLayout}
        items={items}
        initial={initial}
        isSkeletonEnabled={isSkeletonEnabled}
        skeletonAnimation={this.animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [-AppSizes.screen.width, 3 * AppSizes.screen.width],
        })}
        onContentWidthChange={this.onContentWidthChange}
        zones={initial ? [zones[0]] : zones}
        onScroll={_.debounce(this.onScroll, 1000)}
        onMomentumScrollEnd={this.onMomentumScrollEnd}
        onScrollBeginDrag={this.onScrollBeginDrag}
        onListRef={this.onListRef}
        contentPadding={contentPadding}
        disableScroll={disableScroll}
        isScrollOnPastTime={isScrollOnPastTime}
        darkTheme={darkTheme}
        durationWidth={durationWidth}
        time={
          timeIndicatorLabel ? timeIndicatorLabel : getLocaleTime(selectedTime)
        }
        headerHeight={headerHeight}
        zoneHeight={zoneHeight}
        zoneWidth={zoneWidth / 4}
        onHeaderLayout={this.onHeaderLayout}
        isReady={isReady}
        isVisible={isVisible || isFromSuggestionDetails}
        isTimeZoneEnabled={isTimeZoneEnabled}
        duration={duration}
        peopleWithUnknownTimeZones={peopleWithUnknownTimeZones}
        halfDurationAdditionalPadding={halfDurationAdditionalPadding}
        hasConflict={hasConflict}
        selectedCalendarTitle={selectedCalendarTitle}
        suggestionValues={suggestionValues}
        handleTimePick={this.handleTimePick}
        handleDurationPick={handleDurationPick}
        fromSuggestionPopup={fromSuggestionPopup}
        itemWidth={INITIAL_ZONE_WIDTH}
      />
    )
  }
}

ZonesContainer.propTypes = {
  darkTheme: PropTypes.bool,
  timeIndicatorLabel: PropTypes.string,
  disableScroll: PropTypes.bool,
  isStartDateToday: PropTypes.bool,

  userInfo: PropTypes.object,
  onReady: PropTypes.func,
  onZonesScrolled: PropTypes.func,
  refFunction: PropTypes.func,

  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isTimeZoneEnabled: PropTypes.bool,
  participants: PropTypes.array.isRequired,
  people: PropTypes.object.isRequired,
  duration: PropTypes.number,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  conflicts: PropTypes.object,
  useConflicts: PropTypes.bool,
  columnsAmount: PropTypes.number,
  overlappingEvents: PropTypes.array,
  handleCalendar: PropTypes.func,
  isMultiTimeZone: PropTypes.bool,
  hasConflict: PropTypes.bool,
  selectedCalendarTitle: PropTypes.string,
  isFromSuggestionDetails: PropTypes.bool,
  isVisible: PropTypes.bool,
  isInitialSuggestionTimeIncreased: PropTypes.bool,
  mappedEventsList: PropTypes.array,
}

const mapStateToProps = (state, ownProps) => {
  const userInfo = selectUserInfo(state)
  const selectedCalendarTitle = selectSelectedCalendarTitle(state)
  const activeTeeupGameplans = selectActiveTeeupGameplans(state)
  const isBeforeCalendarDate = selectBeforeCalendarDate(state)
  const activeTeeupId = selectActiveTeeupId(state)
  const whenGameplan = activeTeeupGameplans.find(
    (gameplan) => gameplan.type === GAMEPLAN_OPTIONS.when
  )

  const calendarStart = getDateWithTimezone(ownProps.startDate)
    .subtract(1, "day")
    .startOf("day")
  const calendarEnd = getDateWithTimezone(ownProps.startDate)
    .add(1, "day")
    .startOf("day")
  const { useConflicts, mappedEventsList, columnsAmount } =
    calendarConflictsForTimeWheel(calendarStart, calendarEnd, 2)(state)
  const selectedCalendarDay = selectSelectedCalendarDay(state)

  return {
    userInfo,
    selectedCalendarTitle,
    isInitialSuggestionTimeIncreased: Boolean(
      activeTeeupId || (!activeTeeupId && !whenGameplan.suggestions[0])
    ),
    useConflicts,
    mappedEventsList,
    columnsAmount,
    selectedCalendarDay,
    isBeforeCalendarDate,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setBeforeCalendarDate: bindActionCreators(setBeforeCalendarDate, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZonesContainer)
