import React from "react"

import "./index.scss"
import ChatInput from "../ChatInput"
import SuggestToolbar from "../SuggestToolbar"

const ConversationBottom = ({
  createMessage,
  setPeopleSideBar,
  inputRef,
  handleOpenSuggestion,
  newDesign,
}) => {
  return (
    <div className="conversationBottom">
      <ChatInput
        createMessage={createMessage}
        inputRef={inputRef}
        newDesign={newDesign}
      />
      {!newDesign && (
        <SuggestToolbar
          setPeopleSideBar={setPeopleSideBar}
          handleOpenSuggestion={handleOpenSuggestion}
        />
      )}
    </div>
  )
}

export default ConversationBottom
