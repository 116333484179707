import actionTypes from "./actionTypes"
import { push } from "connected-react-router"

import ApiCalendar from "react-google-calendar-api"
import api from "../api"
import endpoints from "@config/endpoints"
import { fetchUserInfo, setUserIsLoading } from "@actions/userActions"
import { loginProviders } from "@configs/enums"
import { getCurrentTimezone } from "@utils/dateUtils"
import { currentCountry } from "@utils/location"
import notificationService from "../notificationsService"
import { getRandomBase64Image } from "@utils/imageUtils"
import { v4 as uuidv4 } from "uuid"

export const loginEmail = (email, password) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOGIN_ERROR,
      payload: null,
    })

    api.client
      .post(endpoints.LOGIN, {
        email,
        password,
        type: "email",
      })
      .then((res) => {
        const { data } = res
        if (data.user.hasPassword) {
          data.loginType = "email"
          saveUserAndRedirect(data, dispatch)
          notificationService.askForNotificationsPermsions()
        } else {
          // it's temp password
          dispatch(push("change-temp-password", data.accessToken))
        }
      })
      .catch((error) => {
        if (error.response) {
          const { status, message } = error.response.data
          if (status === 401 && message) {
            dispatch({
              type: actionTypes.SET_LOGIN_ERROR,
              payload: message,
            })
          }
        }
        console.log("loginError", error)
      })
  }
}

export const logOut = () => {
  return async (dispatch) => {
    await localStorage.clear()
    ApiCalendar.handleSignoutClick()
    dispatch({ type: actionTypes.LOG_OUT })
    dispatch(push("/"))
    notificationService.removePlayedId()
  }
}

export const saveUser = (data, dispatch) => {
  dispatch({ type: actionTypes.SET_USER_INFO, payload: data })
  dispatch(setUserIsLoading(false))
}

export const saveUserAndRedirect = (data, dispatch) => {
  api.createSession(data)

  dispatch({ type: actionTypes.SET_USER_INFO, payload: data })
  dispatch(setUserIsLoading(false))
  dispatch(push("/teeUps"))
  fetchUserInfo()
}

export const verifyUserAndSave = (userInfo, idToken, logout) => {
  // check here if user is authenticated/registered. if either of these two is missing API will throw error
  if (
    !userInfo["https://coo-e.com/userId"] ||
    !userInfo["https://coo-e.com/userType"]
  ) {
    return
  }

  return (dispatch) => {
    api.client
      .post(endpoints.verifyUserType, {
        emailAddress: userInfo.email,
        sub: userInfo.sub,
      })
      .then((response) => {
        const { data } = response

        // if(data.registrationType === loginProviders.email) {
        //     alert('User already registered with email');
        //     logout();
        //     dispatch(setUserIsLoading(false));

        //     return;
        // }

        let socialUserInfo = {
          id: data.userId,
          email: userInfo.email,
          name: userInfo.name || userInfo.givenName,
          firstName: userInfo.givenName || userInfo.name,
          lastName: userInfo.familyName,
          nickname: userInfo.nickname,
          sub: userInfo.sub,
          // avatar,
          loginType: data.registrationType,
          accessToken: idToken,
        }

        saveUserAndRedirect(socialUserInfo, dispatch)
      })
      .catch((err) => {
        console.log("verifyUserAndSave error", err)
        const { message } = err.response.data
        dispatch({
          type: actionTypes.SET_LOGIN_ERROR,
          payload: message,
        })
        logOut()
      })
  }
}

export const sendPasswordRecoveryLink = (email) => {
  return api.client.post(endpoints.sendPasswordRecoveryLink, { email })
}

export const validateInvitation = (value) => {
  return api.client.post(endpoints.verifyUserType, value)
}

export const sendBetaCode = (value, id) => {
  return api.client.post(endpoints.sendBetaCode, {
    ...value,
    deviceId: id,
  })
}

export const recoveryRequest = (value, id) => {
  return api.client.post(endpoints.recoverAccount, {
    ...value,
    deviceId: id,
  })
}

export const recoveryRequestVerify = (value, contanctId) => {
  return api.client.patch(endpoints.recoverAccountVerify + contanctId, {
    code: value,
  })
}

export const changeEmailVerificationRequest = (
  contactMechanismId,
  accessToken
) => {
  return api.client.post(
    endpoints.changeEmailVerificationRequest +
      contactMechanismId +
      "/verifications",
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const changeEmailVerificationVerify = (
  value,
  contactMechanismVerificationId,
  designation,
  accessToken
) => {
  return api.client.post(
    endpoints.changeEmailVerificationVerify +
      contactMechanismVerificationId +
      "/verify",
    {
      code: value,
      designation: designation,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const createUserCM = (typeId, title, accessToken) => {
  return api.client.post(
    endpoints.addUserCM,
    {
      typeId,
      value: title,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const deleteUserCM = (cmId, accessToken, typeOfCM) => {
  return api.client.delete(
    `users/contact-mechanisms/${cmId}${typeOfCM}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const deleteUserCMFromEmail = (cmId, accessToken, typeOfCM) => {
  return function (dispatch) {
    return api.client.delete(
      `users/contact-mechanisms/${cmId}${typeOfCM}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  }
}

export const getUserCM = (accessToken) => {
  return api.client.get(endpoints.addUserCM, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const verifyBetaCode = (key, id, code) => {
  return api.client.post(endpoints.verifyBetaCode, {
    [key]: {
      id,
      code,
    },
  })
}

export const register = async (userInfo) => {
  const {
    id,
    email,
    // sub,
    nickname,
    password,
    timezone,
    phoneNumber,
    name,
    firstname = "",
    lastname = "",
    deviceId,
  } = userInfo

  // default avatar
  const avatar = await getRandomBase64Image()
  const avatartype = "image/base64"

  let body = {
    id,
    name,
    lastname,
    firstname,
    username: nickname,
    password: password,
    phoneNumber,
    locale: "en",
    // sub: sub,
    email,
    avatar,
    avatartype,
    type: "email",
    timezone: timezone || getCurrentTimezone(),
    country: currentCountry,
    // countryCode: countryCode || `${getCountryCode() || 1}`,
    deviceId,
  }

  return api.client.post(endpoints.register, body)
}

export const registerWithSocial = async (userInfo) => {
  const deviceId = uuidv4().replace(/-/g, "")
  const {
    email = "",
    family_name = "",
    given_name = "",
    nickname = "",
    name = "",
    picture,
    sub,
    // updated_at,
    // locale,
  } = userInfo

  // default avatar
  const avatar = picture || (await getRandomBase64Image())
  const avatartype = "image/base64"

  let body = {
    deviceId,
    name,
    lastname: family_name,
    firstname: given_name,
    username: nickname,
    locale: "en",
    ...(email && { email }),
    avatar,
    avatartype,
    timezone: getCurrentTimezone(),
    country: currentCountry,
    sub,
    type: sub.split("|")[0],
  }

  return api.client.post(endpoints.register, body)
}

export const getTokenOnVerifiedInvitation = (token) => {
  return {
    type: actionTypes.GET_TOKEN_ON_VERIFIED_INVITATION,
    payload: token,
  }
}
