import React from "react"
import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"
import { images } from "@utils/imageUtils"

import { TeeupsStyles as styles } from "./styles"

const TeeupBlock = ({
  title,
  positionStyles,
  hasConflist = false,
  coverLastColumn = true,
  showIcons = true,
  compacted,
  isTemporaryEvent = false,
}) => {
  const renderCalendarIcon = () => {
    if (!showIcons) {
      return null
    }
    if (hasConflist) {
      return (
        <Image style={styles.teeupEventIcon} source={images.calendarAlert} />
      )
    } else {
      return <Image style={styles.teeupEventIcon} source={images.calendarOk} />
    }
  }

  return (
    <View
      style={[
        styles.eventBlockContainer,
        !coverLastColumn &&
          (compacted ? styles.smallExtraPadding : styles.extraPadding),
        positionStyles,
      ]}
    >
      <View
        style={[
          styles.teeupEventContainer,
          hasConflist && styles.conflictedTeeupEventContainer,
          !compacted && styles.eventContainerPadding,
        ]}
      >
        <Image
          source={isTemporaryEvent ? images.bubbleWithClock : images.teeupSmall}
          style={[
            styles.teeupEventIcon,
            !isTemporaryEvent && styles.teeupEventIconPink,
          ]}
        />
        <Text
          style={[
            styles.teeupEventText,
            isTemporaryEvent && styles.teeupEventTextTemporary,
          ]}
        >
          {title || "Teeup"}
        </Text>
        <View style={styles.calendarIconContainer}>{renderCalendarIcon()}</View>
      </View>
    </View>
  )
}

TeeupBlock.propTypes = {
  title: PropTypes.string,
  positionStyles: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  hasConflist: PropTypes.bool,
  coverLastColumn: PropTypes.bool,
  showIcons: PropTypes.bool,
  compacted: PropTypes.bool,
  isTemporaryEvent: PropTypes.bool,
}

export default TeeupBlock
