import { StyleSheet, Platform } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"
import { images } from "@utils/imageUtils"
import styled from "styled-components"

const isIOS = Platform.OS === "ios"

export default StyleSheet.create({
  initialTopPart: {
    flex: 1,
    alignItems: "center",
    maxWidth: "75%",
  },
  wrapperContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  initialInvitePart: {
    flexDirection: "row",
    justifyContent: "center",
  },
  invitationMessage: {
    ...AppFonts.mediumDemibold,
    textAlign: "center",
    marginTop: Units.responsiveValue(8),
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  alignCenter: {
    alignItems: "center",
  },
  darkGrey2: {
    color: AppColors.brand.darkGrey2,
  },
  warmGrey2: {
    color: AppColors.brand.warmGrey2,
  },
  initialUsername: {
    ...AppFonts.biggerDemibold,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.34),
  },
  createdText: {
    ...AppFonts.smallishDemibold,
    lineHeight: Units.responsiveValue(18),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  invitedTitle: {
    ...AppFonts.mediumBold,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  invitedPeopleText: {
    ...AppFonts.mediumMedium,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  subsectionTitle: {
    ...AppFonts.smallerBold,
    lineHeight: Units.responsiveValue(18),
    letterSpacing: -Units.responsiveValue(0.2),
  },
  inviteesLine: {
    ...AppFonts.mediumMedium,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  buttonImg: {
    marginLeft: Units.responsiveValue(6),
  },
  expandedButton: {
    width: Units.responsiveValue(20),
    height: Units.responsiveValue(20),
  },
  collapsedButton: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
  },
  separateLine: {
    flex: 1,
    borderTopWidth: 1,
    borderColor: AppColors.brand.grey3,
    marginTop: Units.responsiveValue(12),
  },
  textPadding: {
    paddingHorizontal: 25,
  },
  mainContainer: {
    width: "100%",
    flexDirection: "column-reverse",
    alignItems: undefined,
  },
  boxContainer: ({
    isStatusMessage,
    isInitial,
    isImageChange,
    isGameplanUpdate,
  }) => {
    const padding = isInitial
      ? {
          paddingTop: Units.responsiveValue(12),
          paddingBottom: Units.responsiveValue(14),
          paddingHorizontal: Units.responsiveValue(14),
        }
      : {}
    return {
      backgroundColor:
        isStatusMessage || isImageChange || isGameplanUpdate
          ? AppColors.transparent
          : AppColors.brand.lightGrey,
      borderRadius: Units.responsiveValue(!isImageChange ? 20 : 0),
      flexDirection: "column",
      ...padding,
    }
  },
  boxContainerGrey: {
    backgroundColor: AppColors.brand.lightGrey,
  },
  text: {
    flexShrink: 1,
    ...AppFonts.mediumMedium,
    letterSpacing: -Units.responsiveValue(0.3),
    lineHeight: Units.responsiveValue(20),
    color: AppColors.brand.warmGrey2,
    textAlign: "center",
  },
  expansionText: {
    ...AppFonts.smallishDemibold,
    marginTop: Units.responsiveValue(5),
    letterSpacing: -Units.responsiveValue(0.3),
    lineHeight: Units.responsiveValue(20),
    color: AppColors.brand.warmGrey2,
    textAlign: "center",
  },
  expansionTextHighlight: {
    color: AppColors.brand.pink,
  },
  textBold: {
    fontFamily: AppFonts.family.bold,
  },
  textInitial: {
    display: "flex",
    flexWrap: "wrap",
    flexShrink: 2,
  },
  textEmoji: {},
  textContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatarsContainer: {
    marginBottom: -Units.rem(0.5),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  accessoryImage: {
    width: Units.rem(1),
    height: Units.rem(1),
  },
  imageMargin: {
    marginLeft: -Units.rem(0.5),
  },
  subviews: {
    flexDirection: "column",
  },
  messageContentContainer: {
    flexDirection: "column",
    marginTop: Units.responsiveValue(16),
  },
  leftImage: {
    marginRight: Units.rem(0.5),
  },
  flex: {
    flex: 1,
  },
  marginBottomSmall: {
    marginBottom: Units.rem(0.125),
  },
  marginBottomSmaller: {
    marginBottom: Units.rem(0.375),
  },
  marginBottom: {
    marginBottom: Units.rem(0.5),
  },
  gameplansSplitter: {
    height: 8,
  },
  peopleSubview: {
    paddingTop: Units.responsiveValue(5),
  },
  peopleSubviewItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: Units.responsiveValue(6),
  },
  peopleSubviewIcon: {
    width: Units.responsiveValue(14),
    height: Units.responsiveValue(14),
  },
  peopleSubviewIconWithMargin: {
    width: Units.responsiveValue(14),
    height: Units.responsiveValue(14),
    marginRight: Units.responsiveValue(6),
  },
  peopleSubviewText: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.warmGrey2,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.2),
    flexGrow: 1,
  },
  peopleSubviewMainText: {
    flexShrink: 1,
  },
  statusTitle: {
    fontFamily: AppFonts.family.bold,
  },
  peopleSubviewTime: {
    flexGrow: 0,
  },
  statusAvatar: {
    width: 24,
    height: 24,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: AppColors.brand.white,
    justifyContent: "center",
    alignItems: "center",
  },
  statusAvatarImage: {
    width: 12,
    height: 12,
  },
  waitingForLayout: {
    opacity: 0,
    position: "absolute",
  },
  absoluteExpandedButton: {
    position: "absolute",
    right: -Units.responsiveValue(22),
    top: 0,
  },
  textMessageUrl: { color: AppColors.brand.pink },
  icon: {
    marginRight: Units.responsiveValue(2),
    marginLeft: Units.responsiveValue(4),
    marginBottom: Units.responsiveValue(4),
    width: Units.responsiveValue(15),
    height: Units.responsiveValue(15),
  },
  userStatusWrapper: {
    flexDirection: "row",
    alignItems: "center",
    height: Units.responsiveValue(20),
    lineHeight: Units.responsiveValue(20),
    // marginVertical: Units.responsiveValue(expanded ? 4 : 0),
  },
})

export const StatusAvatarProps = {
  planning: {
    image: images.mapSigns,
  },
  allset: {
    image: images.thumbsUp,
  },
  happening: {
    image: images.bolt,
  },
  cancelled: {
    image: images.times,
  },
  ended: {
    image: images.hourglassEnd,
  },
  criticalMassEnabled: {
    image: images.progressBar,
  },
  happeningSoon: {
    image: images.bolt,
  },
}

export const StatusAvatarStyles = StyleSheet.create({
  planning: {
    backgroundColor: AppColors.brand.orange,
  },
  allset: {
    backgroundColor: AppColors.brand.green,
  },
  happening: {
    backgroundColor: AppColors.brand.blue2,
  },
  cancelled: {
    backgroundColor: AppColors.brand.red,
  },
  ended: {
    backgroundColor: AppColors.brand.warmGrey,
  },
  criticalMassEnabled: {
    backgroundColor: AppColors.brand.green,
  },
  happeningSoon: {
    backgroundColor: AppColors.brand.blue2,
  },
})

export const InitialMessageStyles = (isMyMessage) =>
  StyleSheet.create({
    box: {
      backgroundColor: isMyMessage
        ? AppColors.brand.lightBlue4
        : AppColors.brand.lightGrey,
      borderRadius: Units.responsiveValue(20),
      paddingHorizontal: Units.responsiveValue(14),
      // paddingTop: Units.rem(0.5),
      paddingHorizontal: "12px",
      paddingVertical: "12px",
      backfaceVisibility: "hidden",
    },
    headingText: {
      color: isMyMessage ? "rgb(8, 95, 142)" : AppColors.brand.darkGrey2,
      ...AppFonts.smallishDemibold,
    },
    bodyText: {
      color: isMyMessage ? "rgb(8, 95, 142)" : AppColors.brand.black2,
      ...AppFonts.biggerMedium,
    },
    headingOnBodyText: {
      color: isMyMessage ? "rgb(8, 95, 142)" : AppColors.brand.darkGrey2,
      ...AppFonts.biggerMedium,
    },
    boldText: {
      color: isMyMessage ? "rgb(8, 95, 142)" : AppColors.brand.black2,
      fontFamily: AppFonts.biggerBold.fontFamily,
    },
    section: {
      // marginTop: '16px',
      // marginBottom: '16px',
      // margin: Units.rem(1)
    },
    expandToggle: {
      paddingVertical: Units.rem(0.5),
    },
    expandToggleText: {
      color: AppColors.brand.pink,
      ...AppFonts.smallishDemibold,
    },
  })

export const StyledGameplanWrapper = styled.div`
  position: relative;
`

export const StyledThreeDots = styled.img`
  position: absolute;
  top: ${Units.responsiveValue(6)}px;
  ${(props) => (props.isMyMessage ? "left: 0" : "right: 0")};
  cursor: pointer;
`
