import { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  DEFAULT_SORT,
  initialFiltersValues,
  teeupsFiltering,
  teeupSorting,
} from "@utils/teeupUtils"
import {
  selectTeeupsParticipants,
  selectTeeupsPeople,
  selectTeeupsGameplans,
} from "../../../selectors/teeups"
import { selectUnseenTeeups } from "@selectors/teeups"
import { selectTeeupInvitationsRequests } from "../../../selectors/requests"
import { selectUserId } from "../../../selectors/user"
import { selectNotificationTeeups, selectTeeupSearch } from "@selectors/common"
import { selectActiveTeeups } from "../../../selectors/teeups"
import { fetchTeeups } from "@actions/teeupActions"

const useProfilePageSharedTeeUps = (id) => {
  const [sharedTeeUpsData, setSharedTeeUpsData] = useState(null)
  const sorting = DEFAULT_SORT
  const filtering = initialFiltersValues

  const dispatch = useDispatch()

  //------SELECTORS------
  const activeTeeups = useSelector(selectActiveTeeups)
  const teeupsGameplans = useSelector(selectTeeupsGameplans)
  const userId = useSelector(selectUserId)
  const notificationTeeups = useSelector(selectNotificationTeeups)
  const notifications = useSelector(selectUnseenTeeups)
  const teeupFilter = useSelector(selectTeeupSearch)
  const teeupParticipants = useSelector(selectTeeupsParticipants)
  const teeupPeople = useSelector(selectTeeupsPeople)
  const teeupInvitations = useSelector(selectTeeupInvitationsRequests)
  const teeupGameplans = useSelector(selectTeeupsGameplans)
  //------SELECTORS------

  useEffect(() => {
    dispatch(fetchTeeups())
  }, [])

  useEffect(() => {
    setSharedTeeUpsData(filterAndSortedData())
  }, [activeTeeups])


  const sortedList = useMemo(() => {
    const sorted = teeupSorting(activeTeeups, sorting, teeupsGameplans)
    return sorting.desc ? sorted.reverse() : sorted
  }, [sorting, activeTeeups])

  const filterAndSortedData = () => {
    let teeupsData = teeupsFiltering(
      sortedList,
      filtering,
      userId,
      teeupsGameplans
    )

    if (notificationTeeups) {
      const notificationsArr = notifications.map(
        (notification) => notification.id
      )
      return teeupsData.filter((teeup) => notificationsArr.includes(teeup.id))
    }

    if (teeupFilter) {
      return teeupsData.filter(
        (teeup = {}) =>
          teeup.name && teeup.name.match(new RegExp(teeupFilter, "i"))
      )
    }

    return teeupsData
  }

  return {
    sharedTeeUps: sharedTeeUpsData, //list of teeups
    notifications,
    userId,
    teeupParticipants,
    teeupPeople,
    teeupInvitations,
    teeupGameplans,
    activeTeeups,
  }

}

export default useProfilePageSharedTeeUps
