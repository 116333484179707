import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { selectUserId } from "@selectors/user"
import { selectTeeupParticipant } from "@selectors/teeups"
import { selectActiveTeeup } from "@selectors/activeTeeup"
// import {
//     openProfile,
//     showActionsheet,
//     showSuggestionList,
//     routeBack,
// } from '@nav'
import { ViewProps } from "./ParticipantDetailsStyles"
import { images } from "@utils/imageUtils"
import ParticipantDetailsView from "./ParticipantDetailsView"
import ParticipantLikesTabContainer from "./ParticipantLikesTabContainer"
import ParticipantSuggestionTabContainer from "./ParticipantSuggestionTabContainer"
import { isTeeupCancelled } from "@utils/teeupUtils"
import { teeupUserGenericStatus } from "@configs/enums"
import I18n from "@i18n"

const buttonIds = {
  back: "back",
}

const prepareLeftButtons = () => {
  return [
    {
      id: buttonIds.back,
      icon: images.back,
      color: ViewProps.navButtonColor,
    },
  ]
}

const routes = [
  { key: "suggestionView", title: "Suggestions" },
  { key: "likeView", title: "Reactions" },
]

class ParticipantDetailsContainer extends Component {
  static options() {
    return {
      topBar: {
        leftButtons: prepareLeftButtons(),
        noBorder: true,
        shadowRadius: 0,
        shadowOffset: {
          height: 0,
        },
        elevation: 0,
      },
    }
  }

  navigationButtonPressed({ buttonId }) {
    // if (buttonId === 'back') {
    //     routeBack(this.props.componentId)
    // }
  }

  constructor(props) {
    super(props)
    // Navigation.events().bindComponent(this)
  }

  handleLongPressSuggestion = ({ suggestion }) => {
    // showActionsheet([
    //     {
    //         title: I18n.t('gameplans.goToSuggestionsList'),
    //         image: images.suggestionListMenu,
    //         callback: () => {
    //             showSuggestionList({
    //                 optionId: suggestion.optionId,
    //             })
    //         },
    //     },
    // ])
  }

  renderSuggestionsView = () => {
    const { userId, userInfo } = this.props
    return (
      <ParticipantSuggestionTabContainer
        userId={userId}
        userName={userInfo.username}
        onLongPressSuggestion={this.handleLongPressSuggestion}
      />
    )
  }

  renderLikeView = () => {
    const { userId, userInfo } = this.props

    return (
      <ParticipantLikesTabContainer
        userId={userId}
        userName={userInfo.username}
        onLongPressSuggestion={this.handleLongPressSuggestion}
      />
    )
  }

  routeProfile = () => {
    const { userId, isMyProfile } = this.props

    // openProfile({
    //     isMyProfile,
    //     userId,
    // })
  }

  shouldComponentUpdate(nextProps) {
    const { userId } = this.props
    return (
      Boolean(nextProps.userInfo) ||
      nextProps.teeupLastActions[userId] !== this.props.teeupLastActions[userId]
    )
  }

  render() {
    const { teeup, userId, userInfo } = this.props
    let statusText = teeupUserGenericStatus[userInfo.status]
    if (userInfo.additionalCount > 0) {
      statusText += ` +${userInfo.additionalCount}`
    }

    const sceneMapping = {
      suggestionView: this.renderSuggestionsView,
      likeView: this.renderLikeView,
    }
    return (
      <ParticipantDetailsView
        userId={userId}
        userInfo={userInfo}
        routes={routes}
        sceneMapping={sceneMapping}
        routeProfile={this.routeProfile}
        statusText={statusText}
        isTeeupDisabled={isTeeupCancelled(teeup)}
        photo={teeup.photo}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const teeup = selectActiveTeeup(state)
  const currentUserId = selectUserId(state)
  // const teeupLastActions = selectTeeupLastActions(state)
  const teeupLastActions = ""
  const userInfo = selectTeeupParticipant(ownProps.userId)(state)
  const isMyProfile = ownProps.userId === currentUserId
  return {
    userId: ownProps.userId || null,
    teeup,
    teeupLastActions,
    currentUserId,
    isMyProfile,
    userInfo,
  }
}

export default connect(mapStateToProps)(ParticipantDetailsContainer)

ParticipantDetailsContainer.propTypes = {
  userId: PropTypes.number.isRequired,
  userInfo: PropTypes.object,
  // teeupLastActions: PropTypes.object,
  teeupLastActions: PropTypes.string,
  currentUserId: PropTypes.number.isRequired,
  isMyProfile: PropTypes.bool,
  teeup: PropTypes.object,
}
