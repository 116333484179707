/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
} from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"
import I18n from "@i18n"
import { images } from "@utils/imageUtils"

class SearchBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      text: "",
    }
  }

  handleChangeText = (text) => {
    const { onChangeText } = this.props

    this.setState(
      () => ({ text }),
      () => onChangeText(this.state.text)
    )
  }

  handleClearText = () => {
    const { onChangeText } = this.props

    this.setState(
      () => ({ text: "" }),
      () => onChangeText("")
    )
  }

  render() {
    const {
      onSubmitEditing,
      onClose,
      label,
      hideClearButtonOnEmptyField,
      mode,
    } = this.props

    const { text } = this.state
    const showClearButton =
      (!!text && text.length > 0) || !hideClearButtonOnEmptyField

    return (
      <View style={styles.headerContainer}>
        <View style={styles.textInputContainer}>
          <Image source={images.searchGray} style={styles.searchIcon} />
          <TextInput
            style={[styles.textInput, styles[`textInput${mode}`]]}
            key={label}
            placeholder={label}
            underlineColorAndroid="transparent"
            onChangeText={this.handleChangeText}
            onSubmitEditing={onSubmitEditing}
            returnKeyType="go"
            value={text}
            autoFocus
          />
          {showClearButton && (
            <TouchableOpacity
              onPress={this.handleClearText}
              style={styles.clearButton}
            >
              <Image source={images.timesGray} />
            </TouchableOpacity>
          )}
        </View>
        {!!onClose && (
          <TouchableOpacity onPress={onClose} style={styles.cancelContainer}>
            <Text style={styles.cancelText}>
              {I18n.t("systemButtons.cancel")}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    )
  }
}

SearchBar.propTypes = {
  onChangeText: PropTypes.func.isRequired,
  onSubmitEditing: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  label: PropTypes.string,
  mode: PropTypes.string,
  hideClearButtonOnEmptyField: PropTypes.bool,
}

SearchBar.defaultProps = {
  label: "",
  onSubmitEditing: () => {},
  hideClearButtonOnEmptyField: false,
  mode: "Grey",
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: Units.rem(2.5),
  },
  textInputContainer: {
    flex: 1,
    position: "relative",
  },
  cancelContainer: {
    marginLeft: Units.rem(1),
  },
  searchIcon: {
    position: "absolute",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)",
    width: "16px",
    height: "16px",
  },
  clearButton: {
    position: "absolute",
    top: "50%",
    marginTop: -Units.rem(0.5),
    right: Units.rem(0.75),
    paddingLeft: Units.responsiveValue(12),
  },
  cancelText: {
    color: AppColors.brand.pink,
    ...AppFonts.bigDemibold,
    paddingVertical: Units.rem(0.5),
  },
  textInput: {
    flex: 1,
    ...AppFonts.biggerMedium,
    paddingHorizontal: Units.rem(1.875),
    paddingLeft: "38px",
    paddingTop: Units.rem(0.5625),
    paddingBottom: Units.rem(0.5625),
    borderRadius: Units.rem(0.5),
    overflow: "hidden",
  },
  textInputGrey: {
    //backgroundColor: AppColors.brand.whiteGrey,
  },
  textInputWhite: {
    backgroundColor: AppColors.brand.white,
  },
})

export default SearchBar
