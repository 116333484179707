import React, { memo } from "react"

import { images } from "@utils/imageUtils"

import { useSelector } from "react-redux"
import { selectUserInfo } from "@selectors/user"

import "./sub-header.scss"

const SuggestModalSubHeader = ({ children }) => {
  const userInfo = useSelector(selectUserInfo)

  return (
    <div className="suggest-modal__sub-header">
      <div className="suggest-modal-pinboard">
        <div className="suggest-modal-pinboard__header">
          <img src={images.ndSuggestClock} alt="clock" style={{ marginRight: 4 }} />
          @{userInfo && userInfo.nickname}
        </div>
        {children}
      </div>
    </div>
  )
}

export default memo(SuggestModalSubHeader)
