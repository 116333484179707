const actionTypes = {
  LOGIN_EMAIL: "LOGIN_EMAIL",
  SET_USER_INFO: "SET_USER_INFO",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  SET_LOGIN_ERROR: "SET_LOGIN_ERROR",
  LOG_OUT: "LOG_OUT",
  SET_RESET_PASS_ERROR: "SET_RESET_PASS_ERROR",
  SET_USER_IS_LOADING: "SET_USER_IS_LOADING",
  FETCH_TEEUPS: "FETCH_TEEUPS",
  SET_TEEUPS: "SET_TEEUPS",
  GET_TOKEN_ON_VERIFIED_INVITATION: "GET_TOKEN_ON_VERIFIED_INVITATION",

  SET_HIGHLIGHTED_MESSAGE: "SET_HIGHLIGHTED_MESSAGE",
  SET_TEXT_MESSAGE: "SET_TEXT_MESSAGE",

  SET_ACTIVE_TEEUP: "SET_ACTIVE_TEEUP",
  UPDATE_TEEUP_STATUS: "UPDATE_TEEUP_STATUS",
  UPDATE_ACTIVE_TEEUP: "UPDATE_ACTIVE_TEEUP",
  UPDATE_TEEUP: "UPDATE_TEEUP",
  UPDATE_TEEUP_INFO: "UPDATE_TEEUP_INFO",
  TEEUP_UPDATE_ARCHIVE_STATUS: "TEEUP_UPDATE_ARCHIVE_STATUS",
  GOT_TEEUP_ORGANISERS: "GOT_TEEUP_ORGANISERS",
  GOT_PARTICIPANTS: "GOT_PARTICIPANTS",

  REMOVE_SUGGESTION_NEW_STATUS: "REMOVE_SUGGESTION_NEW_STATUS",
  OPEN_CREATE_TEEUP: "OPEN_CREATE_TEEUP",
  CLOSE_CREATE_TEEUP: "CLOSE_CREATE_TEEUP",
  SET_CONTACTS: "SET_CONTACTS",
  GOT_TEEUPS_PARTS: "GOT_TEEUPS_PARTS",
  GOT_TEEUP_PARTS: "GOT_TEEUP_PARTS",
  CREATE_TEEUP: "CREATE_TEEUP",
  MOVE_TEEUP_TO_TRASH: "MOVE_TEEUP_TO_TRASH",
  SET_MESSAGES: "SET_MESSAGES",
  ADD_MESSAGE: "ADD_MESSAGE",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  CONFIRM_REACTION: "CONFIRM_REACTION",
  RETRACT_REACTION: "RETRACT_REACTION",
  ADD_REACTION: "ADD_REACTION",
  UPDATE_GAMEPLAN: "UPDATE_GAMEPLAN",
  ARCHIVE_TEEUP: "ARCHIVE_TEEUP",
  TOGGLE_TEEUP_LIST: "TOGGLE_TEEUP_LIST",
  TEEUP_UPDATE_ISARCHIVE_PROMPT_SHOW: "TEEUP_UPDATE_ISARCHIVE_PROMPT_SHOW",
  SET_SUGGESTION_REMOVED_STATUS: "SET_SUGGESTION_REMOVED_STATUS",
  REMOVE_SUGGESTION_REMOVED_STATUS: "REMOVE_SUGGESTION_REMOVED_STATUS",
  SET_TEEUP_PEOPLE_AND_PARTICIPANTS: "SET_TEEUP_PEOPLE_AND_PARTICIPANTS",

  //Requests
  GET_CONNECTION_REQUESTS: "GET_CONNECTION_REQUESTS",
  SET_CONNECTION_REQUESTS: "SET_CONNECTION_REQUESTS",
  APROVE_CONNECTION_REQUEST: "APROVE_CONNECTION_REQUEST",
  DELETE_CONNECTION_REQUEST: "DELETE_CONNECTION_REQUEST",
  SEND_CONNECTION_REQUEST: "SEND_CONNECTION_REQUEST",
  MARK_AS_READ_REQUESTS: "MARK_AS_READ_REQUESTS",
  RECEIVED_REQUEST_MESSAGE: "RECEIVED_REQUEST_MESSAGE",
  CHANGE_USER_TEEUP_STATUS: "CHANGE_USER_TEEUP_STATUS",

  START_DRAGGING_SUGGESTION: "START_DRAGGING_SUGGESTION",
  UPDATE_DRAGGING_SUGGESTION: "UPDATE_DRAGGING_SUGGESTION",
  EXPAND_CONVERSATION_CARD: "EXPAND_CONVERSATION_CARD",
  SET_ONBOARDING_WAS_SHOWN: "SET_ONBOARDING_WAS_SHOWN",

  // Interests
  GET_INTERESTS: "GET_INTERESTS",
  GOT_INTERESTS: "GOT_INTERESTS",
  GET_FOLLOWED_INTERESTS: "GET_FOLLOWED_INTERESTS",
  GOT_FOLLOWED_INTERESTS: "GOT_FOLLOWED_INTERESTS",
  FOLLOW_INTERESTS: "FOLLOW_INTERESTS",
  UNFOLLOW_INTEREST: "UNFOLLOW_INTEREST",
  UPDATE_INTEREST: "UPDATE_INTEREST",
  CREATE_INTEREST: "CREATE_INTEREST",
  ADD_NEW_INTERESTS: "ADD_NEW_INTERESTS",
  REMOVE_NEW_INTEREST: "REMOVE_NEW_INTEREST",
  CLEAR_NEW_INTERESTS: "CLEAR_NEW_INTERESTS",

  // Contacts
  GET_CONTACTS: "GET_CONTACTS",
  GOT_CONTACTS: "GOT_CONTACTS",
  GOT_BOOK_CONTACTS: "GOT_BOOK_CONTACTS",
  RESYNC_CONTACTS_BOOK: "RESYNC_CONTACTS_BOOK",
  ADD_TAGS_TO_USERS: "ADD_TAGS_TO_USERS",
  REMOVE_TAGS_FROM_USERS: "REMOVE_TAGS_FROM_USERS",
  CONTACT_CHANGE_DEFAULT_TYPE: "CONTACT_CHANGE_DEFAULT_TYPE",

  // Groups
  EDIT_VALUES: "groups/EDIT_VALUES",
  RESET_VALUES: "groups/RESET_VALUES",
  FETCH_GROUPS: "FETCH_GROUPS",
  GET_GROUPS: "GET_GROUPS",
  GET_GROUP_TEEUPS: "GET_GROUP_TEEUPS",
  SET_GROUP_TEEUPS: "SET_GROUP_TEEUPS",
  UPDATE_GROUP: "UPDATE_GROUP",
  SET_ACTIVE_GROUP: "SET_ACTIVE_GROUP",
  SELECT_ACTIVE_GROUP: "SELECT_ACTIVE_GROUP",
  FETCH_ACTIVE_GROUP: "FETCH_ACTIVE_GROUP",

  // Settings
  POST_SETTINGS: "POST_SETTINGS",
  CALENDAR_RESYNC_TEEUP_EVENTS: "CALENDAR_RESYNC_TEEUP_EVENTS",
  SET_SETTINGS: "SET_SETTINGS",
  CHANGE_TIMEZONE: "CHANGE_TIMEZONE",

  // Contact mechanisms
  GET_USER_CONTACT_MECHANISMS: "GET_USER_CONTACT_MECHANISMS",
  SET_USER_CONTACT_MECHANISMS: "SET_USER_CONTACT_MECHANISMS",
  ADD_USER_CONTACT_MECHANISM: "ADD_USER_CONTACT_MECHANISM",
  UPDATE_USER_CONTACT_MECHANISM: "UPDATE_USER_CONTACT_MECHANISM",
  DELETE_USER_CONTACT_MECHANISM: "DELETE_USER_CONTACT_MECHANISM",
  ADD_USER_CONTACT_LOCATION: "ADD_USER_CONTACT_LOCATION",
  REMOVE_USER_CONTACT_LOCATION: "REMOVE_USER_CONTACT_LOCATION",
  ADD_USER_CONTACT_URL: "ADD_USER_CONTACT_URL",
  REMOVE_USER_CONTACT_URL: "REMOVE_USER_CONTACT_URL",
  UPDATE_USER_CONTACT_LOCATIONS: "UPDATE_USER_CONTACT_LOCATIONS",
  CHANGE_LOCATION_LABEL: "CHANGE_LOCATION_LABEL",
  ADD_NEW_LOCATION_LABEL: "ADD_NEW_LOCATION_LABEL",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  SAVE_NEW_LOCATIONS: "SAVE_NEW_LOCATIONS",
  UPDATE_USER_CONTACT_MECHANISMS: "UPDATE_USER_CONTACT_MECHANISMS",
  CLEAR_USER_CONTACT_LOCATION: "CLEAR_USER_CONTACT_LOCATION",

  // Notifications
  NOTIFICATIONS_START: "NOTIFICATIONS_START",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GOT_NOTIFICATIONS: "GOT_NOTIFICATIONS",
  GET_NOTIFICATIONS_ERROR: "GET_NOTIFICATIONS_ERROR",
  READ_NOTIFICATION: "READ_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  UPDATE_NOTIFICATION_LAST_SEEN: "UPDATE_NOTIFICATION_LAST_SEEN",

  SET_SHOW_SIDE_BAR: "SET_SHOW_SIDE_BAR",

  SET_DRAFT_INVITEES: "SET_DRAFT_INVITEES",
  SET_TITLE: "SET_TITLE",
  SET_SHOW_PEOPLE_SIDE_BAR: "SET_SHOW_PEOPLE_SIDE_BAR",
  SET_SIDE_BAR_CONTENT: "SET_SIDE_BAR_CONTENT",

  GOT_CALENDAR: "GOT_CALENDAR",
  RESET_CALENDAR_STATE: "RESET_CALENDAR_STATE",
  SET_DISPLAYED_CALENDAR: "SET_DISPLAYED_CALENDAR",

  SET_REPLY_MESSAGE: "SET_REPLY_MESSAGE",
  MOVE_TEEUP_TO_ACTIVE: "MOVE_TEEUP_TO_ACTIVE",
  SET_NUDGE_PEOPLE_OPEN: "SET_NUDGE_PEOPLE_OPEN",

  TOGGLE_PARTICIPANT_DETAILS: "TOGGLE_PARTICIPANT_DETAILS",
  SELECT_DETAILS_USER: "SELECT_DETAILS_USER",

  SET_TEEUP_FILTER: "SET_TEEUP_FILTER",
  GOT_TEEUPS_STATE: "GOT_TEEUPS_STATE",
  SET_MENTIONED_MESSAGES: "SET_MENTIONED_MESSAGES",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_ACTIVE_NOTIFICATIONS_TEEUPS: "SET_ACTIVE_NOTIFICATIONS_TEEUPS",
  SET_HELP_POPUP: "SET_HELP_POPUP",

  // COMMON
  SET_ACTIVE_INVITEES_TAB: "SET_ACTIVE_INVITEES_TAB",
  SET_CHANGE_PASSWORD_ERROR: "SET_CHANGE_PASSWORD_ERROR",
  SET_PEOPLE_NUDGE: "SET_PEOPLE_NUDGE",
  SET_MODAL_SUCCESS: "SET_MODAL_SUCCESS",
  SET_HIDE_SUGGESTION_LIST_FOOTER: "SET_HIDE_SUGGESTION_LIST_FOOTER",
  SET_SHOW_SUGGESTION_LIST_FOOTER: "SET_SHOW_SUGGESTION_LIST_FOOTER",
  TOGGLE_HELP_PHONE_POPUP: "TOGGLE_HELP_PHONE_POPUP",
  IS_ONLINE_TAB_OPENED: "IS_ONLINE_TAB_OPENED",
  OPEN_SUGGESTION: "OPEN_SUGGESTION",
  CLOSE_SUGGESTION: "CLOSE_SUGGESTION",
  SET_TEEUPS_MODE: "SET_TEEUPS_MODE",
  TOGGLE_SUGGESTION_DETAILS: "TOGGLE_SUGGESTION_DETAILS",
  GET_STYLED_MESSAGES: "GET_STYLED_MESSAGES",
  GET_CURRENT_WINDOW_PARAMS: "GET_CURRENT_WINDOW_PARAMS",
  SET_PEOPLE_TO_NUDGE: "SET_PEOPLE_TO_NUDGE",
  SET_SELECTED_CALENDAR_DAY: "SET_SELECTED_CALENDAR_DAY",

  //SUGGESTING CALENDAR WEEK
  GET_SUGGESTION_WEEK: "GET_SUGGESTION_WEEK",

  //PREVIEW PAGE
  GET_PREVIEW_INFO: "GET_PREVIEW_INFO",

  //CALENDAR
  SET_BEFORE_CALENDAR_DATE: "SET_BEFORE_CALENDAR_DATE",

  GET_USER_PRIMARY_LOCATION: "GET_USER_PRIMARY_LOCATION",

  //NEW SUGGEST ONLINE
  SET_SUGGEST_ONLINE_ACTIVE_MEETING_APP: "SET_SUGGEST_ONLINE_ACTIVE_MEETING_APP",
  SET_SUGGEST_ONLINE_ACTIVE_BUTTON_ID: "SET_SUGGEST_ONLINE_ACTIVE_BUTTON_ID",
  SET_SUGGEST_ONLINE_APP_NAME: "SET_SUGGEST_ONLINE_APP_NAME",
  SET_SUGGEST_ONLINE_DETAILS: "SET_SUGGEST_ONLINE_DETAILS",
  SET_SUGGEST_IN_PERSON_MAP_CENTER: "SET_SUGGEST_IN_PERSON_MAP_CENTER",
  SET_SUGGEST_IN_PERSON_IS_LOCATION_SELECTED: "SET_SUGGEST_IN_PERSON_IS_LOCATION_SELECTED",
  SET_SUGGEST_IN_PERSON_ACTIVE_PLACE: "SET_SUGGEST_IN_PERSON_IS_LOCATION_SELECTED"
}

export default actionTypes
