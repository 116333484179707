import React from "react"
import { connect } from "react-redux"
import propTypes from "prop-types"
import { View, Text, TouchableOpacity, Image, Dimensions } from "react-native"
import { images } from "@utils/imageUtils"
import { styles } from "./styles"
import { TEEUP_LIST_MODES } from "@utils/teeupUtils"
import CreateTeeUpButton from "@components/CreateTeeUpButton"
import { selectDetailsOpen } from "@selectors/activeTeeup"
import "./index.scss"
import { useHistory } from "react-router-dom"

const MenuComponent = ({
  title,
  imageSource,
  isSelected,
  onPress,
  hasNotifications,
  isDetailsOpen,
  currentWindowParams,
}) => {
  const windowWidth = Dimensions.get("window").width
  const adaptation =
    (isDetailsOpen && windowWidth <= 1425) || currentWindowParams.width <= 1125
  return (
    <TouchableOpacity
      onPress={onPress}
      style={
        adaptation && {
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
        }
      }
    >
      <div
        className="sidemenu__wrapper"
        style={{
          backgroundColor: isSelected && "#E3E5E8",
          padding: "13px 12px",
        }}
      >
        {/* isSelected && '#e4e4e4 */}
        <View style={{ position: "relative" }}>
          <Image source={imageSource} style={styles.icon} />
          {hasNotifications && <View style={styles.badgeWrapper}></View>}
        </View>
        <View
          style={[
            styles.titleWrapperNone,
            styles.titleWrapper,
            !adaptation && { marginLeft: "10px" },
          ]}
        >
          {(isDetailsOpen && windowWidth <= 1425) ||
          currentWindowParams.width <= 1125 ? (
            <></>
          ) : (
            <Text style={styles.title}>{title}</Text>
          )}
        </View>
      </div>
      {/* <View style={isSelected && styles.borderBottom}></View> */}
    </TouchableOpacity>
  )
}

MenuComponent.propTypes = {
  title: propTypes.string.isRequired,
  isSelected: propTypes.bool.isRequired,
  imageSource: propTypes.oneOfType([propTypes.number, propTypes.string]),
  onPress: propTypes.func.isRequired,
  itemCount: propTypes.number,
}

const TeeupsSidemenuComponent = ({
  teeupsCount,
  teeupsMode,
  onMenuButtonPress,
  hasNotifications,
  isDetailsOpen,
  currentWindowParams,
}) => {
  const history = useHistory()
  return (
    <View
      style={[
        styles.sideMenuWrapper,
        currentWindowParams.width <= 1005 && { position: "absolute" },
      ]}
    >
      <div>
        <CreateTeeUpButton
          isDetailsOpen={isDetailsOpen}
          currentWindowParams={currentWindowParams}
        />
        <MenuComponent
          title={"Inbox"}
          imageSource={images.newCreateInbox}
          // imageSource={images.inbox}
          isSelected={teeupsMode === TEEUP_LIST_MODES.ACTIVE}
          // onPress={() => onMenuButtonPress(TEEUP_LIST_MODES.ACTIVE)}
          onPress={() => {
            onMenuButtonPress(TEEUP_LIST_MODES.ACTIVE)
            history.push("/teeUps")
          }}
          hasNotifications={hasNotifications}
          style={styles.iconSelected}
          isDetailsOpen={isDetailsOpen}
          currentWindowParams={currentWindowParams}
        />
        <MenuComponent
          title={"Coming Up"}
          imageSource={images.newCreateTeeupCalendar}
          // imageSource={images.calendarSmallIcon}
          isSelected={teeupsMode === TEEUP_LIST_MODES.COMING_UP}
          isDetailsOpen={isDetailsOpen}
          // onPress={() => onMenuButtonPress(TEEUP_LIST_MODES.COMING_UP)}
          onPress={() => {
            onMenuButtonPress(TEEUP_LIST_MODES.COMING_UP)
            history.push("/teeUps")
          }}
          currentWindowParams={currentWindowParams}
        />
        <div className="sidemenu__divider"></div>
        <MenuComponent
          title={"Archive"}
          imageSource={images.newCreateArchived}
          // imageSource={images.archiveOutline}
          isSelected={teeupsMode === TEEUP_LIST_MODES.ARCHIVED}
          isDetailsOpen={isDetailsOpen}
          // onPress={() => onMenuButtonPress(TEEUP_LIST_MODES.ARCHIVED)}
          onPress={() => {
            onMenuButtonPress(TEEUP_LIST_MODES.ARCHIVED)
            history.push("/teeUps")
          }}
          currentWindowParams={currentWindowParams}
        />
        <MenuComponent
          title={"Trash"}
          imageSource={images.newCreateTrash}
          // imageSource={images.trashcanOutline}
          isSelected={teeupsMode === TEEUP_LIST_MODES.SKIPPED}
          isDetailsOpen={isDetailsOpen}
          // onPress={() => onMenuButtonPress(TEEUP_LIST_MODES.SKIPPED)}
          onPress={() => {
            onMenuButtonPress(TEEUP_LIST_MODES.SKIPPED)
            history.push("/teeUps")
          }}
          currentWindowParams={currentWindowParams}
        />
      </div>
    </View>
  )
}

const mapStateToProps = (state) => ({
  isDetailsOpen: selectDetailsOpen(state),
  currentWindowParams: state.common.currentWindowParams,
})

TeeupsSidemenuComponent.propTypes = {
  // teeupsCount: propTypes.number.isRequired,
  onMenuButtonPress: propTypes.func.isRequired,
  teeupsMode: propTypes.oneOfType([
    propTypes.object,
    propTypes.string.isRequired,
  ]),
}

export default connect(mapStateToProps, null)(TeeupsSidemenuComponent)
