import React, { createContext, useContext, useReducer } from "react"

import { TopLevelReducer } from "./reducer"
import {
  TOP_LEVEL_SET_IS_REGISTRATION_IN_PROGRESS,
  TOP_LEVEL_SET_SOCIAL_REGISTRATION_TYPE,
} from "./types"

const TopLevelStateContext = createContext({})
const TopLevelDispatchContext = createContext(undefined)

const TopLevelProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TopLevelReducer, {
    isRegistrationInProgress: false,
    socialRegistrationType: null,
  })

  return (
    <TopLevelStateContext.Provider value={state}>
      <TopLevelDispatchContext.Provider value={dispatch}>
        {children}
      </TopLevelDispatchContext.Provider>
    </TopLevelStateContext.Provider>
  )
}

const useTopLevelState = () => {
  const context = useContext(TopLevelStateContext)

  if (context === undefined) {
    throw new Error("useTopLevelState must be used within a TopLevelProvider")
  }
  return context
}

const useTopLevelDispatch = () => {
  const context = useContext(TopLevelDispatchContext)

  if (context === undefined) {
    throw new Error(
      "useTopLevelDispatch must be used within a TopLevelProvider"
    )
  }
  return context
}

const useTopLevelContext = () => {
  const context = useTopLevelDispatch()
  const { isRegistrationInProgress, socialRegistrationType } =
    useTopLevelState()

  const setIsRegistrationInProgress = (inProgress) => {
    context({
      type: TOP_LEVEL_SET_IS_REGISTRATION_IN_PROGRESS,
      payload: inProgress,
    })
  }

  /**
   * @param type should be one of the values from `import { loginProviders } from "@config/enums"`
   */
  const setSocialRegistrationType = (type) => {
    context({
      type: TOP_LEVEL_SET_SOCIAL_REGISTRATION_TYPE,
      payload: type,
    })
  }

  return {
    isRegistrationInProgress,
    setIsRegistrationInProgress,
    socialRegistrationType,
    setSocialRegistrationType,
  }
}

export { useTopLevelContext, TopLevelProvider }
