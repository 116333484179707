import React, { Component } from "react"
import { connect } from "react-redux"
import { Keyboard } from "react-native"
import {
  stringToDateFormat,
  getLocaleTime,
  composeDatetime,
  adjustMinuteRange,
  addMinutes,
  getDateWithTimezone,
  isDateBeforeNow,
  fullDayDuration,
  isSameDate,
} from "@utils/dateUtils"
import {
  hasConflictingEvents,
  calendarConflictsForTimeWheel,
  selectTeeupMarkedDates,
} from "@selectors/calendar"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectUserTimezone } from "@selectors/user"
import GameplanTimePicker from "../ui/gameplanTimePicker"

import PropTypes from "prop-types"

import TimezonesView from "./timezonesView"

class TimezonesContainer extends Component {
  constructor(props) {
    super(props)

    let {
      startDate,
      endDate,
      startDateIndex,
      endDateIndex,
      isEndEnabled,
      isTimeZoneEnabled,
      checkForTodaysDate,
      isDurationActive = false,
      isTimeDurationActive = true,
    } = props

    let startDateFormatted = startDate
      ? adjustMinuteRange(startDate)
      : adjustMinuteRange()

    let endDateFormatted = endDate
      ? adjustMinuteRange(endDate)
      : adjustMinuteRange()

    let startTime = stringToDateFormat(startDateFormatted)
    // console.log("startDate 1", startDate)
    startDate = stringToDateFormat(startDateFormatted)
    // console.log("startDate 2", startDate)

    let endTime = stringToDateFormat(endDateFormatted)
    if (isTimeZoneEnabled) {
      endDate = null
    } else {
      endDate = stringToDateFormat(endDateFormatted)
    }

    this.state = {
      isEndEnabled: isEndEnabled ? true : false,
      startDate,
      startTime,
      endDate,
      endTime,
      // set initial time for single timzone
      startDateChanged: Boolean(isTimeZoneEnabled ? props.startDate : true),
      startTimeChanged: Boolean(isTimeZoneEnabled ? props.startDate : true),
      startDateIndex,
      endDateIndex,
      isTimeDurationActive,
      isDurationActive,
    }
    checkForTodaysDate(startDate)
  }

  static getDerivedStateFromProps(nextProps, state) {
    let updates = null

    if (nextProps.duration >= 0 && nextProps.endDate !== state.endDate) {
      updates = {
        duration: nextProps.duration,
        endTime: stringToDateFormat(nextProps.endDate),
        endDate: stringToDateFormat(nextProps.endDate),
        startDateChanged: true,
        startTimeChanged: true,
      }
    }
    // console.log(
    //   "getDerivedStateFromProps",
    //   nextProps?.startDate?.toString(),
    //   state.startDate,
    //   !isSameDate(nextProps.startDate, state.startDate)
    // )
    if (!isSameDate(nextProps.startDate, state.startDate)) {
      updates = {
        ...updates,
        startTime: stringToDateFormat(nextProps.startDate),
        startDate: stringToDateFormat(nextProps.startDate),
        startDateChanged: true,
        startTimeChanged: true,
      }
    }

    // console.log("getDerivedStateFromProps 2", updates?.startDate?.toString())

    if (!nextProps.duration && nextProps.duration !== 0 && state.duration) {
      updates = {
        ...updates,
        duration: nextProps.duration,
        endTime: null,
        endDate: null,
      }
    }
    if (!nextProps.startDate) {
      updates = {
        startDateChanged: false,
        startTimeChanged: false,
      }
    }

    return updates
  }

  durationToMemoize = 0

  handleDurationPick = (chosenDuration) => {
    const { startDate } = this.state
    this.onStartTimeSelected({
      startDate,
      // duration: isTimeDurationActive ? this.durationToMemoize : 0,
      duration: chosenDuration,
    })
  }

  onTimeDurationSwitch = () => {
    const { handleToggleTimeDuration, duration } = this.props
    const { startDate } = this.state
    this.setState(
      ({ isTimeDurationActive }) => ({
        isTimeDurationActive: !isTimeDurationActive,
      }),
      () => {
        const { isTimeDurationActive } = this.state
        if (!isTimeDurationActive) {
          this.durationToMemoize = duration
        }
        handleToggleTimeDuration(isTimeDurationActive)
        this.onStartTimeSelected({
          startDate,
          // duration: isTimeDurationActive ? this.durationToMemoize : 0,
          duration: 120,
        })
      }
    )
  }

  onDurationSwitch = () =>
    this.setState(({ isDurationActive }) => ({
      isDurationActive: !isDurationActive,
    }))

  onDateSelected = ({ startDate, startDateIndex, changeMethod }) => {
    const { checkForTodaysDate } = this.props
    this.setState(
      {
        startDate,
        startDateChanged: true,
        startDateIndex,
      },
      () => this.selectTime(startDate, null, changeMethod)
    )
    checkForTodaysDate(startDate)
  }

  onStartTimeSelected = ({ startDate: date, duration }) => {
    const { isDurationActive } = this.state
    let startTime = stringToDateFormat(date)
    let updates = { startTime, startTimeChanged: true }
    if (duration >= 0 && isDurationActive) {
      let endTime = addMinutes(startTime, duration)
      updates.endTime = endTime
      updates.duration = duration
    } else updates.duration = 0

    // probably error => probably fix: this.setState(updates, () => this.selectTime(date, startTime))
    this.setState(updates, () => this.selectTime(startTime, startTime))
  }

  selectTime = (startDateState, startTimeState, changeMethod) => {
    const {
      startTime,
      startDate,
      endDate,
      endTime,
      isEndEnabled,
      startDateIndex,
      endDateIndex,
      duration,
      isDurationActive,
    } = this.state

    const { type, listComponentId } = this.props

    const startDateObj = getDateWithTimezone(startDateState || startDate).utc()
    const startTimeObj = getDateWithTimezone(startTimeState || startTime)

    let composedStartDate = composeDatetime(startDateObj, startTimeObj)

    let composedEndDate =
      endDate && endTime
        ? composeDatetime(endDate, endTime)
        : duration >= 0 && isDurationActive
        ? addMinutes(startTime, duration)
        : startTime

    this.props.onDone({
      startDate: composedStartDate,
      endDate: composedEndDate,
      type,
      listComponentId,
      isEndEnabled,
      startTime: startTimeState ? startTimeState : startTime,
      startDateIndex,
      endDateIndex,
      duration,
      changeMethod,
    })
  }

  onZonesScrolled = (time) => {
    // When timezones list is scrolled, update selected time
    let startTime = time

    if (isDateBeforeNow(startTime)) {
      startTime = getDateWithTimezone().format()
    }
    this.setState({ startTime, startTimeChanged: true }, () => {
      this.props.onZonesScrolled(startTime)
    })
  }

  changeTime = () => {
    const { checkForTodaysDate, isTimeZoneEnabled, componentId, userTimezone } =
      this.props
    const { startDate, endDate, isEndEnabled } = this.state
    Keyboard.dismiss()
    // showGameplanTimePicker({
    //     onDone: this.onStartTimeSelected,
    //     listComponentId: componentId,
    //     isTimeZoneEnabled,
    //     isTimeInterval: false,
    //     isEndEnabled,
    //     startDate,
    //     endDate,
    //     type: 'time',
    //     checkForTodaysDate,
    //     timezone: userTimezone,
    // })
  }

  formatDuration = (duration) => Math.ceil(duration / 15) * 15

  changeTimeDuration = () => {
    const {
      checkForTodaysDate,
      isTimeZoneEnabled,
      componentId,
      userTimezone,
      duration,
    } = this.props
    const {
      startDate,
      endDate,
      duration: stateDuration,
      isEndEnabled,
      isDurationActive,
    } = this.state
    const formattedDuration = stateDuration || this.formatDuration(duration)
    Keyboard.dismiss()
    const props = {
      onDone: this.onStartTimeSelected,
      listComponentId: componentId,
      closeLayout: this.props.closeLayout,
      isTimeZoneEnabled,
      isTimeInterval: false,
      isEndEnabled,
      startDate,
      endDate,
      type: "timeDuration",
      checkForTodaysDate: checkForTodaysDate,
      timezone: userTimezone,
      duration: formattedDuration,
      isDurationActive,
      onDurationSwitch: this.onDurationSwitch,
    }

    this.props.showLayout(<GameplanTimePicker {...props} />)
  }

  render() {
    const {
      isTemporalCustom,
      isTimeZoneEnabled,
      type,
      showDatePicker,
      showEndDatePicker,
      showEndTimePicker,
      showTimePicker,
      timezoneWasScrolled,
      duration,
      startDate: selectedDate,
      refFunction,
      isStartDateToday,
      dateArray,
      hasConflict,
      useConflicts,
      conflicts,
      columnsAmount,
      teeupEventsByDay,
      overlappingEvents,
      isMultiTimeZone,
      handleCalendar,
      suggestion,
      formattedSuggestion,
      suggestionType,
      skip,
      onDayClick,
      fromCreate,
    } = this.props

    const {
      startDate,
      startTime,
      endDate,
      endTime,
      isEndEnabled,
      startDateChanged,
      startTimeChanged,
      startDateIndex,
      endDateIndex,
      isTimeDurationActive,
    } = this.state

    const localStartTime = getLocaleTime(startTime)
    const localEndTime = getLocaleTime(endTime)
    return (
      <TimezonesView
        isTemporalCustom={isTemporalCustom}
        participants={this.props.participants}
        suggestionType={suggestionType}
        people={this.props.people}
        suggestion={suggestion}
        formattedSuggestion={formattedSuggestion}
        startDateVal={
          startDateChanged || timezoneWasScrolled
            ? stringToDateFormat(startDate, "dddd MMM D")
            : "Select value"
        }
        startTimeVal={startTimeChanged ? localStartTime : "Select value"}
        startDate={startDate}
        startTime={startTime}
        endDateVal={
          endDate && isEndEnabled
            ? stringToDateFormat(endDate, "dddd MMM D")
            : "Optional"
        }
        endTimeVal={
          endTime &&
          duration >= 0 &&
          (localStartTime !== localEndTime || duration === fullDayDuration)
            ? ` - ${localEndTime}`
            : ""
        }
        endDate={endDate}
        endTime={endTime}
        isStartDateToday={isStartDateToday}
        duration={duration}
        isEndEnabled={isEndEnabled}
        isTimeZoneEnabled={isTimeZoneEnabled}
        refFunction={refFunction}
        onZonesScrolled={this.onZonesScrolled}
        type={type}
        showEndDatePicker={showEndDatePicker}
        showEndTimePicker={showEndTimePicker}
        showDatePicker={showDatePicker}
        showTimePicker={showTimePicker}
        onDateSelected={this.onDateSelected}
        selectedDate={selectedDate}
        onCancelPress={this.onCancelPress}
        onSelectPress={this.onSelectPress}
        dateArray={dateArray}
        startDateIndex={startDateIndex}
        endDateIndex={endDateIndex}
        changeDuration={this.props.changeDuration}
        changeTime={this.changeTime}
        changeTimeDuration={this.changeTimeDuration}
        isMultiTimeZone={isMultiTimeZone}
        hasConflict={hasConflict}
        useConflicts={useConflicts}
        conflicts={conflicts}
        columnsAmount={columnsAmount}
        teeupEventsByDay={teeupEventsByDay}
        overlappingEvents={overlappingEvents}
        isTimeDurationActive={isTimeDurationActive}
        onTimeDurationSwitch={this.onTimeDurationSwitch}
        handleCalendar={handleCalendar}
        skip={skip}
        onDayClick={onDayClick}
        fromCreate={fromCreate}
        handleDurationPick={this.handleDurationPick}
      />
    )
  }
}

TimezonesContainer.defaultProps = {
  timezoneWasScrolled: false,
}

TimezonesContainer.propTypes = {
  componentId: PropTypes.string,
  type: PropTypes.string,
  listComponentId: PropTypes.string,
  isDurationActive: PropTypes.bool,
  onDone: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isTimeZoneEnabled: PropTypes.bool,
  participants: PropTypes.array,
  people: PropTypes.object,
  isEndEnabled: PropTypes.bool,
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  closeOtherPickerIOS: PropTypes.func,
  showDatePicker: PropTypes.bool,
  showEndDatePicker: PropTypes.bool,
  showEndTimePicker: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  onZonesScrolled: PropTypes.func,
  timezoneWasScrolled: PropTypes.bool,
  duration: PropTypes.number,
  refFunction: PropTypes.func,
  checkForTodaysDate: PropTypes.func.isRequired,
  isStartDateToday: PropTypes.bool,
  dateArray: PropTypes.array,
  startDateIndex: PropTypes.number,
  endDateIndex: PropTypes.number,
  changeDuration: PropTypes.func,
  hasConflict: PropTypes.bool,
  conflicts: PropTypes.object,
  userTimezone: PropTypes.string,
  teeupEventsByDay: PropTypes.object,
  useConflicts: PropTypes.bool,
  columnsAmount: PropTypes.number,
  overlappingEvents: PropTypes.array,
  isMultiTimeZone: PropTypes.bool,
  handleToggleTimeDuration: PropTypes.func,
  handleCalendar: PropTypes.func,
  isTimeDurationActive: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
  const activeTeeupId = selectActiveTeeupId(state)
  const userTimezone = selectUserTimezone(state)
  const { suggestion, startDate } = ownProps
  let hasConflict = null
  let overlappingEvents = []
  if (
    suggestion &&
    !suggestion.isCustomTime &&
    !suggestion.isCustomDate &&
    suggestion.startDate
  ) {
    const res = hasConflictingEvents(
      suggestion.startDate,
      suggestion.endDate,
      suggestion.id,
      activeTeeupId,
      suggestion.optionId
    )(state)
    hasConflict = res.hasConflict
    overlappingEvents = res.events
  }

  const { useConflicts, conflicts, columnsAmount } =
    calendarConflictsForTimeWheel(startDate, null, 2)(state)
  const teeupEventsByDay = selectTeeupMarkedDates(state)
  return {
    overlappingEvents,
    hasConflict,
    useConflicts,
    conflicts,
    userTimezone,
    columnsAmount,
    teeupEventsByDay,
  }
}

export default connect(mapStateToProps)(TimezonesContainer)
