import React, { Component } from "react"
import { connect } from "react-redux"
import { Image, TouchableOpacity, View } from "react-native"
import PropTypes from "prop-types"
import _ from "lodash"
import { ReactSVG } from "react-svg"
import ReactTooltip from "react-tooltip"
// import Icon from 'react-native-vector-icons/FontAwesome5'

import AddInvitees from "../../../AddInvitees"
import TabInvitees from "../../../CreateTeeUp/addInviteesTabs"

import {
  selectTeeupParticipants,
  selectTeeupPeople,
  // selectTeeupLastActions,
  selectTeeupSuggestions,
  selectTeeupReactions,
  // selectTeeupInvitationHistory,
  selectPermissions,
  selectAdditionalPeopleInTeeup,
  // selectStartWhenTeeupsGameplan,
  // selectWhenWorksTeeupsGameplan,
  // selectWorksWhenSuggestions,
} from "@selectors/teeups"
import {
  selectActiveTeeupOrganizers,
  selectActiveTeeup,
  selectActiveTeeupId,
  selectActiveTeeupSettings,
} from "@selectors/activeTeeup"
import { selectUserId, selectUserInfo } from "@selectors/user"
// import {
//     routeTeeupPermissions,
//     showInviteToTeeup,
//     showParticipantPopup,
//     showAddInvitees,
//     showCritmassOverlay,
//     showMyStatusOverlay,
//     showRespondToPoll,
// } from '@nav'

import {
  addTeeupInvitees,
  gotTeeupParts,
  fetchTeeupUsersById,
  inviteManual,
} from "@actions/teeupActions"
import {
  isTeeupDisabled,
  isTeeupCancelled,
  getGoingCount,
  getJoinedCount,
  getArrivedCount,
  getOnMyWayCount,
  getMightGoCount,
  getNotGoingCount,
  getRunningLateCount,
  getBackedOutCount,
  getCritmassGoingCount,
  getAvatarSize,
  getStatusSize,
  isOrganiser,
  isOwner,
  getBestSuggestionPoll,
  getReactionsResult,
  getSelectedSuggestion,
  // getVotedUsersInPoll,
} from "@utils/teeupUtils"
import { teeupUserGenericStatus, teeupStatusKeys } from "@config/enums"
import ParticipantsCardView from "./participantsCardView"
import ParticipantRow from "./participantRow"
import { images } from "@utils/imageUtils"
import { formatZones } from "@utils/timezones"

import { filterParticipants, EMPTY_AVATAR } from "@utils/teeupUtils"
import { getUsernameOrName, formatInvitees } from "@utils/contactUtils"
import { ParticipantsStyles } from "./participantsStyles"
import { gameplanOptionStatusIds, reactionTypes } from "@config/mappings"
import ParticipantPopup from "../../../participantPopup"
import {
  toggleParticipantDetails,
  toggleDetailsUser,
} from "@actions/activeTeeupActions"
import { selectActiveInviteesTab } from "@selectors/common"
import { setActiveInviteesTab } from "@actions/commonActions"
import CooEVerse from "../../../CooEVerse"
import ViewByTeeups from "../../../ViewByTeeups"

import UserStatusModal from "@components/teeupUserResponseDialogs/userStatusModal"

const prepareSections = (participants = [], people) => {
  // Initialize empty sections
  //  1) get all section keys -> dictionary where keys are status names
  //  2) add participants to sections
  //  3) transform sections dictionary to array
  //  sort manually?
  let sectionMetas = {}

  teeupUserGenericStatus.keys.forEach((key) => {
    const title = teeupUserGenericStatus[key]
    sectionMetas[title] = {
      participants: [],
      title,
      titleKey: key,
    }
  })

  participants.forEach((participantId) => {
    let participant = people[participantId]
    let status = participant.status
      ? teeupUserGenericStatus[participant.status]
      : teeupUserGenericStatus.mightgo
    let section = sectionMetas[status]
    section && section.participants.push(participant)
  })

  let sections = []
  Object.keys(sectionMetas).forEach((key) => {
    let section = sectionMetas[key]
    section.participantsCount = section.participants.length

    if (section.participants.length > 0) {
      sections.push(section)
    }
  })

  return sections
}

class ParticipantsCardContainer extends Component {
  static collapsedHeight = 108
  static collapsedHeightWithMass = 116
  static collapsedHeightWhenWorks = 106

  static getDerivedStateFromProps(nextProps, state) {
    let updatedLastActions = _.differenceWith(
      nextProps.lastActions,
      state.lastActions,
      _.isEqual
    )
    let updates = null

    if (
      nextProps.participants !== state.participants ||
      updatedLastActions.length !== 0
    ) {
      updates = {
        participants: nextProps.participants,
        sections: prepareSections(nextProps.participants, nextProps.people),
        lastActions: nextProps.lastActions,
        formattedTimezones: formatZones(
          nextProps.participants,
          nextProps.people,
          nextProps.userInfo,
          new Date(),
          null,
          true
        ),
      }

      if (!nextProps.expanded) {
        updates.activeSections = updates.sections.map((section, index) => index)
      }
    }

    return updates
  }

  constructor(props) {
    super(props)

    const { participants, people, lastActions, userInfo } = props

    let sections = prepareSections(participants, people)

    const formattedTimezones = formatZones(
      participants,
      people,
      userInfo,
      new Date(),
      null,
      true
    )

    this.state = {
      // participants: sortedParticipants,
      participants,
      sections,
      lastActions,
      activeSections: sections.map((section, index) => index),
      showAllParticipants: false,
      avatarsAmount: 4,
      showMyStatusOverlay: false,
      formattedTimezones,
      invitees: [],
      expandedGroupIndex: -1,
      userId: null,
      currentPeople: [],
    }

    this.infoRef = React.createRef()
  }

  componentDidMount() {
    const isMultiTimezones = this.state.formattedTimezones.zones.length > 1
    this.setState({
      isMultiTimezones,
      currentPeople: this.props.people,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.activeInviteesTab !== prevProps.activeInviteesTab &&
      this.props.inviteesContent !== null
    ) {
      this.props.showAddInvitees(this.handleInvite)
    }
  }

  showAllParticipants = () => {
    this.setState({ showAllParticipants: true })
  }

  handleInvite = () => {
    const {
      showAddInvitees,
      inviteesContent,
      openOption,
      participants,
      setModalContent,
      activeInviteesTab,
      setActiveInviteesTab,
    } = this.props
    const { invitees } = this.state

    let inviteesCopy = invitees
    if (inviteesContent == null) inviteesCopy = []

    return (
      <>
        <div
          style={{ paddingLeft: 12, paddingRight: 8 }}
          className="create__top next-fullHeight"
        >
          <span className="invitees__title">Invite to TeeUp</span>
          <img
            src={images.cross}
            onClick={() => showAddInvitees(null)}
            style={{ height: 25, width: 25, cursor: "pointer" }}
          />
        </div>
        <div className="tabInvitees_wrapper">
          <TabInvitees
            index={1}
            title={"Contacts"}
            action={setActiveInviteesTab}
          />
          <TabInvitees
            index={2}
            title={"Coo-e-verse"}
            action={setActiveInviteesTab}
          />
          <TabInvitees
            index={3}
            title={"Teeups"}
            action={setActiveInviteesTab}
          />
        </div>
        {(activeInviteesTab == undefined || activeInviteesTab == 1) && (
          <AddInvitees
            setInvitees={(invitees) => this.setState({ invitees })}
            invitees={inviteesCopy}
            alreadyInvitedIds={participants}
            isTeeupInvite={true}
            handleOnInviteesDone={this.handleOnInviteesDone}
            setModalContent={setModalContent}
            fromCreateTeeup={true}
          />
        )}
        {activeInviteesTab === 2 && (
          <CooEVerse
            setInvitees={(invitees) => this.setState({ invitees })}
            handleOnInviteesDone={this.handleOnInviteesDone}
            setModalContent={setModalContent}
            fromCreateTeeup={true}
            invitees={inviteesCopy}
          />
        )}
        {activeInviteesTab === 3 && (
          <ViewByTeeups
            setInviteesProp={(invitees) => this.setState({ invitees })}
            handleOnInviteesDone={this.handleOnInviteesDone}
            fromTeeup
            fromCreateTeeup={true}
            inviteesProp={inviteesCopy}
            noHover
          />
        )}
      </>
    )
    // showAddInvitees(componentId, {
    //     isInitInvitationScreen: true,
    //     viewHeader: 'Invite to TeeUp',
    //     invitees: [],
    //     onDone: this.handleOnInviteesDone,

    // })
  }

  renderInviteButton = (expandedPeople) => {
    const { teeup, showAddInvitees } = this.props
    if (!this.canInvite()) {
      return null
    }

    return (
      <TouchableOpacity
        disabled={isTeeupCancelled(teeup)}
        onPress={() => {
          showAddInvitees(this.handleInvite)
          ReactTooltip.hide(this.infoRef.current)
        }}
      >
        <div
          className="overviewheader_status_settings_icon"
          style={
            expandedPeople && {
              position: "absolute",
              right: "40px",
              top: "-35px",
              height: "24px",
              width: "24px",
            }
          }
          ref={this.infoRef}
          data-tip={"Invite People"}
          data-for="information-tooltip"
          onMouseOver={(e) => {
            e.stopPropagation()
            ReactTooltip.show(this.infoRef.current)
          }}
          onMouseLeave={(e) => {
            e.stopPropagation()
            ReactTooltip.hide(this.infoRef.current)
          }}
        >
          <ReactSVG src={images.invite} />
        </div>
      </TouchableOpacity>
    )
  }

  renderAvatarItem =
    (shouldRenderExpanded = false) =>
    ({ item, index }) => {
      const {
        expanded,
        people,
        toggleExpansion,
        organisers,
        teeup,
        whenWorksGameplan,
        showDetails,
        setActiveParticipant,
        linkParticipantPopup,
      } = this.props
      const { currentPeople } = this.state
      const { expandedGroupIndex, participants } = this.state
      const isExpanded = shouldRenderExpanded
      const { itemId, hiddenCount } = item
      const size = getAvatarSize(isExpanded)
      let person = currentPeople[itemId]
        ? currentPeople[itemId]
        : people[itemId]
        ? people[itemId]
        : {}
      const isTeeupOrganiser = isOrganiser(person.id, organisers)
      const isTeeupOwner = isOwner(person.id, teeup)

      if (itemId === EMPTY_AVATAR) {
        return <View style={ParticipantsStyles.emptyAvatar} />
      }

      const isPolling =
        !_.isEmpty(whenWorksGameplan) &&
        whenWorksGameplan.statusId === gameplanOptionStatusIds.POLLING

      const statusSize = getStatusSize(isExpanded, isPolling)

      let vote
      if (isPolling) {
        let bestChoice = getBestSuggestionPoll({
          pollsSuggestions: whenWorksGameplan.suggestions,
          people,
        }).time[0]

        // bestChoice?.reactions.forEach((reaction) => {
        //     if (reaction.reactedBy.includes(person.id)) {
        //         vote =
        //             vote === reactionTypes.checkMark
        //                 ? vote
        //                 : `${reaction.reactionId}`
        //     }
        // })
      }
      const isActivityStreamGroupExpanded = expandedGroupIndex === 0

      return (
        <ParticipantRow
          additionalCount={person.additionalCount}
          avatar={person.avatar}
          status={person.status}
          isPolling={isPolling}
          reaction={vote}
          fullName={person.name ? person.name : ""}
          name={getUsernameOrName(person)}
          isOrganiser={isTeeupOrganiser}
          isOwner={isTeeupOwner}
          expanded={isExpanded}
          toggleExpansion={toggleExpansion}
          userId={person.id}
          index={index}
          disabled={isTeeupCancelled(teeup)}
          avatarSize={size}
          numberOfLinesForName={1}
          isLastItem={index === participants.length - 1}
          statusIconSize={statusSize}
          hiddenCount={hiddenCount}
          isActivityStreamGroupExpanded={isActivityStreamGroupExpanded}
          onPress={() => {
            if (_.isEmpty(person)) {
              return
            }
            linkParticipantPopup(person.id)
            // showDetails();
          }}
          lastTeeupActivity={person.lastTeeupActivity}
          lastTeeupActivityCreatedAt={person.lastTeeupActivityCreatedAt}
        />
      )
    }

  // linkParticipantPopup = (userId) => {
  //     const { userInfo, toggleParticipantDetails, toggleDetailsUser } = this.props

  //     if (+userId === userInfo.id) {

  //         toggleParticipantDetails(true);
  //         toggleDetailsUser(+userId);

  //         return;
  //     };

  //     this.setState({userId})
  // }

  handleOnInviteesDone = async () => {
    const { invitees } = this.state
    const {
      participants,
      activeTeeupId,
      closeOption,
      gotTeeupParts,
      showAddInvitees,
    } = this.props
    const formattedInvitees = formatInvitees(invitees)

    console.log("here 1")
    await addTeeupInvitees(activeTeeupId, formattedInvitees)
    inviteManual(activeTeeupId, formattedInvitees)

    const teeupUsers = await fetchTeeupUsersById(activeTeeupId)
    gotTeeupParts([teeupUsers])
    showAddInvitees(null)
    // closeOption();
  }

  canInvite = () => {
    const { permissions, teeup } = this.props

    return (
      teeup.status !== teeupStatusKeys.ended && permissions.allowInviteOthers
    )
  }

  handlePermissionsClick = () => {
    this.props.openTeeupPermision()
  }

  handleCritmassClick = () => {
    // showCritmassOverlay()
  }

  handleMyStatusPress = () => {
    const { whenWorksGameplan, isAlreadyVotedOnOrganizerPoll, teeup } =
      this.props
    const { showMyStatusOverlay } = this.state

    if (showMyStatusOverlay) return

    const isWhenWorksEnabled =
      !_.isEmpty(whenWorksGameplan) &&
      whenWorksGameplan.statusId === gameplanOptionStatusIds.POLLING

    if (isWhenWorksEnabled && !isAlreadyVotedOnOrganizerPoll) {
      this.handleRespondToPoll()
    } else {
      this.setState({ showMyStatusOverlay: true })
      // showMyStatusOverlay({ teeup })
    }
  }

  handleRespondToPoll = () => {
    // const { userId, people, suggestions } = this.props
    // showRespondToPoll({
    //     userId: userId,
    //     vip: people[userId].isVip,
    //     suggestions: getWhenWorksSuggestions(
    //         getTimeSortedSuggestions(getSuggestionsToDisplay(suggestions))
    //     ),
    //     addReaction: this.addReactionsByUser,
    //     reactions: getUserReactions(suggestions, userId),
    //     showStatusToast: true,
    // })
  }

  addReactionsByUser = (reactions) => {
    const { addReaction, refreshTeeup, activeTeeupId, userId } = this.props
    reactions.forEach((r) => {
      r.values.forEach((suggestionId) => {
        if (suggestionId) {
          addReaction({
            reactionId: r.type,
            suggestionId,
            teeupId: activeTeeupId,
            userId: userId,
          })
        }
      })
    })

    setTimeout(() => {
      refreshTeeup(activeTeeupId)
    }, 300)
  }

  addEmptyViews(participants = []) {
    if (participants.length % 4 === 0) {
      return participants
    }
    for (let i = 0; i < 3; i++) {
      if (participants.length % 4 !== 0) {
        participants.push({ itemId: EMPTY_AVATAR })
      }
    }
    return participants
  }

  calculateCollapsedAvatarAmount = ({ nativeEvent }) => {
    const width = 354
    const avatarSize = getAvatarSize(false)
    // const avatarsAmount = Math.floor((width - 6) / (avatarSize + 6))
    const avatarsAmount = 4
    this.setState({ avatarsAmount })
  }

  setExpandedGroupIndex = (index) => {
    this.setState({ expandedGroupIndex: index })
  }

  handleSetPeopleStatus = (changedPeople) => {
    this.setState({ currentPeople: changedPeople })
  }

  renderStautsOverlay = (dropdownRef) => {
    const { people } = this.props
    let position = { x: 0, y: 0 }

    if (dropdownRef.current) {
      position = dropdownRef.current.getBoundingClientRect()
    }

    return this.state.showMyStatusOverlay ? (
      <UserStatusModal
        handleSetPeopleStatus={this.handleSetPeopleStatus}
        modalPosition={position}
        teeup={this.props.teeup}
        onClose={() => this.setState({ showMyStatusOverlay: false })}
      />
    ) : null
  }

  render() {
    let {
      toggleExpansion,
      expanded,
      people,
      teeup,
      settings,
      additionalPeopleInTeeup,
      startsWhenGameplan,
      whenWorksGameplan,
      changeParticipantsFilter,
      reactions,
      userInfo,
      participantFilter,
      showFilterOptions,
      expandCard,
      renderDropDown,
      isMenuButtonModalOpen,

      currentUser,
    } = this.props

    const { expandedGroupIndex, userId, currentPeople } = this.state

    let { userStatus } = this.props.teeup
    let userParticipant = people[userInfo.id]

    // if (userParticipant) {
    //     userStatus = userParticipant.status
    // }

    const { participants, avatarsAmount, showMyStatusOverlay } = this.state
    let participantsData = [1]
    if (participants && participants.length > 0 && avatarsAmount) {
      // Make sure there's always at least 1 participant - for dynamic height calculating
      participantsData = participants
    }

    // if (expanded) {
    //     console.log(participantFilter)
    //     participantsData = [
    //         ...filterParticipants(participantsData, people, participantFilter)
    //     ]
    // }

    participantsData = participantsData.map((item) => ({ itemId: item }))
    if (participantsData && avatarsAmount) {
      if (!expanded) {
        if (participantsData.length > avatarsAmount) {
          const lastItem = {
            ...participantsData[avatarsAmount - 1],
            hiddenCount: participantsData.length - (avatarsAmount - 1),
          }
          participantsData = [
            ...participantsData.slice(0, avatarsAmount - 1),
            lastItem,
          ]
        }
      }
    }

    if (expanded) {
      participantsData = [...this.addEmptyViews(participantsData)]
    }

    const goingCount = getGoingCount(participants, people)
    const arrivedCount = getArrivedCount(participants, people)
    const onMyWayCount = getOnMyWayCount(participants, people)
    const mightGoCount = getMightGoCount(participants, people)
    const notGoingCount = getNotGoingCount(participants, people)
    const runningLateCount = getRunningLateCount(participants, people)
    const backedOutCount = getBackedOutCount(participants, people)
    const joinedCount = getJoinedCount(participants, people)
    const isCriticalMassEnabled = settings.allowAutoScheduled
    const criticalMass = settings.criticalMassAmount
    const critmassGoingCount = getCritmassGoingCount(participants, people)
    const isStartsWhenEnabled = !_.isEmpty(startsWhenGameplan)
    const isWhenWorksEnabled =
      !_.isEmpty(whenWorksGameplan) &&
      whenWorksGameplan.statusId === gameplanOptionStatusIds.POLLING

    const currentSuggestion = getSelectedSuggestion(
      whenWorksGameplan.suggestions,
      [whenWorksGameplan]
    )
    const participantsReactions = getReactionsResult(
      whenWorksGameplan.statusId,
      people,
      whenWorksGameplan.suggestions,
      [whenWorksGameplan],
      reactions[currentSuggestion.id]
    )
    const showGoingCount =
      whenWorksGameplan.statusId !== gameplanOptionStatusIds.POLLING
    const { additionalCount: myAdditionalCount } = people[userInfo.id] || {}

    return (
      <>
        <ParticipantsCardView
          participantsReactions={participantsReactions}
          extraData={this.props}
          participants={participants}
          participantsData={participantsData}
          reactions={getBestSuggestionPoll({
            pollsSuggestions: whenWorksGameplan.suggestions,
            people,
          })}
          participantsCount={participants.length + additionalPeopleInTeeup}
          goingCount={goingCount}
          arrivedCount={arrivedCount}
          onMyWayCount={onMyWayCount}
          mightGoCount={mightGoCount}
          notGoingCount={notGoingCount}
          runningLateCount={runningLateCount}
          backedOutCount={backedOutCount}
          joinedCount={joinedCount}
          teeupStatus={teeup.status}
          showGoingCount={showGoingCount}
          critmassGoingCount={critmassGoingCount}
          isCriticalMassEnabled={isCriticalMassEnabled}
          criticalMass={criticalMass}
          renderAvatarItem={this.renderAvatarItem}
          renderInviteButton={this.renderInviteButton}
          toggleExpansion={toggleExpansion}
          isTeeupDisabled={isTeeupDisabled(teeup)}
          isExpanded={expanded}
          onInvite={this.handleInvite}
          startsWhenGameplan={startsWhenGameplan}
          participantFilter={participantFilter}
          changeParticipantsFilter={changeParticipantsFilter}
          isShowAll={this.state.showAllParticipants}
          onPermissionsClick={this.handlePermissionsClick}
          showAllParticipants={this.showAllParticipants}
          handleCritmassClick={this.handleCritmassClick}
          isStartsWhenEnabled={isStartsWhenEnabled}
          isWhenWorksEnabled={isWhenWorksEnabled}
          userInfo={userInfo}
          onMyStatusPress={this.handleMyStatusPress}
          userStatus={userStatus}
          myAdditionalCount={myAdditionalCount}
          calculateCollapsedAvatarAmount={this.calculateCollapsedAvatarAmount}
          showFilterOptions={showFilterOptions}
          expandCard={expandCard}
          renderStautsOverlay={this.renderStautsOverlay}
          renderDropDown={renderDropDown}
          isMenuButtonModalOpen={isMenuButtonModalOpen}
          avatarsAmount={this.state.avatarsAmount}
          people={people}
          teeup={teeup}
          formattedTimezones={this.state.formattedTimezones}
          isMultiTimezones={this.state.isMultiTimezones}
          setExpandedGroupIndex={this.setExpandedGroupIndex}
          expandedGroupIndex={expandedGroupIndex}
          linkParticipantDetail={this.props.linkParticipantPopup}
          currentUser={currentUser}
        />
      </>
    )
  }
}

ParticipantsCardContainer.propTypes = {
  componentId: PropTypes.string,
  userId: PropTypes.number,
  userInfo: PropTypes.object,
  participants: PropTypes.array,
  people: PropTypes.object,
  lastActions: PropTypes.object,
  teeup: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  // toggleExpansion: PropTypes.func.isRequired,
  organisers: PropTypes.array,
  reactions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  suggestions: PropTypes.shape({}),
  invitationHistory: PropTypes.array,
  permissions: PropTypes.shape({
    allowInviteOthers: PropTypes.bool,
  }).isRequired,
  activeTeeupId: PropTypes.number,
  settings: PropTypes.object,
  additionalPeopleInTeeup: PropTypes.number,
  startsWhenGameplan: PropTypes.object,
  whenWorksGameplan: PropTypes.object,
  changeParticipantsFilter: PropTypes.func,
  userStatus: PropTypes.string,
  isAlreadyVotedOnOrganizerPoll: PropTypes.bool,
  addReaction: PropTypes.func,
  refreshTeeup: PropTypes.func,
}

ParticipantsCardContainer.defaultProps = {
  people: {},
  participants: [],
  lastActions: {},
  organisers: [],
  invitationHistory: [],
  teeup: {},
  settings: {},
}

const mapStateToProps = (state) => {
  const userId = selectUserId(state)
  const userInfo = selectUserInfo(state)
  const participants = selectTeeupParticipants(state)
  const people = selectTeeupPeople(state)
  // const lastActions = selectTeeupLastActions(state)
  const organisers = selectActiveTeeupOrganizers(state)
  const teeup = selectActiveTeeup(state)
  const suggestions = selectTeeupSuggestions(state)
  const reactions = selectTeeupReactions(state)
  // const invitationHistory = selectTeeupInvitationHistory(state)
  const permissions = selectPermissions(state)
  const activeTeeupId = selectActiveTeeupId(state)
  const settings = selectActiveTeeupSettings(state)
  const additionalPeopleInTeeup = selectAdditionalPeopleInTeeup(state)
  const activeInviteesTab = selectActiveInviteesTab(state)
  // const startsWhenGameplan = selectStartWhenTeeupsGameplan(state)
  // const whenWorksGameplan = selectWhenWorksTeeupsGameplan(state)
  // const pollsSuggestions = selectWorksWhenSuggestions(state)
  const pollsSuggestions = []
  const notVipUsersIds = Object.keys(people).filter((key) => !people[key].isVip)
  const vipUsersIds = Object.keys(people).filter((key) => people[key].isVip)

  let isAlreadyVotedOnOrganizerPoll = false
  let totalUsersVoted = 0
  let totalVipUsersVoted = 0
  // for (let poll of pollsSuggestions) {
  //     const usersVoted = getVotedUsersInPoll(poll, notVipUsersIds)
  //     const vipUsersVoted = getVotedUsersInPoll(poll, vipUsersIds)
  //     if (totalUsersVoted < usersVoted) totalUsersVoted = usersVoted
  //     if (totalVipUsersVoted < vipUsersVoted)
  //         totalVipUsersVoted = vipUsersVoted
  //     if (
  //         !isAlreadyVotedOnOrganizerPoll &&
  //         !!organisers.length &&
  //         _.find(organisers, (org) => org.id === poll.createdBy)
  //     ) {
  //         _.forEach(poll.reactions, (reaction) => {
  //             if (_.find(reaction?.reactedBy, (voted) => voted === userId)) {
  //                 isAlreadyVotedOnOrganizerPoll = true
  //             }
  //         })
  //     }
  // }

  const currentUser = people[userId] || {}

  return {
    userId,
    participants,
    people,
    lastActions: {},
    organisers,
    teeup,
    suggestions,
    reactions,
    invitationHistory: [],
    permissions,
    activeTeeupId,
    settings,
    additionalPeopleInTeeup,
    startsWhenGameplan: {},
    whenWorksGameplan: {},
    userInfo,
    isAlreadyVotedOnOrganizerPoll,
    activeInviteesTab,
    currentUser,
  }
}

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//         {
//             ...teeupActions,
//         },
//         dispatch
// )
export default connect(mapStateToProps, {
  gotTeeupParts,
  toggleParticipantDetails,
  toggleDetailsUser,
  setActiveInviteesTab,
})(ParticipantsCardContainer)
