import React from "react"
import propTypes from "prop-types"
import { initialTeeup } from "@config/enums"

import TeeupsSidemenuComponent from "./TeeupsSidemenuComponent"

class TeeupsSidemenuContainer extends React.Component {
  handleClickMenuButton = (teeupsMode) => {
    this.props.setTeeupActive(initialTeeup)
    this.props.updateTeeupsMode(teeupsMode)
  }

  render() {
    const { teeupsCount, teeupsMode, hasNotifications } = this.props
    return (
      <TeeupsSidemenuComponent
        teeupsMode={teeupsMode}
        teeupsCount={teeupsCount}
        onMenuButtonPress={this.handleClickMenuButton}
        hasNotifications={hasNotifications}
      />
    )
  }
}

TeeupsSidemenuContainer.propTypes = {
  updateTeeupsMode: propTypes.func.isRequired,
  // teeupsCount: propTypes.number.isRequired,
  teeupsMode: propTypes.oneOfType([
    propTypes.object,
    propTypes.string.isRequired,
  ]),
}

export default TeeupsSidemenuContainer
