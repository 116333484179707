import React from "react"
import PropTypes from "prop-types"

import { PART_OF_THE_DAY } from "@utils/timezones"
import { images } from "@utils/imageUtils"
import {
  ZoneStyles as styles,
  CALENDAR_BELT_HEIGHT,
  stylesContainer,
  stylesItem,
  disabledColor,
  disabledOverlay,
} from "./timezonesStyles"
import { View, Text, Image, TouchableOpacity } from "react-native"

const ZoneHour = ({
  hourElement,
  width,
  height,
  isFullTimezone,
  isMyTZ,
  isDisabled,
  disabledWidth,
  isToday,
  handleCalendar,
  hasConflict,
  hourRef,
  hourIndex,
  nowIndex
}) => {
  const { line1, line2, type, showIcon } = hourElement
  return (
    <View
      style={[
        { width },
        !!height && {
          height: isMyTZ ? 2 * height + CALENDAR_BELT_HEIGHT : height,
        },
        !isFullTimezone && { marginLeft: -width / 2 },
      ]}
      ref={(nowIndex === hourIndex && hourIndex > 0) ? hourRef : null} 
    >
      <View
        style={[
          styles.itemContainer,
          stylesContainer["itemContainer" + type](isDisabled && !disabledWidth),
        ]}
      >
        {!!showIcon && (
          <View style={styles.daytimeIcon}>
            <Image
              style={
                (isDisabled || disabledWidth) &&
                disabledColor(type === PART_OF_THE_DAY.Night)
              }
              source={
                showIcon === PART_OF_THE_DAY.Night
                  ? images.moonIcon
                  : images.sunIcon
              }
            />
          </View>
        )}
        {isToday && !!disabledWidth && (
          <View
            style={[
              disabledOverlay(disabledWidth, width, isFullTimezone),
              stylesContainer["itemContainer" + type](true),
            ]}
          />
        )}
        {/* {(nowIndex === hourIndex && hourIndex > 0) && console.log('yeess  */}
        <Text
          style={[
            styles.itemTitle,
            stylesItem["itemText" + type](
              isDisabled ||
                (!!disabledWidth && width - disabledWidth > width / 3)
            ),
          ]}
        >
          {line1}
        </Text>
        <Text
          style={[
            styles.itemSubtitle,
            stylesItem["itemText" + type](
              isDisabled ||
                (!!disabledWidth && width - disabledWidth > width / 3)
            ),
          ]}
        >
          {line2}
        </Text>
      </View>
      {!!height && isMyTZ && (
        <>
          <TouchableOpacity
            style={styles.calendarBeltItem}
            activeOpacity={1}
            onPress={() => {
              handleCalendar({ hasConflict, suggestion: null })
            }}
          >
            <View />
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.itemContainer, styles.itemContainerConflictsBg]}
            activeOpacity={1}
            onPress={() => {
              handleCalendar({ hasConflict, suggestion: null })
            }}
          >
            <View />
          </TouchableOpacity>
        </>
      )}
    </View>
  )
}

ZoneHour.propTypes = {
  hourElement: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  isFullTimezone: PropTypes.bool,
  isMyTZ: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isToday: PropTypes.bool,
  disabledWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  hasConflict: PropTypes.bool,
  handleCalendar: PropTypes.func,
}

export default ZoneHour
