import React from "react"
import { TouchableOpacity } from "react-native"
import PropTypes from "prop-types"

import { images, ImageWithDisabled } from "@utils/imageUtils"

import { Units } from "@theme"
import styles from "./statusMessageStyles"

const ExpandingButton = ({
  onPress,
  expanded,
  isTeeupCancelled,
  buttonStyle,
}) => (
  <TouchableOpacity
    onPress={onPress}
    style={[styles.expandingBtn, buttonStyle]}
    hitSlop={Units.hitSlop()}
  >
    {expanded
      ? ImageWithDisabled(
          images.expandedMessage2,
          styles.buttonImg,
          {
            style: styles.expandedButton,
            resizeMode: "contain",
          },
          isTeeupCancelled
        )
      : ImageWithDisabled(
          images.collapsedMessage2,
          styles.buttonImg,
          {
            style: styles.collapsedButton,
            resizeMode: "contain",
          },
          isTeeupCancelled
        )}
  </TouchableOpacity>
)

ExpandingButton.propTypes = {
  expanded: PropTypes.bool,
  onPress: PropTypes.func,
  isTeeupCancelled: PropTypes.bool,
  buttonStyle: PropTypes.shape({}),
}

export default ExpandingButton
