import React, { Component } from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"

import { StartsWhenBarStyles } from "./participantsStyles"

class StartsWhenBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      width: 0,
    }
  }

  onLayout = ({
    nativeEvent: {
      layout: { width },
    },
  }) => {
    this.setState({
      width,
    })
  }

  render() {
    const { width } = this.state
    const { startsWhenGameplan } = this.props
    const { peopleCount = 1, currentPeopleCount = 0 } = startsWhenGameplan
    const isCompleted = peopleCount - currentPeopleCount <= 0 ? true : false
    const progressActiveBarWidth = isCompleted
      ? 0
      : width - width * (currentPeopleCount / peopleCount)

    return (
      <View style={StartsWhenBarStyles.container}>
        <View style={StartsWhenBarStyles.progressBar} onLayout={this.onLayout}>
          <View
            style={StartsWhenBarStyles.activeProgressBar(
              progressActiveBarWidth
            )}
          />
        </View>

        {!currentPeopleCount || currentPeopleCount === 0 ? (
          <Text style={StartsWhenBarStyles.bottomText}>
            Awaiting responses...
          </Text>
        ) : (
          <View style={StartsWhenBarStyles.bottomContainer}>
            <Text style={StartsWhenBarStyles.bottomText}>
              StartsWhen {peopleCount} people can Go
            </Text>
            <Text style={StartsWhenBarStyles.bottomText}>
              {isCompleted
                ? "Ready to go!"
                : `${peopleCount - currentPeopleCount} more needed!`}
            </Text>
          </View>
        )}
      </View>
    )
  }
}

StartsWhenBar.propTypes = {
  startsWhenGameplan: PropTypes.object.isRequired,
}

export default StartsWhenBar
