import { useMemo, useCallback } from "react"
import { useSelector } from "react-redux"

import { selectTeeupReactionsBySuggestionId } from "@selectors/teeups"
import { useTeeUpPage } from "./index"
import { addReaction } from "@middlewares/backendMiddleware"

export const VOTE_OPTIONS = {
  downVote: "downVote",
  upVote: "upVote",
}

export const ReactionType = {
  LOVE: 1,
  UPVOTE: 2,
  DOWNVOTE: 3,
  NEUTRAL: 4,
  NOT_VOTE: 5,
  X: 6,
  QUESTION: 7,
  CHECK_MARK: 8,
}

const useReactions = (suggestionId) => {
  const { user } = useTeeUpPage()
  const reactions = useSelector(
    selectTeeupReactionsBySuggestionId(suggestionId)
  )

  const upVote = useMemo(
    () =>
      reactions.find((reaction) => reaction.reactionId === ReactionType.UPVOTE),
    [reactions]
  )
  const downVote = useMemo(
    () =>
      reactions.find(
        (reaction) => reaction.reactionId === ReactionType.DOWNVOTE
      ),
    [reactions]
  )

  const userVote = useMemo(() => {
    if (upVote?.reactedBy.find((userId) => userId === user.id)) {
      return VOTE_OPTIONS.upVote
    }

    if (downVote?.reactedBy?.find((userId) => userId === user.id)) {
      return VOTE_OPTIONS.downVote
    }

    return null
  }, [user, upVote, downVote])

  const onUpVote = useCallback(() => {
    addReaction({
      reactionId: ReactionType.UPVOTE,
      suggestionId,
    })
  }, [suggestionId])

  const onDownVote = useCallback(() => {
    addReaction({
      reactionId: ReactionType.DOWNVOTE,
      suggestionId,
    })
  }, [suggestionId])

  return { reactions, upVote, downVote, userVote, onDownVote, onUpVote }
}

export default useReactions
