import React, { useEffect, useState } from "react";

import TopBarManageParticipantsItem from "./TopBarManageParticipantsItem";

import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext";

import './index.scss'

const TopBarManageParticipants = ({ close }) => {
  const { people, participants, teeup } = useTeeUpPage()

  return (
    <div
      className="manage-participants__wrapper"
      onClick={() => close()}
    >
      <div
        className="manage-participants"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="manage-participants__title">Manage Participants</div>
        <div
          className="manage-participants__cross"
          onClick={() => close()}
        >
          <div className="manage-participants__cross--icon"></div>
        </div>
        <div className="manage-participants__content">
          {teeup && teeup.organisers.map((item) => (
            <TopBarManageParticipantsItem
              data={item}
              teeup={teeup}
              key={item.id} 
            />
          ))}
          {participants && participants.map((id) => (
            !teeup.organisers.some(user => user.id === id) && (
              <TopBarManageParticipantsItem
                data={people[id]}
                teeup={teeup}
                key={id}
              />
          )))}
        </div>
      </div>
    </div>
  )
}

export default TopBarManageParticipants;
