import { isNil, toPairs } from "lodash"

export const getQueryString = (params) => {
  // convert object to array of pairs - {paramName: paramValue} [[paramName, paramValue]]
  return (
    toPairs(params)
      .map(
        // check that param value is defined
        (pair) =>
          !isNil(pair[1])
            ? pair
                // encode strings
                .map(encodeURI)
                .join("=")
            : null
      )
      // filter empty parameters
      .filter((param) => !isNil(param))
      // join multiple parameters
      .join("&")
  )
}
