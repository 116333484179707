import { StyleSheet } from "react-native"
import * as defaultStyle from "../style"

const STYLESHEET_ID = "stylesheet.calendar-list.main"

export default function getStyle(theme = {}) {
  const appStyle = { ...defaultStyle, ...theme }
  return StyleSheet.create({
    container: {
      backgroundColor: appStyle.calendarBackground,
    },
    placeholder: {
      backgroundColor: appStyle.calendarBackground,
      alignItems: "center",
      justifyContent: "center",
    },
    placeholderText: {
      fontSize: 30,
      fontWeight: "200",
      color: appStyle.dayTextColor,
    },
    calendar: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    staticHeader: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      backgroundColor: appStyle.calendarBackground,
      paddingLeft: 0,
      paddingRight: 0,
    },
    ...(theme[STYLESHEET_ID] || {}),
  })
}
