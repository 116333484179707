import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"

export const styles = StyleSheet.create({
  container: {},
  splitter: {
    height: Units.responsiveValue(20),
    //backgroundColor: AppColors.brand.lightGrey,
  },
  ask: {
    fontFamily: "greycliff-cf-medium",
    fontSize: "13px",
    letterSpacing: "-0.17px",
    color: "#9d9d9d",
    marginTop: "10px",
    marginBottom: "10px",
  },
  iconImage: {
    width: "14px",
    height: "14px",
  },
  header: {
    //backgroundColor: AppColors.brand.lightGrey,
    color: AppColors.brand.darkGrey2,
    paddingTop: Units.responsiveValue(22),
    paddingBottom: Units.responsiveValue(8),
    ...AppFonts.biggerMedium,
    paddingHorizontal: Units.responsiveValue(16),
    paddingLeft: 0,
  },
  headerBold: {
    ...AppFonts.biggerBold,
    color: AppColors.brand.black2,
  },
})
