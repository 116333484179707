import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units, AppSizes } from "@theme/"

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    paddingTop: AppSizes.statusBarHeight,
    backgroundColor: AppColors.brand.black,
  },
  fakeCardTop: {
    marginTop: Units.rem(0.375),
    marginHorizontal: Units.rem(1),
    borderTopLeftRadius: Units.rem(0.5),
    borderTopRightRadius: Units.rem(0.5),
    height: Units.rem(0.5),
  },
  overlay: {
    flex: 1,
    backgroundColor: AppColors.brand.black30,
  },
  headerWithButtons: {
    backgroundColor: AppColors.brand.white,
    // borderTopLeftRadius: Units.rem(0.5),
    // borderTopRightRadius: Units.rem(0.5),
    height: Units.rem(3.5),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Units.rem(1),
  },
  headerTextContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontSize: AppFonts.sizes.headerSmall,
    fontFamily: AppFonts.family.demibold,
    color: AppColors.brand.black,
  },
  button: {
    zIndex: 1,
  },
  buttonText: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.pink,
  },
  inactiveButtonStyle: {
    color: AppColors.brand.warmGrey2,
  },
  inviteesContainer: {
    backgroundColor: AppColors.brand.white,
    paddingTop: Units.rem(0.5625),
    paddingBottom: Units.rem(0.75),
  },
  inviteesHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inviteesCount: {
    paddingHorizontal: Units.rem(1),
    paddingBottom: Units.rem(0.6875),
    color: AppColors.brand.blackTwo,
    ...AppFonts.mediumDemibold,
  },
  inviteesEdit: {
    paddingHorizontal: Units.rem(1),
    paddingBottom: Units.rem(0.6875),
    color: AppColors.brand.pink,
    ...AppFonts.mediumDemibold,
  },
  inviteesFlatList: {
    paddingTop: Units.rem(0.8125),
    paddingBottom: Units.rem(0.625),
    paddingLeft: Units.rem(1),
  },
  body: {
    flex: 1,
    paddingTop: Units.rem(0.75),
    // backgroundColor: AppColors.brand.lightGrey,
  },
  bodyText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.warmGrey2,
  },
  textInputWrapper: {
    backgroundColor: AppColors.brand.white,
    padding: Units.rem(1),
    // borderColor: AppColors.brand.lightGrey,
    borderTopWidth: 1,
  },
  textInput: {
    ...AppFonts.biggerMedium,
    minHeight: 20,
    paddingTop: 0,
  },
  rowItem: {
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: Units.rem(1),
  },
  chevron: {
    tintColor: AppColors.brand.warmGrey2,
    height: Units.rem(0.75),
  },
})

export default styles
