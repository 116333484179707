import React from "react"
import {
  View,
  Text,
  Image,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
} from "react-native"
import PropTypes from "prop-types"

import { AppColors, AppFonts, Units } from "@theme/"

const styles = StyleSheet.create({
  selectedTagsContainer: {
    padding: Units.responsiveValue(16),
    backgroundColor: AppColors.brand.white,
  },
  selectedTagsTitle: {
    ...AppFonts.mediumDemibold,
    lineHeight: AppFonts.sizes.medium,
    color: AppColors.brand.warmGrey2,
    marginBottom: Units.responsiveValue(10),
  },
  selectedTag: {
    flexDirection: "row",
    alignItems: "center",
    padding: Units.responsiveValue(8),
    borderWidth: 1,
    borderRadius: Units.responsiveValue(4),
    borderColor: AppColors.brand.blueGrey,
    marginRight: Units.responsiveValue(8),
  },
  selectedTagIcon: {
    width: Units.responsiveValue(14),
    height: Units.responsiveValue(14),
    tintColor: AppColors.brand.blueGrey,
  },
  selectedTagLabel: {
    ...AppFonts.mediumDemibold,
    lineHeight: AppFonts.sizes.medium,
    color: AppColors.brand.blueGrey,
    marginLeft: Units.responsiveValue(6),
  },
  removeTagButton: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
    marginLeft: Units.responsiveValue(6),
  },
})

const SelectedTags = ({ selectedTags, onUnselectTag }) => {
  if (!selectedTags.length) {
    return null
  }
  return (
    <View style={styles.selectedTagsContainer}>
      <Text style={styles.selectedTagsTitle}>
        Tags added are only visible to you
      </Text>
      <ScrollView horizontal>
        {selectedTags.map((tag) => (
          <TouchableOpacity
            key={tag.id}
            onPress={onUnselectTag(tag)}
            style={styles.selectedTag}
          >
            <Image
              source={require("../assets/tag.png")}
              style={styles.selectedTagIcon}
            />
            <Text style={styles.selectedTagLabel}>{tag.value}</Text>
            <View style={styles.removeTagButton}>
              <Image
                source={require("../assets/timesCircle.png")}
                style={{ height: 16 }}
              />
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  )
}

SelectedTags.propTypes = {
  selectedTags: PropTypes.array.isRequired,
  onUnselectTag: PropTypes.func.isRequired,
}

export default SelectedTags
