import React, { Component } from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { images } from "@utils/imageUtils"
// import PlacesAutocomplete from 'react-places-autocomplete';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import { ReactSVG } from "react-svg"

import Geocode from "react-geocode"

export class MapContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: "",
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: 49.2827291,
        lng: -123.1207375,
      },
    }
  }

  componentDidMount() {
    const { fromSuggestionPopup, address, fromSuggestionDetails } = this.props
    if (fromSuggestionPopup || fromSuggestionDetails) {
      new window.google.maps.Geocoder().geocode(
        { address: address },
        (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {
            this.setState({
              mapCenter: {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              },
            })
          }
        }
      )
    }
  }

  setCurrentLocation = (location) => {
    const { handlePlaceSelection } = this.props

    let latLng = new window.google.maps.LatLng(
      location?.coords.latitude,
      location?.coords.longitude
    )
    let city
    new window.google.maps.Geocoder().geocode(
      { latLng: latLng },
      (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            results[0].formattedSuggestion = {}
            results[0].formattedSuggestion.mainText =
              results[0].formatted_address
            results[0].formattedSuggestion.secondaryText = ""
            handlePlaceSelection(results[0])

            // //find country name
            //      for (var i=0; i<results[0].address_components.length; i++) {
            //     for (var b=0;b<results[0].address_components[i].types.length;b++) {

            //     //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
            //         if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
            //             //this is the object you are looking for
            //             city= results[0].address_components[i];
            //             break;
            //         }
            //     }
            // }
            //city data
            // alert(city.short_name + " " + city.long_name)
          } else {
            alert("No results found")
          }
        } else {
          alert("Geocoder failed due to: " + status)
        }
      }
    )
    this.setState({
      mapCenter: {
        lat: location?.coords.latitude + Math.random() / 1000000,
        lng: location?.coords.longitude,
      },
    })
  }

  getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((location) =>
      this.setCurrentLocation(location)
    )
  }

  handleChange = (address) => {
    this.setState({ address })
  }

  handleSelect = (address) => {
    // this.handleChange(address);
    geocodeByAddress(address)
      .then((results) => {
        this.setState({ address: results[0].formatted_address })
        return getLatLng(results[0])
      })
      .then((latLng) => this.setState({ mapCenter: latLng }))
      .catch((error) => console.error("Error", error))
  }

  // state = {
  //   showingInfoWindow: false,
  //   activeMarker: {},
  //   selectedPlace: {},

  //   mapCenter: {
  //     lat: 49.2827291,
  //     lng: -123.1207375
  //   }
  // };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    })

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  render() {
    const {
      temporaryPlace,
      handlSetTemporaryPlace,
      handlePlaceSelection,
      fromSuggestionPopup,
      isFromCreateTeeup,
      fromSuggestionDetails,
    } = this.props
    return (
      <div>
        {!fromSuggestionPopup && !fromSuggestionDetails && (
          <>
            <PlacesAutocomplete
              value={temporaryPlace}
              onChange={(temporaryPlace) => {
                handlSetTemporaryPlace(temporaryPlace)
                this.handleChange(temporaryPlace)
              }}
              onSelect={async (value, placeId, suggestion) => {
                handlePlaceSelection(suggestion)
                this.handleSelect(suggestion.description)
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <div className="location__input--wrapper">
                    <img className="location__icon" src={images.search} />
                    <input
                      value={temporaryPlace}
                      className="location__input"
                      {...getInputProps({ placeholder: "Suggest a place..." })}
                    />
                  </div>
                  <div
                    className="location__list no-scroll"
                    style={{
                      position: "absolute",
                      width: "100%",
                      background: "#ffffff",
                      zIndex: 1,
                    }}
                  >
                    {console.log(suggestions, 'suggestions')}
                    {suggestions.map((suggestion) => {
                      return (
                        <div
                          key={
                            suggestion.formattedSuggestion.mainText +
                            suggestion.index
                          }
                          className="location__suggest"
                          {...getSuggestionItemProps(suggestion)}
                        >
                          <div className="location__image--wrapper">
                            <img
                              className="location__image"
                              src={images.searchWhite}
                            />
                          </div>
                          <div className="location__content">
                            <span className="location__name">
                              {suggestion.formattedSuggestion.mainText}
                            </span>
                            <span className="location__description">
                              {suggestion.formattedSuggestion.secondaryText}
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <div
              style={{
                width: "85%",
                height: "28px",
                background: "#F0F2F5",
                // background: '#f0f0f0',
                borderRadius: "18px",
                margin: "20px auto 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={this.getCurrentLocation}
            >
              <ReactSVG
                style={{ fill: "#f42862" }}
                src={images.locationArrow}
              />
              <p
                style={{
                  fontFamily: "greycliff-cf-medium",
                  fontWeight: 500,
                  fontSize: "13px",
                  color: "#f42862",
                  marginLeft: "5px",
                }}
              >
                Use Current Location
              </p>
            </div>
          </>
        )}

        <div
          style={{
            position:
              fromSuggestionPopup || fromSuggestionDetails
                ? "relative"
                : isFromCreateTeeup
                ? "absolute"
                : "fixed",
            height: fromSuggestionPopup
              ? "172px"
              : isFromCreateTeeup
              ? "300px"
              : fromSuggestionDetails
              ? "calc( 100vh - 272px )"
              : "100vh",
            width:
              !fromSuggestionPopup && !fromSuggestionDetails
                ? "300px"
                : isFromCreateTeeup && "380px",
            right: isFromCreateTeeup ? "none" : 0,
            top: isFromCreateTeeup ? "none" : 0,
            marginTop: isFromCreateTeeup && "20px",
          }}
        >
          <Map
            google={this.props.google}
            // style={{width: '100%', height: '127%'}}
            style={{
              width: isFromCreateTeeup
                ? "380px"
                : !fromSuggestionPopup && !fromSuggestionDetails && "300px",
              height: fromSuggestionPopup
                ? "172px"
                : isFromCreateTeeup
                ? "300px"
                : fromSuggestionDetails
                ? "calc( 100vh - 272px )"
                : "100vh",
              position: "absolute",
            }}
            initialCenter={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng,
            }}
            center={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng,
            }}
          >
            <Marker
              onClick={this.onMarkerClick}
              name={`${this.state.address}`}
              position={{
                lat: this.state.mapCenter.lat,
                lng: this.state.mapCenter.lng,
              }}
            />

            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
            >
              <div>
                <h1>{this.state.selectedPlace.name}</h1>
              </div>
            </InfoWindow>
          </Map>
        </div>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC8ce4byJ3uPdPTNZ6_dpw9utQVGClnVL4",
})(MapContainer)
