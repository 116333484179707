import PropTypes from "prop-types"
import React, { PureComponent } from "react"
import { View, Text, PanResponder, InteractionManager } from "react-native"
import { styles } from "../styles"

const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")

export class LetterPicker extends PureComponent {
  static propTypes = {
    letter: PropTypes.string.isRequired,
    style: PropTypes.object,
  }

  _letterHeight = 15
  _calculateLetterHeight = () => this._letter.measure(this._setInitialHeights)
  _setInitialHeights = (x1, y1, width, height) => {
    this._letterHeight = height
  }

  render() {
    const { letter, style } = this.props
    return <Text style={[styles.alphabetLetter, style]}>{letter}</Text>
  }
}

export default class AlphabetPicker extends PureComponent {
  static propTypes = {
    alphabet: PropTypes.array,
    alphabetLength: PropTypes.number,
    onTouchStart: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onTapLetter: PropTypes.func,
    letterStyle: PropTypes.object,
    letterHeight: PropTypes.number,
    style: PropTypes.object,
  }

  static defaultProps = {
    alphabet: ALPHABET,
    alphabetLength: ALPHABET.length,
  }

  _panResponder = {}
  _tapTimeout = null
  _container = null
  _containerHeight = 0
  _containerTopOffset = 0
  _letterTargets = {}

  componentDidMount() {
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: this._handleStartShouldSetPanResponder,
      onMoveShouldSetPanResponder: this._handleMoveShouldSetPanResponder,
      onPanResponderGrant: this._handlePanResponderGrant,
      onPanResponderMove: this._handlePanResponderMove,
      onPanResponderRelease: this._handlePanResponderEnd,
      onPanResponderTerminate: this._handlePanResponderEnd,
    })
  }

  _handleStartShouldSetPanResponder = () => {
    return true
  }

  _handleMoveShouldSetPanResponder = () => {
    return true
  }

  _handlePanResponderGrant = (e, gestureState) => {
    const { onTouchStart } = this.props
    onTouchStart && onTouchStart()
    this._tapTimeout = setTimeout(() => {
      this._onTapLetter(this._findTouchedLetter(gestureState.y0))
    }, 100)
  }

  _handlePanResponderMove = (e, gestureState) => {
    clearTimeout(this._tapTimeout)
    this._onTapLetter(this._findTouchedLetter(gestureState.moveY))
  }

  _handlePanResponderEnd = () => {
    const { onTouchEnd } = this.props
    InteractionManager.runAfterInteractions(() => {
      onTouchEnd && onTouchEnd()
    })
  }

  _onInitialLayout = () => this._container.measure(this._setInitialHeights)
  _setInitialHeights = (x1, y1, width, height, offsetX, offsetY) => {
    this._containerTopOffset = offsetY
    this._containerHeight = height
  }

  _onTapLetter(letter) {
    letter && this.props.onTapLetter && this.props.onTapLetter(letter)
  }

  _findTouchedLetter = (y) => {
    const { alphabet, alphabetLength } = this.props
    let top = y - (this._containerTopOffset || 0)

    if (top >= 1 && top <= this._containerHeight) {
      const index = Math.floor((top / this._containerHeight) * alphabetLength)
      this._letterTargets[index] = alphabet[index]
      return alphabet[index]
    }
  }

  _renderLetter = (letter) => {
    const { letterStyle } = this.props
    return <LetterPicker letter={letter} key={letter} style={letterStyle} />
  }

  render() {
    const { alphabet, alphabetLength, style, letterHeight } = this.props
    const initialLetterHeight = letterHeight ? letterHeight : 15
    const letterCount = 26
    return (
      <View
        style={[
          // styles.alphabetContainer(letterCount, initialLetterHeight),
          style,
          {
            height: initialLetterHeight * alphabetLength,
          },
        ]}
        onLayout={this._onInitialLayout}
        ref={(c) => (this._container = c)}
        {...this._panResponder.panHandlers}
      >
        {alphabet.map(this._renderLetter)}
      </View>
    )
  }
}
