import React, { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"

import './index.scss'

const ThreeDotsDropDownItem = ({
  itemId,
  onPress,
  hoverNumber,
  setHoverNumber,
  image,
  activeImage,
  title,
}) => {
  return (
    <div
      className="top-bar-three-dots__item"
      onMouseOver={() => setHoverNumber(itemId)}
      onMouseLeave={() => setHoverNumber(0)}
      onClick={() => onPress()}
    >
      <ReactSVG
        className="top-bar-three-dots__icon"
        src={itemId === hoverNumber ? activeImage : image}
      />
      <p className="top-bar-three-dots__title">{title}</p>
    </div>
  )
}

const TopBarThreeDotsDropDown = ({ activePopUp, setActivePopUp }) => {
  const [hoverNumber, setHoverNumber] = useState(0)

  return (
    <div className="top-bar-three-dots">
      <ThreeDotsDropDownItem
        itemId={1}
        hoverNumber={hoverNumber}
        setHoverNumber={setHoverNumber}
        image={images.ndTeeUpDropDownManage}
        activeImage={images.ndTeeUpDropDownManageActive}
        setActivePopUp={setActivePopUp}
        onPress={() => setActivePopUp(1)}
        title="Manage Participants"
      />
      <ThreeDotsDropDownItem
        itemId={2}
        hoverNumber={hoverNumber}
        setHoverNumber={setHoverNumber}
        image={images.ndTeeUpDropDownNudge}
        activeImage={images.ndTeeUpDropDownNudgeActive}
        onPress={() => setActivePopUp(2)}
        title="Ask People Where are They?"
      />
      <ThreeDotsDropDownItem
        itemId={3}
        hoverNumber={hoverNumber}
        setHoverNumber={setHoverNumber}
        image={images.ndTeeUpDropDownNudge}
        activeImage={images.ndTeeUpDropDownNudgeActive}
        onPress={() => setActivePopUp(3)}
        title="Ask People if They are Going?"
      />
      <ThreeDotsDropDownItem
        itemId={4}
        hoverNumber={hoverNumber}
        setHoverNumber={setHoverNumber}
        image={images.ndTeeUpDropDownTagUser}
        activeImage={images.ndTeeUpDropDownTagUserActive}
        onPress={() => setActivePopUp(4)}
        title="Tag People"
      />
    </div>
  )
}

export default TopBarThreeDotsDropDown;
