import React, { Component } from "react"
import { connect } from "react-redux"
// import { Navigation } from 'react-native-navigation'
import PropTypes from "prop-types"
// import AsyncStorage from '@react-native-community/async-storage'
import {
  selectUserInfo,
  selectContactMechanisms,
  selectPublicContactMechanismsValues,
} from "@selectors/user"
// import { routeBack, showTextPromptDialog, showChooseContactMethod } from '@nav'
// import navigationConfig from '@nav/NavigationConfig'
import { getUserContactMechanisms } from "@actions/userActions"
import { getRandomBase64Image, getBase64 } from "@utils/imageUtils"
import EditUserProfileView from "./editUserProfileView"
// import backend from '@apis/backend'
import * as loginActions from "@actions/loginActions"
import {
  addUserContactMechanism,
  deleteUserContactMechanism,
  addLocation,
  addUrl,
  removeLocation,
  removeUrl,
  publishContactMechanism,
  updateContactMechs,
  unpublishContactMechanism,
} from "@actions/contactMechanismsActions"
import { userContactTypes, contactTypes } from "@utils/contactUtils"
import {
  updateProfile,
  updateUserInfo,
  updateProfileCustom,
} from "@actions/userActions"
// import pickImage from '@utils/imagePicker'
import strings from "@i18n"
import TextPromptDialog from "@ui/textPromptDialog"
import ChooseContactMethod from "./chooseContactMethod"

class EditUserProfileContainer extends Component {
  static options() {
    return {}
  }

  constructor(props) {
    super(props)
    // Navigation.events().bindComponent(this)
    this.state = {
      backgroundImage: props.userInfo?.backgroundImageUrl,
      isModalOpened: false,
      modalContent: null,
      content: null,
      isContactMethodOpened: false,
      selectedPublicPhone: "",
      selectedPublicEmail: "",
    }
  }

  componentDidMount() {
    this.setBackgroundImage()
  }

  componentDidUpdate(prevProps) {
    const {
      userInfo: { backgroundImageUrl },
    } = this.props
    if (prevProps.userInfo.backgroundImageUrl !== backgroundImageUrl) {
      this.setState({
        backgroundImage: backgroundImageUrl,
      })
    }
  }

  closeModal = () => this.setState({ isModalOpened: false, modalContent: null })
  closeContactMethod = () => this.setState({ isContactMethodOpened: false })

  setBackgroundImage = async () => {
    if (!this.state.backgroundImage) {
      let backgroundImage = localStorage.getItem("profileBackgroundImage")
      if (!backgroundImage) {
        backgroundImage = await getRandomBase64Image("dark")
        localStorage.setItem("profileBackgroundImage", backgroundImage)
      }

      this.setState({
        backgroundImage,
      })
    }
  }

  handleAvatarChange = (e) => {
    const file = e.target.files[0]
    getBase64(file).then((base64) => {
      console.log(base64)
      this.updateProfile({ avatar: base64 })
    })
  }

  handleBackgroundChange = (e) => {
    const file = e.target.files[0]
    getBase64(file).then((base64) => {
      this.updateProfile({ backgroundImageUrl: base64 })
    })
  }

  handleFullNameChange = (value) => {
    this.updateProfile({ name: value })
  }

  handleNicknameChange = (value) => {
    const nickNameWhithoutSpaces = value.replace(/\s/g, "")
    this.updateProfile({ nickName: nickNameWhithoutSpaces })
  }

  handleBioChange = (value) => {
    this.updateProfile({ about: value })
  }

  handleHometownChange = (value) => {
    this.updateProfile({ primaryLocation: value })
  }

  handleLocationChange = async (value) => {
    let contactLocation = await addUserContactMechanism({
      value,
      typeId: userContactTypes.LOCATION,
      isPublic: true,
    })
    contactLocation.typeId = userContactTypes.LOCATION
    contactLocation.isPublic = true
    addLocation(contactLocation)
    getUserContactMechanisms()
  }

  handleUrlChange = async (value) => {
    let contactUrl = await addUserContactMechanism({
      value,
      typeId: userContactTypes.URL,
      isPublic: true,
    })
    contactUrl.typeId = userContactTypes.URL
    contactUrl.isPublic = true
    addUrl(contactUrl)
    getUserContactMechanisms()
  }

  handleUrlRemove = async (id) => {
    const {
      removeUrl,
    } = this.props
    await deleteUserContactMechanism(id)
    removeUrl(id)
  }

  handleLocationRemove = async (id) => {
    const {
      removeLocation,
    } = this.props

    await deleteUserContactMechanism(id)
    removeLocation(id)
  }

  handlePhoneChange = () => {
    const { contactMechanisms } = this.props
    const verifiedPhoneContactMechanisms = contactMechanisms.phone.filter(
      (item) => item.isVerified
    )
    const publicPhoneId = verifiedPhoneContactMechanisms.find(
      (item) => item.isPublic
    )?.id

    const props = {
      type: contactTypes.PHONE,
      onSave: (id) =>
        this.onPublishContactMethod(
          id,
          userContactTypes.PHONE,
          userContactTypes.PRIMARY_PHONE,
          contactTypes.PHONE
        ),
      onClose: this.closeContactMethod,
      contactMechanisms: verifiedPhoneContactMechanisms,
      selectedMechId: publicPhoneId,
      selectEmailOrPhone: (phone) => {
        this.setState({ selectedPublicPhone: phone })
      },
    }

    this.setState({
      isContactMethodOpened: true,
      content: <ChooseContactMethod {...props} />,
    })
  }

  handleEmailChange = () => {
    const { contactMechanisms } = this.props
    const verifiedEmailContactMechanisms = contactMechanisms.email.filter(
      (item) => item.isVerified
    )
    const publicEmailId = verifiedEmailContactMechanisms.find(
      (item) => item.isPublic
    )?.id

    const props = {
      type: contactTypes.EMAIL,
      onSave: (id) => {
        this.onPublishContactMethod(
          id,
          userContactTypes.EMAIL,
          userContactTypes.PRIMARY_EMAIL,
          contactTypes.EMAIL
        )
      },
      onClose: this.closeContactMethod,
      contactMechanisms: verifiedEmailContactMechanisms,
      selectedMechId: publicEmailId,
      selectEmailOrPhone: (email) => {
        this.setState({ selectedPublicEmail: email })
      },
    }

    this.setState({
      isContactMethodOpened: true,
      content: <ChooseContactMethod {...props} />,
    })
  }

  onPublishContactMethod = async (id, firstTypeId, secondTypedId, mechType) => {
    const { contactMechanisms } = await publishContactMechanism(id)

    const filteredContactMechs = contactMechanisms.filter(
      (item) => item.typeid === firstTypeId || item.typeid === secondTypedId
    )

    this.props.updateContactMechs(filteredContactMechs, mechType)
  }

  onUnpublishContactMethod = async (
    id,
    firstTypeId,
    secondTypedId,
    mechType
  ) => {
    const { contactMechanisms } = await unpublishContactMechanism(id)

    const filteredContactMechs = contactMechanisms.filter(
      (item) => item.typeid === firstTypeId || item.typeid === secondTypedId
    )

    this.props.updateContactMechs(filteredContactMechs, mechType)
  }

  handleRemovePhone = () => {
    const { phone } = this.props
    this.onUnpublishContactMethod(
      phone.id,
      userContactTypes.PHONE,
      userContactTypes.PRIMARY_PHONE,
      contactTypes.PHONE
    )
  }

  handleRemoveEmail = () => {
    const { email } = this.props
    this.onUnpublishContactMethod(
      email.id,
      userContactTypes.EMAIL,
      userContactTypes.PRIMARY_EMAIL,
      contactTypes.EMAIL
    )
  }

  updateProfile = async (body) => {
    try {
      let userInfo = await updateProfile(body)
      if (userInfo.username && !userInfo.nickname) {
        userInfo.nickname = userInfo.username
      }
      if (userInfo.primarylocation) {
        userInfo.primaryLocation = userInfo.primarylocation
      }
      this.props.updateUserInfo(userInfo)
    } catch (error) {
      console.log("error updateProfile", error)
    }
  }

  render() {
    const {
      backgroundImage,
      isModalOpened,
      modalContent,
      content,
      isContactMethodOpened,
      selectedPublicEmail,
      selectedPublicPhone,
    } = this.state
    const {
      userInfo,
      closeEdit,
      location,
      url,
      email,
      phone,
      contactMechanisms,
    } = this.props

    // const contactMechanismsNew = contactMechanisms ? [...contactMechanisms] : []
    const contactMechanismsNew = Object.assign({}, contactMechanisms);

    return (
      <EditUserProfileView
        userInfo={userInfo}
        contactMechanisms={contactMechanismsNew}
        // location={contactMechanisms.location}
        // url={contactMechanisms.url}
        // email={email}
        publicPhone={phone}
        publicEmail={email}
        // location={this.state.location ? this.state.location : location }
        // url={this.state.url ? this.state.url : url }
        // email={this.state.email ? this.state.email : email}
        // phone={this.state.phone ? this.state.phome : phone}
        handleBack={closeEdit}
        backgroundImage={backgroundImage}
        handleFullNameChange={this.handleFullNameChange}
        handleNicknameChange={this.handleNicknameChange}
        handleBioChange={this.handleBioChange}
        handleHometownChange={this.handleHometownChange}
        handleLocationChange={this.handleLocationChange}
        handleUrlChange={this.handleUrlChange}
        handlePhoneChange={this.handlePhoneChange}
        handleEmailChange={this.handleEmailChange}
        handleUrlRemove={this.handleUrlRemove}
        handleLocationRemove={this.handleLocationRemove}
        handleRemovePhone={this.handleRemovePhone}
        handleRemoveEmail={this.handleRemoveEmail}
        handleAvatarChange={this.handleAvatarChange}
        handleBackgroundChange={this.handleBackgroundChange}
        isModalOpened={isModalOpened}
        modalContent={modalContent}
        closeModal={this.closeModal}
        content={content}
        isContactMethodOpened={isContactMethodOpened}
        selectedPublicEmail={selectedPublicEmail}
        selectedPublicPhone={selectedPublicPhone}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const userInfo = selectUserInfo(state)
  const contactMechanisms = selectContactMechanisms(state)
  // const { url, location, email, phone } = selectPublicContactMechanismsValues(state)
  const { email, location, phone, url } = selectContactMechanisms(state)

  return {
    userInfo,
    url,
    location,
    email,
    phone,
    contactMechanisms,
    state,
  }
}

const mapDispatchToProps = {
  updateUserInfo,
  addLocation,
  addUrl,
  removeLocation,
  removeUrl,
  updateContactMechs,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserProfileContainer)

EditUserProfileContainer.propTypes = {
  userInfo: PropTypes.object,
  // componentId: PropTypes.string.isRequired,
  url: PropTypes.object,
  location: PropTypes.object,
  // contactMechanisms: PropTypes.array,
  email: PropTypes.object,
  phone: PropTypes.object,
}
