import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

const ParticipantsActivityRowStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: Units.rem(1),
    paddingBottom: Units.responsiveValue(8),
    paddingTop: Units.responsiveValue(4),
  },
  middleContainer: {
    paddingHorizontal: Units.rem(1),
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-around",
  },
  username: {
    ...AppFonts.bigBold,
    color: AppColors.brand.blackTwo,
  },
  lastAction: {
    ...AppFonts.mediumRegular,
    color: AppColors.brand.warmGrey,
  },
  timestamp: {
    ...AppFonts.smallRegular,
    alignSelf: "flex-end",
    color: AppColors.brand.warmGrey,
  },
})

const ParticipantsStyles = StyleSheet.create({
  mainPeopleBlock: {
    flex: 1,
    backgroundColor: AppColors.brand.white,
  },
  spacer: {
    flex: 1,
  },
  extendedScroll: {
    flex: 1,
  },
  settingsButtons: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: 100,
    paddingLeft: Units.rem(1),
    paddingRight: Units.rem(1),
    paddingBottom: Units.rem(1),
    backgroundColor: AppColors.brand.lightGrey,
    justifyContent: "center",
  },
  lastAvatarItemInRow: {
    paddingRight: 0,
  },
  activityStreamRowContainer: {
    paddingRight: 0,
    paddingTop: Units.responsiveValue(8),
    paddingBottom: Units.responsiveValue(8),
    borderTopWidth: Units.responsiveValue(1),
    borderColor: AppColors.brand.grey243,
    flexDirection: "row",
  },
  activityStreamDescriptionWrapper: {
    paddingLeft: Units.responsiveValue(8),
    paddingTop: Units.responsiveValue(1),
  },
  activityStreamDescriptionFullName: {
    ...AppFonts.biggerDemibold,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.34),
    color: AppColors.brand.blackTwo,
  },
  activityStreamDescriptionName: {
    ...AppFonts.smallishMedium,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.2),
    color: AppColors.brand.darkGrey2,
  },
  activityStreamDescriptionLastActivity: {
    ...AppFonts.extraSmall,
    lineHeight: Units.responsiveValue(15),
    letterSpacing: -Units.responsiveValue(0.15),
    color: AppColors.brand.grey157,
  },
  settingsButtonsIcon: {
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
    tintColor: AppColors.brand.pink,
    marginRight: Units.rem(1),
    marginLeft: Units.rem(1),
  },
  grayArrow: {
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
  },
  peopleGoingNeeded: {
    paddingTop: Units.rem(1),
  },
  participantListContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  participantList: {
    flex: 1,
  },
  participantListGap: {
    marginBottom: 150,
  },
  setStatusButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: AppColors.brand.white,
    height: Units.responsiveValue(40),
    borderRadius: Units.responsiveValue(22),
    borderWidth: 1,
    borderColor: AppColors.brand.pink,
  },
  setStatusButtonText: {
    ...AppFonts.extraSmallExtrabold,
    textTransform: "uppercase",
    color: AppColors.brand.pink,
    textAlign: "center",
  },
  statusButton: {
    alignSelf: "center",
    flexDirection: "row",
    height: Units.responsiveValue(40),
    borderRadius: Units.responsiveValue(22),
    alignItems: "center",
    width: "100%",
  },
  statusButtonContainerExpanded: {
    width: Units.responsiveValue(128),
    // height: Units.responsiveValue(32),
  },
  statusButtonContainerCollapsed: {
    marginTop: Units.responsiveValue(13),
    marginLeft: Units.responsiveValue(8),
    marginRight: Units.responsiveValue(16),
    width: Units.responsiveValue(116),
    height: Units.responsiveValue(40),
  },
  showAllButton: {
    ...AppFonts.mediumBold,
    color: AppColors.navbarButton,
    textAlign: "center",
    marginTop: Units.responsiveValue(24),
    marginBottom: Units.responsiveValue(18),
  },
  inviteButton: {
    height: Units.responsiveValue(26),
    borderRadius: Units.responsiveValue(13),
    borderWidth: 1,
    borderColor: AppColors.brand.grey193,
    paddingHorizontal: Units.rem(0.7),
    flexDirection: "row",
    alignItems: "center",
  },
  addParticipantImage: {
    marginRight: Units.responsiveValue(4),
    tintColor: "#b2b2b2",
    cursor: "pointer",
  },
  addButton: {
    paddingTop: Units.rem(6),
  },
  avatarName: {
    ...AppFonts.small,
    color: AppColors.brand.grey3,
    textAlign: "center",
    marginTop: 5,
  },
  filtersContainer: {
    marginTop: Units.responsiveValue(12),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: Units.responsiveValue(16),
    paddingBottom: Units.responsiveValue(16),
    paddingHorizontal: Units.responsiveValue(16),
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: AppColors.brand.lightGrey,
    zIndex: 1,
  },
  filters: {
    flexDirection: "row",
    alignItems: "center",
    lineHeight: Units.responsiveValue(20),
  },
  contentContainer: {
    paddingLeft: Units.responsiveValue(6),
  },
  collapsedContentContainer: {
    paddingLeft: Units.rem(1),
  },
  expandedContentContainer: {
    flex: 1,
    paddingRight: Units.responsiveValue(16),
  },
  rowContainer: {
    // paddingTop: 0,
    paddingTop: Units.rem(0.75),
  },
  expandedAvatarContainer: {
    width: 56,
    height: 56,
  },
  rowContainerAdditionalPadding: {
    paddingLeft: Units.responsiveValue(6),
  },
  expandedRowContainerAdditionalPadding: {
    // flex: 1,
    paddingHorizontal: Units.responsiveValue(4),
    // width: 75,
  },
  emptyAvatar: {
    flex: 1,
    backgroundColor: AppColors.brand.white,
  },
  invitedParticipantOpacity: {
    opacity: 0.6,
  },
  pollingAvatarStatus: (expanded) => ({
    marginRight: expanded ? 11 : 2,
  }),
  titleWrapper: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  title: {
    ...AppFonts.bold,
    fontSize: 17,
    marginRight: Units.rem(0.75),
    color: AppColors.brand.black,
    cursor: "pointer",
  },
  filterText: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.mediumDemibold,
    letterSpacing: -Units.responsiveValue(0.3),
  },
  sort: {
    marginRight: Units.rem(0.5),
  },
  number: {
    ...AppFonts.mediumDemibold,
    color: "#b2b2b2",
    opacity: 0.8,
  },
  viewAllText: {
    color: AppColors.brand.warmGrey,
    textAlign: "center",
    fontFamily: "greycliffcf-regular-salt",
    // paddingBottom: 10
  },
  userIcon: {
    tintColor: "#b2b2b2",
    marginRight: 3,
  },
  caretDown: {
    shadowOpacity: 0.2,
    width: Units.responsiveValue(9),
    height: Units.responsiveValue(5),
    marginLeft: Units.rem(0.5),
    resizeMode: "stretch",
  },
  arrowIcon: {
    width: Units.rem(0.625),
    height: Units.rem(0.625),
    tintColor: "#b2b2b2",
    marginLeft: 7,
    marginRight: 3,
  },
  padding: {
    paddingTop: Units.rem(0.75),
  },
  myStatusButtonText: {
    flex: 1,
    ...AppFonts.extraSmallExtrabold,
    textTransform: "uppercase",
    color: AppColors.brand.white,
    textAlign: "center",
  },
  rightImage: {
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
    tintColor: AppColors.brand.white,
    marginLeft: 12,
  },
})

const SectionHeaderStyles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingHorizontal: Units.rem(1),
    paddingBottom: Units.rem(1),
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: Units.rem(0.25),
  },
  title: {
    ...AppFonts.mediumBold,
    color: AppColors.brand.warmGrey,
  },
  separator: {
    height: 1,
    marginBottom: Units.rem(1),
    backgroundColor: AppColors.brand.whiteGrey,
  },
})

const CriticalMassBarStyles = StyleSheet.create({
  mainContainer: {
    paddingTop: Units.responsiveValue(8),
    paddingHorizontal: Units.responsiveValue(16),
    overflow: "hidden",
  },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: Units.responsiveValue(6),
  },
  progressbarContainer: {
    flexDirection: "row",
    height: Units.responsiveValue(10),
    borderRadius: Units.responsiveValue(5),
    borderWidth: 0,
  },
  progressEmpty: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    height: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.warmGrey2,
    borderRadius: Units.responsiveValue(5),
    opacity: 0.35,
  },
  progressFill: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,
    height: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.green,
    borderRadius: Units.responsiveValue(5),
    opacity: 1,
  },
  text: {
    ...AppFonts.small,
    color: AppColors.brand.grey3,
  },
  checkIcon: {
    width: Units.rem(0.8),
    height: Units.rem(0.8),
    tintColor: AppColors.brand.green,
  },
})

const StartsWhenBarStyles = StyleSheet.create({
  container: {
    flexDirection: "column",
    // margin: Units.responsiveValue(12),
    paddingHorizontal: Units.responsiveValue(16),
    paddingTop: Units.responsiveValue(6),
    paddingBottom: Units.responsiveValue(6),
  },
  bottomContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  bottomText: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(8),
    letterSpacing: Units.responsiveValue(-0.1),
    paddingTop: Units.responsiveValue(6),
  },
  progressBar: {
    backgroundColor: AppColors.brand.warmGrey2_35,
    height: Units.responsiveValue(10),
    borderRadius: Units.responsiveValue(8),
  },
  activeProgressBar: (width) => ({
    backgroundColor: AppColors.brand.blueEmerald,
    borderRadius: Units.responsiveValue(8),
    position: "absolute",
    top: 0,
    bottom: 0,
    right: width,
    left: 0,
  }),
})

const CriticalMassButtonStyles = StyleSheet.create({
  container: {
    height: 48,
    backgroundColor: AppColors.brand.white,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: AppColors.brand.white,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingRight: Units.rem(1),
  },
  text: {
    ...AppFonts.biggerBold,
    color: AppColors.brand.black,
    justifyContent: "center",
  },
  icon: {
    marginRight: Units.rem(1),
    marginLeft: Units.rem(1),
  },
  arrow: {
    flex: 1,
    flexDirection: "row-reverse",
  },
})

const titleContainer = (expanded) => ({
  flexDirection: "row",
  alignItems: "center",
  paddingHorizontal: expanded ? 0 : Units.rem(1),
  marginBottom: expanded ? 12 : 0,
  height: Units.responsiveValue(20),
})

export {
  ParticipantsStyles,
  ParticipantsActivityRowStyles,
  SectionHeaderStyles,
  CriticalMassBarStyles,
  StartsWhenBarStyles,
  CriticalMassButtonStyles,
  titleContainer,
}
