import _ from "lodash"
import { createSelector } from "reselect"

import { selectUserInterests } from "@selectors/user"

const selectCommunity = (state) => state.community

export const selectInterests = createSelector(
  selectCommunity,
  (selectCommunity) => selectCommunity.interests || []
)

export const selectMatchings = createSelector(
  selectCommunity,
  (selectCommunity) => selectCommunity.matchings || []
)

export const selectInterestsList = createSelector(
  selectInterests,
  (interests) => interests.list || []
)

export const selectInterestsFilter = createSelector(
  selectInterests,
  (interests) => interests.filter || {}
)

export const selectInterestByIndex = (index, id) =>
  createSelector(selectInterestsList, (interests) => {
    if (interests && interests.length) {
      if (interests.length > index && interests[index].id === id) {
        return interests[index]
      } else {
        // Something's wrong, didn't find interest at specified index
        for (let i = 0, len = interests.length; i < len; i++) {
          if (interests[i].id === id) {
            // Found a matching interest, probably this is the one
            return interests[i]
          }
        }

        return {}
      }
    } else {
      // No interests
      return {}
    }
  })

export const selectInterestById = (id) =>
  createSelector(
    selectInterestsList,
    (interests) => _.find(interests, (item) => item.id === id) || {}
  )

export const selectMatchingById = (id) =>
  createSelector(
    selectMatchings,
    (matchings) => _.find(matchings, (item) => item.id === id) || null
  )

export const isFollowingInterestSelector = (state) => (interestId) =>
  selectUserInterests(state).filter(({ id }) => id === interestId).length > 0

export const selectFilteredCommunityInterests = createSelector(
  selectInterestsFilter,
  (filter) => filter.interests
)

export const selectFilteredCommunityLocations = createSelector(
  selectInterestsFilter,
  (filter) => filter.locations
)
