import React from "react"
import { connect } from "react-redux"
import { Platform, TouchableOpacity, Image } from "react-native"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import closeIcon from "../../../assets/images/close.svg"
import { images } from "@utils/imageUtils"
import { SectionListStyles } from "../../gamePlansCard/suggestionsList/suggestionListStyles"

import {
  selectAvaliableCalendars,
  selectCalendarPermissions,
} from "@selectors/calendar"
import { selectCalendarSyncSettings } from "@selectors/user"
import { changeSettings } from "@actions/settingsActions"
import {
  gotAvaliableCalendars,
  setCalendarPermissions,
} from "@actions/calendarActions"
import TimezoneSelect from "./timezoneSelect"
import {
  getCalendars,
  getSpecificCalendarByIdWithCalendars,
  handleAskCalendarPermissions,
  getCalendarEvents,
} from "@utils/calendarSyncUtils"
import { getDefaultTimezone } from "@utils/dateUtils"

import TimeZoneSettingsView from "./timeZoneSettingsView"
import NotImplementedYet from "./NotImplementedYet"

const noOp = () => {}

class TimeZoneSettingsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.calendarSyncSettings,
      defaultTimezone: getDefaultTimezone(),
      content: null,
    }
  }

  static options() {
    return {
      popGesture: false,
    }
  }

  componentDidMount() {
    const { syncTeeups } = this.props.calendarSyncSettings
    this.handleCheckPermissions(syncTeeups, true)
  }

  navigationButtonPressed({ buttonId }) {
    switch (buttonId) {
      case "backButton": {
        const { onCloseModal, componentId } = this.props
        onCloseModal(componentId)
        break
      }
      default:
    }
  }

  handleLoadUserCalendars = async () => {
    try {
      const calendars = await getCalendars()
      gotAvaliableCalendars(calendars)
    } catch (error) {
      gotAvaliableCalendars([])
      // console.log('TimeZoneSettingsContainer error: ', { error })
    }
  }

  handleCheckPermissions = async (syncTeeups, getCalendars) => {
    setCalendarPermissions(null)
    const permission = await handleAskCalendarPermissions(
      getCalendars ? this.handleLoadUserCalendars : noOp,
      !syncTeeups
    )
    setCalendarPermissions(permission)
  }

  onCalendarSelected = (componentId, calendar) => {
    this.onChange(calendar.id, "calendar")
    // pop(componentId)
  }

  onAdditionalSelected = (componentId, calendars) => {
    this.onChange(calendars, "additionalCalendars")
    // pop(componentId)
  }

  closeSelect = () => {
    this.setState({ content: null })
    this.setState({ homeTimezone: false })
  }

  onTimezoneSelected = (componentId, timezoneName) => {
    const { defaultTimezone } = this.state
    const { customTimezone } = this.props.calendarSyncSettings
    if (timezoneName !== customTimezone) {
      this.onChange(
        defaultTimezone === timezoneName ? null : timezoneName,
        "customTimezone"
      )
    }
    if (componentId) this.setState({ content: null })
  }

  onCustomTimezone = (notImplemented) => {
    if (!notImplemented) {
      const {
        calendarSyncSettings: { customTimezone },
        componentId,
      } = this.props
      const { defaultTimezone } = this.state
      const props = {
        onSelected: this.onTimezoneSelected,
        selectedTimezone: customTimezone || defaultTimezone,
        tzAutodetected: !customTimezone,
        defaultTimezone: defaultTimezone,
        travelTimezone: defaultTimezone,
      }

      this.setState({
        content: (
          <TimezoneSelect
            {...props}
            defaultTimezone={defaultTimezone}
            closeSelect={this.closeSelect}
          />
        ),
      })
    } else {
      this.setState({ homeTimezone: true })
      this.setState({
        content: <NotImplementedYet closeSelect={this.closeSelect} />,
      })
    }
  }

  onCalendarSelect = () => {
    const { componentId, calendarSyncSettings } = this.props
    // routeCalendarSelect(componentId, {
    //     onCalendarSelected: this.onCalendarSelected,
    //     calendarSelectedId: calendarSyncSettings.calendar,
    // })
  }

  onAdditionalCalendarsSelect = () => {
    const { componentId, calendarSyncSettings } = this.props
    // routeAdditionalCalendarSelect(componentId, {
    //     onCalendarSelected: this.onAdditionalSelected,
    //     calendarSelectedId: calendarSyncSettings.calendar,
    //     additionalCalendarsIds: calendarSyncSettings.additionalCalendars,
    // })
  }

  onChange = (value, settingName) => {
    const formattedSettings = {
      calendarSync: {
        ...this.props.calendarSyncSettings,
        [settingName]: value,
      },
    }
    if (settingName === "syncTeeups" && value) {
      this.handleCheckPermissions(true, false)
    } else if (
      (settingName === "calendar" &&
        value !== this.props.calendarSyncSettings.calendar) ||
      (settingName === "additionalCalendars" &&
        value.join() !==
          this.props.calendarSyncSettings.additionalCalendars.join())
    ) {
      getCalendarEvents({})
    }
    changeSettings(formattedSettings)
  }

  componentWillUnmount() {
    const { calendar, syncOnlyAllSet, syncTeeups } =
      this.props.calendarSyncSettings
    if (
      this.state.calendar !== calendar ||
      this.state.syncOnlyAllSet !== syncOnlyAllSet ||
      this.state.syncTeeups !== syncTeeups
    ) {
      // Modified some settings related to calendar stuff. re-sync calendar teeup events
      // resyncCalendarTeeupEvents()
    }
  }

  componentDidUpdate(prevProps) {
    const { syncTeeups } = this.props.calendarSyncSettings

    if (prevProps.calendarSyncSettings.syncTeeups !== syncTeeups) {
      if (syncTeeups) {
        // Option toggled ON, resync all teeups into calendar
      } else {
        // Option toggled OFF, remove all teeups from calendar
      }
    }
  }

  render() {
    const {
      calendars,
      calendarSyncSettings: {
        calendar,
        additionalCalendars,
        customTimezone,
        syncOnlyAllSet,
        syncTeeups,
      },
      closeOption,
    } = this.props

    const { defaultTimezone, content, homeTimezone } = this.state

    let calendarTitle = "Select"
    if (calendar) {
      let cal = getSpecificCalendarByIdWithCalendars(calendar, calendars)
      if (cal) {
        calendarTitle = cal.title
      }
    }

    return (
      <>
        {content &&
          ReactDOM.createPortal(
            <>
              <div className="settings__timezone_modal_wrapper">
                <div className="settings__timezone_modal">
                  <div className="settings__timezone__top">
                    <span>
                      {homeTimezone ? "Home Timezone" : "Active Time Zone"}
                    </span>
                    <TouchableOpacity onPress={this.closeSelect}>
                      <Image
                        source={images.crossSettings}
                        style={SectionListStyles.cancelBtnImage}
                      />
                    </TouchableOpacity>
                  </div>
                  <div className="settings__timezone_border"></div>
                  {content}
                </div>
              </div>
            </>,
            document.getElementById("modal-root")
          )}
        <TimeZoneSettingsView
          calendarTitle={calendarTitle}
          additionalCalendarsAmount={additionalCalendars.length}
          syncTeeups={syncTeeups}
          syncOnlyAllSet={syncOnlyAllSet}
          customTimezone={customTimezone || `${defaultTimezone} (detected)`}
          defaultTimezone={defaultTimezone}
          tzAutodetected={!customTimezone}
          onChange={this.onChange}
          onCalendarSelect={this.onCalendarSelect}
          onAdditionalCalendarsSelect={this.onAdditionalCalendarsSelect}
          onCustomTimezone={this.onCustomTimezone}
        />
      </>
    )
  }
}
TimeZoneSettingsContainer.propTypes = {
  calendars: PropTypes.array,
  calendarSyncSettings: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
}

const mapStateToProps = (state) => {
  const calendarSyncSettings = selectCalendarSyncSettings(state)
  const calendars = selectAvaliableCalendars(state)
  const permission = selectCalendarPermissions(state)

  return {
    calendarSyncSettings,
    calendars,
    permission,
  }
}

export default connect(mapStateToProps)(TimeZoneSettingsContainer)
