import actionTypes from "@actions/actionTypes"

const initialState = {
  activeMeetingApp: null,
  activeButtonId: 0,
  mapCenter: {
    lat: 49.2827291,
    lng: -123.1207375,
  },
  isLocationSelected: false,
  activePlace: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SUGGEST_ONLINE_ACTIVE_MEETING_APP:
      return {
        ...state,
        activeMeetingApp: payload,
      }
    case actionTypes.SET_SUGGEST_ONLINE_ACTIVE_BUTTON_ID:
      return {
        ...state,
        activeButtonId: payload,
      }
    case actionTypes.SET_SUGGEST_ONLINE_APP_NAME:
      return {
        ...state,
        activeMeetingApp: {
          ...state.activeMeetingApp,
          appName: payload,
        }
      }
    case actionTypes.SET_SUGGEST_ONLINE_DETAILS:
      return {
        ...state,
        activeMeetingApp: {
          ...state.activeMeetingApp,
          details: payload,
        }
      }
    case actionTypes.SET_SUGGEST_IN_PERSON_MAP_CENTER:
      return {
        ...state,
        mapCenter: payload
      }
    case actionTypes.SET_SUGGEST_IN_PERSON_IS_LOCATION_SELECTED:
      return {
        ...state,
        isLocationSelected: payload
      }
    case actionTypes.SET_SUGGEST_IN_PERSON_ACTIVE_PLACE:
      return {
        ...state,
        activePlace: payload
      }
    default: 
      return state
  }
}
