import React, { useCallback, useState, useEffect } from "react"
import cn from "classnames"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"

import "./index.scss"
import logo from "../../assets/images/teeUpsPink.svg"
import CodeInputNeo from "../VerifyCode/common/CodeInputNeo/CodeInputNeo"
import { setShowSideBar } from "@actions/commonActions"
import {
  verifyContactMechanism,
  requestVerifyContactMechanism,
  deleteMechanismFromState,
  deleteMechanism,
} from "@actions/contactMechanismsActions"

const VerifyContactMethod = ({
  contactMechanismId,
  defaultVerificationId,
  userId,
  value,
  showSucces,
  setShowSideBar,
  cancel,
  stepSuccess,
  createdCM,
  deleteMechanismFromState,
  deleteMechanism,
}) => {
  const [code, setCode] = useState("")
  const [counter, setCounter] = useState(60)
  const [error, setError] = useState("")
  const [verificationId, setVerificationId] = useState(defaultVerificationId)
  const history = useHistory()

  useEffect(() => {
    const pushBack = () => {
      history.push("/contact")
      setShowSideBar(false)
    }

    window.onpopstate = pushBack
    return () => {
      window.removeEventListener("onpopstate", pushBack)
    }
  }, [])

  const handleChangeCode = useCallback((val) => {
    setCode(val)
  })

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  const handleVerifyCode = () => {
    verifyContactMechanism(verificationId, code)
      .then((response) => {
        showSucces()
      })
      .catch((error) => {
        const { status, message } = error.response.data
        if (message) {
          setError(message)
        }
      })
  }

  const resendCode = async () => {
    const { contactMechanismVerificationId } =
      await requestVerifyContactMechanism(contactMechanismId)
    setVerificationId(contactMechanismVerificationId)
    setCounter(60)
  }

  const cancelVerification = async () => {
    await deleteMechanism(createdCM)
      .then(() => {
        deleteMechanismFromState(createdCM)
      })
      .catch((error) => {
        console.log("error", error)
      })
    cancel(stepSuccess)
    // deleteUserCM(createdCM ,token, '/recovery')
  }

  return (
    <div className="forgot__page" style={{ flexDirection: "column" }}>
      <div className="forgot__content">
        <img className="forgot__icon" src={logo} />
        <span className="forgot__title">Verify Your Account</span>
        <p className="forgot__subtitle">
          Enter the code we sent to: &nbsp;
          <br />
          {/* {value.slice(0, 1) + 'x'.repeat(value.length-6) + value.replace(/ /g,'').slice(value.length-5, value.length)} */}
          {value}
        </p>
        {error ? (
          <span className="forgot__error">{error}</span>
        ) : (
          <p
            className="forgot__subtitle"
            style={{ fontSize: 16, color: "#454c52", marginBottom: 8 }}
          >
            Verification Code:
          </p>
        )}
        <CodeInputNeo
          errorMessage={error}
          //   customContainerStyle={commonStyles.inputMargin16}
          onChange={handleChangeCode}
        />
        <div
          style={{
            width: 327,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <button
            className="verify__resend verify__resend--recovery"
            onClick={cancelVerification}
            style={{ width: 88, height: 48, margin: 0 }}
          >
            Cancel
          </button>
          <button
            disabled={code.length !== 6}
            className={cn("forgot__button", {
              "forgot__button--disabled": code.length !== 6,
            })}
            style={{ width: 227 }}
            onClick={handleVerifyCode}
          >
            Verify
          </button>
        </div>
        <button
          disabled={counter > 0}
          className={cn("verify__resend", {
            "verify__resend--disabled": counter > 0,
          })}
          onClick={resendCode}
        >
          {"Resend code " + (counter > 0 ? `(${counter})` : "")}
        </button>
        <span className="verify__text">
          We recommend checking your SPAM folder if you don’t receive it
        </span>
      </div>
    </div>
  )
}

export default connect(null, {
  setShowSideBar,
  deleteMechanismFromState,
  deleteMechanism,
})(VerifyContactMethod)
