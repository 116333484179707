import React, { useRef } from "react"
import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"

import {
  getDateWithTimezone,
  getHour,
  getMinutes,
  isToday,
} from "@utils/dateUtils"
import { getEndDate } from "@utils/calendarUtils"
import { images } from "@utils/imageUtils"

import ZoneHour from "./zoneHour"
import EventBlock from "./eventBlocks/event"

import {
  ZoneStyles as styles,
  CALENDAR_BELT_HEIGHT,
  zoneHoursColumn,
  currentTimeline,
  dateTimeline,
  dateSeparatorImage,
  calendarEventsOverlay,
} from "./timezonesStyles"

const getEventPositioning = (
  event,
  zoneWidth,
  zoneHeight,
  columnsAmount,
  zoneShift
) => {
  const rowHeight = zoneHeight / columnsAmount
  const startH = getDateWithTimezone(event.startDate).hour()
  const startM = getDateWithTimezone(event.startDate).minute()
  const endH = getDateWithTimezone(getEndDate(event)).hour()
  const endM = getDateWithTimezone(getEndDate(event)).minute()
  const endPosition = 1 + zoneShift - endM / 60
  const duration = endH + endM / 60 - (startH + startM / 60)

  

  return {
    height: event.width * rowHeight,
    width: duration * zoneWidth,
    top: (event.startColumn - 1) * rowHeight,
    right: endPosition * zoneWidth,
  }
}

export default class ZonesItem extends React.Component {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(nextProps) {
    const { zoneWidth, headerHeight } = this.props
    return (
      nextProps.zoneWidth !== zoneWidth ||
      nextProps.headerHeight !== headerHeight
    )
  }
  render() {
    const {
      selectedDate,
      // startDate,
      hour,
      zones,
      hourElements,
      onLayout,
      headerHeight,
      zoneHeight,
      zoneWidth,
      conflicts,
      useConflicts,
      handleCalendar,
      // isFromSuggestionDetails,
      itemDate,
      nowIndex,
      index: itemIndex,
      myZoneShiftExact,
      style,
    } = this.props

    return (
      <View style={[zoneHoursColumn(zoneWidth), style]} onLayout={onLayout}>
        {zones.map((zone, index) => {
          const isFullTimezone = zone.timezoneUnparsed % 1 === 0
          const zoneShift = isFullTimezone ? 0 : 0.5
          const currentHour = getHour(undefined, zone.timezoneName)
          const currentMinutes = getMinutes(undefined, zone.timezoneName)

          const currentTimeLinePosition = 1 + zoneShift - currentMinutes / 60
          const fromRightPosition = currentTimeLinePosition * zoneWidth
          let zoneHour = hour + zone.timezone
          // let zoneHour = hour

          if (zoneHour < 0) {
            zoneHour += 24
          } else if (zoneHour >= hourElements.length) {
            zoneHour -= hourElements.length
          }
          const isSelectedTimeToday =
            isToday(selectedDate) && itemIndex === nowIndex
          const isCurrentTimelineEnabled =
            isSelectedTimeToday &&
            currentTimeLinePosition &&
            currentHour === zoneHour
          const separatorHeight = zoneHeight * 2 + CALENDAR_BELT_HEIGHT
          return [
            headerHeight && (
              <View key={"H" + index} style={{ height: headerHeight }} />
            ),
            <ZoneHour
              key={`${index}`}
              hourIndex={index}
              hourRef={this.props.hourRef}
              hourElement={hourElements && hourElements[zoneHour]}
              width={zoneWidth}
              height={zoneHeight}
              isFullTimezone={isFullTimezone}
              isMyTZ={zone.isMyTZ}
              isDisabled={itemIndex < nowIndex + myZoneShiftExact}
              disabledWidth={isCurrentTimelineEnabled && fromRightPosition}
              isToday={isSelectedTimeToday}
              handleCalendar={handleCalendar}
              hasConflict={useConflicts && !!conflicts}
              // if we need to hide an events line, when conflicts is empty
            />,
            isCurrentTimelineEnabled && zone.isMyTZ && (
              <CurrentTimeline
                key={`currentTimeline ${index}`}
                index={index}
                fromRightPosition={fromRightPosition}
                headerHeight={headerHeight}
                zoneHeight={zoneHeight}
              />
            ),
            zoneHour === 0 && zone.isMyTZ && (
              <View
                key={"dateSeparator" + index}
                style={[
                  dateTimeline(headerHeight, separatorHeight),
                  !isFullTimezone && {
                    marginLeft: -zoneWidth / 2,
                  },
                ]}
              >
                <Image
                  style={dateSeparatorImage(separatorHeight)}
                  source={images.dateSeparator}
                />
                <View style={styles.dateTitleBlock}>
                  <Text style={styles.dateTitle}>{itemDate.date()}</Text>
                  <Text style={styles.dateMonthTitle}>
                    {itemDate.format("MMM")}
                  </Text>
                </View>
              </View>
            ),
          ]
        })}
      </View>
    )
  }
}

ZonesItem.propTypes = {
  hourElements: PropTypes.array,
  width: PropTypes.number,
  hour: PropTypes.number,
  zones: PropTypes.array,
  onLayout: PropTypes.func,
  headerHeight: PropTypes.number,
  zoneHeight: PropTypes.number,
  zoneWidth: PropTypes.number,
  // conflicts: PropTypes.object,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  useConflicts: PropTypes.bool,
  isFromSuggestionDetails: PropTypes.bool,
  handleCalendar: PropTypes.func,
  itemDate: PropTypes.object,
  nowIndex: PropTypes.number,
  index: PropTypes.number,
}

class CurrentTimeline extends React.PureComponent {
  static propTypes = {
    index: PropTypes.number,
    fromRightPosition: PropTypes.number,
    headerHeight: PropTypes.number,
    zoneHeight: PropTypes.number,
  }
  render() {
    const { index, fromRightPosition, headerHeight, zoneHeight } = this.props
    return (
      <View
        key={"timeline" + index}
        style={currentTimeline(fromRightPosition, headerHeight, zoneHeight * 2)}
      >
        <View style={styles.redDot} />
        <View style={styles.redLine} />
      </View>
    )
  }
}

export const ConflictsItem = ({
  useConflicts,
  conflicts,
  zoneHeight,
  zoneWidth,
  headerHeight,
  overlappingEvents = [],
  columnsAmount,
  isFullTZ,
}) => {
  const zoneShift = isFullTZ ? 0 : 0.5
  if (!useConflicts || !conflicts || !zoneHeight) return null
  return (
    <View
      style={calendarEventsOverlay({
        headerHeight,
        zoneHeight: zoneHeight + CALENDAR_BELT_HEIGHT,
        zoneWidth,
      })}
      pointerEvents="none"
    >
      {conflicts.map((conflict) => {
        const dimentions = getEventPositioning(
          conflict,
          zoneWidth,
          zoneHeight,
          columnsAmount,
          zoneShift
        )
        return (
          <EventBlock
            key={conflict.id}
            event={conflict}
            positionStyles={dimentions}
            hasConflict={overlappingEvents.includes(
              `${conflict.type}-${conflict.id}`
            )}
            // it's confict with current suggestion which we are creating
          />
        )
      })}
    </View>
  )
}

ConflictsItem.propTypes = {
  headerHeight: PropTypes.number,
  zoneHeight: PropTypes.number,
  zoneWidth: PropTypes.number,
  // conflicts: PropTypes.object,
  useConflicts: PropTypes.bool,
  columnsAmount: PropTypes.number,
  overlappingEvents: PropTypes.array,
  isFullTZ: PropTypes.bool,
}
