import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import React from "react"
import propTypes from "prop-types"

import {
  selectTeeupGoingPeopleIds,
  selectTeeupMightgoPeopleIds,
  selectTeeupGoingPeopleCount,
  selectTeeupMightGoPeopleCount,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import * as teeupActions from "@actions/teeupActions"

import TeeupUserStatusesComponent from "./TeeupUserStatusesComponent"

const TeeupUserStatusesContainer = ({
  goingPeopleIds,
  mightgoPeopleIds,
  userId,
  userStatusUpdated,
  goingPeopleCount,
  mightGoPeopleCount,
}) => {
  const selectStatus = (statusId) => {
    userStatusUpdated(statusId)
  }

  return (
    <TeeupUserStatusesComponent
      goingPeopleIds={goingPeopleIds}
      mightgoPeopleIds={mightgoPeopleIds}
      userId={userId}
      selectStatus={selectStatus}
      goingPeopleCount={goingPeopleCount}
      mightGoPeopleCount={mightGoPeopleCount}
    />
  )
}

const mapStateToProps = (state) => {
  const goingPeopleIds = selectTeeupGoingPeopleIds(state)
  const mightgoPeopleIds = selectTeeupMightgoPeopleIds(state)
  const userId = selectUserId(state)
  const goingPeopleCount = selectTeeupGoingPeopleCount(state)

  const mightGoPeopleCount = selectTeeupMightGoPeopleCount(state)

  return {
    goingPeopleIds,
    mightgoPeopleIds,
    userId,
    goingPeopleCount,
    mightGoPeopleCount,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...teeupActions,
    },
    dispatch
  )

TeeupUserStatusesContainer.propTypes = {
  userId: propTypes.number.isRequired,
  goingPeopleIds: propTypes.arrayOf().isRequired,
  mightgoPeopleIds: propTypes.arrayOf().isRequired,
  userStatusUpdated: propTypes.func.isRequired,
  goingPeopleCount: propTypes.number.isRequired,
  mightGoPeopleCount: propTypes.number.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeeupUserStatusesContainer)
