import { StyleSheet } from "react-native"

import { AppColors, AppSizes, AppFonts, Units } from "@theme/"

const DiscussionStyles = StyleSheet.create({
  FlatListContainer: {
    height: "100%",
  },
  FlatList: {
    overflowY: "scroll",
  },
  scrollView: {
    flex: 1,
  },
  scrollViewContainer: {
    flexGrow: 1,
  },
  headerTabs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tabTitle: {
    fontFamily: "greycliffcf-bold-salt",
    fontSize: 17,
    letterSpacing: "-0.34px",
    color: "#222222",
  },
  contentContainer: {
    flexGrow: 1,
    cursor: "text",
    userSelect: "text",
    overflowY: "scroll",
    height: "100%",
  },
  rowContainer: {},
  separator: {
    width: Units.rem(0.5),
  },
  splitter: {
    height: 1,
    backgroundColor: AppColors.brand.whiteGrey,
    marginHorizontal: Units.rem(1),
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Units.responsiveValue(16),
    paddingBottom: Units.responsiveValue(7),
    height: Units.responsiveValue(36),
  },
  title: {
    ...AppFonts.teeupCardTitle,
    color: AppColors.brand.black,
  },
  number: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.warmGrey,
  },
  chatContainer: {
    // transform: [{ scaleY: -1 }],
    flex: 1,
  },
  chatContainerPlaceholder: {
    flex: 1,
    // transform: [{ scaleY: -1 }],
  },
  timezonesContainer: {
    backgroundColor: AppColors.brand.lightGrey,
  },
  detailsContainer: {
    backgroundColor: AppColors.brand.lightGrey,
    flex: 1,
  },
  detailsLinesWrapper: {
    marginHorizontal: Units.responsiveValue(16),
    marginVertical: Units.responsiveValue(12),
  },
  detailsLine: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.black2,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.4),
    marginBottom: Units.responsiveValue(12),
  },
  suggestionBoxContainer: {
    backgroundColor: AppColors.brand.lightGrey,
    paddingVertical: Units.rem(0.5),
    paddingHorizontal: 0,
    zIndex: 1,
  },
  suggestionWrapperStyle: {
    flexGrow: 0,
    maxHeight: AppSizes.screen.heightHalf,
  },
  suggestionContainer: {
    flexDirection: "column",
    backgroundColor: AppColors.brand.white,
  },
  activeSendIcon: {
    tintColor: AppColors.brand.pink,
  },
  inactiveSendIcon: {
    tintColor: AppColors.brand.warmGrey2,
  },
  customSuggestionStyles: {
    backgroundColor: AppColors.brand.white,
    borderRadius: Units.rem(0.5),
    marginLeft: Units.rem(2),
    marginRight: Units.rem(0.75),
  },
  topBorder: {
    borderTopWidth: 1,
    borderColor: AppColors.brand.warmGrey2,
  },
  rowWrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  badgesContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  pinnedCountWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    borderWidth: Units.responsiveValue(1),
    borderRadius: Units.responsiveValue(12),
    height: Units.responsiveValue(24),
    marginLeft: Units.responsiveValue(4),
    paddingHorizontal: Units.responsiveValue(9),
    borderColor: "rgba(195, 209, 217, 1)",
  },
  pinImage: {
    tintColor: AppColors.brand.blueGrey2,
    height: 14,
    width: 14,
  },
  pinnedCount: {
    marginLeft: Units.responsiveValue(6),
    color: AppColors.brand.blueGrey2,
    ...AppFonts.mediumDemibold,
  },
  pinnedCountWrapperExpanded: {
    borderColor: AppColors.brand.grey193,
  },
  pinnedCountWrapperZero: {
    paddingHorizontal: Units.responsiveValue(16),
  },
  pinImageExpanded: {
    tintColor: AppColors.brand.grey134,
  },
  pinnedCountExpanded: {
    color: AppColors.brand.grey134,
  },
  unreadCountWrapper: {
    justifyContent: "center",
    borderRadius: Units.responsiveValue(12),
    height: Units.responsiveValue(24),
    paddingHorizontal: Units.responsiveValue(9),
    backgroundColor: AppColors.brand.pink,
  },
  unreadCount: {
    color: AppColors.brand.white,
    ...AppFonts.mediumDemibold,
  },
  skeletonText: {
    width: Units.responsiveValue(120),
    marginTop: Units.responsiveValue(12),
    height: Units.responsiveValue(20),
    alignSelf: "center",
  },
  skeletonInitialMessage: {
    marginTop: Units.responsiveValue(12),
    width: "90%",
    height: Units.responsiveValue(100),
    borderRadius: Units.responsiveValue(20),
    borderColor: "white",
    alignSelf: "center",
    position: "relative",
  },
  systemMessageGroupToggle: {
    height: Units.rem(1.75),
    justifyContent: "center",
    alignItems: "center",
    // transform: [{ scaleY: -1 }],
  },
  systemMessageGroupShowText: {
    color: AppColors.brand.warmGrey2,
    ...AppFonts.smallishDemibold,
  },
  systemMessageGroupHideText: {
    color: AppColors.brand.pink,
    ...AppFonts.smallishDemibold,
  },
  tabbarStyle: {
    height: Units.responsiveValue(40),
    paddingTop: 0,
  },
  pinkIcon: {
    tintColor: AppColors.brand.pink,
  },
})

const MessageRowStyles = StyleSheet.create({
  avatarWrapper: {
    width: 32,
  },
  container: {
    // transform: [{ scaleY: -1 }],
    flexDirection: "row",
    paddingHorizontal: Units.rem(1),
    // paddingBottom: Units.rem(0.75),
  },
  myContainer: {
    justifyContent: "flex-end",
  },
  middleContainer: {
    paddingHorizontal: Units.rem(0.5),
    flexDirection: "column",
    alignItems: "flex-start",
  },
  myMiddleContainer: {
    alignItems: "flex-end",
  },
  suggestionMiddleContainer: {
    flex: 1,
    paddingHorizontal: Units.rem(0.5),
    flexDirection: "column",
  },
  nameContainer: {
    flexDirection: "row",
    marginBottom: Units.rem(0.375),
    flex: 1,
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  myNameContainer: {
    justifyContent: "flex-end",
  },
  username: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.coolGrey,
  },
  contentContainer: {
    borderWidth: 2,
    borderColor: AppColors.brand.grey,
    backgroundColor: AppColors.brand.white,
    overflow: "hidden",
    marginBottom: 2,
  },
  myMessageContainer: {
    backgroundColor: AppColors.brand.blue2,
  },
  blueMessageContainer: {
    borderColor: AppColors.brand.blue2,
  },
  greenMessageContainer: {
    borderColor: AppColors.brand.green,
  },
  pinkMessageContainer: {
    borderColor: AppColors.brand.pink,
  },
  myContentContainer: {},
  childContainer: {
    margin: Units.responsiveValue(12),
    marginBottom: 0,
  },
  childMessage: {
    borderWidth: 0,
    transform: [],
    borderTopRightRadius: Units.rem(1.125),
    borderTopLeftRadius: 0,
    color: AppColors.brand.blackTwo,
    backgroundColor: AppColors.brand.lightGrey,
  },
  myChildMessage: {
    borderTopLeftRadius: Units.rem(1.125),
    borderTopRightRadius: 0,
    backgroundColor: "rgb(50, 166, 238)",
  },
  defaultChildMessage: {
    marginBottom: 0,
  },
  mySuggestionContentContainer: {
    borderColor: AppColors.brand.grey,
  },
  otherMessageRightCompensation: {
    marginRight: Units.rem(2),
  },
  message: {
    ...AppFonts.medium,
    color: AppColors.brand.blackTwo,
  },
  myMessage: {
    color: AppColors.brand.white,
  },
  messageContainer: {
    paddingHorizontal: Units.rem(1.125),
    paddingTop: Units.rem(0.625),
    paddingBottom: Units.rem(0.875),
  },
  timestamp: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.warmGrey,
    marginLeft: Units.rem(0.25),
  },
  suggestionTimeStamp: {
    ...AppFonts.smallBold,
    color: AppColors.brand.pink,
    paddingLeft: Units.rem(0.25),
  },
  suggestImage: {
    tintColor: AppColors.brand.pink,
    height: Units.rem(0.625),
    width: Units.rem(0.625),
  },
  suggestedTouchable: {
    paddingLeft: Units.rem(0.25),
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: AppColors.brand.lightBlue,
  },
  url: {
    color: AppColors.brand.blue,
  },
  unpinIconWrapper: (myMessage) => {
    const pinPosition = myMessage
      ? { left: -Units.responsiveValue(16) }
      : { right: -Units.responsiveValue(16) }
    return {
      top: -Units.responsiveValue(16),
      backgroundColor: "red",
      ...pinPosition,
    }
  },
  unpinIcon: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
    tintColor: AppColors.brand.warmGrey2,
  },
  mentionedUser: (myMessage) => ({
    color: myMessage ? AppColors.brand.white : AppColors.brand.pink,
    textDecorationColor: myMessage
      ? AppColors.brand.white
      : AppColors.brand.pink,
    textDecorationLine: myMessage ? "underline" : "none",
  }),
  head: () => ({
    borderRadius: Units.responsiveValue(20),
  }),
  default: (myMessage) => ({
    borderBottomLeftRadius: Units.responsiveValue(20),
    borderBottomRightRadius: Units.responsiveValue(20),
    borderTopLeftRadius: myMessage ? Units.responsiveValue(20) : 0,
    borderTopRightRadius: myMessage ? 0 : Units.responsiveValue(20),
    marginBottom: 12,
  }),
  tail: (myMessage) => ({
    borderBottomLeftRadius: Units.responsiveValue(20),
    borderBottomRightRadius: Units.responsiveValue(20),
    borderTopLeftRadius: myMessage ? Units.responsiveValue(20) : 0,
    borderTopRightRadius: myMessage ? 0 : Units.responsiveValue(20),
    marginBottom: 12,
  }),
  middle: (myMessage) => ({
    borderBottomLeftRadius: myMessage ? Units.responsiveValue(20) : 0,
    borderBottomRightRadius: myMessage ? 0 : Units.responsiveValue(20),
    borderTopLeftRadius: myMessage ? Units.responsiveValue(20) : 0,
    borderTopRightRadius: myMessage ? 0 : Units.responsiveValue(20),
  }),
  inputField: {
    flexDirection: "row",
    paddingHorizontal: 12,
  },
})

const StatusRowStyles = StyleSheet.create({
  mainContainer: {
    flexDirection: "column-reverse",
    alignItems: "center",
    // transform: [{ scaleY: -1 }],
    marginTop: Units.rem(0.75),
    marginHorizontal: Units.rem(1),
    width: "100%",
  },
  mainContainerFull: {
    alignItems: undefined,
  },
  boxContainer: {
    backgroundColor: AppColors.brand.lightBlue2,
    borderRadius: Units.rem(1.125),
    flexDirection: "column",
    paddingTop: Units.rem(0.375),
    paddingBottom: Units.rem(0.5),
    paddingHorizontal: Units.rem(1.125),
  },
  text: {
    ...AppFonts.smallishMedium,
    letterSpacing: -Units.rem(0.0125),
    color: AppColors.brand.grey3,
    textAlign: "center",
  },
  textBold: {
    fontFamily: AppFonts.family.bold,
  },
  textInitial: {
    textAlign: "left",
    display: "flex",
    flexWrap: "wrap",
    flexShrink: 2,
  },
  textEmoji: {},
  textContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatarsContainer: {
    marginBottom: -Units.rem(0.5),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  accessoryImage: {
    width: Units.rem(1),
    height: Units.rem(1),
  },
  imageMargin: {
    marginLeft: -Units.rem(0.5),
  },
  subviews: {
    flexDirection: "column",
  },
  messageContentContainer: {
    flexDirection: "column",
    marginTop: Units.rem(0.75),
  },
  leftImage: {
    marginRight: Units.rem(0.5),
  },
  flex: {
    flex: 1,
  },
  marginBottomSmall: {
    marginBottom: Units.rem(0.125),
  },
  marginBottomSmaller: {
    marginBottom: Units.rem(0.375),
  },
  marginBottom: {
    marginBottom: Units.rem(0.5),
  },
  gameplansSplitter: {
    height: 8,
  },
})

const CardStyles = StyleSheet.create({
  cardContent: {
    flex: 1,
    marginTop: Units.responsiveValue(12),
  },
  skeletonContainer: {
    overflow: "hidden",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
})

export { DiscussionStyles, MessageRowStyles, StatusRowStyles, CardStyles }
