import actionTypes from "./actionTypes"

const getInterests = (searchTerm) => ({
  type: actionTypes.GET_INTERESTS,
  payload: { searchTerm },
})
const getFollowedInterests = () => ({
  type: actionTypes.GET_FOLLOWED_INTERESTS,
})

const followInterests = (interestIds) => ({
  type: actionTypes.FOLLOW_INTERESTS,
  payload: { interestIds },
})

const unfollowInterest = (interestId) => ({
  type: actionTypes.UNFOLLOW_INTEREST,
  payload: { interestId },
})

const updateInterest = (interestId, payload) => ({
  type: actionTypes.UPDATE_INTEREST,
  payload: {
    interestId,
    ...payload,
  },
})

const createInterest = (value) => ({
  type: actionTypes.CREATE_INTEREST,
  payload: {
    value,
  },
})

const clearNewInterests = () => ({
  type: actionTypes.CLEAR_NEW_INTERESTS,
})

const removeNewInterest = (interestId) => ({
  type: actionTypes.REMOVE_NEW_INTEREST,
  payload: { interestId },
})

export default {
  getInterests,
  getFollowedInterests,
  followInterests,
  unfollowInterest,
  updateInterest,
  createInterest,
  clearNewInterests,
  removeNewInterest,
}
