import { StyleSheet } from "react-native"
import { AppColors, AppFonts } from "@theme"

const TAB_HEIGHT = 40
const BORDER_WIDTH = 2

export default StyleSheet.create({
  container: {
    flexBasis: 0,
    flex: 1,
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: AppColors.brand.white,
    marginRight: "10px",
    minWidth: "initial",
  },
  containerActive: {
    borderBottomColor: AppColors.bgGradient2,
  },
  hitBox: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: TAB_HEIGHT,
    width: TAB_HEIGHT,
    cursor: "pointer",
    padding: 6,
    width: "max-content",
  },
  iconWrap: {
    marginRight: 0,
  },
  title: {
    fontFamily: AppFonts.family.extrabold,
    fontSize: 12,
    letterSpacing: -0.24,
    textTransform: "uppercase",
    color: AppColors.brand.warmGrey2,
  },
  titleActive: {
    color: AppColors.brand.black2,
  },
})
