import React from "react"
import propTypes from "prop-types"
import { View } from "react-native"

import TimezonesContainer from "@components/timezones/timezonesContainer"
import { GameplanStyles } from "../createTeeupStyles"

export const TimeDateTab = ({
  startDate,
  endDate,
  isTemporalCustom,
  onDatesSelected,
  isTimeZoneEnabled,
  isMultiTimeZone,
  participants,
  people,
  showDatePicker,
  showTimePicker,
  showEndDatePicker,
  showEndTimePicker,
  closeOtherPickerIOS,
  timezoneWasScrolled,
  duration,
  checkForTodaysDate,
  isStartDateToday,
  startDateIndex,
  endDateIndex,
  dateArray,
  onZonesScrolled,
  refFunction,
  changeDuration,
  suggestion,
  handleToggleTimeDuration,
  handleCalendar,
  isDurationActive,
  isTimeDurationActive,
  showLayout,
  closeLayout,
  formattedSuggestion,
  suggestionType,
  skip,
  onDayClick,
  fromCreate,
}) => {
  return (
    <View style={GameplanStyles.flex}>
      <TimezonesContainer
        isTemporalCustom={isTemporalCustom}
        startDate={startDate}
        formattedSuggestion={formattedSuggestion}
        isDurationActive={isDurationActive}
        endDate={endDate}
        isEndEnabled={false}
        type={"date"}
        onDone={onDatesSelected}
        isTimeZoneEnabled={isTimeZoneEnabled}
        participants={participants}
        people={people}
        showDatePicker={showDatePicker}
        showTimePicker={showTimePicker}
        showEndDatePicker={showEndDatePicker}
        showEndTimePicker={showEndTimePicker}
        closeOtherPickerIOS={closeOtherPickerIOS}
        timezoneWasScrolled={timezoneWasScrolled}
        duration={duration}
        checkForTodaysDate={checkForTodaysDate}
        isStartDateToday={isStartDateToday}
        startDateIndex={startDateIndex}
        endDateIndex={endDateIndex}
        dateArray={dateArray}
        suggestion={suggestion}
        isTimeDurationActive={isTimeDurationActive}
        skip={skip}
        onDayClick={onDayClick}
        fromCreate={fromCreate}
      />
      {!skip && (
        <TimezonesContainer
          startDate={startDate}
          isTemporalCustom={isTemporalCustom}
          isDurationActive={isDurationActive}
          formattedSuggestion={formattedSuggestion}
          endDate={endDate}
          isEndEnabled={false}
          type={isMultiTimeZone ? "time" : "timeDuration"}
          suggestionType={suggestionType}
          onDone={onDatesSelected}
          isTimeZoneEnabled={isTimeZoneEnabled}
          isMultiTimeZone={isMultiTimeZone}
          participants={participants}
          people={people}
          showDatePicker={showDatePicker}
          showTimePicker={showTimePicker}
          showEndDatePicker={showEndDatePicker}
          showEndTimePicker={showEndTimePicker}
          closeOtherPickerIOS={closeOtherPickerIOS}
          onZonesScrolled={onZonesScrolled}
          duration={duration}
          changeDuration={changeDuration}
          refFunction={refFunction}
          checkForTodaysDate={checkForTodaysDate}
          isStartDateToday={isStartDateToday}
          suggestion={suggestion}
          handleToggleTimeDuration={handleToggleTimeDuration}
          handleCalendar={handleCalendar}
          isTimeDurationActive={isTimeDurationActive}
          showLayout={showLayout}
          closeLayout={closeLayout}
          fromCreate={fromCreate}
        />
      )}
    </View>
  )
}
TimeDateTab.propTypes = {
  startDate: propTypes.oneOfType([propTypes.object, propTypes.string]),
  endDate: propTypes.oneOfType([propTypes.object, propTypes.string]),
  onDatesSelected: propTypes.func,
  routes: propTypes.array,
  onIndexChange: propTypes.func,
  index: propTypes.number,
  isTimeZoneEnabled: propTypes.bool,
  customTime: propTypes.string,
  recommendations: propTypes.array,
  timezoneWasScrolled: propTypes.bool,
  isDurationActive: propTypes.bool,
  refFunction: propTypes.func,
  componentId: propTypes.string,
  onCancelPress: propTypes.func,
  dateArray: propTypes.array.isRequired,
  participants: propTypes.array,
  people: propTypes.oneOfType([propTypes.object, propTypes.array]),

  showDatePicker: propTypes.bool,
  showTimePicker: propTypes.bool,
  showEndDatePicker: propTypes.bool,
  showEndTimePicker: propTypes.bool,
  closeOtherPickerIOS: propTypes.func.isRequired,
  duration: propTypes.any,
  checkForTodaysDate: propTypes.func.isRequired,
  isStartDateToday: propTypes.bool,
  startDateIndex: propTypes.number,
  endDateIndex: propTypes.number,
  onZonesScrolled: propTypes.func.isRequired,
  changeDuration: propTypes.func.isRequired,
  suggestion: propTypes.object,
  isMultiTimeZone: propTypes.bool,
  handleToggleTimeDuration: propTypes.func,
  handleCalendar: propTypes.func,
  isTimeDurationActive: propTypes.bool,
}
