import React, { useState, useEffect, useRef, memo } from "react"
import { connect } from "react-redux"

import PropTypes from "prop-types"
import { View, Image, Text } from "react-native"
import UsersWithoutTimezones from "@ui/usersWithoutTimezones"
import { images } from "@utils/imageUtils"
import HeadersView from "./timezoneHeaders"
import Timeline from "./timeline"
import {
  ZoneStyles as styles,
  calendarBeltWrapper,
  calendarIcon,
  myCalendarText,
} from "./timezonesStyles"
import "./index.scss"

import ReactDOM from "react-dom"
import TimezonesTimePicker from "../TimezonesTimePicker"
import { floor } from "lodash"
import { FixedSizeList as List, areEqual } from "react-window"
import useDraggableScroll from "use-draggable-scroll"

import { selectBeforeCalendarDate } from "../../../selectors/calendar"

const ZonesView = ({
  isTemporalCustom,
  disableScroll,
  items,
  zones,
  zoneHeight,
  zoneWidth,
  initial,
  onHeaderLayout,
  renderItem,
  onScroll,
  darkTheme,
  durationWidth,
  duration,
  peopleWithUnknownTimeZones,
  halfDurationAdditionalPadding,
  hasConflict,
  headerHeight,
  selectedCalendarTitle,
  isSkeletonEnabled,
  containerRef,
  suggestionValues,
  handleTimePick,
  handleDurationPick,
  fromSuggestionPopup,
  itemWidth,
}) => {
  const LIST_WIDTH_CUSTOM = 898
  const LIST_WIDTH = 380

  const headerRef = useRef(null)
  const offsetIfDurationMoreThanTwelveHours = !disableScroll
    ? 0
    : (zoneHeight * zoneWidth) / 2

  const [isTimePickerOpened, setIsTimePickerOpened] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)

  const { onMouseDown } = useDraggableScroll(containerRef)
  useEffect(() => {
    containerRef.current.addEventListener("mousedown", (...args) => {
      onMouseDown(...args)
      setIsScrolling(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (isScrolling) {
        setIsScrolling(false)
      }
    }, 1800)
  }, [isScrolling])

  const Row = memo(({ index, style }) => {
    return renderItem(items[index], index, style)
  }, areEqual)

  const renderDurationPicker = () => {
    return (
      <div
        className="suggestion-duration-picker"
        onClick={() =>
          !fromSuggestionPopup ? setIsTimePickerOpened(true) : {}
        }
      >
        <img
          className="suggestion-duration-image"
          src={images.PurplePencilSVG}
          alt="duration icon"
        />
        <span className="suggestion-duration-title">
          {`${floor(duration / 60)}h `}{" "}
          {!!(duration % 60) && `${duration % 60}min`}
        </span>
      </div>
    )
  }

  const renderPopUpDuration = () => {
    ReactDOM.createPortal(
      <TimezonesTimePicker
        setIsTimePickerOpened={setIsTimePickerOpened}
        suggestionValues={suggestionValues}
        handleTimePick={handleTimePick}
        handleDurationPick={handleDurationPick}
        duration={duration}
      />,
      document.getElementById("modal-root")
    )
  }

  const shouldRenderDurationZone = !!(durationWidth && darkTheme)

  const styleViewWrapper = [styles.flex]
  if (isSkeletonEnabled) styleViewWrapper.push(styles.invisible)

  const styleViewInside = [styles.flex, styles.timezoneContainerBottomPadding]

  const styleDivInside = [
    { display: "flex", width: "100%", overflow: "hidden" },
  ]

  // if isTemporalCustom the listWidth value should be as same as modal width. i.e: 898px
  const listWidth = isTemporalCustom ? LIST_WIDTH_CUSTOM : LIST_WIDTH

  return (
    <div className={`zonesContainer ${isTemporalCustom && "--custom"}`}>
      {renderDurationPicker()}

      {isTimePickerOpened && renderPopUpDuration()}

      <View style={styleViewWrapper}>
        {shouldRenderDurationZone &&
          renderDurationZone(durationWidth, halfDurationAdditionalPadding)}
        <View style={styleViewInside}>
          {fromSuggestionPopup ? (
            <div ref={containerRef} style={styleDivInside}>
              {items.slice(0, 150).map(renderItem)}
            </div>
          ) : (
            <List
              className="infinite-list"
              onScroll={() => onScroll(containerRef)}
              outerRef={containerRef}
              height={headerRef?.current?.scrollHeight + 30}
              itemCount={items.length}
              itemSize={itemWidth}
              layout="horizontal"
              width={listWidth}
            >
              {Row}
            </List>
          )}

          <Timeline
            darkTheme={darkTheme}
            durationWidth={durationWidth}
            duration={duration}
            halfDurationAdditionalPadding={halfDurationAdditionalPadding}
            offsetIfDurationMoreThanTwelveHours={
              offsetIfDurationMoreThanTwelveHours
            }
          />
          <HeadersView
            zones={zones}
            onHeaderLayout={onHeaderLayout}
            zoneHeight={zoneHeight}
            initial={initial}
            darkTheme={darkTheme}
            duration={duration}
            isOnSuggestionDetails={disableScroll}
            fromSuggestionPopup={fromSuggestionPopup}
            headerRef={headerRef}
            isScrolling={isScrolling}
          />
          {!!zoneHeight && (
            <View
              style={[calendarBeltWrapper(headerHeight, zoneHeight)]}
              pointerEvents="none"
            >
              <View style={styles.row}>
                <Image
                  style={calendarIcon(hasConflict)}
                  source={images.calendarDay}
                  resizeMode="contain"
                />
                <Text style={myCalendarText(hasConflict)}>MY CALENDAR</Text>
              </View>
              <Text style={styles.selectedCalendarText}>
                {selectedCalendarTitle}
              </Text>
            </View>
          )}
        </View>
      </View>
      {!!peopleWithUnknownTimeZones.length &&
        !isSkeletonEnabled &&
        UsersWithoutTimezones({
          participants: peopleWithUnknownTimeZones,
          withDarkTheme: darkTheme,
        })}
    </div>
  )
}

ZonesView.propTypes = {
  darkTheme: PropTypes.bool,
  isVisible: PropTypes.bool,
  disableScroll: PropTypes.bool,
  contentPadding: PropTypes.number,

  zoneHeight: PropTypes.number,
  zoneWidth: PropTypes.number,
  durationWidth: PropTypes.number,
  initial: PropTypes.bool,
  items: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,

  onHeaderLayout: PropTypes.func.isRequired,
  onListLayout: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onScrollBeginDrag: PropTypes.func.isRequired,
  onMomentumScrollEnd: PropTypes.func.isRequired,
  onListRef: PropTypes.func.isRequired,
  getItemLayout: PropTypes.func.isRequired,
  isReady: PropTypes.bool,
  isScrollOnPastTime: PropTypes.bool,
  isSkeletonEnabled: PropTypes.bool,
  skeletonAnimation: PropTypes.object,

  peopleWithUnknownTimeZones: PropTypes.array,
  duration: PropTypes.number,
  halfDurationAdditionalPadding: PropTypes.object,
  hasConflict: PropTypes.bool,
  selectedCalendarTitle: PropTypes.string,
  headerHeight: PropTypes.number,
  onContentWidthChange: PropTypes.func,
}

const renderDurationZone = (durationWidth, halfDurationAdditionalPadding) => (
  <View
    style={[styles.splitterContainer, halfDurationAdditionalPadding]}
    pointerEvents="none"
  >
    <View
      style={[
        styles.durationSplitter,
        styles.durationSplitterDarkTheme,
        durationWidth && {
          width: durationWidth,
        },
      ]}
    >
      <View
        style={[
          styles.durationSplitterBorder,
          styles.durationSplitterLeftBorder,
          styles.durationSplitterBorderDarkTheme,
        ]}
      >
        <View
          style={[
            styles.durationSplitterInner,
            styles.durationSplitterInnerDarkTheme,
          ]}
        />
        <View style={[styles.leftTriangle, styles.leftTriangleDarkTheme]}>
          {/* <Svg height={'6'} width={'6'} preserveAspectRatio="none">
                        <Polygon
                            points="0,0 0,6 6,0"
                            fill={AppColors.brand.white70}
                        />
                    </Svg> */}
        </View>
        <View
          style={[
            styles.leftTriangleBottom,
            styles.leftTriangleDarkThemeBottom,
          ]}
        >
          {/* <Svg height={'6'} width={'6'} preserveAspectRatio="none">
                        <Polygon
                            points="0,0 0,6 6,6"
                            fill={AppColors.brand.white70}
                        />
                    </Svg> */}
        </View>
      </View>
      <View
        style={[
          styles.durationSplitterBorder,
          styles.durationSplitterRightBorder,
          styles.durationSplitterBorderDarkTheme,
        ]}
      >
        <View
          style={[
            styles.durationSplitterInner,
            styles.durationSplitterInnerDarkTheme,
          ]}
        />
        <View
          style={[
            styles.rightTriangleBottom,
            styles.rightTriangleDarkThemeBottom,
          ]}
        >
          {/* <Svg height={'6'} width={'6'} preserveAspectRatio="none">
                        <Polygon
                            points="6,0 6,6 0,6"
                            fill={AppColors.brand.white70}
                        />
                    </Svg> */}
        </View>
        <View style={[styles.rightTriangle, styles.rightTriangleDarkTheme]}>
          {/* <Svg height={'6'} width={'6'} preserveAspectRatio="none">
                        <Polygon
                            points="0,0 6,0 6,6"
                            fill={AppColors.brand.white70}
                        />
                    </Svg> */}
        </View>
      </View>
    </View>
  </View>
)

const mapStateToProps = (state) => {
  const isBeforeCalendarDate = selectBeforeCalendarDate(state)

  return {
    isBeforeCalendarDate,
  }
}

export default connect(mapStateToProps, null)(ZonesView)
