import actionTypes from "../actions/actionTypes"

const initialState = {
  invitees: [],
  invited: [],
  draftInvitees: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACTS:
      return {
        ...state,
        invitees: action.invitees,
      }
    case actionTypes.SET_DRAFT_INVITEES:
      return {
        ...state,
        draftInvitees: action.invitees,
      }
    default:
      return state
  }
}
