import React, { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import cn from "classnames"
import moment from "moment"

import AddInvitees from "../../components/AddInvitees"
import TabInvitees from "../../components/CreateTeeUp/addInviteesTabs"
import CooEVerse from "../../components/CooEVerse"
import ViewByTeeups from "../../components/ViewByTeeups"
import GameplanContainer from "../../components/CreateTeeUp/gameplanContainer"
import TeeupPermissionsNd from "./TeeUpPermissionsNd"

import { GAMEPLAN_OPTIONS } from "../../utils/gamePlanUtils"
import { images } from "@utils/imageUtils"
import { getBase64, getRandomBase64Image } from "@utils/imageUtils"
import { formatInvitees } from "@utils/contactUtils"

import { closeCreateTeeup, createTeeup } from "../../actions/createTeeupActions"
import { setTitle, setActiveInviteesTab } from "@actions/commonActions"
import { getContacts } from "@actions/createTeeupActions"
import { fetchTeeups } from "../../actions/teeupActions"
import { fetchRequests } from "../../actions/requestActions"

import { selectUserId } from "../../selectors/user"
import { selectTeeupGameplans } from "../../selectors/teeups"
import { selectDraftInvitees } from "@selectors/invitees"
import { selectActiveTeeup } from "../../selectors/activeTeeup"
import { getDraftTeeup } from "@selectors/createTeeUp"
import { selectActiveInviteesTab } from "@selectors/common"
import { selectUserInfo } from "@selectors/user"

import InvitePeopleNd from "../../components/InvitePeopleNd"

import { inviteToTeeup } from "@middlewares/backendMiddleware"

import { useHistory } from "react-router-dom"

import "./index.scss"

const GamePlanSuggestNd = ({ gameplan, index, onGameplanRowClick }) => {
  const [isHover, setIsHover] = useState(false)
  const emptySuggestText =
    gameplan.title === "When"
      ? "Suggest the Date and Time"
      : "Suggest the place"

  const getGamePlanWhen = () => {
    const gamePlanStartDate = moment(
      new Date(gameplan.suggestions[0].startDate._i)
    ).format("ddd, MMM D")
    const gamePlanStartDateHours = moment(
      new Date(gameplan.suggestions[0].startDate._i)
    ).format("h:mm")
    const gamePlanEndDateHours = moment(
      new Date(gameplan.suggestions[0].endDate)
    ).format("h:mm A")

    return (
      <>
        {gamePlanStartDate}
        <br />
        {gamePlanStartDateHours} - {gamePlanEndDateHours}
      </>
    )
  }

  return (
    <div className="suggestNd">
      <div className="suggestNd__content">
        <div className="suggestNd__type">
          <img src={gameplan.icon} alt="" style={{ marginRight: 5 }} />
          <span className="suggestNd__title">{gameplan.title}</span>
        </div>
        <h4 className="suggestNd__decision">
          {gameplan.suggestions.length > 0 ? (
            gameplan.type === "when" ? (
              getGamePlanWhen()
            ) : (
              <>
                {gameplan.suggestions[0].value},
                <br />
                {gameplan.suggestions[0].details}
              </>
            )
          ) : (
            "No Decision Yet"
          )}
        </h4>
        {gameplan.suggestions.length < 1 && (
          <p className="suggestNd__suggestion">{emptySuggestText}</p>
        )}
        <div
          className="suggestNd__button"
          onMouseOver={() => {
            setIsHover(true)
          }}
          onMouseLeave={() => {
            setIsHover(false)
          }}
          onClick={() => onGameplanRowClick(gameplan, index)}
        >
          <img
            src={
              isHover
                ? images.ndGamePlanSuggestActive
                : images.ndGamePlanSuggest
            }
            style={{ marginRight: 5 }}
          />
          <span className="suggestNd__text">
            Suggest {gameplan.title === "When" ? "When" : "Where"}
          </span>
        </div>
      </div>
    </div>
  )
}

const CreateTeeUpNd = ({
  userInfo,
  draftInvitees,
  close,
  userId,
  createTeeUp,
  activeInviteesTab,
  setActiveInviteesTab,
  getContacts,
  fetchRequests,
  fetchTeeups,
}) => {
  // Input values
  const [image, setImage] = useState()
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  // ---

  const [invitees, setInvitees] = useState(draftInvitees)
  const [content, setContent] = useState(null)
  const [isOptionOpened, setIsOptionOpened] = useState(false)
  const [isGameplanOpened, setIsGameplanOpened] = useState(false)
  const [initialGameplans, setInitialGameplans] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [gameplanData, setGameplanData] = useState([
    {
      icon: images.ndGamePlanWhen,
      isPrimary: true,
      suggestions: [],
      title: "When",
      type: "when",
    },
    {
      icon: images.ndGamePlanWhere,
      isPrimary: true,
      suggestions: [],
      title: "Where",
      type: "where",
    },
  ])
  const [settings, setSettings] = useState({
    allowInviteOthers: true,
    allowChangeGameplan: true,
    allowDecideGameplan: true,
    allowChangeTitle: true,
    allowSetPlanning: true,
    allowSetEnded: true,
    allowInviteLink: true,
    allowJoin: false,
  })

  // Hover/open states
  const [isHover, setIsHover] = useState(false)
  const [isInviteesOpen, setIsInviteesOpen] = useState(false)
  const [isPermissionsOpen, setIsPermissionsOpen] = useState(false)
  const [isAdded, setIsAdded] = useState(true)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  // ---

  //errors
  const [isInputError, setIsInputError] = useState(false)
  //---

  const optionRef = useRef()
  const history = useHistory()

  const isValidated = invitees.length > 0 && name.length > 0 && !isInputError

  const openOption = () => setIsOptionOpened(true)
  const closeOption = () => setIsOptionOpened(false)

  useEffect(() => {
    (async () => {
      const image = await getRandomBase64Image()
      setImage(image)
    })()
    fetchTeeups()
    getContacts()
    fetchRequests() //loading user contacts
  }, [])

  useEffect(() => {
    name.length >= 54 ? setIsInputError(true) : setIsInputError(false)
  }, [name]) //email char limit

  const deleteInviteUser = (id) => {
    setInvitees(invitees.filter((user) => user.id !== id))
  }

  const onGameplanRowClick = (gameplan, index) => {
    if (
      gameplan.type === GAMEPLAN_OPTIONS.startsWhen ||
      gameplan.type === GAMEPLAN_OPTIONS.whenWorks
    ) {
      const props = {
        initialGameplan: gameplan,
        callback: ({ startDate, peopleCount, dates }) => {
          let updatedGameplans = this.props.teeup.gameplans.map(
            (singleGameplan, singleGameplanIndex) => {
              if (index === singleGameplanIndex) {
                return {
                  ...singleGameplan,
                  startDate,
                  peopleCount: parseInt(peopleCount),
                  dates,
                  suggestions: dates,
                  availability: [],
                }
              }
              return singleGameplan
            }
          )
          this.props.updateGameplanOptions({
            gameplans: updatedGameplans,
          })
        },
      }
    } else {
      openOption()
      setIsGameplanOpened(true)
      setContent(gameplanContent(gameplan, index))
    }
  }

  const handleDecidePress = (newGameplan) => () => {
    const {
      updateGameplanOptions,
      teeup: { gameplans },
    } = this.props
    const selectedSuggestion = newGameplan?.suggestions[0]
    if (!selectedSuggestion) return null

    const updatedGameplans = gameplans.map((gameplan) => {
      if (newGameplan.type === gameplan.type) {
        const updatedSuggestions = gameplan.suggestions.map((suggestion) => ({
          ...suggestion,
          decided: suggestion.selected && !suggestion.decided,
        }))
        return {
          ...gameplan,
          suggestions: updatedSuggestions,
        }
      } else return gameplan
    })

    updateGameplanOptions({
      gameplans: updatedGameplans,
    })
  }

  const renderGameplanDefaults = () => {
    return (
      <>
        {gameplanData.map((gameplan, index) => (
          <React.Fragment key={index}>
            <GamePlanSuggestNd
              gameplan={gameplan}
              onGameplanRowClick={onGameplanRowClick}
              onDecidePress={handleDecidePress(gameplan)}
              index={index}
            />
          </React.Fragment>
        ))}
      </>
    )
  }

  const gameplanContent = (gameplan, index) => {
    return (
      <>
        <GameplanContainer
          fromCreate={false}
          closeCreate={() => {
            closeOption()
            setIsGameplanOpened(false)
          }}
          suggestions={gameplan["suggestions"]}
          index={index}
          gameplanData={gameplanData}
          setGameplanData={setGameplanData}
          {...gameplan}
          allowPutOnGameplan={true}
          isFromCreateTeeup={true}
          initialGameplans={initialGameplans}
          setInitialGameplans={setInitialGameplans}
        />
      </>
    )
  }

  const handleChangeSettings = (key, value) => {
    const newSettings = {
      ...settings,
      [key]: value,
    }
    setSettings(newSettings)
  }

  const create = async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    const otherData = {}
    const gameplanOptions = [
      {
        type: "where",
        title: "test",
      },
    ]
    const emails = []
    const phones = []
    const manualInvitees = invitees.filter(
      (invitee) => invitee.userType === "manual"
    )
    manualInvitees.forEach((element) => {
      if (element.contactType === "phone") phones.push(element.value)
      if (element.contactType === "email") emails.push(element.value)
    })
    const dataForManualInvitees = { phones, emails }
    const formattedInvites = formatInvitees(invitees)
    otherData.photo = image || (await getRandomBase64Image("randomSmaller"))

    await createTeeUp(
      userId,
      name.trim(),
      message.trim() || " ",
      invitees,
      gameplanData,
      dataForManualInvitees,
      otherData
    )
    // Timeout to wait until socket connection is established
    await new Promise((resolve) =>
      setTimeout(() => resolve(inviteToTeeup(formattedInvites)), 5000)
    )
    close()
  }

  return (
    <div
      className="create-teeup"
      style={{
        overflowY: isPermissionsOpen && "hidden",
      }}
    >
      {isOptionOpened && (
        <div className="create-teeup__popup-wrapper">
          <div
            ref={optionRef}
            className="create__modal--window settings__modal settings__modal-content"
            style={{
              overflow: "auto",
              width: "380px",
              minHeight: "300px",
              height: "100%",
              maxHeight: "75vh",
              padding: 0,
              backgroundColor: "#ffffff",
              marginLeft: 25,
            }}
            onClick={(event) => event.stopPropagation()}
          >
            {content}
          </div>
        </div>
      )}
      {isInviteModalOpen && (
        <InvitePeopleNd
          setInvitees={setInvitees}
          invitees={invitees}
          onClose={setIsInviteModalOpen}
          setIsAdded={setIsAdded}
        />
      )}
      {isPermissionsOpen && (
        <TeeupPermissionsNd
          setIsPermissionsOpen={setIsPermissionsOpen}
          settings={settings}
          onChangeSettings={handleChangeSettings}
        />
      )}
      <div className="create-teeup__wrapper">
        <h2 className="create-teeup__title">Create TeeUp</h2>

        <div className="create-teeup__content">
          <div
            className="create-teeup__avatar"
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            <div className="create-teeup__image-wrapper">
              <img src={images.ndCreateTeeupPhoto} />
              <input
                className="create-teeup__image-input"
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0]
                  getBase64(file).then((base64) => {
                    setImage(base64)
                  })
                }}
              />
            </div>
          </div>
          <div className="create-teeup__top">
            <div className="create-teeup__name-wrapper">
              <input
                className="create-teeup__name"
                type="text"
                placeholder="Add a Title"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
              <img src={images.ndNameStar} className="create-teeup__star" />
            </div>
            <div
              className="create-teeup__settings"
              onClick={() => {
                setIsPermissionsOpen(true)
              }}
            >
              <img src={images.ndCreateTeeupSettings} />
            </div>
            {isInputError && (
              <div className="create-teeup__name--error">
                The title should be less than 54 characters
              </div>
            )}
          </div>
          <div className="create-teeup__people">
            <h3 className="create-teeup__subtitle">People</h3>
            <div className="create-teeup__link">
              <img
                src={images.ndCreateTeeupInviteLink}
                style={{ marginRight: 5 }}
              />
              Shareable link is {settings.allowInviteLink ? "On" : "Off"}
            </div>
            <div
              className={cn({
                "create-teeup__invite-wrapper": true,
                "create-teeup__invite-wrapper--active": invitees.length > 0,
                "create-teeup__invite-wrapper--open": isInviteesOpen,
              })}
            >
              {invitees.length > 0 && (
                <img
                  src={images.ndInviteDownArrow}
                  className={cn({
                    "create-teeup__invite-arrow": true,
                    "create-teeup__invite-arrow--close": isInviteesOpen,
                  })}
                  onClick={() => {
                    setIsInviteesOpen(!isInviteesOpen)
                  }}
                />
              )}
              <div
                className={cn({
                  "create-teeup__invitees": invitees.length > 0,
                  "create-teeup__invitees--open":
                    isInviteesOpen && invitees.length > 0,
                })}
              >
                <div style={{ display: "flex" }}>
                  <div
                    className={cn("create-teeup__me", {
                      "create-teeup__me--open": invitees.length > 0,
                    })}
                    style={{
                      backgroundImage: `url(${
                        userInfo?.avatar || userInfo.user?.avatar
                      })`,
                    }}
                  >
                    <img src={images.ndCrown} className="create-teeup__crown" />
                  </div>
                  {invitees.length > 0 && isInviteesOpen && (
                    <div className="create-teeup__me-info">
                      <h5 className="create-teeup__me-name">{userInfo.name}</h5>
                      <p className="create-teeup__me-email">{userInfo.email}</p>
                    </div>
                  )}
                </div>
                {invitees.length > 0 &&
                  invitees.map(
                    ({
                      avatar,
                      firstName,
                      name,
                      userType,
                      label,
                      emails,
                      id,
                    }) => (
                      <div
                        className={cn("create-teeup__user", {
                          "create-teeup__user--open": isInviteesOpen,
                        })}
                        key={avatar + name}
                      >
                        {avatar ? (
                          <div
                            className="create-teeup__user-avatar"
                            style={{ backgroundImage: `url(${avatar})` }}
                          ></div>
                        ) : (
                          <div className="create-teeup__user-avatar create-teeup__user-manual">
                            {name ? name[0] : label[0]}
                          </div>
                        )}
                        {!isInviteesOpen && (
                          <div
                            className="create-teeup__user-avatar--cross"
                            onClick={() => {
                              deleteInviteUser(id)
                            }}
                          >
                            <img
                              src={images.ndInviteesCrossActive}
                              style={{
                                width: 8,
                                height: 8,
                              }}
                              className="create-teeup__user-avatar--icon"
                            />
                          </div>
                        )}
                        {isInviteesOpen && (
                          <>
                            <div className="create-teeup__user-info">
                              <h5 className="create-teeup__user-name">
                                {name ? name : label}
                              </h5>
                              <p className="create-teeup__user-email">
                                {emails.length > 0 && emails[0].email}
                              </p>
                            </div>
                            <div
                              className="create-teeup__user-cross--wrapper"
                              onClick={() => {
                                deleteInviteUser(id)
                              }}
                            >
                              <div className="create-teeup__user-cross"></div>
                            </div>
                          </>
                        )}
                      </div>
                    )
                  )}
              </div>
              <div
                className={cn("create-teeup__invite", {
                  "create-teeup__invite--active": invitees.length > 0,
                })}
                onClick={() => {
                  // openOption()
                  // setContent(inviteesContent)
                  setIsInviteModalOpen(true)
                }}
                onMouseOver={() => {
                  setIsHover(true)
                }}
                onMouseLeave={() => {
                  setIsHover(false)
                }}
              >
                <img
                  src={
                    isHover
                      ? images.ndCreateTeeupInviteActive
                      : images.ndCreateTeeupInvite
                  }
                  style={{ marginRight: 5 }}
                />
                Invite
              </div>
            </div>
          </div>
          <div className="create-teeup__message">
            <h3 className="create-teeup__subtitle">Start message</h3>
            <textarea
              className="create-teeup__text"
              placeholder="Add a message to start conversation"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
            />
          </div>
          <div className="create-teeup__gameplan">
            <h3 className="create-teeup__subtitle">Game Plan</h3>
            {renderGameplanDefaults()}
          </div>
          <div className="create-teeup__bottom">
            <button
              type="button"
              className="create-teeup__cancel"
              onClick={(e) => {
                e.preventDefault()
                history.push("/teeUps")
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="create-teeup__suggest"
              onClick={create}
              disabled={!isValidated || isLoading}
              style={{ opacity: isValidated || isLoading ? 1 : 0.5 }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: selectUserInfo(state),
  userId: selectUserId(state),
  draftGameplans: selectTeeupGameplans(state),
  draftInvitees: selectDraftInvitees(state),
  activeTeeUp: selectActiveTeeup(state),
  draftTeeup: getDraftTeeup(state),
  activeInviteesTab: selectActiveInviteesTab(state),
})

const mapDispatchToProps = (dispatch) => ({
  close: bindActionCreators(closeCreateTeeup, dispatch),
  createTeeUp: bindActionCreators(createTeeup, dispatch),
  setTitle: bindActionCreators(setTitle, dispatch),
  setActiveInviteesTab: bindActionCreators(setActiveInviteesTab, dispatch),
  getContacts: bindActionCreators(getContacts, dispatch),
  fetchTeeups: bindActionCreators(fetchTeeups, dispatch),
  fetchRequests: bindActionCreators(fetchRequests, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeeUpNd)
