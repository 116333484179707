import React, { useEffect, useState } from "react"
import TeeUps from "@components/TeeUps"
import TeeUpsOverview from "@components/TeeUpsOverview"
import { fetchTeeups } from "@actions/teeupActions"
import { setTitle } from "@actions/commonActions"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setupTeeupListSocket } from "@middlewares/backendMiddleware"
import { selectUserId, selectToken } from "@selectors/user"
import { selectIsTeeupListVisible, selectActiveTeeups } from "@selectors/teeups"
import CalendarSettingsContainer from "@components/more/calendarSettings"
import { getUserSettings } from "@actions/userActions"
import NudgeSelectParticipantsContainer from "@components/nudges/nudgeSelectParticipantsContainer"
import { selectIsNudgePeopleOpen, selectPeopleSideBar } from "@selectors/common"
import { fetchRequests } from "@actions/requestActions"
import cn from "classnames"
import { getContacts } from "@actions/createTeeupActions"
import { setTeeupActive } from "@actions/activeTeeupActions"
import { initialTeeup } from "@config/enums"

import { useParams } from "react-router-dom"

const TeeUpsPage = (props) => {
  const {
    fetchTeeups,
    activeTeeUp,
    userId,
    token,
    isTeeupListVisible,
    setTitle,
    isNudgePeopleOpen,
    teeups,
    fetchRequests,
    peopleSideBar,
    getContacts,
  } = props
  const [isCalendarSettingsOpen, setIsCalendarSettingsOpen] = useState(false)

  const param = useParams()

  useEffect(() => {
    if (teeups.length === 0) {
      fetchTeeups()
    }
    getContacts()

    setupTeeupListSocket(token, userId)

    return () => {
      setTeeupActive(initialTeeup)
    }
  }, [])

  useEffect(() => {
    if (activeTeeUp.name) {
      setTitle(activeTeeUp.name)
    }
  }, [activeTeeUp])

  return (
    <div
      style={{ overflow: "initial" }}
      className={cn("page", {
        page__expandedPeople: peopleSideBar,
      })}
    >
      <TeeUps
        param={param.id}
        openCalendarSettings={async () => {
          await getUserSettings()
          setIsCalendarSettingsOpen(true)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    activeTeeUp: selectActiveTeeup(state),
    userId: selectUserId(state),
    token: selectToken(state),
    isTeeupListVisible: selectIsTeeupListVisible(state),
    isNudgePeopleOpen: selectIsNudgePeopleOpen(state),
    teeups: selectActiveTeeups(state),
    peopleSideBar: selectPeopleSideBar(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTeeups: bindActionCreators(fetchTeeups, dispatch),
  setTitle: bindActionCreators(setTitle, dispatch),
  fetchRequests: bindActionCreators(fetchRequests, dispatch),
  getContacts: bindActionCreators(getContacts, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeeUpsPage)
