import React from "react"
import { View, Text, Image, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"
import _ from "lodash"
import {
  selectTeeupReactionsBySuggestionId,
  selectTeeupPeople,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { connect } from "react-redux"
import { selectActiveTeeup } from "@selectors/activeTeeup"

import I18n from "@i18n"
import SuggestionNudge from "@components/nudges/suggestionNudge"
import { images } from "@utils/imageUtils"
import {
  teeupListNudgeTypes,
  teeupUserStatus,
  teeupUserStatusKeys,
} from "@configs/enums"

import { DialogButtonWithIcon } from "../dialogButtons/dialogButtons"
import CloseButton from "../dialogButtons/closeButton"
import { SuggestionNudgeResponseStyles as styles } from "./styles"
import { updateUserStatus } from "@actions/requestActions"
import { userStatusTypes, teeupStatusTypes } from "../../../config/mappings"
import { bindActionCreators } from "redux"

const SuggestionNudgeResponseView = ({
  fromTeeupList,
  subTypeId,
  nudgeIssuer,
  suggestionResponse,
  onSave,
  handleClose,
  suggestion,
  isTeeupSocketConnected,
  reactions,
  currentUserId,
  teeup,
  people,
  updateUserStatus,
}) => {
  let userStatus = teeupUserStatusKeys.invited
  let additionalCount = 0
  let userParticipant = people[currentUserId]

  if (userParticipant) {
    userStatus = userParticipant.status
    additionalCount = userParticipant.additionalCount || 0
  }

  const includesAlreadyResponded = !!reactions.find(
    (reaction) =>
      reaction &&
      reaction.reactedBy &&
      reaction.reactedBy.includes(currentUserId)
  )

  const handleUserStatusSelected = (chosenStatus, additionalCount) => {
    if (!chosenStatus) {
      return
    }

    const userStatusKey = teeupUserStatus[chosenStatus]
      ? userStatus
      : teeupUserStatus.reverse[chosenStatus]
    let statusId = userStatusTypes.reverse[userStatusKey]

    updateUserStatus(teeup.id, statusId, additionalCount)
  }

  const handleClickStatus = (item) => {
    if (userStatus !== item.status) {
      handleUserStatusSelected(item.title)
    } else {
      //     // Send an empty string as a parameter to dismiss the overlay
      handleUserStatusSelected("")
    }
  }

  const renderItem = (item, index) => {
    const reaction = reactions.find((rt) => rt.reactionId === item.id)
    const alreadyResponded =
      reaction &&
      reaction.reactedBy &&
      reaction.reactedBy.includes(currentUserId)

    const isLast = index === suggestionResponse - 1
    const buttonImageProps = item.icon
      ? {
          icon: item.icon,
          useIconComponent: true,
        }
      : {
          primaryIcon: item.image,
          primaryIconStyles: styles.nudgeStatusIcon,
        }

    return (
      <DialogButtonWithIcon
        key={item.id}
        // containerStyles={{
        //     ...(!isLast && styles.statusItemMargin),
        // }}
        containerStyles={!isLast && styles.statusItemMargin}
        {...buttonImageProps}
        title={alreadyResponded ? `Still ${item.title}` : item.title}
        // title={alreadyResponded ? `Still` : 'Hello'}
        // onClickPrimary={onSave(alreadyResponded ? -1 : item.id)}
        onClickPrimary={() => {
          handleClickStatus(item)
          onSave(alreadyResponded ? -1 : item.id)()
        }}
        includesAlreadyResponded={includesAlreadyResponded}
      />
    )
  }

  const renderHeader = () => {
    return (
      <View style={styles.titleContainer}>
        <View style={styles.senderContainer}>
          <Image style={styles.senderIcon} source={images.nudge} />
          <Text style={styles.sender}>
            {nudgeIssuer.username || nudgeIssuer.name} asked me
          </Text>
        </View>
        <View style={styles.questionContainer}>
          <Text style={styles.question}>
            {
              I18n.nudgePromptContent[teeupListNudgeTypes.reverse[subTypeId]]
                .action
            }
          </Text>
        </View>
        {subTypeId === teeupListNudgeTypes.suggestion && (
          <View style={styles.suggestionNudgeContainer}>
            <SuggestionNudge onNudgesScreen suggestion={suggestion} />
          </View>
        )}

        <CloseButton onClose={handleClose} />
      </View>
    )
  }

  const hideButtonsForSuggestionType =
    _.isEmpty(suggestion) && subTypeId === teeupListNudgeTypes.suggestion
  const hideButtonsForFromTeeupList = fromTeeupList && !isTeeupSocketConnected

  return (
    <View style={{ width: "320px", height: "391px", borderRadius: "10px" }}>
      {renderHeader()}
      {!hideButtonsForFromTeeupList && !hideButtonsForSuggestionType && (
        <View style={styles.statusesContainer}>
          {suggestionResponse.map(renderItem)}
          <span
            style={{
              width: "130px",
              height: "14px",
              fontSize: "12px",
              fontFamily: "greycliffcf-extrabold-salt",
              color: "rgb(244, 40, 98)",
              textTransform: "uppercase",
              position: "absolute",
              bottom: "29px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            View in highlights
          </span>
        </View>
      )}
    </View>
  )
}

SuggestionNudgeResponseView.propTypes = {
  fromTeeupList: PropTypes.bool,
  subTypeId: PropTypes.number,
  suggestionResponse: PropTypes.array,
  suggestion: PropTypes.object,
  onSave: PropTypes.func,
  handleClose: PropTypes.func,
  nudgeIssuer: PropTypes.object,
  showViewInHighlightsButton: PropTypes.bool,
  isTeeupSocketConnected: PropTypes.bool,
  reactions: PropTypes.array,
  currentUserId: PropTypes.number,
}

const mapStateToProps = (state, props) => {
  const { suggestion } = props
  const suggestionId = suggestion ? suggestion.id : null
  const reactions =
    suggestion?.reactions ||
    selectTeeupReactionsBySuggestionId(suggestionId)(state)
  const currentUserId = selectUserId(state)
  const teeup = selectActiveTeeup(state)
  const people = selectTeeupPeople(state)

  return {
    reactions,
    currentUserId,
    teeup,
    people,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUserStatus: bindActionCreators(updateUserStatus, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestionNudgeResponseView)
