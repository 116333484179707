import React, { memo } from "react"
import PropTypes from "prop-types"
import TitleMessage from "../statusMessage/titleMessage"

const GameplanUpdateMessage = ({ item, people, suggestions }) => {
  const { isDecided, suggestionId } = item
  const suggestion = suggestions[suggestionId] || {}
  const { type } = suggestion

  const user = people[item.senderId]
  let text = `${user?.username || user?.name} marked ${type} as ${
    isDecided ? "Decided" : "Undecided"
  }`

  return (
    <TitleMessage
      text={text}
      item={item}
      senders={[user]}
      containerStyle={containerStyle}
      isGameplanUpdate
    />
  )
}

GameplanUpdateMessage.propTypes = {
  item: PropTypes.object.isRequired,
  people: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
  isGameplanUpdate: PropTypes.bool,
}

export default memo(GameplanUpdateMessage)

const containerStyle = {
  paddingHotizontal: 8,
  paddingVertical: 8,
  marginHorizontal: 8,
}
