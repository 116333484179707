import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export default StyleSheet.create({
  container: {},
  contentContainer: {
    paddingBottom: Units.responsiveValue(30),
    paddingHorizontal: Units.responsiveValue(24),
  },
  header: {
    alignItems: "center",
    marginBottom: Units.responsiveValue(16),
  },
  section: {
    marginVertical: Units.responsiveValue(20),
    paddingHorizontal: Units.responsiveValue(20),
  },
  avatar: {
    width: Units.responsiveValue(88),
    height: Units.responsiveValue(88),
    borderRadius: Units.responsiveValue(44),
    marginTop: Units.responsiveValue(20),
    marginBottom: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.warmGrey2,
  },
  name: {
    ...AppFonts.header20,
    marginBottom: Units.responsiveValue(2),
  },
  nickname: {
    ...AppFonts.cardTitle,
    color: AppColors.brand.darkGrey2,
    marginBottom: Units.responsiveValue(5),
  },
  location: {
    ...AppFonts.bold,
  },
  about: {
    ...AppFonts.biggerMedium,
    letterSpacing: Units.responsiveValue(-0.4),
    lineHeight: Units.responsiveValue(22),
    color: AppColors.brand.darkGrey2,
  },
  sectionHeading: {
    ...AppFonts.header20,
    marginBottom: Units.responsiveValue(18),
  },
  list: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginRight: Units.responsiveValue(-8),
  },
  interestsToggle: {
    ...AppFonts.cardTitle,
    alignSelf: "center",
    marginTop: Units.responsiveValue(12),
    color: AppColors.brand.pink,
  },
  tagsHeading: {
    ...AppFonts.mediumDemibold,
  },
  headingNote: {
    fontWeight: "normal",
    color: AppColors.brand.warmGrey2,
  },
  userTagsContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingBottom: Units.responsiveValue(12),
  },
  userTag: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: AppColors.brand.washedBlue,
    borderWidth: Units.responsiveValue(1),
    borderRadius: Units.responsiveValue(4),
    padding: Units.responsiveValue(8),
    marginTop: Units.responsiveValue(12),
    marginRight: Units.responsiveValue(8),
  },
  userTagImage: {},
  tagTextWrapper: { flexShrink: 1 },
  userTagText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.washedBlue,
    marginLeft: Units.responsiveValue(6),
  },
  userTagButton: {
    borderColor: AppColors.brand.pink,
  },
  userTagButtonText: {
    color: AppColors.brand.pink,
  },
})
