import React, { useEffect, useState } from "react";

import { images } from "@utils/imageUtils";

import './index.scss'


const TopBarManageNudgeItem = ({ data, selectedPeople, handleSelect }) => {
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    setIsSelected(selectedPeople.some(item => item.id === data.id))
  }, [selectedPeople])

  return (
    <div
      className="manage-nudge-item"
      onClick={() => handleSelect()}
    >
      <div className="manage-nudge-item__avatar--wrapper">
        {data.avatar ? (
          <img
            className="manage-nudge-item__avatar"
            src={data.avatar}
            alt="avatar"
          />
        ) : (
          <p className="manage-nudge-item__avatar--empty">
            {data.name.split('')[0]}
          </p>
        )}
      </div>
      <h4 className="manage-nudge-item__title">{data.name}</h4>
      <div className="manage-nudge-item__select">
        <img
          src={isSelected ? images.ndTeeUpSelected : images.ndTeeUpNotSelected}
          alt="Not Selected"
        />
      </div>
    </div>
  )
}

export default TopBarManageNudgeItem;