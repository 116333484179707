import React from "react"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import PhoneNumber from "react-native-phone-input/lib/phoneNumber"

import { userTypes } from "@config/enums"
import { contactTypes } from "@utils/contactUtils"
import { batchVerifyAndGetUserInfo } from "../../../actions/batchVerifyAndGetUserInfo"
import { validateEmail } from "@utils/dataUtils"
import AddContactsView from "./AddManualContactsView"

const countriesMap = require("../../countrySelector/countries.json")

class AddContacts extends React.Component {
  static options() {
    return {
      topBar: {
        visible: false,
        drawBehind: true,
        animate: false,
      },
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      contactList: [
        {
          id: 0,
          value: "",
          isValid: true,
        },
      ],
    }

    this._checkForExistedUser = debounce(this.checkForExistedUser, 250)
    // this.navigationEventListener = Navigation.events().bindComponent(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.contactList !== this.state.contactList) {
      console.log(this.state.contactList, 'sdsdsdsds')
    }
  }

  componentWillUnmount() {
    if (this.navigationEventListener) {
      this.navigationEventListener.remove()
    }
  }

  addTextInputRow = () => {
    const { contactList } = this.state
    const nextId = contactList.length + 1
    this.setState({
      contactList: contactList.concat([
        {
          id: nextId,
          value: "",
          isValid: true,
        },
      ]),
    })
  }

  removeTextInputRow = (index) => () => {
    const { contactList } = this.state
    this.setState({
      contactList: contactList.filter((contact) => contact.id !== index),
    })
  }

  getInitPhoneNumber = (index) => (phoneNumber) => {
    const contactList = [...this.state.contactList]
    const arrayIndex = contactList.findIndex((contact) => contact.id === index)
    const editedContact = {
      ...contactList[arrayIndex],
      value: phoneNumber + " ",
      isValid: false,
    }

    contactList.splice(arrayIndex, 1, editedContact)

    this.setState({ contactList })
  }

  handleTextChange = (index) => (text, cca2) => {
    const { contactType } = this.props
    const contactList = [...this.state.contactList]
    const arrayIndex = contactList.findIndex((contact) => contact.id === index)
    let isValidInput
    if (contactType === contactTypes.EMAIL) {
      isValidInput = validateEmail(text)
    }
    if (contactType === contactTypes.PHONE) {
      isValidInput = PhoneNumber.isValidNumber(text, cca2)
    }
    const countryCode = cca2
      ? `+${countriesMap[cca2.toUpperCase()].callingCode}`
      : ""

    this._checkForExistedUser(index)

    const editedContact = {
      ...contactList[arrayIndex],
      value: text,
      isValid: isValidInput,
      countryCode: countryCode,
    }

    contactList.splice(arrayIndex, 1, editedContact)

    this.setState({ contactList })
  }

  handleDone = () => {
    const { contactType, onDone, onClose } = this.props
    const data = this.state.contactList.map(({ value }) => value)
    onDone({ contactType, data })
    onClose()
  }

  checkForExistedUser = async (index) => {
    const { contactList } = this.state
    let [currentInput] = contactList.filter((i) => i.id === index)
    const { value, isValid, existedUser } = currentInput
    if (!isValid || !value) {
      if (existedUser) {
        delete currentInput.existedUser
        this.setState({
          contactList: contactList.map((i) =>
            i.id === index ? currentInput : i
          ),
        })
      }
      return null
    }
    let data = await batchVerifyAndGetUserInfo([value.replace(/\s/g, "")])
    const { users, contacts } = data

    let user = null
    if (users.length) user = users[0]
    if (contacts.length) user = contacts[0]
    if (user && user.userType !== userTypes.draft) {
      const {
        contactId,
        familyName,
        givenName,
        firstname,
        lastname,
        userType,
        name,
        nickname,
      } = user
      const username = contactId
        ? `${givenName} ${familyName}`
        : nickname || name || `${firstname} ${lastname}`

      this.setState({
        contactList: contactList.map((contact) =>
          contact.id === index
            ? {
                ...contact,
                existedUser: { username, isCooe: !!userType },
              }
            : contact
        ),
      })
    }
  }

  render() {
    const { contactType, defaultCountry, onClose } = this.props
    const { contactList } = this.state
    const hasEmptyInput = contactList.some((contact) => !contact.value)
    const hasInvalidInput = contactList.some((contact) => !contact.isValid)
    const disableDoneButton =
      !contactList.length || hasEmptyInput || hasInvalidInput

    const isPhoneContact = contactType === contactTypes.PHONE

    return (
      <AddContactsView
        isPhoneContact={isPhoneContact}
        handleDone={this.handleDone}
        disableDoneButton={disableDoneButton}
        onClose={onClose}
        contactList={contactList}
        defaultCountry={defaultCountry}
        removeTextInputRow={this.removeTextInputRow}
        handleTextChange={this.handleTextChange}
        addTextInputRow={this.addTextInputRow}
        getInitPhoneNumber={this.getInitPhoneNumber}
      />
    )
  }
}

AddContacts.propTypes = {
  componentId: PropTypes.string.isRequired,
  contactType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  defaultCountry: PropTypes.string,
}

export default AddContacts
