import React from "react"

import {
  SuggestModalFooter,
  SuggestModalContent,
  SuggestModalSubHeader,
} from "../../components"

const TimeSlotsTab = () => {
  return (
    <>
      <SuggestModalSubHeader></SuggestModalSubHeader>
      <SuggestModalContent type="when">TimeSlotsTab</SuggestModalContent>
      <SuggestModalFooter></SuggestModalFooter>
    </>
  )
}

export default TimeSlotsTab
