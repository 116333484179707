import React from "react"
import { useHistory } from "react-router-dom"

import "../index.scss"
import { images } from "@utils/imageUtils"

const AlreadyRedeemed = ({ setRedeemed }) => {
  const history = useHistory()

  return (
    <>
      <div className="forgot__content">
        <img style={{ marginBottom: 18 }} src={images.logoCooe} />
        <span className="forgot__title__redeemed">Already Redeemed</span>
        <p className="forgot__subtitle" style={{ maxWidth: "none" }}>
          This invitation has already been validated and is no longer valid.
          Please check to see if you have already created an account.
        </p>
      </div>
      <button
        onClick={() => {
          history.push("/sign-in")
        }}
        className="forgot__button"
        style={{ width: 327 }}
      >
        Sign In
      </button>
      <button onClick={() => setRedeemed(false)} className="verify__resend">
        Request an Invitation
      </button>
    </>
  )
}

export default AlreadyRedeemed
