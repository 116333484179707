import React from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"
import styles from "./dateMessageStyles"

export const DateMessage = ({ date = "Today" }) => (
  <View style={styles.dateMessageContainer}>
    <View style={styles.dateMessageLine} />
    <Text style={styles.dateMessageDate}>{date}</Text>
    <View style={styles.dateMessageLine} />
  </View>
)

DateMessage.propTypes = {
  date: PropTypes.string,
}
