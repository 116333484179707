import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { AppColors } from "@theme"
import { selectUserId } from "@selectors/user"
// import { selectComponentIdByName } from '@selectors/navigation'
// import backend from '@apis/backend'
import {
  verifyContactMechanism,
  contactMechanismVerified,
  deleteUserContactMechanism,
  requestVerifyContactMechanism,
} from "@actions/contactMechanismsActions"
// import { dismissModal, pop, routeRetrieveTeeupError } from '@nav'
// import navigationConfig from '@nav/NavigationConfig'
// import { Navigation } from 'react-native-navigation'
import { ViewStyles } from "./retrieveTeeupStyles"
import { images } from "@utils/imageUtils"
import RetrieveTeeupVerifyView from "./retrieveTeeupVerifyView"
// import RNExitApp from 'react-native-kill-app'
import { signUpErrorTypes, requestErrors } from "@configs/enums"

class RetrieveTeeupVerifyContainer extends Component {
  constructor(props) {
    super(props)
    const { contactMechanismVerificationId } = props
    this.state = {
      value: "",
      loading: false,
      error: null,
      contactMechanismVerificationId,
      verifedAttempts: 0,
      previousCode: "",
    }
    // Navigation.events().bindComponent(this)
  }

  static options() {
    return {
      topBar: {
        title: {
          text: "Enter Verification Code",
          alignment: "center",
          ...ViewStyles.topBarTitle,
        },
        leftButtons: [
          {
            id: "close",
            icon: images.close,
            color: AppColors.brand.pink,
          },
        ],
      },
    }
  }

  componentDidUpdate() {
    if (this.state.loading) {
      // Navigation.mergeOptions(this.props.componentId, {
      //     topBar: {
      //         background: { color: AppColors.brand.black50 },
      //     },
      // })
    } else {
      // Navigation.mergeOptions(this.props.componentId, {
      //     topBar: {
      //         background: { color: AppColors.brand.white },
      //     },
      // })
    }
  }

  navigationButtonPressed({ buttonId }) {
    if (buttonId === "close") this.onClose()
  }

  onClose = async () => {
    const {
      contactMechanismId,
      fromAccountScreen,
      componentId,
      parentComponentId,
    } = this.props

    this.setState({ loading: true, error: null })
    try {
      if (!fromAccountScreen) {
        await deleteUserContactMechanism(contactMechanismId)
      }
    } catch (e) {
      // routeRetrieveTeeupError({
      //     error: requestErrors.unrecoverableError,
      // })
    } finally {
      if (fromAccountScreen) {
        // dismissModal(componentId)
        // pop(parentComponentId)
      }
    }
  }

  onValue = (value) => {
    this.setState({ value: value.trim() })
  }

  onContinue = async () => {
    const {
      fromAccountScreen,
      userId,
      componentId,
      parentComponentId,
      returnToRegistration,
      closeVerify,
      handleModal,
    } = this.props
    const { contactMechanismVerificationId, value, verifedAttempts } =
      this.state

    this.setState({ loading: true, error: null })
    try {
      const { status, message } = await verifyContactMechanism(
        contactMechanismVerificationId,
        value
      )
      if (!status || (status >= 200 && status < 300)) {
        if (returnToRegistration) {
          // dismissModal(componentId)
        } else {
          await contactMechanismVerified(fromAccountScreen, {
            componentId,
            parentComponentId,
          })
        }
        handleModal(null)
        this.setState({ loading: false })
      } else {
        this.setState({
          loading: false,
          error: message,
          verifedAttempts: verifedAttempts + 1,
          previousCode: value,
          value: "",
        })
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
        verifedAttempts: verifedAttempts + 1,
        previousCode: value,
        value: "",
      })

      if (e !== signUpErrorTypes.invalidOrExpiredCode) {
        // routeRetrieveTeeupError({
        //     error: requestErrors.unrecoverableError,
        // })
      }
    } finally {
      this.clearVerifyCodeInput()
      // closeVerify()
    }
  }

  onResend = async () => {
    const { contactMechanismId } = this.props
    this.setState({
      loading: true,
      error: null,
      verifedAttempts: 0,
      value: "",
      previousCode: "",
    })
    try {
      const { contactMechanismVerificationId } =
        await requestVerifyContactMechanism(contactMechanismId)
      this.setState({
        loading: false,
        contactMechanismVerificationId,
      })
    } catch (e) {
      this.setState({ loading: false, error: e })
      // routeRetrieveTeeupError({
      //     error:
      //         e === signUpErrorTypes.tooManyRequests
      //             ? signUpErrorTypes.tooManyRequests
      //             : requestErrors.unrecoverableError,
      // })
    } finally {
      this.clearVerifyCodeInput()
    }
  }

  onBack = async () => {
    const {
      contactMechanismId,
      fromAccountScreen,
      componentId,
      parentComponentId,
      closeVerify,
    } = this.props

    this.setState({ loading: true, error: null })
    try {
      if (!fromAccountScreen) {
        await deleteUserContactMechanism(contactMechanismId)
      }
    } catch (e) {
      //
    } finally {
      if (fromAccountScreen) {
        closeVerify()
      }
    }
  }

  setVerifyCodeRef = (ref) => (this.verifyCode = ref)

  clearVerifyCodeInput = () => {
    if (this.verifyCode) {
      this.verifyCode.clear()
    }
  }

  render() {
    const {
      contactMechanismValue,
      returnToRegistration,
      contactMechanismType,
      deleteContactMechanism,
    } = this.props
    const { value, loading, error, verifedAttempts, previousCode } = this.state

    return (
      <RetrieveTeeupVerifyView
        contact={contactMechanismValue}
        returnToRegistration={returnToRegistration}
        value={value}
        onValue={this.onValue}
        onContinue={this.onContinue}
        onResend={this.onResend}
        onBack={this.onBack}
        loading={loading}
        error={error}
        type={contactMechanismType}
        verifedAttempts={verifedAttempts}
        previousCode={previousCode}
        setVerifyCodeRef={this.setVerifyCodeRef}
        verifyCode={this.verifyCode}
        contactValue={this.props.contactValue}
        handleModal={this.props.handleModal}
        deleteContactMechanism={deleteContactMechanism}
      />
    )
  }
}

RetrieveTeeupVerifyContainer.propTypes = {
  // componentId: PropTypes.string.isRequired,
  // parentComponentId: PropTypes.string.isRequired,
  // userId: PropTypes.number.isRequired,
  contactMechanismId: PropTypes.number.isRequired,
  contactMechanismVerificationId: PropTypes.number.isRequired,
  contactMechanismValue: PropTypes.string.isRequired,
  fromAccountScreen: PropTypes.bool,
  returnToRegistration: PropTypes.bool,
  contactMechanismType: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
  const userId = selectUserId(state)
  // const parentComponentId = ownProps.fromAccountScreen
  //     ? selectComponentIdByName('editContact')(state)
  //     : ''
  return {
    userId: userId || ownProps.userId,
  }
}

export default connect(mapStateToProps, null)(RetrieveTeeupVerifyContainer)
