import React, { memo } from "react"
import { View } from "react-native"
import DraggableSuggestionRow from "@components/gamePlansCard/draggableSuggestionRow"
import styles from "./suggestionMessageStyles"

const SuggestionMessage = (props) => {
  return (
    <View style={styles.suggestionMessageContainer}>
      <DraggableSuggestionRow {...props} fromConversation />
    </View>
  )
}

export default memo(SuggestionMessage)
