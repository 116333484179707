import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import MyContactItem from '@components/Contacts/MyContactItem'
import CheckBox from '@ui/checkbox'
import {
    teeupListNudgeTypes,
} from '@configs/enums'
import I18n from '@i18n'
import { messageTypeIds } from '@configs/mappings'
import { sendNudge } from '@middlewares/backendMiddleware'
import { images } from "@utils/imageUtils";
import { setModalPeopleNudge, setPeopleSideBar } from "@actions/commonActions";
import { setActiveTextMessage }  from "@actions/teeupCardActions";
import { selectSuggestionToNudgeId, selectTypeToNudge, selectPeopleSideBar} from '@selectors/common';
import { selectActiveTeeupId } from "@selectors/activeTeeup";
import './index.scss';

const SelectPeopleToNudge = ({
  invitees,
  setInvitees,
  people,
  handleToggleContact,
  setModalPeopleNudge,
  suggestionToNudgeId,
  typeToNudge,
  setActiveTextMessage,
  activeTeeupId,
  peopleToNudge,
}) => {
    const inviteesId = invitees.map(item => item.id);
    const peopleId = people.map(item => item.id);
    
    const selectAll = () => setInvitees([...invitees.filter(invitee => !peopleId.includes(invitee.id)), ...people]);
    const unselectAll = () => setInvitees(invitees.filter(invitee => !peopleId.includes(invitee.id)));

    const selectedEveryOne = people.every(person => inviteesId.includes(person.id));
    const selectAllAction = selectedEveryOne ? unselectAll : selectAll;

    let onNudge 

    const nudgeAreYouGoing = async () => {
        await sendNudge({
            message: I18n.nudgePromptContent.areYouGoing.action,
            messageTypeId: messageTypeIds.nudge,
            mentionIds: inviteesId,
            subTypeId: teeupListNudgeTypes.areYouGoing,
            teeupId: activeTeeupId
        })

        setModalPeopleNudge(false);
    }
    
    const nudgeWhereAreYou = async () => {
        await sendNudge({
            message: I18n.nudgePromptContent.whereAreYou.action,
            messageTypeId: messageTypeIds.nudge,
            mentionIds: inviteesId,
            subTypeId: teeupListNudgeTypes.whereAreYou,
            teeupId: activeTeeupId
        })

        setModalPeopleNudge(false);
    }

    const nudgeWorkForYou = async () => {
        await sendNudge({
            message: I18n.nudgePromptContent.suggestion.action,
            messageTypeId: messageTypeIds.nudge,
            mentionIds: inviteesId,
            subTypeId: teeupListNudgeTypes.suggestion,
            targetId: suggestionToNudgeId,
            teeupId: activeTeeupId
        })

        setModalPeopleNudge(false);
    }

    const mentionPeople = () => {
        const mentionMessage =
        invitees?.length === people?.length
                ? '@Everybody '
                : invitees
                      .map(
                          (participant) =>
                              `@${participant.username || participant.name}`
                      )
                      .join(' ') + ' '
        setActiveTextMessage(mentionMessage)
        setModalPeopleNudge(false);
    }

    switch (typeToNudge) {
        case 'nudgeWhereAreYou':
            onNudge = nudgeWhereAreYou
            break;
        case 'nudgeAreYouGoing':
            onNudge = nudgeAreYouGoing
            break;
        case 'nudgeWorkForYou':
            onNudge = nudgeWorkForYou
            break;
        case 'mentionPeople':
            onNudge = mentionPeople
        default:
            break;
    }

    return (
      <div
        className="selectPeopleToNudge_wrapper"
        onClick={(e) => {
          e.stopPropagation();
        //   setModalPeopleNudge(false);
          unselectAll();
        }}
      >
        <div
          className="selectPeopleToNudge"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="selectPeopleToNudge_header">
            <div className="selectPeopleToNudge_header__title_wrapper">
              <span className="selectPeopleToNudge_header__title">
                Select People to{" "}
                {typeToNudge === "mentionPeople" ? "Mention" : "Nudge"}
              </span>
              {/* {invitees.length > 0 && <div className="selectPeopleToNudge_header__title__selectedPeople">{(invitees.length > 0 && invitees.length === 1) ? invitees.length + ' person' : invitees.length + ' people'} selected</div>} */}
            </div>
            <img
              src={images.crossSettings}
              style={{ cursor: "pointer", alignSelf: "flex-start" }}
              onClick={() => {
                // setModalPeopleNudge(false);
                unselectAll();
              }}
            />
          </div>
          {/* <div className="selectPeopleToNudge_header_shadow"></div> */}
          <div
            style={{
              boxShadow:
                "inset 0px 11px 6px -10px #F0F2F5, inset 0px -11px 6px -10px #F0F2F5",
              padding: "3px",
            }}
          >
            {/* <div style={{boxShadow: 'inset 0px 11px 6px -10px #f0f0f0, inset 0px -11px 6px -10px #f0f0f0', padding: '3px'}}> */}
            <div className="select-all__row">
              <div className="selectPeopleToNudge_header__button_selectAll_avatar">
                <img src={images.everyoneAvatar} alt="everyoneAvatar" />
              </div>
              <span className="selectPeopleToNudge_header__button_selectAll_text">
                Select Everyone
              </span>
              <button
                className="selectPeopleToNudge_header__button_selectAll"
                onClick={() => selectAllAction()}
              >
                <CheckBox isActive={selectedEveryOne} />
              </button>
            </div>
            <div className="mycontact__wrapper">
              {(peopleToNudge?.length ? peopleToNudge : people).map((item) => (
                <MyContactItem
                  key={item.id}
                  setContent={() => {}}
                  item={item}
                  invitees={invitees}
                  onRemoveInvitee={handleToggleContact({ data: item })}
                  nudgePeople={true}
                  noHover
                />
              ))}
            </div>
          </div>
          {/* <div className="selectPeopleToNudge_footer_shadow"></div> */}
          <div className="selectPeopleToNudge_footer">
            <button
              className="selectPeopleToNudge_footer__button selectPeopleToNudge_footer__button_cancel"
              onClick={() => {
                setModalPeopleNudge(false);
                unselectAll();
              }}
            >
              Cancel
            </button>
            <button
              className="selectPeopleToNudge_footer__button selectPeopleToNudge_footer__button_nudge"
              onClick={() => {
                setModalPeopleNudge(false);
                onNudge();
              }}
            >
              {typeToNudge === "mentionPeople" ? "Mention" : "Nudge"}
            </button>
          </div>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => {
  return {
    suggestionToNudgeId: selectSuggestionToNudgeId(state),
    typeToNudge: selectTypeToNudge(state),
    activeTeeupId: selectActiveTeeupId(state),
    peopleToNudge: state.common.peopleToNudge,
    peopleSideBar: selectPeopleSideBar(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setModalPeopleNudge: bindActionCreators(setModalPeopleNudge, dispatch),
  setActiveTextMessage: bindActionCreators(setActiveTextMessage, dispatch),
  setPeopleSideBar: bindActionCreators(setPeopleSideBar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPeopleToNudge);
