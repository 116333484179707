import { StyleSheet } from "react-native"

import { AppFonts, Units, AppColors } from "@theme/"

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    marginBottom: Units.responsiveValue(12),
    marginRight: Units.responsiveValue(12),
    borderRadius: 100,
    alignItems: "center",
  },
  text: {
    ...AppFonts.cardTitle,
    color: AppColors.brand.darkGrey2,
  },
  image: {
    marginRight: 5,
    marginBottom: -3,
  },
})
