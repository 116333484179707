import React, { useState } from "react"
import ReactTooltip from "react-tooltip"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { setModalPeopleNudge } from "@actions/commonActions"

import "./index.scss"

const PeopleTooltip = ({ setModalPeopleNudge }) => {
  const [isOpen, setIsOpen] = useState(true)

  const hideTooltip = () => {
    setIsOpen(false)
    ReactTooltip.hide()
  }

  return (
    <ReactTooltip
      id="people-tooltip"
      class="extraClass"
      offset={{ top: 5 }}
      backgroundColor="#ffffff"
      place="top"
      effect="solid"
      afterHide={() => setIsOpen(true)}
      delayHide={500}
      // delayShow={ 500 }
      getContent={(id) => {
        if (!id || !isOpen) {
          return null
        }
        return (
          <>
            <div className="teeup_tooltip_container_sort">
              <div
                className="tooltip_list_sort"
                onClick={() =>
                  setModalPeopleNudge(true, undefined, "nudgeWhereAreYou")
                }
              >
                <span className="tooltip_list_sort__title_teeup">
                  Ask people where are they?
                </span>
              </div>
              <div
                className="tooltip_list_sort"
                onClick={() =>
                  setModalPeopleNudge(true, undefined, "nudgeAreYouGoing")
                }
              >
                <span className="tooltip_list_sort__title_teeup">
                  Ask people if they are going?
                </span>
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

const mapDispatchToProps = (dispatch) => ({
  setModalPeopleNudge: bindActionCreators(setModalPeopleNudge, dispatch),
})

export default connect(null, mapDispatchToProps)(PeopleTooltip)
