import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { selectTeeupPeople } from "@selectors/teeups"
import closeGrey from "../../../assets/images/close-grey.png"
import { selectReplyMessage } from "@selectors/common"
import { setReplyMessage } from "@actions/commonActions"
import {
  CommentOnImage,
  CommentOnFile,
} from "../Chat/discussionCard/ReplyOverlay/replyToFile"
import { conversationTypeIds } from "@configs/mappings"
import DraggableSuggestionRow from "@components/gamePlansCard/draggableSuggestionRow"

const ReplyOverview = ({ replyMessage, sender, setReplyMessage }) => {
  const getTitle = () => {
    if (replyMessage.text) {
      return `Replying to ${sender.name}`
    }

    return "Commenting on"
  }

  const getMessagePreview = () => {
    const { conversationTypeId } = replyMessage

    if (conversationTypeId === conversationTypeIds.image) {
      return (
        <CommentOnImage
          username={sender.name}
          image={replyMessage.image}
          setReplyImageSize={() => {}}
        />
      )
    }

    if (conversationTypeId === conversationTypeIds.file) {
      return <CommentOnFile username={sender.name} file={replyMessage.file} />
    }

    if (conversationTypeId === conversationTypeIds.suggestion) {
      return (
        <DraggableSuggestionRow
          name={sender.name}
          forChat={false}
          isMyMessage={false}
          draggable={false}
          fromSuggestionChat
          suggestedItem
          suggestion={replyMessage}
          fromConversation
        />
      )
    }

    return <span className="reply-message">{replyMessage.text}</span>
  }

  return (
    <div className="reply-container">
      <div className="reply-header">
        <span className="reply-title">{getTitle()}</span>
        <img
          className="reply-close"
          src={closeGrey}
          onClick={() => setReplyMessage(null)}
        />
      </div>
      {getMessagePreview()}
    </div>
  )
}

const mapStateToProps = (state) => {
  const replyMessage = selectReplyMessage(state)
  const people = selectTeeupPeople(state)
  const sender = people[replyMessage.senderId]

  return {
    replyMessage,
    sender,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setReplyMessage: bindActionCreators(setReplyMessage, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReplyOverview)
