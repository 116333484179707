import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import { selectActiveInviteesTab } from "@selectors/common"
import "./index.scss"

const TabInvitees = ({
  index,
  title,
  activeInviteesTab,
  action,
  setInviteesTab,
}) => {
  let activeTab = activeInviteesTab === index

  return (
    <div
      className={`tabInvitees ${activeTab && "tabInvitees_active"}`}
      onClick={(e) => {
        e.stopPropagation()
        action(index)
        setInviteesTab && setInviteesTab(index)
      }}
    >
      <div
        className={`tabInvitees_title ${
          activeTab && `tabInvitees_title_active`
        }`}
      >
        {title}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  activeInviteesTab: selectActiveInviteesTab(state),
})

export default connect(mapStateToProps, null)(TabInvitees)
