/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"
import PropTypes from "prop-types"
import { View, TouchableOpacity, FlatList, Text, Image } from "react-native"

import { images } from "@utils/imageUtils"
import { AppColors } from "@theme/"
import Icon from "@ui/icon"
import Avatar from "@ui/avatar"
import { ViewStyles, EditOrgsStyles } from "./teeupSettingsStyles"
import organizerRed from "../../assets/images/organizerRed.png"
import tick from "../../assets/images/checkWhiteIcon.png"

class TeeupEditOrgsView extends React.Component {
  renderItem = ({ item }) => {
    const { ownerId, organiserIds = [], onToggleUser } = this.props
    const { id, name, avatar } = item
    const isOwner = id === ownerId
    const isOrganiser = organiserIds.includes(id)

    return (
      <TouchableOpacity
        style={EditOrgsStyles.row}
        onPress={() => !isOwner && onToggleUser(id)}
      >
        <Avatar size={44} imageUrl={avatar} />
        <Text style={EditOrgsStyles.userName}>{name}</Text>
        {isOwner ? (
          <img style={{ width: 24, height: 24 }} src={organizerRed} />
        ) : (
          <View
            style={
              isOrganiser
                ? EditOrgsStyles.checkedCircle
                : EditOrgsStyles.uncheckedCircle
            }
          >
            {isOrganiser && (
              <img style={{ width: 20, height: 20 }} src={tick} />
            )}
          </View>
        )}
      </TouchableOpacity>
    )
  }

  renderListHeader = () => <View style={EditOrgsStyles.header} />

  renderListFooter = () => <View style={EditOrgsStyles.footer} />

  render() {
    const { people, organiserIds } = this.props

    return (
      <View style={ViewStyles.background}>
        <FlatList
          data={Object.values(people)}
          extraData={organiserIds}
          renderItem={this.renderItem}
          keyExtractor={(item, index) => `${index}`}
          ListHeaderComponent={this.renderListHeader}
          ListFooterComponent={this.renderListFooter}
        />
      </View>
    )
  }
}

TeeupEditOrgsView.propTypes = {
  people: PropTypes.object,
  organiserIds: PropTypes.array,
  onToggleUser: PropTypes.func,
  ownerId: PropTypes.number,
}

export default TeeupEditOrgsView
