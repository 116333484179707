import React from "react"
import Props from "prop-types"
import { View, TouchableOpacity, Text, Image } from "react-native"

import { images } from "@utils/imageUtils"
import { genderOptions } from "@configs/enums"
import styles from "./styles"

export const renderTag = (item) => (
  <View key={`tag-${item.id}`} style={styles.userTag}>
    <Image source={images.userTag} style={styles.userTagImage} />
    <View style={styles.tagTextWrapper}>
      <Text style={styles.userTagText}>{item.value}</Text>
    </View>
  </View>
)

renderTag.propTypes = {
  item: Props.string.isRequired,
}

export const AddUserTag = (props) => (
  <TouchableOpacity {...props} style={[styles.userTag, styles.userTagButton]}>
    <Text style={[styles.userTagText, styles.userTagButtonText]}>+ Add</Text>
  </TouchableOpacity>
)

export const formatTagText = (tag) => {
  if (tag.name && tag.name.toLowerCase() === "age") {
    return `Age ${tag.value}`
  }

  if (tag.name && tag.name.toLowerCase() === "gender") {
    return genderOptions[tag.value]
  }

  if (tag.name && tag.name.toLowerCase() === "language") {
    return `${
      Array.isArray(tag.value) ? tag.value.join(", ") : tag.value
    }-speaking`
  }

  if (tag.type && tag.type.toLowerCase() === "custom") {
    return tag.name
  }

  return tag.value
}
