import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { View, TouchableOpacity, Image, Text } from "react-native"
// import LinearGradient from 'react-native-linear-gradient'
import { images } from "@utils/imageUtils"
import strings from "@i18n"
import { SectionFooterStyles } from "./suggestionListStyles"
import { connect } from "react-redux"
import { hideSuggestionListFooter } from "../../../actions/commonActions"

const SuggestionListFooter = (props) => {
  const {
    onAddSuggestion,
    isDisabled,
    FromModal,
    gameplan,
    hideSuggestionListFooter,
  } = props

  useEffect(() => {
    FromModal && onAddSuggestion()
  }, [])

  return (
    <View style={[SectionFooterStyles.suggestionButtonWrapper]}>
      {/* <LinearGradient
                style={SectionFooterStyles.shadowTop}
                colors={['rgba(0,0,0,0.08)', 'rgba(0,0,0,0)']}
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
            /> */}
      <View style={SectionFooterStyles.suggestionButtonBackground}>
        <TouchableOpacity
          style={[
            SectionFooterStyles.suggestionButton,
            isDisabled
              ? SectionFooterStyles.suggestionButtonDisabled
              : SectionFooterStyles.suggestionButtonEnabled,
          ]}
          onPress={() => {
            onAddSuggestion()
            hideSuggestionListFooter()
          }}
          disabled={isDisabled}
        >
          <Image
            source={images.suggest}
            style={[
              isDisabled
                ? SectionFooterStyles.suggestionImageDisabled
                : SectionFooterStyles.suggestionImageEnabled,
            ]}
          />
          <Text
            style={[
              SectionFooterStyles.suggestionButtonText,
              isDisabled
                ? SectionFooterStyles.suggestionButtonTextDisabled
                : SectionFooterStyles.suggestionButtonTextEnabled,
            ]}
          >
            {/* {strings.suggestYour} */}
            Suggest
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSuggestionListFooter: () => dispatch(hideSuggestionListFooter()),
  }
}

export default connect(null, mapDispatchToProps)(SuggestionListFooter)

SuggestionListFooter.propTypes = {
  onAddSuggestion: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}
