import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  TouchableOpacity,
  View,
  Text,
  Animated,
  Keyboard,
  Platform,
} from "react-native"
import _ from "lodash"
import ReactDOM from "react-dom"
// import DocumentPicker from 'react-native-document-picker'
// import { PERMISSIONS } from 'react-native-permissions'

// import { teeupListNudgeTypes, highlightTypes } from '@configs/enums'
// import {
//     routeSetupGameplan,
//     showActionsheet,
//     showSuggestionChat,
//     showImagePreview,
//     showParticipantPopup,
//     showSuggestionList,
//     showHighlightsScreen,
// } from '@nav'
// import teeupActions from '@actions/teeupActions'
// import teeupCardActions from '@actions/teeupCardActions'
import { setActiveHighlightedMessage } from "@actions/teeupCardActions"
import {
  selectPermissions,
  selectSuggestionMessages,
  // selectTeeupMessages,
  // selectTeeupMessagesTimestamps,
  selectTeeupPeople,
  // selectTeeupReadTimestamps,
  selectTeeupSuggestions,
  // selectAdditionalGoingPeopleInTeeup,
  // selectTeeupState,
  selectDecidedGameplans,
} from "@selectors/teeups"
import {
  //     selectDraggingSuggestion,
  //     selectDraggingSuggestionId,
  //     selectActiveReplyMessage,
  //     selectActiveTextMessage,
  selectActiveHighlightedMessage,
} from "@selectors/teeupCard"
// import { selectNewSuggestions } from '@selectors/localData'
import { selectUserInfo } from "@selectors/user"
import {
  selectActiveTeeup,
  selectActiveTeeupSettings,
  selectHiddenPinnedMessages,
  selectMessagesWithoutNugdes,
  selectMessages,
} from "@selectors/activeTeeup"

// import { checkModulePermission } from '@utils/permissionUtils'
// import pickImage from '@utils/imagePicker'
import {
  GAMEPLAN_OPTIONS,
  getGameplanIcon,
  getGameplanTitle,
} from "@utils/gamePlanUtils"
// import { sounds } from '@utils/soundUtils'
import { getUsernameOrName } from "@utils/contactUtils"
import { images, getRecentPhotos } from "@utils/imageUtils"
// import { getRecentFiles } from '@utils/fileUtils'
import {
  getMessageOptions,
  renderMentionedUser,
  // handleCheckIfInputValid,
  // getMessageById,
  showChatMessageContextMenu,
} from "@utils/chatUtils"
import {
  isTeeupDisabled,
  getLastUserMention,
  getMentionedUsersIds,
} from "@utils/teeupUtils"
// import { refreshInitialMessageOptions } from '@utils/messagesUtils'

import { conversationTypeIds, messageTypeIds } from "@configs/mappings"
// import I18n from '@i18n'

// import Toast from '@ui/toast'
import ChatMessage from "@ui/chatMessage"
import DateMessage from "@ui/dateMessage"
import SeparatorMessage from "@ui/separatorMessage"

import DiscussionCardView from "./discussionCardView"
import { DiscussionStyles } from "./discussionStyles"
import { Units } from "@theme"
import ParticipantPopup from "@components/participantPopup"
import HighlightsTabContainer from "./highlights/highlightsTabContainer"
import {
  toggleParticipantDetails,
  toggleDetailsUser,
} from "@actions/activeTeeupActions"
import { getStyledMessages } from "@actions/commonActions"
import { sendMessage } from "../../../../middlewares/backendMiddleware"

const SWIPE_DISTANCE_TOSHOW_TIMESTAMPS = 75
const SCROLL_BENCHMARK = 15
const isIOS = Platform.OS === "ios"

const everybodyItem = { name: "Everybody", icon: images.users }

class DiscussionCardContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    const { discussionCardRef } = props
    if (discussionCardRef) {
      discussionCardRef(this)
    }

    this.state = {
      showSend: false,
      showJumpBackButton: false,
      lastVisibleTimestamp: null,
      inputText: "",
      mentionedUser: null,
      showFullMentionsList: false,
      isKeyboardActive: false,
      expandedStatusMessagesArray: [],
      expandedSystemMessagesGroupArray: [],
      isSwiping: false,
      isContextMenuOpen: false,

      showAddMediaBlock: false,
      isTimestampsVisible: false,
      attachedFiles: [],
      swipeStart: 0,
      swipeEnd: 0,
      unreadCount: 0,
      cameraRollImages: [],
      recentFiles: [],
      gettingRecentPhotos: false,
      gettingRecentFiles: false,
      isFileOpen: false,
      timestampsViewed: [],
      allowCleanUnread: false,
      loadAllMessages: false,
      unreadBannerEnabled: true,
      uploadFiles: [],
      sessionSuggestions: this.props.newSuggestions,
      newMessagesStarterId: null,
      userId: null,
      isHighlightsVisible: false,
      highlightInitialTab: 0,
      animationName: "left-slide",
    }

    this.heightAnimatedValue = new Animated.Value(0)
    this.inputWidthAnimatedValue = new Animated.Value(0)
    this.mentionsPotitionAnimatedValue = new Animated.Value(62)
    // this.shouldProcessInitialUnreads = !!props.teeupState.newMessages

    if (Platform.OS === "ios") {
      this.keyboardWillShowListener = Keyboard.addListener(
        "keyboardWillShow",
        () => this._keyboardDidShow()
      )
      this.keyboardWillHideListener = Keyboard.addListener(
        "keyboardWillHide",
        () => this._keyboardDidHide(false)
      )
    } else {
      this.keyboardDidShowListener = Keyboard.addListener(
        "keyboardDidShow",
        () => this._keyboardDidShow()
      )
      this.keyboardDidHideListener = Keyboard.addListener(
        "keyboardDidHide",
        () => this._keyboardDidHide(true)
      )
    }

    this._itemRefs = {}
  }

  // static getDerivedStateFromProps(nextProps) {
  //     if (!nextProps.previewOn && !nextProps.expanded) {
  //         return {
  //             showJumpBackButton: false,
  //         }
  //     }

  //     return null
  // }
  // update for handling nudges
  componentDidUpdate(prevProps) {
    const { allMessages } = this.props
    const { allMessages: prevAllMessages } = prevProps
    if (
      prevAllMessages.length > 0 &&
      allMessages.length > prevAllMessages.length
    ) {
      const newMessage = allMessages.filter(
        (m) => !prevAllMessages.includes(m)
      )[0]
      if (newMessage) {
        if (newMessage.messageTypeId === messageTypeIds.nudge) {
          this.setState((prevState) => ({
            ...prevState,
            isHighlightsVisible: true,
            highlightInitialTab: 2,
          }))
        } else if (newMessage.isSuggestion) {
          this.setState((prevState) => ({
            ...prevState,
            sessionSuggestions: {
              ...prevState.sessionSuggestions,
              [newMessage.id]: newMessage,
            },
          }))
          setTimeout(
            () =>
              this.setState((prevState) => ({
                ...prevState,
                sessionSuggestions: {},
              })),
            5000
          )
        }
      }
    }
  }
  handleFileUploadCancel = (fileName) => {
    const { uploadFiles } = this.state
    const updatedUploadFiles = uploadFiles.filter(
      (item) => item.fileName !== fileName
    )
    this.setState({
      uploadFiles: updatedUploadFiles,
    })
  }

  onFileSendError = (fileName) => {
    const { uploadFiles } = this.state
    const updatedUploadFiles = uploadFiles.map((item) => {
      if (item.fileName === fileName) {
        return {
          ...item,
          error: true,
        }
      } else {
        return {
          ...item,
        }
      }
    })
    this.setState({
      uploadFiles: updatedUploadFiles,
    })
  }

  onFileSend = (req, fileName) => {
    const { uploadFiles } = this.state
    const updatedUploadFiles = [...uploadFiles]
    const isFileUploading = updatedUploadFiles.find(
      (item) => item.fileName === fileName
    )

    if (!isFileUploading) {
      updatedUploadFiles.push({
        fileName,
        request: req,
      })
      this.setState({
        uploadFiles: updatedUploadFiles,
      })
    }
  }

  onFileRetry = (fileName) => {
    const { uploadFiles } = this.state
    const updatedUploadFiles = uploadFiles.filter(
      (item) => item.fileName !== fileName
    )

    this.setState({
      uploadFiles: updatedUploadFiles,
    })
  }

  onStartShouldSetResponder = (e) => {
    this.setState({ swipeStart: e.nativeEvent.x })
    return false
  }

  onMoveShouldSetResponder = (e) => {
    const { swipeStart, isSwiping, isTimestampsVisible } = this.state
    const { expanded, draggingSuggestion } = this.props

    if (draggingSuggestion) {
      return true
    }

    if (!expanded || !swipeStart || (isSwiping && !isTimestampsVisible)) {
      return false
    }

    const { pageX } = e.nativeEvent
    const swipeLength = pageX - swipeStart

    if (Math.abs(swipeLength) < SWIPE_DISTANCE_TOSHOW_TIMESTAMPS) {
      return false
    } else {
      this.setState({
        isTimestampsVisible: swipeLength < 0,
        swipeStart: pageX,
      })
    }

    // Cancel other touches in this case
    return false
  }

  animateFieldHeight = (toValue) => {
    Animated.timing(this.heightAnimatedValue, {
      duration: 500,
      toValue,
      useNativeDriver: false,
    }).start(() => {
      if (this.showExpansionKeyboard === true) {
        this.handleKeyboardFocus()
        this.showExpansionKeyboard = false
      }
    })
  }

  // _keyboardDidShow = () => {
  //     this.setState({ isKeyboardActive: true })
  // }

  // _keyboardDidHide(didHide) {
  //     if (this.requestedContextMenu && didHide) {
  //         this.props.highlighterRef.current?.setVisible(
  //             this.requestedContextMenu
  //         )
  //         this.requestedContextMenu = ''
  //     }
  //     this.setState({ isKeyboardActive: false })
  // }

  // componentWillUnmount() {
  //     if (this.keyboardWillShowListener)
  //         this.keyboardWillShowListener.remove()
  //     if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove()
  //     if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove()
  //     if (this.keyboardWillHideListener)
  //         this.keyboardWillHideListener.remove()

  //     refreshInitialMessageOptions()
  // }

  componentDidMount() {
    this.setState({
      lastVisibleTimestamp: this.props.messages.length
        ? this.props.messages[0].timestamp
        : undefined,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      expanded,
      people,
      messages,
      initJumpBack,
      activeReplyMessage,
      activeTextMessage,
      activeHighlightedMessage,
      setActiveTextMessage,
      notificationMentionMessage,
      setNotificationMentionMessage,
      teeupRef,
      toggleExpansion,
    } = this.props
    const {
      mentionedUser,
      showFullMentionsList,
      inputText,
      lastVisibleTimestamp,
      unreadCount,
    } = this.state
    //     if (
    //         expanded !== prevProps.expanded &&
    //         !activeReplyMessage &&
    //         !notificationMentionMessage
    //     ) {
    //         this.animateFieldHeight(expanded ? 56 : 0)
    //         initJumpBack()
    //         if (expanded) {
    //             // unreadCount &&
    //             //     this.setState({ isTimestampsVisible: true }, () => {
    //             //         setTimeout(() => {
    //             //             this.setState({ isTimestampsVisible: false })
    //             //         }, 3000)
    //             //     })
    //         } else {
    //             teeupRef?.handleSetShowUserSuggestions(false)
    //             this.handleKeyboardClose()
    //             this.cleanUnread()
    //         }
    //     }

    //     if (messages.length !== prevProps.messages.length) {
    //         if (this.shouldProcessInitialUnreads) {
    //             this.setNewMessagesStarter()
    //         }

    //         const unreadMessagesCount = _.filter(
    //             messages,
    //             (msg) => msg.text && msg.timestamp > lastVisibleTimestamp
    //         ).length

    //         if (unreadMessagesCount && unreadMessagesCount !== unreadCount) {
    //             this.setState({
    //                 unreadCount: unreadMessagesCount,
    //                 allowCleanUnread: false,
    //             })
    //         }
    //         if (notificationMentionMessage) {
    //             const message = messages.find(
    //                 (item) => item?.text === notificationMentionMessage
    //             )

    //             if (message) {
    //                 !expanded && this.toggleExpansion()
    //                 this.animatedJumpTo(message.id)
    //                 setNotificationMentionMessage(null)
    //             }
    //         }
    //     }
    //     if (
    //         teeupRef?.state.showUserSuggestions &&
    //         (mentionedUser !== prevState.mentionedUser ||
    //             showFullMentionsList !== prevState.showFullMentionsList)
    //     ) {
    //         if (mentionedUser) {
    //             const mentionedUserLowerCase = mentionedUser.toLowerCase()
    //             const filteredMentionSuggestions = _.filter(
    //                 [everybodyItem, ...Object.values(people)],
    //                 (item) => {
    //                     const matching = getUsernameOrName(item)
    //                     const userLowerCase = matching.toLowerCase()
    //                     return userLowerCase.startsWith(mentionedUserLowerCase)
    //                 }
    //             )
    //             teeupRef.handleSetUserSuggestionsList(
    //                 filteredMentionSuggestions
    //             )
    //         } else {
    //             teeupRef.handleSetUserSuggestionsList(
    //                 showFullMentionsList
    //                     ? [everybodyItem, ...Object.values(people)]
    //                     : []
    //             )
    //         }
    //     }
    //     if (
    //         (inputText.match(/@/g) || '').length <
    //         (prevState.inputText.match(/@/g) || '').length
    //     ) {
    //         teeupRef?.handleSetShowUserSuggestions(false)
    //     }
    //     if (!!activeTextMessage && !prevProps.activeTextMessage) {
    //         this.handleKeyboardFocus()
    //         this.onChangeText(inputText + ' ' + activeTextMessage)
    //         setActiveTextMessage(null)
    //     }

    if (!!activeHighlightedMessage && !prevProps.activeHighlightedMessage) {
      document.getElementById(activeHighlightedMessage).scrollIntoView()
      const messagesList = document.getElementById("messages-list")
      if (messagesList) {
        messagesList.scrollTop -= 10
      }
    }
  }

  setNewMessagesStarter = () => {
    this.shouldProcessInitialUnreads = false
    const unreadMessages = this.getUnreadMessages()
    if (!unreadMessages.length) return

    const firstUnreadMessage = unreadMessages[unreadMessages.length - 1]
    const messageIndex = this.props.messages.findIndex(
      (message) => message.id === firstUnreadMessage.id
    )
    const previousMessage = this.props.messages[messageIndex + 1]
    // if (previousMessage?.daybreak) return

    this.setState({
      newMessagesStarterId: firstUnreadMessage.id,
    })
  }

  onExpansionAnimationComplete = () => {
    // Let's keep this here for some potential future usage (by Wang)
    // if (this.showExpansionKeyboard === true) {
    //     this.handleKeyboardFocus(1000)
    //     this.showExpansionKeyboard = false
    // }
  }

  goToSuggestion = (suggestion) => {
    // this.keyboardPersistingTransit(showSuggestionChat, {
    //     suggestionParams: { suggestion },
    // })
  }

  getUnreadMessages = () => {
    const { unreadCount } = this.state
    const onlyValidMessages = _.filter(
      this.props.messages,
      (message) => message.timestamp
    )

    return onlyValidMessages.slice(0, unreadCount)
  }

  cleanUnread = () => {
    // clear unreads if all of the unread messages are viewed
    const { unreadCount, timestampsViewed } = this.state
    const unreadMessages = this.getUnreadMessages()

    if (!unreadCount) return

    const newUnreadMessagesCount = _.difference(
      unreadMessages.map((msg) => msg.timestamp),
      timestampsViewed
    ).length
    if (newUnreadMessagesCount === 0) {
      this.clearUnread()
    } else {
      this.setState({ unreadCount: newUnreadMessagesCount })
    }
  }

  openPreview =
    (id, { isReply, isComment, isGamePlanSelect }, meta) =>
    () => {
      //     const { toggleExpansion, expanded, people, messages } = this.props
      //     const [message] = getMessageById(id, messages)
      //     const user = people[message.senderId]
      //     if (message && message.image && user) {
      //         this.keyboardPersistingTransit(
      //             showImagePreview,
      //             message,
      //             this.props.pinUnpinMessage,
      //             user
      //         )
      //         return
      //     }
      //     if (
      //         !expanded &&
      //         (message.conversationTypeId === conversationTypeIds.message ||
      //             message.conversationTypeId === conversationTypeIds.reply ||
      //             message.conversationTypeId ===
      //                 conversationTypeIds.suggestionMessage)
      //     ) {
      //         return toggleExpansion()
      //     }
      //     if (expanded && isReply) {
      //         // Handle tapping on reply message
      //         const repliedToMessageId = message.messageId
      //         this.props.pushToJumpBack({
      //             position: id,
      //             nextPosition: repliedToMessageId,
      //         })
      //         return this.animatedJumpTo(repliedToMessageId)
      //     }
      //     if (expanded && isComment) {
      //         // Handle tapping on suggestion comment
      //         const commentedSuggestionId = +message.suggestionId
      //         this.props.pushToJumpBack({
      //             position: id,
      //             nextPosition: commentedSuggestionId,
      //         })
      //         return this.animatedJumpTo(commentedSuggestionId)
      //     }
      //     if (expanded && isGamePlanSelect && meta.suggestion) {
      //         // Handle tapping on suggestion select/unselect gameplan
      //         const suggestionId = +meta.suggestion.id
      //         this.props.pushToJumpBack({
      //             position: id,
      //             nextPosition: suggestionId,
      //         })
      //         return this.animatedJumpTo(suggestionId)
      //     }
    }

  setRequestedContextMenu = (highlightId) => {
    this.requestedContextMenu = highlightId
  }

  setIsContextMenuVisible = (isContextMenuOpen) => {
    this.setState({ isContextMenuOpen })
  }

  highlighterRefSetVisible = (highlightId) => {
    this.props.highlighterRef.current.setVisible(highlightId)
  }

  highlighterRefSetBottomPanelHeight = (bottomPanelHeight) => {
    // !this.props.highlighterRef.current?.setBottomPanelHeight(
    //     bottomPanelHeight
    // )
  }

  onContextMenu =
    (id, suggestionMessage) =>
    (passedProps = {}) => {
      const {
        pinUnpinMessage,
        pinUnpinSuggestion,
        toggleExpansion,
        downloadFile,
        expanded,
        teeup,
        permissions,
        decidedGameplans,
        userStatus,
        hiddenPinnedMessages,
        messages,
      } = this.props

      showChatMessageContextMenu({
        messages,
        id,
        suggestionMessage,
        passedProps,
        pinUnpinMessage,
        pinUnpinSuggestion,
        toggleExpansion,
        downloadFile,
        expanded,
        teeup,
        permissions,
        decidedGameplans,
        userStatus,
        hiddenPinnedMessages,
        isKeyboardActive: this.state.isKeyboardActive,
        onFilePress: this.onFilePress,
        onReplyStart: this.onReplyStart,
        setRequestedContextMenu: this.setRequestedContextMenu,
        setIsContextMenuVisible: this.setIsContextMenuVisible,
        highlighterRefSetVisible: this.highlighterRefSetVisible,
        highlighterRefSetBottomPanelHeight:
          this.highlighterRefSetBottomPanelHeight,
      })
    }

  keyboardPersistingTransit = (...args) => {
    const [transitionMethod, ...params] = args
    const { isKeyboardActive } = this.state

    this.handleCloseKeyboardOnTouchOutside()
    setTimeout(
      () => {
        transitionMethod(
          ...[...params, isKeyboardActive && this.handleKeyboardFocus]
        )
      },
      isKeyboardActive ? 300 : 0
    )
  }

  linkParticipantPopup = (userId) => {
    const { userInfo, toggleParticipantDetails, toggleDetailsUser } = this.props

    if (+userId === userInfo.id) {
      toggleParticipantDetails(true)
      toggleDetailsUser(+userId)

      return
    }

    this.setState({ userId })
  }

  // get information of system message series
  getSystemMessageGroupInfo = (index) => {
    const { messages } = this.props
    let first,
      size = 0
    // calculate count forward
    for (first = index; this.shouldGroupMessages(messages[first]); first--) {
      size++
    }
    // calculate count backward
    for (
      first = index;
      this.shouldGroupMessages(messages[first + 1]);
      first++
    ) {
      size++
    }
    return {
      first,
      size,
    }
  }

  shouldGroupMessages = (message = {}) =>
    message.conversationTypeId === conversationTypeIds.status ||
    message.conversationTypeId === conversationTypeIds.gameplanUpdate

  toggleSystemMessageGroup = (id) => () => {
    let { expandedSystemMessagesGroupArray } = this.state
    if (expandedSystemMessagesGroupArray.includes(id)) {
      expandedSystemMessagesGroupArray =
        expandedSystemMessagesGroupArray.filter((v) => v !== id)
    } else {
      expandedSystemMessagesGroupArray = [
        ...expandedSystemMessagesGroupArray,
        id,
      ]
    }

    this.setState({ expandedSystemMessagesGroupArray })
  }

  onReplyStart = (id, initialSuggestionMessage) => {
    const { teeupRef } = this.props
    // teeupRef?.handleSetShowUserSuggestions(false)
    const message = _.find(this.props.messages, (msg) => msg.id === id)
    this.props.setActiveReplyMessage(initialSuggestionMessage || message)
  }

  toggleExpansion = (showKeyboard) => {
    const { expanded, toggleExpansion } = this.props

    if (!expanded && showKeyboard === true) {
      this.showExpansionKeyboard = true
    }

    toggleExpansion()
  }

  toggleSwiping = (isSwiping) => this.setState({ isSwiping })

  renderWithExtraBehaviors = (children, { expandCard, dismissKeyboard }) => {
    const { expanded, toggleExpansion } = this.props

    return (
      <TouchableOpacity
        onPress={() => {
          !expanded && expandCard && toggleExpansion()
          this.state.isKeyboardActive &&
            dismissKeyboard &&
            this.handleKeyboardClose()
        }}
        activeOpacity={1}
        style={{ cursor: "default" }}
      >
        {children}
      </TouchableOpacity>
    )
  }

  onFilePress = (isFileOpen) => {
    this.setState({ isFileOpen })
  }

  renderItem = (message) => {
    const {
      isChild,
      myMessageParent,
      item,
      index,
      listRef,
      onUnpinMessage,
      disableReply = false,
      onPinboardScreen = false,
      onSuggestionCommentPress,
    } = message

    const {
      people,
      expanded,
      toggleExpansion,
      teeup,
      userInfo,
      suggestions,
      messages,
      permissions,
      newSuggestions,
      suggestionMessages,
      teeupTitle,
      settings,
      draggingSuggestionId,
      activeReplyMessage,
      highlighterRef,
      hiddenPinnedMessages,
      newDesign,
    } = this.props

    if (item?.daybreak) {
      return this.renderWithExtraBehaviors(
        <DateMessage date={item.daybreak} key={`${item.daybreak}-${index}`} />,
        { expandCard: true, dismissKeyboard: true }
      )
    }
    if (isChild && myMessageParent && !index) {
      return
    }

    const {
      expandedStatusMessagesArray,
      expandedSystemMessagesGroupArray,
      isTimestampsVisible,
      isKeyboardActive,
      newMessagesStarterId,
      sessionSuggestions,
    } = this.state
    const activeReplyMessageId = activeReplyMessage
      ? activeReplyMessage.id
      : null
    if (
      !people[item.senderId] &&
      !Array.isArray(item.senderId) &&
      !item.isSystem
    ) {
      return
    }
    const showUnreadBreak = _.get(item, "id", -1) === newMessagesStarterId

    let params = {
      people,
      expanded,
      toggleExpansion,
      teeup,
      userInfo,
      suggestions,
      messages,
      permissions,
      newSuggestions: sessionSuggestions,
      suggestionMessages,
      teeupTitle,
      item,
      index,
      onUnpinMessage,
      standAlone: true,
      expandedStatusMessagesArray,
      isTimestampsVisible: onPinboardScreen ? false : isTimestampsVisible,
      onContextMenu: this.onContextMenu,
      openPreview: this.openPreview,
      onReplyStart: this.onReplyStart,
      goToSuggestion: this.goToSuggestion,
      onStatusMessagePress: this.onStatusMessagePress,
      handleCloseAddFilesMenu: this.handleCloseAddFilesMenu,
      toggleSwiping: this.toggleSwiping,
      draggingSuggestionId,
      settings,
      activeReplyMessageId,
      openParticipantDetails: this.linkParticipantPopup,
      handleKeyboardClose: this.handleKeyboardClose,
      isKeyboardActive,
      onFilePress: this.onFilePress,
      isFileOpen: this.state.isFileOpen,
      isReplyDisabled: disableReply
        ? disableReply
        : this.state.isContextMenuOpen,
      onSuggestionCommentPress,
      uploadFiles: this.state.uploadFiles,
      onFileUploadCancel: this.handleFileUploadCancel,
      onFileRetry: this.onFileRetry,
    }
    let overrideOptions = {}
    if (hiddenPinnedMessages.includes(item.id)) {
      overrideOptions.isPinned = false
    }

    let showSystemMessagesToggle = false
    let systemMessagesToggleText = ""
    let chatMessageVisible = true
    let firstSystemMessageIdInGroup = null
    if (
      item.conversationTypeId === conversationTypeIds.status ||
      item.conversationTypeId === conversationTypeIds.gameplanUpdate
    ) {
      const { first, size } = this.getSystemMessageGroupInfo(index)
      firstSystemMessageIdInGroup = messages[first].id
      if (size > 1) {
        params.standAlone = false
        chatMessageVisible = expandedSystemMessagesGroupArray.includes(
          firstSystemMessageIdInGroup
        )
        if (index === first - size + 1) {
          showSystemMessagesToggle = true
          systemMessagesToggleText = `${size} Updates`
        }
      }
    }

    let options = getMessageOptions(params, overrideOptions, newDesign)
    const { isPinned } = options

    //temp solution to remove startsWhen suggestions
    if (
      options.children &&
      options.children.props &&
      options.children.props.suggestion &&
      options.children.props.suggestion.type === GAMEPLAN_OPTIONS.startsWhen
    ) {
      return null
    }

    const key = `message-${item.id || item.timestamp}`

    const getViewObj = (style, _options = options) => (
      <View
        collapsable={false}
        style={style}
        ref={listRef}
        // ref={(ref) => {
        //     item.id && highlighterRef.current?.setRef(key, ref)
        // }}
      >
        <ChatMessage
          {..._options}
          key={item.id}
          ref={(ref) => {
            if (item.id) this._itemRefs[item.id] = ref
          }}
          onPinboardScreen={onPinboardScreen}
          newDesign={newDesign}
        />
        {showUnreadBreak &&
          this.renderWithExtraBehaviors(
            <SeparatorMessage timestamp={item.timestamp} />,
            { expandCard: true, dismissKeyboard: true }
          )}
      </View>
    )

    const returnObj = getViewObj()
    let viewObj = returnObj

    if (isPinned) {
      viewObj = getViewObj(
        { marginBottom: Units.responsiveValue(8) },
        { ...options, isPinned: false }
      )
    }

    // item.id && highlighterRef.current?.setViewObj(key, viewObj)

    return returnObj
  }

  onStatusMessagePress = (id, index) => {
    this.setState(
      (prev) => ({
        expandedStatusMessagesArray: _.find(
          prev.expandedStatusMessagesArray,
          (expandedId) => expandedId === id
        )
          ? _.filter(
              prev.expandedStatusMessagesArray,
              (expandedId) => expandedId !== id
            )
          : [...prev.expandedStatusMessagesArray, id],
      }),
      () => {
        setTimeout(() => {
          this.scrollExpandedMessage(index + 1 - this.props.messages.length)
        }, 200)
      }
    )
  }

  onSend = () => {
    const { inputText, attachedFiles, attachedType } = this.state
    const {
      messageSend,
      setActiveTextMessage,
      activeReplyMessage,
      activeTextMessage,
      people,
      userInfo,
      applicationSoundEnabled,
    } = this.props
    const messageText = inputText.replace(/  +/g, " ").trim()
    if (messageText && messageText.length > 0) {
      const mentionedUserIds = getMentionedUsersIds(
        messageText,
        people,
        userInfo?.id
      )

      messageSend({
        message: messageText,
        messageId: activeReplyMessage ? activeReplyMessage.id : undefined,
        messageTypeId: messageTypeIds.message,
        mentionIds: mentionedUserIds,
      })
      this.setState({
        activeReplyMessage: null,
        lastVisibleTimestamp: new Date(Date.now() + 4000),
      })

      !!activeTextMessage && setActiveTextMessage()
      this.clearInput()
    }
    if (attachedFiles?.length) {
      if (attachedType === "img") {
        attachedFiles.forEach((image) =>
          messageSend({
            message: image.uri,
            messageTypeId: messageTypeIds.image,
          })
        )
      } else {
        const file = attachedFiles[0]
        const prefixToAdd = !isIOS && file.uri.startsWith("/") ? "file://" : ""
        messageSend({
          message: { ...file, uri: prefixToAdd + file.uri },
          messageTypeId: messageTypeIds.file,
          onFileSend: this.onFileSend,
          onFileSendError: this.onFileSendError,
        })
      }
      this.setState({
        attachedFiles: [],
        attachedType: null,
        showSend: false,
      })
    } else this.clearInput()
    // if (applicationSoundEnabled) {
    //     sounds.playBloopSound()
    // }
    this.handleCloseAddFilesMenu()
  }

  onAddSuggestion = (gameplan) => {
    // const { allowSelectGameplan } = this.props.permissions
    // routeSetupGameplan({
    //     ...gameplan,
    //     suggestions: [],
    //     allowPutOnGameplan:
    //         allowSelectGameplan && !gameplan.suggestions.length,
    // })
  }

  onSuggest = () => {
    const {
      teeup: { gameplans },
    } = this.props
    const buttons = gameplans.map((gameplan) => {
      if (
        gameplan.type === GAMEPLAN_OPTIONS.startsWhen ||
        gameplan.type === GAMEPLAN_OPTIONS.whenWorks
      ) {
        return
      }
      return {
        title: `Suggest ${getGameplanTitle(gameplan)}`,
        icon: getGameplanIcon(gameplan),
        callback: () => this.onAddSuggestion(gameplan),
      }
    })

    // showActionsheet(buttons)
  }

  enableMentionSelect = () => {
    const { teeupRef, people } = this.props
    teeupRef.handleSetShowUserSuggestions(true)
    teeupRef.handleSetUserSuggestionsList([
      everybodyItem,
      ...Object.values(people),
    ])
  }

  clearInput = () => {
    this.onChangeText("")
  }

  onChangeText = (text) => {
    // const { people } = this.props
    // const { attachedFiles } = this.state
    // const lastChar = text[text.length - 1]
    // const lastMatchedUser = getLastUserMention(text)
    // if (lastChar === '@') {
    //     this.enableMentionSelect()
    // }
    // const { isInputValidToSend } = handleCheckIfInputValid(
    //     text,
    //     [everybodyItem, ...Object.values(people)],
    //     showSuggestionList
    // )
    // this.setState({
    //     mentionedUser: lastMatchedUser,
    //     showFullMentionsList: lastChar === '@',
    // })
    // this.setState({ inputText: text }, () => {
    //     this.setState((currentState) => {
    //         const { showSend, inputText } = currentState
    //         if (
    //             (showSend && inputText.length === 0) ||
    //             !isInputValidToSend
    //         ) {
    //             return { showSend: false }
    //         } else if (
    //             !showSend &&
    //             ((inputText.length > 0 && isInputValidToSend) ||
    //                 attachedFiles?.length)
    //         ) {
    //             return { showSend: true }
    //         }
    //     })
    // })
  }

  scrollExpandedMessage = (messageId) => {
    if (this._listRef && messageId > 0) {
      this._listRef.scrollToIndex({
        index: messageId,
        viewPosition: 1,
        viewOffset: 0,
      })
    }
  }

  animatedJumpTo = (messageId) => {
    const index = this.props.messages.findIndex(
      (message) => message.id === messageId
    )

    if (this._listRef) {
      this._listRef.scrollToIndex({
        index,
        viewPosition: 1,
        viewOffset: -10,
      })
    }

    // if (this._itemRefs[messageId]) {
    //     setTimeout(() => {
    //         this._itemRefs[messageId]?.animate()
    //     }, 300)
    // }
  }

  onJumpBack = () => {
    // const position = this.props.popFromJumpBack()
    // if (!position) return
    // if (position === 'bottom') {
    //     return this.setState(
    //         {
    //             loadAllMessages: true,
    //         },
    //         () => {
    //             if (this._listRef) {
    //                 this._listRef.scrollToOffset(0)
    //             }
    //         }
    //     )
    // }
    // // For reply message stack
    // this.animatedJumpTo(position)
  }

  handleOffsetChange = (offsetY) => {
    const { expanded } = this.props

    // clear unread messages when scrolled to the bottom
    if (expanded && offsetY < SCROLL_BENCHMARK) {
      this.clearUnread()
    }

    this.lastOffsetY = offsetY

    this.setState({
      // only automatically remove unread states when user scrolled at least once
      allowCleanUnread: expanded,
      showJumpBackButton: offsetY > SCROLL_BENCHMARK,
      unreadBannerEnabled: offsetY > SCROLL_BENCHMARK,
    })

    // If scrolled to bottom
    // if (offsetY === 0) this.props.initJumpBack()
  }

  onScroll = (evt) => {
    this.handleOffsetChange(evt.nativeEvent.contentOffset.y)
  }

  onViewableItemsChanged = ({ viewableItems }) => {
    if (this.timeout) {
      clearInterval(this.timeout)
    }

    this.timeout = setTimeout(() => {
      const { teeup, expanded, readTimestamp, teeupReadTimestamp } = this.props
      const { timestampsViewed } = this.state

      if (expanded) {
        const newTimestampsViewed = _.uniq([
          ...timestampsViewed,
          ...viewableItems.map((msg) => msg.item.timestamp),
        ])
        this.setState(
          {
            timestampsViewed: newTimestampsViewed,
          },
          () => {
            this.cleanUnread()
          }
        )
      }

      this.setState((prevState) => {
        const newlyVisible = viewableItems.filter((item) => item.isViewable)
        const newlyVisibleSorted = _.sortBy(
          newlyVisible,
          (msg) => msg.item.timestamp && msg.item.timestamp
        )

        const lastVisibleItem = _.last(newlyVisibleSorted)

        if (newlyVisible.length) {
          if (lastVisibleItem.item.timestamp > teeupReadTimestamp) {
            readTimestamp(teeup.id, lastVisibleItem.item.timestamp)
          }

          if (
            !prevState.lastVisibleTimestamp ||
            prevState.lastVisibleTimestamp < lastVisibleItem.item.timestamp
          ) {
            return {
              lastVisibleTimestamp: lastVisibleItem.item.timestamp,
            }
          }
        }

        return {}
      })
    }, 400)
  }

  onUnreadClose = () => {
    const { readTimestamp } = this.props
    const sortedMessages = _.sortBy(this.props.messages, (msg) => msg.timestamp)
    const mostRecentMessage = _.last(sortedMessages)

    readTimestamp(this.props.teeup.id, mostRecentMessage.timestamp)
    this.clearUnread()
  }

  getFirstUnreadMessage = () => {
    const unreadMessages = this.getUnreadMessages()
    return unreadMessages[unreadMessages.length - 1]
  }

  onUnreadPress = () => {
    const { expanded } = this.props
    if (this._listRef) {
      const { messages, toggleExpansion } = this.props
      !expanded && toggleExpansion()
      const firstUnreadMessage = this.getFirstUnreadMessage()
      const index = _.findIndex(
        messages,
        (msg) => msg.id === firstUnreadMessage.id
      )

      requestAnimationFrame(() => {
        this._listRef.scrollToIndex({ index })
        this.setState({
          loadAllMessages: true,
        })
      })
    }
  }

  clearUnread = () => {
    const { readTeeupState, updateTeeupsState } = this.props
    // clear teeupState.newMessages
    readTeeupState(this.props.teeup.id)
    setTimeout(() => {
      updateTeeupsState()
    }, 200)

    this.setState({ unreadCount: 0, loadAllMessages: false })
  }

  handleOpenFilePicker = async () => {
    // try {
    //     const pickerResult = await DocumentPicker.pick({
    //         // type: [
    //         //     DocumentPicker.types.pdf,
    //         //     DocumentPicker.types.plainText,
    //         // ],
    //     })
    //     this.handleAddFile(pickerResult)
    // } catch (err) {
    //     if (DocumentPicker.isCancel(err)) {
    //         // User cancelled the picker, exit any dialogs or menus and move on
    //     } else {
    //         Toast.show('Unable to open the file manager', {
    //             bottomOffset: 8,
    //         })
    //     }
    // }
  }

  handleAddFile = (file) => {
    // let updates = {}
    // const { attachedFiles, attachedType } = this.state
    // if (attachedType && attachedType !== 'file')
    //     return Toast.show(I18n.t('attachFilesWarning'))
    // if (_.find(attachedFiles, (i) => i.uri === file.uri, null)) {
    //     updates.attachedFiles = []
    //     updates.attachedType = null
    // } else {
    //     updates.attachedFiles = [file]
    //     updates.attachedType = 'file'
    // }
    // this.setState(updates)
  }

  handleAddMedia =
    ({ type, uri }) =>
    async () => {
      //     const { attachedFiles, attachedType } = this.state
      //     let updates = {}
      //     if (attachedType && attachedType !== 'img') {
      //         return Toast.show(I18n.t('attachFilesWarning'))
      //     } else if (
      //         attachedFiles?.length === 4 &&
      //         uri &&
      //         !_.find(attachedFiles, (i) => i.uri === uri)
      //     ) {
      //         return Toast.show(I18n.t('maxAttachedImages'))
      //     }
      //     try {
      //         let imageUri = null
      //         const size = 512
      //         const format = 'PNG'
      //         if (type) {
      //             const { data } = await pickImage({
      //                 size,
      //                 title: 'New community request image ',
      //                 format,
      //                 type,
      //             })
      //             imageUri = data
      //         } else if (uri) {
      //             imageUri = uri
      //         }
      //         if (_.find(attachedFiles, (i) => i.uri === imageUri, null)) {
      //             updates.attachedFiles = attachedFiles.filter(
      //                 (i) => i.uri !== imageUri
      //             )
      //         } else {
      //             updates.attachedFiles = [
      //                 ...attachedFiles,
      //                 {
      //                     uri: imageUri,
      //                 },
      //             ]
      //         }
      //         this.setState({
      //             ...updates,
      //             ...{
      //                 attachedType: updates.attachedFiles.length ? 'img' : null,
      //             },
      //         })
      //     } catch (err) {
      //         console.log(err)
      //     }
      // }
      // handleChangeInputAnimatedWidth = (toValue) => {
      //     Animated.timing(this.inputWidthAnimatedValue, {
      //         duration: 1000,
      //         toValue,
      //         useNativeDriver: false,
      //     }).start()
    }

  handleOpenAddFilesMenu = () => {
    // const { teeupRef } = this.props
    // let {
    //     gettingRecentFiles,
    //     gettingRecentPhotos,
    //     cameraRollImages,
    //     recentFiles,
    // } = this.state
    // if (gettingRecentPhotos || gettingRecentFiles) {
    //     return
    // }
    // this.handleChangeInputAnimatedWidth(1)
    // Keyboard.dismiss()
    // this._inputRef.blur()
    // this.setState(
    //     {
    //         showAddMediaBlock: true,
    //         gettingRecentPhotos: true,
    //         gettingRecentFiles: true,
    //     },
    //     async () => {
    //         teeupRef?.handleSetShowUserSuggestions(false)
    //         if (cameraRollImages.length === 0) {
    //             try {
    //                 await checkModulePermission(
    //                     isIOS
    //                         ? PERMISSIONS.IOS.PHOTO_LIBRARY
    //                         : PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE
    //                 )
    //                 cameraRollImages = await getRecentPhotos()
    //             } catch (error) {
    //                 console.log(error)
    //             }
    //             this.setState({ gettingRecentPhotos: false })
    //         } else {
    //             this.setState({ gettingRecentPhotos: false })
    //         }
    //         if (recentFiles.length === 0) {
    //             try {
    //                 if (!isIOS) {
    //                     await checkModulePermission(
    //                         PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE
    //                     )
    //                 }
    //                 recentFiles = await getRecentFiles()
    //             } catch (error) {
    //                 console.log(error)
    //             }
    //             this.setState({ gettingRecentFiles: false })
    //         } else {
    //             this.setState({ gettingRecentFiles: false })
    //         }
    //         this.setState({
    //             cameraRollImages,
    //             recentFiles,
    //         })
    //     }
    // )
  }

  handleCloseAddFilesMenu = () => {
    // this.handleChangeInputAnimatedWidth(0)
    this.setState({
      showAddMediaBlock: false,
      gettingRecentPhotos: false,
      gettingRecentFiles: false,
    })
  }

  handleUserMention = () => {
    // const { teeupRef } = this.props
    // const { inputText } = this.state
    // if (!teeupRef?.state.showUserSuggestions) {
    //     const trimmedText = inputText.trim()
    //     this.onChangeText(trimmedText.length ? trimmedText + ' @' : '@')
    //     this.handleKeyboardFocus()
    // } else {
    //     teeupRef?.handleSetShowUserSuggestions(false)
    // }
  }

  onFieldHeightChange = (event) => {
    const { height } = event.nativeEvent.layout
    this.props.teeupRef.handleSetMentionsAnimatedValue(height)
  }

  handlePressPinnedIcon = (
    highlightType,
    hasUnreadNudges,
    hasUnreadMentions
  ) => {
    // showHighlightsScreen({
    //     renderChatItem: this.renderItem,
    //     initialIndex: highlightType,
    //     isNewMessageInNudges: hasUnreadNudges,
    //     isNewMessageInMentions: hasUnreadMentions,
    // })
    this.setState({ isHighlightsVisible: true })
  }

  handleKeyboardFocus = (delay = 100) => {
    setTimeout(() => {
      this._inputRef && this._inputRef.focus()
    }, delay)
  }

  handleKeyboardClose = () => Keyboard.dismiss()

  handleCloseKeyboardOnTouchOutside = () => {
    const { isKeyboardActive } = this.state

    isKeyboardActive && this.handleKeyboardClose()
  }

  handleRemoveAttached = (index) => () => {
    this.setState(({ attachedFiles }) => {
      const updatedAttached = [...attachedFiles]
      updatedAttached.splice(index, 1)
      return {
        attachedFiles: updatedAttached,
        ...(updatedAttached.length === 0 && { attachedType: null }),
      }
    })
  }

  onKeyboardFocus = () => {
    this.handleKeyboardFocus(0)
  }

  handleCloseHighlights = () => {
    // if closing animation reset state
    if (this.state.animationName === "right-slide") {
      this.setState({
        isHighlightsVisible: false,
        animationName: "left-slide",
      })
    }
  }

  render() {
    const {
      teeupRef,
      expanded,
      visible,
      messages,
      teeup,
      draggingSuggestion,
      draggingSuggestionId,
      activeReplyMessage,
      people,
      nudgeType,
      getStyledMessages,
    } = this.props
    const {
      showSend,
      showJumpBackButton,
      inputText,
      showAddMediaBlock,
      attachedFiles,
      attachedType,
      isSwiping,
      cameraRollImages,
      recentFiles,
      gettingRecentFiles,
      unreadCount,
      gettingRecentPhotos,
      unreadBannerEnabled,
      isKeyboardActive,
      userId,
      isHighlightsVisible,
      animationName,
    } = this.state

    const isMessagingDisabled = teeup.status === "cancelled"
    const isSuggestionsDisabled = isTeeupDisabled(teeup)
    const isScrollEnabled = !isSwiping && !draggingSuggestion

    const styledMessages = []
    messages.forEach((item, index) =>
      styledMessages.push(this.renderItem({ item, index }))
    )
    getStyledMessages(styledMessages)

    return (
      <>
        <DiscussionCardView
          newDesign={this.props.newDesign}
          index={this.props.index}
          setIndex={this.props.setIndexFunc}
          expanded={expanded}
          visible={visible}
          messages={messages}
          nudgeType={nudgeType}
          onMentionedUserRender={renderMentionedUser(
            people,
            false,
            teeupRef?.state.showUserSuggestions &&
              teeupRef?.state.suggestionsList?.length
          )}
          cameraRollImages={cameraRollImages}
          recentFiles={recentFiles}
          gettingRecentPhotos={gettingRecentPhotos}
          gettingRecentFiles={gettingRecentFiles}
          animationStyle={{
            minHeight: this.heightAnimatedValue,
          }}
          inputAnimationStyle={{
            width: this.inputWidthAnimatedValue.interpolate({
              inputRange: [0, 1],
              outputRange: [showSend || inputText ? "83%" : "73%", "100%"],
            }),
          }}
          inputRef={(ref) => (this._inputRef = ref)}
          listRef={(ref) => (this._listRef = ref)}
          showJumpBackButton={showJumpBackButton}
          showSend={showSend || !!activeReplyMessage || !!attachedFiles?.length}
          isKeyboardActive={isKeyboardActive}
          unreadCount={unreadCount}
          activeReplyMessage={activeReplyMessage}
          attachedFiles={attachedFiles}
          attachedType={attachedType}
          renderItem={this.renderItem}
          draggingSuggestionId={draggingSuggestionId}
          scrollEnabled={isScrollEnabled}
          onSend={this.onSend}
          onChangeText={this.onChangeText}
          onJumpBack={this.onJumpBack}
          onScroll={this.onScroll}
          onSuggest={this.onSuggest}
          onViewableItemsChanged={this.onViewableItemsChanged}
          onUnreadClose={this.onUnreadClose}
          onUnreadPress={this.onUnreadPress}
          onOpenFilePicker={this.handleOpenFilePicker}
          onAddFile={this.handleAddFile}
          onAddMedia={this.handleAddMedia}
          onRemoveAttached={this.handleRemoveAttached}
          onOpenAddFilesMenu={this.handleOpenAddFilesMenu}
          onCloseAddMediaBlock={this.handleCloseAddFilesMenu}
          onUserMention={this.handleUserMention}
          onPressPinnedIcon={this.handlePressPinnedIcon}
          inputValue={inputText}
          isMessagingDisabled={isMessagingDisabled}
          showAddMediaBlock={showAddMediaBlock}
          isSuggestionsDisabled={isSuggestionsDisabled}
          onMoveShouldSetResponder={this.onMoveShouldSetResponder}
          onStartShouldSetResponder={this.onStartShouldSetResponder}
          onFieldHeightChange={this.onFieldHeightChange}
          toggleExpansion={this.toggleExpansion}
          testID={"teeupDiscussion"}
          unreadBannerEnabled={unreadBannerEnabled}
          handleCloseKeyboardOnTouchOutside={
            this.handleCloseKeyboardOnTouchOutside
          }
        />
        {userId && (
          <ParticipantPopup
            userId={userId}
            onClose={() => this.setState({ userId: null })}
          />
        )}
        {isHighlightsVisible && (
          <div
            className="higlights__wrapper"
            onAnimationEnd={this.handleCloseHighlights}
          >
            <div className="higlights" style={{ animationName }}>
              <HighlightsTabContainer
                initialIndex={this.state.highlightInitialTab}
                close={() => this.setState({ animationName: "right-slide" })}
                renderChatItem={this.renderItem}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

DiscussionCardContainer.defaultProps = {
  people: {},
  messages: [],
  suggestionMessages: {},
  suggestions: {},
  newSuggestions: {},
  settings: {},
  toggleExpansion: () => {},
  pinUnpinMessage: () => {},
  pinUnpinSuggestion: () => {},
}

const mapMethodsToProps = {
  toggleParticipantDetails: toggleParticipantDetails,
  toggleDetailsUser: toggleDetailsUser,
  setActiveHighlightedMessage: setActiveHighlightedMessage,
  getStyledMessages: getStyledMessages,
}

const mapStateToProps = (state) => {
  const userInfo = selectUserInfo(state)
  const permissions = selectPermissions(state)

  //temp soliution to remove duplicates
  const messages = selectMessagesWithoutNugdes(state)
  const allMessages = selectMessages(state)
  // let timestamps = selectTeeupMessagesTimestamps(state)
  // messages = _.uniqBy(messages, (m) => [m.id, m.timestamp].join())
  // timestamps = _.uniq(timestamps, 'timestamp')
  const hiddenPinnedMessages = selectHiddenPinnedMessages(state)
  const suggestionMessages = selectSuggestionMessages(state)
  const teeup = selectActiveTeeup(state)
  const people = selectTeeupPeople(state)
  const suggestions = selectTeeupSuggestions(state)
  // const teeupReadTimestamp = selectTeeupReadTimestamps(state)
  // const newSuggestions = selectNewSuggestions(state)
  const settings = selectActiveTeeupSettings(state)
  // const teeupState = selectTeeupState(state)
  const decidedGameplans = selectDecidedGameplans(state)

  // const draggingSuggestion = selectDraggingSuggestion(state)
  // const notificationMentionMessage =
  //     state.localData.notificationMentionMessage

  // const draggingSuggestionId = selectDraggingSuggestionId(state)

  // const additionalGoingPeopleInTeeup = selectAdditionalGoingPeopleInTeeup(
  //     state
  // )
  // const activeReplyMessage = selectActiveReplyMessage(state)
  // const activeTextMessage = selectActiveTextMessage(state)
  // const applicationSoundEnabled = selectSoundSettings(state)
  const activeHighlightedMessage = selectActiveHighlightedMessage(state)

  return {
    messages,
    allMessages,
    hiddenPinnedMessages,
    suggestionMessages,
    teeupTitle: teeup.name,
    people,
    userInfo,
    suggestions,
    // teeupReadTimestamp,
    teeup,
    // newSuggestions,
    permissions,
    settings,
    // draggingSuggestion,
    // draggingSuggestionId,
    // additionalGoingPeopleInTeeup,
    // activeReplyMessage,
    // activeTextMessage,
    activeHighlightedMessage,
    // teeupState,
    // timestamps,
    decidedGameplans,
    // notificationMentionMessage,
    // applicationSoundEnabled,
  }
}

DiscussionCardContainer.propTypes = {
  componentId: PropTypes.string,
  teeupRef: PropTypes.object,
  discussionCardRef: PropTypes.func,
  readTimestamp: PropTypes.any,
  teeupTitle: PropTypes.string,
  hiddenPinnedMessages: PropTypes.array,
  expanded: PropTypes.bool,
  previewOn: PropTypes.bool,
  // downloadFile: PropTypes.func.isRequired,

  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      timestamp: PropTypes.number,
      daybreak: PropTypes.string,
    })
  ),
  teeup: PropTypes.object,
  newSuggestions: PropTypes.object,
  permissions: PropTypes.shape({ allowSelectGameplan: PropTypes.bool }),
  suggestions: PropTypes.shape({}),
  userInfo: PropTypes.shape({ id: PropTypes.number }),
  suggestionMessages: PropTypes.shape({}),
  people: PropTypes.shape({}),
  teeupState: PropTypes.object,
  timestamps: PropTypes.array,
  // teeupReadTimestamp: PropTypes.number.isRequired,

  toggleExpansion: PropTypes.func,
  messageSend: PropTypes.func,
  readTeeupState: PropTypes.func,
  updateTeeupsState: PropTypes.func,
  // pinUnpinMessage: PropTypes.func.isRequired,
  // pinUnpinSuggestion: PropTypes.func.isRequired,
  settings: PropTypes.object,
  draggingSuggestion: PropTypes.any,
  draggingSuggestionId: PropTypes.any,
  additionalGoingPeopleInTeeup: PropTypes.number,
  setActiveReplyMessage: PropTypes.func,
  activeReplyMessage: PropTypes.shape({
    id: PropTypes.string,
  }),
  setActiveTextMessage: PropTypes.func,
  activeTextMessage: PropTypes.string,
  activeHighlightedMessage: PropTypes.string,
  highlighterRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(React.Component) }),
  ]),
  // initJumpBack: PropTypes.func.isRequired,
  // pushToJumpBack: PropTypes.func.isRequired,
  // popFromJumpBack: PropTypes.func.isRequired,
  decidedGameplans: PropTypes.object,
  userStatus: PropTypes.string,
  visible: PropTypes.bool,
  notificationMentionMessage: PropTypes.string,
  setNotificationMentionMessage: PropTypes.func,
  nudgeType: PropTypes.number,
  applicationSoundEnabled: PropTypes.bool,
}

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//         {
//             ...teeupActions,
//             ...teeupCardActions,
//         },
//         dispatch
//     )

export default connect(mapStateToProps, mapMethodsToProps, null, {
  forwardRef: true,
})(DiscussionCardContainer)
