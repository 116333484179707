import React, { Component } from "react"
import { debounce, isEmpty } from "lodash"
import propTypes from "prop-types"
// import AsyncStorage from '@react-native-community/async-storage'
import {
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Image,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
} from "react-native"
// import { Navigation } from 'react-native-navigation'
// import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view'

import I18n from "@i18n"
import { AppColors, Units } from "@theme/"
import { images } from "@utils/imageUtils"
// import backend from '@apis/backend'
import {
  getLocation,
  getDistanceByCoordinates,
  SELECT_LOCATION_TYPES,
} from "@utils/location"
import { styles } from "./styles"
import {
  autocompletePlace,
  findByPlaceId,
  findByPlaceCoords,
  autocompleteNearbySearch,
} from "../../../middlewares/backendMiddleware"

const SelectPlaceTitleButton = ({ onPress }) => (
  <KeyboardAvoidingView
    behavior={Platform.OS === "ios" ? "padding" : null}
    enabled
  >
    <TouchableOpacity onPress={onPress} style={styles.doneButton}>
      <Text style={styles.doneButtonText}>Done</Text>
    </TouchableOpacity>
  </KeyboardAvoidingView>
)
SelectPlaceTitleButton.propTypes = {
  onPress: propTypes.func.isRequired,
}

const METERS_IN_MILE = 1609
const MAX_HISTORY_LENGTH = 100

const SearchSuggestionItem = ({
  onPress,
  icon,
  distance,
  description,
  details,
  isSearchTerm,
  onRightArrowPress,
  isManualSuggestion,
}) => (
  <TouchableOpacity onPress={onPress} style={styles.suggestionWrapper}>
    <View style={styles.leftPart}>
      <View style={styles.iconWrapper(isSearchTerm)}>
        {!isManualSuggestion && (
          <Image style={styles.iconStyle} source={icon} />
        )}
      </View>
      {!!distance && (
        <Text style={styles.distanceText}>
          {Math.round((distance / METERS_IN_MILE) * 10) / 10} mi
        </Text>
      )}
    </View>
    <View style={styles.descriptionPart}>
      <Text numberOfLines={1} style={styles.text}>
        {isManualSuggestion ? `"${description}"` : description}
      </Text>
      {!!details && (
        <Text numberOfLines={1} style={styles.subText}>
          {details}
        </Text>
      )}
    </View>
    {!isManualSuggestion && (
      <TouchableOpacity
        style={styles.rigthArrowWrapper}
        onPress={onRightArrowPress}
      >
        <Image source={images.topLeftArrow} />
      </TouchableOpacity>
    )}
  </TouchableOpacity>
)
SearchSuggestionItem.propTypes = {
  onPress: propTypes.func.isRequired,
  onRightArrowPress: propTypes.func.isRequired,
  icon: propTypes.number.isRequired,
  distance: propTypes.number,
  description: propTypes.oneOfType([
    propTypes.string.isRequired,
    propTypes.object,
  ]),
  details: propTypes.string,
  isSearchTerm: propTypes.bool.isRequired,
  isManualSuggestion: propTypes.bool,
}

class SearchLocationList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      searchValue: props.selectedValue || null,
      requestsHistory: [],
      filteredHistory: [],
      userLocation: null,
    }
    this.searchRef = React.createRef()
    this._searchPlace = debounce(this.handleSearchAutocomplete, 250)
  }

  componentDidMount() {
    this.locationRequest()
    this.getRequestsHistory()
    const { selectedValue, isPlaceLabelInput } = this.props
    this.handleReplaceSearchValue(selectedValue)
    if (selectedValue && !isPlaceLabelInput) {
      this.handleSearchAutocomplete(selectedValue)
    }
  }

  locationRequest = () => {
    getLocation(
      (location) => {
        this.setState({ userLocation: location }, () => {
          if (this.selectUserLocationRequest) {
            this.selectUserLocationRequest = false
            this.handleSelectCurrentLocation()
          }
        })
      },
      () => {
        this.locationWasDenied = true
      }
    )
  }

  getRequestsHistory = async () => {
    try {
      const { type } = this.props
      const { userLocation } = this.state
      // const history = await AsyncStorage.getItem(`${type}History`)
      const history = {}
      if (!history) return
      const requestsHistory = JSON.parse(history)
      const initHistory = requestsHistory.map((histItem) => {
        const { coordinates } = histItem
        return {
          ...histItem,
          ...(coordinates && {
            distance: getDistanceByCoordinates(
              {
                latitude: coordinates.lat,
                longitude: coordinates.lng,
              },
              userLocation
            ),
          }),
        }
      })
      this.setState({
        requestsHistory: initHistory,
        filteredHistory: initHistory,
      })
    } catch (error) {
      console.log("getRequestsHistory ERROR: ", error)
    }
  }

  handleSetItemToHistory = async (item) => {
    try {
      const { type } = this.props
      let { requestsHistory } = this.state
      const isItemExists = requestsHistory.filter(
        (hist) => hist.id === item.id
      )[0]
      if (isItemExists) return
      const historyItem = {
        ...item,
        isHistoryItem: true,
      }
      if (requestsHistory.length === MAX_HISTORY_LENGTH) {
        //max history items
        requestsHistory.unshift(historyItem)
        requestsHistory.length = MAX_HISTORY_LENGTH
      } else requestsHistory.unshift(historyItem)
      const history = JSON.stringify(requestsHistory)
      // await AsyncStorage.setItem(`${type}History`, history)
      this.setState({ requestsHistory })
    } catch (error) {
      console.log("handleSetItemToHistory ERROR: ", error)
    }
  }

  onRowPresssed =
    ({ item }) =>
    async () => {
      const { isEnableSelectCurrentLocation } = this.state
      const { type, onSelectValue } = this.props
      const {
        isManualSuggestion,
        mainText,
        secondaryText,
        description,
        details = null,
        coordinates = null,
        place_id = null,
      } = item
      !!description && this.handleSetItemToHistory(item)
      let selectedLocationValue = description
      let params = {
        type,
        value: selectedLocationValue,
        coordinates,
        googlePlaceId: place_id,
      }
      const isPlaceName = type === SELECT_LOCATION_TYPES.placeName

      if (
        (!isPlaceName && place_id) ||
        (isEnableSelectCurrentLocation && isManualSuggestion)
      ) {
        params.type = SELECT_LOCATION_TYPES.placeName
      }

      params.details = secondaryText?.trim() || details
      params.fullDetails = details || secondaryText?.trim()
      if (mainText) {
        params.value = mainText.trim()
      } else if (secondaryText) {
        params.value = secondaryText.trim()
      }
      await onSelectValue(params)
      this.onClose()
    }

  handleEnterPress = (event) => {
    const { isPlaceLabelInput } = this.props
    if (isPlaceLabelInput) {
      return this.handleSelectPlaceTitle()
    }
    const text = event.nativeEvent.text
    this.handleSearchTermPress(text)()
  }

  handleChangeSearch = (searchValue) => {
    const { isEnableSelectCurrentLocation } = this.state
    if (isEnableSelectCurrentLocation) {
      this.setState({ isEnableSelectCurrentLocation: false })
    }
    this._searchPlace(searchValue.trim())
  }

  changeSearchValue = (searchValue) =>
    this.setState({ searchValue: searchValue.trim() })

  handleSearchAutocomplete = async (searchValue) => {
    const { currentUserHomeLocation } = this.props
    const { userLocation } = this.state
    let locationParams = null
    if (userLocation) {
      locationParams = [userLocation.latitude, userLocation.longitude]
    }
    const cities = await autocompletePlace(searchValue, locationParams)
    let formattedData = await this.handleFormatAutoCompleteData(cities)
    const isStartsWithHome = "Home".match(new RegExp(searchValue, "i"))
    if (
      isStartsWithHome &&
      isStartsWithHome[0] &&
      !isEmpty(currentUserHomeLocation)
    ) {
      formattedData = [
        {
          id: currentUserHomeLocation.id,
          mainText: "Home",
          secondaryText: currentUserHomeLocation.name,
          place_id: currentUserHomeLocation.googlePlaceId,
          details: currentUserHomeLocation.name,
          description: "Home",
          coordinates: {
            lat: currentUserHomeLocation.latitude,
            lng: currentUserHomeLocation.longitude,
          },
        },
        ...formattedData,
      ]
    }
    this.setState({ searchValue, data: formattedData }, () =>
      this.filterHistoryItems(searchValue)
    )
  }

  handleFormatAutoCompleteData = (cities) => {
    const formatted = cities.predictions.map(async (res) => {
      const predictionObject = {}
      predictionObject.mainText = res.structured_formatting.main_text
      if (res.structured_formatting.secondary_text) {
        const resArr = res.structured_formatting.secondary_text.split(", ")
        const arLength = resArr.length
        predictionObject.secondaryText =
          arLength >= 2
            ? [resArr[arLength - 2], resArr[arLength - 1]].join(", ")
            : res.structured_formatting.secondary_text
      }
      predictionObject.description = res.description
      predictionObject.id = res.id
      predictionObject.place_id = res.place_id
      if (!res.place_id) {
        return { ...predictionObject, isSearchTerm: true }
      }
      const response = await findByPlaceId(res.place_id)
      const item = response.results[0]
      if (item) {
        const {
          geometry: { location },
          formatted_address,
        } = item
        const { userLocation } = this.state
        predictionObject.coordinates = location
        predictionObject.details = formatted_address
        predictionObject.distance = getDistanceByCoordinates(
          {
            latitude: location.lat,
            longitude: location.lng,
          },
          userLocation
        )
        return predictionObject
      }
    })
    return Promise.all(formatted).then((results) => results)
  }

  filterHistoryItems = (searchValue) => {
    const { requestsHistory } = this.state
    const pattern = RegExp(searchValue, "gi")
    const filteredHistory = requestsHistory.filter((item) =>
      item.description.match(pattern)
    )
    this.setState({ filteredHistory })
  }

  handleResetSearch = () => {
    this.setState({ searchValue: null, data: [] }, () => {
      this.searchRef.current.clear()
      this.filterHistoryItems()
    })
  }

  handleSelectCurrentLocation = async () => {
    if (this.state.userLocation) {
      const {
        userLocation: { latitude, longitude },
      } = this.state
      let locationValue = `${latitude},${longitude}`
      try {
        const places = await findByPlaceCoords({
          latitude,
          longitude,
        })
        const currentPlace = places.results[0]
        locationValue = currentPlace.formatted_address
      } catch (error) {
        console.log("handleSelectCurrentLocation error: ", error)
      }
      this.handleReplaceSearchValue(locationValue)
      this.handleSearchAutocomplete(locationValue)
      this.setState({ isEnableSelectCurrentLocation: true })
    } else {
      this.selectUserLocationRequest = true
      if (this.locationWasDenied) {
        this.locationWasDenied = false
        this.locationRequest()
      }
    }
  }

  handleReplaceSearchValue = (searchValue) => {
    this.searchRef.current.setNativeProps({ text: searchValue })
    this.setState({ searchValue }, () => {
      this.filterHistoryItems(searchValue)
    })
  }

  onClose = () => {
    Keyboard.dismiss()
    // Navigation.dismissModal(this.props.componentId)
  }

  renderItem = ({ item }) => {
    if (!item) {
      return null
    }
    const {
      description,
      mainText,
      details,
      secondaryText,
      distance,
      isManualSuggestion,
      isHistoryItem,
      isLocationButton,
      isSearchTerm,
    } = item
    if (isLocationButton) {
      return (
        <TouchableOpacity
          style={styles.suggestionWrapper}
          onPress={this.handleSelectCurrentLocation}
        >
          <View style={styles.leftPart}>
            <Image style={styles.iconStyle} source={images.locationSmall} />
          </View>
          <View style={styles.descriptionPart}>
            <Text style={styles.text}>{I18n.t("useCurrentLocation")}</Text>
          </View>
        </TouchableOpacity>
      )
    }
    return (
      <SearchSuggestionItem
        onPress={
          isSearchTerm && !isHistoryItem
            ? this.handleSearchTermPress(mainText || description)
            : this.onRowPresssed({ item })
        }
        icon={
          isHistoryItem
            ? images.clockHistory
            : isSearchTerm
            ? images.search
            : images.marker
        }
        description={mainText || description}
        details={details || secondaryText}
        distance={distance}
        isManualSuggestion={Boolean(isManualSuggestion && !isHistoryItem)}
        isSearchTerm={Boolean(isSearchTerm && !isHistoryItem)}
        onRightArrowPress={this.handleAddValueAndSearch(
          mainText || description
        )}
      />
    )
  }

  handleSearchTermPress = (searchValue) => async () => {
    this.handleReplaceSearchValue(searchValue)
    const { userLocation } = this.state
    let locationParams = null
    if (userLocation) {
      locationParams = [userLocation.latitude, userLocation.longitude]
    }
    try {
      const nearbyPlaces = await autocompleteNearbySearch(
        searchValue,
        locationParams
      )
      let formattedData = await this.handleFormatNearbyPlaces(
        nearbyPlaces?.results || []
      )
      this.setState({ data: formattedData })
    } catch (e) {
      console.log("handleSearchTermPress: ", { e })
    }
  }

  handleAddValueAndSearch = (searchValue) => async () => {
    this.handleReplaceSearchValue(searchValue)
    const { userLocation } = this.state
    let locationParams = null
    if (userLocation) {
      locationParams = [userLocation.latitude, userLocation.longitude]
    }
    try {
      const cities = await autocompletePlace(searchValue, locationParams)
      let formattedData = await this.handleFormatAutoCompleteData(cities)
      this.setState({ data: formattedData })
    } catch (e) {
      console.log("handleSearchTermPress: ", { e })
    }
  }

  handleFormatNearbyPlaces = (places = []) => {
    const { userLocation } = this.state
    const formattedData = places.map((place) => {
      let formattedObject = {}
      formattedObject.mainText = place.name
      formattedObject.description = place.name
      formattedObject.id = place.id
      formattedObject.place_id = place.place_id
      formattedObject.coordinates = place.geometry?.location
      formattedObject.details = place.vicinity
      formattedObject.distance = getDistanceByCoordinates(
        {
          latitude: place.geometry?.location.lat,
          longitude: place.geometry?.location.lng,
        },
        userLocation
      )
      return formattedObject
    })
    return formattedData
  }

  handleMapClick = async () => {
    await this.props.onMapScreenOpen()
    this.onClose()
  }

  handleSelectPlaceTitle = async () => {
    const { onSelectValue } = this.props
    const { searchValue } = this.state
    await onSelectValue({
      type: SELECT_LOCATION_TYPES.placeTitle,
      value: searchValue,
    })
    this.onClose()
  }

  keyExtractor = (item, index) => `suggestLocationItem-${item?.id} + ${index}`

  render() {
    const { data, searchValue, filteredHistory } = this.state
    const { textPlaceholder, isPlaceLabelInput } = this.props
    let historyData = []
    let flatListData = []
    let additionalInputProps = {}

    if (!isPlaceLabelInput) {
      additionalInputProps = { returnKeyType: "search" }
      historyData = filteredHistory
      flatListData = [...historyData, ...data]
      if (searchValue) {
        flatListData.unshift({
          id: searchValue.trim(),
          description: searchValue.trim(),
          isManualSuggestion: true,
        })
      }
      if (!searchValue) {
        flatListData.unshift({ isLocationButton: true })
      }
    }

    return (
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <TouchableOpacity
            hitSlop={Units.hitSlop()}
            onPress={this.onClose}
            style={styles.headerButton}
          >
            <Image style={styles.backArrow} source={images.back} />
          </TouchableOpacity>
          <View style={styles.inputWrapper}>
            <TextInput
              ref={this.searchRef}
              style={styles.textSearch}
              placeholder={textPlaceholder}
              placeholderTextColor={AppColors.brand.warmGrey2}
              underlineColorAndroid="transparent"
              onChangeText={
                isPlaceLabelInput
                  ? this.changeSearchValue
                  : this.handleChangeSearch
              }
              onSubmitEditing={this.handleEnterPress}
              blurOnSubmit={false}
              autoFocus
              {...additionalInputProps}
            />
            {!!searchValue && (
              <TouchableOpacity
                hitSlop={Units.hitSlop()}
                onPress={this.handleResetSearch}
              >
                <Image style={styles.clearImage} source={images.closeWithBg} />
              </TouchableOpacity>
            )}
          </View>
        </View>
        {isPlaceLabelInput ? (
          <>
            <View style={styles.placeholderView} />
            <SelectPlaceTitleButton onPress={this.handleSelectPlaceTitle} />
          </>
        ) : (
          // <KeyboardAwareFlatList
          //     style={styles.listContainer}
          //     keyboardShouldPersistTaps="handled"
          //     data={flatListData}
          //     initialListSize={4}
          //     renderItem={this.renderItem}
          //     keyExtractor={this.keyExtractor}
          //     ItemSeparatorComponent={() => (
          //         <View style={styles.separator} />
          //     )}
          // />
          <View></View>
        )}
      </View>
    )
  }
}

SearchLocationList.propTypes = {
  isPlaceLabelInput: propTypes.string,
  type: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  onSelectValue: propTypes.func.isRequired,
  textPlaceholder: propTypes.string.isRequired,
  onMapScreenOpen: propTypes.func,
  selectedValue: propTypes.string,
}

export default SearchLocationList
