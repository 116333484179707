import React from "react"
import { Text, Image, View, TouchableOpacity } from "react-native"
import Props from "prop-types"

import { images } from "@utils/imageUtils"
import styles from "./styles"

const Interest = ({ text, outline, isLast, hideIcon, onRemove }) => (
  <View
    style={[styles.container(isLast), outline ? styles.outlineContainer : null]}
  >
    {!hideIcon && (
      <Image
        style={styles.image}
        source={outline ? images.heartOutline : images.heart}
      />
    )}
    <Text style={[styles.text, outline ? styles.outlineText : null]}>
      {text}
    </Text>

    {!!onRemove && (
      <TouchableOpacity
        style={styles.removeContainer}
        activeOpacity={0.7}
        onPress={onRemove}
      >
        <Image source={images.removeCircle} />
      </TouchableOpacity>
    )}
  </View>
)

Interest.propTypes = {
  text: Props.string.isRequired,
  isLast: Props.bool,
  outline: Props.bool,
  hideIcon: Props.bool,
  onRemove: Props.func,
}

export default Interest
