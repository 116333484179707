import ApiCalendar from "react-google-calendar-api"
import moment from "moment"
import get from "lodash/get"
import { gapi } from "gapi-script"

import actionTypes from "./actionTypes"
import api from "../api"
import endpoints from "@config/endpoints"
import { store } from "../index"
import {
  formatCalendarEventDate,
  getDateWithTimezone,
  getCurrentTimezone,
} from "@utils/dateUtils"
import { selectUserId, selectCalendarSyncSettings } from "@selectors/user"
import { selectTeeupsGameplans, selectActiveTeeups } from "@selectors/teeups"
import { getSelectedGameplan } from "@utils/gamePlanUtils"
import { teeupStatusKeys } from "@configs/enums"

export const gotCalendar = (payload) => ({
  type: actionTypes.GOT_CALENDAR,
  payload, 
})

export const gotAvaliableCalendars = (calendars) => ({
  type: actionTypes.GOT_AVALIABLE_CALENDARS,
  payload: calendars,
})

export const setCalendarPermissions = (permissions) => ({
  type: actionTypes.SET_CALENDAR_PERMISSIONS,
  payload: permissions,
})

export const calendarAddTeeupEvents = (newlySyncedTeeups) => ({
  type: actionTypes.CALENDAR_ADD_TEEUP_EVENTS,
  payload: newlySyncedTeeups,
})

export const setBeforeCalendarDate = (payload) => ({
  type: actionTypes.SET_BEFORE_CALENDAR_DATE,
  payload,
})

export const calendarTeeupRemoved = (teeupId, eventIdBE) => {
  const url = endpoints.calendarEvent(teeupId) + "/" + eventIdBE
  return api.client
    .delete(url)
    .then((response) => {
      const { data } = response

      return data
    })
    .catch((error) => console.log("delete calendar error", error))
}

export const calendarTeeupAdd = ({
  teeupId,
  userId,
  eventId,
  eventIdBE,
  external = false,
}) => {
  let url = endpoints.calendarEvent(teeupId)
  let body = {
    eventId,
    external,
  }

  if (eventIdBE) {
    // Means event already exists in BE, need to update it
    url += "/" + eventIdBE
    return api.client
      .put(url)
      .then((response) => {
        const { data } = response

        return data
      })
      .catch((error) => console.log("update calendar error", error))
  } else {
    body.userId = userId
  }

  return api.client
    .post(url, body)
    .then((response) => {
      const { data } = response

      return data
    })
    .catch((error) => console.log("add calendar error", error))
}

const MAX_EVENTS = 10000

// Workaround for fetching past events from Google Calendar
const listAllEvents = () =>
  gapi.client.calendar.events.list({
    calendarId: "primary",
    timeMin: moment().startOf("month").subtract(6, "months").format(),
    showDeleted: false,
    singleEvents: true,
    maxResults: MAX_EVENTS,
    orderBy: "startTime",
  })

export const fetchCalendarEvents = () => {
  if (!ApiCalendar.sign) {
    return
  }
  const { syncTeeups } = selectCalendarSyncSettings(store.getState())
  syncTeeups &&
    listAllEvents()
      .then(({ result }) => {
        const { items: events, summary } = result
        const filteredEvents = []
        events.forEach((event) => {
          let formattedEvent = {
            ...event,
            name: event.summary,
          }
          // if event start has only date then it's all day
          if (event.start.date) {
            const momentDate = moment.utc(event.start.date)
            const eventDay = getDateWithTimezone()
              .year(momentDate.year())
              .month(momentDate.month())
              .date(momentDate.date())

            formattedEvent.startDate = formatCalendarEventDate(
              eventDay.startOf("day")
            )
            formattedEvent.endDate = formatCalendarEventDate(
              eventDay.endOf("day")
            )
          } else {
            formattedEvent.startDate = event.start.dateTime
            formattedEvent.endDate = event.end.dateTime
          }

          filteredEvents.push(formattedEvent)
        })
        store.dispatch({
          type: actionTypes.GOT_CALENDAR,
          payload: { events: filteredEvents, userEmail: summary },
        })

        const calendarSyncSettings = selectCalendarSyncSettings(
          store.getState()
        )
        if (calendarSyncSettings.syncTeeups) {
          syncCalendar(filteredEvents)
        }
      })
      .catch((error) => {
        console.log("fetchCalendarEvents error", error)
      })
}

export const syncCalendar = async (calendarEvents) => {
  const state = store.getState()
  const teeups = selectActiveTeeups(state)
  const teeupGameplans = selectTeeupsGameplans(state)

  // 1) not there yet
  // 2) has a date/time specific when gameplan
  const timeZone = getCurrentTimezone()

  teeups.forEach(async (teeup) => {
    const gameplans = get(teeupGameplans, teeup.id, [])
    const selectedWhen = getSelectedGameplan(gameplans, "when")

    if (
      selectedWhen &&
      getDateWithTimezone(selectedWhen.startDate).isAfter(new Date())
    ) {
      const { startDate, endDate } = selectedWhen
      const existingEvent =
        teeup.events &&
        calendarEvents.find((calendarEvent) => {
          return teeup.events.some(
            (teeupEvent) => teeupEvent.eventId === calendarEvent.id
          )
        })

      const summary =
        teeup.status === teeupStatusKeys.planning
          ? `[Planning] ${teeup.name}`
          : teeup.name
      const event = {
        start: { dateTime: startDate, timeZone },
        end: { dateTime: endDate, timeZone },
        summary,
      }

      if (existingEvent) {
        updateEvent(event, existingEvent.id)
      } else {
        createEvent(event, teeup)
      }
    }
  })
}

const updateEvent = async (updatedEvent, eventId) => {
  await ApiCalendar.updateEvent(updatedEvent, eventId)
}

const createEvent = (event, teeup) => {
  const state = store.getState()
  const userId = selectUserId(state)

  ApiCalendar.createEvent(event)
    .then((response) => {
      const { id } = response.result
      const url = endpoints.calendarEvent(teeup.id)

      api.client
        .post(url, { eventId: id, external: false, userId })
        .catch((error) => {
          console.log("createCalendarEvent error", error)
        })
    })
    .catch((error) => {
      console.log("ApiCalendar createEvent", error)
    })
}
export const resetCalendar = () => ({
  type: actionTypes.RESET_CALENDAR_STATE,
})

export const setDisplayCalendar = (payload) => ({
  type: actionTypes.SET_DISPLAYED_CALENDAR,
  payload,
})
