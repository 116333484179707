import React, { useEffect, useState } from "react"
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
} from "react-native"
import PropTypes from "prop-types"
import "./index.scss"
import { getNameOrUsername } from "@utils/contactUtils"
import { ViewProps, ViewStyles } from "./editUserProfileStyles"
import Avatar from "@ui/avatar"
import Icon from "@ui/icon"
import { Units } from "@theme/"
// import { ScrollView } from 'react-native-gesture-handler'
// import ClickableRow from '@ui/settingsClickableRow'
import { ArrowButton } from "@ui/settingsClickableRow/actionButtons"
import { images } from "@utils/imageUtils"
import strings from "@i18n"
import { updateProfile, updateUserInfo } from "@actions/userActions"
import Modal from "react-modal"

const EditContactRow = ({ value, label, leftIcon, onAdd, onRemove }) => {
  return value ? (
    <View style={ViewStyles.editContactRow}>
      <View style={ViewStyles.contactValueWithIcon}>
        <View style={ViewStyles.iconWrapper}>
          <Image source={leftIcon} style={ViewStyles.contactIcon} />
        </View>
        <Text style={ViewStyles.editContactRowValue}>{value}</Text>
      </View>
      <TouchableOpacity onPress={onRemove} style={ViewStyles.iconWrapper}>
        <Image source={images.trashWhiteIcon} style={ViewStyles.trashIcon} />
      </TouchableOpacity>
    </View>
  ) : (
    <TouchableOpacity
      onPress={onAdd}
      style={ViewStyles.editContactRowWithPlusButton}
    >
      <View style={ViewStyles.iconWrapper}>
        <Image
          source={images.plusWithRoundBorder}
          style={ViewStyles.plusIcon}
        />
      </View>
      <Text style={ViewStyles.editContactRowLabel}>{label}</Text>
    </TouchableOpacity>
  )
}

const ClickableRow = ({ title, value, onClick, phoneOrEmail, notSet, handleUrlRemove, handleLocationRemove }) => {
  const [changeMode, setChangeMode] = useState(false)
  const [fieldValue, setFieldValue] = useState(value)

  const inputStyles = {
    backgroundColor: "transparent",
    border: !changeMode ? "none" : "1px solid #E3E5E8", //e4e4e4
    borderRadius: "4px",
    padding: "5px",
    width: "300px",
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "1.07",
    color: "#767676",
    // color: '#9d9d9d',
  }

  const cancelBtn = {
    width: "70px",
    height: "32px",
    borderRadius: "16px",
    color: "#f42862",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "greycliff-cf-medium",
  }

  const doneBtn = {
    width: "70px",
    height: "32px",
    borderRadius: "16px",
    backgroundColor: "#f42862",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "greycliff-cf-medium",
  }

  const handleDone = async (fieldValue) => {
    if (title === "Display Name") setFieldValue("@" + fieldValue)
    await onClick(fieldValue)
    setChangeMode(false)
  }

  const handleChangeMode = () => {
    if (title === "Display Name") setFieldValue(value.slice(1))
    setChangeMode(true)
  }

  const handleClear = async () => {
    if (title === "Address") {
      await handleLocationRemove()
      setFieldValue("")
    } else if (title === "Website/URL") {
      await handleUrlRemove()
      setFieldValue("")
    } else {
      await onClick("")
      setFieldValue("")
    }
  }

  return (
    <div className="clickable__row">
      <span className="clickable__title">{title}</span>
      <div className="clickable__wrapper">
        {/* <span className="clickable__value">{value ? value : 'Not Set'}</span> */}
        <input
          style={inputStyles}
          className="clickable__value"
          value={
            fieldValue
              ? fieldValue
              : changeMode
              ? fieldValue
              : notSet
              ? notSet
              : value
          }
          disabled={!changeMode ? true : false}
          onChange={(e) => setFieldValue(e.target.value)}
        />

        {!changeMode ? (
          <span
            className="clickable__action"
            onClick={() => (phoneOrEmail ? onClick() : handleChangeMode())}
          >
            {value ? "Change" : "Set"}
          </span>
        ) : (
          <>
            <span
              style={cancelBtn}
              onClick={() => {
                setChangeMode(false)
                setFieldValue(value)
              }}
            >
              Cancel
            </span>
            <span style={doneBtn} onClick={() => handleDone(fieldValue)}>
              Done
            </span>
          </>
        )}

        {!changeMode &&
        !["Name", "Display Name", "Phone Number", "Email"].includes(title) ? (
          (!changeMode && value) || value === "Not Set" ? (
            <span className="clickable__action" onClick={() => handleClear()}>
              {" "}
              Clear{" "}
            </span>
          ) : null
        ) : null}
      </div>
    </div>
  )
}

EditContactRow.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  // leftIcon: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
}

const modalStyles = {
  content: {
    width: 320,
    height: 300,
    top: "50%",
    right: "20%",
    left: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "transparent",
  },
  overlay: {
    backgroundColor: "transparent",
  },
}

const EditUserProfileView = ({
  userInfo = {},
  handleBack,
  backgroundImage,
  handleFullNameChange,
  handleNicknameChange,
  handleBioChange,
  handleHometownChange,
  handleLocationChange,
  handleUrlChange,
  handlePhoneChange,
  handleEmailChange,
  handleAvatarChange,
  handleBackgroundChange,
  handleUrlRemove,
  handleLocationRemove,
  handleRemovePhone,
  handleRemoveEmail,
  // location,
  // url,
  // email,
  // phone,
  // publicPhone,
  // publicEmail,
  isModalOpened,
  modalContent,
  closeModal,
  isContactMethodOpened,
  content,
  handleLocationClear,

  contactMechanisms,
  createdlocation,
  selectedPublicEmail,
  selectedPublicPhone,
}) => {
  const [loading, setLoading] = useState(true)

  const { username, name, about, primaryLocation, primarylocation, nickname } =
    userInfo
  const { email, location, phone, url } = contactMechanisms

  const publicPhone = phone.filter((item) => item.isPublic)[0]?.value
  const publicEmail = email.filter((item) => item.isPublic)[0]?.value

  useEffect(() => {
    userInfo?.name || userInfo?.nickname ? setLoading(false) : setLoading(true)
  }, [userInfo])

  return (
    <View style={ViewStyles.container}>
      {isContactMethodOpened ? (
        <div
          className="settings__modal settings__modal-content"
          style={{ animationName: "none", zIndex: 1001, width: "100%" }}
          onClick={(event) => event.stopPropagation()}
        >
          {content}
        </div>
      ) : (
        <>
          {!loading && (
            <>
              {/* <Modal
                        isOpen={isModalOpened}
                        onRequestClose={closeModal}
                        style={modalStyles}
                        ariaHideApp={false}
                    >
                        {modalContent}
                    </Modal> */}
              <ImageBackground
                source={backgroundImage ? { uri: backgroundImage } : null}
                style={ViewStyles.headerImage}
              ></ImageBackground>
              <TouchableOpacity style={ViewStyles.backgroundEditIcon}>
                <Image
                  source={images.editProfileImage}
                  style={{ height: 32 }}
                  alt="edit profile background icon"
                />
                <input
                  className="upload-input"
                  style={{ width: 32, right: 0 }}
                  type="file"
                  onChange={handleBackgroundChange}
                />
              </TouchableOpacity>
              {/* <View style={ViewStyles.header}>
                        <View style={ViewStyles.headerActions}>
                            <TouchableOpacity
                                onPress={handleBack}
                                hitSlop={Units.hitSlop()}
                                style={ViewStyles.headerButton}
                            >
                                <Image
                                    source={images.back}
                                    style={[ViewStyles.headerButtonIcon, {height: 24, width: 24}]}
                                
                                />
                            </TouchableOpacity>
                            <Text style={ViewStyles.headerText}>
                                {strings.editProfile.editProfile}
                            </Text>
                            <View style={ViewStyles.headerButton} />
                        </View>
                    </View> */}
              <View style={ViewStyles.contentContainer}>
                <View style={ViewStyles.avatarStatusWrapper}>
                  <TouchableOpacity
                    hitSlop={Units.hitSlop()}
                    onPress={() => {}}
                    activeOpacity={1}
                    disabled={true}
                  >
                    <Avatar
                      imageUrl={userInfo?.avatar || userInfo.user?.avatar}
                      size={ViewProps.avatarSize}
                      accesory={
                        <TouchableOpacity style={ViewStyles.avatarEditIcon}>
                          <img
                            src={images.editProfileImage}
                            style={{ height: 32 }}
                            alt="edit profile avatar icon"
                          />
                          <input
                            className="upload-input"
                            style={{ width: 32, right: 0 }}
                            type="file"
                            onChange={handleAvatarChange}
                          />
                        </TouchableOpacity>
                      }
                    />
                  </TouchableOpacity>
                </View>
                <View style={ViewStyles.contentWrapper}>
                  <View style={ViewStyles.userContainer}>
                    <View style={ViewStyles.userContainerInformation}>
                      <Text style={ViewStyles.nameText}>
                        {getNameOrUsername(userInfo)}
                      </Text>
                      {(!!username || !!nickname) && (
                        <Text
                          style={
                            (ViewStyles.additionalText, { color: "#767676" })
                          }
                        >
                          @{username || nickname}
                        </Text>
                      )}
                    </View>
                  </View>
                  <View>
                    <View>
                      <View style={ViewStyles.editBlockHeader}>
                        <Text style={ViewStyles.editBlockHeaderText}>
                          {strings.editProfile.publicProfile}
                        </Text>
                      </View>
                      <View style={ViewStyles.editBlockWrapper}>
                        <ClickableRow
                          title={strings.editProfile.name}
                          value={name}
                          onClick={handleFullNameChange}
                        />
                        <ClickableRow
                          title={strings.editProfile.displayName}
                          value={
                            username
                              ? `@${username}`
                              : nickname
                              ? `@${nickname}`
                              : "Not Set"
                          }
                          onClick={handleNicknameChange}
                        />
                        <ClickableRow
                          title={strings.editProfile.bio}
                          notSet={!userInfo?.about && "Not Set"}
                          value={userInfo?.about}
                          // value={userInfo?.about ? userInfo?.about : 'Not Set'}
                          onClick={handleBioChange}
                        />
                        <ClickableRow
                          title={strings.editProfile.hometown}
                          notSet={
                            !(Object.keys(userInfo).includes("primarylocation")
                              ? primarylocation
                              : primaryLocation) && "Not Set"
                          }
                          value={
                            Object.keys(userInfo).includes("primarylocation")
                              ? primarylocation
                              : primaryLocation
                          }
                          onClick={handleHometownChange}
                        />
                      </View>
                    </View>
                    <View>
                      <View>
                        <Text style={ViewStyles.editBlockHeaderText}>
                          Public Information
                        </Text>
                      </View>
                      <View style={ViewStyles.additionalInformationWrapper}>
                        <Text style={ViewStyles.additionalInformation}>
                          {strings.editProfile.emailPhoneInfo}
                        </Text>
                      </View>
                      <View
                        style={[
                          ViewStyles.editBlockWrapper,
                          { marginBottom: 0 },
                        ]}
                      >
                        <ClickableRow
                          title={"Phone Number"}
                          notSet={!publicPhone && "Not Set"}
                          value={selectedPublicPhone || publicPhone}
                          onClick={handlePhoneChange}
                          phoneOrEmail
                        />
                        <ClickableRow
                          title={"Email"}
                          notSet={!publicEmail && "Not Set"}
                          value={selectedPublicEmail || publicEmail}
                          onClick={handleEmailChange}
                          phoneOrEmail
                        />
                        <ClickableRow
                          title={"Address"}
                          notSet={!location[location.length - 1]?.value && "Not Set"}
                          value={location[location.length - 1]?.value}
                          onClick={handleLocationChange}
                          handleLocationRemove={() => handleLocationRemove(location[location.length - 1]?.id)}
                        /> 
                        <ClickableRow
                          title={"Website/URL"}
                          notSet={!url[url.length - 1]?.value && "Not Set"}
                          value={url[url.length - 1]?.value}
                          onClick={handleUrlChange}
                          handleUrlRemove={() => handleUrlRemove(url[url.length - 1]?.id)}
                        />
                      </View>
                      <View
                        style={ViewStyles.additionalInformationWrapper}
                      >
                        <Text style={ViewStyles.additionalInformation}>
                          {strings.editProfile.publicInfo}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          )}
        </>
      )}
    </View>
  )
}

export default EditUserProfileView

EditUserProfileView.propTypes = {
  userInfo: PropTypes.object,
  handleBack: PropTypes.func,
  backgroundImage: PropTypes.string,
  handleFullNameChange: PropTypes.func,
  handleNicknameChange: PropTypes.func,
  handleBioChange: PropTypes.func,
  handleHometownChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  handleUrlChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  handleEmailChange: PropTypes.func,
  handleAvatarChange: PropTypes.func,
  handleBackgroundChange: PropTypes.func,
  handleUrlRemove: PropTypes.func,
  handleLocationRemove: PropTypes.func,
  handleRemovePhone: PropTypes.func,
  handleRemoveEmail: PropTypes.func,
  location: PropTypes.object,
  url: PropTypes.object,
  email: PropTypes.object,
  phone: PropTypes.object,
}
