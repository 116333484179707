import { useDispatch, useSelector } from "react-redux"

import { selectActiveMeetingApp, selectActiveButtonId } from "@selectors/suggest"
import actionTypes from "@actions/actionTypes"

const useOnlineTabState = () => {
  const dispatch = useDispatch()
  const activeApp = useSelector(selectActiveMeetingApp)
  const activeButtonId = useSelector(selectActiveButtonId)

  const setActiveApp = (payload) => {
    dispatch({ type: actionTypes.SET_SUGGEST_ONLINE_ACTIVE_MEETING_APP, payload })
  }

  const setActiveButtonId = (payload) => {
    dispatch({ type: actionTypes.SET_SUGGEST_ONLINE_ACTIVE_BUTTON_ID, payload })
  }

  const addSuggestAppName = (payload) => {
    dispatch({ type: actionTypes.SET_SUGGEST_ONLINE_APP_NAME, payload})
  }

  const addSuggestDetails = (payload) => {
    dispatch({ type: actionTypes.SET_SUGGEST_ONLINE_DETAILS, payload })
  }

  return {
    activeApp,
    activeButtonId,
    setActiveApp,
    setActiveButtonId,
    addSuggestAppName,
    addSuggestDetails
  }
}

export default useOnlineTabState
