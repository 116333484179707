import React from "react"
import { View, Image } from "react-native"

import { images } from "@utils/imageUtils"
import { ReplyButtonStyles } from "./messageSwipeoutButtonsStyles"

const ReplyButton = () => (
  <View style={ReplyButtonStyles.container}>
    <Image source={images.replyCircle} />
  </View>
)

export default ReplyButton
