import React, { createContext, useContext, useReducer, useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { PROFILE_PAGE_SET_USER_INFO, PROFILE_PAGE_SET_IS_TAG_USER } from "./ProfilePageActions"
import { ProfilePageReducer } from "./ProfilePageReducer"

import { fetchProfileWithTags } from "@actions/userActions"
import { selectUserId } from "@selectors/user"

const ProfilePageStateContext = createContext({})
const ProfilePageDispatchContext = createContext(undefined)

const ProfilePageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProfilePageReducer, {
    userInfo: null,
    isTagUser: true,
    isThreeDotsOpen: false,
  })

  const { id } = useParams()
  const currentUserId = useSelector(selectUserId)

  useEffect(() => {
    id &&
      (async () => {
        const curUserInfo = await fetchProfileWithTags(id, currentUserId)

        dispatch({ type: PROFILE_PAGE_SET_USER_INFO, payload: curUserInfo })
        dispatch({ type: PROFILE_PAGE_SET_IS_TAG_USER, payload: false })
      })()
  }, [id])

  return (
    <ProfilePageStateContext.Provider value={state}>
      <ProfilePageDispatchContext.Provider value={dispatch}>
        {children}
      </ProfilePageDispatchContext.Provider>
    </ProfilePageStateContext.Provider>
  )
}

const useProfilePageState = () => {
  const context = useContext(ProfilePageStateContext)

  if (context === undefined) {
    throw new Error("useProfilePageState must be used within a ProfilePageProvider")
  }

  return context
}

const useProfilePageDispatch = () => {
  const context = useContext(ProfilePageDispatchContext)

  if (context === undefined) {
    throw new Error("ProfilePageDispatchContext must be used within a ProfilePageProvider")
  }

  return context
}

export {
  ProfilePageProvider,
  useProfilePageState,
  useProfilePageDispatch
}

