import React from "react"
import { images } from "@utils/imageUtils"
import "./invitationsSent.scss"

const InvitationSentModal = ({ closeSentInvitationsModal }) => {
  return (
    <div className="invitationsSent__wrapper">
      <div className="invitationsSent__container">
        <p className="invitationsSent--message">Invitations Sent</p>
        <img
          className="invitationsSent--image"
          src={images.InvitationsSentSVG}
        />
        <button
          className="invitationsSent--button"
          onClick={() => closeSentInvitationsModal()}
        >
          OK
        </button>
      </div>
    </div>
  )
}

export default InvitationSentModal
