import React from "react"
import { TextInput, Text, View, TouchableOpacity, Image } from "react-native"
import PropTypes from "prop-types"
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import PhoneInputField from "@ui/phoneInputField"
import { formatNumber } from "libphonenumber-js"
import PhoneInput from "../../AddInvitees/AddContacts/phoneInput"

import { Units } from "@theme"
import { images } from "@utils/imageUtils"

import { styles } from "./EditContactScreenStyles"

const renderAddRow = (type, onButtonPress) => {
  return (
    <div className="settings__rowWrapper">
      <TouchableOpacity
        style={styles.rowContainer}
        onPress={() => onButtonPress()}
      >
        <Image style={styles.image} source={images.plusCircle} />
        <Text style={styles.contentText}>
          {type === "phone" ? "Add a phone number" : "Add an email address"}
        </Text>
      </TouchableOpacity>
    </div>
  )
}

const renderInputRow = (
  type,
  value,
  onRemove,
  onChangeText,
  isVerified,
  onChooseCountry,
  phoneRef,
  primaryEmail,
  unverifiedPressed,
  id,
  defaultCountry
) => {
  const showRemoveButton = !(value === primaryEmail)
  return (
    <View style={[styles.rowContainer, { zIndex: 1 }]} key={id}>
      {showRemoveButton ? (
        <TouchableOpacity hitSlop={Units.hitSlop()} onPress={onRemove}>
          <Image style={styles.image} source={images.removeItem} />
        </TouchableOpacity>
      ) : (
        <View style={styles.image} />
      )}
      {type === "phone" ? (
        <PhoneInput
          value={value}
          index={id}
          handleTextChange={onChangeText}
          // isLastRow={isLastRow}
          defaultCountry={defaultCountry}
          disabled={!onChangeText}
          inputStyle={{ width: "250px" }}
          // getInitPhoneNumber={getInitPhoneNumber}
          // countryCode={countryCode}
        />
      ) : (
        <TextInput
          style={styles.contentText}
          onChangeText={(text) => onChangeText()(text)}
          placeholder="email@address.com"
          value={value}
          editable={!!onChangeText}
        />
      )}
      {isVerified === true && (
        <View style={styles.verifiedStyle}>
          <Text style={styles.verifiedTextStyle}>Verified</Text>
          <Image
            style={styles.verifiedIconStyle}
            source={images.checkWhiteIcon}
            resizeMode="contain"
          />
        </View>
      )}
      {isVerified === false && (
        <TouchableOpacity
          style={styles.unverifiedStyle}
          onPress={() => unverifiedPressed(value, id)}
        >
          <Text style={styles.verifiedTextStyle}>Unverified</Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

const EditContactScreenView = ({
  contactType,
  content,
  primaryEmail,
  deleteContact,
  addContact,
  activateInput,
  deleteInput,
  inputValue,
  onChangeInputValue,
  onChooseCountry,
  phoneRef,
  unverifiedPressed,
  defaultCountry,
  country,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          {content.map((contentItem) =>
            renderInputRow(
              contactType,
              contentItem.value,
              () => deleteContact(contentItem),
              null,
              contentItem.isVerified,
              null,
              null,
              primaryEmail,
              unverifiedPressed,
              contentItem.id
            )
          )}
          {activateInput &&
            renderInputRow(
              contactType,
              inputValue,
              deleteInput,
              onChangeInputValue,
              null,
              onChooseCountry,
              phoneRef,
              () => {},
              null,
              null,
              country ? country : defaultCountry
            )}
          {renderAddRow(contactType, addContact)}
        </View>
      </View>
    </View>
  )
}

export default EditContactScreenView

EditContactScreenView.propTypes = {
  contactType: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  // primaryEmail: PropTypes.string.isRequired,
  deleteContact: PropTypes.func.isRequired,
  addContact: PropTypes.func.isRequired,
  activateInput: PropTypes.bool.isRequired,
  deleteInput: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChangeInputValue: PropTypes.func.isRequired,
  onChooseCountry: PropTypes.func.isRequired,
  phoneRef: PropTypes.func.isRequired,
  unverifiedPressed: PropTypes.func.isRequired,
  // defaultCountry: PropTypes.string.isRequired,
}
