import React, { useEffect } from "react"

import {
  SuggestModalFooter,
  SuggestModalContent,
  SuggestModalSubHeader,
} from "../../components"

import Pinboard from "./Pinboard"
import MeetingApp from "./MeetingApp"
import MeetingDetails from "./MeetingDetails"
import FooterButtons from "./FooterButtons"

import useOnlineTabState from "../Hooks/useOnlineTabState"

import './index.scss'

const OnlineTab = () => {
  const { activeApp, setActiveApp } = useOnlineTabState()

  useEffect(() => {
    return () => {
      setActiveApp(null)
    }
  }, [])

  return (
    <>
      <SuggestModalSubHeader>
        <Pinboard suggestInfo={activeApp} />
      </SuggestModalSubHeader>
      <SuggestModalContent>
        <MeetingApp />
        {activeApp && <MeetingDetails /> }
      </SuggestModalContent>
      <SuggestModalFooter>
        <FooterButtons suggestInfo={activeApp} />
      </SuggestModalFooter>
    </>
  )
}

export default OnlineTab
