import React, { useEffect, useState } from "react"
import { Image, Text, ScrollView } from "react-native"
import ReactDOM from "react-dom"
import ClickableRow from "@ui/settingsClickableRow"
import {
  ToggleButton,
  ArrowButton,
} from "@ui/settingsClickableRow/actionButtons"
import PropTypes from "prop-types"
import strings from "@i18n"
import ApiCalendar from "react-google-calendar-api"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { fetchCalendarEvents } from "@actions/calendarActions"

import { resetCalendar, setDisplayCalendar } from "@actions/calendarActions"
import { selectHelpPopup } from "@selectors/common"
import { selectDisplayCalendar } from "@selectors/calendar"
import { setHelpPopup } from "@actions/commonActions"
import { images } from "@utils/imageUtils"

import { styles } from "./calendarSettingsStyles"
import "./index.scss"

const ClickableRowCalendar = ({ primaryText, isDisplayCalendar, setDisplayCalendar }) => {
  const [isCalendarConnected, setIsCalendarConnected] = useState(false)
  const [isCalendarDisplayed, setIsCalendarDisplayed] = useState(true)

  return (
    <div className="clickable__row">
      <div className="clickable__wrapper">
        <span className="clickable__value_accountDetails_value">
          {primaryText}
        </span>
        <div className="clickable__action_wrapper">
          <div onClick={() => {
            setIsCalendarConnected(!isCalendarConnected)
          }}>
            <Image
              source={images.syncCalendar}
              style={{
                  marginRight: 20,
                  height: 15,
                  width: 15,
                  cursor: "pointer",
                  tintColor: isCalendarConnected && "#f42862"
                }
              }
            />
          </div>
          <div onClick={() => {
            setDisplayCalendar(!isDisplayCalendar)
            if (isDisplayCalendar) {
              fetchCalendarEvents()
            }
          }}>
            <Image
              source={isDisplayCalendar ? images.disabledCalendar : images.activatedCalendar} 
              style={[styles.calendarIcons]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const CalendarSettingsView = ({
  onChange,
  calendarTitle,
  additionalCalendarsAmount,
  syncTeeups,
  syncOnlyAllSet,
  customTimezone,
  tzAutodetected,
  onCalendarSelect,
  onAdditionalCalendarsSelect,
  onCustomTimezone,
  calendars,
  email,
  resetCalendar,
  popup,
  setPopup,
  isDisplayCalendar,
  setDisplayCalendar
}) => {
  const [buttonText, setButtonText] = useState("")
  const [step, setStep] = useState(1)

  useEffect(() => {
    ApiCalendar.sign
      ? setButtonText("Remove Calendar")
      : setButtonText("Add Calendar")
    sessionStorage["PopupCalendarsShown"] != "yes" && setPopup(true)
  }, [])

  useEffect(() => {
    email
      ? setButtonText("Remove Calendar")
      : setButtonText("Add Calendar")
  }, [email])

  const handleAddRemoveCalendar = (event) => {
    event.preventDefault()
    if (ApiCalendar.sign) {
      ApiCalendar.handleSignoutClick()
      resetCalendar()
    } else {
      ApiCalendar.handleAuthClick()
    }
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.ask}>
        You can select one calendar to
        <Image source={images.syncCalendar} style={styles.descriptionIcon} />
        sync TeeUps and several calendars to be
        <Image
          source={images.activatedCalendar}
          style={styles.descriptionIcon}
        />
        displayed to help determine scheduling conflicts.
      </Text>
      <Text style={[styles.ask, { marginBottom: 20, marginTop: 10 }]}>
        Only You Can See Your Calendar
      </Text>
      {/* <Text style={styles.header}>
                {strings.tzAndCalendar.settingsCalendarSubtitle}
            </Text> */}
      <div className="settings__rowWrapper">
        <ClickableRow
          primaryText={["Only sync All Set TeeUps to main calendar"]}
          actionButton={
            <ToggleButton
              onToggle={(event) => onChange(event, "syncOnlyAllSet")}
              value={syncOnlyAllSet}
            />
          }
          onPress={() => onChange(!syncOnlyAllSet, "syncOnlyAllSet")}
          fromCalendarSettings={true}
        />
        {/* <ClickableRow
                    primaryText={[strings.tzAndCalendar.syncToCalendar.title]}
                    detailsText={[strings.tzAndCalendar.syncToCalendar.details]}
                    actionButton={
                        <ToggleButton
                            onToggle={event => onChange(event, 'syncTeeups')}
                            value={syncTeeups}
                        />
                    }
                    onPress={() => onChange(!syncTeeups, 'syncTeeups')}
                /> */}
        {/* <SyncCalendarButton /> */}

        {/* {syncTeeups && (
                    <ClickableRow
                        primaryText={[
                            strings.tzAndCalendar.primaryCalendar.title,
                        ]}
                        secondaryText={[calendarTitle]}
                        detailsText={[
                            strings.tzAndCalendar.primaryCalendar.details,
                        ]}
                        actionButton={<ArrowButton />}
                        onPress={onCalendarSelect}
                        />
                    )} */}
        {/* { syncTeeups && <SyncCalendarButton /> } */}

        {/* <ClickableRow
                    primaryText={[
                        strings.tzAndCalendar.additionalCalendars.title,
                    ]}
                    secondaryText={
                        additionalCalendarsAmount > 0
                            ? [`${additionalCalendarsAmount} selected`]
                            : []
                    }
                    detailsText={[
                        strings.tzAndCalendar.additionalCalendars.details,
                    ]}
                    actionButton={<ArrowButton />}
                    onPress={onAdditionalCalendarsSelect}
                /> */}
      </div>

      {email && (
        <div className="settings__calendars_email">
          <div className="settings__calendars_email_title">{email}</div>
          <div className="settings__rowWrapper">
            <ClickableRowCalendar
              primaryText={email}
              isDisplayCalendar={isDisplayCalendar}
              setDisplayCalendar={setDisplayCalendar}
            />
          </div>
        </div>
      )}

      <div className="settings-connection-details" style={{ marginTop: 16 }}>
        <button
          className="settings-connection-details-button"
          onClick={handleAddRemoveCalendar}
        >
          {buttonText}
        </button>
      </div>

      {/* <Text style={styles.header}>
                {strings.tzAndCalendar.settingsTZSubtitle}
            </Text>

            <ClickableRow
                primaryText={[strings.tzAndCalendar.useWhenCreating.title]}
                actionButton={<ToggleButton
                                disabled
                                value={false}
                                onToggle={() => {}}
                                />}
            />
            <ClickableRow
                primaryText={[strings.tzAndCalendar.useWhenJoining.title]}
                actionButton={<ToggleButton
                                disabled
                                value={false}
                                onToggle={() => {}}
                                />}
            /> */}
      {popup &&
        ReactDOM.createPortal(
          <>
            <div className="settings__connectionDetails_popup_wrapper">
              <div
                className="settings__connectionDetails_popup"
                style={{
                  width: 402,
                  paddingRight: 20,
                  paddingTop: 25,
                  paddingBottom: 25,
                  paddingLeft: 25,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="settings__calendars_popup_header">
                  <span className="settings__connectionDetails_popup_heading">
                    Configure Your Calendars
                  </span>
                  <span className="settings__calendars_popup_pageNumber">
                    {step === 1 ? "1/2" : "2/2"}
                  </span>
                </div>
                <span
                  className="settings__calendars_popup_subheading"
                  style={{ alignSelf: "flex-start" }}
                >
                  Only You Can See Your Calendar
                </span>
                <img
                  src={
                    step === 1
                      ? images.calendarPopupOne
                      : images.calendarPopupTwo
                  }
                  className="settings__calendars_popup_image"
                  alt=""
                />
                {step === 1 ? (
                  <>
                    <span className="settings__calendars_popup_text">
                      Tapping on
                      <Image
                        source={images.syncCalendar}
                        style={[
                          styles.descriptionIcon,
                          { tintColor: "#979797", display: "inline-block" },
                        ]}
                      />
                      icon will allow TeeUps to be synced with your calendar.
                      Turning off, the TeeUps will not update in your calendar.
                    </span>
                    <br />
                    <span className="settings__calendars_popup_text">
                      Make sure your calendar permissions allow us to sync your
                      TeeUps with your calendar.
                    </span>
                  </>
                ) : (
                  <>
                    <span className="settings__calendars_popup_text">
                      By selecting multiple calendars, you can easily determine
                      scheduling conflicts.
                    </span>
                    <br />
                    <span className="settings__calendars_popup_text">
                      Tap on
                      <Image
                        source={images.disabledCalendar}
                        style={[
                          styles.descriptionIcon,
                          {
                            tintColor: "#979797",
                            display: "inline-block",
                            height: 13,
                            width: 13,
                          },
                        ]}
                      />
                      icon to display the calendar in the app, and tap on
                      <Image
                        source={images.activatedCalendar}
                        style={[
                          styles.descriptionIcon,
                          { display: "inline-block", height: 14, width: 12 },
                        ]}
                      />
                      to hide it
                    </span>
                  </>
                )}

                {step === 1 ? (
                  <button
                    className="settings__connectionDetails_popup_button"
                    onClick={(e) => {
                      e.stopPropagation()
                      setStep(2)
                    }}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="settings__connectionDetails_popup_button"
                    onClick={(e) => {
                      e.stopPropagation()
                      setPopup(false)
                      sessionStorage["PopupCalendarsShown"] = "yes"
                      setStep(1)
                    }}
                  >
                    OK
                  </button>
                )}
              </div>
            </div>
          </>,
          document.getElementById("modal-root")
        )}
    </ScrollView>
  )
}

const mapStateToProps = (state) => {
  const popup = selectHelpPopup(state)
  const isDisplayCalendar = selectDisplayCalendar(state)

  return {
    popup,
    isDisplayCalendar,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetCalendar: bindActionCreators(resetCalendar, dispatch),
  setPopup: bindActionCreators(setHelpPopup, dispatch),
  setDisplayCalendar: bindActionCreators(setDisplayCalendar, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarSettingsView)

CalendarSettingsView.propTypes = {
  onChange: PropTypes.func.isRequired,
  // syncTeeups: PropTypes.bool.isRequired,
  // syncOnlyAllSet: PropTypes.bool.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  additionalCalendarsAmount: PropTypes.number,
  customTimezone: PropTypes.string,
  onCustomTimezone: PropTypes.func.isRequired,
  onCalendarSelect: PropTypes.func.isRequired,
  onAdditionalCalendarsSelect: PropTypes.func.isRequired,
  tzAutodetected: PropTypes.bool,
  isDisplayCalendar: PropTypes.bool,
}
