import React, {
  useState,
  useEffect,
  useLayoutEffect,
  memo,
  useMemo,
} from "react"
import { useSelector, connect } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { bindActionCreators } from "redux"
import { useAuth0 } from "@auth0/auth0-react"
import ApiCalendar from "react-google-calendar-api"
import { useOneSignalSetup } from "react-onesignal"
import { isMobile } from "react-device-detect"

import { getCreateTeeupState } from "./selectors/createTeeUp"

import "./index.scss"
import useRoutes from "./routes"
import SideBar from "./components/SideBar"
import CreateTeeUp from "./components/CreateTeeUp"
import { history } from "./configureStore"
import { selectToken, selectIsUserLoading } from "./selectors/user"
import api from "api"
import { verifyUserAndSave, registerWithSocial } from "@actions/loginActions"
import { setUserIsLoading } from "@actions/userActions"
import LoadingPage from "pages/LoadingPage"
import { selectShowSideBar, selectTitle } from "./selectors/common"
import { fetchCalendarEvents } from "@actions/calendarActions"
import { Helmet } from "react-helmet"
import notificationService from "./notificationsService"
import {
  selectCalendarSyncSettings,
  selectUserInfo,
  selectLoginErrorMessage,
} from "@selectors/user"
import { setTeeupFilter, getCurrentWindowParams } from "@actions/commonActions"
import { loadNotifications } from "@actions/notificationActions"
import { selectUnseenTeeups } from "@selectors/teeups"
import LocationDetector from "./components/LocationDetector"

import NetworkDetector from "./NetworkDetector"
import { removeItem } from "@utils/localStorage"
import { useTopLevelContext } from "contexts/TopLevelContext"

function App({
  isOpen,
  verifyUserAndSave,
  showSiderBar,
  isUserLoading,
  setUserIsLoading,
  title,
  syncTeeups,
  userInfo,
  setTeeupFilter,
  notifications,
  getCurrentWindowParams,
}) {
  const {
    isLoading,
    isAuthenticated,
    user,
    getIdTokenClaims,
    logout,
    loginWithRedirect,
  } = useAuth0()
  const [signedForGoogleCalendar, setSignedForGoogleCalendar] = useState(
    ApiCalendar.sign
  )

  const {
    setIsRegistrationInProgress,
    isRegistrationInProgress,
    socialRegistrationType,
  } = useTopLevelContext()

  const [isSingInError, setIsSingInError] = useState(false)
  const [isLocationDetectorOpen, setIsLocationDetectorOpen] = useState(false)

  const routes = useRoutes()
  const isAuthenticatedUser = useSelector(selectToken)
  const loginErrorMessage = useSelector(selectLoginErrorMessage)

  if (isOpen) {
    document.body.style.overflow = "hidden"
  }

  const loading = useMemo(() => {
    if (!isLoading && !isUserLoading && !isRegistrationInProgress) {
      return false
    }

    return true
  }, [isLoading, isUserLoading, isRegistrationInProgress])

  // registration
  useEffect(() => {
    if (user && isRegistrationInProgress && socialRegistrationType) {
      setIsRegistrationInProgress(false)
      setUserIsLoading(true)

      registerWithSocial(user)
        .then((value) => {
          loginWithRedirect({
            connection: socialRegistrationType,
          })
        })
        .catch((e) => {
          removeItem("userInfo")
          history.push("/")
          setIsSingInError(true)
        })
    }
  }, [
    user,
    setIsRegistrationInProgress,
    isRegistrationInProgress,
    setUserIsLoading,
    loginWithRedirect,
    socialRegistrationType,
  ])

  useEffect(() => {
    api.init()
    notificationService.init()
    loadNotifications()
  }, [user])

  useOneSignalSetup(() => {
    if (isAuthenticatedUser) {
      notificationService.askForNotificationsPermsions()
    }
  })

  useEffect(() => {
    if (isAuthenticated) {
      setUserIsLoading(true)
      getIdToken()
    }
  }, [user, isLoading])

  useEffect(() => {
    let timePicker
    window.onblur = function () {
      timePicker = setTimeout(() => {
        window.location.reload() // reload page when user not active for 30min
      }, 1800000)
    }
    window.onfocus = function () {
      clearInterval(timePicker)
    }
  }, [window.onblur, window.onfocus])

  useEffect(() => {
    if (signedForGoogleCalendar) {
      fetchCalendarEvents()
    }
  }, [signedForGoogleCalendar, syncTeeups])

  ApiCalendar.onLoadCallback = () => {
    setSignedForGoogleCalendar(ApiCalendar.sign)

    ApiCalendar.listenSign((sign) => {
      setSignedForGoogleCalendar(sign)
    })
  }

  const getIdToken = async () => {
    try {
      const idToken = await getIdTokenClaims()
      verifyUserAndSave(user, idToken.__raw, logout)
    } catch (e) {
      console.log(e.message)
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", () =>
      getCurrentWindowParams({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    )
  }, [])

  if (isMobile) {
    const { pathname } = history.location
    const isResetPasswordPage = pathname.includes("/forgot-password")

    if (!isResetPasswordPage) {
      window.location = "https://www.coo-e.com/"

      return null
    }
  }

  if (loading) {
    if (!isSingInError && !loginErrorMessage) {
      return <LoadingPage setIsSingInError={setIsSingInError} />
    } else {
      logout()
    }
  }

  return (
    <ConnectedRouter history={history}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="app">
        {isAuthenticatedUser && showSiderBar && (
          <SideBar
            notifications={notifications}
            setTeeupFilter={setTeeupFilter}
            userInfo={userInfo}
          />
        )}
        {/* <LocationDetector close={() => setIsLocationDetectorOpen(false)} /> */} 
        {routes}
        {isOpen && <CreateTeeUp />}
      </div>
    </ConnectedRouter>
  )
}

const mapStateToProps = (state) => ({
  userInfo: selectUserInfo(state),
  isOpen: getCreateTeeupState(state),
  syncTeeups: selectCalendarSyncSettings(state).syncTeeups,
  showSiderBar: selectShowSideBar(state),
  isUserLoading: selectIsUserLoading(state),
  title: selectTitle(state),
  notifications: selectUnseenTeeups(state),
})

const mapDispatchToProps = (dispatch) => ({
  verifyUserAndSave: bindActionCreators(verifyUserAndSave, dispatch),
  setUserIsLoading: bindActionCreators(setUserIsLoading, dispatch),
  setTeeupFilter: bindActionCreators(setTeeupFilter, dispatch),
  getCurrentWindowParams: bindActionCreators(getCurrentWindowParams, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkDetector(memo(App)))
