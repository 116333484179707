import React, { memo } from "react"
import classnames from "classnames"

const SelectTimeOptionButton = ({
  name,
  timeRange,
  selected,
  handleTimeSelect,
}) => {
  return (
    <div
      className={classnames("select-time__option-button", {
        selected: selected,
      })}
      onClick={handleTimeSelect}
    >
      <span>{name}</span>
      <span>{timeRange}</span>
    </div>
  )
}

export default memo(SelectTimeOptionButton)
