import React from "react"
import { images } from "@utils/imageUtils"
import { logOut } from "@actions/loginActions"
import { useAuth0 } from "@auth0/auth0-react"
import { connect } from "react-redux"

import "./index.scss"

const ChangePasswordSuccess = ({ logOut, email }) => {
  const { logout } = useAuth0()
  const successImage = {
    padding: "32px 0 36px 0",
  }

  const handleContinueToSignIn = () => {
    // logout({ returnTo: window.location.origin });
    logOut()
  }

  return (
    <>
      <img className="change__password--logo" src={images.cooeModal} />
      <img style={successImage} src={images.passwordChangeSuccess} />
      <p className="change__password--title">
        {email ? "Email Changed" : "Password Changed"}
      </p>
      <p
        className="change__password--text"
        style={{ width: "290px", height: "58px", paddingBottom: "24px" }}
      >
        Remember, nobody, including Coo•e support staff, will ever ask you for
        your password, so do not share it.
      </p>
      <button
        style={{ backgroundColor: "#f53263" }}
        className="change__password--button"
        onClick={() => handleContinueToSignIn()}
      >
        Continue to Sign In
      </button>
    </>
  )
}

export default connect(null, { logOut })(ChangePasswordSuccess)
