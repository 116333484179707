import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import _ from "lodash"
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
} from "react-native"
import strings from "@i18n"
import { ViewStyles, ViewProps } from "./retrieveTeeupStyles"
import InputField from "@ui/inputField"
import PhoneInputField from "@ui/phoneInputField"
import RoundedButton from "@ui/roundedButton"
import { userLoginTypes } from "@configs/mappings"
import PhoneInput from "../AddInvitees/AddContacts/phoneInput"
import CodeInputNeo from "../../pages/VerifyCode/common/CodeInputNeo/CodeInputNeo"

const Wrapper = (props) => {
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <KeyboardAvoidingView
        style={ViewStyles.flex}
        behavior={Platform.OS === "ios" ? "padding" : undefined}
      >
        {props.children}
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  )
}

Wrapper.propTypes = {
  children: PropTypes.object,
}

const RetrieveTeeupVerifyView = ({
  contact,
  value,
  onValue,
  onContinue,
  onResend,
  onBack,
  loading,
  error,
  type,
  verifedAttempts,
  previousCode,
  setVerifyCodeRef,
  verifyCode,
  contactValue,
  handleModal,
  deleteContactMechanism,
}) => {
  const [counter, setCounter] = useState(60)

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  let onChangeText = _.debounce((value) => {
    // Debounce to reduce calls
    onValue(value)
  }, 0)

  return (
    <>
      <div
        className="settings__connectionDetails_popup_wrapper"
        onClick={(e) => {
          e.stopPropagation()
          handleModal(null)
          deleteContactMechanism()
        }}
        style={{ flexDirection: "column" }}
      >
        <div
          className="settings__connectionDetails_popup"
          onClick={(e) => e.stopPropagation()}
          style={{
            width: 329,
            height: 401,
            paddingVertical: 0,
            paddingHorizontal: 35,
          }}
        >
          <span className="settings__connectionDetails_popup_heading_title">
            Verify {type == "email" ? "Email" : "Phone"}
          </span>
          <p
            className="forgot__subtitle__recovery"
            style={{ marginTop: 8, marginBottom: 16 }}
          >
            Enter the code we sent to to:
            <br />
            <span style={{ color: "#222222" }}>
              {contactValue.slice(0, 1) +
                "x".repeat(contactValue.length - 6) +
                contactValue
                  .replace(/ /g, "")
                  .slice(contactValue.length - 5, contactValue.length)}
            </span>
          </p>
          {/* <View> */}
          {/* <Text style={ViewStyles.primaryText}>
                        {verifedAttempts > 0 && !!error
                            ? 'The verification code is invalid. Please check the code sent to'
                            : `Enter the verification code sent to`}
                    </Text> */}
          {/* {type === userLoginTypes.phone && (
                        <PhoneInput
                            value={contact}
                            customContainerStyle={
                                ViewStyles.disabledInputContainer
                            }
                            customInputTextStyle={ViewStyles.disabledPhoneInput}
                            refFunc={() => {}}
                            customInputStyle={{width: '100%'}}
                            disabled={true}
                            allowFormatOnInit={false}
                        />
                    )}
                    {type === userLoginTypes.email && (
                        <InputField
                            defaultValue={contact}
                            customContainerStyle={
                                ViewStyles.disabledInputContainer
                            }
                            customInputStyle={ViewStyles.disabledInputStyle}
                            customInputWrapperStyle={
                                ViewStyles.disabledInputWrapper
                            }
                            editable={false}
                        />
                    )}   */}
          <div
            className="forgot__input--wrapper"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginBottom: 16,
            }}
          >
            <label
              htmlFor="input"
              className={cn("forgot__label_verifyModal", {
                "forgot__label--error": verifedAttempts > 0 && !!error,
              })}
            >
              {error != ""
                ? "Please Enter Valid Verification Code:"
                : "Verification Code:"}
            </label>
            <div style={{ position: "relative", marginTop: 4, width: "100%" }}>
              <CodeInputNeo
                errorMessage={error}
                customContainerStyle={{ marginTop: 16 }}
                onChange={(val) => onChangeText(val)}
                value={value}
              />
            </div>
          </div>
          <div
            style={{
              width: 327,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <button
              className="verify__resend verify__resend--recovery"
              onClick={() => {
                handleModal(null)
                deleteContactMechanism()
              }}
              style={{ width: 88, height: 48, margin: 0 }}
            >
              Cancel
            </button>
            <button
              disabled={
                value === "" ||
                (value && value === previousCode) ||
                (value && value.length < 6)
              }
              className={cn("forgot__button", {
                "forgot__button--disabled": value.length !== 6,
              })}
              style={{ width: 227 }}
              onClick={onContinue}
            >
              Verify
            </button>
          </div>
          <button
            disabled={counter > 0}
            className={cn("verify__resend", {
              "verify__resend--disabled": counter > 0,
            })}
            onClick={() => {
              onResend()
              setCounter(60)
            }}
          >
            {"Resend code " + (counter > 0 ? `(${counter})` : "")}
          </button>
          <span className="verify__text">
            We recommend checking your SPAM folder if <br /> you don’t receive
            it
          </span>

          {/* <input
                            style={{marginTop: 15, width: '100%'}}
                            className="login__input"
                            type="text"
                            value={value}
                            onChange={({target}) => onChangeText(target.value)}
                            placeholder={'Enter code'}
                            maxLength={6}
                        /> */}
          {/* </View> */}
          {/* <View>
                    <View style={ViewStyles.buttonRow}>
                        <RoundedButton
                            {...ViewProps.outlineButtonStyles}
                            title="Back"
                            onPress={onBack}
                        />
                        <RoundedButton
                            {...ViewProps.buttonStyles}
                            title="Validate Code"
                            disabled={
                                value === '' ||
                                (value && value === previousCode) ||
                                (value && value.length < 6)
                            }
                            onPress={onContinue}
                        />
                    </View>
                    {verifedAttempts >= 2 && (
                        <TouchableOpacity
                            style={ViewStyles.footerButton}
                            onPress={onResend}
                        >
                            <Text style={ViewStyles.footerButtonText}>
                                {`Didn't get it? Resend code`}
                            </Text>
                        </TouchableOpacity>
                    )}
                </View> */}
        </div>
      </div>
      {/* {loading && (
            <View style={ViewStyles.loadingOverlay}>
                <ActivityIndicator size="large" />
            </View>
        )} */}
    </>
  )
}

RetrieveTeeupVerifyView.propTypes = {
  contact: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onValue: PropTypes.func,
  onContinue: PropTypes.func,
  onResend: PropTypes.func,
  onBack: PropTypes.func,
  loading: PropTypes.bool,
  verifedAttempts: PropTypes.number,
  setVerifyCodeRef: PropTypes.func,
  previousCode: PropTypes.string,
}

export default RetrieveTeeupVerifyView
