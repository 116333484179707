import React, { Component } from "react"
import PropTypes from "prop-types"
import { View, Text, TouchableOpacity } from "react-native"

import { getDateWithTimezone } from "@utils/dateUtils"
import DayCalendarTimetableContainer from "./DayCalendarTimetableContainer"
import { DayCalendarStyles as styles } from "./styles"

class DayCalendarView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      allowCreateTeeupFromEvent,
      selectedDate,
      moveCalendarForward,
      moveCalendarBackward,
      newSuggestionProps,
      onPickTimerangeFromCalendar,
      changeDate,
      duration,
      onDurationChange,
      allowClickToPast,
      loadEvents,
      scrollOffset,
      setOffset,
    } = this.props

    return (
      <View style={styles.flex}>
        <DayCalendarTimetableContainer
          newSuggestionProps={newSuggestionProps}
          onPickTimerangeFromCalendar={onPickTimerangeFromCalendar}
          allowCreateTeeupFromEvent={allowCreateTeeupFromEvent}
          selectedDate={selectedDate}
          changeDate={changeDate}
          duration={duration}
          onDurationChange={onDurationChange}
          loadEvents={loadEvents}
          scrollOffset={scrollOffset}
          setOffset={setOffset}
        />
      </View>
    )
  }
}

DayCalendarView.propTypes = {
  allowClickToPast: PropTypes.bool,
  allowCreateTeeupFromEvent: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  moveCalendarForward: PropTypes.func,
  moveCalendarBackward: PropTypes.func,
  changeDate: PropTypes.func,
  duration: PropTypes.number,
  onDurationChange: PropTypes.func,
  newSuggestionProps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      duration: PropTypes.number,
      isFromCreateTeeup: PropTypes.bool,
      passedEvent: PropTypes.object,
      selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onDatesSelected: PropTypes.func.isRequired,
    }).isRequired,
  ]),
  onPickTimerangeFromCalendar: PropTypes.func,
  loadEvents: PropTypes.bool,
  scrollOffset: PropTypes.number,
  setOffset: PropTypes.func,
}

DayCalendarView.defaultProps = {
  allowClickToPast: true,
  allowCreateTeeupFromEvent: true,
}

export default DayCalendarView
