import React from "react"
import { FlatList } from "react-native"
import PropTypes from "prop-types"
import _ from "lodash"

import {
  teeupUserStatusKeys,
  teeupStatusKeys,
  teeupUserStatusKeysWithPeople,
} from "@configs/enums"
import { DialogButtonWithIcon } from "../dialogButtons/dialogButtons"
import { images } from "@utils/imageUtils"

import { UserStatusModalStyles as styles } from "./styles"
import { UserStatusButtonProps } from "./userStatusButtonProps"
import { getItem } from "@utils/localStorage"

const shouldHideUserStatusButton = (currentTeeupStatus, userStatus) => {
  switch (userStatus) {
    case teeupUserStatusKeys.going:
    case teeupUserStatusKeys.mightgo:
    case teeupUserStatusKeys.notgoing:
    case teeupUserStatusKeys.onmyway:
    case teeupUserStatusKeys.arrived:
    case teeupUserStatusKeys.joined:
    case teeupUserStatusKeys.coolIdea:
    case teeupUserStatusKeys.runningLate:
    case teeupUserStatusKeys.backedOut:
      return false
    case teeupUserStatusKeys.attended:
      return currentTeeupStatus !== teeupStatusKeys.ended
    default:
      return true
  }
}

const shouldDisableUserStatusButton = (currentTeeupStatus, userStatus) => {
  if (userStatus === teeupUserStatusKeys.joined) return true

  switch (currentTeeupStatus) {
    case teeupStatusKeys.ended:
      return userStatus !== teeupUserStatusKeys.attended
    case teeupStatusKeys.planning:
    case teeupStatusKeys.happening:
    case teeupStatusKeys.happeningSoon:
    case teeupStatusKeys.allset:
      return false
    default:
      return true
  }
}

export default class StatusSelector extends React.Component {
  static getDerivedStateFromProps(nextProps, state) {
    const { teeupStatus, userStatus, additionalCount } = nextProps
    const updates = {}

    if (teeupStatus !== state.teeupStatus) {
      updates.teeupStatus = teeupStatus
    }
    if (userStatus !== state.userStatus) {
      updates.userStatus = userStatus
    }
    if (additionalCount !== state.additionalCount) {
      updates.additionalCount = additionalCount
    }

    return _.isEmpty(updates) ? null : updates
  }

  constructor(props) {
    super(props)
    this.state = {
      teeupStatus: props.teeupStatus,
      userStatus: props.userStatus,
      additionalCount: props.additionalCount,
    }
    this._userStatuses = UserStatusButtonProps.filter(
      (status) =>
        status.isAvailable &&
        status.title !== "Backed Out" &&
        status.title !== "Attended"
    )
  }

  handleClickStatus = (item) => () => {
    const { onUserStatusSelected, userStatus, handleSetPeopleStatus, people } =
      this.props
    if (userStatus !== item.status) {
      onUserStatusSelected(item.title)
      const userInfo = getItem("userInfo")
      const changedPeople = Object.values(people).map((person) => {
        if (person.id === userInfo.id) {
          person.status = item.status
          return person
        } else {
          return person
        }
      })
      handleSetPeopleStatus(changedPeople)
    } else {
      // Send an empty string as a parameter to dismiss the overlay
      onUserStatusSelected("")
    }
  }

  renderItem = ({ item, index }) => {
    const { teeupStatus, userStatus, onClickBringing } = this.props

    if (shouldHideUserStatusButton(teeupStatus, item.status)) {
      return null
    }

    const selected = userStatus === item.status

    if (!item?.status) {
      item.status = -1
    }

    const showRightButton = teeupUserStatusKeysWithPeople.includes(item.status)

    const shouldDisableButton = shouldDisableUserStatusButton(
      teeupStatus,
      item.status
    )

    const isLast = index === this._userStatuses.length

    return (
      <DialogButtonWithIcon
        title={item.title}
        primaryIcon={item.image}
        secondaryIcon={showRightButton ? images.briningOthers : null}
        borderColor={item.color}
        containerStyles={[
          styles.statusItemMargin,
          selected && { backgroundColor: item.color },
        ]}
        primaryIconStyles={!selected ? { tintColor: item.color } : null}
        disabled={shouldDisableButton}
        isActive={selected}
        onClickPrimary={this.handleClickStatus(item)}
        onClickSecondary={() => onClickBringing(item.status)}
      />
    )
  }

  render() {
    const { isDataLoaded } = this.props
    return (
      <FlatList
        pointerEvents={isDataLoaded ? "auto" : "none"}
        data={this._userStatuses}
        extraData={this.state}
        renderItem={this.renderItem}
        initialNumToRender={this._userStatuses.length}
        keyExtractor={(item) => item.status}
      />
    )
  }
}

StatusSelector.propTypes = {
  additionalCount: PropTypes.number,
  userStatus: PropTypes.string,
  teeupStatus: PropTypes.string,
  onUserStatusSelected: PropTypes.func,
  isDataLoaded: PropTypes.bool,
  onClickBringing: PropTypes.func,
}
