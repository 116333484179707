import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { changeSettings } from "@actions/settingsActions"

import {
  selectUserNotificationSettings,
  selectNotificationLevel,
} from "@selectors/user"
import { getNotificationSettingsByLevel } from "@utils/settingsUtils"

import NotificationLevelView from "./notificationLevelView"

class NotificationLevelContainer extends React.Component {
  onSelectNotificationLevel = (level) => () => {
    if (level !== this.props.notificationSettings.notificationLevel) {
      const formattedSettings = {
        notifications: {
          ...this.props.notificationSettings,
          ...getNotificationSettingsByLevel(level),
        },
        notificationLevel: level,
      }
      changeSettings(formattedSettings)
    }
  }

  render() {
    const { notificationLevel } = this.props

    return (
      <NotificationLevelView
        notificationLevel={notificationLevel}
        onSelect={this.onSelectNotificationLevel}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const notificationSettings = selectUserNotificationSettings(state)
  const notificationLevel = selectNotificationLevel(state)
  return {
    notificationSettings,
    notificationLevel,
  }
}

export default connect(mapStateToProps)(NotificationLevelContainer)

NotificationLevelContainer.propTypes = {
  notificationSettings: PropTypes.object.isRequired,
  notificationLevel: PropTypes.string,
}
