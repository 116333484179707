import React from "react"
import PropTypes from "prop-types"
import { View, FlatList, Image, Text } from "react-native"
import { images } from "@utils/imageUtils"

import { styles } from "./UserNudgesScreenStyles"

const ListEmptyComponent = () => (
  <View style={styles.emptyComponent}>
    <Image source={images.nudgesPlaceholder} style={styles.nudgesImage} />
    <Text style={styles.nudgesTitle}>No nudges yet</Text>
    <Text style={styles.nudgesText}>
      You have not nudged someone or been nudged by someone yet. If you are this
      is where they will appear.
    </Text>
  </View>
)

const UserNudgesComponent = ({ nudgesWithResponses, onNudgeRender }) => {
  if (!nudgesWithResponses.length) {
    return <ListEmptyComponent />
  }
  return (
    <View style={[styles.flex, styles.nudgesContainer]}>
      <FlatList
        contentContainerStyle={styles.listPadding}
        data={nudgesWithResponses}
        renderItem={onNudgeRender}
      />
    </View>
  )
}
UserNudgesComponent.propTypes = {
  nudgesWithResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
  onNudgeRender: PropTypes.func.isRequired,
  insideTabContainer: PropTypes.bool,
}

export default UserNudgesComponent
