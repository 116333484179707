import React, { useRef, useEffect } from "react"
import { Text, ScrollView } from "react-native"
import PropTypes from "prop-types"

import { ServiceStyles } from "./moreStyles"

const TermsOfServiceScreen = ({ componentId, contentRef }) => {
  useEffect(() => {
    contentRef.current.scrollIntoView()
  }, [])

  return (
    <ScrollView
      contentContainerStyle={ServiceStyles.container}
      style={{ height: "92vh", marginBottom: 0 }}
    >
      <Text style={ServiceStyles.contentHeading}>Your Acceptance</Text>
      <Text style={ServiceStyles.contentText}>
        Welcome to the Terms of Use for Coo-E. This is an agreement
        (“Agreement”) between Coo-E LLC (“Coo-E&quot;), the owner and operator
        of www.coo-e.com and the Coo-E software, components, and any associated
        services offered (collectively the “Platform”) and you (“you”, “your” or
        “user(s)”), a user of the Platform. Throughout this Agreement, the words
        “Coo-E,” “us,” “we,” and “our,” refer to our company, Coo-E, as is
        appropriate in the context of the use of the words.
        <br />
        <br />
        By clicking “I agree”, accessing, or using the Platform you agree to be
        bound by this Agreement and the Privacy Policy. We may amend our Terms
        of Use or the Privacy Policy and may notify you when we do so. PLEASE BE
        AWARE THAT THERE ARE ARBITRATION AND CLASS ACTION PROVISIONS THAT MAY
        AFFECT YOUR RIGHTS. If you do not agree to the Terms of Use or the
        Privacy Policy please cease using our Platform immediately.
      </Text>
      <Text style={ServiceStyles.contentHeading}>
        User Information and Accounts
      </Text>
      <Text style={ServiceStyles.contentText}>
        Users may be required to register on the Platform before accessing
        portions of the Platform. Your information will be collected and
        disclosed in accordance with our Privacy Policy. All users are required
        to provide truthful and accurate information when registering for our
        Platform and must be over the age of 13. Where you make any purchases
        through the Platform you must be over the age of 18. Users may only
        register for one account per user. We reserve the right to verify all
        user credentials and to reject any users. You are entirely responsible
        for maintaining the confidentiality of password and account and for any
        and all activities that occur under your account. You agree to notify
        Coo-E immediately of any unauthorized use of your account or any other
        breach of security. Coo-E will not be liable for any losses you incur as
        a result of someone else using your password or account, either with or
        without your knowledge. If you are registering on behalf of your
        company, you represent and warrant that you are authorized by your
        company to create an account on your company’s behalf and you represent
        and warrant that you are authorized by your company to incur financial
        obligations and enter into legally binding agreements on behalf of your
        company.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Access</Text>
      <Text style={ServiceStyles.contentText}>
        After registering and properly paying for our Platform, where required,
        we shall grant you access to Platform as permitted by us and in
        accordance with this Agreement. All rights not explicitly granted are
        reserved for Coo-E. Where you download software to access our Platform,
        we grant you a limited, fully revocable, non-exclusive license to
        download one copy of our Platform onto your electronic device. If you
        breach this Agreement, your access or license to use our Platform may be
        terminated at our discretion. Additionally, we may revoke your access or
        license to use our Platform if we believe that your actions may harm us,
        our business interests, or any third party rights. Failure by us to
        revoke your access does not act as a waiver of your conduct
      </Text>
      <Text style={ServiceStyles.contentHeading}>Platform and Disclaimer</Text>
      <Text style={ServiceStyles.contentText}>
        The Coo-e Platform allows users to connect with other users via our
        Platform. Coo-E is solely responsible for only providing access to and
        charging payments related to your access of the Platform in accordance
        with this Agreement (“Coo-E Services”). Please be aware that access to
        the Platform and any Coo-E Services are “as-is” and “as-available.”
        Additionally any information or content found on our Platform, including
        any notifications or tools provided, are offered only for informational
        and entertainment purposes only. Further, Coo-E does not endorse,
        recommend, and is not otherwise affiliated with any users or Vendors
        (defined below) or any User Content (defined below). Coo-E has no
        liability to users for any User Content including all information, copy,
        images, URL names, and anything else provided by any third parties using
        the Platform (collectively “Non-Coo-E Content”). You understand that all
        Non-Coo-E Content or any other information may be inaccurate,
        unsubstantiated or possibly even incorrect. Coo-E does not offer users
        any guarantee of success through the use of Platform. We shall not be
        liable for any inconvenience, loss, liability, or damage resulting from
        any interruption of the Platform, directly or indirectly caused by, or
        proximately resulting from, any circumstances beyond our control,
        including, but not limited to, causes attributable to you; inability to
        access to the Platform; failure of a communications satellite, strike;
        labor dispute; riot or insurrection; war; explosion; malicious mischief;
        fire, flood, lightning, earthquake, wind, ice, extreme weather
        conditions, or other acts of God; failure or reduction of power; or any
        court order, law, act or order of government restricting or prohibiting
        the operation of or access to the Platform.
      </Text>
      <Text style={ServiceStyles.contentHeading}>
        Interactions With Other Users
      </Text>
      <Text style={ServiceStyles.contentText}>
        Please use caution and common sense when interacting with any users or
        Vendors found via the Platform. YOU AGREE THAT YOU ARE SOLELY
        RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS AND VENDORS. YOU
        UNDERSTAND THAT COO-E DOES NOT CONDUCT CRIMINAL OR BACKGROUND CHECKS ON
        ITS USERS OR VALIDATE ANY USER INFORMATION OR NON-COO-E CONTENT.
        FURTHERMORE, COO-E MAKES NO REPRESENTATIONS OR WARRANTIES AS TO ANY
        USERS OR VENDORS OR TO THE CONDUCT OF ANY USERS OR VENDORS. COO-E URGES
        YOU TO USE COMMON SENSE AND CAUTION WHEN MEETING ANY USERS OR VENDORS
        FOUND THROUGH THE PLATFORM. YOU UNDERSTAND THAT OTHER USERS MAY ACT
        UNLAWFULLY OR IN A DECEPTIVE MANNER, YOU AGREE THAT COO-E IS NOT LIABLE
        OR RESPONSIBLE FOR ANY ACTS OR OMISSIONS OF ANY USERS. **User releases
        COO-E from any liability associated with user’s interactions with others
        via the Platform.** Coo-E does not control or direct any users, and
        makes no representations or warranties to any User Content including but
        not limited to accuracy, reliability, or quality. Coo-E does not act as
        an agent or intermediary for users. Coo-E merely makes the Platform
        available to enable the users to communicate and interact with other
        users. Any opinions, advice, or information expressed by any user or
        Vendor are those of the individual and the individual alone and they do
        not reflect the opinions of Coo-E.
      </Text>
      <Text style={ServiceStyles.contentHeading}>User Profile</Text>
      <Text style={ServiceStyles.contentText}>
        Please be aware that any User Content may be publically searchable and
        viewable by third parties via the Platform or via the Internet
        (including but not limited to search engines such as Yahoo, Bing, or
        Google). Additionally, your User Content may be searchable by third
        parties.
      </Text>
      <Text style={ServiceStyles.contentHeading}>User Privacy</Text>
      <Text style={ServiceStyles.contentText}>
        We value your privacy and understand your privacy concerns. Our Privacy
        Policy is incorporated into this Agreement, and it governs your
        submission of information to our Platform. Please review our Privacy
        Policy so that you may understand our privacy practices. All information
        we collect is subject to our Privacy Policy, and by using the Platform
        you consent to all actions taken by us with respect to your information
        in compliance with the Privacy Policy. You further understand that any
        information collected by Coo-E may be transferred outside of your
        resident jurisdiction and/or to other countries for storage, processing
        and use by Coo-E and its affiliates.
      </Text>
      <Text style={ServiceStyles.contentHeading}>
        Usage and Location Information
      </Text>
      <Text style={ServiceStyles.contentText}>
        While using the Platform, we may have access to your user usage and
        location information. Please be aware that you may limit our access to
        such information by adjusting your mobile device settings. However, some
        portions of the Platform may not function properly or become
        inaccessible if you decide to limit our access to such information. Such
        information will be collected and disseminated in accordance with our
        Privacy Policy.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Use of the Platform</Text>
      <Text style={ServiceStyles.contentText}>
        When using our Platform, you are responsible for your and for any use of
        Coo-E made using your account. You agree to the following:
        <br />
        <br />
        - You way not copy, distribute or disclose any part of the Platform in
        any medium, including without limitation by any automated or
        non-automated “scraping”;
        <br />
        - You may not attempt to interfere with, compromise the system integrity
        or security, or decipher any transmissions to or from the servers
        running the Platform;
        <br />
        - You may not use any robot, spider, crawler, scraper or other automated
        means or interface not provided by us to access the Platform or to
        extract data;
        <br />
        - You may not use automated bots or other software to send more messages
        through our Platform than humanly possible;
        <br />
        - You may not share your access with any other parties, except as
        permitted by us;
        <br />
        - You may not use the Platform on a computer that is used to operate
        nuclear facilities, life support, or other mission critical applications
        where life or property may be at stake;
        <br />
        - You may not decompile, reverse engineer, disassemble, modify, rent,
        sell, lease, loan, distribute, or create derivative works or
        improvements to the Platform or any portion of it;
        <br />
        - You may not access our Platform in an attempt to build a similar or
        other competitive product;
        <br />
        - You may not use the Platform in an unlawful manner;
        <br />
        - You may not take any action that imposes, or may impose at our sole
        discretion, an unreasonable or disproportionately large load on our
        infrastructure;
        <br />
        - You may not collect or harvest any personally identifiable
        information, including account names, from the Platform;
        <br />
        - You may not impersonate any person or entity or misrepresent your
        affiliation with a person or entity;
        <br />
        - You may not violate or infringe other people&apos;s intellectual
        property, privacy, or other contractual rights while using our Platform;
        <br />
        - You may not violate any requirements, procedures, policies or
        regulations of networks connected to Coo-E;
        <br />
        - You may not sell, lease, loan, distribute, transfer, or sublicense the
        Platform or access to it or derive income from the use or provision of
        the Platform unless enabled through the functionality of our Platform;
        <br />
        - You may not interfere with or disrupt the Platform;
        <br />
        - You may not violate any law or regulation and you solely are
        responsible for such violations;
        <br />
        - You agree that you will not hold Coo-E responsible for your use of our
        Platform; and <br />
        - You agree not to cause, or aid in, the disruption, destruction,
        manipulation, removal, disabling, or impairment of any portion of our
        Platform, including the de-indexing or de-caching of any portion of our
        Platform from a thirty party’s website, such as by requesting its
        removal from a search engine.
        <br />
        <br />
        If you are discovered to be undertaking any of the aforementioned
        actions your privileges to use our Platform may at our discretion be
        terminated or suspended. Generally, we will provide an explanation for
        any suspension or termination of your use of any of our Platform, but
        Coo-E reserves the right to suspend or terminate any account at any time
        without notice or explanation.
      </Text>
      <Text style={ServiceStyles.contentHeading}>User Content</Text>
      <Text style={ServiceStyles.contentText}>
        Your ability to submit or transmit any information through the Platform,
        including but not limited to data, written content, images, videos, or
        any other information will be referred to as “User Content” throughout
        this Agreement. Please be aware that we are not required to host,
        display, migrate, or distribute any of your User Content and we may
        refuse to accept or transmit any User Content. You agree that you are
        solely responsible for any User Content submitted and you release us
        from any liability associated with any User Content submitted. We
        provide industry standard security for our Platform but we cannot
        guarantee the absolute safety and security of any such User Content. Any
        User Content found to be in violation of this Agreement or that we
        determine to be harmful to the Platform may be modified, edited, or
        removed at our discretion.
        <br />
        <br />
        When submitting any User Content to our Platform you represent and
        warrant that you own all rights to the User Content and you have paid
        for or otherwise have permission to use any User Content submitted.
        Furthermore, you represent and warrant that all User Content is legal
        and the User Content does not interfere with any third party rights or
        obligations.
        <br />
        <br />
        When you submit any User Content to us, you grant Coo-E, its partners,
        affiliates, users, representatives and assigns a non-exclusive, limited,
        fully-paid, royalty-free, revocable, world-wide, universal,
        transferable, assignable license to display, distribute, store,
        broadcast, transmit, reproduce, modify, prepare derivative works, or use
        and reuse all or part of your User Content for the purposes of providing
        you any services associated with the Platform. Additionally, you grant
        to Coo-E a worldwide, perpetual, irrevocable, royalty-free license to
        use and incorporate into the Platform any suggestion, enhancement
        request, recommendation, correction or other feedback provided by you
        relating to the operation of our Platform.
      </Text>
      <Text style={ServiceStyles.contentHeading}>User Content Guidelines</Text>
      <Text style={ServiceStyles.contentText}>
        We reserve the right to remove, delete, modify, screen, edit, or refuse
        any User Content for any reason or no reason, and with or without notice
        to you. Please be aware that all User Content may be viewed by third
        parties, thus we cannot guarantee the confidentiality of any User
        Content.
        <br />
        <br />
        When submitting any User Content you agree to the following:
        <br />
        <br />
        - you agree that User Content submitted is truthful and accurate;
        <br />
        - you agree not to submit any User Content that contains any
        confidential information;
        <br />
        - you agree not to submit any User Content that contains nudity, or
        sexual, or explicit content;
        <br />
        - you agree not to submit any User Content that depicts gratuitous
        violence, animal or child abuse, or encourages violence against others;
        <br />
        - you agree not to submit any User Content contains hate speech or
        promotes or condones violence against individuals or groups based on
        race or ethnic origin, religion, disability, gender, age, nationality,
        veteran status, or sexual orientation/gender identity;
        <br />
        - you agree not to submit any User Content that is considered spam or
        controversial; and
        <br />
        - you agree not to submit any User Content that may be considered:
        misleading, unlawful, defamatory, obscene, invasive, threatening, or
        harassing.
        <br />
        <br />
        <br />
        If you have violated any of our User Content Guidelines or if you we
        believe that any User Content may harm the Platform, your access to the
        Platform may be suspended or terminated.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Monitoring User Content</Text>
      <Text style={ServiceStyles.contentText}>
        Coo-E shall have the right, but not the obligation, to monitor all User
        Content on the Platform at all times, to determine compliance with this
        Agreement and any guidelines established by us. Without limiting the
        foregoing, Coo-E shall have the right, but not the obligation, to remove
        any User Content that Coo-E at its sole discretion. For example, we may
        remove User Content if we believe that any User Content may harm us or
        our business interests. We have no obligation to retain or provide you
        with copies of any User Content after your termination of this
        Agreement.
      </Text>
      <Text style={ServiceStyles.contentHeading}>
        User Content Storage Limits and Usage Information
      </Text>
      <Text style={ServiceStyles.contentText}>
        The Platform may offer reasonable storage of all User Content. However,
        all User Content storage is subject to our internal data storage limits.
        We have no obligation to store any excess User Content. If you violate
        any storage limits, Coo-E will notify you and may work with you to bring
        your usage into conformity with our data storage policies. If,
        notwithstanding our efforts, you are unable or unwilling to abide by our
        storage limits, we may invoice you for excess data storage in accordance
        with our applicable policies. Through the Platform we may collect your
        usage data and interactions with the Platform. This information will be
        used by Coo-E to determine how you use our Platform and how we can
        improve or modify our Platform. The information collected may be used
        and disseminated per our Privacy Policy.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Users and Vendors</Text>
      <Text style={ServiceStyles.contentText}>
        Vendors (“Vendors”) may post listings (“Listing(s)”) or offerings on the
        Platform that may be offered to users for sale. Listings may contain
        additional information such as location, cancellation policies,
        materials provided, and pricing. Such Listings are the sole
        responsibility of the Vendor and Coo-E is not a party to any contract
        created between User and Vendor.
        <br />
        <br />
        You acknowledge and agree that your purchase via a Listing from a
        Vendor, creates a direct contractual relationship solely between you and
        the Vendor. Coo-E is not responsible or liable for the actions or
        inactions of a Vendor in relation to you. User acknowledges and agrees
        that you are solely responsible for taking any and all precautions as
        may be reasonable and proper regarding any acts or omissions of a Vendor
        or a third party. You acknowledge and agree that Coo-E may release your
        contact information to Vendors when you purchase anything offered via a
        Listing.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Platform Availability</Text>
      <Text style={ServiceStyles.contentText}>
        Although we try to provide continuous availability to you, we do not
        guarantee that the Platform will always be available, work, or be
        accessible at any particular time. Specifically, we do not guarantee any
        uptime or specific availability of the Platform. You agree and
        acknowledge that the Platform uses remote access and may not always be
        either 100% reliable or available. Only users who are eligible to use
        our Platform may do so and we may refuse service or terminate your
        access at any time. We cannot guarantee that anything found on our
        Platform will work to the functionality desired by you or give you any
        desired results.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Modification of Platform</Text>
      <Text style={ServiceStyles.contentText}>
        We reserve the right to alter, modify, update, or remove the Platform or
        any portions thereof, at any time. We may conduct such modifications to
        our Platform for security reasons, intellectual property, legal reasons,
        or various other reasons at our discretion, and we are not required to
        explain such modifications or provide you access to previous versions of
        our Platform. For example, we may provide updates to fix security flaws,
        or to respond to legal demands. Please note that this is a non-binding
        illustration of how we might exercise our rights under this section, and
        nothing in this section obligates us to take measures to update the
        Platform for security, legal or other purposes.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Platform Security</Text>
      <Text style={ServiceStyles.contentText}>
        Coo-E implements administrative, physical, and technical safeguards for
        protection of the security, confidentiality and integrity of your
        information and all User Content. Those safeguards used or based on
        industry standards. Aside from such industry standard safeguards, Coo-E
        can make no guarantees regarding any Platform security or any User
        Content secured.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Payments</Text>
      <Text style={ServiceStyles.contentText}>
        You agree to pay for all costs, fees, and taxes listed when purchasing
        any access to the Platform or for any purchases. User authorizes Coo-E
        or its third party payment processors to charge their method of payment
        on file at the time of purchase. Where applicable, you must agree to our
        third party payment processors terms and conditions for processing
        payments. All information that you provide in connection with a purchase
        or transaction must be accurate, complete, and current. Where you have
        failed to pay or where payments are overdue, Coo-E may suspend or
        terminate your access to the paid portions of the Platform, without
        liability to us.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Taxes</Text>
      <Text style={ServiceStyles.contentText}>
        Where Coo-E does not charge you taxes for any purchases or payments, you
        agree to pay any and all applicable taxes. Additionally, where requested
        by us, you agree to provide us tax documentation to support any claims
        of on-time tax payment.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Recurring Payments</Text>
      <Text style={ServiceStyles.contentText}>
        For your convenience when you make a recurring purchase or payment, your
        payment information shall be logged. WHERE YOU SIGN UP FOR A RECURRING
        PAYMENT, COO-E MAY CHARGE YOU AUTOMATICALLY ON A RECURRING BASIS AFTER
        YOUR INITIAL PAYMENT (“INITIAL PAYMENT”), UNLESS YOU NOTIFY US THAT YOU
        WANT TO CANCEL SUCH RECURRING PAYMENT BY EMAIL AT SUPPORT@COO-E.COM OR
        BY CANCELLING VIA YOUR ACCOUNT DASHBOARD. ADDITIONALLY, YOU AUTHORIZE US
        TO BILL YOU ON A RECURRING BASIS AND AGREE THAT AT THE EXPIRATION OF
        YOUR INITIAL PAYMENT PERIOD, YOU SHALL BE CHARGED FOR ADDITIONAL PERIODS
        (OF THE SAME LENGTH AS YOUR INITIAL PAYMENT PERIOD) UNTIL WE ARE
        NOTIFIED OTHERWISE. YOU AGREE THAT NO ADDITIONAL CONSENT IS REQUIRED BY
        YOU TO CHARGE YOUR PREFERRED PAYMENT METHOD AUTOMATICALLY AND ON AN
        AUTOMATICALLY RENEWABLE BASIS.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Intellectual Property</Text>
      <Text style={ServiceStyles.contentText}>
        The name “Coo-E”, the Coo-E Platform along with the design of the Coo-E
        Platform and any text, writings, images, templates, scripts, graphics,
        interactive features and any trademarks or logos contained therein
        (&quot;Marks&quot;), are owned by or licensed to Coo-E, subject to
        copyright and other intellectual property rights under US and foreign
        laws and international conventions. Coo-E reserves all rights not
        expressly granted in and to the Platform. You agree to not engage in the
        use, copying, or distribution anything contained within the Platform
        unless we have given express written permission.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Idea Submission</Text>
      <Text style={ServiceStyles.contentText}>
        Coo-E or any of its employees do not accept or consider unsolicited
        ideas, including but not limited to ideas relating to processes,
        technologies, product enhancements, or product names. Please do not
        submit any unsolicited ideas, content, artwork, suggestions, or other
        works (“Submissions”) in any form to Coo-E. The sole purpose of this
        policy is to avoid potential misunderstandings or disputes when Coo-E’s
        products might seem similar to ideas you submitted to Coo-E. If, despite
        our request that you not send us your ideas, you agree to the following:
        (1) your Submissions and their contents will automatically become the
        property of Coo-E, without any compensation to you; (2) Coo-E may use or
        redistribute the Submissions and their contents for any purpose and in
        any way; (3) there is no obligation for Coo-E to review the Submission;
        and (4) there is no obligation to keep any Submissions confidential.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Disclaimer</Text>
      <Text style={ServiceStyles.contentText}>
        THE PLATFORM IS PROVIDED ON AN &quot;AS IS&quot;, &quot;AS
        AVAILABLE&quot; AND &quot;WITH ALL FAULTS&quot; BASIS. TO THE FULLEST
        EXTENT PERMISSIBLE BY LAW, NEITHER COO-E, NOR ANY OF OUR EMPLOYEES,
        MANAGERS, OFFICERS, ASSIGNS, AFFILIATES, OR AGENTS MAKE ANY
        REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOEVER,
        EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO: (1) THE PLATFORM; (2)
        ANY INFORMATION OR CONTENT PROVIDED VIA THE PLATFORM; OR (3) SECURITY
        ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO COO-E, OR VIA THE
        PLATFORM. IN ADDITION, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM,
        TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION, AND FREEDOM FROM COMPUTER
        VIRUS.
        <br />
        <br />
        COO-E DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM WILL BE ERROR-FREE
        OR UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; OR THAT THE PLATFORM
        OR THE SERVER THAT MAKES THE PLATFORM AVAILABLE IS FREE FROM ANY HARMFUL
        COMPONENTS. COO-E DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT
        THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE PLATFORM IS
        ACCURATE, COMPLETE, OR USEFUL. COO-E DOES NOT WARRANT THAT YOUR USE OF
        THE PLATFORM IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND COO-E
        SPECIFICALLY DISCLAIMS ANY SUCH WARRANTIES.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Limitation of Liability</Text>
      <Text style={ServiceStyles.contentText}>
        IN NO EVENT SHALL Coo-E, ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES,
        OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL,
        SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED ON WARRANTY,
        CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND
        WHETHER OR NOT THE COO-E IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT
        PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. This limitation of
        liablity does not apply to new jersey residents. FOR NEW JERSEY
        RESIDENTS, OUR LIMITATION OF LIABILITY IS LIMITED TO THE LOWEST AMOUNT
        PERMITTED BY STATE LAW. **SOME STATES DO NOT ALLOW THE LIMITATION OR
        EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE
        ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE
        OTHER LEGAL RIGHTS THAT VARY FROM STATE TO STATE.** Specifically, in
        those jurisdictions not allowed, we do not disclaim liability for: (1)
        death or personal injury caused by Coo-E’s negligence or that of any of
        its officers, employees or agents; (2) fraudulent misrepresentation; or
        (3) any liability which it is not lawful to exclude either now or IN THE
        FUTURE. WHERE A TOTAL DISCLAIMER OF LIABILITY IS DISALLOWED YOU AGREE
        THAT OUR TOTAL LIABILITY TO YOU SHALL NOT EXCEED THE AMOUNTS YOU HAVE
        PAID IN THE PAST SIX (6) MONTHS TO USE OUR PLATFORM OR ONE HUNDRED USD.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Indemnity</Text>
      <Text style={ServiceStyles.contentText}>
        You agree to defend, indemnify and hold harmless Coo-E, its officers,
        directors, employees, affiliates, and agents, from and against any and
        all claims, damages, obligations, losses, liabilities, costs or debt,
        and expenses (including but not limited to attorney&apos;s fees) arising
        from:
        <br />
        <br />
        - your violation of any term of this Agreement; or <br />
        - your use of and access to the Coo-E Platform; <br />
        - your violation of any third party right, including without limitation
        any copyright, property, or contractual right. <br />
        <br />
        <br />
        This defense and indemnification obligation will survive this Agreement
        and your use of the Coo-E Platform. You also agree that you have a duty
        to defend us against such claims and we may require you to pay for an
        attorney(s) of our choice in such cases. You agree that this indemnity
        extends to requiring you to pay for our reasonable attorneys’ fees,
        court costs, and disbursements. In the event of a claim such as one
        described in this paragraph, we may elect to settle with the
        party/parties making the claim and you shall be liable for the damages
        as though we had proceeded with a trial.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Copyrights</Text>
      <Text style={ServiceStyles.contentText}>
        We take copyright infringement very seriously. If you believe that any
        content owned by you has been infringed upon please send us a message
        which contains:
        <br />
        <br />
        - Your name.
        <br />
        - The name of the party whose copyright has been infringed, if different
        from your name.
        <br />
        - The name and description of the work that is being infringed.
        <br />
        - The location on our Platform of the infringing copy.
        <br />
        - A statement that you have a good faith belief that use of the
        copyrighted work described above is not authorized by the copyright
        owner (or by a third party who is legally entitled to do so on behalf of
        the copyright owner) and is not otherwise permitted by law.
        <br />
        - A statement that you swear, under penalty of perjury, that the
        information contained in this notification is accurate and that you are
        the copyright owner or have an exclusive right in law to bring
        infringement proceedings with respect to its use.
        <br />
        <br />
        <br />
        You must sign this notification and send it to our Copyright Agent:
        Copyright Agent of Coo-E, support@coo-e.com.
        <br />
        <br />
        <br />
        Counter Notice
        <br />
        <br />
        In the event that you receive a notification from Coo-E stating content
        posted by you has been subject to a copyright takedown notice, you may
        respond by filing a counter-notice pursuant to the DMCA. Your
        counter-notice must contain the following:
        <br />
        <br />
        - Your name, address, email and physical or electronic signature.
        <br />
        - The notification reference number (if applicable).
        <br />
        - Identification of the material and its location before it was removed.
        <br />
        - A statement under penalty of perjury that the material was removed by
        mistake or misidentification.
        <br />
        - Your consent to the jurisdiction of a federal court in the district
        where you live (if you are in the U.S.), or your consent to the
        jurisdiction of a federal court in the district where your Vendor is
        located (if you are not in the US).
        <br />
        - Your consent to accept service of process from the party who submitted
        the takedown notice.
        <br />
        <br />
        <br />
        Please be aware that we may not take any action regarding your
        counter-notice unless your notification strictly complies with the
        foregoing requirements. Please send this counter-notice in accordance
        with the takedown notice instructions above.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Choice of Law</Text>
      <Text style={ServiceStyles.contentText}>
        This Agreement shall be governed by the laws in force in the state of
        New Jersey. The offer and acceptance of this contract is deemed to have
        occurred in the state of New Jersey.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Disputes</Text>
      <Text style={ServiceStyles.contentText}>
        Any dispute relating in any way to your visit to the Platform or our
        Platform shall be submitted to confidential arbitration in Highland
        Park, NJ. Arbitration under this Agreement shall be conducted pursuant
        to the applicable Commercial Rules (“Rules”) then prevailing at the
        American Arbitration Association. Arbitration shall be conducted in
        English by one (1) arbitrator as selected pursuant to the Rules; the
        arbitrator&apos;s award shall be final and binding and may be entered as
        a judgment in any court of competent jurisdiction. Each party shall be
        responsible for their own arbitration fees and costs. To the fullest
        extent permitted by applicable law, no arbitration under this Agreement
        shall be joined to an arbitration involving any other party subject to
        this Agreement, whether through class action proceedings or otherwise.
        Where permitted by the Rules, both parties may make any and all
        appearances telephonically or electronically. You agree that regardless
        of any statute or law to the contrary, any claim or cause of action
        arising out of, related to or connected with the use of the Platform or
        this Agreement must be filed within one (1) year after such claim or
        cause of action arose or be forever banned (New Jersey users are
        exempted from the foregoing one (1) year limitation). Notwithstanding
        the foregoing, either party may seek equitable relief to protect its
        interests (including but not limited to injunctive relief), or make a
        claim for nonpayment, in a court of appropriate jurisdiction, and issues
        of intellectual property ownership or infringement may be decided only
        by a court of appropriate jurisdiction and not by arbitration. In the
        event that the law does not permit the abovementioned dispute to be
        resolved through arbitration or if this arbitration agreement is
        unenforceable, you agree that any actions and disputes shall be brought
        solely in a court of competent jurisdiction located within Middlesex
        County, NJ
      </Text>
      <Text style={ServiceStyles.contentHeading}>Class Action Waiver</Text>
      <Text style={ServiceStyles.contentText}>
        You and Coo-E agree that any proceedings to resolve or litigate any
        dispute whether through a court of law or arbitration shall be solely
        conducted on an individual basis. You agree that you will not seek to
        have any dispute heard as a class action, representative action,
        collective action, or private attorney general action. This class action
        waiver does not apply to New Jersey residents.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Severability</Text>
      <Text style={ServiceStyles.contentText}>
        In the event that a provision of this Agreement is found to be unlawful,
        conflicting with another provision of the Agreement, or otherwise
        unenforceable, the Agreement will remain in force as though it had been
        entered into without that unenforceable provision being included in it.
        <br />
        <br />
        If two or more provisions of this Agreement or any other agreement you
        may have with Coo-E are deemed to conflict with each other’s operation,
        Coo-E shall have the sole right to elect which provision remains in
        force.
        <br />
        <br />
      </Text>
      <Text style={ServiceStyles.contentHeading}>Non-Waiver</Text>
      <Text style={ServiceStyles.contentText}>
        We reserve all rights permitted to us under this Agreement as well as
        under the provisions of any applicable law. Our non-enforcement of any
        particular provision or provisions of this Agreement or the any
        applicable law should not be construed as our waiver of the right to
        enforce that same provision under the same or different circumstances at
        any time in the future.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Assignment and Survival</Text>
      <Text style={ServiceStyles.contentText}>
        You may not assign your rights and/or obligations under this Agreement
        to any other party without our prior written consent. We may assign our
        rights and/or obligations under this Agreement to any other party at our
        discretion. All portions of this Agreement that would reasonably be
        believed to survive termination shall survive and remain in full force
        upon termination, including but not limited to the Limitation of
        Liabilities, Representation and Warranties, Licensing, Indemnification,
        and Arbitration sections.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Termination</Text>
      <Text style={ServiceStyles.contentText}>
        You may cancel your access to the Platform or any recurring payments at
        any time via your Coo-E dashboard or contacting us at support@coo-e.com.
        Please be aware that upon termination of your account, access to
        portions of our Platform may be become immediately disabled. We may
        terminate this Agreement with you if we determine that: (1) you have
        violated any applicable laws while using our Platform; (2) if you have
        violated any portion of this Agreement or any of our Platform policies;
        or (3) if we believe that any of your current or future actions may
        legally harm Coo-E, our business interests or a third party, at our
        discretion. In the event of termination, we will strive to provide you
        with a timely explanation; however, we are not required to do so.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Entire Agreement</Text>
      <Text style={ServiceStyles.contentText}>
        This Agreement along with the Privacy Policy and any other supporting
        agreements provided by Coo-E constitute the complete and exclusive
        understanding and agreement between the parties regarding the subject
        matter herein and supersede all prior or contemporaneous agreements or
        understandings written or oral, relating to its subject matter. Any
        waiver, modification or amendment of any provision of this Agreement
        will be effective only if in writing and signed by a duly authorized
        representative of each party. Where this Agreement conflicts with our
        Privacy Policy or any other documentation listed on our website this
        Agreement shall supersede and control.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Amendments</Text>
      <Text style={ServiceStyles.contentText}>
        We may amend this Agreement from time to time. When we amend this
        Agreement, we will update this page and indicate the date that it was
        last modified or we may email you. You may refuse to agree to the
        amendments, but if you do, you must immediately cease using our Platform
        and our Platform.
      </Text>
      <Text style={ServiceStyles.contentHeading}>
        Electronic Communications
      </Text>
      <Text style={ServiceStyles.contentText}>
        The communications between you and Coo-E use electronic means, whether
        you visit the Platform or send Coo-E e-mails, or whether Coo-E posts
        notices on the Platform or communications with you via mobile
        notifications or e-mail. For contractual purposes, you (1) consent to
        receive communications from Coo-E in an electronic form; and (2) agree
        that all terms, conditions, agreements, notices, disclosures, and other
        communications that Coo-E provides to you electronically satisfy any
        legal requirement that such communications would satisfy if it were to
        be in writing. The foregoing does not affect your statutory rights.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Export Controls</Text>
      <Text style={ServiceStyles.contentText}>
        The Platform and the underlying information and technology may not be
        downloaded, accessed, or otherwise exported or re-exported (1) into (or
        to a national or resident of) any country to which the U.S. has
        currently embargoed goods; or (2) to anyone on the U.S. Treasury
        Department&apos;s list of Specially Designated Nationals or the U.S.
        Commerce Department&apos;s Table of Deny Orders. By downloading or using
        the Platform, you agree to the foregoing and you represent and warrant
        that you are not located in, under the control of, or a national or
        resident of any such country or on any such list, and that you will
        otherwise comply with all applicable export control laws.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Platform Issues</Text>
      <Text style={ServiceStyles.contentText}>
        Where you have any questions, issues, or if you are having trouble
        accessing or using the Platform, please contact us at support@coo-e.com.
      </Text>
      <Text style={ServiceStyles.contentHeading}>California Users</Text>
      <Text style={ServiceStyles.contentText}>
        Pursuant to California Civil Code Section 1789.3, any questions about
        pricing, complaints, or inquiries about Coo-E must be sent to our agent
        for notice to: support@coo-e.com
        <br />
        <br />
        Lastly, California users are also entitled to the following specific
        consumer rights notice: The Complaint Assistance Unit of the Division of
        Consumer Services of the California Department of Consumer Affairs may
        be contacted in writing at 1625 North Market Blvd., Sacramento, CA
        95834, or by telephone at (916) 445-1254 or (800) 952-5210.
      </Text>
    </ScrollView>
  )
}

export default TermsOfServiceScreen

TermsOfServiceScreen.propTypes = {
  componentId: PropTypes.string.isRequired,
}
