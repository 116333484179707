import TimePickerItem from "./TimePickerItem"
import React from "react"

const DurationContainer = ({
  durationHourNumbers,
  setDurationHourNumbers,
  durationMinuteNumbers,
  setDurationMinuteNumbers,
  chosenDurationHour,
  chosenDurationMinute,
}) => {
  const selectDurationHour = (chosenHour) => {
    let arrayWithSelectedItem = durationHourNumbers.map((item) => {
      if (item.value === chosenHour) {
        item.selected = true
        return item
      } else {
        item.selected = false
        return item
      }
    })

    setDurationHourNumbers(arrayWithSelectedItem)
  }

  const selectDurationMinute = (chosenHour) => {
    let arrayWithSelectedItem = durationMinuteNumbers.map((item) => {
      if (item.value === chosenHour) {
        item.selected = true
        return item
      } else {
        item.selected = false
        return item
      }
    })

    setDurationMinuteNumbers(arrayWithSelectedItem)
  }

  return (
    <div className="start-time__container">
      <p className="start-time__title">
        Duration:
        <span className="start-time__time">
          {chosenDurationHour &&
            chosenDurationMinute &&
            ` ${chosenDurationHour}h ${chosenDurationMinute}min`}
          {chosenDurationHour &&
            !chosenDurationMinute &&
            (chosenDurationHour > 1
              ? ` ${chosenDurationHour} hours`
              : ` ${chosenDurationHour} hour`)}
          {chosenDurationMinute &&
            !chosenDurationHour &&
            ` ${chosenDurationMinute}min`}
        </span>
      </p>
      <div className="start-time__picker-wrapper">
        <TimePickerItem
          average
          pickHour
          noMargin
          durationHourNumbers={durationHourNumbers}
          selectDurationHour={selectDurationHour}
        />
        <TimePickerItem
          average
          noMargin
          durationMinuteNumbers={durationMinuteNumbers}
          selectDurationMinute={selectDurationMinute}
        />
      </div>
    </div>
  )
}

export default DurationContainer
