import React, { memo, useEffect } from "react"
import { useSelector } from "react-redux"

import { setupWebSocket } from "@middlewares/backendMiddleware"
import { useTeeUpPage } from "./TeeUpPageContext"
import { selectToken } from "@selectors/user"
import TeeUpPageLeftSection from "./TeeUpPageLeftSection"
import TeeUpPageCenterSection from "./TeeUpPageCenterSection"
import TeeUpPageRightSection from "./TeeUpPageRightSection"
import { SuggestWhenModal, SuggestWhereModal } from "./TeeUpPageModals"

import "./index.scss"

const TeeUpPage = () => {
  const { user, teeup, isSuggestWhenModalOpen, isSuggestWhereModalOpen } =
    useTeeUpPage()
  const token = useSelector(selectToken)

  // connect to socket
  useEffect(() => {
    if (user && teeup && token) {
      setupWebSocket(token, teeup.id, user.id)
    }
  }, [user, teeup, token])

  return (
    <>
      {isSuggestWhenModalOpen && <SuggestWhenModal />}
      {isSuggestWhereModalOpen && <SuggestWhereModal />}
      <div className="teeup-page page">
        <div className="teeup-page__container">
          <TeeUpPageLeftSection />
          <TeeUpPageCenterSection />
          <TeeUpPageRightSection />
        </div>
      </div>
    </>
  )
}

export default memo(TeeUpPage)
