import React, { memo } from "react"

import {
  SuggestModalFooter,
  SuggestModalContent,
  SuggestModalSubHeader,
} from "../../components"
import Button from "@ui/Button"

import "./generalTimeTab.scss"
import QuickSelect from "./QuickSelect/QuickSelect"
import CalendarSelect from "./CalendarSelect/CalendarSelect"
import SelectTime from "./SelectTime/SelectTime"
import useGeneralTimeTab from "./GeneralTimeContext/useGeneralTimeTab"

const GeneralTimeTab = () => {
  const { canSuggest, onSuggest } = useGeneralTimeTab()

  return (
    <>
      <SuggestModalSubHeader></SuggestModalSubHeader>
      <SuggestModalContent type="when">
        <div className="general-time__date">
          <div className="general-time__calendar">
            <div className="general-time__sub-title">
              Select one or more days
            </div>
            <CalendarSelect />
          </div>
          <div className="general-time__date-divider" />
          <div className="general-time__quick-select">
            <div className="general-time__sub-title">Quick select</div>
            <QuickSelect />
          </div>
        </div>
        <div className="general-time__time">
          <div className="general-time__sub-title">
            Select time<span>optional</span>
          </div>
          <SelectTime />
        </div>
      </SuggestModalContent>
      <SuggestModalFooter>
        <Button
          type={canSuggest ? "primary" : "disabled"}
          as="div"
          style={{ height: "40px", width: "144px", fontSize: "17px" }}
          onClick={() => (canSuggest ? onSuggest() : null)}
        >
          <span>Suggest</span>
        </Button>
      </SuggestModalFooter>
    </>
  )
}

export default memo(GeneralTimeTab)
