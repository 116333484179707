import React, { Component, PureComponent } from "react"
import { View, TouchableOpacity, Text } from "react-native"
import PropTypes from "prop-types"

import { shouldUpdate } from "../../../component-updater"
import styleConstructor from "./style"
import * as defaultStyle from "../../../style"
import _ from "lodash"

class Day extends Component {
  static displayName = "IGNORE"

  static propTypes = {
    // TODO: disabled props should be removed
    state: PropTypes.oneOf(["disabled", "today", ""]),

    // Specify theme properties to override specific styles for calendar parts. Default = {}
    theme: PropTypes.object,
    marking: PropTypes.any,
    onPress: PropTypes.func,
    onLongPress: PropTypes.func,
    date: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.style = styleConstructor(props.theme)
    this.theme = { ...defaultStyle, ...(props.theme || {}) }
    this.onDayPress = this.onDayPress.bind(this)
    this.onDayLongPress = this.onDayLongPress.bind(this)
    this.markingStyle = this.getDrawingStyle(props.marking || [])
  }

  onDayPress() {
    this.props.onPress(this.props.date)
  }

  onDayLongPress() {
    this.props.onLongPress(this.props.date)
  }

  shouldComponentUpdate(nextProps) {
    const newMarkingStyle = this.getDrawingStyle(nextProps.marking)

    if (!_.isEqual(this.markingStyle, newMarkingStyle)) {
      this.markingStyle = newMarkingStyle
      return true
    }
    return shouldUpdate(this.props, nextProps, [
      "state",
      "children",
      "marking",
      "onPress",
      "onLongPress",
    ])
  }

  renderDots(marking) {
    const baseDotStyle = [this.style.dot, this.style.visibleDot]
    if (
      marking.dots &&
      Array.isArray(marking.dots) &&
      marking.dots.length > 0
    ) {
      // Filter out dots so that we we process only those items which have key and color property
      const validDots = marking.dots.filter((d) => d && d.color)
      return validDots.map((dot, index) => {
        return (
          <View
            key={dot.key ? dot.key : index}
            style={[
              baseDotStyle,
              {
                backgroundColor:
                  marking.selected && dot.selectedDotColor
                    ? dot.selectedDotColor
                    : dot.color,
              },
            ]}
          />
        )
      })
    }
    return
  }

  getDrawingStyle(marking) {
    const defaultStyle = { textStyle: {} }
    if (!marking) {
      return defaultStyle
    }

    const resultStyle = [marking].reduce((prev, next) => {
      if (next.quickAction) {
        if (next.first || next.last) {
          prev.containerStyle = this.style.firstQuickAction
          prev.textStyle = this.style.firstQuickActionText
          if (next.endSelected && next.first && !next.last) {
            prev.rightFillerStyle = "#c1e4fe"
          } else if (next.endSelected && next.last && !next.first) {
            prev.leftFillerStyle = "#c1e4fe"
          }
        } else if (!next.endSelected) {
          prev.containerStyle = this.style.quickAction
          prev.textStyle = this.style.quickActionText
        } else if (next.endSelected) {
          prev.leftFillerStyle = "#c1e4fe"
          prev.rightFillerStyle = "#c1e4fe"
        }
        return prev
      }

      const color = next.color
      if (next.status === "NotAvailable") {
        prev.textStyle = this.style.naText
      }
      if (next.startingDay) {
        prev.startingDay = {
          color,
        }
      }
      if (next.endingDay) {
        prev.endingDay = {
          color,
        }
      }
      if (!next.startingDay && !next.endingDay) {
        prev.day = {
          color,
        }
      }
      if (next.textColor) {
        prev.textStyle.color = next.textColor
      }
      return prev
    }, defaultStyle)
    return resultStyle
  }

  render() {
    const containerStyle = [this.style.base]
    const textStyle = [this.style.text]
    const marking = this.props.marking || {}
    const dot = this.renderDots(marking)
    let fillers = {}
    let rightFillerStyle = {}
    let leftFillerStyle = {}

    if (marking.selected && this.props.state === "today") {
      containerStyle.push(this.style.selectedToday)
      textStyle.push(this.style.selectedTodayText)
    } else if (marking.selected) {
      containerStyle.push(this.style.selected)
      textStyle.push(this.style.selectedText)
      if (marking.selectedColor) {
        containerStyle.push({ backgroundColor: marking.selectedColor })
      }
    } else if (
      typeof marking.disabled !== "undefined"
        ? marking.disabled
        : this.props.state === "disabled"
    ) {
      textStyle.push(this.style.disabledText)
    } else if (this.props.state === "today") {
      containerStyle.push(this.style.today)
      textStyle.push(this.style.todayText)
    }

    if (this.props.isNextMonthDay) {
      textStyle.push(this.style.nextMonthText)
    }

    /*
         maxWidth: this.props.leftFillerWidth
            ? this.props.leftFillerWidth.interpolate({
                 inputRange: [0, 1],
                outputRange: ['0%', '50%'],
                                      })
         : '50%',
        */

    if (this.props.marking) {
      const flags = this.markingStyle

      if (flags.startingDay && !flags.endingDay) {
        leftFillerStyle = {
          backgroundColor: this.theme.calendarBackground,
        }
        rightFillerStyle = {
          backgroundColor: flags.startingDay.color,
        }
      } else if (flags.endingDay && !flags.startingDay) {
        rightFillerStyle = {
          backgroundColor: this.theme.calendarBackground,
        }
        leftFillerStyle = {
          backgroundColor: flags.endingDay.color,
        }
      } else if (flags.endingDay && flags.startingDay) {
        rightFillerStyle = {
          backgroundColor: this.theme.calendarBackground,
        }
        leftFillerStyle = {
          backgroundColor: this.theme.calendarBackground,
        }
      }

      fillers = (
        <View style={[this.style.fillers]}>
          <View style={[this.style.leftFiller, leftFillerStyle]} />
          <View style={[this.style.rightFiller, rightFillerStyle]} />
        </View>
      )
    }

    return (
      <TouchableOpacity
        testID={this.props.testID}
        onPress={this.onDayPress}
        onLongPress={this.onDayLongPress}
        disabled={marking.disableTouchEvent}
        style={{
          alignItems: "center",
          alignSelf: "stretch",
          marginLeft: -1,
        }}
        activeOpacity={1}
      >
        {fillers}
        <View style={containerStyle}>
          <Text allowFontScaling={false} style={textStyle}>
            {String(this.props.children)}
          </Text>
          <View
            style={{
              flexDirection: "row",
              position: "absolute",
              top: 12,
            }}
          >
            {dot}
          </View>
        </View>
      </TouchableOpacity>
    )
  }
}

export default Day
