import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units, AppStyles, AppSizes } from "@theme/"

export const CALENDAR_BELT_HEIGHT = Units.responsiveValue(20)

export const ZoneStyles = StyleSheet.create({
  zonesContainer: {
    backgroundColor: AppColors.brand.lightGrey,
    boxShadow: "inset 0 11px 6px -10px rgb(0 0 0 / 20%)",
    maxHeight: "300px",
  },
  timezoneContainerBottomPadding: {
    paddingBottom: Units.responsiveValue(6),
  },
  flex: {
    flex: 1,
  },
  headersContainerInitial: {
    height: "65px",
  },
  headersContainer: {
    ...AppStyles.absolute,
    height: "20px",
  },
  calendarBeltItem: {
    height: CALENDAR_BELT_HEIGHT,
  },
  row: {
    flexDirection: "row",
  },
  selectedCalendarText: {
    ...AppFonts.extraSmallDemibold,
    lineHeight: Units.responsiveValue(11),
    color: AppColors.brand.black2,
  },
  disabledHours: {
    backgroundColor: AppColors.brand.grey157,
  },
  dateTitleBlock: {
    zIndex: 1,
    marginLeft: Units.responsiveValue(8),
    marginBottom: Units.responsiveValue(16),
  },
  dateTitle: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.black2,
    lineHeight: Units.responsiveValue(17),
    letterSpacing: -Units.responsiveValue(0.26),
  },
  dateMonthTitle: {
    ...AppFonts.extraSmallMedium,
    color: AppColors.brand.black2,
    lineHeight: Units.responsiveValue(10),
    letterSpacing: -Units.responsiveValue(0.15),
  },
  redLine: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: Units.responsiveValue(6),
    backgroundColor: AppColors.brand.pink,
    // borderWidth: Units.responsiveValue(1),
    borderWidth: Units.responsiveValue(2),
    borderColor: AppColors.brand.white,
    borderTopWidth: Units.responsiveValue(0),
    borderTopColor: undefined,
  },
  redDot: {
    borderRadius: Units.responsiveValue(5),
    height: Units.responsiveValue(10),
    width: Units.responsiveValue(10),
    marginTop: -Units.responsiveValue(8),
    backgroundColor: AppColors.brand.pink,
    borderWidth: Units.responsiveValue(1),
    // borderWidth: Units.responsiveValue(2),
    borderColor: AppColors.brand.white,
    borderBottomWidth: Units.responsiveValue(0),
    borderBottomColor: undefined,
  },
  headerContainer: {
    flexDirection: "row",
    paddingHorizontal: Units.rem(0.75),
    paddingTop: Units.rem(1.25),
    paddingBottom: Units.rem(0.375),
    // alignItems: 'flex-end',
  },
  bottomPadding: {
    paddingBottom: Units.responsiveValue(20),
  },
  splitterContainer: {
    ...AppStyles.absolute,
    alignItems: "center",
    marginLeft: 0,
    marginTop: "4px",
    // marginTop: Units.responsiveValue(4),
  },
  splitter: {
    flex: 1,
    alignItems: "center",
    backgroundColor: AppColors.brand.lightGrey,
    width: Units.responsiveValue(6),
  },
  innerSplitter: {
    backgroundColor: AppColors.brand.pink,
    width: Units.responsiveValue(2),
    flex: 1,
  },
  splitterDarkTheme: {
    backgroundColor: AppColors.brand.white70,
    width: 2,
    flex: 1,
    marginTop: -2,
  },
  durationSplitter: {
    backgroundColor: AppColors.brand.pink_16,
    flex: 1,
  },
  durationSplitterDarkTheme: {
    backgroundColor: AppColors.brand.white20,
  },
  durationSplitterBorder: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 3,
    backgroundColor: AppColors.brand.lightGrey,
    alignItems: "center",
  },
  durationSplitterBorderDarkTheme: {
    width: 0,
    backgroundColor: "red",
  },
  durationSplitterBorderDarkThemeSmall: {
    position: "absolute",
    top: 0,
    bottom: 0,
    height: "auto",
    backgroundColor: AppColors.brand.white50,
    width: 1,
  },
  durationSplitterLeftBorderSmall: {
    left: -0.5,
  },
  durationSplitterRightBorderSmall: {
    right: -0.5,
  },
  durationSplitterLeftBorder: {
    left: 0,
  },
  durationSplitterRightBorder: {
    right: 0,
  },
  leftTriangle: {
    position: "absolute",
    top: 0,
    left: 3,
  },
  leftTriangleDarkTheme: {
    left: 1.5,
  },
  leftTriangleBottom: {
    position: "absolute",
    bottom: 0,
    left: 3,
  },
  leftTriangleDarkThemeBottom: {
    left: 1.5,
  },
  rightTriangle: {
    position: "absolute",
    top: 0,
    right: 3,
  },
  rightTriangleDarkTheme: {
    right: 1.5,
  },
  rightTriangleBottom: {
    position: "absolute",
    bottom: 0,
    right: 3,
  },
  rightTriangleDarkThemeBottom: {
    right: 1.5,
  },
  durationSplitterInner: {
    width: 1,
    backgroundColor: AppColors.brand.pink,
    flex: 1,
  },
  durationSplitterInnerDarkTheme: {
    backgroundColor: AppColors.brand.white70,
  },
  headerNameContainer: {
    flexDirection: "column",
    paddingHorizontal: Units.rem(0.5),
  },
  headerNameContainerLightBackground: {
    paddingRight: 12,
  },
  headerNameContainerBackground: {
    backgroundColor: AppColors.brand.black,
    borderColor: "transparent",
    borderRadius: Units.responsiveValue(8),
  },
  headerName: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.warmGrey,
    lineHeight: Units.rem(1.125),
  },
  headerTimezone: {
    color: AppColors.brand.black,
    ...AppFonts.mediumMedium,
    lineHeight: Units.rem(1.125),
  },
  headerTimezoneBold: {
    ...AppFonts.mediumBold,
  },
  headerTimezoneLight: {
    color: AppColors.brand.white,
  },
  itemContainer: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: Units.rem(0.5),
    paddingBottom: Units.rem(0.4375),
    paddingTop: Units.rem(0.3125),
    borderRightWidth: 1,
    borderColor: AppColors.brand.lightGrey,
    maxHeight: Units.rem(3.5),
    backgroundColor: "white",
  },
  itemTitle: {
    ...AppFonts.bigDemibold,
    lineHeight: Units.rem(1.125),
  },

  itemSubtitle: {
    ...AppFonts.extraSmallDemibold,
    lineHeight: Units.rem(1.125),
  },
  triangle: {
    width: 12,
    // height: 8,
    marginBottom: -2,
    tintColor: AppColors.brand.pink,
  },
  triangleBottom: {
    width: 12,
    height: 8,
    marginBottom: 2,
    tintColor: AppColors.brand.pink,
    transform: [{ rotateX: "180deg" }],
  },
  triangleDarkTheme: {
    width: 12,
    height: 8,
    marginTop: 4,
    tintColor: AppColors.brand.white70,
  },
  triangleDarkThemeBottom: {
    width: 12,
    height: 8,
    marginTop: -1,
    tintColor: AppColors.brand.white70,
    transform: [{ rotateX: "180deg" }],
  },
  dateContainer: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  invisible: {
    opacity: 0,
  },
  durationMargin: {
    marginRight: 12,
  },
  daytimeIcon: {
    position: "absolute",
    top: 5.5,
    right: 5.5,
  },
  skeletonRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  skeletonColumn: {
    justifyContent: "center",
  },
  skeletonRightColumn: {
    alignItems: "flex-end",
  },
  skeletonHour: {
    height: Units.responsiveValue(48),
    width: Units.responsiveValue(56),
    marginRight: Units.responsiveValue(1),
    backgroundColor: AppColors.brand.grey2,
  },
  blinkLine: {
    width: Units.responsiveValue(50),
  },
  blinkZone: {
    ...StyleSheet.absoluteFill,
    zIndex: 1,
  },
})

export const skeletonWrapper = (isSkeletonEnabled) => ({
  top: 15,
  position: "absolute",
  width: AppSizes.screen.width,
  opacity: isSkeletonEnabled ? 1 : 0,
})

export const skeletonText = (height, width, marginTop = 0, marginLeft = 0) => ({
  height,
  width,
  marginTop,
  marginLeft,
  borderRadius: Units.responsiveValue(4),
  backgroundColor: AppColors.brand.grey2,
})

export const skeletonLine = (additionalTopMargin = 0) => ({
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: Units.responsiveValue(8),
  marginTop: Units.responsiveValue(8 + additionalTopMargin),
  marginHorizontal: Units.responsiveValue(12),
})

export const skeletonAvatar = (size, avatarsCount, index) => ({
  height: size,
  backgroundColor: AppColors.brand.grey2,
  width: size,
  borderRadius: size / 2,
  ...(avatarsCount > 1 && {
    borderWidth: Units.responsiveValue(2),
    borderColor: AppColors.brand.lightGrey,
    zIndex: avatarsCount - index,
    ...(index > 0 && {
      marginLeft: -size / 2,
    }),
  }),
})

export const zoneHoursColumn = (width) => ({
  flexDirection: "column",
  width,
})

export const currentTimeline = (fromRight, headerHeight, height) => ({
  position: "absolute",
  top: headerHeight,
  height: height + CALENDAR_BELT_HEIGHT,
  right: fromRight,
  alignItems: "center",
})

export const dateTimeline = (headerHeight, separatorHeight) => ({
  position: "absolute",
  top: headerHeight,
  left: 0,
  height: Units.responsiveValue(separatorHeight),
  justifyContent: "flex-end",
})

export const stylesContainer = {
  itemContainerNight: (isDisabled) => ({
    backgroundColor: AppColors.brand[isDisabled ? "grey157" : "purpleBlue"],
  }),
  itemContainerDaytime: (isDisabled) => ({
    backgroundColor: AppColors.brand[isDisabled ? "grey204" : "lightYellow"],
  }),
  itemContainerInBetween: (isDisabled) => ({
    backgroundColor: AppColors.brand[isDisabled ? "grey204" : "lightOrange"],
  }),
  itemContainerConflictsBg: {
    backgroundColor: AppColors.brand.white,
  },
}

export const headerAvatarMargin = (index) => ({
  marginLeft: index === 0 ? 0 : -Units.rem(1),
  zIndex: 1000 - index,
  border: "2px solid #ffffff",
  borderRadius: "50%",
})

export const stylesItem = {
  itemTextNight: (isDisabled) => ({
    color: AppColors.brand.white,
    ...(isDisabled && { opacity: 0.3 }),
  }),
  itemTextDaytime: (isDisabled) => ({
    color: AppColors.brand.black2,
    ...(isDisabled && { opacity: 0.3 }),
  }),
  itemTextInBetween: (isDisabled) => ({
    color: AppColors.brand.black2,
    ...(isDisabled && { opacity: 0.3 }),
  }),
}

export const calendarBeltWrapper = (headerHeight, zoneHeight) => ({
  position: "absolute",
  top: headerHeight + zoneHeight,
  zIndex: 100,
  height: CALENDAR_BELT_HEIGHT,
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingHorizontal: Units.rem(0.75),
})

export const calendarIcon = (hasConflict) => ({
  height: Units.rem(0.625),
  width: Units.rem(0.625),
  tintColor: hasConflict ? AppColors.brand.pink : AppColors.brand.grey157,
})

export const myCalendarText = (hasConflict) => ({
  ...AppFonts.extraSmallExtrabold,
  letterSpacing: Units.responsiveValue(0.45),
  lineHeight: Units.responsiveValue(11),
  color: hasConflict ? AppColors.brand.pink : AppColors.brand.grey157,
  paddingLeft: Units.responsiveValue(5),
})

export const disabledColor = (isNight) => ({
  tintColor: AppColors.brand[isNight ? "grey204" : "grey157"],
})

export const disabledOverlay = (disabledWidth, hourWidth, isFullTimezone) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: !isFullTimezone ? disabledWidth - hourWidth / 2 : disabledWidth,
})

export const dateSeparatorImage = (separatorHeight) => ({
  position: "absolute",
  top: -3,
  bottom: -3,
  left: -7,
  height: Units.responsiveValue(separatorHeight + 6),
})

export const calendarEventsOverlay = ({
  headerHeight,
  zoneHeight,
  zoneWidth,
}) => ({
  position: "absolute",
  top: headerHeight + zoneHeight,
  height: zoneHeight,
  left: -12 * zoneWidth,
  right: 0,
  zIndex: 100,
})

export const ZoneProps = {
  avatarSize: Units.rem(2),
}
