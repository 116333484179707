import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import user from "./userReducer"
import teeups from "./teeupsReducer"
import activeTeeup from "./activeTeeupReducer"
import createTeeup from "./teeupCreateReducer"
import invitees from "./inviteesReducer"
import requests from "./requestReducer"
import actionTypes from "actions/actionTypes"
import teeupCardReducer from "./teeupCardReducer"
import communityReducer from "./communityReducer"
import localDataReducer from "./localDataReducer"
import contactsReducer from "./contactsReducer"
import groupReducer from "./groupReducer"
import teeupsStateReducer from "./teeupsStateReducer"
import calendarReducer from "./calendarReducer"
import commonReducer from "./commonReducer"
import notificationReducer from "./notificationReducer"
import signUpWithInvitation from "./signUpWithInvitation"
import suggestReducer from "./suggestReducer"

const createRootReducer = (history) => (state, action) => {
  if (action.type === actionTypes.LOG_OUT) {
    state = undefined
  }

  return appReducer(history)(state, action)
}

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    teeups,
    activeTeeup,
    createTeeup,
    invitees,
    calendar: calendarReducer,
    requests,
    teeupCard: teeupCardReducer,
    contacts: contactsReducer,
    community: communityReducer,
    group: groupReducer,
    localData: localDataReducer,
    teeupsState: teeupsStateReducer,
    common: commonReducer,
    notification: notificationReducer,
    signUpWithInvitation: signUpWithInvitation,
    suggest: suggestReducer,
  })

export default createRootReducer
