import React, { useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link, useHistory } from "react-router-dom"
import { images } from "@utils/imageUtils"
import { setItem } from "@utils/localStorage"
import { loginEmail } from "@actions/loginActions"
import CustomTemplateSign from "pages/components/CustomTemplateSign/index"
import { TEMPLATE_TYPES } from "pages/components/CustomTemplateSign/constants"
import checkPink from "../../assets/images/selectedPink.png"
import "./index.scss"

const SignInEmail = ({ loginEmail, loginErrorMessage }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)

  const history = useHistory()

  const onSubmit = (event) => {
    event.preventDefault()
    loginEmail(email, password)
    setItem("rememberMe", rememberMe)
  }

  const redirectToSignUpPage = () => {
    history.push("/sign-up")
  }

  return (
    <CustomTemplateSign type={TEMPLATE_TYPES.signinEmail}>
      <>
        <form className="login__form" onSubmit={onSubmit}>
          <label>Username or Email Address</label>
          <div style={{ position: "relative" }}>
            <input
              className="login__input forgot__input"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <label>Password</label>
          <div style={{ position: "relative" }}>
            <input
              className="login__input forgot__input login__password"
              type={passwordShown ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {password && (
              <img
                src={images.showPasswordImg}
                style={{
                  position: "absolute",
                  right: "13px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setPasswordShown(!passwordShown)}
                alt="password icon"
              />
            )}
          </div>
          <div className="login__extra">
            <div className="login__checkbox--wrapper">
              <div
                className="login__checkbox"
                onClick={() => setRememberMe(!rememberMe)}
              >
                {rememberMe && <img src={checkPink} alt="checkPink" />}
              </div>
              <span className="login__text">Remember me</span>
            </div>
            <Link to="forgot-password">
              <span style={{ cursor: "pointer" }} className="login__text">
                Forgot Password?
              </span>
            </Link>
          </div>
          {loginErrorMessage && (
            <p className="error_message">{loginErrorMessage}</p>
          )}
          <input className="submit" type="submit" value="Log In" />
        </form>
        <span className="login__or">OR</span>
        <Link to="/sign-in">
          <span className="login__text clickable_text">
            Log in using a Social Account
          </span>
        </Link>
        <span
          className="clickable_text--signup "
          onClick={redirectToSignUpPage}
        >
          Don't have an account yet? Sign Up
        </span>
      </>
    </CustomTemplateSign>
  )
}

const mapStateToProps = (state) => {
  return {
    loginErrorMessage: state.user.loginErrorMessage,
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginEmail: bindActionCreators(loginEmail, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignInEmail)
