import React, { Component } from "react"
import Props from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Text, View } from "react-native"
import { selectTeeupActivePeople } from "@selectors/teeups"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import * as teeupActions from "@actions/teeupActions"
import { images } from "@utils/imageUtils"
import { AppColors, AppFonts } from "@theme"
import I18n from "@i18n"
import TeeupEditOrgsView from "./teeupEditOrgsView"
import closeIcon from "../../assets/images/close.svg"
import { PermissionStyles } from "./teeupSettingsStyles"
import { updateTeeupOrganisers } from "../../actions/activeTeeupActions"

class TeeupEditOrgsContainer extends Component {
  static options() {
    return {
      topBar: {
        title: {
          text: I18n.t("editOrganizers"),
          color: AppColors.brand.blackTwo,
          ...AppFonts.headerSmallBold,
        },
        subtitle: {
          color: AppColors.brand.darkGrey2,
          ...AppFonts.mediumDemibold,
        },
        noBorder: true,
        leftButtons: [
          {
            id: "close",
            text: I18n.t("systemButtons.close"),
            icon: images.close,
            color: AppColors.brand.pink,
          },
        ],
        rightButtons: [
          {
            id: "done",
            text: I18n.t("systemButtons.done"),
            color: AppColors.brand.pink,
          },
        ],
      },
    }
  }

  constructor(props) {
    super(props)
    // Navigation.events().bindComponent(this)
    const {
      teeup: { organisers },
    } = props
    const organiserIds = organisers.map(({ id }) => id)
    this.state = {
      organiserIds,
    }
  }

  componentDidMount() {
    const { organiserIds } = this.state
    // Navigation.mergeOptions(this.props.componentId, {
    //     topBar: {
    //         subtitle: {
    //             text: `${organiserIds.length} selected`,
    //         },
    //     },
    // })
  }

  navigationButtonPressed({ buttonId }) {
    const {
      teeup: { id: teeupId },
      people,
    } = this.props
    const { organiserIds } = this.state
    const organisers = organiserIds.map((id) => people[id])

    switch (buttonId) {
      case "close":
        // dismissModal(this.props.componentId)
        break
      case "done":
        // dismissModal(this.props.componentId)
        break
      default:
    }
  }

  handleToggleUser = (id) => {
    const { organiserIds } = this.state
    let newOrganiserIds = [...organiserIds]
    if (newOrganiserIds.includes(id)) {
      newOrganiserIds = newOrganiserIds.filter((orgId) => orgId !== id)
    } else {
      newOrganiserIds.push(id)
    }
    // Navigation.mergeOptions(this.props.componentId, {
    //     topBar: {
    //         subtitle: {
    //             text: `${newOrganiserIds.length} selected`,
    //         },
    //     },
    // })
    this.setState({ organiserIds: newOrganiserIds })
  }

  render() {
    const {
      people,
      teeup,
      teeup: { id: teeupId },
      closeEditOrgs,
    } = this.props
    const { organiserIds } = this.state
    const ownerId = teeup.createdby
    const organisers = organiserIds.map((id) => people[id])

    return (
      <div
        className="settings__modal settings__modal-content settings__modal-addPeople"
        onClick={(event) => event.stopPropagation()}
        style={{
          width: "100%"
        }}
      >
        <div className="settings__top">
          <Text
            style={PermissionStyles.doneButton}
            onClick={() => {
              updateTeeupOrganisers(teeupId, organisers)
              closeEditOrgs()
            }}
          >
            Done
          </Text>
          <View style={{ alignItems: "center" }}>
            <span style={{ fontSize: 17 }}>Edit Organizers</span>
            <span
              style={{ fontSize: 13 }}
            >{`${organiserIds.length} selected`}</span>
          </View>
          <img src={closeIcon} alt="close" onClick={closeEditOrgs} />
        </div>
        <TeeupEditOrgsView
          people={people}
          organiserIds={organiserIds}
          ownerId={ownerId}
          onToggleUser={this.handleToggleUser}
        />
      </div>
    )
  }
}

TeeupEditOrgsContainer.propTypes = {
  componentId: Props.string,
  teeup: Props.object.isRequired,
  people: Props.object.isRequired,
  updateTeeupOrganisers: Props.func.isRequired,
}

const mapStateToProps = (state) => {
  const teeup = selectActiveTeeup(state)
  const people = selectTeeupActivePeople(state)
  return {
    teeup,
    people,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...teeupActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeeupEditOrgsContainer)
