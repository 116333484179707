import React, { useState, useMemo, useEffect, useRef } from "react"
import { MentionsInput, Mention } from "react-mentions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash"
import { ReactSVG } from "react-svg"
import ReactTooltip from "react-tooltip"

import "./index.scss"
import { selectTeeupPeople } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { selectActiveMessage } from "@selectors/teeupCard"
import { selectActiveTeeupId, selectActiveTeeup } from "@selectors/activeTeeup"
import { images } from "@utils/imageUtils"
import { uploadFile } from "@actions/activeTeeupActions"
import { sendMessage } from "middlewares/backendMiddleware"
import { messageTypeIds } from "@config/mappings"
import FilePreview from "./FilePreview"
import { isImageFile } from "@utils/fileUtils"
import mentionsPink from "../../../assets/images/mentions-pink.svg"
import everyoneAvatar from "../../../assets/images/everyoneAvatar.png"
import { setReplyMessage } from "@actions/commonActions"
import { updateUserStatus } from "@actions/requestActions"
import { teeupUserStatusPriorities, teeupUserStatusKeys } from "@config/enums"
import {
  fetchTeeupUsersById,
  fetchGameplanOptions,
  gotTeeupParts,
  updateGameplan,
} from "@actions/teeupActions"
import { setActiveTextMessage } from "@actions/teeupCardActions"
import FileCardCarousel from "./FileCardCarousel"

import Avatar from "@ui/avatar"

const ChatInput = ({
  createMessage,
  users,
  currentUserId,
  activeTeeupId,
  setReplyMessage,
  activeMessage,
  updateUserStatus,
  gotTeeupParts,
  activeTeeup,
  inputRef,
  setActiveTextMessage,
  newDesign,
}) => {
  const [text, setText] = useState("")
  const [files, setFiles] = useState([])
  const [isShift, setIsShift] = useState(false)
  const inputEl = useRef(null)
  const infoRef = useRef()
  const fileRef = useRef()

  useEffect(() => {
    setReplyMessage(null)
    setText("")
  }, [activeTeeupId])

  useEffect(() => {
    if (activeMessage) setText(text + " " + activeMessage)
  }, [activeMessage])

  const resetState = () => {
    setText("")
    setFiles([])
    setActiveTextMessage(null)
  }

  const handleSubmit = (e) => {
    const statusId = teeupUserStatusPriorities.joined

    e.preventDefault()
    createMessage(text)

    // if (activeTeeup.userStatus !== teeupUserStatusKeys.joined) {
    //   updateUserStatus(activeTeeupId, statusId).then(async (error) => {
    //     const teeupUsers = await fetchTeeupUsersById(activeTeeupId)
    //     gotTeeupParts([teeupUsers])
    //     updateGameplan(activeTeeupId, await fetchGameplanOptions(activeTeeupId))
    //   })
    // }

    files.forEach((file) => {
      if (isImageFile(file.type)) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener("load", function () {
          const url = reader.result
          sendMessage(url, messageTypeIds.image)
        })
      } else {
        uploadFile(activeTeeupId, file)
      }
    })

    resetState()
  }

  const onFileChoose = (event) => {
    if (files.length >= 10) {
      alert("Maximum number of files for upload is 10.")
    } else {
      const newFiles = Array.from(event.target.files)
      setFiles([...files, ...newFiles])
    }
  }

  const usersDisplay = useMemo(() => {
    const usersForMentions = Object.values(users)
      .filter(({ id, status }) => id !== currentUserId && status !== "invited")
      .map(({ id, username, name, avatar }) => ({
        id,
        display: username || name,
        avatar,
      }))

    return [
      {
        id: -1,
        display: "Everybody",
        avatar: everyoneAvatar,
      },
      ...usersForMentions,
    ]
  }, [users])

  const showSendButton = () => !_.isEmpty(text) || !_.isEmpty(files)

  const removeFile = (name) => {
    const updatedFiles = files.filter((file) => file.name !== name)
    setFiles(updatedFiles)
  }

  const handleEnterPress = (e) => {
    if (!isShift && e.keyCode === 13) handleSubmit(e)
    if (e.keyCode === 17) setIsShift(true)
    if (isShift && e.keyCode === 13) {
      setText(text + "\r\n")
      setIsShift(false)
    } else return
  }

  const renderInput = () => (
    <form
      onKeyDown={(e) => handleEnterPress(e)}
      onKeyUp={() => setIsShift(false)}
    >
      <MentionsInput
        // inputRef={inputEl}
        inputRef={inputRef}
        className="chatinput"
        markup="@{{__type__||__id__||__display__}}"
        value={text}
        onChange={(e, newValue, newPlainTextValue, mentions) => {
          setText(newPlainTextValue)
        }}
        placeholder="Say something..."
        allowSuggestionsAboveCursor
      >
        <Mention
          trigger="@"
          data={usersDisplay}
          appendSpaceOnAdd
          displayTransform={(id, display) => `@${display}`}
          renderSuggestion={(suggestion) => {
            const { avatar, display } = suggestion
            return (
              <>
                <Avatar
                  size={40}
                  borderWidth={0}
                  imageUrl={avatar}
                  hiddenCount={false}
                  fromMentions
                />
                <span className="mention-name">{display}</span>
              </>
            )
          }}
        />
      </MentionsInput>
    </form>
  )

  const triggerMentions = () => {
    setText((prevText) => prevText + " @")
    inputRef.current.focus()
  }

  if (newDesign) {
    return (
      <div className="chat-input">
        <div className="chat-input__files" ref={fileRef}>
          {!!files.length && (
            <FileCardCarousel
              parentWidth={fileRef.current.clientWidth}
              files={files}
              removeFile={removeFile}
            />
          )}
        </div>
        <div className="chat-input__text-input">{renderInput()}</div>
        <div className="chat-input__actions">
          <label
            className="chat-input__action-button"
            data-tip={"Attach Files"}
            data-for="information-tooltip"
            htmlFor="media-input"
          >
            <ReactSVG src={images.ndPaperClipIcon} />
          </label>
          <input id="media-input" type="file" onInput={onFileChoose} />
          <div
            className="chat-input__action-button"
            data-tip={"Mention someone"}
            data-for="information-tooltip"
            onClick={triggerMentions}
            ref={infoRef}
          >
            <ReactSVG src={images.ndMentionIcon} />
          </div>
          <div
            className="chat-input__action-button"
            data-tip={
              '<div>Send Now <br><div style="color: #a9b0b9; display: flex;">Press<div style="color: #ffffff; background-color: #000000; opacity: 1; border-radius: 4px; width: fit-content; padding: 0 2px; margin-left: 2px;">Enter</div></div></div>'
            }
            data-for="information-tooltip"
            onClick={handleSubmit}
          >
            <ReactSVG src={images.ndPaperPlaneIcon} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="chatinput__wrapper">
      <form className="chatinput__form" onSubmit={handleSubmit}>
        <div className="file-upload">
          <label htmlFor="media-input">
            <img
              src={images.openAttachments}
              className="file-upload__plus_image"
              alt=""
              ref={infoRef}
              data-tip={"Attach Files"}
              data-for="information-tooltip"
              onMouseOver={(e) => {
                e.stopPropagation()
                ReactTooltip.show(infoRef.current)
              }}
              onMouseLeave={(e) => {
                e.stopPropagation()
                ReactTooltip.hide(infoRef.current)
              }}
            />
          </label>
          <input id="media-input" type="file" onChange={onFileChoose} />
        </div>
        <div className="input_wrapper">
          <div className="files_wrapper">
            {files.map((file) => (
              <FilePreview
                key={`${file.name}-${file.size}`}
                file={file}
                removeFile={removeFile}
              />
            ))}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            {renderInput()}
          </form>
          {showSendButton() && (
            <ReactSVG
              className="send-button"
              src={images.send}
              onClick={handleSubmit}
            />
          )}
          <img
            className="mention-button"
            src={images.mentionConversation}
            onClick={triggerMentions}
            ref={infoRef}
            data-tip={"Mention User"}
            data-for="information-tooltip"
            onMouseOver={(e) => {
              e.stopPropagation()
              ReactTooltip.show(infoRef.current)
            }}
            onMouseLeave={(e) => {
              e.stopPropagation()
              ReactTooltip.hide(infoRef.current)
            }}
          />
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    users: selectTeeupPeople(state),
    currentUserId: selectUserId(state),
    activeTeeupId: selectActiveTeeupId(state),
    activeMessage: selectActiveMessage(state),
    activeTeeup: selectActiveTeeup(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  setReplyMessage: bindActionCreators(setReplyMessage, dispatch),
  updateUserStatus: bindActionCreators(updateUserStatus, dispatch),
  gotTeeupParts: bindActionCreators(gotTeeupParts, dispatch),
  updateGameplan: bindActionCreators(updateGameplan, dispatch),
  setActiveTextMessage: bindActionCreators(setActiveTextMessage, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatInput)
