import React from "react"
import classNames from "classnames"
import { ReactSVG } from "react-svg"

import { VOTE_OPTIONS, useReactions } from "../../../../TeeUpPageContext"
import { images } from "@utils/imageUtils"
import { selectTeeupUserById } from "@selectors/teeups"

import "./gamePlanSuggestion.scss"
import { useSelector } from "react-redux"

const GamePlanSuggestion = ({ overview = "", details = "", suggestion }) => {
  const { upVote, downVote, userVote, onDownVote, onUpVote } = useReactions(
    suggestion?.id
  )
  const user = useSelector(selectTeeupUserById(suggestion?.createdBy))

  return (
    <div className="game-plan-suggestion">
      <div className="game-plan-suggestion__header">
        {user && (
          <span className="game-plan-suggestion__header-username">{`@${user.username}`}</span>
        )}
        {false && (
          <span className="game-plan-suggestion__header-popular">Popular</span>
        )}
      </div>
      <div className="game-plan-suggestion__content">
        <div className="game-plan-suggestion__info">
          <div className="game-plan-suggestion__overview">{overview}</div>
          <div className="game-plan-suggestion__details">
            {details.length > 25 ? details.slice(0, 25) + "..." : details}
          </div>
        </div>
        <div className="game-plan-suggestion__voting">
          <div
            className={classNames(
              "game-plan-suggestion__voting-button game-plan-suggestion__voting-up-vote",
              { selected: VOTE_OPTIONS.upVote === userVote }
            )}
            onClick={onUpVote}
          >
            <ReactSVG
              src={images.ndThumbUpYellowIcon}
              className="voting-button-thumb"
            />
            <span>{upVote?.reactedBy?.length || 0}</span>
          </div>
          <div
            className={classNames(
              "game-plan-suggestion__voting-button game-plan-suggestion__voting-down-vote",
              { selected: VOTE_OPTIONS.downVote === userVote }
            )}
            onClick={onDownVote}
          >
            <ReactSVG
              src={images.ndThumbDownYellowIcon}
              className="voting-button-thumb"
            />
            <span>{downVote?.reactedBy?.length || 0}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GamePlanSuggestion
