import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactDOM from "react-dom"
import { View, Text, ScrollView } from "react-native"
import { Link } from "react-router-dom"
import cn from "classnames"

import { selectToken, selectUserInfo } from "@selectors/user"
import {
  createUserCM,
  getUserCM,
  changeEmailVerificationRequest,
  deleteUserCM,
} from "@actions/loginActions"
import strings from "@i18n"
import { images } from "@utils/imageUtils"
import { styles } from "./loginDetailsStyles"
import VerifyCode from "./VerifyCode"

const ClickableRowCM = ({
  primaryText,
  secondaryText,
  onClick,
  action,
  iconImage,
  emailMethod,
  token,
  type,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState("")
  const [popup, setPopup] = useState(false)
  const [error, setError] = useState("")
  const [contactMechanismVerificationId, setContactMechanismVerificationId] =
    useState()
  const [addedCM, setAddedCM] = useState()

  const onChangeHandler = (e) => {
    setValue(e.target.value)
  }

  const typeId = type === "email" ? 7 : 8

  const onDoneHandler = () => {
    action(typeId, value, token)
      .then((res) => {
        // console.log(res, 'response')
        // setCmData(res.data)
        setAddedCM(res.data)
        changeEmailVerificationRequest(res.data.id, token)
          .then((response) => {
            setContactMechanismVerificationId(
              response.data.contactMechanismVerificationId
            )
            setPopup(true)
          })
          .catch((error) => {
            console.log("error", error)
            setError("The contact is invalid")
          })
      })
      .catch((error) => {
        // console.log('CM error', error)
      })
  }

  const deleteUser = () => {
    deleteUserCM(addedCM.id, token, "/recovery")
  }

  const ChangePasswordBtn = {
    marginLeft: "auto",
    marginRight: "20px",
    fontFamily: "greycliffcf-medium-salt",
    fontSize: "15px",
    lineHeight: "22px",
    letterSpacing: "-0.3px",
    color: "#f42862",
    cursor: "pointer",
  }

  return (
    <>
      {popup &&
        ReactDOM.createPortal(
          <VerifyCode
            accessToken={token}
            setPopup={setPopup}
            type={"email"}
            value={value}
            resendCodeFn={onDoneHandler}
            contactMechanismVerificationId={contactMechanismVerificationId}
            setEditMode={setEditMode}
            deleteUser={deleteUser}
          />,
          document.getElementById("modal-root")
        )}
      <div className="clickable__row">
        {iconImage && (
          <img className="clickable__row_img" src={iconImage} alt="img" />
        )}
        <span className="clickable__title">{primaryText}</span>
        <div
          className="clickable__wrapper"
          style={{ marginLeft: !emailMethod ? "26px" : "0px" }}
        >
          {!editMode && (
            <span className="clickable__value">
              {secondaryText ? secondaryText : "Not Set"}
            </span>
          )}
          {!editMode && (
            <span
              className="clickable__action"
              onClick={() => setEditMode(true)}
            >
              {secondaryText ? "Change" : "Set"}
            </span>
          )}
          {editMode && (
            <>
              <input
                className="clickable__input"
                placeholder={secondaryText ? secondaryText : "Not Set"}
                value={value}
                onChange={(e) => onChangeHandler(e)}
              />
              <div className="clickable__buttonsWrapper">
                <button
                  className="clickable__button clickable__button_cancel"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </button>
                <button
                  disabled={value.length < 6}
                  className={cn("clickable__button clickable__button_done", {
                    "forgot__button--disabled": value.length < 6,
                  })}
                  // className="clickable__button clickable__button_done"
                  onClick={onDoneHandler}
                >
                  Done
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
const ClickableRow = ({
  primaryText,
  secondaryText,
  onClick,
  action,
  iconImage,
  emailMethod,
  token,
  userInfo,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState("")

  const onChangeHandler = (e) => {
    setValue(e.target.value)
  }

  const ChangePasswordBtn = {
    marginLeft: "auto",
    marginRight: "20px",
    fontFamily: "greycliffcf-medium-salt",
    fontSize: "15px",
    lineHeight: "22px",
    letterSpacing: "-0.3px",
    color: "#f42862",
    cursor: "pointer",
  }

  return (
    <div className="clickable__row">
      {iconImage && (
        <img className="clickable__row_img" src={iconImage} alt="img" />
      )}
      <span className="clickable__title">{primaryText}</span>
      <div
        className="clickable__wrapper"
        style={{ marginLeft: !emailMethod ? "26px" : "0px" }}
      >
        {!editMode && (
          <span className="clickable__value">
            {secondaryText ? secondaryText : "Not Set"}
          </span>
        )}
        {!editMode && userInfo.loginType === "email" && (
          <Link style={ChangePasswordBtn} to="change-password">
            {secondaryText && "Change Password"}
          </Link>
        )}
        {!editMode && (
          <span className="clickable__action" onClick={() => setEditMode(true)}>
            {secondaryText ? "Unlink" : "Set"}
          </span>
        )}
        {editMode && (
          <>
            <input
              className="clickable__input"
              placeholder={secondaryText ? secondaryText : "Not Set"}
              value={value}
              onChange={(e) => onChangeHandler(e)}
            />
            <div className="clickable__buttonsWrapper">
              <button
                className="clickable__button clickable__button_cancel"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </button>
              <button className="clickable__button clickable__button_done">
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const ClickableRowLink = ({
  primaryText,
  secondaryText,
  onClick,
  actions,
  iconImage,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState("")

  const onChangeHandler = (e) => {
    setValue(e.target.value)
  }

  return (
    <div className="clickable__row">
      <img className="clickable__row_img" src={iconImage} alt="img" />
      <span className="clickable__title">{primaryText}</span>
      <div className="clickable__wrapper">
        {!editMode && (
          <span className="clickable__value">
            {secondaryText ? secondaryText : "Not Set"}
          </span>
        )}
        {!editMode && (
          <span className="clickable__action" onClick={() => setEditMode(true)}>
            {secondaryText === "Unlink" ? secondaryText : "Link"}
          </span>
        )}
        {editMode && (
          <>
            <input
              className="clickable__input"
              placeholder={secondaryText}
              value={value}
              onChange={(e) => onChangeHandler(e)}
            />
            <div className="clickable__buttonsWrapper">
              <button
                className="clickable__button clickable__button_cancel"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </button>
              <button className="clickable__button clickable__button_done">
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const ClickableRowLogout = ({ primaryText, onPress, iconImage }) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      className="clickable__row"
      onClick={onPress}
    >
      <img className="clickable__row_img" src={iconImage} alt="img" />
      <span className="clickable__title">{primaryText}</span>
    </div>
  )
}

const LoginDetailsView = ({
  hideChangePassword,
  signInMethodTitle,
  signInMethod,
  signInMethodNew,
  onLogout,
  syncedTwitter,
  syncedGmail,
  syncedFacebook,
  token,
  userInfo,
}) => {
  const [contactMechanisms, setContactMechanisms] = useState([])

  useEffect(() => {
    getUserCM(token)
      .then((res) => {
        // console.log(res.data.contactMechanisms, 'infooo');
        setContactMechanisms(res.data.contactMechanisms)
      })
      .catch((err) => {
        console.log(err, "error")
      })
  }, [])

  let recoveryEmail = ""
  let recoveryPhone = ""

  contactMechanisms.map((cm) => {
    if (cm.typeId === 7) recoveryEmail = cm.value
    if (cm.typeId === 8) recoveryPhone = cm.value
  })

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* <View style={styles.splitter} /> */}
      {/* <Text style={styles.header}>
                {strings.loginDetails.loginSettingSubtitle}
            </Text> */}
      {/* <div className="settings__rowWrapper">
                <ClickableRow
                    iconImage={images.signIn}
                    primaryText={[strings.loginDetails.signInMethod.title]}
                    secondaryText={[`${signInMethodTitle}: ${signInMethod}`]}
                    actionButton={<ArrowButton />}
                />
                {!hideChangePassword && (
                    <ClickableRow
                        iconImage={images.changePassword}
                        primaryText={[strings.loginDetails.changePassword.title]}
                        actionButton={<ArrowButton />}
                    />
                )}
            </div> */}

      <Text style={[styles.header, styles.headerBold, { paddingTop: "12px" }]}>
        Sign in methods
      </Text>

      <div className="settings__rowWrapper">
        <ClickableRow
          iconImage={images.envelopSettings}
          primaryText={["Email"]}
          secondaryText={[
            // syncedGmail || strings.loginDetails.accNotLinked,
            signInMethodNew,
          ]}
          actions={""}
          emailMethod={true}
          userInfo={userInfo}
          // actionButton={<ArrowButton />}
        />
        <ClickableRowLink
          iconImage={images.googleSettings}
          primaryText={["Google"]}
          secondaryText={[syncedGmail || strings.loginDetails.accNotLinked]}
          actions={""}
          // actionButton={<ArrowButton />}
        />

        <ClickableRowLink
          iconImage={images.twitterSettings}
          primaryText={["Twitter"]}
          secondaryText={[syncedTwitter || strings.loginDetails.accNotLinked]}
          actions={""}
          // actionButton={<ArrowButton />}
        />

        <ClickableRowLink
          iconImage={images.facebookSettings}
          primaryText={["Facebook"]}
          secondaryText={[syncedFacebook || strings.loginDetails.accNotLinked]}
          actions={""}
          // actionButton={<ArrowButton />}
        />
      </div>

      <Text
        style={[
          styles.header,
          styles.headerBold,
          { paddingTop: "12px", marginTop: "10px" },
        ]}
      >
        Account Recovery
      </Text>

      <div className="settings__rowWrapper">
        <ClickableRowCM
          primaryText={["Mobile Phone"]}
          action={createUserCM}
          token={token}
          type={"phone"}
          secondaryText={recoveryPhone}
          // secondaryText={[
          //     syncedFacebook || strings.loginDetails.accNotLinked,
          // ]}
          // actionButton={<ArrowButton />}
        />
        <ClickableRowCM
          primaryText={["Email"]}
          action={createUserCM}
          token={token}
          type={"email"}
          secondaryText={recoveryEmail}
          // secondaryText={[
          //     syncedFacebook || strings.loginDetails.accNotLinked,
          // ]}
          // actionButton={<ArrowButton />}
        />
      </div>

      <Text style={styles.ask}>
        We ask you to type in account recovery methods and verify them again to
        be sure that you can recover your account safely
      </Text>

      <View style={styles.splitter} />

      <div className="settings__rowWrapper" style={{ borderRadius: "unset" }}>
        <ClickableRowLogout
          iconImage={images.logout}
          primaryText={[strings.loginDetails.logout.title]}
          onPress={onLogout}
        />
      </div>
    </ScrollView>
  )
}

const mapStateToProps = (state) => ({
  token: selectToken(state),
  userInfo: selectUserInfo(state),
})

LoginDetailsView.propTypes = {
  hideChangePassword: PropTypes.bool,
  signInMethodTitle: PropTypes.string.isRequired,
  signInMethod: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  syncedTwitter: PropTypes.string,
  syncedGmail: PropTypes.string,
  syncedFacebook: PropTypes.string,
}

export default connect(mapStateToProps, null)(LoginDetailsView)
