import React, { Component } from "react"
import {
  FlatList,
  Platform,
  Dimensions,
  ActivityIndicator,
  View,
} from "react-native"
import PropTypes from "prop-types"
import XDate from "xdate"

import { xdateToData, parseDate } from "../interface"
import styleConstructor from "./style"
import dateutils from "../dateutils"
import CalendarListItem from "./item"
import CalendarHeader from "../calendar/header/index"
import { STATIC_HEADER } from "../testIDs"
import moment from "moment"
import { findPositionInExistingDatesArray } from "@utils/calendarUtils"
import { getDateWithTimezone } from "@utils/dateUtils"

/**
 * @description: Calendar List component for both vertical and horizontal calendars
 * @extends: Calendar
 * @extendslink: docs/Calendar
 * @example: https://github.com/wix/react-native-calendars/blob/master/example/src/screens/calendarsList.js
 * @gif: https://github.com/wix/react-native-calendars/blob/master/demo/calendar-list.gif
 */
class CalendarList extends Component {
  static displayName = "CalendarList"

  static propTypes = {
    // ...Calendar.propTypes,
    /** Max amount of months allowed to scroll to the past. Default = 50 */
    pastScrollRange: PropTypes.number,
    /** Max amount of months allowed to scroll to the future. Default = 50 */
    futureScrollRange: PropTypes.number,
    /** Enable or disable scrolling of calendar list */
    scrollEnabled: PropTypes.bool,
    /** Enable or disable vertical scroll indicator. Default = false */
    showScrollIndicator: PropTypes.bool,
    /** When true, the calendar list scrolls to top when the status bar is tapped. Default = true */
    scrollsToTop: PropTypes.bool,
    /** Enable or disable paging on scroll */
    pagingEnabled: PropTypes.bool,
    /** Whether the scroll is horizontal */
    horizontal: PropTypes.bool,
    /** Used when calendar scroll is horizontal, default is device width, pagination should be disabled */
    calendarWidth: PropTypes.number,
    /** Dynamic calendar height */
    calendarHeight: PropTypes.number,
    /** Should Keyboard persist taps */
    keyboardShouldPersistTaps: PropTypes.oneOf(["never", "always", "handled"]),
    /** Style for the List item (the calendar) */
    // calendarStyle: PropTypes.oneOfType([
    //     PropTypes.object,
    //     PropTypes.number,
    //     PropTypes.array,
    // ]),
    /** Whether to use static header that will not scroll with the list (horizontal only) */
    staticHeader: PropTypes.bool,
    /** A custom key extractor for the generated calendar months */
    keyExtractor: PropTypes.func,
  }

  static defaultProps = {
    horizontal: false,
    calendarWidth: 370,
    calendarHeight: 360,
    pastScrollRange: 50,
    futureScrollRange: 50,
    showScrollIndicator: false,
    scrollEnabled: true,
    scrollsToTop: false,
    removeClippedSubviews: Platform.OS === "android" ? false : true,
    keyExtractor: (item, index) => String(index),
  }

  constructor(props) {
    super(props)

    this.style = styleConstructor(props.theme)

    this.viewabilityConfig = {
      itemVisiblePercentThreshold: 20,
    }

    const rows = []
    const texts = []
    const date = parseDate(props.current) || XDate()

    for (
      let i = 0;
      i <= this.props.pastScrollRange + this.props.futureScrollRange;
      i++
    ) {
      const rangeDate = date
        ?.clone()
        .addMonths(i - this.props.pastScrollRange, true)
      const rangeDateStr = rangeDate.toString("MMM yyyy")
      texts.push(rangeDateStr)
      /*
       * This selects range around current shown month [-0, +2] or [-1, +1] month for detail calendar rendering.
       * If `this.pastScrollRange` is `undefined` it's equal to `false` or 0 in next condition.
       */
      if (
        (this.props.pastScrollRange - 1 <= i &&
          i <= this.props.pastScrollRange + 1) ||
        (!this.props.pastScrollRange && i <= this.props.pastScrollRange + 2)
      ) {
        rows.push(rangeDate)
      } else {
        rows.push(rangeDateStr)
      }
    }

    this.state = {
      rows,
      texts,
      openDate: date,
      currentMonth: parseDate(props.current),
      currentWeekDay: moment(props.current).day(),
      isCurrentMonth: 0,
      isCurrentWeek: 0,
      expandCalendar: false,
      counterWeek: 0,
      isWeek: [],
      isWeekIndex: 0,
      initialWeekIndex: 0,
    }

    this.onViewableItemsChangedBound = this.onViewableItemsChanged.bind(this)
    this.renderCalendarBound = this.renderCalendar.bind(this)
    this.getItemLayout = this.getItemLayout.bind(this)
    this.onLayout = this.onLayout.bind(this)
  }

  updateWeek = (value) => {
    this.setState({
      isWeek: value,
    })
    let initialWeek = this.state.isWeek.findIndex(
      (week) =>
        "today" ===
        week.props.children.filter(
          (day) => day.props.children.props.state === "today"
        )[0]?.props.children.props.state
    )
    if (initialWeek > 0) {
      this.setState({
        isWeekIndex: initialWeek,
      })
    }
  }

  onLayout(event) {
    if (this.props.onLayout) {
      this.props.onLayout(event)
    }
  }

  scrollToDay(d, offset, animated) {
    const day = parseDate(d)
    const diffMonths = Math.round(
      this.state.openDate
        ?.clone()
        .setDate(1)
        .diffMonths(day?.clone().setDate(1))
    )
    const size = this.props.horizontal
      ? this.props.calendarWidth
      : this.props.calendarHeight
    let scrollAmount =
      size * this.props.pastScrollRange + diffMonths * size + (offset || 0)

    if (!this.props.horizontal) {
      let week = 0
      const days = dateutils.page(day, this.props.firstDay)
      for (let i = 0; i < days.length; i++) {
        week = Math.floor(i / 7)
        if (dateutils.sameDate(days[i], day)) {
          scrollAmount += 46 * week
          break
        }
      }
    }
    this.listView.scrollToOffset({ offset: scrollAmount, animated })
  }

  scrollToMonth(m) {
    const month = parseDate(m)
    const scrollTo = month || this.state.openDate
    let diffMonths = Math.round(
      this.state.openDate
        ?.clone()
        .setDate(1)
        .diffMonths(scrollTo?.clone().setDate(1))
    )
    const size = this.props.horizontal
      ? this.props.calendarWidth
      : this.props.calendarHeight
    const scrollAmount = size * this.props.pastScrollRange + diffMonths * size

    this.listView.scrollToOffset({ offset: scrollAmount, animated: false })
  }

  UNSAFE_componentWillReceiveProps(props) {
    const current = parseDate(this.props.current)
    const nextCurrent = parseDate(props.current)

    if (nextCurrent && current && nextCurrent.getTime() !== current.getTime()) {
      this.scrollToMonth(nextCurrent)
    }

    const rowclone = this.state.rows
    const newrows = []

    for (let i = 0; i < rowclone.length; i++) {
      let val = this.state.texts[i]
      if (rowclone[i].getTime) {
        val = rowclone[i]?.clone()
        val.propbump = rowclone[i].propbump ? rowclone[i].propbump + 1 : 1
      }
      newrows.push(val)
    }
    this.setState({
      rows: newrows,
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.context.date &&
      this.props.current &&
      prevProps.context.date !== this.props.context.date
    ) {
      const day = moment(this.props.context.date)
      const initialDay = moment(this.props.current)
      const weekDay = day.day()
      const initialWeek = initialDay.week()
      const currentWeek = day.week()

      if (
        weekDay !== this.state.currentWeekDay ||
        initialWeek !== currentWeek
      ) {
        if (initialWeek !== currentWeek) {
          this.setState({
            currentWeekDay: 0,
          })
        } else {
          if (this.props.current === this.props.context.date)
            this.setState({
              currentWeekDay: weekDay,
            })
        }
      }
    }
  }

  onViewableItemsChanged({ viewableItems }) {
    function rowIsCloseToViewable(index, distance) {
      for (let i = 0; i < viewableItems.length; i++) {
        if (Math.abs(index - parseInt(viewableItems[i].index)) <= distance) {
          return true
        }
      }
      return false
    }

    const rowclone = this.state.rows
    const newrows = []
    const visibleMonths = []

    for (let i = 0; i < rowclone.length; i++) {
      let val = rowclone[i]
      const rowShouldBeRendered = rowIsCloseToViewable(i, 1)

      if (rowShouldBeRendered && !rowclone[i].getTime) {
        val = this.state.openDate
          ?.clone()
          .addMonths(i - this.props.pastScrollRange, true)
      } else if (!rowShouldBeRendered) {
        val = this.state.texts[i]
      }
      newrows.push(val)
      if (rowIsCloseToViewable(i, 0)) {
        visibleMonths.push(xdateToData(val))
      }
    }

    if (this.props.onVisibleMonthsChange) {
      this.props.onVisibleMonthsChange(visibleMonths)
    }

    this.setState({
      rows: newrows,
      currentMonth: parseDate(visibleMonths[0]),
    })
  }

  renderCalendar({ item }) {
    const {
      rightFillerWidth,
      leftFillerWidth,
      isCalendarExpanded,
      calendarHeight,
    } = this.props
    const { isCurrentMonth, counterWeek } = this.state
    const isTemporalCustom = false
    return (
      <CalendarListItem
        scrollToMonth={this.scrollToMonth.bind(this)}
        addMonth={this.addMonth}
        item={item}
        {...this.props}
        {...this.state}
        isTemporalCustom={isTemporalCustom}
        calendarHeight={isTemporalCustom ? 100 : calendarHeight}
        calendarWidth={isTemporalCustom ? 350 : 370}
        style={this.props.calendarStyle}
        rightFillerWidth={rightFillerWidth}
        leftFillerWidth={leftFillerWidth}
        isCurrentMonth={isCurrentMonth}
        isCalendarExpanded={isCalendarExpanded}
        counterWeek={counterWeek}
        updateWeek={this.updateWeek}
        isMonth={isCurrentMonth}
      />
    )
  }

  getItemLayout(data, index) {
    return {
      length: this.props.horizontal
        ? this.props.calendarWidth
        : this.props.calendarHeight,
      offset:
        (this.props.horizontal
          ? this.props.calendarWidth
          : this.props.calendarHeight) * index,
      index,
    }
  }

  getMonthIndex(month) {
    let diffMonths =
      this.state.openDate.diffMonths(month) + this.props.pastScrollRange
    return diffMonths
  }

  addMonth = (count) => {
    if (count > 0) {
      this.setState({
        counterWeek: 0,
        isCurrentMonth: this.state.isCurrentMonth + 1,
      })
    } else {
      if (this.state.isCurrentMonth > 1) {
        this.setState({
          counterWeek: this.state.isWeek.length - 1,
          isCurrentMonth: this.state.isCurrentMonth - 1,
        })
      } else {
        this.setState({
          counterWeek: this.state.isWeek.length - 1 - this.state.isWeekIndex,
          isCurrentMonth: this.state.isCurrentMonth - 1,
        })
      }
    }
    this.updateMonth(this.state.currentMonth?.clone().addMonths(count, true))
  }

  updateMonth(day, doNotTriggerListeners) {
    if (
      day.toString("yyyy MM") === this.state.currentMonth.toString("yyyy MM")
    ) {
      return
    }

    this.setState(
      {
        currentMonth: day?.clone(),
      },
      () => {
        this.scrollToMonth(this.state.currentMonth)

        if (!doNotTriggerListeners) {
          const currMont = this.state.currentMonth?.clone()
          if (this.props.onMonthChange) {
            this.props.onMonthChange(xdateToData(currMont))
          }
          if (this.props.onVisibleMonthsChange) {
            this.props.onVisibleMonthsChange([xdateToData(currMont)])
          }
        }
      }
    )
  }

  getNewHorizontalScrollIndex = (prevDate, newDate) => {
    const prevDateObj = getDateWithTimezone(prevDate)
    const selectedDateObj = getDateWithTimezone(newDate)
    if (selectedDateObj && !prevDateObj.isSame(selectedDateObj, "day")) {
      const newIndex = findPositionInExistingDatesArray(
        this.state.datesArray,
        selectedDateObj,
        this.state.activeScreen
      )
      return newIndex
    }
    return this.state.currentDayIndex
  }

  handlePressArrowRight = () => {
    if (!this.props.isCalendarExpanded) {
      this.setState((state) => {
        return { counterWeek: state.counterWeek + 1 }
      })
    } else {
      this.setState({
        currentWeekDay: 0,
      })
      this.props.onPressArrowRight()
      this.setState({ isCurrentMonth: this.state.isCurrentMonth + 1 })
    }
  }

  handlePressArrowLeft = () => {
    if (!this.props.isCalendarExpanded) {
      if (this.state.isCurrentMonth > 0 && this.state.counterWeek === 0) {
        this.addMonth(-1)
      } else {
        this.setState((state) => {
          return { counterWeek: state.counterWeek - 1 }
        })
      }
    } else {
      this.props.onPressArrowLeft()
      this.setState({ isCurrentMonth: this.state.isCurrentMonth - 1 })
    }
  }

  handlePressExpandArrow = () => {
    this.setState({ expandCalendar: !this.state.expandCalendar })
  }

  renderStaticHeader() {
    const {
      staticHeader,
      horizontal,
      renderExpandArrow,
      isExpand,
      handleToggleCalendar,
      isCalendarExpanded,
      isTemporalCustom,
      showIndicator,
      headerStyle,
      theme,
      hideArrows,
      firstDay,
      renderArrow,
      monthFormat,
      hideDayNames,
      showWeekNumbers,
      disableArrowLeft,
      expandIconName,
      onWeekDayPress,
      enableOnWeekDayPress,
      changePosition,
    } = this.props
    const { currentWeekDay, counterWeek, isCurrentMonth } = this.state
    const useStaticHeader = staticHeader && horizontal

    if (useStaticHeader) {
      let indicator
      if (showIndicator) {
        indicator = <ActivityIndicator color={theme?.indicatorColor} />
      }
      return (
        <CalendarHeader
          style={[
            this.style.staticHeader,
            headerStyle,
            { backgroundColor: "F0F2F5" },
          ]} // backgroundColor: f0f0f0
          isTemporalCustom={isTemporalCustom}
          month={this.state.currentMonth}
          addMonth={this.addMonth}
          showIndicator={indicator}
          theme={theme}
          hideArrows={hideArrows}
          firstDay={firstDay}
          renderArrow={renderArrow}
          monthFormat={monthFormat}
          hideDayNames={hideDayNames}
          weekNumbers={showWeekNumbers}
          onPressArrowLeft={this.handlePressArrowLeft}
          onPressArrowRight={this.handlePressArrowRight}
          renderExpandArrow={renderExpandArrow}
          disableArrowLeft={
            isCalendarExpanded
              ? disableArrowLeft
              : isCurrentMonth === 0 && counterWeek === 0
          }
          expandIconName={expandIconName}
          onWeekDayPress={onWeekDayPress}
          enableOnWeekDayPress={enableOnWeekDayPress}
          changePosition={changePosition}
          testID={STATIC_HEADER}
          currentWeekDay={currentWeekDay}
          isExpand={isExpand}
          handleToggleCalendar={handleToggleCalendar}
          isCalendarExpanded={isCalendarExpanded}
        />
      )
    }
  }

  renderDaysFlatList() {
    const {
      isTemporalCustom,
      style,
      removeClippedSubviews,
      horizontal,
      pagingEnabled,
      showScrollIndicator,
      scrollEnabled,
      keyExtractor,
      scrollsToTop,
      onEndReachedThreshold,
      onEndReached,
      keyboardShouldPersistTaps,
    } = this.props

    const flatStyle = [this.style.container, style]
    if (isTemporalCustom)
      flatStyle.push({
        maxWidth: "370px",
        marginHorizontal: "auto",
      })
    return (
      <FlatList
        onLayout={this.onLayout}
        ref={(c) => (this.listView = c)}
        style={flatStyle}
        data={this.state.rows}
        removeClippedSubviews={removeClippedSubviews}
        pageSize={1} // ListView deprecated
        horizontal={horizontal}
        pagingEnabled={pagingEnabled}
        onViewableItemsChanged={this.onViewableItemsChangedBound}
        viewabilityConfig={this.viewabilityConfig}
        renderItem={this.renderCalendarBound}
        showsVerticalScrollIndicator={showScrollIndicator}
        showsHorizontalScrollIndicator={showScrollIndicator}
        scrollEnabled={scrollEnabled}
        keyExtractor={keyExtractor}
        initialScrollIndex={
          this.state.openDate ? this.getMonthIndex(this.state.openDate) : false
        }
        getItemLayout={this.getItemLayout}
        scrollsToTop={scrollsToTop}
        onEndReachedThreshold={onEndReachedThreshold}
        onEndReached={onEndReached}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      />
    )
  }

  render() {
    const { isTemporalCustom, isCalendarExpanded } = this.props
    const newStyle = []

    if (isTemporalCustom && !isCalendarExpanded) {
      newStyle.push({ height: 100 })
    } else if (isTemporalCustom && isCalendarExpanded) {
      newStyle.push({ height: 220 })
    }
    return (
      <View style={newStyle}>
        {this.renderDaysFlatList()}
        {this.renderStaticHeader()}
      </View>
    )
  }
}

export default CalendarList
