import React from "react"
import "./index.scss"
import { images } from "@utils/imageUtils"
import tagIcon from "../../../assets/images/tag.svg"
import { openCreateTeeup } from "@actions/createTeeupActions"

import { connect } from "react-redux"

const ActionButton = ({ icon, title, onClick }) => {
  return (
    <div className="invitees__action" onClick={onClick}>
      <img className="invitees__icon" src={icon} alt={title} />
      <span className="invitees__text">{title}</span>
    </div>
  )
}

const Invitees = ({
  invitees,
  renderMyContactItem,
  additionalStyles,
  handleOnInviteesDone,
  onDone,
  inviteToNewTeeup,
  closeOptionWithAnimations,
  noHover,
  fromCreateTeeup,
  openCreateTeeup,
  isTeeupInvite,
}) => {
  return (
    <div
      className="invitees"
      style={
        isTeeupInvite
          ? {
             top: "45px",
             left: "calc((100vw - 660px)/2 + 670px)" 
          }
          : additionalStyles
      }
    >
      <div
        className="invitees__header"
        style={{ padding: fromCreateTeeup && "12px" }}
      >
        {invitees.length === 1 ? `${1} person` : `${invitees.length} people`}{" "}
        selected
      </div>
      <div
        className="invitees__wrapper"
        style={{ overflow: fromCreateTeeup && "unset" }}
      >
        <div
          className="invitees__content"
          style={{
            maxHeight: fromCreateTeeup && "calc(90vh - 84px)",
            background: fromCreateTeeup && "#ffffff",
          }}
        >
          {invitees.map((item) => (
            <React.Fragment key={item.id}>
              {renderMyContactItem({ item, isInvitees: true }, noHover)}
            </React.Fragment>
          ))}
        </div>
        <div className="invitees__actions">
          {handleOnInviteesDone && (
            <>
              <ActionButton
                icon={images.inviteTeeup}
                title={"Invite to TeeUp"}
                onClick={() => handleOnInviteesDone(invitees, onDone)}
              />
              <ActionButton
                icon={tagIcon}
                title={"Tag Users"}
                onClick={() => handleOnInviteesDone(invitees, onDone, true)}
              />
              {/* <ActionButton icon={images.inviteTeeup} title={"Create Teeup"} onClick={() => openCreateTeeup()} /> */}
            </>
          )}
          {inviteToNewTeeup && (
            <button
              className="add__invitee__on__teeup__creation"
              onClick={(e) => {
                e.preventDefault()
                closeOptionWithAnimations()
              }}
            >
              Add
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    openCreateTeeup: () => dispatch(openCreateTeeup()),
  }
}

export default connect(null, mapDispatchToProps)(Invitees)
