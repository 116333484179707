import React from "react"

import "./index.scss"

const Error = ({ image, title, subtitle, onClickOkay, onClickLearnMore }) => {
  return (
    <div className="error__container">
      <div className="error__content">
        <img className="error__image" src={image} />
        <span className="error__title">{title}</span>
        <span className="error__subtitle">{subtitle}</span>
        <span onClick={onClickLearnMore} className="error__learnmore">
          Learn More About This Issue
        </span>

        <button className="error__button" onClick={onClickOkay}>
          Okay
        </button>
      </div>
    </div>
  )
}

export default Error
