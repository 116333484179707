import React, { useState } from "react"
import { connect } from "react-redux"

import cn from "classnames"

import { selectTeeupsPeople } from "@selectors/teeups"
import { selectAllContacts } from "@selectors/contacts"

import AddInviteesContainerNd from '../AddInvitees/AddInviteesContainerNd'

import './index.scss'

const ContactBookContainer = ({
  activeInviteesTab,
  setActiveInviteesTab,
  setInvitees,
  invitees,
  fromContactBook,
  allContacts,
  teeupPeople,
  fromContactsBook,
  openInviteToTeeupModal,
  handleOnInviteesDone
}) => {
  const [combinedPeople, setCombinedPeople] = useState([])

  const getUniquePeopleFromUsersTeeups = () => {
    const peopleFromAllTeeups = []
    Object.values(teeupPeople).map((teeup) =>
      peopleFromAllTeeups.push(...Object.values(teeup))
    )

    let map = new Map()
    for (let person of peopleFromAllTeeups) {
      map.set(person.id, person)
    }

    const uniquePeople = []
    map.forEach((person) => uniquePeople.push(person))

    return uniquePeople
  }

  const combineContactsAndConnections = () => {
    const combinedPeople = getUniquePeopleFromUsersTeeups()

    for (let i = 0; i < combinedPeople.length; i++) {
      for (let j = 0; j < allContacts.length; j++) {
        if (combinedPeople[i].id === allContacts[j].id)
          combinedPeople[i] = allContacts[j]
      }
    }

    return combinedPeople
  }

  return (
    <div>
      <div className="inviteNd__header">
        <h4
          className={cn("inviteNd__item", {
            "inviteNd__item--active": activeInviteesTab === 1,
          })}
          onClick={() => {
            setActiveInviteesTab(1)
          }}
        >
          CONTACT BOOK
        </h4>
        <h4
          className={cn("inviteNd__item", {
            "inviteNd__item--active": activeInviteesTab === 2,
          })}
          onClick={() => {
            setActiveInviteesTab(2)
            setCombinedPeople(combineContactsAndConnections())
          }}
        >
          CONNECTIONS
        </h4>
      </div>
      {activeInviteesTab === 1 && (
        <AddInviteesContainerNd
          setInvitees={setInvitees}
          invitees={invitees}
          fromNewCreateTeeup={true}
          fromContactsBook={fromContactsBook}
          openInviteToTeeupModal={openInviteToTeeupModal}
          handleOnInviteesDone={handleOnInviteesDone}
        />
      )}
      {activeInviteesTab === 2 && (
        <AddInviteesContainerNd
          setInvitees={setInvitees}
          invitees={invitees}
          fromNewCreateTeeup={true}
          myContacts={true}
          combinedPeople={combinedPeople}
          fromContactsBook={fromContactsBook}
          openInviteToTeeupModal={openInviteToTeeupModal}
          handleOnInviteesDone={handleOnInviteesDone}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state, passedProps) => {
  const teeupPeople = selectTeeupsPeople(state)
  const allContacts = selectAllContacts(state)

  return {
    allContacts,
    teeupPeople
  }
}

export default connect(mapStateToProps)(ContactBookContainer);
