import React from "react"
import PropTypes from "prop-types"
import {
  SectionList,
  Animated,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native"

import strings from "@i18n"
import { images } from "@utils/imageUtils"
import { GAMEPLAN_OPTIONS } from "@utils/gamePlanUtils"
import ViewWithHeader from "@ui/viewWithHeader"
import { Units } from "@theme"
import { ViewStyles } from "../teeupStyles"
import { SectionListStyles } from "./suggestionListStyles"
import DraggableSuggestionRow from "../draggableSuggestionRow"
import ActiveSuggestionDropZone from "./activeSuggestionDropZone"
import SuggestToolbar from "../../TeeUpsOverview/SuggestToolbar"
import { connect } from "react-redux"

const getEmptyListValues = (type, title, activeGameplanSuggestion) => {
  if (activeGameplanSuggestion) {
    if (activeGameplanSuggestion.decided) {
      return {
        image:
          type === GAMEPLAN_OPTIONS.when
            ? "emptyDecidedWhenSuggestionPlaceholder"
            : "emptyDecidedWhereSuggestionPlaceholder",
        titleText: strings.formatString(strings.singleDecidedSuggestion.title, {
          title,
        }),
        descriptionText: strings.singleDecidedSuggestion.text,
      }
    } else {
      return {
        image:
          type === GAMEPLAN_OPTIONS.when
            ? "emptyWhenSuggestionPlaceholder"
            : "emptyWhereSuggestionPlaceholder",
        titleText: strings.formatString(strings.singleSelectedSuggestion.title),
        descriptionText: strings.formatString(
          strings.singleSelectedSuggestion.text,
          { title }
        ),
      }
    }
  } else {
    return {
      image:
        type === GAMEPLAN_OPTIONS.when
          ? "emptyWhenSuggestionPlaceholder"
          : "emptyWhereSuggestionPlaceholder",
      titleText: strings.formatString(strings.emptySuggestionList.title, {
        title,
      }),
      descriptionText: strings.formatString(strings.emptySuggestionList.text, {
        title,
      }),
    }
  }
}

const ListEmptyComponent = ({
  type,
  title,
  activeGameplanSuggestion,
  renderFooter,
  showFooter,
}) => {
  const { image, titleText, descriptionText } = getEmptyListValues(
    type,
    title,
    activeGameplanSuggestion
  )

  return (
    <>
      <View style={ViewStyles.placeholderWrapper}>
        <img style={{ height: "300px" }} src={images[image]} />
        <Text style={ViewStyles.placeholderTitle}>{titleText}</Text>
        <Text style={ViewStyles.placeholderText}>{descriptionText}</Text>
      </View>
      {showFooter && renderFooter()}
    </>
  )
}

ListEmptyComponent.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  activeGameplanSuggestion: PropTypes.object,
}

class SuggestionListView extends React.Component {
  render() {
    const {
      sections,
      extraData,
      renderHeader,
      renderFooter,
      renderItem,
      draggingSuggestion,
      draggingSuggestionId,
      sectionListRef,
      draggingSuggestionRowRef,
      draggingRowPos,
      isActiveSuggestionDragging,
      activeSuggestionDropRef,
      teeupName,
      sectionTitle,
      onCancel,
      dropTargetPermissionText,
      gameplanOnScreen,
      activeGameplanSuggestion,
      onAddSuggestion,
      fromSuggestionModal,
      showFooter,
      closeSidebar,
      isSuggestionOpen,
    } = this.props
    const { suggestions } = gameplanOnScreen
    const draggableSuggestionZIndex = 20
    const isListEmpty =
      !suggestions?.length ||
      (suggestions?.length === 1 && !!activeGameplanSuggestion)

    return (
      <ViewWithHeader
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
        SuggestionListProp
      >
        <View
          style={[
            SectionListStyles.headerContainer,
            {
              position: "sticky",
              top: 0,
              backgroundColor: "#ffffff",
              zIndex: 1,
            },
          ]}
        >
          <Text style={SectionListStyles.type}>
            {sectionTitle}
            {!isSuggestionOpen && (
              <img
                src={images.cross}
                style={{
                  cursor: "pointer",
                  position: "relative",
                  right: "-270px",
                  // top: '-6px',
                  cursor: "pointer",
                }}
                onClick={closeSidebar}
              />
            )}
          </Text>
          {/* <TouchableOpacity onPress={onAddSuggestion} style={SectionListStyles.suggestContainer}>
                        <Image style={SectionListStyles.icon} source={images.suggestLamp} />
                        <Text style={SectionListStyles.suggest}>Suggest</Text>
                    </TouchableOpacity> */}
        </View>
        {!!draggingSuggestion && <View style={SectionListStyles.overlay} />}
        {
          isListEmpty ? (
            <>
              {renderHeader()}
              <ListEmptyComponent
                {...gameplanOnScreen}
                activeGameplanSuggestion={activeGameplanSuggestion}
                renderFooter={renderFooter}
                showFooter={showFooter}
              />
            </>
          ) : (
            <div style={{ backgroundColor: "#F0F2F5" }}>
              {/* backgroundColor: '#f0f0f0' */}
              {renderHeader()}
              {sections.map((section, index) =>
                renderItem({ item: sections.index, section, index })
              )}
              {showFooter && renderFooter()}
            </div>
          )
          // (
          //     <SectionList
          //         style={SectionListStyles.sectionList}
          //         sections={sections}
          //         extraData={extraData}
          //         keyExtractor={(a) => a}
          //         renderItem={renderItem}
          //         draggingSuggestionId={draggingSuggestionId}
          //         scrollEnabled={!draggingSuggestion}
          //         // stickySectionHeadersEnabled
          //         bounces={false}
          //         ref={sectionListRef}
          //     />
          // )
        }
        {!!draggingSuggestion && (
          <Animated.View
            style={[
              ViewStyles.hoverComponent,
              {
                width: draggingSuggestion.rect.width,
                transform: [
                  { translateX: draggingRowPos.x },
                  { translateY: draggingRowPos.y },
                ],
                zIndex: draggableSuggestionZIndex,
              },
            ]}
            pointerEvents="none"
          >
            <DraggableSuggestionRow
              {...draggingSuggestion.props}
              rowRef={draggingSuggestionRowRef}
              isDragged
            />
          </Animated.View>
        )}
        {/* <ActiveSuggestionDropZone
                    ref={(ref) => {
                        activeSuggestionDropRef(ref)
                    }}
                    isActiveSuggestionDragging={isActiveSuggestionDragging}
                    dropTargetPermissionText={dropTargetPermissionText}
                /> */}
        {/* {renderFooter()} */}
      </ViewWithHeader>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showFooter: state.common.showFooter,
    isSuggestionOpen: state.common.isSuggestionOpen,
  }
}

export default connect(mapStateToProps, null)(SuggestionListView)

SuggestionListView.propTypes = {
  gameplanOnScreen: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  extraData: PropTypes.object,
  renderHeader: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  handleOnOpen: PropTypes.func,
  draggingSuggestion: PropTypes.any,
  draggingSuggestionId: PropTypes.any,
  sectionListRef: PropTypes.func,
  draggingSuggestionRowRef: PropTypes.func,
  draggingRowPos: PropTypes.any,
  isActiveSuggestionDragging: PropTypes.bool,
  activeSuggestionDropRef: PropTypes.func,
  teeupName: PropTypes.string,
  sectionTitle: PropTypes.string,
  onCancel: PropTypes.func,
  dropTargetPermissionText: PropTypes.string,
  activeGameplanSuggestion: PropTypes.object,
}
