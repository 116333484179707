import React from "react"
import { View, Text, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"

import { Units } from "@theme"
import { OverlayContainerStyles as styles } from "./timePickerOverlayStyles"

const OverlayContainer = ({
  onCancelPress,
  onSelectPress,
  disableSelect,
  children,
}) => {
  return (
    <React.Fragment>
      <TouchableOpacity
        onPress={onCancelPress}
        style={styles.topOverlayContainer}
      />
      <View style={styles.container}>
        <View style={styles.topButtonContainer}>
          <TouchableOpacity hitSlop={Units.hitSlop()} onPress={onCancelPress}>
            <Text style={styles.buttonText}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
            hitSlop={Units.hitSlop()}
            onPress={onSelectPress}
            disabled={disableSelect}
          >
            <Text
              style={[styles.buttonText, disableSelect && styles.disabledText]}
            >
              Select
            </Text>
          </TouchableOpacity>
        </View>

        <View style={styles.topContainer}>{children}</View>
      </View>
    </React.Fragment>
  )
}

OverlayContainer.propTypes = {
  onCancelPress: PropTypes.func.isRequired,
  onSelectPress: PropTypes.func.isRequired,
  disableSelect: PropTypes.bool,
  children: PropTypes.element,
}

export default OverlayContainer
