import actionTypes from "../actions/actionTypes"
import { titles } from "../config/enums"

const initialState = {
  showSideBar: true,
  replyMessage: null,
  title: titles.defaultTitle,
  version: "1.8",
  isNudgePeopleOpen: false,
  teeupFilter: "",
  peopleSideBar: false,
  sideBarContent: [],
  mentionedMessages: [],
  activeTab: 0,
  notificationTeeups: false,
  helpPopup: false,
  activeInviteesTab: 1,
  modalPeopleNudge: false,
  suggestionToNudgeId: 0,
  typeToNudge: null,
  modalSuccess: false,
  showFooter: true,
  showHelpPopup: false,
  isOnlineTab: false,
  isSuggestionOpen: false,
  hoveredTeeupId: null,
  teeupsMode: null,
  isSuggestionDetailsOpened: null,
  detailsTab: null,
  suggestionContent: null,
  styledMessages: [],
  currentSuggestionId: null,
  currentWindowParams: { width: window.innerWidth, height: window.innerHeight },
  address: "",
  timeParams: null,
  peopleToNudge: [],
  selectedCalendarDay: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_SIDE_BAR:
      return {
        ...state,
        showSideBar: action.payload,
      }
    case actionTypes.SET_REPLY_MESSAGE:
      return {
        ...state,
        replyMessage: action.payload,
      }
    case actionTypes.SET_TITLE:
      return {
        ...state,
        title: action.title,
      }
    case actionTypes.SET_NUDGE_PEOPLE_OPEN:
      return {
        ...state,
        isNudgePeopleOpen: action.payload,
      }
    case actionTypes.SET_TEEUP_FILTER:
      return {
        ...state,
        teeupFilter: action.filter,
      }
    case actionTypes.SET_SHOW_PEOPLE_SIDE_BAR:
      return {
        ...state,
        peopleSideBar: action.payload,
      }
    case actionTypes.SET_SIDE_BAR_CONTENT:
      return {
        ...state,
        sideBarContent: action.payload,
      }
    case actionTypes.SET_MENTIONED_MESSAGES:
      return {
        ...state,
        mentionedMessages: action.payload,
      }
    case actionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      }
    case actionTypes.SET_HELP_POPUP:
      return {
        ...state,
        helpPopup: action.payload,
      }
    case actionTypes.SET_ACTIVE_NOTIFICATIONS_TEEUPS:
      return {
        ...state,
        notificationTeeups: !state.notificationTeeups,
      }
    case actionTypes.SET_ACTIVE_INVITEES_TAB:
      return {
        ...state,
        activeInviteesTab: action.payload,
      }
    case actionTypes.SET_PEOPLE_NUDGE:
      return {
        ...state,
        modalPeopleNudge: action.value,
        suggestionToNudgeId: action.id,
        typeToNudge: action.typeId,
      }
    case actionTypes.SET_MODAL_SUCCESS:
      return {
        ...state,
        modalSuccess: action.payload,
      }
    case actionTypes.SET_HIDE_SUGGESTION_LIST_FOOTER:
      return {
        ...state,
        showFooter: false,
      }
    case actionTypes.SET_SHOW_SUGGESTION_LIST_FOOTER:
      return {
        ...state,
        showFooter: true,
      }

    case actionTypes.TOGGLE_HELP_PHONE_POPUP:
      return {
        ...state,
        showHelpPopup: action.payload,
      }

    case actionTypes.IS_ONLINE_TAB_OPENED:
      return {
        ...state,
        isOnlineTab: action.payload,
      }

    case actionTypes.OPEN_SUGGESTION:
      return {
        ...state,
        isSuggestionOpen: true,
      }

    case actionTypes.CLOSE_SUGGESTION:
      return {
        ...state,
        isSuggestionOpen: false,
      }

    case actionTypes.SET_TEEUPS_MODE:
      return {
        ...state,
        teeupsMode: action.payload,
      }
    case actionTypes.TOGGLE_SUGGESTION_DETAILS:
      return {
        ...state,
        isSuggestionDetailsOpened: action.payload,
        detailsTab: action.detailsTab,
        suggestionContent: action.suggestionContent,
        currentSuggestionId: action.currentSuggestionId,
        address: action.address,
        timeParams: action.timeParams,
      }
    case actionTypes.GET_STYLED_MESSAGES:
      return {
        ...state,
        styledMessages: action.payload,
      }
    case actionTypes.GET_CURRENT_WINDOW_PARAMS:
      return {
        ...state,
        currentWindowParams: action.payload,
      }

    case actionTypes.SET_PEOPLE_TO_NUDGE:
      return {
        ...state,
        peopleToNudge: action.payload,
      }
    case actionTypes.SET_SELECTED_CALENDAR_DAY:
      return {
        ...state,
        selectedCalendarDay: action.payload,
      }
    default:
      return state
  }
}
