import React from "react"
import "./index.scss"
import { images } from "@utils/imageUtils"

const LoadingView = () => {
  return (
    <div className="desert__container">
      <img className="desert__wrapper" src={images.desert_bg} />
      <img className="boomerang" src={images.boomerang} />
    </div>
  )
}

export default LoadingView
