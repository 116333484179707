import React, { useRef } from "react"
import { ReactSVG } from "react-svg"
import ReactTooltip from "react-tooltip"

import "./index.scss"
import DropDown from "../DropDown"

import { images } from "@utils/imageUtils"
import { teeupUserStatusKeys, teeupUserStatus } from "@config/enums"
import { AppColors } from "@theme"
import _ from "lodash"

const UserStatusButtonProps = [
  {
    title: teeupUserStatus.joined,
    titleColor: AppColors.brand.darkGrey2,
    refTitle: teeupUserStatus.onmyway,
    topTitle: "TEEUP REACTION",
    image: images.joinedBig,
    color: AppColors.status.user.joined,
    border: AppColors.brand.grey2,
    right: true,
    testID: "teeupUserJoinedBtn",
    status: teeupUserStatusKeys.joined,
  },
  {
    title: teeupUserStatus.coolIdea,
    refTitle: teeupUserStatus.onmyway,
    image: images.snowflake,
    color: AppColors.status.user.coolIdea,
    right: false,
    testID: "teeupUserCoolideaBtn",
    status: teeupUserStatusKeys.coolIdea,
  },
  {
    title: teeupUserStatus.invited,
    refTitle: teeupUserStatus.onmyway,
    image: images.envelope,
    color: AppColors.status.user.invited,
    right: true,
    testID: "teeupUserInvitedBtn",
    status: teeupUserStatusKeys.invited,
  },
  {
    title: teeupUserStatus.going,
    refTitle: teeupUserStatus.onmyway,
    image: images.arrowRight,
    color: AppColors.status.user.going,
    right: true,
    testID: "teeupGoingBtn",
    status: teeupUserStatusKeys.going,
  },
  {
    title: teeupUserStatus.mightgo,
    refTitle: teeupUserStatus.onmyway,
    image: images.question,
    color: AppColors.status.user.mightgo,
    right: true,
    testID: "teeupMightGoBtn",
    status: teeupUserStatusKeys.mightgo,
  },
  {
    title: teeupUserStatus.notgoing,
    refTitle: teeupUserStatus.onmyway,
    image: images.times,
    color: AppColors.status.user.notgoing,
    right: true,
    testID: "teeupUserNotGoingBtn",
    status: teeupUserStatusKeys.notgoing,
  },
  {
    title: teeupUserStatus.onmyway,
    refTitle: teeupUserStatus.onmyway,
    image: images.rocket,
    color: AppColors.status.user.onmyway,
    right: false,
    testID: "teeupUserOnMyWayBtn",
    status: teeupUserStatusKeys.onmyway,
  },
  {
    title: teeupUserStatus.attended,
    refTitle: teeupUserStatus.onmyway,
    image: images.flagCheckered,
    color: AppColors.status.user.attended,
    right: true,
    testID: "teeupUserAttendedBtn",
    status: teeupUserStatusKeys.attended,
  },
  {
    topTitle: "HEADS UP",
    title: teeupUserStatus.onmyway,
    refTitle: teeupUserStatus.going,
    image: images.arrowRight,
    color: AppColors.status.user.onmyway,
    right: true,
    testID: "teeupUserGoingBtn",
    status: teeupUserStatusKeys.onmyway,
  },
  {
    title: teeupUserStatus.runningLate,
    refTitle: teeupUserStatus.droppedout,
    image: images.runningLate,
    color: AppColors.status.user.runningLate,
    right: true,
    testID: "teeupUserRunningLateBtn",
    status: teeupUserStatusKeys.runningLate,
  },
  {
    title: teeupUserStatus.arrived,
    refTitle: teeupUserStatus.onmyway,
    image: images.flag,
    color: AppColors.status.user.arrived,
    right: true,
    testID: "teeupUserArrivedBtn",
    status: teeupUserStatusKeys.arrived,
  },
  {
    title: teeupUserStatus.backedOut,
    refTitle: teeupUserStatus.droppedout,
    image: images.backedOut,
    color: AppColors.status.user.backedOut,
    right: false,
    testID: "teeupUserBackedoutBtn",
    status: teeupUserStatusKeys.backedOut,
  },
]

const OverviewHeader = ({
  openSettings,
  teeUp,
  handleMyStatusPress,
  renderStautsOverlay,
  people,
  onDecline,
  onAccept,
}) => {
  const infoRef = useRef()

  const userStatusButtonProps =
    UserStatusButtonProps.find((prop) => prop.status === teeUp.userStatus) || {}

  const isInitiallyInvited = teeUp.userStatus === teeupUserStatusKeys.invited
  const owner = _.get(people, teeUp.createdby, {})

  return (
    <div className="overviewheader">
      <div
        className="overviewheader__image"
        style={{ backgroundImage: `url(${teeUp.photo})` }}
      >
        {isInitiallyInvited && (
          <div className="overviewheader__invitation">
            <div className="overviewheader__invitation-wrapper">
              <div className="overviewheader__invitation-content">
                <div
                  className="overviewheader__invitation-avatar"
                  style={{ backgroundImage: `url(${owner.avatar})` }}
                ></div>
                <div className="overviewheader__invitation-text">
                  <img src={images.convert} />
                  <span>{owner.name} invited you</span>
                </div>
              </div>
              <div className="overviewheader__invitation-message">
                {teeUp.invitationMessage}
              </div>
            </div>
            <div className="overviewheader__invitation-buttons">
              <div
                className="overviewheader__invitation-button"
                onClick={onDecline}
              >
                Decline
              </div>
              <div
                className="overviewheader__invitation-button"
                onClick={onAccept}
              >
                Join
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="overviewheader__content">
        <h1 className="overviewheader__title">{teeUp.name}</h1>
        <div className="overviewheader_status_settings">
          {teeUp && (
            <DropDown teeUp={teeUp} title={teeUp.name} imgUrl={teeUp.photo} />
          )}
          <div
            className="overviewheader_status_settings_icon"
            ref={infoRef}
            data-tip={"TeeUp Settings"}
            data-for="information-tooltip"
            onMouseOver={(e) => {
              e.stopPropagation()
              ReactTooltip.show(infoRef.current)
            }}
            onMouseLeave={(e) => {
              e.stopPropagation()
              ReactTooltip.hide(infoRef.current)
            }}
          >
            <ReactSVG
              onClick={() => {
                openSettings()
                ReactTooltip.hide(infoRef.current)
              }}
              src={images.settings}
            />
          </div>
        </div>
        {/* {renderStautsOverlay(divRef)}
        {userStatusButtonProps.status === teeupUserStatusKeys.joined ? (
          <div ref={divRef} onClick={handleMyStatusPress} className="overviewheader__status overviewheader__status--set">
            <span>SET STATUS</span>
          </div>
        ): 
        <div ref={divRef} onClick={handleMyStatusPress} className="overviewheader__status overviewheader__status--with" style={{backgroundColor: userStatusButtonProps.color}}>
          <img src={userStatusButtonProps.image} alt="user status image" />
          <span>{userStatusButtonProps.title}</span>
        </div>
        } */}
      </div>
    </div>
  )
}

export default OverviewHeader
