import React, { useState, useEffect } from "react"
import { TouchableOpacity, Image } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import ParticipantsCard from "./participantsCard/participantsCardContainer"
import { PARTICIPANTS } from "@utils/filters"
import closeIcon from "../../../assets/images/close.svg"
import MenuButtonModal from "../../ui/menuButtonModal"
import ParticipantDetailsContainer from "./participantsCard/participantDetails/ParticipantDetailsContainer"
import TeeupPermissions from "../../teeupSettings/teeupPermissionsContainer"
import { isOrganiser, isOwner } from "@utils/teeupUtils"
import { selectUserId, selectUserInfo } from "@selectors/user"
import {
  selectActiveTeeup,
  selectDetailsOpen,
  selectDetailsUser,
} from "@selectors/activeTeeup"
import { updateTeeup } from "../../../middlewares/backendMiddleware"
import { SectionListStyles } from "../../gamePlansCard/suggestionsList/suggestionListStyles"
import { images } from "@utils/imageUtils"
import DropDown from "../../customDropdown"
import { setPeopleSideBar } from "@actions/commonActions"
import {
  toggleParticipantDetails,
  toggleDetailsUser,
} from "@actions/activeTeeupActions"
import ParticipantPopup from "../../participantPopup"
import colors from "@theme/colors"

const People = ({
  activeUserId,
  teeup,
  isDetailsOpen,
  detailsUserId,
  setPeopleSideBar,
  showAddInvitees,
  openOption,
  closeOption,
  userInfo,
  toggleParticipantDetails,
  toggleDetailsUser,
  setModalContent,
  inviteesContent,
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [activeParticipant, setActiveParticipant] = useState(-1)
  const [animation, setAnimation] = useState("")
  const [isMenuButtonModalOpen, setIsMenuButtonModalOpen] = useState(false)
  const [isParticipantDetailsOpen, setParticipantDetailsOpen] = useState(false)
  const [participantFilter, setParticipantFilter] = useState(
    PARTICIPANTS.LATEST_UPDATE
  )
  const [isTeeupPermissionOpened, setTeeupPermissionOpened] = useState(false)
  const [permissionsAnimation, setPermissionsAnimation] = useState("")
  const [userId, setUserId] = useState(null)

  // useEffect(() => {
  //   setParticipantDetailsOpen(isDetailsOpen)
  // }, [isDetailsOpen])

  // useEffect(() => {
  //   setActiveParticipant(detailsUserId)
  // }, [detailsUserId])

  const closeOverlay = () => {
    setAnimation("right-slide")
    setPeopleSideBar(false)
    setTimeout(() => {
      setIsOverlayOpen(false)
      setAnimation("")
    }, 250)
  }

  const closeDetails = (e) => {
    e.stopPropagation()
    setAnimation("right-slide")
    setPeopleSideBar(false)

    setTimeout(() => {
      toggleParticipantDetails(false)
      setAnimation("")
    }, 250)
  }

  const closePermissions = () => {
    setPermissionsAnimation("right-slide")
    setPeopleSideBar(false)
    setTimeout(() => {
      setTeeupPermissionOpened(false)
      setPermissionsAnimation("")
    }, 250)
  }

  const linkParticipantPopup = (userId) => {
    // if (+userId === userInfo.id) {

    //     toggleParticipantDetails(true);
    //     toggleDetailsUser(+userId);

    //     return;
    // };

    setUserId(userId)
  }

  const renderCard = (expanded) => (
    <ParticipantsCard
      expanded={expanded}
      showAddInvitees={showAddInvitees}
      inviteesContent={inviteesContent}
      participantFilter={participantFilter}
      openOption={openOption}
      closeOption={closeOption}
      showFilterOptions={() => {
        setIsMenuButtonModalOpen(!isMenuButtonModalOpen)
      }}
      showDetails={() => setParticipantDetailsOpen(true)}
      setActiveParticipant={setActiveParticipant}
      openTeeupPermision={() => setTeeupPermissionOpened(true)}
      isMenuButtonModalOpen={isMenuButtonModalOpen}
      renderDropDown={() => (
        <DropDown isOpened={isMenuButtonModalOpen} buttons={getButtons()} />
      )}
      linkParticipantPopup={linkParticipantPopup}
      expandCard={() => {
        setIsOverlayOpen(true)
        setPeopleSideBar(true)
      }}
      setModalContent={setModalContent}
      // teeup={teeup}
    />
  )

  const renderPopUp = () => {
    if (!userId) return

    return <ParticipantPopup userId={userId} onClose={() => setUserId(null)} />
  }
  const getButtons = () => {
    return [
      {
        title: "Latest Update",
        callback: () => {
          setParticipantFilter(PARTICIPANTS.LATEST_UPDATE)
          setIsMenuButtonModalOpen(false)
        },
        isSelected: participantFilter === PARTICIPANTS.LATEST_UPDATE,
      },
      {
        title: "Status",
        callback: () => {
          setParticipantFilter(PARTICIPANTS.STATUS)
          setIsMenuButtonModalOpen(false)
        },
        isSelected: participantFilter === PARTICIPANTS.STATUS,
      },
    ]
  }

  const isTeeupOwner = isOwner(activeUserId, teeup)
  const isTeeupOrganiser = isOrganiser(activeUserId, teeup?.organisers)

  return (
    <div
      className="block people"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setIsOverlayOpen(true)
        setPeopleSideBar(true)
      }}
    >
      {renderCard(false)}
      {renderPopUp()}
      {isOverlayOpen ? (
        <div
          className="settings"
          style={{
            width: 370,
            //  left: 'calc(100vw - 405px)',
            height: "calc(100vh - 164px)",
            boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
            zIndex: 1,
          }}
        >
          <div style={{ animationName: animation }} className="people__overlay">
            <div
              onClick={(e) => {
                e.stopPropagation()
                closeOverlay()
                setPeopleSideBar(false)
              }}
            >
              <img
                className="people__overlay--cross"
                src={images.cross}
                alt="close"
              />
            </div>
            {renderCard(true)}
          </div>
        </div>
      ) : null}

      {isTeeupPermissionOpened ? (
        <div
          style={{ animationName: permissionsAnimation }}
          className="people__overlay"
        >
          <div className="people__overlay__image settings__top">
            <TouchableOpacity onPress={closePermissions}>
              <Image
                source={images.back}
                style={SectionListStyles.cancelBtnImage}
              />
            </TouchableOpacity>
            <span>Organizers &amp; Permissions</span>
            <div></div>
          </div>
          <TeeupPermissions
            isOrganiser={isTeeupOrganiser}
            isOwner={isTeeupOwner}
            updateTeeup={updateTeeup}
          />
        </div>
      ) : null}
      {/* {isDetailsOpen && (
        <div className="settings" style={{backgroundColor: 'none', position: 'fixed', top: 80}} onClick={closeDetails}>
          <div style={{ animationName: animation, padding: 0}} className="people__overlay" onClick={(e) => e.stopPropagation()}>
            <div className="settings__top">
              <span>Participant Details</span>
              <img style={{ top: 'initial' }} src={closeIcon} alt="close" onClick={closeDetails} />
            </div>

            <ParticipantDetailsContainer userId={detailsUserId} />
          </div>
        </div>
      )} */}
    </div>
  )
}

const menuButtonStyles = {
  position: "absolute",
  right: 0,
  width: "100%",
  left: 0,
  height: "100vh",
}

const mapStateToProps = (state) => {
  const activeUserId = selectUserId(state)
  // const teeup = selectActiveTeeup(state)
  const isDetailsOpen = selectDetailsOpen(state)
  const detailsUserId = selectDetailsUser(state)
  const userInfo = selectUserInfo(state)

  return {
    activeUserId,
    // teeup,
    isDetailsOpen,
    detailsUserId,
    userInfo,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPeopleSideBar: bindActionCreators(setPeopleSideBar, dispatch),
  toggleParticipantDetails: bindActionCreators(
    toggleParticipantDetails,
    dispatch
  ),
  toggleDetailsUser: bindActionCreators(toggleParticipantDetails, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(People)
