import React from "react"
import PropTypes from "prop-types"
import { View, FlatList, Image, Text } from "react-native"
import { ReactSVG } from "react-svg"
import { images } from "@utils/imageUtils"
import I18n from "@i18n"

import { styles } from "./UserMentionsScreenStyles"

import "./mentionsScreenComponent.scss"

const ListEmptyComponent = () => (
  <View style={styles.emptyComponent}>
    <Image source={images.mentionsPlaceholder} style={styles.mentionsImage} />
    <Text style={styles.mentionsTitle}>
      {I18n.mentionsScreen.mentionsTitle}
    </Text>
    <Text style={styles.mentionsText}>{I18n.mentionsScreen.mentionsText}</Text>
  </View>
)

const EmptyMentions = () => (
  <div className="empty-mentions">
    <ReactSVG src={images.ndEmptyMentionsImage} />
    <h2 className="empty-mentions__title">No mentions yet</h2>
    <p className="empty-mentions__description">
      You have not been mentioned directly in a message yet. If you are, this is
      where you can easily view those messages.
    </p>
  </div>
)

const UserMentionsComponent = ({ mentions, renderItem, newDesign }) => {
  if (!mentions.length) {
    return newDesign ? <EmptyMentions /> : <ListEmptyComponent />
  }
  return (
    <View
      style={[
        styles.flex,
        styles.mentionsContainer,
        newDesign && { backgroundColor: "transparent" },
      ]}
    >
      <View style={styles.chatContainer}>
        <FlatList
          contentContainerStyle={styles.listPadding}
          data={mentions}
          renderItem={renderItem}
        />
      </View>
    </View>
  )
}

UserMentionsComponent.propTypes = {
  mentions: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
}

export default UserMentionsComponent
