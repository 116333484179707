import _ from "lodash"
import { getContactName } from "./dataUtils"

export const sections = {
  FREQUENT: "mostTeeupedWithUsers",
  PREVIOUS: "previousTeeups",
  RECOMMENDED: "recommended",
  CONTACT_LIST: "contactList",
}

export const getUserNameForAlphabeticalSorting = (user) => {
  return (
    user.lastname ||
    user.lastName ||
    user.firstname ||
    user.firstName ||
    user.name ||
    " "
  )
}

export const getAlphabeticalListHeaders = (list) => {
  let sections = {}
  list.forEach((contact) => {
    let sectionName = getUserNameForAlphabeticalSorting(contact)
    sectionName = sectionName[0].toUpperCase()
    if (!sectionName.match(/[a-z]/i)) {
      sectionName = "#"
    }

    if (sections[sectionName]) {
      sections[sectionName].data.push(contact)
    } else {
      sections[sectionName] = { name: sectionName, data: [contact] }
    }
  })
  let sectionsArray = _.isEmpty(sections)
    ? []
    : Object.keys(sections).map((key) => sections[key])

  sectionsArray = sectionsArray.sort((a, b) => {
    // if (a.name === '#') {
    //     // Move all unknown symbols to back
    //     return 1
    // }
    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  })

  if (sectionsArray[0] && sectionsArray[0].name === "#") {
    const tmp = sectionsArray[0]
    sectionsArray.splice(0, 1)
    sectionsArray.push(tmp)
  }

  return sectionsArray
}

export const mergeListItemsById = (list) => {
  let mergedList = []
  list.forEach((item) => {
    const sameItemIndex = mergedList.findIndex(
      (user) =>
        user.id === item.id ||
        (user.value && item.value && item.value === user.value) // added this temporary, because looks like it somehow creates multiple instances of the same contacts while syncing(same content bug different id, looks like mongo id)
    )
    if (sameItemIndex === -1) mergedList.push(item)
    else {
      // TODO: looks strange combining 2 objects with same id...
      mergedList[sameItemIndex] = {
        ...mergedList[sameItemIndex],
        ...item,
      }
    }
  })
  return mergedList || []
}

export const mapContactsToTags = (contacts) => {
  let tagsMap = {}

  contacts.forEach((contact) => {
    if (contact.tags) {
      contact.tags.forEach((tag) => {
        if (tagsMap[tag.id]) {
          tagsMap[tag.id].push(contact)
        } else {
          tagsMap[tag.id] = [contact]
        }
      })
    }
  })

  return tagsMap
}

export const mergeListObjectItemsById = (listObject) => {
  // let mergedList = []

  const uniquenessId = (contact) =>
    contact.cooeId ? contact.cooeId : contact.id

  const listNames = Object.keys(listObject)
  let listArrays = []
  listNames.forEach((listName) => {
    listArrays.push(listObject[listName])
  })

  let mergedList = Array.prototype.concat(...listArrays)
  mergedList = _.uniqBy(mergedList, uniquenessId)
  // listNames.forEach(list => {
  //     // const innerList = mergeListItemsById(listObject[list])
  //     const innerList = listObject[list]
  //     mergedList.concat(innerList)
  // })
  // mergedList = mergeListItemsById(mergedList)
  return mergedList
}

export const getContactsArray = (contacts) => {
  let newFormattedArray = []
  if (Array.isArray(contacts)) {
    newFormattedArray = contacts
  } else {
    Object.keys(contacts)
      .filter(
        (key) => key !== sections.PREVIOUS && key !== sections.RECOMMENDED
      )
      .forEach((sectionName) => {
        newFormattedArray = [...newFormattedArray, ...contacts[sectionName]]
      })
  }
  const mergedContacts = mergeListItemsById(newFormattedArray)
  return mergedContacts
}

export const filterContactsFromUsersList = (usersList, contactsList) => {
  const list = []
  if (usersList) {
    usersList.forEach((user) => {
      const contactsListItem = contactsList.find(
        (contact) => contact.id === user.id
      )
      if (contactsListItem) {
        list.push({
          ...user,
          ...contactsListItem,
        })
      }
    })
  }

  return list
}

export const hideAlreadyInvited = (contactsArray = [], inviteesIds = []) => {
  if (inviteesIds.length > 0) {
    return contactsArray.filter((contact) => !inviteesIds.includes(contact.id))
  }

  return contactsArray
}

export const findContactsByText = (contactList, text) => {
  // remove '+' because it breaks regexp
  const searchText = text.trim().split("+").join("")

  if (searchText.length === 0) {
    return contactList
  }

  const foundContacts = contactList
    ? contactList.filter((contact) => {
        const pattern = RegExp(searchText, "gi")
        const { username, value, emails, phoneNumbers } = contact
        const name = getContactName(contact)
        const isFound =
          (name && name.match(pattern)) ||
          (username && username.match(pattern)) ||
          (value && value.match(pattern))
        let emailSearchResult = false
        if (emails) {
          const [result] = emails.filter(
            ({ email }) => email && email.match(pattern)
          )
          if (result) emailSearchResult = true
        }
        let numbersSearchResult = false
        if (phoneNumbers) {
          const [result] = phoneNumbers.filter(
            ({ number }) => number && number.match(pattern)
          )
          if (result) numbersSearchResult = true
        }
        return isFound || emailSearchResult || numbersSearchResult
      })
    : []

  return foundContacts
}
