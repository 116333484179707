import React, { useRef, useState } from "react"
import { TouchableOpacity } from "react-native"
import { images } from "@utils/imageUtils"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"

import PeopleTooltip from "@ui/peopleTooltip"

import { connect } from "react-redux"

import { setPeopleToNudge } from "@actions/commonActions"
import { bindActionCreators } from "redux"

import ReactTooltip from "react-tooltip"
import threeDotsIcon from "../../../../assets/images/three-dots.svg"

const ReactionsSection = ({
  likes,
  dislikes,
  reactedPeople,
  setPeopleToNudge,
}) => {
  const peopleRef = useRef()

  const [nudgePeople, setNudgePeople] = useState(false)

  return (
    <div className="reactions__section--container">
      <div className="reactions__section--header">
        <div className="reactions__section--header__wrapper">
          {(likes || dislikes) &&
            (likes ? (
              <FaThumbsUp fill="rgb(255, 181, 32)" />
            ) : (
              <FaThumbsDown fill="rgb(255, 181, 32)" />
            ))}
          <h4 className="reactions__section--header__title">
            {!likes && !dislikes
              ? "no reactions"
              : likes
              ? "likes"
              : "dislikes"}
          </h4>
        </div>

        <PeopleTooltip />

        <TouchableOpacity
          onPress={() => {
            setNudgePeople(true)
            setPeopleToNudge(reactedPeople)
          }}
        >
          <img
            className="reactions__section--header__image"
            src={images.nudgesNew}
          />
          {nudgePeople && (
            <img
              src={threeDotsIcon}
              ref={peopleRef}
              data-tip={Math.floor(Math.random() * (100 - 1) + 1)}
              data-for="people-tooltip"
              className="reactions-view"
              onMouseOver={(e) => {
                e.stopPropagation()
                ReactTooltip.show(peopleRef.current)
              }}
              onMouseLeave={() => setNudgePeople(false)}
              // onClick={(e) => {
              //         e.stopPropagation()
              //         ReactTooltip.show(peopleRef.current)
              //     }}
              alt=""
            />
          )}
        </TouchableOpacity>
      </div>

      <div className="reactions__section--content">
        {reactedPeople &&
          reactedPeople.map((person) => (
            <div className="reactions__section--content__item">
              {person?.avatar ? (
                <img
                  src={person?.avatar}
                  className="reactions__section--content__item--image"
                />
              ) : (
                <div className="reactions__section--image__placeholder" />
              )}
              <p className="reactions__section--content__item--name">
                {person.username}
              </p>
            </div>
          ))}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPeopleToNudge: bindActionCreators(setPeopleToNudge, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(ReactionsSection)
