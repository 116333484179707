import React, { memo } from "react"
import PropTypes from "prop-types"
import { TouchableOpacity } from "react-native"
import Avatar from "@ui/avatar"
import UserStatusAccesory from "@ui/avatar/userStatusAccesory"
import styles from "./messageAvatarStyles"

const MessageAvatar = ({
  isAvatarVisible,
  useAvatarBorder,
  avatar,
  initials,
  status,
  disabled,
  onPress,
}) => (
  <TouchableOpacity style={styles.messageAvatar} onPress={onPress}>
    {isAvatarVisible && (
      <Avatar
        imageUrl={avatar}
        size={38}
        initials={initials}
        borderWidth={useAvatarBorder ? 2 : 0}
        disabled={disabled}
        accesory={
          <UserStatusAccesory
            userStatus={status}
            disabled={disabled}
            accesoryStyle={styles.avatarAccesory}
          />
        }
      />
    )}
  </TouchableOpacity>
)

MessageAvatar.propTypes = {
  isAvatarVisible: PropTypes.bool.isRequired,
  avatar: PropTypes.string,
  initials: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  useAvatarBorder: PropTypes.bool,
}

export default memo(MessageAvatar)
