import { StyleSheet } from "react-native"
import { Units, AppSizes } from "@theme"

export const styles = StyleSheet.create({
  imageWrapper: {
    height: Units.responsiveValue(AppSizes.screen.heightQuarter) / 2,
    width: "30vh",
    padding: Units.responsiveValue(4),
    marginTop: Units.responsiveValue(0),
    borderRadius: Units.responsiveValue(20),
    overflow: "hidden",
    // alignSelf: isImageChange ? 'center' : undefined,
  },
  changeImageWrapper: {
    marginTop: Units.responsiveValue(5),
    alignSelf: "center",
  },
  pinnedImageWrapper: {
    width: 300,
    height: 300,
  },
  image: {
    flex: 1,
    borderRadius: Units.responsiveValue(20),
  },
})
