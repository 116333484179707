import React, { useEffect, useState } from "react"
import { useDispatch} from "react-redux"

import classnames from "classnames"

import TopBar from "./TopSection/TopBar"
import LocationMap from "./LocationMapSection/LocationMap"
import PeopleColumn from "./PeopleSection/PeopleColumn"

import { getContacts } from "@actions/createTeeupActions"

import { useTeeUpPage } from "../TeeUpPageContext"

import "./index.scss"

const TeeUpPageRightSection = () => {
  const { interactionArea, openPeopleArea } = useTeeUpPage()
  const [close, setClose ] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getContacts()) //load all user contacts
  }, [])

  useEffect(() => {
    setClose(!!interactionArea || !!openPeopleArea)
  }, [interactionArea, openPeopleArea])

  return (
    <div
      className={classnames("teeup-page__right", {
        close: close
      })}
    >
      <TopBar />
      {!close && <LocationMap />}
      <PeopleColumn close={close} />
    </div>
  )
}

export default TeeUpPageRightSection
