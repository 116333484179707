import React from "react"
import { connect } from "react-redux"
import { openCreateTeeup } from "../../actions/createTeeupActions"
import { images } from "@utils/imageUtils"
import cn from "classnames"
import "./index.scss"
import { NavLink } from "react-router-dom"

const CreateTeeUpButton = ({ setFocusCounter, focusCounter }) => {
  return (
    <NavLink
      className={cn("sidebar-create", {
        "sidebar-create--active": focusCounter === 0,
      })}
      to="/create"
      onClick={() => {
        setFocusCounter(0)
      }}
    >
      <img
        src={images.ndCreateTeeup}
        className="sidebar-create__icon"
        alt="Create TeeUp"
      />
      <h4
        className="sidebar-create__title"
      >
        New TeeUp
      </h4>
    </NavLink>
  )
}

const mapMethodsToProps = {
  open: openCreateTeeup,
}

export default connect(null, mapMethodsToProps)(CreateTeeUpButton)