import React from "react"
import PropTypes from "prop-types"
import { View } from "react-native"

import { ViewStyles } from "./timezonesStyles"
import ExpandableCalendar from "@ui/expandableCalendar"

const TimezonesViewDate = ({
  onDateSelected,
  teeupEventsByDay,
  startDate,
  endDate,
  duration,
  skip,
  onDayClick,
  fromCreate,
  isTemporalCustom,
}) => (
  <View style={ViewStyles.topContainer}>
    <ExpandableCalendar
      onDateSelected={onDateSelected}
      teeupEventsByDay={teeupEventsByDay}
      startDate={startDate}
      endDate={endDate}
      duration={duration}
      skip={skip}
      isTemporalCustom={isTemporalCustom}
      onDayClick={onDayClick}
      fromCreate={fromCreate}
    />
  </View>
)

TimezonesViewDate.propTypes = {
  isTimeZoneEnabled: PropTypes.bool,
  onDatePicker: PropTypes.func,
  onEndDatePicker: PropTypes.func,
  startDateVal: PropTypes.string,
  endDate: PropTypes.string,
  endDateVal: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showDatePicker: PropTypes.bool,
  showEndDatePicker: PropTypes.bool,
  onDateSelected: PropTypes.func,
  onEndDateSelected: PropTypes.func,
  onToggleEndEnable: PropTypes.bool,
  isEndEnabled: PropTypes.bool,
  dateArray: PropTypes.array.isRequired,
  startDateIndex: PropTypes.number.isRequired,
  endDateIndex: PropTypes.number.isRequired,
  teeupEventsByDay: PropTypes.object,
  duration: PropTypes.number,
}

export default TimezonesViewDate
