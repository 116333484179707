import React from "react"
import classnames from "classnames"
import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"

import "./header.scss"

// tabs is an array of objects { id: string, name: string }
const SuggestModalHeader = ({
  title,
  activeTab,
  handleTabClick,
  handleClose,
  tabs = [],
}) => {
  return (
    <div className="suggest-modal__header">
      <div className="suggest-modal__header-title">{title}</div>
      <div className="suggest-modal__header-tabs">
        {tabs.map(({ id, name }) => (
          <div
            className={classnames("suggest-modal__header-tab", {
              active: activeTab === id,
            })}
            key={id}
            onClick={() => handleTabClick(id)}
          >
            <span>{name}</span>
          </div>
        ))}
      </div>
      <div
        className="suggest-modal__header-close"
        onClick={() => handleClose()}
      >
        <ReactSVG src={images.ndCloseXIcon} />
      </div>
    </div>
  )
}

export default SuggestModalHeader
