import React from "react"

import OverviewConversation from "../../../components/TeeUpsOverview/OverviewConversation"

import "./teeUpPageCenterSection.scss"

const TeeUpPageCenterSection = () => {
  return (
    <div className="teeup-page__center">
      <OverviewConversation newDesign />
    </div>
  )
}

export default TeeUpPageCenterSection
