const Translation = {
  systemButtons: {
    ok: "OK",
    askMeLater: "Ask Me Later",
    cancel: "Cancel",
    clear: "Clear",
    send: "Send",
    save: "Save",
    back: "Back",
    next: "Next",
    done: "Done",
    select: "Select",
    close: "Close",
    gotIt: "Got It!",
    search: "Search",
    more: "More",
    create: "Create",
    add: "Add",
    edit: "Edit",
    undo: "Undo",
    keepInActive: "Keep in Active",
    continue: "Continue",
    ignore: "Ignore",
    changeIt: "Change it",
    change: "Change",
    respond: "Respond",
    view: "View",
    notNow: "Not now",
    copyText: "Copy text",
  },
  teeupListPrompts: {
    organizer: "You're the organizer",
    afterDecline: "You declined this TeeUp, join it now?",
    afterDroppout: "You dropped out of this TeeUp, rejoin it now?",
    archive: "Archive this TeeUp",
    isUndecidedWhenInGameplan:
      "Your Action is Required to Decide on the date/time",
    mention: "mentioned you in conversation",
    suggestion: "does this work for you?",
    areYouGoing: "asked are you going?",
    whereAreYou: "asked where are you?",
    nudgeRespondedTo: "responded to your nudge",
  },
  viewInHighlights: "View in highlights",
  nudgePromptContent: {
    inexact: {
      text: "Decided on an inexact date/time means Coo-e cannot provide TeeUp participants with calendaring support",
      action: "Suggest Date/Time",
    },
    undecided: {
      action: "Mark Decided",
    },
    setTeeupToAllset: {
      action: "All Set",
      text: "The Game Plan is all decided do you want to let everyone know the TeeUp is All Set?",
    },
    setTeeupToEnded: {
      action: "Ended",
      text: "The TeeUp appears to have ended, do you want to set it as Ended?",
    },
    areYouGoing: {
      action: "Are you going?",
    },
    whereAreYou: {
      action: "Where are you?",
    },
    suggestion: {
      action: "Does this work for you?",
    },
    mentionUser: {
      action: "Mention User",
    },
  },
  setTeeupToAllset:
    "You have changed the TeeUp to All Set and the Game Plan has been been marked as Decided",
  emptyWhereWhenSettingAllset:
    "There is no Where on the Game Plan. Are you sure you want to mark the TeeUp as All Set?",
  changeToHappening:
    "Are you sure you want to switch the TeeUp to Happening Now?",
  backToPlanning: {
    success: "Are you sure you want to switch the TeeUp back to Planning?",
    warning:
      "An Ended TeeUp cannot be changed to %{newState} it can only be changed back to Planning",
  },
  emptyMessageError: "You can't send empty message.",
  teeup: {
    replies: {
      replyTo: "Replying to %{username}",
      commentOn: "Commenting on",
    },
    toastMsg: {
      toActive: "%{teeupName} moved to Active",
      toArchive: "%{teeupName} moved to Archive",
      toTrash: "%{teeupName} moved to Trash",
      decline: "You Declined this TeeUp and it has been moved to the Archive",
      dropout:
        "You Dropped out of the TeeuUp and it has been moved to the Archive",
      forbiddenDecideGP: `You don't have permissions to change Game Plan`,
    },
  },
  markAllAsRead: "Mark all as Read",
  markAllTeeupsAsRead: "Mark all Teeups as Read?",
  unpinFromPlan: "Unpin from Game Plan",
  editSuggestion: "Edit Suggestion",
  makeGameplan: "Make Game Plan",
  participants: "People",
  discussion: "Conversation",
  pinnedBy: "Pinned by %{userName}",
  pinboard: {
    removeFrom: "Remove from pinboard",
    addTo: "Add to Pinboard",
  },
  showInConversation: "Show in conversation",
  saySomething: "Say something...",
  settings: "Settings",
  basicInformation: "Basic Information",
  groupAndInvitees: "Group and Invitees",
  communityPublicVisibility: "Community (Public Visibility)",
  invitationMessage: "Invitation message (optional)",
  sendInvitation: "Send invitation",
  gameplan: "Game Plan",
  gameplanDefaults: "Game Plan Defaults",
  teeupNameHint: "TeeUp Name",
  teeupSettings: "TeeUp Options",
  teeupGlobalSettings: "Global TeeUp Settings",
  teeupMe: "Me and this Teeup",
  teeupTitle: "TeeUp Title",
  teeupPicture: "TeeUp Picture",
  teeupStateSettings: "TeeUp Progression Settings",
  orgsPermissions: "Organizers & Permissions",
  calendarOptions: "Calendar and Timezone Settings",
  dropoutOfTeeup: "Drop Out of TeeUp",
  cancelTeeup: "Cancel TeeUp",
  editOrganizers: "Edit Organizers",
  useCurrentLocation: "Use Current Location",
  critMass: {
    itsOnWith: "It’s On with %{amount}",
    moreNeeded: "%{amount} more needed!",
  },
  teeupSettingsParams: {
    automateEndedState: "Set to Ended after a period of time",
    timeToWait: "Time to wait",
    automateScheduledState:
      "Advance TeeUp to ‘All Set’ only if enough people are Going",
    peopleNeeded: "Minimum needed",
    peopleGoingNeeded: "Minimum going needed",
    automateNotifyBeforeHappening:
      "Notify participants before the TeeUp is set to “Happening Now”",
    whenToNotify: "When to notify",
  },
  syncTeeupToCalendar: "Sync TeeUp to Calendar",
  yourTimeZone: "Your Time Zone",
  inviteHint: "Invite by name, phone or email",
  invitationMessageHint: "Add a message to your invitees",
  suggestionsList: "Suggestions List",
  activeSuggestion: "Active Suggestion",
  emptyGameplan: {
    tooltipButton: "Got it",
    tooltipText:
      "Suggestions placed here become the Game Plan, and can then be marked as decided",
  },

  gameplanFooter:
    "Game Plan Options allow you to make additional rows, rename rows, and reorder them to suit your needs.",
  invitationMessageFooter:
    "You can include an optional message to describe your TeeUp a bit more to your invitees.",
  gameplanOptions: "Game Plan Options",
  suggestYour: "Make a new suggestion",
  suggestWhen: "Suggest When",
  suggestWhere: "Suggest Where",
  selectAnyDatesTooltip:
    "Select any dates that you would like to poll your participants for WhenWorks.",
  tapAddTimeTooltip:
    "Tap here to select time options for your poll on this day. Repeat for all of your selected day choices",
  whenWorksTitle: "Setup WhenWorks",
  whenWorksLabel: "When Works",
  whenWorksDialogTitle: `Also remove "%{date}" from the poll?`,
  whenWorksDialogDescr: `You have removed all times on this date from the poll`,
  whenWorksConfiguration: "Your WhenWorks configuration:",
  emptyWhenWorks: "Select a date to get started",
  configuringWhenWorks: "Configuring WhenWorks",
  pollingDates: "Polling %{dates} Dates",
  pollingDatesAndTime: "Polling %{dates} Dates and %{time} Time",
  selectDatesAndTime: "Select Date and Time Options",
  addTimeBtn: "+ Add Time",
  suggest: "Suggest",
  newTeeup: "New TeeUp",
  suggestion: "Suggestion",
  suggestionDetails: "Suggestion Details",
  commentedOn: "Commented on",
  startTime: "Start Time",
  currentTime: "Current Time",
  loginGreetings: {
    login: "Welcome to our private beta! Select an option below to continue:",
    email: "Great! Enter the email address where you received the invitation.",
    phone: "Great! Enter the phone number where you received the invitation.",
    tellUs:
      "Great! Enter the same email address that you registered in TellUs below to continue:",
    signUp: "Sign up and create an account",
  },
  loginGreetingsErrors: {
    email: `We're sorry. We don't have an invitation to this email. Please check the email address you entered.`,
    phone: `We're sorry. We don't have an invitation to this number. Please check the number you entered.`,
    alreadyRegistered: `The %{type} is already used in another account`,
  },
  emailOrPhone: "Email or phone number",
  emailAddress: "Email address",
  phoneNumber: "Phone number",
  verificationCode: "Verification Code",
  countryCode: "Country Code",
  betaVerifyGreeting: {
    congrats: "Congratulations! You have been invited.",
    sendCode: `We'll now send a verification number to this %{type}`,
  },
  enterCode: "Enter Code",
  loginInfo: "Login Info",
  calendar: {
    toastMsg: {
      unavaliable: "No calendars avaliable on this device.",
      noPermissions: "Please enable calendar permissions via settings.",
      notFound:
        "Could not find the selected calendar. Please select an existing calendar via profile -> Calendar Sync.",
      authorizeManually:
        "Please add calendar permissions via settings so TeeUps can be synced.",
    },
    alerts: {
      permissionTitle: "Application Calendar Permission",
      permissionMsg:
        "Application needs access to your calendar " +
        "so you can sync it with teeups.",
    },
    calendar: "Calendar",
  },
  calendarSettings: "Calendars & Time-zones",
  version: "Version",
  updatesAvailable: "Updates available",
  syncTellus: "Sync Contacts to Tellus",
  syncedTellusSuccess: "Contacts Synced to TellUs!",
  completeTellUsSurvey:
    "Complete the Tellus survey to unlock the Coo-e app and continue with beta participation.",
  teeupListHeader:
    "The list automatically sorts to show you the most relevant TeeUp at the top.",
  sortingBy: "Sorting TeeUps by",
  smartSort: "Smart Sort",
  teeupStatus: "TeeUp State",
  status: "TeeUp State",
  userStatus: "My Status",
  createdAt: "Created Date",
  createdat: "Created Date",
  noAlertsPrimary: "No alerts at this time",
  noAlertsSecondary: "You’ll receive alerts from TeeUp activity",
  viewMyTeeups: "View my TeeUps",
  invitationHistory: "Invitation history",
  invitationHistoryMessage: "Invitation Message",
  invitees: "Invitees",
  invited: "invited",
  you: "You",
  noResults: "No results found",
  beFirstToAdd: "Be the first to add",
  newlyFollowing: "Newly Following",
  otherInterests: "Other interests",
  popular: "Popular",
  nowReceivingNotifications: "Now receiving notifications for “%{name}”",
  notReceivingNotifications: "Not receiving notifications for “%{name}”",
  nowFollowingPrivately: "Now following “%{name}” privately",
  notFollowingPrivately: "Now following “%{name}” publicly",
  notFollowing: "Not following “%{name}” anymore",
  attachFile: "Attach a file",
  browseGallery: "Browse gallery",
  browseFiles: "Browse all files",
  recentFiles: "Recent files",
  maxAttachedImages: "You can only send 4 images per message",
  attachFilesWarning:
    "You cannot send both files and images in the same message",
  respondToPoll: "Respond to Poll",
  youAreVip: "You are a VIP to this TeeUp",
  changeVIPstatus: "Only organiser can change user's VIP status",
  cancelTeeupAlert: {
    title: "Cancel TeeUp?",
    description:
      'Are you sure you want to cancel "%{name}"? This will end the TeeUp for all participants and be archived. This action cannot be undone.',
    cancelTeeUpAndArchive: "Cancel TeeUp and Archive",
    goBack: "Go Back",
  },

  // Game plans
  specificDate: "Specific Date",
  specificTime: "Specific Time",

  // interest
  interestCommunity: "Interest Community",
  followThisInterest: "Follow this interest",
  allInterested: "All Interested",
  groups: "Groups",
  teeups: "TeeUps",
  comments: "Comments",
  postComment: "Post a comment",
  commentThread: "Comment Thread",
  community: "Community",
  filterByInterest: "Filter by Interest",
  selectTeeUp: "Select TeeUp",
  selectLocation: "Select Location",
  interestInTeeupRestriction:
    "Community TeeUp needs location and interests added.",
  interestInGroupRestriction: "Location is required for groups with interests.",

  forYou: "Matches For You",
  myCooes: `My Coo-e's`,
  emptyCooesList: `You haven't made any Coo-e's`,
  emptyCooesInfo: `Looking do something with other? Put a call out to the Coo-e-verse to help them find you!`,
  profile: "Profile",

  //Groups
  selectGroup: "Select Group",
  userGroups: "Your Groups",
  removeLocation: "Location removed.",
  removeLocationError: "Something went wrong. Cant remove the location",
  createLocationError: "Something went wrong. Cant create the location",
  movedToActive: "Teeup was moved to Active section",
  movedToArchive: "Teeup was moved to Archived section",
  newRequest: {
    title: "Coo-e the Community",
    messagePlaceholder: "I'm looking for...",
    addMedia: "Add media",
    postingFor: "Posting for %{name}",
    visibleOn: {
      myself: "Visible on my profile",
      group: "Visible on group page",
      teeup: "Visible on TeeUp summary",
    },
    Myself: "Myself",
    myGroup: "My Group",
    myTeeup: "My Teeup",
    expiration: "Expiration",
    onCreate: "New request was successfully created.",
    creationFailed: "Creation failed. Something went wrong.",
  },
  createTeeup: {
    createDecidedError:
      "The suggestion needs to be on the Game Plan to set it as decided",
  },
  pinnedMessagesScreen: {
    filters: {
      showAll: "Show all",
      messages: "Messages",
      pictures: "Pictures",
      files: "Files",
    },
    unpin: "removed from Pinboard",
    pin: "Added to Pinboard",
  },
  mentionsScreen: {
    mentionsTitle: "No mentions yet",
    mentionsText:
      "You have no been mentioned directly in a message yet. If you are, this is where you can easily view those messages.",
  },
  noSelectablePlaces: "No selectable places here",
  locationAlerts: {
    locationRadiusComboTitle: "Location Already Saved",
    locationRadiusComboMesage:
      'You already saved  %{name} - %{radius}mi as "%{label}"',
    diffLocationSameLabelTitle: "Label in Use",
    diffLocationSameLabelMesage:
      '"%{label}" is already assigned to %{name} - %{radius}mi. \n\n Would you like to reassign it to this location instead?',
    sameLocationsDiffRadiusTitle: "Location Already Saved",
    sameLocationsDiffRadiusMessage:
      "You already saved %{name} - %{radius}mi to your locations. \n\n Would you like to change this existing location's radius to %{newRadius}mi?",
  },

  location: {
    changeLocationTitle: "Change Location",
    primaryLocationTitle: "Primary Location",
    noPermissionTitle: "No Location Access",
    noPermissionDescription:
      "To use your current location, allow us to use your location in\nSettings > Coo-e > Location.",
  },
  polls: {
    newOrganiserPoll: {
      text: "The organizer is taking a poll to find out the best time to meet.  Cast your vote now!",
      buttonTitle: "Respond to Poll",
    },
    everyoneResponded: {
      text: "Everyone has responded to the poll. Close the poll now to select the best time!",
      buttonTitle: "Close Poll",
    },
    allVipsResponded: {
      text: "Your VIPs have all responded to the poll. You can close the poll now to select the best time, or wait for everyone else.",
      buttonTitle: "Close Poll",
    },
  },

  //noInternet
  noInternet: {
    title: "No Internet Connection",
    message: "Connect to the internet to use Coo-e",
  },
  participantsFilter: {
    title: "Sort People by:",
    lastActivity: "Last activity",
    status: "Status",
    name: "Name",
  },
  participantCard: {
    invitation: {
      label: "Invite",
    },
    nudges: {
      areYouGoing: "Ask people if they are going?",
      whereAreYou: "Ask people where are they?",
    },
  },
  participantDetails: {
    nudges: {
      whereAreYou: "Ask Where are you?",
      areYouGoing: "Ask Are you going?",
      mentionUser: "Mention User",
    },
    viewProfile: "View User Profile",
    changeStatus: "Change Status",
  },
  determiningWhenWorks: "Determining WhenWorks",
  decisionWhenWorks: "Decision WhenWorks",
  periodWhenWorks: `%{startDate} to %{endDate}`,
  noResponseYet: "No Response Yet",
  closePoll: "Close poll",
  contactDetails: "Contact Details",
  pollStatuses: {
    closedPoll: "Poll closed",
    polling: "Polling",
    scheduled: "Scheduled",
    completed: "Completed",
  },
  timezonesNotificationTitle: "Multiple Time Zones Detected",
  showTimesAndOther: "Show times in other time zones",
  inconvenientTime: "May be an inconvenient time",
  rememberToSelect:
    "Remember to select times based your own timezone. We will translate for participants.",
  timezonesNotificationDescr:
    "Your TeeUp has people in more than one time zone. Look for our helper icons to aid in selecting the best time options.",
  whenWorksResponded: `%{answered}/%{count} responded`,
  nudgeResponded: {
    worksForMe: "Works for me",
    doesntWork: "Does not work",
    userAskedMe: "%{username} asked me",
    doesItWork: "Does this work for you?",
    positiveResponse: "This %{still}works for me",
    negativeResponse: `This doesn't work for me`,
  },
  selectPeopleToNudge: "Select People to Nudge",
  selectPeopleToMention: "People to Mention",
  gameplans: {
    withdrawSuggestion: "Withdraw Suggestion",
    restoreSuggestion: "Restore Suggestion",
    pastSuggestion: "A suggestion in the past cannot be moved to the Game Plan",
    dropToChange: "Drop here to change Game Plan",
    dragToRemove: "Drag here to remove the Suggestion from the Game Plan",
    dragAway: "Drag Suggestion away to remove it from Game Plan",
    moveToGameplan: "Move to Game Plan",
    removeFromGameplan: "Remove from Game Plan",
    markDecided: "Mark as Decided",
    markNotDecided: "Mark Not-decided",
    setAsDecided: "Set %{type} as Decided",
    setAsNotDecided: "Set %{type} as Undecided",
    nudgeAboutSuggestion: "Nudge People About This",
    viewOnMap: "View on map",
    commentOnSuggestion: "Comment on Suggestion",
    goToSuggestionsList: "Go to Suggestions List",
    availability: "AVAILABILITY",
    newSuggestion: "NEW SUGGESTION",
    suggestion: "SUGGESTION",
    noSuggestions: "NO SUGGESTIONS",
    decided: "DECIDED",
    undecided: "UNDECIDED",
  },
  teeupStatusNudgeTitle: "%{creator} nudged me",
  nudgeUserText: "%{creator} asked %{askedUsers}",
  nudgeTimestamp: "Nudged %{time}",
  iResponded: "I responded",
  emptySuggestionList: {
    noGamePlanYet: "No Game Plan yet",
    title: "No {title} suggestion yet",
    text: "Suggest something for {title}, discuss it with other participants, and move it to Game Plan to make TeeUp happen",
  },
  singleSelectedSuggestion: {
    title: "No Alternative Suggestions",
    text: "Suggest something else for {title} and discuss it with others.",
  },
  singleDecidedSuggestion: {
    title: "{title} is Decided",
    text: "No need to suggest anymore. Just enjoy your TeeUp!",
  },
  notImplemented: "Currently not implemented",
  tzAndCalendar: {
    settingsCalendarSubtitle:
      "Primary and display calendars, and home time-zone",
    settingsTZSubtitle: "Time Zone Privacy Setting",
    syncToCalendar: {
      title: "Sync TeeUps to Calendar",
      details: `Send all TeeUps' Game Plan details to your calendar. Exact Date/Time required.`,
    },
    primaryCalendar: {
      title: "Select Primary Calendar",
      details: "External Calendar you want to insert your TeeUps",
    },
    additionalCalendars: {
      title: "Select Additional Calendars",
      details:
        "Select calendars that will be displayed and used to help determine scheduling conflicts",
    },
    syncOnlyAllSet: {
      title: "Only update calendar when TeeUp is All Set",
    },
    homeTZ: {
      title: "Home Time Zone",
      details:
        "Set your home time-zone to allow the app to help you manage your TeeUps while traveling",
      notSet: "Home time-zone is not set",
    },
    activeTZ: {
      title: "Active Time Zone",
      details: "This Time-zone will be used when creating and joining TeeUps",
      home: "Home",
      travelling: "Travelling",
      manual: "Manual Override",
      detected: "Detected Automatically",
    },
    useWhenCreating: {
      title: "Use the current time-zone when creating TeeUps",
    },
    useWhenJoining: {
      title: "Use the current time-zone when joining TeeUps",
    },
  },
  loginDetails: {
    loginSettingSubtitle: "Manage account and login details",
    connectedAccsSubtitle: "Connected accounts",
    signInMethod: {
      title: "Sign-in Method",
    },
    changePassword: {
      title: "Change Password",
    },
    logout: {
      title: "Logout",
    },
    deactivate: {
      title: "Deactivate Account",
    },
    accNotLinked: "Not Linked",
  },
  contactSync: {
    syncSettings: {
      title: "Sync Contacts",
      lastSync: `Last synced {lastSynced}`,
      neverSynced: "Contacts have never been synced",
    },
  },
  policiesAndServices: {
    settingsTitle: "Policies & Services",
    privacyPolicy: {
      title: "Privacy Policy",
    },
    termsOfService: {
      title: "Terms of Service",
    },
    aboutCooe: {
      title: "About Coo-E",
    },
    licenses: {
      title: "Licenses",
    },
  },
  bugReportingAndFeedback: {
    settingsTitle: "Bug Reporting & Feedback",
    bugReport: {
      title: "Submit a bug report",
    },
    featureRequest: {
      title: "Feature request",
    },
    coeeResearch: {
      title: "Coo-e Research",
    },
  },
  contactMethods: {
    settingsTitle: "Coo-e Linked Contact Methods",
    primaryContactMethodsTitle: "Primary Contact Methods",
    primaryContactMethodsPurpose:
      "Primary contact methods are used for account recovery and service messages.",
    additionalContactMethodsTitle: "Additional Contact Methods",
    additionalContactMethodsPurpose:
      "Primary contact methods along with verified contact methods are used to receive invitations.",
    primaryTitle: {
      email: "Primary Email",
      phone: "Primary Mobile Phone",
    },
    additionalTitle: {
      email: "Email",
      phone: "Mobile Phone",
    },
    addNewTitle: {
      email: "Add Email",
      phone: "Add Mobile Phone",
    },
  },
  profileOverview: {
    publicContacInfo: "Public contact information",
    name: "Name",
    displayName: "Display Name",
    editName: "Edit Full Name",
    editDisplayName: "Edit Nickname",
    selectAvatar: "Select Avatar",
    selectBackground: "Select Background",
    teeupAlert: "You are going to close the current TeeUp and open this one",
    openTeeup: "Open TeeUp",
    contactInfo: "CONTACT INFO",
    sharedTeeups: "SHARED TEEUPS",
    publicInformation: "PUBLIC INFORMATION",
  },
  editProfile: {
    editProfile: "Edit Profile",
    publicProfile: "Public Profile",
    name: "Name",
    displayName: "Display Name",
    bio: "Bio",
    hometown: "Home Town / Location",
    addPhoneNumber: "Add Phone Number",
    addEmail: "Add Email",
    addAddress: "Add Address",
    addUrl: "Add Website / URL",
    emailPhoneInfo:
      "Email addresses and phone numbers must first be added as verified contact methods before you can select them.",
    publicInfo:
      "Items added to Public Information is accessible to all users of the application. Anyone viewing your User Profile can see this information.",
    selectPhone: "Select Phone Number",
    selectEmail: "Select Email Address",
  },
  notifications: {
    invitationToTeeUp: "Notify me when I'm Invited to a TeeUp",
    teeUpStateChange: "Notify me about TeeUp State Change",
    teeupComingUpSoon: "Notify me when TeeUp is Coming Up Soon",
    organizerStatusChanged:
      "Notify me about User Organizer status for TeeUp Changes",
    teeupAdvanceMode: "Notify me about TeeUp Advance Mode Stuff",
    newMessage: "Notify me about all Chat Messages",
    mentionInMessage: "Notify me when I am @ Mentioned in Conversation",
    directMessage: "Notify me about Direct Messages",
    newReplyAndComment:
      "Notify me about reply / comment on my message / suggestion",
    gamePlanChanged: "Notify me about TeeUp Game Plan Change",
    suggestionOps: "Suggestion Added",
    gamePlanDecided: "Notify me about TeeUp Game Plan Decided State Change",
    reactionToSuggestion: "Notify me when somebody reacted on My Suggestion",
  },
  notificationsSettings: {
    settingsTitle: "Notification Settings",
    notificationLevelTitle: "Notification Level",
    applicationSounds: {
      title: "Application sounds",
      details: `This only controls the sounds made by the application during use. The notification settings and sound is separate.`,
    },
    notificationLevel: {
      title: "Notification Level",
    },
    appSoundsSettings: {
      title: "Open system app sound settings",
      details: `Open the system sound settings for this application`,
    },
    muteEverything: {
      title: "Mute Everything",
      details: `Turn off all notifications`,
    },
    importantOnly: {
      title: "Important Notifications Only",
      details: {
        listTitle: "You will be notified about:",
        directedMsg: "• Directed messages and replies",
        GPandStageChanges: "• Game Plan and TeeUp stage changes",
        teeupInvites: "• TeeUp Invites",
      },
    },
    conversation: {
      title: "Conversation Notifications",
      details: {
        listTitle:
          "You will be notified about everything listed above and the following:",
        chatMsg: "• New chat messages",
        suggestions: "• New suggestions",
      },
    },
    everything: {
      title: "Everything",
      details: {
        listTitle: `Warning — This may cause you to receive a lot of notifications. You will receive notifications for everything listed above and from the following:`,
        reactions: "• Reactions to suggestions",
        userStatusChanges: "• User status changes",
        pinned: "• Pinned and unpinned items",
        withdrawnSuggestion: "• When suggestions are withdrawn",
      },
    },
  },
  teeupActivity: {
    changeUserStatus: "Changed status to {{status}}",
    addSuggestion: "Added a suggestion for {{row}}",
    withdrewSuggestion: "Withdrew a suggestion",
    commentOnSuggestion: "Commented on a suggestion",
    changeGamePlan: "Changed {{row}} on the Game Plan",
    setSuggestionAsDecided: "Set {{row}} as decided",
    setSuggestionAsUndecided: "Set {{row}} as undecided",
    createTeeup: "Started TeeUp",
    joinTeeup: "Joined the TeeUp",
    changeTeeupStatus: "Set the TeeUp to {{status}}",
    cancelTeeup: "Canceled the TeeUp",
    inviteToTeeup: "Invited {{names}}",
    invitePeopleToTeeup: "Invited {{count}} people",
    sentMessage: "Conversation message",
    sentPicture: "Posted an image",
    sentFile: "Uploaded a file",
  },
  suggestionOnlineLinks: {
    topic: "Topic",
    roomOwner: "Room Owner",
    meetingId: "Meeting ID",
    password: "Password",
    passcode: "Passcode",
    number: "Number",
    oneTouchNumber: "One Touch Number",
    accessCode: "Access Code",
  },
  notificationCategories: {
    teeup: "Teeup Notifications",
    conversation: "Conversation Notifications",
    gamePlan: "Game Plan Notifications",
  },
  notificationSounds: {
    magpie_bird_call: "Bird Call",
    water_droplets: "Water Droplets",
    whistle: "Coo-e Whistle",
  },
  notificationSoundSettings: {
    muteAll: "Mute all sounds",
    default: "Coo-e Default",
    custom: "Custom",
  },
  notificationSoundMode: {
    default: "Default",
    custom: "Custom",
    none: "None",
  },
}

export default Translation
