import { notificationLevels } from "@configs/enums"

const disableAllPreset = {
  mentionInMessage: false,
  newSuggestionComment: false,
  newMessageReply: false,
  gamePlanChanged: false,
  gamePlanDecided: false,
  newSuggestion: false,
  teeUpStateChange: false,
  invitationToTeeUp: false,
  newMessage: false,
  reactionToSuggestion: false,
  userStatusChanges: false,
  pinnedUnpinned: false,
  suggestionWithdrawn: false,
  teeupChanged: false,
  joinGroupRequest: false,
  newFriendRequest: false,
}

const importantPreset = {
  mentionInMessage: true,
  newSuggestionComment: true,
  newMessageReply: true,
  gamePlanChanged: true,
  gamePlanDecided: true,
  newSuggestion: true,
  teeUpStateChange: true,
  invitationToTeeUp: true,
}

const conversationPreset = {
  newMessage: true,
  newSuggestion: true,
}

const everythingPreset = {
  reactionToSuggestion: true,
  userStatusChanges: true,
  pinnedUnpinned: true,
  suggestionWithdrawn: true,
  teeupChanged: true,
  joinGroupRequest: true,
  newFriendRequest: true,
}

export const notificationPresets = {
  teeup: [
    "invitationToTeeUp",
    "teeUpStateChange",
    "teeupComingUpSoon",
    // 'organizerStatusChanged',
    // 'teeupAdvanceMode',
  ],
  conversation: [
    "newMessage",
    "mentionInMessage",
    // 'directMessage',
    "newReplyAndComment", // pseudo key
  ],
  gamePlan: [
    "gamePlanChanged",
    "suggestionOps", //pseudo key
    "gamePlanDecided",
    "reactionToSuggestion",
  ],
}

export const groupedNotifications = {
  suggestionOps: [
    "newSuggestion",
    "suggestionWithdrawn",
    "suggestionChanged",
    "suggestionRemoved",
  ],
  newReplyAndComment: ["newMessageReply", "newSuggestionComment"],
}

export const getRealNotificationKeys = (originalKey) => {
  return groupedNotifications[originalKey] || [originalKey]
}

export const getNotificationSettingsByLevel = (level) => {
  switch (level) {
    case notificationLevels.muteEverything: {
      return disableAllPreset
    }
    case notificationLevels.importantOnly: {
      return {
        ...disableAllPreset,
        ...importantPreset,
      }
    }
    case notificationLevels.conversation: {
      return {
        ...disableAllPreset,
        ...importantPreset,
        ...conversationPreset,
      }
    }
    case notificationLevels.everything: {
      return {
        ...disableAllPreset,
        ...importantPreset,
        ...conversationPreset,
        ...everythingPreset,
      }
    }
  }
}
