import { StyleSheet } from "react-native"

import { AppFonts, Units, AppColors } from "@theme/"

export const styles = StyleSheet.create({
  container: { flexDirection: "row", flexShrink: 1, flexGrow: 0 },
  statusBlock: ({ isCurrentUserReact }) => ({
    alignItems: "center",
    justifyContent: "center",
    width: Units.responsiveValue(28),
    height: Units.responsiveValue(48),
    padding: Units.responsiveValue(4),
    borderRadius: Units.responsiveValue(10),
    opacity: isCurrentUserReact ? 0.7 : 1,
    backgroundColor: AppColors.brand.white,
  }),
  countText: ({ isCurrentUserReact }) => ({
    ...AppFonts.biggerDemibold,
    opacity: isCurrentUserReact ? 0.7 : 1,
    color: AppColors.brand.warmGrey3,
  }),
  additionalMargin: { marginLeft: Units.responsiveValue(4) },
})
