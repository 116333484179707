import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
// import teeupActions from '@actions/teeupActions'
import { selectActiveTeeupId } from "@selectors/activeTeeup"
// import ProgressCircle from 'react-native-progress-circle'
import { View, Text, TouchableOpacity, Image } from "react-native"
import { AppColors } from "@theme"
import { images } from "@utils/imageUtils"
import { getFileSizeFromBytes } from "@utils/fileUtils"
import styles from "./templateFileMessageStyles"

const TemplateFileMessage = (props) => {
  const {
    item,
    uploadFiles,
    messageSend,
    onFileRetry,
    onFileUploadCancel,
    removeTemporaryMessages,
    teeupId,
  } = props
  const [percent, setPercent] = useState(0)
  const [error, setError] = useState(false)
  const uploadedFile = uploadFiles.find(
    (file) => file.fileName === item.fileName
  )

  useEffect(() => {
    if (uploadedFile && uploadedFile?.error) {
      setError(true)
    }
  }, [uploadFiles])

  if (uploadedFile) {
    uploadedFile.request.upload.onprogress = (e) =>
      setPercent(Math.floor((e.loaded / e.total) * 100))
  }

  const onRetryPress = () => {
    onFileRetry(uploadedFile.fileName)
    setPercent(0)
    setError(false)
    setTimeout(() => {
      messageSend({ ...item.attachedFile })
    }, 100)
  }

  const onCancelPress = async () => {
    if (uploadedFile) {
      await uploadedFile.request.abort()
      removeTemporaryMessages(teeupId)
      onFileUploadCancel(uploadedFile.fileName)
    }
  }

  const { size, sizeType } = getFileSizeFromBytes(item.fileSize, true, false)

  return (
    <View style={styles.templateMessageContainer}>
      {error ? (
        <View style={styles.fileSendFailWrapper}>
          <Image source={images.fileSendFail} />
        </View>
      ) : null
      // <ProgressCircle
      //     percent={percent}
      //     radius={24}
      //     borderWidth={2}
      //     color={AppColors.brand.darkBlue}
      //     shadowColor={AppColors.brand.lightBlue4}
      //     bgColor={AppColors.brand.lightBlue4}
      // >
      //     <Text style={styles.uploadText}>{percent}%</Text>
      // </ProgressCircle>
      }
      <View style={styles.descriptionWrapper}>
        <Text style={styles.uploadText}>
          {error ? "Upload failed:" : "Uploading:"}
        </Text>
        <Text style={styles.fileText}>{item.fileName}</Text>
        {error ? (
          <TouchableOpacity onPress={onRetryPress}>
            <Text style={styles.retryText}>Retry</Text>
          </TouchableOpacity>
        ) : (
          <Text style={styles.fileText}>
            {size}
            {sizeType}
          </Text>
        )}
      </View>
      {percent !== 100 && (
        <TouchableOpacity onPress={onCancelPress}>
          <Image source={images.pinkClose} />
        </TouchableOpacity>
      )}
    </View>
  )
}

TemplateFileMessage.propTypes = {
  item: PropTypes.object,
  uploadFiles: PropTypes.array,
  messageSend: PropTypes.func,
  onFileRetry: PropTypes.func,
  onFileUploadCancel: PropTypes.func,
  removeTemporaryMessages: PropTypes.func,
  teeupId: PropTypes.number,
}

const mapStateToProps = (state) => {
  const teeupId = selectActiveTeeupId(state)

  return {
    teeupId,
  }
}

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//         {
//             ...teeupActions,
//         },
//         dispatch
//     )

export default connect(mapStateToProps, null)(TemplateFileMessage)
