import React, { useState, useMemo, useEffect } from "react"
import { connect } from "react-redux"

import cn from "classnames"

import TeeupItem from "../teeupList/TeeupItem"
import get from "lodash/get"
import find from "lodash/find"
import {
  DEFAULT_SORT,
  initialFiltersValues,
  teeupsFiltering,
  teeupSorting,
} from "@utils/teeupUtils"
import {
  selectTeeupsParticipants,
  selectTeeupsPeople,
  selectTeeupsGameplans,
} from "../../selectors/teeups"
import { selectActiveTeeupId, selectActiveTeeup } from "@selectors/activeTeeup"
import { selectUnseenTeeups } from "@selectors/teeups"
import { selectTeeupInvitationsRequests } from "../../selectors/requests"
import { selectUserId, selectToken } from "../../selectors/user"
import { selectNotificationTeeups, selectTeeupSearch } from "@selectors/common"
import {
  selectActiveTeeups,
  selectArchivedTeeups,
  selectRemovedTeeups,
} from "../../selectors/teeups"
import {
  setTeeupActive,
  deleteTeeup,
} from "@actions/activeTeeupActions"
import { teeupUserStatusPriorities } from "@configs/enums"
import { userStatusTypes } from "@config/mappings"
import { images } from "@utils/imageUtils"
import {
  updateTeeupArchiveStatus,
  updateIsArchivePromptShow,
  fetchGameplanOptions,
  fetchTeeupUsersById,
} from "@actions/teeupActions"

import "./index.scss"

// create custom forceUpdate() hook
const useForceUpdate = () => {
  const [value, setValue] = useState(0)

  return () => setValue(value => value + 1)
}

const ContactBookInviteToTeeup = ({
  closeInviteToTeeupModal,
  teeupParticipants,
  teeupInvitations,
  teeupPeople,
  teeupGameplans,
  userId,
  activeTeeupId,
  notifications,
  notificationTeeups,
  activeTeeups,
  teeupsGameplans,
  teeupFilter,
  archiveTeeup,
  moveTeeupToTrash,
  moveToActive,
  teeupsMode,
  updateGameplan,
  updateActiveTeeup,
  gotTeeupParts,
  handleOnInviteesDone,
  setActiveTeeup,
  updateTeeupIsArchivePromptShow,
  loading,
  resetInvitees
}) => {
  const [isCrossHover, setIsCrossHover] = useState(false)
  const [teeupsFilter, setTeeupsFilter] = useState("")
  const [sorting, setSorting] = useState(DEFAULT_SORT)
  const [filtering, setFiltering] = useState(initialFiltersValues)
  const [filteredTeeUpsData, setFilteredTeeUpsData] = useState([])
  const [isSortMostRecent, setIsSortMostRecent] = useState(false)

  useEffect(() => {
    getFilteredTeeUps(teeupsFilter)
  }, [])

  useEffect(() => {
    getFilteredTeeUps(teeupsFilter)
  }, [teeupsFilter])

  useEffect(() => {
    if (isSortMostRecent) {
      setIsSortMostRecent(filteredTeeUpsData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
    } else {
      getFilteredTeeUps(teeupsFilter)
    }
  }, [isSortMostRecent])

  const getFilteredTeeUps = (teeupsFilter) => {
    setFilteredTeeUpsData(filterAndSortedData()
      .filter(
        (teeup) =>
          teeup.name
            .toLowerCase()
            .includes(teeupsFilter.toLowerCase()) &&
          teeup.status !== "ended"
    )) // load and filter teeups
  }

  const forceUpdate = useForceUpdate()

  const handleRespondToInvitation = (teeup) => (response) => {
    const { id: teeupId } = teeup
    const statusId = teeupUserStatusPriorities.joined

    if (response) {
      this.props
        .updateUserStatus(teeupId, statusId)
        .then(async (error) => {
          setTeeupActive(teeup)
          updateActiveTeeup({
            id: teeupId,
            userStatus: userStatusTypes[statusId],
          })
          const teeupUsers = await fetchTeeupUsersById(teeupId)
          gotTeeupParts([teeupUsers])
          updateGameplan(teeup.id, await fetchGameplanOptions(teeupId))

          if (error) {
            console.log("handleRespondToInvitation Error: ", {
              error,
            })
          }
        })
        .catch((error) => {
          console.log("handleRespondToInvitation catch ERROR: ", {
            error,
          })
        })
    } else {
      updateTeeupArchiveStatus(teeupId)
      archiveTeeup(teeupId)
    }

    setTimeout(() => {
      forceUpdate()
    }, 500)
  }

  const sortedList = useMemo(() => {
    const sorted = teeupSorting(activeTeeups, sorting, teeupsGameplans)
    return sorting.desc ? sorted.reverse() : sorted
  }, [sorting, activeTeeups])

  const onArchivePressed = (teeup) => {
    updateTeeupArchiveStatus(teeup.id)
    archiveTeeup(teeup.id)
  }

  const onTrashPressed = (teeup) => {
    deleteTeeup(teeup.id)
    moveTeeupToTrash(teeup)
  }

  const onMoveToActivePress = (teeup) => {
    moveToActive(teeup)
  }


  const handleKeepInTheListPress = (teeup) => {
    const { id: teeupId } = teeup

    updateTeeupIsArchivePromptShow({
      userId,
      teeup: { ...teeup, isArchivePromptShow: false },
    })
    updateIsArchivePromptShow(teeupId, userId, false)
  }

  const filterAndSortedData = () => {
    let teeupsData = teeupsFiltering(
      sortedList,
      filtering,
      userId,
      teeupsGameplans
    )

    if (notificationTeeups) {
      const notificationsArr = notifications.map(
        (notification) => notification.id
      )
      return teeupsData.filter((teeup) => notificationsArr.includes(teeup.id))
    }

    if (teeupFilter) {
      return teeupsData.filter(
        (teeup = {}) =>
          teeup.name && teeup.name.match(new RegExp(teeupFilter, "i"))
      )
    }

    return teeupsData
  }

  const renderItem = ({ item, index }) => {
    const { id, name } = item

    const teeups = filterAndSortedData().filter(
      (teeup) => teeup.status !== "ended"
    )

    const notificationsArr = notifications.map(
      (notification) => notification.id
    )
    return (
      <TeeupItem
        key={`teeupItem-${id}`}
        teeup={item}
        currentUserId={userId}
        invitation={find(teeupInvitations, ["teeupId", id], null)}
        participants={get(teeupParticipants, id, [])}
        people={get(teeupPeople, id, {})}
        gameplans={get(teeupGameplans, id, [])}
        onPress={() => selectTeeup(item)}
        onKeepInTheListPress={() => handleKeepInTheListPress(item)}
        onArchivePressed={() => onArchivePressed(item)}
        onTrashPressed={() => onTrashPressed(item)}
        onMoveToActivePress={() => onMoveToActivePress(item)}
        name={name}
        onRespondToInvitation={handleRespondToInvitation(item)}
        testID={`teeup-${id}`}
        isSelected={id === activeTeeupId}
        isLast={index + 1 === teeups.length}
        mode={teeupsMode}
        notificationsArr={notificationsArr}
        preventRedirecting
        preventHover
        fromContactBook={true}
        handleOnInviteesDone={handleOnInviteesDone}
        loading={loading}
      />
    )
  }

  const selectTeeup = (item) => {
    setActiveTeeup(item)
  }

  return (
    <div
      className="invite-to-teeup__wrapper"
      onClick={() => {
        resetInvitees()
        closeInviteToTeeupModal()
      }}
    >
      <div
        className="invite-to-teeup"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <h3 className="invite-to-teeup__title">Invite to TeeUp</h3>
        <div
          className="invite-to-teeup__cross"
          onClick={() => {
            resetInvitees()
            closeInviteToTeeupModal()
          }}
          onMouseOver={() => setIsCrossHover(true)}
          onMouseLeave={() => setIsCrossHover(false)}
        >
          <div
            className="invite-to-teeup__img"
            style={{
              backgroundImage:
                `url(${isCrossHover ? images.ndInviteesCrossActive : images.ndInviteesCross})`
            }}
          ></div>
        </div>

        <div className="invite-to-teeup__filter">
          <div className="invite-to-teeup__search">
            <img
              src={images.ndSearch}
            />
            <input
              type="text"
              className="invite-to-teeup__input"
              value={teeupsFilter}
              onChange={(e) => setTeeupsFilter(e.target.value)}
              placeholder="Search TeeUps"
            />
          </div>
          <div
            className={cn("invite-to-teeup__resent", {
              "invite-to-teeup__resent--active": isSortMostRecent,
            })}
            onClick={() => setIsSortMostRecent(!isSortMostRecent)}
          >
            <img
              src={images.ndSortIcon} style={{ marginRight: 6 }} />
            Most recent
          </div>
        </div>

        <div className="invite-to-teeup__content">
          {filteredTeeUpsData.map((item, index) => renderItem({ item, index }))}
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  const teeupParticipants = selectTeeupsParticipants(state)
  const teeupPeople = selectTeeupsPeople(state)
  const teeupGameplans = selectTeeupsGameplans(state)
  const activeTeeupId = selectActiveTeeupId(state)
  const notifications = selectUnseenTeeups(state)
  const teeupInvitations = selectTeeupInvitationsRequests(state)
  const userId = selectUserId(state)
  const notificationTeeups = selectNotificationTeeups(state)
  return {
    teeupParticipants,
    teeupInvitations,
    teeupPeople,
    teeupGameplans,
    userId,
    activeTeeupId,
    notifications,
    notificationTeeups,
    all: state,
    activeTeeups: selectActiveTeeups(state),
    archivedTeeups: selectArchivedTeeups(state),
    removedTeeups: selectRemovedTeeups(state),
    token: selectToken(state),
    userId: selectUserId(state),
    activeTeeUpId: selectActiveTeeupId(state),
    teeupsGameplans: selectTeeupsGameplans(state),
    activeTeeUp: selectActiveTeeup(state),
    teeupFilter: selectTeeupSearch(state),
    notificationTeeups: selectNotificationTeeups(state),
    notifications: selectUnseenTeeups(state),
  }
}

export default connect(mapStateToProps, null)(ContactBookInviteToTeeup)
