import { useCallback, useMemo } from "react"

import {
  useGeneralTimeDispatch,
  useGeneralTimeState,
} from "./GeneralTimeContext"
import { SET_DAYS_SELECTED, SET_QUICK_SELECT, SET_TIME } from "./actionTypes"
import { createSuggestions } from "@middlewares/backendMiddleware"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

const useGeneralTimeTab = () => {
  const { user, teeup, closeSuggestWhenModal } = useTeeUpPage()
  const context = useGeneralTimeDispatch()
  const { daysSelected, quickSelect, time } = useGeneralTimeState()

  const setQuickSelect = useCallback(
    (value) => {
      context({
        type: SET_QUICK_SELECT,
        payload: value,
      })
    },
    [context]
  )

  const setDaysSelected = useCallback(
    (value) => {
      context({
        type: SET_DAYS_SELECTED,
        payload: [...value],
      })
    },
    [context]
  )

  const setTime = useCallback(
    (value) => {
      context({
        type: SET_TIME,
        payload: value,
      })
    },
    [context]
  )

  const onSuggest = useCallback(() => {
    const suggest = [...daysSelected].map((day) => {
      const startDate = new Date(day)
      const endDate = new Date(day)

      // default full day
      startDate.setMinutes(0)
      startDate.setHours(time ? time.start : 0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)
      endDate.setMinutes(time ? 0 : 59)
      endDate.setHours(time ? time.end : 23)
      endDate.setSeconds(0)
      endDate.setMilliseconds(0)

      const gamePlanOption = teeup.gameplans.find(
        (option) => option.type === "when"
      )

      return {
        type: "when",
        startDate,
        userId: user.id,
        teeupId: teeup.id,
        endDate,
        value: null,
        value2: null,
        isCustomDate: false,
        isCustomTime: false,
        isTimeZoneEnabled: false,
        selected: false,
        optionId: gamePlanOption.optionId || 0,
        index: 0,
      }
    })

    if (suggest.length) {
      createSuggestions(suggest)
      closeSuggestWhenModal()
    }
  }, [closeSuggestWhenModal, daysSelected, teeup, time, user])

  const canSuggest = useMemo(() => {
    return daysSelected.length || quickSelect
  }, [daysSelected, quickSelect])

  return {
    daysSelected,
    quickSelect,
    time,
    setQuickSelect,
    setDaysSelected,
    setTime,
    canSuggest,
    onSuggest,
  }
}

export default useGeneralTimeTab
