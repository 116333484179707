import { StyleSheet } from "react-native"

import { Units } from "@theme/"

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  splitter: {
    height: Units.responsiveValue(16),
  },
})
