import React from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"

// import I18n from '../../../../i18n';
import { getContactInitials } from "@utils/dataUtils"
// import { getNudgeText } from '@utils/teeupUtils'
import Avatar from "../../../ui/avatar"
// import Icon from '@ui/icon'
import { AppColors } from "@theme"
import { TimeFromNow } from "../teeupItemComponents"

import { styles, headerContainer, invitationRow } from "./styles"
import { images } from "@utils/imageUtils"

const teeupListPrompts = {
  organizer: "You're the organizer",
  afterDecline: "You declined this TeeUp, join it now?",
  afterDroppout: "You dropped out of this TeeUp, rejoin it now?",
  archive: "Archive this TeeUp?",
  isUndecidedWhenInGameplan:
    "Your Action is Required to Decide on the date/time",
  mention: "mentioned you in conversation",
}

const OrganizerText = () => (
  <Text style={[styles.row, styles.headerTitleText]}>
    {teeupListPrompts.organizer}
  </Text>
)

const InvitationUserName = ({ invitation }) => (
  <Text style={[styles.row, styles.InvitationUserName]}>
    {invitation.userName}{" "}
  </Text>
)

const InvitationText = ({ invitation }) => (
  <Text style={styles.headerInvitationText}>invited you to:</Text>
)

InvitationText.propTypes = {
  invitation: PropTypes.object,
}

const DeclinedRejoinText = () => (
  <Text style={[styles.row, styles.headerTitleText]}>
    {teeupListPrompts.afterDecline}
  </Text>
)

const DroppedOutRejoinText = () => (
  <Text style={[styles.row, styles.headerTitleText]}>
    {teeupListPrompts.afterDroppout}
  </Text>
)

const ArchiveText = () => (
  <Text style={[styles.row, styles.headerTitleText]}>
    {teeupListPrompts.archive}
  </Text>
)

// const IsUndecidedInGameplanText = () => (
//     <Text style={[styles.row, styles.headerTitleText]}>
//         {I18n.t('teeupListPrompts.isUndecidedWhenInGameplan')}
//     </Text>
// )

// const NudgeText = ({ nudgeType, nudgeIssuer }) => (
//     <Text style={[styles.row, styles.headerTitleText]}>
//         {getNudgeText(nudgeType, nudgeIssuer)}
//     </Text>
// )
// NudgeText.propTypes = {
//     nudgeType: PropTypes.number.isRequired,
//     nudgeIssuer: PropTypes.object,
// }

const LeftItemsHeader = ({
  status,
  showAvatar = true,
  ownerAvatar,
  ownerName,
  isOrganizer = false,
  invitation,
  isInvited,
  isNew,
  isArchivePromptShow,
  isDeclined,
  isDroppedOut,
  fromNowString,
  isGreyOverlay,
  children,
  isEnded,
}) => {
  let textComponent
  if (isDroppedOut) {
    textComponent = <DroppedOutRejoinText />
  } else if (isDeclined) {
    textComponent = <DeclinedRejoinText />
  } else if (isArchivePromptShow) {
    textComponent = <ArchiveText />
  } else if (isInvited && invitation) {
    textComponent = <InvitationText invitation={invitation} />
  } else if (isOrganizer) {
    textComponent = <OrganizerText />
  }

  if (!textComponent) {
    return null
  }

  return (
    <View style={headerContainer(fromNowString, isArchivePromptShow, isEnded)}>
      <View
        style={[
          styles.headerContent,
          isArchivePromptShow && [styles.row, styles.contentBetween],
        ]}
      >
        <View style={isArchivePromptShow ? { display: "none" } : styles.row}>
          {showAvatar && (
            <View style={styles.avatarStyle}>
              <Avatar
                size={40}
                borderWidth={1.5}
                innerContentStyle={{
                  borderColor: AppColors.brand.white,
                  border: "solid 2px",
                }}
                imageUrl={isOrganizer ? ownerAvatar : invitation?.userAvatar}
                initials={getContactInitials(
                  isOrganizer ? { name: ownerName } : invitation || {}
                )}
                isOrganiser={isOrganizer}
                disabled={isGreyOverlay}
              />
            </View>
          )}
          {invitation && (
            <View style={invitationRow(!showAvatar, true)}>
              <InvitationUserName invitation={invitation} />
              <div style={{ display: "flex" }}>{textComponent}</div>
            </View>
          )}
        </View>

        {!invitation && (
          <View style={invitationRow(!showAvatar)}>{textComponent}</View>
        )}
        {!!fromNowString && (
          <TimeFromNow isNew={isNew} fromNowString={fromNowString} />
        )}
      </View>
    </View>
  )
}

LeftItemsHeader.propTypes = {
  showAvatar: PropTypes.bool,
  isNew: PropTypes.bool.isRequired,
  isOrganizer: PropTypes.bool,
  fromNowString: PropTypes.string,
  ownerAvatar: PropTypes.string,
  ownerName: PropTypes.string,
  invitation: PropTypes.object,
  nudgeInfo: PropTypes.object,
  isInvited: PropTypes.bool,
  isArchivePromptShow: PropTypes.bool,
  isDeclined: PropTypes.bool,
  isDroppedOut: PropTypes.bool,
  isGreyOverlay: PropTypes.bool,
  isUndecidedWhenInGameplan: PropTypes.bool,
}

export default LeftItemsHeader
