const chat = process.env.REACT_APP_SOCKET_ENDPOINT
const auth0domain = process.env.REACT_APP_AUTH0_DOMAIN
const api = process.env.REACT_APP_API_ENDPOINT

const endpoints = {
  // Login
  LOGIN: "/users/login",
  TEEUPS: "/users/teeups",
  register: "users/register",
  Login: "users/login",
  token: "users/token",
  sendBetaCode: "verify/send",
  verifyBetaCode: "verify/validate",
  logout: `https://${auth0domain}/v2/logout`,
  userInfo: "users",
  draftTeeup: () => "users/recommend/teeup",
  sendPasswordRecoveryLink: "tmp-passwords/recover",
  resetPassword: "users/passwords",
  //recover email
  recoverEmail: "recovery",
  recoverAccount: "contact-mechanisms/recovery",
  recoverAccountVerify: "contact-mechanisms/recovery/",
  changeEmailVerificationRequest: "users/contact-mechanisms/",
  changeEmailVerificationVerify: "users/contact-mechanisms/verifications/",
  addUserCM: "users/contact-mechanisms",

  //beta login
  verifyUserType: "verify/users/type",
  // Verify Current Password
  verifyPassword: "users/passwords/verify",
  // TellUs
  syncContacts: () => "users/contacts",
  fetchContacts: () => "users/contacts",
  editContact: (userId, recordId) => `users/contacts/${recordId}`,
  fetchContact: (userId, contactId) => `users/contacts/${contactId}`,

  // User Tags
  getUserTags: () => "users/tags",
  createUserTag: () => "users/tags",
  deleteUserTag: (userId, tagId) => "users/tags/" + tagId,

  // User Connections
  removeConnection: () => "users/connections",

  // Blacklist
  blacklist: () => `users/blacklists`,

  // Tag Users
  tagUsers: () => "users/tags/add",
  removeTags: () => "users/tags/remove",

  // Community
  community: {
    communitiesSearch: "communities/search",
  },

  calendarEvent: (teeupId) => "teeups/" + teeupId + "/events",

  // TeeUp
  teeup: {
    createTeeup: "teeups",
    teeups: () => "users/teeups",
    teeupsState: () => "users/teeups/state",
    teeupStateSeen: (id) => "users/teeups/" + id + "/state/seen",
    overview: (id) => "teeups/" + id,
    organisers: (id) => "teeups/" + id + "/organizers",
    users: (id) => "teeups/" + id + "/users",
    user: (id, userId) => "teeups/" + id + "/users/" + userId,
    suggestions: (id) => "teeups/" + id + "/suggestions",
    gameplanOptions: (id) => "teeups/" + id + "/gameplan-options",
    gameplanOption: (teeupId, gameplanOptionId) =>
      "teeups/" + teeupId + "/gameplan-options/" + gameplanOptionId,
    messages: (id) => "teeups/" + id + "/messages",
    reactions: (teeupId, suggestionId) =>
      "teeups/" + teeupId + "/suggestions/" + suggestionId + "/reactions",
    userStatus: (teeupId) => "teeups/" + teeupId + "/status/user",
    markAsDecided: (teeupId, suggestionId) =>
      "teeups/" + teeupId + "/suggestions/" + suggestionId + "/decide",
    addInvitees: (teeupId) => "teeups/" + teeupId + "/invitees",
    getInviteeSuggestions: "users/suggest-invitees",
    invitationHistory: (teeupId) => "teeups/" + teeupId + "/invite-history",
    categories: (teeupId, categoryId) =>
      `teeups/${teeupId}/categories/${categoryId}`,

    teeupArchiveStatus: (teeupId) => "teeups/" + teeupId + "/archiving",
    deleteTeeup: (teeupId) => `teeups/${teeupId}/users/skip`,
    activateTeeup: (teeupId) => `teeups/${teeupId}/activate`,

    teeupSaveFiles: (teeupId) => "teeups/" + teeupId + "/messages",
    updateStartsWhenSuggestion: (teeupId, gameplanOptionsId, suggestionId) =>
      "teeups/" +
      teeupId +
      "/gameplan-options/" +
      gameplanOptionsId +
      "/suggestions/" +
      suggestionId,
    inviteManual: (teeupId) => `teeups/${teeupId}/invitees/send`,
    // preview: (teeupId) => `teeups/${teeupId}/preview`, //endpoint for testing Preview page
    preview: (teeupId) => `/teeups/invitees/${teeupId}/preview `,
    userLocation: '/users/locations',
  },

  // Notifications
  notification: {
    registerDevice: "devices",
    deleteUserDeviceId: (deviceId) => "devices/" + deviceId,
    notifications: () => "users/notifications",
    readNotification: (notificationId) =>
      "users/notifications/" + notificationId,
    removeNotification: (notificationId) =>
      "users/notifications/" + notificationId,
  },

  // Users
  user: {
    profile: (userId) => `users/${userId}/view`,
    profileWithTags: (userId, currentUserId) =>
      `users/${userId}/view/${currentUserId}`,
    interests: () => `users/interests`,
    tags: () => `users/tags`,
    attributes: () => `users/attributes`,
    edit: () => `users`,
    avatar: `users/avatar`,
    teeupsUsers: `users/teeups/users`,
    teeupsGO: `users/teeups/gameplan-options`,
    matchings: () => `users/matchings`,
    locations: () => `users/locations`,
    editProfile: `users/profiles`,
  },

  interests: {
    interests: () => "interests",
    userInterests: () => "users/interests",
    follow: () => "users/interests",
    unfollow: (userId, interestId) => "users/interests/" + interestId,
    update: (userId, interestId) => "users/interests/" + interestId,
  },

  // Chat
  chat,

  //settings
  settings: `users/settings`,
  deleteUserContacts: () => "users/contacts/bulk-delete",

  //user contacts
  contactMechanisms: () => `users/contact-mechanisms`,
  contactMechanismsId: (contactMechanismId) =>
    `users/contact-mechanisms/${contactMechanismId}`,
  contactMechanismVerifications: (contactMechanismId) =>
    `users/contact-mechanisms/${contactMechanismId}/verifications`,
  contactMechanismVerificationsVerify: (verificationId) =>
    `users/contact-mechanisms/verifications/${verificationId}/verify/`,
  batchVerifyAndGetUserInfo: () => `/users/search`,
  contactMechanismVerify: `/users/contact-mechanisms/verify`,
  contactMechanismPublish: (contactMechanismId) =>
    `/users/contact-mechanisms/${contactMechanismId}/publish`,
  contactMechanismUnpublish: (contactMechanismId) =>
    `/users/contact-mechanisms/${contactMechanismId}/unpublish`,

  // Groups
  userGroups: () => "users/groups",
  group: {
    overview: (groupId) => `groups/${groupId}`,
    inviteUser: (groupId) => `groups/${groupId}/invitees`,
    getTeeups: (groupId) => `groups/${groupId}/teeups`,
    addInvitees: (groupId) => `groups/${groupId}/invitees`,
  },

  // Requests
  requests: () => "users/requests",
  responseToRequest: (userId, requestId) => "users/requests/" + requestId,
  directMessagesList: () => "users/directMessages",
  directConversation: (userId, targetId) =>
    "users/directMessages/conversation/" + targetId,
  api,
}

export default endpoints
