import React from "react"

import { images } from "@utils/imageUtils"


import './index.scss'
import useSuggestOnline from "../../Hooks/useSuggestOnline"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"
import useOnlineTabState from "../../Hooks/useOnlineTabState"


const FooterButtons = ({ suggestInfo }) => {
  const { activeButtonId, setActiveButtonId } = useOnlineTabState()
  const { onSubmit } = useSuggestOnline(suggestInfo) //temp-test solution
  const { closeSuggestWhereModal } = useTeeUpPage()

  return (
    <>
      {activeButtonId === 0 && (
        <div
          className="suggest-online__suggest inactive"
        >
          Suggest
        </div>
      )}
      {activeButtonId === 1 && (
        <div
          className="suggest-online__button"
          onClick={() => setActiveButtonId(2)}
        >
          <img
            src={images.ndSuggestSkipDetails}
            alt="skip"
            style={{ marginRight: 4 }}
          />
          Skip meeting details
        </div>
      )}
      {activeButtonId === 2 && (
        <>
          <div
            className="suggest-online__button"
            onClick={() => {
              onSubmit(true)
              closeSuggestWhereModal()
            }}  
          >
            Decide on this
          </div>
          <div
            className="suggest-online__suggest"
            onClick={() => {
              onSubmit(false)
              closeSuggestWhereModal()
            }}
          >
            Suggest
          </div>
        </>
      )}
    </>
  )
}

export default FooterButtons
