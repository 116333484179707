import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { View, ScrollView, Text } from "react-native"
import ClickableRow from "@ui/settingsClickableRow"
import {
  ToggleButton,
  ArrowButton,
} from "@ui/settingsClickableRow/actionButtons"
import {
  notificationPresets,
  getRealNotificationKeys,
} from "@utils/settingsUtils"
import strings from "@i18n"
// import { screenNames } from '@nav'

import { styles } from "./NotificationSettingsStyles"
import { commonScreenStyles as commonStyles } from "../moreStyles"

const NotificationSettingsView = ({
  toggleSoundSettings,
  openSystemSettings,
  linkToSoundSettings,
  toggleDisableAll,
  toggleNotification,
  applicationSoundEnabled,
  notificationDisabled,
  notifications,
  soundMode,
}) => {
  const renderNotificationList = () => {
    if (notificationDisabled) return null
    return (
      <>
        {Object.keys(notificationPresets).map((category) => (
          <Fragment key={category}>
            <Text style={commonStyles.header} key={category}>
              {strings.notificationCategories[category]}
            </Text>
            <div className="settings__rowWrapper">
              {notificationPresets[category].map((notificationKey, index) => {
                const firstRealKey = getRealNotificationKeys(notificationKey)[0]

                return (
                  <ClickableRow
                    key={notificationKey}
                    onPress={() =>
                      toggleNotification(
                        notificationKey,
                        !notifications[firstRealKey]
                      )
                    }
                    primaryText={[strings.notifications[notificationKey]]}
                    actionButton={
                      <ToggleButton
                        value={notifications[firstRealKey]}
                        onToggle={(val) =>
                          toggleNotification(notificationKey, val)
                        }
                      />
                    }
                    customRowStyles={
                      index === notificationPresets[category].length - 1 &&
                      commonStyles.sectionTail
                    }
                  />
                )
              })}
            </div>
          </Fragment>
        ))}
      </>
    )
  }

  return (
    <ScrollView
      style={commonStyles.background}
      contentContainerStyle={[
        commonStyles.container,
        { marginBottom: "150px" },
      ]}
    >
      <div className="settings__rowWrapper">
        <ClickableRow
          primaryText={["Application Sounds"]}
          onPress={() => toggleSoundSettings(!applicationSoundEnabled)}
          actionButton={
            <ToggleButton
              value={applicationSoundEnabled}
              onToggle={toggleSoundSettings}
            />
          }
        />
      </div>
      <Text style={[commonStyles.caution, commonStyles.sectionTail]}>
        This only controls the sounds made by the application during use. The
        push notification settings and sound is separate.
      </Text>

      {/* <ClickableRow
                primaryText={['Open system app sound settings']}
                secondaryText={['coo-e_notification']}
                actionButton={<ArrowButton />}
                onPress={openSystemSettings}
            /> */}
      {/* <Text style={[commonStyles.caution, commonStyles.sectionTail]}>
                Open the system sound settings for this application
            </Text> */}

      {/* <ClickableRow
                primaryText={['Push Notification Sounds']}
                secondaryText={[strings.notificationSoundMode["soundMode"]]}
                actionButton={<ArrowButton />}
                onPress={linkToSoundSettings}
            /> */}
      <div className="settings__rowWrapper">
        <ClickableRow
          primaryText={["Receive Push Notifications"]}
          onPress={() => toggleDisableAll(!notificationDisabled)}
          actionButton={
            <ToggleButton
              value={!notificationDisabled}
              onToggle={(val) => toggleDisableAll(!val)}
            />
          }
          customRowStyles={commonStyles.sectionTail}
        />
      </div>

      {renderNotificationList()}
    </ScrollView>
  )
}

NotificationSettingsView.propTypes = {
  applicationSoundEnabled: PropTypes.bool,
  notificationLevel: PropTypes.string,
  onToggleSoundSettings: PropTypes.func,
  openSystemSettings: PropTypes.func,
}

export default NotificationSettingsView
