import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"

export default StyleSheet.create({
  container: {
    height: 48,
    backgroundColor: AppColors.brand.white,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: AppColors.brand.white,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingRight: Units.rem(1),
  },
  text: {
    ...AppFonts.biggerBold,
    color: AppColors.brand.black,
    justifyContent: "center",
  },
  icon: {
    marginRight: Units.rem(1),
    marginLeft: Units.rem(1),
    tintColor: AppColors.brand.pink,
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
  },
  arrow: {
    flex: 1,
    flexDirection: "row-reverse",
  },
  arrowImage: {
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
  },
})
