import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom"
import { ReactSVG } from "react-svg";

import { peopleStatus } from "../../PeopleSection/PeopleRow/PeopleRow";
import ConfirmDeleteUser from "./ConfirmDeleteUset";
import InvitationSent from "./InvitationSent";

import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext";

import { deleteUser } from "middlewares/backendMiddleware";
import { updateTeeupOrganisers } from "@actions/activeTeeupActions";

import { images } from "@utils/imageUtils";

import './index.scss'


const TopBarManageParticipantsItem = ({ data, teeup }) => {
  const { user } = useTeeUpPage()
  const [hoverNumber, setHoverNumber] = useState(0)
  const [currentStatus, setCurrentStatus] = useState(null)
  const [currentOrganizers, setCurrentOrganizers] = useState(teeup.organisers)
  const [confirtDelete, setConfirtDelete] = useState(false)
  const [resentInvitation, setRecentInvitation] = useState(false)

  useEffect(() => {
    setCurrentStatus(peopleStatus.find(item => item.status === data.status))
  }, [])

  useEffect(() => {
    if (currentOrganizers) {
      updateTeeupOrganisers(teeup.id, currentOrganizers)
    }
  }, [currentOrganizers])

  const handleMakeAnOrganizer = (user) => {
    setCurrentOrganizers(state => ([...state, user]))
  }

  const handleDemoteOrganizer = (user) => {
    setCurrentOrganizers(state => state.filter(item => item.id !== user.id))
  }

  return (
    <div className="manage-participants-item">
      {data && (
        <>
          {confirtDelete && ReactDOM.createPortal(
            <ConfirmDeleteUser
              deleteUser={() => deleteUser(data.id)}
              closeModal={() => setConfirtDelete(false)}
            />,
            document.getElementById("modal-root")
          )}
          {resentInvitation && ReactDOM.createPortal(
            <InvitationSent
              closeModal={() => setRecentInvitation(false)}
            />,
            document.getElementById("modal-root")
          )}
          <div className="people-column-item__avatar--wrapper">
            {data.isOrganiser && (
              <ReactSVG
                className="people-column-item__avatar--organizer"
                src={images.ndCrown}
              />
            )}
            {data.avatar ? (
              <img
                className="people-column-item__avatar"
                src={data.avatar}
                alt="avatar"
              />
            ) : (
              <p className="people-column-item__avatar--empty">
                {data.name.split('')[0]}
              </p>
            )}
            {currentStatus && (
              <div
                className="people-column-item__status"
                style={{ backgroundColor: currentStatus.color }}
              >
                <img
                  className="people-column-item__status--icon"
                  src={currentStatus.image}
                />
              </div>
            )}
          </div>
          <h4 className="manage-participants-item__title">{data.name}</h4>
          {teeup.organisers.some(organizer => organizer.id === user.id)
            && (
            <div className="manage-participants-item__buttons">
              {data.status === 'invited' ? (
                <button
                  type="button"
                  className="manage-participants-item__recent-invitation"
                  onMouseOver={() => setHoverNumber(4)}
                  onMouseLeave={() => setHoverNumber(0)}
                  onClick={() => setRecentInvitation(true)}
                >
                  <ReactSVG
                    src={hoverNumber === 4 ? images.ndRecentInvitationActive : images.ndRecentInvitation}
                    style={{ marginRight: 5 }}
                  />
                  Resend Invitation
                </button>
              ) : (
                <button
                  type="button"
                  className="manage-participants-item__organizer"
                  onMouseOver={() => setHoverNumber(data.isOrganiser ? 2 : 3)}
                  onMouseLeave={() => setHoverNumber(0)}
                  onClick={() => {
                    !data.isOrganiser
                      ? handleMakeAnOrganizer(data)
                      : handleDemoteOrganizer(data) 
                  }}
                >
                  {data.isOrganiser ? (
                    <>
                      <ReactSVG
                        src={hoverNumber === 2 ? images.ndDemoteOrganizerActive : images.ndDemoteOrganizer}
                        style={{marginRight: 5 }}
                      />
                      Demote from Organizer
                    </>
                  ) : (
                    <>
                      <ReactSVG
                        src={hoverNumber === 3 ? images.ndMakeAnOrganizerActive : images.ndMakeAnOrganizer}
                        style={{marginRight: 5 }}
                      />
                      Make an Organizer
                    </>
                  )}
                </button>
              )}
              <button
                type="button"
                className="manage-participants-item__delete-user"
                onMouseOver={() => setHoverNumber(1)}
                onMouseLeave={() => setHoverNumber(0)}
                onClick={() => setConfirtDelete(true)}
              >
                <ReactSVG src={hoverNumber === 1 ? images.ndDeleteUserActive : images.ndDeleteUser} />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default TopBarManageParticipantsItem;
