import { StyleSheet } from "react-native"
import { AppColors } from "@theme"

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
    elevation: 2,
    shadowOffset: { height: 1 },
    shadowColor: "black",
    shadowOpacity: 0.15,
    shadowRadius: 3,
  },
})
