import React, { Component } from "react"
import { Text } from "react-native"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { selectTeeupSuggestions, selectTeeupReactions } from "@selectors/teeups"
import DraggableSuggestionRow from "../../../../gamePlansCard/draggableSuggestionRow"
import ParticipantSuggestionTabView from "./ParticipantSuggestionTabView"
import EmptyTab from "./EmptyTab"
import { ViewStyles } from "./ParticipantDetailsStyles"

const sectionTitles = {
  when: "When",
  where: "Where",
  what: "What",
}

const formatSections = (teeupSuggestions, userId, reactions) => {
  let sections = {}

  for (let suggestion in teeupSuggestions) {
    if (teeupSuggestions[suggestion].createdBy === userId) {
      const suggestionItem = {
        suggestion: teeupSuggestions[suggestion],
        reactions: reactions[suggestion],
      }
      const type = teeupSuggestions[suggestion].type
      if (!(type in sections)) {
        sections[type] = {
          title: sectionTitles[type],
          data: [],
        }
      }
      sections[type].data.push(suggestionItem)
    }
  }

  return Object.values(sections)
}

class ParticipantSuggestionTabContainer extends Component {
  renderItem = ({ item }) => {
    return (
      <DraggableSuggestionRow
        draggable={false}
        type={item.type}
        suggestion={item.suggestion}
        name={this.props.userName}
        customStyle={ViewStyles.customSuggestionStyles}
        handleOnLongPress={this.props.onLongPressSuggestion}
      />
    )
  }
  renderHeader = ({ section }) => {
    if (section.data.length !== 0) {
      return <Text style={ViewStyles.listHeader}>{section.title}</Text>
    }
    return null
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    let updates = null
    if (
      nextProps.suggestions !== prevProps.suggestions ||
      nextProps.reactions !== prevProps.reactions
    ) {
      let { suggestions, userId, reactions } = nextProps

      updates = {
        sections: formatSections(suggestions, userId, reactions),
      }
    }
    return updates
  }

  constructor(props) {
    super(props)
    const { suggestions, userId, reactions } = props

    this.state = {
      sections: formatSections(suggestions, userId, reactions),
    }
  }

  render() {
    const { sections } = this.state

    if (sections.length === 0) {
      return <EmptyTab text={"No suggestions made"} />
    }

    return (
      <ParticipantSuggestionTabView
        renderItem={this.renderItem}
        renderHeader={this.renderHeader}
        sections={sections}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const suggestions = selectTeeupSuggestions(state)
  const reactions = selectTeeupReactions(state)
  return {
    suggestions,
    reactions,
  }
}

export default connect(mapStateToProps)(ParticipantSuggestionTabContainer)

ParticipantSuggestionTabContainer.propTypes = {
  userName: PropTypes.string.isRequired,
  suggestions: PropTypes.object.isRequired,
  reactions: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  onLongPressSuggestion: PropTypes.func.isRequired,
}
