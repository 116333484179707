import React, { useState, useCallback, useEffect } from "react"
import cn from "classnames"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"

import "./index.scss"
import { images } from "@utils/imageUtils"
import logo from "../../assets/images/teeUpsPink.svg"
import cancel from "../../assets/images/cancel.png"
import ToggleButton from "../VerifyInvitation/common/ToggleButton/ToggleButton"
import PhoneInputField from "../../components/AddInvitees/AddContacts/phoneInput"
import { validateEmail } from "@utils/dataUtils"
import { validatePhoneNumber } from "utils/contactUtils"
import {
  addUserContactMechanism,
  requestVerifyContactMechanism,
} from "@actions/contactMechanismsActions"
import { userContactTypes } from "@utils/contactUtils"
import { setShowSideBar } from "@actions/commonActions"

const typeOptions = [
  { value: "email", label: "Email" },
  { value: "phone", label: "Phone (SMS)" },
]

const AddContactMethod = ({
  showVerifyCode,
  showFail,
  setShowSideBar,
  cancel,
  setCreatedCM,
}) => {
  const [type, setType] = useState("email")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState({
    countryCode: "us",
    dialCode: 1,
    name: "United States",
  })
  const [error, setError] = useState(null)
  const [isAlreadyVerified, setIsAlreadyVerified] = useState("")

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const history = useHistory()

  const IS_ALREADY_VERIFIED_ERROR = "Contact mechanism is already verified"
  useEffect(() => {
    const pushBack = () => {
      history.push("/contact")
      setShowSideBar(false)
    }

    window.onpopstate = pushBack
    return () => {
      window.removeEventListener("onpopstate", pushBack)
    }
  }, [])

  useEffect(() => {
    if (type === "email") {
      setIsButtonDisabled(!validateEmail(email))
    } else {
      setIsButtonDisabled(!validatePhoneNumber(phone))
    }
  }, [email, phone])

  const clearEmail = () => {
    setEmail("")
  }

  const onChangeInputValue = (index) => (input) => {
    setPhone(input)
  }

  const onChangeInputCountry = (countryValue) => {
    setCountry(countryValue)
  }

  const handleTypeChange = useCallback((type) => {
    setError(null)
    setType(type)
    setEmail("")
    setPhone("")
    setIsButtonDisabled(true)
  })

  const verifyContactMethod = async () => {
    let body = {
      value: email,
      typeId: userContactTypes.EMAIL,
    }
    if (type === "phone") {
      body = {
        value: phone,
        typeId: userContactTypes.PHONE,
      }
    }

    try {
      const { id: contactMechanismId } = await addUserContactMechanism(body)
      setCreatedCM(contactMechanismId)
      const { contactMechanismVerificationId } =
        await requestVerifyContactMechanism(contactMechanismId)

      showVerifyCode(
        contactMechanismId,
        contactMechanismVerificationId,
        email || phone
      )
    } catch (error) {
      const { status, message } = error.response.data

      if (status === 409) {
        showFail()
      }

      if (message === IS_ALREADY_VERIFIED_ERROR)
        setIsAlreadyVerified("This email is already linked to your account.")
    }
  }

  return (
    <div className="forgot__page" style={{ flexDirection: "column" }}>
      <div className="forgot__content">
        <img className="forgot__icon" src={images.cooeModal} />
        <span className="forgot__title">Add Connection Details</span>
        <p className="forgot__subtitle">
          Please provide an email address or phone number to receive invitations
        </p>
        <p
          className="forgot__subtitle"
          style={{ fontSize: 16, color: "#454c52", marginBottom: 8 }}
        >
          Type of Contact Method
        </p>
      </div>

      <div style={{ width: 327, marginBottom: 20 }}>
        <ToggleButton
          options={typeOptions}
          onChange={handleTypeChange}
          value={type}
        />
        {type === "email" ? (
          <>
            <label htmlFor="email" className="forgot__label">
              Email
            </label>
            <div style={{ position: "relative" }}>
              <input
                id="email"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                className={cn("login__input forgot__input", {
                  "forgot__input--error": error,
                })}
                type="text"
              />
            </div>
            <span className="forgot__error">{isAlreadyVerified || error}</span>
          </>
        ) : (
          <>
            {/* <label className="forgot__label">Phone Number</label> */}
            <PhoneInputField
              value={phone}
              handleTextChange={onChangeInputValue}
              inputStyle={{ width: "327px" }}
              fromInvitation={true}
              onChangeInputCountry={onChangeInputCountry}
              countryCode={country}
              label={"Country"}
            />
            <PhoneInputField
              value={phone}
              handleTextChange={onChangeInputValue}
              inputStyle={{ width: "327px" }}
              fromInvitation={true}
              countryCode={country}
              label={"Phone Number"}
              onlyNumber={true}
            />
            <div className="forgot__infoText">
              Users will be able to locate you by your phone number, but you
              will be invited via in-app and push notifications as a Coo•e user.
            </div>
          </>
        )}
      </div>

      <div
        style={{
          width: 327,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          className="verify__resend verify__resend--recovery"
          onClick={() => cancel()}
          style={{ width: 88, height: 48, margin: 0 }}
        >
          Cancel
        </button>
        <button
          disabled={isButtonDisabled}
          className={cn("forgot__button", {
            "forgot__button--disabled": isButtonDisabled,
          })}
          style={{ width: 227 }}
          onClick={verifyContactMethod}
        >
          Continue and Verify
        </button>
      </div>
    </div>
  )
}

export default connect(null, { setShowSideBar })(AddContactMethod)
