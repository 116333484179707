import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"

export const UserStatusModalStyles = StyleSheet.create({
  titleContainer: {
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    height: Units.responsiveValue(125),
    backgroundColor: AppColors.brand.white,
    paddingVertical: Units.responsiveValue(16),
    paddingHorizontal: Units.responsiveValue(16),
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  subtitle: {
    marginTop: Units.responsiveValue(16),
    ...AppFonts.middleExtraBold,
    color: AppColors.brand.grey112,
    textTransform: "uppercase",
  },

  statusesContainer: {
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: Units.responsiveValue(24),
    backgroundColor: "#f0f2f5",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },

  statusItemMargin: {
    marginBottom: Units.responsiveValue(16),
    textAlign: "left",
  },
  // statusButton: (bgColor) => ({
  //     backgroundColor: bgColor,
  // }),
  // leftImage: (color) => ({
  //     tintColor: color,
  // }),
})
