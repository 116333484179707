import React, { useState, useRef } from "react"
import { connect } from "react-redux"
import { TouchableOpacity, Image } from "react-native"
import { bindActionCreators } from "redux"
import ReactDOM from "react-dom"
import { useHistory } from "react-router-dom"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import "./index.scss"

import ProgressionSettings from "./ProgressionSettings"
import Option from "./Option"
import OptionWithIcon from "./OptionWithIcon"
import Notification from "./Notification"
import TitleInput from "./TitleInput"

import closeIcon from "../../assets/images/close.svg"
import picture from "../../assets/images/teeUp-image.svg"
import angle from "../../assets/images/angle-right.svg"
import traffic from "../../assets/images/traffic.svg"
import king from "../../assets/images/king.svg"
import trashcanSVG from "../../assets/images/trashcanSVG.svg"
// import trashcan from '../../assets/images/trashcan.svg';
import running from "../../assets/images/running.svg"
import notifications from "../../assets/images/no-notifications.svg"
import calendar from "../../assets/images/calendar.svg"
import homeIcon from "../../assets/images/home-icon.svg"
import editIcon from "../../assets/images/edit-icon.svg"
import calendarIcon from "../../assets/images/calendar-icon.svg"

import { settingsData } from "./settingsData"
import {
  selectActiveTeeupState,
  selectActiveTeeup,
} from "../../selectors/activeTeeup"
import { selectUserId } from "@selectors/user"
import {
  selectChosenGameplanByType,
  selectDecidedGameplanByType,
} from "../../selectors/teeups"
import { getBase64, images } from "@utils/imageUtils"
import { updateTeeup, dropoutTeeup } from "../../middlewares/backendMiddleware"
import { isOrganiser, isOwner } from "@utils/teeupUtils"
import TeeupPermissions from "../teeupSettings/teeupPermissionsContainer"
import { updateTeeupStatus } from "../../middlewares/backendMiddleware"
import { updateTeeupArchiveStatus } from "@actions/teeupActions"
import { teeupStatusKeys } from "@config/enums"
import { SectionListStyles } from "../gamePlansCard/suggestionsList/suggestionListStyles"
import { setHelpPopup } from "@actions/commonActions"
import { selectHelpPopup } from "@selectors/common"

const TeeUpsSettings = ({
  closeSettings,
  activeTeeup,
  activeUserId,
  enableUseAutoHappening,
  enableUseAutoEnded,
  setPopup,
  popup,
}) => {
  const [content, setContent] = useState(null)
  const [isOptionOpened, setIsOptionOpened] = useState(false)
  const [animation, setAnimation] = useState("")
  const [value, setValue] = useState("")
  const optionRef = useRef()

  const history = useHistory()

  const openOption = () => setIsOptionOpened(true)
  const closeOption = () => setIsOptionOpened(false)

  const isTeeupOwner = isOwner(activeUserId, activeTeeup)
  const isTeeupOrganiser = isOrganiser(activeUserId, activeTeeup.organisers)

  const changeImage = (image) => {
    updateTeeup({
      teeupId: activeTeeup.id,
      teeup: { photo: image },
    })
  }

  const onChangeHandler = (e) => {
    setValue(e.target.value)
  }

  const changeTitle = (name) => {
    updateTeeup({
      teeupId: activeTeeup.id,
      teeup: { name },
    })
  }

  const closeWithAnimations = () => {
    document.body.style.overflow = "auto"
    setAnimation("right-slide")
    setTimeout(() => {
      closeSettings()
      closeOption()
    }, 250)
  }

  const closeOptionWithAnimations = () => {
    optionRef.current.style.animationName = "right-slide"

    setTimeout(() => {
      closeOption()
    }, 250)
  }

  const cancelTeeup = () => {
    confirmAlert({
      title: "Cancel TeeUp?",
      message: `Are you sure you want to cancel "${activeTeeup.name}"? This will end the TeeUp for all participants and be archived. This action cannot be undone.`,
      buttons: [
        {
          label: "Go Back",
        },
        {
          label: "Cancel",
          onClick: () => {
            updateTeeupArchiveStatus(activeTeeup.id)
            updateTeeupStatus(activeUserId, teeupStatusKeys.cancelled)
            history.push("/teeUps")
          },
        },
      ],
    })
  }

  const dropOutTeeup = () => {
    confirmAlert({
      title: "Drop out of TeeUp?",
      message: `You won’t receive any more updates or be able to participate.`,
      buttons: [
        {
          label: "Cancel",
        },
        {
          label: "Drop Out",
          onClick: () => {
            dropoutTeeup({ teeup: activeTeeup, userId: activeUserId })
            history.push("/teeUps")
          },
        },
      ],
    })
  }

  const noOrganizersDropDawn = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="no-organizer__wrapper">
            <div className="no-organizer__content">
              <p className="no-organizer__text">
                Only oraganizer can change TeeUp
              </p>
              <button
                className="no-organizer__button"
                onClick={() => onClose()}
              >
                Ok
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const renderButton = (icon, text, onClick, arrow) => {
    return (
      <div className="settings__option settings__flex" onClick={onClick}>
        <div className="settings__option-wrapper">
          <div className="settings__option-image">
            <img src={icon} alt="" />
          </div>
          <span className="settings__option-text">{text}</span>
        </div>
        {arrow && (
          <img
            className="settings__arrow"
            src={images.arrowsOptions}
            alt="angle right"
          />
        )}
      </div>
    )
  }

  const renderTitleInput = () => (
    <TitleInput
      onClose={(name) => {
        changeTitle(name)
        closeOption()
      }}
      closeIcon={closeIcon}
      teeupName={activeTeeup.name}
    />
  )

  const PermissionsSettings = () => {
    return (
      <>
        <div className="settings__top">
          <TouchableOpacity onPress={closeOptionWithAnimations}>
            <img src={images.closeSettings} alt="close" />
            {/* <Image
                  source={images.back}
                  style={SectionListStyles.cancelBtnImage}
              /> */}
          </TouchableOpacity>
          <span>Organizers &amp; Permissions</span>
          <div></div>
        </div>
        <TeeupPermissions
          isOrganiser={isTeeupOrganiser}
          isOwner={isTeeupOwner}
          updateTeeup={updateTeeup}
        />
      </>
    )
  }

  const CalendarSettings = () => {
    return (
      <>
        <div className="settings__top">
          <span>Calendar and Timezone Settings</span>
          <img
            src={closeIcon}
            alt="close"
            onClick={closeOptionWithAnimations}
          />
        </div>
        <div className="settings__title">
          Primary and display calendars, and home time-zone
        </div>
        <div className="settings__option settings__option--big">
          <OptionWithIcon
            icon={calendarIcon}
            title="Calendar"
            subtitle="qgjones@coo-e.com"
          />
          <Option name="Sync TeeUps" />
        </div>
        <div className="settings__option">
          <span className="settings__option-text">
            Select Additional Calendars
          </span>
          <span className="settings__option-notation">
            Select calendars that will be displayed and used to help determine
            scheduling conflicts
          </span>
        </div>
        <OptionWithIcon
          icon={homeIcon}
          title="Home Time Zone"
          subtitle="(UTC+03) Europe/Minsk"
          notation={
            "Set your home time-zone to allow the app to help you manage your TeeUps while traveling"
          }
        />
        <OptionWithIcon
          icon={editIcon}
          title="Active Time Zone"
          subtitle="(UTC+01) Europe/Amsterdam"
          notation={
            "This Time-zone will be used when creating and joining TeeUps"
          }
        />
        <div className="settings__title">Time Zone Privacy Setting</div>
        <Option name="Use the current time-zone when creating TeeUps" />
        <Option name="Use the current time-zone when joining TeeUps" />
      </>
    )
  }

  const NotificationSettings = () => {
    return (
      <>
        <div className="settings__top">
          <span>Notification Settings</span>
          <img
            src={closeIcon}
            alt="close"
            onClick={closeOptionWithAnimations}
          />
        </div>
        {settingsData.notifications.map((notification) => (
          <Notification
            title={notification.title}
            key={notification.title}
            notation={notification.notation}
          />
        ))}
      </>
    )
  }

  return (
    <div
      className="settings"
      style={{ justifyContent: "flex-end" }}
      onClick={closeWithAnimations}
    >
      {/* {isOptionOpened && 
        <div ref={optionRef} className="settings__modal settings__modal-content" style={{ animationName: animation}} onClick={(event) => event.stopPropagation()}>
          {content}
        </div>
      } */}
      <div
        style={{
          animationName: animation,
          minHeight: "100vh",
          backgroundColor: "rgb(240, 240, 240)",
        }}
        ref={optionRef}
        className="settings__modal"
        onClick={(event) => event.stopPropagation()}
      >
        {!isOptionOpened ? (
          <>
            <div className="settings__top">
              <span>TeeUp Options</span>
              <img
                src={images.closeSettings}
                alt="close"
                onClick={closeWithAnimations}
              />
            </div>

            <div
              className="settings__option"
              style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
              onClick={() => {
                isTeeupOrganiser ? setPopup(true) : noOrganizersDropDawn()
              }}
            >
              {/* <span className="settings__option-subtitle">TeeUp Title</span> */}
              <span className="settings__option-text">TeeUp Title</span>
              <span
                className="settings__option-text-info"
                style={{ marginTop: 5 }}
              >
                {activeTeeup.name}
              </span>
            </div>

            <div className="settings__option settings__flex">
              <span className="settings__option-text">TeeUp Picture</span>
              <span
                className="settings__option-text settings__change"
                onClick={
                  !isTeeupOrganiser ? () => noOrganizersDropDawn() : null
                }
              >
                Change
                {isTeeupOrganiser && (
                  <input
                    className="upload-input"
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0]
                      getBase64(file).then((base64) => {
                        changeImage(base64)
                      })
                    }}
                  />
                )}
              </span>
            </div>

            {picture && (
              <div
                className="settings__picture"
                style={{ backgroundImage: `url(${activeTeeup.photo})` }}
              ></div>
            )}

            <div className="settings__subtitle" style={{ color: "#212121" }}>
              Global TeeUp Settings
            </div>

            {/* {renderButton(traffic, 'TeeUp Progression Settings', () => {
            openOption();
            setContent(
              <ProgressionSettings
              enableUseAutoEnded={enableUseAutoEnded}
              enableUseAutoHappening={enableUseAutoHappening}
              close={closeOptionWithAnimations}
              teeupId={activeTeeup.id}
              />
              )},
              true
            )} */}
            {renderButton(
              images.partipicants,
              "Participant Permissions",
              () => {
                openOption()
                setContent(PermissionsSettings)
              },
              true
            )}

            <div className="settings__hint" style={{ color: "#212121" }}>
              When this is on, you will no longer receive notifications from
              this TeeUp to your phone.{" "}
            </div>
            {/* {renderButton(notifications, 'Notification Settings', () => {openOption(); setContent(NotificationSettings)}, true)}
          {renderButton(calendar, 'Calendar and Timezone Settings', () => {openOption(); setContent(CalendarSettings)}, true)} */}
            {renderButton(running, "Drop Out of TeeUp", dropOutTeeup, false)}
            {isTeeupOwner &&
              renderButton(trashcanSVG, "Cancel TeeUp", cancelTeeup, false)}
          </>
        ) : (
          content
        )}

        {popup &&
          ReactDOM.createPortal(
            <>
              <div
                className="settings__connectionDetails_popup_wrapper"
                onClick={(e) => {
                  e.stopPropagation()
                  setPopup(false)
                }}
              >
                <div
                  className="settings__connectionDetails_popup"
                  style={{
                    padding: "32px 45px",
                    width: 309,
                    position: "relative",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={images.closeSettings}
                    className="settings__modal__closeIcon"
                    alt="close"
                    onClick={() => setPopup(false)}
                  />
                  <span className="settings__connectionDetails_popup_heading">
                    TeeUp Title
                  </span>
                  <input
                    className="clickable__input"
                    style={{ width: "100%", marginTop: 22 }}
                    placeholder={"TeeUp Title"}
                    value={value}
                    onChange={(e) => onChangeHandler(e)}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 44,
                    }}
                  >
                    <button
                      className="settings__connectionDetails_popup_button"
                      style={{ marginTop: 0, width: 150, height: 48 }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setPopup(false)
                        setValue("")
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="settings__connectionDetails_popup_button"
                      style={{
                        marginTop: 0,
                        width: 150,
                        height: 48,
                        backgroundColor: "#f42862",
                        color: "#ffffff",
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        changeTitle(value)
                        setPopup(false)
                        setValue("")
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </>,
            document.getElementById("modal-root")
          )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const suggestionOnGameplan = selectChosenGameplanByType("when")(state)
  const suggestionDecided = selectDecidedGameplanByType("when")(state)
  const enableUseAutoHappening =
    "isCustomDate" in suggestionOnGameplan && !suggestionOnGameplan.isCustomDate
  const enableUseAutoEnded =
    "endDate" in suggestionDecided && !suggestionDecided.endDate
  const popup = selectHelpPopup(state)
  return {
    activeTeeup: selectActiveTeeupState(state),
    activeUserId: selectUserId(state),
    enableUseAutoHappening,
    enableUseAutoEnded,
    popup,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPopup: bindActionCreators(setHelpPopup, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeeUpsSettings)
