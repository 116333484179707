import React, { useState } from "react"
import ReactDOM from "react-dom"

import TagUser from "@components/TagUser";
import useProfilePageUser from "../ProfilePageContext/useProfilePageUser";

import { images } from "@utils/imageUtils"

import './index.scss'

const ProfilePageTags = ({ userInfo }) => {
  const { setIsTagUser } = useProfilePageUser()
  const [isEditTagsOpen, setIsEditTagsOpen] = useState(false)

  return (
    <div className="profile-about-item">
      {isEditTagsOpen && ReactDOM.createPortal(
        <TagUser
          close={() => setIsEditTagsOpen(false)}
          selectedPeople={[userInfo]}
          fromProfile={true}
          userInfo={userInfo}
          changeUserTags={() => setIsTagUser(true)}
        />,
        document.getElementById("modal-root")
      )}
      <h4 className="profile-about-item__title">user tags</h4>
      <div className="profile-about-item__line"></div>

      <div
        className="profile-about-item__button"
        onClick={() => setIsEditTagsOpen(true)}
      >
        <img
          src={images.ndProfileEditTags}
          alt="edit tags"
          style={{ marginRight: 5 }}
        />
        Edit Tags
      </div>
    
      <div className="profile-about-item__tags">
        {userInfo.tags.length
          ? userInfo.tags.map((tag, index) => (
              <div className="profile-about-item__tag" key={index}>
                <img src={images.ndTags} alt="tag" style={{ marginRight: 5 }} />
                {tag.value}
              </div>
            ))
          : "No any tags"
        }
      </div>
    </div>
  )
}

export default ProfilePageTags
