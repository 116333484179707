import actionTypes from "./actionTypes"
import { store } from "../index"
import { getDefaultTimezone, setCurrentTimezone } from "@utils/dateUtils"
import { updateProfile, updateUserInfo } from "./userActions"
import { selectUserId, selectUserSettings } from "@selectors/user"
import api from "../api"
import endpoints from "@config/endpoints"
import { fetchCalendarEvents, resetCalendar } from "@actions/calendarActions"

const postSettings = (settings) => ({
  type: actionTypes.POST_SETTINGS,
  payload: settings,
})

const resyncCalendarTeeupEvents = () => ({
  type: actionTypes.CALENDAR_RESYNC_TEEUP_EVENTS,
})

const postUserSettings = (settings) => {
  let url = endpoints.settings
  return api.client
    .post(url, settings)
    .then((response) => {
      const { data } = response
      store.dispatch({ type: actionTypes.SET_SETTINGS, payload: { ...data } })

      return data
    })
    .catch((error) => console.log("post user settings error", error))
}

const changeUserTimeZone = (timezone) => {
  updateProfile({ timezone: timezone === null ? "" : timezone })
    .then((response) => {
      store.dispatch({
        type: actionTypes.CHANGE_TIMEZONE,
        payload: { customTimezone: timezone },
      })
      store.dispatch({
        type: actionTypes.UPDATE_USER_INFO,
        payload: { ...response, timezone },
      })
      setCurrentTimezone(timezone)
    })
    .catch((error) => {
      console.log("PATCH changeUserTimeZone error", error)
    })
}

export const changeSettings = (settings) => {
  const userId = selectUserId(store.getState())
  const userSettings = selectUserSettings(store.getState())
  // if (userSettings.sounds) {
  //     delete userSettings.sounds
  // }
  const fullSettings = {
    ...userSettings,
    ...settings,
  }
  if (userId) {
    postUserSettings(fullSettings, settings.callback)
    if (settings.calendarSync && settings.calendarSync.customTimezone) {
      const timezone = fullSettings.calendarSync.customTimezone
      changeUserTimeZone(timezone)
    }
  }

  const { calendarSync } = settings

  if (calendarSync) {
    if (calendarSync.syncTeeups) {
      fetchCalendarEvents()
    } else {
      store.dispatch(resetCalendar())
    }
  }
}

export { postSettings, resyncCalendarTeeupEvents }
