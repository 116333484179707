import React from "react"
import PropTypes from "prop-types"
import { View, Text, TouchableOpacity, ActivityIndicator } from "react-native"
import strings from "@i18n"

import styles from "./styles"

const Header = ({ loading, disableSend, onClose, onSend }) => (
  <View style={styles.header}>
    <View style={styles.headerWithButtons}>
      <TouchableOpacity style={styles.button} onPress={onClose}>
        <Text style={[styles.buttonText]}>{strings.systemButtons.cancel}</Text>
      </TouchableOpacity>
      <View style={styles.headerTextContainer}>
        <Text style={styles.headerText}>{strings.sendInvitation}</Text>
      </View>
      <TouchableOpacity
        style={styles.button}
        disabled={disableSend}
        onPress={onSend}
      >
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Text
            style={[
              styles.buttonText,
              disableSend && styles.inactiveButtonStyle,
            ]}
          >
            Send
          </Text>
        )}
      </TouchableOpacity>
    </View>
  </View>
)

Header.propTypes = {
  loading: PropTypes.bool.isRequired,
  disableSend: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
}

export default Header
