import { StyleSheet, Platform } from "react-native"
// import { isIphoneX } from 'react-native-iphone-x-helper'

import { AppColors, AppFonts, Units, AppStyles } from "@theme/"

const ViewStyles = StyleSheet.create({
  container: {
    //backgroundColor: AppColors.brand.whiteGrey,
    paddingBottom: Units.responsiveValue(24),
  },
  zonesContainer: {
    //backgroundColor: AppColors.brand.whiteGrey,
  },
  topOverlayContainer: {
    flex: 1,
    backgroundColor: AppColors.brand.grey40,
  },
  topContainer: {
    // backgroundColor: 'white',
  },
  topContainerRowStyle: {
    flexDirection: "row",
  },
  rowContainer: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingHorizontal: Units.rem(1),
    paddingTop: Units.rem(0.6875),
    paddingBottom: Units.rem(0.9375),
    alignItems: "center",
  },
  timeDurationRow: {
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderColor: AppColors.brand.lightGrey,
    height: Units.responsiveValue(46.5),
    ...Platform.select({
      ios: {
        paddingHorizontal: Units.rem(1),
      },
      android: {
        paddingHorizontal: Units.rem(0.8),
      },
    }),
  },
  timeDurationSwitch: {
    flexDirection: "row",
    alignItems: "center",
  },
  rowContainerBottomBorder: {
    borderBottomColor: AppColors.brand.lightGrey,
    borderBottomWidth: 1,
  },
  rowContainerRightBorder: {
    borderRightColor: AppColors.brand.lightGrey,
    borderRightWidth: 1,
  },
  topButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(16),
    backgroundColor: AppColors.brand.lightWhite,
  },
  buttonText: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.pink,
  },
  timeDurationTitle: {
    ...AppFonts.mediumDemibold,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
    color: AppColors.brand.black2,
    paddingLeft: Units.responsiveValue(10),
  },
  rowTitle: {
    ...AppFonts.bigDemibold,
    flex: 1,
  },
  rowTitleSmall: {
    flex: 0,
  },
  flex: {
    flex: 1,
  },
  rowValue: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.warmGrey2,
    letterSpacing: -Units.responsiveValue(0.3),
  },
  rowValueActive: {
    color: AppColors.brand.pink,
  },
  rowValueConflict: {
    ...AppFonts.mediumBold,
    color: AppColors.brand.red,
  },
  timezoneContainerBottomPadding: {
    paddingBottom: Units.responsiveValue(6),
  },
  pickerContainer: {
    alignItems: "center",
  },
})

const ViewProps = {
  selectizeTintColor: AppColors.brand.pink,
  inputHintColor: AppColors.brand.warmGrey2,
  navButtonColor: AppColors.brand.pink,
}

const DateStyles = StyleSheet.create({
  itemContainer: {
    padding: Units.rem(1),
    flexDirection: "column",
    alignItems: "center",
  },
  overlay: {
    ...AppStyles.absolute,
    alignItems: "center",
  },
  overlayMarker: {
    backgroundColor: AppColors.brand.pink,
    flex: 1,
    borderRadius: Units.rem(0.5),
  },
})

const HourStyles = StyleSheet.create({})

export { ViewStyles, DateStyles, HourStyles, ViewProps }
