import { createSelector } from "reselect"

export const selectTeeups = (state) => state.createTeeup

export const getCreateTeeupState = createSelector(
  selectTeeups,
  (createTeeup) => createTeeup.isModalOpen || null
)

export const getDraftTeeup = createSelector(
  selectTeeups,
  (createTeeup) => createTeeup.draftTeeup
)
