import { useSelector } from "react-redux"
import { validateSuggestion, GAMEPLAN_OPTIONS, locationTypeIds } from "@utils/gamePlanUtils"

import {
  selectTeeupRecommendationsIds,
  selectTeeupRecommendations,
  selectTeeupSuggestions,
  selectTeeupGameplans
} from "@selectors/teeups"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectUserId } from "@selectors/user"

import { createSuggetion } from "@middlewares/backendMiddleware"

const formatSections = (
  gameplans,
  recommendationsItems,
  recommendationsIds
) => {
  let sections = gameplans.map((gameplan, index) => ({
    ...gameplan,
    data: [index],
    index,
  }))
  let recommendations = recommendationsIds
    ? recommendationsIds.map(
        (recommendationId) => recommendationsItems[recommendationId]
      )
    : []

  if (recommendations.length > 0) {
    sections.push({
      index: gameplans.length,
      title: "Possibilities for your TeeUp",
      data: [gameplans.length],
      recommendations,
    })
  }

  return sections
}

const useSuggestOnline = (suggestInfo) => {
    const currentSuggestions = useSelector(selectTeeupSuggestions)
    const gameplans = useSelector(selectTeeupGameplans)
    const recommendations = useSelector(selectTeeupRecommendations)
    const recommendationsIds = useSelector(selectTeeupRecommendationsIds)
    const userId = useSelector(selectUserId)
    const teeupId = useSelector(selectActiveTeeupId)
  
    const sections = formatSections(gameplans, recommendations, recommendationsIds)
    const locationType = locationTypeIds.online
    const optionId = sections[1].optionId
    const type = 'where'
  
    const onSubmit =  async (selected) => {
      const newSuggestion = {
        locationType,
        value: suggestInfo && suggestInfo.title,
        value2: undefined,
        details: suggestInfo && suggestInfo.details,
        optionId,
      }
      const isValid = validateSuggestion(currentSuggestions, newSuggestion, type)
      if (isValid) {
        const dataToUpdate = {
          type,
          userId,
          teeupId,
          value: newSuggestion.value,
          value2: newSuggestion.value2,
          isCustomDate: true,
          isCustomTime: true,
          isTimeZoneEnabled: undefined,
          details: newSuggestion.details,

          startDate: undefined,
          googlePlaceId: null,
          selected,
        }

        if (optionId) {
          dataToUpdate.optionId = optionId
        }

        if (locationType && type === GAMEPLAN_OPTIONS.where) {
          dataToUpdate.locationType = locationType
          dataToUpdate.fullDetails = null
        }

        dataToUpdate.index = 1
        createSuggetion(dataToUpdate)
      }
    }

    return {
      onSubmit
    }
}

export default useSuggestOnline
