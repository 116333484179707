/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"

// components
// import IconFontAwesome from 'react-native-vector-icons/FontAwesome'
// import IconFontAwesome5 from 'react-native-vector-icons/FontAwesome5'
// import IconFontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro'
// import IconMaterialIcons from 'react-native-vector-icons/MaterialIcons'
// import IconAntDesign from 'react-native-vector-icons/AntDesign'
// import Ionicons from 'react-native-vector-icons/Ionicons'

// const getIconType = type => {
//     let iconClass
//     switch (type) {
//         case 'font-awesome': {
//             iconClass = IconFontAwesome
//             break
//         }
//         case 'font-awesome5': {
//             iconClass = IconFontAwesome5
//             break
//         }
//         case 'font-awesome5pro': {
//             iconClass = IconFontAwesome5Pro
//             break
//         }
//         case 'material-icons': {
//             iconClass = IconMaterialIcons
//             break
//         }
//         case 'ant-design': {
//             iconClass = IconAntDesign
//             break
//         }
//         case 'ionicicons': {
//             iconClass = Ionicons
//             break
//         }
//     }
//     return iconClass
// }

const Icon = (info) => {
  let iconType
  let icon = null

  // if (info) {
  //     iconType = getIconType(info.type)
  // }

  if (iconType) {
    icon = React.createElement(iconType, info, [])
  }

  return icon
}

export default Icon
