import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { images } from "@utils/imageUtils"
import { bindActionCreators } from "redux"

import {
  GAMEPLAN_OPTIONS,
  GAMEPLAN_SOURCE_TYPE,
  getSuggestionValue,
} from "@utils/gamePlanUtils"
import "./index.scss"
import { Image } from "react-native"
import { ReactComponent as LocationSVG } from "../../../assets/images/location.svg"
import { ReactComponent as ClockSVG } from "../../../assets/images/clock.svg"

import {
  setReplyMessage,
  setNudgePeopleOpen,
  setActiveTab,
  setModalPeopleNudge,
  hideSuggestionListFooter,
  toggleSuggestionDetails,
} from "@actions/commonActions"

import {
  setSuggestionRemovedStatus,
  removeSuggestionRemovedStatus,
} from "@actions/teeupActions"

import { getItem } from "@utils/localStorage"

import {
  pinUnpinMessage,
  pinUnpinSuggestion,
  promoteToGameplan,
  removeFromGameplan,
  toggleWithdraw,
  markAsDecided,
} from "middlewares/backendMiddleware"

import {
  selectMessageById,
  selectGameplanById,
  selectActiveTeeupId,
} from "@selectors/activeTeeup"

import { store } from "../../../index"

import { selectTeeupGameplans } from "@selectors/teeups"
import GoogleMap from "../../CreateTeeUp/GoogleMap"
import GameplanContainer from "../../CreateTeeUp/gameplanContainer"
import ZonesContainer from "../../timezones/zones/zonesContainer"
import MessagesPage from "pages/MessagesPage"

const SuggestionPopup = ({
  currentSuggestion,
  toggleSuggestionPopup,
  setCurrentSuggestion,
  currentSuggestionData,
  setCurrentSuggestionData,
  activeTeeupId,
  gameplans,

  renderLines,
  renderRightHeaderPart,

  setModalPeopleNudge,
  setReplyMessage,

  setSuggestionRemovedStatus,
  removeSuggestionRemovedStatus,

  onAddSuggestion,
  handleOpenSuggestion,
  hideSuggestionListFooter,
  draggableSuggestionRow,

  initialSuggestions,

  toggleSuggestionDetails,
}) => {
  const [duration, setDuration] = useState(0)
  const [endDateHoursInMinutes, setEndDateHoursInMinutes] = useState(1)

  const {
    type,
    suggestionValues,
    pollStatus,
    suggestionsList,
    startsWhenConditionMet,
    suggestionsCount,
    suggestion,
    reactions,
    noSuggestion,
    isTeeupCancelled,
    reactionTotal,
    sourceType,
    sections,
  } = currentSuggestionData

  const initialSuggestion = initialSuggestions?.filter(
    (suggestion) => suggestion.type === type
  )[0]
  const completeCurrentSuggestion =
    selectMessageById(currentSuggestion.id)(store.getState()) ||
    initialSuggestion

  const isCustomSuggestion =
    completeCurrentSuggestion?.locationType === 2 ||
    completeCurrentSuggestion?.isCustomTime ||
    completeCurrentSuggestion?.isCustomDate

  const closeSuggestionPopup = () => {
    toggleSuggestionPopup(false)
    setCurrentSuggestion && setCurrentSuggestion({})
  }

  const isDecided = gameplans
    .filter((gemeplan) => gemeplan.type === type)[0]
    .suggestions.filter(
      (suggestion) => suggestion?.id === completeCurrentSuggestion?.id
    )[0]?.decided

  const getDuration = (suggestion) => {
    let startDateParams = suggestion?.startDate
      ?.split("T")[1]
      ?.split(":")
      .slice(0, 2)
    let endDateParams = suggestion?.endDate
      ?.split("T")[1]
      ?.split(":")
      .slice(0, 2)

    if (suggestion?.endDate) {
      const startDateMinutes = +startDateParams[0] * 60 + +startDateParams[1]
      const endDateMinutes = +endDateParams[0] * 60 + +endDateParams[1]
      setEndDateHoursInMinutes(endDateMinutes)
      setDuration(endDateMinutes - startDateMinutes)
    }
  }

  useEffect(() => {
    if (type === "when") getDuration(completeCurrentSuggestion)
  }, [completeCurrentSuggestion])

  const lines =
    renderLines &&
    renderLines(
      type,
      suggestionValues,
      pollStatus,
      suggestionsList,
      startsWhenConditionMet
    )
  const likes =
    renderRightHeaderPart &&
    renderRightHeaderPart(
      type,
      suggestionsCount,
      noSuggestion,
      suggestion,
      reactions,
      isTeeupCancelled,
      pollStatus,
      reactionTotal,
      sourceType,
      sections
    )

  const suggestionContent = lines ? { lines, likes } : draggableSuggestionRow

  const address =
    type === "where" &&
    `${completeCurrentSuggestion?.value}, ${completeCurrentSuggestion?.details}`

  return (
    <div
      className="suggestion__popup--wrapper"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="suggestion__popup--gameplan__container">
        <img
          className="close__img"
          src={images.whiteCircleCross}
          onClick={closeSuggestionPopup}
        />

        <div
          style={{ boxSizing: "border-box", padding: "10px 12px 0 12px" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="gameplan__header">
            {type === "when" ? (
              <ClockSVG className="suggestionSVG" />
            ) : (
              <LocationSVG className="suggestionSVG" />
            )}
            <p className="suggestion__type">{type.toUpperCase()}</p>
            {currentSuggestion.selected && (
              <p className="suggestion__game-plan">GAME PLAN</p>
            )}
          </div>

          <div className="gameplan__info">
            {draggableSuggestionRow ? (
              draggableSuggestionRow
            ) : (
              <>
                {renderLines(
                  type,
                  suggestionValues,
                  pollStatus,
                  suggestionsList,
                  startsWhenConditionMet
                )}
                {renderRightHeaderPart(
                  type,
                  suggestionsCount,
                  noSuggestion,
                  suggestion,
                  reactions,
                  isTeeupCancelled,
                  pollStatus,
                  reactionTotal,
                  sourceType,
                  sections
                )}
              </>
            )}
          </div>
        </div>

        {!isCustomSuggestion && (
          <div
            className="suggestion__content"
            style={{
              width: "100%",
              height: "172px",
              overflow: "hidden",
              marginTop: "12px",
            }}
          >
            {type === "where" ? (
              <GoogleMap fromSuggestionPopup address={address} />
            ) : (
              // <GameplanContainer {...gameplans[0]} suggestions={[]}/>
              <ZonesContainer
                fromSuggestionPopup
                participants={[]}
                onZonesScrolled={() => {}}
                duration={duration}
                endDateHoursInMinutes={endDateHoursInMinutes}
              />
            )}
          </div>
        )}

        <div className="gameplan__footer">
          <div
            className="footer__action"
            onClick={() => {
              toggleSuggestionDetails(
                true,
                1,
                suggestionContent,
                completeCurrentSuggestion.id,
                address,
                { endDateHoursInMinutes, duration }
              )
              closeSuggestionPopup()
            }}
          >
            <img src={images.suggestionPopupDetails} />
            <span className="footer__action--text">Details</span>
          </div>
          <div
            className="footer__action"
            onClick={() => {
              toggleSuggestionDetails(
                true,
                2,
                suggestionContent,
                completeCurrentSuggestion.id,
                address,
                { endDateHoursInMinutes, duration }
              )
              closeSuggestionPopup()
            }}
          >
            <img src={images.suggestionPopupComments} />
            <span className="footer__action--text">Comments</span>
          </div>
          <div
            className="footer__action"
            onClick={() => {
              toggleSuggestionDetails(
                true,
                3,
                suggestionContent,
                completeCurrentSuggestion.id,
                address,
                { endDateHoursInMinutes, duration }
              )
              closeSuggestionPopup()
            }}
          >
            <img src={images.suggestionPopupReactions} />
            <span className="footer__action--text">Reactions</span>
          </div>
        </div>
      </div>

      <div
        className="suggestion__popup--actions__container"
        onClick={(e) => e.stopPropagation()}
      >
        {onAddSuggestion && (
          <div
            className="suggestion__popup--action"
            onClick={() => {
              onAddSuggestion()
              handleOpenSuggestion && handleOpenSuggestion()
              hideSuggestionListFooter()
            }}
          >
            <img src={images.suggestLamp} />
            <span>Make New Suggestion</span>
          </div>
        )}

        <div
          className="suggestion__popup--action"
          onClick={() =>
            setModalPeopleNudge(true, currentSuggestion.id, "nudgeWorkForYou")
          }
        >
          <Image
            source={images.nudgesNew}
            style={{
              width: 20,
              height: 20,
              tintColor: "#000000",
              display: "inline-block",
            }}
          />
          <span>Ask "Does this work for you?"</span>
        </div>

        <div
          className="suggestion__popup--action"
          onClick={() => {
            setReplyMessage(completeCurrentSuggestion)
            closeSuggestionPopup()
          }}
        >
          <img src={images.replyMessageNew} />
          <span>Comment</span>
        </div>

        <div
          className="suggestion__popup--action"
          onClick={() =>
            pinUnpinSuggestion({
              isPinned: completeCurrentSuggestion?.isPinned ? false : true,
              suggestionId: currentSuggestion.id,
            })
          }
        >
          <img
            src={
              completeCurrentSuggestion?.isPinned
                ? images.unpinMessageNew
                : images.pinMessageNew
            }
          />
          <span>
            {completeCurrentSuggestion?.isPinned
              ? "Remove from pinboard"
              : "Add to Pinboard"}
          </span>
        </div>

        <div
          className="suggestion__popup--action"
          onClick={() => {
            if (currentSuggestion.selected) {
              removeFromGameplan({
                suggestionId: completeCurrentSuggestion?.id,
              })
              setSuggestionRemovedStatus(
                completeCurrentSuggestion?.id,
                activeTeeupId
              )
              setTimeout(() => {
                removeSuggestionRemovedStatus(
                  completeCurrentSuggestion?.id,
                  activeTeeupId
                )
              }, 15000)
            } else {
              promoteToGameplan({ suggestionId: currentSuggestion.id, type })
            }
            closeSuggestionPopup()
          }}
        >
          <img
            src={
              currentSuggestion.selected
                ? images.removeFromGameplanNew
                : images.moveToGameplanNew
            }
          />
          <span>
            {currentSuggestion.selected
              ? "Remove from Game Plan"
              : "Move To Game Plan"}
          </span>
        </div>

        <div
          className="suggestion__popup--action"
          onClick={() =>
            markAsDecided(completeCurrentSuggestion?.id, !isDecided)
          }
        >
          <img src={isDecided ? images.unlocked : images.lock} />
          <span>Make {isDecided ? "Undecided" : "Decided"}</span>
        </div>

        <div
          className="suggestion__popup--action"
          onClick={() => {
            removeFromGameplan({ suggestionId: completeCurrentSuggestion?.id })
            toggleWithdraw({
              suggestionId: completeCurrentSuggestion?.id,
              isWithdrawn: true,
            })
            closeSuggestionPopup()
          }}
        >
          <img src={images.iconTrash} />
          <span>Withdraw</span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    initialSuggestions: state.activeTeeup.messages.filter(
      (message) => message.suggestionIds
    )[0]?.gameplans,
    gameplans: selectTeeupGameplans(state),
    activeTeeupId: selectActiveTeeupId(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setModalPeopleNudge: (value, id, typeId) =>
      dispatch(setModalPeopleNudge(value, id, typeId)),
    setReplyMessage: (message) => dispatch(setReplyMessage(message)),
    setSuggestionRemovedStatus: (id, activeTeeupId) =>
      dispatch(setSuggestionRemovedStatus(id, activeTeeupId)),
    removeSuggestionRemovedStatus: (id, activeTeeupId) =>
      dispatch(removeSuggestionRemovedStatus(id, activeTeeupId)),
    hideSuggestionListFooter: () => dispatch(hideSuggestionListFooter()),
    toggleSuggestionDetails: (
      isOpened,
      detailsTab,
      content,
      id,
      address,
      timeParams
    ) =>
      dispatch(
        toggleSuggestionDetails(
          isOpened,
          detailsTab,
          content,
          id,
          address,
          timeParams
        )
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionPopup)
