import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import tagIcon from "../../assets/images/tag.svg"
import MyContactItem from "@components/Contacts/MyContactItem"
import { differenceBy } from "lodash"
import { images } from "@utils/imageUtils"
import ExpandedTags from "./components/ExpandedTags"

const TagUsersModal = ({ invitees, closeTagUsersModal, tags, getContacts }) => {
  const [expandedTags, setExpandedTags] = useState(false)
  const [tagTitle, setTagTitle] = useState("")
  const [mostFrequentTag, setMostFrequentTag] = useState("")

  useEffect(() => {
    findMostFrequentTag(invitees)
  }, [])

  const findMostFrequentTag = (usersToTag) => {
    let arrayOfInviteesTags = []
    let preprocessedTags = []
    arrayOfInviteesTags = usersToTag.map(
      (user) => user.tags && user.tags.map((tag) => tag.value)
    )

    for (let i = 0; i < arrayOfInviteesTags.length; i++) {
      preprocessedTags = preprocessedTags.concat(arrayOfInviteesTags[i])
    }

    let mostFrequent = preprocessedTags
      .sort(
        (a, b) =>
          preprocessedTags.filter((v) => v === a).length -
          preprocessedTags.filter((v) => v === b).length
      )
      .pop()
    setMostFrequentTag(mostFrequent)
  }

  const handleToggleContact =
    ({ data }) =>
    () => {
      this.setState(({ invitees }) => {
        const diffInvitees = differenceBy(invitees, [data], "id")
        const isInvited = diffInvitees.length !== invitees.length

        return {
          showInvitees: true,
          invitees: isInvited ? diffInvitees : invitees.concat([{ ...data }]),
        }
      })
    }

  return (
    <div className="tagUsersModal" onClick={() => closeTagUsersModal()}>
      <div
        className="tagUsersModal__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="tagUsersModal__header-text">
          <p>Tag Users</p>
        </div>

        <div className="tagUsersModal__common-tags">
          <p className="common-tags-title">Common tag:</p>
          {mostFrequentTag && (
            <div className="tag__container">
              <img style={{ width: "10px" }} src={tagIcon} />
              <p>{mostFrequentTag}</p>
            </div>
          )}
        </div>

        <div className="tagUsersModal--users">
          {invitees.map((item) => (
            <MyContactItem
              key={item.id}
              setContent={() => {}}
              item={item}
              invitees={invitees}
              onRemoveInvitee={handleToggleContact({ data: item })}
              dontShowCircle
              noHover
            />
          ))}
        </div>

        <div
          className="tagUsersModal__bottom"
          onClick={() => setExpandedTags(true)}
        >
          <img src={images.redPlus} className="redPlus" />
          <p>Add a tag</p>
        </div>
      </div>

      {expandedTags &&
        ReactDOM.createPortal(
          <ExpandedTags
            tagTitle={tagTitle}
            setTagTitle={setTagTitle}
            closeTagUsersModal={closeTagUsersModal}
            invitees={invitees}
            tags={tags}
            getContacts={getContacts}
          />,
          document.getElementById("modal-root")
        )}
    </div>
  )
}

export default TagUsersModal
