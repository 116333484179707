import {
  statusTypeIds,
  reactionTypes,
  conversationTypeIds,
} from "@configs/mappings"
import { areDatesEqual, formatDaybreakDate } from "@utils/dateUtils"
import _ from "lodash"

let initialMessageOptions = {
  collapsedHeight: undefined,
  expandedHeight: undefined,
  heightCalculated: undefined,
  animatedHeight: undefined,
}

const gameplanUpdateTypes = {
  REMOVE: "remove",
  REPLACE: "replace",
  SELECT: "select",
}

const getInitialMessageOptions = () => {
  return initialMessageOptions
}

const isWhenWorksReaction = (message) =>
  message.typeId === statusTypeIds.reaction &&
  (Number(message.statusId) === reactionTypes.XMark ||
    Number(message.statusId) === reactionTypes.question ||
    Number(message.statusId) === reactionTypes.checkMark)

const compareDates = (next, message) => {
  if (
    !areDatesEqual(message?.timestamp, next?.timestamp) &&
    !(next?.conversationTypeId === conversationTypeIds.nudge)
  ) {
    const { daybreak } = formatDaybreakDate(message.timestamp)
    return daybreak !== "INVALID DATE" ? { daybreak } : null
  }
  return null
}

const formatStatusMessage = (checkedItem, newItem) => {
  const newCheckedItem = { ...checkedItem }
  if (
    newCheckedItem &&
    (newCheckedItem.conversationTypeId === conversationTypeIds.status ||
      newCheckedItem.conversationTypeId === conversationTypeIds.imageChange) &&
    newItem.conversationTypeId === conversationTypeIds.status &&
    ((newCheckedItem.statusId === newItem.statusId &&
      newCheckedItem.suggestionId === newItem.suggestionId) ||
      (isWhenWorksReaction(newCheckedItem) && isWhenWorksReaction(newItem))) &&
    newCheckedItem.typeId === newItem.typeId &&
    newCheckedItem.additionalCount === newItem.additionalCount
  ) {
    if (
      newCheckedItem.typeId === statusTypeIds.reaction &&
      newItem.typeId === statusTypeIds.reaction &&
      isWhenWorksReaction(newCheckedItem) &&
      isWhenWorksReaction(newItem)
    ) {
      if (Array.isArray(newCheckedItem.senderId)) {
        const sameElem = _.find(
          newCheckedItem.senderId,
          (item) => item === newItem.senderId
        )
        if (!sameElem) {
          newCheckedItem.senderId.push(newItem.senderId)
        }
      } else if (newCheckedItem.senderId !== newItem.senderId) {
        newCheckedItem.senderId = [newCheckedItem.senderId, newItem.senderId]
      }
      newCheckedItem.timestamps.push(newItem.timestamp)
      return newCheckedItem
    }
    if (Array.isArray(newCheckedItem.senderId)) {
      const sameElem = _.find(
        newCheckedItem.senderId,
        (item) => item === newItem.senderId
      )
      if (!sameElem) {
        newCheckedItem.senderId.push(newItem.senderId)
        newCheckedItem.timestamps.push(newItem.timestamp)
        return newCheckedItem
      }
      return formatResults.DO_NOTHING
    } else {
      if (
        newCheckedItem.additionalCount !== newItem.additionalCount &&
        newCheckedItem.statusId === newItem.statusId &&
        newCheckedItem.senderId === newItem.senderId
      ) {
        newCheckedItem.additionalCount = newItem.additionalCount
        return newCheckedItem
      } else if (newCheckedItem.senderId !== newItem.senderId) {
        newCheckedItem.senderId = [newCheckedItem.senderId, newItem.senderId]
        newCheckedItem.timestamps = [
          newCheckedItem.timestamp,
          newItem.timestamp,
        ]
        return newCheckedItem
      } else if (
        (newCheckedItem.typeId === statusTypeIds.invite &&
          newItem.typeId === statusTypeIds.invite) ||
        !newItem.typeId
      ) {
        if (Array.isArray(newCheckedItem.userId)) {
          newCheckedItem.userId.push(newItem.userId)
          newCheckedItem.timestamps.push(newItem.timestamp)
        } else if (
          newItem.image &&
          newCheckedItem.image &&
          newItem.image !== newCheckedItem.image
        ) {
          if (newItem.timestamp > newCheckedItem.timestamp) {
            newCheckedItem.image = newItem.image
          } else return formatResults.DO_NOTHING
        } else {
          newCheckedItem.userId = [newCheckedItem.userId, newItem.userId]
          newCheckedItem.timestamps = [
            newItem.timestamp,
            newCheckedItem.timestamp,
          ]
        }
        return newCheckedItem
      }
      return formatResults.DO_NOTHING
    }
  } else {
    return formatResults.PUSH
  }
}

const checkStatusMessage = (next = {}, message = {}, fromBackend = false) => {
  if (
    ((message.id && !next.id) || (!message.id && !next.id)) &&
    message.conversationTypeId !== conversationTypeIds.status &&
    next.conversationTypeId !== conversationTypeIds.status &&
    !message.daybreak &&
    !next.daybreak
  ) {
    return checkResults.REMOVE_PREVIOUS
  }
  if (
    (next.senderId === message.senderId ||
      (Array.isArray(next.senderId) &&
        next.senderId.includes(message.senderId))) &&
    (next.conversationTypeId === conversationTypeIds.status ||
      next.conversationTypeId === conversationTypeIds.imageChange) &&
    (message.conversationTypeId === conversationTypeIds.status ||
      message.conversationTypeId === conversationTypeIds.imageChange)
  ) {
    if (next.typeId === message.typeId) {
      if (!message.suggestionId && !next.suggestionId) {
        return checkResults.REMOVE_PREVIOUS
      } else if (+message.suggestionId === +next.suggestionId) {
        return checkResults.REMOVE_PREVIOUS
      }
      return checkResults.ADD_MESSAGE
    } else {
      if (+message.suggestionId === +next.suggestionId) {
        if (
          next.typeId === statusTypeIds.retract &&
          message.typeId === statusTypeIds.reaction
        ) {
          return fromBackend
            ? checkResults.REMOVE_ALL
            : checkResults.REMOVE_PREVIOUS
        }

        if (
          next.typeId === statusTypeIds.reaction &&
          message.typeId === statusTypeIds.retract
        ) {
          return fromBackend
            ? checkResults.REMOVE_PREVIOUS
            : checkResults.REMOVE_ALL
        }
        return checkResults.ADD_MESSAGE
      }
    }
  }
  return checkResults.ADD_MESSAGE
}

const formatResults = {
  DO_NOTHING: null,
  PUSH: "push",
}

const checkResults = {
  REMOVE_ALL: "REMOVE",
  REMOVE_PREVIOUS: "PREVIUOS",
  ADD_MESSAGE: null,
}

const setInitialMessageCollapsedHeight = (height) => {
  initialMessageOptions.collapsedHeight = height
}

const setInitialMessageHeightCalculated = (calculated) => {
  initialMessageOptions.heightCalculated = calculated
}

const setInitialMessageExpandedHeight = (height) => {
  initialMessageOptions.expandedHeight = height
}

const setInitialMessageAnimatedHeight = (height) => {
  initialMessageOptions.animatedHeight = height
}

export {
  gameplanUpdateTypes,
  getInitialMessageOptions,
  isWhenWorksReaction,
  compareDates,
  formatStatusMessage,
  checkStatusMessage,
  formatResults,
  checkResults,
  setInitialMessageCollapsedHeight,
  setInitialMessageHeightCalculated,
  setInitialMessageExpandedHeight,
  setInitialMessageAnimatedHeight,
}
