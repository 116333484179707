import React from "react"
import PropTypes from "prop-types"
import { View, Text, FlatList, TouchableOpacity, Image } from "react-native"
// import { TapGestureHandler } from 'react-native-gesture-handler'
import ContentLoader from "react-content-loader"

import TabBar from "@ui/tabBar"
import { AppColors } from "@theme/"
import { highlightTypes } from "@configs/enums"
import { Units } from "@theme/"
import { images } from "@utils/imageUtils"
// import I18n from '@i18n/'
import UnreadBar from "./UnreadBar"
import { DiscussionStyles, CardStyles } from "./discussionStyles"
import HighlightsButton from "../HighlightsButton"
import PinnedMessagesScreen from "./PinnedMessagesScreen"
import UserNudgesScreen from "./UserNudgesScreen"
import UserMentionsScreen from "./UserMentionsScreen"
// import { teeupListNudgeTypes } from '@configs/enums'
import SlimScroll from "@ui/slimScroll"

const styles = {
  tabIcon: {
    height: 16,
    width: 16,
    tintColor: AppColors.brand.grey144,
  },
  activeTint: {
    tintColor: AppColors.brand.black2,
  },
  flex: {
    flex: 1,
  },
  header: {
    height: 50,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    flexGrow: 1,
    fontDamily: "greycliffcf-demibold-salt",
    color: "black",
    fontSize: 20,
    lineHeight: 25,
  },
  closeIcon: {
    width: 20,
    height: 20,
    marginRight: 20,
  },
}

const tabs = [
  {
    id: highlightTypes.conversation,
    icon: (
      <Image
        source={images.conversationNew}
        style={[styles.tabIcon, { width: "18px", height: "18px" }]}
      />
    ),
    activeIcon: (
      <Image
        source={images.conversationNew}
        style={[
          styles.tabIcon,
          styles.activeTint,
          { width: "18px", height: "18px" },
        ]}
      />
    ),
    title: "Conversation",
    ndIcon: images.ndConversationIcon,
  },
  {
    id: highlightTypes.pinboard,
    icon: <Image source={images.thumbtackNew} style={styles.tabIcon} />,
    activeIcon: (
      <Image
        source={images.thumbtackNew}
        style={[styles.tabIcon, styles.activeTint]}
      />
    ),
    title: "Pinboard",
    ndIcon: images.ndPinIcon,
  },
  {
    id: highlightTypes.mentions,
    icon: <Image source={images.mentionsNew} style={styles.tabIcon} />,
    activeIcon: (
      <Image
        source={images.mentionsNew}
        style={[styles.tabIcon, styles.activeTint]}
      />
    ),
    title: "Mentions",
    ndIcon: images.ndMentionIcon,
  },
  {
    id: highlightTypes.nudges,
    icon: (
      <Image
        source={images.nudgesNew}
        style={[styles.tabIcon, { width: "17px" }]}
      />
    ),
    activeIcon: (
      <Image
        source={images.nudgesNew}
        style={[styles.tabIcon, styles.activeTint, { width: "17px" }]}
      />
    ),
    title: "Nudges",
    ndIcon: images.ndNudgesIcon,
  },
]

export default class DiscussionCardView extends React.Component {
  static getDerivedStateFromProps(nextProps, state) {
    let updates = null

    if (nextProps.draggingSuggestionId !== state.draggingSuggestionId) {
      updates = { draggingSuggestionId: nextProps.draggingSuggestionId }
    }

    return updates
  }

  constructor(props) {
    super(props)
    this.state = {
      draggingSuggestionId: null,
    }
    this.viewabilityConfig = {
      viewAreaCoveragePercentThreshold: 50,
      waitForInteraction: false,
    }
  }

  componentDidUpdate(prevProps) {
    // if first render of message then scroll to bottom
    if (prevProps.messages.length === 0 && this.props.messages.length > 0) {
      const messagesList = document.getElementById("messages-list")

      if (messagesList) {
        messagesList.scrollTop = messagesList.scrollHeight
      }
    }
    const messagesList = document.getElementById("messages-list")
    if (messagesList) {
      messagesList.scrollTop = messagesList.scrollHeight
    }
  }

  renderSkeleton = () => {
    return (
      <View style={CardStyles.skeletonContainer}>
        <ContentLoader
          backgroundColor="#E1E9EE"
          foregroundColor="#F2F8FC"
          width="400"
          height="350"
          viewBox="0 0 400 350"
        >
          <rect x="0" y="200" rx="20" ry="20" width="90%" height="100" />
          <rect x="100" y="100" width="120" height="20" />
          <rect x="100" y="150" width="120" height="20" />
        </ContentLoader>
      </View>
    )
  }

  render() {
    const {
      messages,
      attachedFiles,
      attachedType,
      pinnedMessagesCount,
      expanded,
      visible: cardVisible,
      inputValue,
      onMentionedUserRender,
      renderItem,
      animationStyle,
      inputAnimationStyle,
      inputRef,
      listRef,
      showSend,
      showJumpBackButton,
      onViewableItemsChanged,
      unreadCount,
      isMessagingDisabled,
      isSuggestionsDisabled,
      scrollEnabled,
      cameraRollImages,
      gettingRecentPhotos,
      recentFiles,
      gettingRecentFiles,
      unreadBannerEnabled,

      onSend,
      onSuggest,
      onChangeText,
      onJumpBack,
      onScroll,
      onUnreadClose,
      onUnreadPress,
      onOpenFilePicker,
      onAddFile,
      onAddMedia,
      onOpenAddFilesMenu,
      onCloseAddMediaBlock,
      showAddMediaBlock,
      onPressPinnedIcon,
      testID,
      onUserMention,
      onRemoveAttached,
      toggleExpansion,

      //responder
      onMoveShouldSetResponder,
      onStartShouldSetResponder,
      onFieldHeightChange,
      handleCloseKeyboardOnTouchOutside,
      nudgeType,
      newDesign,
    } = this.props

    const renderView = ({ style, ...props }) => {
      return <div id="messages-list" style={{ ...style }} {...props} />
    }

    return (
      <View
        style={CardStyles.cardContent}
        pointerEvents="box-none"
        testID={testID}
      >
        {newDesign ? (
          <TabBar tabs={tabs} index={this.props.index} newDesign />
        ) : (
          <View
            style={DiscussionStyles.titleContainer}
            pointerEvents="box-none"
          >
            <View style={DiscussionStyles.headerTabs}>
              <Text style={DiscussionStyles.tabTitle}>
                {tabs[this.props.index].title}
              </Text>
              <TabBar tabs={tabs} index={this.props.index} />
            </View>
            {/* <HighlightsButton
                        onPressPinnedIcon={onPressPinnedIcon}
                        expanded={expanded}
                    /> */}
          </View>
        )}
        <View style={DiscussionStyles.splitter} />
        {this.props.index === highlightTypes.conversation && (
          <View
            style={[
              DiscussionStyles.chatContainer,
              messages.length === 0 && { transform: "scaleY(-1)" },
            ]}
          >
            {unreadBannerEnabled && (
              <UnreadBar
                count={unreadCount}
                onClose={expanded && onUnreadClose}
                onPress={onUnreadPress}
              />
            )}
            <TouchableOpacity
              style={DiscussionStyles.chatContainerPlaceholder}
              onPress={
                !expanded ? toggleExpansion : handleCloseKeyboardOnTouchOutside
              }
            />
            {/* <TapGestureHandler
                            onHandlerStateChange={onStartShouldSetResponder}
                        > */}
            {messages.length > 0 ? (
              <div className="discussion-items__container scroll">
                {/* <div className="discussion_items"> */}
                <SlimScroll renderView={renderView}>
                  {messages.map((item, index) => (
                    <React.Fragment
                      key={`message-${
                        item.id || item.timestamp || item.daybreak
                      }`}
                    >
                      {renderItem({ item, index })}
                    </React.Fragment>
                  ))}
                </SlimScroll>
                {/* </div> */}
              </div>
            ) : (
              this.renderSkeleton()
            )}
            {/* </TapGestureHandler> */}
          </View>
        )}
        {this.props.index === highlightTypes.pinboard && (
          <PinnedMessagesScreen
            disableBind={true}
            insideTabContainer={true}
            renderChatItem={renderItem}
            newDesign={newDesign}
          />
        )}
        {this.props.index === 2 && (
          <UserMentionsScreen handleClose={() => {}} newDesign={newDesign} />
        )}
        {this.props.index === 3 && <UserNudgesScreen />}
      </View>
    )
  }
}

DiscussionCardView.propTypes = {
  messages: PropTypes.array.isRequired,
  animationStyle: PropTypes.shape({}).isRequired,
  inputAnimationStyle: PropTypes.shape({}).isRequired,
  activeReplyMessage: PropTypes.shape({}),
  attachedFiles: PropTypes.array,
  attachedType: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object,
  ]),
  showJumpBackButton: PropTypes.bool.isRequired,
  unreadCount: PropTypes.number,
  showSend: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  visible: PropTypes.bool,
  inputValue: PropTypes.string,
  onMentionedUserRender: PropTypes.func,
  pinnedMessagesCount: PropTypes.number,
  isMessagingDisabled: PropTypes.bool.isRequired,
  showAddMediaBlock: PropTypes.bool.isRequired,
  isSuggestionsDisabled: PropTypes.bool.isRequired,
  testID: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  inputRef: PropTypes.func.isRequired,
  listRef: PropTypes.func.isRequired,
  onViewableItemsChanged: PropTypes.func.isRequired,
  draggingSuggestionId: PropTypes.any,
  scrollEnabled: PropTypes.bool,
  gettingRecentPhotos: PropTypes.bool,
  cameraRollImages: PropTypes.array.isRequired,
  recentFiles: PropTypes.array.isRequired,
  gettingRecentFiles: PropTypes.bool,
  toggleExpansion: PropTypes.func.isRequired,
  unreadBannerEnabled: PropTypes.bool.isRequired,

  onSend: PropTypes.func.isRequired,
  onSuggest: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onJumpBack: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onUnreadClose: PropTypes.func.isRequired,
  onUnreadPress: PropTypes.func.isRequired,
  onPressPinnedIcon: PropTypes.func.isRequired,
  onOpenFilePicker: PropTypes.func.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onRemoveAttached: PropTypes.func.isRequired,
  onAddMedia: PropTypes.func.isRequired,
  onOpenAddFilesMenu: PropTypes.func.isRequired,
  onUserMention: PropTypes.func.isRequired,
  onMoveShouldSetResponder: PropTypes.func.isRequired,
  onStartShouldSetResponder: PropTypes.func.isRequired,
  onCloseAddMediaBlock: PropTypes.func.isRequired,
  onFieldHeightChange: PropTypes.func.isRequired,
  highlighterRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(React.Component) }),
  ]),
  handleCloseKeyboardOnTouchOutside: PropTypes.func,
  nudgeType: PropTypes.number,
}
