import React from "react"
import moment from "moment"
import PropTypes from "prop-types"
import { View } from "react-native"
import _ from "lodash"

import { calendarDateChangeMethod } from "@configs/enums"
import { AppColors } from "@theme"
import Icon from "@ui/icon"
import { getDateWithTimezone } from "@utils/dateUtils"
import { ExpandableCalendar, CalendarProvider } from "../calendars"
import { styles, container } from "./styles"

const today = new Date()
const todayMonthNumber = today.getMonth() + 1
const collapsedCalendarHeight = 100

const getMonthText = (number) => {
  return getDateWithTimezone()
    .month(number - 1)
    .format("MMMM")
}

export default class ExpandableCalendarView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      initialMonth: getMonthText(todayMonthNumber),
      currentMonth: getMonthText(todayMonthNumber),
      disableLeftArrow: true,
      // wrapperHeight: collapsedCalendarHeight,
      wrapperHeight: 89,
      // wrapperHeight: 222,
      weekDates: [],
      markedDates: props.teeupEventsByDay,
      isNextStartDateBeforePrevious: false,
    }
  }

  handleConvertToDateString = (date) =>
    getDateWithTimezone(date).format("YYYY-MM-DD")

  onDateChangedN = (startDate, changeMethod) => {
    const { onDateSelected } = this.props
    setTimeout(() => {
      onDateSelected({
        startDate: moment.utc(startDate),
        startDateIndex: 0,
        changeMethod,
      })
    }, 0)
  }

  componentDidUpdate(prevProps) {
    // const prevStartDateWithTimezone = getDateWithTimezone(
    //     prevProps.startDate
    // )
    const nextStartDateWithTimeZone = getDateWithTimezone(this.props.startDate)
    const nextStartDate = nextStartDateWithTimeZone.format("YYYY-MM-DD")
    const nextEndDateWithTimeZone = getDateWithTimezone(this.props.endDate)
    const nextEndDate = nextEndDateWithTimeZone.format("YYYY-MM-DD")
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.duration !== prevProps.duration
    ) {
      // const isNextStartDateBeforePrevious = nextStartDateWithTimeZone.isBefore(
      //     prevStartDateWithTimezone
      // )

      if (this.props.endDate && nextStartDate !== nextEndDate) {
        this.setState({
          markedDatesPeriod: {
            [nextStartDate]: {
              startingDay: true,
              color: AppColors.brand.pink,
              selected: true,
            },
            [nextEndDate]: {
              endingDay: true,
              color: AppColors.brand.pink,
              selected: true,
            },
          },
          // isNextStartDateBeforePrevious: !!isNextStartDateBeforePrevious,
        })
      } else {
        this.setState({
          markedDatesPeriod: {},
          // isNextStartDateBeforePrevious: !!isNextStartDateBeforePrevious,
        })
      }
    }
  }

  onDateChanged = _.debounce(this.onDateChangedN, 500)

  getTheme = () => {
    return {
      arrowColor: AppColors.brand.black2,
      arrowStyle: { padding: 0 },
      textDayHeaderFontSize: 11,
      textDayHeaderFontFamily: "greycliffcf-bold",
      textSectionTitleColor: AppColors.brand.black2,
      dayTextColor: AppColors.brand.black2,
      textDayFontSize: 11,
      textDayFontFamily: "greycliffcf-bold",
      textDayStyle: { marginTop: 0 },
      selectedDayTextColor: AppColors.brand.white,
      selectedDayTextStyle: { width: 18, height: 18 },
      textDisabledColor: AppColors.brand.grey216,
      textMonthFontSize: 15,
      textMonthFontFamily: "greycliffcf-demibold",
      monthTextColor: AppColors.brand.black2,
      selectedTodayDayBackgroundColor: AppColors.brand.light3,
      selectedTodayTextColor: AppColors.brand.black2,
      "stylesheet.day.basic": {
        base: {
          width: 18,
          height: 18,
          alignItems: "center",
          justifyContent: "center",
        },
        selected: {
          borderRadius: 6,
          backgroundColor: AppColors.brand.pink,
        },
      },
      "stylesheet.day.multiDot": {
        base: {
          width: 18,
          height: 18,
          alignItems: "center",
          justifyContent: "center",
        },
        selected: {
          borderRadius: 6,
          backgroundColor: AppColors.brand.pink,
        },
        dot: {
          width: 4,
          height: 4,
          marginTop: 7,
          marginLeft: 1,
          marginRight: 1,
          borderRadius: 2,
          opacity: 0,
        },
        text: {
          marginTop: 0,
          fontSize: 11,
          fontFamily: "greycliffcf-bold-salt",
          color: "#222",
          backgroundColor: "rgba(255, 255, 255, 0)",
        },
      },
    }
  }

  checkDisableLeftArrow = () => {
    const { initialMonth, currentMonth } = this.state
    if (initialMonth === currentMonth) {
      this.setState({ disableLeftArrow: true })
    } else {
      this.setState({ disableLeftArrow: false })
    }
  }

  checkDisableWeekLeftArrow = () => {
    const { weekDates } = this.state
    let disableLeftArrow = false
    weekDates.forEach((xDate) => {
      const date = xDate.toString()
      if (getDateWithTimezone(date).isBefore(getDateWithTimezone(today))) {
        disableLeftArrow = true
      }
    })

    this.setState({ disableLeftArrow })
  }

  onWeekChange = (weekDates) => {
    this.setState({ weekDates }, () => {
      if (!this.state.isOpen) {
        this.checkDisableWeekLeftArrow()
      }
    })
  }

  onWeekDayPress = (index) => {
    const currentDate = this.state.weekDates[index].toString()

    if (this.calendar) {
      this.calendar.contentRef.onDayPress({
        dateString: getDateWithTimezone(currentDate).format("YYYY-MM-DD"),
      })
      this.onDateChanged(currentDate, calendarDateChangeMethod.dayPress)
    }
  }

  updateHeight = (height, isOpen) => {
    this.setState({
      wrapperHeight: isOpen ? height : collapsedCalendarHeight,
    })
  }

  handleCalendarToggle = (isOpen) =>
    this.setState({ isOpen }, () => {
      if (isOpen) {
        this.checkDisableLeftArrow()
      } else {
        this.checkDisableWeekLeftArrow()
      }
    })

  handleMonthChange = (month) => {
    const { isOpen } = this.state
    const monthTitle = getMonthText(month.month)

    this.setState({ currentMonth: monthTitle }, () => {
      if (isOpen) {
        this.checkDisableLeftArrow()
      }
    })
  }

  render() {
    const {
      teeupEventsByDay,
      startDate,
      skip,
      onDayClick,
      fromCreate,
      isTemporalCustom,
    } = this.props
    const {
      disableLeftArrow,
      wrapperHeight,
      isOpen,
      markedDatesPeriod,
      isNextStartDateBeforePrevious,
    } = this.state
    const selected = getDateWithTimezone(startDate).format("YYYY-MM-DD")
    const markedDates = _.merge(
      _.cloneDeep(teeupEventsByDay),
      markedDatesPeriod
    )

    return (
      <View style={[{ marginLeft: "5px", backgroundColor: "#ffffff" }]}>
        <CalendarProvider
          ref={(ref) => (this.calendarProvider = ref)}
          onDateChanged={this.onDateChanged}
          onMonthChange={this.handleMonthChange}
          disabledOpacity={0.6}
          allowShadow={false}
          date={selected}
        >
          <ExpandableCalendar
            isTemporalCustom={isTemporalCustom}
            allowShadow={false}
            ref={(ref) => (this.calendar = ref)}
            hideKnob
            firstDay={0}
            skip={skip}
            theme={this.getTheme()}
            initialDate={startDate}
            onToogle={this.handleCalendarToggle}
            monthFormat={isTemporalCustom ? "MMMM yyyy" : "MMMM"}
            expandedIcon={
              <Icon
                type="font-awesome5"
                name={isOpen ? "carret-up" : "caret-down"}
                size={14}
                color="black"
              />
            }
            expandIconName={isOpen ? "up" : "down"}
            markedDates={markedDates}
            markingType={"multi-dot"}
            minDate={this.handleConvertToDateString(today)}
            disableArrowLeft={disableLeftArrow}
            onWeekChange={this.onWeekChange}
            onWeekDayPress={this.onWeekDayPress}
            enableOnWeekDayPress={!isOpen}
            updateHeight={this.updateHeight}
            markedDatesPeriod={markedDatesPeriod}
            isNextStartDateBeforePrevious={isNextStartDateBeforePrevious}
            onDayClick={onDayClick}
            fromCreate={fromCreate}
          />
        </CalendarProvider>
      </View>
    )
  }
}

ExpandableCalendarView.propTypes = {
  onDateSelected: PropTypes.func,
  teeupEventsByDay: PropTypes.object,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  duration: PropTypes.number,
}
