import { StyleSheet } from "react-native"

import { AppFonts, Units, AppColors } from "@theme/"

export default StyleSheet.create({
  container: (isLast) => ({
    flexDirection: "row",
    paddingHorizontal: Units.responsiveValue(10),
    paddingVertical: Units.responsiveValue(8),
    marginRight: !isLast ? Units.responsiveValue(8) : 0,
    borderRadius: Units.responsiveValue(100),
    alignItems: "center",
    backgroundColor: AppColors.brand.washedBlue,
    marginTop: Units.responsiveValue(15),
  }),
  text: {
    flexShrink: 1,
    flexDirection: "row",
    ...AppFonts.cardTitle,
    marginTop: -Units.responsiveValue(3),
    color: "white",
  },
  image: {
    marginLeft: -Units.responsiveValue(2),
    marginRight: Units.responsiveValue(5),
  },

  outlineContainer: {
    borderWidth: 1,
    borderColor: AppColors.brand.washedBlue,
    backgroundColor: "transparent",
  },
  outlineText: {
    color: AppColors.brand.washedBlue,
  },
  removeContainer: {
    marginLeft: 6,
    marginRight: -2,
  },
})
