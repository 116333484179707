import React from "react"
import PropTypes from "prop-types"
import {
  ScrollView,
  View,
  TouchableOpacity,
  Text,
  Platform,
} from "react-native"

import Switch from "react-switch"
import { AppColors } from "@theme/"
import Avatar from "@ui/avatar"
import { ViewStyles, PermissionStyles } from "./teeupSettingsStyles"
import { MdAdd } from "react-icons/md"
import { IconContext } from "react-icons"

class TeeupPermissionsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isInit: true,
    }
    this.switchRefs = []
  }

  componentDidMount() {
    this.setState({ isInit: false })
  }

  renderOrgItem(user) {
    const { ownerId } = this.props
    const { id, username, avatar } = user
    const name = username || ""
    const isOwner = id === ownerId

    return (
      <TouchableOpacity
        onPress={this.linkParticipantDetail(id)}
        key={id}
        style={PermissionStyles.orgItem}
      >
        <Avatar size={64} imageUrl={avatar} isOwner={isOwner} isOrganiser />
        <Text style={PermissionStyles.orgName}>{name}</Text>
      </TouchableOpacity>
    )
  }

  linkParticipantDetail = (userId) => () => {
    // showParticipantDetails({ userId })
  }

  getNumberOfOrganisersText(organisers) {
    if (organisers.length === 1) {
      return `${organisers.length} organizer`
    }

    return `${organisers.length} organizers`
  }

  renderOrgs() {
    const { organisers, editableOrganizersList, onClickEditOrgs } = this.props

    return (
      <View style={PermissionStyles.orgs}>
        <View style={PermissionStyles.numOrgsWrapper}>
          <Text style={PermissionStyles.numOrgsText}>
            {this.getNumberOfOrganisersText(organisers)}
          </Text>
        </View>
        <View style={PermissionStyles.orgsList}>
          {organisers.map((user) => this.renderOrgItem(user))}
          {editableOrganizersList && (
            <View style={PermissionStyles.orgItem}>
              <TouchableOpacity
                style={PermissionStyles.addOrg}
                onPress={onClickEditOrgs}
              >
                <IconContext.Provider value={PermissionStyles.addIcon}>
                  <MdAdd />
                </IconContext.Provider>
              </TouchableOpacity>
              <Text style={PermissionStyles.addOrgText}>Add Org</Text>
            </View>
          )}
        </View>
      </View>
    )
  }

  handleSwitch = (label, value) => {
    const currentSwitch = this.switchRefs.find((item) => item.label === label)
    if (currentSwitch) {
      currentSwitch.ref._handleChange({
        nativeEvent: { value: !value },
      })
    }
  }

  setSwitchRef = (label, ref) => {
    if (this.state.isInit) {
      this.switchRefs.push({ label, ref })
    }
  }

  renderPermissionRow(label, description, disabled, value, onChange) {
    let trackColor = AppColors.brand.pink
    let thumbColor = AppColors.brand.white
    let trackColorFalse = AppColors.brand.warmGrey2_40
    if (disabled) {
      if (value) {
        trackColor = AppColors.brand.pink_15
        thumbColor = AppColors.brand.pinkLight
      } else {
        trackColorFalse = AppColors.brand.warmGrey2_15
      }
    } else {
      if (value) {
        trackColor = AppColors.brand.pink_40
        thumbColor = AppColors.brand.pink
      }
    }

    return (
      <TouchableOpacity
        style={PermissionStyles.permissionRow}
        disabled={disabled}
        activeOpacity={1}
        onPress={() => onChange(!value)}
      >
        <View style={PermissionStyles.permissionRowText}>
          <Text
            style={[
              PermissionStyles.permissionRowLabel,
              disabled && PermissionStyles.permissionRowLabelDisabled,
            ]}
          >
            {label}
          </Text>
          {!!description && (
            <Text style={PermissionStyles.permissionRowDescription}>
              {description}
            </Text>
          )}
        </View>
        <Switch
          onChange={() => {}}
          checked={value}
          disabled={disabled}
          uncheckedIcon={false}
          checkedIcon={false}
          width={50}
          height={30}
          offColor="#9D9D9D"
          onColor="#F42862"
        />
      </TouchableOpacity>
    )
  }

  renderPermissions() {
    const { settings, editablePermissions, onChangeSettings } = this.props

    return (
      <View style={PermissionStyles.permissions}>
        <View style={PermissionStyles.permissionsHintWrapper}>
          <Text style={PermissionStyles.permissionsHint}>
            {editablePermissions
              ? "Allow anyone to:"
              : "Organizer is allowing everyone to:"}
          </Text>
        </View>
        <View style={PermissionStyles.permissionsContent}>
          {this.renderPermissionRow(
            "Invite others",
            "",
            !editablePermissions,
            settings.allowInviteOthers,
            (value) => {
              onChangeSettings("allowInviteOthers", value)
            }
          )}
          {this.renderPermissionRow(
            "Make a suggestion the Game Plan",
            "",
            !editablePermissions,
            settings.allowSelectGameplan,
            (value) => {
              onChangeSettings("allowSelectGameplan", value)
            }
          )}
          {this.renderPermissionRow(
            "Mark Game Plan items as Decided",
            "",
            !editablePermissions,
            settings.allowDecideGameplan,
            (value) => {
              onChangeSettings("allowDecideGameplan", value)
            }
          )}
          {this.renderPermissionRow(
            "Change the TeeUp state",
            "",
            !editablePermissions,
            settings.allowChangeState,
            (value) => {
              onChangeSettings("allowChangeState", value)
            }
          )}
          {this.renderPermissionRow(
            "Change Game Plan options",
            "Includes adding/removing rows, renaming rows, and reordering rows.",
            !editablePermissions,
            settings.allowChangeGameplanOptions,
            (value) => {
              onChangeSettings("allowChangeGameplanOptions", value)
            }
          )}
        </View>
      </View>
    )
  }

  render() {
    return (
      <ScrollView style={ViewStyles.background}>
        {this.renderOrgs()}
        {this.renderPermissions()}
      </ScrollView>
    )
  }
}

TeeupPermissionsView.propTypes = {
  settings: PropTypes.object,
  organisers: PropTypes.array,
  editablePermissions: PropTypes.bool,
  editableOrganizersList: PropTypes.bool,
  onClickEditOrgs: PropTypes.func,
  onChangeSettings: PropTypes.func,
  ownerId: PropTypes.number,
}

export default TeeupPermissionsView
