import React, { useEffect, useState } from "react"

import cn from "classnames"

import PeopleRow from "../PeopleRow"
import PeopleTimeZone from "../PeopleTimeZone"

import { useTeeUpPage } from "../../../TeeUpPageContext"


import "./index.scss"

const PeopleColumn = ({ close }) => {
  const { people } = useTeeUpPage()
  const [peopleTimezoneAndId, setPeopleTimezoneAndId] = useState(null)

  useEffect(() => {
    if (people) {
      setPeopleTimezoneAndId(getFilteredPeople(people))
    }
  }, [people])

  const getFilteredPeople = (peopleArray) => {
    const newPeopleIdAndTimezone = []

    for (let value in peopleArray) {
      if (
        !newPeopleIdAndTimezone.some(
          (item) => item.timezone === peopleArray[value].timezone
        )
      ) {
        newPeopleIdAndTimezone.push({
          id: [peopleArray[value].id],
          timezone: peopleArray[value].timezone,
        })
      } else {
        newPeopleIdAndTimezone.map((item) => {
          item.timezone === peopleArray[value].timezone &&
            item.id.push(peopleArray[value].id)
        })
      }
    }

    return newPeopleIdAndTimezone
  }

  return (
    <div className="people-column">
      {peopleTimezoneAndId &&
        Object.keys(people).length > 0 &&
        peopleTimezoneAndId.map((item, index) => (
          <div
            className={cn("people-column-timezone--wrapper", {
              "people-column-timezone--wrapper-close": close,
            })}
            key={item.id + index}
          >
            <PeopleTimeZone peopleTimezone={item.timezone} close={close} />
            <div className="people-column-timezone__content">
              {item.id.map((itemId) => {
                if (people[itemId]) {
                  return (
                    <PeopleRow
                      key={people[itemId]?.status + itemId || ""}
                      peopleInfo={people[itemId]}
                      close={close}
                    />
                  )
                }
              })}
            </div>
          </div>
        ))}
    </div>
  )
}

export default PeopleColumn
