import React, { useState, useMemo, useRef } from "react"
import { connect } from "react-redux"
import search from "../../assets/images/search.svg"
import TeeupItem from "../teeupList/TeeupItem"
import get from "lodash/get"
import find from "lodash/find"
import {
  DEFAULT_SORT,
  initialFiltersValues,
  teeupsFiltering,
  teeupSorting,
} from "@utils/teeupUtils"
import {
  selectTeeupsParticipants,
  selectTeeupsPeople,
  selectTeeupsGameplans,
} from "../../selectors/teeups"
import { selectActiveTeeupId, selectActiveTeeup } from "@selectors/activeTeeup"
import { selectUnseenTeeups } from "@selectors/teeups"
import { selectTeeupInvitationsRequests } from "../../selectors/requests"
import { selectUserId, selectToken } from "../../selectors/user"
import { selectNotificationTeeups, selectTeeupSearch } from "@selectors/common"
import {
  selectActiveTeeups,
  selectArchivedTeeups,
  selectRemovedTeeups,
} from "../../selectors/teeups"
import {
  setTeeupActive,
  updateActiveTeeup,
  deleteTeeup,
  moveToActive,
} from "@actions/activeTeeupActions"
import { teeupUserStatusPriorities, teeupUserStatusKeys } from "@configs/enums"
import { userStatusTypes } from "@config/mappings"
import SortTooltip from "../../components/teeupList/sortTooltip"
import ReactDOM from "react-dom"
import { View, Text, TouchableOpacity, Image } from "react-native"
import { ViewStyles } from "../../components/teeupList/styles"
import ReactTooltip from "react-tooltip"
import threeDotsIcon from "../../assets/images/three-dots.svg"
import { images } from "@utils/imageUtils"
import {
  updateTeeupArchiveStatus,
  updateIsArchivePromptShow,
  fetchGameplanOptions,
  fetchTeeupUsersById,
} from "@actions/teeupActions"
import "./index.scss"

const SelectTeeupModal = (props) => {
  const [filtering, setFiltering] = useState(initialFiltersValues)
  const [sorting, setSorting] = useState(DEFAULT_SORT)
  const [threeDots, setThreeDots] = useState(false)
  const [teeupFilter, setTeeupFilter] = useState("")

  const sortTeeupRef = useRef(null)

  const {
    notifications,
    notificationTeeups,
    activeTeeups,
    teeupsGameplans,
    closeModal,
    setActiveTeeup,
    openConfirmUsersModal,
  } = props

  const handleRespondToInvitation = (teeup) => (response) => {
    const { updateGameplan, updateActiveTeeup, gotTeeupParts } = props
    const { id: teeupId } = teeup
    const statusId = teeupUserStatusPriorities.joined

    if (response) {
      this.props
        .updateUserStatus(teeupId, statusId)
        .then(async (error) => {
          setTeeupActive(teeup)
          updateActiveTeeup({
            id: teeupId,
            userStatus: userStatusTypes[statusId],
          })
          const teeupUsers = await fetchTeeupUsersById(teeupId)
          gotTeeupParts([teeupUsers])
          updateGameplan(teeup.id, await fetchGameplanOptions(teeupId))

          if (error) {
            console.log("handleRespondToInvitation Error: ", {
              error,
            })
          }
        })
        .catch((error) => {
          console.log("handleRespondToInvitation catch ERROR: ", {
            error,
          })
        })
    } else {
      updateTeeupArchiveStatus(teeupId)
      this.props.archiveTeeup(teeupId)
    }

    setTimeout(() => {
      this.forceUpdate()
    }, 500)
  }

  const sortedList = useMemo(() => {
    const sorted = teeupSorting(activeTeeups, sorting, teeupsGameplans)
    return sorting.desc ? sorted.reverse() : sorted
  }, [sorting, activeTeeups])

  const handleKeepInTheListPress = (teeup) => {
    const { userId, updateTeeupIsArchivePromptShow } = this.props
    const { id: teeupId } = teeup

    updateTeeupIsArchivePromptShow({
      userId,
      teeup: { ...teeup, isArchivePromptShow: false },
    })
    updateIsArchivePromptShow(teeupId, userId, false)
  }

  const filterAndSortedData = () => {
    const { userId, teeupsGameplans, teeupFilter } = props
    let teeupsData = teeupsFiltering(
      sortedList,
      filtering,
      userId,
      teeupsGameplans
    )

    if (notificationTeeups) {
      const notificationsArr = notifications.map(
        (notification) => notification.id
      )
      return teeupsData.filter((teeup) => notificationsArr.includes(teeup.id))
    }

    if (teeupFilter) {
      return teeupsData.filter(
        (teeup = {}) =>
          teeup.name && teeup.name.match(new RegExp(teeupFilter, "i"))
      )
    }

    return teeupsData
  }

  const onArchivePressed = (teeup) => {
    updateTeeupArchiveStatus(teeup.id)
    this.props.archiveTeeup(teeup.id)
  }

  const onTrashPressed = (teeup) => {
    deleteTeeup(teeup.id)
    this.props.moveTeeupToTrash(teeup)
  }

  const onMoveToActivePress = (teeup) => {
    moveToActive(teeup)
  }

  const selectTeeup = (item) => {
    setActiveTeeup(item)
    openConfirmUsersModal()
  }

  const openConfirmUsers = () => {
    closeModal()
    openConfirmUsersModal()
  }

  const renderItem = ({ item, index }) => {
    const {
      teeupParticipants,
      teeupInvitations,
      userId,
      teeupPeople,
      teeupGameplans,
      activeTeeupId,
      notifications,
    } = props
    const { id, name } = item

    const teeups = filterAndSortedData().filter(
      (teeup) => teeup.status !== "ended"
    )

    const notificationsArr = notifications.map(
      (notification) => notification.id
    )
    return (
      <TeeupItem
        key={`teeupItem-${id}`}
        teeup={item}
        currentUserId={userId}
        invitation={find(teeupInvitations, ["teeupId", id], null)}
        participants={get(teeupParticipants, id, [])}
        people={get(teeupPeople, id, {})}
        gameplans={get(teeupGameplans, id, [])}
        onPress={() => selectTeeup(item)}
        onKeepInTheListPress={() => handleKeepInTheListPress(item)}
        onArchivePressed={() => onArchivePressed(item)}
        onTrashPressed={() => onTrashPressed(item)}
        onMoveToActivePress={() => onMoveToActivePress(item)}
        name={name}
        onRespondToInvitation={handleRespondToInvitation(item)}
        testID={`teeup-${id}`}
        isSelected={id === activeTeeupId}
        isLast={index + 1 === teeups.length}
        mode={props.teeupsMode}
        notificationsArr={notificationsArr}
        preventRedirecting
        preventHover
      />
    )
  }

  return (
    <div className="modal__wrapper">
      <div className="modal__container">
        <div className="modal__header--container">
          <p>Invite to TeeUp</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="modal__header--searchWrapper">
              <img className="searchbar__searchIcon" src={search} />
              <input
                type="text"
                value={teeupFilter}
                onChange={(e) => setTeeupFilter(e.target.value)}
                placeholder="Search"
              />
            </div>
            <div>
              {ReactDOM.createPortal(
                <SortTooltip changeSortType={setSorting} sorting={sorting} />,
                document.getElementById("modal-root")
              )}

              <TouchableOpacity
                style={[
                  ViewStyles.row,
                  {
                    position: "relative",
                    borderRadius: "50px",
                    // border: 'solid 1px #e0e0e0',
                    width: "108px",
                    marginLeft: "12px",
                    marginTop: "20px",
                    paddingLeft: "17px",
                    paddingRight: "17px",
                    paddingTop: "11px",
                    paddingBottom: "11px",
                    backgroundColor: "#F5F8FA",
                    // backgroundColor: '#f5f5f5'
                  },
                ]}
                onMouseOver={(e) => {
                  e.stopPropagation()
                  setThreeDots(true)
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation()
                  ReactTooltip.hide(sortTeeupRef.current)
                  setThreeDots(false)
                }}
              >
                {threeDots && (
                  <img
                    src={threeDotsIcon}
                    ref={sortTeeupRef}
                    data-tip={2}
                    data-for="sort-tooltip"
                    className="reactions-view"
                    onClick={(e) => {
                      e.stopPropagation()
                      ReactTooltip.show(sortTeeupRef.current)
                    }}
                    alt=""
                  />
                )}
                <img
                  style={{ height: 10, width: 10, padding: "0 5px 0 0" }}
                  src={images.teeupsSort}
                />
                <Text style={[ViewStyles.sortTitle, { fontSize: "12px" }]}>
                  {sorting.title}
                </Text>
              </TouchableOpacity>
            </div>
          </div>
        </div>
        <div className="teeups__wrapper">
          <div className="teeups__container">
            {filterAndSortedData()
              .filter(
                (teeup) =>
                  teeup.name
                    .toLowerCase()
                    .includes(teeupFilter.toLowerCase()) &&
                  teeup.status !== "ended"
              )
              .map((item, index) => renderItem({ item, index }))}
          </div>
        </div>
        <div className="bottom">
          <button className="bottom__btn" onClick={() => closeModal()}>
            Cancel
          </button>
          <button
            className="bottom__btn red"
            onClick={() => openConfirmUsers()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const teeupParticipants = selectTeeupsParticipants(state)
  const teeupPeople = selectTeeupsPeople(state)
  const teeupGameplans = selectTeeupsGameplans(state)
  const activeTeeupId = selectActiveTeeupId(state)
  const notifications = selectUnseenTeeups(state)
  const teeupInvitations = selectTeeupInvitationsRequests(state)
  const userId = selectUserId(state)
  const notificationTeeups = selectNotificationTeeups(state)
  return {
    teeupParticipants,
    teeupInvitations,
    teeupPeople,
    teeupGameplans,
    userId,
    activeTeeupId,
    notifications,
    notificationTeeups,
    all: state,
    activeTeeups: selectActiveTeeups(state),
    archivedTeeups: selectArchivedTeeups(state),
    removedTeeups: selectRemovedTeeups(state),
    token: selectToken(state),
    userId: selectUserId(state),
    activeTeeUpId: selectActiveTeeupId(state),
    teeupsGameplans: selectTeeupsGameplans(state),
    activeTeeUp: selectActiveTeeup(state),
    teeupFilter: selectTeeupSearch(state),
    notificationTeeups: selectNotificationTeeups(state),
    notifications: selectUnseenTeeups(state),
  }
}

export default connect(mapStateToProps, null)(SelectTeeupModal)
