import React, { memo } from "react"

import { TeeUpPageProvider } from "./TeeUpPageContext"
import TeeUpPage from "./TeeUpPage"

const TeeUpPageWithContext = () => {
  return (
    <TeeUpPageProvider>
      <TeeUpPage />
    </TeeUpPageProvider>
  )
}

export default memo(TeeUpPageWithContext)
