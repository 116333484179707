import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import { View, Text, TouchableOpacity, Image } from "react-native"
import { images } from "@utils/imageUtils"
import threeDotsIcon from "../../../assets/images/three-dots.svg"
import { styles } from "./styles"

const DetailGroup = (props) => {
  const {
    title,
    secondaryTitle,
    showDivider,
    children,
    onExpand,
    expanded,
    headerActions,
    noPadding,
  } = props
  const [hoveredAction, setHoveredAction] = useState("")
  const [threeDotsPeople, setThreeDotsPeople] = useState(false)
  const peopleRef = useRef()

  const isShowExpandArrow = !!onExpand
  const HeaderLeftWrapper = isShowExpandArrow ? TouchableOpacity : View

  return (
    <View style={[styles.container, noPadding && { padding: 0 }]}>
      <View style={styles.wrapper}>
        <View style={styles.headerWrapper}>
          <HeaderLeftWrapper
            onPress={onExpand}
            style={styles.leftHeaderWrapper}
          >
            <View>
              <Text style={styles.titleText}>{title}</Text>
            </View>
            {isShowExpandArrow && (
              <Image
                style={styles.arrowIcon}
                source={
                  expanded
                    ? images.detailCardOpenArrow
                    : images.detailCardCloseArrow
                }
              />
            )}
            <View>
              <Text style={styles.secondaryTitleText}>{secondaryTitle}</Text>
            </View>
          </HeaderLeftWrapper>
          {headerActions && (
            <View style={styles.rightHeaderWrapper}>
              {headerActions.map((action, index) => (
                <TouchableOpacity
                  onPress={action.onPress}
                  onMouseOver={() => {
                    setHoveredAction(action.name)
                    setThreeDotsPeople(true)
                  }}
                  onMouseLeave={(e) => {
                    e.stopPropagation()
                    setHoveredAction("")
                    ReactTooltip.hide(peopleRef.current)
                    setThreeDotsPeople(false)
                  }}
                  style={[styles.rightHeaderActionWrapper, action?.customStyle]}
                  key={index}
                >
                  {hoveredAction === action.name
                    ? action.activeImage
                    : action.image}
                  {threeDotsPeople &&
                    hoveredAction === "nudges" &&
                    hoveredAction === action.name && (
                      <img
                        src={threeDotsIcon}
                        ref={peopleRef}
                        data-tip={Math.floor(Math.random() * (100 - 1) + 1)}
                        data-for="people-tooltip"
                        className="reactions-view"
                        onClick={(e) => {
                          e.stopPropagation()
                          ReactTooltip.show(peopleRef.current)
                        }}
                        alt=""
                      />
                    )}
                  {action?.label && (
                    <Text style={styles.rightHeaderActionText}>
                      {action.label}
                    </Text>
                  )}
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
        {showDivider && <View style={styles.divider} />}
        {children}
      </View>
    </View>
  )
}

DetailGroup.defaultProps = {
  expanded: false,
}

DetailGroup.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  showDivider: PropTypes.bool,
  children: PropTypes.object,
  onExpand: PropTypes.func,
  expanded: PropTypes.bool,
  headerActions: PropTypes.array,
}

export default DetailGroup
