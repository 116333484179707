import { useSelector } from "react-redux"

import { useTeeUpPageDispatch, useTeeUpPageState } from "./TeeUpPageContext"
import {
  SET_INTERACTION_AREA,
  OPEN_PEOPLE_AREA,
  SET_IS_SUGGEST_WHEN_OPEN,
  SET_IS_SUGGEST_WHERE_OPEN,
} from "./actionTypes"
import { selectUserInfo } from "@selectors/user"
import { selectTeeupPeople, selectTeeupParticipants } from "@selectors/teeups"

const useTeeUpPage = () => {
  // react context
  const context = useTeeUpPageDispatch()
  const {
    interactionArea,
    openPeopleArea,
    isSuggestWhereModalOpen,
    isSuggestWhenModalOpen,
  } = useTeeUpPageState()
  // redux
  const teeup = useSelector((state) => state.activeTeeup)
  const user = useSelector(selectUserInfo)
  const participants = useSelector(selectTeeupParticipants)
  const people = useSelector(selectTeeupPeople)
  const peopleLocations = useSelector(
    (state) => state.teeups.teeupPeopleLocation.locations
  )

  const setInteractionArea = (value) => {
    context({
      type: SET_INTERACTION_AREA,
      payload: value,
    })
  }

  const closeInteractionArea = () => {
    context({
      type: SET_INTERACTION_AREA,
      payload: null,
    })
  }

  const setOpenPeopleArea = (value) => {
    context({
      type: OPEN_PEOPLE_AREA,
      payload: value,
    })
  }

  const openSuggestWhenModal = () => {
    context({
      type: SET_IS_SUGGEST_WHEN_OPEN,
      payload: true,
    })
  }

  const closeSuggestWhenModal = () => {
    context({
      type: SET_IS_SUGGEST_WHEN_OPEN,
      payload: false,
    })
  }

  const openSuggestWhereModal = () => {
    context({
      type: SET_IS_SUGGEST_WHERE_OPEN,
      payload: true,
    })
  }

  const closeSuggestWhereModal = () => {
    context({
      type: SET_IS_SUGGEST_WHERE_OPEN,
      payload: false,
    })
  }

  return {
    teeup,
    user,
    interactionArea,
    openPeopleArea,
    participants,
    people,
    setInteractionArea,
    setOpenPeopleArea,
    closeInteractionArea,
    peopleLocations,
    isSuggestWhenModalOpen,
    isSuggestWhereModalOpen,
    openSuggestWhenModal,
    closeSuggestWhenModal,
    openSuggestWhereModal,
    closeSuggestWhereModal,
  }
}

export default useTeeUpPage
