import React from "react"
import PropTypes from "prop-types"
import {
  View,
  Text,
  ScrollView,
  Linking,
  TouchableOpacity,
  Image,
} from "react-native"
import DetailGroup from "@ui/detailGroup"
import { images } from "@utils/imageUtils"
import { ViewStyles } from "./userProfileStyles"
import strings from "@i18n"

const UserProfileContactInfo = (props) => {
  const { userProfile } = props
  const { email, phone, location, url } = userProfile

  return (
    <ScrollView style={ViewStyles.contactInfoWrapper}>
      <DetailGroup
        title={strings.profileOverview.publicInformation}
        showDivider
      >
        <View style={ViewStyles.publicInfoWrapper}>
          {!!email && (
            <View style={ViewStyles.publicInfoRow}>
              <Image
                style={ViewStyles.publicInfoIcon}
                source={images.contactEmail}
              />
              <Text style={ViewStyles.publicInfoText}>{email}</Text>
            </View>
          )}
          {!!phone && (
            <View style={ViewStyles.publicInfoRow}>
              <Image
                style={ViewStyles.publicInfoIcon}
                source={images.contactPhone}
              />
              <Text style={ViewStyles.publicInfoText}>{phone}</Text>
            </View>
          )}
          {!!location && (
            <View style={ViewStyles.publicInfoRow}>
              <Image
                style={ViewStyles.publicInfoIcon}
                source={images.contactAddress}
              />
              <Text style={ViewStyles.publicInfoText}>{location}</Text>
            </View>
          )}
          {!!url && (
            <TouchableOpacity
              onPress={() => Linking.openURL(url)}
              style={ViewStyles.publicInfoRow}
            >
              <Image
                style={ViewStyles.publicInfoIcon}
                source={images.contactUrl}
              />
              <Text style={ViewStyles.publicInfoText}>{url}</Text>
            </TouchableOpacity>
          )}
        </View>
      </DetailGroup>
    </ScrollView>
  )
}

UserProfileContactInfo.propTypes = {
  userInfo: PropTypes.object,
  isMyProfile: PropTypes.bool,
  userProfile: PropTypes.object,
}

export default UserProfileContactInfo
