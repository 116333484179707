/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"
import PropTypes from "prop-types"
import { Keyboard } from "react-native"

import { AppFonts, Units, AppSizes, AppColors } from "@theme/"

// components
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from "react-native"

import Icon from "./icon"

const RoundedButton = ({
  title,
  leftIcon,
  leftImage,
  centerImage,
  color,
  style,
  styleTitle,
  onPress,
  accesoryStyle,
  disabled = false,
  small = false,
  additionalMargin = false,
  testID,
  loading,
  isOnboarding = false,
}) => {
  let titleComponent =
    title && title !== "" ? (
      <Text style={[styles.title, small && styles.smallTitle, styleTitle]}>
        {title}
      </Text>
    ) : null
  let leftAccessory = leftIcon ? leftIcon : leftImage ? leftImage : null

  let accesoryMargins = small
    ? styles.smallAccesoryMargins
    : styles.accesoryMargins

  const onButtonPress = () => {
    onPress()
    Keyboard.dismiss()
  }

  if (leftIcon) {
    leftAccessory = <Icon {...leftIcon} />
  } else if (leftImage) {
    leftAccessory = (
      <Image
        source={leftImage}
        style={[styles.icon, accesoryMargins, accesoryStyle]}
      />
    )
  }

  let button
  if (small) {
    button = (
      <TouchableOpacity
        testID={testID}
        activeOpacity={0.8}
        style={[container(disabled), { backgroundColor: color }, style]}
        disabled={disabled}
        onPress={onButtonPress}
      >
        {leftAccessory}
        {titleComponent}
      </TouchableOpacity>
    )
  } else {
    button = (
      <TouchableOpacity
        testID={testID}
        activeOpacity={0.8}
        style={[
          container(disabled),
          { backgroundColor: color },
          style,
          additionalMargin && {
            marginHorizontal: Units.rem(4.125),
          },
        ]}
        disabled={disabled}
        onPress={onButtonPress}
      >
        {centerImage ? (
          <>
            <View style={styles.flex}></View>
            {leftAccessory}
            {titleComponent}
            <View style={styles.flex}></View>
          </>
        ) : (
          <>
            <View style={styles.flex}>{leftAccessory}</View>
            {titleComponent}
            <View style={styles.flex}></View>
          </>
        )}
      </TouchableOpacity>
    )
  }

  if (loading) {
    button = (
      <View
        testID={testID}
        style={[
          container(false),
          { backgroundColor: color },
          style,
          additionalMargin && {
            marginHorizontal: Units.rem(4.125),
          },
          styles.spinnerWrapper,
        ]}
      >
        <ActivityIndicator
          style={styles.flex}
          color={isOnboarding ? AppColors.bgGradient1 : AppColors.brand.white}
        />
      </View>
    )
  }

  return button
}

const styles = StyleSheet.create({
  spinnerWrapper: { height: Units.responsiveValue(45) },
  title: {
    color: "white",
    ...AppFonts.bigBold,
    paddingTop: Units.rem(0.5),
    paddingBottom: Units.rem(0.625),
  },
  smallTitle: {
    paddingRight: Units.rem(0.875),
  },
  icon: {
    width: Units.rem(1.125),
    height: Units.rem(1.125),
    marginVertical: Units.rem(0.5),
    resizeMode: "contain",
  },
  smallAccesoryMargins: {
    marginHorizontal: Units.rem(0.5),
  },
  accesoryMargins: {
    marginHorizontal: Units.rem(1),
  },
  padding: {
    width: Units.rem(0.5),
  },
  flex: {
    flex: 1,
  },
})

const container = (disabled) => ({
  flexDirection: "row",
  borderRadius: AppSizes.borderRadius,
  justifyContent: "center",
  alignItems: "center",
  opacity: disabled ? 0.3 : 1,
})

RoundedButton.propTypes = {
  title: PropTypes.string,
  leftIcon: PropTypes.string,
  leftImage: PropTypes.any,
  color: PropTypes.string,
  // style: PropTypes.object,
  // styleTitle: PropTypes.object,
  onPress: PropTypes.func.isRequired,
  accesoryStyle: PropTypes.object,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  additionalMargin: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  testID: PropTypes.string,
  loading: PropTypes.bool,
  centerImage: PropTypes.bool,
}

export default RoundedButton
