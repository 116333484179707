import React from "react"
import { View, Text } from "react-native"
import PropTypes from "prop-types"

import { getTimeFormat } from "@utils/dateUtils"
import {
  statusTypeIds,
  reactionsTranslations,
  userStatusTypes,
} from "@configs/mappings"
import { teeupUserGenericStatus } from "@configs/enums"
import { isWhenWorksReaction } from "@utils/messagesUtils"

import styles from "./statusMessageStyles"

const handlePrefixUse = ({ sendersLength, userStatusTrans }) => {
  const isPrefixUsed =
    teeupUserGenericStatus.going === userStatusTrans ||
    teeupUserGenericStatus.notgoing === userStatusTrans ||
    teeupUserGenericStatus.onmyway === userStatusTrans

  return isPrefixUsed ? (sendersLength === 1 ? "is " : "are ") : ""
}

const PeopleSubview = ({ senders, item }) => {
  let userStatus = userStatusTypes[item.statusId]
  let userStatusTrans = teeupUserGenericStatus[userStatus]
  const usePrefix = handlePrefixUse({ sendersLength: 1, userStatusTrans })
  let title
  if (isWhenWorksReaction(item)) {
    title = " responded to the poll"
  } else if (item.typeId === statusTypeIds.reaction) {
    title = ` reacted with ${reactionsTranslations[item.statusId]}`
  } else {
    title = ` ${usePrefix}${userStatusTrans}`
  }

  return (
    <View style={styles.peopleSubview}>
      {senders.map((sender) => (
        <View
          key={sender.username + sender.timestamp}
          style={styles.peopleSubviewItem}
        >
          <Text
            style={[styles.peopleSubviewText, styles.peopleSubviewMainText]}
          >
            {sender.username}
            <Text style={styles.statusTitle}>{title}</Text>
          </Text>
          <Text style={[styles.peopleSubviewText, styles.peopleSubviewTime]}>
            {getTimeFormat(sender.timestamp)}
          </Text>
        </View>
      ))}
      <View style={styles.separateLine} />
    </View>
  )
}

PeopleSubview.propTypes = {
  senders: PropTypes.array,
  item: PropTypes.object,
  isTeeupCancelled: PropTypes.bool,
}

export default PeopleSubview
