import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { IconContext } from "react-icons"
import { FaCheckCircle, FaTimesCircle, FaCircle } from "react-icons/fa"
import cn from "classnames"

import { resetPassword } from "@actions/userActions"
import { selectModalSuccess } from "@selectors/common"
import {
  createPassword,
  setupTeeupListSocket,
} from "@middlewares/backendMiddleware"
import usePassword, {
  CHANGE_PASSWORD_REQUIREMENTS,
  PASSWORD_RULES,
} from "../../../SignUpPage/usePassword"
import StyledInput from "../../../components/StyledInput/StyledInput"
import { logOut } from "@actions/loginActions"
import ChangePasswordSuccess from "../../../ChangePassword/ChangePasswordSuccess"

import { images } from "@utils/imageUtils"

const ChangePassword = ({
  data,
  resetPassErrorMessage,
  resetPassword,
  accessToken,
  passwordRecoveryError,
  modalSuccess,
  logOut,
}) => {
  const [content, setContent] = useState(null)

  const history = useHistory()
  const token = history.location.state

  const {
    password,
    confirmPassword,
    changePassword: setPassword,
    changeConfirmPassword: setConfirmPassword,
    clearStatus,
    failedRequirements,
    isValid,
    visiblePasswordError,
    visiblePasswordErrorDetail,
    visibleConfirmPasswordError,
  } = usePassword()

  useEffect(() => {
    setupTeeupListSocket(accessToken, data.user.id)
  }, [])

  const getRuleIcon = (failedRule) =>
    failedRule ? (
      <IconContext.Provider value={{ color: "#ff0000" }}>
        <FaTimesCircle />
      </IconContext.Provider>
    ) : (
      <IconContext.Provider value={{ color: "#33b542" }}>
        <FaCheckCircle />
      </IconContext.Provider>
    )

  const handleChangeTempPassword = () => {
    //Your new password must be different from your previous password
    createPassword(accessToken, password)
  }

  return (
    <>
      {!modalSuccess ? (
        <div className="forgot__page" style={{ position: "relative" }}>
          <div className="forgot__content">
            <img className="forgot__icon" src={images.cooeModal} />

            <span className="reset_password__title">Set Password</span>
            <p className="reset_password__subtitle">
              In order to protect your account, make sure your password:
            </p>
            <span className="reset_password__requirements_list">
              {CHANGE_PASSWORD_REQUIREMENTS.map((req) => {
                const failedRule = failedRequirements.includes(req.type)

                return (
                  <div key={`req-${req.type}`} className="requirements__item">
                    {password ? (
                      getRuleIcon(failedRule)
                    ) : (
                      <IconContext.Provider value={{ color: "#3e3e3e" }}>
                        <FaCircle />
                      </IconContext.Provider>
                    )}
                    <span className="requirements__text">{req.text}</span>
                  </div>
                )
              })}

              <div className="requirements__item">
                {password ? (
                  getRuleIcon(resetPassErrorMessage === PASSWORD_RULES.PREVIOUS)
                ) : (
                  <IconContext.Provider value={{ color: "#3e3e3e" }}>
                    <FaCircle />
                  </IconContext.Provider>
                )}
                <span className="requirements__text">
                  Not be a previously used password
                </span>
              </div>
            </span>
            <StyledInput
              type="password"
              value={password}
              setValue={setPassword}
              placeholder="Create New Password"
              isValid={!visiblePasswordError}
              validationError={"Please enter a valid password"}
            />
            <StyledInput
              type="password"
              value={confirmPassword}
              setValue={setConfirmPassword}
              placeholder="Confirm Password"
              isValid={!visibleConfirmPasswordError}
              validationError={visibleConfirmPasswordError}
            />
            <span className="reset_password--error">
              {resetPassErrorMessage === PASSWORD_RULES.PREVIOUS
                ? ""
                : resetPassErrorMessage}
            </span>
            <button
              disabled={!isValid}
              style={{ marginTop: 10 }}
              className={cn("reset_password_button", {
                "reset_password_button--disabled": !isValid,
              })}
              onClick={handleChangeTempPassword}
            >
              {/* Set Password */}
              Continue
            </button>
          </div>
        </div>
      ) : (
        <ChangePasswordSuccess />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    resetPassErrorMessage: state.user.resetPassErrorMessage,
    modalSuccess: selectModalSuccess(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetPassword: bindActionCreators(resetPassword, dispatch),
  logOut: bindActionCreators(logOut, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
