import React, { useState, useMemo, useEffect } from "react"

import "./index.scss"
import { getFileSizeFromBytes, isImageFile } from "@utils/fileUtils"
import { getFileIcon } from "@utils/imageUtils"
import { images } from "@utils/imageUtils"

const FilePreview = ({ file, removeFile }) => {
  const { name, type, size } = file
  let preview = null

  if (isImageFile(type)) {
    const url = URL.createObjectURL(file)
    preview = <img className="img-preview" src={url} alt="" />
  } else {
    const fileType = type.replace("application/", ".")
    const { size: fileSize, sizeType } = getFileSizeFromBytes(size)

    preview = (
      <div className="file-preview">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "10px",
            color: "rgb(115, 132, 140)",
          }}
        >
          <span className="file-preview__name">{name}</span>
          <span className="file-preview__size">
            {fileSize} {sizeType}
          </span>
        </div>
        <img style={{ height: "2.5rem" }} src={getFileIcon(fileType)} />
      </div>
    )
  }

  return (
    <div className="file_preview_container">
      <img
        className="close-icon remove_file_icon"
        src={images.closeGrey}
        alt=""
        onClick={() => removeFile(name)}
      />
      {preview}
    </div>
  )
}

export default FilePreview
