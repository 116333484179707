import React, { useRef, useState } from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import People from "./People"
import GamePlanContainer from "../../gamePlansCard/gameplansCardContainer"
import { images } from "@utils/imageUtils"

const RightSide = ({
  sideBarContent,
  peopleSideBar,
  setPeopleSideBar,
  teeup,
  handleOpenSuggestion,
  content,
  setContent,
  suggestionListContent,
  setSuggestionListContent,
}) => {
  // const [content, setContent] = useState(null);
  const [isOptionOpened, setIsOptionOpened] = useState(false)
  const [animation, setAnimation] = useState("")
  const [modalContent, setModalContent] = useState(null)
  const [inviteesContent, setInviteesContent] = useState(null)
  const optionRef = useRef()

  const openOption = () => setIsOptionOpened(true)
  const closeOption = () => setIsOptionOpened(false)

  const closeWithAnimations = () => {
    document.body.style.overflow = "auto"
    setAnimation("right-slide")
    setTimeout(() => {
      closeOption()
      setAnimation("")
    }, 250)
  }

  return (
    <div className="rightside">
      {modalContent &&
        ReactDOM.createPortal(
          <div
            className="addinvitees__overlay"
            onClick={() => setModalContent(null)}
          >
            <div
              className="addinvitees__modal"
              onClick={(event) => event.stopPropagation()}
            >
              {modalContent}
            </div>
          </div>,
          document.getElementById("modal-root")
        )}
      {inviteesContent &&
        ReactDOM.createPortal(
          <div
            className="settings create"
            style={{ overflow: "hidden" }}
            onClick={() => setInviteesContent(null)}
          >
            <div
              className="create__modal--window settings__modal settings__modal-content animation"
              style={{
                overflow: "initial",
                width: 608,
                height: "90vh",
                padding: 0,
                backgroundColor: "#ffffff",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              {inviteesContent}
            </div>
          </div>,
          document.getElementById("modal-root")
        )}
      {peopleSideBar && (
        <>
          <div className="title__wrapper" style={{ animationName: animation }}>
            <h3 className="teeup__title">{teeup.name}</h3>
          </div>

          <div
            className="settings"
            style={{
              width: 370,
              // left: 'calc(100vw - 405px)',
              height: "calc(100vh - 164px)",
              minHeight: "515px",
              boxShadow: "0 0 4px 0 rgb(0 0 0 / 10%)",
              borderRadius: "8px",
              zIndex: 1,
            }}
            onClick={() => {
              closeWithAnimations()
              setPeopleSideBar(false)
            }}
          >
            <div
              ref={optionRef}
              className="people__overlay"
              style={{ animationName: animation, padding: 0 }}
              onClick={(event) => event.stopPropagation()}
            >
              {suggestionListContent}
              {/* {content || suggestionListContent || sideBarContent} */}
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  closeWithAnimations()
                  setPeopleSideBar(false)
                }}
              >
                {/* <img  className="people__overlay--cross" src={images.cross} alt="close" /> */}
              </div>
            </div>
          </div>
        </>
      )}
      <People
        showAddInvitees={setInviteesContent}
        inviteesContent={inviteesContent}
        openOption={openOption}
        closeOption={closeWithAnimations}
        setModalContent={setModalContent}
        teeup={teeup}
      />
      <div className="block gameplan">
        <div className="gameplan__container">
          <GamePlanContainer
            openSuggestions={openOption}
            closeSuggestions={() => {
              closeOption()
              setPeopleSideBar(false)
            }}
            showGameplanOptions={(content) => {
              setPeopleSideBar(true)
              openOption()
              setContent(content)
            }}
            showSuggestionList={(content) => {
              setPeopleSideBar(true)
              openOption()
              setSuggestionListContent(content)
            }}
            handleOpenSuggestion={handleOpenSuggestion}
            closeSidebar={() => {
              closeWithAnimations()
              setPeopleSideBar(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default RightSide
