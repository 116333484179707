import React, { Component } from "react"
import { View, TouchableOpacity, FlatList, ScrollView } from "react-native"
import PropTypes from "prop-types"
import ContactAvatar from "../ui/contactAvatar"
import { styles } from "./styles"
// import * as Animatable from 'react-native-animatable'

const gradientColors = ["rgb(0,0,0)", "rgba(0,0,0,0)"]

class InviteeList extends Component {
  state = {}

  onLayout = (event) => {
    const { height } = event.nativeEvent.layout
    this.setState({ height })
  }

  renderItem = ({ item }) => {
    const { onRemoveInvitee, onPressRow } = this.props
    const {
      avatar,
      userType,
      contactType,
      label,
      firstName,
      lastName,
      name,
      value,
    } = item
    return (
      <View
        key={"invitee" + firstName + name}
        style={styles.inviteeItem}
        pointerEvents={onPressRow ? "none" : "auto"}
      >
        <ContactAvatar
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
          name={name}
          onRemoveInvitee={
            onRemoveInvitee ? onRemoveInvitee({ data: item }) : undefined
          }
          contactLabel={label}
          userType={userType}
          contactType={contactType}
          value={value}
        />
      </View>
    )
  }

  keyExtractor = (item, index) => `${item.userType}-${index}`

  renderScollViewItem = (item, index) => this.renderItem({ item, index })

  componentDidUpdate(prevProps) {
    let invitees = this.props.invitees
    if (invitees.length !== prevProps.invitees.length) {
      if (this.listRef) {
        // this.listRef.scrollToItem({ animated: true, item: invitees[invitees.length - 1] })
        setTimeout(() => {
          // Add time to render the new item
          this.listRef.scrollToOffset({ animated: true, offset: 500 })
        }, 100)
      } else if (this.scrollViewRef) {
        setTimeout(() => {
          this.scrollViewRef.scrollToEnd({ animated: true })
        }, 100)
      }
    }
  }

  render() {
    let { invitees, flatListStyle, onPressRow, shouldShow } = this.props
    const { height } = this.state

    if (onPressRow) {
      return (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          keyboardShouldPersistTaps="always"
          style={styles.scrollViewContainer}
          contentContainerStyle={styles.contentContainerStyle}
          ref={(ref) => (this.scrollViewRef = ref)}
        >
          <TouchableOpacity
            style={styles.absoluteButton}
            onPress={onPressRow}
          />
          {invitees.map(this.renderScollViewItem)}
        </ScrollView>
      )
    }

    return (
      <View
        transition="height"
        duration={500}
        pointerEvents={onPressRow ? "none" : "auto"}
        style={[
          styles.inviteesContainer,
          // styles.slideAnimation(
          //     !height || invitees.length === 0 || !shouldShow
          //         ? 0
          //         : height
          // ),
        ]}
      >
        <FlatList
          contentContainerStyle={[styles.flatList, flatListStyle]}
          horizontal
          data={invitees}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          ref={(ref) => (this.listRef = ref)}
        />
        {
          // Need to know height of item to animate row appearance properly
          !height && (
            <View style={styles.waitingForHeight} onLayout={this.onLayout}>
              <FlatList
                contentContainerStyle={[styles.flatList, flatListStyle]}
                horizontal
                data={[{}]}
                renderItem={this.renderItem}
                keyExtractor={this.keyExtractor}
              />
            </View>
          )
        }
        {/* {!shouldShow ? (
                    <LinearGradient
                        colors={gradientColors}
                        style={styles.inviteesShadow}
                        start={{ x: 0.5, y: 0 }}
                        end={{ x: 0.5, y: 1 }}
                    />
                ) : (
                    <View style={styles.inviteesBorder} />
                )} */}
      </View>
    )
  }
}

InviteeList.propTypes = {
  invitees: PropTypes.array.isRequired,
  onRemoveInvitee: PropTypes.func,
  onPressRow: PropTypes.func,
  flatListStyle: PropTypes.object,
  shouldShow: PropTypes.bool,
}

export default InviteeList
