import actionTypes from "./actionTypes"
import endpoints from "@config/endpoints"
import api from "../api"
import { getUserContactMechanisms } from "./userActions"
import { store } from "../index"
import { selectRetreiveTeeupId } from "@selectors/retrieveTeeup"
import { v4 as uuidv4 } from "uuid"

const addMechanism = (type, contact) => ({
  type: actionTypes.ADD_USER_CONTACT_MECHANISM,
  payload: { type, contact },
})

const deleteMechanism = (id) => {
  return function (dispatch) {
    return api.client.delete(endpoints.contactMechanismsId(id))
  }
}

const deleteMechanismFromState = (id) => ({
  type: actionTypes.DELETE_USER_CONTACT_MECHANISM,
  payload: id,
})

const getMechanisms = () => ({ type: actionTypes.GET_USER_CONTACT_MECHANISMS })

const verifySignedContactMechanism = () => ({
  type: actionTypes.VERIFY_SIGNED_CONTACT_MECHANISM,
})

const addLocation = (location) => ({
  type: actionTypes.ADD_USER_CONTACT_LOCATION,
  payload: location,
})

const removeLocation = (location) => ({
  type: actionTypes.REMOVE_USER_CONTACT_LOCATION,
  payload: location,
})

const addUrl = (url) => ({
  type: actionTypes.ADD_USER_CONTACT_URL,
  payload: url,
})

const removeUrl = (url) => ({
  type: actionTypes.REMOVE_USER_CONTACT_URL,
  payload: url,
})

const updateLocation = (locations) => ({
  type: actionTypes.UPDATE_USER_CONTACT_LOCATIONS,
  payload: locations,
})

const updateContactMechs = (contactMechanisms, mechType) => ({
  type: actionTypes.UPDATE_USER_CONTACT_MECHANISMS,
  payload: { contactMechanisms, mechType },
})

// const changeLocationDescriptionAndPlaceId = ({ id, location }) =>
//     actionNormal({
//         type: actionTypes.CHANGE_LOCATION_DESCRITION_AND_PLACEID,
//         payload: { id, location },
//     })

// const changeRadius = ({ id, newRadius }) =>
//     actionNormal({
//         type: actionTypes.CHANGE_LOCATION_RADIUS,
//         payload: { id, newRadius },
//     })

const changeLabel = ({ id, newLabel }) => ({
  type: actionTypes.CHANGE_LOCATION_LABEL,
  payload: { id, newLabel },
})

const updateUserLocation = ({ id, location }) => ({
  type: actionTypes.UPDATE_LOCATION,
  payload: { id, location },
})

const addNewLabel = ({ newLabel }) => ({
  type: actionTypes.ADD_NEW_LOCATION_LABEL,
  payload: { newLabel },
})

const saveNewLocations = (newLocations) => ({
  type: actionTypes.SAVE_NEW_LOCATIONS,
  payload: { newLocations },
})

const addUserContactMechanism = (contacts) => {
  const url = endpoints.contactMechanisms()

  return api.client
    .post(url, contacts)
    .then((response) => {
      const { data } = response
      
      return data
    })
    .catch((error) => {
      console.log("addUserContactMechanism error", error)
      throw error
    })
}

const deleteUserContactMechanism = (contactMechanismId) => {
  const url = endpoints.contactMechanismsId(contactMechanismId)

  return api.client.delete(url).then((response) => {
    const { data } = response

    return data
  })
}

const publishContactMechanism = (contactMechanismId) => {
  const url = endpoints.contactMechanismPublish(contactMechanismId)

  return api.client.post(url, {}).then((response) => {
    const { data } = response

    return data
  })
}

const unpublishContactMechanism = (contactMechanismId) => {
  const url = endpoints.contactMechanismUnpublish(contactMechanismId)

  return api.client.post(url, {}).then((response) => {
    const { data } = response

    return data
  })
}

const requestVerifyContactMechanism = (contactMechanismId) => {
  const url = endpoints.contactMechanismVerifications(contactMechanismId)
  const deviceId = uuidv4()

  return api.client.post(url, { deviceId }).then((response) => {
    const { data } = response

    return data
  })
}

const deleteContactMechanism = async (contactMechanismId) => {
  try {
    const url = endpoints.contactMechanismsId(contactMechanismId)
    await api.client.delete(url)
    getUserContactMechanisms()
  } catch (e) {
    console.log(e)
  }
}

const verifyContactMechanism = (verificationId, code) => {
  const url = endpoints.contactMechanismVerificationsVerify(verificationId)
  return api.client.post(url, { code })
}

const contactMechanismVerified = async (userId, fromAccountScreen, params) => {
  try {
    const contactMechanisms = await getUserContactMechanisms()
    store.dispatch({
      type: actionTypes.SET_USER_CONTACT_MECHANISMS,
      payload: contactMechanisms,
    })

    // const retrieveTeeupId = selectRetreiveTeeupId(store.getState())

    // await getFullTeeupsDispatch(userId)
    // const teeups = selectTeeups(globalGetState())

    // if (retrieveTeeupId && !fromAccountScreen) {
    //     const filteredTeeups = teeups.filter(
    //         ({ id }) => `${id}` === retrieveTeeupId
    //     )
    //     if (filteredTeeups.length > 0) {
    //         const teeup = filteredTeeups[0]
    //         globalDispatch({
    //             type: actionTypes.CREATE_TEEUP_UPDATE,
    //             payload: teeup,
    //         })

    //         routeHome()
    //         setTimeout(() => {
    //             routeTeeup()
    //         }, 200)
    //     } else {
    //         routeHome()
    //     }
    // } else if (teeups.length > 0 && !fromAccountScreen) {
    //     const teeup = teeups[0]
    //     globalDispatch({
    //         type: actionTypes.CREATE_TEEUP_UPDATE,
    //         payload: teeup,
    //     })

    //     routeHome()
    //     setTimeout(() => {
    //         routeTeeup()
    //     }, 200)
    // } else if (!fromAccountScreen) {
    //     routeHome()
    // } else {
    //     dismissModal(params.componentId)
    //     pop(params.parentComponentId)
    // }
  } catch (e) {
    console.log(e)
  }
}

export {
  addMechanism,
  deleteMechanism,
  getMechanisms,
  verifySignedContactMechanism,
  addLocation,
  removeLocation,
  updateLocation,
  addUrl,
  removeUrl,
  addNewLabel,
  changeLabel,
  updateUserLocation,
  saveNewLocations,
  updateContactMechs,
  addUserContactMechanism,
  deleteUserContactMechanism,
  publishContactMechanism,
  unpublishContactMechanism,
  requestVerifyContactMechanism,
  deleteContactMechanism,
  verifyContactMechanism,
  contactMechanismVerified,
  deleteMechanismFromState,
}
