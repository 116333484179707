import React from "react"
import { GoogleApiWrapper } from "google-maps-react"

import {
  SuggestModalFooter,
  SuggestModalContent,
  SuggestModalSubHeader,
  SuggestModalLeftSide
} from "../../components"

import Pinboard from "./Pinboard"
import SearchField from "./SearchField"
import MapContainer from "./MapContainer"

import './index.scss'
import useInPersonTabState from "../Hooks/useInPersonTabState"

const InPersonTab = () => {
  const { activePlace } = useInPersonTabState()

  return (
    <div className="suggest-in-person">
      <SuggestModalLeftSide>
        <SuggestModalSubHeader>
          <Pinboard activePlace={activePlace} />
        </SuggestModalSubHeader>
        <SuggestModalContent>
          <SearchField />

        </SuggestModalContent>
        <SuggestModalFooter></SuggestModalFooter>
      </SuggestModalLeftSide>
      <MapContainer />
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC8ce4byJ3uPdPTNZ6_dpw9utQVGClnVL4",
})(InPersonTab)
// export default InPersonTab