import { StyleSheet } from "react-native"
import { AppFonts, AppColors, Units } from "@theme"

export const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatarStyle: {
    marginRight: Units.responsiveValue(6),
    flexDirection: "row",
    alignSelf: "baseline",
  },
  headerTitleText: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
  },
  contentBetween: {
    justifyContent: "space-between",
  },
  headerContent: {
    width: "100%",
  },
})

export const IconProps = {
  email: {
    type: "material-icons",
    name: "email",
    size: Units.responsiveValue(13),
    color: AppColors.brand.pink,
  },
}

export const headerContainer = (
  fromNowString,
  isArchivePromptShow,
  isEnded
) => ({
  display: "flex",
  justifyContent: "end",
  marginLeft: "auto",
  flex: "1",
  padding: "2px",
  backgroundColor: !isEnded ? "white" : "AppColors.brand.grey243",
  borderRadius: Units.responsiveValue(12),
  ...(!fromNowString &&
    !isArchivePromptShow && {
      // backgroundColor: AppColors.brand.grey243,
      backgroundColor: AppColors.brand.cardBackground,
    }),
})

export const invitationRow = (additionalPadding, directionColumn) => ({
  // ...(additionalPadding ? { paddingLeft: Units.responsiveValue(10) } : { marginLeft: Units.responsiveValue(40) }),
  flexDirection: directionColumn ? "column" : "row",
  alignItems: "center",
  marginLeft: 8,
})
