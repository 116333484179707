import React from "react"

import "./index.scss"

const SuggestModalLeftSide = ({ children }) => {
  return (
    <div className="suggest-modal__left-side">
      {children}
    </div>
  )
}

export default SuggestModalLeftSide
