import React from "react"
import { Text, View, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { selectUserId } from "@selectors/user"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { reactionTypes } from "@configs/mappings"
// import teeupActions from '@actions/teeupActions'
import { isTeeupDisabled } from "@utils/teeupUtils"
import { AppColors, Units } from "@theme"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"

import {
  GameplansRowStyles,
  reactionContainer,
  activeReactionContainer,
} from "./gameplansStyles"
import { addReaction } from "../../middlewares/backendMiddleware"
// import { ViewStyles } from '../teeupStyles'
import { IconContext } from "react-icons"
import { updateUserStatus } from "@actions/requestActions"
import { teeupUserStatusPriorities, teeupUserStatusKeys } from "@config/enums"
// import {fetchTeeupUsersById, fetchGameplanOptions, gotTeeupParts, updateGameplan} from '@actions/teeupActions';
import * as teeupActions from "@actions/teeupActions"
import {
  fetchTeeupUsersById,
  fetchGameplanOptions,
} from "@actions/teeupActions"
import { ReactionButtonView } from "./reactionButtonView"

// const getIcon = (iconID) => {
//     switch (iconID) {
//         case reactionTypes.upvote:
//             return <FaThumbsUp/>
//         case reactionTypes.downvote:
//             return <FaThumbsDown/>
//         default:
//             return null
//     }
// }

class ReactionButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      [reactionTypes.upvote]: 0,
      [reactionTypes.downvote]: 0,
      [reactionTypes.notVote]: 0,
      reacted: null,
      reactedCustomId: null,
    }
  }

  onReaction = (reactionId) => {
    // const {
    //     teeup: { id: teeupId },
    //     teeup,
    //     userId,
    //     updateUserStatus,
    //     updateGameplan,
    // } = this.props
    const id = Number(reactionId)
    const { suggestionId, retractReaction } = this.props
    // const statusId = teeupUserStatusPriorities.joined

    addReaction({
      reactionId: id,
      suggestionId,
    })

    // if (teeup.userStatus !== teeupUserStatusKeys.joined) {
    //     updateUserStatus(
    //         teeupId,
    //         statusId,
    //     ).then(async (error) => {
    //         const teeupUsers = await fetchTeeupUsersById(teeupId)
    //         this.props.gotTeeupParts([teeupUsers])
    //         updateGameplan(teeupId, await fetchGameplanOptions(teeupId))

    //         if (error) {
    //             console.log('handleRespondToInvitation Error: ', {
    //                 error,
    //             })
    //         }
    //     })
    // }
  }

  static getDerivedStateFromProps(nextProps) {
    let updates = null
    if (nextProps.reactions) {
      updates = {
        reacted: null,
        reactedCustomId: null,
      }

      nextProps.reactions.map((reaction) => {
        if (reaction && reaction.reactedBy) {
          updates[reaction.reactionId] = reaction.reactedBy.length

          reaction.reactedBy.map((reactedSingle) => {
            if (nextProps.userId === reactedSingle) {
              updates.reacted = Number(reaction.reactionId)
            }
            if (nextProps.customReactionId === reactedSingle) {
              updates.reactedCustomId = Number(reaction.reactionId)
            }
          })
        }
      })
    }

    if (updates && !updates[reactionTypes.upvote]) {
      updates[reactionTypes.upvote] = 0
    }
    if (updates && !updates[reactionTypes.downvote]) {
      updates[reactionTypes.downvote] = 0
    }
    if (updates && !updates[reactionTypes.notVote]) {
      updates[reactionTypes.notVote] = 0
    }
    return updates
  }

  render() {
    const { inSuggestion, isFromSuggestionListHeader, inactive, newDesign } =
      this.props
    // console.log(this.state.[reactionTypes.upvote], 'upvote')
    // console.log(this.state.[reactionTypes.downvote], 'downvote')
    // console.log(this.state.[reactionTypes.notVote], 'notVote')

    return (
      <View
        style={[
          GameplansRowStyles.reactionsContainer,
          inSuggestion && GameplansRowStyles.reactionsContainerInSuggestion,
          isFromSuggestionListHeader &&
            GameplansRowStyles.reactionsContainerInSuggestionHeader,
        ]}
      >
        <ReactionButtonView
          id={reactionTypes.upvote}
          disableReaction={this.props.disableReaction}
          teeup={this.props.teeup}
          previewMode={this.props.previewMode}
          isMyMessage={this.props.isMyMessage}
          inSuggestion={this.props.inSuggestion}
          inactive={this.props.inactive}
          isInNudge={this.props.isInNudge}
          sections={this.props.sections}
          suggestionId={this.props.suggestionId}
          reacted={this.state.reacted}
          onReaction={this.onReaction}
          number={this.state}
          inactive={inactive}
          newDesign={newDesign}
        />
        <ReactionButtonView
          id={reactionTypes.downvote}
          disableReaction={this.props.disableReaction}
          teeup={this.props.teeup}
          previewMode={this.props.previewMode}
          isMyMessage={this.props.isMyMessage}
          inSuggestion={this.props.inSuggestion}
          inactive={this.props.inactive}
          isInNudge={this.props.isInNudge}
          sections={this.props.sections}
          suggestionId={this.props.suggestionId}
          reacted={this.state.reacted}
          onReaction={this.onReaction}
          number={this.state}
          inactive={inactive}
          newDesign={newDesign}
        />
        {/* {!previewMode &&
                    this.RenderReactionButton(reactionTypes.notVote)} */}
      </View>
    )
  }
}

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//         {
//             ...teeupActions,
//         },
//         dispatch
//     )

const mapStateToProps = (state) => {
  const userId = selectUserId(state)
  const teeup = selectActiveTeeup(state)
  return {
    userId,
    teeup,
  }
}

const mapMethodsToProps = (dispatch) =>
  bindActionCreators(
    {
      ...teeupActions,
      updateUserStatus,
    },
    dispatch
  )

export default connect(mapStateToProps, mapMethodsToProps)(ReactionButton)

ReactionButton.propTypes = {
  reactions: PropTypes.array,
  disableReaction: PropTypes.bool,
  previewMode: PropTypes.bool,
  isMyMessage: PropTypes.bool,
  suggestionId: PropTypes.number,
  // addReaction: PropTypes.func.isRequired,
  // retractReaction: PropTypes.func.isRequired,
  // userId: PropTypes.oneOfType([
  //     PropTypes.number.isRequired,
  //     PropTypes.object,
  // ]),
  customReactionId: PropTypes.number,
  teeup: PropTypes.object,
  inSuggestion: PropTypes.bool,
  inactive: PropTypes.bool,
  isInNudge: PropTypes.bool,
}

ReactionButton.defaultProps = {
  inSuggestion: true,
  retractReaction: () => {},
  addReaction: () => {},
}
