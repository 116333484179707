import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { View } from "react-native"

// import { bindActionCreators } from 'redux'

import UserMentionsScreenComponent from "./UserMentionsScreenComponent"

import {
  selectPermissions,
  selectSuggestionMessages,
  selectTeeupGameplans,
  // selectTeeupReadTimestamps,
  selectTeeupSuggestions,
  selectTeeupPeople,
  // selectTeeupState,
} from "@selectors/teeups"
// import {
//     selectActiveReplyMessage,
//     selectActiveTextMessage,
// } from '@selectors/teeupCards'
// import { selectNewSuggestions } from '@selectors/localData'
import { selectUserInfo } from "@selectors/user"
import {
  selectActiveTeeup,
  selectVisibleTeeupPinnedMessages,
  selectTeeupMentions,
} from "@selectors/activeTeeup"

import { getMessageOptions, showChatMessageContextMenu } from "@utils/chatUtils"

import ChatMessage from "@ui/chatMessage"

import { styles } from "./UserMentionsScreenStyles"

const noOp = () => {}

class UserMentionsScreenContainer extends React.Component {
  static propTypes = {
    mentions: PropTypes.arrayOf(PropTypes.object),
    teeup: PropTypes.object.isRequired,
    people: PropTypes.object.isRequired,
    teeupSuggestions: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    suggestions: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    newSuggestions: PropTypes.array.isRequired,
    suggestionMessages: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    newDesign: PropTypes.bool,
  }

  constructor(props) {
    super(props)
  }

  handleNudgeItemRender = ({ item, index }) => {
    const {
      people,
      teeup,
      userInfo,
      suggestions,
      mentions,
      permissions,
      newSuggestions,
      suggestionMessages,
      // highlighterRef,
      newDesign,
    } = this.props

    let params = {
      people,
      expanded: true,
      // toggleExpansion: noOp, //
      teeup,
      userInfo,
      suggestions,
      messages: mentions,
      permissions,
      newSuggestions,
      suggestionMessages,
      item,
      index,
      // onUnpinMessage: null,
      showOnMentionsScreen: true,
      expandedStatusMessagesArray: [],
      isTimestampsVisible: false,
      openPreview: noOp, //
      isReplyDisabled: true,
      // onReplyStart: noOp, //
      // goToSuggestion: noOp, //
      // onStatusMessagePress: noOp, //
      // handleCloseAddFilesMenu: noOp, //
      // toggleSwiping: noOp, //
      onContextMenu: this.onContextMenu,
      activeReplyMessageId: null,
      // openParticipantDetails: this.linkParticipantPopup,
      openParticipantDetails: noOp,
      handleKeyboardClose: noOp, //
      isKeyboardActive: false,
      // onFilePress: noOp, //
      // onSuggestionCommentPress: noOp, //
      // onFileUploadCancel: noOp, //
      // onFileRetry: noOp, //
    }
    let options = getMessageOptions(params)

    const getViewObj = (_options = options, style) => (
      <View collapsable={false} style={[styles.mentionsMessage, style]}>
        <ChatMessage
          {..._options}
          key={item.id}
          messages={params.messages}
          showOnMentionsScreen={params.showOnMentionsScreen}
          newDesign={newDesign}
        />
      </View>
    )

    const returnObj = getViewObj()
    // let viewObj = returnObj
    // item.id && highlighterRef.current?.setViewObj(key, viewObj)

    return returnObj
  }

  onContextMenu =
    (id, suggestionMessage) =>
    (passedProps = {}) => {
      const {
        teeup,
        permissions,
        userInfo: { status: userStatus },
        mentions,
        handleClose,
      } = this.props

      showChatMessageContextMenu({
        outsideOfChat: true,
        messages: mentions,
        id,
        suggestionMessage,
        passedProps,
        // toggleExpansion: noOp, //
        // downloadFile: noOp, //
        expanded: true,
        teeup,
        permissions,
        userStatus,
        isKeyboardActive: false,
        onShowOnConversation: handleClose,
        // onFilePress: noOp,
        // onReplyStart: noOp,
        // setRequestedContextMenu: noOp,
      })
    }

  render() {
    const { mentions, newDesign } = this.props
    return (
      <UserMentionsScreenComponent
        mentions={mentions}
        renderItem={this.handleNudgeItemRender}
        newDesign={newDesign}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const userInfo = selectUserInfo(state)
  const people = selectTeeupPeople(state)
  const mentions = selectTeeupMentions(state)
  const permissions = selectPermissions(state)
  const pinnedMessages = selectVisibleTeeupPinnedMessages(state)
  const suggestionMessages = selectSuggestionMessages(state)
  const teeup = selectActiveTeeup(state)
  const gameplans = selectTeeupGameplans(state)
  const suggestions = selectTeeupSuggestions(state)
  // const teeupReadTimestamp = selectTeeupReadTimestamps(state)
  // const newSuggestions = selectNewSuggestions(state)
  // const teeupState = selectTeeupState(state)
  // const activeReplyMessage = selectActiveReplyMessage(state)
  // const activeTextMessage = selectActiveTextMessage(state)

  return {
    userInfo,
    people,
    mentions,
    permissions,
    pinnedMessagesCount: pinnedMessages.length,
    suggestionMessages,
    gameplans,
    suggestions,
    teeupReadTimestamp: [],
    teeup,
    newSuggestions: [],
    // teeupState,
    // activeReplyMessage,
    // activeTextMessage,
  }
}

// const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(UserMentionsScreenContainer)
