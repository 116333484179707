import React from "react"
import PropTypes from "prop-types"
import { View, Animated } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import styles, { messageStyles } from "./messageStyles"
import { Units } from "@theme"

import { setMentionedMessages } from "@actions/commonActions"

import "./message.scss"

class Message extends React.PureComponent {
  constructor(props) {
    super(props)

    this.opacity = new Animated.Value(0)
    this.state = {
      hasOverlay: false,
    }
  }

  componentDidMount() {
    this.props.messages
      ? this.props.setMentionedMessages(this.props.messages)
      : this.props.setMentionedMessages(undefined)
  }

  animate() {
    this.setState({
      hasOverlay: true,
    })

    Animated.sequence([
      Animated.timing(this.opacity, {
        useNativeDriver: false,
        toValue: 1,
        duration: 1000,
      }),
      Animated.timing(this.opacity, {
        useNativeDriver: false,
        toValue: 0,
        duration: 400,
      }),
    ]).start(() => {
      this.setState({ hasOverlay: false })
    })
  }

  render() {
    const {
      isMyMessage,
      isStatusMessage,
      isSuggestion,
      messageType,
      isDisabled,
      children,
      onLongPress,
      openPreview,
      isTeeupCancelled,
      isImage,
      isFile,
      isReply,
      onSuggestionCommentScreen,
      messages,
      id,
      isSuggestionOpen,
      newDesign,
      isInitialMessage,
      isTimestampsVisible,
    } = this.props

    const style = [
      messageStyles({
        isMyMessage,
        isTeeupCancelled,
        isImage,
        isFile,
        isReply,
        isSuggestion,
        onSuggestionCommentScreen,
        messages,
        isSuggestionOpen,
        newDesign,
        isInitialMessage,
        isTimestampsVisible,
        isStatusMessage,
      }),
      // !isSuggestion && styles[messageType](isMyMessage),
      isStatusMessage && styles.statusMessage,
      isSuggestion && styles.suggestionMessage,
      styles.selectableText,
    ]
    return (
      <View
        nativeID={id}
        style={[
          style,
          messages && {
            backgroundColor: "#fff2dd",
            border: newDesign ? "1px solid #e3e5e8" : "none",
          },
        ]}
      >
        {children}
        {this.state.hasOverlay && (
          <Animated.View style={[styles.overlay, { opacity: this.opacity }]} />
        )}
      </View>
    )
  }
}

Message.propTypes = {
  isMyMessage: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isSuggestion: PropTypes.bool,
  isImage: PropTypes.bool,
  isFile: PropTypes.bool,
  suggestionStatus: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isStatusMessage: PropTypes.bool,
  onLongPress: PropTypes.func,
  openPreview: PropTypes.func,
  isTeeupCancelled: PropTypes.bool,
  isReply: PropTypes.bool,
  isAnimated: PropTypes.bool,
  onSuggestionCommentScreen: PropTypes.bool,
  newDesign: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isSuggestionOpen: state.common.isSuggestionOpen,
})

const mapDispatchToProps = (dispatch) => ({
  setMentionedMessages: bindActionCreators(setMentionedMessages, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Message)
