import React, { useState, useRef, useEffect } from "react"
import "./index.scss"
import { connect } from "react-redux"
import { selectUserId } from "../../../selectors/user"
import { useHistory } from "react-router-dom"
import ReactDOM from "react-dom"
import Avatar from "@ui/avatar"
import CloseButton from "../../teeupUserResponseDialogs/dialogButtons/closeButton"
import StatusTooltip from "../StatusTooltip"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import Modal from "react-modal"
import cn from "classnames"
import { updateTeeupStatus } from "../../../middlewares/backendMiddleware"
import { selectActiveTeeup } from "../../../selectors/activeTeeup"
import { updateTeeupArchiveStatus } from "../../../actions/teeupActions"
import { statusText } from "../../../config/mappings"
import { ReactSVG } from "react-svg"
import { images } from "@utils/imageUtils"

const messages = {
  allset: "Are you sure you want to mark the TeeUp as All Set?",
  happening: "Are you sure you want to switch the TeeUp to Happening Now?",
  ended: "Are you sure you want to end the TeeUp?",
}

const options = [
  {
    value: "rgb(255, 111, 37)",
    label: "planning",
  },
  {
    value: "rgb(0, 197, 174)",
    label: "allset",
  },
  {
    value: "rgb(98, 68, 202)",
    label: "happeningSoon",
  },
  {
    value: "rgb(0, 188, 246)",
    label: "happening",
  },
  {
    value: "rgb(127, 127, 127)",
    label: "ended",
  },
  {
    value: "rgb(245, 45, 34)",
    label: "cancelled",
  },
]

const DropDown = ({ userId, teeUp, active, title, imgUrl }) => {
  const [status, setStatus] = useState(active.status)
  const { settings, organisers } = teeUp
  const organizer = organisers.map(({ id }) => id).includes(userId)
  const { value: statusColor } = options.find(({ label }) => label === status)
  const [statusBorder, setstatusBorder] = useState(statusColor)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 })
  const dropdownRef = useRef()
  const [isHovered, setIsHovered] = useState("white")
  const history = useHistory()

  const [isTooltip, setIsTooltip] = useState(false)
  const [tooltipText, setTooltipText] = useState("")
  const triangleColor = `transparent transparent ${statusColor} transparent`

  useEffect(() => {
    if (isModalOpen) {
      setIsHovered(false)
    }
  }, [isModalOpen])

  // useEffect(() => {
  //   setStatus(active.status);
  // }, [active]);

  const statusButtonStyles = {
    borderColor: status,
    color: statusColor,
    backgroundColor: isModalOpen ? statusColor : "transparent",
  }

  const modalStyles = {
    content: {
      top: "50%",
      transform: "translateY(-50%)",
      left: "41%",
      backgroundColor: "#F5F8FA",
      // backgroundColor: '#F5F5F5',
      width: 320,
      height: "fit-content",
      boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 8px 5px",
      border: "1px solid #F3F3F3",
      padding: "0px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  }

  const openModal = () => {
    setIsModalOpen(!isModalOpen)
    setModalPosition(dropdownRef.current.getBoundingClientRect())
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const changeStatus = (option) => {
    if (option.label === "happeningSoon" || option.value === statusColor) {
      return
    }
    if (organizer && settings.allowChangeState) {
      if (option.label !== "planning") {
        let message = messages[option.label]
        confirmAlert({
          message,
          buttons: [
            {
              label: "No",
              onClick: () => setIsModalOpen(false),
            },
            {
              label: "Yes",
              onClick: () => {
                setStatus(option.label)
                setIsModalOpen(false)
                updateTeeupStatus(userId, option)
              },
            },
          ],
        })
      } else {
        setStatus(option.label)
        setIsModalOpen(false)
        updateTeeupStatus(userId, option)
      }
    } else {
      return
    }
  }

  const cancelTeeUp = () => {
    confirmAlert({
      title: "Cancel TeeUp?",
      message: `Are you sure you want to cancel "${teeUp.name}"? This will end the TeeUp for all participants and be archived. This action cannot be undone.`,
      buttons: [
        {
          label: "Go Back",
          onClick: () => setIsModalOpen(false),
        },
        {
          label: "Cancel",
          onClick: () => {
            setStatus("cancelled")
            setIsModalOpen(false)
            updateTeeupArchiveStatus(teeUp.id)
            updateTeeupStatus(userId, options[options.length - 1])
            history.push("/teeUps")
          },
        },
      ],
    })
  }

  const backgroundStatusBorderColor = (option) => {
    const notClickable =
      !organizer || option.label === "happeningSoon" ? true : false
    let border = ""
    if (isHovered && option.value === statusBorder) {
      border = `2px solid ${option.value}`
    } else if (notClickable && option.value !== statusColor) {
      border = `2px solid #fff`
    } else {
      border = `2px solid #f0f2f5`
    }
    return border
  }

  const changeToHoveredColor = (color) => {
    setstatusBorder(color)
  }

  const textButtonColor = (option) => {
    let color = ""
    if (option.value === statusColor) color = "white"
    else if (option.label === "happeningSoon" || !organizer) {
      color = "#A9B0B9"
    } else {
      color = "#8D8D8D"
    }
    return color
  }

  const backgrounStatusdColor = (option) => {
    let color
    if (option.value === statusColor) {
      color = statusColor
    } else if (!organizer || option.label === "happeningSoon") {
      color = "#f0f2f5"
    } else {
      color = "white"
    }
    return color
  }
  const handleHovering = (option) => {
    if (option.label !== "happeningSoon" && organizer) {
      changeToHoveredColor(option.value)
      setIsHovered(true)
    } else if (!organizer) {
      setIsTooltip(true)
      if (option.label === "happening") {
        setTooltipText("Only organizers can change to Happening Now")
      } else if (option.label === "happeningSoon") {
        setTooltipText(
          "Happening soon is automatic, you cannot change to it manually"
        )
      } else {
        setTooltipText("Only organizers can change the Teeup State")
      }
    } else if (organizer && option.label === "happeningSoon") {
      setIsTooltip(true)
      setTooltipText(
        "Happening soon is automatic, you cannot change to it manually"
      )
    } else if (!organizer && option.label === "happening") {
      setIsTooltip(true)
      setTooltipText("Only organizers can change to Happening Now")
    } else setIsTooltip(false)
  }

  return (
    <>
      <div
        ref={dropdownRef}
        className={cn("dropdown", {
          "dropdown--is-active": isModalOpen,
        })}
        style={statusButtonStyles}
        onClick={openModal}
      >
        {statusText[status]}
        <div
          className={cn("dialog-button__dropdown-arrow", {
            "arrow-color__orange": status === "planning",
            "arrow-color__green": status === "allset",
            "arrow-color__blue": status === "happening",
            "arrow-color__purple": status === "happeningSoon",
            "arrow-color__gray": status === "ended",
            "arrow-color__red": status === "cancelled",
          })}
        >
          <ReactSVG src={images.ndDropdownArrowIcon} className={"icon"} />
        </div>
      </div>

      {isModalOpen && (
        <>
          <div className="select-status-backdrop" />
          <div className="select-status-modal">
            <div className="dropdown__header_title_container">
              <Avatar size={60} borderWidth={0} imageUrl={imgUrl} />
              <span className="dropdown__header_title">{title}</span>
              <span className="dropdown__header_status">STAGE</span>
              <CloseButton onClose={closeModal} />
            </div>
            <div className="dropdown__content_container">
              {options.map((option, index, arr) => {
                if (index === arr.length - 1) return

                return (
                  <div
                    className="dropdown__option"
                    key={option.value}
                    data-tip
                    data-for="status-tooltip"
                    onMouseOver={() => {
                      handleHovering(option)
                    }}
                    onMouseOut={(e) => {
                      let unhoveredColor =
                        statusColor === option.value ? statusColor : "#fff"
                      changeToHoveredColor(unhoveredColor)
                      setIsHovered(false)
                      setIsTooltip(false)
                    }}
                    onClick={
                      option.value === statusColor
                        ? () => setIsModalOpen(false)
                        : () => changeStatus(option)
                    }
                    style={{
                      cursor:
                        option.label === "happeningSoon" || !organizer
                          ? ""
                          : "pointer",
                      backgroundColor: backgrounStatusdColor(option),
                      color: textButtonColor(option),
                      border: backgroundStatusBorderColor(option),
                    }}
                  >
                    {statusText[option.label]}
                  </div>
                )
              })}
              {organizer && (
                <span className="dropdown__cancel" onClick={cancelTeeUp}>
                  cancel teeup
                </span>
              )}
            </div>

            {isTooltip &&
              ReactDOM.createPortal(
                <StatusTooltip tooltipText={tooltipText} />,
                document.getElementById("modal-root")
              )}
          </div>
        </>
      )}

      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        ariaHideApp={false}
      >
        <div className="dropdown__header_title_container">
          <Avatar size={60} borderWidth={0} imageUrl={imgUrl} />
          <span className="dropdown__header_title">{title}</span>
          <span className="dropdown__header_status">STATUS</span>
          <CloseButton onClose={closeModal} />
        </div>
        <div className="dropdown__content_container">
          {options.map((option, index, arr) => {
            if (index === arr.length - 1) return

            return (
              <div
                className="dropdown__option"
                key={option.value}
                onClick={
                  option.value === statusColor
                    ? () => setIsModalOpen(false)
                    : () => changeStatus(option)
                }
                style={{
                  backgroundColor:
                    option.value === statusColor ? statusColor : "white",
                  color: option.value === statusColor ? "white" : "#8D8D8D",
                }}
              >
                {statusText[option.label]}
              </div>
            )
          })}
          {organizer && (
            <span className="dropdown__cancel" onClick={cancelTeeUp}>
              cancel teeup
            </span>
          )}
        </div>
      </Modal> */}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userId: selectUserId(state),
    active: selectActiveTeeup(state),
  }
}

export default connect(mapStateToProps, null)(DropDown)
