import React, { Component } from "react"
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native"
import XDate from "xdate"
import PropTypes from "prop-types"
import styleConstructor from "./style"
import { weekDayNames } from "../../dateutils"
import {
  CHANGE_MONTH_LEFT_ARROW,
  CHANGE_MONTH_RIGHT_ARROW,
} from "../../testIDs"
import { images } from "@utils/imageUtils"
import { AppColors } from "@theme"

class CalendarHeader extends Component {
  static displayName = "IGNORE"

  static propTypes = {
    theme: PropTypes.object,
    hideArrows: PropTypes.bool,
    month: PropTypes.instanceOf(XDate),
    addMonth: PropTypes.func,
    showIndicator: PropTypes.bool,
    firstDay: PropTypes.number,
    renderArrow: PropTypes.func,
    hideDayNames: PropTypes.bool,
    weekNumbers: PropTypes.bool,
    onPressArrowLeft: PropTypes.func,
    onPressArrowRight: PropTypes.func,
    disableArrowLeft: PropTypes.bool,
    disableArrowRight: PropTypes.bool,
    webAriaLevel: PropTypes.number,
    updateSuggestWeek: PropTypes.func,
    isCalendarExpanded: PropTypes.bool,
    addWeek: PropTypes.func,
    substractWeek: PropTypes.func,
  }

  static defaultProps = {
    monthFormat: "MMMM yyyy",
    webAriaLevel: 1,
  }

  constructor(props) {
    super(props)

    this.style = styleConstructor(props.theme)
    this.addMonth = this.addMonth.bind(this)
    this.substractMonth = this.substractMonth.bind(this)
    this.onPressLeft = this.onPressLeft.bind(this)
    this.onPressRight = this.onPressRight.bind(this)
  }

  addMonth() {
    this.props.addMonth(1)
  }

  substractMonth() {
    this.props.addMonth(-1)
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.month.toString("yyyy MM") !==
      this.props.month.toString("yyyy MM")
    ) {
      return true
    }
    if (nextProps.showIndicator !== this.props.showIndicator) {
      return true
    }
    if (nextProps.hideDayNames !== this.props.hideDayNames) {
      return true
    }
    if (nextProps.firstDay !== this.props.firstDay) {
      return true
    }
    if (nextProps.weekNumbers !== this.props.weekNumbers) {
      return true
    }
    if (nextProps.monthFormat !== this.props.monthFormat) {
      return true
    }
    if (nextProps.renderArrow !== this.props.renderArrow) {
      return true
    }
    if (nextProps.disableArrowLeft !== this.props.disableArrowLeft) {
      return true
    }
    if (nextProps.disableArrowRight !== this.props.disableArrowRight) {
      return true
    }
    if (nextProps.expandIconName !== this.props.expandIconName) {
      return true
    }
    if (nextProps.enableOnWeekDayPress !== this.props.enableOnWeekDayPress) {
      return true
    }
    if (nextProps.currentWeekDay !== this.props.currentWeekDay) {
      return true
    }
    if (nextProps.isExpand !== this.props.isExpand) {
      return true
    }
    if (nextProps.isCalendarExpanded !== this.props.isCalendarExpanded) {
      return true
    }

    return false
  }

  onPressLeft() {
    const { onPressArrowLeft } = this.props
    if (typeof onPressArrowLeft === "function") {
      return onPressArrowLeft(this.substractMonth, this.props.month)
    }
    onPressArrowLeft(this.substractMonth, this.props.month)
    this.substractMonth()
  }

  onPressRight() {
    const { onPressArrowRight } = this.props
    if (typeof onPressArrowRight === "function") {
      return onPressArrowRight(this.addMonth, this.props.month)
    }
    onPressArrowRight(this.addMonth, this.props.month)
    this.addMonth()
  }

  renderMonth(arg) {
    const isAbsolute = !!arg?.isAbsolute
    const {
      changePosition,
      handleToggleCalendar,
      isCalendarExpanded,
      isTemporalCustom,
      month,
      monthFormat,
      webAriaLevel,
      showIndicator,
    } = this.props
    const webProps = Platform.OS === "web" ? { "aria-level": webAriaLevel } : {}
    const newStyles = {
      content: [{ flexDirection: "row" }],
      text: [this.style.monthText],
    }

    if (isTemporalCustom) {
      // newStyles.content.push(this.style.roundBorder)
      newStyles.content.push({ paddingHorizontal: 12, paddingVertical: 7 })
      newStyles.text.push({ margin: 0, marginRight: 10 })
    }

    const absoluteStyle = isAbsolute
      ? {
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "1rem",
        }
      : {}

    return (
      <TouchableOpacity style={absoluteStyle} onPress={handleToggleCalendar}>
        <View style={newStyles.content}>
          <Text
            allowFontScaling={false}
            style={newStyles.text}
            accessibilityRole="header"
            onPress={changePosition}
            {...webProps}
          >
            {month.toString(monthFormat).toUpperCase()}
          </Text>
          <img
            src={images.expandCalendarArrow}
            style={{ transform: isCalendarExpanded && "rotate(180deg)" }}
            alt="expand icon"
          />
          {showIndicator && (
            <ActivityIndicator
              color={this.props.theme && this.props.theme.indicatorColor}
            />
          )}
        </View>
      </TouchableOpacity>
    )
  }

  renderLeftArrow() {
    const { testID, disableArrowLeft, hideArrows, isTemporalCustom } =
      this.props

    if (hideArrows) {
      return <View />
    }

    const newStyles = [
      this.style.arrow,
      { marginLeft: 10, padding: 15, cursor: "pointer" },
    ]

    if (isTemporalCustom) {
      // newStyles.push(this.style.roundBorder)
      newStyles.push({ padding: 7 })
      newStyles.push({ width: 31, height: 31 })
    }

    return (
      <TouchableOpacity
        onPress={this.onPressLeft}
        disabled={disableArrowLeft}
        style={newStyles}
        hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
        testID={
          testID
            ? `${CHANGE_MONTH_LEFT_ARROW}-${testID}`
            : CHANGE_MONTH_LEFT_ARROW
        }
      >
        <img
          src={images.chevron}
          style={{ width: 15, height: 15, transform: "rotate(180deg)" }}
          alt="arrow icon"
        />
      </TouchableOpacity>
    )
  }

  renderRightArrow() {
    const { testID, disableArrowRight, hideArrows, isTemporalCustom } =
      this.props
    if (hideArrows) {
      return <View />
    }
    const newStyles = [
      this.style.arrow,
      { marginRight: 10, padding: 15, cursor: "pointer" },
    ]

    if (isTemporalCustom) {
      // newStyles.push(this.style.roundBorder)
      newStyles.push({ padding: 7 })
      newStyles.push({ width: 31, height: 31 })
    }

    return (
      <TouchableOpacity
        onPress={this.onPressRight}
        disabled={disableArrowRight}
        style={newStyles}
        hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
        testID={
          testID
            ? `${CHANGE_MONTH_RIGHT_ARROW}-${testID}`
            : CHANGE_MONTH_RIGHT_ARROW
        }
      >
        <img
          src={images.chevron}
          style={{ width: 15, height: 15 }}
          alt="arrow icon"
        />
      </TouchableOpacity>
    )
  }

  renderDayNames() {
    let weekDaysNames = weekDayNames(this.props.firstDay)
    const { currentWeekDay, isExpand, isTemporalCustom } = this.props

    const wrapStyle = [this.style.week]
    if (isTemporalCustom) {
      // wrapStyle.push({ margin: 0, marginBottom: "2rem", width: "75%" })
      wrapStyle.push({ margin: 0, marginBottom: "2rem", width: "370px" })
    }

    return (
      <View style={wrapStyle}>
        {this.props.weekNumbers && (
          <Text allowFontScaling={false} style={this.style.dayHeader}></Text>
        )}
        {weekDaysNames.map((day, idx) => (
          <TouchableOpacity
            key={"weekDaysNames" + idx}
            onPress={() => this.props.onWeekDayPress(idx)}
            disabled={!this.props.enableOnWeekDayPress}
          >
            <Text
              allowFontScaling={false}
              key={idx}
              accessible={false}
              style={[
                this.style.dayHeader,
                idx < currentWeekDay &&
                  isExpand && {
                    color: AppColors.brand.grey216,
                  },
              ]}
              numberOfLines={1}
              importantForAccessibility="no"
            >
              {day.toUpperCase()}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    )
  }

  renderWithoutCustom() {
    const { hideDayNames, isTemporalCustom } = this.props
    const viewStyles = [this.style.header]
    if (isTemporalCustom) {
      viewStyles.push({ width: "370px" })
    }
    return (
      <>
        <View style={viewStyles}>
          {this.renderLeftArrow()}
          {this.renderMonth()}
          {this.renderRightArrow()}
        </View>
        {!hideDayNames && this.renderDayNames()}
      </>
    )
  }

  renderWithCustom() {
    const { hideDayNames } = this.props

    const wrapStyle = {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      position: "relative",
      marginVertical: "auto",
      height: "100%",
    }
    const actionsStyle = {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "50%",
      height: "100%",
      marginVertical: 12,
    }
    return (
      <View style={wrapStyle}>
        {this.renderMonth({ isAbsolute: true })}
        <View style={actionsStyle}>
          {this.renderLeftArrow()}
          {hideDayNames ? <View /> : this.renderDayNames()}
          {this.renderRightArrow()}
        </View>
      </View>
    )
  }

  render() {
    const generalStyle = [this.props.style]
    const { isTemporalCustom } = this.props
    if (isTemporalCustom) {
      generalStyle.push({
        // top: 0,
        marginHorizontal: "auto",
        marginTop: "7px",
        width: "370px",
        height: "3.7rem",
      })
    }
    return (
      <View style={generalStyle}>
        {/* {isTemporalCustom
          ? this.renderWithCustom()
          : this.renderWithoutCustom()} */}
        {this.renderWithoutCustom()}
      </View>
    )
  }
}

export default CalendarHeader
