import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import _ from "lodash"
import cn from "classnames"

import cancel from "../../../assets/images/cancel.png"

const NameDisplay = ({ handleAddNames }) => {
  const [fullName, setFullName] = useState("")
  const [name, setName] = useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isCorrectName, setIsCorrectName] = useState(true);
  const [isCorrectFullName, setIsCorrectFullName] = useState(true)

  useEffect(() => {
    if (name.trim().length === 0 || fullName.trim().length === 0) {
      setIsButtonDisabled(true)
    }
  }, [fullName, name])

  useEffect(() => {
    if (fullName.length < 3 || fullName.includes("  ")) {
      setIsCorrectFullName(false)
      setIsButtonDisabled(true)
    } else {
      setIsCorrectFullName(true)
    }
  }, [fullName])

  useEffect(() => {
    if (name.length < 3 || name.includes("  ")) {
      setIsCorrectName(false)
      setIsButtonDisabled(true)
    } else {
      setIsCorrectName(true)
      setIsButtonDisabled(false)
    }
  }, [name])

  return (
    <>
      <span className="forgot__title">Name and Display</span>
      <p className="forgot__subtitle">
        Your display name is how you will be viewed by others in conversations
        and public spaces for privacy.
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleAddNames(fullName, name)
        }}
      >
        <label htmlFor="fullname" className="forgot__label">
          Full Name
        </label>
        <div style={{ position: "relative", marginBottom: 15 }}>
          <input
            id="fullname"
            placeholder="Enter first and last name"
            value={fullName}
            onChange={({ target }) => setFullName(target.value)}
            className="login__input forgot__input"
            type="text"
          />
          {(!isCorrectFullName && fullName.length > 0) && (
            <span
              style={{
                position: "absolute",
                left: 0,
                bottom: -15,
                fontFamily: "greycliff-cf-medium",
                fontSize: 15,
                fontWeight: 600,
                textAlign: "left",
                color: "#ff0000",
              }}
            >
              Must be at least 3 characters
            </span>
          )}
        </div>

        <label htmlFor="name" className="forgot__label">
          Display Name
        </label>
        <div style={{ position: "relative" }}>
          <input
            id="name"
            placeholder="Create a display name"
            value={name}
            onChange={({ target }) => setName(target.value)}
            className="login__input forgot__input"
            type="text"
          />
          {(!isCorrectName && name.length > 0) && (
            <span
              style={{
                position: "absolute",
                left: 0,
                bottom: -15,
                fontFamily: "greycliff-cf-medium",
                fontSize: 15,
                fontWeight: 600,
                textAlign: "left",
                color: "#ff0000",
              }}
            >
              Must be at least 3 characters
            </span>
          )}
        </div>
        <button
          type="submit"
          disabled={isButtonDisabled}
          style={{ marginTop: 40 }}
          className={cn("forgot__button", {
            "forgot__button--disabled": isButtonDisabled,
          })}
          onClick={() => handleAddNames(fullName, name)}
        >
          Continue
        </button>
      </form>
    </>
  )
}

export default NameDisplay
