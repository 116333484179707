import React from "react"
import { View, Text } from "react-native"
import PropTypes from "prop-types"
import Avatar from "@ui/avatar"
import StatusSelector from "./statusSelector"
import { UserStatusModalStyles as styles } from "./styles"
import CloseButton from "../dialogButtons/closeButton"

const MyStatusView = ({
  userInfo,
  status,
  additionalCount,
  userStatus,
  onUserStatusSelected,
  isDataLoaded,
  onClose,
  onClickBringing,
  handleSetPeopleStatus,
  people,
}) => {
  const renderHeader = () => {
    return (
      <View style={styles.titleContainer}>
        <Avatar size={60} borderWidth={0} imageUrl={userInfo.avatar} />

        <Text style={styles.subtitle}>MY SHARED STATUS</Text>
        <CloseButton onClose={onClose} />
      </View>
    )
  }

  return (
    <>
      {renderHeader()}
      <View style={styles.statusesContainer}>
        <StatusSelector
          people={people}
          handleSetPeopleStatus={handleSetPeopleStatus}
          additionalCount={additionalCount}
          userStatus={userStatus}
          teeupStatus={status}
          onUserStatusSelected={onUserStatusSelected}
          isDataLoaded={isDataLoaded}
          onClickBringing={onClickBringing}
        />
      </View>
    </>
  )
}

MyStatusView.propTypes = {
  userInfo: PropTypes.object,
  status: PropTypes.string,
  additionalCount: PropTypes.number,
  userStatus: PropTypes.string,
  isDataLoaded: PropTypes.bool,
  onUserStatusSelected: PropTypes.func,
  onClose: PropTypes.func,
  onClickBringing: PropTypes.func,
}

export default MyStatusView
