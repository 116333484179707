const reverseMapping = (keys, mapping) => {
  let reverse = {}
  for (let i = 0, len = keys.length; i < len; i++) {
    const key = keys[i]
    reverse[mapping[key]] = key
  }
  return reverse
}

const prepareMappings = (mapping) => {
  const keys = Object.keys(mapping)
  const reverse = reverseMapping(keys, mapping)
  const values = Object.keys(reverse)

  return {
    mapping,
    keys,
    values,
    reverse,
    ...mapping,
  }
}

const teeupStatus = prepareMappings({
  planning: "Planning",
  allset: "All set",
  happening: "Happening now",
  happeningSoon: "Happening Soon",
  ended: "Ended",
  cancelled: "Cancelled",
})

const teeupFilterButtonTitles = prepareMappings({
  ...teeupStatus,
  planning_unscheduled: "Unscheduled",
  planning: "Scheduled",
})

const teeupNotifications = prepareMappings({
  criticalMassEnabled: "criticalMassEnabled",
  happeningSoon: "Happening",
})

const teeupUserGenericStatus = prepareMappings({
  going: "Going",
  mightgo: "Might Go",
  notgoing: "Not Going",
  invited: "Invited",
  joined: "Joined",
  onmyway: "On My Way",
  arrived: "Arrived",
  // attended: 'Attended',
  declined: "Declined",
  droppedout: "Dropped out",
  // coolIdea: 'Cool Idea',
  // notSure: 'Not Sure',
  // notGood: 'Not Good',
  runningLate: "Running Late",
  // backedOut: 'Backed Out',
})

const teeupUserStatus = prepareMappings({
  going: "I'm Going",
  mightgo: "Might Go",
  notgoing: "Not Going",
  invited: "",
  joined: "Joined",
  onmyway: "On My Way",
  arrived: "Arrived",
  attended: "Attended",
  skipped: "Declined",
  droppedout: "Dropped out",
  coolIdea: "Cool Idea",
  notSure: "Not Sure",
  notGood: "Not Good",
  runningLate: "Running Late",
  backedOut: "Backed Out",
})

const gameplanType = {
  activity: "What",
  when: "When",
  startsWhen: "StartsWhen",
  where: "Where",
}

const teeupParticipantStatusPriorities = {
  going: 1,
  arrived: 2,
  mightgo: 3,
  onmyway: 4,
  runningLate: 5,
  notgoing: 6,
  joined: 7,
  invited: 8,
}

const userTypes = {
  normal: 1,
  betaInitial: 2,
  betaFull: 3,
  draft: 4,
  draftTellUs: 5,
}

const loginProviders = {
  fb: "facebook",
  apple: "apple",
  google: "google-oauth2",
  twitter: "twitter",
  email: "email",
}

const genderOptions = {
  undefined: "Not specified",
  male: "Male",
  female: "Female",
  other: "Non-binary",
}

const teeupLastActivityTypes = prepareMappings({
  changeUserStatus: 1,
  addSuggestion: 2,
  withdrewSuggestion: 3,
  commentOnSuggestion: 4,
  changeGamePlan: 5,
  setSuggestionAsDecided: 6,
  setSuggestionAsUndecided: 7,
  createTeeup: 8,
  joinTeeup: 9,
  changeTeeupStatus: 10,
  cancelTeeup: 11,
  inviteToTeeup: 12,
  sentMessage: 13,
  sentPicture: 14,
  sentFile: 15,
})

const connectionStatuses = {
  PENDING: "Request sent",
  REJECTED: "Request rejected",
  ACCEPTED: "Connected",
}

const teeUpSorting = [
  null,
  "status", //instead of teeupStatus
  // 'teeupStatus',
  "userStatus",
  "createdat",
  // 'createdAt',
]

const teeupTypes = {
  basic: "basic",
  group: "group",
  community: "community",
}

const teeupTypesTitles = prepareMappings({
  basic: "Private",
  group: "Group",
  community: "Public",
})

const chatMessageTypes = {
  DEFAULT: "default",
  HEAD: "head",
  MIDDLE: "middle",
  TAIL: "tail",
}

const teeupUserStatusKeys = {
  invited: "invited",
  joined: "joined",
  going: "going",
  mightgo: "mightgo",
  notgoing: "notgoing",
  onmyway: "onmyway",

  arrived: "arrived",
  droppedout: "droppedout",
  declined: "declined",
  // skipped: 'skipped',
  // attended: 'attended',
  // coolIdea: 'coolIdea',
  // notSure: 'notSure',
  // notGood: 'notGood',
  runningLate: "runningLate",
  // backedOut: 'backedOut',
}

const teeupUserStatusPriorities = {
  invited: 1,
  going: 2,
  mightgo: 3,
  notgoing: 4,
  joined: 5,
  onmyway: 6,
  arrived: 7,
  attended: 8,
  droppedout: 9,
  skipped: 10,
  approvalWaiting: 11,
  approvalDeclined: 12,
  coolIdea: 13,
  runningLate: 17,
  backedOut: 18,
}

const teeupStatusKeys = {
  planning: "planning",
  allset: "allset",
  happening: "happening",
  happeningSoon: "happeningSoon",
  ended: "ended",
  cancelled: "cancelled",
}

const teeupStageFilterKeys = {
  planning_unscheduled: "planning_unscheduled",
  ...teeupStatusKeys,
}

const directMessageTypes = {
  regular: 1,
  initial: 2,
}

const placeNameTypes = ["cafe", "restaurant", "establishment", "hospital"]

const signupResultTypes = {
  skip: "skip",
}

const signUpErrorTypes = {
  email: "Failed to verify email",
  phone: "Failed to verify phone number",
  default: "Failed to verify phone number or email",
  invalidOrExpiredCode: "Invalid or expired code",
  tooManyRequests: "Too Many Requests",
  userNotFound: "User Not Found",
}

const requestErrors = {
  recoverableError: "Recoverable error",
  unrecoverableError: "Unrecoverable error",
  timeout: "Call timed out",
}

const notificationLevels = {
  muteEverything: "MUTE_EVERYTHING",
  importantOnly: "IMPORTANT_ONLY",
  conversation: "CONVERSATION",
  everything: "EVERYTHING",
}

const teeupUserStatusKeysWithPeople = [
  teeupUserStatusKeys.mightgo,
  teeupUserStatusKeys.going,
  teeupUserStatusKeys.onmyway,
  teeupUserStatusKeys.arrived,
  teeupUserStatusKeys.runningLate,
]

const teeupListNudgeTypes = prepareMappings({
  invited: 1,
  rejoin: 2,
  archive: 3,
  suggestion: 4,
  areYouGoing: 5,
  whereAreYou: 6,
  actionRequires: 7,
  mention: 8,
  nudgeRespondedTo: 9,
})

const teeupCategories = prepareMappings({
  teeups: 1,
  archivedTeeups: 2,
  removedTeeups: 3,
})

const highlightTypes = {
  conversation: 0,
  pinboard: 1,
  mentions: 2,
  nudges: 3,
}

const calendarDateChangeMethod = {
  pageScroll: "pageScroll",
  propUpdate: "propUpdate",
  dayPress: "dayPress",
  hideSkeletonAfterScroll: "hideSkeletonAfterScroll", //custom
}

const titles = {
  defaultTitle: "Coo·e Coordinator",
  createTitle: "Create TeeUp",
  suggestWhere: "Suggest Where",
  suggestWhen: "Suggest When",
}

const initialTeeup = {
  id: null,
  name: null,
  invited: [],
  userStatus: "going",
  gameplans: [
    {
      type: "when",
      icon: "clock",
      title: "When",
      isPrimary: true,
      suggestions: [],
    },
    {
      type: "where",
      icon: "map-marker-alt",
      title: "Where",
      isPrimary: true,
      suggestions: [],
    },
  ],
  organisers: [],
  settings: {},
  type: "basic",
  photo: null,
  interests: [],
  status: "planning",
  isPrivate: true,
  valid: false,
  messages: [],
  detailsOpen: false,
  detailsUser: null,
  invitationHistory: [],
}

export {
  teeupStatus,
  teeupNotifications,
  teeupUserStatus,
  teeupUserGenericStatus,
  gameplanType,
  loginProviders,
  userTypes,
  teeUpSorting,
  genderOptions,
  connectionStatuses,
  chatMessageTypes,
  teeupUserStatusKeys,
  teeupStatusKeys,
  teeupUserStatusKeysWithPeople,
  teeupStageFilterKeys,
  teeupFilterButtonTitles,
  teeupTypes,
  teeupTypesTitles,
  teeupUserStatusPriorities,
  directMessageTypes,
  placeNameTypes,
  signupResultTypes,
  signUpErrorTypes,
  requestErrors,
  notificationLevels,
  teeupListNudgeTypes,
  teeupCategories,
  calendarDateChangeMethod,
  highlightTypes,
  titles,
  initialTeeup,
  teeupParticipantStatusPriorities,
  teeupLastActivityTypes,
}
