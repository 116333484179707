import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { setupTeeupListSocket } from "@middlewares/backendMiddleware"
import { getCalendarEvents } from "@utils/calendarSyncUtils"

import { setTeeupActive } from "@actions/activeTeeupActions"
import { selectUserId, selectToken } from "@selectors/user"
import CalendarView from "@components/calendar"
import { initialTeeup } from "@config/enums"

const buttonIds = {
  back: "backId",
  settings: "settingsId",
}

const MyCalendarView = (props) => {
  const {
    calendarWidth,
    openCalendarSettings,
    activeScreen,
    refVal,
    token,
    userId,
  } = props

  useEffect(() => {
    setupTeeupListSocket(token, userId)

    getCalendarEvents({ shouldSupressPermissionToast: false })
    return () => {
      setTeeupActive(initialTeeup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CalendarView
      openSettings={openCalendarSettings}
      fromProfile={true}
      calendarWidth={calendarWidth}
      activeScreenFN={activeScreen}
      refVal={refVal}
    />
  )
}

MyCalendarView.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    userId: selectUserId(state),
    token: selectToken(state),
  }
}

const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(MyCalendarView)
