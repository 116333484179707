import { StyleSheet } from "react-native"

import { AppColors, Units, AppFonts, AppSizes } from "@theme"

export const styles = StyleSheet.create({
  placeholderView: { flex: 1, flexGrow: 1, backgroundColor: "transparent" },
  container: {
    flex: 1,
    backgroundColor: AppColors.brand.white,
    paddingTop: Units.responsiveValue(AppSizes.statusBarHeight),
    paddingBottom: Units.responsiveValue(AppSizes.additionalBottomSpace),
  },
  listContainer: {
    backgroundColor: AppColors.brand.lightGrey,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(8),
  },
  suggestionRow: {
    // height: Units.responsiveValue(64),
    marginTop: Units.responsiveValue(1),
    paddingHorizontal: Units.responsiveValue(12),
    paddingVertical: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.grey247,
    flexDirection: "row",
    alignItems: "center",
  },
  locationRow: { flex: 1 },
  historyIconWrapper: {
    marginRight: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.grey2,
    alignItems: "center",
    justifyContent: "center",
    height: Units.responsiveValue(32),
    width: Units.responsiveValue(32),
    borderRadius: Units.responsiveValue(16),
  },
  historyIcon: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
  },
  specificText: {
    flexGrow: 1,
    marginHorizontal: Units.rem(0.75),
  },
  separator: {
    height: 1,
    backgroundColor: AppColors.brand.whiteGrey,
  },
  cancelText: {
    ...AppFonts.bigDemibold,
    color: AppColors.brand.pink,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  labelText: {
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.black2,
    lineHeight: Units.responsiveValue(25),
    letterSpacing: -Units.responsiveValue(0.4),
  },
  headerButton: {
    width: Units.responsiveValue(44),
    height: Units.responsiveValue(44),
    alignItems: "center",
    justifyContent: "center",
  },
  backArrow: {
    tintColor: AppColors.brand.pink,
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
  },
  inputWrapper: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: AppColors.brand.whiteGrey,
    height: Units.responsiveValue(42),
    paddingHorizontal: Units.responsiveValue(16),
    borderRadius: Units.responsiveValue(8),
    overflow: "hidden",
  },
  textSearch: {
    flex: 1,
    padding: 0,
    margin: 0,
    flexDirection: "row",
    ...AppFonts.biggerMedium,
    color: AppColors.brand.black,
  },
  clearButtonWrapper: {
    justifyContent: "center",
    flexShrink: 1,
  },
  clearImage: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
  },
  suggestionWrapper: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: Units.responsiveValue(12),
    paddingRight: Units.responsiveValue(16),
    backgroundColor: AppColors.brand.grey247,
  },
  leftPart: {
    width: Units.responsiveValue(44),
    alignItems: "center",
    justifyContent: "center",
  },
  descriptionPart: {
    flex: 1,
    flexGrow: 1,
    marginLeft: Units.responsiveValue(12.3),
    justifyContent: "center",
    alignItems: "flex-start",
  },
  distanceText: {
    flexDirection: "row",
    ...AppFonts.extraSmallMedium,
    letterSpacing: -Units.responsiveValue(0.2),
    textAlign: "center",
    color: AppColors.brand.warmGrey2,
    marginTop: Units.responsiveValue(8),
  },
  text: {
    flexDirection: "row",
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.black2,
    letterSpacing: -Units.responsiveValue(0.34),
    textAlign: "left",
  },
  subText: {
    flexDirection: "row",
    ...AppFonts.mediumMedium,
    color: AppColors.brand.warmGrey2,
    letterSpacing: -Units.responsiveValue(0.3),
    textAlign: "left",
  },
  searchValue: {
    ...AppFonts.biggerBold,
  },
  doneButton: {
    flexDirection: "row",
    margin: Units.responsiveValue(16),
    height: Units.responsiveValue(48),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.pink,
  },
  mapImage: {
    width: Units.responsiveValue(18),
    height: Units.responsiveValue(14),
  },
  iconWrapper: (isSearchTerm) => ({
    height: Units.responsiveValue(28),
    width: Units.responsiveValue(28),
    borderRadius: Units.responsiveValue(14),
    backgroundColor: isSearchTerm ? AppColors.brand.pink_15 : undefined,
    alignItems: "center",
    justifyContent: "center",
  }),
  rigthArrowWrapper: {
    alignItems: "center",
    justifyContent: "center",
  },
  iconStyle: {
    tintColor: AppColors.brand.black2,
    height: Units.responsiveValue(17),
    width: Units.responsiveValue(17),
    resizeMode: "contain",
  },
  doneButtonText: {
    flexDirection: "row",
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.4),
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.white,
  },
})
