import actionTypes from "../actions/actionTypes"
import { userContactTypes } from "@utils/contactUtils"
import { currentCountry } from "@utils/location"
import _ from "lodash"

export const initialState = {
  userInfo: {
    userId: null,
  },
  loginErrorMessage: null,
  resetPassErrorMessage: null,
  hasInternetConnection: false,
  avatar: "https://www.w3schools.com/w3images/avatar2.png",
  friendlist: [],

  customDates: [],
  customTimes: [],
  settings: {
    notifications: {},
    calendarSync: {},
    contacts: {},
    notificationLevel: "",
    applicationSoundEnabled: true,
  },
  contactMechanisms: {
    phone: [],
    email: [],
    location: [],
    userTitles: ["Home", "Work", "School", "Hangout"],
    url: [],
  },
  interests: [],
  authDetails: {
    verifyAttempt: 0,
  },
  primaryLocation: {},
  isLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_INFO:
      // Need to check if country is present because not all users were registered with country mentioned in user info
      let { country } = action.payload
      return {
        ...state,
        userInfo: { ...action.payload, country: country || currentCountry },
      }
    case actionTypes.UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
      }
    case actionTypes.SET_LOGIN_ERROR:
      return {
        ...state,
        loginErrorMessage: action.payload,
      }
    case actionTypes.ADD_USER_CONTACT_LOCATION: {
      let location = action.payload
      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          location: [...state.contactMechanisms.location, location],
        },
      })
    }
    case actionTypes.REMOVE_USER_CONTACT_LOCATION: {
      let id = action.payload
      let location = state.contactMechanisms.url.filter(
        (singleLocation) => singleLocation.id !== id
      )
      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          location,
        },
      })
    }
    case actionTypes.REMOVE_USER_CONTACT_URL: {
      let id = action.payload
      let url = state.contactMechanisms.url.filter(
        (singleUrl) => singleUrl.id !== id
      )
      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          url,
        },
      })
    }
    case actionTypes.SET_USER_CONTACT_MECHANISMS: {
      let contactMechanisms = action.payload
      let contactsNew = {
        phone: [],
        email: [],
        location: [],
        url: [],
      }

      contactMechanisms.map((contact) => {
        if (contact.value) {
          const newContact = {
            ...contact,
          }

          if (
            contact.typeid === userContactTypes.PHONE ||
            contact.typeid === userContactTypes.PRIMARY_PHONE
          ) {
            contactsNew.phone.push(newContact)
          } else if (
            contact.typeid === userContactTypes.EMAIL ||
            contact.typeid === userContactTypes.PRIMARY_EMAIL
          ) {
            contactsNew.email.push(newContact)
          } else if (contact.typeid === userContactTypes.LOCATION) {
            contactsNew.location.push(newContact)
          } else if (contact.typeid === userContactTypes.URL) {
            contactsNew.url.push(newContact)
          }
        }
      })

      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          ...contactsNew,
        },
      })
    }
    case actionTypes.ADD_USER_CONTACT_URL: {
      let url = action.payload
      
      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          url: [...state.contactMechanisms.url, url],
        },
      })
    }
    case actionTypes.CLEAR_USER_CONTACT_LOCATION: {
      let url = action.payload
      return {
        contactMechanisms: {
          ...state.contactMechanisms,
          url: [...state.contactMechanisms.url, url],
        },
      }
    }
    case actionTypes.UPDATE_USER_CONTACT_MECHANISMS: {
      const { contactMechanisms, mechType } = action.payload
      let updatedContactMechanisms = _.assign({}, state.contactMechanisms)
      updatedContactMechanisms[mechType] = contactMechanisms
      return _.assign({}, state, {
        contactMechanisms: updatedContactMechanisms,
      })
    }

    case actionTypes.DELETE_USER_CONTACT_MECHANISM: {
      const keys = Object.keys(state.contactMechanisms)
      const filterFunc = (c) => c.id !== action.payload
      const updatedContacts = []
      keys.forEach((key) => {
        updatedContacts[key] = state.contactMechanisms[key].filter(filterFunc)
      })

      return {
        ...state,
        contactMechanisms: updatedContacts,
      }
    }
    case actionTypes.SET_SETTINGS: {
      let newSettings = { ...state.settings, ...action.payload }

      return _.assign({}, state, {
        settings: newSettings,
      })
    }
    case actionTypes.CHANGE_TIMEZONE: {
      return _.assign({}, state, {
        settings: {
          ...state.settings,
          calendarSync: {
            ...state.settings.calendarSync,
            ...action.payload,
          },
        },
      })
    }
    case actionTypes.SET_RESET_PASS_ERROR: {
      return {
        ...state,
        resetPassErrorMessage: action.payload,
      }
    }
    case actionTypes.SET_USER_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case actionTypes.GET_USER_PRIMARY_LOCATION: {
      return {
        ...state,
        primaryLocation: action.payload,
      }
    }
    default:
      return state
  }
}
