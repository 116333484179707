import React, { useMemo } from "react"

import { useTeeUpPage } from "../../TeeUpPageContext"
import GamePlanCard from "./GamePlanCard"
import { images } from "@utils/imageUtils"

import "./teeupGamePlan.scss"

const TeeupGamePlan = () => {
  const {
    teeup,
    interactionArea,
    setInteractionArea,
    closeInteractionArea,
    openSuggestWhenModal,
    openSuggestWhereModal,
  } = useTeeUpPage()

  const whereGamePlan = useMemo(() => {
    const plan = teeup?.gameplans?.find((plan) => plan.type === "where")
    const decided = plan?.suggestions?.find((suggestion) => suggestion.decided)

    return { plan, decided }
  }, [teeup])

  const whenGamePlan = useMemo(() => {
    const plan = teeup.gameplans.find((plan) => plan.type === "when")
    const decided = plan?.suggestions?.find((suggestion) => suggestion.decided)

    return { plan, decided }
  }, [teeup])

  const handleSuggestionsClick = (gamePlanType, activeArea) => {
    if (gamePlanType === activeArea) {
      closeInteractionArea()
      return
    }

    setInteractionArea(gamePlanType)
  }

  const handleSuggestWhenClick = () => {
    openSuggestWhenModal()
  }

  const handleSuggestWhereClick = () => {
    openSuggestWhereModal()
  }

  return (
    <div className="teeup-game-plan">
      <div className="teeup-game-plan__title">Game plan</div>
      {teeup?.gameplans.length > 0 && (
        <>
          <GamePlanCard
            onSuggestionsListClick={() =>
              handleSuggestionsClick("when", interactionArea)
            }
            onDecisionClick={() => console.log("onDecisionClick")}
            onSuggestClick={handleSuggestWhenClick}
            config={{ icon: images.ndClockIcon, type: "when" }}
            plan={whenGamePlan?.plan}
            decided={whenGamePlan?.decided}
          />
          <GamePlanCard
            onSuggestionsListClick={() =>
              handleSuggestionsClick("where", interactionArea)
            }
            onDecisionClick={() => console.log("onDecisionClick")}
            onSuggestClick={handleSuggestWhereClick}
            config={{ icon: images.ndLocationPinIcon, type: "where" }}
            plan={whereGamePlan?.plan || ""}
            decided={whereGamePlan?.decided || ""}
          />
        </>
      )}
    </div>
  )
}

export default TeeupGamePlan
