import React, { useState, useCallback } from "react"
import { v4 as uuidv4 } from "uuid"
import cn from "classnames"

import "./index.scss"
import { images } from "@utils/imageUtils"
import cancel from "../../assets/images/cancel.png"
import ToggleButton from "./common/ToggleButton/ToggleButton"
import PhoneInputField from "../../components/AddInvitees/AddContacts/phoneInput"
import { validateInvitation, sendBetaCode } from "@actions/loginActions"
import AlreadyRedeemed from "./alreadyRedeemed"

const typeOptions = [
  { value: "email", label: "Email" },
  { value: "phone", label: "Phone (SMS)" },
]

const FirstVerificationStep = ({ moveToNextStep }) => {
  const [type, setType] = useState("email")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState({
    countryCode: "us",
    dialCode: 1,
    name: "United States",
  })
  const [error, setError] = useState(null)
  const [redeemed, setRedeemed] = useState(false)

  const clearEmail = () => {
    setEmail("")
    setError(null)
  }

  const onChangeInputValue = (index) => (input) => {
    setPhone(input)
  }

  const onChangeInputCountry = (countryValue) => {
    setCountry(countryValue)
  }

  const handleTypeChange = useCallback((type) => {
    setError(null)
    setType(type)
    setEmail("")
    setPhone("")
  })

  const handleClickVerify = (e) => {
    e.preventDefault()
    setError("")

    let value = { phoneNumber: phone }
    if (type === "email") {
      value = { emailAddress: email }
    }

    validateInvitation(value)
      .then(() => {
        const key = type === "email" ? "email1" : "phoneNumber"
        if (type === "email") {
          value = { [key]: email }
        }
        const deviceId = uuidv4()

        sendBetaCode(value, deviceId)
          .then((response) => {
            const { status, message, result } = response.data

            if (status === 409) {
              setError(message)
            } else {
              const state = {
                id: result[key].id,
                value: email || phone,
                deviceId,
                userId: result["userId"],
                type,
              }

              moveToNextStep(state)
            }
          })
          .catch(() => {
            setRedeemed(true)
          })
      })
      .catch((error) => {
        const { message } = error.response.data
        if (message) {
          setError(message)
        } else {
          setError("Please enter a valid email address")
        }
      })
  }

  return (
    <div className="forgot__page" style={{ flexDirection: "column" }}>
      {!redeemed ? (
        <>
          <div className="forgot__content">
            <img className="forgot__icon" src={images.cooeModal} />
            <span className="forgot__title">Verify Invitation</span>
            <p className="forgot__subtitle">
              We need to verify your invitation before you can create an account
            </p>
            <p
              className="forgot__subtitle"
              style={{ fontSize: 16, color: "#454c52", marginBottom: 8 }}
            >
              How did you receive the invitation?
            </p>
          </div>

          <div style={{ width: 327, marginBottom: 20 }}>
            <ToggleButton
              options={typeOptions}
              onChange={handleTypeChange}
              value={type}
            />
            {type === "email" ? (
              <>
                <form onSubmit={(e) => handleClickVerify(e)}>
                  <label htmlFor="email" className="forgot__label">
                    Email
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      id="email"
                      value={email}
                      onChange={({ target }) => setEmail(target.value)}
                      className={cn("login__input forgot__input", {
                        "forgot__input--error": error,
                      })}
                      type="text"
                    />
                  </div>
                  {error && (
                    <span className="forgot__error">
                      Please enter a valid email address
                    </span>
                  )}
                </form>
              </>
            ) : (
              <>
                {/* <label className="forgot__label">Phone Number</label> */}
                <PhoneInputField
                  value={phone}
                  handleTextChange={onChangeInputValue}
                  inputStyle={{ width: "327px" }}
                  fromInvitation={true}
                  onChangeInputCountry={onChangeInputCountry}
                  countryCode={country}
                  label={"Country"}
                />
                <PhoneInputField
                  value={phone}
                  handleTextChange={onChangeInputValue}
                  inputStyle={{ width: "327px" }}
                  fromInvitation={true}
                  countryCode={country}
                  label={"Phone Number"}
                  onlyNumber={true}
                />
              </>
            )}
          </div>
          <button
            type="submit"
            disabled={
              (type === "email" && !(email.trim().length > 0)) ||
              (type === "phone" && !(phone.trim().length > 0))
            }
            className={cn("forgot__button", {
              "forgot__button--disabled":
                (type === "email" && !(email.trim().length > 0)) ||
                (type === "phone" && !(phone.trim().length > 0)),
            })}
            style={{ width: 327 }}
            onClick={(e) => handleClickVerify(e)}
          >
            Continue
          </button>
        </>
      ) : (
        <AlreadyRedeemed setRedeemed={setRedeemed} />
      )}
    </div>
  )
}

export default FirstVerificationStep
