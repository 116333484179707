import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    overflow: "hidden",
    borderRadius: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.white,
    paddingHorizontal: Units.responsiveValue(12),
    paddingVertical: Units.responsiveValue(11),
    marginBottom: Units.responsiveValue(7),
  },
  bar: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    width: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.pink,
  },
  icon: {
    marginTop: Units.responsiveValue(3),
    marginRight: Units.responsiveValue(3),
    color: AppColors.brand.darkGrey2,
  },
  line: {
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.darkGrey2,
  },
})
