import React, { useState } from "react"
import { View, Text, TouchableOpacity, Image } from "react-native"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import TeeupStatus from "./TeeupStatus"
import MyStatus from "./MyStatus"
import ActionButtonsBar from "./ActionButtonsBar"
import TeeupItemHeaderTitle from "./Header"
import { renderFirstAvatars, TimeFromNow } from "./teeupItemComponents"

import moment from "moment"
import propsMapper from "./propsMapper"
import { styles, container } from "./smallItemStyle"
import { images } from "@utils/imageUtils"

export const TeeupItem = ({
  ownerId,
  isOrganizer = false,
  ownerAvatar,
  ownerName,
  name,
  status,
  teeupStatusStyling,
  isGreyOverlay,
  userStatus,
  isArchivePromptShow,
  isInitiallyInvited,
  isDroppedOut,
  isSkippedDeclined,
  onArchivePressed,
  handleSuggestionOrStatusNudgePressed,
  when,
  where,
  goingCount,
  isNewTitle,
  isNewWhen,
  isNewWhere,
  isNewNudge,
  newMessages,
  onPress,
  photo = "",
  ignoreHorizontalMargin = false,
  fromTeeupsSection = false,
  testID,
  style,
  contentStyle,
  fromNowString,
  invitation,
  onDeclineInvitation,
  onAcceptInvitation,
  onKeepInTheListPress,
  people = {},
  showTimestampInHeader,
  isUndecidedWhenInGameplan,
  nudgeInfo,
  teeup,
  readTeeupState,
  teeupId,
  disablePrompts,
}) => {
  const isNew = isNewTitle || isNewWhen || isNewWhere || newMessages > 0
  const ImageWrapper = View

  const [isUndecidedPromptShow, setUndecidedPrompt] = useState(
    isUndecidedWhenInGameplan
  )

  const [isButtonsShown, handleIgnorePress] = useState(true)

  let when1 = ""
  const selectedWhen = teeup.gameplans[0].suggestions?.map(
    (suggestion, index) => {
      if (suggestion.when && suggestion.isSelected) {
        when1 = suggestion.when.value
      }
    }
  )

  const whenFormatted = moment(when1).format("llll")

  let where1 = ""
  const selectedWhere = teeup.gameplans[1].suggestions?.map(
    (suggestion, index) => {
      if (suggestion.where && suggestion.isSelected) {
        where1 = suggestion.where.details
      }
    }
  )

  const onIgnoreNudges = (hide) => {
    handleIgnorePress(hide)
    readTeeupState(teeupId)
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[container(isGreyOverlay), style]}
      activeOpacity={1}
      testID={testID}
    >
      {isNew && <View style={styles.newSideBar} />}

      {/* <TeeupItemHeaderTitle
                showAvatar={isInitiallyInvited || isOrganizer}
                isOrganizer={isOrganizer}
                ownerAvatar={ownerAvatar}
                ownerName={ownerName}
                isNew={isNew}
                fromNowString={fromNowString}
                isGreyOverlay={isGreyOverlay}
            /> */}
      <View
        style={[
          styles.contentContainer,
          ignoreHorizontalMargin ? styles.noMargin : null,
          fromTeeupsSection ? styles.withBorders : null,
          contentStyle,
        ]}
      >
        <View style={styles.leftPartContainer}>
          <ImageWrapper style={styles.thumbnailImage}>
            <Image style={styles.flex} source={{ uri: photo }} />
          </ImageWrapper>
          {isOrganizer && (
            <View style={styles.crownContainer}>
              <Image style={styles.crownImage} source={images.crown} />
            </View>
          )}
        </View>
        <View style={styles.content}>
          <View style={styles.header}>
            <View style={styles.row}>
              <TeeupStatus
                status={status}
                testID={`${testID}-${status}`}
                teeupStatusStyling={teeupStatusStyling}
              />
              {!!userStatus && <MyStatus smallItem userStatus={userStatus} />}
            </View>
            {!showTimestampInHeader && (
              <TimeFromNow isNew={isNew} fromNowString={fromNowString} />
            )}
            {showTimestampInHeader && (
              <TimeFromNow isNew={isNew} fromNowString={fromNowString} />
            )}
          </View>
          <View style={[styles.row, styles.nameMargin]}>
            <Text
              style={[
                styles.nameText,
                isNewTitle ? styles.hasUpdateText : {},
                (teeupStatusStyling === "droppedout" ||
                  teeupStatusStyling === "ended") &&
                  styles.nameInactiveText,
              ]}
            >
              {name.substring(0, 64)}
            </Text>
          </View>
          <View style={[styles.row, { alignItems: "end" }]}>
            <View style={styles.divider}>
              {!!whenFormatted && (
                <View style={styles.row}>
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={[
                      styles.mediumText,
                      isNewWhen ? styles.hasUpdateText : {},
                    ]}
                  >
                    {whenFormatted === "Invalid date" || !whenFormatted
                      ? ""
                      : whenFormatted}
                  </Text>
                </View>
              )}
              {!!where1 && (
                <View style={styles.row}>
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={[
                      styles.mediumText,
                      isNewWhere ? styles.hasUpdateText : {},
                    ]}
                  >
                    {where1 && where1}
                  </Text>
                </View>
              )}
            </View>
            <View style={[styles.divider, { justifyContent: "flex-end" }]}>
              {!disablePrompts && (
                <>
                  <TeeupItemHeaderTitle
                    showAvatar={
                      !(
                        isDroppedOut ||
                        isSkippedDeclined ||
                        isArchivePromptShow ||
                        (isOrganizer && isUndecidedWhenInGameplan)
                      )
                    }
                    invitation={invitation}
                    isInvited={isInitiallyInvited}
                    ownerAvatar={ownerAvatar}
                    ownerName={ownerName}
                    smallItem
                    isNew={isNew}
                    isDroppedOut={isDroppedOut}
                    isDeclined={isSkippedDeclined}
                    isArchivePromptShow={isArchivePromptShow}
                    isGreyOverlay={isGreyOverlay}
                    nudgeInfo={isButtonsShown && isNewNudge && nudgeInfo}
                    isUndecidedWhenInGameplan={
                      isUndecidedPromptShow && isUndecidedWhenInGameplan
                    }
                  />
                  <ActionButtonsBar
                    isInvited={isInitiallyInvited}
                    isDroppedOut={isDroppedOut}
                    isSkippedDeclined={isSkippedDeclined}
                    isArchivePromptShow={isArchivePromptShow}
                    isUndecidedWhenInGameplan={
                      isUndecidedPromptShow && isUndecidedWhenInGameplan
                    }
                    onIgnoreUndecidedInGameplan={setUndecidedPrompt}
                    onArchivePressed={onArchivePressed}
                    handleSuggestionOrStatusNudgePressed={
                      handleSuggestionOrStatusNudgePressed
                    }
                    onDeclineInvitation={onDeclineInvitation}
                    onKeepInTheListPress={onKeepInTheListPress}
                    onAcceptInvitation={onAcceptInvitation}
                    isGreyOverlay={isGreyOverlay}
                    onViewTeeup={onPress}
                    nudgeInfo={isButtonsShown && isNewNudge && nudgeInfo}
                    onIgnore={onIgnoreNudges}
                  />
                </>
              )}
            </View>
          </View>
          <View style={styles.usersLine}>
            {renderFirstAvatars(people, ownerId, ImageWrapper)}
            <Image source={images.arrow} style={styles.arrowIcon} />
            <Text style={styles.countText}>{goingCount}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}
TeeupItem.propTypes = {
  // In teeup object
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  teeupStatusStyling: PropTypes.string.isRequired,
  isGreyOverlay: PropTypes.bool,
  userStatus: PropTypes.string,
  isOrganizer: PropTypes.bool.isRequired,
  // Calculated from teeupGameplans
  when: PropTypes.string,
  where: PropTypes.string,
  // Calculated from teeupPeople
  goingCount: PropTypes.number.isRequired,
  fromNowString: PropTypes.string.isRequired,
  isArchivePromptShow: PropTypes.bool.isRequired,
  onArchivePressed: PropTypes.func,
  invitation: PropTypes.object,
  ownerId: PropTypes.number,
  ownerAvatar: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string.isRequired,
  ]),
  ownerName: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string.isRequired,
  ]),
  people: PropTypes.shape({}).isRequired,
  isNewTitle: PropTypes.bool,
  isNewWhen: PropTypes.bool,
  isNewWhere: PropTypes.bool,
  newMessages: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  nudgeInfo: PropTypes.object,

  onPress: PropTypes.func.isRequired,
  photo: PropTypes.string,

  ignoreHorizontalMargin: PropTypes.bool,
  fromTeeupsSection: PropTypes.bool,
  testID: PropTypes.string,

  style: PropTypes.object,
  contentStyle: PropTypes.object,
  onDeclineInvitation: PropTypes.func,
  onAcceptInvitation: PropTypes.func,
  onKeepInTheListPress: PropTypes.func,
  isInitiallyInvited: PropTypes.bool,
  isDroppedOut: PropTypes.bool,
  isSkippedDeclined: PropTypes.bool,
  showTimestampInHeader: PropTypes.bool,
  isUndecidedWhenInGameplan: PropTypes.bool,
  readTeeupState: PropTypes.func,
  teeupId: PropTypes.number,
  isNewNudge: PropTypes.bool,
  handleSuggestionOrStatusNudgePressed: PropTypes.func,
  disablePrompts: PropTypes.bool,
}

export default connect(propsMapper)(TeeupItem)
