import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import I18n from "@i18n"
import { selectUserInfo } from "@selectors/user"
import { selectActiveTeeupId, selectActiveTeeup } from "@selectors/activeTeeup"
// import backend from '@apis/backend'
import {
  selectTeeupsSuggestions,
  selectTeeupsPeople,
  selectTeeupReactionsBySuggestionId,
  selectTeeups,
} from "@selectors/teeups"
// import { showHighlightsScreen, routeTeeup } from '@nav'
// import teeupActions from '@actions/teeupActions'
import {
  highlightTypes,
  teeupListNudgeTypes,
  teeupUserStatus,
} from "@configs/enums"
import { reactionTypes, userStatusTypes } from "@configs/mappings"
import { replyToNudge } from "../../../middlewares/backendMiddleware"
import { getButtonsForResponse } from "./suggestionOrStatusNudgeResponseButtonProps"
import SuggestionOrStatusNudgeResponseView from "./suggestionOrStatusNudgeResponseView"

class SuggestionOrStatusNudgeResponseContainer extends React.Component {
  constructor(props) {
    super(props)
    this._suggestionResponse = getButtonsForResponse(props.subTypeId)
    this.state = {
      suggestion: !props.fromTeeupList ? props.nudgedSuggestion : {},
      reactions: !props.fromTeeupList ? props.nudgedSuggestionReactions : [],
    }
  }

  // async componentDidMount() {
  //     const {
  //         fromTeeupList,
  //         teeupId,
  //         nudgeInfo,
  //         subTypeId,
  //         teeupConnectToSocket,
  //     } = this.props

  //     if (fromTeeupList) {
  //         try {
  //             // await teeupConnectToSocket(teeupId)
  //             setTimeout(
  //                 () => this.setState({ isTeeupSocketConnected: true }),
  //                 1000
  //             )
  //             if (
  //                 subTypeId === teeupListNudgeTypes.suggestion &&
  //                 nudgeInfo?.targetId
  //             ) {
  //                 // const result = await backend.getTeeupSuggestion(
  //                 //     teeupId,
  //                 //     Number(nudgeInfo.targetId)
  //                 // )
  //                 // this.setState({ suggestion: result.suggestion })
  //             }
  //         } catch (e) {
  //             console.log(
  //                 'SuggestionOrStatusNudgeResponseContainer didmount error: ',
  //                 { e }
  //             )
  //         }
  //     }
  // }

  handleSave = (nudgeAnswerId) => async () => {
    if (nudgeAnswerId === -1) {
      // already responded
      this.props.close()
      return
    }

    const {
      teeupId,
      userInfo,
      addReaction,
      // replyToNudge,
      subTypeId,
      nudgeInfo,
      fromTeeupList,
      updateTeeupsState,
      readTeeupState,
      callback,
      updateUserStatusForTeeup,
    } = this.props
    const { suggestion } = this.state

    const answerId = Number(nudgeAnswerId)

    let replyObject = {
      messageId: nudgeInfo.id,
      typeId: answerId,
    }
    const stillWorks = this.isStillWorks()
    const stillDoesntWork = this.isStillDoenstWork()
    const isSuggestionAnswer =
      !(answerId === reactionTypes.upvote && stillWorks) &&
      !(answerId === reactionTypes.downvote && stillDoesntWork)
    try {
      if (
        subTypeId === teeupListNudgeTypes.suggestion &&
        nudgeInfo.targetId &&
        suggestion.id
      ) {
        const text = this.getResponseText(answerId, stillWorks)

        // if (isSuggestionAnswer) {
        //     await addReaction({
        //         reactionId: answerId,
        //         suggestionId: suggestion.id,
        //         teeupId,
        //         userId: userInfo.id,
        //     })
        // }
        replyObject.message = text
      } else {
        // await this.props.changeUserTeeupStatus({
        //     teeupId,
        //     statusId: answerId,
        // })
        const userStatus = userStatusTypes[answerId]
        // updateUserStatusForTeeup({
        //     userStatus,
        //     userId: userInfo.id,
        //     teeupId,
        // })
        replyObject.message = teeupUserStatus[userStatus]
      }
      await replyToNudge(replyObject)
    } catch (error) {
      if (!isSuggestionAnswer) {
        // await backend.replyToMessage(teeupId, nudgeInfo.id, {
        //     text: replyObject.message,
        //     typeId: replyObject.typeId,
        // })
      }
      console.log("reply to nudge error", error)
    }
    // readTeeupState(teeupId)
    this.props.close()
    // if (callback) {
    //     callback()
    // }
    // if (fromTeeupList) {
    //     updateTeeupsState()
    // }
  }

  isStillWorks = () => {
    const { userInfo } = this.props
    const {
      suggestion: { reactions = [] },
    } = this.state
    // const positiveReactions = reactions.find(
    //     ({ reactionId }) => reactionId === reactionTypes.upvote
    // )
    // if (!positiveReactions) return false
    // const stillWorks = positiveReactions.reactedBy.includes(userInfo.id)
    // return stillWorks
  }

  isStillDoenstWork = () => {
    const { userInfo } = this.props
    const {
      suggestion: { reactions = [] },
    } = this.state

    const negativeReactions = reactions.find(
      ({ reactionId }) => reactionId === reactionTypes.downvote
    )
    if (!negativeReactions) return false
    const stillDoesntWork = negativeReactions.reactedBy.includes(userInfo.id)
    return stillDoesntWork
  }

  getResponseText = (reactionId, stillWorks) => {
    if (reactionId === reactionTypes.downvote) {
      return I18n.nudgeResponded.negativeResponse
    }

    return `This ${stillWorks ? "still " : ""} works for me`
  }

  render() {
    const {
      nudgeIssuer,
      subTypeId,
      fromTeeupList,
      showViewInHighlightsButton = true,
      close,
    } = this.props
    const { suggestion, isTeeupSocketConnected } = this.state

    return (
      <SuggestionOrStatusNudgeResponseView
        fromTeeupList={fromTeeupList}
        subTypeId={subTypeId}
        suggestion={suggestion}
        suggestionResponse={this._suggestionResponse}
        nudgeIssuer={nudgeIssuer}
        onSave={this.handleSave}
        // linkToHighlights={this.linkToHighlights}
        handleClose={close}
        showViewInHighlightsButton={showViewInHighlightsButton}
        isTeeupSocketConnected={isTeeupSocketConnected}
      />
    )
  }
}

SuggestionOrStatusNudgeResponseContainer.propTypes = {
  componentId: PropTypes.string,
  onClose: PropTypes.func,
  updateTeeupsState: PropTypes.func,
  updateUserStatusForTeeup: PropTypes.func,
  readTeeupState: PropTypes.func,
  teeupConnectToSocket: PropTypes.func,
  teeupRemoveListener: PropTypes.func,

  subTypeId: PropTypes.number,
  activeTeeupId: PropTypes.number,
  teeupId: PropTypes.number,
  teeupInfo: PropTypes.object,
  callback: PropTypes.func,
  addReaction: PropTypes.func,
  replyToNudge: PropTypes.func,
  setTeeupActive: PropTypes.func,
  userInfo: PropTypes.object,
  nudgeIssuer: PropTypes.object,
  teeup: PropTypes.object,
  nudgeInfo: PropTypes.object,
  nudgedSuggestion: PropTypes.object,
  nudgedSuggestionReactions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  fromTeeupList: PropTypes.bool,
  showViewInHighlightsButton: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
  const activeTeeupId = selectActiveTeeupId(state)
  // const teeups = selectTeeups(state)
  const { teeup, nudgeInfo = {} } = ownProps
  const teeupId = activeTeeupId || teeup?.id || teeup?.teeupId
  const userInfo = selectUserInfo(state)
  const suggestions = selectTeeupsSuggestions(state)[teeupId]
  const nudgeCreatorId = Number(nudgeInfo.userId || nudgeInfo.senderId)
  const people = selectTeeupsPeople(state)[teeupId]
  const nudgedSuggestion = nudgeInfo.targetId
    ? suggestions[Number(nudgeInfo.targetId)]
    : {}
  const nudgedSuggestionReactions = nudgeInfo.targetId
    ? selectTeeupReactionsBySuggestionId(Number(nudgeInfo.targetId))(state)
    : {}
  const nudgeIssuer = people[nudgeCreatorId] || {}

  // const teeupInfo = selectActiveTeeup(state)

  return {
    userInfo,
    teeupId,
    activeTeeupId,
    nudgedSuggestion,
    nudgedSuggestionReactions,
    nudgeIssuer,
    // teeupInfo,
    fromTeeupList: !!teeup,
  }
}

export default connect(mapStateToProps)(
  SuggestionOrStatusNudgeResponseContainer
)
