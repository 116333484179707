import React, { memo } from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"
import styles from "./messageSenderStyles"

const MessageSender = ({ name, isAttachment }) => (
  <View style={isAttachment && styles.attachmentSender}>
    <Text style={styles.messageSenderText}>{name}</Text>
  </View>
)

MessageSender.propTypes = {
  name: PropTypes.string,
  isAttachment: PropTypes.bool.isRequired,
}

export default memo(MessageSender)
