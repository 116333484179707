export const getFileSizeFromBytes = (
  fileSizeInBytes,
  calculateKbOnly = true,
  capitalize = true
) => {
  if (!fileSizeInBytes) {
    return {
      size: 0,
      sizeType: capitalize ? "KB" : "Kb",
    }
  }
  let size = Math.ceil(fileSizeInBytes / 1024)
  let sizeType = "Kb"
  if (!calculateKbOnly) {
    if (size > 1024) {
      size = Math.ceil((size * 10) / 1024) / 10
      sizeType = "Mb"
    }
    if (size > 1024) {
      size = Math.ceil((size * 10) / 1024) / 10
      sizeType = "Gb"
    }
  }
  return {
    size,
    sizeType: capitalize ? sizeType.toUpperCase() : sizeType,
  }
}

export const getFileExtension = (name) => {
  const extension = name ? name.split(".").pop() : ""

  return `.${extension}`
}

export const isImageFile = (type) => type.startsWith("image/")
