import React, { useState } from "react"
import CheckCurrentPassword from "./CheckCurrentPassword"
import ChangeTempPassword from "../ChangeTempPassword"
import { setupTeeupListSocket } from "../../middlewares/backendMiddleware"
import { selectUserId } from "@selectors/user"
import { store } from "../../index"

import "./index.scss"
import ChangePasswordSuccess from "./ChangePasswordSuccess"

const ChangePassword = () => {
  const [accessToken, setAccessToken] = useState()
  const [step, setStep] = useState(1)

  const state = store.getState()
  const userId = selectUserId(state)

  return (
    <div className="change__password">
      <div className="change__password--container">
        {step === 1 && (
          <CheckCurrentPassword
            setStep={setStep}
            setAccessToken={setAccessToken}
          />
        )}
        {step === 2 && (
          <ChangeTempPassword
            loggedChangePassword
            accessToken={accessToken}
            setupTeeupListSocket={setupTeeupListSocket}
            userId={userId}
            setStep={setStep}
          />
        )}
        {step === 3 && <ChangePasswordSuccess />}
      </div>
    </div>
  )
}
export default ChangePassword
