/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  View,
  Text,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native"
import ClickableRow from "@ui/settingsClickableRow"
import {
  ToggleButton,
  ArrowButton,
} from "@ui/settingsClickableRow/actionButtons"
import { images } from "@utils/imageUtils"
import { setHelpPopup } from "@actions/commonActions"
import strings from "@i18n"
import UserProfileContainer from "../profile/UserProfile/index"
import LoginDetailsContainer from "./LoginDetails"
import AccountDetailsContainer from "./AccountDetails"
import CalendarSettingsContainer from "./calendarSettings"
import NotificationSettingsContainer from "./NotificationSettings"
import PoliciesAndServicesContainer from "./PoliciesAndServices"
import TimeZoneSettingsContainer from "./timeZones"
import PrivacyPolicyScreen from "./PrivacyPolicyScreen"
import TermsOfServiceScreen from "./TermsOfServiceScreen"
import NotImplementedYet from "./timeZones/NotImplementedYet"
import ReactDOM from "react-dom"

import { ViewStyles as styles } from "./moreStyles"

const isIOS = Platform.OS === "ios"

const MoreView = ({
  linkToScreen,
  onToggleAllowSynchronization,
  versionNumber,
  updateAvailable,
  buildNumber,
  lastSynced,
  contactMechanisms,
  allowSynchronization,
  contactSyncAnimationStyles,
  handleCloseScreen,
  close,
  userId,
  version,
  setPopup,
  activeScreen,
}) => {
  const [isOptionOpened, setIsOptionOpened] = useState(false)
  const [content, setContent] = useState(null)
  const [title, setTitle] = useState("")
  const [active, setActive] = useState(0)
  const optionRef = useRef()
  const contentRef = useRef()

  const [isNotImplemented, setIsNotImplemented] = useState(false)

  const openOption = () => setIsOptionOpened(true)
  const closeOption = () => setIsOptionOpened(false)

  const screens = {
    0: "profile",
    1: "connectionDetails",
    2: "accountManagement",
    3: "calendars",
    4: "timezones",
    5: "notificationSettings",
    6: "policiesInformation",
    7: "privacy",
    8: "terms",
    9: "licenses",
  }

  const activeStyles = {
    backgroundColor: "#f0f2f5",
    borderRadius: "14px 0 0 14px",
  }

  useEffect(() => {
    if (activeScreen || activeScreen === 0) {
      handleMenuActions(screens[activeScreen || 0])
    }
  }, [activeScreen])

  useEffect(() => {
    handleMenuActions(screens[0])
  }, [])

  const informationTabs = [6, 7, 8, 9]

  const handleMenuActions = (menu) => {
    switch (menu) {
      case "profile":
        openOption()
        setContent(
          <UserProfileContainer userId={userId} closeOption={closeOption} />
        )
        setTitle(strings.profile)
        setActive(0)
        break
      case "connectionDetails":
        openOption()
        setContent(<AccountDetailsContainer closeOption={closeOption} />)
        setTitle("Connection Details")
        setActive(1)
        break
      case "accountManagement":
        openOption()
        setContent(<LoginDetailsContainer closeOption={closeOption} />)
        setTitle("Account Management")
        setActive(2)
        break
      case "calendars":
        openOption()
        setContent(<CalendarSettingsContainer closeOption={closeOption} />)
        setTitle("Calendars")
        setActive(3)
        break
      case "timezones":
        openOption()
        setContent(<TimeZoneSettingsContainer closeOption={closeOption} />)
        setTitle("Time-zones")
        setActive(4)
        break
      case "notificationSettings":
        openOption()
        setContent(<NotificationSettingsContainer closeOption={closeOption} />)
        setTitle("Notification Settings")
        setActive(5)
        break
      case "policiesInformation":
        openOption()
        setContent(
          <PrivacyPolicyScreen />,
          strings.policiesAndServices.privacyPolicy.title
        )
        setTitle("Policies & Information • Privacy Policy")
        setContent(
          <PoliciesAndServicesContainer
            closeOption={closeOption}
            handleAction={handleMenuActions}
          />
        )
        setTitle("Policies & Information")
        setActive(7)
        break
      case "privacy":
        openOption()
        setContent(
          <PrivacyPolicyScreen contentRef={contentRef} />,
          strings.policiesAndServices.privacyPolicy.title
        )
        setTitle("Policies & Information • Privacy Policy")
        setActive(7)
        break
      case "terms":
        openOption()
        setContent(
          <TermsOfServiceScreen contentRef={contentRef} />,
          strings.policiesAndServices.termsOfService.title
        )
        setTitle("Policies & Information • Terms of Service")
        setActive(8)
        break
      case "licenses":
        openOption()
        setContent(<PoliciesAndServicesContainer closeOption={closeOption} />)
        setTitle("Policies & Information • Licenses")
        setActive(9)
        break
      case "bugReporting":
        setIsNotImplemented(true)
        break
      default:
        break
    }
  }

  return (
    <>
      <View style={styles.settingsSidebar}>
        <h2 className="settings-title">Settings</h2>
        <ScrollView style={styles.background}>
          <View style={styles.buttonClusters}>
            <ClickableRow
              iconImage={images.profileSets}
              primaryText={[strings.profile]}
              stylesTab={active === 0 ? activeStyles : {}}
              fromProfile={true}
              onPress={() => handleMenuActions("profile")}
            />
            <ClickableRow
              iconImage={images.linkedContactMethod}
              primaryText={["Connection Details"]}
              stylesTab={active === 1 ? activeStyles : {}}
              fromProfile={true}
              onPress={() => handleMenuActions("connectionDetails")}
            />
            <ClickableRow
              iconImage={images.accountManagement}
              primaryText={["Account Management"]}
              secondaryText={contactMechanisms}
              stylesTab={active === 2 ? activeStyles : {}}
              fromProfile={true}
              onPress={() => handleMenuActions("accountManagement")}
            />
            <ClickableRow
              iconImage={images.calendarSVG}
              primaryText={["Calendars"]}
              stylesTab={active === 3 ? activeStyles : {}}
              fromProfile={true}
              onPress={() => handleMenuActions("calendars")}
            />
            <ClickableRow
              iconImage={images.timeZonesSettings}
              primaryText={["Time-zones"]}
              stylesTab={active === 4 ? activeStyles : {}}
              fromProfile={true}
              onPress={() => handleMenuActions("timezones")}
            />
            <ClickableRow
              iconImage={images.notificationsSVG}
              primaryText={["Notification Settings"]}
              fromProfile={true}
              stylesTab={active === 5 ? activeStyles : {}}
              onPress={() => handleMenuActions("notificationSettings")}
            />
          </View>

          <View style={styles.buttonClusters}>
            <ClickableRow
              iconImage={images.policiesAndServicesSVG}
              primaryText={["Policies & Information"]}
              fromProfile={true}
              stylesTab={active === 6 ? activeStyles : {}}
              onPress={() => handleMenuActions("policiesInformation")}
            />
            {informationTabs.includes(active) && (
              <ClickableRow
                iconImage={images.policiesAndServicesSVG}
                primaryText={["Privacy Policy"]}
                fromProfile={true}
                stylesTab={active === 7 ? activeStyles : {}}
                onPress={() => handleMenuActions("privacy")}
                subTab={true}
              />
            )}
            {informationTabs.includes(active) && (
              <ClickableRow
                iconImage={images.policiesAndServicesSVG}
                primaryText={["Terms of Service"]}
                fromProfile={true}
                stylesTab={active === 8 ? activeStyles : {}}
                onPress={() => handleMenuActions("terms")}
                subTab={true}
              />
            )}
            {informationTabs.includes(active) && (
              <ClickableRow
                iconImage={images.policiesAndServicesSVG}
                primaryText={["Licenses"]}
                fromProfile={true}
                stylesTab={active === 9 ? activeStyles : {}}
                onPress={() => handleMenuActions("licenses")}
                subTab={true}
              />
            )}
          </View>
          <View style={styles.buttonClusters}>
            <ClickableRow
              iconImage={images.bugReporting}
              primaryText={[strings.bugReportingAndFeedback.settingsTitle]}
              fromProfile
              onPress={() => handleMenuActions("bugReporting")}
              stylesTab={{ color: "inherit" }}
              // onPress={linkToScreen(
              //     screenNames.bugReportingAndFeedback,
              //     I18n.t('bugReportingAndFeedback.settingsTitle')
              // )}
            />
          </View>
        </ScrollView>
      </View>
      {isOptionOpened && (
        <div
          ref={optionRef}
          className="settings__modal settings__modal-content"
          style={{
            animationName: "none",
            margin: "0 20px",
            maxWidth: "640px",
            width: "100%",
            overflowY: "hidden",
            maxHeight: "100vh",
            paddingTop: "16px",
            // paddingBottom: "16px",
          }}
          onClick={(event) => event.stopPropagation()}
        >
          {title && (
            <h1
              className="settings-title"
              style={{ marginBottom: 20 }}
              ref={contentRef}
            >
              {title}
            </h1>
          )}
          {(title === "Connection Details" || title === "Calendars") && (
            <img
              className="settings_help_icon"
              src={images.questionIcon}
              onClick={() => setPopup(true)}
              alt="settings icon"
            />
          )}
          {content}
        </div>
      )}
      {isNotImplemented &&
        ReactDOM.createPortal(
          <NotImplementedYet closeSelect={() => setIsNotImplemented(false)} />,
          document.getElementById("modal-root")
        )}
    </>
  )
}

MoreView.propTypes = {
  onToggleAllowSynchronization: PropTypes.func.isRequired,
  linkToScreen: PropTypes.func.isRequired,
  lastSynced: PropTypes.string,
  contactMechanisms: PropTypes.arrayOf(PropTypes.string),
  allowSynchronization: PropTypes.bool,
  contactSyncAnimationStyles: PropTypes.object,
  handleCloseScreen: PropTypes.func,
}

MoreView.defaultProps = {}

const mapDispatchToProps = (dispatch) => ({
  setPopup: bindActionCreators(setHelpPopup, dispatch),
})

export default connect(null, mapDispatchToProps)(MoreView)
