/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"
import PropTypes from "prop-types"
import { Text, View } from "react-native"
import { ParticipantsStyles } from "./participantsStyles"

import { teeupUserStatusKeys } from "../../../../config/enums"

// components
import { TouchableOpacity } from "react-native"
import Avatar from "../../../ui/avatar"
import UserStatusAccesory from "../../../ui/avatar/userStatusAccesory"
import PollingAccesory from "../../../ui/avatar/pollingAccesory"
import { getTimeDifferenceStrForActivity } from "@utils/dateUtils"

const ParticipantRow = ({
  avatar,
  status,
  isPolling,
  reaction,
  isOwner,
  isOrganiser,
  userId,
  linkParticipantDetail,
  expanded,
  toggleExpansion,
  disabled,
  additionalCount,
  name,
  avatarSize,
  statusIconSize,
  hiddenCount,
  onPress,
  isLastItem,
  isActivityStreamGroupExpanded,
  numberOfLinesForName,
  fullName,
  lastTeeupActivity,
  lastTeeupActivityCreatedAt,
}) => {
  const wrapperTogglingProperties = !expanded
    ? {
        onPress: toggleExpansion,
        activeOpacity: 1,
      }
    : { onPress: () => linkParticipantDetail(userId) }

  return (
    <TouchableOpacity
      style={[
        ParticipantsStyles.rowContainer,
        expanded ? {} : { height: "100%" },
        expanded
          ? ParticipantsStyles.expandedRowContainerAdditionalPadding
          : ParticipantsStyles.rowContainerAdditionalPadding,
        expanded && isLastItem && ParticipantsStyles.lastAvatarItemInRow,
        isActivityStreamGroupExpanded &&
          expanded &&
          ParticipantsStyles.activityStreamRowContainer,
      ]}
      {...wrapperTogglingProperties}
      onPress={!hiddenCount && onPress}
    >
      <Avatar
        imageUrl={avatar}
        size={avatarSize}
        style={[
          status === teeupUserStatusKeys.invited
            ? ParticipantsStyles.invitedParticipantOpacity
            : null,
          expanded ? ParticipantsStyles.expandedAvatarContainer : null,
        ]}
        disabled={disabled}
        isOwner={isOwner}
        isOrganiser={isOrganiser}
        accesory={
          isPolling ? (
            <PollingAccesory
              pollingReaction={reaction}
              avatarSize={avatarSize}
              accesoryHeight={statusIconSize}
              disabled={disabled}
              accesoryStyle={ParticipantsStyles.pollingAvatarStatus(expanded)}
            />
          ) : (
            <UserStatusAccesory
              additionalCount={additionalCount}
              userStatus={status}
              disabled={disabled}
              accesoryStyle={{ top: avatarSize - statusIconSize }}
            />
          )
        }
        hiddenCount={hiddenCount}
      />
      {name && expanded && !isActivityStreamGroupExpanded && (
        <View style={{ width: avatarSize }}>
          <Text
            style={ParticipantsStyles.avatarName}
            numberOfLines={numberOfLinesForName}
          >
            {name}
          </Text>
        </View>
      )}
      {expanded && isActivityStreamGroupExpanded && (
        <View style={ParticipantsStyles.activityStreamDescriptionWrapper}>
          <Text style={ParticipantsStyles.activityStreamDescriptionFullName}>
            {fullName}
          </Text>
          <Text style={ParticipantsStyles.activityStreamDescriptionName}>
            @{name}
          </Text>
          <Text
            style={ParticipantsStyles.activityStreamDescriptionLastActivity}
          >
            {lastTeeupActivity}
            {lastTeeupActivityCreatedAt
              ? getTimeDifferenceStrForActivity(lastTeeupActivityCreatedAt)
              : ""}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  )
}

ParticipantRow.propTypes = {
  avatar: PropTypes.string,
  status: PropTypes.string,
  isOwner: PropTypes.bool,
  isOrganiser: PropTypes.bool,
  userId: PropTypes.number,
  linkParticipantDetail: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  // toggleExpansion: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  avatarSize: PropTypes.number,
  statusIconSize: PropTypes.number,
  isPolling: PropTypes.bool,
  reaction: PropTypes.string,
  additionalCount: PropTypes.number,
  hiddenCount: PropTypes.number,
}

export default ParticipantRow
