import React from "react"
import PropTypes from "prop-types"
import { View, ScrollView } from "react-native"
import ClickableRow from "@ui/settingsClickableRow"
import { SelectedButton } from "@ui/settingsClickableRow/actionButtons"
import { notificationLevels } from "@configs/enums"

import strings from "@i18n"

import { AppColors } from "@theme/"
import { styles } from "./notificationLevelStyles"

const NotificationLevelView = ({ notificationLevel, onSelect }) => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.splitter} />
      <ClickableRow
        primaryText={[strings.notificationsSettings.muteEverything.title]}
        detailsText={[strings.notificationsSettings.muteEverything.details]}
        actionButton={
          notificationLevel === notificationLevels.muteEverything ? (
            <SelectedButton color={AppColors.brand.pink} />
          ) : null
        }
        onPress={onSelect(notificationLevels.muteEverything)}
      />
      <ClickableRow
        primaryText={[strings.notificationsSettings.importantOnly.title]}
        detailsText={[
          strings.notificationsSettings.importantOnly.details.listTitle,
          strings.notificationsSettings.importantOnly.details.directedMsg,
          strings.notificationsSettings.importantOnly.details.GPandStageChanges,
          strings.notificationsSettings.importantOnly.details.teeupInvites,
        ]}
        actionButton={
          notificationLevel === notificationLevels.importantOnly ? (
            <SelectedButton color={AppColors.brand.pink} />
          ) : null
        }
        onPress={onSelect(notificationLevels.importantOnly)}
      />
      <ClickableRow
        primaryText={[strings.notificationsSettings.conversation.title]}
        detailsText={[
          strings.notificationsSettings.conversation.details.listTitle,
          strings.notificationsSettings.conversation.details.chatMsg,
          strings.notificationsSettings.conversation.details.suggestions,
        ]}
        actionButton={
          notificationLevel === notificationLevels.conversation ? (
            <SelectedButton color={AppColors.brand.pink} />
          ) : null
        }
        onPress={onSelect(notificationLevels.conversation)}
      />
      <ClickableRow
        primaryText={[strings.notificationsSettings.everything.title]}
        detailsText={[
          strings.notificationsSettings.everything.details.listTitle,
          strings.notificationsSettings.everything.details.reactions,
          strings.notificationsSettings.everything.details.userStatusChanges,
          strings.notificationsSettings.everything.details.pinned,
          strings.notificationsSettings.everything.details.withdrawnSuggestion,
        ]}
        actionButton={
          notificationLevel === notificationLevels.everything ? (
            <SelectedButton color={AppColors.brand.pink} />
          ) : null
        }
        onPress={onSelect(notificationLevels.everything)}
      />
    </ScrollView>
  )
}

NotificationLevelView.propTypes = {
  onSelect: PropTypes.func.isRequired,
  notificationLevel: PropTypes.string,
}

export default NotificationLevelView
