import React, { useState, memo, useRef } from "react"
import PropTypes from "prop-types"
import { View, TouchableOpacity, Text } from "react-native"
import ReactTooltip from "react-tooltip"

import threeDotsIcon from "../../../../assets/images/three-dots.svg"

import styles from "./TabBarItem.styles"

const TabBarItem = ({
  title,
  icon,
  activeIcon,
  id,
  active,
  onSelect,
  activeTab,
}) => {
  const HitBox = active ? View : TouchableOpacity
  const hasIcon = active ? !!activeIcon : !!icon

  const [hovered, setIsHovered] = useState(false)
  const infoRef = useRef()

  return (
    <View
      style={[styles.container, active && styles.containerActive]}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={(e) => {
        e.stopPropagation()
        ReactTooltip.hide(infoRef.current)
        setIsHovered(false)
      }}
    >
      <HitBox style={styles.hitBox} onPress={() => onSelect(id)}>
        {hasIcon && (
          <View style={styles.iconWrap}>
            {active || hovered ? activeIcon : icon}
            {hovered && (
              <img
                src={threeDotsIcon}
                ref={infoRef}
                data-tip={title}
                data-for="information-tooltip"
                className="reactions-view"
                onMouseOver={(e) => {
                  e.stopPropagation()
                  ReactTooltip.show(infoRef.current)
                }}
                onClick={() => onSelect(id)}
                alt=""
              />
            )}
          </View>
        )}
        {/* <Text style={[styles.title, active && styles.titleActive]}>
                    {activeTab === id && title}
                </Text> */}
      </HitBox>
    </View>
  )
}

TabBarItem.propTypes = {
  id: PropTypes.any.isRequired,
  icon: PropTypes.node,
  activeIcon: PropTypes.node,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default memo(TabBarItem)
