import _ from "lodash"
import endpoints from "../config/endpoints"
import { requestErrors, signUpErrorTypes } from "../config/enums"

const getHeaders = ({ isDataUpload = false }) => {
  const storage = JSON.parse(localStorage.getItem("userInfo"))
  let token = storage.accessToken
  let headers = {
    "Content-Type": isDataUpload ? "multipart/form-data" : "application/json",
    Accept: "application/json",
  }

  if (token) {
    headers.Authorization = "Bearer " + token
  }

  return headers
}

const handledFetch = (
  url,
  method,
  body,
  fullError,
  isDataUpload,
  skipRecoverableError = false
) => {
  let status
  const headers = getHeaders({ isDataUpload })
  const requestParams = {
    method,
    headers,
  }
  if (body) {
    requestParams.body = body
  }
  return fetch(url, requestParams)
    .then((response) => {
      // 204 No Content
      status = response.status
      if (response.status === 204) {
        return ""
      } else {
        return response.json()
      }
    })
    .then((response) => {
      if (status >= 200 && status < 304) {
        return response
      } else {
        // error
        let message
        if (_.isString(response)) {
          message = response
        } else if (response.message) {
          message = response.message
        } else {
          message = "Unknown error"
        }
        let error = message
        if (fullError) {
          error = { message: error, status }
        }

        if (status >= 401 && status <= 403 && !skipRecoverableError) {
          return Promise.reject(requestErrors.recoverableError)
        }

        if (status >= 500 && error !== signUpErrorTypes.invalidOrExpiredCode) {
          return Promise.reject(requestErrors.unrecoverableError)
        }

        return Promise.reject(error)
      }
    })
    .catch((error) => {
      console.log(
        "url: ",
        url,
        "body: ",
        body,
        "method: ",
        method,
        "headers: ",
        headers
      )
      console.log("Backend error") //eslint-disable-line
      console.log(error) //eslint-disable-line
      if (error === requestErrors.recoverableError) {
        return Promise.reject(requestErrors.recoverableError)
      } else if (error === requestErrors.unrecoverableError) {
        return Promise.reject(requestErrors.unrecoverableError)
      } else {
        return Promise.reject(error)
      }
    })
}

export const batchVerifyAndGetUserInfo = (values) => {
  const url = endpoints.api + endpoints.batchVerifyAndGetUserInfo()
  const body = JSON.stringify({
    values,
  })
  return handledFetch(url, "POST", body)
}
