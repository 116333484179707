import React from "react"

const CommentsDetails = ({ messages, styledMessages, currentSuggestionId }) => {
  const currentSuggestionMessageIds = messages
    .filter((message) => message.suggestionId === currentSuggestionId)
    .map((message) => message.id)
  const currentSuggestionMessages = styledMessages.filter((styledMessage) =>
    currentSuggestionMessageIds.includes(
      styledMessage.props.children[0]?.props.id
    )
  )

  return (
    <div
      className="comments__details--container"
      style={{ height: "calc(100vh - 272px)", overflowY: "scroll" }}
    >
      {currentSuggestionMessages.map((message) => (
        <>{message}</>
      ))}
    </div>
  )
}

export default CommentsDetails
