import OneSignal from "react-onesignal"

import api from "./api"
import endpoints from "@config/endpoints"

const NOTIFICATION_PERMISSIONS = {
  granted: "granted",
  default: "default",
}

class NotificationService {
  init() {
    const appId = process.env.REACT_APP_ONE_SIGNAL_APP_ID
    const safari_web_id = process.env.REACT_APP_ONE_SIGNAL_SAFARI_WEB_ID

    const options = {
      safari_web_id,
    }

    // let elasticsearch = require("elasticsearch");
    // let client = new elasticsearch.Client({
    //   host: "https://dev-applogs.coo-e.com:9200",
    //   log: "trace",
    //   apiVersion: "7.4",
    // });

    OneSignal.initialize(appId, options, [
      {
        event: "notificationDisplay",
        callback: (event) => console.log(event, "NOTIFICATION"),
      },
    ])

    // OneSignal.sendSelfNotification(
    //   /* Title (defaults if unset) */
    //   "OneSignal Web Push Notification"
    // );
  }

  async askForNotificationsPermsions() {
    let currentState = await OneSignal.getNotificationPermission()
    if (currentState !== NOTIFICATION_PERMISSIONS.granted) {
      // Workaround to force OneSignal prompt to popup if user denied notifications in native prompt
      setTimeout(() => OneSignal.showSlidedownPrompt(), 5000)
      await OneSignal.registerForPushNotifications()
      currentState = await OneSignal.getNotificationPermission()
    }

    if (currentState === NOTIFICATION_PERMISSIONS.granted) {
      await OneSignal.registerForPushNotifications()

      const playerId = await OneSignal.getPlayerId()
      api.client
        .post(endpoints.notification.registerDevice, {
          deviceId: playerId,
        })
        .catch((error) => {
          console.log("error registering device", error)
        })
    }
  }

  async removePlayedId() {
    const playerId = await OneSignal.getPlayerId()

    const deleteUrl = endpoints.notification.deleteUserDeviceId(playerId)
    api.client.delete(deleteUrl).catch((error) => {
      console.log("error removing device id", error)
    })
  }
}

const notificationService = new NotificationService()

export default notificationService
