import React from "react"
import { ReactSVG } from "react-svg"
import classNames from "classnames"

import { images } from "@utils/imageUtils"
import { VOTE_OPTIONS } from "../../../TeeUpPageContext"

import "./gamePlanBox.scss"

const GamePlanDecided = ({
  overview = "",
  details = "",
  upVote = 0,
  downVote = 0,
  userVote,
  onUpVote,
  onDownVote,
}) => {
  return (
    <div className="game-plan-box decided">
      <div className="decided-box__title">Decided</div>
      <div className="decided-box__content">
        <div className="decided-box__info">
          <div className="decided-box__overview">{overview}</div>
          <div className="decided-box__details">{details}</div>
        </div>
        <div className="decided-box__voting">
          <div
            className={classNames(
              "decided-box__voting-button decided-box__voting-up-vote",
              { selected: VOTE_OPTIONS.upVote === userVote }
            )}
            onClick={onUpVote}
          >
            <ReactSVG
              src={images.ndThumbUpYellowIcon}
              className="voting-button-thumb"
            />
            <span>{upVote}</span>
          </div>
          <div
            className={classNames(
              "decided-box__voting-button decided-box__voting-down-vote",
              { selected: VOTE_OPTIONS.downVote === userVote }
            )}
            onClick={onDownVote}
          >
            <ReactSVG
              src={images.ndThumbDownYellowIcon}
              className="voting-button-thumb"
            />
            <span>{downVote}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GamePlanDecided
