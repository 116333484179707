import React from "react"
import { ReactSVG } from "react-svg"

import OpenButton from "../OpenButton"

import { useTeeUpPage } from "../../../TeeUpPageContext"

import { getGoingCount } from "@utils/teeupUtils"
import { images } from "@utils/imageUtils"

import "./index.scss"

const TopBarCloseButton = () => {
  const {
    participants,
    people,
    interactionArea,
    openPeopleArea,
    setOpenPeopleArea
  } = useTeeUpPage()

  const goingCount = getGoingCount(participants, people)

  return (
    <div className="top-bar-close">
      {!interactionArea && !openPeopleArea ? (
        <>
          <button
            type="button"
            className="top-bar-close__main"
            onClick={() => setOpenPeopleArea(true)}
          >
            <ReactSVG
              src={images.ndTeeUpPeopleMainButton}
              className="top-bar-close__main--icon"
            />
            PEOPLE
          </button>
    
          <div className="top-bar-close__count--wrapper">
            <img src={images.ndTeeUpPeopleCount} alt="people count" />
            {people && (
              <p className="top-bar-close__count">{Object.keys(people).length}</p>
            )}
            <img src={images.ndTeeUpPeopleCountArrow} alt="people arrow" />
            <p className="top-bar-close__count--going">{goingCount}</p>
          </div>
        </>
      ) : (
        <OpenButton />
      )}
    </div>
  )
}

export default TopBarCloseButton