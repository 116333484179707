import React from "react"
import { View, Text, Image } from "react-native"
import PropTypes from "prop-types"
import "./index.scss"
// import RoundedButton from '@ui/roundedButton'

import styles from "./emptyViewStyles"

export const EmptyView = ({
  imageSrc,
  title,
  subtitle,
  customContainerStyle,
  noTeeupsMatching,
  setTeeupFilter,
  resetSortAndFilter,
}) => {
  return (
    <View
      style={[styles.emptyContainer, customContainerStyle]}
      testID={"meGroupsEmptyList"}
    >
      <Image source={imageSrc} style={styles.emptyImage} />
      <Text style={styles.emptyTitle}>{title}</Text>
      <Text style={styles.emptySubtitle}>{subtitle}</Text>
      {noTeeupsMatching && (
        <button
          className="reset__button"
          onClick={() => {
            setTeeupFilter("")
            resetSortAndFilter()
          }}
        >
          Reset Search & Filters
        </button>
      )}
      {/* {btn && (
                <RoundedButton
                    title={btn.title}
                    onPress={btn.action}
                    style={styles.emptyActionButton}
                    loading={loading}
                />
            )} */}
    </View>
  )
}

EmptyView.defaultProps = {
  customContainerStyle: {},
  scrollEnabled: true,
}

EmptyView.propTypes = {
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btn: PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
    PropTypes.bool,
  ]),

  testID: PropTypes.string,
  // loading: PropTypes.bool,
  customContainerStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  scrollEnabled: PropTypes.bool,
}
