import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { ReactSVG } from "react-svg"

import InvitePeopleNd from "../../../../../components/InvitePeopleNd"
import TopBarThreeDotsDropDown from "../TopBarThreeDotsDropDown"
import TopBarManageParticipants from "../TopBarManageParticipants"
import TopBarManageNudge from "../TopBarManageNudge"

import { sendMessage } from "../../../../../middlewares/backendMiddleware"

import { formatInvitees } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"
import { getMentionedUsersIds } from "@utils/teeupUtils"

import {
  addTeeupInvitees,
  fetchTeeupUsersById,
  gotTeeupParts,
} from "@actions/teeupActions"

import "./index.scss"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"
import { useDispatch } from "react-redux"

const TopBarPeople = () => {
  const { teeup } = useTeeUpPage()
  const dispatch = useDispatch()

  const [invitees, setInvitees] = useState([])
  const [inviteToTeeUpById, setInviteToTeeUpById] = useState(false) // true when user pressed button 'Add Invitees'

  //---------HOVER/ONCLICK STATES---------
  const [isButtonHover, setIsButtonHover] = useState(0)
  const [isInvitePeopleOpen, setIsInvitePeopleOpen] = useState(false)
  const [isThreeDotsDropDownOpen, setIsThreeDotsDropDownOpen] = useState(false)
  const [activePopUp, setActivePopUp] = useState(0)

  useEffect(() => {
    if (inviteToTeeUpById) {
      (async () => {
        const formattedInvitees = formatInvitees(invitees)
  
        await addTeeupInvitees(teeup.id, formattedInvitees) // add invitees to people count in teeup data
        const teeupUsers = await fetchTeeupUsersById(teeup.id)
        dispatch(gotTeeupParts([teeupUsers]))
        
        //reset states
        setInviteToTeeUpById(false)
        setInvitees([])
      })();
    }
  }, [inviteToTeeUpById])

  return (
    <div className="top-bar-manage">
      {isInvitePeopleOpen && ReactDOM.createPortal(
        <InvitePeopleNd
          invitees={invitees}
          setInvitees={setInvitees}
          onClose={setIsInvitePeopleOpen}
          setInviteToTeeUpById={setInviteToTeeUpById}
        />,
        document.getElementById("modal-root") // INVITE PEOPLE POP-UP
      )}
      {activePopUp === 1 && 
        ReactDOM.createPortal(
          <TopBarManageParticipants close={() => setActivePopUp(0)} />,
          document.getElementById("modal-root")
      )}
      {(activePopUp === 2 || activePopUp === 3) && 
        ReactDOM.createPortal(
          <TopBarManageNudge
            activePopUp={activePopUp}
            title={activePopUp === 2
              ? "Ask People Where are They?"
              : "Ask People if They are Going?"}
            buttonText="Nudge People"
            close={() => setActivePopUp(0)} />,
          document.getElementById("modal-root")
      )}
      {(activePopUp === 4) && 
        ReactDOM.createPortal(
          <TopBarManageNudge
            activePopUp={activePopUp}
            title="Select People to Tag"
            buttonText="Tag People"
            close={() => setActivePopUp(0)} />,
          document.getElementById("modal-root")
      )}
      <button
        className="top-bar-manage__button"
        onMouseOver={() => setIsButtonHover(1)}
        onMouseLeave={() => setIsButtonHover(0)}
      >
        <ReactSVG
          src={isButtonHover === 1 ? images.ndShareTeeUpActive : images.ndShareTeeUp}
          className="top-bar-manage__button__icon"
        />
        Share
      </button>
      <button
        className="top-bar-manage__button"
        onMouseOver={() => setIsButtonHover(2)}
        onMouseLeave={() => setIsButtonHover(0)}
        onClick={() => setIsInvitePeopleOpen(true)}
      >
        <ReactSVG
          src={isButtonHover === 2 ? images.ndInviteToCurTeeUpActive : images.ndInviteToCurTeeUp}
          className="top-bar-manage__button__icon"
        />
        Invite
      </button>
      <button
        className="top-bar-manage__button"
        onMouseOver={() => setIsButtonHover(3)}
        onMouseLeave={() => setIsButtonHover(0)}
        onClick={() => setIsThreeDotsDropDownOpen(!isThreeDotsDropDownOpen)}
      >
        <ReactSVG src={isButtonHover === 3 ? images.ndTeeUpThreeDotsActive : images.ndTeeUpThreeDots} />
        {isThreeDotsDropDownOpen && (
          <TopBarThreeDotsDropDown
            activePopUp={activePopUp}
            setActivePopUp={setActivePopUp}
          />
        )}
      </button>
    </div>
  )
}

export default TopBarPeople