import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./expandedTags.scss"
import tagIcon from "../../../assets/images/tag.svg"
import {
  getUserTags,
  createUserTag,
  deleteUserTag,
  addTagsToUsers,
  removeTagsToUsers,
} from "@actions/userActions"
import { getContactId } from "@utils/contactUtils"
import {
  selectUserId,
  selectContactsSyncedAt,
  selectContactsSyncSettings,
} from "@selectors/user"
import { resyncContactsBook } from "@actions/contactActions"
import { postSettings } from "../../../actions/settingsActions"

const MAX_TAG_LENGTH = 32
const isCooeUser = (user) => user && (!!user.username || !!user.avatar)

const ExpandedTags = ({
  tagTitle,
  setTagTitle,
  closeTagUsersModal,
  invitees,
  tags,
  userId,
  contactSyncedAt,
  contactSyncSettings,
  getContacts,
}) => {
  const [tagsToTag, setTagsToTag] = useState([])

  const getUserIds = () => {
    if (!invitees) {
      return []
    }
    return invitees
      .filter((contact) => isCooeUser(contact) || contact.type === "contact")
      .map((contact) => getContactId(contact))
  }

  const getContactIds = () => {
    if (!invitees) {
      return []
    }

    return invitees
      .filter((contact) => !isCooeUser(contact) && contact.type !== "contact")
      .map((contact) => contact.id)
  }

  useEffect(() => {
    const userIdsToTag = getUserIds()
    const contactIdsToTag = getContactIds()
    console.log(userIdsToTag, 'userIdsToTag')
    console.log(contactIdsToTag, 'contactIdsToTag')
  }, [])

  const handleRefetchContacts = async () => {
    let allowSynchronization = contactSyncSettings.allowSynchronization

    if (!contactSyncedAt && !allowSynchronization) {
      const res = await postSettings({
        contacts: {
          ...contactSyncSettings,
          ...contactSyncSettings,
          allowSynchronization: true,
        },
      })
      allowSynchronization = res.contacts.allowSynchronization
    }
    resyncContactsBook()
  }

  const handleTagUsers = (selectedTags) => {
    const userIdsToTag = getUserIds()
    const contactIdsToTag = getContactIds()
    const selectedTagIds = [...selectedTags].map((tag) => tag.id)

    const body = {
      userIds: userIdsToTag,
      contactIds: contactIdsToTag,
      tagIds: selectedTagIds,
    }
    addTagsToUsers(userId, body, handleRefetchContacts)
  }

  const handleAddTagPress = async () => {
    if (!tagsToTag.length) {
      handleTagUsers([await createUserTag(userId, tagTitle)])
      await getContacts()
      closeTagUsersModal()
    }
    if (tagsToTag.length) {
      handleTagUsers(tagsToTag)
      await getContacts()
      closeTagUsersModal()
    }
  }

  const clearInput = () => {
    setTagTitle("")
    setTagsToTag([])
  }

  return (
    <div className="expandedTags" onClick={() => closeTagUsersModal()}>
      <div
        className="expandedTags__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="unactive" />
        <div className="tag-list__wrapper">
          <div className="tag-list">
            {tags.map((tag) => (
              <div key={tag.id} className="tag__wrapper">
                <div
                  className="tag"
                  onClick={() => {
                    setTagsToTag([tag])
                    setTagTitle(tag.value)
                  }}
                >
                  <img src={tagIcon} />
                  <p className="tag--title">{tag.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bottom_container">
          <img className="tag__image" src={tagIcon} />
          <input
            className="tag__input"
            value={tagTitle}
            type="text"
            onChange={(e) => setTagTitle(e.target.value.slice(0, 32))}
            onFocus={() => clearInput()}
            placeholder="Tag Text"
          />
          <p
            style={{ color: tagTitle.length === 32 && "#f42862" }}
            className="counter"
          >
            {tagTitle.length}/32
          </p>
          <button
            style={{ color: tagTitle && "#f42862" }}
            className="add-tag__button"
            disabled={!tagTitle && true}
            onClick={() => handleAddTagPress()}
          >
            Add Tag
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const userId = selectUserId(state)
  const contactSyncedAt = selectContactsSyncedAt(state)
  const contactSyncSettings = selectContactsSyncSettings(state)

  return {
    userId,
    taggingUsersEnabled: "usersToTag" in ownProps,
    contactSyncedAt,
    contactSyncSettings,
  }
}

export default connect(mapStateToProps, null)(ExpandedTags)
