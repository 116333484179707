import React from "react"

import "./gamePlanBox.scss"

const GamePlanNoDecision = () => {
  return (
    <div className="game-plan-box no-decision">
      <span className="no-decision-overview">No Decision Yet</span>
      <span className="no-decision-details">
        Decide on or make a suggestion
      </span>
    </div>
  )
}

export default GamePlanNoDecision
