import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export default StyleSheet.create({
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
  },
  emptyImage: {
    width: Units.rem(10),
    height: Units.rem(10),
    // marginBottom: Units.rem(1.375),
    // maxHeight: AppSizes.isSmallPhone ? '60%' : undefined,
  },
  emptyTitle: {
    marginBottom: Units.responsiveValue(8),
    color: AppColors.brand.black,
    textAlign: "center",
    ...AppFonts.headerDemibold,
  },
  emptySubtitle: {
    textAlign: "center",
    color: AppColors.brand.warmGrey,
    ...AppFonts.bigDemibold,
  },
  emptyActionButton: {
    marginTop: Units.rem(1.5),
    backgroundColor: AppColors.brand.pink,
    borderRadius: 22,
    width: Units.rem(13.75),
    height: Units.rem(2.75),
  },
})
