import React, { useEffect, useState } from "react";
import cn from "classnames";

import ProfileDropDown from "../ProfileDropDown";

import { getRandomBase64Image } from "@utils/imageUtils"

import './index.scss'
import useProfilePageUser from "../ProfilePageContext/useProfilePageUser";

const ProfilePageHeader = ({ userInfo, activeScreen, setActiveScreen, setIsTagUser }) => {
  const [backgroundImage, setBackgroundImage] = useState(null)
  const {isThreeDotsOpen, setIsThreeDotsOpen} = useProfilePageUser()

  const menuTitles = ["Shared teeups", "About"]

  useEffect(() => {
    userInfo && userInfo.profile.backgroundImageUrl
      ? setBackgroundImage(userInfo.backgroundImageUrl)
      : (async () => {
        const image = await getRandomBase64Image()
  
        setBackgroundImage(image)
        })()
  }, [])

  return (
    <div className="profile-header">
      <div
        className="profile-header__background"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
      <div className="profile-header__info">
        {userInfo && (
          <>
            <div
              className="profile-header__avatar"
              style={{
                backgroundImage: `url(${userInfo.profile.avatar})`,
              }}
            ></div>
            <div className="profile-header__title">
              <h3 className="profile-header__name">{userInfo.profile.name}</h3>
              <h4 className="profile-header__username">@{userInfo.profile.username}</h4>
            </div>
          </>
        )}
        <div
          className="profile-header__three-dots__wrapper"
          onClick={() => setIsThreeDotsOpen(!isThreeDotsOpen)}
        >
          <div className="profile-header__three-dots" />
        </div>
        {isThreeDotsOpen && (
          <ProfileDropDown
            close={() => setIsThreeDotsOpen(false)}
            userInfo={userInfo}
            setIsTagUser={setIsTagUser}
          />
        )}
      </div>
      <div className="profile-header__menu">
        {menuTitles.map((title, index) => (
          <div
            key={index}
            className="profile-header__menu-item__wrapper"
            onClick={() => setActiveScreen(index)}
          >
            <h3
              className={cn("profile-header__menu-item", {
                "profile-header__menu-item--active": activeScreen === index
              })}
            >
              {title}
            </h3>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfilePageHeader
