import React from "react"
import { View, Text, Image } from "react-native"
import PropTypes from "prop-types"
import moment from "moment"

import { getUsernameOrName } from "@utils/contactUtils"
import TabView from "@ui/tabView"
import { ViewProps, ViewStyles } from "./ParticipantDetailsStyles"
import Avatar from "@ui/avatar"
import UserStatusAccesory from "@ui/avatar/userStatusAccesory"

const ParticipantDetailsView = ({
  routes,
  sceneMapping,
  statusText,
  userInfo = {},
  isTeeupDisabled,
  photo,
}) => {
  const joinedAt = moment(userInfo.joinedAt).format("llll")
  return (
    <View style={ViewStyles.container}>
      <View style={ViewStyles.detailsBackground}>
        <Image style={ViewStyles.detailsBackgroundImage} source={photo} />
      </View>
      <View style={ViewStyles.userContainer}>
        <Avatar
          imageUrl={userInfo.avatar}
          size={ViewProps.avatarSize}
          disabled={isTeeupDisabled}
          accesory={
            <UserStatusAccesory
              additionalCount={userInfo.additionalCount}
              userStatus={userInfo.status}
              accesoryStyle={ViewStyles.avatarAccesory}
            />
          }
          participantDetails
        />
        <View style={ViewStyles.userContainerInformation}>
          <Text style={ViewStyles.nameText}>{getUsernameOrName(userInfo)}</Text>
          <Text style={ViewStyles.statusText}>@{userInfo.username}</Text>
          <Text style={ViewStyles.statusText}>Joined {joinedAt}</Text>
        </View>
      </View>

      <TabView
        routes={routes}
        sceneMapping={sceneMapping}
        customTabbarStyle={ViewStyles.tabbar}
        participantDetails
      />
    </View>
  )
}

export default ParticipantDetailsView

ParticipantDetailsView.propTypes = {
  routes: PropTypes.array.isRequired,
  sceneMapping: PropTypes.object.isRequired,
  routeProfile: PropTypes.func.isRequired,
  statusText: PropTypes.string,
  userInfo: PropTypes.object,
  isTeeupDisabled: PropTypes.bool,
}
