import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash"
import Modal from "react-modal"

import { updateUserStatus } from "@actions/requestActions"
import {
  selectTeeupsPeople,
  selectTeeupsGameplans,
  selectTeeupsSuggestions,
} from "@selectors/teeups"
import { selectUserInfo, selectUserId } from "@selectors/user"
// import { showBringingOthersOverlay } from '@nav'

import {
  teeupUserStatus,
  teeupStatus,
  teeupUserStatusKeys,
} from "@configs/enums"
import { userStatusTypes } from "@config/mappings"
// import navigationConfig from '@nav/NavigationConfig'

import MyStatusView from "./myStatusView"
import BringingOthers from "../bringingOthersModal"

import "./userStatusModal.scss"

class MyStatusContainer extends Component {
  state = {
    isBringingOthersOpen: false,
    status: "",
  }
  // static options() {
  //     return {
  //         statusBar: {
  //             ...navigationConfig.darkStatusBar,
  //         },
  //     }
  // }

  handleClose = () => {
    this.props.onClose()
  }

  onStatusSelected = (status) => {
    if (status && status !== "" && typeof status === "string") {
      this.props.teeupStatusUpdated(teeupStatus.reverse[status])
    }
  }

  handleUserStatusSelected = (userStatus, additionalCount) => {
    const { teeup, updateUserStatus } = this.props

    this.handleClose()

    if (!userStatus) {
      return
    }

    const userStatusKey = teeupUserStatus[userStatus]
      ? userStatus
      : teeupUserStatus.reverse[userStatus]
    let statusId = userStatusTypes.reverse[userStatusKey]
    updateUserStatus(teeup.id, statusId, additionalCount)
  }

  handleClickBringing = (status) => {
    this.setState({ isBringingOthersOpen: true, status })
  }

  render() {
    const {
      teeup,
      people,
      userInfo,
      userId,
      isDataLoaded,
      modalPosition,
      handleSetPeopleStatus,
    } = this.props

    const { status } = teeup

    let userStatus = teeupUserStatusKeys.invited
    let additionalCount = 0
    let userParticipant = people[userInfo.id]

    if (userParticipant) {
      userStatus = userParticipant.status
      additionalCount = userParticipant.additionalCount || 0
    }

    const modalStyles = {
      content: {
        top: "50%",
        transform: "translateY(-50%)",
        left: "41%",
        backgroundColor: "#F5F8FA",
        //   backgroundColor: '#F5F5F5',
        width: 320,
        border: "1px solid #F3F3F3",
        padding: 0,
        height: "fit-content",
        borderRadius: "10px",
        boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.4)",
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      },
    }

    return (
      <>
        <div className="select-status-backdrop" />
        <div className="select-status-modal">
          {/* <Modal
            isOpen={true}
            onRequestClose={this.handleClose}
            style={modalStyles}
            ariaHideApp={false}
          > */}
          {this.state.isBringingOthersOpen ? (
            <BringingOthers
              teeup={this.props.teeup}
              status={this.state.status}
              onSubmit={this.handleUserStatusSelected}
              onClose={this.handleClose}
            />
          ) : (
            <MyStatusView
              people={people}
              handleSetPeopleStatus={handleSetPeopleStatus}
              userId={userId}
              userInfo={userInfo}
              status={status}
              isDataLoaded={isDataLoaded}
              teeup={teeup}
              additionalCount={additionalCount}
              userStatus={userStatus}
              onUserStatusSelected={this.handleUserStatusSelected}
              onClose={this.handleClose}
              onClickBringing={this.handleClickBringing}
            />
          )}
          {/* </Modal> */}
        </div>
      </>
    )
  }
}

MyStatusContainer.propTypes = {
  componentId: PropTypes.string,
  teeup: PropTypes.object,
  people: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  userInfo: PropTypes.object,
  userId: PropTypes.number,
  isDataLoaded: PropTypes.bool,
  onClose: PropTypes.func,
  userStatusUpdated: PropTypes.func,
  teeupStatusUpdated: PropTypes.func,
  suggestions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

const mapStateToProps = (state, ownProps) => {
  const teeupId = ownProps.teeup.id
  const people = selectTeeupsPeople(state)[teeupId]
  const gameplans = selectTeeupsGameplans(state)[teeupId]
  const suggestions = selectTeeupsSuggestions(state)[teeupId]
  const userInfo = selectUserInfo(state)
  const userId = selectUserId(state)

  const isDataLoaded = !_.isEmpty(people) && !_.isEmpty(gameplans)

  return {
    people,
    userInfo,
    userId,
    suggestions,
    isDataLoaded,
  }
}
const mapDispatchToProps = (dispatch) => ({
  updateUserStatus: bindActionCreators(updateUserStatus, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyStatusContainer)
