import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

const styles = StyleSheet.create({
  inviteesContainer: {
    backgroundColor: AppColors.brand.white,
    zIndex: 10,
  },
  flatList: {
    paddingTop: Units.rem(1),
    paddingBottom: Units.rem(0.75),
    paddingLeft: Units.rem(1),
    justifyContent: "center",
  },
  inviteesBorder: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: -1,
    height: 1,
    backgroundColor: AppColors.brand.grey2,
  },
  waitingForHeight: {
    opacity: 0,
    position: "absolute",
  },
  slideAnimation: (height) => ({
    height,
  }),
  inviteesShadow: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: -4,
    height: 4,
    opacity: 0.08,
  },
  inviteeItem: {
    // width: Units.rem(3),
  },
  avatar: {
    height: Units.rem(3.5),
    width: Units.rem(3.5),
    borderRadius: Units.rem(3.5),
    resizeMode: "cover",
  },
  inviteeIcon: {
    height: Units.rem(3.5),
    width: Units.rem(3.5),
    borderRadius: Units.rem(3.5),
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.brand.grey2,
  },
  inviteeInitial: {
    ...AppFonts.medium,
    color: AppColors.brand.darkGrey2,
  },
  inviteeLabel: {
    ...AppFonts.smallMedium,
    color: AppColors.brand.blackTwo,
  },
  removeButton: {
    height: Units.rem(1.25),
    width: Units.rem(1.25),
    position: "absolute",
    top: -Units.rem(0.25),
    right: -Units.rem(0.25),
  },
  scrollViewContainer: {
    flexDirection: "row",
  },
  contentContainerStyle: {
    flexGrow: 1,
    paddingHorizontal: Units.rem(1),
    backgroundColor: AppColors.brand.white,
  },
  absoluteButton: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  iconWrapper: {
    width: Units.rem(1.3125),
    height: Units.rem(1.3125),
    borderRadius: Units.rem(1.3125),
    backgroundColor: AppColors.brand.white,
    justifyContent: "center",
    alignItems: "center",
  },
  inviteeMechanismType: {
    fontSize: Units.responsiveValue(11),
    color: AppColors.brand.warmGrey2,
    letterSpacing: Units.rem(-0.0125),
    fontFamily: AppFonts.family.medium,
  },
  inviteeInfo: {
    justifyContent: "center",
    alignItems: "center",
  },
})

const icons = {
  mobileIcon: {
    type: "font-awesome",
    name: "phone",
    size: Units.rem(1),
    color: "grey",
  },
  emailIcon: {},
}

export { styles, icons }
