import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export const styles = StyleSheet.create({
  sideMenuWrapper: {
    display: "flex",
    width: 128,
    // backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: Units.responsiveValue(30),
    position: "fixed",
    // position: 'absolute',
    zIndex: 1,
  },
  row: { flexDirection: "column" },
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: Units.responsiveValue(11),
    height: Units.responsiveValue(56),
    // backgroundColor: AppColors.brand.white,
  },
  content: {
    backgroundColor: AppColors.brand.lightGrey,
    paddingTop: Units.responsiveValue(20),
  },
  lineWrapper: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: Units.responsiveValue(11),
    paddingRight: Units.responsiveValue(14),
    paddingLeft: Units.responsiveValue(14),
    borderRadius: Units.responsiveValue(8),
    // backgroundColor: AppColors.brand.white,
  },
  title: {
    fontSize: 13,
    fontFamily: "greycliffcf-demibold-salt",
    // textTransform: 'uppercase',
    fontWeight: "600",
    letterSpacing: -0.26,
    color: "#222222",
  },
  titleWrapper: {
    display: "block",
    transition: "display 0.5s",
    flexGrow: 1,
    flexDirection: "row",
    // marginLeft: Units.responsiveValue(10),
    alignItems: "center",
  },
  titleWrapperNone: {
    display: "none",
    transition: "all 0.5s",
  },
  badgeWrapper: {
    width: 8,
    height: 8,
    borderRadius: 7.5,
    border: "solid 1px #F0F2F5",
    // border: 'solid 1px #f0f0f0',
    backgroundColor: "#f42862",
    position: "absolute",
    top: -2,
    right: -2,
    zIndex: 1,
  },
  //images
  closeButton: {
    tintColor: AppColors.brand.pink,
    width: Units.responsiveValue(18),
    height: Units.responsiveValue(18),
    resizeMode: "contain",
  },
  image: (isSelected) => ({
    width: Units.responsiveValue(23),
    height: Units.responsiveValue(18),
    tintColor: AppColors.brand[isSelected ? "pink" : "grey"],
    resizeMode: "contain",
  }),
  //text
  headerTitle: {
    flexGrow: 1,
    ...AppFonts.headerSmallBold,
    alignSelf: "center",
    textAlign: "center",
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.47),
    color: AppColors.brand.black2,
  },
  titleText: (isSelected) => ({
    ...(isSelected ? AppFonts.mediumExtraBold : AppFonts.mediumDemibold),
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.35),
    color: AppColors.brand.black2,
  }),
  badgeText: {
    ...AppFonts.mediumDemibold,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.35),
    color: AppColors.brand.white,
  },
  icon: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(14),
    tintColor: "black",
    resizeMode: "contain",
  },
  iconSelected: {
    width: Units.responsiveValue(23),
    height: Units.responsiveValue(18),
    tintColor: "pink",
    resizeMode: "contain",
  },
})
