import { createSelector } from "reselect"
import _ from "lodash"

import { selectUserId } from "./user"
import { conversationTypeIds, statusTypeIds } from "@config/mappings"

export const selectActiveTeeupState = (state) => state.activeTeeup

export const selectActiveTeeup = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => activeTeeup
)

export const selectActiveTeeupId = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => activeTeeup.id
)

export const selectActiveTeeupOrganizers = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => activeTeeup.organisers
)

export const selectActiveTeeupSettings = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => activeTeeup.settings || {}
)

export const selectMessages = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => activeTeeup.messages
)

export const selectDetailsOpen = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => activeTeeup.detailsOpen
)

export const selectDetailsUser = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => activeTeeup.detailsUser
)

export const selectMessageById = (messageId) =>
  createSelector(selectMessages, (messages) =>
    messages.find((message) => message.id == messageId)
  )

export const selectMessagesWithoutNugdes = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => {
    return activeTeeup?.messages
      ?.filter(
        (message) =>
          message.typeId !== statusTypeIds.retract &&
          message.conversationTypeId !== conversationTypeIds.nudge
      )
      .reverse()
  }
)

export const selectTeeupInvitationHistory = createSelector(
  selectActiveTeeupState,
  (activeTeeup) => activeTeeup.invitationHistory
)

export const selectActiveTeeupInvited = createSelector(
  selectActiveTeeup,
  (activeTeeup) => activeTeeup.invited || []
)

export const selectActiveTeeupGameplans = createSelector(
  selectActiveTeeup,
  (activeTeeup) => activeTeeup.gameplans || []
)

export const selectGameplanById = (gameplanId) =>
  createSelector(selectMessages, (messages) => {
    const messagesCount = messages.length
    if (messagesCount > 0) {
      const { gameplans } = messages[messagesCount - 1]
      return gameplans.find((gp) => gp.id == gameplanId)
    }
  })

export const selectTeeupPinnedMessages = createSelector(
  selectMessages,
  (messages) => {
    const messagesWithInitialSuggestions = [...messages]
    const initialMessage = messages[messages.length - 1]

    if (initialMessage?.gameplans?.length) {
      _.forEach(
        initialMessage.gameplans,
        (gameplan) =>
          gameplan.id && messagesWithInitialSuggestions.push(gameplan)
      )
    }
    const filteredByPinned = _.filter(
      messagesWithInitialSuggestions,
      (message) => message.isPinned
    )
    const sortedPinnedMessages = filteredByPinned.sort((a, b) => {
      const aDate = a?.updatedAt ? new Date(a.updatedAt) : new Date()
      const bDate = b?.updatedAt ? new Date(b.updatedAt) : new Date()
      return aDate.getTime() - bDate.getTime()
    })
    return sortedPinnedMessages.reverse()
  }
)

export const selectHiddenPinnedMessages = createSelector(
  selectActiveTeeup,
  (teeup) => teeup.hiddenPinnedMessages || []
)

export const selectVisibleTeeupPinnedMessages = createSelector(
  selectTeeupPinnedMessages,
  selectHiddenPinnedMessages,
  (messages, hiddenMessages) => {
    return messages.filter((message) => !hiddenMessages.includes(message.id))
  }
)

export const selectTeeupMentions = createSelector(
  selectMessages,
  selectUserId,
  (messages, userId) => {
    messages = messages.filter((m) => !!m)
    if (!messages.length) return messages

    const teeupMentions = _.filter(
      messages,
      (message) =>
        message.conversationTypeId === conversationTypeIds.message &&
        !!message.mentionIds &&
        message.mentionIds.find((id) => id === userId)
    )

    return teeupMentions
  }
)

export const selectTeeupNudges = createSelector(
  selectMessages,
  selectUserId,
  (messages, userId) => {
    if (!messages.length) return messages

    const teeupNudges = _.filter(
      messages,
      (message) =>
        message?.conversationTypeId === conversationTypeIds.nudge &&
        (userId === Number(message.senderId) ||
          userId === Number(message.userId) ||
          message.mentionIds.find((id) => id === userId)) &&
        !message.messageId
    )

    return teeupNudges
  }
)

export const selectNudgesResponses = createSelector(
  selectMessages,
  selectTeeupNudges,
  (messages, nudges) => {
    if (!messages.length) return messages
    const nudgesResponses = _.filter(messages, (message) =>
      _.find(nudges, (nudge) => nudge.id === message.messageId)
    )
    const filtered = _.filter(
      nudgesResponses,
      (res, i, self) =>
        res.id ===
        _.find(
          self,
          (b) => b.userId === res.userId && res.messageId === b.messageId
        )?.id
    )
    return filtered
  }
)

export const selectTeeupNudgesWithResponses = createSelector(
  selectTeeupNudges,
  selectNudgesResponses,
  (teeupNudges, nudgesResponses) => {
    if (!teeupNudges.length) return []
    else {
      return teeupNudges.map((nudge) => {
        let nudgeAnswers = {}
        nudge.mentionIds.forEach((mentionId) => {
          const userResponse = nudgesResponses.find(
            (res) => res.userId === mentionId && res.messageId === nudge.id
          )
          if (userResponse) {
            nudgeAnswers[mentionId] = userResponse
          }
        })
        return { ...nudge, nudgeAnswers }
      })
    }
  }
)

export const selectActiveTeeupGroupId = createSelector(
  selectActiveTeeup,
  (activeTeeup) => activeTeeup.groupId || null
)
