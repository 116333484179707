import React, { useState, useMemo, useCallback } from "react"
import { ReactSVG } from "react-svg"

import CalendarDays from "./CalendarDays"
import { images } from "@utils/imageUtils"
import { MONTHS, MAX_NUMBER_OF_SELECTED_DATES } from "./constants"

import "./useCalendar.scss"

const useCalendar = () => {
  const [dates, setDates] = useState([])
  const [displayDate, setDisplayDate] = useState(new Date())

  const secondCalendarDate = useMemo(() => {
    const year = displayDate.getFullYear()
    const month = displayDate.getMonth() + 1
    const day = 1

    const newDate = new Date(year, month, day)

    return newDate
  }, [displayDate])

  const handleArrowClick = useCallback(
    (direction) => () => {
      const move = direction === "left" ? -1 : 1

      const year = displayDate.getFullYear()
      const month = displayDate.getMonth() + move
      const day = 1

      const newDate = new Date(year, month, day)

      setDisplayDate(newDate)
    },
    [displayDate]
  )

  const addDate = useCallback(
    (date) => {
      if (dates.length <= MAX_NUMBER_OF_SELECTED_DATES) {
        setDates([...dates, date])
      } else {
        alert(
          `You can only create ${MAX_NUMBER_OF_SELECTED_DATES} suggestions.`
        )
      }
    },
    [dates]
  )

  const removeDate = useCallback(
    (date) => {
      const newDates = dates.filter((d) => d.getTime() !== date.getTime())
      setDates(newDates)
    },
    [dates]
  )

  const handleDateClick = useCallback(
    (date) => {
      if (!date) {
        return false
      }

      const alreadySelected = !!dates.find(
        (d) => d.getTime() === date.getTime()
      )

      alreadySelected ? removeDate(date) : addDate(date)
    },
    [addDate, dates, removeDate]
  )

  const Calendar = () => {
    if (!dates) {
      return null
    }

    return (
      <div className="calendar">
        <div className="calendar-header">
          <div
            className="calendar-header__arrows calendar-header__arrows-left"
            onClick={handleArrowClick("left")}
          >
            <ReactSVG src={images.ndArrowLeftIcon} />
          </div>
          <div className="calendar-header__month-year-display">{`${
            MONTHS[displayDate.getMonth()]
          } ${displayDate.getFullYear()}`}</div>
          <div className="calendar-header__month-year-display">{`${
            MONTHS[secondCalendarDate.getMonth()]
          } ${secondCalendarDate.getFullYear()}`}</div>
          <div
            className="calendar-header__arrows calendar-header__arrows-right"
            onClick={handleArrowClick("right")}
          >
            <ReactSVG src={images.ndArrowRightIcon} />
          </div>
        </div>
        <div className="calendar-days">
          <div className="calendar-days__one">
            <CalendarDays
              day={displayDate}
              handleDateClick={handleDateClick}
              selectedDates={dates}
            />
          </div>
          <div className="calendar-days__two">
            <CalendarDays
              day={secondCalendarDate}
              handleDateClick={handleDateClick}
              selectedDates={dates}
            />
          </div>
        </div>
      </div>
    )
  }

  return {
    addDate,
    removeDate,
    Calendar,
    dates,
  }
}

export default useCalendar
