import React from "react"
import propTypes from "prop-types"
import { View, Image, Text } from "react-native"

import { images } from "@utils/imageUtils"
import { UserStatusButtonProps } from "../teeupStyles"

import { styles } from "./TeeupUserStatusesStyles"

// TODO if only button display is required (DEFINETELY END DECISION) then remove onPress function
const UserStatusBlock = ({
  peopleCount,
  isCurrentUserReact,
  image,
  withMargin,
  selectStatus,
  statusId,
}) => (
  <View
    style={[
      styles.statusBlock({ isCurrentUserReact }),
      withMargin && styles.additionalMargin,
    ]}
    onPress={() => selectStatus(statusId)}
  >
    <Image source={image} />
    <Text style={styles.countText({ isCurrentUserReact })}>{peopleCount}</Text>
  </View>
)
UserStatusBlock.propTypes = {
  peopleCount: propTypes.number.isRequired,
  image: propTypes.string.isRequired,
  isCurrentUserReact: propTypes.bool.isRequired,
  withMargin: propTypes.bool.isRequired,
}
const TeeupUserStatusesComponent = ({
  userId,
  goingPeopleIds,
  mightgoPeopleIds,
  selectStatus,
  goingPeopleCount,
  mightGoPeopleCount,
}) => {
  return (
    <View style={styles.container}>
      <UserStatusBlock
        peopleCount={goingPeopleCount}
        isCurrentUserReact={
          goingPeopleIds.filter((id) => +id === userId).length
        }
        image={images.startsWhenGoing}
        selectStatus={selectStatus}
        statusId={UserStatusButtonProps[2].status}
      />
      <UserStatusBlock
        peopleCount={mightGoPeopleCount}
        isCurrentUserReact={
          mightgoPeopleIds.filter((id) => +id === userId).length
        }
        image={images.startsWhenMightGo}
        withMargin={true}
        selectStatus={selectStatus}
        statusId={UserStatusButtonProps[3].status}
      />
    </View>
  )
}

TeeupUserStatusesComponent.propTypes = {
  userId: propTypes.number.isRequired,
  goingPeopleIds: propTypes.arrayOf().isRequired,
  mightgoPeopleIds: propTypes.arrayOf().isRequired,
  selectStatus: propTypes.func.isRequired,
  goingPeopleCount: propTypes.number.isRequired,
  mightGoPeopleCount: propTypes.number.isRequired,
}

UserStatusBlock.propTypes = {
  selectStatus: propTypes.func.isRequired,
  statusId: propTypes.string.isRequired,
}

export default TeeupUserStatusesComponent
