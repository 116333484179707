import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { ToggleButton } from "@ui/settingsClickableRow/actionButtons"

import { images } from "@utils/imageUtils"

const TeeupPermissionsItemNd = ({ title, text, value, onChange }) => {
  return (
    <div
      className="teeup-permissions__item"
      onClick={() => onChange(!value)}
    >
      <div className="teeup-permissions__text--wrapper">
        <p className="teeup-permissions__text">{title}</p>
        {text && <span className="teeup-permissions__span">{text}</span>}
      </div>
      <ToggleButton
        onToggle={() => onChange}
        value={value}
      />
    </div>
  )
}

const TeeupPermissionsNd = ({ setIsPermissionsOpen, settings, onChangeSettings }) => {

  return (
    <div
      className="teeup-permissions__wrapper"
      onClick={() => {
        setIsPermissionsOpen(false)
      }}
    >
      <div
        className="teeup-permissions"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <h3 className="teeup-permissions__title">TeeUp Permissions</h3>
        <img
          src={images.ndInviteesCross}
          className="teeup-permissions__cross"
          onClick={() => {
            setIsPermissionsOpen(false)
          }}
        />
        <div className="teeup-permissions__content">
          <h4 className="teeup-permissions__subtitle">Allow anyone to</h4>

          <TeeupPermissionsItemNd
            title="Invite Others"
            value={settings.allowInviteOthers}
            onChange={(value) => {
              onChangeSettings("allowInviteOthers", value)
            }}
          />
          <TeeupPermissionsItemNd
            title="Change Game Plan"
            value={settings.allowChangeGameplan}
            onChange={(value) => {
              onChangeSettings("allowChangeGameplan", value)
            }}
          />
          <TeeupPermissionsItemNd
            title="Mark Game Plan items as Decided"
            text="Includes placing suggestion on, removing from, & withdrawing suggestion"
            value={settings.allowDecideGameplan}
            onChange={(value) => {
              onChangeSettings("allowDecideGameplan", value)
            }}
          />
          <TeeupPermissionsItemNd
            title="Change TeeUp Title"
            value={settings.allowChangeTitle}
            onChange={(value) => {
              onChangeSettings("allowChangeTitle", value)
            }}
          />
          <TeeupPermissionsItemNd
            title="Change TeeUp to All Set or Planning"
            value={settings.allowSetPlanning}
            onChange={(value) => {
              onChangeSettings("allowSetPlanning", value)
            }}
          />
          <TeeupPermissionsItemNd
            title="Set the TeeUp as Ended"
            value={settings.allowSetEnded}
            onChange={(value) => {
              onChangeSettings("allowSetEnded", value)
            }}
          />

          <h4 className="teeup-permissions__subtitle">Sharing TeeUp</h4>
          <TeeupPermissionsItemNd
            title="Invite via link"
            value={settings.allowInviteLink}
            onChange={(value) => {
              onChangeSettings("allowInviteLink", value)
            }}
          />
          <TeeupPermissionsItemNd
            title="Joining via the link requires
            organizers' approval"
            value={settings.allowJoin}
            onChange={(value) => {
              onChangeSettings("allowJoin", value)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TeeupPermissionsNd