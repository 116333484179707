import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { View, Text, Image, TouchableOpacity, FlatList } from "react-native"
// import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view'

import SearchBar from "@ui/searchBar"
import ClickableRow from "@ui/settingsClickableRow"
import { ToggleButton } from "@ui/settingsClickableRow/actionButtons"
import { images } from "@utils/imageUtils"
import { getTimezones } from "@utils/timezones"
import { styles } from "./calendarSettingsStyles"

class TimezoneRow extends React.Component {
  onSelected = () => {
    const { timezone, onSelected } = this.props
    onSelected(true, timezone.name)
  }

  render() {
    const { timezone, selectedTimezone, isSelected } = this.props
    const { isHomeTZ, isTravelTZ } = timezone
    const isSelectedTimezone = timezone.name === selectedTimezone
    return (
      <TouchableOpacity
        style={styles.timezoneContainer}
        onPress={this.onSelected}
      >
        {/* {isTravelTZ && 
                    <Image 
                        source={images.tzEmptyIconHome}
                        style={styles.cancelBtnImage} 
                    />
                } */}
        <View style={styles.timezoneIconContainerWrapper}>
          <View style={styles.timezoneIconContainer}>
            {isHomeTZ && <Image source={images.tzEmptyIconHome} />}
            {isTravelTZ && <Image source={images.tzEmptyIconTravel} />}
          </View>
          <Text style={styles.text}>
            (UTC{timezone.utcOffsetStr}) {timezone.name}
          </Text>
        </View>

        {isSelectedTimezone && (
          <Image source={images.iconSortSelected} style={styles.checkImage} />
        )}
      </TouchableOpacity>
    )
  }
}

TimezoneRow.propTypes = {
  onSelected: PropTypes.func.isRequired,
  timezone: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
}

const SelectedTimezoneRow = ({
  timezone,
  isHomeTZ,
  isTravelTZ,
  onSelected,
}) => {
  const timezoneInfo = getTimezones()[timezone]
  let icon = images.tzEmptyIconActive
  if (isHomeTZ) {
    icon = images.tzEmptyIconHome
  } else if (isTravelTZ) {
    icon = images.tzEmptyIconTravel
  }
  return (
    <TouchableOpacity
      style={[
        styles.selectetTimezoneContainer,
        { borderBottom: "1px solid #f3f3f3" },
      ]}
      onPress={onSelected}
    >
      {/* <View style={styles.timezoneIconContainer}>
                <Image style={styles.selectedTZicon} source={icon} />
            </View> */}
      <Image source={images.tzEmptyIconHome} style={styles.cancelBtnImage} />
      <View style={styles.selectetTimezoneInfo}>
        <Text style={[styles.text, styles.selectedTZtext]}>
          (UTC{timezoneInfo.utcOffsetStr}) {timezoneInfo.name}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

SelectedTimezoneRow.propTypes = {
  timezone: PropTypes.string.isRequired,
  isHomeTZ: PropTypes.bool,
  isTravelTZ: PropTypes.bool,
}

class TimezoneSelect extends React.Component {
  constructor(props) {
    super(props)

    const { defaultTimezone, selectedTimezone, homeTimezone, travelTimezone } =
      props

    let priorityTimezonesFound = 0
    let timezones = _.sortBy(getTimezones(), ["utcOffset", "name"])

    // Move default timezone + selected timezone to the top (if any)
    for (let i = 0, len = timezones.length; i < len; i++) {
      const timezone = {
        ...timezones[i],
        isHomeTZ: timezones[i].name === homeTimezone,
        isTravelTZ:
          timezones[i].name === travelTimezone &&
          timezones[i].name !== homeTimezone,
      }
      if (
        timezone.name === defaultTimezone ||
        timezone.name === selectedTimezone ||
        timezone.isHomeTZ ||
        timezone.isTravelTZ
      ) {
        timezones[i] = timezones[priorityTimezonesFound]
        timezones[priorityTimezonesFound] = timezone
        priorityTimezonesFound++
      }
    }

    this.state = {
      selectedTimezone: props.selectedTimezone,
      tzAutodetected: props.tzAutodetected,
      timezones,
      searchedTimezones: timezones,
      searchText: "",
    }

    this._handleSearch = _.debounce(this.onChangeSearchText, 250)
  }

  static options() {
    return {
      popGesture: false,
    }
  }

  renderItem = ({ item }) => {
    const { selectedTimezone, onSelected, componentId } = this.props
    return (
      <TimezoneRow
        componentId={componentId}
        onSelected={onSelected}
        timezone={item}
        isSelected={item.name === selectedTimezone}
        selectedTimezone={selectedTimezone}
      />
    )
  }

  keyExtractor = (item) => {
    return item.name
  }

  timezonesHoursMatch = (tz, tzSearch) => {
    if (tzSearch.length === 2) {
      return tz === tzSearch
    }
    if (tzSearch[0] === "0") {
      return tz[0] === "0"
    }
    return tz.includes(tzSearch)
  }

  timezonesMinutesMatch = (tz, tzSearch) => {
    if (!tzSearch) {
      // no minutes set in search -- anything match
      return true
    }
    if (tzSearch.length === 2) {
      return tz === tzSearch
    }
    if (tzSearch[0] === "0") {
      return tz[0] === "0"
    }
    return tz.includes(tzSearch)
  }

  onChangeSearchText = (searchText) => {
    const { timezones, searchedTimezones: displayedTimezones } = this.state
    const text = searchText.trim()
    if (!text) {
      if (displayedTimezones.length !== timezones.length) {
        this.setState({ searchedTimezones: timezones })
      }
      return
    }
    const searchHourOffset = text.match(/[-+]?\d{1,2}:?\d{0,2}/) || []
    let hoursMins, offsetDirection
    if (searchHourOffset[0]) {
      hoursMins = searchHourOffset[0].match(/\d{1,2}/g)
      offsetDirection =
        searchHourOffset[0][0] === "+" || searchHourOffset[0][0] === "-"
          ? searchHourOffset[0][0]
          : "+"
    }
    const searchTimezoneName = text.match(/[a-zA-Z]+/gi) || []
    const timezoneName = searchTimezoneName.join("/")
    const searchedTimezones = timezones.filter((timezone) => {
      const TZhoursMins = timezone.utcOffsetStr.match(/\d{1,2}/g)
      const TZoffsetDirection = timezone.utcOffsetStr[0]
      if (
        !!hoursMins &&
        !!offsetDirection &&
        this.timezonesHoursMatch(TZhoursMins[0], hoursMins[0]) &&
        this.timezonesMinutesMatch(TZhoursMins[1], hoursMins[1]) &&
        TZoffsetDirection === offsetDirection
      ) {
        return true
      }
      if (
        !!timezoneName &&
        timezone.name.toLowerCase().includes(timezoneName.toLowerCase())
      ) {
        return true
      }
      return false
    })
    this.setState({ searchedTimezones })
  }

  onToggleDetect = (tzAutodetected) => {
    this.setState({
      tzAutodetected,
      selectedTimezone: this.props.defaultTimezone,
    })
    this.props.onSelected(null, null)
  }

  render() {
    const { homeTimezone, travelTimezone, closeSelect } = this.props
    const { selectedTimezone, tzAutodetected, searchedTimezones, searchText } =
      this.state

    return (
      <View style={styles.timezonesSelectContainer}>
        <ClickableRow
          primaryText={["Detect Automatically"]}
          actionButton={
            <ToggleButton
              onToggle={() => this.onToggleDetect}
              value={tzAutodetected}
            />
          }
          onPress={() => this.onToggleDetect(!tzAutodetected)}
          timeZoneModal={true}
        />
        {tzAutodetected && (
          <SelectedTimezoneRow
            timezone={selectedTimezone}
            isHomeTZ={selectedTimezone === homeTimezone}
            onSelected={this.props.onSelected}
            isTravelTZ={selectedTimezone === travelTimezone}
          />
        )}
        {!tzAutodetected && (
          <>
            <View style={styles.searchBarContainer}>
              <Text style={styles.searchBarHeader}>
                Set Display Time Zone Manually
              </Text>
              <SearchBar
                label={"Search Time Zone"}
                text={searchText}
                onChangeText={this._handleSearch}
                hideClearButtonOnEmptyField
                mode={"White"}
              />
            </View>
            <FlatList
              data={searchedTimezones}
              renderItem={this.renderItem}
              keyExtractor={this.keyExtractor}
              contentContainerStyle={{ height: 293 }}
            />
          </>
        )}
        <TouchableOpacity style={styles.timezoneConfirm} onPress={closeSelect}>
          OK
        </TouchableOpacity>
      </View>
    )
  }
}

export default TimezoneSelect

TimezoneSelect.propTypes = {
  onSelected: PropTypes.func.isRequired,
  selectedTimezone: PropTypes.string,
  tzAutodetected: PropTypes.bool,
  defaultTimezone: PropTypes.string,
  homeTimezone: PropTypes.string,
  travelTimezone: PropTypes.string,
}
