import React, { useState } from "react"
import { Image, View, Text, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"

import TabBar from "@ui/tabBar"
import { images } from "@utils/imageUtils"
import { AppColors } from "@theme/"
import { highlightTypes } from "@configs/enums"
import PinnedMessagesScreen from "../PinnedMessagesScreen"
import UserNudgesScreen from "../UserNudgesScreen"
import UserMentionsScreen from "../UserMentionsScreen"

const styles = {
  tabIcon: {
    height: 16,
    width: 16,
    tintColor: AppColors.brand.grey144,
  },
  activeTint: {
    tintColor: AppColors.brand.black2,
  },
  flex: {
    flex: 1,
  },
  header: {
    height: 50,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    flexGrow: 1,
    fontDamily: "greycliffcf-demibold-salt",
    color: "black",
    fontSize: 20,
    lineHeight: 25,
  },
  closeIcon: {
    width: 20,
    height: 20,
    marginRight: 20,
  },
}
// tintColor
const tabs = [
  {
    id: highlightTypes.pinboard,
    icon: <Image source={images.thumbtack} style={styles.tabIcon} />,
    activeIcon: (
      <Image
        source={images.thumbtack}
        style={[styles.tabIcon, styles.activeTint]}
      />
    ),
    title: "PINBOARD",
  },
  {
    id: highlightTypes.mentions,
    icon: <Image source={images.mentions} style={styles.tabIcon} />,
    activeIcon: (
      <Image
        source={images.mentions}
        style={[styles.tabIcon, styles.activeTint]}
      />
    ),
    title: "MENTIONS",
  },
  {
    id: highlightTypes.nudges,
    icon: <Image source={images.nudge} style={styles.tabIcon} />,
    activeIcon: (
      <Image
        source={images.nudge}
        style={[styles.tabIcon, styles.activeTint]}
      />
    ),
    title: "NUDGES",
  },
]

export default function HighlightsTabContainer({
  initialIndex = highlightTypes.pinboard,
  renderChatItem,
  close,
}) {
  const [index, setIndex] = useState(initialIndex)

  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.title}> Highlights</Text>
        <TouchableOpacity onPress={close}>
          <Image style={styles.closeIcon} source={images.closeGrey} />
        </TouchableOpacity>
      </View>
      <TabBar tabs={tabs} activeTab={index} onChange={setIndex} />

      {index === highlightTypes.pinboard && (
        <PinnedMessagesScreen
          disableBind={true}
          insideTabContainer={true}
          renderChatItem={renderChatItem}
        />
      )}
      {index === 1 && <UserMentionsScreen handleClose={() => {}} />}
      {index === 2 && <UserNudgesScreen />}
    </View>
  )
}

HighlightsTabContainer.options = {
  topBar: {
    title: {
      text: "Highlights",
    },
    leftButtons: [
      {
        id: "back",
        text: "back",
        icon: images.back,
        color: AppColors.brand.pink,
      },
    ],
  },
}

HighlightsTabContainer.propTypes = {
  initialIndex: PropTypes.number,
  renderChatItem: PropTypes.func,
}
