import { StyleSheet, Platform } from "react-native"
import { AppColors, AppFonts } from "@theme"
import * as defaultStyle from "../../../style"

const STYLESHEET_ID = "stylesheet.day.multiDot"
const FILLER_HEIGHT = 18

export default function styleConstructor(theme = {}) {
  const appStyle = { ...defaultStyle, ...theme }
  return StyleSheet.create({
    base: {
      width: 32,
      height: 32,
      alignItems: "center",
    },
    text: {
      marginTop: 4,
      ...AppFonts.smallestBold,
      color: appStyle.dayTextColor,
      backgroundColor: "rgba(255, 255, 255, 0)",
    },
    alignedText: {
      marginTop: Platform.OS === "android" ? 4 : 6,
    },
    selected: {
      backgroundColor: appStyle.selectedDayBackgroundColor,
      borderRadius: 6,
    },
    today: {
      backgroundColor: AppColors.brand.grey227,
      borderRadius: 6,
    },
    selectedToday: {
      backgroundColor: AppColors.brand.pinkLight3,
      borderRadius: 6,
    },
    selectedTodayText: {
      color: AppColors.brand.black2,
    },
    todayText: {
      color: AppColors.brand.black2,
    },
    selectedText: {
      color: appStyle.selectedDayTextColor,
    },
    disabledText: {
      color: appStyle.textDisabledColor,
    },
    dot: {
      width: 4,
      height: 4,
      marginTop: 1,
      marginLeft: 1,
      marginRight: 1,
      borderRadius: 2,
      opacity: 0,
    },
    visibleDot: {
      opacity: 1,
      backgroundColor: appStyle.dotColor,
    },
    selectedDot: {
      backgroundColor: appStyle.selectedDotColor,
    },
    nextMonthText: {
      color: AppColors.brand.grey157,
    },
    fillers: {
      position: "absolute",
      height: FILLER_HEIGHT,
      flexDirection: "row",
      left: 0,
      right: 0,
      justifyContent: "space-between",
    },
    leftFiller: {
      height: FILLER_HEIGHT,
      flex: 1,
    },
    rightFiller: {
      height: FILLER_HEIGHT,
      flex: 1,
    },
    ...(theme[STYLESHEET_ID] || {}),
  })
}
