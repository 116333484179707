import React, { useCallback, useState, useEffect } from "react"
import cn from "classnames"
import CodeInputNeo from "../../../../pages/VerifyCode/common/CodeInputNeo/CodeInputNeo"
import commonStyles from "../codeInputStyles"

import {
  recoveryRequestVerify,
  changeEmailVerificationVerify,
} from "@actions/loginActions"

const VerifyCode = ({
  type,
  userId,
  deleteUser,
  setEditMode,
  value,
  contanctVerification,
  contactMechanismVerificationId,
  accessToken,
  resendCodeFn,
  setPopup,
  onDoneHandler,
}) => {
  const [code, setCode] = useState("")
  const [counter, setCounter] = useState(60)
  const [error, setError] = useState("")

  const handleChangeCode = useCallback((val) => {
    setCode(val)
  })

  const key = type === "email" ? "email1" : "phoneNumber"

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  const handleVerifyCode = () => {
    contactMechanismVerificationId &&
      changeEmailVerificationVerify(
        code,
        contactMechanismVerificationId,
        4,
        accessToken
      )
        .then((res) => {
          setPopup(false)
          setEditMode(false)
          // createUserCM(2, emailValue)
          //   .then((res) => {
          //     console.log(res.data, 'CMMMMMM')
          //   })
          //   .catch((error) => {
          //     console.log('CM error', error)
          //   })
          // setVerified(true);
          // setData(response.data)
          //   if (response.data[key]) {
          //     setVerified(true);
          //     // console.log('token', response.data['token'])
          //   } else {
          //     setError('Please Enter Valid Verification Code:')
          //   }
        })
        .catch((error) => {
          console.log("error", error)
          setError("The code is invalid")
        })
  }

  const resendCode = (e) => {
    resendCodeFn(e)
    setCounter(60)
  }

  return (
    <div
      className="settings__connectionDetails_popup_wrapper"
      onClick={(e) => {
        e.stopPropagation()
        setPopup(false)
        deleteUser()
      }}
      style={{ flexDirection: "column" }}
    >
      <div
        className="settings__connectionDetails_popup"
        onClick={(e) => e.stopPropagation()}
        style={{
          width: 329,
          height: 401,
          paddingVertical: 0,
          paddingHorizontal: 35,
        }}
      >
        <span className="settings__connectionDetails_popup_heading_title">
          Verify Recovery {type == "email" ? "Email" : "Phone"}
        </span>
        <p
          className="forgot__subtitle__recovery"
          style={{ marginTop: 8, marginBottom: 16 }}
        >
          Enter the code we sent to to:
          <br />
          <span style={{ color: "#222222" }}>
            {value.slice(0, 1) +
              "x".repeat(value.length - 6) +
              value.replace(/ /g, "").slice(value.length - 5, value.length)}
          </span>
        </p>
        <div
          className="forgot__input--wrapper"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginBottom: 16,
          }}
        >
          <label
            htmlFor="input"
            className={cn("forgot__label_verifyModal", {
              "forgot__label--error": error != "",
            })}
          >
            {error != ""
              ? "Please Enter Valid Verification Code:"
              : "Verification Code:"}
          </label>
          <div style={{ position: "relative", marginTop: 4 }}>
            <CodeInputNeo
              errorMessage={error}
              customContainerStyle={commonStyles.inputMargin16}
              onChange={handleChangeCode}
            />
          </div>
        </div>
        <button
          disabled={code.length !== 6}
          className={cn("forgot__button", {
            "forgot__button--disabled": code.length !== 6,
          })}
          onClick={handleVerifyCode}
        >
          Verify
        </button>
        {/* <button
          disabled={counter > 0}
          className={cn("verify__resend verify__resend--recovery", {
            "verify__resend--disabled verify__resend--disabled--recovery ": counter > 0
          })}
          onClick={(e) => resendCode(e)}
        >
          { (counter > 0 ? `(${counter})` : '') + ' ' + 'Resend code' }
        </button> */}
        <button
          disabled={counter > 0}
          className={cn("verify__resend", {
            "verify__resend--disabled": counter > 0,
          })}
          onClick={resendCode}
        >
          {"Resend code " + (counter > 0 ? `(${counter})` : "")}
        </button>
        <span className="verify__text">
          We recommend checking your SPAM folder if <br /> you don’t receive it
        </span>
      </div>
    </div>
  )
}

export default VerifyCode
