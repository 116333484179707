import React from "react"
import { Image } from "react-native"
import { unsplashApi } from "../config/externalApi"
import { suggestionOnlineTypeOfLinks } from "@utils/gamePlanUtils"

const images = {
  tag: require("../assets/images/tag.svg"),
  goingNoBg: require("../assets/images/goingNoBg.png"),
  going: require("../assets/images/going.png"),
  notgoing: require("../assets/images/notGoing.png"),
  notgoingNoBg: require("../assets/images/notGoingNoBg.png"),
  mightgo: require("../assets/images/mightGo.png"),
  mightgoNoBg: require("../assets/images/mightGoNoBg.png"),
  onmyway: require("../assets/images/onMyWay.png"),
  onmywayNoBg: require("../assets/images/onMyWayNoBg.png"),
  arrived: require("../assets/images/arrived.png"),
  arrivedNoBg: require("../assets/images/arrivedNoBg.png"),
  attended: require("../assets/images/attended.png"),
  droppedout: require("../assets/images/droppedOut.png"),
  droppedoutNoBg: require("../assets/images/droppedOutNoBg.png"),
  runningLate: require("../assets/images/runningLate.png"),
  runningLateNoBg: require("../assets/images/runningLateNoBg.png"),
  backedOut: require("../assets/images/backedout.png"),
  backedOutNoBg: require("../assets/images/backedoutNoBg.png"),
  users: require("../assets/images/users.png"),
  archiveOutline: require("../assets/images/archiveOutline.png"),
  trashcanOutline: require("../assets/images/trashcanOutline.png"),
  comingUpOutline: require("../assets/images/comingUpOutline.png"),
  organizer: require("../assets/images/organizer.png"),
  alerts: require("../assets/images/alerts.png"),
  back: require("../assets/images/back.png"),
  close: require("../assets/images/close.svg"),
  checkWhiteIcon: require("../assets/images/checkWhiteIcon.png"),
  homeIcon: require("../assets/images/homeIcon.png"),
  calendarSettingsGear: require("../assets/images/calendarSettingsGear.png"),
  caretDown: require("../assets/images/caretDown.png"),
  calendarGoToday: require("../assets/images/calendarGoToday.png"),
  selectedPink: require("../assets/images/selectedPink.png"),
  calendarComingUp: require("../assets/images/calendarComingUp.png"),
  calendarDay: require("../assets/images/calendarDay.png"),
  calendarMonth: require("../assets/images/calendarMonth.png"),
  calendarWeek: require("../assets/images/calendarWeek.png"),
  calendarAlert: require("../assets/images/calendar_alert.png"),
  calendarOk: require("../assets/images/calendar_ok.png"),
  bubbleWithClock: require("../assets/images/bubbleWithClock.png"),
  teeupSmall: require("../assets/images/teeupSmall.png"),
  // arrow: require('../assets/images/arrow.png'),
  arrow: require("../assets/images/arrowSVG.svg"),
  joinedBig: require("../assets/images/joinedBig.png"),
  snowflake: require("../assets/images/snowflake.png"),
  envelope: require("../assets/images/envelope.png"),
  arrowRight: require("../assets/images/arrowRight.png"),
  question: require("../assets/images/question.png"),
  times: require("../assets/images/times.png"),
  rocket: require("../assets/images/rocket.png"),
  flagCheckered: require("../assets/images/flagCheckered.png"),
  runningLateOrange: require("../assets/images/runningLateOrange.png"),
  flag: require("../assets/images/flag.png"),
  backedoutRed: require("../assets/images/backedoutRed.png"),
  orgAndPerm: require("../assets/images/orgAndPerm.png"),
  percentBarIcon: require("../assets/images/percentBarIcon.png"),
  grayArrow: require("../assets/images/grayArrow.png"),
  invited: require("../assets/images/invited.png"),
  invitedNoBg: require("../assets/images/invitedNoBg.png"),
  systemStatusGoing: require("../assets/images/systemStatusGoing.png"),
  systemStatusArrived: require("../assets/images/systemStatusArrived.png"),
  systemStatusBackedOut: require("../assets/images/systemStatusBackedOut.png"),
  systemStatusMightGo: require("../assets/images/systemStatusMightGo.png"),
  systemStatusNotGoing: require("../assets/images/systemStatusNotGoing.png"),
  systemStatusOnMyWay: require("../assets/images/systemStatusOnMyWay.png"),
  systemStatusRunningLate: require("../assets/images/systemStatusRunningLate.png"),
  expandedMessage2: require("../assets/images/expandedMessage2.png"),
  collapsedMessage2: require("../assets/images/collapsedMessage2.png"),
  closeDialog: require("../assets/images/closeDialog.png"),
  nudgePeople: require("../assets/images/nudgePeople.png"),
  mention: require("../assets/images/mention.png"),
  send: require("../assets/images/send.svg"),
  chatMedia: require("../assets/images/chatMedia.png"),
  chatDocument: require("../assets/images/chatDocument.png"),
  closeGrey: require("../assets/images/close-grey.png"),
  briningOthers: require("../assets/images/iconBringing.png"),
  whenList: require("../assets/images/list.png"),
  cancel: require("../assets/images/cancel.png"),
  thumbtack: require("../assets/images/thumbtack.png"),
  nudge: require("../assets/images/nudge.png"),
  mentions: require("../assets/images/mentions.png"),
  mentionsPlaceholder: require("../assets/images/mentionsPlaceholder.png"),
  pinboardPlaceholder: require("../assets/images/pinboardPlaceholder.png"),
  nudgesPlaceholder: require("../assets/images/nudgesPlaceholder.png"),
  everyoneAvatar: require("../assets/images/everyoneAvatar.png"),
  // chevron: require('../assets/images/angle-right.svg'),
  chevron: require("../assets/images/chevronGray.svg"),
  magicWand: require("../assets/images/magicWand.png"),
  decrement: require("../assets/images/decrement.png"),
  increment: require("../assets/images/increment.png"),

  // nudges user statuses without bg
  goingWithoutBG: require("../assets/images/goingWithoutBG.png"),
  notGoingWithoutBG: require("../assets/images/notGoingWithoutBG.png"),
  mighgoWithoutBG: require("../assets/images/mighgoWithoutBG.png"),
  onmywayWithoutBG: require("../assets/images/onmywayWithoutBG.png"),
  arrivedWithoutBG: require("../assets/images/arrivedWithoutBG.png"),
  runningLateWithoutBG: require("../assets/images/runningLateWithoutBG.png"),
  backedoutWithoutBG: require("../assets/images/backedoutWithoutBG.png"),

  // Empty List Placeholders
  noArchivedTeeups: require("../assets/images/emptyListPlaceholders/noArchivedTeeups.png"),
  noScheduledTeeups: require("../assets/images/emptyListPlaceholders/noScheduledTeeup.png"),
  noTeeupsInSearch: require("../assets/images/emptyListPlaceholders/noTeeupsInSearch.png"),
  noTeeupsInTrash: require("../assets/images/emptyListPlaceholders/noTeeupsInTrash.png"),

  // File icons
  fileIcons: {
    unknown: require("../assets/images/fileIcons/undefined.png"),
    pdf: require("../assets/images/fileIcons/pdf.png"),
    archive: require("../assets/images/fileIcons/archive.png"),
    audio: require("../assets/images/fileIcons/audio.png"),
    video: require("../assets/images/fileIcons/video.png"),
    code: require("../assets/images/fileIcons/code.png"),
    csv: require("../assets/images/fileIcons/csv.png"),
    excel: require("../assets/images/fileIcons/excel.png"),
    txt: require("../assets/images/fileIcons/txt.png"),
    word: require("../assets/images/fileIcons/word.png"),
    powerpoint: require("../assets/images/fileIcons/powerpoint.png"),
  },
  //enabled/disbled social icons
  socialIcons: [
    {
      name: suggestionOnlineTypeOfLinks.zoom,
      enabled: require("../assets/images/socialIcons/icon_zoom_enabled/icon_zoom_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_zoom_disabled/icon_zoom_disabled.png"),
      // color: AppColors.brand.dodgerBlue,
    },
    {
      name: suggestionOnlineTypeOfLinks.skype,
      enabled: require("../assets/images/socialIcons/icon_skype_enabled/icon_skype_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_skype_disabled/icon_skype_disabled.png"),
      // color: AppColors.brand.navyBlue,
    },
    {
      name: suggestionOnlineTypeOfLinks.googleHangouts,
      enabled: require("../assets/images/socialIcons/icon_google_enabled/icon_google_hangouts_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_google_disabled/icon_google_disabled.png"),
      // color: AppColors.brand.elfGreen,
    },
    {
      name: suggestionOnlineTypeOfLinks.slack,
      enabled: require("../assets/images/socialIcons/icon_slack_enabled/icon_slack_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_slack_disabled/icon_slack_disabled.png"),
      // color: AppColors.brand.jungleGreen,
    },
    {
      name: suggestionOnlineTypeOfLinks.microsoftTeams,
      enabled: require("../assets/images/socialIcons/icon_microsoft_teams_enabled/icon_microsoft_teams_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_microsoft_teams_disabled/icon_microsoft_teams_disabled.png"),
      // color: AppColors.brand.richBlue,
    },
    {
      name: suggestionOnlineTypeOfLinks.facebookMessengerRoom,
      enabled: require("../assets/images/socialIcons/icon_facebook_messenger_enabled/icon_facebook_messenger_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_facebook_messenger_disabled/icon_facebook_messenger_disabled.png"),
      // color: AppColors.brand.neonBlue,
    },
    {
      name: suggestionOnlineTypeOfLinks.goToMeeting,
      enabled: require("../assets/images/socialIcons/icon_gotomeeting_enabled/icon_gotomeeting_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_gotomeeting_disabled/icon_gotomeeting_disabled.png"),
      // color: AppColors.brand.yellowSea,
    },
    {
      name: suggestionOnlineTypeOfLinks.webEx,
      enabled: require("../assets/images/socialIcons/icon_webex_enabled/icon_webex_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_webex_disabled/icon_webex_disabled.png"),
      // color: AppColors.brand.deepSkyBlue,
    },
    {
      name: suggestionOnlineTypeOfLinks.discord,
      enabled: require("../assets/images/socialIcons/icon_discord_enabled/icon_discord_enabled.png"),
      disabled: require("../assets/images/socialIcons/icon_discord_disabled/icon_discord_disabled.png"),
      // color: AppColors.brand.jordyBlue,
    },
  ],
  //empty suggestion list
  emptyDecidedWhereSuggestionPlaceholder: require("../assets/images/emptyDecidedWhereSuggestionPlaceholder.png"),
  emptyDecidedWhenSuggestionPlaceholder: require("../assets/images/emptyDecidedWhenSuggestionPlaceholder.png"),
  emptyWhereSuggestionPlaceholder: require("../assets/images/emptyWhereSuggestionPlaceholder.png"),
  emptyWhenSuggestionPlaceholder: require("../assets/images/emptyWhenSuggestionPlaceholder.png"),

  meCalendar: require("../assets/images/meCalendar.png"),
  addressBook: require("../assets/images/addressBook.png"),
  earth: require("../assets/images/earth.svg"),
  signIn: require("../assets/images/signIn.png"),
  syncContactsSpinner: require("../assets/images/syncContactsSpinner.png"),
  notificationSettings: require("../assets/images/notificationSettings.png"),
  policiesAndServices: require("../assets/images/policiesAndServices.png"),
  bugReporting: require("../assets/images/bugReporting.png"),
  participantSettings: require("../assets/images/participantSettings.png"),
  editProfileImage: require("../assets/images/editProfileImage.png"),
  editProfilePhone: require("../assets/images/editProfilePhone.png"),
  editProfileLink: require("../assets/images/editProfileLink.png"),
  editProfileEmail: require("../assets/images/editProfileEmail.png"),
  editProfileAddress: require("../assets/images/editProfileAddress.png"),
  plusWithRoundBorder: require("../assets/images/plusWithRoundBorder.png"),
  trashWhiteIcon: require("../assets/images/trashWhiteIcon.png"),
  googleLogo: require("../assets/images/googleLogo.png"),
  twitterLogo: require("../assets/images/twitter.png"),
  facebookLogoReverse: require("../assets/images/facebookLogo.png"),
  deactivateAccount: require("../assets/images/deactivateAccount.png"),
  logout: require("../assets/images/logout.png"),
  changePassword: require("../assets/images/changePassword.png"),
  pinkAddPlusWithBg: require("../assets/images/pinkAddPlusWithBg.png"),
  plusCircle: require("../assets/images/plusCircle.png"),
  checkWhiteIcon: require("../assets/images/checkWhite.png"),
  removeItem: require("../assets/images/delete.png"),
  logoWhite: require("../assets/images/logoWhite.svg"),
  invitationVerified: require("../assets/images/invitationVerified.svg"),
  exclamation: require("../assets/images/exclamation-mark.svg"),
  suggestionIconDecided: require("../assets/images/suggestionIconDecided.png"),
  successCreate: require("../assets/images/successCreate.svg"),
  failed: require("../assets/images/failed.svg"),
  edit: require("../assets/images/edit.svg"),
  more: require("../assets/images/more.png"),
  inviteButton: require("../assets/images/invite.png"),
  connect: require("../assets/images/connect.png"),
  connectDisabled: require("../assets/images/connectDisabled.png"),
  connectPending: require("../assets/images/connectPending.png"),

  // Contact Methods settings
  cmVerified: require("../assets/images/cmVerified.png"),
  cmPending: require("../assets/images/cmPending.png"),
  cmUnverified: require("../assets/images/cmUnverified.png"),
  tzEmptyIconHome: require("../assets/images/tzEmptyIconHome.png"),
  tzEmptyIconActive: require("../assets/images/tzEmptyIconActive.png"),
  timesGray: require("../assets/images/timesGray.png"),
  searchGray: require("../assets/images/searchGray.png"),
  pencil: require("../assets/images/pencil.png"),

  // New design
  verse: require("../assets/images/verse.svg"),
  messages: require("../assets/images/messages.svg"),
  teeup: require("../assets/images/teeup.svg"),
  filter: require("../assets/images/filter.svg"),
  search: require("../assets/images/searchIcon.svg"),
  searchWhite: require("../assets/images/searchIconWhite.svg"),
  bell: require("../assets/images/bellIcon.svg"),
  calendar: require("../assets/images/calendarIcon.svg"),
  contacts: require("../assets/images/contacts.svg"),
  settings: require("../assets/images/settingsIcon.svg"),
  crown: require("../assets/images/newDesign/ndNewCrown.svg"),
  people: require("../assets/images/people.svg"),
  invite: require("../assets/images/invite.svg"),
  cross: require("../assets/images/crossGray.svg"),
  pinkInvite: require("../assets/images/pinkInvite.svg"),
  teeupsSort: require("../assets/images/teeupsSort.svg"),
  user: require("../assets/images/user.svg"),
  nudgesNew: require("../assets/images/nudgesNew.svg"),
  mentionsNew: require("../assets/images/mentionsNew.svg"),
  thumbtackNew: require("../assets/images/thumbtackNew.svg"),
  conversationNew: require("../assets/images/conversationNew.svg"),
  openAttachments: require("../assets/images/openAttchments.svg"),
  mentionConversation: require("../assets/images/mentionConversation.svg"),
  suggestLamp: require("../assets/images/suggestLamp.svg"),
  suggestClock: require("../assets/images/suggestClock.svg"),
  suggestMarker: require("../assets/images/suggestMarker.svg"),
  crossCircle: require("../assets/images/crossCircle.svg"),
  inviteTeeup: require("../assets/images/inviteTeeup.svg"),
  tagBlack: require("../assets/images/tagBlack.svg"),
  message: require("../assets/images/message.svg"),
  threeDotsIconSuggestion: require("../assets/images/threeDotsSuggestionIconn.svg"),
  convert: require("../assets/images/convert.svg"),
  iconTrash: require("../assets/images/iconTrash.svg"),
  iconArchive: require("../assets/images/iconArchive.svg"),
  iconActiveTeeupAction: require("../assets/images/iconActiveTeeupAction.svg"),
  moveToGameplanNew: require("../assets/images/moveToGameplanNew.svg"),
  pinMessageNew: require("../assets/images/pinMessageNew.svg"),
  nudgePeopleNew: require("../assets/images/nudgePeopleNew.svg"),
  replyMessageNew: require("../assets/images/replyMessageNew.svg"),
  removeFromGameplanNew: require("../assets/images/removeFromGameplanNew.svg"),
  restoreNew: require("../assets/images/restoreNew.svg"),
  unpinMessageNew: require("../assets/images/unpinMessageNew.svg"),
  copyNew: require("../assets/images/copyNew.svg"),
  detailCardOpenArrow: require("../assets/images/detailGroupHeaderOpenedChevron.svg"),
  detailCardCloseArrow: require("../assets/images/detailGroupHeaderClosedChevron.svg"),
  clock: require("../assets/images/clock.svg"),
  location: require("../assets/images/location.svg"),
  dots: require("../assets/images/dots.png"),
  closeSettings: require("../assets/images/closeSettings.svg"),
  arrowsOptions: require("../assets/images/arrowsOptions.svg"),
  addCircle: require("../assets/images/addDetail.png"),
  help: require("../assets/images/help.svg"),
  inbox: require("../assets/images/inbox.svg"),
  calendarSmallIcon: require("../assets/images/calendarSmallIcon.svg"),
  filterIcon: require("../assets/images/filterIcon.svg"),
  card: require("../assets/images/card.svg"),
  listView: require("../assets/images/listView.svg"),
  iconClockGamePlan: require("../assets/images/iconClockGamePlan.svg"),
  iconPinGamePlan: require("../assets/images/iconPinGamePlan.svg"),
  contactMenuIcon: require("../assets/images/contactMenuIcon.svg"),
  key: require("../assets/images/key.svg"),
  connection: require("../assets/images/connection.svg"),
  iconSortSelected: require("../assets/images/iconSortSelected.svg"),
  envelopSettings: require("../assets/images/envelopSettings.svg"),
  facebookSettings: require("../assets/images/facebookSettings.svg"),
  googleSettings: require("../assets/images/googleSettings.svg"),
  twitterSettings: require("../assets/images/twitterSettings.svg"),
  calendarSVG: require("../assets/images/CalendarSVG.svg"),
  notificationsSVG: require("../assets/images/notificationsSVG.svg"),
  policiesAndServicesSVG: require("../assets/images/policiesAndServicesSVG.svg"),
  linkedContactMethod: require("../assets/images/linkedContactMethod.svg"),
  accountManagement: require("../assets/images/accountManagement.svg"),
  profileSets: require("../assets/images/profileSets.svg"),
  timeZonesSettings: require("../assets/images/timeZonesSettings.svg"),
  activeTimeZone: require("../assets/images/ActiveTimeZone.svg"),
  crossSettings: require("../assets/images/cross.svg"),
  InvitationsSentSVG: require("../assets/images/InvitationsSentSVG.svg"),
  helpPopupDetails: require("../assets/images/helpPopupDetails.svg"),
  redPlus: require("../assets/images/redPlus.svg"),
  questionIcon: require("../assets/images/questionIcon.svg"),
  showPasswordImg: require("../assets/images/showPasswordImg.svg"),
  exclamationSign: require("../assets/images/exclamationSign.svg"),
  cooeModal: require("../assets/images/cooeModal.svg"),
  passwordChangeSuccess: require("../assets/images/passwordChangeSuccess.svg"),

  inviteToTeeupPink: require("../assets/images/inviteToTeeupPink.svg"),
  sendMessagePink: require("../assets/images/sendMessagePink.svg"),
  tagIconPink: require("../assets/images/tagIconPink.svg"),
  PurplePencilSVG: require("../assets/images/PurplePencilSVG.svg"),
  disabledCalendar: require("../assets/images/disabledCalendar.svg"),
  activatedCalendar: require("../assets/images/activatedCalendar.svg"),
  syncCalendar: require("../assets/images/syncCalendar.svg"),
  calendarPopupOne: require("../assets/images/calendarPopupOne.png"),
  calendarPopupTwo: require("../assets/images/calendarPopupTwo.png"),
  logoCooe: require("../assets/images/logoCooe.svg"),
  boomerang: require("../assets/images/boomerang.svg"),
  desert_bg: require("../assets/images/desert3_bg.svg"),
  detailsIcon: require("../assets/images/detailsIcon.svg"),
  NoTeeupsMatching: require("../assets/images/NoTeeupsMatching.svg"),
  partipicants: require("../assets/images/partipicants.svg"),
  mute: require("../assets/images/mute.svg"),
  expandCalendarArrow: require("../assets/images/expandCalendarArrow.svg"),

  phoneFrame: require("../assets/images/phoneFrame.png"),
  closePhoneFrame: require("../assets/images/closePhoneFrame.png"),
  locationArrow: require("../assets/images/locationArrow.svg"),
  whiteCircleCross: require("../assets/images/whiteCircleCross.svg"),
  suggestionPopupDetails: require("../assets/images/suggestionPopupDetails.svg"),
  suggestionPopupComments: require("../assets/images/suggestionPopupComments.svg"),
  suggestionPopupReactions: require("../assets/images/suggestionPopupReactions.svg"),
  lock: require("../assets/images/lock.svg"),
  unlocked: require("../assets/images/unlocked.svg"),
  manageParticipants: require("../assets/images/manageParticipants.svg"),
  trashcanSVG: require("../assets/images/trashcanSVG.svg"),
  expandStartMessageArrow: require("../assets/images/expandStartMessageArrow.svg"),

  newCreateTeeupPlus: require("../assets/images/newCreateTeeupPlus.svg"),
  newCreateTeeupCalendar: require("../assets/images/newCreateTeeupCalendar.svg"),
  newCreateArchived: require("../assets/images/newCreateArchived.svg"),
  newCreateTrash: require("../assets/images/newCreateTrash.svg"),
  newCreateInbox: require("../assets/images/newCreateInbox.svg"),
  inviteToTeeupsFromList: require("../assets/images/inviteToTeeupsFromList.svg"),

  // preview

  appStore: require("../assets/images/preview/appStore.svg"),
  googlePlay: require("../assets/images/preview/googlePlay.png"),
  iconWherePreview: require("../assets/images/preview/iconWhere.svg"),
  iconWhenPreview: require("../assets/images/preview/iconWhen.svg"),
  iconPeoplePreview: require("../assets/images/peoplePreview.svg"),
  logoPreview: require("../assets/images/preview/logo.svg"),
  logoPreviewGoogle: require("../assets/images/preview/logoGoogle.svg"),
  logoPreviewApple: require("../assets/images/preview/logoApple.svg"),
  logoPreviewTwitter: require("../assets/images/preview/twitter.svg"),
  logoPreviewFacebook: require("../assets/images/preview/logoFacebook.svg"),

  //new design

  ndTeeup: require("../assets/images/newDesign/ndTeeup.svg"),
  ndCalendar: require("../assets/images/newDesign/ndCalendar.svg"),
  ndCalendarActive: require("../assets/images/newDesign/ndCalendarActive.svg"),
  ndConnections: require("../assets/images/newDesign/ndConnections.svg"),
  ndLogo: require("../assets/images/newDesign/ndLogo.svg"),
  ndHelp: require("../assets/images/newDesign/ndHelp.svg"),
  ndHelpActive: require("../assets/images/newDesign/ndHelpActive.svg"),
  ndCreateTeeup: require("../assets/images/newDesign/ndCreateTeeup.svg"),
  ndInboxActive: require("../assets/images/newDesign/ndInboxActive.svg"),
  ndInbox: require("../assets/images/newDesign/ndInbox.svg"),
  ndArchive: require("../assets/images/newDesign/ndArchive.svg"),
  ndArchiveActive: require("../assets/images/newDesign/ndArchiveActive.svg"),
  ndTrash: require("../assets/images/newDesign/ndTrash.svg"),
  ndTrashActive: require("../assets/images/newDesign/ndTrashActive.svg"),
  ndCalendarMonth: require("../assets/images/newDesign/ndCalendarMonth.svg"),
  ndCalendarMonthActive: require("../assets/images/newDesign/ndCalendarMonthActive.svg"),
  ndCalendarWeek: require("../assets/images/newDesign/ndCalendarWeek.svg"),
  ndCalendarWeekActive: require("../assets/images/newDesign/ndCalendarWeekActive.svg"),
  ndCalendarDay: require("../assets/images/newDesign/ndCalendarDay.svg"),
  ndCalendarDayActive: require("../assets/images/newDesign/ndCalendarDayActive.svg"),
  ndComingUp: require("../assets/images/newDesign/ndComingUp.svg"),

  ndEmptyPinBoardImage: require("../assets/images/newDesign/ndEmptyPinBoardImage.svg"),
  ndEmptyMentionsImage: require("../assets/images/newDesign/ndEmptyMentionsImage.svg"),
  ndSortIcon: require("../assets/images/newDesign/icons/ndSortIcon.svg"),
  ndFilterIcon: require("../assets/images/newDesign/icons/ndFilterIcon.svg"),
  ndCardIcon: require("../assets/images/newDesign/icons/ndCardIcon.svg"),
  ndCloseIcon: require("../assets/images/newDesign/icons/ndCloseIcon.svg"),
  ndPlusUserIcon: require("../assets/images/newDesign/icons/ndPlusUserIcon.svg"),
  ndFlagIcon: require("../assets/images/newDesign/icons/ndFlagIcon.svg"),
  ndXIcon: require("../assets/images/newDesign/icons/ndXIcon.svg"),
  ndQuestionMarkIcon: require("../assets/images/newDesign/icons/ndQuestionMarkIcon.svg"),
  ndRocketIcon: require("../assets/images/newDesign/icons/ndRocketIcon.svg"),
  ndRunningManIcon: require("../assets/images/newDesign/icons/ndRunningManIcon.svg"),
  ndArrowIcon: require("../assets/images/newDesign/icons/ndArrowIcon.svg"),
  ndArrowIconWhite: require("../assets/images/newDesign/icons/ndArrowIconWhite.png"),
  ndEnvelopIcon: require("../assets/images/newDesign/icons/ndEnvelopIcon.svg"),
  ndDropdownArrowIcon: require("../assets/images/newDesign/icons/ndDropdownArrowIcon.svg"),
  ndCheckmarkCircleIcon: require("../assets/images/newDesign/icons/ndCheckmarkCircleIcon.svg"),
  ndCollapseButtonIcon: require("../assets/images/newDesign/icons/ndCollapseButtonIcon.svg"),
  ndClockIcon: require("../assets/images/newDesign/icons/ndClockIcon.svg"),
  ndLocationPinIcon: require("../assets/images/newDesign/icons/ndLocationPinIcon.svg"),
  ndLightBulbWithPlusIcon: require("../assets/images/newDesign/icons/ndLightBulbWithPlusIcon.svg"),
  ndBulletListIcon: require("../assets/images/newDesign/icons/ndBulletListIcon.svg"),
  ndThumbUpIcon: require("../assets/images/newDesign/icons/ndThumbUpIcon.svg"),
  ndThumbDownIcon: require("../assets/images/newDesign/icons/ndThumbDownIcon.svg"),
  ndCloseXIcon: require("../assets/images/newDesign/icons/ndCloseXIcon.svg"),
  ndClearClockIcon: require("../assets/images/newDesign/icons/ndClearClockIcon.svg"),
  ndClearLocationPinIcon: require("../assets/images/newDesign/icons/ndClearLocationPinIcon.svg"),
  ndThumbUpYellowIcon: require("../assets/images/newDesign/icons/ndThumbUpYellowIcon.svg"),
  ndThumbDownYellowIcon: require("../assets/images/newDesign/icons/ndThumbDownYellowIcon.svg"),
  ndConversationIcon: require("../assets/images/newDesign/icons/ndConversationIcon.svg"),
  ndPinIcon: require("../assets/images/newDesign/icons/ndPinIcon.svg"),
  ndMentionIcon: require("../assets/images/newDesign/icons/ndMentionIcon.svg"),
  ndNudgesIcon: require("../assets/images/newDesign/icons/ndNudgesIcon.svg"),
  ndPaperClipIcon: require("../assets/images/newDesign/icons/ndPaperClipIcon.svg"),
  ndPaperPlaneIcon: require("../assets/images/newDesign/icons/ndPaperPlaneIcon.svg"),
  ndUndefinedFileTypeIcon: require("../assets/images/newDesign/icons/ndUndefinedFileTypeIcon.svg"),
  ndFileTypeWordIcon: require("../assets/images/newDesign/icons/ndFileTypeWordIcon.svg"),
  ndFileTypePDFIcon: require("../assets/images/newDesign/icons/ndFileTypePDFIcon.svg"),
  ndFileTypePowerpointIcon: require("../assets/images/newDesign/icons/ndFileTypePowerpointIcon.svg"),
  ndFileTypeExcelIcon: require("../assets/images/newDesign/icons/ndFileTypeExcelIcon.svg"),
  ndFileTypeArchiveIcon: require("../assets/images/newDesign/icons/ndFileTypeArchiveIcon.svg"),
  ndFileTypeCSVIcon: require("../assets/images/newDesign/icons/ndFileTypeCSVIcon.svg"),
  ndFileTypeAudioIcon: require("../assets/images/newDesign/icons/ndFileTypeAudioIcon.svg"),
  ndFileTypeVideoIcon: require("../assets/images/newDesign/icons/ndFileTypeVideoIcon.svg"),
  ndFileTypeTextIcon: require("../assets/images/newDesign/icons/ndFileTypeTextIcon.svg"),
  ndFileTypeCodeIcon: require("../assets/images/newDesign/icons/ndFileTypeCodeIcon.svg"),
  ndCloseFilledIcon: require("../assets/images/newDesign/icons/ndCloseFilledIcon.svg"),
  ndArrowLeftIcon: require("../assets/images/newDesign/icons/ndArrowLeftIcon.svg"),
  ndArrowRightIcon: require("../assets/images/newDesign/icons/ndArrowRightIcon.svg"),
  ndThreeDotsIcon: require("../assets/images/newDesign/icons/ndThreeDotsIcon.svg"),
  ndGlobeAmericasIcon: require("../assets/images/newDesign/icons/ndGlobeAmericasIcon.svg"),
  ndCheckmarkCheckbox: require("../assets/images/newDesign/ndCheckmarkCheckbox.svg"),
  ndComingUpActive: require("../assets/images/newDesign/ndComingUpActive.svg"),
  ndContacts: require("../assets/images/newDesign/ndContacts.svg"),
  ndContactsActive: require("../assets/images/newDesign/ndContactsActive.svg"),
  ndCooWeConnections: require("../assets/images/newDesign/ndCooWeConnections.svg"),
  ndCooWeConnectionsActive: require("../assets/images/newDesign/ndCooWeConnectionsActive.svg"),
  ndGroup: require("../assets/images/newDesign/ndGroup.svg"),
  ndGroupActive: require("../assets/images/newDesign/ndGroupActive.svg"),
  ndDMS: require("../assets/images/newDesign/ndDMS.svg"),
  ndDMSActive: require("../assets/images/newDesign/ndDMSActive.svg"),
  ndMeetWithMe: require("../assets/images/newDesign/ndMeetWithMe.svg"),
  ndSettings: require("../assets/images/newDesign/ndSettings.svg"),
  ndCreateTeeupSettings: require("../assets/images/newDesign/ndCreateTeeupSettings.svg"),
  ndCreateTeeupPhoto: require("../assets/images/newDesign/ndCreateTeeupPhoto.svg"),
  ndCreateTeeupInviteLink: require("../assets/images/newDesign/ndCreateTeeupInviteLink.svg"),
  ndCreateTeeupInvite: require("../assets/images/newDesign/ndCreateTeeupInvite.svg"),
  ndGamePlanWhen: require("../assets/images/newDesign/ndGamePlanWhen.svg"),
  ndGamePlanWhere: require("../assets/images/newDesign/ndGamePlanWhere.svg"),
  ndGamePlanSuggest: require("../assets/images/newDesign/ndGamePlanSuggest.svg"),
  ndNameStar: require("../assets/images/newDesign/ndNameStar.svg"),
  ndCrown: require("../assets/images/newDesign/ndCrown.svg"),
  ndGamePlanSuggestActive: require("../assets/images/newDesign/ndGamePlanSuggestActive.svg"),
  ndCreateTeeupInviteActive: require("../assets/images/newDesign/ndCreateTeeupInviteActive.svg"),
  ndInviteDownArrow: require("../assets/images/newDesign/ndInviteDownArrow.svg"),
  ndInviteesCross: require("../assets/images/newDesign/ndInviteesCross.svg"),
  ndInviteesCrossActive: require("../assets/images/newDesign/ndInviteesCrossActive.svg"),
  ndSearch: require("../assets/images/newDesign/ndSearch.svg"),
  ndTags: require("../assets/images/newDesign/ndTags.svg"),
  ndSort: require("../assets/images/newDesign/ndSort.svg"),
  ndCheckboxActive: require("../assets/images/newDesign/ndCheckboxActive.svg"),
  ndIsCooWeUser: require("../assets/images/newDesign/ndIsCooWeUser.svg"),
  ndClearFilters: require("../assets/images/newDesign/ndClearFilters.svg"),
  ndMinimizeFilters: require("../assets/images/newDesign/ndMinimizeFilters.svg"),
  ndInviteBlank: require("../assets/images/newDesign/ndInviteBlank.svg"),
  ndInviteBlankActive: require("../assets/images/newDesign/ndInviteBlankActive.svg"),
  ndInviteTag: require("../assets/images/newDesign/ndInviteTag.svg"),
  ndInviteTagActive: require("../assets/images/newDesign/ndInviteTagActive.svg"),
  ndInviteSelect: require("../assets/images/newDesign/ndInviteSelect.svg"),
  ndInviteSelectActive: require("../assets/images/newDesign/ndInviteSelectActive.svg"),
  ndInviteSelected: require("../assets/images/newDesign/ndInviteSelected.svg"),
  ndInviteSelectedActive: require("../assets/images/newDesign/ndInviteSelectedActive.svg"),
  ndInviteToTeeUp: require("../assets/images/newDesign/ndInviteToTeeUp.png"),
  ndInvitationsSent: require("../assets/images/newDesign/ndInvitationsSent.svg"),
  ndTeeUpPeopleMainButton: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpPeopleMainButton.svg"),
  ndTeeUpPeopleCount: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpPeopleCount.svg"),
  ndTeeUpPeopleCountArrow: require("../assets/images/newDesign/TeeUpRightSide/ndTeUpPeopleCountArrow.svg"),
  ndShareTeeUp: require("../assets/images/newDesign/TeeUpRightSide/ndShareTeeUp.svg"),
  ndShareTeeUpActive: require("../assets/images/newDesign/TeeUpRightSide/ndShareTeeUpActive.svg"),
  ndInviteToCurTeeUp: require("../assets/images/newDesign/TeeUpRightSide/ndInviteToCurTeeUp.svg"),
  ndInviteToCurTeeUpActive: require("../assets/images/newDesign/TeeUpRightSide/ndInviteToCurTeeUpActive.svg"),
  ndTeeUpThreeDots: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpThreeDots.svg"),
  ndTeeUpThreeDotsActive: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpThreeDotsActive.svg"),
  ndTeeUpDropDownManage: require("../assets/images/newDesign/TeeUpRightSide/ndPeopleDropDownManage.svg"),
  ndTeeUpDropDownManageActive: require("../assets/images/newDesign/TeeUpRightSide/ndPeopleDropDownManageActive.svg"),
  ndTeeUpDropDownNudge: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpDropDownNudge.svg"),
  ndTeeUpDropDownNudgeActive: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpDropDownNudgeActive.svg"),
  ndTeeUpDropDownTagUser: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpTagUser.svg"),
  ndTeeUpDropDownTagUserActive: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpTagUserActive.svg"),
  ndJoinedStatus: require("../assets/images/newDesign/TeeUpRightSide/ndJoinedStatus.png"),
  ndViewProfile: require("../assets/images/newDesign/TeeUpRightSide/ndViewProfile.svg"),
  ndViewProfileActive: require("../assets/images/newDesign/TeeUpRightSide/ndViewProfileActive.svg"),
  ndDeleteUser: require("../assets/images/newDesign/TeeUpRightSide/ndUserAcross.svg"),
  ndDeleteUserActive: require("../assets/images/newDesign/TeeUpRightSide/ndUserAcrossActive.svg"),
  ndDemoteOrganizer: require("../assets/images/newDesign/TeeUpRightSide/ndDemoteOrganizer.svg"),
  ndDemoteOrganizerActive: require("../assets/images/newDesign/TeeUpRightSide/ndDemoteOrganizerActive.svg"),
  ndRecentInvitation: require("../assets/images/newDesign/TeeUpRightSide/ndRecentInvitation.svg"),
  ndRecentInvitationActive: require("../assets/images/newDesign/TeeUpRightSide/ndRecentInvitationActive.svg"),
  ndMakeAnOrganizer: require("../assets/images/newDesign/TeeUpRightSide/ndMakeAnOrganizer.svg"),
  ndMakeAnOrganizerActive: require("../assets/images/newDesign/TeeUpRightSide/ndMakeAnOrganizerActive.svg"),
  ndTeeUpSelected: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpSelected.png"),
  ndTeeUpNotSelected: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpNotSelected.png"),
  ndTeeUpSelectEverybody: require("../assets/images/newDesign/TeeUpRightSide/ndTeeUpSelectEverybody.svg"),
  ndProfileAddContact: require("../assets/images/newDesign/ndProfileAddContact.svg"),
  ndProfileEditTags: require("../assets/images/newDesign/ndProfileEditTags.svg"),
  ndProfilePublicInfoPhone: require("../assets/images/newDesign/ndProfilePublicInfoPhone.png"),
  ndProfilePublicInfoEmail: require("../assets/images/newDesign/ndProfilePublicInfoEmail.svg"),
  ndProfileInviteUser: require("../assets/images/newDesign/ndProfileInviteUser.svg"),
  ndProfileTagUser: require("../assets/images/newDesign/ndProfileTagUser.svg"),
  ndProfileBlockUser: require("../assets/images/newDesign/ndBlockUser.svg"),
  ndSuggestDiscord: require("../assets/images/newDesign/SuggestOnline/ndSuggestDiscord.svg"),
  ndSuggestDiscordInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestDiscordInactive.svg"),
  ndSuggestDiscordIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestDiscordIcon.svg"),
  ndSuggestFacebook: require("../assets/images/newDesign/SuggestOnline/ndSuggestFacebook.svg"),
  ndSuggestFacebookInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestFacebookInactive.svg"),
  ndSuggestFacebookIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestFacebookIcon.svg"),
  ndSuggestGoogleMeet: require("../assets/images/newDesign/SuggestOnline/ndSuggestGoogleMeet.svg"),
  ndSuggestGoogleMeetInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestGoogleMeetInactive.svg"),
  ndSuggestGoogleMeetIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestGoogleMeetIcon.svg"),
  ndSuggestGoToMeeting: require("../assets/images/newDesign/SuggestOnline/ndSuggestGoToMeeting.svg"),
  ndSuggestGoToMeetingInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestGoToMeetingInactive.svg"),
  ndSuggestGoToMeetingIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestGoToMeetingIcon.svg"),
  ndSuggestTeams: require("../assets/images/newDesign/SuggestOnline/ndSuggestTeams.svg"),
  ndSuggestTeamsInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestTeamsInactive.svg"),
  ndSuggestTeamsIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestTeamsIcon.svg"),
  ndSuggestPhone: require("../assets/images/newDesign/SuggestOnline/ndSuggestPhone.svg"),
  ndSuggestPhoneInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestPhoneInactive.svg"),
  ndSuggestPhoneIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestPhoneIcon.svg"),
  ndSuggestSlack: require("../assets/images/newDesign/SuggestOnline/ndSuggestSlack.svg"),
  ndSuggestSlackInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestSlackInactive.svg"),
  ndSuggestSlackIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestSlackIcon.svg"),
  ndSuggestWebEx: require("../assets/images/newDesign/SuggestOnline/ndSuggestWebEx.svg"),
  ndSuggestWebExInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestWebExInactive.svg"),
  ndSuggestWebExIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestWebExIcon.svg"),
  ndSuggestWhatsApp: require("../assets/images/newDesign/SuggestOnline/ndSuggestWhatsApp.svg"),
  ndSuggestWhatsAppInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestWhatsAppInactive.svg"),
  ndSuggestWhatsAppIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestWhatsAppIcon.svg"),
  ndSuggestZoom: require("../assets/images/newDesign/SuggestOnline/ndSuggestZoom.svg"),
  ndSuggestZoomInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestZoomInactive.svg"),
  ndSuggestZoomIcon: require("../assets/images/newDesign/SuggestOnline/ndSuggestZoomIcon.svg"),
  ndSuggestOther: require("../assets/images/newDesign/SuggestOnline/ndSuggestOther.svg"),
  ndSuggestOtherInactive: require("../assets/images/newDesign/SuggestOnline/ndSuggestOtherInactive.svg"),
  ndSuggestSkipDetails: require("../assets/images/newDesign/SuggestOnline/ndSuggestSkipDetails.svg"),
  ndSuggestDetails: require("../assets/images/newDesign/SuggestOnline/ndSuggestDetails.svg"),
  ndSuggestPasteLink: require("../assets/images/newDesign/SuggestOnline/ndSuggestPasteLink.svg"),
  ndSuggestCreateMeeting: require("../assets/images/newDesign/SuggestOnline/ndSuggestCreateMeeting.svg"),
  ndSuggestAppNameClear: require("../assets/images/newDesign/SuggestOnline/ndSuggestAppNameClear.svg"),
  ndSuggestAppNameClearActive: require("../assets/images/newDesign/SuggestOnline/ndSuggestAppNameClearActive.svg"),
  ndSuggestClock: require("../assets/images/newDesign/SuggestOnline/ndSuggestClock.svg"),
  ndSuggestLocation: require("../assets/images/newDesign/SuggestInPerson/ndSuggestLocation.svg"),
  ndSuggestMarker: require("../assets/images/newDesign/SuggestInPerson/ndSuggestMarker.png"),
  ndSuggestSearchNearbyItem: require("../assets/images/newDesign/SuggestInPerson/ndSuggestSearchNearbyItem.svg"),
  ndSuggestSearchItem: require("../assets/images/newDesign/SuggestInPerson/ndSuggestSearchItem.svg"),
  ndSuggestSearchRecentItem: require("../assets/images/newDesign/SuggestInPerson/ndSuggestSearchRecentItem.svg"),
}

const ImageWithDisabled = (source, style, otherProps, disabled) => {
  if (disabled) {
    return (
      // <Grayscale style={style}>
      <Image source={source} {...otherProps} />
      // </Grayscale>
    )
  }

  return (
    <Image
      source={source}
      {...otherProps}
      style={[style, otherProps ? otherProps.style || {} : {}]}
    />
  )
}

const FastImageWithDisabled = (source = null, style, otherProps, disabled) => {
  let sourcePath = source ? source.uri : ""
  if (typeof sourcePath === "object") {
    sourcePath = sourcePath.Location || ""
  }

  if (disabled) {
    return (
      // <Grayscale style={style}>
      <Image source={{ uri: sourcePath }} {...otherProps} />
      //  </Grayscale>
    )
  }

  return (
    <Image
      source={{ uri: sourcePath }}
      {...otherProps}
      style={[style, otherProps ? otherProps.style || {} : {}]}
    />
  )
}

const getFileIcon = (type) => {
  switch (type) {
    case ".pdf":
      return images.fileIcons.pdf
    case ".docx":
    case ".doc":
      return images.fileIcons.word
    case ".xlsx":
    case ".xlsm":
    case ".xltx":
    case ".xltm":
      return images.fileIcons.excel
    case ".ppt":
    case ".pptx":
      return images.fileIcons.powerpoint
    case ".csv":
      return images.fileIcons.csv
    case ".mp3":
    case ".wav":
    case ".aac":
    case ".ogg":
    case ".wma":
    case ".flac":
      return images.fileIcons.audio
    case ".avi":
    case ".mp4":
    case ".mkv":
    case ".mov":
    case ".wmv":
    case ".flv":
      return images.fileIcons.video
    case ".txt":
      return images.fileIcons.txt
    case ".cs":
    case ".java":
    case ".js":
    case ".scala":
    case ".kt":
    case ".kts":
    case ".cpp":
    case ".c":
    case ".swift":
    case ".sh":
    case ".h":
    case ".py":
      return images.fileIcons.code
    case ".tar":
    case ".tar.gz":
    case ".tgz":
    case ".tar.bz2":
    case ".bz2":
    case ".rar":
    case ".zip":
    case ".dmg":
    case ".7z":
      return images.fileIcons.archive
    default:
      return images.fileIcons.unknown
  }
}

const getNewDesignFileIcon = (type) => {
  switch (type) {
    case ".pdf":
      return images.ndFileTypePDFIcon
    case ".docx":
    case ".doc":
      return images.ndFileTypeWordIcon
    case ".xlsx":
    case ".xlsm":
    case ".xltx":
    case ".xltm":
      return images.ndFileTypeExcelIcon
    case ".ppt":
    case ".pptx":
      return images.ndFileTypePowerpointIcon
    case ".csv":
      return images.ndFileTypeCSVIcon
    case ".mp3":
    case ".wav":
    case ".aac":
    case ".ogg":
    case ".wma":
    case ".flac":
      return images.ndFileTypeAudioIcon
    case ".avi":
    case ".mp4":
    case ".mkv":
    case ".mov":
    case ".wmv":
    case ".flv":
      return images.ndFileTypeVideoIcon
    case ".txt":
      return images.ndFileTypeTextIcon
    case ".cs":
    case ".java":
    case ".js":
    case ".scala":
    case ".kt":
    case ".kts":
    case ".cpp":
    case ".c":
    case ".swift":
    case ".sh":
    case ".h":
    case ".py":
      return images.ndFileTypeCodeIcon
    case ".tar":
    case ".tar.gz":
    case ".tgz":
    case ".tar.bz2":
    case ".bz2":
    case ".rar":
    case ".zip":
    case ".dmg":
    case ".7z":
      return images.ndFileTypeArchiveIcon
    default:
      return images.ndUndefinedFileTypeIcon
  }
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

const getRandomBase64Image = (source = "random") => {
  return fetch(unsplashApi[source])
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )
    .catch((e) => {
      console.log("unsplashApi request Error: ", e)
    })
}

export {
  images,
  FastImageWithDisabled,
  ImageWithDisabled,
  getFileIcon,
  getBase64,
  getRandomBase64Image,
  getNewDesignFileIcon,
}
