import React, { useState } from "react"
import { connect } from "react-redux"
import { images } from "@utils/imageUtils"
import {
  selectReplyMessage,
  selectActiveTab,
  selectModalPeopleNudge,
} from "@selectors/common"

import "./index.scss"
import TimeZoneDetails from "./components/TimeZoneDetails"
import CommentsDetails from "./components/CommentsDetails"
import ReactionsDetails from "./components/ReactionsDetails"

import DiscussionCard from "../Chat/discussionCard/discussionCardContainer"

import { toggleSuggestionDetails } from "@actions/commonActions"
import {
  selectActiveTeeup,
  selectActiveTeeupSettings,
  selectHiddenPinnedMessages,
  selectMessagesWithoutNugdes,
  selectMessages,
} from "@selectors/activeTeeup"

import {
  selectPermissions,
  // selectSuggestionMessages,
  selectTeeupGameplans,
  selectTeeupPeople,
  selectTeeupRecommendationsIds,
  selectTeeupRecommendations,
  selectDecidedGameplans,
  selectTeeupsParticipants,
  selectTeeupReactionsBySuggestionId,
  selectTeeupParticipants,
  // selectTeeupMessages,
} from "@selectors/teeups"

const SuggestionDetails = ({
  toggleSuggestionDetails,
  detailsTab,
  suggestionContent,
  activeTab,
  messages,
  styledMessages,
  currentSuggestionId,
  activeTeeup,
  gameplans,
  reactions,
  participants,
  people,
  address,
  timeParams,
}) => {
  const allReactedPeopleIds = []
  reactions.map((reaction) => allReactedPeopleIds.push(...reaction.reactedBy))

  const usersWhoLikes = []
  const usersWhoDislikes = []

  reactions.map((reaction) =>
    reaction.reactionId === 2
      ? reaction.reactedBy.forEach((personId) =>
          usersWhoLikes.push(people[personId])
        )
      : reaction.reactedBy.forEach((personId) =>
          usersWhoDislikes.push(people[personId])
        )
  )

  const neutralReactions = Object.values(people).filter(
    (person) => !allReactedPeopleIds.includes(person.id)
  )

  const [currentTab, setCurrentTab] = useState(detailsTab)
  const changeTab = (tab) => setCurrentTab(tab)

  return (
    <div className="suggestion__details--wrapper">
      <div className="suggestion__details--header__container">
        <h1 className="suggestion__details--header__title">
          Suggestion Details
        </h1>
        <img
          className="suggestion__details--close__img"
          src={images.cross}
          onClick={() => toggleSuggestionDetails(false)}
        />
      </div>

      <div className="suggestion__details--suggestion__container">
        <div className="suggestion__details--suggestion">
          {!suggestionContent.lines ? (
            suggestionContent
          ) : (
            <>
              {suggestionContent.lines}
              {suggestionContent.likes}
            </>
          )}
        </div>
      </div>

      <div className="suggestion__details--tab__nav">
        <button
          className={`suggestion__details--tab__button ${
            currentTab === 1 && "tab__button--active"
          }`}
          onClick={() => changeTab(1)}
        >
          Time zone
        </button>
        <button
          className={`suggestion__details--tab__button ${
            currentTab === 2 && "tab__button--active"
          }`}
          onClick={() => changeTab(2)}
        >
          Comments
        </button>
        <button
          className={`suggestion__details--tab__button ${
            currentTab === 3 && "tab__button--active"
          }`}
          onClick={() => changeTab(3)}
        >
          Reactions
        </button>
      </div>

      <div className="suggestion__details--tab__content--container">
        {currentTab === 1 && (
          <TimeZoneDetails
            address={address}
            participants={participants}
            people={people}
            endDateHoursInMinutes={timeParams.endDateHoursInMinutes}
            duration={timeParams.duration}
          />
        )}
        {/* {currentTab === 2 && (<DiscussionCard index={activeTab}/>)} */}
        {currentTab === 2 && (
          <CommentsDetails
            messages={messages}
            styledMessages={styledMessages}
            currentSuggestionId={currentSuggestionId}
          />
        )}
        {currentTab === 3 && (
          <ReactionsDetails
            usersWhoLikes={usersWhoLikes}
            usersWhoDislikes={usersWhoDislikes}
            neutralReactions={neutralReactions}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    detailsTab: state.common.detailsTab,
    suggestionContent: state.common.suggestionContent,
    activeTab: selectActiveTab(state),
    messages: selectMessagesWithoutNugdes(state),
    styledMessages: state.common.styledMessages,
    currentSuggestionId: state.common.currentSuggestionId,

    activeTeeup: selectActiveTeeup(state),
    gameplans: selectTeeupGameplans(state),
    reactions: selectTeeupReactionsBySuggestionId(
      state.common.currentSuggestionId
    )(state),
    participants: selectTeeupParticipants(state),
    people: selectTeeupPeople(state),
    address: state.common.address,
    timeParams: state.common.timeParams,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSuggestionDetails: (isOpened) =>
      dispatch(toggleSuggestionDetails(isOpened)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionDetails)
