import React from "react"
import { Keyboard, View, Text, Platform, BackHandler } from "react-native"
import PropTypes from "prop-types"
// import { Navigation } from 'react-native-navigation'
import _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { countryCodeFromDialingCode } from "@utils/contactUtils"

import { EVENT_TYPES, getEventWithTime } from "@utils/calendarUtils"
import { timeDifferenceMinutes } from "@utils/dateUtils"
import { formatInviteesForTimezones } from "@utils/timezones"
import {
  generateDaysArray,
  compareTimeStrings,
  getCurrentTimezone,
  getTimezonesForCountry,
  composeDatetime,
  addMinutes,
  isToday,
  getDuration,
  getDateWithTimezone,
  isDateBeforeNow,
  adjustMinuteRange,
} from "@utils/dateUtils"
import strings from "@i18n"
import * as teeupActions from "@actions/teeupActions"
import Toast from "@ui/toast"
import SearchList from "../ui/searchList"
import SearchLocationList from "../ui/searchLocationList"
import PlacesAutocomplete from "react-places-autocomplete"

import {
  selectTeeupParticipants,
  selectTeeupRecommendationsIds,
  selectTeeupRecommendations,
  selectTeeupPeople,
  selectTeeupSuggestions,
} from "@selectors/teeups"
import {
  selectActiveTeeupId,
  selectActiveTeeup,
  selectActiveTeeupInvited,
} from "@selectors/activeTeeup"
import {
  selectUserInfo,
  selectUserCustomDates,
  selectUserCustomTimes,
  selectUserHomeLocation,
  selectUserTimezone,
} from "@selectors/user"
import { images } from "@utils/imageUtils"
import { SELECT_LOCATION_TYPES } from "@utils/location"
import {
  validateSuggestion,
  defaultCustomDates,
  defaultCustomTimes,
  GAMEPLAN_OPTIONS,
  DATE_SELECT_TYPES,
  locationTypeIds,
  whenTypeIds,
  isMultiTimezone,
  formatSuggestion,
  getSuggestionOnlineValuesFromLink,
} from "@utils/gamePlanUtils"

import { placeNameTypes } from "@config/enums"
import TimePickerContainer from "../ui/gameplanTimePicker"
import { GameplanStyles, whereOnlineFieldsContainer } from "./createTeeupStyles"

import GameplanView from "./gameplanView"
import { TimeDateTab } from "./tabs/TimeDateTab"
import { RenderFields } from "./components/renderFields"
import MapPartComponent from "./mapPart"
import { AppColors } from "@theme"
import Icon from "@ui/icon"
import {
  createSuggetion,
  promoteToGameplan,
} from "../../middlewares/backendMiddleware"
import { updateUserStatus } from "@actions/requestActions"
import { teeupUserStatusPriorities, teeupUserStatusKeys } from "@config/enums"
import {
  fetchTeeupUsersById,
  fetchGameplanOptions,
} from "@actions/teeupActions"
import { isOnlineTabOpened } from "@actions/commonActions"

import GoogleMap from "./GoogleMap"

const buttonIds = {
  close: "closeId",
}

const whenRoutes = [
  {
    key: "specificTime",
    title: "Specific Date/Time",
  },
  {
    key: "freestyleText",
    title: "Freestyle Text",
  },
]

const whereRoutes = [
  { key: "realWorld", title: "In Person" },
  { key: "online", title: "Online" },
]

const defineRoutes = ({ isWhenType, isWhereType, isWhatType }) => {
  if (isWhenType) return whenRoutes
  if (isWhereType) return whereRoutes
  if (isWhatType) return null
  return null
}

const isIOS = Platform.OS === "ios"

let dateArray

class GameplanContainer extends React.Component {
  static defaultProps = {
    shouldPutInGameplan: false,
  }

  componentDidMount() {
    const { type, suggestions } = this.props

    if (type === "when") {
      this.onZonesScrolled(
        adjustMinuteRange(getDateWithTimezone().add(3, "hours").format())
      )
    }

    if (isIOS) {
      Keyboard.addListener("keyboardWillShow", this.keyboardWillShow)
      Keyboard.addListener("keyboardWillHide", this.keyboardWillHide)
    } else {
      Keyboard.addListener("keyboardDidShow", this.keyboardWillShow)
      Keyboard.addListener("keyboardDidHide", this.keyboardWillHide)
    }
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleAndroidBackPress
    )
  }

  componentWillUnmount() {
    if (isIOS) {
      Keyboard.removeListener("keyboardWillShow", this.keyboardWillShow)
      Keyboard.removeListener("keyboardWillHide", this.keyboardWillHide)
    } else {
      Keyboard.removeListener("keyboardDidShow", this.keyboardWillShow)
      Keyboard.removeListener("keyboardDidHide", this.keyboardWillHide)
    }
    if (this.backHandler) {
      this.backHandler.remove()
    }
  }

  keyboardWillShow = () => this.setState({ isKeyboardShown: true })
  keyboardWillHide = () => this.setState({ isKeyboardShown: false })

  handleAndroidBackPress = () => {
    this.handleClose()
    return true
  }

  handleDone = (selected) => {
    if (this._closing) {
      return
    }

    const {
      startDate,
      onlineTypeOfInviteLink,
      endDate,
      startDateFreestyle,
      endDateFreestyle,
      value2,
      isTimeZoneEnabled,
      isCustomDate,
      isCustomTime,
      coordinates,
      googlePlaceId,
      onlineDetails,
      onlineName,
      // selected,
      locationType,
      whenType,
      isTimeDurationActive,
      fullDetails,
      duration,
      showDetails,
    } = this.state

    const {
      currentSuggestions,
      index,
      optionId,
      userInfo: { id: userId },
      teeupId,
      type,
      allowPutOnGameplan,
      updateGameplan,
      componentId,
      shouldPutInGameplan,
      createSuggestionCallback,
      updateUserStatus,
      gotTeeupParts,
      activeTeeup,

      initialGameplans,
      setInitialGameplans,
    } = this.props
    const newSuggestion = {
      ...this.getFormattedSuggestion(),
      optionId,
    }
    const isValid = validateSuggestion(currentSuggestions, newSuggestion, type)
    if (isValid) {
      // this._closing = true
      const dataToUpdate = {
        type,
        startDate,
        userId,
        teeupId,
        endDate,
        value: newSuggestion.value,
        value2: newSuggestion.value2,
        isCustomDate,
        isCustomTime,
        isTimeZoneEnabled,
        details: newSuggestion.details,
        ...coordinates,
        googlePlaceId,
        selected: selected || false,
        // selected: allowPutOnGameplan ? selected : false,
      }
      if (shouldPutInGameplan) {
        dataToUpdate.selected = true
      }
      if (whenType === whenTypeIds.freestyleText) {
        dataToUpdate.startDate = startDateFreestyle
        dataToUpdate.endDate = endDateFreestyle
      }
      if (isCustomTime && type === GAMEPLAN_OPTIONS.when) {
        dataToUpdate.isTimeZoneEnabled = false
        if (value2 && isTimeZoneEnabled) {
          dataToUpdate.startDate = undefined
          dataToUpdate.endDate = undefined
        }
      }
      if (
        type === GAMEPLAN_OPTIONS.when &&
        !isTimeDurationActive &&
        !isTimeZoneEnabled
      ) {
        dataToUpdate.endDate = undefined
        dataToUpdate.isCustomTime = true
      }

      if (optionId) {
        dataToUpdate.optionId = optionId
      }
      if (locationType && type === GAMEPLAN_OPTIONS.where) {
        dataToUpdate.locationType = locationType
        dataToUpdate.fullDetails = fullDetails || null
        if (locationType === locationTypeIds.online) {
          dataToUpdate.googlePlaceId = null
          dataToUpdate.value =
            showDetails && onlineTypeOfInviteLink?.linkWithMetadata
              ? onlineTypeOfInviteLink.linkWithMetadata
              : onlineName
          dataToUpdate.details = onlineDetails || null
        }
      }

      dataToUpdate.index = index
      if (!duration) {
        delete dataToUpdate.endDate
      }
      if (shouldPutInGameplan && createSuggestionCallback) {
        createSuggestionCallback()
      }

      if (this.props.gameplanData) {
        const newData = this.props.gameplanData.find(
          (item) => item.type === type
        )
        newData.suggestions = [{ ...formatSuggestion(dataToUpdate) }]
      }
      createSuggetion(dataToUpdate)

      const statusId = teeupUserStatusPriorities.joined

      // if (activeTeeup.userStatus !== teeupUserStatusKeys.joined) {
      //     updateUserStatus(
      //         teeupId,
      //         statusId,
      //     )
      //     .then(async (error) => {
      //         const teeupUsers = await fetchTeeupUsersById(teeupId);
      //         gotTeeupParts([teeupUsers]);
      //         updateGameplan(teeupId, await fetchGameplanOptions(teeupId));

      //         if (error) {
      //             console.log('handleRespondToInvitation Error: ', {
      //                 error,
      //             })
      //         }
      //     })
      // }

      const createdSuggestionId =
        Object.values(currentSuggestions)[
          Object.values(currentSuggestions).length - 1
        ]?.id + 1

      if (selected && !setInitialGameplans)
        promoteToGameplan({ suggestionId: createdSuggestionId, type })
      if (selected && setInitialGameplans)
        setInitialGameplans([...initialGameplans, { type }])

      this.props.closeCreate()
    } else {
      alert("Similar suggestion already exists")
      // Toast.show('Similar suggestion already exists', {
      //     bottomOffset: 8,
      // })
    }
  }

  handleClose = () => {
    if (this._closing) {
      return
    }

    this._closing = true
    // Navigation.dismissModal(this.props.componentId)
  }

  handleCalendar = ({ suggestion }) => {
    let updatedSuggestion = suggestion
      ? suggestion
      : this.getFormattedSuggestion()
    const { teeupName } = this.props
    const { startDate: selectedDate } = this.state

    const passedEvent = selectedDate
      ? {
          endDate: getEventWithTime(updatedSuggestion).endDate,
          startDate: updatedSuggestion.startDate,
          startTime: updatedSuggestion.startDate,
          duration: updatedSuggestion.duration,
          type: EVENT_TYPES.teeup,
          isTemporaryEvent: true,
          name: teeupName,
        }
      : null
    this.setState({ isCalendarShown: true, passedEvent })
  }

  handleCloseCalendarCard = () => this.setState({ isCalendarShown: false })

  constructor(props) {
    super(props)
    this.customTimeInput = _.debounce(this.customTimeInputNotDelayed, 500)
    this.freeStyleTextInput = React.createRef()
    let { suggestions, type } = this.props
    const isWhenType = type === GAMEPLAN_OPTIONS.when
    const isWhereType = type === GAMEPLAN_OPTIONS.where
    const isWhatType = type === GAMEPLAN_OPTIONS.what
    const initialDuration = 60

    this.routes = defineRoutes({ isWhenType, isWhereType, isWhatType })

    dateArray = generateDaysArray()

    this.defaultWhere = {
      locationType: locationTypeIds.realWorld,
      onlineDetails: null,
      onlineName: null,
      value: null,
      customPlaceName: null,
      placeName: null,
      details: null,
      coordinates: {},
      googlePlaceId: null,
      onlineTypeOfInviteLink: null,
      showDetails: false,
    }
    this.defaultWhen = {
      value: null,
      timeWasSelected: false,
      dateWasSelected: false,
      isDurationActive: true,
      timezoneWasScrolled: false,
      duration: initialDuration,
      startDateIndex: 0,
      whenType: whenTypeIds.specificTime,
    }

    let state = {
      isNew: true,
      selected: false,
      content: null,
      layout: null,
      isModalOpened: false,
      showDatePicker: false,
      showTimePicker: false,
      showEndDatePicker: false,
      showEndTimePicker: false,
      dateWasSelected: false,
      timeWasSelected: false,
      timezoneWasScrolled: false,
      canSubmit: false,
      temporaryPlace: "",
      isTimeDurationActive: true,
      ...(isWhenType ? this.defaultWhen : isWhereType ? this.defaultWhere : {}),
    }

    let { participants, people } = this.getParticipants(this.props)
    const isTimezonesEnabled = isMultiTimezone(people, participants)

    let newState =
      suggestions && suggestions.length > 0
        ? {
            ...suggestions[0],
            ...(isWhereType
              ? suggestions[0].locationType === locationTypeIds.online
                ? {
                    sceneIndex: 1,
                    index: 1,
                    placeName: null,
                    details: null,
                    onlineName: suggestions[0].value,
                    onlineTypeOfInviteLink: suggestions[0]?.value,
                    onlineDetails: suggestions[0].details,
                    selected: suggestions[0].selected,
                    showDetails: !!suggestions[0]?.value,
                  }
                : {
                    sceneIndex: 0,
                    index: 0,
                    onlineName: null,
                    onlineTypeOfInviteLink: null,
                    onlineDetails: null,
                    fullDetails: null,
                    locationType: locationTypeIds.realWorld,

                    // locationType: isTimezonesEnabled
                    //     ? locationTypeIds.realWorld
                    //     : locationTypeIds.online,
                    ...this.getDefaultWhereValues(),
                  }
              : isWhenType &&
                (suggestions[0].isCustomDate || suggestions[0].isCustomTime)
              ? {
                  sceneIndex: 1,
                  index: 1,
                  isCustomDate: suggestions[0].isCustomDate || false,
                  isCustomTime: suggestions[0].isCustomTime || false,
                  whenType: whenTypeIds.freestyleText,
                  startDateFreestyle: suggestions[0].value,
                  endDateFreestyle: suggestions[0].value2,
                  value: suggestions[0].value,
                  value2: suggestions[0].value2,
                }
              : null),
            // this.getDefaultWhenTabForWhenSuggestion(
            //       isTimezonesEnabled
            //   )),
          }
        : {
            isCustomDate: true,
            isCustomTime: true,
            onlineDetails: null,
            isTimezonesEnabled,
            ...(isWhereType && {
              index: isTimezonesEnabled ? 1 : 0,
              sceneIndex: isTimezonesEnabled ? 1 : 0,
              locationType: locationTypeIds.realWorld,
              //   locationType: isTimezonesEnabled
              //       ? locationTypeIds.realWorld
              //       : locationTypeIds.online,
            }),
            //   ...(isWhenType &&
            //       this.getDefaultWhenTabForWhenSuggestion(
            //           isTimezonesEnabled
            //       )),
          }

    if (participants && isWhenType) {
      newState.isTimeZoneEnabled = isTimezonesEnabled
      newState.multizoneUsers = isTimezonesEnabled
      newState.dateArray = dateArray
      newState.startDateIndex = 0
      newState.endDateIndex = 0
      // if there is suggestion, the initial duration would be the time difference between end date and start date
      newState.duration = suggestions[0]
        ? timeDifferenceMinutes(
            suggestions[0].startDate,
            suggestions[0].endDate
          )
        : initialDuration
    }

    this.state = {
      ...state,
      //separate tab change index from rendering content index because of tab-view separator error
      index: 0,
      sceneIndex: 0,
      ...newState,
      ...(props.teeupId && { isTimeDurationActive: true }),
    }

    this.isSuggestionFullySet() && this.updateSendButton(true)
    // Navigation.events().bindComponent(this)
  }

  getDefaultWhenTabForWhenSuggestion = (isTimezonesEnabled) => {
    const { suggestions, currentSuggestions } = this.props
    const formattedCurrentSuggestions = Object.values(currentSuggestions)

    if (suggestions && suggestions.length > 0) {
      const suggestion = suggestions[0]

      const isExactDateTimeSuggestion =
        !!suggestion.startDate && !!suggestion.endDate
      const isExactDateSuggestion = !!suggestion.startDate

      return {
        index: isExactDateSuggestion ? 0 : 1,
        sceneIndex: isExactDateSuggestion ? 0 : 1,
        whenType: isExactDateSuggestion
          ? whenTypeIds.specificTime
          : whenTypeIds.freestyleText,
        isTimeDurationActive: isExactDateTimeSuggestion,
      }
    } else if (
      formattedCurrentSuggestions &&
      formattedCurrentSuggestions.length > 0
    ) {
      const isSuggestionWithExactDateAndTime = formattedCurrentSuggestions.find(
        (sug) => sug.startDate && sug.endDate
      )
      const isSuggestionWithExactDate = formattedCurrentSuggestions.find(
        (sug) => sug.startDate && !sug.endDate
      )
      const isSuggestionWithSpecificDate =
        isSuggestionWithExactDateAndTime || isSuggestionWithExactDate

      return {
        index: isSuggestionWithSpecificDate ? 0 : 1,
        sceneIndex: isSuggestionWithSpecificDate ? 0 : 1,
        whenType: isSuggestionWithSpecificDate
          ? whenTypeIds.specificTime
          : whenTypeIds.freestyleText,
        isTimeDurationActive:
          !isSuggestionWithExactDateAndTime && isSuggestionWithExactDate
            ? false
            : true,
      }
    } else {
      return {
        index: isTimezonesEnabled ? 0 : 1,
        sceneIndex: isTimezonesEnabled ? 0 : 1,
        whenType: isTimezonesEnabled
          ? whenTypeIds.specificTime
          : whenTypeIds.freestyleText,
      }
    }
  }

  getDefaultWhereValues = () => {
    let customPlaceName, placeName, details
    let { suggestions } = this.props
    const {
      value,
      details: suggestionDetails,
      googlePlaceId,
      selected,
      fullDetails,
    } = suggestions[0]
    const valueArr = value?.split(", ") || []
    const detailsArr = suggestionDetails?.split(", ") || []
    if (valueArr.length === 1) {
      customPlaceName = value
    }
    if (detailsArr.length === 1) {
      placeName = suggestionDetails
    } else if (detailsArr.length <= 3) {
      placeName = detailsArr.join(", ")?.replace(/\n|\r/g, "").trim()
    } else {
      placeName = detailsArr[0]?.replace(/\n|\r/g, "").trim()
      details = detailsArr?.slice(1)?.join(", ")?.replace(/\n|\r/g, "").trim()
    }
    return {
      customPlaceName: customPlaceName || null,
      placeName: placeName || null,
      details: details || null,
      googlePlaceId,
      selected,
      fullDetails: fullDetails || null,
    }
  }

  isSuggestionFullySet() {
    let { suggestions, type } = this.props
    const isWhenType = type === GAMEPLAN_OPTIONS.when
    const isWhereType = type === GAMEPLAN_OPTIONS.where
    const isWhatType = type === GAMEPLAN_OPTIONS.what

    if (suggestions && suggestions.length > 0) {
      if (
        isWhereType &&
        (this.state?.onlineTypeOfInviteLink || this.state?.onlineDetails)
      ) {
        return true
      }
      if (
        isWhenType &&
        (this.state.isCustomDate ||
          this.state.isCustomTime ||
          (!this.state.isCustomDate && this.state.startDate))
      ) {
        return true
      }
      if (isWhatType && this.state.value) {
        return true
      }
    }

    return false
  }

  navigationButtonPressed({ buttonId }) {
    if (this._closing) {
      return
    }

    switch (buttonId) {
      case buttonIds.close: {
        this._closing = true

        Keyboard.dismiss()
        // Navigation.dismissModal(this.props.componentId)
        break
      }
    }
  }

  handleToggleTimeDuration = (value) => {
    this.setState({ isTimeDurationActive: value })
  }

  handleToggleAddingToGameplan = () =>
    this.setState((prevState) => {
      const updateBody = {
        selected: !prevState.selected,
      }

      if (!prevState.canSubmit && this.isSuggestionFullySet()) {
        updateBody.canSubmit = true
      }
      return updateBody
    })

  updateSendButton = (canSubmit) => this.setState({ canSubmit })

  onZonesScrolled = (time) => {
    const { duration, sceneIndex } = this.state
    const { suggestions } = this.props
    // When timezones list is scrolled, update selected time
    // If there are suggestions the default date is taken from them
    // const composedStartDate = suggestions[0] ? suggestions[0].startDate : time
    const composedStartDate = time ? time : suggestions[0].startDate

    this.setState(
      {
        isCustomTime: false,
        isCustomDate: false,
        timeWasSelected: true,
        dateWasSelected: true,
        startDate: composedStartDate,
        timezoneWasScrolled: true,
        isStartDateToday: isToday(composedStartDate),
        canSubmit: true,
        ...(duration >= 0 && {
          endDate: addMinutes(composedStartDate, duration),
        }),
      },
      () => this.updateSendButton(true)
    )
  }

  getParticipants = (props) => {
    // If creating new teeup, there are no participants yet, only invitees
    let { participants, people, invitees, userInfo } = props
    const currentTimezone = getCurrentTimezone()

    if (participants && participants.length) {
      const formattedPeople = {}
      // check if timezone is present
      // and try to get it from country code
      Object.keys(people).forEach((key) => {
        const person = { ...people[key] }
        if (key === userInfo.id && !person.timezone) {
          person.timezone = userInfo.timezone || currentTimezone
        }
        if (!person.timezone && person.countryCode) {
          try {
            const timezones = getTimezonesForCountry(
              countryCodeFromDialingCode(person.countryCode)
            )
            person.timezone = timezones[0].name
          } catch (e) {
            //
          }
        }
        formattedPeople[key] = person
      })

      return { participants, people: formattedPeople }
    } else if (invitees && invitees.length) {
      const { formattedPeople, formattedParticipants } =
        formatInviteesForTimezones(invitees)

      people = formattedPeople
      participants = formattedParticipants

      userInfo = { ...userInfo }
      userInfo.timezone = userInfo.timezone || currentTimezone
      people[userInfo.id] = userInfo
      participants.unshift(userInfo.id)
    }

    return { participants, people }
  }

  getEndDate = (state, updates, newEndDate, settingTime, propsDuration) => {
    const { isEndDate, isEndTime, startDate } = updates
    const { duration, endDate } = state

    // Many options possibl:
    //  1) with timezones, we only look at duration
    //  2) only setting TIME part, no DATE (isEndDate === false) part of endDate
    //  3) only setting DATE part, no TIME (isEndTime === false) part of endDate
    //  4) setting TIME part of endDate, DATE part is already set
    //  5) setting DATE part of endDate, TIME part is already set

    let finalEndDate = null
    // Only set end date if it's explicitly added someplace
    if (propsDuration === 0) {
      finalEndDate = startDate
    } else if (duration) {
      finalEndDate = endDate
    } else if (!isEndDate && !isEndTime) {
      // No end date needed at all
    } else if (settingTime) {
      // Setting TIME part of end date
      if (isEndDate) {
        // End date specified, combine it with end time.
        // If case isEndTime was turned off, prepare to use TIME from startDate
        finalEndDate = composeDatetime(
          endDate,
          isEndTime ? newEndDate : startDate
        )
      } else {
        // No end date specified, use startDate
        finalEndDate = composeDatetime(startDate, newEndDate)
      }
    } else {
      // Setting DATE part of end date
      if (isEndTime) {
        // End date specified, combine it with end time
        // If case isEndDate was turned off, prepare to use DATE from startDate
        finalEndDate = composeDatetime(
          isEndDate ? newEndDate : startDate,
          endDate
        )
      } else {
        // No end date specified, use startDate
        finalEndDate = composeDatetime(newEndDate, startDate)
      }
    }

    return finalEndDate
  }

  handleChooseDate = (updates) => () => {
    const prevState = { ...this.state }
    this.setState(updates)
    Toast.show(
      `“${this.state.value}” converted to “${
        this.state.dateArray[updates.startDateIndex]
      }”`,
      {
        bottomOffset: 64,
        buttonLabel: strings.systemButtons.undo,
        buttonOnPress: () => this.setState(prevState),
      }
    )
  }

  onDatesSelected = (dateProps) => {
    let {
      startDate,
      endDate,
      duration,
      isEndEnabled,
      type,
      listComponentId,
      startTime,
      startDateIndex,
      endDateIndex,
      isFreestyle = false,
      changeMethod,
    } = dateProps
    // if (changeMethod) {
    //     this.zonesViewRef.checkDateChangeMethod(changeMethod)
    // }

    const {
      startDateFreestyle,
      startDate: stateStartDate,
      isTimeZoneEnabled,
      dateWasSelected,
      value,
      dateArray,
      sceneIndex,
      isDurationActive,
      isTimeDurationActive,
    } = this.state
    let updates = {}
    let previousStartDate = isFreestyle ? startDateFreestyle : stateStartDate
    if (isDateBeforeNow(startDate)) {
      startDate = getDateWithTimezone().format()
    }
    switch (type) {
      case DATE_SELECT_TYPES.date:
        if (!isFreestyle) {
          updates.startDate = startDate
          // updates.value = null
          updates.isCustomDate = false
          updates.isEndDate = isEndEnabled
          updates.isEndTime = this.state.isEndTime
          updates.dateWasSelected = true
          if (duration >= 0) {
            updates.duration = duration
          }
          if (startDateIndex) {
            updates.startDateIndex = startDateIndex
          }
          if (endDateIndex) {
            updates.endDateIndex = endDateIndex
          }
          break
        } else {
          updates.startDateFreestyle = previousStartDate
            ? composeDatetime(startDate, previousStartDate)
            : startDate
          updates.value = null
          updates.isCustomDate = false
          updates.isEndDate = isEndEnabled
          updates.isEndTime = this.state.isEndTime
          updates.dateWasSelected = true
          if (duration >= 0) {
            updates.duration = duration
          }
          if (startDateIndex) {
            updates.startDateIndexFreestyle = startDateIndex
          }
          if (endDateIndex) {
            updates.endDateIndexFreeStyle = endDateIndex
          }
          break
        }
      case DATE_SELECT_TYPES.time:
        if (!isFreestyle) {
          updates.startDate = previousStartDate
            ? composeDatetime(previousStartDate, startDate)
            : startDate
          // updates.value2 = null
          updates.isCustomTime = false
          updates.isEndTime = isEndEnabled
          updates.isEndDate = this.state.isEndDate
          updates.timeWasSelected = true
          if (duration >= 0) {
            updates.duration = duration
          }
          break
        } else {
          updates.startDateFreestyle = previousStartDate
            ? composeDatetime(previousStartDate, startDate)
            : startDate
          updates.value2 = null
          updates.isCustomTime = false
          updates.isEndTime = isEndEnabled
          updates.isEndDate = this.state.isEndDate
          updates.timeWasSelected = true
          break
        }
      case DATE_SELECT_TYPES.duration:
        updates.duration = duration
        break
      case DATE_SELECT_TYPES.timeDuration:
        if (!isFreestyle) {
          updates.startDate = previousStartDate
            ? composeDatetime(previousStartDate, startDate)
            : startDate
          updates.value2 = null
          updates.isCustomTime = false
          updates.isEndTime = isEndEnabled
          updates.isEndDate = this.state.isEndDate
          updates.timeWasSelected = true
          updates.duration = duration
          break
        } else {
          updates.startDateFreestyle = previousStartDate
            ? composeDatetime(previousStartDate, startDate)
            : startDate
          updates.value2 = null
          updates.isCustomTime = false
          updates.isEndTime = isEndEnabled
          updates.isEndDate = this.state.isEndDate
          updates.timeWasSelected = true
          if (duration >= 0) {
            updates.duration = duration
          }
          break
        }
      case DATE_SELECT_TYPES.timeRange: {
        updates.startDate = startDate
        updates.endDate = endDate
        updates.duration = getDuration(startDate, endDate)
        // if (this.zonesViewRef) {
        //     this.zonesViewRef.scrollToIndex(
        //         startDate,
        //         false,
        //         updates.duration
        //     )
        // }
        this.setState(updates)
        return
      }
      default:
        break
    }

    updates.endDate = this.getEndDate(
      this.state,
      updates,
      endDate,
      type === DATE_SELECT_TYPES.time,
      duration
    )

    if (
      isEndEnabled &&
      this.state.duration &&
      duration !== 0 &&
      compareTimeStrings(updates.startDate, updates.endDate)
    ) {
      updates.endDate = updates.startDate
    }

    if (!this.state.isTimeZoneEnabled) {
      updates.canSubmit = true
    } else {
      updates.canSubmit =
        (updates.timeWasSelected || this.state.timeWasSelected) &&
        (updates.dateWasSelected || this.state.dateWasSelected)
    }

    if (
      type === DATE_SELECT_TYPES.date &&
      !isTimeZoneEnabled &&
      dateWasSelected &&
      value &&
      isFreestyle
    ) {
      // showOverlayModal({
      //     title: 'Convert to Specific Date',
      //     description: `Would you like to change “${value}” to the more specific date of “${dateArray[startDateIndex]}”?`,
      //     rightButton: {
      //         text: 'Change it',
      //         onPress: this.handleChooseDate(updates),
      //     },
      // })
    } else {
      const { startDate: updatedStartDate, duration: updatedDuration } = updates
      let composedStartDate =
        updatedStartDate || composeDatetime(this.state.startDate, startTime)
      const durationVal =
        updatedDuration >= 0 ? updatedDuration : this.state.duration
      if ((!isDurationActive || !isTimeDurationActive) && updatedStartDate) {
        updates.endDate = updatedStartDate
        updates.duration = 0
      } else {
        updates.endDate = addMinutes(
          updatedStartDate ? updatedStartDate : startDate,
          durationVal
        )
        if (durationVal >= 0) {
          updates.isDurationActive = true
        }
      }

      // this.setState(updates, () => {
      //     if (!isFreestyle && this.zonesViewRef) {
      //         if (durationVal >= 0) {
      //             this.zonesViewRef.scrollToIndex(
      //                 updatedStartDate,
      //                 false,
      //                 durationVal
      //             )
      //         } else this.zonesViewRef.scrollToIndex(composedStartDate)
      //     }
      // })
      this.setState(updates)
    }

    if (!isFreestyle && sceneIndex > 0) {
      this.setState({ sceneIndex: 0, index: 0 }, () => {
        this.tabViewRef.onTabIndexChange(0)
      })
    }

    // Dismiss searchList component along with timezones component
    // Don't dismiss setupGameplan component when dismissing overlay
    if (listComponentId && listComponentId !== this.props.componentId) {
      // Navigation.dismissModal(listComponentId)
    }
  }

  onTimePicker = (
    type,
    listComponentId,
    isEndEnabled,
    isFreestyle = true,
    additionalPickerProps = {}
  ) => {
    const { userTimezone } = this.props
    const {
      isTimeZoneEnabled,
      startDateFreestyle,
      endDateFreestyle,
      duration,
      dateArray,
      startDateIndexFreestyle,
      endDateIndexFreeStyle,
      startDate,
      endDate,
      startDateIndex,
      endDateIndex,
    } = this.state
    Keyboard.dismiss()

    const props = {
      onDone: (values) => this.onDatesSelected({ ...values, isFreestyle }),
      listComponentId,
      closeLayout: () => this.setState({ layout: null }),
      isTimeZoneEnabled: true,
      isMultiTimeZone: isTimeZoneEnabled,
      isTimeInterval: true,
      isEndEnabled,
      startDate: isFreestyle ? startDateFreestyle : startDate,
      endDate: isFreestyle ? endDateFreestyle : endDate,
      duration,
      type,
      checkForTodaysDate: this.checkForTodaysDate,
      dateArray,
      startDateIndex: isFreestyle ? startDateIndexFreestyle : startDateIndex,
      endDateIndex: isFreestyle ? endDateIndexFreeStyle : endDateIndex,
      timezone: userTimezone,
      ...additionalPickerProps,
    }

    this.setState({ layout: <TimePickerContainer {...props} /> })
  }

  onDateGenericSelected = (inputValue, isNew) => {
    Keyboard.dismiss()
    const value = inputValue.replace(/\s+/g, " ").trim()

    if (isNew) {
      this.props.newCustomDate(value)
    }

    this.setState({
      value,
      isCustomDate: true,
      dateWasSelected: true,
      canSubmit: !!value || !!this.state.value2,
    })
  }

  onTimeGenericSelected = (inputValue2, isNew) => {
    Keyboard.dismiss()
    const value2 = inputValue2.replace(/\s+/g, " ").trim()

    if (isNew) {
      this.props.newCustomTime(value2)
    }
    this.setState({
      value2,
      isCustomTime: true,
      timeWasSelected: true,
      canSubmit: !!value2 || !!this.state.value,
    })
  }

  fieldPressed = (field) => {
    this.setState({
      isModalOpened: true,
      content: (
        <SearchList
          onClose={() => this.setState({ isModalOpened: false })}
          {...field}
        />
      ),
    })
  }

  selectLocationValue = ({
    type,
    value,
    details,
    coordinates,
    placeName,
    googlePlaceId,
    fullDetails,
  }) => {
    this.setState(() => {
      if (type === SELECT_LOCATION_TYPES.placeTitle) {
        return { customPlaceName: value }
      } else
        return {
          placeName,
          details,
          fullDetails,
          [type]: value,
          coordinates,
          googlePlaceId,
        }
    }, this.handleUpdateSuggestButton)
  }

  handleUpdateSuggestButton = (isSpecificDateTime = false) => {
    const {
      customPlaceName,
      placeName,
      details,
      value,
      onlineDetails,
      locationType,
      onlineName,
      onlineTypeOfInviteLink,
    } = this.state
    const hasPlacename =
      locationType && locationType === locationTypeIds.online
        ? (onlineDetails && onlineDetails.length > 0) ||
          (onlineTypeOfInviteLink?.type &&
            onlineTypeOfInviteLink?.type.length > 0)
        : (customPlaceName && customPlaceName.length > 0) ||
          (placeName && placeName.length > 0) ||
          (value && value.length > 0) ||
          (details && details.length > 0)
    if (!isSpecificDateTime) {
      this.updateSendButton(hasPlacename)
    }
  }

  handleOpenMapScreen = () => {
    const { placeName, details, googlePlaceId } = this.state
    // routeLocation({
    //     locationItem: {
    //         title: placeName,
    //         name: details,
    //         googlePlaceId,
    //     },
    //     editNewItemLocation: async ({ location }) => {
    //         const predictionObject = {}
    //         if (location.structured_formatting.main_text) {
    //             predictionObject.mainText =
    //                 location.structured_formatting.main_text
    //         }
    //         predictionObject.description = location.description
    //         predictionObject.id = location.id
    //         predictionObject.place_id = location.place_id
    //         // const response = await backend.findByPlaceId(location.place_id)
    //         const response = {}

    //         const item = response.results[0]

    //         if (item) {
    //             predictionObject.description = item.formatted_address
    //             predictionObject.coordinates = item.geometry.location
    //         }
    //         if (location.structured_formatting.secondary_text) {
    //             const locationArr = location.structured_formatting.secondary_text.split(
    //                 ', '
    //             )
    //             const arLength = locationArr.length
    //             predictionObject.secondaryText =
    //                 arLength >= 2
    //                     ? [
    //                           locationArr[arLength - 2],
    //                           locationArr[arLength - 1],
    //                       ].join(', ')
    //                     : location.structured_formatting.secondary_text
    //             const descriptionArr = predictionObject.description.split(
    //                 ', '
    //             )
    //             const descriptionArrLength = descriptionArr.length
    //             descriptionArr[descriptionArrLength - 1] =
    //                 locationArr[arLength - 1]
    //             descriptionArr[descriptionArrLength - 2] =
    //                 locationArr[arLength - 2]
    //             predictionObject.description = descriptionArr.join(', ')
    //         }
    //         const isPlaceName = item
    //             ? item.types.some((type) =>
    //                   placeNameTypes.find((item) => type === item)
    //               )
    //             : false
    //         const {
    //             description,
    //             details = null,
    //             coordinates = null,
    //             place_id = null,
    //             mainText = null,
    //         } = predictionObject

    //         this.selectLocationValue({
    //             type: isPlaceName
    //                 ? SELECT_LOCATION_TYPES.placeName
    //                 : SELECT_LOCATION_TYPES.details,
    //             value: mainText,
    //             details: description || details,
    //             coordinates,
    //             googlePlaceId: place_id,
    //         })
    //     },
    //     isFullFormat: true,
    // })
  }

  onToggleTimezone = (value) => {
    this.setState({ isTimeZoneEnabled: value })
  }

  onIndexChange = (sceneIndex) => {
    Keyboard.dismiss()
    this.setState({ locationType: null, whenType: null })
    const { type, isOnlineTabOpened, isOnlineTab } = this.props
    const currentTab = this.routes[sceneIndex].key
    const isWhen = type === GAMEPLAN_OPTIONS.when
    const isWhere = type === GAMEPLAN_OPTIONS.where
    let stateUpdate = {}
    stateUpdate.canSubmit = false
    let isSpecificDateTime = false

    if (isWhen && sceneIndex === 0) {
      stateUpdate.canSubmit = true
      isSpecificDateTime = true
      // this.zonesViewRef.scrollToSelectedTime(() => {}, true, true)
    }
    if (isWhen && sceneIndex === 1) {
      stateUpdate = {
        whenType: whenTypeIds[currentTab],
      }
    }
    if (isWhere) {
      stateUpdate = {
        locationType: locationTypeIds[currentTab],
      }
    }

    this.setState({ sceneIndex, ...stateUpdate }, () => {
      this.handleUpdateSuggestButton(isSpecificDateTime)
    })
    isOnlineTabOpened(!isOnlineTab)
  }

  customTimeInputNotDelayed = (inputValue2) => {
    const value2 = inputValue2.trim()

    this.setState({
      value2,
      isCustomTime: true,
      canSubmit: value2.length > 0,
    })
    this.props.newCustomTime(value2)
  }

  closeOtherPickerIOS = (pickerKey, closePicker) => {
    this.setState({
      [pickerKey]: !this.state[pickerKey],
      [closePicker]: false,
    })
  }

  onCancelPress = (type) => {
    switch (type) {
      case "date": {
        this.setState({
          isCustomDate: true,
          value: null,
          dateWasSelected: false,
          canSubmit: Boolean(
            this.state.value2 ||
              (!this.state.isCustomTime && this.state.startDateFreestyle)
          ),
        })
        break
      }
      case "time":
        this.setState({
          isCustomTime: true,
          value2: null,
          timeWasSelected: false,
          canSubmit: Boolean(
            this.state.value ||
              (!this.state.isCustomDate && this.state.startDateFreestyle)
          ),
        })
        break
    }
  }

  changeDuration = () => {
    this.onTimePicker("duration", this.props.componentId, false, false)
  }

  checkForTodaysDate = (date) => {
    this.setState({
      isStartDateToday: isToday(date),
    })
  }
  // handleSelectLocation = (props) => () => showSearchLocationList(props)
  handleSelectLocation = (props) => () => {}

  handleChangeValue = (value) => {
    this.setState({ value, canSubmit: !!value })
  }

  handleChangePlaceName = (placeName) =>
    this.setState({ placeName }, this.handleUpdateSuggestButton)

  handleChangeOnlineName = (onlineName) => {
    this.setState({ onlineName }, this.handleUpdateSuggestButton)
  }

  handleChangeCustomDetails = (onlineDetails) => {
    const linkWithMetadata = getSuggestionOnlineValuesFromLink(onlineDetails)
    if (linkWithMetadata) {
      this.setState(
        {
          onlineTypeOfInviteLink: linkWithMetadata,
          onlineDetails: "",
          showDetails: true,
        },
        this.handleUpdateSuggestButton
      )
    } else {
      this.setState({ onlineDetails }, this.handleUpdateSuggestButton)
    }
  }

  clearValue = (value) => () =>
    this.setState(
      {
        [value]: null,
        ...(value === "details" ? { googlePlaceId: null } : {}),
      },
      this.handleUpdateSuggestButton
    )

  clearPlaceAddressInfo = () => {
    this.setState(
      {
        placeName: null,
        details: null,
        coordinates: null,
        googlePlaceId: null,
      },
      this.handleUpdateSuggestButton
    )
  }

  handlSetTemporaryPlace = (tempPlace) => {
    this.setState({ temporaryPlace: tempPlace })
  }

  handlePlaceSelection = async (suggestion) => {
    this.setState(
      {
        placeName: suggestion.formattedSuggestion.mainText,
        details: suggestion.formattedSuggestion.secondaryText,
        temporaryPlace: "",
      },
      this.handleUpdateSuggestButton
    )
  }

  renderScene = (route) => {
    const { temporaryPlace } = this.state
    const { currentUserHomeLocation, isFromCreateTeeup } = this.props
    switch (route.key) {
      case "specificTime":
        return this.renderSpecificTimeDateTab()
      case "freestyleText":
        return this.renderFreestyleText()
      case "realWorld":
        return (
          <GoogleMap
            temporaryPlace={temporaryPlace}
            handlSetTemporaryPlace={this.handlSetTemporaryPlace}
            handlePlaceSelection={this.handlePlaceSelection}
            isFromCreateTeeup={isFromCreateTeeup}
          />
        )
      case "online":
        return this.renderWhereFields("online")
      default:
        return null
    }
  }

  renderWhereFields = (field) => {
    const { coordinates, details, placeName, googlePlaceId, showDetails } =
      this.state

    const isMapEnabled =
      !_.isEmpty(coordinates) && !_.isEmpty(details) && !_.isEmpty(placeName)
    return (
      <View style={GameplanStyles.whereContainer} pointerEvents="box-none">
        <View
          style={
            field === "realWorld"
              ? GameplanStyles.whereFieldsContainer
              : whereOnlineFieldsContainer(showDetails)
          }
          pointerEvents="box-none"
        >
          {this.getRenderedFields(field)}
        </View>
        {field === "realWorld" && (isMapEnabled || googlePlaceId) && (
          <MapPartComponent
            coordinates={coordinates}
            googlePlaceId={googlePlaceId}
            details={details}
            placeName={placeName}
          />
        )}
      </View>
    )
  }
  renderFreestyleText = () => {
    const { isCustomDate } = this.state
    const renderedFields = this.getRenderedFields()
    return (
      <View
        style={[
          GameplanStyles.mainContainer,
          GameplanStyles.container,
          GameplanStyles.containerWhen,
        ]}
      >
        <View style={GameplanStyles.fieldsContainer}>{renderedFields}</View>

        {isCustomDate && (
          <View style={GameplanStyles.exactDateWarning}>
            <Icon
              type="font-awesome5"
              name="alert-circle-outline"
              size={12}
              color={AppColors.brand.orange}
            />
            <Text style={GameplanStyles.exactDateWarningText}>
              Feel free to make this suggestion, but the TeeUp can’t be added to
              your calendar later without an exact date.
            </Text>
          </View>
        )}
      </View>
    )
  }

  renderSpecificTimeDateTab = () => {
    const { type, suggestions, fromCreate, isTemporalCustom } = this.props
    const {
      isTimeZoneEnabled,
      showDatePicker,
      showTimePicker,
      showEndDatePicker,
      showEndTimePicker,
      timezoneWasScrolled,
      duration,
      isStartDateToday,
      dateArray,
      startDateIndex,
      endDateIndex,
      details,
      value,
      onlineName,
      onlineDetails,
      locationType,
      placeName,
      isDurationActive,
      isTimeDurationActive,
      onlineTypeOfInviteLink,
    } = this.state

    let suggestion = suggestions.length > 0 ? suggestions[0] : { ...this.state }
    if (onlineTypeOfInviteLink?.type || onlineDetails) {
      suggestion = {
        ...suggestion,
        type,
        value: value || onlineTypeOfInviteLink?.link,
        details: details || onlineDetails,
      }
      suggestion = {
        ...suggestion,
        type,
        ...(locationType === locationTypeIds.online
          ? { value: onlineTypeOfInviteLink?.link, details: onlineDetails }
          : { value: placeName, details }),
      }
    }
    const formattedSuggestion = this.getFormattedSuggestion()
    return (
      <TimeDateTab
        {...this.getParticipants(this.props)}
        startDate={suggestion.startDate}
        endDate={suggestion.endDate}
        isTemporalCustom={isTemporalCustom}
        suggestionType={type}
        isTimeZoneEnabled={true}
        isDurationActive={isDurationActive}
        isTimeDurationActive={isTimeDurationActive}
        isMultiTimeZone={isTimeZoneEnabled}
        showDatePicker={showDatePicker}
        showTimePicker={showTimePicker}
        showEndDatePicker={showEndDatePicker}
        showEndTimePicker={showEndTimePicker}
        timezoneWasScrolled={timezoneWasScrolled}
        duration={duration}
        isStartDateToday={isStartDateToday}
        startDateIndex={startDateIndex}
        endDateIndex={endDateIndex}
        dateArray={dateArray}
        suggestion={suggestion}
        formattedSuggestion={formattedSuggestion}
        onDatesSelected={this.onDatesSelected}
        onZonesScrolled={this.onZonesScrolled}
        // onZonesScrolled={() => {}}
        closeOtherPickerIOS={this.closeOtherPickerIOS}
        refFunction={() => {}}
        changeDuration={this.changeDuration}
        checkForTodaysDate={this.checkForTodaysDate}
        handleToggleTimeDuration={this.handleToggleTimeDuration}
        handleCalendar={this.handleCalendar}
        showLayout={(layout) => this.setState({ layout })}
        closeLayout={() => this.setState({ layout: null })}
        fromCreate={fromCreate}
      />
    )
  }

  handleScreenPress = () => Keyboard.dismiss()

  getRenderedFields = (field) =>
    RenderFields(
      this.getFieldsForRender(field),
      this.fieldPressed,
      this.props.componentId,
      this.onCancelPress
    )

  setShowDetails = (onlineTypeOfInviteLink) => {
    const secondLineSuggestOnline = onlineTypeOfInviteLink?.metadata.find(
      (item) => item.label === "Topic"
    )?.value
    this.setState({ showDetails: true })
    this.handleChangeCustomDetails(secondLineSuggestOnline)
    this.handleUpdateSuggestButton()
  }

  getFieldsForRender = (field) => {
    const {
      isEndDate,
      isEndTime,

      placeName,
      details,
      customPlaceName,
      value,
      value2,

      onlineDetails,
      onlineName,
    } = this.state
    const {
      type,
      title,
      currentUserHomeLocation,
      userCustomDates,
      userCustomTimes,
    } = this.props
    let fields = []
    const suggestion = this.getFormattedSuggestion()

    let dateData = []
    userCustomDates.forEach((val) =>
      dateData.push({ value: val, isHistory: true })
    )
    defaultCustomDates.forEach((val) => dateData.push({ value: val }))

    let timeData = []
    userCustomTimes.forEach((val) =>
      timeData.push({ value: val, isHistory: true })
    )
    defaultCustomTimes.forEach((val) => timeData.push({ value: val }))

    switch (type) {
      case GAMEPLAN_OPTIONS.when: {
        fields.push({
          selectSpecific: (componentId) =>
            this.onTimePicker("date", componentId, isEndDate),
          type: "date",
          label: "Date",
          hint: "Say anything",
          data: dateData,
          value,
          callback: this.onDateGenericSelected,
          icon: images.whenList,
        })
        fields.push({
          selectSpecific: (componentId) =>
            this.onTimePicker("time", componentId, isEndTime),
          type: "time",
          label: "Time",
          hint: "Say anything",
          data: timeData,
          value: value2,
          callback: this.onTimeGenericSelected,
          icon: images.whenList,
        })
        break
      }
      case GAMEPLAN_OPTIONS.where: {
        if (field === "realWorld") {
          const isSecondFieldValueSelected = placeName || details
          const placeTitleHint = "Suggest Where at this place..."
          fields.push({
            type: "button",
            isReadyForShow: true,
            value: customPlaceName,
            hint: isSecondFieldValueSelected
              ? placeTitleHint
              : `Suggest Where...`,
            onValueClear: this.clearValue(
              SELECT_LOCATION_TYPES.customPlaceName
            ),
            onFieldPress: this.handleSelectLocation({
              selectedValue: customPlaceName,
              type: SELECT_LOCATION_TYPES.customPlaceName,
              onSelectValue: this.selectLocationValue,
              label: "Address",
              textPlaceholder: isSecondFieldValueSelected
                ? placeTitleHint
                : "Type Place Name",
              currentUserHomeLocation,
              isPlaceLabelInput: isSecondFieldValueSelected,
            }),
            onFieldChange: this.handleChangeCustomPlaceName,
          })
          fields.push({
            type: "button",
            isReadyForShow: !!suggestion.value,
            value: placeName,
            subValue: details,
            icon: images.search,
            hint: "Add an address",
            onValueClear: this.clearPlaceAddressInfo,
            onFieldPress: this.handleSelectLocation({
              selectedValue: placeName,
              type: SELECT_LOCATION_TYPES.placeName,
              onSelectValue: this.selectLocationValue,
              label: "Place name",
              textPlaceholder: "Add an Address...",
              currentUserHomeLocation,
            }),
          })
        } else if (field === "online") {
          if (!this.state.showDetails) {
            fields.push({
              label: "Name / Description",
              type: "input",
              value: onlineName,
              hint: "Type anything",
              onFieldChange: this.handleChangeOnlineName,
              onInputClear: this.clearValue("onlineName"),
            })
            fields.push({
              label: "URL or Conference Details",
              type: "input",
              value: onlineDetails,
              hint: "Type anything",
              onFieldChange: this.handleChangeCustomDetails,
              onInputClear: this.clearValue("onlineDetails"),
              isMultiline: true,
              setShowDetails: () =>
                this.setShowDetails(this.state.onlineTypeOfInviteLink),
            })
          } else {
            fields.push({
              type: "details",
              onlineTypeOfInviteLink: this.state.onlineTypeOfInviteLink,
            })
          }
        }
        break
      }
      case GAMEPLAN_OPTIONS.what: {
        fields.push({
          type: "input",
          value,
          hint: title,
          onFieldChange: this.handleChangeValue,
          onInputClear: this.clearValue("value"),
        })
      }
    }
    return fields
  }

  contentLayout = ({ nativeEvent }) => {
    const height = nativeEvent.layout.height
    this.setState({
      contentHeight: height,
    })
  }

  getLocationSecondaryText = () => {
    const { details, placeName, customPlaceName } = this.state
    let secondaryText = details || ""
    const result =
      placeName && secondaryText && customPlaceName
        ? `, \n${secondaryText}`
        : secondaryText
    return result
  }

  getFormattedSuggestion = () => {
    const {
      locationType,
      startDateFreestyle,
      endDateFreestyle,
      isTimeDurationActive,
      isTimeZoneEnabled,
      whenType,
      onlineName,
      customPlaceName,
      placeName,
      onlineDetails,
      fullDetails,
      onlineTypeOfInviteLink,
      showDetails,
    } = this.state

    const { type } = this.props

    return {
      ...this.state,
      ...(type === GAMEPLAN_OPTIONS.where
        ? locationType === locationTypeIds.online
          ? {
              value: onlineTypeOfInviteLink?.type
                ? onlineTypeOfInviteLink.type
                : onlineName,
              details: onlineDetails,
            }
          : {
              value: customPlaceName || placeName,
              details: customPlaceName
                ? placeName
                  ? `${placeName}${this.getLocationSecondaryText()}`
                  : null
                : this.getLocationSecondaryText(),
              fullDetails: fullDetails,
            }
        : {}),
      ...(type === GAMEPLAN_OPTIONS.when
        ? whenType === whenTypeIds.freestyleText
          ? {
              startDate: startDateFreestyle,
              endDate: endDateFreestyle,
              isCustomDate: true,
              isCustomTime: true,
              isFreestyleValues: true,
            }
          : {
              value: null,
              value2: null,
              isCustomDate: false,
              isCustomTime: false,
              ...(!isTimeDurationActive &&
                !isTimeZoneEnabled && {
                  showTime: false,
                  endDate: null,
                }),
              isFreestyleValues: false,
            }
        : {}),
    }
  }

  onDurationSwitch = () =>
    this.setState((prevState) => ({
      isDurationActive: !prevState.isDurationActive,
    }))

  render() {
    const {
      startDate,
      isTimeZoneEnabled,
      multizoneUsers,
      index,
      sceneIndex,
      canSubmit,
      selected,
      isCustomDate,
      dateWasSelected,
      contentHeight,
      isCalendarShown,
      passedEvent,
      duration,
    } = this.state

    const {
      type,
      suggestions,
      title,
      recommendations,
      recommendationsIds,
      componentId,
      isTemporalCustom,
      allowPutOnGameplan,
      isFromCreateTeeup = false,
    } = this.props

    const gameplanRecommendations =
      recommendationsIds &&
      recommendationsIds.map(
        (recommendationId) => recommendations[recommendationId]
      )

    const renderedFields = this.getRenderedFields()

    const suggestion = this.getFormattedSuggestion()
    return (
      <GameplanView
        {...this.getParticipants(this.props)}
        onToggleTimezone={this.onToggleTimezone}
        routes={this.routes}
        isTemporalCustom={isTemporalCustom}
        renderScene={this.renderScene}
        onIndexChange={this.onIndexChange}
        onCalendar={this.handleCalendar}
        onClose={this.handleClose}
        onSubmit={this.handleDone}
        tabViewRef={(ref) => (this.tabViewRef = ref)}
        onScreenPress={this.handleScreenPress}
        onToggleAddingToGameplan={this.handleToggleAddingToGameplan}
        onLayout={this.contentLayout}
        onDatesSelected={this.onDatesSelected}
        onCloseCalendarCard={this.handleCloseCalendarCard}
        renderedFields={renderedFields}
        type={type}
        title={title}
        isTimeZoneEnabled={isTimeZoneEnabled}
        multizoneUsers={multizoneUsers}
        suggestion={suggestion}
        timeIndicatorLabel={startDate ? strings.startTime : strings.currentTime}
        recommendations={gameplanRecommendations}
        componentId={componentId}
        sceneIndex={sceneIndex}
        index={index}
        canSubmit={canSubmit}
        allowPutOnGameplan={allowPutOnGameplan}
        // selected={allowPutOnGameplan === true ? selected : false}
        selected={selected}
        isCustomDate={dateWasSelected ? isCustomDate : false}
        contentHeight={contentHeight}
        isCalendarShown={isCalendarShown}
        passedEvent={passedEvent}
        startDate={startDate}
        duration={duration}
        isFromCreateTeeup={isFromCreateTeeup}
        content={this.state.content}
        isModalOpened={this.state.isModalOpened}
        closeSuggestions={this.props.closeCreate}
        layout={this.state.layout}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const userInfo = selectUserInfo(state)
  const teeupId = selectActiveTeeupId(state)
  const teeupName = selectActiveTeeup(state).name
  const userCustomDates = selectUserCustomDates(state)
  const userCustomTimes = selectUserCustomTimes(state)
  const participants = selectTeeupParticipants(state)
  const recommendationsIds = selectTeeupRecommendationsIds(state)
  const recommendations = selectTeeupRecommendations(state)
  const people = selectTeeupPeople(state)
  const invitees = selectActiveTeeupInvited(state)
  const currentSuggestions = selectTeeupSuggestions(state)
  const currentUserHomeLocation = selectUserHomeLocation(state)
  const userTimezone = selectUserTimezone(state)
  const activeTeeup = selectActiveTeeup(state)
  const isOnlineTab = state.common.isOnlineTab

  return {
    participants,
    teeupId,
    teeupName,
    people,
    invitees,
    userInfo,
    userCustomDates,
    userCustomTimes,
    recommendationsIds,
    recommendations,
    currentSuggestions,
    currentUserHomeLocation,
    userTimezone,
    activeTeeup,
    isOnlineTab,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...teeupActions,
      updateUserStatus,
      isOnlineTabOpened,
    },
    dispatch
  )

GameplanContainer.propTypes = {
  // componentId: PropTypes.string.isRequired,
  updateGameplan: PropTypes.func.isRequired,
  // newCustomDate: PropTypes.func.isRequired,
  // newCustomTime: PropTypes.func.isRequired,
  title: PropTypes.string,
  suggestions: PropTypes.array,
  allowPutOnGameplan: PropTypes.bool,
  type: PropTypes.string,
  invitees: PropTypes.array,
  userInfo: PropTypes.object.isRequired,
  people: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  participants: PropTypes.array,
  userCustomDates: PropTypes.array.isRequired,
  userCustomTimes: PropTypes.array.isRequired,
  recommendations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  recommendationsIds: PropTypes.array.isRequired,
  currentSuggestions: PropTypes.object.isRequired,
  currentUserHomeLocation: PropTypes.object,
  optionId: PropTypes.number,
  index: PropTypes.number,
  teeupId: PropTypes.number,
  teeupName: PropTypes.string,
  userTimezone: PropTypes.string,
  isFromCreateTeeup: PropTypes.bool,
  shouldPutInGameplan: PropTypes.bool,
  createSuggestionCallback: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(GameplanContainer)
