import React from "react"
import {
  View,
  NativeModules,
  findNodeHandle,
  TouchableOpacity,
  Animated,
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native"
import styles from "./styles"
import PropTypes from "prop-types"
import { Units } from "@theme"

const RCTUIManager = NativeModules.UIManager

const { height: screenHeight } = Dimensions.get("screen")
const isAndroid = Platform.OS === "android"

export default class Highlighter extends React.Component {
  _refContainer = {}
  _objContainer = {}
  _animatedValue = new Animated.Value(0)
  _bottomHeightConstant = 0
  _bottomHeight = 0

  state = {
    selectedView: null,
    top: 0,
    renderKids: null,
    visible: false,
  }

  show(show, onEnd) {
    const { duration } = this.props
    Animated.timing(this._animatedValue, {
      toValue: show ? 1 : 0,
      duration: duration ? duration : 300,
      useNativeDriver: true,
    }).start(onEnd)
  }

  componentDidMount() {
    this.setState({ renderKids: true })
  }

  setVisible = (highlight) => {
    if (highlight) {
      setTimeout(() => {
        const { useStaticTop } = this.props
        const originalView = this._objContainer[highlight]
        const selectedView = React.cloneElement(originalView)
        this.setState({ selectedView })
        try {
          if (highlight && this._refContainer[highlight]) {
            RCTUIManager.measure(
              findNodeHandle(this._refContainer[highlight]),
              (_x, _y, _width, height, _pageX, pageY) => {
                let top = !pageY && useStaticTop ? this.state.top : 0
                if (pageY && height) {
                  const { style } = originalView.props
                  if (style) {
                    if (style.marginTop) {
                      top = style.marginTop
                    } else if (style.margin) {
                      top = style.margin
                    } else if (style.marginVertical) {
                      top = style.marginVertical
                    }
                  }

                  top = pageY - top
                  if (
                    screenHeight <
                      this._bottomHeightConstant +
                        top +
                        height +
                        Units.responsiveValue(100) &&
                    this._bottomHeightConstant > 0
                  ) {
                    this._bottomHeight =
                      this._bottomHeightConstant -
                      (screenHeight - top - this._bottomHeightConstant) +
                      Units.responsiveValue(isAndroid ? 35 : 0)

                    this._bottomHeight += height
                    if (this._bottomHeight < 0) this._bottomHeight = 0
                  }
                }
                this.setState({ top, visible: true }, () => {
                  this.show(true)
                })
              }
            )
          }
        } catch (e) {
          //
        }
      }, 0)
    } else if (this.state.visible) {
      this._bottomHeight = 0
      this._bottomHeightConstant = 0
      this.show(false, () => this.setState({ visible: false }))
    }
  }

  setRef = (key, ref, overwrite = true) => {
    if (overwrite || !this._refContainer[key]) this._refContainer[key] = ref
  }

  setViewObj = (key, obj) => {
    this._objContainer[key] = obj
  }

  setBottomPanelHeight = (height) => {
    this._bottomHeightConstant = height
  }

  render() {
    const { children, style, dimAmount, mirrored } = this.props
    const { selectedView, renderKids, top, visible } = this.state
    return (
      <View
        style={[
          styles.root,
          {
            transform: [{ translateY: -this._bottomHeight }],
            // , height: '95%'
          },
        ]}
      >
        <View style={[styles.container, style]}>
          {renderKids && children}
          {visible && (
            <TouchableOpacity activeOpacity={1} style={StyleSheet.absoluteFill}>
              <View
              // style={styles.bottomCover(this._bottomHeight)}
              />

              <Animated.View
                pointerEvents="none"
                // style={styles.overlay(
                //     dimAmount,
                //     this._animatedValue,
                //     -this._bottomHeight
                // )}
              />
              <View
                pointerEvents="none"
                style={{
                  top,
                  transform: [
                    {
                      rotateX: mirrored ? "180deg" : "0deg",
                    },
                  ],
                }}
              >
                {selectedView}
              </View>
            </TouchableOpacity>
          )}
        </View>
      </View>
    )
  }
}

Highlighter.defaultProps = {
  useStaticTop: false,
}

Highlighter.propTypes = {
  highlight: PropTypes.string,
  onRequestClose: PropTypes.func,
  duration: PropTypes.number,
  dimAmount: PropTypes.number,
  mirrored: PropTypes.bool,
  // style: ViewPropTypes.style,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  useStaticTop: PropTypes.bool,
}
