import React from "react"
import PropTypes from "prop-types"
import { View, Text, Image, TouchableOpacity } from "react-native"
import { images } from "@utils/imageUtils"

import { CalendarEventsStyles as styles } from "./styles"

const CalendarEventBlock = ({
  title,
  positionStyles,
  hasConflist = false,
  showActionButton = false,
  onClick,
  coverLastColumn = true,
  compacted = false,
}) => {
  return (
    <View
      style={[
        styles.eventBlockContainer,
        !coverLastColumn &&
          (compacted ? styles.smallExtraPadding : styles.extraPadding),
        positionStyles,
      ]}
    >
      <View
        style={[
          styles.calendarEventContainer,
          hasConflist
            ? styles.conflictedCalendarEventContainer
            : styles.basicCalendarEventContainer,
          !compacted && styles.eventContainerPadding,
        ]}
      >
        <Text
          style={[
            styles.calendarEventText,
            hasConflist
              ? styles.conflictedCalendarEventText
              : styles.basicCalendarEventText,
          ]}
          numberOfLines={compacted ? 1 : null}
          ellipsizeMode={"clip"}
        >
          {title}
        </Text>
        {showActionButton && (
          <TouchableOpacity
            style={styles.calendarEventActionButtonContainer}
            onPress={onClick}
          >
            <Image
              source={images.magicWand}
              style={[
                styles.magicWandIcon,
                hasConflist
                  ? styles.conflictedCalendarEventIcon
                  : styles.basicCalendarEventIcon,
              ]}
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

CalendarEventBlock.propTypes = {
  title: PropTypes.string,
  positionStyles: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  hasConflist: PropTypes.bool,
  showActionButton: PropTypes.bool,
  onClick: PropTypes.func,
  coverLastColumn: PropTypes.bool,
  compacted: PropTypes.bool,
}

export default CalendarEventBlock
