import React from "react"
import { Scrollbars } from "react-custom-scrollbars"

const SlimScroll = ({ children, renderView, hideHorizontal }) => {
  const renderThumbVertical = ({ style, ...props }) => {
    const thumbStyle = {
      cursor: "pointer",
      borderRadius: "inherit",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      visibility: "hidden",
    }
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        className="scroll-thumb"
        {...props}
      />
    )
  }

  const renderTrackHorizontal = ({ style, ...props }) => {
    const thumbStyle = {
      display: hideHorizontal && "none",
      cursor: "pointer",
      borderRadius: "inherit",
      // backgroundColor: 'rgba(0, 0, 0, 0.2)',
      visibility: "hidden",
      width: "100%",
      height: 2,
      bottom: 2,
    }

    return (
      <div
        style={{ ...style, ...thumbStyle }}
        className="scroll-thumb"
        {...props}
      />
    )
  }

  const renderTrack = ({ style }) => {
    const thumbStyle = {
      position: "absolute",
      width: 8,
      right: 4,
      bottom: 2,
      top: 2,
      borderRadius: 3,
    }
    return <div style={{ ...style, ...thumbStyle }} />
  }

  return (
    <Scrollbars
      renderView={renderView}
      renderThumbVertical={renderThumbVertical}
      renderTrackVertical={renderTrack}
      renderTrackHorizontal={renderTrackHorizontal}
    >
      {children}
    </Scrollbars>
  )
}

export default SlimScroll
