import React from "react"
import PropTypes from "prop-types"

import ZonesContainer from "./zones/zonesContainer"
import { getSuggestionValue } from "@utils/gamePlanUtils"

class TimezonesViewTime extends React.Component {
  render() {
    const {
      isTemporalCustom,
      overlappingEvents,
      participants,
      people,
      refFunction,
      onZonesScrolled,
      isTimeZoneEnabled,
      startDate,
      endDate,
      duration,
      selectedDate,
      hasConflict,
      useConflicts,
      conflicts,
      columnsAmount,
      isMultiTimeZone,
      isTimeDurationActive,
      handleCalendar,
      suggestionType,
      formattedSuggestion,
      fromCreate,
      handleDurationPick,
    } = this.props
    const suggestionValues = getSuggestionValue(
      formattedSuggestion,
      suggestionType
    )
    return (
      <React.Fragment>
        <ZonesContainer
          refFunction={refFunction}
          participants={participants}
          isTemporalCustom={isTemporalCustom}
          people={people}
          isVisible={isMultiTimeZone || isTimeDurationActive}
          // isStartDateToday={isStartDateToday}
          onZonesScrolled={onZonesScrolled}
          isTimeZoneEnabled={isTimeZoneEnabled}
          startDate={startDate}
          endDate={endDate}
          duration={duration}
          selectedDate={selectedDate}
          conflicts={conflicts}
          useConflicts={useConflicts}
          columnsAmount={columnsAmount}
          overlappingEvents={overlappingEvents}
          handleCalendar={handleCalendar}
          isMultiTimeZone={isMultiTimeZone}
          hasConflict={hasConflict}
          fromCreate={fromCreate}
          suggestionValues={suggestionValues}
          handleDurationPick={handleDurationPick}
        />
      </React.Fragment>
    )
  }
}

TimezonesViewTime.propTypes = {
  participants: PropTypes.array,
  people: PropTypes.object,
  refFunction: PropTypes.func,
  onZonesScrolled: PropTypes.func,
  isTimeZoneEnabled: PropTypes.bool,
  onStartTimePicker: PropTypes.func,
  changeDuration: PropTypes.func,
  changeTime: PropTypes.func,
  onEndTimePicker: PropTypes.func,
  startTimeVal: PropTypes.string,
  endTimeVal: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isStartDateToday: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  showEndTimePicker: PropTypes.bool,
  onStartTimeSelected: PropTypes.func,
  onEndTimeSelected: PropTypes.func,
  onToggleEndEnable: PropTypes.bool,
  isEndEnabled: PropTypes.bool,
  duration: PropTypes.number,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hasConflict: PropTypes.bool,
  useConflicts: PropTypes.bool,
  columnsAmount: PropTypes.number,
  conflicts: PropTypes.object,
  overlappingEvents: PropTypes.array,
  changeTimeDuration: PropTypes.func,
  isMultiTimeZone: PropTypes.bool,
  isTimeDurationActive: PropTypes.bool,
  onTimeDurationSwitch: PropTypes.func,
  handleCalendar: PropTypes.func,
}

export default TimezonesViewTime
