import React, { useEffect, useState } from "react"

import MeetingLink from "./MeetingLink"

import useOnlineTabState from "../../Hooks/useOnlineTabState"
import { images } from "@utils/imageUtils"

import './index.scss'

const MeetingDetails = () => {
  const {
    activeApp,
    activeButtonId,
    setActiveButtonId,
    addSuggestAppName,
    addSuggestDetails
  } = useOnlineTabState()

  const [appName, setAppName] = useState("")
  const [comment, setComment] = useState("")
  const [pasteText, setPasteText] = useState(null)

  useEffect(() => {
    if (activeApp.title === "Other") {
      appName.length > 0
        ? setActiveButtonId(1)
        : setActiveButtonId(0)
    }
  }, [appName])

  useEffect(() => {
    activeButtonId !== 2 && setPasteText(null)
  }, [activeApp.id])

  useEffect(() => {
    addSuggestAppName(appName)
  }, [appName])

  const handlePasteLink = () => {
    navigator.clipboard.readText()
      .then(text => {
        text && setPasteText(text)
        setActiveButtonId(2)
        addSuggestDetails(text)
      })
  }

  return (
    <div className="suggest-online-details">
      {activeApp.title === "Other" && (
        <div className="suggest-online-details__app">
          <h3 className="suggest-online__title">Add name</h3>
          <div className="suggest-online-details__app--name">
            {appName.length === 0 && <p className="suggest-online-details__app--required">*</p>}
            <input
              type="text"
              className="suggest-online-details__app--input"
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
              placeholder="What is the app name?"
            />
            {appName.length > 0 && (
              <div
                className="suggest-online-details--clear"
                onClick={() => setAppName("")}
              ></div>
            )}
          </div>
        </div>
      )}

      <div className="suggest-online-details__top">
        <h3 className="suggest-online__title">
          {activeButtonId === 2 
            ? "Meeting details"
            : "Add meeting details"
          }
        </h3>

        <div className="suggest-online-details__meaning">
          <img src={images.ndSuggestDetails} alt="meaning" style={{ marginRight: 4 }} />
          What does this mean?
        </div>
      </div>

      {pasteText && (
        <MeetingLink pasteText={pasteText} setPasteText={setPasteText} />
      )}

      {activeButtonId !== 2 && !pasteText && (
        <div className="suggest-online-details__buttons">
          {activeApp.title === "Zoom" && (
            <div className="suggest-online-details__button">
              <img src={images.ndSuggestCreateMeeting} alt="create meeting" style={{ marginRight: 4 }} />
              Create a Zoom meeting
            </div>
          )}
          <div
            className="suggest-online-details__button"
            onClick={() => handlePasteLink()}
          >
            <img src={images.ndSuggestPasteLink} alt="paste link" style={{ marginRight: 4 }} />
            Paste meeting link or message
          </div>
        </div>
      )}

      {activeButtonId === 2 && (
        <div className="suggest-online-details__comments">
          <h3 className="suggest-online__title">Comment</h3>
          <textarea
            className="suggest-online-details__textarea"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Add a comment..."
          />
        </div>
      )}
    </div>
  )
}

export default MeetingDetails
