import { useEffect } from "react"
import { useSelector } from "react-redux";

import { useProfilePageDispatch, useProfilePageState } from "./ProfilePageContext"
import {
  PROFILE_PAGE_SET_IS_TAG_USER,
  PROFILE_PAGE_SET_USER_INFO,
  PROFILE_PAGE_SET_IS_THREE_DOTS_OPEN
} from "./ProfilePageActions";

import { fetchProfileWithTags } from "@actions/userActions"
import { selectUserId } from "@selectors/user"

const useProfilePageUser = (id) => {
  const context = useProfilePageDispatch()
  const { userInfo, isTagUser, isThreeDotsOpen } = useProfilePageState()

  const currentUserId = useSelector(selectUserId)

  useEffect(() => {
    id && isTagUser &&
      (async () => {
        const curUserInfo = await fetchProfileWithTags(id, currentUserId)

        context({ type: PROFILE_PAGE_SET_USER_INFO, payload: curUserInfo })
        context({ type: PROFILE_PAGE_SET_IS_TAG_USER, payload: false })
      })()
  }, [isTagUser])

  const setIsTagUser = (value) => {
    context({
      type: PROFILE_PAGE_SET_IS_TAG_USER,
      payload: value
    })
  }

  const setIsThreeDotsOpen = (value) => {
    context({
      type: PROFILE_PAGE_SET_IS_THREE_DOTS_OPEN,
      payload: value
    })
  }

  return {
    userInfo,
    isTagUser,
    isThreeDotsOpen,
    setIsTagUser,
    setIsThreeDotsOpen
  }
}

export default useProfilePageUser
