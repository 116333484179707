import actionTypes from "./actionTypes"

import api from "../api"
import endpoints from "@config/endpoints"
import { store } from "../index"
import { loginProviders } from "@configs/enums"
import { saveUserAndRedirect, saveUser } from "./loginActions"
import { push } from "connected-react-router"
import { PASSWORD_RULES } from "../pages/SignUpPage/usePassword"

export const updateUserInfo = (userInfo) => ({
  type: actionTypes.UPDATE_USER_INFO,
  payload: userInfo,
})

export const setUserInfo = (userInfo) => ({
  type: actionTypes.SET_USER_INFO,
  payload: userInfo,
})

export const fetchUserInfo = (redirectToTeeups = false) => {
  api.client
    .get(endpoints.userInfo)
    .then((response) => {
      const { data } = response

      if (data.loginType === loginProviders.fb) {
        data.facebookUsername = data.nickname
      }

      if (data.loginType === loginProviders.twitter) {
        data.twitterUsername = data.nickname
      }

      store.dispatch(updateUserInfo(data))

      if (redirectToTeeups) {
        store.dispatch(push("/teeUps"))
      }
    })
    .catch((error) => console.log("fetch userInfo error", error))
}

export const fetchProfile = (userId) => {
  let url = endpoints.user.profile(userId)
  return api.client
    .get(url)
    .then((response) => {
      const { data } = response

      return data
    })
    .catch((error) => console.log("fetch profile error", error))
}

export const updateProfile = (data) =>
  api.client
    .patch(endpoints.user.editProfile, data)
    .then((response) => {
      const { data } = response

      return data
    })
    .catch((error) => console.log("fetch profile error", error))

export const getUserContactMechanisms = () => {
  const url = endpoints.contactMechanisms()

  return api.client
    .get(url)
    .then((response) => {
      const { data } = response

      store.dispatch({
        type: actionTypes.SET_USER_CONTACT_MECHANISMS,
        payload: data.contactMechanisms,
      })
    })
    .catch((error) => console.log("fetch profile error", error))
}

export const resetPassword = (password, token) => {
  return (dispatch) => {
    api.client
      .post(
        endpoints.resetPassword,
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const userInfo = {
          ...response.data,
          accessToken: token,
        }
        saveUserAndRedirect(userInfo, dispatch)
      })
      .catch((error) => {
        console.log("resetPassword error", error)

        if (
          error.response &&
          error.response.data &&
          error.response.status === 403
        ) {
          dispatch({
            type: actionTypes.SET_RESET_PASS_ERROR,
            payload: PASSWORD_RULES.PREVIOUS,
          })
        } else {
          dispatch({
            type: actionTypes.SET_RESET_PASS_ERROR,
            payload:
              "An error occurred while trying to change the password. Please try again.",
          })
        }
      })
  }
}

export const PasswordChange = (password, token) => {
  return (dispatch) => {
    api.client
      .post(
        endpoints.resetPassword,
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const userInfo = {
          ...response.data,
          accessToken: token,
        }
        saveUser(userInfo, dispatch)
      })
      .catch((error) => {
        console.log("resetPassword error", error)

        if (
          error.response &&
          error.response.data &&
          error.response.status === 403
        ) {
          dispatch({
            type: actionTypes.SET_RESET_PASS_ERROR,
            payload: PASSWORD_RULES.PREVIOUS,
          })
        } else {
          dispatch({
            type: actionTypes.SET_RESET_PASS_ERROR,
            payload:
              "An error occurred while trying to change the password. Please try again.",
          })
        }
      })
  }
}

export const getUserSettings = () => {
  let url = endpoints.settings
  return api.client
    .get(url)
    .then((response) => {
      const { data } = response

      store.dispatch({
        type: actionTypes.SET_SETTINGS,
        payload: data,
      })
    })
    .catch((error) => console.log("fetch profile error", error))
}

export const getUserLocation = () => {
  let url = endpoints.teeup.userLocation

  return api.client
    .get(url)
    .then((res) => {
      const { data } = res
      store.dispatch({
        type: actionTypes.GET_USER_PRIMARY_LOCATION,
        payload: data,
      })
    })
    .catch(e => console.log("get user location failed", e))
}

export const postUserLocation = (body) => {
  let url = endpoints.teeup.userLocation

  return api.client
    .post(url, {
      "longitude": body.longitude,
      "latitude": body.latitude,
    })
    .then((res) => {
      const { data } = res

      return data
    })
    .catch(e => console.log("post user location failed", e))
}

export const fetchProfileWithTags = (userId, currentUserId) => {
  let url = endpoints.user.profileWithTags(userId, currentUserId)

  return api.client
    .get(url)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => console.log("fetch profile error", error))
}

export const getBlacklist = () => {
  const url = endpoints.blacklist()

  return api.client
    .get(url)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => console.log("fetch blacklist error", error))
}

export const getUserTags = (userId) => {
  const url = endpoints.getUserTags(userId)
  return api.client
    .get(url)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => console.log("fetch user tags error", error))
}

export const createUserTag = (userId, tagName) => {
  const url = endpoints.createUserTag(userId)
  return api.client
    .post(url, { value: tagName.trim() })
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => console.log("create user tags error", error))
}

export const deleteUserTag = (userId, tagId) => {
  const url = endpoints.deleteUserTag(userId, tagId)
  return api.client
    .delete(url)
    .catch((error) => console.log("delete user tags error", error))
}

export const addTagsToUsers = (userId, body, onTagUsersEnd) => {
  const url = endpoints.tagUsers(userId)

  return api.client
    .post(url, body)
    .then(() => {
      if (onTagUsersEnd) {
        onTagUsersEnd()
      }
    })
    .catch((error) => {
      console.log("tagUsers error", error)
    })
}

export const removeTagsToUsers = (userId, body, onTagUsersEnd) => {
  const url = endpoints.removeTags(userId)

  return api.client
    .delete(url, { data: body })
    .then(() => {
      if (onTagUsersEnd) {
        onTagUsersEnd()
      }
    })
    .catch((error) => {
      console.log("removeTags error", error)
    })
}

export const setUserIsLoading = (payload) => ({
  type: actionTypes.SET_USER_IS_LOADING,
  payload,
})
export const verifyPassword = (password) => {
  return api.client.post(endpoints.verifyPassword, { password })
}
