import React from "react"
import PropTypes from "prop-types"
import { View, Text, Platform } from "react-native"

import { DatePicker } from "@ui/scrollablePicker"
import { ViewStyles, TimeRangeStyles } from "./timePickerOverlayStyles"

const TimeRangePicker = ({
  startDate,
  startTime,
  endDate,
  endTime,
  timezone,
  onStartTimeSelected,
  onEndTimeSelected,
}) => {
  return (
    <View style={TimeRangeStyles.container}>
      <View style={TimeRangeStyles.pickerContainer}>
        <View
          style={[TimeRangeStyles.rowContainer, ViewStyles.rowContainerBorder]}
        >
          <Text style={ViewStyles.rowPickerTitle}>Start Time</Text>
        </View>
        <View style={TimeRangeStyles.wheelContainer}>
          <DatePicker
            timezone={timezone}
            initDate={new Date(startTime)}
            mode="time"
            onDateSelected={onStartTimeSelected}
            hideDate={true}
            startDate={new Date(startDate)}
            format24={false}
            style={
              Platform.OS === "ios"
                ? TimeRangeStyles.dateWheelContainer
                : TimeRangeStyles.dateWheelContainerAndroid
            }
          />
        </View>
      </View>
      <View style={TimeRangeStyles.pickerContainer}>
        <View
          style={[TimeRangeStyles.rowContainer, ViewStyles.rowContainerBorder]}
        >
          <Text style={ViewStyles.rowPickerTitle}>End Time</Text>
        </View>
        <View style={TimeRangeStyles.wheelContainer}>
          <DatePicker
            timezone={timezone}
            initDate={new Date(endTime)}
            mode="time"
            onDateSelected={onEndTimeSelected}
            hideDate={true}
            startDate={new Date(endDate)}
            format24={false}
            style={
              Platform.OS === "ios"
                ? TimeRangeStyles.dateWheelContainer
                : TimeRangeStyles.dateWheelContainerAndroid
            }
          />
        </View>
      </View>
    </View>
  )
}

TimeRangePicker.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onStartTimeSelected: PropTypes.func,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onEndTimeSelected: PropTypes.func,
  timezone: PropTypes.string,
}

export default TimeRangePicker
