import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"

import cn from "classnames"

import { images } from "@utils/imageUtils"
import ContactBookContainer from "../ContactBook/ContactBookContainer"

import {
  selectUserId,
  selectContactsSyncedAt,
  selectContactsSyncSettings,
  selectUserVerifiedCountryCode,
} from "@selectors/user"

import { selectTeeupsPeople } from "@selectors/teeups"

import {
  selectAllContactsWithoutInvitedToTeeup,
  selectAllContactsWithoutInvitedToGroup,
  selectAllContacts,
  selectContactsByTags,
  selectTags,
} from "@selectors/contacts"
import { selectConnectionRequests } from "@selectors/requests"
import Toast from "../ui/toast"

import {
  contactTypes,
  getInviteesWithoutDuplicates,
  prepareManualContact,
  formatContact,
  userTypes,
} from "@utils/contactUtils"

import { batchVerifyAndGetUserInfo } from "../../actions/batchVerifyAndGetUserInfo"

import AddManualContactsContainerNd from "../AddInvitees/AddContactsNd/AddManualContactsContainerNd"

import "./index.scss"

const showToastAboutDuplicates = () => {
  Toast.show("This user is already invited. Duplicate was removed", {
    bottomOffset: 8,
  })
}

const InvitePeopleNd = ({
  setInvitees,
  invitees,
  onClose,
  userPhoneNumbers,
  userEmails,
  alreadyInvitedIds = [],
  setInviteToTeeUpById = () => {},
}) => {
  const [activeInviteesTab, setActiveInviteesTab] = useState(1)
  const [inviteBy, setInviteBy] = useState("")
  const [currentCheckbox, setCurrentCheckbox] = useState(1)

  const [error, setError] = useState(false)

  const [manualInvitees, setManualInvitees] = useState(
    invitees.filter((invite) => invite?.userType === userTypes.MANUAL)
  )

  const [manualContactId, setManualContactId] = useState(0)

  useEffect(() => {
    if (setInvitees) setInvitees(invitees)

    const currentManualInvitees = invitees.filter(
      (invite) => invite?.userType === userTypes.MANUAL
    )
    if (manualInvitees.length !== currentManualInvitees.length) {
      setManualInvitees(currentManualInvitees)
    }
  }, [setInvitees])

  const handleAddManualContacts = async ({ contactType, data }) => {
    const userPhoneNumber = userPhoneNumbers.map((number) =>
      number.value.replace(/\s+/g, "")
    )
    const userEmail = userEmails.map((email) => email.value.toLowerCase())
    const manualInviteesValues = manualInvitees.map((invite) => invite?.value)
    const updatedData = [...manualInviteesValues, ...data]
    const newUniqueData = [...new Set(updatedData)]

    if (updatedData.length !== newUniqueData.length) {
      showToastAboutDuplicates()
    }
    const contactMechanismValues = newUniqueData
      .map((value) => value.replace(/\s+/g, "").toLowerCase())
      .filter((value) => {
        if (
          (contactType === contactTypes.PHONE &&
            userPhoneNumber.includes(value)) ||
          (contactType === contactTypes.EMAIL && userEmail.includes(value))
        ) {
          Toast.show(
            `You can't add yourself in TeeUp. Contact was removed from the list`,
            {
              bottomOffset: 8,
            }
          )
          return false
        }
        if (value.trim().length === 0) {
          return false
        }
        return true
      })
    let formattedInvitees = invitees
    try {
      let { users, contacts, rest } = await batchVerifyAndGetUserInfo(
        contactMechanismValues
      )
      if (manualInvitees.length) {
        setManualContactId(
          Math.min(...manualInvitees.map((invite) => invite.id))
        )
      }

      const formattedManualContacts = rest.map((value) => {
        // use negative id to avoid duplication to user id
        const alreadyAddedInvite = manualInvitees.find(
          (invite) => invite.value === value
        )
        if (!alreadyAddedInvite) {
          setManualContactId(manualContactId - 1)
        }
        return alreadyAddedInvite
          ? alreadyAddedInvite
          : prepareManualContact({
              manualContactId: manualContactId,
              contactType,
              value,
            })
      })
      formattedInvitees = formattedInvitees.concat(
        users,
        contacts.map((contact) => formatContact(contact)),
        formattedManualContacts
      )
      setManualInvitees([...formattedManualContacts])
    } catch (err) {
      console.warn("Error batchVerifyAndGetUserInfo: ", err.message)
    }

    setInvitees(
      getInviteesWithoutDuplicates({
        formattedInvitees,
        alreadyInvitedIds,
        showToastAboutDuplicates,
      })
    )
  }

  return (
    <div
      className="inviteNd__wrapper"
      onClick={() => {
        onClose(false)
      }}
    >
      <div
        className="inviteNd"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <h2 className="inviteNd__title">Invite to TeeUp</h2>
        <div
          className="inviteNd__cross--wrapper"
          onClick={() => {
            onClose(false)
          }}
        >
          <div className="inviteNd__cross"></div>
        </div>
        <div className="inviteNd__content">
          <div className="inviteNd__contacts">
            <ContactBookContainer
              activeInviteesTab={activeInviteesTab}
              setActiveInviteesTab={setActiveInviteesTab}
              setInvitees={setInvitees}
              invitees={invitees}
              setInviteToTeeUpById={setInviteToTeeUpById}
            />
          </div>
          <div className="inviteNd__rightside">
            <AddManualContactsContainerNd
              inviteBy={inviteBy}
              currentCheckbox={currentCheckbox}
              setInviteBy={setInviteBy}
              setCurrentCheckbox={setCurrentCheckbox}
              error={error}
              setError={setError}
              onDone={(contacts) => {
                handleAddManualContacts(contacts)
              }}
            />
            <div className="inviteNd-invitees__wrapper">
              <h3 className="inviteNd-invitees__title">
                {invitees.length > 0
                  ? `${invitees.length} people selected`
                  : "No people selected"}
              </h3>
              <div
                className={cn("inviteNd-invitees", {
                  "inviteNd-invitees--error": error,
                })}
              >
                {invitees.length > 0 &&
                  invitees.map(
                    ({
                      avatar,
                      firstName,
                      name,
                      userType,
                      label,
                      emails,
                      id,
                      value,
                    }) => {
                      return (
                        <div
                          className="inviteNd-invitees__item"
                          key={avatar + name}
                        >
                          {avatar ? (
                            <div
                              className="inviteNd-invitees__avatar"
                              style={{ backgroundImage: `url(${avatar})` }}
                            ></div>
                          ) : (
                            <div className="inviteNd-invitees__avatar inviteNd-invitees__avatar--manual">
                              {name ? name[0] : label[0]}
                            </div>
                          )}
                          <h5 className="inviteNd-invitees__name">
                            {name ? name : label}
                          </h5>
                          <div
                            className="inviteNd-invitees__cross--wrapper"
                            onClick={() => {
                              setInvitees(
                                invitees.filter((item) => item.id !== id)
                              )
                            }}
                          >
                            {/* <img
                            src={images.ndInviteesCross}
                            className="inviteNd-invitees__cross"
                          /> */}
                            <div className="inviteNd-invitees__cross"></div>
                          </div>
                        </div>
                      )
                    }
                  )}
                {invitees.length > 0 && (
                  <div className="inviteNd-invitees__button--wrapper">
                    <button
                      type="button"
                      className="inviteNd-invitees__button"
                      onClick={() => {
                        onClose(false)
                        setInviteToTeeUpById(true)
                      }}
                    >
                      Add Invitees
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, passedProps) => {
  const { isTeeupInvite, isGroupInvite, alreadyInvitedIds } = passedProps
  const userId = selectUserId(state)
  const contacts =
    alreadyInvitedIds && isTeeupInvite
      ? selectAllContactsWithoutInvitedToTeeup(state)
      : alreadyInvitedIds && isGroupInvite
      ? selectAllContactsWithoutInvitedToGroup(state)
      : selectAllContacts(state)
  const contactsByTags = selectContactsByTags(state)
  const tags = selectTags(state)
  const requests = selectConnectionRequests(state)
  const verifiedCountryCode = selectUserVerifiedCountryCode(state)
  const contactSyncedAt = selectContactsSyncedAt(state)
  const contactSyncSettings = selectContactsSyncSettings(state)
  const userPhoneNumbers = []
  const userEmails = []
  const teeupPeople = selectTeeupsPeople(state)
  const allContacts = selectAllContacts(state)

  return {
    userId,
    contacts,
    contactsByTags,
    tags,
    userPhoneNumbers,
    userEmails,
    requests,
    verifiedCountryCode,
    contactSyncedAt,
    contactSyncSettings,
    userPhoneNumbers,
    userEmails,
    allContacts,
    teeupPeople,
  }
}

export default connect(mapStateToProps, null)(InvitePeopleNd)
