import React, { useEffect, useRef, useState } from "react"
import ReactTooltip from "react-tooltip"

import SuggestModal from "./suggestModal"
import { images } from "@utils/imageUtils"
import "./index.scss"

const SuggestToolbar = ({ setPeopleSideBar, handleOpenSuggestion }) => {
  const [FromModal, setFromModal] = useState(true)

  const [content, setContent] = useState(null)
  const [isOptionOpened, setIsOptionOpened] = useState(false)
  const [animation, setAnimation] = useState("")
  const optionRef = useRef()

  const openOption = () => {
    setIsOptionOpened(true)
    setPeopleSideBar(true)
  }
  const closeOption = () => {
    setIsOptionOpened(false)
    setPeopleSideBar(false)
  }

  const [isOpen, setIsOpen] = useState(false)
  const node = useRef()

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  const closeWithAnimations = () => {
    document.body.style.overflow = "auto"
    setAnimation("right-slide")
    setTimeout(() => {
      closeOption()
      setAnimation("")
    }, 250)
  }

  return (
    <>
      <div
        ref={node}
        className="suggesttoolbar__wrapper"
        onClick={() => {
          setIsOpen(!isOpen)
          ReactTooltip.hide(node.current)
        }}
        data-tip={"Make Suggestion"}
        data-for="information-tooltip"
        onMouseOver={(e) => {
          e.stopPropagation()
          ReactTooltip.show(node.current)
        }}
        onMouseLeave={(e) => {
          e.stopPropagation()
          ReactTooltip.hide(node.current)
        }}
      >
        <SuggestModal
          isOpen={isOpen}
          openSuggestions={openOption}
          setIsOpen={setIsOpen}
          closeSuggestions={closeOption}
          FromModal={FromModal}
          showGameplanOptions={setContent}
          handleOpenSuggestion={handleOpenSuggestion}
        />
        <div className="content__wrapper">
          <div className="suggesttoolbar__content">
            <img src={images.suggestLamp}></img>
            {/* <span>Suggest</span> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default SuggestToolbar
