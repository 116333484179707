import React, { useState, useMemo } from "react"

import { SuggestModalHeader, SuggestModalWrapper } from "../components"
import Modal from "@components/Modal"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"
import OnlineTab from "./OnlineTab"
import InPersonTab from "./InPersonTab"

const SUGGEST_WHERE_TABS = [
  { id: "online", name: "Online" },
  { id: "inPerson", name: "In Person" },
]

const SuggestWhereModal = () => {
  const [activeTab, setActiveTab] = useState(SUGGEST_WHERE_TABS[0].id)

  const { closeSuggestWhereModal } = useTeeUpPage()

  const handleTabClick = (tabId) => {
    setActiveTab(tabId)
  }

  const handleClose = () => {
    closeSuggestWhereModal()
  }

  const content = useMemo(() => {
    switch (activeTab) {
      case "inPerson":
        return <InPersonTab />
      case "online":
      default:
        return <OnlineTab />
    }
  }, [activeTab])

  return (
    <Modal>
      <SuggestModalWrapper>
        <SuggestModalHeader
          title="Suggest Where"
          tabs={SUGGEST_WHERE_TABS}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          handleClose={handleClose}
        ></SuggestModalHeader>
        {content}
      </SuggestModalWrapper>
    </Modal>
  )
}

export default SuggestWhereModal
