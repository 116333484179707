import React from "react"
import GameplanContainer from "@components/CreateTeeUp/gameplanContainer"
import { SuggestModalContent } from "../../components"
import { useSelector } from "react-redux"
import { selectTeeupGameplans, selectPermissions } from "@selectors/teeups"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

const TimeWheelTab = () => {
  const { closeSuggestWhenModal } = useTeeUpPage()
  const gameplans = useSelector(selectTeeupGameplans)
  const permissions = useSelector(selectPermissions)
  const renderBody = () => {
    const { allowSelectGameplan } = permissions
    return (
      <GameplanContainer
        {...gameplans[0]}
        suggestions={[]}
        closeCreate={() => closeSuggestWhenModal()}
        isTemporalCustom={true}
        allowPutOnGameplan={
          allowSelectGameplan && !gameplans[0]?.suggestions?.length
        }
      />
    )
  }
  return (
    <>
      <SuggestModalContent>{renderBody()}</SuggestModalContent>
    </>
  )
}

export default TimeWheelTab
