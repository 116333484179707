import { createSelector } from "reselect"
import union from "lodash/union"
import {
  getEventsLayoutForDay,
  getTeeupsAndEventsArray,
  hasConflict,
  EVENT_TYPES,
  getEventWithTime,
  getEndDate,
} from "@utils/calendarUtils"
import { getDateWithTimezone, compareTimeStrings } from "@utils/dateUtils"
import { selectCalendarSyncSettings } from "@selectors/user"
import { selectActiveTeeups, selectArchivedTeeups } from "@selectors/teeups"
import { teeupStatusKeys } from "@configs/enums"
import { AppColors } from "@theme"
import CalendarState from "../reducers/calendarStorage"
import { create } from "lodash"
// import Calendar from "@components"

const selectCalendar = (state) => state.calendar

export const selectCalendarEventsByDay = createSelector(
  selectCalendar,
  () => CalendarState.calendarEventsByDay || {}
)

export const selectTeeupEventsByDay = createSelector(
  selectCalendar,
  () => CalendarState.teeupEventsByDay || {}
)

export const selectCalendarEvents = createSelector(
  selectCalendar,
  () => CalendarState.calendarEvents || {}
)

export const selectTeeupEvents = createSelector(
  selectCalendar,
  () => CalendarState.teeupEvents || {}
)

export const selectCalendarDays = createSelector(
  selectCalendar,
  () => CalendarState.calendarDays || []
)

export const selectBeforeCalendarDate = createSelector(
  selectCalendar,
  (calendar) => calendar.isBeforeCalendarDate
)

export const selectDisplayCalendar = createSelector(
  selectCalendar,
  (calendar) => calendar.isDisplayedCalendar
)

export const selectTeeupMarkedDates = createSelector(
  selectActiveTeeups,
  selectArchivedTeeups,
  selectTeeupEventsByDay,
  (teeups, archivedTeeups, teeupEventsByDay) => {
    const planning = {
      key: "planning",
      color: AppColors.brand.orange,
    }
    const allset = {
      key: "allset",
      color: AppColors.brand.green,
    }
    const happening = {
      key: "happening",
      color: AppColors.brand.blue,
    }
    let dateObject = {}
    const teeupsArray = [...teeups, ...archivedTeeups]
    Object.keys(teeupEventsByDay).forEach((date) => {
      const statuses = []
      const teeupsByDate = teeupEventsByDay[date]
      teeupsByDate.forEach((teeup) => {
        const itemTeeup = teeupsArray.find(
          (singleTeeup) => singleTeeup.id === teeup
        )
        if (itemTeeup) {
          if (itemTeeup.status === teeupStatusKeys.planning) {
            statuses.push(planning)
          } else if (itemTeeup.status === teeupStatusKeys.allset) {
            statuses.push(allset)
          } else if (itemTeeup.status === teeupStatusKeys.happening) {
            statuses.push(happening)
          }
        }
      })

      const formattedDate = getDateWithTimezone(date)
      if (formattedDate.isValid()) {
        const date = formattedDate.format("YYYY-MM-DD")

        dateObject[date] = { dots: union(statuses) }
      }
    })
    return dateObject
  }
)

export const selectCalendarByTimeInterval = (startDate, endDate) =>
  createSelector(
    selectCalendarDays,
    selectCalendarEventsByDay,
    selectTeeupEventsByDay,
    selectCalendarEvents,
    selectTeeupEvents,
    (
      calendarDaysList,
      calendarEventsByDay,
      teeupEventsByDay,
      calendarEvents,
      teeupEvents
    ) => {
      let calendar = {}
      for (const day of calendarDaysList) {
        if (getDateWithTimezone(day).isAfter(endDate)) {
          break
        }
        if (getDateWithTimezone(day).isSameOrAfter(startDate)) {
          calendar[day] = getTeeupsAndEventsArray(
            teeupEventsByDay[day],
            calendarEventsByDay[day],
            teeupEvents,
            calendarEvents
          )
        }
      }
      return calendar
    }
  )

export const hasConflictingEvents = (
  startDate,
  endDate,
  suggestionId,
  teeupId,
  optionId
) =>
  createSelector(selectCalendarForDay(startDate), (conflictingEvents) => {
    if (!endDate) {
      endDate = getEndDate({ startDate })
    }
    let res = {
      hasConflict: false,
      events: [],
    }

    if (!conflictingEvents || conflictingEvents.length === 0) {
      return res
    } else {
      // Look for overlapping events
      const event1 = {
        id: teeupId,
        startDate,
        endDate,
        suggestionId,
        optionId,
        type: EVENT_TYPES.teeup,
      }
      for (let i = 0, len = conflictingEvents.length; i < len; i++) {
        const event2 = getEventWithTime(conflictingEvents[i])
        if (
          event2.type === EVENT_TYPES.teeup &&
          event1.id === event2.id &&
          event1.optionId === event2.optionId
        ) {
          break
        }

        const conflict = hasConflict(event1, event2)
        if (conflict) {
          res.hasConflict = true
          res.events.push(`${event2.type}-${event2.id}`)
        }
      }
      return res
    }
  })

export const selectCalendarForDay = (date, hideOptions) =>
  createSelector(
    selectCalendarEventsByDay,
    selectTeeupEventsByDay,
    selectCalendarEvents,
    selectTeeupEvents,
    (calendarEventsByDay, teeupEventsByDay, calendarEvents, teeupEvents) => {
      const dayString = getDateWithTimezone(date).startOf("day").format()
      const calendar = getTeeupsAndEventsArray(
        teeupEventsByDay[dayString],
        calendarEventsByDay[dayString],
        teeupEvents,
        calendarEvents,
        hideOptions
      )
      return calendar
    }
  )

export const selectCalendarForWeek = (state) => (date) => {
  const startDate = getDateWithTimezone(date).startOf("week")
  const endDate = getDateWithTimezone(date).endOf("week")

  return selectCalendarByTimeInterval(startDate, endDate)(state)
}

export const selectCalendarForMonth = (state) => (date) => {
  const startDate = getDateWithTimezone(date).startOf("month")
  const endDate = getDateWithTimezone(date).endOf("month")

  return selectCalendarByTimeInterval(startDate, endDate)(state)
}

export const selectLatestTeeupEventDate = createSelector(
  selectTeeupEventsByDay,
  (teeupEvents) => {
    let latestTimeString = getDateWithTimezone().startOf("day").format()
    Object.keys(teeupEvents).map((eventTime) => {
      if (compareTimeStrings(latestTimeString, eventTime)) {
        latestTimeString = eventTime
      }
    })
    return latestTimeString
  }
)

export const calendarConflictsForTimeWheel = (
  calendarStart,
  calendarEnd,
  maxColumnsCount
) =>
  createSelector(
    selectCalendarByTimeInterval(calendarStart, calendarEnd),
    (conflictingEvents) => {
      const events = [].concat.apply([], Object.values(conflictingEvents))
      const { columnsAmount, mappedEventsList } = getEventsLayoutForDay(
        events,
        maxColumnsCount
      )
      if (
        !columnsAmount ||
        !mappedEventsList ||
        mappedEventsList.length === 0
      ) {
        return {
          useConflicts: false,
          mappedEventsList: [],
          columnsAmount: 0,
        }
      }

      return {
        useConflicts: true,
        mappedEventsList,
        columnsAmount,
      }
    }
  )

export const selectShouldSyncTeeupsCalendar = createSelector(
  selectCalendarSyncSettings,
  (calendarSyncSettings) => calendarSyncSettings?.syncTeeups
)
export const selectShouldSyncOnlyAllSet = createSelector(
  selectCalendarSyncSettings,
  (calendarSyncSettings) => calendarSyncSettings?.syncOnlyAllSet
)

export const selectSelectedCalendar = createSelector(
  selectCalendarSyncSettings,
  (calendarSyncSettings) => calendarSyncSettings?.calendar
)

export const selectAdditionalCalendars = createSelector(
  selectCalendarSyncSettings,
  (calendarSyncSettings) => calendarSyncSettings.additionalCalendars || []
)

export const selectCustomTimezone = createSelector(
  selectCalendarSyncSettings,
  (calendarSyncSettings) => calendarSyncSettings?.customTimezone
)
export const selectAvaliableCalendars = createSelector(
  selectCalendar,
  () => CalendarState.avaliableCalendars || []
)
export const selectCalendarPermissions = createSelector(
  selectCalendar,
  () => CalendarState.calendarPermissions
)

export const selectSelectedCalendarTitle = createSelector(
  selectAvaliableCalendars,
  selectSelectedCalendar,
  (avaliableCalendars, selectedCalendar) => {
    if (selectCalendar && avaliableCalendars.length) {
      const selectedCalendarInfo = avaliableCalendars.find(
        (calendar) => calendar.id === selectedCalendar
      )
      return selectedCalendarInfo ? selectedCalendarInfo?.title : ""
    } else {
      return ""
    }
  }
)

export const selectUserEmailCalendar = createSelector(
  selectCalendar,
  (calendar) => calendar.userEmail || null
)
