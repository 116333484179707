import React from "react"
import GoogleMapReact  from "google-map-react";

import './index.scss'
import { images } from "@utils/imageUtils";
import useInPersonTabState from "../../Hooks/useInPersonTabState";

const Marker = ({ text }) => <div className="suggest-in-person-map__marker">
  <img src={images.ndSuggestMarker}/>{text}</div>;

const MapContainer = () => {
  const { mapCenter, setMapCenter } = useInPersonTabState()

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      })
    }
  }

  return (
    <div className="suggest-in-person-map">
      <GoogleMapReact 
        center={mapCenter}
        zoom={13}
        options={{
          styles: [{
            featureType: 'poi.business',
            elementType: 'labels',
            stylers: [{
                visibility: 'on'
            }]
          }],
          zoomControl: false,
          fullscreenControl: false
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        <Marker
          lat={mapCenter.lat}
          lng={mapCenter.lng}
        />
      </GoogleMapReact >
      <div
        className="suggest-in-person-map__button"
        onClick={() => getCurrentLocation()}
      >
        Center on Current Location
      </div>
    </div>
  )
}

export default MapContainer;
