import React, { useEffect } from "react"
import { Text, ScrollView } from "react-native"
import PropTypes from "prop-types"

import { ServiceStyles } from "./moreStyles"

const PrivacyPolicyScreen = ({ componentId, contentRef }) => {
  useEffect(() => {
    contentRef.current.scrollIntoView()
  }, [])

  return (
    <ScrollView
      contentContainerStyle={ServiceStyles.container}
      style={{ height: "92vh", marginBottom: 0 }}
    >
      <Text style={ServiceStyles.contentHeading}>Introduction</Text>
      <Text style={ServiceStyles.contentText}>
        Coo-E LLC (Coo-E) is dedicated to protecting your personal information.
        This Privacy Policy applies to your use of any Coo-E-owned websites,
        software, or associated services (collectively the “Platform”). Our
        Privacy Policy discloses our privacy practices and explains to you how
        we use, collect, store, transfer, and share your personal information.
        Please review this Privacy Policy periodically as we may revise it from
        time to time without notice. If you do not agree with or accept our
        Privacy Policy in its entirety, you must not access or use the Platform.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Information Collected</Text>
      <Text style={ServiceStyles.contentText}>
        Personal data, or personal information, means any information about an
        individual from which that person can be identified. It does not include
        data where the identity has been removed or otherwise anonymized.
        <br />
        <br />
        Personal Information Collected Voluntarily Provided
        <br />
        <br />
        You will not be required to provide us with any personal information
        when you visit our Platform. However, portions of our Platform may
        require us to collect personal information about you. During your use of
        the Platform we may collect your name, email, gender, address, telephone
        number, username, password, identifier, organization/business
        information, additional personal information, interests, and other
        identifying details. Any personal information you submit via the
        Platform shall be used and disclosed in accordance with this Privacy
        Policy. We may also collect payment information that you make available
        to us for your use of the Platform. Please be aware that this payment i
        nformation shall be stored by third party payment processors.
        <br />
        <br />
        Personal Information Automatically Collected
        <br />
        <br />
        Whenever you use our Platform, we may collect non-identifying
        information from you, such as your IP address, interactions with the
        Platform, query information, location information, referring URL,
        browser, application interaction, mobile provider information, operating
        system, data usage, data transferred, and Internet Service Provider.
      </Text>
      <Text style={ServiceStyles.contentHeading}>
        Sharing and Use of Your Information
      </Text>
      <Text style={ServiceStyles.contentText}>
        This section explains how Coo-E may share or use your personal
        information. You consent to our use of your personal information for the
        following purposes:
        <br />
        <br />
        - CONTRACT ADMINISTRATION - We may use your personal information to
        negotiate, execute, renew and/or manage a contract with you.
        <br />
        - ACCESS AND COMMUNICATIONS TO OUR PLATFORM AND SERVICES - We may use
        your personal information to (1) set-up and manage your Coo-E account;
        (2) interact with you through our Platform (e.g. software updates,
        Platform announcements, etc.): (3) to allow other users to contact and
        communicate with you; and/or (4) manage and respond to your questions
        (e.g. technical, commercial or administrative) or requests for
        maintenance and support.
        <br />
        - USE OF THE PLATFORM - We may use your personal information to (1)
        enable you to enjoy the use of, and easily navigate the Platform; and/or
        (2) better understand your needs and interests.
        <br />
        - SHARING WITH THIRD PARTIES - We may use your personal information to
        share with other users of the Platform; to share with our partner
        companies or future affiliates for research, marketing, and other
        purposes. We may also share your information with service providers with
        whom we contract with such as contractors, web hosts, data providers and
        others so that we may provide you any services and allow you to access
        the Platform.
        <br />
        - TRAINING &amp; IMPROVEMENTS - We may use your personal information to
        (1) train our employees or contractors to allow for a better Platform
        experience; and/or (2) improve the Platform.
        <br />
        - DIRECT MARKETING AND INQUIRIES - We may use your personal information
        to contact you for additional products and services that you may be
        interested in or to respond to an inquiry.
        <br />
        - LEGAL PROCESS - We may share your information: if we believe that
        disclosure is reasonably necessary to comply with a law, regulation,
        legal or governmental request; to respond to a subpoena, court order,
        warrant, or other legal process; to enforce applicable Terms of Service
        or this Privacy Policy, including investigation of potential violations
        thereof; to protect the safety, rights, or property of the public, any
        person, or Coo-E; to detect, prevent, or otherwise address security,
        technical issues, or illegal or suspected illegal activities (including
        fraud); or as evidence in litigation in which we are involved, as part
        of a judicial or regulatory proceeding.
        <br />
        1. **Accessing and Editing Your Information**
        <br />
        <br />
        When using our Platform you may be able to access and edit some of your
        personal information via the Platform dashboard. If at any time, you
        have any questions or wish to review, change, or edit any additional
        personal information collected by us, please contact us at
        support@coo-e.com.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Removal of Information</Text>
      <Text style={ServiceStyles.contentText}>
        If you wish to have any of your personal information stored within the
        Coo-E Platform removed, please contact us at support@coo-e.com. Please
        be aware that removal requests are not processed instantaneously. There
        may be a reasonable delay in removing any personal information
        requested. Additionally, we will retain and use your personal
        information (even after a removal or deletion request), where required,
        so that we may fulfill our legal obligations, resolve disputes, and
        enforce our agreements.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Cookies</Text>
      <Text style={ServiceStyles.contentText}>
        We use cookies to identify you and to remember your logins. Therefore,
        you must have cookies enabled in your browser to use our Platform.
        Please be aware that some or all of our cookies may be accessible by
        third parties. Additionally, while using portions of our Platform, we
        may track your usage information so that we understand how you interact
        with our Platform.
      </Text>
      <Text style={ServiceStyles.contentHeading}>
        Opting Out and Do Not Track
      </Text>
      <Text style={ServiceStyles.contentText}>
        If you decide to provide us with your contact information, you agree
        that we may send you communications via text and email where you have
        consented. However, you may opt out of any communications and you may
        opt out of certain personal information submission where applicable. If
        you wish to opt out of certain communications or information collection,
        please do so via the Platform dashboard.
        <br />
        <br />
        We currently do not offer website functionality for you to opt out of
        any “do not track” signals or listings.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Third Parties</Text>
      <Text style={ServiceStyles.contentText}>
        Coo-E may post links to third party websites on our Platform, which may
        include information that we have no control over. When accessing a third
        party site through our Platform, you acknowledge that you are aware that
        these third party websites are not screened for privacy or security
        issues by us. Coo-E bears no responsibility for the information
        collected or used by any advertiser or third party website that you have
        visited through a link on our Platform. You must review that third
        party’s Terms of Service and Privacy Policy to understand how their
        information collection practices work.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Security Measures</Text>
      <Text style={ServiceStyles.contentText}>
        We make reasonable attempts to protect your information by using
        physical and electronic safeguards in accordance with industry
        standards. However, as our Platform is hosted electronically we can make
        no guarantees as to the security or privacy of your information. For
        this reason, we recommend that you use anti-virus software, robust
        passwords, firewalls, and other precautions to protect yourself from
        security and privacy threats.
      </Text>
      <Text style={ServiceStyles.contentHeading}>
        Your California Privacy Rights
      </Text>
      <Text style={ServiceStyles.contentText}>
        Coo-E permits residents of the State of California to use its Platform,
        and complies with the California Business and Professions Code §§
        22575-22579. If you are a California resident you may request certain
        information regarding our disclosure of personal information to any
        third parties for their direct marketing purposes. Various provisions
        throughout this Privacy Policy address requirements of the Californian
        privacy statutes. Although we do not disseminate your information to
        third parties without permission, you must presume that we collect
        electronic information from all visitors. You may contact us at
        support@coo-e.com with any questions regarding your California Privacy
        Rights.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Age Compliance</Text>
      <Text style={ServiceStyles.contentText}>
        We intend to fully comply with American and international laws
        respecting children’s privacy. Therefore, we do not collect or process
        any information for any persons under the age of 13. If you are under 13
        you may not access the Platform.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Transfer of Your</Text>
      <Text style={ServiceStyles.contentText}>
        If you are not a citizen of the United States please be aware that your
        personal information shall be transferred and processed in the United
        States. Please be aware that US laws may not be as protective as the
        laws of your local jurisdiction
      </Text>
      <Text style={ServiceStyles.contentHeading}>Merger and Acquisition</Text>
      <Text style={ServiceStyles.contentText}>
        In the event that Coo-E is involved in a bankruptcy, merger,
        acquisition, reorganization or sale of assets, your personal information
        may be sold or transferred as part of that transaction. Please be aware
        that once the information is transferred your privacy rights may change.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Amendments</Text>
      <Text style={ServiceStyles.contentText}>
        Like our Terms of Service, we may amend this Privacy Policy at any time
        without notice When we amend this Privacy Policy, we will modify the
        date listed on this Agreement or we may contact you. You must agree to
        the amendments as a condition of your continued use of our Platform. If
        you do not agree, you must immediately cease using our Platform and
        notify us of your refusal to agree by e-mailing us at support@coo-e.com.
      </Text>
      <Text style={ServiceStyles.contentHeading}>Contacting Us</Text>
      <Text style={ServiceStyles.contentText}>
        If you have any additional questions, please contact us at
        support@coo-e.com
      </Text>
    </ScrollView>
  )
}

export default PrivacyPolicyScreen

PrivacyPolicyScreen.propTypes = {
  componentId: PropTypes.string.isRequired,
}
