import React from "react"
import { TouchableOpacity, Image, View, Text, TextInput } from "react-native"
import PropTypes from "prop-types"

import { images } from "@utils/imageUtils"
import { GameplanStyles, multilineStyle } from "../createTeeupStyles"
import Linkify from "linkifyjs/react"

const ClearButton = ({ onPress }) => (
  <TouchableOpacity style={GameplanStyles.closeButton} onPress={onPress}>
    <Image source={images.closeWithBg} />
  </TouchableOpacity>
)
ClearButton.propTypes = {
  onPress: PropTypes.func.isRequired,
}

const RenderLocationButton = ({
  label,
  value,
  subValue,
  hint,
  onFieldPress,
  withMapImage,
  onMapScreenOpen,
  onValueClear,
  isReadyForShow,
  icon,
  index,
}) => {
  if (!isReadyForShow) return null
  return (
    <View style={GameplanStyles.fieldContainer} key={index + hint}>
      {!!label && <Text style={GameplanStyles.fieldLabel}>{label}</Text>}
      <TouchableOpacity
        onPress={onFieldPress}
        style={[GameplanStyles.commonFieldStyle, GameplanStyles.field]}
      >
        <View style={GameplanStyles.flex}>
          <Text
            style={[
              GameplanStyles.fieldTitle,
              onMapScreenOpen && GameplanStyles.mapImageMargin,
              !!value && GameplanStyles.fieldTitleValue,
            ]}
          >
            {value || hint}
          </Text>
          {subValue && (
            <Text
              style={[GameplanStyles.fieldTitle, GameplanStyles.subValueText]}
            >
              {subValue}
            </Text>
          )}
        </View>
        {value ? (
          <ClearButton onPress={onValueClear} />
        ) : icon ? (
          <img src={icon} style={GameplanStyles.buttonIcon} />
        ) : withMapImage ? (
          <TouchableOpacity
            onPress={onMapScreenOpen}
            style={GameplanStyles.mapIcon}
          >
            <Image source={images.whiteMap} />
          </TouchableOpacity>
        ) : null}
      </TouchableOpacity>
    </View>
  )
}
RenderLocationButton.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  subValue: PropTypes.string,
  hint: PropTypes.string,
  withMapImage: PropTypes.bool,
  onMapScreenOpen: PropTypes.func,
  onFieldPress: PropTypes.func.isRequired,
  onValueClear: PropTypes.func.isRequired,
  isReadyForShow: PropTypes.bool.isRequired,
  icon: PropTypes.number,
  index: PropTypes.number,
}

const RenderInput = ({
  label,
  value,
  hint,
  onFieldChange,
  isMultiline,
  onInputClear,
  setShowDetails,
}) => {
  const multiLineProps = isMultiline
    ? { multiline: true, scrollEnabled: false }
    : {}
  return (
    <View
      style={[
        GameplanStyles.fieldContainer,
        isMultiline && { marginBottom: 10 },
      ]}
      key={label}
    >
      {!!label && <Text style={GameplanStyles.fieldLabel}>{label}</Text>}
      <View style={[GameplanStyles.commonFieldStyle, GameplanStyles.field]}>
        <TextInput
          style={[GameplanStyles.fieldInput, multilineStyle(isMultiline)]}
          placeholder={hint}
          value={value || ""}
          onChangeText={onFieldChange}
          autoCorrect={false}
          numberOfLines={isMultiline ? 4 : 1}
          {...multiLineProps}
        />
        {!!value && <ClearButton onPress={onInputClear} />}
      </View>
    </View>
  )
}
RenderInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  hint: PropTypes.string,
  onInputClear: PropTypes.string,
  isMultiline: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
}

const RenderTime = (
  label,
  onPress,
  value,
  hint,
  icon,
  selectSpecific,
  componentId,
  type,
  onCancelPress
) => {
  return (
    <View style={GameplanStyles.fieldContainer} key={label}>
      <Text style={GameplanStyles.fieldLabel}>{label}</Text>
      <TouchableOpacity style={GameplanStyles.fieldWhen} onPress={onPress}>
        <Text
          style={[
            GameplanStyles.fieldTitleWhen,
            value && GameplanStyles.fieldTitleValue,
          ]}
        >
          {value ? value : hint}
        </Text>
        {value ? (
          <TouchableOpacity
            style={GameplanStyles.fieldAction}
            onPress={() => onCancelPress(type)}
          >
            <Image
              style={GameplanStyles.fieldCancelIcon}
              source={images.cancel}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={GameplanStyles.fieldAction}
            onPress={onPress}
          >
            <img src={icon} style={{ width: 20 }} />
          </TouchableOpacity>
        )}
      </TouchableOpacity>
    </View>
  )
}

const RenderDetails = ({ onlineTypeOfInviteLink }) => {
  const linkOptions = {
    className: "message-link",
  }

  return (
    <View
      key={onlineTypeOfInviteLink.link}
      style={GameplanStyles.mesengerDetails}
    >
      <View style={GameplanStyles.headerDetails}>
        <View>
          {images.socialIcons.map((icon) => {
            return (
              icon.name === onlineTypeOfInviteLink.type && (
                <Image
                  key={icon.name}
                  style={[GameplanStyles.detailIcon, { width: 16, height: 16 }]}
                  source={icon.enabled}
                />
              )
            )
          })}
        </View>
        <Text style={GameplanStyles.headerTxt}>
          {onlineTypeOfInviteLink?.type}
        </Text>
      </View>
      {onlineTypeOfInviteLink?.link && (
        <View style={GameplanStyles.subTitle}>
          <Text style={GameplanStyles.subTitleTxt}>JOIN LINK</Text>
          <View>
            <Text style={GameplanStyles.detailsDescription}>
              <Linkify options={linkOptions}>
                {onlineTypeOfInviteLink?.link}
              </Linkify>
            </Text>
          </View>
        </View>
      )}
      {onlineTypeOfInviteLink?.metadata &&
        onlineTypeOfInviteLink.metadata.map((item) => {
          return (
            <View style={GameplanStyles.subTitle} key={item.label}>
              <Text style={GameplanStyles.subTitleTxt}>{item.label}</Text>
              <View>
                <Text style={GameplanStyles.detailsDescription}>
                  {item.value}
                </Text>
              </View>
            </View>
          )
        })}
    </View>
  )
}

RenderDetails.propTypes = {
  onlineTypeOfInviteLink: PropTypes.object,
}

export const RenderFields = (fields, onPress, componentId, onCancelPress) => {
  let renderedFields = []
  for (let index = 0, len = fields.length; index < len; index++) {
    const {
      type,
      label,
      value,
      subValue,
      hint,
      icon,
      selectSpecific,
      onFieldPress,
      onFieldChange,
      withMapImage,
      onMapScreenOpen,
      isMultiline,
      onInputClear,
      onValueClear,
      isReadyForShow,
      onlineTypeOfInviteLink,
    } = fields[index]

    if (index !== 0) {
      renderedFields.push(
        <View key={"S" + index} style={GameplanStyles.fieldContainerSplitter} />
      )
      if (type === "button" && value) {
        renderedFields.push(
          <View
            key={"S" + index + value}
            style={GameplanStyles.fieldContainerSplitter}
          />
        )
      }
    }

    switch (type) {
      case "date":
      case "time":
        renderedFields.push(
          RenderTime(
            label,
            () => onPress(fields[index], index),
            value,
            hint,
            icon,
            selectSpecific,
            componentId,
            type,
            onCancelPress
          )
        )
        break
      case "button":
        renderedFields.push(
          RenderLocationButton({
            label,
            value,
            subValue,
            hint,
            onFieldPress,
            withMapImage,
            onMapScreenOpen,
            onValueClear,
            isReadyForShow,
            icon,
            index,
          })
        )
        break
      case "input":
        renderedFields.push(
          RenderInput({
            label,
            value,
            hint,
            onFieldChange,
            isMultiline,
            onInputClear,
          })
        )
        break
      case "details":
        renderedFields.push(
          RenderDetails({
            onlineTypeOfInviteLink,
          })
        )
    }
  }

  return renderedFields
}
