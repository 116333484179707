import React, { useState } from "react"
import Switch from "react-switch"

import "./index.scss"

const SwitchBlock = ({
  sectionName,
  title,
  conditionName,
  conditionValue,
  initialValue,
  onChange,
  isDisable,
}) => {
  return (
    <>
      <div className="settings__option-title">{sectionName}</div>
      <div className="switch__block">
        <div className="switch__upper">
          <h2 className="switch__title">{title}</h2>
          <Switch
            onChange={() => onChange(initialValue)}
            checked={initialValue}
            uncheckedIcon={false}
            checkedIcon={false}
            width={50}
            height={30}
            offColor="#9D9D9D"
            onColor="#F42862"
            disabled={isDisable}
          />
        </div>
        <div className="switch__bottom">
          <span className="switch__condition">{conditionName}</span>
          <span className="switch__condition">{conditionValue}</span>
        </div>
      </div>
    </>
  )
}

export default SwitchBlock
