import React, { useState } from "react"
import { View, Text, ScrollView } from "react-native"
// import ClickableRow from '@ui/settingsClickableRow'
import {
  ToggleButton,
  ArrowButton,
} from "@ui/settingsClickableRow/actionButtons"
import PropTypes from "prop-types"
import strings from "@i18n"

import { images } from "@utils/imageUtils"

import { styles } from "./calendarSettingsStyles"
import SyncCalendarButton from "../../../SyncCalendarButton"

const ClickableRow = ({
  primaryText,
  secondaryText,
  onPress,
  actions,
  iconImage,
  emailMethod,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState("")

  const onChangeHandler = (e) => {
    setValue(e.target.value)
  }

  return (
    <div className="clickable__row">
      {iconImage && (
        <img className="clickable__row_img" src={iconImage} alt="img" />
      )}
      <span className="clickable__title">{primaryText}</span>
      <div
        className="clickable__wrapper"
        style={{ marginLeft: !emailMethod ? "26px" : "0px" }}
      >
        {!editMode && (
          <span className="clickable__value">
            {secondaryText ? secondaryText : "Not Set"}
          </span>
        )}
        {!editMode && (
          <span
            className="clickable__action"
            onClick={() => onPress && onPress()}
          >
            {secondaryText ? "Change" : "Set"}
          </span>
        )}
        {editMode && (
          <>
            <input
              className="clickable__input"
              placeholder={secondaryText ? secondaryText : "Not Set"}
              value={value}
              onChange={(e) => onChangeHandler(e)}
            />
            <div className="clickable__buttonsWrapper">
              <button
                className="clickable__button clickable__button_cancel"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </button>
              <button className="clickable__button clickable__button_done">
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const ClickableRowSwitch = ({ primaryText, onPress, actionButton }) => {
  return (
    <div className="clickable__row" onClick={() => onPress()} style={{cursor: "pointer"}}>
      <div className="clickable__row_switch">{actionButton}</div>
      <span className="clickable__title_switch">{primaryText}</span>
    </div>
  )
}

const TimeZoneSettingsView = ({
  onChange,
  calendarTitle,
  additionalCalendarsAmount,
  syncTeeups,
  syncOnlyAllSet,
  customTimezone,
  tzAutodetected,
  onCalendarSelect,
  onAdditionalCalendarsSelect,
  onCustomTimezone,
  defaultTimezone,
}) => {
  const [isTSCreatingTeeup, setIsTSCreatingTeeup] = useState(true)
  const [isTSJoiningTeeup, setIsTSJoiningTeeup] = useState(true)

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* <Text style={styles.header}>
                {strings.tzAndCalendar.settingsCalendarSubtitle}
            </Text> */}
      <div className="settings__rowWrapper">
        {/* <ClickableRow
                    primaryText={[strings.tzAndCalendar.syncToCalendar.title]}
                    detailsText={[strings.tzAndCalendar.syncToCalendar.details]}
                    actionButton={
                        <ToggleButton
                            onToggle={event => onChange(event, 'syncTeeups')}
                            value={syncTeeups}
                        />
                    }
                    onPress={() => onChange(!syncTeeups, 'syncTeeups')}
                /> */}
        {/* {syncTeeups && (
                    <ClickableRow
                        primaryText={[
                            strings.tzAndCalendar.primaryCalendar.title,
                        ]}
                        secondaryText={[calendarTitle]}
                        detailsText={[
                            strings.tzAndCalendar.primaryCalendar.details,
                        ]}
                        actionButton={<ArrowButton />}
                        onPress={onCalendarSelect}
                    />
                )} */}
        {/* { syncTeeups && <SyncCalendarButton /> } */}
        {/* <SyncCalendarButton /> */}

        {/* <ClickableRow
                    primaryText={[strings.tzAndCalendar.syncOnlyAllSet.title]}
                    actionButton={
                        <ToggleButton
                            onToggle={event =>
                                onChange(event, 'syncOnlyAllSet')
                            }
                            value={syncOnlyAllSet}
                        />
                    }
                    onPress={() => onChange(!syncOnlyAllSet, 'syncOnlyAllSet')}
                /> */}

        {/* <ClickableRow
                primaryText={[
                    strings.tzAndCalendar.additionalCalendars.title,
                ]}
                secondaryText={
                    additionalCalendarsAmount > 0
                        ? [`${additionalCalendarsAmount} selected`]
                        : []
                }
                detailsText={[
                    strings.tzAndCalendar.additionalCalendars.details,
                ]}
                actionButton={<ArrowButton />}
                onPress={onAdditionalCalendarsSelect}
            /> */}
      </div>

      {/* <Text style={styles.header}>
                {strings.tzAndCalendar.settingsTZSubtitle}
            </Text>

            <ClickableRow
                primaryText={[strings.tzAndCalendar.useWhenCreating.title]}
                actionButton={<ToggleButton
                                disabled
                                value={false}
                                onToggle={() => {}}
                                />}
            />
            <ClickableRow
                primaryText={[strings.tzAndCalendar.useWhenJoining.title]}
                actionButton={<ToggleButton
                                disabled
                                value={false}
                                onToggle={() => {}}
                                />}
            /> */}

      {/* <Text style={[styles.header, styles.headerBold]}>
                Time Zones
            </Text> */}

      <div className="settings__rowWrapper">
        <ClickableRow
          iconImage={images.tzEmptyIconHome}
          primaryText={[strings.tzAndCalendar.homeTZ.title]}
          secondaryText={[strings.tzAndCalendar.homeTZ.notSet]}
          secondaryText={defaultTimezone}
          // footerText={[strings.tzAndCalendar.homeTZ.details]}
          actionButton={<ArrowButton />}
          onPress={() => onCustomTimezone(true)} //Here
        />
      </div>

      <Text style={styles.ask}>
        Set your home time-zone to allow the app to help you manage your TeeUps
        while traveling
      </Text>

      <View style={styles.splitter} />

      <div className="settings__rowWrapper">
        <ClickableRow
          iconImage={images.activeTimeZone}
          primaryText={[strings.tzAndCalendar.activeTZ.title]}
          secondaryText={[customTimezone]}
          detailsText={[
            tzAutodetected
              ? strings.tzAndCalendar.activeTZ.detected
              : strings.tzAndCalendar.activeTZ.manual,
          ]}
          footerText={[strings.tzAndCalendar.activeTZ.details]}
          actionButton={<ArrowButton />}
          onPress={onCustomTimezone}
        />
      </div>

      <Text style={styles.ask}>
        This Time-zone will be used when creating and joining TeeUps
      </Text>

      <View style={styles.splitter} />

      <div className="settings__rowWrapper">
        <ClickableRowSwitch
          primaryText={"Use the current time-zone when creating TeeUps"}
          onPress={() => setIsTSCreatingTeeup(!isTSCreatingTeeup)}
          actionButton={
            <ToggleButton
              value={isTSCreatingTeeup}
              onToggle={() => setIsTSCreatingTeeup}
            />
          }
        />
        <ClickableRowSwitch
          primaryText={"Use the current time-zone when joining TeeUps"}
          onPress={() => setIsTSJoiningTeeup(!isTSJoiningTeeup)}
          actionButton={
            <ToggleButton
              value={
                // notifications[firstRealKey]
                isTSJoiningTeeup
              }
              onToggle={() => 
                // toggleNotification(
                //     notificationKey,
                //     val
                // )
                // console.log(val, 'val')
                setIsTSJoiningTeeup
              }
            />
          }
        />
      </div>
    </ScrollView>
  )
}

export default TimeZoneSettingsView

TimeZoneSettingsView.propTypes = {
  onChange: PropTypes.func.isRequired,
  // syncTeeups: PropTypes.bool.isRequired,
  // syncOnlyAllSet: PropTypes.bool.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  additionalCalendarsAmount: PropTypes.number,
  customTimezone: PropTypes.string,
  onCustomTimezone: PropTypes.func.isRequired,
  onCalendarSelect: PropTypes.func.isRequired,
  onAdditionalCalendarsSelect: PropTypes.func.isRequired,
  tzAutodetected: PropTypes.bool,
}
