import React from "react"
import { View, Text, Image, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"
// import ParsedText from 'react-native-parsed-text'

import { getContactName } from "@utils/dataUtils"
import { images } from "@utils/imageUtils"
import CheckBox from "@ui/checkbox"
import { Units } from "@theme"
import styles from "./styles"

class ParticipantItem extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: props.data && props.data.selectedIndex,
    }
  }

  handleToggleParticipant = () => {
    const { data, onToggleParticipant } = this.props
    onToggleParticipant(data)
  }

  render() {
    const { selected, data, testID } = this.props

    if (!data) {
      return null
    }
    const label = getContactName(data) || ""
    const { isForSelectAll } = data

    return (
      <>
        <View style={styles.container} testID={testID}>
          <TouchableOpacity
            style={styles.participantInfo}
            onPress={this.handleToggleParticipant}
            testID={`${testID}-mainClickableArea`}
          >
            {data.avatar ? (
              <Image source={{ uri: data.avatar }} style={styles.avatar} />
            ) : isForSelectAll ? (
              <Image source={images.everyoneAvatar} style={styles.avatar} />
            ) : (
              <View style={[styles.avatar, styles.participantIcon]}>
                <Text style={styles.participantInitial}>{label[0]}</Text>
              </View>
            )}
            <View style={styles.userContent}>
              <Text
                style={styles.titleText}
                // testID={`${testID}-participantInitial`}
              >
                {label || (isForSelectAll && "Select Everyone")}
              </Text>
              {data.username && (
                <Text style={styles.username} numberOfLines={1}>
                  @{data.username}
                </Text>
              )}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={this.handleToggleParticipant}
            style={styles.checkboxContainer}
            hitSlop={Units.hitSlop()}
            testID={`${testID}-secondaryClickableArea`}
          >
            <CheckBox
              isActive={selected}
              testID={`${testID}-selectedCheckbox`}
            />
          </TouchableOpacity>
        </View>
      </>
    )
  }
}

ParticipantItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onToggleParticipant: PropTypes.func.isRequired,
  testID: PropTypes.string,
}

export default ParticipantItem
