import { createSelector } from "reselect"

export const selectSuggest = (state) => state.suggest

export const selectActiveMeetingApp = createSelector(
  selectSuggest,
  (state) => state.activeMeetingApp
)

export const selectActiveButtonId = createSelector(
  selectSuggest,
  (state) => state.activeButtonId
)

export const selectMapCenter = createSelector(
  selectSuggest,
  (state) => state.mapCenter
)

export const selectIsLocationSelected = createSelector(
  selectSuggest,
  (state) => state.isLocationSelected
)

export const selectActivePlace = createSelector(
  selectSuggest,
  (state) => state.activePlace
)
