import ExpandedUserList from "./ExpandedUserList"
import React, { useState } from "react"
import { images } from "../../../utils/imageUtils.js"
import {
  renderFirstAvatars,
  TimeFromNow,
} from "@components/teeupList/TeeupItem/teeupItemComponents"
import { View } from "react-native"
import { timeFromNow } from "@utils/dateUtils"
import moment from "moment"

const ViewByTeeupsItem = ({
  activeTeeup,
  contacts,
  people,
  getUserId,
  handleToggleContact,
  invitees,
  setInvitees,
  fromCreateTeeup,
  handleOnInviteesDone,
  noHover,
}) => {
  const [expanded, setExpanded] = useState(false)
  const {
    seen,
    newMessages,
    newTitle,
    newWhen,
    newWhere,
    updatedAt,
    updatedat,
    isArchivePromptShow: showArchivePrompt,
  } = activeTeeup

  const hasUpdate = seen === false
  const isNew =
    (hasUpdate && !!newTitle) ||
    (hasUpdate && !!newWhen) ||
    (hasUpdate && !!newWhere) ||
    (hasUpdate && newMessages > 0)
  const fromNow = timeFromNow(updatedAt || updatedat)
  const fromNowString = fromNow === "1d" ? "Yesterday" : `${fromNow} ago`
  const ImageWrapper = View

  let when1 = ""
  const selectedWhen = activeTeeup.gameplans[0].suggestions?.map(
    (suggestion) => {
      if (suggestion.when && suggestion.isSelected) {
        when1 = suggestion.when.value
      }
    }
  )
  const whenFormatted = moment(when1).format("llll")
  let where1 = ""
  const selectedWhere = activeTeeup.gameplans[1].suggestions?.map(
    (suggestion) => {
      if (suggestion.where && suggestion.isSelected) {
        where1 = suggestion.where.details
      }
    }
  )

  const betterTextRow = {
    width: "440px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "16px",
  }

  return (
    <div
      style={fromCreateTeeup && { padding: "5px", width: "auto" }}
      className="teeupItem__container"
    >
      <div className="teeupItem_info__container">
        <img className="teeupItem__image" src={activeTeeup.photo} alt="" />
        <div className="teeupItem_info">
          <h2 className="teeupItem_name">{activeTeeup.name}</h2>
          {when1 && <p style={betterTextRow}>{whenFormatted}</p>}
          {where1 && <p style={betterTextRow}>{where1}</p>}
        </div>
        <div className="teeupItem_time">
          <TimeFromNow isNew={isNew} fromNowString={fromNowString} />
        </div>
      </div>
      {!expanded ? (
        <div
          className="teeupItem__userlist"
          onClick={() => setExpanded(!expanded)}
        >
          {renderFirstAvatars(
            Object.values(people).filter((person) => person.id !== getUserId),
            activeTeeup.createdby,
            ImageWrapper
          )}
          <img
            className="teeupItem_displayUsersArrow"
            src={images.arrowsOptions}
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      ) : (
        <ExpandedUserList
          contacts={contacts}
          people={Object.values(people).filter(
            (person) => person.id !== getUserId
          )}
          expanded={expanded}
          setExpanded={setExpanded}
          handleToggleContact={handleToggleContact}
          invitees={invitees}
          setInvitees={setInvitees}
          handleOnInviteesDone={handleOnInviteesDone}
          noHover={noHover}
        />
      )}
    </div>
  )
}

export default ViewByTeeupsItem
