import React, { useMemo } from "react"

import { images } from "@utils/imageUtils"
import { GamePlanSuggestionList } from "../GamePlanComponents"
import { useTeeUpPage } from "../../../TeeUpPageContext"

import "./gamePlanWhere.scss"

const GamePlanWhere = () => {
  const { teeup } = useTeeUpPage()

  const whereGamePlan = useMemo(() => {
    const plan = teeup.gameplans.find((plan) => plan.type === "where")
    const decided = plan?.suggestions?.find((suggestion) => suggestion.decided)

    return { plan, decided }
  }, [teeup])

  const getOverview = (suggestion) => {
    return suggestion?.value || ""
  }

  const getDetails = (suggestion) => {
    return suggestion?.details
  }

  return (
    <GamePlanSuggestionList
      decided={whereGamePlan.decided}
      config={{
        icon: images.ndClearLocationPinIcon,
        type: "where",
        getDetails,
        getOverview,
      }}
      plan={whereGamePlan.plan}
    />
  )
}

export default GamePlanWhere
