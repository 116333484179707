import React from "react"
import { connect } from "react-redux"
import { TouchableOpacity, Image } from "react-native"
import PropTypes from "prop-types"

// import { push, screenNames } from '@nav'
// import navigationConfig from '@nav/NavigationConfig'
import * as contactMechanismsActions from "@actions/contactMechanismsActions"
import {
  requestVerifyContactMechanism,
  deleteContactMechanism,
} from "@actions/contactMechanismsActions"
import { getUserContactMechanisms } from "@actions/userActions"
import { selectContactMechanisms, selectUserInfo } from "@selectors/user"
import { userContactTypes, contactTypes } from "@utils/contactUtils"
import Toast from "@ui/toast"
import { images } from "@utils/imageUtils"
import { AppColors } from "@theme/"
import closeIcon from "../../../assets/images/close.svg"
import { SectionListStyles } from "../../gamePlansCard/suggestionsList/suggestionListStyles"
import RetrieveTeeupVerifyContainer from "../../retrieveTeeup/retrieveTeeupVerifyContainer"

import AccountDetailsView from "./AccountDetailsView"

class AccountDetailsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null,
      isEditOpened: false,
      activateInput: false,
      inputValue: "",
      defaultCountry: "us",
      loading: false,
      contactType: "",
      modal: null,
    }
  }

  static options() {
    return {
      popGesture: false,
    }
  }

  getTitleScreen = (contactType) => {
    switch (contactType) {
      case "phone":
        return "Your Phone Number"
      case "email":
        return "Your Email Address"
    }
  }

  addContact = () => {
    this.setState({
      activateInput: true,
      // visibleSave: true,
    })
  }

  contactTypeHandler = (value) => {
    this.setState({
      contactType: value,
    })
  }

  onChangeInputValue = (index) => (input) => {
    this.setState({
      inputValue: input,
    })
  }

  onChooseCountry = () => {
    // routeCountrySelector({
    //     callback: (country) => {
    //         this.phone.selectCountry(country)
    //         this.setState({ inputValue: '' })
    //     },
    // })
  }

  deleteInput = () => {
    this.setState({
      activateInput: false,
      inputValue: "",
    })
  }

  handleModal = (value) => {
    this.setState({ modal: value })
  }

  async addContactMechanism(value, id) {
    // const { contactType } = this.props
    const { contactType } = this.state
    let { inputValue } = this.state

    if (value) {
      inputValue = value
    }

    inputValue = inputValue.trim()
    if (!inputValue) {
      return
    }

    const body = {
      value: inputValue,
      typeId:
        contactType === contactTypes.PHONE
          ? userContactTypes.PHONE
          : userContactTypes.EMAIL,
    }

    this.setState({ loading: true, error: null })
    try {
      let contactMechanismId = id
      if (!value && !contactMechanismId) {
        const { id } = await contactMechanismsActions.addUserContactMechanism(
          body
        )
        getUserContactMechanisms()

        contactMechanismId = id
      }

      const { contactMechanismVerificationId } =
        await requestVerifyContactMechanism(contactMechanismId)
      const props = {
        contactMechanismId,
        contactMechanismVerificationId,
        contactMechanismValue: inputValue,
        fromAccountScreen: true,
        contactMechanismType: contactType,
        closeVerify: this.closeVerify,
      }

      this.setState({
        loading: false,
        modal: (
          <RetrieveTeeupVerifyContainer
            {...props}
            contactValue={inputValue}
            handleModal={this.handleModal}
            deleteContactMechanism={() =>
              deleteContactMechanism(contactMechanismId)
            }
          />
        ),
      })
    } catch (e) {
      if (e === "Contact mechanism already exists") {
        Toast.show(
          `This ${
            contactType === contactTypes.PHONE ? "phone number" : "email"
          } is already in use`
        )
      }

      if (e === "Too Many Requests") {
        Toast.show("Too Many Requests", {
          bottomOffset: 8,
        })
      }
      this.setState({ loading: false, error: e })
    }
  }

  onSave = () => {
    this.addContactMechanism()
    this.deleteInput()
  }

  setContent = (content) => this.setState({ content, isEditOpened: true })
  closeEdit = () => this.setState({ isEditOpened: false })

  componentDidMount() {
    contactMechanismsActions.getMechanisms()
  }

  render() {
    const {
      contactMechanisms,
      primaryEmail,
      primaryPhone,
      closeOption,
      country,
    } = this.props
    const { isEditOpened, content, activateInput, modal } = this.state

    return (
      <>
        {/* <div className="settings__top">
                    <TouchableOpacity
                        onPress={closeOption}
                    >
                        <Image
                            source={images.back}
                            style={SectionListStyles.cancelBtnImage}
                        />
                    </TouchableOpacity>
                    <span>Coo-e Linked Contact Methods</span>
                    <span>Primary Contact Methods</span>
                    <div></div>
                </div> */}
        <AccountDetailsView
          contactMechanisms={contactMechanisms}
          primaryEmail={primaryEmail}
          primaryPhone={primaryPhone}
          isEditOpened={isEditOpened}
          content={content}
          setContent={this.setContent}
          getTitleScreen={this.getTitleScreen}
          closeEdit={this.closeEdit}
          addContact={this.addContact}
          // unverifiedPressed={this.unverifiedPressed}
          activateInput={activateInput}
          onChangeInputValue={this.onChangeInputValue}
          deleteInput={this.deleteInput}
          inputValue={this.state.inputValue}
          loading={this.state.loading}
          defaultCountry={this.state.defaultCountry}
          phoneRef={(ref) => (this.phone = ref)}
          country={country}
          onChooseCountry={this.onChooseCountry}
          contactTypeHandler={this.contactTypeHandler}
          contactType={this.state.contactType}
          onSave={this.onSave}
          modal={modal}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const contacts = selectContactMechanisms(state)
  const user = selectUserInfo(state)
  let primaryEmail = {}
  let primaryPhone = {}
  let contactMechanisms = []
  const { id: userId, country } = user
  contacts.phone.forEach((cm) => {
    if (cm.typeid === userContactTypes.PRIMARY_PHONE) {
      primaryPhone = cm
    } else {
      contactMechanisms.push(cm)
    }
  })
  contacts.email.forEach((cm) => {
    if (cm.typeid === userContactTypes.PRIMARY_EMAIL) {
      primaryEmail = cm
    } else {
      contactMechanisms.push(cm)
    }
  })
  return {
    contactMechanisms,
    primaryEmail,
    primaryPhone,
    userId,
    country,
  }
}

AccountDetailsContainer.propTypes = {
  contactMechanisms: PropTypes.array,
  primaryEmail: PropTypes.object,
  primaryPhone: PropTypes.object,
  // componentId: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(AccountDetailsContainer)
