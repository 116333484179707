import { StyleSheet } from "react-native"
import { AppFonts, AppColors, Units } from "@theme"

export const styles = StyleSheet.create({
  rowWrapper: {
    flexDirection: "row",
    alignItems: "center",
    padding: Units.responsiveValue(12),
    height: "5vh",
    maxHeight: "5vh",
    justifyContent: "center",
    backgroundColor: "#F0F2F5",
    // backgroundColor: '#F0F0F0',
  },
  searchContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
    padding: Units.responsiveValue(8),
    borderRadius: Units.responsiveValue(8),
  },
  inputStyle: {
    ...AppFonts.biggerMedium,
    flex: 1,
    flexGrow: 1,
    padding: 0,
    margin: 0,
    marginHorizontal: Units.responsiveValue(8),
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  searchImage: {
    tintColor: AppColors.brand.warmGrey,
  },
})

export const filterIcon = {
  size: Units.responsiveValue(18),
  color: AppColors.brand.warmGrey,
}
