import React, { memo } from "react"
import { View } from "react-native"
import PropTypes from "prop-types"
import * as linkify from "linkifyjs"
import Linkify from "linkifyjs/react"
import mentions from "linkifyjs/plugins/mention"

import { getUsernameOrName } from "@utils/contactUtils"
import styles from "./textMessageStyles"

mentions(linkify)

const TextMessage = ({
  text,
  isMyMessage,
  people,
  renderMentionedUser,
  onUserPress,
  directMessage,
  currentUserId,
  highlightMyMention,
}) => {
  const getValidUrl = (str) => {
    let isValidURL = false
    let path = ""
    try {
      path = new URL(str)
      isValidURL = true
    } catch (error) {}
    return { isValidURL, path }
  }

  let linkProps = {
    onClick: (event) => {
      event.preventDefault()
      const { isValidURL, path } = getValidUrl(event.target)
      if (isValidURL) {
        window.open(path, "blank")
        return
      }
      const mentionedUsername = event.target
        .getAttribute("href")
        .replace("/", "")

      let mentionedUserId
      let isMentionedUserExists = false
      if (people) {
        Object.values(people).find((user) => {
          if (!user) return
          const userPart = getUsernameOrName(user)
          const userName = userPart.trim()
          if (userName.toLowerCase() === mentionedUsername.toLowerCase()) {
            isMentionedUserExists = true
            mentionedUserId = Number(user.id)
            return
          }
        })
      }

      if (isMentionedUserExists) {
        onUserPress(mentionedUserId)
      }
    },
  }

  const options = {
    className: {
      url: "message-link",
      mention: "message-mention",
    },
    attributes: linkProps,
  }

  if (directMessage) {
    return (
      <View style={styles.container}>
        <Linkify options={options}>{text}</Linkify>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <Linkify options={options}>{text}</Linkify>
    </View>
  )
}

TextMessage.propTypes = {
  text: PropTypes.string,
  renderMentionedUser: PropTypes.func,
  onUserPress: PropTypes.func,
  isMyMessage: PropTypes.bool,
  people: PropTypes.object,
  directMessage: PropTypes.bool,
  currentUserId: PropTypes.number,
  highlightMyMention: PropTypes.bool,
}

export default memo(TextMessage)
