import { createSelector } from "reselect"
import find from "lodash/find"

import { hideAlreadyInvited } from "@utils/contacts"
import { getContactId } from "@utils/contactUtils"
import { selectTeeupParticipants } from "./teeups"
import { selectActiveGroupMembersIds } from "./group"
import ContactState from "../reducers/contactStorage"

export const selectContacts = (state) => state.contacts
export const selectAlreadyInvitedIds = (ids) => ids

export const selectBookContacts = createSelector(
  selectContacts,
  () => ContactState.bookContacts || []
)

export const selectAllContacts = createSelector(
  selectContacts,
  () => ContactState.allContacts || []
)

export const selectAllContactsWithoutInvitedToTeeup = createSelector(
  selectContacts,
  selectTeeupParticipants,
  (_, alreadyInvitedIds) =>
    ContactState.allContacts
      ? hideAlreadyInvited(ContactState.allContacts, alreadyInvitedIds)
      : []
)

export const selectAllContactsWithoutInvitedToGroup = createSelector(
  selectContacts,
  selectActiveGroupMembersIds,
  (_, alreadyInvitedIds) =>
    ContactState.allContacts
      ? hideAlreadyInvited(ContactState.allContacts, alreadyInvitedIds)
      : []
)

export const selectTags = createSelector(
  selectContacts,
  () => ContactState.tags || []
)

export const selectIsContactsSynced = createSelector(
  selectContacts,
  (contacts) => contacts.isContactsSynced
)

export const selectSyncInProgress = createSelector(
  selectContacts,
  (contacts) => contacts.syncInProgress
)

export const selectContactsByTags = createSelector(
  selectContacts,
  () => ContactState.byTags
)

export const selectContactsByTagsWithoutInvitedToTeeup = createSelector(
  selectContacts,
  selectTeeupParticipants,
  (_, alreadyInvitedIds) => {
    if (alreadyInvitedIds && alreadyInvitedIds.length > 0) {
      let newByTags = {}
      Object.keys(ContactState.byTags).forEach((tagKey) => {
        newByTags[tagKey] = hideAlreadyInvited(
          ContactState.byTags[tagKey],
          alreadyInvitedIds
        )
      })
      return newByTags
    }
    return ContactState.byTags
  }
)

export const selectContactsByTagsWithoutInvitedToGroup = createSelector(
  selectContacts,
  selectActiveGroupMembersIds,
  (_, alreadyInvitedIds) => {
    if (alreadyInvitedIds && alreadyInvitedIds.length > 0) {
      let newByTags = {}
      Object.keys(ContactState.byTags).forEach((tagKey) => {
        newByTags[tagKey] = hideAlreadyInvited(
          ContactState.byTags[tagKey],
          alreadyInvitedIds
        )
      })
      return newByTags
    }
    return ContactState.byTags
  }
)

export const selectContactById = (id) =>
  createSelector(
    selectAllContacts,
    () =>
      find(ContactState.allContacts, (user) => getContactId(user) === id) || {}
  )
