import React, { PureComponent } from "react"
import { BackHandler } from "react-native"
// import { Navigation } from 'react-native-navigation'
import {
  stringToDateFormat,
  getLocaleTime,
  composeDatetime,
  adjustMinuteRange,
  addHours,
  addDayTimestampString,
  addMinutes,
} from "@utils/dateUtils"
import { DATE_SELECT_TYPES } from "@utils/gamePlanUtils"
import PropTypes from "prop-types"
import Toast from "@ui/toast"

import TimePickerView from "./timePickerView"
import { AppColors } from "@theme"
import moment from "moment"

class TimePickerContainer extends PureComponent {
  constructor(props) {
    super(props)

    let {
      startDate,
      endDate,
      type,
      duration: propsDuration,
      startDateIndex,
      endDateIndex,
      isEndEnabled,
      isTimeZoneEnabled,
      checkForTodaysDate,
    } = props

    let startDateFormatted = startDate
      ? adjustMinuteRange(
          moment(startDate),
          type === DATE_SELECT_TYPES.timeRange ? 5 : 15
        )
      : adjustMinuteRange()

    let endDateFormatted = endDate
      ? adjustMinuteRange(
          endDate,
          type === DATE_SELECT_TYPES.timeRange ? 5 : 15
        )
      : adjustMinuteRange()

    const minutesPicker = moment(startDate).minutes()
    const hoursPicker = moment(startDate).hours()
    let startTime = stringToDateFormat(startDateFormatted)
    startDate = stringToDateFormat(startDateFormatted)
    let endTime = stringToDateFormat(endDateFormatted)
    if (isTimeZoneEnabled) {
      endDate = null
    } else {
      endDate = stringToDateFormat(endDateFormatted)
    }
    const durationMinutesInterval = 5
    const duration = propsDuration || 0
    const durationHoursIndex = Math.floor(duration / 60)
    const durationMinutesIndex = (duration % 60) / durationMinutesInterval

    this.state = {
      isEndEnabled: isEndEnabled ? true : false,
      startDate,
      startTime,
      endDate,
      endTime,
      duration,
      minutesPicker,
      hoursPicker,
      // set initial time for single timzone
      startDateChanged: Boolean(isTimeZoneEnabled ? props.startDate : true),
      startTimeChanged: Boolean(isTimeZoneEnabled ? props.startDate : true),
      showDatePicker: true,
      showTimePicker: true,
      showEndDatePicker: false,
      showEndTimePicker: false,
      showDurationPicker: true,
      startDateIndex,
      durationHoursIndex,
      durationMinutesIndex,
      endDateIndex,
      isDurationActive: Boolean(props.isDurationActive),
    }
    checkForTodaysDate(startDate)

    // Navigation.events().bindComponent(this)
  }

  static options() {
    return {
      statusBar: {
        backgroundColor: AppColors.brand.grey40,
        style: "light",
      },
      overlay: {
        interceptTouchOutside: false,
      },
    }
  }

  handleAndroidBackPress = () => {
    this.onCancelPress()
    return true
  }

  onCancelPress = () => {
    this.props.closeLayout()
  }

  onSelectPress = async () => {
    const {
      startTime,
      startDate,
      endDate,
      endTime,
      duration,
      isEndEnabled,
      startDateChanged,
      startTimeChanged,
      startDateIndex,
      endDateIndex,
      isDurationActive: stateIsDurationActive,
    } = this.state
    const {
      type,
      listComponentId,
      isTimeZoneEnabled,
      onDurationSwitch,
      isDurationActive,
    } = this.props

    if (type === "date" && !startDateChanged) {
      Toast.show("Please select start date")
    } else if (type === "time" && !startTimeChanged) {
      Toast.show("Please select start time")
    } else {
      let composedStartDate = composeDatetime(startDate, startTime)
      let composedEndDate = composeDatetime(
        isTimeZoneEnabled ? startDate : endDate,
        endTime
      )
      if (stateIsDurationActive !== isDurationActive && onDurationSwitch) {
        await onDurationSwitch()
      }
      this.props.onDone({
        startDate: composedStartDate,
        endDate: composedEndDate,
        duration,
        type,
        listComponentId,
        isEndEnabled,
        startDateIndex,
        endDateIndex,
      })

      this.props.closeLayout()
    }
  }

  onDateSelected = (index) => {
    const date = addDayTimestampString(index)
    const { isTimeZoneEnabled, checkForTodaysDate } = this.props
    const startDate = stringToDateFormat(date)
    const { endDateIndex } = this.state

    this.setState(
      {
        startDate,
        startDateChanged: true,
        startDateIndex: index,
      },
      () => {
        isTimeZoneEnabled && this.selectTime(startDate)
        index > endDateIndex && this.onEndDateSelected(index)
      }
    )
    checkForTodaysDate(startDate)
  }

  onEndDateSelected = (index) => {
    const date = addDayTimestampString(index)

    this.setState({
      endDate: stringToDateFormat(date),
      endDateIndex: index,
    })
  }

  onStartTimeSelected = (date) => {
    let startTime = stringToDateFormat(date)
    // if (this.zonesViewRef) {
    //     this.zonesViewRef.scrollToIndex(startTime)
    // }
    // if (
    //     this.props.duration &&
    //     this.props.type !== DATE_SELECT_TYPES.timeRange
    // ) {
    //     let endTime = addHours(
    //         stringToDateFormat(date),
    //         this.props.duration
    //     )
    //     this.setState({
    //         endTime,
    //     })
    // }

    this.setState({ startTime, startTimeChanged: true })
  }

  onEndTimeSelected = (date) => {
    let endTime = stringToDateFormat(date)
    this.setState({ endTime })
  }

  onDurationHoursSelected = (hours) => {
    const { startTime, duration, durationMinutesIndex } = this.state
    const durationMinutes = duration % 60
    const updatedMinutes = hours === 24 ? 0 : durationMinutes
    const newDuration = hours * 60 + updatedMinutes
    const endTime = addMinutes(startTime, newDuration)
    this.setState({
      endTime,
      duration: newDuration,
      durationHoursIndex: hours,
      durationMinutesIndex: hours === 24 ? 0 : durationMinutesIndex,
    })
  }

  onDurationMinutesSelected = (minutes, minutesInterval) => {
    const { startTime, duration, durationHoursIndex } = this.state
    const updatedMinutesIndex = durationHoursIndex === 24 ? 0 : minutes
    const durationHours = Math.floor(duration / 60)
    const newDuration =
      durationHours * 60 + updatedMinutesIndex * minutesInterval
    const endTime = addMinutes(startTime, newDuration)
    if (durationHoursIndex === 24) {
      this.setState(
        {
          durationMinutesIndex: minutes,
        },
        () => {
          this.setState({
            endTime,
            duration: newDuration,
            durationMinutesIndex: updatedMinutesIndex,
          })
        }
      )
    } else {
      this.setState({
        endTime,
        duration: newDuration,
        durationMinutesIndex: updatedMinutesIndex,
      })
    }
  }

  selectTime = (startDateState) => {
    const {
      startTime,
      startDate,
      endDate,
      endTime,
      isEndEnabled,
      startDateIndex,
      endDateIndex,
    } = this.state
    const { type, listComponentId } = this.props
    let composedStartDate = composeDatetime(
      startDateState ? startDateState : startDate,
      startTime
    )
    let composedEndDate = composeDatetime(endDate, endTime)

    this.props.onDone({
      startDate: composedStartDate,
      endDate: composedEndDate,
      type,
      listComponentId,
      isEndEnabled,
      startTime,
      startDateIndex,
      endDateIndex,
    })
  }

  onToggleEndEnable = (value) => {
    this.setState({ isEndEnabled: value })
  }

  onPicker = ({ pickerKey, closePickerKey }) => {
    this.setState({
      [pickerKey]: !this.state[pickerKey],
      [closePickerKey]: false,
    })
  }

  onDurationSwitch = (isDurationActive) => {
    this.setState({
      isDurationActive,
    })
  }

  render() {
    const {
      isMultiTimeZone,
      type,
      startDate: selectedDate,
      isStartDateToday,
      dateArray,
      isTimeInterval,
      timezone,
    } = this.props
    const {
      startDate,
      startTime,
      endDate,
      endTime,
      duration,
      isEndEnabled,
      showDatePicker,
      showTimePicker,
      showEndDatePicker,
      showEndTimePicker,
      showDurationPicker,
      startDateChanged,
      startTimeChanged,
      startDateIndex,
      endDateIndex,
      durationHoursIndex,
      durationMinutesIndex,
      isDurationActive,
      minutesPicker,
      hoursPicker,
    } = this.state

    return (
      <TimePickerView
        onPickerHeaderPress={this.onPicker}
        onToggleEndEnable={this.onToggleEndEnable}
        startDateVal={
          startDateChanged
            ? stringToDateFormat(startDate, "dddd MMM D")
            : "Select value"
        }
        startTimeVal={
          startTimeChanged ? getLocaleTime(startTime) : "Select value"
        }
        startDate={startDate}
        startTime={startTime}
        endDateVal={
          endDate && isEndEnabled
            ? stringToDateFormat(endDate, "dddd MMM D")
            : "Optional"
        }
        endTimeVal={
          endTime && isEndEnabled ? getLocaleTime(endTime) : "Optional"
        }
        endDate={endDate}
        endTime={endTime}
        isStartDateToday={isStartDateToday}
        durationVal={duration}
        isEndEnabled={isEndEnabled}
        isTimeInterval={isTimeInterval}
        isMultiTimeZone={isMultiTimeZone}
        type={type}
        showEndDatePicker={showEndDatePicker}
        showEndTimePicker={showEndTimePicker}
        showDatePicker={showDatePicker}
        showTimePicker={showTimePicker}
        showDurationPicker={showDurationPicker}
        onDateSelected={this.onDateSelected}
        onStartTimeSelected={this.onStartTimeSelected}
        onEndDateSelected={this.onEndDateSelected}
        onEndTimeSelected={this.onEndTimeSelected}
        onDurationHoursSelected={this.onDurationHoursSelected}
        onDurationMinutesSelected={this.onDurationMinutesSelected}
        selectedDate={selectedDate}
        onCancelPress={this.onCancelPress}
        onSelectPress={this.onSelectPress}
        dateArray={dateArray}
        startDateIndex={startDateIndex}
        endDateIndex={endDateIndex}
        durationHoursIndex={durationHoursIndex}
        durationMinutesIndex={durationMinutesIndex}
        timezone={timezone}
        isDurationActive={isDurationActive}
        onDurationSwitch={this.onDurationSwitch}
        minutesPicker={minutesPicker}
        hoursPicker={hoursPicker}
      />
    )
  }
}

TimePickerContainer.propTypes = {
  componentId: PropTypes.string,
  type: PropTypes.string,
  listComponentId: PropTypes.string,
  onDone: PropTypes.func,
  startDate: PropTypes.string,
  isTimeZoneEnabled: PropTypes.bool,
  isMultiTimeZone: PropTypes.bool,
  isEndEnabled: PropTypes.bool,
  endDate: PropTypes.string,
  closeOtherPickerIOS: PropTypes.func,
  showDatePicker: PropTypes.bool,
  showEndDatePicker: PropTypes.bool,
  showEndTimePicker: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  onZonesScrolled: PropTypes.func,
  duration: PropTypes.number,
  refFunction: PropTypes.func,
  checkForTodaysDate: PropTypes.func.isRequired,
  isStartDateToday: PropTypes.bool,
  dateArray: PropTypes.array,
  startDateIndex: PropTypes.number,
  endDateIndex: PropTypes.number,
  isTimeInterval: PropTypes.bool,
  timezone: PropTypes.string,
  isDurationActive: PropTypes.bool,
  onDurationSwitch: PropTypes.func,
  closeLayout: PropTypes.func,
}

export default TimePickerContainer
