import React from "react"
import propTypes from "prop-types"
import { View, Text } from "react-native"
import AlphaList from "./verticalAlphabet"

import { styles } from "./styles"

const AlphabeticalListColumn = ({
  alphabet,
  onTapLetter = () => {},
  activeLetterIndex,
  activeLetter,
}) => {
  return (
    <View style={styles.alphabetIndexContainer}>
      {activeLetterIndex || activeLetter ? (
        <View
          style={[
            styles.activeLettercontainer,
            { top: activeLetterIndex * 10 },
          ]}
        >
          <Text style={styles.activeLetter}>{activeLetter}</Text>
        </View>
      ) : null}
      <AlphaList
        alphabet={alphabet}
        letterStyle={styles.alphabetIndexLetter}
        onTapLetter={onTapLetter}
        letterHeight={22}
      />
    </View>
  )
}

AlphabeticalListColumn.propTypes = {
  alphabet: propTypes.arrayOf(propTypes.string),
  onTapLetter: propTypes.func,
  activeLetterIndex: propTypes.number,
  activeLetter: propTypes.string,
}

export default AlphabeticalListColumn
