/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"
// import Icon from 'react-native-vector-icons/FontAwesome5'
import { View, Text, TouchableOpacity, Image, Animated } from "react-native"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
// import ReactNativeHapticFeedback from 'react-native-haptic-feedback'
import Linkify from "linkifyjs/react"
import ReactTooltip from "react-tooltip"

import {
  selectTeeupReactionsBySuggestionId,
  selectTeeupPeople,
  selectTeeupParticipants,
  selectDecidedGameplanByType,
  selectTeeupGoingPeopleCount,
} from "@selectors/teeups"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { images } from "@utils/imageUtils"
import { getMonthFormat, getDateWithTimezone } from "@utils/dateUtils"
import actionTypes from "@actions/actionTypes"
import {
  GAMEPLAN_OPTIONS,
  GAMEPLAN_SOURCE_TYPE,
  getSuggestionValue,
} from "@utils/gamePlanUtils"
import { AppColors } from "@theme/"
// import { showSuggestionChat } from '@nav'
import Toast from "@ui/toast"
// import { teeupUserStatusKeys } from '@configs/enums'

import { SectionHeaderStyles } from "./gameplansStyles"
import ReactionButton from "./reactionButton"
import TeeupUserStatuses from "./TeeupUserStatuses"
import I18n from "@i18n"
import { gameplanOptionStatusIds } from "@configs/mappings"
import { getReactionsResult } from "@utils/teeupUtils"
import clock from "../../assets/images/clock.svg"
import location from "../../assets/images/location.svg"
import suggestionIconUndecided from "../../assets/images/suggestionIconUndecided.png"
import suggestionIconDecided from "../../assets/images/suggestionIconDecided.png"
import { ReactSVG } from "react-svg"
import { ReactComponent as LocationSVG } from "../../assets/images/location.svg"
import { ReactComponent as ClockSVG } from "../../assets/images/clock.svg"
import { hideSuggestionListFooter } from "@actions/commonActions"
import threeDotsIcon from "../../assets/images/three-dots.svg"
import ReactDOM from "react-dom"
import SuggestionPopup from "./SuggestionPopup"

import "./svgStyles.scss"
// const isDecided = (user, availability) =>
//     availability?.startTime && availability?.endTime && isGoing(user)

// const isGoing = user =>
//     user.status === teeupUserStatusKeys.going ||
//     user.status === teeupUserStatusKeys.onmyway ||
//     user.status === teeupUserStatusKeys.arrived ||
//     user.status === teeupUserStatusKeys.mightgo

class SuggestionSectionHeader extends React.Component {
  constructor(props) {
    super(props)
    this._promptAnimValue = new Animated.Value(0)
    this._promptAnimActiveValue = new Animated.Value(0)
    this._rippleOpacity = new Animated.Value(0)
    this._rippleScale = new Animated.Value(0)
    this._promptAnimation = null
    this._dropActive = false
    this.state = {
      isHoverSuggestionCount: false,
    }
    this.infoRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { isDragging, cardExpanded } = this.props

    if (isDragging !== prevProps.isDragging) {
      if (this._promptAnimation) {
        this._promptAnimation.stop()
        this._promptAnimValue.setValue(0)
      }
      if (isDragging) {
        this._promptAnimActiveValue.setValue(0)
        this._dropActive = false
        this._promptAnimation = Animated.timing(this._promptAnimValue, {
          toValue: 1,
          duration: 600,
          delay: cardExpanded ? 0 : 300,
          useNativeDriver: true,
        })
        this._promptAnimation.start()
      }
    }
  }

  measureInWindow(func) {
    this._contentRef.measureInWindow(func)
  }

  animateDropActive(active) {
    if (this._dropActive === active) {
      return
    }

    this._dropActive = active
    Animated.timing(this._promptAnimActiveValue, {
      toValue: active ? 1 : 0,
      duration: 150,
      useNativeDriver: true,
    }).start()

    // ReactNativeHapticFeedback.trigger('impactLight', {
    //     enableVibrateFallback: true,
    //     ignoreAndroidSystemSettings: true,
    // })
  }

  animateChangeGameplan() {
    this._rippleOpacity.setValue(0)
    this._rippleScale.setValue(0)

    Animated.parallel([
      Animated.timing(this._promptAnimValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(this._rippleOpacity, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(this._rippleScale, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }),
    ]).start()
    setTimeout(() => {
      Animated.timing(this._rippleOpacity, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start()
    }, 300)
  }

  handleDecideSuggestion(suggestionId, isDecided) {
    if (!suggestionId) {
      return
    }

    const {
      permissions: { allowDecideGameplan },
    } = this.props

    if (allowDecideGameplan) {
      // globalDispatch({
      //     type: actionTypes.MARK_AS_DECIDED,
      //     payload: { suggestionId, isDecided },
      // })
    } else {
      Toast.show(I18n.t("teeup.toastMsg.forbiddenDecideGP"), {
        bottomOffset: 8,
        textAlign: "center",
      })
    }
  }

  renderLockIcon() {
    const { suggestion = {}, pollStatus } = this.props
    const { id, type } = suggestion
    const noSuggestion = _.isEmpty(suggestion)
    const suggestionType = this.props.type || type
    const disabled =
      suggestionType === GAMEPLAN_OPTIONS.startsWhen ||
      suggestionType === GAMEPLAN_OPTIONS.whenWorks ||
      noSuggestion
    let decided = suggestion.decided
    if (
      suggestionType === GAMEPLAN_OPTIONS.startsWhen &&
      suggestion.currentPeopleCount >= suggestion.peopleCount
    ) {
      decided = true
    }

    if (
      pollStatus === gameplanOptionStatusIds.SCHEDULED &&
      suggestionType === GAMEPLAN_OPTIONS.whenWorks
    ) {
      decided = true
    }

    return (
      <View
        style={SectionHeaderStyles.lockIcon}
        // onPress={() => this.handleDecideSuggestion(id, !decided)}
        // disabled={disabled}
      >
        <img
          style={{ width: 30, height: 30 }}
          src={decided ? suggestionIconDecided : suggestionIconUndecided}
          alt="suggestion icon"
        />
      </View>
    )
  }

  renderContentHeader(type, gameplanExists, suggestion, pollStatus, onPress) {
    const {
      icon,
      newSuggestionsCount,
      suggestionsCount,
      handleOpenSuggestion,
      hideSuggestionListFooter,
    } = this.props
    let { title } = this.props

    let info = ""
    let color = ""
    let customIcon = ""
    let customSizes = null
    switch (type) {
      case "when":
      case GAMEPLAN_OPTIONS.whenWorks:
      case GAMEPLAN_OPTIONS.startsWhen:
        // customIcon = clock
        customIcon = <ClockSVG className="suggestionSVG" />
        customSizes = { width: 12, height: 12 }
        break
      case "where":
        // customIcon = location
        customIcon = <LocationSVG className="suggestionSVG" />
        customSizes = { width: 11, height: 13 }
        break
      default:
        customIcon = "cube"
    }
    // customIcon = icon || customIcon

    if (!(gameplanExists && suggestion.decided)) {
      if (type === GAMEPLAN_OPTIONS.startsWhen) {
        info = "・AVAILABILITY"
      } else if (type === GAMEPLAN_OPTIONS.whenWorks) {
        info = `・${this.getPollStatusLabel(pollStatus).toUpperCase()}`
      } else if (newSuggestionsCount > 0) {
        info =
          this.getPollStatusLabel(pollStatus).toUpperCase() ||
          `・${newSuggestionsCount} NEW SUGGESTION${
            newSuggestionsCount > 1 ? "S" : ""
          }`
        // color = AppColors.brand.pink
      } else {
        info =
          suggestionsCount > 0
            ? this.getPollStatusLabel(pollStatus).toUpperCase() ||
              `・${suggestionsCount} SUGGESTION${
                suggestionsCount > 1 ? "S" : ""
              }`
            : "・NO SUGGESTIONS YET"
      }
    } else {
      info = "・DECIDED"
      color = "#00c5ae"
    }

    return (
      <View style={SectionHeaderStyles.contentHeader}>
        <View style={SectionHeaderStyles.suggestWrapper}>
          {/* <Image source={customIcon} style={{...customSizes, tintColor: color}} /> */}
          {customIcon !== "cube" && customIcon}
          {/* <img src={customIcon} style={{}} /> */}
          <Text
            style={[SectionHeaderStyles.contentHeaderText, color && { color }]}
            numberOfLines={1}
            ellipsizeMode="middle"
          >
            {/* {`${title.toUpperCase()}${info}`} */}
            {`${title.toUpperCase()}`}
            {suggestion.decided && "・DECIDED"}
          </Text>
        </View>

        <TouchableOpacity
          style={SectionHeaderStyles.suggestWrapper}
          onPress={() => {
            this.props.onAddSuggestion()
            handleOpenSuggestion()
            hideSuggestionListFooter()
            ReactTooltip.hide(this.infoRef.current)
          }}
          //    onPress={handleOpenSuggestion}
        >
          {/* onPress={() => onPress()}> */}
          <div
            style={{ height: "auto" }}
            className="overviewheader_status_settings_icon_lamp"
            ref={this.infoRef}
            data-tip={"Make Suggestion"}
            data-for="information-tooltip"
            onMouseOver={(e) => {
              e.stopPropagation()
              ReactTooltip.show(this.infoRef.current)
            }}
            onMouseLeave={(e) => {
              e.stopPropagation()
              ReactTooltip.hide(this.infoRef.current)
            }}
          >
            <ReactSVG src={images.suggestLamp} />
            <div className="overviewheader_status_settings_icon_lamp_text">
              Suggest
            </div>
          </div>
        </TouchableOpacity>
      </View>
    )
  }

  getPollStatusLabel = (pollStatus) => {
    switch (pollStatus) {
      case gameplanOptionStatusIds.POLL_CLOSED:
        return I18n.t("pollStatuses.closedPoll")
      case gameplanOptionStatusIds.POLL_COMPLETE:
        return I18n.t("pollStatuses.completed")
      case gameplanOptionStatusIds.POLLING:
        return I18n.t("pollStatuses.polling")
      case gameplanOptionStatusIds.SCHEDULED:
        return I18n.t("pollStatuses.scheduled")
      default:
        return ""
    }
  }

  renderReactionIcon = (image, total = 0) => {
    return (
      <View style={SectionHeaderStyles.reactionCountView}>
        <Image style={SectionHeaderStyles.totalImage} source={image} />
        <Text style={SectionHeaderStyles.reactionsTotal}>{total}</Text>
      </View>
    )
  }

  renderRightHeaderPart = (
    type,
    suggestionsCount,
    noSuggestion,
    suggestion,
    reactions,
    isTeeupCancelled,
    pollStatus,
    total,
    sourceType,
    sections
  ) => {
    if (type !== GAMEPLAN_OPTIONS.whenWorks && suggestionsCount < 1) {
      return null
    }

    if (
      (type === GAMEPLAN_OPTIONS.whenWorks ||
        (type === GAMEPLAN_OPTIONS.when &&
          sourceType === GAMEPLAN_SOURCE_TYPE.whenWorks)) &&
      !suggestion.decided
    ) {
      return (
        <View style={SectionHeaderStyles.pollStatusBlock}>
          {this.renderReactionIcon(images.acceptedTimeIcon, total.approve)}
          {this.renderReactionIcon(images.canceledTimeIcon, total.cancel)}
        </View>
      )
    }

    if (type === GAMEPLAN_OPTIONS.startsWhen) {
      return <TeeupUserStatuses />
    } else {
      return (
        !noSuggestion &&
        !suggestion.decided && (
          <ReactionButton
            suggestionId={suggestion.id}
            reactions={reactions}
            disableReaction={isTeeupCancelled}
            inSuggestion={false}
            sections={sections}
          />
        )
      )
    }
  }

  getWhenWorksTitleLine = (pollStatus, time = 0) => {
    switch (pollStatus) {
      case gameplanOptionStatusIds.POLL_CLOSED:
      case gameplanOptionStatusIds.POLLING: {
        return I18n.t("determiningWhenWorks")
      }
      case gameplanOptionStatusIds.POLL_COMPLETE:
        return I18n.t("decisionWhenWorks")
      case gameplanOptionStatusIds.SCHEDULED:
        return getDateWithTimezone(time).format("dddd MMM D")
      default:
        return null
    }
  }

  renderLines = (
    type,
    suggestionValues,
    pollStatus,
    suggestionsList,
    startsWhenConditionMet
  ) => {
    const { suggestionsCount } = this.props
    let color = AppColors.brand.blackTwo
    if (type === GAMEPLAN_OPTIONS.startsWhen && !startsWhenConditionMet) {
      color = AppColors.brand.darkGrey2
    }

    let line1 = suggestionValues.line1 || ""
    let line2 = suggestionValues.line2 || ""

    let icon = null

    if (type === GAMEPLAN_OPTIONS.whenWorks) {
      line1 = this.getWhenWorksTitleLine(pollStatus, suggestionsList[0].date) // TODO: change to actual
      line2 =
        pollStatus === gameplanOptionStatusIds.SCHEDULED
          ? getDateWithTimezone(suggestionsList[0].date).format("h:mm A")
          : I18n.t("periodWhenWorks", {
              // TODO: change to actual
              startDate: getMonthFormat(
                getDateWithTimezone(suggestionsList[0].date)
              ),
              endDate: getMonthFormat(
                getDateWithTimezone(
                  suggestionsList[suggestionsList.length - 1].date
                )
              ),
            })
      color =
        pollStatus === gameplanOptionStatusIds.SCHEDULED
          ? AppColors.brand.blackTwo
          : AppColors.brand.darkGrey2
    } else {
      if (!line1) {
        if (line2) {
          line1 = line2
        } else {
          // line1 = type === 'where' ? 'Suggest Where' : 'Suggest When'
          line1 =
            suggestionsCount > 0
              ? `View ${suggestionsCount} Suggestion${
                  suggestionsCount > 1 ? "s" : ""
                }`
              : "No Suggestions Yet"
          icon = suggestionsCount > 0 && (
            <img
              src={images.listView}
              style={{ width: 15, height: 15, marginRight: 5 }}
            />
          )
          color = AppColors.brand.pink
        }
        line2 = ""
      }
    }

    const linkOptions = {
      className: "message-link",
    }

    return (
      <View>
        <Text style={[SectionHeaderStyles.contentText]} numberOfLines={1}>
          {icon}

          {line1}
        </Text>
        {!!line2 && (
          <Text style={[SectionHeaderStyles.contentText]} numberOfLines={1}>
            <Linkify options={linkOptions}>{line2}</Linkify>
          </Text>
        )}
      </View>
    )
  }

  handleSuggestionListPress = () => {
    const { onPress, handleOpenSuggestion } = this.props

    onPress()
    // handleOpenSuggestion();
  }

  render() {
    const {
      suggestion = {},
      suggestionsCount,
      reactions,
      isTeeupCancelled,
      isDragging,
      onPress,
      onLongPress,
      testID,
      title,
      pollStatus,
      suggestionsList,
      people,
      gameplans,
      sourceType,
      handleOpenSuggestion,
      hideSuggestionListFooter,

      isSuggestionPopupOpened,
      currentSuggestion,
      currentSuggestionData,
      toggleSuggestionPopup,
      setCurrentSuggestion,
      setCurrentSuggestionData,
    } = this.props

    let sections = []

    reactions.map(({ reactionId, reactedBy }) => {
      if (reactedBy) {
        let reaction = reactionId
        let data = reactedBy.map((userId) => {
          return { name: people[userId]?.name, avatar: people[userId]?.avatar }
        })
        if (!data.length) {
          reaction = ""
        }
        sections.push({ reaction, data })
      }
    })

    const type = this.props.type || suggestion.type

    const noSuggestion = _.isEmpty(suggestion)
    const disableLinkingToSuggestionChat = noSuggestion && !onPress
    // const dropPrompt = I18n.t('gameplans.dropToChange')
    const dropPrompt = "Drop To Change"
    let startsWhenGameplan = null
    let startsWhenConditionMet = false
    if (suggestion.type === GAMEPLAN_OPTIONS.startsWhen) {
      startsWhenGameplan = gameplans.find(
        (gameplan) => gameplan.type === GAMEPLAN_OPTIONS.startsWhen
      )
      startsWhenConditionMet = startsWhenGameplan.conditionsMet
    }

    let suggestionValues = getSuggestionValue(
      suggestion,
      type,
      startsWhenGameplan,
      title
    )

    const reactionTotal = getReactionsResult(
      pollStatus,
      people,
      suggestionsList,
      gameplans,
      reactions
    )

    return (
      <>
        <TouchableOpacity
          style={[
            SectionHeaderStyles.container,
            {
              height:
                suggestion.id && suggestionsCount - 1
                  ? "135px"
                  : suggestion.id
                  ? "115px"
                  : "100px",
            },
          ]}
          ref={(ref) => (this._contentRef = ref)}
          disabled={isTeeupCancelled || disableLinkingToSuggestionChat}
          onLongPress={onLongPress}
          onPress={() =>
            onPress ? this.handleSuggestionListPress() : () => {}
          }
          testID={`${testID}-name`}
        >
          {/* {this.renderLockIcon()} */}
          <View style={SectionHeaderStyles.contentWrapper}>
            <View style={SectionHeaderStyles.content}>
              {this.renderContentHeader(
                type,
                !noSuggestion,
                suggestion,
                pollStatus,
                onPress ? onPress : () => {}
              )}
              <div
                className="gameplan__box"
                onClick={(e) => {
                  e.stopPropagation()
                  if (suggestion.id) {
                    toggleSuggestionPopup(true)
                    setCurrentSuggestion(suggestion)
                    setCurrentSuggestionData({
                      type,
                      suggestionValues,
                      pollStatus,
                      suggestionsList,
                      startsWhenConditionMet,
                      suggestionsCount,
                      suggestion,
                      reactions,
                      noSuggestion,
                      isTeeupCancelled,
                      reactionTotal,
                      sourceType,
                      sections,
                    })
                  } else {
                    this.handleSuggestionListPress()
                  }
                }}
              >
                <View
                  style={[
                    SectionHeaderStyles.contentBox,
                    suggestionValues.empty && { height: "100%", maxHeight: 61 },
                  ]}
                  className="gameplan__box"
                >
                  {!!suggestion.id && (
                    <img src={threeDotsIcon} className="three__dots" />
                  )}
                  {this.renderLines(
                    type,
                    suggestionValues,
                    pollStatus,
                    suggestionsList,
                    startsWhenConditionMet
                  )}
                  {this.renderRightHeaderPart(
                    type,
                    suggestionsCount,
                    noSuggestion,
                    suggestion,
                    reactions,
                    isTeeupCancelled,
                    pollStatus,
                    reactionTotal,
                    sourceType,
                    sections
                  )}
                </View>
              </div>
              {suggestionsCount > 0 && suggestion.id && !suggestion.decided && (
                <Text
                  onMouseOver={() => {
                    this.setState({ isHoverSuggestionCount: true })
                  }}
                  onMouseLeave={() => {
                    this.setState({ isHoverSuggestionCount: false })
                  }}
                  style={[
                    SectionHeaderStyles.moreSuggestions,
                    this.state.isHoverSuggestionCount && { color: "#222222" },
                  ]}
                >
                  {suggestion.id && suggestionsCount - 1 >= 1
                    ? suggestionsCount - 1
                      ? suggestionsCount - 1 + " suggestion"
                      : suggestionsCount - 1 + " more suggestions"
                    : ""}
                </Text>
              )}
            </View>

            {/* <Icon
                            style={SectionHeaderStyles.chevronIcon}
                            name="chevron-right"
                            color={AppColors.brand.grey193}
                            size={16}
                        /> */}
          </View>
        </TouchableOpacity>

        {isSuggestionPopupOpened &&
          currentSuggestion.id === suggestion.id &&
          ReactDOM.createPortal(
            <SuggestionPopup
              currentSuggestion={currentSuggestion}
              currentSuggestionData={currentSuggestionData}
              toggleSuggestionPopup={toggleSuggestionPopup}
              setCurrentSuggestion={setCurrentSuggestion}
              setCurrentSuggestionData={setCurrentSuggestionData}
              renderLines={this.renderLines}
              renderRightHeaderPart={this.renderRightHeaderPart}
              onAddSuggestion={this.props.onAddSuggestion}
              handleOpenSuggestion={handleOpenSuggestion}
            />,
            document.getElementById("modal-root")
          )}
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const type = props.type || props.suggestion.type
  const decidedGameplan = selectDecidedGameplanByType(type)(state)
  const decidedGameplanId = decidedGameplan.id || -1
  const goingPeopleCount = selectTeeupGoingPeopleCount(state)

  if (props.suggestion && props.suggestion.id) {
    const suggestionId = props.suggestion.id
    const reactions = selectTeeupReactionsBySuggestionId(suggestionId)(state)
    const people = selectTeeupPeople(state)
    const participants = selectTeeupParticipants(state)
    const activeTeeupId = selectActiveTeeupId(state)
    return {
      reactions,
      people,
      participants,
      decidedGameplanId,
      activeTeeupId,
      goingPeopleCount,
    }
  } else {
    return {
      reactions: [],
      people: selectTeeupPeople(state),
      participants: [],
      decidedGameplanId,
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSuggestionListFooter: () => dispatch(hideSuggestionListFooter()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SuggestionSectionHeader)

SuggestionSectionHeader.defaultProps = {
  suggestion: {},
}

SuggestionSectionHeader.propTypes = {
  reactions: PropTypes.array,
  gameplans: PropTypes.array,
  suggestionsList: PropTypes.array,
  onPress: PropTypes.func,
  onLongPress: PropTypes.func,
  isActive: PropTypes.bool,
  permissions: PropTypes.object,
  suggestion: PropTypes.object,
  people: PropTypes.object,
  participants: PropTypes.array,
  type: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  isMultizone: PropTypes.bool,
  testID: PropTypes.string,
  isTeeupCancelled: PropTypes.bool,
  activeTeeupId: PropTypes.number,
  isDragging: PropTypes.bool,
  goingPeopleCount: PropTypes.number,
  pollStatus: PropTypes.number,
  suggestionsCount: PropTypes.number.isRequired,
  newSuggestionsCount: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  cardExpanded: PropTypes.bool,
  isHeaderCollapsed: PropTypes.bool,
  showTimezoneView: PropTypes.func,
  sourceType: PropTypes.string,
}
