import React, { useEffect, useState } from "react"

import ct from "countries-and-timezones"

import { getDateWithTimezone } from "@utils/dateUtils"

import './index.scss'

export const getTimezones = () => ct.getAllTimezones()

const PeopleTimeZone = ({ peopleTimezone, close }) => {
  const [timezoneInfo, setTimezoneInfo] = useState(null)

  useEffect(() => {
    setTimezoneInfo(getTimezones()[peopleTimezone])
  }, [])


  return (
    <div className="people-column-timezone">
      {!close ? (
        <>
          {!timezoneInfo && (
            <p className="people-column-timezone__empty">Timezone not specified</p>
          )}
          <h3 className="people-column-timezone__zone">
            {timezoneInfo && timezoneInfo.name.split('/')[1].replace(/[0-9+]/g, '')}
          </h3>
          <p className="people-column-timezone__country">
            {timezoneInfo && `${timezoneInfo.country} `}
            (UTC 
              <span className="people-column-timezone__country--plus">
                {timezoneInfo && ` ${timezoneInfo.utcOffsetStr}`}
              </span>
            )
          </p>
          <div className="people-column-timezone__time">
            {peopleTimezone &&
              getDateWithTimezone(new Date(), peopleTimezone).format("h:mm A ddd MMM D")}
          </div>
        </>
      ): (
        <div className="people-column-timezone__container">
          <p className="people-column-timezone__country">
            {timezoneInfo && `${timezoneInfo.country} `}
          </p>
          <div className="people-column-timezone__time--short">
            {peopleTimezone &&
              getDateWithTimezone(new Date(), peopleTimezone).format("h:mm a")}
          </div>
        </div>
      )}
    </div>
  )
}

export default PeopleTimeZone
