import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.brand.white,
  },
  header: {
    backgroundColor: AppColors.brand.white,
  },
  titleContainerStyle: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  headerTitle: {
    ...AppFonts.dynamic("headerSmall", "demibold"),
    color: AppColors.brand.black,
    textAlign: "center",
    lineHeight: Units.rem(1.5625),
    letterSpacing: -Units.responsiveValue(0.4),
    width: "100%",
  },
  headerSubtitle: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.grey157,
    textAlign: "center",
    lineHeight: Units.rem(1.25),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  shadowTop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    opacity: 0.08,
    height: Units.rem(0.5),
  },
})

export const ViewProps = {
  gradientShadow: {
    colors: ["black", "white"],
  },
}

export default styles
