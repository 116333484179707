import React from "react"
import PropTypes from "prop-types"
import { userStatusImage, isShowAdditionalPeople } from "@utils/teeupUtils"

import { View, Text, Image } from "react-native"

import { AppColors } from "@theme/"
import { UserStatusAccesoryStyles as styles } from "./styles"

const UserStatusAccesory = ({
  additionalCount,
  userStatus,
  disabled,
  accesoryStyle = {},
  type = "small",
}) => {
  const icon = userStatusImage(userStatus, false)
  const useAdditionalCountProps = isShowAdditionalPeople(
    userStatus,
    additionalCount
  )

  if (!icon) {
    return null
  }

  return (
    <View
      style={[
        styles.accesory(!!useAdditionalCountProps),
        styles.sizes[type].accesory(!!useAdditionalCountProps),

        { backgroundColor: AppColors.status.user[userStatus] },
        disabled && styles.disabledAccesory,
        accesoryStyle,
      ]}
    >
      <Image
        source={icon}
        style={styles.sizes[type].accesoryImage(!!useAdditionalCountProps)}
        resizeMode="contain"
      />

      {!!useAdditionalCountProps && (
        <View style={styles.sizes[type].additionalCountContainer}>
          <Text
            style={[styles.additionalCount, styles.sizes[type].additionalCount]}
          >
            +{additionalCount}
          </Text>
        </View>
      )}
    </View>
  )
}

UserStatusAccesory.propTypes = {
  additionalCount: PropTypes.number,
  userStatus: PropTypes.string,
  disabled: PropTypes.bool,
  accesoryStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  type: PropTypes.oneOf(["small", "medium"]),
}

export default UserStatusAccesory
