import React, { Component } from "react"
import { Text, Image, View } from "react-native"
// import { TabView, TabBar, SceneMap } from 'react-native-tab-view'
import PropTypes from "prop-types"
import cn from "classnames"
import "./tabView.scss"

class tabView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
    }
  }

  renderTabLabel = (props) => {
    const { tabsProps } = this.props
    let { index } = this.state
    const routes = [
      { key: "suggestionView", title: "Suggestions" },
      { key: "likeView", title: "Reactions" },
    ]
    // let selected = routes[index].key === props.route.key
    let selected = routes[index].key === "likeView"
    props.route = { key: "suggestionView", title: "Suggestions" }

    return (
      <View>
        <>
          <Text testID={props.route.testID}>{props.route.title}</Text>
          {tabsProps &&
            tabsProps[props.route.key] &&
            tabsProps[props.route.key].indicator > 0 && (
              <View>
                <Text>{tabsProps[props.route.key].indicator}</Text>
              </View>
            )}
        </>
      </View>
    )
  }

  render() {
    const {
      sceneMapping,
      renderScene,
      disableSwipe,
      routes,
      onTabIndexChange,
      isTemporalCustom,
    } = this.props

    return (
      <>
        {!isTemporalCustom && (
          <div className="tabsview">
            {routes.map((route, index) => (
              <div
                key={route.key}
                className={cn("tabsview__title", {
                  "tabsview__title--active": index === this.state.index,
                })}
                onClick={() => {
                  this.setState({ index })
                  onTabIndexChange && onTabIndexChange(index)
                }}
              >
                {route.title}
              </div>
            ))}
          </div>
        )}
        {/* <div style={{backgroundColor: '#f0f0f0'}}> */}
        {renderScene(routes[this.state.index])}
        {/* </div> */}
      </>
    )
  }
}

export default tabView

tabView.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      image: PropTypes.string,
    })
  ).isRequired,
  initialTabIndex: PropTypes.number,
  sceneMapping: PropTypes.object,
  renderScene: PropTypes.func,
  onTabIndexChange: PropTypes.func,
  onDataUpdate: PropTypes.func,
  disableSwipe: PropTypes.bool,
  tabsProps: PropTypes.object,
  // customTabbarWrapperStyle: PropTypes.object,
}
