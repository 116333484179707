import React from "react"
import PropTypes from "prop-types"
import {
  ZoneStyles as styles,
  ZoneProps,
  CALENDAR_BELT_HEIGHT,
  headerAvatarMargin,
} from "./timezonesStyles"
import { View, Text } from "react-native"
import Avatar from "@ui/avatar"
import { ThreeDots } from "react-loader-spinner"
import { AppColors } from "@theme"

const HeadersView = ({
  zones,
  onHeaderLayout,
  zoneHeight,
  initial,
  darkTheme,
  duration,
  fromSuggestionPopup,
  headerRef,
  isScrolling,
}) => {
  const loaderDots = (
    <ThreeDots color={AppColors.bgGradient2} height={10} width={80} />
  )

  return (
    <View
      ref={headerRef}
      style={initial ? styles.headersContainerInitial : styles.headersContainer}
      onLayout={onHeaderLayout}
      pointerEvents={"none"}
    >
      {zones.map((zone, index) => {
        return [
          <View style={styles.headerContainer} key={"H" + index}>
            {zone.avatars.map((avatar, indexZone) => (
              <Avatar
                key={"A" + indexZone}
                style={headerAvatarMargin(indexZone)}
                imageUrl={avatar}
                size={ZoneProps.avatarSize}
              />
            ))}
            <View
              style={[
                styles.headerNameContainer,
                !duration && styles.headerNameContainerLightBackground,
                darkTheme && styles.headerNameContainerBackground,
              ]}
            >
              <Text style={styles.headerName} numberOfLines={1}>
                {zone.title}
              </Text>
              <Text
                style={[
                  styles.headerTimezone,
                  darkTheme && styles.headerTimezoneLight,
                ]}
              >
                {zone.country} ({zone.abbreviation})
              </Text>
            </View>
            <View style={styles.flex} />
            {!fromSuggestionPopup && zone.time && (
              <View style={styles.dateContainer}>
                <Text style={styles.headerName}>
                  {isScrolling ? loaderDots : zone.day}
                </Text>
                <Text
                  style={[
                    styles.headerTimezone,
                    darkTheme && styles.headerTimezoneLight,
                    zone.isMyTZ && styles.headerTimezoneBold,
                  ]}
                >
                  {isScrolling ? loaderDots : zone.time}
                </Text>
              </View>
            )}
          </View>,
          <View
            key={index}
            style={{
              height:
                !!zoneHeight && zone.isMyTZ
                  ? 2 * zoneHeight + CALENDAR_BELT_HEIGHT
                  : zoneHeight,
            }}
          />,
        ]
      })}
    </View>
  )
}

HeadersView.propTypes = {
  zones: PropTypes.array.isRequired,
  onHeaderLayout: PropTypes.func.isRequired,
  zoneHeight: PropTypes.number,
  initial: PropTypes.bool,
  darkTheme: PropTypes.bool,
  duration: PropTypes.number,
}

export default HeadersView
