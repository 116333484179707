import React, { Component, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Props from "prop-types"
// import { Navigation } from 'react-native-navigation'
import _ from "lodash"

// import backend from '@apis/backend'
import { images } from "@utils/imageUtils"
// import {
//     showUserTags,
//     dismissModal,
//     showActionsheet,
//     showOverlayModal,
//     showInviteContactsToTeeup,
// } from '@nav'
import { selectUserId, selectUserInfo } from "@selectors/user"
import { selectContactById } from "@selectors/contacts"
import { selectRequestsBetweenUsersList } from "@selectors/requests"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { requestTypes } from "@utils/contactUtils"
import * as loginActions from "@actions/loginActions"
import * as requestActions from "@actions/requestActions"
import * as contactActions from "@actions/contactActions"
import * as teeupActions from "@actions/teeupActions"
import {
  fetchRequests,
  removeConnection,
  sendRequest,
} from "@actions/requestActions"
import {
  updateProfile,
  fetchProfile,
  fetchProfileWithTags,
  fetchUserInfo,
  getBlacklist,
} from "@actions/userActions"
import { AppColors } from "@theme"
import ErrorScreen from "@components/ui/errorScreen"
import LoadingScreen from "@components/ui/loadingScreen"
import ProfileView from "./profileView"
import strings from "@i18n"
import { checkIfEqual } from "@utils/dataUtils"
import Modal from "react-modal"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import InviteContactsToTeeup from "../../me/Contacts/InviteContactsToTeeup"
import UserTags from "../../me/UserTags"

// different from isCooeUser contact utils because fetchProfile doesn't return cooeId or userType
const isCooeUser = (user) => user && (!!user.username || !!user.avatar)
const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "initial",
    bottom: "initial",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "transparent",
    padding: "none",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
}

class ProfileContainer extends Component {
  static options(passProps) {
    return {
      topBar: {
        title: {
          text: passProps.isMyProfile ? "My Profile" : strings.contactDetails,
        },
        noBorder: true,
        leftButtons: [
          {
            id: "close",
            text: strings.systemButtons.close,
            icon: images.close,
            color: AppColors.brand.pink,
          },
        ],
        rightButtons: [
          passProps.isMyProfile
            ? {
                id: "edit",
                text: strings.systemButtons.edit,
                icon: images.edit,
                color: AppColors.brand.pink,
              }
            : {
                id: "actions",
                text: "Actions",
                icon: images.more,
              },
        ],
      },
    }
  }

  state = {
    interestsExpanded: false,
    user: null,
    interests: [],
    allInterests: [],
    tags: [],
    attributes: [],
    error: "",
    isLoading: false,
    blacklist: [],
    requestLoading: false,
    isEditProfileOpen: false,
    text: "",
    isModalOpened: false,
    content: null,
  }

  constructor(props) {
    super(props)
    // Navigation.events().bindComponent(this)
  }

  componentDidMount() {
    this.fetchProfileData()
  }

  componentDidAppear() {
    const { fromMeScreen } = this.props
    if (fromMeScreen) {
      this.updateProfileData()
    } else {
      this.fetchProfileData()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      this.fetchProfileData()
    }
    if (
      this.props.fromMeScreen &&
      this.state.isEditProfileOpen &&
      !checkIfEqual(this.props.userInfo, prevProps.userInfo)
    ) {
      this.setState({
        isLoading: true,
      })
    }
  }

  updateProfileData = () => {
    const { userInfo } = this.props
    const { interests = [], attributes = {}, ...otherUserInfo } = userInfo
    let updatedState = {
      user: otherUserInfo,
      isLoading: false,
      isEditProfileOpen: false,
    }
    if (interests) {
      updatedState.interests = interests.filter((item) => !item.private)
      updatedState.allInterests = interests
    }
    if (attributes) {
      updatedState.attributes = Object.values(attributes)
    }
    this.setState(updatedState)
  }

  fetchProfileData = async () => {
    const {
      userId,
      currentUserId,
      activeTeeupId,
      isMyProfile,
      selectedUser,
      refreshTeeup,
    } = this.props
    this.setState({ isLoading: true })
    try {
      const [user, { blockedUsers: blacklist }, userInfo] = await Promise.all([
        isMyProfile
          ? fetchProfile(userId)
          : fetchProfileWithTags(userId, currentUserId),
        getBlacklist(),
        isMyProfile ? fetchUserInfo(userId) : true,
      ])
      const { profile, interests, attributes, tags, isConnected } = user

      isMyProfile && updateProfile(userInfo)
      // activeTeeupId && refreshTeeup(activeTeeupId)

      this.setState({
        user: {
          ...selectedUser,
          ...profile,
          cooeId: profile.id,
        },
        interests: interests.filter((item) => !item.private),
        allInterests: interests,
        tags: isMyProfile ? [] : tags,
        attributes,
        isConnected,
        error: "",
        isLoading: false,
        blacklist,
      })
    } catch (error) {
      this.setState({
        error: error || "Unknown error",
        isLoading: false,
      })
    }
  }

  showConnectButtons(connection) {
    if (!connection || connection.sentTo !== this.props.userId) {
      return [
        {
          title: "Request to Connect",
          image: images.connect,
          callback: this.handleConnect,
        },
      ]
    }
    return []
  }

  handleActionsPress = () => {
    const { userId, connection } = this.props
    const { user, blacklist, isConnected } = this.state
    const checkForCooeUser = isCooeUser(user)
    const blacklisted = _.find(blacklist, ["blockedid", userId])

    const actionButtons = []
    if (blacklisted) {
      actionButtons.push({
        title: "Unblock User",
        image: images.block,
        callback: this.handleBlacklist,
      })
    } else {
      actionButtons.push({
        title: "Block User",
        image: images.block,
        callback: this.handleBlacklist,
      })
    }

    if (checkForCooeUser) {
      if (isConnected) {
        actionButtons.push({
          title: "Remove Connection",
          image: images.removeConnection,
          callback: this.handleConnect,
        })
      } else if (!connection || connection.sentTo !== userId) {
        actionButtons.push({
          title: "Request to Connect",
          image: images.connect,
          callback: this.handleConnect,
        })
      }
    }

    // showActionsheet(actionButtons)
  }

  navigationButtonPressed({ buttonId }) {
    const { user, allInterests, attributes } = this.state
    switch (buttonId) {
      case "edit":
        this.setState({ isEditProfileOpen: true })
        // Navigation.push(this.props.componentId, {
        //     component: {
        //         name: 'editProfile',
        //         passProps: {
        //             userId: this.props.userId,
        //             user,
        //             interests: allInterests,
        //             attributes,
        //         },
        //     },
        // })
        break
      case "actions": {
        this.handleActionsPress()
        break
      }
      case "close":
        if (this.props.onClose) {
          this.props.onClose()
        }
        // dismissModal(this.props.componentId)
        break
      default:
    }
  }

  toggleInsterests = () =>
    this.setState((prevState) => ({
      interestsExpanded: !prevState.interestsExpanded,
    }))

  setText = (text) => {
    this.setState({ text })
  }

  handleAddTag = () => {
    const { user, tags } = this.state
    const props = {
      title: user.name ? `${user.name}'s Tags` : "Tag User",
      usersToTag: [user],
      onTagsChange: this.handleTagsChange,
      onTagUsersEnd: this.fetchProfileData,
      selectedTags: tags,
      onClose: this.closeContent,
    }

    this.setState({ content: <UserTags {...props} /> })
  }

  handleConnect = async () => {
    const { isConnected } = this.state
    const { userId, fetchRequests, currentUserId } = this.props

    if (isConnected) {
      confirmAlert({
        title: "Remove Connection",
        message: `Are you sure you want to remove this user as a Connected contact?`,
        buttons: [
          {
            label: "Cancel",
            onClick: () => {},
          },
          {
            label: "Remove",
            onClick: () => {
              removeConnection(userId)
              fetchRequests(currentUserId)
            },
          },
        ],
      })
    } else {
      this.setState({ isModalOpened: true })
    }
  }

  connectUsers = async (userId, message) => {
    const { currentUserId } = this.props

    if (!this.state.requestLoading) {
      this.setState({ requestLoading: true })
      sendRequest(
        currentUserId,
        userId,
        message,
        requestTypes.inviteToConnect
      ).then(() => {
        this.setState({ requestLoading: false }, () => this.fetchProfileData())
      })
    }
  }

  onInvite = () => {
    const invitees = [this.state.user]
    this.setState({
      content: (
        <InviteContactsToTeeup
          closeContent={this.closeContent}
          invitees={invitees}
        />
      ),
    })
  }

  handleBlacklist = async () => {
    const { userId } = this.props
    const blacklisted = _.find(this.state.blacklist, [
      "blockedid",
      this.props.userId,
    ])

    if (!blacklisted) {
      // showOverlayModal({
      //     title: 'Block User',
      //     description:
      //         'This person will no longer see your profile and cannot invite you to TeeUps, Groups, or to Connect.',
      //     rightButton: {
      //         text: 'Block',
      //         onPress: async () => {
      //             // await backend.blacklistUser(userId)
      //             // const {
      //             //     blockedUsers: blacklist,
      //             // } = await backend.getBlacklist()
      //             // this.setState({ blacklist })
      //         },
      //     },
      // })
    } else {
      // await backend.removeUserFromBlacklist(userId)
      // const { blockedUsers: blacklist } = await backend.getBlacklist()
      // this.setState({ blacklist })
    }
  }

  closeModal = () => {
    this.setState({ isModalOpened: false })
  }

  closeContent = () => {
    this.setState({ content: null })
  }

  render() {
    const {
      interestsExpanded,
      user,
      interests,
      tags,
      attributes,
      error,
      isLoading,
      requestLoading,
      isConnected,
      isModalOpened,
      text,
      content,
    } = this.state
    const { isMyProfile, connection, userId } = this.props

    if (error) {
      return <ErrorScreen message={error} />
    }

    if (isLoading && !user) {
      return <LoadingScreen />
    }

    if (!user) {
      return null
    }

    const checkForCooeUser = isCooeUser(user)
    const showFullName = isMyProfile || isConnected
    const nameLine1 =
      showFullName || !user.username ? user.name : `@${user.username}`
    const nameLine2 = showFullName && user.username ? `@${user.username}` : null

    return (
      <>
        <Modal
          isOpen={isModalOpened}
          onRequestClose={this.closeModal}
          style={modalStyles}
          ariaHideApp={false}
        >
          <div
            className="react-confirm-alert-body"
            style={{ textAlign: "center" }}
          >
            <h1>Request to Connect</h1>
            <p>
              Add a short message to describe why you want to connect (optional)
            </p>
            <input
              style={{
                width: "90%",
                border: "1px solid #666",
                borderRadius: 5,
                padding: "0px 10px",
                height: 26,
              }}
              placeholder="Introduction message"
              type="text"
              value={text}
              onChange={({ target }) => {
                this.setText(target.value)
              }}
            />
            <div
              className="react-confirm-alert-button-group"
              style={{ justifyContent: "center" }}
            >
              <button onClick={this.closeModal}>Cancel</button>
              <button
                onClick={() => {
                  this.connectUsers(userId, text)
                  this.closeModal()
                }}
              >
                Request
              </button>
            </div>
          </div>
        </Modal>
        {content ? (
          <div style={{ top: "-54px", position: "relative" }}>{content}</div>
        ) : (
          <ProfileView
            user={user}
            nameLine1={nameLine1}
            nameLine2={nameLine2}
            attributes={attributes}
            userTags={tags}
            interests={interests}
            interestsExpanded={interestsExpanded}
            isMyProfile={isMyProfile}
            isLoading={isLoading}
            onInterestsToggle={this.toggleInsterests}
            onAddTag={this.handleAddTag}
            onRefresh={this.fetchProfileData}
            onConnect={this.handleConnect}
            onInvite={this.onInvite}
            requestLoading={requestLoading}
            request={connection}
            connected={isConnected}
            isCooeUser={checkForCooeUser}
          />
        )}
      </>
    )
  }
}

ProfileContainer.defaultProps = {
  fromMeScreen: false,
}

ProfileContainer.propTypes = {
  componentId: Props.string,
  isMyProfile: Props.bool,
  currentUserId: Props.number,
  activeTeeupId: Props.number,
  userId: Props.number.isRequired,
  selectedUser: Props.object.isRequired,
  onClose: Props.func,
  // refreshTeeup: Props.func.isRequired,
  connection: Props.object,
  fromMeScreen: Props.bool,
  userInfo: Props.object,
}

const mapStateToProps = (state, passedProps) => {
  const currentUserId = selectUserId(state)
  const selectedUser = selectContactById(passedProps.userId)(state)
  const activeTeeupId = selectActiveTeeupId(state)
  const requestsBetweenUsers = selectRequestsBetweenUsersList(
    passedProps.userId
  )(state)
  const connection = requestsBetweenUsers[requestsBetweenUsers.length - 1] || {}
  const userInfo = selectUserInfo(state)

  return {
    currentUserId,
    selectedUser,
    activeTeeupId,
    connection,
    userInfo,
  }
}

const mapDispatchToProps = {
  fetchRequests,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
