import React, { useState, useRef, useEffect } from "react"
import { View, Text, TouchableOpacity, Image } from "react-native"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import ReactDOM from "react-dom"
import { AppFonts, AppColors, Units } from "@theme"
import cn from "classnames"

import TeeupStatus from "./TeeupStatus"
import MyStatus from "./MyStatus"
import ActionButtonsBar from "./ActionButtonsBar"
import TeeupItemHeaderTitle from "./Header"
import LeftItemsHeader from "./LeftItemsHeader"
import { renderFirstAvatars, TimeFromNow } from "./teeupItemComponents"
import { styles } from "./styles"
import propsMapper from "./propsMapper"
import arrowRight from "../../../assets/images/arrowRight-grey.png"
import { images } from "@utils/imageUtils"
import moment from "moment"
import { teeupUserStatusKeys, teeupUserStatusPriorities } from "@config/enums"
import threeDotsIcon from "../../../assets/images/three-dots.svg"
import { useHistory, useRouteMatch } from "react-router-dom"
import TeeupTooltip from "./TeeupTooltip"

import "./index.scss"

export const TeeupItem = React.forwardRef(
  ({
    ownerId,
    isOrganizer,
    ownerAvatar,
    ownerName,
    name,
    status,
    teeupStatusStyling,
    isGreyOverlay,
    userStatus,
    isArchivePromptShow,
    isInitiallyInvited,
    isDroppedOut,
    isSkippedDeclined,
    onArchivePressed,
    onTrashPressed,
    onMoveToActivePress,
    when,
    where,
    goingCount,
    isNewTitle,
    isNewWhen,
    isNewWhere,
    newMessages,
    onPress,
    photo = "",
    ignoreHorizontalMargin = false,
    fromTeeupsSection = false,
    testID,
    style,
    contentStyle,
    fromNowString,
    invitation,
    onDeclineInvitation,
    onAcceptInvitation,
    onKeepInTheListPress,
    people = {},
    showTimestampInHeader,
    isUndecidedWhenInGameplan,
    nudgeInfo,
    isSelected,
    teeup,
    isLast,
    mode,
    isComingUp,
    notificationsArr,
    preventRedirecting,
    preventHover,

    setInviteesContent,
    styleItem,
    fromContactBook,
    handleOnInviteesDone,
    loading,
  }) => {
    const [tooltip, setTooltip] = useState(false)
    const [isTeeUpHover, setIsTeeUpHover] = useState(false)

    const isNew = isNewTitle || isNewWhen || isNewWhere || newMessages > 0
    // const ImageWrapper = isGreyOverlay ? Grayscale : View
    const ImageWrapper = View

    const history = useHistory()
    const match = useRouteMatch({
      path: `/teeUps/${teeup.id}`,
    })

    const [isUndecidedPromptShow, setUndecidedPrompt] = useState(
      isUndecidedWhenInGameplan
    )

    const [isButtonsShown, handleIgnorePress] = useState(true)

    const isSeen = notificationsArr?.includes(teeup.id)
    let when1 = ""
    const selectedWhen = teeup.gameplans[0].suggestions?.map(
      (suggestion, index) => {
        if (suggestion.when) {
          when1 = suggestion.when.value
        }
      }
    )

    const whenFormatted = moment(when1).format("llll")

    let where1 = ""
    const selectedWhere = teeup.gameplans[1].suggestions?.map(
      (suggestion, index) => {
        if (suggestion.where) {
          where1 = suggestion.where.fullDetails
            ? suggestion.where.fullDetails
            : suggestion.where.details
        }
      }
    )

    const [threeDotsTeeup, setThreeDotsTeeup] = useState(false)
    const teeupRef = useRef()

    const renderActionsForSelectedTeeup = () => {
      // user status logic is broken
      // const { isArchived, userStatus } = teeup;
      // let isSkipped = userStatus === teeupUserStatusKeys.skipped
      return (
        <View style={styles.actionButtonContainer}>
          {(mode === "ARCHIVED" || mode === "SKIPPED") && (
            <TouchableOpacity
              style={styles.actionButton}
              onPress={onMoveToActivePress}
            >
              <Image style={styles.actionButtonImage} source={images.users} />
              <Text style={styles.actionButtonText}>Move to Active</Text>
            </TouchableOpacity>
          )}
          {mode !== "ARCHIVED" && (
            <TouchableOpacity
              style={styles.actionButton}
              onPress={onArchivePressed}
            >
              <Image
                style={styles.actionButtonImage}
                source={images.archiveOutline}
              />
              <Text style={styles.actionButtonText}>Archive</Text>
            </TouchableOpacity>
          )}
          {mode !== "SKIPPED" && (
            <TouchableOpacity
              style={styles.actionButton}
              onPress={onTrashPressed}
            >
              <Image
                style={styles.actionButtonImage}
                source={images.trashcanOutline}
              />
              <Text style={styles.actionButtonText}>Move to Trash</Text>
            </TouchableOpacity>
          )}
        </View>
      )
    }

    const isEnded = status === "ended"
    const isArchivedCancelled = teeup.isArchived && teeup.status === "cancelled"

    return (
      <>
        <div
          // className={"teeups__container"}
          className={
            "teeups__container" +
            (isInitiallyInvited && !isEnded && !isArchivedCancelled
              ? " invited"
              : "")
          }
          style={{
            minHeight: isInitiallyInvited ? 132 : 130,
            borderRadius: Units.responsiveValue(8),
            marginBottom: isLast || isComingUp ? 0 : Units.responsiveValue(12),
            marginTop: isComingUp ? "5px" : "0",
            position: "relative",
          }}
          onMouseOver={() => {
            !preventHover && !isComingUp && setThreeDotsTeeup(true)
            fromContactBook && setIsTeeUpHover(true)
          }}
          onMouseLeave={() => {
            // ReactTooltip.hide(teeupRef.current)
            !tooltip && setThreeDotsTeeup(false)
            fromContactBook && setIsTeeUpHover(false)
          }}
        >
          <Link
            // className="teeups__container"
            // style={{ boxSizing: "content-box" }}
            to={!fromContactBook && `/teeUps/${teeup.id}`}
            onMouseDown={() => {
              if (window.event.which === 2) {
                onPress(teeup)
                !preventRedirecting &&
                  setTimeout(
                    window.open(`/teeUps/${teeup.id}`, "_blank").focus(),
                    0
                  )
              }
            }}
            onClick={async () => {
              !fromContactBook && onPress(teeup)
              !preventRedirecting &&
                setTimeout(() => {
                  history.push(`/teeUps/${teeup.id}`)
                }, 1000)
            }}
            activeOpacity={1}
            testID={testID}
          >
            {/* {isNew && <View style={styles.newSideBar} />} */}

            {/* <TeeupItemHeaderTitle
              showAvatar={isInitiallyInvited || isOrganizer}
              isOrganizer={isOrganizer}
              ownerAvatar={ownerAvatar}
              ownerName={ownerName}
              isNew={isNew}
              fromNowString={fromNowString}
              isGreyOverlay={isGreyOverlay}
            /> */}
            <View
              style={[
                styles.contentContainer,
                ignoreHorizontalMargin ? styles.noMargin : null,
                fromTeeupsSection ? styles.withBorders : null,
                contentStyle,
                // isInitiallyInvited && styles.contentContainerOrange,
              ]}
            >
              <View
                style={[
                  styles.leftPartContainer,
                  (isEnded || isArchivedCancelled) && styles.grayout,
                ]}
              >
                {isInitiallyInvited ? (
                  <View style={styles.leftContainer}>
                    <LeftItemsHeader
                      showAvatar={
                        !(
                          isDroppedOut ||
                          isSkippedDeclined ||
                          isArchivePromptShow ||
                          (isOrganizer && isUndecidedWhenInGameplan)
                        )
                      }
                      invitation={invitation}
                      isInvited={isInitiallyInvited}
                      ownerAvatar={ownerAvatar}
                      ownerName={ownerName}
                      isNew={isNew}
                      status={status}
                      isEnded={isEnded}
                      isDroppedOut={isDroppedOut}
                      isDeclined={isSkippedDeclined}
                      isArchivePromptShow={isArchivePromptShow}
                      isGreyOverlay={isGreyOverlay}
                      nudgeInfo={isButtonsShown && nudgeInfo}
                      isUndecidedWhenInGameplan={
                        isUndecidedPromptShow && isUndecidedWhenInGameplan
                      }
                    />
                    <View style={styles.noImageBg}></View>
                  </View>
                ) : (
                  <View style={styles.thumbnailImage}>
                    <Image style={styles.flex} source={{ uri: photo }} />
                    {isOrganizer && (
                      <View style={styles.organizerContainer}>
                        <Image
                          style={styles.organizerIcon}
                          source={images.crown}
                        />
                      </View>
                    )}
                  </View>
                )}
              </View>
              {isSeen && !isInitiallyInvited && <View style={styles.sideBar} />}
              <View
                style={[
                  styles.content,
                  (isEnded || isArchivedCancelled) && {
                    backgroundColor: "#F5F8FA",
                    border: "solid 1px #e3e5e8",
                    borderBottomRightRadius: 8,
                    borderTopRightRadius: 8,
                  },

                  isInitiallyInvited && {
                    borderTopRightRadius: 6,
                    borderBottomRightRadius: 6,
                    borderBottomLeftRadius: 8,
                    borderTopLeftRadius: 8,
                  },
                  !isInitiallyInvited && {
                    border: "solid 1px #e3e5e8",
                    borderBottomRightRadius: 8,
                    borderTopRightRadius: 8,
                  },
                ]}
              >
                {/* backgroundColor: '#f5f5f5' */}
                <View>
                  <View style={styles.header}>
                    <View style={styles.row}>
                      <TeeupStatus
                        status={status}
                        testID={`${testID}-${status}`}
                        teeupStatusStyling={teeupStatusStyling}
                      />
                      {!!userStatus && <MyStatus userStatus={userStatus} />}
                    </View>
                    {!showTimestampInHeader && (
                      <TimeFromNow
                        teeup={teeup}
                        notificationsArr={notificationsArr}
                        isNew={isNew}
                        fromNowString={fromNowString}
                      />
                    )}
                  </View>
                  <View style={[styles.row, styles.nameMargin]}>
                    <Text
                      style={[
                        styles.nameText,
                        isNewTitle ? styles.hasUpdateText : {},
                        (teeupStatusStyling === "droppedout" ||
                          teeupStatusStyling === "ended" ||
                          isArchivedCancelled) &&
                          styles.nameInactiveText,
                      ]}
                    >
                      {name.substring(0, 64)}
                    </Text>
                  </View>
                  {!isTeeUpHover ? (
                    <>
                      {!!whenFormatted && (
                        <View style={styles.row}>
                          <Text
                            numberOfLines={1}
                            ellipsizeMode="tail"
                            style={[
                              styles.mediumText,
                              isNewWhen ? styles.hasUpdateText : {},
                              {
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "200px",
                              },
                            ]}
                          >
                            {whenFormatted === "Invalid date" || !whenFormatted
                              ? ""
                              : whenFormatted}
                          </Text>
                        </View>
                      )}
                      {!!where1 && (
                        <View style={styles.row}>
                          <Text
                            numberOfLines={1}
                            ellipsizeMode="tail"
                            style={[
                              styles.mediumText,
                              isNewWhere ? styles.hasUpdateText : {},
                              {
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "200px",
                              },
                            ]}
                          >
                            {where1 ? where1 : "No Where"}
                          </Text>
                        </View>
                      )}
                    </>
                  ) : (
                    <div
                      className={cn("teeup-item", {
                        "teeup-item--loading": loading,
                      })}
                      onClick={() => onPress(teeup)}
                    >
                      {loading ? (
                        <div className="teeup-item__loader"></div>
                      ) : (
                        <img
                          src={images.ndInviteToTeeUp}
                          style={{ marginRight: 6 }}
                        />
                      )}
                      Invite to this TeeUp
                    </div>
                  )}
                </View>
                {!isTeeUpHover && !isInitiallyInvited && (
                  <View style={styles.usersLine}>
                    {renderFirstAvatars(
                      people,
                      ownerId,
                      ImageWrapper,
                      isEnded || isArchivedCancelled
                    )}
                    <Image source={arrowRight} style={styles.arrowIcon} />
                    <Text style={styles.countText}>{goingCount}</Text>
                  </View>
                )}
                {(!teeup.isArchived || (teeup.isArchived && !isEnded)) && (
                  <TeeupItemHeaderTitle
                    showAvatar={
                      !(
                        isDroppedOut ||
                        isSkippedDeclined ||
                        isArchivePromptShow ||
                        (isOrganizer && isUndecidedWhenInGameplan)
                      )
                    }
                    invitation={invitation}
                    isInvited={isInitiallyInvited}
                    ownerAvatar={ownerAvatar}
                    ownerName={ownerName}
                    isNew={isNew}
                    status={status}
                    isEnded={isEnded}
                    isDroppedOut={isDroppedOut}
                    isDeclined={isSkippedDeclined}
                    isArchivePromptShow={isArchivePromptShow}
                    isGreyOverlay={isGreyOverlay}
                    nudgeInfo={isButtonsShown && nudgeInfo}
                    isUndecidedWhenInGameplan={
                      isUndecidedPromptShow && isUndecidedWhenInGameplan
                    }
                  >
                    <ActionButtonsBar
                      isInvited={isInitiallyInvited}
                      isDroppedOut={isDroppedOut}
                      isSkippedDeclined={isSkippedDeclined}
                      isArchivePromptShow={isArchivePromptShow}
                      // isUndecidedWhenInGameplan={
                      //     isUndecidedPromptShow && isUndecidedWhenInGameplan
                      // }
                      // onIgnoreUndecidedInGameplan={setUndecidedPrompt}
                      onArchivePressed={onArchivePressed}
                      onDeclineInvitation={onDeclineInvitation}
                      onKeepInTheListPress={onKeepInTheListPress}
                      onAcceptInvitation={onAcceptInvitation}
                      isGreyOverlay={isGreyOverlay}
                      isEnded={isEnded}
                      // onViewTeeup={onPress}
                      // nudgeInfo={isButtonsShown && nudgeInfo}
                      // onIgnore={handleIgnorePress}
                    />
                  </TeeupItemHeaderTitle>
                )}
              </View>
            </View>
          </Link>
          {!fromContactBook && threeDotsTeeup && teeup && (
            <img
              src={threeDotsIcon}
              ref={teeupRef}
              data-tip={teeup.id}
              data-for="teeup-tooltip"
              className="three-dots-teeup"
              onMouseDown={(e) => {
                e.stopPropagation()
                ReactTooltip.show(teeupRef.current)
                setTooltip(true)
              }}
              onMouseUp={(e) => {
                e.stopPropagation()
                ReactTooltip.show(teeupRef.current)
                setTooltip(true)
              }}
              onClick={(e) => {
                e.stopPropagation()
                ReactTooltip.show(teeupRef.current)
                // setTooltip(true)
              }}
              alt=""
              isInvited={isInitiallyInvited}
            />
          )}
        </div>

        {tooltip &&
          ReactDOM.createPortal(
            <TeeupTooltip
              isInvited={isInitiallyInvited}
              // isDroppedOut={isDroppedOut}
              // isSkippedDeclined={isSkippedDeclined}
              // isArchivePromptShow={isArchivePromptShow}
              onArchivePressed={onArchivePressed}
              // onDeclineInvitation={onDeclineInvitation}
              // onKeepInTheListPress={onKeepInTheListPress}
              // onAcceptInvitation={onAcceptInvitation}
              // isGreyOverlay={isGreyOverlay}
              // onViewTeeup={onPress}
              mode={mode}
              onMoveToActivePress={onMoveToActivePress}
              onTrashPressed={onTrashPressed}
              onClose={() => {
                setTooltip(false)
                ReactTooltip.hide(teeupRef.current)
                setThreeDotsTeeup(false)
              }}
              setThreeDotsTeeup={setThreeDotsTeeup}
              setInviteesContent={setInviteesContent}
              teeup={teeup}
            />,

            document.getElementById("modal-root")
          )}
      </>
    )
  }
)

TeeupItem.propTypes = {
  // In teeup object
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  teeupStatusStyling: PropTypes.string.isRequired,
  isGreyOverlay: PropTypes.bool,
  // userStatus: PropTypes.string,
  isOrganizer: PropTypes.bool,
  // Calculated from teeupGameplans
  when: PropTypes.string,
  where: PropTypes.string,
  // Calculated from teeupPeople
  goingCount: PropTypes.number.isRequired,
  fromNowString: PropTypes.string.isRequired,
  isArchivePromptShow: PropTypes.bool.isRequired,
  onArchivePressed: PropTypes.func,
  invitation: PropTypes.object,
  ownerId: PropTypes.number,
  ownerAvatar: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string.isRequired,
  ]),
  ownerName: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string.isRequired,
  ]),
  people: PropTypes.shape({}).isRequired,
  isNewTitle: PropTypes.bool,
  isNewWhen: PropTypes.bool,
  isNewWhere: PropTypes.bool,
  newMessages: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  nudgeInfo: PropTypes.object,

  onPress: PropTypes.func.isRequired,
  photo: PropTypes.string,

  ignoreHorizontalMargin: PropTypes.bool,
  fromTeeupsSection: PropTypes.bool,
  testID: PropTypes.string,

  style: PropTypes.object,
  contentStyle: PropTypes.object,
  onDeclineInvitation: PropTypes.func,
  onAcceptInvitation: PropTypes.func,
  onKeepInTheListPress: PropTypes.func,
  isInitiallyInvited: PropTypes.bool,
  isDroppedOut: PropTypes.bool,
  isSkippedDeclined: PropTypes.bool,
  showTimestampInHeader: PropTypes.bool,
  isUndecidedWhenInGameplan: PropTypes.bool,
}

export default connect(propsMapper)(TeeupItem)
