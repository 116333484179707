import React from "react"
import PropTypes from "prop-types"

// components
import { View } from "react-native"
import TimezonesViewTime from "./timezonesViewTime"
import TimezonesViewDate from "./timezonesViewDate"

const TimezonesView = ({ type, isTemporalCustom, ...props }) => {
  if (type === "date") {
    return <TimezonesViewDate {...props} isTemporalCustom={isTemporalCustom} />
  }
  if (type === "time" || type === "timeDuration") {
    return <TimezonesViewTime {...props} isTemporalCustom={isTemporalCustom} />
  }
  return <View />
}

TimezonesView.propTypes = {
  type: PropTypes.string,
}

export default TimezonesView
