import React from "react"
import { View, Text, Image, StyleSheet } from "react-native"
import PropTypes from "prop-types"

// import { userStatusImage } from '@utils/teeupUtils'
import { teeupUserStatus, teeupUserStatusKeys } from "../../../../config/enums"
import { AppColors, AppFonts, Units } from "@theme"
import { images } from "@utils/imageUtils"

const userStatusImage = (userStatus, hasBg = true) => {
  let image = images.goingNoBg
  switch (userStatus) {
    case teeupUserStatusKeys.going:
      image = hasBg ? images.going : images.goingNoBg
      break
    case teeupUserStatusKeys.notgoing:
      image = hasBg ? images.notgoing : images.notgoingNoBg
      break
    case teeupUserStatusKeys.mightgo:
      image = hasBg ? images.mightgo : images.mightgoNoBg
      break
    case teeupUserStatusKeys.invited:
      image = hasBg ? images.invited : images.invitedNoBg
      break
    case teeupUserStatusKeys.onmyway:
      image = hasBg ? images.onmyway : images.onmywayNoBg
      break
    case teeupUserStatusKeys.arrived:
      image = hasBg ? images.arrived : images.arrivedNoBg
      break
    case teeupUserStatusKeys.attended:
      image = hasBg ? images.attended : null
      break
    case teeupUserStatusKeys.droppedout:
      image = hasBg ? images.droppedout : images.droppedoutNoBg
      break
    case teeupUserStatusKeys.runningLate:
      image = hasBg ? images.runningLate : images.runningLateNoBg
      break
    case teeupUserStatusKeys.backedOut:
      image = hasBg ? images.backedout : images.backedoutNoBg
      break
    default:
      break
  }

  return image
}
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: Units.responsiveValue(5),
  },
  text: {
    ...AppFonts.smallestExtrabold,
    color: AppColors.brand.warmGrey2,
    textTransform: "uppercase",
  },
  iconContainer: {
    width: Units.responsiveValue(10),
    height: Units.responsiveValue(10),
    marginRight: Units.responsiveValue(3),
    borderRadius: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.warmGrey2,
  },
  icon: {
    width: Units.responsiveValue(10),
    height: Units.responsiveValue(10),
  },
})

const MyStatus = ({ userStatus }) => {
  const icon =
    userStatus === "invited" ? null : userStatusImage(userStatus, false)

  return (
    <View style={styles.container} testID="my-status">
      {!!icon && (
        <View style={styles.iconContainer}>
          <Image style={styles.icon} source={icon} />
        </View>
      )}
      <Text style={styles.text}>{teeupUserStatus[userStatus]}</Text>
    </View>
  )
}

MyStatus.propTypes = {
  // userStatus: PropTypes.string.isRequired,
}

export default MyStatus
