import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import teeupActions from "@actions/teeupActions"
import { selectTeeupsPeople } from "@selectors/teeups"
import { selectUserInfo, selectUserId } from "@selectors/user"
import { teeupUserStatusKeys } from "@configs/enums"

import BringingView from "./bringingView"

class BringingContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      count: 0,
    }
  }

  componentDidMount() {
    const { people, userInfo } = this.props

    let additionalCount = 0
    let userParticipant = people[userInfo.id]
    if (userParticipant) {
      additionalCount = userParticipant.additionalCount || 0
    }

    this.setState({
      count: additionalCount,
    })
  }

  handleClose = () => {
    const { onClose } = this.props
    onClose()
  }

  handleClickMinus = () => {
    const { count } = this.state

    this.setState({
      count: Math.max(0, count - 1),
    })
  }

  handleClickPlus = () => {
    const { count } = this.state

    this.setState({
      count: Math.min(9, count + 1),
    })
  }

  handleSubmit = () => {
    const { onSubmit, status } = this.props
    const { count } = this.state
    onSubmit(status, count)
  }

  getUserStatus = () => {
    const { people, userInfo } = this.props

    let userStatus = teeupUserStatusKeys.invited
    let userParticipant = people[userInfo.id]

    if (userParticipant) {
      userStatus = userParticipant.status
    }

    return userStatus
  }

  render() {
    const { count } = this.state
    const { teeup, userInfo, userId, status } = this.props

    let userStatus = this.getUserStatus()
    return (
      <BringingView
        userId={userId}
        userInfo={userInfo}
        status={status}
        teeup={teeup}
        userStatus={userStatus}
        additionalCount={count}
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
        onClickMinus={this.handleClickMinus}
        onClickPlus={this.handleClickPlus}
      />
    )
  }
}

BringingContainer.propTypes = {
  // componentId: PropTypes.string,
  teeup: PropTypes.object,
  // people: PropTypes.array,
  // userInfo: PropTypes.object,
  // userId: PropTypes.number.isRequired,
  // onClose: PropTypes.func,
  // userStatusUpdated: PropTypes.func.isRequired,
  status: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
  const teeupId = ownProps.teeup.id
  const people = selectTeeupsPeople(state)[teeupId]
  const userInfo = selectUserInfo(state)
  const userId = selectUserId(state)

  return {
    people,
    userInfo,
    userId,
  }
}

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//         {
//             ...teeupActions,
//         },
//         dispatch
//     )

export default connect(mapStateToProps, null)(BringingContainer)
