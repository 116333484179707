import { StyleSheet } from "react-native"
import { Units, AppColors, AppFonts } from "@theme"

export default StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  selfFlexEnd: { alignSelf: "flex-end" },
  chatMessageWrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  chatMessageContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingHorizontal: Units.rem(1),
    // transform: [{ scaleY: -1 }],
    alignItems: "center",
    marginTop: Units.responsiveValue(2),
    // paddingTop: isPinned ? Units.responsiveValue(8) : null,
    // marginBottom: isPinned ? Units.responsiveValue(4) : null,
  },
  myChatMessageContainer: {
    justifyContent: "flex-end",
  },
  chatMessage: {
    alignItems: "flex-start",
    justifyContent: "center",
    flex: 1,
    marginLeft: Units.responsiveValue(0),
    position: "relative",
    // marginLeft: isMyMessage
    //     ? Units.responsiveValue(23)
    //     : Units.responsiveValue(0),
  },
  myChatMessage: {
    alignItems: "flex-end",
    // marginLeft: Units.responsiveValue(23)
  },
  statusMessage: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 0,
  },
  thumbtackIcon: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
    tintColor: AppColors.brand.warmGrey2,
  },
  pinWrapper: {
    flexDirection: "row",
  },
  pinIcon: {
    transform: [{ rotateY: "0deg" }],
    tintColor: AppColors.brand.lightDarkBlue,
    height: Units.responsiveValue(10),
    width: Units.responsiveValue(10),
    marginRight: Units.responsiveValue(2),
  },
  messagePadding: {
    // paddingTop: '10px'
  },
  indentWrapper: {
    marginTop: Units.responsiveValue(12),
  },
  rowWithPinIcon: {
    flexGrow: 1,
    flexDirection: "row-reverse",
    alignSelf: "flex-start",
    alignItems: "center",
    paddingVertical: Units.responsiveValue(4),
  },
  pinnedUser: {
    ...AppFonts.smallDemibold,
    fontSize: Units.responsiveValue(11),
    color: AppColors.brand.lightDarkBlue,
    flexWrap: "wrap",
  },
  senderSpace: {
    marginTop: Units.responsiveValue(12),
  },
})

export const thumbtackWrapper = (isMyMessage, isReply, isSuggestion) => ({
  marginTop: Units.responsiveValue(isReply || isSuggestion ? 0 : 8),
  paddingLeft: Units.responsiveValue(8),
  marginLeft: Units.responsiveValue(5),
  alignItems: "flex-end",
  alignSelf: "flex-start",
  transform: [{ rotateY: isMyMessage ? "180deg" : "0deg" }],
})

export const pinnedStyle = (
  isPinned,
  isDisabled,
  onPinboardScreen,
  showOnMentionsScreen,
  newDesign
) => ({
  backgroundColor: isPinned
    ? isDisabled
      ? AppColors.brand.lightGrey
      : newDesign
      ? "#f0f2f5"
      : AppColors.brand.pinnedMessage
    : "transparent",
  transform: (onPinboardScreen || showOnMentionsScreen) && [{ scaleY: -1 }],
  paddingTop: newDesign && isPinned ? "5px" : 0,
})
