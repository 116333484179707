import React, { PureComponent } from "react"
import { View, Text, TouchableOpacity, Image } from "react-native"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import "./index.scss"
import I18n from "../../../i18n"
import { AppColors, Units } from "@theme"

import { images } from "@utils/imageUtils"
import { getContactInitials, getContactName } from "@utils/dataUtils"
import {
  isCooeUser as checkIsCooeUser,
  getContactId,
  requestStatusId,
} from "@utils/contactUtils"

import Tag from "./Tag"
import styles from "./styles"
import { selectConnectionRequests } from "@selectors/requests"
import ProfileContainer from "../../profile/Home/profileContainer"
import closeIcon from "../../../assets/images/close.svg"
import CheckBox from "@ui/checkbox"

// import contactActions from '@actions/contactActions'

// import { inviteUserToGroup, removeConnection } from '@apis/backend'

// import {
//     showActionsheet,
//     openProfile,
//     openUnregisteredProfile,
//     showInviteContactsToTeeup,
//     showUserTags,
//     showOverlayModal,
//     showModal,
//     screenNames,
//     dismissModal,
// } from '@nav'

// import { selectConnectionRequests } from '@selectors/requests'
import { selectUserId } from "../../../selectors/user"
import { userTypes as enumUserTypes } from "../../../config/enums"

import ConnectedImg from "../assets/connected.png"
import UserProfileContainer from "../../profile/UserProfile/index"

export class MyContactItem extends PureComponent {
  handlePressContactItem = () => {
    const { item, setContent, close, myContacts } = this.props

    if (checkIsCooeUser(item))
      setContent(
        <>
          <TouchableOpacity style={styles.iconWrapper} onPress={close}>
            <Image source={images.cross} style={styles.closeIcon} />
          </TouchableOpacity>
          <UserProfileContainer
            userId={getContactId(item)}
            myContacts={myContacts}
          />
        </>
      )
  }

  buttonCallbackInvite = () => {
    const { item: user } = this.props
    const invitees = [user]
    // showInviteContactsToTeeup({ invitees })
  }

  buttonCallbackTag = () => {
    const { item: user } = this.props
    const usersToTag = [user]
    // showUserTags({
    //     props: {
    //         title: user.firstName
    //             ? `${user.firstName}'s Tags`
    //             : 'Tag Users',
    //         usersToTag,
    //         selectedTags: user.tags,
    //         onTagsChange: this.handleTagsChange,
    //         onTagUsersEnd: this.handleRefetchContacts,
    //     },
    // })
  }

  buttonCallbackRemoveConnection = () => {
    // showOverlayModal({
    //     title: 'Remove Connection',
    //     description:
    //         'Are you sure you want to remove this user as a Connected contact?',
    //     rightButton: {
    //         text: 'Remove',
    //         onPress: this.handleRemoveConnection,
    //     },
    // })
  }

  handlePressMore = () => {
    const {
      item: user,
      requests,
      isMoreMenuOpened,
      openMoreMenu,
      closeMoreMenu,
    } = this.props

    const commonButtons = [
      {
        title: "Invite to TeeUp",
        // image: ConnectImg,
        callback: this.buttonCallbackInvite,
      },
      {
        title: "Invite to Group",
        // image: GroupImg,
        callback: this.showInviteToGroupModal,
        testID: "modalInviteGroupButton",
      },
      {
        title: "Tag User",
        // image: TagImg,
        callback: this.buttonCallbackTag,
      },
    ]

    const requestConnectButton = (isRequestSent) => {
      if (!isRequestSent) {
        return [
          {
            title: "Request to Connect",
            // image: ConnectImg,
            callback: this.handleRequestToConnect,
          },
        ]
      }
      return []
    }

    const removeConnectionButton = [
      {
        title: "Remove Connection",
        // image: RemoveConnectionImg,
        callback: this.buttonCallbackRemoveConnection,
      },
    ]

    const isConnected = user.isConnected
    const isDraftUser = user.userType === enumUserTypes.draft

    let isRequestPending
    let isRequestSent = false
    if (isConnected) {
      isRequestPending = false
    } else {
      requests.forEach((request) => {
        if (
          request.sentTo === getContactId(user) &&
          request.statusId === requestStatusId.pending
        ) {
          isRequestPending = true
          isRequestSent = true
        }

        if (
          request.createdBy === getContactId(user) &&
          request.statusId === requestStatusId.pending
        ) {
          isRequestSent = true
        }
      })
    }
    let buttonsWithConnection
    if (isConnected) {
      buttonsWithConnection = commonButtons.concat(removeConnectionButton)
    } else if (isRequestPending || isDraftUser) {
      buttonsWithConnection = commonButtons
    } else {
      buttonsWithConnection =
        requestConnectButton(isRequestSent).concat(commonButtons)
    }

    if (!isMoreMenuOpened) {
      // openMoreMenu(
      //     showActionsheet(
      //         checkIsCooeUser(user)
      //             ? buttonsWithConnection
      //             : commonButtons,
      //         undefined,
      //         closeMoreMenu
      //     )
      // )
    }
  }

  showInviteToGroupModal = () => {
    const { item: user } = this.props
    const { id } = user
    // showModal(
    //     screenNames.userGroups,
    //     {
    //         topBar: {
    //             title: {
    //                 text: I18n.t('userGroups'),
    //             },
    //             leftButtons: [
    //                 {
    //                     id: 'back',
    //                     icon: images.back,
    //                     color: AppColors.brand.pink,
    //                 },
    //             ],
    //         },
    //     },
    //     // { dismissModal, invitedUserId: id, inviteUserToGroup },
    //     true
    // )
  }

  handleRequestToConnect = () => {
    const { item: userToConnect } = this.props
    // showOverlayModal({
    //     title: 'Request to Connect',
    //     textInput: true,
    //     textInputPlaceholder: 'Introduction message',
    //     description:
    //         'Add a short message to describe why you want to connect (optional)',
    //     rightButton: {
    //         text: 'Request',
    //         onPress: ({ text }) =>
    //             this.props.connectUsers(getContactId(userToConnect), text),
    //     },
    // })
  }

  onRefreshContacts = () => {
    // contactActions.getContacts()
  }

  handleRemoveConnection = async () => {
    const { item: userToRemove } = this.props
    // await removeConnection(getContactId(userToRemove))
    this.onRefreshContacts()
  }

  handlePressMessage = () => {
    const { item, sendDirectMessage } = this.props
    sendDirectMessage(item)
  }

  renderTag = (tag, index, data) => (
    <Tag
      key={tag.id}
      data={tag}
      isSelected={false}
      isLast={index === data.length - 1}
    />
  )

  render() {
    const {
      item,
      selectedTags,
      isInvitees,
      onRemoveInvitee,
      invitees,
      dontShowCircle,
      handleOnInviteesDone,
      onDone,
      noHover,
      nudgePeople,
    } = this.props

    const { avatar, firstName, lastName, isConnected, tags } = item
    const initial = getContactInitials(item)
    const name = getContactName(item)
    const maxLength = 20
    let wordLength = 0
    const hiddenTags =
      tags && tags.length
        ? tags.filter((tag) => {
            wordLength += tag.value.length
            if (wordLength >= maxLength) {
              return true
            }
            return false
          })
        : []
    const tagsToShow = (tag, index) => index < tags.length - hiddenTags.length

    const isCooeUser = checkIsCooeUser(item)

    const showMessageButton = isConnected

    const invited = Boolean(
      invitees.filter(
        (invitee) =>
          (invitee.userType === item.userType ||
            invitee.userType === item.usertype) &&
          invitee.id === item.id
      ).length
    )

    return (
      <div
        className="mycontact__row"
        style={nudgePeople && { padding: "8px 16px" }}
        onClick={this.handlePressContactItem}
      >
        <View>
          {avatar ? (
            <Image source={{ uri: avatar }} style={styles.avatar} />
          ) : (
            <View style={[styles.avatar, styles.initial]}>
              <Text style={styles.initialText}>{initial}</Text>
            </View>
          )}
          {isConnected && isCooeUser && (
            <Image
              source={ConnectedImg}
              style={styles.connected}
              testID="my-connected-icon"
            />
          )}
        </View>
        <View style={styles.body}>
          <View
            style={[
              styles.userInfo,
              styles.flexRow,
              nudgePeople && { width: "max-content" },
            ]}
          >
            <Text style={styles.name}>{name}</Text>
            {isCooeUser && (
              <Image source={images.teeupSmall} style={styles.cooEIcon} />
            )}
          </View>
          {selectedTags && selectedTags.length ? (
            <View style={[styles.tagList, styles.selectedTagList]}>
              {selectedTags.map(this.renderTag)}
            </View>
          ) : (
            <View style={styles.tagList}>
              {tags && tags.filter(tagsToShow).map(this.renderTag)}
              {hiddenTags.length ? (
                <Text style={styles.tagLabel}>{`, +${hiddenTags.length}`}</Text>
              ) : null}
            </View>
          )}
        </View>
        {/* {(!noHover && !fromPeopleNudge) &&  ( */}
        {!noHover && (
          <div className="on--hover__bar" onClick={(e) => e.stopPropagation()}>
            <div className="on--hover__action">
              <img className="action__image" src={images.inviteToTeeupPink} />
              <button
                className="action__button"
                onClick={() => handleOnInviteesDone([item], onDone)}
              >
                Invite to TeeUp
              </button>
            </div>
            {/* <div className="on--hover__action">
                            <img className="action__image" src={images.sendMessagePink} />
                            <button className="action__button" onClick={() => console.log('Message')}>Message</button>
                        </div> */}
            <div className="on--hover__action">
              <img className="action__image" src={images.tagIconPink} />
              <button
                className="action__button"
                onClick={() => handleOnInviteesDone([item], onDone, true)}
              >
                Tag User
              </button>
            </div>
          </div>
        )}

        {showMessageButton && (
          <TouchableOpacity
            hitSlop={Units.hitSlop()}
            onPress={this.handlePressMessage}
          >
            {/* <Image source={images.sendMessagePink} style={styles.message} /> */}
          </TouchableOpacity>
        )}
        {isInvitees ? (
          <TouchableOpacity
            style={styles.moreButton}
            onPress={onRemoveInvitee}
            hitSlop={Units.hitSlop()}
            testID={"myContactMoreButton" + firstName + " " + lastName}
          >
            <Image
              style={{ width: 42, height: 42 }}
              source={images.crossCircle}
            />
          </TouchableOpacity>
        ) : (
          !dontShowCircle && (
            <TouchableOpacity
              style={styles.moreButton}
              onPress={onRemoveInvitee}
              hitSlop={Units.hitSlop()}
              testID={"myContactMoreButton" + firstName + " " + lastName}
            >
              {/* <img src={images.more} style={{width: 16, height: 16}} /> */}
              {/* <View style={styles.checkbox}></View> */}
              <CheckBox isActive={invited} />
            </TouchableOpacity>
          )
        )}
      </div>
    )
  }
}
// MyContactItem.propTypes = {
//     item: PropTypes.object.isRequired,
//     selectedTags: PropTypes.array,
//     selectedContactIds: PropTypes.object,
//     sendDirectMessage: PropTypes.func,
//     requests: PropTypes.array,
//     userId: PropTypes.number.isRequired,
//     connectUsers: PropTypes.func.isRequired,
//     isMoreMenuOpened: PropTypes.bool.isRequired,
//     openMoreMenu: PropTypes.func.isRequired,
//     closeMoreMenu: PropTypes.func.isRequired,
// }

const mapStateToProps = (state) => {
  const requests = selectConnectionRequests(state)
  const userId = selectUserId(state)

  return {
    userId,
    requests,
  }
}

export default connect(mapStateToProps)(MyContactItem)
