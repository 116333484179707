import React, { useState } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { MdRemoveCircle } from "react-icons/md"
import { IconContext } from "react-icons"
import { bindActionCreators } from "redux"
import { useHistory } from "react-router-dom"
import Modal from "react-modal"

import "./index.scss"
import logo from "../../assets/images/teeUpsPink.svg"
import { userContactTypes } from "@utils/contactUtils"
import {
  deleteMechanism,
  deleteMechanismFromState,
} from "@actions/contactMechanismsActions"
import { images } from "@utils/imageUtils"

const modalStyles = {
  content: {
    backgroundColor: "#ffffff",
    width: 308,
    height: 208,
    boxSizing: "border-box",
    borderRadius: "14px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    padding: "35px 35px 30px 35px",
  },
  overlay: {
    backgroundColor: "#00000080",
  },
}

const List = ({
  contactMechanisms,
  showAddContact,
  deleteMechanismFromState,
}) => {
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false)
  const [activeMechanism, setActiveMechanism] = useState(null)

  const history = useHistory()

  const openModal = (mechanism) => {
    if (
      mechanism.typeid === userContactTypes.PRIMARY_EMAIL ||
      mechanism.typeid === userContactTypes.PRIMARY_PHONE
    ) {
      mechanism.isPrimary = true
    }

    setIsRemoveModalOpened(true)
    setActiveMechanism(mechanism)
  }

  const closeModal = () => {
    setIsRemoveModalOpened(false)
    setActiveMechanism(null)
  }

  const removeContacMechanism = () => {
    deleteMechanism(activeMechanism.id)()
      .then((response) => {
        deleteMechanismFromState(activeMechanism.id)
        closeModal()
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const renderContactMechanismList = () => {
    return contactMechanisms.map((mechanism) => {
      mechanism.type =
        mechanism.typeid === userContactTypes.EMAIL ||
        mechanism.typeid === userContactTypes.PRIMARY_EMAIL
          ? "email"
          : "phone"
      const isPrimary =
        mechanism.typeid !== userContactTypes.PRIMARY_PHONE &&
        mechanism.typeid !== userContactTypes.PRIMARY_EMAIL
      if (isPrimary)
        return (
          <div
            key={mechanism.id}
            className="contact__item"
            style={{ paddingLeft: isPrimary ? 13 : 20 }}
          >
            {isPrimary && (
              <IconContext.Provider
                value={{
                  style: {
                    color: "red",
                    width: 30,
                    height: 30,
                    marginRight: 10,
                    cursor: "pointer",
                  },
                }}
              >
                <MdRemoveCircle onClick={() => openModal(mechanism)} />
              </IconContext.Provider>
            )}
            <div style={{ width: "100%" }}>
              <span className="contact__type">{mechanism.type}</span>
              <div className="contact__edit--wrapper">
                <span className="contact__value">{mechanism.value}</span>
              </div>
            </div>
          </div>
        )
    })
  }

  const renderRemoveModal = () => {
    if (!activeMechanism) {
      return null
    }

    return (
      <Modal
        isOpen={isRemoveModalOpened}
        onRequestClose={closeModal}
        style={modalStyles}
        ariaHideApp={false}
      >
        <span className="delete__title">
          Delete {activeMechanism.isPrimary ? "Primary " : ""}Contact?
        </span>
        <p className="delete__text">
          {
            // activeMechanism.isPrimary ?
            activeMechanism.id === contactMechanisms[0].id
              ? `Removing your primary ${activeMechanism.type} will not change your login credentials, but only keep others from sending invites to that contact method.`
              : `Are you sure you want to delete ${activeMechanism.value} as a contact method?`
          }
        </p>
        <div className="delete__buttons">
          <span onClick={closeModal}>Cancel</span>
          <span onClick={removeContacMechanism}>Delete</span>
        </div>
      </Modal>
    )
  }

  return (
    <div className="forgot__page">
      <div className="forgot__content">
        {renderRemoveModal()}
        <img className="forgot__icon" src={images.cooeModal} />
        <span className="forgot__title">Contact Methods</span>
        <p className="forgot__subtitle" style={{ marginBottom: 10 }}>
          Add the methods people can find and invite you to make plans.
        </p>
        <span className="contact__notation">
          You must have at least one contact method
        </span>
        {renderContactMechanismList()}
        <span className="contact__add" onClick={showAddContact}>
          + Add Contact Method
        </span>
        <div className="forgot__button" onClick={() => history.push("/")}>
          Start using Coo-e
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteMechanismFromState,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(List)
