/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"
import propTypes from "prop-types"
import {
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  RefreshControl,
  Image,
} from "react-native"
// import FastImage from 'react-native-fast-image'
import Tag from "../components/Tag"
import Interest from "../components/Interest"
import styles from "./styles"
import SocialButtons from "./SocialButtons"
import { AddUserTag, renderTag, formatTagText } from "./heplers"

const ProfileView = ({
  user,
  nameLine1,
  nameLine2,
  interests,
  attributes,
  userTags = [],
  isMyProfile,
  isLoading,
  interestsExpanded,
  onAddTag,
  onInterestsToggle,
  onRefresh,
  onConnect,
  onInvite,
  requestLoading,
  request,
  connected,
  isCooeUser,
}) => (
  <View
    contentContainerStyle={styles.contentContainer}
    refreshControl={
      <RefreshControl refreshing={isLoading} onRefresh={onRefresh} />
    }
  >
    <View style={styles.header}>
      <Image style={styles.avatar} source={user.avatar || ""} />
      <Text style={styles.name}>{nameLine1}</Text>
      {!!nameLine2 && <Text style={styles.nickname}>{nameLine2}</Text>}
      {!!user.primarylocation && (
        <Text style={styles.location}>
          {user.primarylocation.toUpperCase()}
        </Text>
      )}

      <SocialButtons
        onConnect={onConnect}
        visible={!isMyProfile}
        onInvite={onInvite}
        requestLoading={requestLoading}
        request={request}
        isCooeUser={isCooeUser}
        isConnected={connected}
      />
    </View>

    {!isMyProfile && (
      <View style={styles.section}>
        <Text style={styles.tagsHeading}>
          User Tags{" "}
          <Text style={styles.headingNote}>(only visible to you)</Text>
        </Text>

        <View style={styles.userTagsContent}>
          {userTags.map(renderTag)}
          <AddUserTag onPress={onAddTag} />
        </View>
      </View>
    )}

    {/* {!!user.about && <Text style={styles.about}>{user.about}</Text>} */}

    {user.showAttributes && !!attributes.length && (
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Attributes</Text>
        <View style={styles.list}>
          {attributes.map(
            (attr) =>
              attr.visible &&
              !!attr.name && (
                <Tag
                  key={`${attr.value}-${attr.id}`}
                  text={formatTagText(attr)}
                />
              )
          )}
        </View>
      </View>
    )}

    {user.showInterests && !!interests.length && (
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Interests</Text>
        <View style={styles.list}>
          {interests
            .filter((item, index) => interestsExpanded || index < 8)
            .map((interest) => (
              <Interest key={interest.value} text={interest.value} />
            ))}
        </View>
        {interests.length >= 8 && (
          <TouchableOpacity onPress={onInterestsToggle}>
            <Text style={styles.interestsToggle}>
              {interestsExpanded ? "View Less" : "View All"}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    )}
  </View>
)

ProfileView.propTypes = {
  user: propTypes.shape({
    avatar: propTypes.string,
    primarylocation: propTypes.string,
    about: propTypes.string,
    showAttributes: propTypes.bool,
    showInterests: propTypes.bool,
  }).isRequired,
  interestsExpanded: propTypes.bool.isRequired,
  isMyProfile: propTypes.bool.isRequired,
  onAddTag: propTypes.func.isRequired,
  onInterestsToggle: propTypes.func.isRequired,
  interests: propTypes.arrayOf(propTypes.object).isRequired,
  attributes: propTypes.arrayOf(propTypes.object).isRequired,
  userTags: propTypes.array,
  isLoading: propTypes.bool.isRequired,
  onRefresh: propTypes.func.isRequired,
  onConnect: propTypes.func.isRequired,
  onInvite: propTypes.func.isRequired,
  requestLoading: propTypes.bool.isRequired,
  request: propTypes.object,
  isCooeUser: propTypes.bool.isRequired,
  nameLine1: propTypes.string.isRequired,
  nameLine2: propTypes.string,
  connected: propTypes.bool,
}

export default ProfileView
