import { createSelector } from "reselect"

export const selectCommon = (state) => state.common

export const selectShowSideBar = createSelector(
  selectCommon,
  (common) => common.showSideBar
)

export const selectReplyMessage = createSelector(
  selectCommon,
  (common) => common.replyMessage
)

export const selectTitle = createSelector(
  selectCommon,
  (common) => common.title
)

export const selectIsNudgePeopleOpen = createSelector(
  selectCommon,
  (common) => common.isNudgePeopleOpen
)

export const selectVersion = createSelector(
  selectCommon,
  (common) => common.version
)

export const selectTeeupSearch = createSelector(
  selectCommon,
  (common) => common.teeupFilter
)

export const selectPeopleSideBar = createSelector(
  selectCommon,
  (common) => common.peopleSideBar
)

export const selectSideBarContent = createSelector(
  selectCommon,
  (common) => common.sideBarContent
)

export const selectMentionedMessages = createSelector(
  selectCommon,
  (common) => common.mentionedMessages
)

export const selectActiveTab = createSelector(
  selectCommon,
  (common) => common.activeTab
)

export const selectNotificationTeeups = createSelector(
  selectCommon,
  (common) => common.notificationTeeups
)

export const selectHelpPopup = createSelector(
  selectCommon,
  (common) => common.helpPopup
)

export const selectActiveInviteesTab = createSelector(
  selectCommon,
  (common) => common.activeInviteesTab
)

export const selectModalPeopleNudge = createSelector(
  selectCommon,
  (common) => common.modalPeopleNudge
)

export const selectSuggestionToNudgeId = createSelector(
  selectCommon,
  (common) => common.suggestionToNudgeId
)

export const selectTypeToNudge = createSelector(
  selectCommon,
  (common) => common.typeToNudge
)

export const selectModalSuccess = createSelector(
  selectCommon,
  (common) => common.modalSuccess
)

export const selectSelectedCalendarDay = createSelector(
  selectCommon,
  (common) => common.selectedCalendarDay
)
