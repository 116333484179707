import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ReactDOM from "react-dom"

import "./index.scss"
import ConversationBottom from "../ConversationBottom"
import DiscussionCard from "../Chat/discussionCard/discussionCardContainer"
import { sendMessage } from "../../../middlewares/backendMiddleware"
import MessageTooltip from "../Chat/MessageTooltip/index"
import InformationTooltip from "@ui/informationTooltip"
import {
  selectReplyMessage,
  selectActiveTab,
  selectModalPeopleNudge,
} from "@selectors/common"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectTeeupPeopleByTeeupId } from "@selectors/teeups"
import { selectUserInfo, selectUserId } from "@selectors/user"
import { getMentionedUsersIds } from "@utils/teeupUtils"
import {
  setReplyMessage,
  setPeopleSideBar,
  setActiveTab,
} from "@actions/commonActions"
import { messageTypeIds } from "@config/mappings"
import ReplyOverview from "./ReplyOverview"
import SelectPeopleToNudge from "../Chat/selectPeopleToNudge/index"
import { differenceBy } from "lodash"
import classNames from "classnames"

const OverviewConversation = ({
  replyMessage,
  setReplyMessage,
  userInfo,
  people,
  activeTab,
  setPeopleSideBar,
  getUserId,
  modalPeopleNudge,
  setActiveTab,
  handleOpenSuggestion,
  fromSuggestionContainer,

  isWhereSuggestion,

  newDesign,
}) => {
  const [invitees, setInvitees] = useState([])
  const inputRef = useRef()

  useEffect(() => {
    setActiveTab(0)
  }, [])

  const handleToggleContact =
    ({ data }) =>
    () => {
      setInvitees((invitees) => {
        const diffInvitees = differenceBy(invitees, [data], "id")
        const isInvited = diffInvitees.length !== invitees.length
        return isInvited ? diffInvitees : invitees.concat([{ ...data }])
      })
    }

  const createMessage = (text) => {
    const message = text.replace(/  +/g, " ").trim()
    if (message.length === 0) return

    const mentionedUserIds = getMentionedUsersIds(message, people, userInfo?.id)
    if (replyMessage) {
      sendMessage(message, messageTypeIds.message, replyMessage, [])
      setReplyMessage(null)
    } else {
      sendMessage(message, messageTypeIds.message, null, mentionedUserIds)
    }
  }

  return (
    <>
      {modalPeopleNudge &&
        ReactDOM.createPortal(
          <SelectPeopleToNudge
            invitees={invitees}
            setInvitees={setInvitees}
            people={Object.values(people).filter(
              (person) => person.id !== getUserId
            )}
            handleToggleContact={handleToggleContact}
          />,
          document.getElementById("modal-root")
        )}
      <div
        className={classNames("conversation", { "nd-conversation": newDesign })}
        style={
          fromSuggestionContainer && {
            minWidth:
              isWhereSuggestion && document.documentElement.clientWidth <= 1450
                ? "350px"
                : isWhereSuggestion &&
                  document.documentElement.clientWidth <= 1505
                ? "420px"
                : "470px",
            maxWidth: "500px",
          }
        }
      >
        <DiscussionCard index={activeTab} newDesign={newDesign} />
        <InformationTooltip />
        <MessageTooltip inputRef={inputRef} />
        {replyMessage && <ReplyOverview />}
        {activeTab === 0 && (
          <ConversationBottom
            createMessage={createMessage}
            setPeopleSideBar={setPeopleSideBar}
            inputRef={inputRef}
            handleOpenSuggestion={handleOpenSuggestion}
            newDesign={newDesign}
          />
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    replyMessage: selectReplyMessage(state),
    userInfo: selectUserInfo(state),
    people: selectTeeupPeopleByTeeupId(selectActiveTeeupId(state))(state),
    activeTab: selectActiveTab(state),
    getUserId: selectUserId(state),
    modalPeopleNudge: selectModalPeopleNudge(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  setReplyMessage: bindActionCreators(setReplyMessage, dispatch),
  setPeopleSideBar: bindActionCreators(setPeopleSideBar, dispatch),
  setActiveTab: bindActionCreators(setActiveTab, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewConversation)
