import React from "react"
import PropTypes from "prop-types"

import { TouchableOpacity, View, Text, Image, Animated } from "react-native"
import Toast from "@ui/toast"
import strings from "@i18n"

import styles from "./styles"

class SettingsClickableRow extends React.Component {
  defaultOnPress = () => {
    Toast.show(strings.notImplemented, {
      bottomOffset: 8,
    })
  }

  onPressFn = () => {
    const { onPress } = this.props
    if (onPress) {
      onPress()
    } else {
      this.defaultOnPress()
    }
  }

  renderText = (textArray, type, additionalStyles = {}) => {
    const { timeZoneModal, fromCalendarSettings, stylesTab } = this.props
    const isActive = !!stylesTab
    return textArray.map((text, index) => {
      if (typeof text === "string") {
        return (
          <Text
            key={`${type}-${index}`}
            style={[
              styles["text" + type],
              additionalStyles,
              timeZoneModal && styles.detector,
              fromCalendarSettings && styles.calendarText,
              { color: isActive ? "black" : "#595f82" },
            ]}
          >
            {text}
          </Text>
        )
      } else if (typeof text === "object") {
        return (
          <View style={styles.row} key={`${type}-${index}`}>
            <Text style={[styles["text" + type], additionalStyles]}>
              {text.text}
            </Text>
            <Image style={styles.textIcon} source={text.icon} />
          </View>
        )
      } else return null
    })
  }

  renderIcon = () => {
    const { iconImage, isAnimated, iconAnimationStyles, stylesTab } = this.props
    const isActive = !!stylesTab

    if (iconImage) {
      const imageComponent = isAnimated ? (
        <Animated.Image
          style={[styles.icon, iconAnimationStyles]}
          source={iconImage}
          resizeMode="contain"
        />
      ) : (
        <Image
          style={[styles.icon, isActive ? { tintColor: "black" } : {}]}
          source={iconImage}
          resizeMode="contain"
        />
      )
      return <View style={styles.iconBlockWrapper}>{imageComponent}</View>
    }
    return null
  }

  render() {
    const {
      primaryText,
      secondaryText,
      detailsText,
      footerText,
      actionButton,
      stylesTab,
      fromProfile,
      timeZoneModal,
      subTab,
      fromCalendarSettings,
    } = this.props
    const hasSecondaryText = !!secondaryText && secondaryText.length > 0
    const hasDetailsText = !!detailsText && detailsText.length > 0
    const hasFooterText = !!footerText && footerText.length > 0

    return (
      <>
        {fromProfile ? (
          <div
            className="clickable-row"
            onClick={this.onPressFn}
            style={{ ...stylesTab }}
          >
            <View
              style={[
                styles.leftBlockWrapper,
                subTab && { paddingLeft: "36px" },
              ]}
            >
              <View style={[styles.row, { height: 16 }]}>
                {this.renderIcon()}
                <View style={styles.leftBlockWrapper}>
                  {this.renderText(primaryText, "Primary")}
                </View>
              </View>
            </View>
          </div>
        ) : (
          <TouchableOpacity
            onPress={this.onPressFn}
            style={[
              styles.row,
              styles.clickableRowStyles,
              stylesTab,
              timeZoneModal && {
                paddingHorizontal: "16px",
                paddingTop: 0,
                borderBottom: "1px solid #f3f3f3",
              },
              fromCalendarSettings && {
                display: "flex",
                flexDirection: "row-reverse",
              },
            ]}
          >
            <View style={styles.leftBlockWrapper}>
              <View style={styles.row}>
                {this.renderIcon()}
                <View style={styles.leftBlockWrapper}>
                  {this.renderText(primaryText, "Primary")}
                  {hasSecondaryText && <View style={styles.separatorPadding} />}
                  {this.renderText(secondaryText, "Secondary")}
                  {hasDetailsText && <View style={styles.separatorPadding} />}
                  {this.renderText(detailsText, "Details")}
                </View>
              </View>
              {hasFooterText && <View style={styles.separatorPadding} />}
              {this.renderText(footerText, "Details")}
            </View>
            <View
              style={[
                styles.rightBlockWrapper,
                fromCalendarSettings && { width: "auto", marginRight: 10 },
              ]}
            >
              {actionButton}
            </View>
          </TouchableOpacity>
        )}
      </>
    )
  }
}

SettingsClickableRow.propTypes = {
  iconImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAnimated: PropTypes.bool,
  iconAnimationStyles: PropTypes.object,
  primaryText: PropTypes.arrayOf(PropTypes.string),
  secondaryText: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  detailsText: PropTypes.arrayOf(PropTypes.string),
  footerText: PropTypes.arrayOf(PropTypes.string),
  actionButton: PropTypes.element,
  onPress: PropTypes.func,
}
SettingsClickableRow.defaultProps = {
  renderPrimaryBlock: () => {},
  primaryText: [],
  secondaryText: [],
  detailsText: [],
  footerText: [],
}

export default SettingsClickableRow
