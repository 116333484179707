import React from "react"
import {
  View,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  StyleSheet,
} from "react-native"
import PropTypes from "prop-types"

import { AppColors, AppFonts, Units } from "@theme/"

const styles = StyleSheet.create({
  createTagContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: Units.responsiveValue(56),
    paddingHorizontal: Units.responsiveValue(16),
    borderTopWidth: 3,
    borderTopColor: AppColors.brand.lightGrey,
    backgroundColor: AppColors.brand.white,
  },
  createTagIcon: {
    width: Units.responsiveValue(22),
    height: Units.responsiveValue(22),
    tintColor: AppColors.brand.pink,
    marginRight: Units.responsiveValue(17),
  },
  createTagText: {
    flex: 1,
    ...AppFonts.biggerMedium,
    color: AppColors.brand.blackTwo,
  },
  lengthCountdown: {
    ...AppFonts.medium,
    color: AppColors.brand.grey212,
  },
})

const MAX_TAG_LENGTH = 30

const CreateTagInput = ({
  isCreateTagMode,
  onToggleCreateTagMode,
  onChangeText,
  onBlur,
  onSubmitEditing,
  currentTagValue,
}) => {
  if (isCreateTagMode) {
    return (
      <View style={styles.createTagContainer}>
        <Image
          source={require("../assets/addTag.png")}
          style={styles.createTagIcon}
        />
        <TextInput
          autoFocus={true}
          returnKeyType="done"
          enablesReturnKeyAutomatically={true}
          style={styles.createTagText}
          selectionColor={AppColors.brand.pink}
          underlineColorAndroid={AppColors.brand.white100}
          placeholder="Tag Text"
          placeholderTextColor={AppColors.brand.grey212}
          onChangeText={onChangeText}
          onBlur={onBlur}
          onSubmitEditing={onSubmitEditing}
          value={currentTagValue}
        />
        <View>
          <Text
            style={styles.lengthCountdown}
          >{`${currentTagValue.length}/${MAX_TAG_LENGTH}`}</Text>
        </View>
      </View>
    )
  }
  return (
    <TouchableOpacity
      onPress={onToggleCreateTagMode}
      style={styles.createTagContainer}
    >
      <Image
        source={require("../assets/addTag.png")}
        style={styles.createTagIcon}
      />
      <Text style={styles.createTagText}>Create a tag</Text>
    </TouchableOpacity>
  )
}

CreateTagInput.propTypes = {
  isCreateTagMode: PropTypes.bool.isRequired,
  onToggleCreateTagMode: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSubmitEditing: PropTypes.func.isRequired,
  currentTagValue: PropTypes.string.isRequired,
}

export default CreateTagInput
