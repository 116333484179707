import React, { lazy, Suspense } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import TeeUpsPage from "./pages/TeeUpsPage"
// import SignInPage from "./pages/SignInPage"
import SignInEmail from "./pages/SignInPage/SignInEmail"
import SignInSocial from "./pages/SignInPage/SingleSignInPage"
import PrivateRoute from "./routes/PrivateRoute"
import PublicRoute from "./routes/PublicRoute"
import VerifyPage from "./pages/VerifyPage"
import ForgotPassword from "./pages/ForgotPassword"
import VerifyInvitation from "./pages/VerifyInvitation"
import SignUp from "./pages/SignUpPage"
import ContactMethods from "./pages/ContactMethods"
import ChangeTempPassword from "./pages/ChangeTempPassword"
import LandingPage from "./pages/LandingPage"
import VersePage from "./pages/VersePage"
import MessagesPage from "./pages/MessagesPage"
import NotificationsPage from "./pages/NotificationsPage"
import CalendarPage from "./pages/CalendarPage"
import ContactsPage from "./pages/ContactsPage"
import SettingsPage from "./pages/SettingsPage"
import ChangePassword from "pages/ChangePassword"
import PreviewPage from "./pages/Preview/PreviewPage"
import TeeUpPage from "./pages/TeeUpPage"
import CreateTeeUpNd from "./pages/CreateTeeUpNd"
import ContactsPageNd from "./pages/ContactPageNd/ContactPageNd"
import MyProfileNd from "./pages/MyProfileNd/MyProfileNd"
import UserProfilePage from "pages/ProfilePage"

// import TeeUpsOverview from './components/TeeUpsOverview';

const MePage = lazy(() => import("./pages/MePage"))

const useRoutes = () => {
  return (
    <Suspense fallback={<span>loading...</span>}>
      <Switch>
        <PublicRoute path="/i/:slug" component={PreviewPage} exact />
        {/* <PublicRoute path="/preview" component={PreviewPage} exact /> */}
        {/* <PublicRoute path="/sign-in" component={SignInPage} exact /> */}
        <PublicRoute path="/sign-in" component={SignInSocial} exact />
        <PublicRoute path="/sign-in-email" component={SignInEmail} exact />
        <PublicRoute path="/sign-up" component={SignUp} exact />
        <PublicRoute path="/forgot-password" component={ForgotPassword} exact />
        <PublicRoute
          path="/verify-invitation"
          component={VerifyInvitation}
          exact
        />
        <PrivateRoute path="/teeUps" component={TeeUpsPage} exact />
        {/* new path for profile */}
        <PrivateRoute path="/my-profile" component={MyProfileNd} exact />

        <PrivateRoute path="/me" component={MePage} exact />
        <PrivateRoute path="/verse" component={VersePage} exact />
        <PrivateRoute path="/messages" component={MessagesPage} exact />
        <PrivateRoute path="/create" component={CreateTeeUpNd} exact />
        <PrivateRoute
          path="/notifications"
          component={NotificationsPage}
          exact
        />
        <PrivateRoute path="/calendar" component={CalendarPage} exact />
        {/*temp path for contacts page*/}
        <PrivateRoute path="/contacts" component={ContactsPageNd} exact />
        <PrivateRoute path="/settings" component={SettingsPage} exact />
        <PrivateRoute
          path="/change-password"
          component={ChangePassword}
          exact
        />
        <PublicRoute path="/" component={LandingPage} exact />
        <PrivateRoute path="/contact" component={ContactMethods} exact />
        {/* TODO remove commented out part, once new "/teeUps/:id" is done */}
        {/** old */}
        <PrivateRoute path="/teeUpss/:id" component={TeeUpsPage} exact />
        {/** new, in development */}
        <PrivateRoute path="/teeUps/:id" component={TeeUpPage} exact />

        {/*new path for user profile IN DEVELOPMENT*/}
        <PrivateRoute path="/profile/:id" component={UserProfilePage} exact />
        <Route
          path="/verify/:token"
          render={(props) => <VerifyPage {...props} />}
        />
        <Route
          path="/change-temp-password"
          render={(props) => <ChangeTempPassword {...props} />}
        />
        <Redirect to="/teeUps" />
      </Switch>
    </Suspense>
  )
}

export default useRoutes
