import React, { useEffect, useState } from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"

import { usePosition } from 'use-position';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

import { images } from "@utils/imageUtils";

import './index.scss'

const LocationDetector = ({
  pressedCount,
  setPressedCount,
  primaryLocation,
  setPrimaryLocation,
  close
}) => {
  const { latitude, longitude, } = usePosition()
  const [temporaryPlace, setTemporaryPlace] = useState('')
  const [address, setAddress] = useState('')
  const [pressedManually, setPressedManually] = useState(false)

  useEffect(() => {
    if (pressedCount === 0 && latitude) {
      setPrimaryLocation({
        longitude,
        latitude,
      })
    }
    primaryLocation.longitude && close() 
  }, [latitude, longitude])

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        setAddress(results[0].formatted_address)
        return getLatLng(results[0])
      })
      .then((latLng) => setPrimaryLocation({ 
        longitude: latLng.lng,
        latitude: latLng.lat,
      }))
      .catch((error) => console.error("Error", error))
  }
  
  const handleChange = (address) => {
    setAddress(address)
  }

  return (
    <div 
      className="location-detector__wrapper"
      onClick={() => close()}
    >
      <div
        className="location-detector"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="location-detector__title">Can you determine your geolocation?</h3>
        {/* {latitude && latitude}
        <br/>
        {longitude && longitude} */}
        {pressedManually && (
          <PlacesAutocomplete
            value={temporaryPlace}
            onChange={(temporaryPlace) => {
              setTemporaryPlace(temporaryPlace)
              handleChange(temporaryPlace)
            }}
            onSelect={async (value, placeId, suggestion) => {
              handleSelect(suggestion.description)
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <div className="location__input--wrapper">
                  <img className="location__icon" src={images.search} />
                  <input
                    value={temporaryPlace}
                    className="location__input"
                    {...getInputProps({ placeholder: "Suggest a place..." })}
                  />
                </div>
                <div
                  className="location__list no-scroll"
                  style={{
                    position: "absolute",
                    width: "100%",
                    background: "#ffffff",
                    zIndex: 1,
                  }}
                >
                  {suggestions.map((suggestion) => {
                    return (
                      <div
                        key={
                          suggestion.formattedSuggestion.mainText +
                          suggestion.index
                        }
                        className="location__suggest"
                        {...getSuggestionItemProps(suggestion)}
                      >
                        <div className="location__image--wrapper">
                          <img
                            className="location__image"
                            src={images.searchWhite}
                          />
                        </div>
                        <div className="location__content">
                          <span className="location__name">
                            {suggestion.formattedSuggestion.mainText}
                          </span>
                          <span className="location__description">
                            {suggestion.formattedSuggestion.secondaryText}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
        <div className="location-detector__buttons">
          <div
            className="location-detector__manually"
            onClick={() => setPressedManually(true)}
          >
            manually
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC8ce4byJ3uPdPTNZ6_dpw9utQVGClnVL4",
})(LocationDetector);
