import React, { useState, useEffect, useMemo } from "react"
import { View } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Dimensions } from "react-native"
import _ from "lodash"

import TeeupList from "../teeupList"
import SortingAndFilter from "../SideFilterSortMenu"
import TeeupsSearchInput from "../TeeupsSearchInput"
import TeeupsMenu from "../TeeupsMenu"
import { setTeeupActive } from "../../actions/activeTeeupActions"
import {
  selectActiveTeeups,
  selectArchivedTeeups,
  selectRemovedTeeups,
  selectTeeupsGameplans,
} from "../../selectors/teeups"
import {
  DEFAULT_SORT,
  TEEUP_LIST_MODES,
  initialFiltersValues,
  teeupsFiltering,
  teeupSorting,
} from "@utils/teeupUtils"
import { selectToken, selectUserId } from "../../selectors/user"
import {
  selectActiveTeeup,
  selectActiveTeeupId,
  selectDetailsOpen,
} from "../../selectors/activeTeeup"
import { setupWebSocket } from "../../middlewares/backendMiddleware"
import TagButton from "../ui/tagButton"
import CalendarCard from "../calendarCard"
import TeeUpsOverview from "../TeeUpsOverview"
import { selectTeeupSearch, selectNotificationTeeups } from "@selectors/common"
import { selectUnseenTeeups } from "@selectors/teeups"
import {
  setPeopleSideBar,
  handleOpenSuggestion,
  handleCloseSuggestion,
  handleSetTeeupsMode,
} from "@actions/commonActions"

import { useHistory } from "react-router-dom"

import ReactDOM from "react-dom"

import SuggestionContainer from "../TeeUpsOverview/SuggestionModal/SuggestionContainer"

import "./index.scss"

const TeeUps = (props) => {
  const {
    activeTeeUpId,
    token,
    userId,
    notificationTeeups,
    notifications,
    isDetailsOpen,
    param,
    all,
    activeTeeups,
    activeTeeUp,
    setPeopleSideBar,
    handleOpenSuggestion,
    handleCloseSuggestion,
    isSuggestionOpen,
    handleSetTeeupsMode,
    teeupsMode,
  } = props

  const [searchTerm, setSearchTerm] = useState("")
  // const [teeupsMode, setTeeupsMode] = useState(null)
  const [showFilter, setShowFilter] = useState(false)
  const [sorting, setSorting] = useState(DEFAULT_SORT)
  const [filtering, setFiltering] = useState(initialFiltersValues)
  const [contentHeight, setContentHeight] = useState(null)
  // const [isSuggestionOpen, setIsSuggestionOpen] = useState(false)
  const [content, setContent] = useState(null)
  const [suggestionListContent, setSuggestionListContent] = useState(null)

  const history = useHistory()

  useEffect(() => {
    if (param && activeTeeups.length)
      setTeeupActive(activeTeeups.filter((item) => item.id === +param)[0])
  }, [activeTeeups])

  const windowWidth = Dimensions.get("window").width

  useEffect(() => {
    if (activeTeeUpId) setupWebSocket(token, activeTeeUpId, userId)
  }, [activeTeeUpId])

  useEffect(() => {
    // activeTeeUpId == null && setTeeupsMode(TEEUP_LIST_MODES.ACTIVE)
    // activeTeeUpId == null && handleSetTeeupsMode(TEEUP_LIST_MODES.ACTIVE)
  }, [activeTeeUpId])

  const teeupList = useMemo(() => {
    if (teeupsMode === TEEUP_LIST_MODES.ARCHIVED) {
      return props.archivedTeeups
    }

    if (teeupsMode === TEEUP_LIST_MODES.SKIPPED) {
      return props.removedTeeups
    }

    return props.activeTeeups
  }, [
    props.archivedTeeups,
    props.removedTeeups,
    props.activeTeeups,
    teeupsMode,
  ])

  const sortedList = useMemo(() => {
    const { teeupsGameplans } = props

    const sorted = teeupSorting(teeupList, sorting, teeupsGameplans)

    return sorting.desc ? sorted.reverse() : sorted
  }, [sorting, teeupsMode, teeupList])

  const resetSortAndFilter = () => {
    setSorting(DEFAULT_SORT)
    setFiltering(initialFiltersValues)
  }

  const filterAndSortedData = () => {
    const { userId, teeupsGameplans, teeupFilter } = props
    let teeupsData = teeupsFiltering(
      sortedList,
      filtering,
      userId,
      teeupsGameplans
    )

    if (notificationTeeups) {
      const notificationsArr = notifications.map(
        (notification) => notification.id
      )
      return teeupsData.filter((teeup) => notificationsArr.includes(teeup.id))
    }

    if (teeupFilter) {
      return teeupsData.filter(
        (teeup = {}) =>
          teeup.name && teeup.name.match(new RegExp(teeupFilter, "i"))
      )
    }

    return teeupsData
  }

  const renderFilterButtons = (teeupFiltering) => {
    return (
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {Object.values(teeupFiltering).map((filter) => {
          if (!filter.length) return null
          return filter.map((button) => (
            <TagButton
              key={`${button.id}`}
              onPress={() => {
                const { id, filterType } = button

                let currentFilter = [...teeupFiltering[filterType]]

                currentFilter = _.filter(
                  currentFilter,
                  (value) => value && value.id !== id
                )
                setFiltering({
                  ...teeupFiltering,
                  [filterType]: currentFilter,
                })
              }}
              value={button.title}
            />
          ))
        })}
      </View>
    )
  }

  const contentLayout = ({ nativeEvent }) => {
    const { height } = nativeEvent.layout
    setContentHeight(height)
  }

  // const handleOpenSuggestion = () => setIsSuggestionOpen(true);
  // const handleCloseSuggestion = () => setIsSuggestionOpen(false);

  return (
    <View
      style={{
        flexDirection: "row",
        height: "100%",
        flex: 1,
        position: "relative",
      }}
      onLayout={contentLayout}
    >
      {/* <TeeupsMenu
        setTeeupActive={setTeeupActive}
        teeupsMode={teeupsMode}
        updateTeeupsMode={handleSetTeeupsMode}
        // updateTeeupsMode={setTeeupsMode}
        hasNotifications={notifications.length > 0}
      /> */}
      {showFilter && (
        <SortingAndFilter
          closeMenu={() => setShowFilter(false)}
          teeupSorting={sorting}
          teeupFiltering={filtering}
          teeupsData={filterAndSortedData()}
          addTeeupsSorting={setSorting}
          addTeeupsFiltering={setFiltering}
          clearTeeupsFiltering={resetSortAndFilter}
        />
      )}

      {/* {!activeTeeUpId ? ( */}
      {!param ? (
        <div className="teeups-container">
          <TeeupList
            scrollEnabled={false}
            teeups={filterAndSortedData()}
            searchTerm={searchTerm}
            teeupsMode={teeupsMode}
            sorting={sorting}
            openedSort={showFilter}
            toggleSort={setShowFilter}
            onRefresh={() => {}}
            onTeeupPressed={(teeup) => setTeeupActive(teeup)}
            emptyScrollEnabled={false}
            renderFilterButtons={renderFilterButtons(filtering)}
            addTeeupsSorting={setSorting}
            sortingType={sorting}
            teeupFilter={props.teeupFilter}
            resetSortAndFilter={resetSortAndFilter}
            param={param}
            history={history}
            // TODO prop drilling is not a good solution.consider moving stuff to contexts
            filtering={filtering}
            setFiltering={setFiltering}
          />
        </div>
      ) : (
        <View
          style={[
            // windowWidth > 1440 && {marginHorizontal: 300},
            { display: "flex", flexDirection: "row", flexGrow: 1 },
            isDetailsOpen
              ? { justifyContent: "flex-end" }
              : { justifyContent: "center" },
            // windowWidth <= 1440 && {marginLeft: 275, marginRight: 250},
            // {position: 'absolute', maxWidth: '864px', width: '-webkit-fill-available', top: 0, right: '50%', transform: 'translateX(50%)'},
          ]}
        >
          <TeeUpsOverview
            currentTeeUp={props.activeTeeUp}
            handleOpenSuggestion={handleOpenSuggestion}
            content={content}
            setContent={setContent}
            suggestionListContent={suggestionListContent}
            setSuggestionListContent={setSuggestionListContent}
          />
        </View>
      )}

      {isSuggestionOpen &&
        ReactDOM.createPortal(
          <SuggestionContainer
            activeTeeUp={props.activeTeeUp}
            handleCloseSuggestion={handleCloseSuggestion}
            content={content}
            setContent={setContent}
            suggestionListContent={suggestionListContent}
            setSuggestionListContent={setSuggestionListContent}
            setPeopleSideBar={setPeopleSideBar}
          />,
          document.getElementById("modal-root")
        )}
    </View>
  )
}

const mapStateToProps = (state) => {
  return {
    all: state,
    activeTeeups: selectActiveTeeups(state),
    archivedTeeups: selectArchivedTeeups(state),
    removedTeeups: selectRemovedTeeups(state),
    token: selectToken(state),
    userId: selectUserId(state),
    activeTeeUpId: selectActiveTeeupId(state),
    teeupsGameplans: selectTeeupsGameplans(state),
    activeTeeUp: selectActiveTeeup(state),
    teeupFilter: selectTeeupSearch(state),
    notificationTeeups: selectNotificationTeeups(state),
    notifications: selectUnseenTeeups(state),
    isDetailsOpen: selectDetailsOpen(state),
    isSuggestionOpen: state.common.isSuggestionOpen,
    teeupsMode: state.common.teeupsMode,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setTeeupActive: bindActionCreators(setTeeupActive, dispatch),
  setPeopleSideBar: bindActionCreators(setPeopleSideBar, dispatch),
  handleOpenSuggestion: bindActionCreators(handleOpenSuggestion, dispatch),
  handleCloseSuggestion: bindActionCreators(handleCloseSuggestion, dispatch),
  handleSetTeeupsMode: bindActionCreators(handleSetTeeupsMode, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeeUps)
