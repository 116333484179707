import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
  },
  container: {
    backgroundColor: AppColors.brand.white,
    paddingHorizontal: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(12),
    alignItems: "center",
  },
  avatar: {
    width: Units.responsiveValue(40),
    height: Units.responsiveValue(40),
    marginRight: Units.responsiveValue(16),
    borderRadius: Units.responsiveValue(40 / 2),
  },
  initial: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.brand.grey2,
  },
  initialText: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.darkGrey2,
  },
  body: {
    flex: 1,
    justifyContent: "center",
  },
  name: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.blackTwo,
  },
  connected: {
    position: "absolute",
    bottom: 0,
    left: -Units.responsiveValue(4),
    width: 18,
    height: 14,
  },
  userInfo: {
    alignItems: "center",
  },
  cooEIcon: {
    marginTop: Units.responsiveValue(2),
    marginLeft: Units.responsiveValue(4),
    width: Units.responsiveValue(13),
    height: Units.responsiveValue(12),
    tintColor: AppColors.brand.pink,
  },
  tagList: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: Units.responsiveValue(4),
  },
  selectedTagList: {
    flexWrap: "wrap",
  },
  tag: {
    alignItems: "center",
  },
  tagIcon: {
    tintColor: AppColors.brand.darkGrey2,
    width: Units.responsiveValue(10),
    height: Units.responsiveValue(10),
    marginRight: Units.responsiveValue(2),
  },
  selectedTagIcon: {
    tintColor: AppColors.brand.blueGrey,
  },
  tagLabel: {
    ...AppFonts.smallish,
    lineHeight: AppFonts.sizes.smallish,
    color: AppColors.brand.darkGrey2,
  },
  selectedTagLabel: {
    color: AppColors.brand.blueGrey,
  },
  message: {
    width: Units.responsiveValue(20),
    height: Units.responsiveValue(20),
  },
  moreButton: {
    marginLeft: Units.responsiveValue(24),
    width: Units.responsiveValue(24),
    height: Units.responsiveValue(24),
    justifyContent: "center",
    alignItems: "center",
  },
  moreIcon: {
    tintColor: AppColors.brand.darkGrey2,
    width: Units.responsiveValue(16),
    heigth: Units.responsiveValue(16),
    resizeMode: "contain",
  },
  checkbox: {
    width: 24,
    height: 24,
    border: "1px solid #d9d9d9",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  closeIcon: {
    tintColor: "white",
    height: 25,
    width: 19,
  },
  iconWrapper: {
    height: 25,
    width: 19,
    position: "absolute",
    right: 16,
    top: 16,
    zIndex: 1,
  },
})

export default styles
