import React from "react"
import { FlatList } from "react-native"
import PropTypes from "prop-types"

import { RecommendationStyles } from "./gameplansStyles"
import RecommendedItem from "./RecommendedItem"

const RecommendationList = ({
  recommendations,
  onSelectRecommendation,
  numberOfPeople,
}) => {
  const renderItem = ({ item }) => {
    return (
      <RecommendedItem
        item={item}
        onSelectRecommendation={onSelectRecommendation}
        numberOfPeople={numberOfPeople}
      />
    )
  }

  return (
    <FlatList
      data={recommendations}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      style={RecommendationStyles.flatlist}
    />
  )
}

export default RecommendationList

RecommendationList.propTypes = {
  recommendations: PropTypes.array,
  onSelectRecommendation: PropTypes.func,
  item: PropTypes.object,
  numberOfPeople: PropTypes.number,
}
