import React from "react"
import { View, SectionList } from "react-native"
import PropTypes from "prop-types"
import { ViewStyles } from "./ParticipantDetailsStyles"

const ParticipantSuggestionTabView = ({
  renderItem,
  renderHeader,
  sections,
}) => {
  return (
    <View style={ViewStyles.container}>
      <SectionList
        sections={sections}
        keyExtractor={(item) => item.suggestion.id}
        renderSectionHeader={renderHeader}
        renderItem={renderItem}
        stickySectionHeadersEnabled={false}
        showsVerticalScrollIndicator={false}
      />
    </View>
  )
}

export default ParticipantSuggestionTabView

ParticipantSuggestionTabView.propTypes = {
  renderItem: PropTypes.func.isRequired,
  renderHeader: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
}
