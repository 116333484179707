import React, { } from "react"

import "./index.scss";

const ConfirmDeleteUser = ({ deleteUser, closeModal }) => {
  return (
    <div
      className="confirm-delete-user__wrapper"
      onClick={() => closeModal()}
    >
      <div
        className="confirm-delete-user"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="confirm-delete-user__title">Are you sure you want to delete this user?</h3>
        <div className="confirm-delete-user__buttons">
          <button
            className="confirm-delete-user__cancel"
            type="button"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            className="confirm-delete-user__delete"
            type="button"
            onClick={() => {
              deleteUser()
              closeModal()
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmDeleteUser;
