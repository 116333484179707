import { SET_DAYS_SELECTED, SET_QUICK_SELECT, SET_TIME } from "./actionTypes"

export const GeneralTimeReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_DAYS_SELECTED:
      return {
        ...state,
        daysSelected: payload,
      }
    case SET_QUICK_SELECT:
      return {
        ...state,
        quickSelect: payload,
      }
    case SET_TIME:
      return {
        ...state,
        time: payload,
      }
    default: {
      return state
    }
  }
}
