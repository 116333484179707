import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg";
import I18n from '@i18n'

import { images } from "@utils/imageUtils";

import { messageTypeIds } from '@configs/mappings'
import { teeupListNudgeTypes } from '@configs/enums'
import { sendNudge } from '@middlewares/backendMiddleware'
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectUserId } from "@selectors/user"

import './index.scss'
import { NavLink } from "react-router-dom";

const PeopleThreeDotsItem = ({
  itemId,
  hoverCount,
  setHoverCount,
  image,
  imageActive,
  title,
  done,
  path,
}) => {
  if (itemId === 1) {
    return (
      <NavLink
        to={path}
        className="people-column-dropdown__item"
        onMouseOver={() => setHoverCount(itemId)}
        onMouseLeave={() => setHoverCount(0)}
      >
        <ReactSVG
          className="people-column-dropdown__icon"
          src={itemId === hoverCount ? imageActive : image}
        />
        <h4 className="people-column-dropdown__title">{title}</h4>
      </NavLink>
    )
  }

  return (
    <div
      className="people-column-dropdown__item"
      onMouseOver={() => setHoverCount(itemId)}
      onMouseLeave={() => setHoverCount(0)}
      onClick={() => done()}
    >
      <ReactSVG
        className="people-column-dropdown__icon"
        src={itemId === hoverCount ? imageActive : image}
      />
      <h4 className="people-column-dropdown__title">{title}</h4>
    </div>
  )
}

const PeopleThreeDots = ({ data }) => {
  const [hoverCount, setHoverCount] = useState(0)

  const activeTeeupId = useSelector(selectActiveTeeupId)
  const userId = useSelector(selectUserId)

  const nudgeAreYouGoing = async () => {
    await sendNudge({
        message: I18n.nudgePromptContent.areYouGoing.action,
        messageTypeId: messageTypeIds.nudge,
        mentionIds: [data.id],
        subTypeId: teeupListNudgeTypes.areYouGoing,
        teeupId: activeTeeupId
    })
  }

  const nudgeWhereAreYou = async () => {
    await sendNudge({
        message: I18n.nudgePromptContent.whereAreYou.action,
        messageTypeId: messageTypeIds.nudge,
        mentionIds: [data.id],
        subTypeId: teeupListNudgeTypes.whereAreYou,
        teeupId: activeTeeupId
    })
  }

  return (
    <div className="people-column-dropdown">
      <PeopleThreeDotsItem
        itemId={1}
        hoverCount={hoverCount}
        setHoverCount={setHoverCount}
        image={images.ndViewProfile}
        imageActive={images.ndViewProfileActive}
        title="View profile"
        path={userId === data.id ? '/my-profile' : `/profile/${data.id}`}
      />
      <PeopleThreeDotsItem
        itemId={2}
        hoverCount={hoverCount}
        setHoverCount={setHoverCount}
        image={images.ndTeeUpDropDownNudge}
        imageActive={images.ndTeeUpDropDownNudgeActive}
        title="Are you going?"
        done={() => nudgeAreYouGoing()}
      />
      <PeopleThreeDotsItem
        itemId={3}
        hoverCount={hoverCount}
        setHoverCount={setHoverCount}
        image={images.ndTeeUpDropDownNudge}
        imageActive={images.ndTeeUpDropDownNudgeActive}
        title="Where are you?"
        done={() => nudgeWhereAreYou()}
      />
    </div>
  )
}

export default PeopleThreeDots;
