import _ from "lodash"
import strings from "@i18n"
import { Platform } from "react-native"
import moment from "moment"

import actionTypes from "@actions/actionTypes"
import { selectTeeups, selectArchivedTeeups } from "@selectors/teeups"
import {
  selectCalendarDays,
  selectSelectedCalendar,
  selectAdditionalCalendars,
} from "@selectors/calendar"

import {
  calendarTeeupRemoved,
  calendarTeeupAdd,
  calendarAddTeeupEvents,
} from "@actions/calendarActions"
import { teeupStatusKeys } from "@configs/enums"
import {
  formatCalendarEventDate,
  getDateWithTimezone,
  isEqualDates,
} from "@utils/dateUtils"
import {
  DEFAULT_SYNCED_DURATION_MINS,
  DEFAULT_EVENT_DURATION_MINS,
  getEndDate,
} from "@utils/calendarUtils"
import { isTeeupDisabled } from "@utils/teeupUtils"
import { getSelectedGameplan } from "@utils/gamePlanUtils"
import Toast from "@ui/toast"
import CalendarState from "../reducers/calendarStorage"
import { store } from "../index"

const SKIP_CALENDAR_PERMISSION = "SKIP_CALENDAR_PERMISSION"

export const isAuthorized = () => {
  // return RNCalendarEvents.checkPermissions()
  //     .then((status) => {
  //         if (status === 'authorized') {
  //             return true
  //         } else if (status === 'undetermined' || status === 'denied') {
  //             // think its temp solution. Was a crash when we ask calendar permission when other we already have permission prompt
  //             return SKIP_CALENDAR_PERMISSION
  //         }
  //         return false
  //     })
  //     .catch((error) => {
  //         console.log('RNCalendarEvents.authorizationStatus: error', {
  //             error,
  //         })
  //         return false
  //     })
}

export const authorize = () => {
  // return RNCalendarEvents.requestPermissions()
  //     .then((status) => {
  //         if (status === 'authorized') {
  //             return true
  //         }
  //         return false
  //     })
  //     .catch((error) => {
  //         console.log('RNCalendarEvents.authorizeEventStore: error', {
  //             error,
  //         })
  //         return false
  //     })
}

export const hasCalendarPermissions = async (supressToasts) => {
  const permission = await handleCheckCalendarPermissions(supressToasts)
  // switch (permission) {
  //     // case RESULTS.UNAVAILABLE:
  //     //   This feature is not available (on this device / in this context)
  //     //   break;
  //     case RESULTS.DENIED:
  //         // The permission has not been requested / is denied but requestable
  //         if (!supressToasts) {
  //             Toast.show(I18n.t('calendar.toastMsg.noPermissions'))
  //         }
  //         return false
  //     case RESULTS.BLOCKED:
  //         // The permission is denied and not requestable anymore
  //         return false
  //     case RESULTS.GRANTED:
  //         break
  // }
  let isAllowed = await isAuthorized()
  if (!isAllowed || (isAllowed && isAllowed !== SKIP_CALENDAR_PERMISSION)) {
    isAllowed = await authorize()
  }

  if (!isAllowed || (isAllowed && isAllowed === SKIP_CALENDAR_PERMISSION)) {
    if (!supressToasts) {
      Toast.show(strings.calendar.toastMsg.authorizeManually)
    }
    return false
  }

  let calendar = await getSpecificCalendarById(
    selectSelectedCalendar(store.getState())
  )

  if (!calendar) {
    if (!supressToasts) {
      Toast.show(strings.calendar.toastMsg.notFound)
    }
    return false
  }

  return true
}

export const handleCheckCalendarPermissions = async (supressToasts = false) => {
  const permissionChecks = []
  // if (Platform.OS === 'ios') {
  //     permissionChecks.push(check(PERMISSIONS.IOS.CALENDARS))
  // } else {
  //     permissionChecks.push(check(PERMISSIONS.ANDROID.READ_CALENDAR))
  //     permissionChecks.push(check(PERMISSIONS.ANDROID.WRITE_CALENDAR))
  // }

  const checkResult = await Promise.all(permissionChecks)
  // if (checkResult.every((result) => result === RESULTS.GRANTED)) {
  //     return RESULTS.GRANTED
  // }
  // if (checkResult.some((result) => result === RESULTS.UNAVAILABLE)) {
  //     return RESULTS.UNAVAILABLE
  // }
  // if (checkResult.some((result) => result === RESULTS.BLOCKED)) {
  //     if (!supressToasts) {
  //         Toast.show(I18n.t('calendar.toastMsg.noPermissions'))
  //     }
  //     return RESULTS.BLOCKED
  // }
  // if (checkResult.some((result) => result === RESULTS.DENIED)) {
  //     if (!supressToasts) {
  //         Toast.show(I18n.t('calendar.toastMsg.noPermissions'))
  //     }
  //     return RESULTS.DENIED
  // }
}

export const handleAskCalendarPermissions = async (
  callback,
  supressToasts = false
) => {
  let permissionsCheck = await handleCheckCalendarPermissions(supressToasts)
  // switch (permissionsCheck) {
  //     // case RESULTS.DENIED:
  //     case RESULTS.UNAVAILABLE:
  //         return permissionsCheck
  //     case RESULTS.GRANTED:
  //         callback()
  //         return permissionsCheck
  // }
  let requestResults = []
  const requestMessage = {
    title: strings.calendar.alerts.permissionTitle,
    message: strings.calendar.alerts.permissionMsg,
    buttonNeutral: strings.systemButtons.askMeLater,
    buttonNegative: strings.systemButtons.cancel,
    buttonPositive: strings.systemButtons.ok,
  }
  // if (Platform.OS === 'ios') {
  //     const requestResult = await request(
  //         PERMISSIONS.IOS.CALENDARS,
  //         requestMessage
  //     )
  //     requestResults.push(requestResult)
  // } else {
  //     if (permissionsCheck !== RESULTS.BLOCKED)
  //         requestResults = await requestMultiple([
  //             PERMISSIONS.ANDROID.READ_CALENDAR,
  //             PERMISSIONS.ANDROID.WRITE_CALENDAR,
  //         ])
  // }

  permissionsCheck = await handleCheckCalendarPermissions(supressToasts)

  // if (permissionsCheck === RESULTS.GRANTED) {
  //     callback()
  // }
  return permissionsCheck
}

let calendars
export const getCalendars = async () => {
  if (calendars) {
    return calendars
  }

  let isAllowed = await isAuthorized()
  if (!isAllowed || (isAllowed && isAllowed !== SKIP_CALENDAR_PERMISSION)) {
    isAllowed = await authorize()
  }

  // calendars = await RNCalendarEvents.findCalendars()
  return calendars
}

export const getSpecificCalendarById = async (selectedCalendar) => {
  let calendars = await getCalendars()
  return getSpecificCalendarByIdWithCalendars(selectedCalendar, calendars)
}

export const getSpecificCalendarByIdWithCalendars = (
  selectedCalendar,
  calendars
) => {
  let calendar = null
  if (CalendarState && calendars.length > 0) {
    // Should be efficient enough for this screen
    calendars.forEach((cal) => {
      if (cal.id === selectedCalendar) {
        calendar = cal
      }
    })
  }
  return calendar
}

export const getCalendarEvents = async ({
  syncSelectedInterval = undefined,
  start = undefined,
  shouldSupressPermissionToast = true,
}) => {
  let isAllowed = await hasCalendarPermissions(shouldSupressPermissionToast)

  if (!isAllowed || (isAllowed && isAllowed === SKIP_CALENDAR_PERMISSION)) {
    return
  }

  const state = store.store()
  const selectedCalendarId = selectSelectedCalendar(state)
  const selectedAdditionalCalendarIds = selectAdditionalCalendars(state)
  const calendarDays = selectCalendarDays(state)

  let startDate
  let endDate
  if (!syncSelectedInterval) {
    startDate = getDateWithTimezone().startOf("year")
    endDate = getDateWithTimezone().endOf("year")
    let calendarStartDate
    let calendarEndDate
    if (calendarDays.length > 0) {
      calendarStartDate = getDateWithTimezone(calendarDays[0]).startOf("month")
      calendarEndDate = getDateWithTimezone(
        calendarDays[calendarDays.length - 1]
      ).endOf("month")
    }
    if (calendarStartDate && calendarEndDate) {
      if (startDate && startDate.isAfter(calendarStartDate)) {
        startDate = calendarStartDate
      }
      if (endDate && endDate.isBefore(calendarEndDate)) {
        endDate = calendarEndDate
      }
    }
  } else {
    startDate = getDateWithTimezone(start).startOf("month")
    endDate = getDateWithTimezone(start).endOf("month")
  }

  startDate = startDate?.toISOString()
  endDate = endDate?.toISOString()
  if (!startDate) {
    return
  }
  let events = []
  try {
    // events = await RNCalendarEvents.fetchAllEvents(startDate, endDate, [
    //     selectedCalendarId,
    //     ...selectedAdditionalCalendarIds,
    // ])
  } catch (e) {
    console.log("RNCalendarEvents.fetchAllEvents error: ", e)
    return
  }

  const teeups = selectTeeups(store.getState())
  const archivedTeeups = selectArchivedTeeups(store.getState())
  const teeupEvents = {}
  const teeupsWithEvents = [...teeups, ...archivedTeeups]
  teeupsWithEvents.forEach((teeup) => {
    if (teeup.events && teeup.events.length > 0) {
      teeup.events.forEach((event) => {
        if (event.eventId) {
          teeupEvents[event.eventId] = true
        }
      })
    }
  })

  // Remove teeup events
  const filteredEvents = []
  events.forEach((event) => {
    if (teeupEvents[event.id]) {
      // Teeup event found
      return
    }
    // Transformation of events
    let formattedEvent = { ...event, name: event.title }
    if (event.allDay) {
      const momentDate = moment.utc(event.startDate)
      const eventDay = getDateWithTimezone()
        .year(momentDate.year())
        .month(momentDate.month())
        .date(momentDate.date())

      formattedEvent.startDate = formatCalendarEventDate(
        eventDay.startOf("day")
      )
      formattedEvent.endDate = formatCalendarEventDate(eventDay.endOf("day"))
    } else if (!event.endDate || isEqualDates(event.startDate, event.endDate)) {
      formattedEvent.endDate = formatCalendarEventDate(
        getEndDate({
          startDate: event.startDate,
          endDate: event.endDate,
          overrideDuration: true,
          defaultDurationMins: DEFAULT_EVENT_DURATION_MINS,
        })
      )
    }
    filteredEvents.push(formattedEvent)
  })

  store.dispatch({
    type: actionTypes.GOT_CALENDAR,
    payload: filteredEvents,
  })
}

const checkExistingCalendarEvent = async (event) => {
  if (!event || !event.eventId) {
    return null
  }

  let eventId = event.eventId
  if (Platform.OS === "android") {
    // In Android calendar event ids need to be int
    eventId = parseInt(eventId)
  }
  if (eventId) {
    // try {
    //     let existingEvent = await RNCalendarEvents.findEventById(
    //         '' + eventId
    //     )
    //     if (existingEvent) {
    //         return {
    //             ...event,
    //             ...existingEvent,
    //             eventIdBE: event.id,
    //         }
    //     }
    // } catch (error) {
    //     console.log('RNCalendarEvents.findEventById error: ', error)
    // }
    // The event is not found. Maybe user deleted it manually or on a different device eventId is not shared
    calendarTeeupRemoved(event.teeupId, event.id)
    return null
  }
  return null
}

const unscheduleCalendarEvent = async (event, teeupId) => {
  if (event && event.id && !event.external) {
    // can delete only the events we've auto-synced
    await removeTeeupEvent(event.eventId)
    calendarTeeupRemoved(teeupId, event.eventIdBE)
  }
}

export const syncCalendarTeeups = async (
  userId,
  shouldSync,
  shouldSyncAllSetOnly,
  selectedCalendar,
  teeups = [],
  teeupGameplans,
  teeupsState = {}, // dynamic, usually unseen updates for teeups,
  shouldCheckSelectedCalendar = true
) => {
  if (shouldCheckSelectedCalendar && (!shouldSync || !selectedCalendar)) {
    // Only allow to even try syncing if a specific calendar added
    return
  }

  if (shouldCheckSelectedCalendar) {
    let isAllowed = await hasCalendarPermissions(true)
    if (!isAllowed || (isAllowed && isAllowed === SKIP_CALENDAR_PERMISSION)) {
      return
    }
  }
  let newlySyncedTeeups = {}

  for (let i = 0, len = teeups.length; i < len; i++) {
    const teeup = teeups[i]
    const teeupId = teeup.id
    const teeupState = teeupsState[teeupId]
    const gameplans = teeupGameplans[teeupId]
    const teeupTitle =
      teeupState && teeupState.newTitle ? teeupState.newTitle : teeup.name
    const teeupStatus =
      teeupState && teeupState.newStatus ? teeupState.newStatus : teeup.status

    let existingEvents = []
    let existingEventIds = []

    // Should try to sync this teeup to calendar if:
    // 1) not there yet
    // 2) has a date/time specific when gameplan

    // TODO: also handle startsWhen and whenWorks

    // Use dynamically gotten teeup updates if any. Otherwise use downloaded teeup gameplan
    // TODO: why teeupState has newWhen.when? should immediately be the correct structure in newWhen?
    const newWhen = _.get(teeupState, ["newWhen", "when"], null)
    let startDate
    let endDate
    let isDesided = false
    if (newWhen) {
      if (newWhen.type === "Day" && teeupState.newWhen.type === "WHEN") {
        // Avoiding startsWhen/whenWorks for now
        startDate = newWhen.value
        isDesided = newWhen.isDecided
      }
    } else {
      if (gameplans) {
        let whenGameplan = getSelectedGameplan(gameplans, "when")

        if (whenGameplan) {
          if (!whenGameplan.isCustomDate && !whenGameplan.isCustomTime) {
            // Time/date specific when gameplan
            startDate = whenGameplan.startDate
            endDate = getEndDate(whenGameplan)
          }
          isDesided = whenGameplan.decided
        } else {
          // Maybe it's startsWhen or whenWorks
          gameplans.forEach((gameplan) => {
            if (gameplan.type === "startsWhen") {
              if (gameplan.conditionTime) {
                // conditionTime is the optimal predicted time that fits the best
                startDate = gameplan.conditionTime
              }
            } else if (gameplan.type === "worksWhen") {
              // TODO: in progress
            }
          })
        }
      }
    }
    let promises = []
    if (teeup.events && teeup.events.length > 0) {
      promises = teeup.events.map(
        async (event) => await checkExistingCalendarEvent(event)
      )
    }
    existingEvents = await Promise.all(promises)
    existingEvents = existingEvents.filter((event) => !!event)
    existingEventIds = existingEvents.map((event) => event.id)
    if (
      !startDate ||
      isTeeupDisabled(teeup) ||
      (shouldSyncAllSetOnly &&
        !(
          teeupStatus === teeupStatusKeys.allset ||
          teeupStatus === teeupStatusKeys.happening
        ))
    ) {
      // Might need to unschedule this as probably it was 'allset' teeup and becase 'planning' or something
      let promises = []
      if (existingEvents && existingEvents.length > 0) {
        const newlySynced = []
        promises = existingEvents.map(async (event) => {
          if (event.external) {
            newlySynced.push(event)
            return null
          }
          await unscheduleCalendarEvent(event, teeupId)
        })
        newlySyncedTeeups[teeupId] = {
          ...teeup,
          teeupId,
          userId,
          events: newlySynced,
        }
      }
      await Promise.all(promises)
    } else if (
      !shouldSyncAllSetOnly ||
      teeupStatus === teeupStatusKeys.allset ||
      teeupStatus === teeupStatusKeys.happening
    ) {
      // date/time specific when gameplan found
      const whereGameplan = _.get(
        teeupState,
        ["newWhere", "where"],
        getSelectedGameplan(gameplans, "where")
      )
      let hasAutoSyncedEvent = false
      const newlySynced = []
      if (existingEvents.length > 0) {
        const promises = existingEvents.map(async (event) => {
          if (event.external) {
            newlySynced.push(event)
            return null
          }
          if (!hasAutoSyncedEvent && event.calendar.id === selectedCalendar) {
            hasAutoSyncedEvent = true
            newlySynced.push(event)
            // update data if necessary
            try {
              await addTeeupEvent({
                teeupTitle,
                isPlanning: teeupHasPlanningMark({
                  teeupStatus,
                  isDesided,
                }),
                startDate,
                endDate,
                whereGameplan,
                existingEvent: event,
                selectedCalendar,
              })
            } catch (e) {
              console.log("addTeeupEvent error: ", e)
            }
          } else {
            // it's duplicate in the same calendar
            // or the calendar was changed
            await unscheduleCalendarEvent(event, teeupId)
          }
        })
        existingEvents = await Promise.all(promises)
      }
      if (existingEvents.length === 0 || !hasAutoSyncedEvent) {
        try {
          const eventId = await addTeeupEvent({
            teeupTitle,
            isPlanning: teeupHasPlanningMark({
              teeupStatus,
              isDesided,
            }),
            startDate,
            endDate,
            whereGameplan,
            selectedCalendar,
          })
          if (
            eventId &&
            (existingEvents.length === 0 || !existingEventIds.includes(eventId))
          ) {
            // Only when new event is saved, not an existing update
            newlySynced.push({
              ...teeup,
              teeupId,
              userId,
              eventId,
              id: 1000000,
              external: false,
            })
            // What to do upon error? if BE doesn't receive this calendar sync...
            calendarTeeupAdd({
              teeupId,
              userId,
              eventId,
            })
          } else {
            // Probably some error occured OR same event got updated. Or maybe not even updated, just verified to be the same
          }
        } catch (e) {
          console.log("addTeeupEvent error: ", e)
        }
      }
      newlySyncedTeeups[teeupId] = {
        ...teeup,
        teeupId,
        userId,
        events: newlySynced,
      }
    }
  }

  if (!_.isEmpty(newlySyncedTeeups)) {
    // Some new teeups synced to calendar
    calendarAddTeeupEvents(newlySyncedTeeups)
  }
}

export const removeTeeupEvent = async (eventId) => {
  // return RNCalendarEvents.removeEvent(eventId)
  //     .then((id) => {
  //         return id
  //     })
  //     .catch((error) => {
  //         console.log('error removeTeeupEvent', error)
  //         return null
  //     })
}

const teeupHasPlanningMark = ({ teeupStatus, isDesided }) =>
  teeupStatus === teeupStatusKeys.planning && !isDesided

export const addTeeupEvent = async ({
  teeupTitle,
  isPlanning,
  startDate,
  endDate,
  whereGameplan,
  existingEvent,
  selectedCalendar,
}) => {
  const noDuration = !endDate || isEqualDates(startDate, endDate)
  const start = formatCalendarEventDate(startDate)
  const end = formatCalendarEventDate(
    getEndDate({
      startDate,
      endDate,
      overrideDuration: noDuration,
      defaultDurationMins: DEFAULT_SYNCED_DURATION_MINS,
    })
  )

  const location =
    whereGameplan && whereGameplan.value ? whereGameplan.value : ""
  const title = isPlanning ? `[Planning] ${teeupTitle}` : teeupTitle

  let eventConfig = {
    title,
    startDate: start,
    endDate: end,
    location,
  }

  if (existingEvent) {
    if (
      existingEvent.title === eventConfig.title &&
      getDateWithTimezone(eventConfig.startDate).isSame(
        getDateWithTimezone(existingEvent.startDate)
      ) &&
      getDateWithTimezone(eventConfig.endDate).isSame(
        getDateWithTimezone(existingEvent.endDate)
      ) &&
      existingEvent.location === eventConfig.location
    ) {
      // Events are equal
      return existingEvent.id
    }

    eventConfig.id = existingEvent.id
  }
  if (selectedCalendar) {
    eventConfig.calendarId = selectedCalendar
  }

  return saveEvent(eventConfig)
}

// Returns whether there's a conflict with another event
// export const isEventConflict = startDate => { }
// export const isEventConflict = () => {}

// export const saveEvent = (title, startDate, endDate, location, existingId) => {
export const saveEvent = ({
  calendarId,
  title,
  startDate,
  endDate,
  location,
  id,
}) => {
  // /** The start date of the calendar event in ISO format */
  // startDate: ISODateString;
  // /** The end date of the calendar event in ISO format. */
  // endDate: ISODateString;
  // /** Unique id for the calendar where the event will be saved. Defaults to the device's default  calendar. */
  // calendarId?: string;
  // /** Indicates whether the event is an all-day event. */
  // allDay?: boolean;
  // /** The simple recurrence frequency of the calendar event. */
  // recurrence?: RecurrenceFrequency;
  // /** The location associated with the calendar event. */
  // location?: string;
  // /** iOS ONLY - Indicates whether an event is a detached instance of a repeating event. */
  // isDetached?: boolean;
  // /** iOS ONLY - The url associated with the calendar event. */
  // url?: string;
  // /** iOS ONLY - The notes associated with the calendar event. */
  // notes?: string;
  // /** ANDROID ONLY - The description associated with the calendar event. */
  // description?: string;
  // id?: string;
  //   /** The event's recurrence settings */
  //   recurrenceRule?: RecurrenceRule;
  //   /** The alarms associated with the calendar event, as an array of alarm objects. */
  //   alarms?: Array<Alarm<ISODateString | number>>;

  let details = {
    startDate,
    endDate,
    location,
  }

  if (id) {
    details.id = id
  }
  if (calendarId) {
    details.calendarId = calendarId
  }

  let options = {}

  // return RNCalendarEvents.saveEvent(title, details, options)
  //     .then((id) => {
  //         return id
  //     })
  //     .catch((error) => {
  //         console.log('RNCalendarEvents.saveEvent error: ', error)
  //         return null
  //     })
}
