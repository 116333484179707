import React from "react"
import "./index.scss"

const ExpandedPeopleStatusBlock = ({ renderStatus }) => {
  return (
    <div className="status__block--container">
      <p className="status__block--title">What's your status</p>
      {renderStatus()}
    </div>
  )
}

export default ExpandedPeopleStatusBlock
