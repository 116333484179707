import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { NavLink } from "react-router-dom"
import cn from "classnames"
import { TEEUP_LIST_MODES } from "@utils/teeupUtils"
import { SCREENS } from "@utils/calendarUtils"
import ReactTooltip from "react-tooltip"

import CreateTeeupButton from "./CreateTeeupButton"
import SideBarMenuItem from "./SideBarMenuItem"

import "./index.scss"

import { images } from "@utils/imageUtils"
import {
  setNotificationTeeups,
  toggleHelpPhonePopup,
} from "@actions/commonActions"
import { setTeeupActive } from "@actions/activeTeeupActions"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { initialTeeup } from "@config/enums"
import { handleSetTeeupsMode } from "../../actions/commonActions"

const SideBar = ({
  userInfo,
  notifications,
  setNotificationTeeups,
  activeTeeup,
  toggleHelpPhonePopup,
  showHelpPopup,
  teeupsMode,
  handleSetTeeupsModefn,
}) => {
  const [focusCounter, setFocusCounter] = useState(1)
  const [isSideBarOpen, setIsSideBarOpen] = useState(true)
  const [openNumber, setOpenNumber] = useState(0)
  const infoRef = useRef()

  const handleClickMenuButton = (teeupsMode) => {
    handleSetTeeupsModefn(teeupsMode)
  }

  return (
    <div className="sidebar">
      <NavLink
        className="sidebar__logo__wrapper"
        to="/teeUps"
        onClick={() => {
          setTeeupActive(initialTeeup)
        }}
      >
        <img src={images.ndLogo} className="sidebar__logo" alt="Logo" />
      </NavLink>

      <div
        className="sidebar__items"
        style={{
          height: openNumber === 4 ? "50%" : "80%",
        }}
      >
        <CreateTeeupButton
          isSideBarOpen={isSideBarOpen}
          setFocusCounter={setFocusCounter}
          focusCounter={focusCounter}
        />
        <SideBarMenuItem
          itemId={1}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            handleClickMenuButton(TEEUP_LIST_MODES.ACTIVE)
            setFocusCounter(1)
          }}
          image={images.ndInbox}
          imageActive={images.ndInboxActive}
          text="Inbox"
          path="/teeUps"
        />
        <SideBarMenuItem
          itemId={2}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            handleClickMenuButton(TEEUP_LIST_MODES.ARCHIVED)
            setFocusCounter(2)
          }}
          image={images.ndArchive}
          imageActive={images.ndArchiveActive}
          text="Archive"
          path="/teeUps"
        />
        <SideBarMenuItem
          itemId={3}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            handleClickMenuButton(TEEUP_LIST_MODES.SKIPPED)
            setFocusCounter(3)
          }}
          image={images.ndTrash}
          imageActive={images.ndTrashActive}
          text="Trash"
          path="/teeUps"
        />
        <div className="sidebar-item__line"></div>
        <SideBarMenuItem
          itemId={4}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            handleClickMenuButton(TEEUP_LIST_MODES.COMING_UP)
            setFocusCounter(4)
          }}
          image={images.ndComingUp}
          imageActive={images.ndComingUpActive}
          text="Coming Up"
          path="/teeUps"
        />
        <SideBarMenuItem
          itemId={5}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            setFocusCounter(5)
          }}
          image={images.ndCalendar}
          imageActive={images.ndCalendarActive}
          text="Calendar"
          path="/calendar"
        />
        <div className="sidebar-item__line"></div>
        <SideBarMenuItem
          itemId={6}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            setFocusCounter(6)
          }}
          image={images.ndContacts}
          imageActive={images.ndContactsActive}
          text="Contacts"
          path="/contacts"
        />
      </div>

      <div className="sidebar__bottom">
        {/* TODO uncomment this once it is ready and approved by who ever has to */}
        {/* <SideBarMenuItem
          itemId={10}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            toggleHelpPhonePopup(true)
            setFocusCounter(10)
          }}
          image={images.ndMeetWithMe}
          imageActive={images.ndHelpActive}
          text="Meet with Me"
          isNotLink={true}
        /> */}
        <SideBarMenuItem
          itemId={11}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            toggleHelpPhonePopup(true)
            setFocusCounter(11)
          }}
          image={images.ndSettings}
          imageActive={images.ndHelpActive}
          text="Settings"
          path="/settings"
        />
        <SideBarMenuItem
          itemId={12}
          isSideBarOpen={isSideBarOpen}
          focusCounter={focusCounter}
          onPress={() => {
            setFocusCounter(12)
          }}
          image={images.ndHelp}
          imageActive={images.ndHelpActive}
          text="Help"
          isNotLink={true}
        />

        <div className="sidebar-item__line"></div>

        <NavLink
          to="/my-profile"
          className={cn("sidebar-item", {
            "sidebar-item-focus": focusCounter === 13,
          })}
          onClick={() => setFocusCounter(13)}
        >
          <div
            className="sidebar-item__image"
            alt="Settings"
            style={{
              backgroundImage: `url(${
                userInfo?.avatar || userInfo.user?.avatar
              })`,
            }}
          ></div>
          <h4
            className={cn("sidebar-item__title", {
              "sidebar-item__title-focus": focusCounter === 13,
            })}
          >
            My Profile
          </h4>
        </NavLink>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  activeTeeup: selectActiveTeeupId(state),
  showHelpPopup: state.common.showHelpPopup,
  teeupsMode: state.common.teeupsMode,
})

const mapDispatchToProps = (dispatch) => ({
  setTeeupActive: bindActionCreators(setTeeupActive, dispatch),
  setNotificationTeeups: bindActionCreators(setNotificationTeeups, dispatch),
  toggleHelpPhonePopup: bindActionCreators(toggleHelpPhonePopup, dispatch),
  handleSetTeeupsModefn: bindActionCreators(handleSetTeeupsMode, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
