import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { useTeeUpPage } from "../../../TeeUpPageContext"

import TopBarPeople from "../TopBarCloseButton"
import TopBarManage from "../TopBarManage"

import "./index.scss"

const TopBar = () => {
  const { interactionArea, openPeopleArea } = useTeeUpPage()

  return (
    <div className="top-bar">
      <TopBarPeople />
      {!interactionArea && !openPeopleArea && <TopBarManage />}
    </div>
  )
}

export default TopBar