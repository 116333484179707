import React from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"
import { connect } from "react-redux"
import ViewWithHeader from "@ui/viewWithHeader"
import { GAMEPLAN_OPTIONS } from "@utils/gamePlanUtils"
import { SCREENS } from "@utils/calendarUtils"

import TabView from "@ui/tabViewGameplan"

import DraggableSuggestionRow from "../gamePlansCard/draggableSuggestionRow"
import CalendarCard from "@components/calendarCard"

import { GameplanStyles } from "./createTeeupStyles"
import RecommendationList from "../gamePlansCard/RecommendationList"
import {
  handleCloseSuggestion,
  showSuggestionListFooter,
  setSideBarContent,
} from "@actions/commonActions"

import { selectBeforeCalendarDate } from "../../selectors/calendar"

import "./index.scss"

const KeyboardAvoidingScrollView = ({ isWhere, children }) => {
  if (!isWhere)
    return (
      <View style={[GameplanStyles.flex, GameplanStyles.mainContainer]}>
        {children}
      </View>
    )

  return (
    <View
      style={GameplanStyles.flex}
      contentContainerStyle={GameplanStyles.whereContent}
    >
      {children}
    </View>
  )
}

KeyboardAvoidingScrollView.propTypes = {
  isWhere: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

const GameplanView = (props) => {
  let {
    title,
    renderedFields,
    type,
    isTemporalCustom,
    suggestion,
    routes,
    onIndexChange,
    isTimeZoneEnabled,
    recommendations,
    onSubmit,
    canSubmit,
    renderScene,
    isNew,
    componentId,
    contentHeight,
    isCalendarShown,
    onCloseCalendarCard,
    startDate,
    passedEvent,
    onDatesSelected,
    duration,
    sceneIndex,
    tabViewRef,
    isFromCreateTeeup,
    content,
    isModalOpened,
    closeSuggestions,
    layout,
    handleCloseSuggestion,
    showSuggestionListFooter,
    setSideBarContent,
    isBeforeCalendarDate,
  } = props
  const isWhen = type === GAMEPLAN_OPTIONS.when
  const isWhere = type === GAMEPLAN_OPTIONS.where

  let renderedContent = (
    <View style={[GameplanStyles.containerWhat]}>
      <View style={GameplanStyles.fieldsContainer}>{renderedFields}</View>
    </View>
  )
  let suggestionRow = (
    <View style={GameplanStyles.suggestionContainer}>
      <DraggableSuggestionRow
        draggable={false}
        showHints
        type={type}
        isTemporalCustom={isTemporalCustom}
        suggestion={suggestion}
        name={"Your"}
        suggestedItem
        createSuggestion
        previewMode
        isNew={isNew}
        isTimeZoneEnabled={isTimeZoneEnabled}
        isBeforeCalendarDate={isBeforeCalendarDate}
      />
    </View>
  )

  if (isWhen) {
    renderedContent = (
      <TabView
        ref={tabViewRef}
        routes={routes}
        renderScene={renderScene}
        onTabIndexChange={onIndexChange}
        initialTabIndex={sceneIndex}
        isTemporalCustom={isTemporalCustom}
        // titles={["Specific Date?"]}
        scrollEnabled
        customTabbarStyle={GameplanStyles.tabbarStyle}
        customTabbarWrapperStyle={GameplanStyles.tabbarStyle}
      />
    )
  }

  if (isWhere) {
    renderedContent = (
      <>
        <TabView
          ref={tabViewRef}
          routes={routes}
          renderScene={renderScene}
          onTabIndexChange={onIndexChange}
          initialTabIndex={sceneIndex}
          scrollEnabled
          customTabbarStyle={GameplanStyles.tabbarStyle}
          customTabbarWrapperStyle={GameplanStyles.tabbarStyle}
        />
        {recommendations && recommendations.length > 0 && (
          <View style={GameplanStyles.recommendationsContainer}>
            <Text style={GameplanStyles.recommendationsTitle}>
              Recommended for Where
            </Text>
            <RecommendationList recommendations={recommendations} />
          </View>
        )}
      </>
    )
  }

  return (
    <>
      {isModalOpened && (
        <div
          className="settings__modal settings__modal-content"
          style={{ animation: "none", width: "100%" }}
        >
          {content}
        </div>
      )}
      <ViewWithHeader
        headerStyle={GameplanStyles.header}
        title={`Suggest ${title}`}
        isFromCreateTeeup={isFromCreateTeeup}
        isTemporalCustom={isTemporalCustom}
        containerStyle={{
          flex: 0,
          height: "100%",
          minHeight: "300px",
          maxHeight: isModalOpened ? "50vh" : "75vh",
          overflow: "hidden",
        }}
        onCancel={closeSuggestions}
        isWhen
      >
        <KeyboardAvoidingScrollView isWhere={isWhere}>
          <View style={GameplanStyles.flex}>
            {suggestionRow}
            {renderedContent}
          </View>

          <div
            className="footer-buttons__wrapper"
            style={{ marginTop: isWhere ? "83%" : 0 }}
          >
            <button
              className={
                !canSubmit
                  ? "footer-buttons__place footer-button__place-disabled"
                  : "footer-buttons__place"
              }
              disabled={!canSubmit}
              onClick={() => {
                onSubmit(true)
                handleCloseSuggestion()
                showSuggestionListFooter()
                setSideBarContent([])
              }}
            >
              {isTemporalCustom ? "Make the Plan" : "Place on Game Plan"}
            </button>
            <button
              className={
                !canSubmit
                  ? "footer-button__disabled"
                  : "footer-buttons__suggest"
              }
              disabled={!canSubmit}
              onClick={() => {
                onSubmit(false)
                handleCloseSuggestion()
                showSuggestionListFooter()
                setSideBarContent([])
              }}
            >
              Suggest
            </button>
          </div>

          {sceneIndex === 0 && layout && (
            <div
              className={`${
                isFromCreateTeeup ? `addContact__fromCreateTeeup` : `addContact`
              }`}
            >
              <div
                className={`${
                  isFromCreateTeeup
                    ? `addContact__content__fromCreateTeeup`
                    : `addContact__content`
                }`}
                style={{ paddingBottom: 15 }}
              >
                {layout}
              </div>
            </div>
          )}
        </KeyboardAvoidingScrollView>
      </ViewWithHeader>
      {contentHeight && isWhen && (
        <CalendarCard
          newSuggestionProps={{
            duration,
            passedEvent,
            selectedDate: startDate,
            onDatesSelected,
            isFromCreateTeeup,
          }}
          contentHeight={contentHeight}
          parentComponentId={componentId}
          isCalendarShown={isCalendarShown}
          onCloseCalendarCard={onCloseCalendarCard}
          activeScreen={SCREENS.day}
        />
      )}
    </>
  )
}

GameplanView.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  onPress: PropTypes.func,
  renderedFields: PropTypes.array,
  type: PropTypes.string,
  duration: PropTypes.number,
  suggestion: PropTypes.object,
  routes: PropTypes.array,
  onIndexChange: PropTypes.func,
  onDatesSelected: PropTypes.func.isRequired,
  isTimeZoneEnabled: PropTypes.bool,
  isCalendarShown: PropTypes.bool,
  recommendations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  componentId: PropTypes.string,
  sceneMapping: PropTypes.object,
  passedEvent: PropTypes.object,
  renderScene: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startDateIndex: PropTypes.number,
  endDateIndex: PropTypes.number,
  sceneIndex: PropTypes.number,
  contentHeight: PropTypes.number,
  onCalendar: PropTypes.func,
  onCloseCalendarCard: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  // canSubmit: PropTypes.bool.isRequired,
  isCustomDate: PropTypes.bool,
  allowPutOnGameplan: PropTypes.bool,
  onToggleAddingToGameplan: PropTypes.func,
  onScreenPress: PropTypes.func,
  onLayout: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  isNew: PropTypes.bool,
  isFromCreateTeeup: PropTypes.bool,
  tabViewRef: PropTypes.func,
  isBeforeCalendarDate: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    isBeforeCalendarDate: selectBeforeCalendarDate(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleCloseSuggestion: () => dispatch(handleCloseSuggestion()),
    showSuggestionListFooter: () => dispatch(showSuggestionListFooter()),
    setSideBarContent: () => dispatch(setSideBarContent()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameplanView)
