import React from "react"

import "./modal.scss"

const Modal = ({ children }) => {
  return (
    <div className="modal-wrapper">
      {/* <div className="modal-backdrop"> */}
        <div className="modal">{children}</div>
      {/* </div> */}
    </div>
  )
}

export default Modal
