import React, { Component } from "react"
import { Text } from "react-native"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { selectTeeupSuggestions, selectTeeupReactions } from "@selectors/teeups"
import ParticipantLikesTabView from "./ParticipantLikesTabView"
import { ViewStyles } from "./ParticipantDetailsStyles"
import DraggableSuggestionRow from "../../../../gamePlansCard/draggableSuggestionRow"
import EmptyTab from "./EmptyTab"
import { reactionTypes } from "@configs/mappings"

const sectionTitles = {
  [reactionTypes.upvote]: "Likes",
  [reactionTypes.downvote]: "Dislikes",
  [reactionTypes.notVote]: `Can't Do`,
}

const formatLikeSections = (teeupSuggestions, userId, reactions) => {
  let sections = {}

  for (let reaction in reactions) {
    Array.isArray(reactions[reaction]) &&
      reactions[reaction] &&
      reactions[reaction].map((teeupReaction) => {
        teeupReaction?.reactedBy &&
          teeupReaction.reactedBy.forEach((reactionBy) => {
            if (reactionBy === userId) {
              let reactionItem = {
                suggestion: teeupSuggestions[reaction],
                reactions: reactions[reaction],
              }
              const reactionId = teeupReaction.reactionId
              if (!(reactionId in sections)) {
                sections[reactionId] = {
                  title: sectionTitles[reactionId],
                  data: [],
                }
              }
              sections[reactionId].data.push(reactionItem)
            }
          })
      })
  }

  return Object.values(sections)
}

class ParticipantLikesTabContainer extends Component {
  constructor(props) {
    super(props)

    const { teeupSuggestions, userId, reactions } = props
    this.state = {
      sections: formatLikeSections(teeupSuggestions, userId, reactions),
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let updates = null
    if (
      nextProps.teeupSuggestions !== state.teeupSuggestions ||
      nextProps.reactions !== state.reactions
    ) {
      let { teeupSuggestions, userId, reactions } = nextProps

      updates = {
        sections: formatLikeSections(teeupSuggestions, userId, reactions),
      }
    }
    return updates
  }

  renderItem = ({ item }) => {
    const { userId, userName, onLongPressSuggestion } = this.props
    // const isNew = newSuggestions[item.suggestion.id]?.isNew

    return (
      <DraggableSuggestionRow
        draggable={false}
        type={item.type}
        suggestion={item.suggestion}
        name={userName}
        customStyle={ViewStyles.customSuggestionStyles}
        customReactionId={userId}
        handleOnLongPress={onLongPressSuggestion}
      />
    )
  }

  renderHeader = ({ section }) => {
    if (section.data.length !== 0) {
      return <Text style={ViewStyles.listHeader}>{section.title}</Text>
    }
    return null
  }

  render() {
    const { sections } = this.state

    if (sections.length === 0) {
      return <EmptyTab text={"No suggestions liked"} />
    }

    return (
      <ParticipantLikesTabView
        renderItem={this.renderItem}
        renderHeader={this.renderHeader}
        sections={sections}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const teeupSuggestions = selectTeeupSuggestions(state)
  const reactions = selectTeeupReactions(state)
  return {
    teeupSuggestions,
    reactions,
  }
}

export default connect(mapStateToProps)(ParticipantLikesTabContainer)

ParticipantLikesTabContainer.propTypes = {
  userName: PropTypes.string.isRequired,
  teeupSuggestions: PropTypes.object.isRequired,
  reactions: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  newSuggestions: PropTypes.object,
  onLongPressSuggestion: PropTypes.func.isRequired,
}
