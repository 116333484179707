import React, { useState, useEffect } from "react"
import { images } from "@utils/imageUtils"
import endpoints from "@config/endpoints"
import { verifyPassword } from "@actions/userActions"

const CheckCurrentPassword = ({ setStep, setAccessToken }) => {
  const [currentPassword, setCurrentPassword] = useState("")
  const [error, setError] = useState("")

  const handleVerifyPassword = (e) => {
    e.preventDefault()
    verifyPassword(currentPassword)
      .then((response) => {
        const { accessToken } = response.data
        setAccessToken(accessToken)
        setStep(2)
        setError("")
      })
      .catch((error) => setError(error.message))
  }

  return (
    <>
      <img className="change__password--logo" src={images.cooeModal} />
      <p className="change__password--title">Change Password</p>
      <p className="change__password--text">
        To change password please enter your current password
      </p>

      <form>
        <input
          style={{ border: error && "solid 2px #ff0000" }}
          className="change__password--input"
          type="password"
          placeholder="Enter Current Password"
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        {error && (
          <span className="change__password--error">
            The password is invalid
          </span>
        )}

        <button
          style={{ backgroundColor: currentPassword && "#f53263" }}
          className="change__password--button"
          disabled={!currentPassword && true}
          onClick={(e) => handleVerifyPassword(e)}
        >
          Continue
        </button>
      </form>
    </>
  )
}

export default CheckCurrentPassword
