import React from "react"
import { TouchableOpacity, Image } from "react-native"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { selectUserInfo } from "@selectors/user"
import { logOut } from "@actions/loginActions"
// import { dismissModal } from '@nav'
import { loginProviders } from "@configs/enums"
import closeIcon from "../../../assets/images/close.svg"
import { useAuth0 } from "@auth0/auth0-react"
import LoginDetailsView from "./loginDetailsView"
import { confirmAlert } from "react-confirm-alert"
import { images } from "@utils/imageUtils"
import { SectionListStyles } from "../../gamePlansCard/suggestionsList/suggestionListStyles"

const LoginDetailsContainer = (props) => {
  const { logout } = useAuth0()

  const onLogout = () => {
    const { registrationType, loginType } = props.userInfo
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            class="react-confirm-alert-body"
            style={{ transform: "translateY(-50%)" }}
          >
            <img
              src={images.closeSettings}
              className="settings__modal__closeIcon"
              alt="close"
              onClick={onClose}
            />
            <h1>Logout</h1>
            Are you sure you want to logout
            <div class="react-confirm-alert-button-group">
              <button onClick={onClose}>Cancel</button>
              <button
                onClick={async () => {
                  if (
                    (registrationType &&
                      registrationType !== loginProviders.email) ||
                    (loginType && loginType !== loginProviders.email)
                  ) {
                    await logout({ returnTo: window.location.origin })
                  }
                  await props.logOut()
                  onClose()
                }}
              >
                Logout
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const getSignInMethodTitle = (loginType) => {
    switch (loginType) {
      case loginProviders.fb: {
        return "Facebook"
      }
      case loginProviders.google: {
        return "Google"
      }
      case loginProviders.twitter: {
        return "Twitter"
      }
      case loginProviders.email: {
        return "Email"
      }
      default: {
        return "Email"
      }
    }
  }

  const {
    userInfo: {
      email,
      loginType,
      twitterUsername,
      facebookUsername,
      identities,
    },
    closeOption,
  } = props

  const hideChangePassword = loginType && loginType !== loginProviders.email
  const syncedTwitter =
    loginType === loginProviders.twitter ? `@${twitterUsername}` : null
  const syncedGmail = loginType === loginProviders.google ? email : null
  const syncedFacebook =
    loginType === loginProviders.fb ? `@${facebookUsername}` : null
  const signInMethodTitle = getSignInMethodTitle(loginType)
  const signInMethod =
    loginType === loginProviders.twitter
      ? `@${twitterUsername}`
      : loginType === loginProviders.fb
      ? `@${facebookUsername}`
      : email

  let signInMethodNew = ""
  identities && identities.map((item) =>
    item.type === "email"
      ? (signInMethodNew = item.title)
      : (signInMethodNew = "")
  )

  return (
    <>
      {/* <div className="settings__top">
                    <TouchableOpacity
                        onPress={closeOption}
                    >
                        <Image
                        source={images.back}
                        style={SectionListStyles.cancelBtnImage}
                        />
                    </TouchableOpacity>
                    <span>Login Info</span>
                    <div></div>
                    <img src={closeIcon} alt="close" onClick={closeOption} />
                </div> */}
      <LoginDetailsView
        hideChangePassword={hideChangePassword}
        signInMethodTitle={signInMethodTitle}
        signInMethod={signInMethod}
        onLogout={onLogout}
        syncedTwitter={syncedTwitter}
        syncedGmail={syncedGmail}
        syncedFacebook={syncedFacebook}
        signInMethodNew={signInMethodNew}
      />
    </>
  )
}
LoginDetailsContainer.propTypes = {
  componentId: PropTypes.string,
  userInfo: PropTypes.object,
}

const mapStateToProps = (state) => {
  const userInfo = selectUserInfo(state)

  return {
    userInfo,
  }
}

export default connect(mapStateToProps, { logOut })(LoginDetailsContainer)
