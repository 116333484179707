import actionTypes from "@actions/actionTypes"
import { dateComparator, getDateWithTimezone } from "@utils/dateUtils"
// import { gameplanTypes } from '@configs/mappings'
import {
  EVENT_TYPES,
  putTeeupOnCalendarWithoutDuplicates,
  detectTeeupCalendarDay,
} from "@utils/calendarUtils"
import CalendarState from "./calendarStorage"

export const initialState = {
  updaterValue: Date.now(),
  userEmail: "",
}

// Event structure (mandatory fields)
// id
// startDate
// endDate
// type: EVENT_TYPES.calendar
// name

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GOT_CALENDAR: {
      const { events, userEmail } = action.payload
      let calendarDays = [...CalendarState.calendarDays]
      let calendarEventsByDay = {}
      let calendarEvents = {}
      events.forEach((calendarEvent) => {
        const beginningOfTheDay = getDateWithTimezone(calendarEvent.startDate)
          .startOf("day")
          .format()
        const { id } = calendarEvent
        if (!(beginningOfTheDay in calendarEventsByDay)) {
          calendarEventsByDay[beginningOfTheDay] = []
          calendarDays.push(beginningOfTheDay)
        }
        calendarEventsByDay[beginningOfTheDay].push(id)
        calendarEvents[id] = {
          ...calendarEvent,
          type: EVENT_TYPES.calendar,
        }
      })
      calendarDays = [...new Set(calendarDays)]
      calendarDays = calendarDays.sort(dateComparator)

      CalendarState.calendarEventsByDay = calendarEventsByDay
      CalendarState.calendarEvents = calendarEvents
      CalendarState.calendarDays = calendarDays

      return { ...state, userEmail, updaterValue: Date.now() }
    }

    case actionTypes.SET_TEEUPS: {
      let teeupEvents = {}
      let calendarDays = [...CalendarState.calendarDays]
      let { teeups } = action.payload
      let teeupEventsByDay = {
        ...CalendarState.teeupEventsByDay,
      }

      teeups.forEach((teeup) => {
        teeupEvents[teeup.id] = {
          ...(!!CalendarState.teeupEvents[teeup.id] &&
            CalendarState.teeupEvents[teeup.id]),
          id: teeup.id,
          name: teeup.name,
          events: teeup.events || [],
          type: EVENT_TYPES.teeup,
        }
        if (teeupEvents[teeup.id]) {
          const { teeupEvent, beginningOfTheDay, isExactDate } =
            detectTeeupCalendarDay({
              teeupEvent: teeupEvents[teeup.id],
              teeup,
            })

          if (!(beginningOfTheDay in teeupEventsByDay) && isExactDate) {
            teeupEventsByDay[beginningOfTheDay] = []
            calendarDays.push(beginningOfTheDay)
          }

          teeupEventsByDay = putTeeupOnCalendarWithoutDuplicates({
            teeupEventsByDay,
            teeup,
            beginningOfTheDay,
          })
          teeupEvents[teeup.id] = {
            ...teeupEvents[teeup.id],
            ...teeupEvent,
          }
        }
      })

      calendarDays = [...new Set(calendarDays)]
      calendarDays = calendarDays.sort(dateComparator)

      CalendarState.teeupEvents = teeupEvents
      CalendarState.teeupEventsByDay = teeupEventsByDay
      CalendarState.calendarDays = calendarDays

      return { ...state, updaterValue: Date.now() }
    }

    case actionTypes.CALENDAR_ADD_TEEUP_EVENTS: {
      const newlySyncedTeeups = action.payload
      let teeupEvents = { ...CalendarState.teeupEvents }
      let calendarDays = [...CalendarState.calendarDays]
      let teeupEventsByDay = {
        ...CalendarState.teeupEventsByDay,
      }

      const teeupEventsIds = Object.keys(newlySyncedTeeups)

      teeupEventsIds.forEach((teeupId) => {
        const events = newlySyncedTeeups[teeupId].events
        teeupEvents[teeupId] = {
          ...(!!CalendarState.teeupEvents[teeupId] &&
            CalendarState.teeupEvents[teeupId]),
          id: teeupId,
          name: newlySyncedTeeups[teeupId].name,
          type: EVENT_TYPES.teeup,
          events,
        }

        const { teeupEvent, beginningOfTheDay, isExactDate } =
          detectTeeupCalendarDay({
            teeupEvent: teeupEvents[teeupId],
            teeup: newlySyncedTeeups[teeupId],
          })

        if (!(beginningOfTheDay in teeupEventsByDay) && isExactDate) {
          teeupEventsByDay[beginningOfTheDay] = []
          calendarDays.push(beginningOfTheDay)
        }
        teeupEventsByDay = putTeeupOnCalendarWithoutDuplicates({
          teeupEventsByDay,
          teeup: teeupEvents[teeupId],
          beginningOfTheDay,
        })

        teeupEvents[teeupId] = {
          ...teeupEvents[teeupId],
          ...teeupEvent,
        }
      })

      calendarDays = [...new Set(calendarDays)]
      calendarDays = calendarDays.sort(dateComparator)
      CalendarState.teeupEvents = teeupEvents
      CalendarState.teeupEventsByDay = teeupEventsByDay
      CalendarState.calendarDays = calendarDays

      return { ...state, updaterValue: Date.now() }
    }

    case actionTypes.GOT_AVALIABLE_CALENDARS: {
      const calendars = action.payload
      CalendarState.avaliableCalendars = calendars
      return { ...state, updaterValue: Date.now() }
    }

    case actionTypes.SET_CALENDAR_PERMISSIONS: {
      const permissions = action.payload
      CalendarState.calendarPermissions = permissions
      return { ...state, updaterValue: Date.now() }
    }
    case actionTypes.RESET_CALENDAR_STATE: {
      CalendarState.calendarEventsByDay = {}
      CalendarState.calendarEvents = {}
      return initialState
    }
    case actionTypes.SET_DISPLAYED_CALENDAR: {
      if (action.payload) {
        CalendarState.calendarEventsByDay = {}
        CalendarState.calendarEvents = {}
      }
      return {
        ...state,
        isDisplayedCalendar: action.payload
      }
    }
    case actionTypes.GET_SUGGESTION_WEEK:
      return {
        ...state,
        suggestWeek: action.payload,
      }
    case actionTypes.SET_BEFORE_CALENDAR_DATE:
      return {
        ...state,
        isBeforeCalendarDate: action.payload
      }
    default:
      return state
  }
}
