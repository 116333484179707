import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

const styles = StyleSheet.create({
  contactContainer: {
    width: Units.responsiveValue(56),
    marginRight: Units.responsiveValue(16),
  },
  contactCircle: {
    height: Units.rem(3.5),
    width: Units.rem(3.5),
    marginBottom: Units.rem(0.0625),
    borderRadius: Units.rem(3.5) / 2,
    marginTop: Units.rem(0.3),
  },
  contactPhoto: {
    resizeMode: "cover",
  },
  contactDefault: {
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.brand.grey2,
  },
  contactDefaultText: {
    fontSize: AppFonts.sizes.bigger,
    fontFamily: "greycliffcf-medium-salt",
    color: AppColors.brand.darkGrey2,
  },
  contactInfo: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: Units.rem(1.6875),
  },
  contactName: {
    fontSize: AppFonts.sizes.small,
    fontFamily: "greycliffcf-medium-salt",
    color: AppColors.brand.black2,
    letterSpacing: Units.rem(-0.0125),
  },
  contactLabel: {
    fontSize: Units.responsiveValue(11),
    fontFamily: AppFonts.family.medium,
    color: AppColors.brand.warmGrey2,
    letterSpacing: Units.rem(-0.0125),
  },
  iconWrapper: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    flex: 1,
  },
  icon: {
    height: 10,
    width: 14,
    position: "relative",
  },
  iconPhone: {
    height: 14,
  },
  contactLabelIconWrapper: {
    width: Units.rem(1.3125),
    height: Units.rem(1.3125),
    borderRadius: Units.rem(1.3125),
    backgroundColor: AppColors.brand.white,
    top: Units.rem(3.5 - 1.3125),
    right: Units.rem(0),
  },
  removeIconWrapper: {
    width: Units.rem(1.5),
    height: Units.rem(1.5),
    borderRadius: Units.rem(1.5),
    backgroundColor: AppColors.brand.black2,
    borderWidth: 2,
    borderColor: AppColors.brand.white,
    right: -Units.rem(0.3),
  },
  removeInviteeButton: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
})

const icons = {
  phone: {
    source: require("../../../assets/images/phoneCopy.png"),
  },
  email: {
    source: require("../../../assets/images/envelope3x.png"),
  },
}
export { styles, icons }
