import { StyleSheet } from "react-native"
import { AppFonts, AppColors, Units } from "@theme"

export const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatarStyle: {
    marginRight: Units.responsiveValue(6),
    flexDirection: "row",
    alignSelf: "baseline",
  },
  headerTitleText: {
    // ...AppFonts.smallDemibold,
    // lineHeight: Units.responsiveValue(16),
    // color: AppColors.brand.white,
  },
  InvitationUserName: {
    lineHeight: "20px",
    fontSize: "17px",
    fontWeight: "900px",
    color: AppColors.brand.white,
    letterSpacing: "-0.34px",
  },

  headerInvitationText: {
    fontSize: "15px",
    fontWeight: "600px",
    color: AppColors.brand.white,
    letterSpacing: "-0.34px",
  },
  contentBetween: {
    justifyContent: "space-between",
  },
  headerContent: {
    width: "100%",
  },
})

export const IconProps = {
  email: {
    type: "material-icons",
    name: "email",
    size: Units.responsiveValue(13),
    color: AppColors.brand.pink,
  },
}

export const headerContainer = (
  fromNowString,
  isArchivePromptShow,
  isEnded
) => ({
  width: " 100%",
  height: "100%",
  position: "absolute",
  zIndex: 10,
  flexDirection: "row",
  alignItems: "flex-start",
  paddingVertical: 12,
  paddingHorizontal: 12,
  // justifyContent: "space-between",

  // backgroundColor: isEnded ? "white" : "AppColors.brand.grey243",
  borderRadius: Units.responsiveValue(12),
  ...(!fromNowString &&
    !isArchivePromptShow && {
      // backgroundColor: AppColors.brand.grey243,
      backgroundColor: AppColors.brand.cardBackground,
    }),
})

export const invitationRow = (additionalPadding, directionColumn) => ({
  // ...(additionalPadding ? { paddingLeft: Units.responsiveValue(10) } : { marginLeft: Units.responsiveValue(40) }),
  flexDirection: directionColumn ? "column" : "row",
  alignItems: "center",
  marginLeft: 8,
})
