import React from "react"
import Props from "prop-types"
import { View, Text, StyleSheet } from "react-native"

import { AppFonts } from "@theme"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    ...AppFonts.header20,
    marginBottom: 5,
    textAlign: "center",
  },
  message: {
    ...AppFonts.medium,
    textAlign: "center",
  },
})

const ErrorScreen = ({ message = "" }) => (
  <View style={styles.container}>
    <Text style={styles.heading}>Something went wrong</Text>
    {!!message && <Text style={styles.message}>{message}</Text>}
  </View>
)

export default ErrorScreen
