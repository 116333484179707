import React from "react"
import ReactionsSection from "./ReactionsSection"

const ReactionsDetails = ({
  usersWhoLikes,
  usersWhoDislikes,
  neutralReactions,
}) => {
  return (
    <div className="reactions__details--container">
      {!!usersWhoLikes.length && (
        <ReactionsSection likes reactedPeople={usersWhoLikes} />
      )}
      {!!usersWhoDislikes.length && (
        <ReactionsSection dislikes reactedPeople={usersWhoDislikes} />
      )}
      {!!neutralReactions.length && (
        <ReactionsSection reactedPeople={neutralReactions} />
      )}
    </div>
  )
}

export default ReactionsDetails
