import { StyleSheet, Platform } from "react-native"
import { AppColors, AppFonts, Units, AppSizes } from "@theme"

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: Platform.OS === "ios" ? Units.rem(2) : 0,
  },
  screen: {
    flex: 1,
  },
  screenContent: {
    flex: 1,
    paddingHorizontal: 24,
    paddingTop: 10,
    paddingBottom: 24,
    backgroundColor: AppColors.brand.white,
  },
  noMove: {
    backgroundColor: AppColors.brand.white,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  screenTitle: {
    ...AppFonts.header22Demibold,
    color: AppColors.brand.black,
    lineHeight: 26,
    marginBottom: 8,
    textAlign: "center",
  },
  screenSubtitle: {
    ...AppFonts.mediumDemibold,
    lineHeight: 18,
    color: AppColors.brand.warmGrey,
    textAlign: "center",
  },
  textBold: {
    fontFamily: AppFonts.family.bold,
    color: AppColors.brand.black,
  },
  form: {
    flex: 1,
    justifyContent: "space-between",
    paddingBottom: 1,
  },
  statusWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  statusImg: {
    width: 230,
    height: 230,
    marginBottom: 20,
  },
  actionWrapper: {
    flex: 0,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: AppColors.brand.black50,
  },
  inputMarginBig: {
    marginTop: 40,
  },
  inputMargin28: {
    marginTop: 28,
  },
  inputMargin24: {
    marginTop: 24,
  },
  inputMargin16: {
    marginTop: 16,
  },
})
