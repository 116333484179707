import React from "react"
import { View, Text } from "react-native"
import PropTypes from "prop-types"
import Avatar from "@ui/avatar"
import I18n from "@i18n"
import { overlayStyles } from "./styles"
import { teeupUserStatusKeys } from "@configs/enums"
import DialogOverlayWrapper from "../dialogOverlayWrapper"
import CloseButton from "../dialogButtons/closeButton"
import {
  CenteredDialogButton,
  AmountButton,
} from "../dialogButtons/dialogButtons"

const BringingView = ({
  userInfo,
  status,
  additionalCount,
  onClose,
  onSubmit,
  onClickMinus,
  onClickPlus,
}) => {
  const renderHeader = () => {
    return (
      <View style={overlayStyles.titleContainer}>
        <Avatar imageUrl={userInfo.avatar} size={60} />
        <Text style={overlayStyles.subtitle}>
          {status === teeupUserStatusKeys.going && "I'M GOING AND BRINGING"}
          {status === teeupUserStatusKeys.mightgo && "I MIGHT GO AND BRING"}
          {status === teeupUserStatusKeys.onmyway && "I'M ON MY WAY WITH"}
          {status === teeupUserStatusKeys.arrived && "I'M HERE WITH"}
          {status === teeupUserStatusKeys.runningLate &&
            "I'M RUNNING LATE WITH"}
        </Text>
        <CloseButton onClose={onClose} />
      </View>
    )
  }

  return (
    <>
      {renderHeader()}
      <View style={overlayStyles.bringingContainer}>
        <AmountButton
          containerStyles={overlayStyles.amountButtonContainer}
          number={additionalCount}
          onClickDecrease={onClickMinus}
          onClickIncrease={onClickPlus}
        />
        <Text style={overlayStyles.extraText}>OTHERS WITH ME</Text>
        <CenteredDialogButton
          title={I18n.systemButtons.done.toUpperCase()}
          containerStyles={overlayStyles.doneButtonBackground}
          onClick={onSubmit}
          isActive
        />
      </View>
    </>
  )
}

BringingView.propTypes = {
  userInfo: PropTypes.object,
  status: PropTypes.string,
  additionalCount: PropTypes.number,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickMinus: PropTypes.func,
  onClickPlus: PropTypes.func,
}

export default BringingView
