/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"
import PropTypes from "prop-types"
import { View, Text, FlatList } from "react-native"
import * as _ from "lodash"

// import Gradient from '@ui/gradientBackground'
import ViewWithHeader from "@ui/viewWithHeader"
import I18n from "@i18n"

// import { showParticipantDetails } from '@nav'
import styles, { ViewProps } from "./nudgeParticipantStyles"

import ParticipantItem from "./ParticipantItem"

class NudgeSelectParticipantsView extends React.Component {
  state = {
    selectedParticipants: [],
  }

  handleNudge = () => {
    const { onNudge, isMentionPeople, onMention } = this.props
    const { selectedParticipants } = this.state
    if (isMentionPeople) {
      const mentionMessage =
        selectedParticipants
          .map((participant) => `@${participant.username || participant.name}`)
          .join("") + " "
      onMention(mentionMessage)
    } else {
      onNudge(selectedParticipants.map((participant) => participant.id))
    }
  }

  handleToggleAll = () => {
    const { participants } = this.props
    const { selectedParticipants = [] } = this.state

    if (participants.length === selectedParticipants.length) {
      this.setState({ selectedParticipants: [] })
    } else {
      this.setState({ selectedParticipants: participants })
    }
  }

  handleToggleParticipant = (data) => {
    this.setState(({ selectedParticipants }) => {
      const diffParticipants = _.differenceBy(
        selectedParticipants,
        [data],
        "id"
      )
      const isSelected = diffParticipants.length !== selectedParticipants.length

      return {
        selectedParticipants: isSelected
          ? diffParticipants
          : selectedParticipants.concat([{ ...data }]),
      }
    })
  }

  renderListHeader = () => {
    const { participants } = this.props
    const { selectedParticipants = [] } = this.state
    return (
      <ParticipantItem
        selected={participants.length === selectedParticipants.length}
        data={{ isForSelectAll: true }}
        onToggleParticipant={this.handleToggleAll}
        testID={`participant-item-select-all`}
      />
    )
  }

  renderItem = ({ item }) => {
    const { selectedParticipants = [] } = this.state

    const selected = Boolean(
      selectedParticipants.filter(
        (participant) =>
          (participant.userType === item.userType ||
            participant.userType === item.usertype) &&
          participant.id === item.id
      ).length
    )

    return (
      <ParticipantItem
        selected={selected}
        data={item}
        onToggleParticipant={this.handleToggleParticipant}
        testID={`participant-item-${item.id}`}
      />
    )
  }

  linkParticipantDetail = (userId) => () => {
    // showParticipantDetails({ userId })
  }

  keyExtractor = (item) => `${item.id}`

  render() {
    const { participants, onCancel, isMentionPeople } = this.props
    const { selectedParticipants } = this.state
    return (
      <ViewWithHeader
        lightTheme
        style={styles.container}
        headerStyle={styles.header}
        titleContainerStyle={styles.titleContainerStyle}
        titleView={
          <View>
            <Text style={styles.headerTitle} numberOfLines={1}>
              {isMentionPeople
                ? I18n.selectPeopleToMention
                : I18n.selectPeopleToNudge}
            </Text>
            {selectedParticipants.length > 0 ? (
              <Text style={styles.headerSubtitle}>
                {selectedParticipants.length > 1
                  ? `${selectedParticipants.length} people selected`
                  : `${selectedParticipants.length} person selected`}
              </Text>
            ) : null}
          </View>
        }
        onDoneTitle={isMentionPeople ? "Mention" : "Nudge"}
        onDoneDisabled={!selectedParticipants.length}
        onDone={this.handleNudge}
        onClose={onCancel}
      >
        <FlatList
          testID={"nudge-SelectParticipants"}
          data={participants}
          maxToRenderPerBatch={20}
          viewabilityConfig={{
            waitForInteraction: false,
            viewAreaCoveragePercentThreshold: 95,
          }}
          ListHeaderComponent={this.renderListHeader}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          keyExtractor={this.keyExtractor}
          onScrollBeginDrag={this.onScrollBeginDrag}
          onScrollEndDrag={this.onScrollEndDrag}
          onScroll={this.onScroll}
          onScrollToIndexFailed={this.onScrollToIndexFailed}
          getItemLayout={this.getItemLayout}
          keyboardShouldPersistTaps="handled"
        />
        {/* <Gradient
                    style={styles.shadowTop}
                    {...ViewProps.gradientShadow}
                /> */}
      </ViewWithHeader>
    )
  }
}

NudgeSelectParticipantsView.propTypes = {
  participants: PropTypes.array,
  onNudge: PropTypes.func,
  onCancel: PropTypes.func,
  isMentionPeople: PropTypes.bool,
  onMention: PropTypes.func,
}

export default NudgeSelectParticipantsView
