import React, { Component } from "react"
import { Alert, Platform } from "react-native"
import { connect } from "react-redux"
import PropTypes from "prop-types"
// import { Navigation } from 'react-native-navigation'

import * as contactMechanismsActions from "@actions/contactMechanismsActions"
import { getUserContactMechanisms } from "@actions/userActions"
import {
  selectUserInfo,
  selectContactMechanisms,
  selectUserVerifiedCountryCode,
} from "@selectors/user"
// import { routeRetrieveTeeupVerify, routeCountrySelector } from '@nav'
import RetrieveTeeupVerifyContainer from "../../retrieveTeeup/retrieveTeeupVerifyContainer"

// import backend from '@apis/backend'
import {
  contactTypes,
  userContactTypes,
  getCountryCode,
} from "@utils/contactUtils"
import Toast from "@ui/toast"
import { images } from "@utils/imageUtils"
import EditContactScreenView from "./EditContactScreenView"
import arrow from "../../../assets/images/arrow.png"
import { saveButton } from "./EditContactScreenStyles"
import {
  requestVerifyContactMechanism,
  deleteContactMechanism,
} from "@actions/contactMechanismsActions"
import closeIcon from "../../../assets/images/close.svg"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

class EditContactScreenContainer extends Component {
  state = {
    loading: false,
    error: null,
    activateInput: false,
    inputValue: "",
    defaultCountry: "us",
    visibleSave: false,
    modal: null,
  }

  constructor(props) {
    super(props)
  }

  static options() {
    return {
      popGesture: false,
    }
  }

  async componentDidMount() {
    const { verifiedCountryCode } = this.props
    // const cca2 = await getCountryCode()
    const cca2 = null

    this.setState({
      defaultCountry: Platform.select({
        ios: verifiedCountryCode || cca2,
        android: cca2,
      }),
    })
  }

  closeVerify = () => {
    this.setState({ modal: null })
  }

  deleteContact = (contact) => {
    confirmAlert({
      title: "Remove Contact Mechanism",
      message: `Are you sure you want to remove ${contact.value}`,
      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: "Yes",
          onClick: async () => {
            await deleteContactMechanism(contact.id)
          },
        },
      ],
    })
  }

  addContact = () => {
    this.setState({
      activateInput: true,
      visibleSave: true,
    })
  }

  onChangeInputValue = (index) => (input) => {
    this.setState({
      inputValue: input,
    })
  }

  deleteInput = () => {
    this.setState({
      activateInput: false,
      inputValue: "",
    })
  }

  onChooseCountry = () => {
    // routeCountrySelector({
    //     callback: (country) => {
    //         this.phone.selectCountry(country)
    //         this.setState({ inputValue: '' })
    //     },
    // })
  }

  static getDerivedStateFromProps(nextProps, state) {
    let updates = null
    if (nextProps.contacts !== state.contacts) {
      updates = {
        contacts: nextProps.contacts,
      }
    }
    return updates
  }

  unverifiedPressed = (value, contactMechanismId) => {
    this.addContactMechanism(value, contactMechanismId)
  }

  async addContactMechanism(value, id) {
    const { contactType } = this.props
    let { inputValue } = this.state

    if (value) {
      inputValue = value
    }

    inputValue = inputValue.trim()
    if (!inputValue) {
      return
    }

    const body = {
      value: inputValue,
      typeId:
        contactType === contactTypes.PHONE
          ? userContactTypes.PHONE
          : userContactTypes.EMAIL,
    }

    this.setState({ loading: true, error: null })
    try {
      let contactMechanismId = id
      if (!value && !contactMechanismId) {
        const { id } = await contactMechanismsActions.addUserContactMechanism(
          body
        )
        getUserContactMechanisms()

        contactMechanismId = id
      }

      const { contactMechanismVerificationId } =
        await requestVerifyContactMechanism(contactMechanismId)
      const props = {
        contactMechanismId,
        contactMechanismVerificationId,
        contactMechanismValue: inputValue,
        fromAccountScreen: true,
        contactMechanismType: contactType,
        closeVerify: this.closeVerify,
      }

      this.setState({
        loading: false,
        modal: <RetrieveTeeupVerifyContainer {...props} />,
      })
    } catch (e) {
      if (e === "Contact mechanism already exists") {
        Toast.show(
          `This ${
            contactType === contactTypes.PHONE ? "phone number" : "email"
          } is already in use`
        )
      }

      if (e === "Too Many Requests") {
        Toast.show("Too Many Requests", {
          bottomOffset: 8,
        })
      }
      this.setState({ loading: false, error: e })
    }
  }

  onSave = () => {
    this.addContactMechanism()
    this.deleteInput()
  }

  render() {
    const { contactType, primaryEmail, title, onClose, country } = this.props
    const {
      activateInput,
      contacts,
      inputValue,
      loading,
      defaultCountry,
      visibleSave,
      modal,
    } = this.state

    const content = contacts[contactType]

    return (
      <>
        <div className="settings__top">
          <img
            onClick={onClose}
            src={arrow}
            alt=""
            style={{ width: 20, height: 20, transform: "scale(-1)" }}
          />
          <span>{title}</span>
          {visibleSave ? (
            <div onClick={this.onSave} style={saveButton}>
              Save
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {modal && (
          <div
            className="settings__modal settings__modal-content"
            style={{ animationName: "none", marginBottom: "10px" }}
            onClick={(event) => event.stopPropagation()}
          >
            <div className="settings__top">
              <img
                onClick={this.closeVerify}
                src={closeIcon}
                alt=""
                style={{ width: 20, height: 20 }}
              />
              <span>Enter Verification Code</span>
              <div></div>
            </div>
            {modal}
          </div>
        )}
        <EditContactScreenView
          contactType={contactType}
          content={content}
          primaryEmail={primaryEmail}
          country={country}
          deleteContact={this.deleteContact}
          addContact={this.addContact}
          unverifiedPressed={this.unverifiedPressed}
          activateInput={activateInput}
          onChangeInputValue={this.onChangeInputValue}
          deleteInput={this.deleteInput}
          inputValue={inputValue}
          loading={loading}
          onChooseCountry={this.onChooseCountry}
          phoneRef={(ref) => (this.phone = ref)}
          defaultCountry={defaultCountry}
        />
      </>
    )
  }
}

EditContactScreenContainer.propTypes = {
  contacts: PropTypes.object.isRequired,
  contactType: PropTypes.string.isRequired,
  // componentId: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  // primaryEmail: PropTypes.string.isRequired,
  verifiedCountryCode: PropTypes.string,
  retrieveTeeupActions: PropTypes.shape({
    setRetrieveTeeupId: PropTypes.func.isRequired,
  }),
}

const mapStateToProps = (state) => {
  const user = selectUserInfo(state)
  const { id: userId, email: primaryEmail, country } = user
  const contacts = selectContactMechanisms(state)
  const verifiedCountryCode = selectUserVerifiedCountryCode(state)

  return {
    contacts,
    userId,
    primaryEmail,
    verifiedCountryCode,
    country,
  }
}

export default connect(mapStateToProps, null)(EditContactScreenContainer)
