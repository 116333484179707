import { getUserContactMechanisms } from "@actions/userActions"
import { selectUserInfo, selectContactMechanisms } from "@selectors/user"
import React, { useCallback, useEffect } from "react"
import { FaPencilAlt } from "react-icons/fa"
import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import iconPhone from "@assets/images/newDesign/icons/ndPhone.svg"
import iconLinkedin from "@assets/images/newDesign/icons/ndLinkedin.svg"
import iconLocation from "@assets/images/newDesign/icons/ndLocation.svg"
import iconMail from "@assets/images/newDesign/icons/ndMail.svg"
import iconTwitter from "@assets/images/newDesign/icons/ndTwitter.svg"
import "./index.scss"
import { Link } from "react-router-dom"

const MyProfileNd = () => {
  const userInfo = useSelector(selectUserInfo)
  const contactMechanisms = useSelector(selectContactMechanisms)

  useEffect(() => {
    getUserContactMechanisms()
  }, [])

  const getInfo = useCallback(
    (field) => userInfo[field] || userInfo.user[field],
    [userInfo]
  )

  const getPublicInfo = useCallback(
    (field) => {
      if (contactMechanisms[field])
        return (
          contactMechanisms[field].filter((item) => item.isPublic)[0]?.value ||
          "Not set"
        )
      return "Not set"
    },
    [contactMechanisms]
  )

  return (
    <div className="my-profileNd">
      <div className="my-profileNd--main my-profileNd--card">
        <div
          className="my-profileNd--main-avatar"
          style={{ backgroundImage: `url(${getInfo("avatar")})` }}
        />
        <div
          className="my-profileNd--main-bg"
          style={{ backgroundImage: `url(${getInfo("backgroundImageUrl")})` }}
        />
        <div className="my-profileNd--main-info">
          <div className="my-profileNd--main-info--left">
            <h1>{getInfo("name")}</h1>
            <p>@{getInfo("nickname")}</p>
            <p>{getInfo("primaryLocation")}</p>
          </div>
          <Link to="settings">
            <div className="my-profileNd--main-info--right">
              <FaPencilAlt />
              <span>Edit</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="my-profileNd--about my-profileNd--card">
        <p className="my-profileNd--card-title">About</p>
        <hr />
        <p className="my-profileNd--about-desc">{getInfo("about")}</p>
      </div>
      <div className="my-profileNd--public my-profileNd--card">
        <p className="my-profileNd--card-title">Public Information</p>
        <hr />
        <ul className="my-profileNd--public-list">
          <li className="my-profileNd--public-item">
            <ReactSVG src={iconPhone} />
            <p>{getPublicInfo("phone")}</p>
          </li>
          <li className="my-profileNd--public-item">
            <ReactSVG src={iconMail} />
            <p>{getPublicInfo("email")}</p>
          </li>
          <li className="my-profileNd--public-item">
            <ReactSVG src={iconLocation} />
            <p>{getPublicInfo("location")}</p>
          </li>
          <li className="my-profileNd--public-item">
            <ReactSVG src={iconLinkedin} />
            <p>{getPublicInfo("linkedin")}</p>
          </li>
          <li className="my-profileNd--public-item">
            <ReactSVG src={iconTwitter} />
            <p>{getPublicInfo("twitter")}</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MyProfileNd
