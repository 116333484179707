import React, { Component } from "react"
import {
  View,
  Text,
  Image,
  FlatList,
  TouchableOpacity,
  StyleSheet,
} from "react-native"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { selectUserId } from "@selectors/user"
import { selectActiveTeeups } from "@selectors/teeups"
import { AppColors, AppFonts, Units } from "@theme/"
import { images } from "@utils/imageUtils"
// import { showInviteToTeeup, routeCreateTeeup, dismissModal } from '@nav'
import Topbar from "@ui/topbar"
import InviteToTeeup from "../../../InviteToTeeup"
import { openCreateTeeup, setDraftInvitees } from "@actions/createTeeupActions"

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: AppColors.brand.lightGrey },
  list: { flex: 1, marginTop: 4 },
  listContentContainer: { flexGrow: 1 },
  item: {
    backgroundColor: AppColors.brand.white,
    paddingHorizontal: Units.responsiveValue(12),
    height: Units.responsiveValue(64),
    borderBottomWidth: 1,
    borderBottomColor: AppColors.brand.lightGrey,
    flexDirection: "row",
    alignItems: "center",
  },
  teeupThumbnail: {
    width: Units.responsiveValue(54),
    height: Units.responsiveValue(40),
    borderRadius: Units.responsiveValue(6),
    marginRight: Units.responsiveValue(12),
  },
  itemLabel: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.blackTwo,
  },
  footer: {
    marginVertical: Units.responsiveValue(24),
    alignItems: "center",
  },
  footerButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: AppColors.brand.pink,
    height: Units.responsiveValue(48),
    borderRadius: Units.responsiveValue(48 / 2),
    paddingLeft: Units.responsiveValue(12),
    paddingRight: Units.responsiveValue(17),
  },
  plusIcon: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
    marginRight: Units.responsiveValue(10),
    tintColor: AppColors.brand.white,
  },
  footerButtonText: {
    ...AppFonts.biggerDemibold,
    lineHeight: AppFonts.sizes.bigger,
    color: AppColors.brand.white,
  },
})

class InviteContactsToTeeup extends Component {
  state = {
    content: null,
  }

  closeInvite = () => {
    this.setState({ content: null })
  }

  handleShowInviteToTeeup = (teeupId) => () => {
    const props = {
      invitees: this.props.invitees,
      closeInvite: this.props.closeContent,
      teeupId,
    }

    this.setState({ content: <InviteToTeeup {...props} /> })
  }

  handleCreateTeeUp = () => {
    const draftUsers = this.props.invitees.map((contact) => ({
      ...contact,
      givenName: contact.firstName,
      familyName: contact.lastName,
    }))

    this.props.setDraftInvitees(draftUsers)
    this.props.openCreateTeeup()
  }

  handleCloseModal = () => {
    this.props.closeContent()
  }

  renderItem = ({ item: teeup }) => {
    return (
      <TouchableOpacity
        style={styles.item}
        onPress={this.handleShowInviteToTeeup(teeup.id)}
      >
        {teeup.thumbnail ? (
          <Image
            source={{ uri: teeup.thumbnail }}
            style={styles.teeupThumbnail}
          />
        ) : null}
        <Text style={styles.itemLabel}>{teeup.name}</Text>
      </TouchableOpacity>
    )
  }

  keyExtractor = (item, index) => `${index}`

  render() {
    const { teeups } = this.props
    const { content } = this.state
    return (
      <>
        {content ? (
          content
        ) : (
          <View style={styles.container}>
            <Topbar
              title="Invite to TeeUp"
              borderBottomWidth={4}
              leftButtonIcon="back"
              onLeftPress={this.handleCloseModal}
            />
            <FlatList
              style={styles.list}
              contentContainerStyle={styles.listContentContainer}
              data={teeups}
              renderItem={this.renderItem}
              keyExtractor={this.keyExtractor}
            />
            <View style={styles.footer}>
              <TouchableOpacity
                style={styles.footerButton}
                onPress={this.handleCreateTeeUp}
              >
                <Image source={images.plus} style={styles.plusIcon} />
                <Text style={styles.footerButtonText}>Create TeeUp</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </>
    )
  }
}

InviteContactsToTeeup.propTypes = {
  // componentId: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  // teeups: PropTypes.array.isRequired,
  invitees: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  const userId = selectUserId(state)
  const teeups = selectActiveTeeups(state)
  return {
    userId,
    teeups,
  }
}

export default connect(mapStateToProps, { openCreateTeeup, setDraftInvitees })(
  InviteContactsToTeeup
)
