import React from "react"
import find from "lodash/find"
import propTypes from "prop-types"
import { TouchableOpacity, View, Text } from "react-native"

import { teeupStageFilterKeys } from "../../../../config/enums"
import CheckBox from "../../../ui/checkbox"
import { styles } from "./styles"

const FilterPartContent = ({
  buttons = [],
  onFilterButtonPress,
  currentFiltering = [],
  additionalMarginIndexes = [],
}) =>
  buttons.length &&
  buttons.map((filterButton, index) => (
    <TouchableOpacity
      key={`${filterButton.filter}-${filterButton.title}`}
      onPress={() => onFilterButtonPress(filterButton)}
    >
      <View
        style={[
          styles.teeupStageButtonLine,
          find(additionalMarginIndexes, (i) => i === index) &&
            styles.sectionMargin,
        ]}
      >
        {filterButton.id === teeupStageFilterKeys.planning_unscheduled && (
          <Text style={styles.unscheduledText}>Planning</Text>
        )}
        <View style={styles.row}>
          <Text style={styles.buttonTitle}>{filterButton.title}</Text>
          <CheckBox
            isActive={
              !!find(
                currentFiltering,
                (item) => item && item.id === filterButton.id
              )
            }
          />
        </View>
      </View>
    </TouchableOpacity>
  ))

FilterPartContent.propTypes = {
  onFilterButtonPress: propTypes.func.isRequired,
  currentFiltering: propTypes.array,
  additionalMarginIndexes: propTypes.array,
}

export default FilterPartContent
