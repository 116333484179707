import { AppColors } from "@theme/"
import { images } from "@utils/imageUtils"
import { teeupUserStatus, teeupUserStatusKeys } from "@configs/enums"

export const UserStatusButtonProps = [
  {
    title: teeupUserStatus.joined,
    titleColor: AppColors.brand.darkGrey2,
    image: images.joinedBig,
    color: AppColors.status.user.joined,
    border: AppColors.brand.grey2,
    testID: "teeupUserJoinedBtn",
    status: teeupUserStatusKeys.joined,
    isAvailable: false,
  },
  {
    title: teeupUserStatus.coolIdea,
    image: images.snowflake,
    color: AppColors.status.user.coolIdea,
    testID: "teeupUserCoolideaBtn",
    status: teeupUserStatusKeys.coolIdea,
    isAvailable: false,
  },
  {
    title: teeupUserStatus.invited,
    image: images.envelope,
    color: AppColors.status.user.invited,
    testID: "teeupUserInvitedBtn",
    status: teeupUserStatusKeys.invited,
    isAvailable: false,
  },
  {
    title: teeupUserStatus.going,
    image: images.arrowRight,
    color: AppColors.status.user.going,
    testID: "teeupGoingBtn",
    status: teeupUserStatusKeys.going,
    isAvailable: true,
  },
  {
    title: teeupUserStatus.mightgo,
    image: images.question,
    color: AppColors.status.user.mightgo,
    testID: "teeupMightGoBtn",
    status: teeupUserStatusKeys.mightgo,
    isAvailable: true,
  },
  {
    title: teeupUserStatus.notgoing,
    image: images.times,
    color: AppColors.status.user.notgoing,
    testID: "teeupUserNotGoingBtn",
    status: teeupUserStatusKeys.notgoing,
    isAvailable: true,
  },
  {
    title: teeupUserStatus.onmyway,
    image: images.rocket,
    color: AppColors.status.user.onmyway,
    testID: "teeupUserOnMyWayBtn",
    status: teeupUserStatusKeys.onmyway,
    isAvailable: true,
  },
  {
    title: teeupUserStatus.runningLate,
    image: images.runningLateOrange,
    color: AppColors.status.user.runningLate,
    testID: "teeupUserRunningLateBtn",
    status: teeupUserStatusKeys.runningLate,
    isAvailable: true,
  },
  {
    title: teeupUserStatus.attended,
    image: images.flagCheckered,
    color: AppColors.status.user.attended,
    testID: "teeupUserAttendedBtn",
    status: teeupUserStatusKeys.attended,
    isAvailable: true,
  },
  {
    title: teeupUserStatus.arrived,
    image: images.flag,
    color: AppColors.status.user.arrived,
    testID: "teeupUserArrivedBtn",
    status: teeupUserStatusKeys.arrived,
    isAvailable: true,
  },
  {
    title: teeupUserStatus.backedOut,
    image: images.backedoutRed,
    color: AppColors.status.user.backedOut,
    testID: "teeupUserBackedoutBtn",
    status: teeupUserStatusKeys.backedOut,
    isAvailable: true,
  },
]
