import React from "react"
import { ReactSVG } from "react-svg"
import moment from "moment"
import classNames from "classnames"

import { images } from "@utils/imageUtils"
import { useTeeUpPage, useReactions } from "pages/TeeUpPage/TeeUpPageContext"

import "./gamePlanCard.scss"

const GamePlanCard = ({
  children,
  config,
  plan,
  decided,
  onDecisionClick,
  onSuggestClick,
  onSuggestionsListClick,
  ...props
}) => {
  const { upVote, downVote } = useReactions(decided?.id)
  const { interactionArea } = useTeeUpPage()

  const getDecisionOverviewContent = (decision, planType) => {
    if (decision && planType === "where") {
      return decision.value
    }

    if (decision && planType === "when") {
      return moment(decided.startDate).format("dddd, MMMM D")
    }

    return "No Decision yet"
  }

  const getDecisionDetailsContent = (decision, planType) => {
    if (decision && planType === "where") {
      return decision.details
    }

    if (decision && planType === "when") {
      return moment(decided.startDate).format("LT")
    }

    return "Decide on or make a suggestion"
  }

  const handleMainClick = () => {
    if (decided) {
      onDecisionClick()
    }

    return
  }

  return (
    <div
      className={classNames("game-plan-card", {
        active: interactionArea === config.type,
      })}
      {...props}
    >
      <div
        className={classNames("game-plan-card__main", {
          decided: decided,
        })}
        onClick={handleMainClick}
      >
        <div className="game-plan-card__header">
          <ReactSVG src={config.icon} />
          <span className="game-plan-card__header-type">{config.type}</span>
          {decided && (
            <>
              <span className="game-plan-card__header-decided">{`decided`}</span>
              <div className="game-plan-card__header-votes">
                <ReactSVG src={images.ndThumbUpIcon} />
                <span>{upVote?.reactedBy?.length || 0}</span>
              </div>
              <div className="game-plan-card__header-votes">
                <ReactSVG src={images.ndThumbDownIcon} />
                <span>{downVote?.reactedBy?.length || 0}</span>
              </div>
            </>
          )}
        </div>
        <div
          className={classNames("game-plan-card__content", {
            "interaction-area-active": interactionArea === config.type,
          })}
        >
          <div className="game-plan-card__content-decision-overview">
            {getDecisionOverviewContent(decided, config.type)}
          </div>
          <div
            className={classNames("game-plan-card__content-decision-details", {
              decided: decided,
            })}
          >
            {getDecisionDetailsContent(decided, config.type)}
          </div>
        </div>
      </div>
      {interactionArea !== config.type && (
        <div className="game-plan-card__actions">
          <div
            className="game-plan-card__actions-button"
            onClick={onSuggestClick}
          >
            <ReactSVG src={images.ndLightBulbWithPlusIcon} />
            <span>{`Suggest ${plan?.title || ""}`}</span>
          </div>
          {plan?.suggestions?.length > 0 && (
            <div
              className="game-plan-card__actions-button"
              onClick={onSuggestionsListClick}
            >
              <ReactSVG src={images.ndBulletListIcon} />
              <span>{`${plan.suggestions.length} Suggestion${
                plan.suggestions.length > 1 ? "s" : ""
              }`}</span>
              <div className="game-plan-card__actions-button-yellow-dot" />
            </div>
          )}{" "}
        </div>
      )}
    </div>
  )
}

export default GamePlanCard
