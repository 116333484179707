import React from "react"
import PropTypes from "prop-types"

import { teeupListNudgeTypes } from "../../../../config/enums"
// import { defineNudgePrimaryTitle } from '@utils/teeupUtils'
import ActionButtonsView from "./ActionButtonsView"

const noop = () => {}

class ActionButtonsBar extends React.PureComponent {
  state = {
    alreadyAnswered: false,
  }

  handleDecline = (e) => {
    e.preventDefault()
    this.props.onDeclineInvitation()
    this.setState({ alreadyAnswered: true })
  }
  handleAccept = (e) => {
    e.preventDefault()
    this.props.onAcceptInvitation()
    this.setState({ alreadyAnswered: true })
  }
  handleArchivePressed = (e) => {
    e.preventDefault()
    this.props.onArchivePressed()
    this.setState({ alreadyAnswered: true })
  }
  handleKeepInTheListPress = (e) => {
    e.preventDefault()
    this.props.onKeepInTheListPress()
    this.setState({ alreadyAnswered: true })
  }
  handleIgnoreUndecided = () => {
    this.props.onIgnoreUndecidedInGameplan(false)
    this.setState({ alreadyAnswered: true })
  }
  handleIgnore = () => {
    this.props.onIgnore(false)
    this.setState({ alreadyAnswered: true })
  }
  handleView = () => {
    this.props.onViewTeeup()
    this.handleIgnoreUndecided()
    this.setState({ alreadyAnswered: true })
  }
  handleDefineNudgeRespond = () => {
    const {
      nudgeInfo: { nudgeType },
    } = this.props
    let respondAction = noop
    switch (nudgeType) {
      case teeupListNudgeTypes.mention: {
        respondAction = this.handleView
      }
    }
    respondAction()
    this.setState({ alreadyAnswered: true })
  }
  render() {
    const { alreadyAnswered } = this.state
    const {
      isInvited,
      isDroppedOut,
      isSkippedDeclined,
      isArchivePromptShow,
      isEnded,
      // isUndecidedWhenInGameplan,
      // nudgeInfo,
    } = this.props

    if (
      !isInvited &&
      !isDroppedOut &&
      !isSkippedDeclined &&
      !isArchivePromptShow
      // !nudgeInfo &&
      // !isUndecidedWhenInGameplan
    )
      return null

    if (isEnded) {
      return (
        <ActionButtonsView
          isGreyOverlay={isDroppedOut || isSkippedDeclined}
          alreadyAnswered={alreadyAnswered}
          onSecondaryButtonPress={this.handleKeepInTheListPress}
          secondaryButtonText={"Keep in the list"}
          onMainButtonPress={alreadyAnswered ? noop : this.handleArchivePressed}
          noMargin={true}
          mainButtonText={"Archive"}
        />
      )
    }

    if (isInvited) {
      return (
        <ActionButtonsView
          alreadyAnswered={alreadyAnswered}
          onSecondaryButtonPress={alreadyAnswered ? noop : this.handleDecline}
          secondaryButtonText={"Decline"}
          onMainButtonPress={alreadyAnswered ? noop : this.handleAccept}
          mainButtonText={"Accept"}
        />
      )
    }

    // if (isUndecidedWhenInGameplan) {
    //     return (
    //         <ActionButtonsView
    //             alreadyAnswered={alreadyAnswered}
    //             onSecondaryButtonPress={
    //                 alreadyAnswered ? noop : this.handleIgnoreUndecided
    //             }
    //             secondaryButtonText={'Ignore'}
    //             onMainButtonPress={alreadyAnswered ? noop : this.handleView}
    //             mainButtonText={'View'}
    //         />
    //     )
    // }
    // if (nudgeInfo) {
    //     return (
    //         <ActionButtonsView
    //             alreadyAnswered={alreadyAnswered}
    //             onSecondaryButtonPress={
    //                 alreadyAnswered ? noop : this.handleIgnore
    //             }
    //             secondaryButtonText={'Ignore'}
    //             onMainButtonPress={
    //                 alreadyAnswered ? noop : this.handleDefineNudgeRespond
    //             }
    //             mainButtonText={defineNudgePrimaryTitle(
    //                 nudgeInfo.nudgeType
    //             )}
    //         />
    //     )
    // }

    return (
      <ActionButtonsView
        isGreyOverlay
        alreadyAnswered={alreadyAnswered}
        onSecondaryButtonPress={alreadyAnswered ? noop : this.handleAccept}
        secondaryButtonText={"Join"}
      />
    )
  }
}
ActionButtonsBar.propTypes = {
  onDeclineInvitation: PropTypes.func.isRequired,
  onKeepInTheListPress: PropTypes.func,
  onIgnoreUndecidedInGameplan: PropTypes.func,
  onViewTeeup: PropTypes.func,
  onAcceptInvitation: PropTypes.func.isRequired,
  onArchivePressed: PropTypes.func,
  onIgnore: PropTypes.func,
  isArchivePromptShow: PropTypes.bool,
  invitation: PropTypes.object,
  nudgeInfo: PropTypes.object,
  userStatus: PropTypes.string,
  isInvited: PropTypes.bool,
  isDroppedOut: PropTypes.bool,
  isSkippedDeclined: PropTypes.bool,
  isUndecidedWhenInGameplan: PropTypes.bool,
}

export default ActionButtonsBar
