import React, { Component } from "react"
import PropTypes from "prop-types"
import { Platform } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { messageTypeIds } from "@configs/mappings"
import {
  highlightTypes,
  teeupListNudgeTypes,
  teeupUserStatusKeys,
} from "@configs/enums"
import { selectTeeupParticipants, selectTeeupPeople } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
// import teeupActions from '@actions/teeupActions'
// import teeupCardActions from '@actions/teeupCardActions'
// import { pop, showHighlightsScreen } from '@nav'
import I18n from "@i18n"
import NudgeSelectParticipantsView from "./nudgeSelectParticipantsView"
import { sendNudge } from "../../middlewares/backendMiddleware"
import { setNudgePeopleOpen } from "@actions/commonActions"

class NudgeSelectParticipantsContainer extends Component {
  static options() {
    return {
      popGesture: false,
    }
  }

  handleCancel = () => this.props.setNudgePeopleOpen(false)

  handleNudge = (selectedParticipants) => {
    const subTypeId = teeupListNudgeTypes.suggestion
    const suggestionId = 2104

    const messageObj = {
      message:
        I18n.nudgePromptContent[teeupListNudgeTypes.reverse[subTypeId]].action,
      messageTypeId: messageTypeIds.nudge,
      mentionIds: selectedParticipants,
      subTypeId,
    }

    if (suggestionId) {
      messageObj.targetId = suggestionId
    }

    // (message, subTypeId, messageTypeId, mentionIds, targetId)
    sendNudge(messageObj)
    this.props.setNudgePeopleOpen(false)

    // if (Platform.OS === 'android') {
    //     pop(componentId)
    //     sendNudge(messageObj)
    //     showHighlightsScreen({
    //         initialIndex: highlightTypes.nudges,
    //     })
    // } else {
    //     setTimeout(() => {
    //         pop(componentId)
    //     }, 0)
    //     sendNudge(messageObj)
    //     showHighlightsScreen({
    //         initialIndex: highlightTypes.nudges,
    //     })
    // }
  }

  handleMention = (mentionMessage) => {
    const { expandConversationCard, setActiveTextMessage, componentId } =
      this.props
    expandConversationCard(true, () => {
      setActiveTextMessage(mentionMessage)
    })
    // setTimeout(() => pop(componentId), 0)
  }

  // navigationButtonPressed({ buttonId }) {
  //     switch (buttonId) {
  //         case 'close':
  //             pop(this.props.componentId)
  //             break
  //         default:
  //     }
  // }

  render() {
    const { participants, isMentionPeople } = this.props

    return (
      <NudgeSelectParticipantsView
        participants={participants}
        isMentionPeople={isMentionPeople}
        onCancel={this.handleCancel}
        onNudge={this.handleNudge}
        onMention={this.handleMention}
      />
    )
  }
}

NudgeSelectParticipantsContainer.propTypes = {
  componentId: PropTypes.string,
  participants: PropTypes.array,
  sendNudge: PropTypes.func,
  subTypeId: PropTypes.number,
  suggestionId: PropTypes.number,
  expandConversationCard: PropTypes.func,
  setActiveTextMessage: PropTypes.func,
  isMentionPeople: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const userId = selectUserId(state)
  const people = selectTeeupPeople(state)
  const participants = selectTeeupParticipants(state).filter(
    (participant) =>
      participant !== userId &&
      people[participant].status !== teeupUserStatusKeys.invited
  )

  return {
    people,
    participants: participants.map((id) => people[id]),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNudgePeopleOpen,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NudgeSelectParticipantsContainer)
