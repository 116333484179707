import React, { useEffect, useState } from "react"
import ReactTooltip from "react-tooltip"

import "./index.scss"

const ReactionsTooltip = ({ sections, reactionId }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [others, setOthers] = useState(false)
  const [emptyUsers, setEmptyUsers] = useState(false)

  const text =
    reactionId === 2 ? "Nobody liked it yet" : "Nobody disliked it yet"

  const hideTooltip = () => {
    setIsOpen(false)
    ReactTooltip.hide()
  }

  useEffect(() => {
    sections.length > 5 ? setOthers(true) : setOthers(false)
    sections.length === 0 ? setEmptyUsers(true) : setEmptyUsers(false)
  }, [sections])

  return (
    <ReactTooltip
      id="reactions-tooltip"
      class="extraClass"
      backgroundColor="#ffffff"
      place="top"
      effect="solid"
      afterHide={() => setIsOpen(true)}
      delayHide={500}
      // delayShow={ 500 }
      getContent={(id) => {
        if (!id || !isOpen) {
          return null
        }
        return (
          <>
            {emptyUsers && (
              <span
                style={emptyUsers && { marginLeft: 0 }}
                className="tooltip_list_person__title_teeup"
              >
                {text}
              </span>
            )}
            {!emptyUsers && (
              <div className="teeup_tooltip_container_person">
                {sections !== [] &&
                  sections.map((person, index) => (
                    <div
                      key={person.name + index}
                      className="tooltip_list_person"
                    >
                      <img
                        class={"tooltip_list_person_avatar"}
                        src={person.avatar}
                      />
                      <span className="tooltip_list_person__title_teeup">
                        {person.name}
                      </span>
                    </div>
                  ))}
                {others && (
                  <span className="tooltip_list_person__title_teeup tooltip_list_person__title_likes">
                    ... and {sections.length - 5} others liked it{" "}
                  </span>
                )}
              </div>
            )}
          </>
        )
      }}
    />
  )
}

export default ReactionsTooltip
