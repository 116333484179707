import React from "react"
import { useHistory } from "react-router-dom"

import "./index.scss"
import { images } from "@utils/imageUtils"
import header from "../../assets/images/login-header.svg"
import { ReactComponent as Logo } from "../../assets/images/logoWhite.svg"

const LandingPage = () => {
  const history = useHistory()

  return (
    <div className="landing_page">
      <div
        className="header-image"
        style={{ backgroundImage: `url(${header})` }}
      ></div>
      {/* <img src={images.logoWhite} alt="" /> */}
      <Logo style={{ fill: "#F42D63" }} />
      <div>
        <button
          className="sign_up_btn"
          onClick={() => history.push("/sign-up")}
        >
          Sign Up
        </button>
        <button
          className="sign_in_btn"
          onClick={() => history.push("/sign-in")}
        >
          Log In
        </button>
      </div>
      <span
        className="invitation_text"
        onClick={() => history.push("verify-invitation")}
      >
        I received an invitation
      </span>
    </div>
  )
}

export default LandingPage
