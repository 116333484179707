import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import cn from "classnames"

import { resetPassword } from "@actions/userActions"
import VerifyCode from "../../VerifyCode/"
import {
  changeEmailVerificationRequest,
  createUserCM,
  getUserCM,
  deleteUserCMFromEmail,
} from "@actions/loginActions"
import ChangePasswordSuccess from "../../../ChangePassword/ChangePasswordSuccess"
import { setModalSuccess } from "@actions/commonActions"
import { selectModalSuccess } from "@selectors/common"

import { images } from "@utils/imageUtils"

let propsForVerify = {
  type: "email",
  id: -1,
  userId: -1,
  deviceId: null,
  value: "",
}

const ChangeEmail = ({
  resetPassErrorMessage,
  resetPassword,
  accessToken,
  passwordRecoveryError,
  modalSuccess,
  setModalSuccess,
  deleteUserCM,
}) => {
  const [content, setContent] = useState(null)
  const [value, setValue] = useState("")
  const [error, setError] = useState("")
  const [step, setStep] = useState(1)
  const [cmData, setCmData] = useState()
  const [contactMechanismVerificationId, setContactMechanismVerificationId] =
    useState()
  const history = useHistory()
  const token = history.location.state
  const tokenAccess = accessToken && accessToken

  // useEffect(() => {
  //     setupTeeupListSocket(accessToken, data.user.id)
  // }, [])

  // useEffect(() => {
  //   getUserCM(accessToken)
  //     .then( res => {
  //         console.log(res.data.contactMechanisms, 'infooo');
  //         // setContactMechanisms(res.data.contactMechanisms)
  //     })
  //     .catch( err => {
  //         console.log(err, 'error');
  //     })
  // }, [])

  const inputEmailHandle = (email) => {
    setValue(email)
    propsForVerify = { ...propsForVerify, value: email }
  }

  const previousStep = () => setStep(step - 1)

  const handleChangeEmail = () => {
    createUserCM(1, value, accessToken)
      .then((res) => {
        setCmData(res.data)
        changeEmailVerificationRequest(res.data.id, accessToken)
          .then((response) => {
            setContactMechanismVerificationId(
              response.data.contactMechanismVerificationId
            )
            setStep(2)
            //   if (response.data[key]) {
            //     setVerified(true);
            //     // console.log('token', response.data['token'])
            //   } else {
            //     setError('Please Enter Valid Verification Code:')
            //   }
          })
          .catch((error) => {
            console.log("error", error)
            setModalSuccess(true)
            setError("Invalid email or email is already verified")
          })
      })
      .catch((error) => {
        console.log("CM error", error.data)
      })
  }

  return (
    <>
      {step === 1 ? (
        !modalSuccess ? (
          <div className="forgot__page" style={{ position: "relative" }}>
            <div className="forgot__content">
              <img className="forgot__icon" src={images.cooeModal} />
              <span className="reset_password__title">Enter New Email</span>

              <label
                htmlFor="input"
                style={{ marginTop: 32, marginBottom: 0 }}
                className="forgot__label"
              >
                Email
              </label>
              <div style={{ position: "relative", marginTop: 4 }}>
                <input
                  id="input"
                  value={value}
                  onChange={({ target }) => inputEmailHandle(target.value)}
                  className={cn("login__input forgot__input", {
                    "forgot__input--error": error,
                  })}
                  type="email"
                />
              </div>
              <span
                className="reset_password--error"
                style={{ marginBottom: 10 }}
              >
                {error}
              </span>
              <button
                disabled={value.length <= 6}
                style={{ marginTop: 0 }}
                className={cn("reset_password_button", {
                  "reset_password_button--disabled": value.length <= 6,
                })}
                onClick={handleChangeEmail}
              >
                Send me a verification code
              </button>
            </div>
          </div>
        ) : (
          <ChangePasswordSuccess email />
        )
      ) : (
        <VerifyCode
          resendCodeFn={handleChangeEmail}
          contactMechanismVerificationId={contactMechanismVerificationId}
          {...propsForVerify}
          accessToken={accessToken}
          tokenAccess={tokenAccess}
          cmData={cmData}
          deleteUserCM={deleteUserCM}
          previousStep={previousStep}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    resetPassErrorMessage: state.user.resetPassErrorMessage,
    modalSuccess: selectModalSuccess(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetPassword: bindActionCreators(resetPassword, dispatch),
  setModalSuccess: bindActionCreators(setModalSuccess, dispatch),
  deleteUserCM: bindActionCreators(deleteUserCMFromEmail, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail)
