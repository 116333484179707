import React from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"
import { EmptyTabStyles as styles } from "./ParticipantDetailsStyles"

const EmptyTab = ({ text }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{text}</Text>
    </View>
  )
}

EmptyTab.propTypes = {
  text: PropTypes.string,
}

export default EmptyTab
