import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Auth0Provider } from "@auth0/auth0-react"

import App from "./App"
import configureStore from "./configureStore"
import { TopLevelProvider, useTopLevelContext } from "contexts/TopLevelContext"

export const store = configureStore({})
const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID

const AppWithProviders = () => {
  const { setIsRegistrationInProgress, setSocialRegistrationType } =
    useTopLevelContext()

  const onRedirectCallback = (appState) => {
    if (appState?.isRegistrationInProgress) {
      setIsRegistrationInProgress(appState.isRegistrationInProgress)
    }
    if (appState?.socialRegistrationType) {
      setSocialRegistrationType(appState.socialRegistrationType)
    }
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={`https://${domain}/api/v2/`}
      scope="read:current_user update:current_user_metadata"
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  )
}

ReactDOM.render(
  <TopLevelProvider>
    <AppWithProviders />
  </TopLevelProvider>,
  document.getElementById("root")
)
