import React from "react"
import { View, Text, Image, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"
import Avatar from "@ui/avatar"
import UserStatusAccesory from "@ui/avatar/userStatusAccesory"
import { images } from "@utils/imageUtils"
import { ParticipantPopupStyles as styles } from "./styles"
import PopupOverlayWrapper from "./popupOverlayWrapper"
import I18n from "@i18n"

const NudgeButton = ({ onPress, icon, text }) => {
  return (
    <TouchableOpacity style={styles.nudgeContainer} onPress={onPress}>
      <Image style={styles.nudgeIcon} source={icon} />
      <Text style={styles.nudgeText}>{text}</Text>
    </TouchableOpacity>
  )
}

NudgeButton.propTypes = {
  onPress: PropTypes.func,
  icon: PropTypes.string,
  text: PropTypes.string,
}

const ParticipantPopupView = ({
  participantInfo,
  linkParticipantDetail,
  linkProfile,
  nudgeAreYouGoing,
  nudgeWhereAreYou,
  mentionUser,
  handleClose,
  isMyProfile,
  isInvited,
  inviterName,
}) => {
  const renderAvatar = () => {
    const { avatar, status, additionalCount } = participantInfo
    return (
      <View style={styles.avatarContainer}>
        <Avatar
          size={200}
          borderWidth={0}
          imageUrl={avatar}
          accesory={
            <UserStatusAccesory
              additionalCount={additionalCount}
              userStatus={status}
              accesoryStyle={styles.avatarAccesoryContainer}
              type={"medium"}
            />
          }
          hiddenCount={false}
        />
      </View>
    )
  }

  const renderProfileInfo = () => {
    const { name, username } = participantInfo

    return (
      <View style={styles.profileInfoContainer}>
        <View style={styles.nameContainer}>
          {!!name && <Text style={styles.fullName}>{name}</Text>}
          {!!username && <Text style={styles.displayName}>@{username}</Text>}
        </View>
        {!isMyProfile && (
          <View style={styles.profileWrapper}>
            <TouchableOpacity
              style={styles.profileIconContainer}
              onPress={linkProfile}
            >
              <Image source={images.participantProfile} />
            </TouchableOpacity>
          </View>
        )}
      </View>
    )
  }

  const renderLastActivity = () => {
    if (!inviterName) {
      return null
    }

    return (
      <View style={styles.activityContainer}>
        <Text style={styles.activity}>{`Invited by ${inviterName}`}</Text>
      </View>
    )
  }

  const renderParticipantDetailsLink = () => {
    return (
      <TouchableOpacity
        style={styles.participantDetailsLinkContainer}
        onPress={linkParticipantDetail}
      >
        <Image
          style={styles.participantDetailsLinkIcon}
          source={images.detailsIcon}
        />
        <Text style={styles.participantDetailsLink}>Participant Details</Text>
      </TouchableOpacity>
    )
  }

  const renderNudges = () => {
    return (
      <View style={styles.nudgesContainer}>
        <NudgeButton
          onPress={nudgeAreYouGoing}
          text={I18n.nudgePromptContent.areYouGoing.action}
          icon={images.nudgePeopleNew}
        />
        <NudgeButton
          onPress={nudgeWhereAreYou}
          text={I18n.nudgePromptContent.whereAreYou.action}
          icon={images.nudgePeopleNew}
        />
        <NudgeButton
          onPress={mentionUser}
          text={I18n.nudgePromptContent.mentionUser.action}
          icon={images.mentionsNew}
        />
      </View>
    )
  }

  return (
    <PopupOverlayWrapper handleClose={handleClose}>
      <View style={styles.overlayWrapper}>
        {renderAvatar()}
        {renderProfileInfo()}
        <View style={styles.separator} />
        {renderLastActivity()}
        {!isInvited && renderParticipantDetailsLink()}
      </View>
      {!isMyProfile && !isInvited && renderNudges()}
    </PopupOverlayWrapper>
  )
}

ParticipantPopupView.propTypes = {
  participantInfo: PropTypes.object,
  handleClose: PropTypes.func,
  linkParticipantDetail: PropTypes.func,
  linkProfile: PropTypes.func,
  nudgeAreYouGoing: PropTypes.func,
  nudgeWhereAreYou: PropTypes.func,
  mentionUser: PropTypes.func,
  isMyProfile: PropTypes.bool,
  isInvited: PropTypes.bool,
  inviterName: PropTypes.string,
}

export default ParticipantPopupView
