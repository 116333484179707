import React from "react"
import PropTypes from "prop-types"
import { View, ScrollView } from "react-native"
import ClickableRow from "@ui/settingsClickableRow"
import { ArrowButton } from "@ui/settingsClickableRow/actionButtons"
import strings from "@i18n"
import { styles } from "./policiesAndServicesStyles"
const PoliciesAndServicesView = ({ handleAction }) => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.splitter} />
      <div className="settings__rowWrapper">
        <ClickableRow
          primaryText={[strings.policiesAndServices.privacyPolicy.title]}
          actionButton={<ArrowButton />}
          onPress={() => handleAction("privacy")}
        />
        <ClickableRow
          primaryText={[strings.policiesAndServices.termsOfService.title]}
          actionButton={<ArrowButton />}
          onPress={() => handleAction("terms")}
        />
        <ClickableRow
          primaryText={["Licenses"]}
          actionButton={<ArrowButton />}
          onPress={() => handleAction("licenses")}
        />
      </div>
      {/* <ClickableRow
                primaryText={[I18n.t('policiesAndServices.aboutCooe.title')]}
                actionButton={<ArrowButton />}
            />
            <ClickableRow
                primaryText={[I18n.t('policiesAndServices.licenses.title')]}
                actionButton={<ArrowButton />}
            /> */}
    </ScrollView>
  )
}

PoliciesAndServicesView.propTypes = {
  handleAction: PropTypes.func.isRequired,
}

export default PoliciesAndServicesView
