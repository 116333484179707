import React from "react"
import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"

import "./index.scss"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

const OpenButton = () => {
  const { setOpenPeopleArea, closeInteractionArea } = useTeeUpPage()

  return (
    <button
      type="button"
      className="top-bar__open"
      onClick={() => {
        setOpenPeopleArea(false)
        closeInteractionArea()
      }}
    >
      <ReactSVG
        src={images.ndTeeUpPeopleMainButton}
        className="top-bar__open--icon"
      />
    </button>
  )
}

export default OpenButton