import React from "react"
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native"
import PropTypes from "prop-types"

import { AppColors, AppFonts, Units, AppSizes } from "@theme/"
import { images } from "@utils/imageUtils"

const styles = StyleSheet.create({
  topbar: {
    width: "100%",
    height: AppSizes.navbarHeight + AppSizes.statusBarHeight,
    paddingTop: AppSizes.statusBarHeight,
    backgroundColor: AppColors.brand.white,
    justifyContent: "center",
  },
  bottomBorder: {
    width: "100%",
    backgroundColor: AppColors.brand.lightGrey,
  },
  header: {
    position: "absolute",
    width: "100%",
  },
  titleText: {
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.blackTwo,
    textAlign: "center",
  },
  subtitleText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.blackTwo,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    padding: Units.responsiveValue(16),
  },
  buttonLabel: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.pink,
  },
  buttonIcon: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
    tintColor: AppColors.brand.pink,
  },
  disabledLabel: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.warmGrey2,
  },
})

const LeftButtonItem = ({
  leftButtonLabel,
  leftButtonIcon,
  leftIconColor,
  leftButton,
}) => {
  if (leftButtonLabel) {
    return <Text style={styles.buttonLabel}>{leftButtonLabel}</Text>
  }
  if (leftButtonIcon) {
    return (
      <Image
        style={[
          styles.buttonIcon,
          leftIconColor && { tintColor: leftIconColor },
        ]}
        source={leftButtonIcon === "back" ? images.back : images.close}
      />
    )
  }
  return leftButton
}

const getRightButtonIcon = (type) => {
  switch (type) {
    case "back":
      return images.back
    case "pencil":
      return images.pencil
    case "more":
      return images.more
    case "close":
    case "Done":
      return images.close
    default:
      return null
  }
}

const RightButtonItem = ({
  rightButtonLabel,
  rightButtonIcon,
  rightIconColor,
  rightButton,
  disabledLabel,
}) => {
  if (rightButtonLabel) {
    return (
      <Text style={disabledLabel ? styles.disabledLabel : styles.buttonLabel}>
        {rightButtonLabel}
      </Text>
    )
  }
  if (rightButtonIcon) {
    return (
      <Image
        style={[
          styles.buttonIcon,
          rightIconColor && { tintColor: rightIconColor },
        ]}
        source={getRightButtonIcon(rightButtonIcon)}
      />
    )
  }
  return rightButton
}

const Topbar = ({
  title,
  subtitle,
  bottomBorderWidth = 1,
  leftButtonLabel,
  leftButtonIcon,
  leftIconColor,
  leftButton,
  onLeftPress,
  rightButtonLabel,
  rightButton,
  rightButtonIcon,
  rightIconColor,
  onRightPress,
  backgroundColor,
  disabledRightLabel,
}) => (
  <>
    <View style={{ ...styles.topbar, backgroundColor }}>
      <View style={styles.buttonContainer}>
        <View style={styles.header}>
          {title ? <Text style={styles.titleText}>{title}</Text> : null}
          {subtitle ? (
            <Text style={styles.subtitleText}>{subtitle}</Text>
          ) : null}
        </View>
        {leftButtonLabel || leftButtonIcon || leftButton ? (
          <TouchableOpacity style={styles.button} onPress={onLeftPress}>
            <LeftButtonItem
              leftButtonLabel={leftButtonLabel}
              leftButtonIcon={leftButtonIcon}
              leftButton={leftButton}
              leftIconColor={leftIconColor}
            />
          </TouchableOpacity>
        ) : null}
        {rightButtonLabel || rightButtonIcon || rightButton ? (
          <TouchableOpacity
            disabled={disabledRightLabel}
            style={styles.button}
            onPress={onRightPress}
          >
            <RightButtonItem
              rightButtonLabel={rightButtonLabel}
              rightButtonIcon={rightButtonIcon}
              rightButton={rightButton}
              rightIconColor={rightIconColor}
              disabledLabel={disabledRightLabel}
            />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
    <View
      style={[
        styles.bottomBorder,
        { height: Units.responsiveValue(bottomBorderWidth) },
      ]}
    />
  </>
)

Topbar.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  bottomBorderWidth: PropTypes.number,
  leftButtonLabel: PropTypes.string,
  leftButtonIcon: PropTypes.string,
  leftIconColor: PropTypes.string,
  leftButton: PropTypes.node,
  onLeftPress: PropTypes.func,
  rightButtonLabel: PropTypes.string,
  rightButtonIcon: PropTypes.string,
  rightIconColor: PropTypes.string,
  rightButton: PropTypes.node,
  onRightPress: PropTypes.func,
  backgroundColor: PropTypes.string,
  disabledRightLabel: PropTypes.bool,
}

Topbar.defaultProps = {
  backgroundColor: AppColors.brand.white,
  disabledRightLabel: false,
}

export default Topbar
