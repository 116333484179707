import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import PropTypes from "prop-types"
import { Animated, Easing, Platform } from "react-native"
import * as settingsActions from "@actions/settingsActions"
import * as contactActions from "@actions/contactActions"
import {
  selectUserInfo,
  selectMechanismsPhone,
  selectMechanismsEmail,
  selectContactsSyncSettings,
} from "@selectors/user"
import { selectVersion } from "@selectors/common"
import { selectSyncInProgress } from "@selectors/contacts"
import { userContactTypes } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"
import { AppColors } from "@theme/"

import MoreView from "./moreView"

const isIOS = Platform.OS === "ios"

class MoreContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contactSyncRotation: new Animated.Value(0),
    }
  }

  componentDidAppear() {
    if (this.props.refreshing) {
      this.startContactSyncAnimation()
      this.forceUpdate()
    }
  }

  componentDidMount() {
    const { allowSynchronization } = this.props.contactsSyncSettings
    if (allowSynchronization) {
      this.onToggleAllowSynchronization(true, true)
    }
  }

  componentWillUnmount() {
    this.stopContactSyncAnimation()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshing !== this.props.refreshing) {
      if (this.props.refreshing) {
        this.startContactSyncAnimation()
      } else {
        this.stopContactSyncAnimation()
      }
    }
  }

  startContactSyncAnimation() {
    this.contactSyncAnimation = Animated.loop(
      Animated.timing(this.state.contactSyncRotation, {
        toValue: 1,
        easing: Easing.linear,
        duration: 1000,
        useNativeDriver: true,
      }),
      { iterations: -1 }
    )
    this.contactSyncAnimation.start()
  }

  stopContactSyncAnimation() {
    if (this.contactSyncAnimation) {
      this.contactSyncAnimation.stop()
      this.contactSyncAnimation = null
    }
  }

  // handleCloseScreen = () => dismissModal(this.props.componentId)
  handleCloseScreen = () => {}

  openProfile = () => {
    // openProfile({
    //     isMyProfile: true,
    //     userId: this.props.userId,
    //     fromMeScreen: true,
    // })
  }

  linkToScreen =
    (screenName, topbarTitle, props = {}) =>
    () => {
      const options = {
        topBar: {
          title: {
            text: topbarTitle,
          },
          backButton: {
            icon: images.back,
            color: AppColors.brand.pink,
            visible: true,
            showTitle: false,
          },
        },
      }
    }

  onToggleAllowSynchronization = async (event, blockIfUnavailable = false) => {
    let allowSynchronization = event
    if (event) {
      const permission = false
      // if (permission !== RESULTS.GRANTED) {
      //     if (blockIfUnavailable) {
      //         allowSynchronization = false
      //     } else {
      //         return
      //     }
      // }
    }
    const formattedSettings = {
      contacts: {
        ...this.props.contactsSyncSettings,
        allowSynchronization,
      },
    }

    settingsActions.postSettings(formattedSettings)
  }

  render() {
    const {
      contactsSyncedAt,
      phone,
      email,
      contactsSyncSettings: { allowSynchronization },
      close,
      userId,
      version,
    } = this.props
    const { contactSyncRotation } = this.state
    const lastSynced = contactsSyncedAt
      ? moment(contactsSyncedAt).format("MMM D, h:mm A")
      : null
    let contactMechanisms = []
    // if (email) {
    //     contactMechanisms.push(email)
    // }
    // if (phone) {
    //     contactMechanisms.push(phone)
    // }
    const infiniteRotate = contactSyncRotation.interpolate({
      inputRange: [0, 1],
      outputRange: ["0deg", "360deg"],
    })
    const contactSyncAnimationStyles = {
      transform: [
        {
          rotate: infiniteRotate,
        },
      ],
    }
    return (
      <MoreView
        openProfile={this.openProfile}
        linkToScreen={this.linkToScreen}
        onToggleAllowSynchronization={this.onToggleAllowSynchronization}
        lastSynced={lastSynced}
        contactMechanisms={contactMechanisms}
        allowSynchronization={allowSynchronization}
        contactSyncAnimationStyles={contactSyncAnimationStyles}
        handleCloseScreen={this.handleCloseScreen}
        close={close}
        userId={userId}
        version={version}
        activeScreen={this.props.activeScreen}
      />
    )
  }
}

MoreContainer.propTypes = {
  // componentId: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  contactsSyncSettings: PropTypes.object,
  contactsSyncedAt: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  refreshing: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const userInfo = selectUserInfo(state)
  const userId = userInfo.id
  const contactsSyncedAt = userInfo.contactsSyncedAt
  const phoneContactMechanism =
    selectMechanismsPhone(state).find(
      (phone) => phone.typeid === userContactTypes.PRIMARY_PHONE
    ) || {}
  const emailContactMechanism =
    selectMechanismsEmail(state).find(
      (email) => email.typeid === userContactTypes.PRIMARY_EMAIL
    ) || {}
  const contactsSyncSettings = selectContactsSyncSettings(state)
  const syncInProgress = selectSyncInProgress(state)
  const version = selectVersion(state)

  return {
    version,
    userId,
    contactsSyncedAt,
    phone: phoneContactMechanism.value,
    email: emailContactMechanism.value,
    contactsSyncSettings,
    refreshing: contactsSyncSettings.allowSynchronization && syncInProgress,
  }
}

export default connect(mapStateToProps)(MoreContainer)
