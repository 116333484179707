import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { View, Text, Image, TouchableOpacity } from "react-native"
import { bindActionCreators } from "redux"
import Modal from "react-modal"

import { selectTeeupPeople, selectTeeupSuggestions } from "@selectors/teeups"
import { selectTeeupNudgesWithResponses } from "@selectors/activeTeeup"
import I18n from "@i18n"
import { teeupUserStatus } from "@configs/enums"
import { userStatusTypes } from "@configs/mappings"
import { selectUserId } from "@selectors/user"
import { getTimeFormat } from "@utils/dateUtils"
import { formatAskedUsers } from "@utils/teeupUtils"
import { capitalizeFirstLetter, getContactInitials } from "@utils/dataUtils"
import { images } from "@utils/imageUtils"
import { getUsernameOrName } from "@utils/contactUtils"
import UserStatusAccesory from "@ui/avatar/userStatusAccesory"
import { teeupListNudgeTypes } from "@configs/enums"

import SuggestionNudge from "@components/nudges/suggestionNudge"
import UserNudgesScreenComponent from "./UserNudgesScreenComponent"
import {
  NudgeAnswerPlainText,
  NudgeAnswerAvatarAndStatus,
} from "./NudgeAnswers"
import SugestionOrStatusNudgeResponse from "@components/teeupUserResponseDialogs/suggestionOrStatusNudgeResponse"

import {
  styles,
  nudgeItemContentWrapper,
  nudgeTitle,
  nudgeType,
  nudgeTimestamp,
  nudgeAnswerContent,
} from "./UserNudgesScreenStyles"

class UserNudgesScreenContainer extends React.Component {
  static propTypes = {
    nudgesWithResponses: PropTypes.arrayOf(PropTypes.object),
    userId: PropTypes.number.isRequired,
    people: PropTypes.object.isRequired,
    teeupSuggestions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      nudgeResponseDialogOpen: false,
      nudgeInfo: {},
      subTypeId: -1,
    }
  }

  handleNudgeItemRender = ({ item }) => {
    const { userId, people, teeupSuggestions } = this.props
    const { targetId, mentionIds, text, message, nudgeAnswers, createdAt } =
      item
    let nudgesSuggestion = null
    if (targetId) {
      nudgesSuggestion = teeupSuggestions[Number(targetId)]
    }
    const nudgeSenderId = Number(item.userId || item.senderId)
    const isCreatedByCurrentUser = nudgeSenderId === userId
    const nudgeCreator = people[nudgeSenderId] || {}
    const teeupUserIdsToMentions = _.map(Object.keys(people), (id) =>
      Number(id)
    ).filter((i) => i !== nudgeCreator.id)
    const isAllUsersNudged = _.isEqual(
      _.sortBy(mentionIds),
      _.sortBy(teeupUserIdsToMentions)
    )
    const mentionedUsers = _.map(mentionIds, (mentionId) =>
      mentionId === userId ? "me" : getUsernameOrName(people[mentionId])
    ).filter((i) => i)
    const askedUsers = formatAskedUsers(mentionedUsers)
    const askedUsersText =
      isAllUsersNudged && askedUsers.length !== 1 ? "everybody" : askedUsers

    const creator = isCreatedByCurrentUser
      ? "I"
      : getUsernameOrName(nudgeCreator)

    return (
      <View style={styles.nudgeItemWrapper}>
        {!isCreatedByCurrentUser && (
          <View style={styles.avatar}>
            {nudgeCreator.avatar ? (
              <Image
                style={styles.avatarImageStyle}
                source={nudgeCreator.avatar}
              />
            ) : (
              <Text>{getContactInitials(nudgeCreator)}</Text>
            )}
            <UserStatusAccesory
              userStatus={nudgeCreator.status}
              accesoryStyle={styles.userStatusIcon}
            />
          </View>
        )}
        <View style={nudgeItemContentWrapper(isCreatedByCurrentUser)}>
          <View style={styles.row}>
            <Image style={styles.nudgeIcon} source={images.nudge} />
            <Text
              style={[
                styles.flex,
                styles.row,
                nudgeTitle(isCreatedByCurrentUser),
              ]}
            >
              {creator} asked {askedUsersText}
            </Text>
          </View>
          <Text style={nudgeType(isCreatedByCurrentUser)}>
            {capitalizeFirstLetter(text || message)}
          </Text>

          {!!nudgesSuggestion && (
            <View style={styles.suggestionNudge}>
              <SuggestionNudge
                onNudgesScreen={true}
                suggestion={nudgesSuggestion}
              />
            </View>
          )}
          {this.handleRenderNudgesAnswers(
            nudgeAnswers,
            isCreatedByCurrentUser,
            item
          )}
          <Text style={nudgeTimestamp(isCreatedByCurrentUser)}>
            Nudged {getTimeFormat(createdAt, "MMMM D, hh:mm A")}
          </Text>
        </View>
      </View>
    )
  }

  handleRenderNudgesAnswers = (answers, isCreatedByCurrentUser, nudgeInfo) => {
    const { userId, people } = this.props
    let children = null
    if (!isCreatedByCurrentUser) {
      const userAnswer = answers[userId]
      if (userAnswer) {
        let text
        if (nudgeInfo.subTypeId === teeupListNudgeTypes.suggestion) {
          text = userAnswer.text
        } else {
          text = teeupUserStatus[userStatusTypes[userAnswer.typeId]]
        }
        children = (
          <NudgeAnswerPlainText
            respondTitle={I18n.iResponded}
            text={text}
            date={userAnswer.createdAt}
          />
        )
      } else {
        return (
          <TouchableOpacity
            onPress={this.openRespondDialog(nudgeInfo)}
            style={styles.respondButton}
          >
            <Text style={styles.respondButtonTitle}>
              {I18n.systemButtons.respond}
            </Text>
          </TouchableOpacity>
        )
      }
    } else if (!_.isEmpty(answers)) {
      children = Object.values(answers).map((answer, index, self) => {
        const user = people[answer.userId]
        const username = getUsernameOrName(user)
        const userStatusKey = userStatusTypes[answer.typeId]
        if (nudgeInfo.subTypeId === teeupListNudgeTypes.suggestion) {
          return (
            <NudgeAnswerPlainText
              key={`${answer.timestamp}-${index}`}
              respondTitle={username}
              text={answer.text}
              date={answer.timestamp}
            />
          )
        } else {
          return (
            <NudgeAnswerAvatarAndStatus
              key={`${answer.timestamp}-${index}`}
              user={user}
              userStatusKey={userStatusKey}
              date={answer.timestamp}
              index={index}
              totalAmount={self}
            />
          )
        }
      })
    }

    if (!children) return null

    return <View style={styles.nudgeAnswer}>{children}</View>
  }

  openRespondDialog = (nudgeInfo) => () => {
    this.setState({
      nudgeResponseDialogOpen: true,
      subTypeId: nudgeInfo.subTypeId,
      nudgeInfo,
    })
  }

  render() {
    const { nudgesWithResponses } = this.props
    const { nudgeInfo, subTypeId } = this.state
    return (
      <>
        <UserNudgesScreenComponent
          nudgesWithResponses={nudgesWithResponses}
          onNudgeRender={this.handleNudgeItemRender}
        />
        <Modal
          isOpen={this.state.nudgeResponseDialogOpen}
          // onRequestClose={handleClose}
          style={modalStyles}
          ariaHideApp={false}
        >
          <SugestionOrStatusNudgeResponse
            nudgeInfo={nudgeInfo}
            subTypeId={subTypeId}
            close={() => this.setState({ nudgeResponseDialogOpen: false })}
          />
        </Modal>
      </>
    )
  }
}

const modalStyles = {
  content: {
    backgroundColor: "#F5F8FA",
    // backgroundColor: '#F5F5F5',
    width: 320,
    height: 391,
    border: "1px solid #F3F3F3",
    top: "50%",
    left: "50%",
    // left: 'auto',
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "10px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
}

const mapStateToProps = (state) => {
  const teeupSuggestions = selectTeeupSuggestions(state)
  const userId = selectUserId(state)
  const people = selectTeeupPeople(state)
  const nudgesWithResponses = selectTeeupNudgesWithResponses(state)
  return { nudgesWithResponses, userId, people, teeupSuggestions }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNudgesScreenContainer)
