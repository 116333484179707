import React, { useEffect, useState, memo } from "react";

import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

import { geoMiller } from "d3-geo-projection";
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext";

import './index.scss';

const projection = geoMiller()

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const LocationMapChart = () => {
  const { peopleLocations, participants, people } = useTeeUpPage()

  const [peopleLocationsState, setPeopleLocationsState] = useState(null)

  useEffect(() => {
    peopleLocations && setPeopleLocationsState(peopleLocations)
  }, [peopleLocations])

  return (
    <ComposableMap projection={projection} data-tip="" projectionConfig={{ scale: 100 }}>
      <ZoomableGroup center={[10, 0]} zoom={1.2} minZoom={1} maxZoom={1}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                    fill: "#E0E8EE",
                    outline: "none"
                  },
                }}
              />
            ))
          }
        </Geographies>
        {peopleLocationsState && participants.map(id => {
          if (peopleLocationsState[id]) {
            return (
              <Marker
                coordinates={[peopleLocations[id].longitude, peopleLocations[id].latitude]}
                key={id}
              >
                <circle
                  r={5}
                  fill={people[id].isOrganiser ? "#f42862" : "#00bcf6"}
                  stroke="#ffffff"
                />
              </Marker> 
            )
          }
        })}
      </ZoomableGroup>
    </ComposableMap>
  )
}

export default memo(LocationMapChart);
