import React, { memo } from "react"
import classnames from "classnames"

import { useTeeUpPage } from "../TeeUpPageContext"
import TeeupStatus from "./TeeupStatus"
import TeeupGamePlan from "./TeeupGamePlan"
import TeeupSuggestionsInteractionArea from "./TeeupSuggestionsInteractionArea"

import "./index.scss"

const TeeUpPageLeftSection = () => {
  const { teeup, interactionArea } = useTeeUpPage()

  return (
    <div
      className={classnames("teeup-page__left", {
        "with-interaction-area": interactionArea,
      })}
    >
      <div className="teeup-page__left-main">
        <div
          className="teeup-page__image"
          style={{
            backgroundImage: `url(${teeup.photo ? teeup.photo : ""})`,
          }}
        />
        <div className="teeup-page__title-container">
          <span className="teeup-page__name">{teeup.name}</span>
        </div>
        <TeeupStatus />
        <TeeupGamePlan />
      </div>
      {interactionArea && (
        <TeeupSuggestionsInteractionArea>
          Suggestions
        </TeeupSuggestionsInteractionArea>
      )}
    </div>
  )
}

export default memo(TeeUpPageLeftSection)
