import React from "react"
import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"

import "./gamePlanSuggestButton.scss"

const GamePlanSuggestButton = ({ type = "", onClick }) => {
  const handleClick = () => {
    onClick()
  }
  return (
    <div className="game-plan-suggest-button" onClick={handleClick}>
      <ReactSVG src={images.ndLightBulbWithPlusIcon} />
      <span>{`Suggest ${type}`}</span>
    </div>
  )
}

export default GamePlanSuggestButton
