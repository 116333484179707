import React from "react"
import PropTypes from "prop-types"
import { ScrollView, View, TouchableOpacity } from "react-native"
import _ from "lodash"

import { Units } from "@theme"
import { isToday } from "@utils/dateUtils"
import CalendarTimetable from "../calendarComponents/CalendarTimetable"
import CurrentTimeLine from "../calendarComponents/CurrentTimeLine"
import DayCalendarEvents from "../dayCalendar/DayCalendarEvents"
import SelectedDateBlockComponent, { ROW_HEIGHT } from "../common"

import { WeekCalendarTimetableStyles as styles } from "./styles"

const WeekCalendarTimetableView = ({
  hasNewSuggestionProps,
  onPickTimerangeFromCalendar,
  selectedDate,
  duration,
  eventsList,
  totalWidth,
  calculateBlocksWidth,
  weekdays,
  scrollViewRef,
  onScroll,
  onSelectTimeSquare,
  onDurationChanged,
  onCreateTeeup,
}) => {
  return (
    <ScrollView ref={scrollViewRef} onMomentumScrollEnd={onScroll}>
      <View style={styles.topPadding} />
      <CalendarTimetable onSelectTimeSquare={onSelectTimeSquare} />
      <View pointerEvents={"box-none"} style={styles.eventsOverlayContainer}>
        <View
          style={styles.eventsOverlay}
          onLayout={calculateBlocksWidth}
          pointerEvents={"box-none"}
        >
          {weekdays.map((date, index) => {
            const isSelectedTimeForSuggestWhen = isToday(date, selectedDate)
            const today = isToday(date)
            const dayParams = eventsList[date.format("dddd")]
            const hasEvents = !_.isEmpty(dayParams)

            return (
              <View
                key={date.format()}
                style={[styles.dayColumn, index < 6 && styles.dayColumnBorder]}
                pointerEvents={"box-none"}
              >
                {totalWidth !== 0 && hasEvents && (
                  <DayCalendarEvents
                    columnsAmount={dayParams.columnsAmount}
                    totalWidth={totalWidth / 7}
                    calculateBlocksWidth={calculateBlocksWidth}
                    eventsList={dayParams.mappedEventsList}
                    showIcons={false}
                    compacted={true}
                    onCreateTeeup={onCreateTeeup}
                  />
                )}
                {hasNewSuggestionProps && isSelectedTimeForSuggestWhen && (
                  <SelectedDateBlock
                    onDurationChanged={onDurationChanged}
                    onPickTimerangeFromCalendar={onPickTimerangeFromCalendar}
                    totalWidth={totalWidth / 7}
                    selectedDate={selectedDate}
                    duration={duration}
                  />
                )}
                {today && <CurrentTimeLine totalWidth={totalWidth / 7} />}
              </View>
            )
          })}
        </View>
      </View>
    </ScrollView>
  )
}

class SelectedDateBlock extends SelectedDateBlockComponent {
  render() {
    const { position, multiplyIndex } = this.getPosition()
    const { totalWidth, onPickTimerangeFromCalendar } = this.props

    return (
      <TouchableOpacity
        style={styles.selectedTimeBlockContainer({
          position,
          ROW_HEIGHT,
        })}
        onPress={onPickTimerangeFromCalendar}
        hitSlop={Units.hitSlop({
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        })}
      >
        {/* <View
                    {...this._panResponderTop.panHandlers} //Temp remove of drag func
                    style={styles.suggestionDragBox(KNOB_HEIGHT)}
                /> */}

        <View
          style={styles.selectedDateBlock({
            position,
            ROW_HEIGHT,
            multiplyIndex,
            totalWidth,
          })}
        />

        {/* <View
                    {...this._panResponderBottom.panHandlers} //Temp remove of drag func
                    style={styles.suggestionDragBox(KNOB_HEIGHT)}
                /> */}
      </TouchableOpacity>
    )
  }
}

SelectedDateBlock.propTypes = {
  totalWidth: PropTypes.number,
  onPickTimerangeFromCalendar: PropTypes.func,
  onDurationChanged: PropTypes.func,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  duration: PropTypes.number,
}

WeekCalendarTimetableView.propTypes = {
  onSelectTimeSquare: PropTypes.func,
  hasNewSuggestionProps: PropTypes.bool,
  onPickTimerangeFromCalendar: PropTypes.func,
  eventsList: PropTypes.object,
  columnsAmount: PropTypes.number,
  totalWidth: PropTypes.number,
  calculateBlocksWidth: PropTypes.func,
  weekdays: PropTypes.array,
  scrollViewRef: PropTypes.object,
  onDurationChanged: PropTypes.func,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  duration: PropTypes.number,
  onScroll: PropTypes.func,
}

export default WeekCalendarTimetableView
