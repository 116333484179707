import React, { useMemo } from "react"
import moment from "moment"

import { images } from "@utils/imageUtils"
import { GamePlanSuggestionList } from "../GamePlanComponents"
import { useTeeUpPage } from "../../../TeeUpPageContext"

import "./gamePlanWhen.scss"

const GamePlanWhen = () => {
  const { teeup } = useTeeUpPage()

  const whenGamePlan = useMemo(() => {
    const plan = teeup.gameplans.find((plan) => plan.type === "when")
    const decided = plan?.suggestions?.find((suggestion) => suggestion.decided)

    return { plan, decided }
  }, [teeup])

  const getOverview = (suggestion) => {
    return moment(suggestion?.startDate).format("dddd, MMMM D") || ""
  }

  const getDetails = (suggestion) => {
    return moment(suggestion.startDate).format("LT") || ""
  }

  return (
    <GamePlanSuggestionList
      decided={whenGamePlan.decided}
      config={{
        icon: images.ndClearClockIcon,
        type: "when",
        getDetails,
        getOverview,
      }}
      plan={whenGamePlan.plan}
    />
  )
}

export default GamePlanWhen
