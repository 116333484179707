import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

// import { routeCreateTeeup, dismissModal } from '@nav'
import { selectCalendarForDay } from "@selectors/calendar"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { getEventsLayoutForDay } from "@utils/calendarUtils"
// import { getCalendarEvents } from '@utils/calendarSyncUtils'
import { isToday, getDateWithTimezone, composeDatetime } from "@utils/dateUtils"
// import { validateEmail } from '@utils/dataUtils'
import { locationTypeIds } from "@utils/gamePlanUtils"
import {
  contactTypes,
  getInviteesWithoutDuplicates,
  prepareManualContact,
  formatContact,
} from "@utils/contactUtils"
import calendarActions from "@actions/calendarActions"
import {
  selectUserId,
  selectMechanismsPhone,
  selectMechanismsEmail,
} from "@selectors/user"
// import {
//     autocompletePlace,
//     calendarTeeupAdded,
//     batchVerifyAndGetUserInfo,
// } from '@apis/backend'

import { Units } from "@theme/"

import DayCalendarTimetableView from "./DayCalendarTimetableView"
import { openCreateTeeup } from "@actions/createTeeupActions"

const ROW_HEIGHT = Units.responsiveValue(50)

class DayCalendarTimetableContainer extends Component {
  constructor(props) {
    super(props)

    const { columnsAmount, mappedEventsList, conflictPosition } =
      getEventsLayoutForDay([
        ...this.props.eventsList,
        ...this.props.passedEvents,
      ])

    this.state = {
      columnsAmount,
      totalWidth: 0,
      mappedEventsList,
      conflictPosition,
    }
    this.scrollViewContainer = React.createRef()
  }

  static getDerivedStateFromProps = (props) => {
    const { columnsAmount, mappedEventsList, conflictPosition } =
      getEventsLayoutForDay([...props.eventsList, ...props.passedEvents])
    return {
      columnsAmount,
      mappedEventsList,
      conflictPosition,
    }
  }

  componentDidMount() {
    const { initScrollToConflict, isToday, selectedDate, scrollOffset } =
      this.props
    const { conflictPosition } = this.state
    let initOffset
    if (scrollOffset) {
      initOffset = scrollOffset
    } else if (initScrollToConflict && conflictPosition > 0) {
      initOffset = conflictPosition - 1
    } else if (isToday) {
      const currentTime = getDateWithTimezone(selectedDate)
      initOffset = currentTime.hour() + currentTime.minute() / 60
    } else {
      initOffset = 8
    }

    // setTimeout(() => {
    //     this.scrollViewContainer.current?.scrollTo({
    //         y: initOffset * ROW_HEIGHT,
    //         animated: false,
    //     })
    // }, 1)
  }

  // componentDidUpdate(prevProps) {
  //     const { scrollOffset } = this.props
  //     if (prevProps.scrollOffset !== scrollOffset) {
  //         setTimeout(() => {
  //             this.scrollViewContainer.current?.scrollTo({
  //                 y: scrollOffset * ROW_HEIGHT,
  //                 animated: false,
  //             })
  //         }, 1)
  //     }
  // }

  handleCreateTeeupFromCalendar = (calendarEvent) => async () => {
    const { id, attendees, startDate, endDate, location, recurrence } =
      calendarEvent
    const { currectUserId, selectedDate } = this.props

    const re = /↵|\n|\r/g
    const formattedLocation = location ? location.split(re) : null

    if (recurrence === "daily") {
      //workaround, because repeatable events have the same ID and the date of the latest repeatable event is applied
      startDate = composeDatetime(selectedDate, startDate)
      endDate = composeDatetime(selectedDate, endDate)
    }
    const whenSuggestion = {
      type: "when",
      optionId: 1,
      isCustomDate: false,
      isCustomTime: false,
      startDate,
      endDate,
      createdBy: currectUserId,
    }

    let whereSuggestion
    if (location || formattedLocation) {
      whereSuggestion = {
        type: "where",
        optionId: 2,
        isCustomDate: true,
        isCustomTime: true,
        locationTypeId: locationTypeIds.realWorld,
        createdBy: currectUserId,
        selected: false,
      }
    }
    const draftTeeup = {
      name: calendarEvent.name,
      message: calendarEvent.summary,
      whereSuggestions: whereSuggestion ? [whereSuggestion] : [],
      whenSuggestions: whenSuggestion ? [whenSuggestion] : [],
    }

    this.props.openCreateTeeup(draftTeeup)
  }

  prepareTeeupInvitees = async (data) => {
    // if (!data || data.length === 0) {
    //     return []
    // }
    // let manualContactId = 0
    // const userPhoneNumbers = this.props.userPhoneNumbers.map(
    //     (number) => number.value
    // )
    // const userEmails = this.props.userEmails.map((email) => email.value)
    // const newInvitees = data
    //     .filter(
    //         (attendee) =>
    //             !(
    //                 !!attendee.email && userEmails.includes(attendee.email)
    //             ) &&
    //             !(
    //                 !!attendee.phone &&
    //                 userPhoneNumbers.includes(attendee.phone)
    //             )
    //     )
    //     .map((attendee) => attendee.email || attendee.phone)
    // const { users, contacts, rest } = await batchVerifyAndGetUserInfo(
    //     newInvitees
    // )
    // const formattedInvitees = users.concat(
    //     contacts.map((contact) => formatContact(contact)),
    //     rest.map((value) => {
    //         const contactType = validateEmail(value)
    //             ? contactTypes.EMAIL
    //             : contactTypes.PHONE
    //         // use negative id to avoid duplication to user id
    //         manualContactId--
    //         return prepareManualContact({
    //             manualContactId,
    //             contactType,
    //             value,
    //         })
    //     })
    // )
    // const invitees = getInviteesWithoutDuplicates({
    //     formattedInvitees,
    // })
    // return invitees
  }

  calculateBlocksWidth = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout
    this.setState({ totalWidth: width })
  }

  handleSelectTimeSquare = ({ hour, meridiem }) => {
    const { newSuggestionProps, changeDate, selectedDate } = this.props
    if (!newSuggestionProps) return null
    const currentDay = getDateWithTimezone(selectedDate)
    const hours =
      meridiem === "PM"
        ? hour === 12
          ? hour
          : hour + 12
        : hour === 12
        ? 0
        : hour

    const newSelectedStartDate = currentDay.set({
      hour: hours,
      minutes: 0,
    })
    const newStartDate = newSelectedStartDate.format()

    changeDate(newStartDate)
  }

  changeDuration = (obj) => {
    const { length, direction } = obj
    const {
      newSuggestionProps,
      duration,
      onDurationChange,
      changeDate,
      selectedDate,
    } = this.props
    if (!newSuggestionProps) return null

    const currentDay = getDateWithTimezone(selectedDate)
    let newDuration = duration

    let addAmount = length * 60
    if (direction === "top") addAmount *= -1
    newDuration += addAmount

    let newStartDate = currentDay.format()

    if (direction === "top") {
      const newSelectedStartDate = currentDay.add(length, "hours")
      newStartDate = newSelectedStartDate.format()
    }

    changeDate(newStartDate)
    onDurationChange(newDuration)
  }

  onScroll = ({ nativeEvent }) => {
    if (this.props.setOffset) {
      this.props.setOffset(nativeEvent.contentOffset.y / ROW_HEIGHT)
    }
  }

  render() {
    const { columnsAmount, totalWidth, mappedEventsList } = this.state
    const {
      allowCreateTeeupFromEvent,
      isToday,
      newSuggestionProps,
      onPickTimerangeFromCalendar,
      selectedDate,
      duration,
      activeTeeupId,
      isFromSugggestionDetails,
    } = this.props
    const suggestionId = newSuggestionProps?.passedEvent?.suggestionId
    const isHideSuggestionBar =
      isFromSugggestionDetails &&
      !!mappedEventsList.find((event) => event.suggestionId === suggestionId)

    return (
      <DayCalendarTimetableView
        hasNewSuggestionProps={!!newSuggestionProps}
        onPickTimerangeFromCalendar={onPickTimerangeFromCalendar}
        selectedDate={selectedDate}
        duration={duration}
        onSelectTimeSquare={this.handleSelectTimeSquare}
        columnsAmount={columnsAmount}
        totalWidth={totalWidth}
        onDurationChanged={this.changeDuration}
        calculateBlocksWidth={this.calculateBlocksWidth}
        eventsList={mappedEventsList}
        onCreateTeeup={this.handleCreateTeeupFromCalendar}
        allowCreateTeeup={
          allowCreateTeeupFromEvent && !newSuggestionProps?.isFromCreateTeeup
        }
        isToday={isToday}
        scrollViewRef={this.scrollViewContainer}
        onScroll={this.onScroll}
        isFromSugggestionDetails={isFromSugggestionDetails}
        isHideSuggestionBar={isHideSuggestionBar}
      />
    )
  }
}

DayCalendarTimetableContainer.propTypes = {
  activeTeeupId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  currectUserId: PropTypes.number,
  userPhoneNumbers: PropTypes.array,
  userEmails: PropTypes.array,
  onDatesSelected: PropTypes.func,
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      type: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  passedEvents: PropTypes.arrayOf(
    // any extra events to display contlicts, etc
    PropTypes.shape({
      startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      type: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  allowCreateTeeupFromEvent: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isToday: PropTypes.bool,
  initScrollToConflict: PropTypes.bool,
  scrollOffset: PropTypes.number,
  setOffset: PropTypes.func,
  changeDate: PropTypes.func,
  duration: PropTypes.number,
  onDurationChange: PropTypes.func,
  newSuggestionProps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      duration: PropTypes.number,
      passedEvent: PropTypes.object,
      isFromCreateTeeup: PropTypes.bool,
      selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onDatesSelected: PropTypes.func.isRequired,
    }).isRequired,
  ]),
  onPickTimerangeFromCalendar: PropTypes.func,
  isFromSugggestionDetails: PropTypes.bool,
}

DayCalendarTimetableContainer.defaultProps = {
  allowCreateTeeupFromEvent: true,
  initScrollToConflict: false,
  passedEvents: [],
}

const mapStateToProps = (state, ownProps) => {
  const { selectedDate, hideOptions } = ownProps
  const calendar = selectCalendarForDay(selectedDate, hideOptions)(state)
  const activeTeeupId = selectActiveTeeupId(state)
  // const userPhoneNumbers = selectMechanismsPhone(state)
  // const userEmails = selectMechanismsEmail(state)
  const currectUserId = selectUserId(state)

  return {
    eventsList: calendar,
    isToday: isToday(selectedDate),
    currectUserId,
    userPhoneNumbers: [],
    userEmails: [],
    activeTeeupId,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openCreateTeeup,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DayCalendarTimetableContainer)
