import React from "react"
import classNames from "classnames"
import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"

import "./index.scss"

const DROPDOWN_TYPE = "dropdown"
const DEFAULT_TYPE = "default"

/**
 *
 * @param {string} label
 * @param {boolean} selected
 * @param {images.[iconName]} icon @see -> import { images } from "@utils/imageUtils"; icon that shows before label.
 * @param {images.[iconName]} secondaryIcon @see -> import { images } from "@utils/imageUtils"; icon that appears after label and is separated with left border from it.
 * @param {string} selectedBgColor background color when button selected;
 * @param {string} selectedColor text color when button selected;
 * @param {function} onClick
 * @param {DEFAULT_TYPE | DROPDOWN_TYPE} type button type that adds some different styles and elements; @see -> above for constants representing the types
 * @param {boolean} outline when true selectedBgColor becomes the color of the border and background color is transparent.
 * @param {function} secondaryOnClick function triggered when secondary icon clicked.
 * @param {boolean} disabled !todo
 * @param {number} additionalCount
 *
 * * design for this component https://app.zeplin.io/project/5e9762b3260fd77c594a755c/screen/6220c8b9f82fb45bf967dc64
 *
 */
const DialogButton = ({
  icon,
  label,
  secondaryIcon,
  selected = false,
  selectedBgColor = "#00c5ae",
  selectedColor = "#ffffff",
  type = DEFAULT_TYPE,
  outline = false,
  secondaryOnClick,
  additionalCount = 0,
  ...props
}) => {
  const outlineStyle = {
    backgroundColor: "transparent",
    color: selectedBgColor,
    borderColor: selectedBgColor,
  }

  const defaultStyle = {
    backgroundColor: selected ? selectedBgColor : "#ffffff",
    color: selected ? selectedColor : "#767676",
    borderColor: selectedBgColor,
  }

  return (
    <div
      className={classNames("dialog-button", {
        selected,
        dropdown: type === DROPDOWN_TYPE,
      })}
      style={outline ? outlineStyle : defaultStyle}
      {...props}
    >
      {icon && (
        <div className="dialog-button__icon">
          <ReactSVG src={icon} className={"icon"} />
        </div>
      )}
      <div className="dialog-button__label">{label}</div>
      {additionalCount > 0 && (
        <div className="dialog-button__label-additional-count">{`+${additionalCount}`}</div>
      )}
      {secondaryIcon && (
        <div
          className="dialog-button__secondaryIcon"
          onClick={secondaryOnClick}
        >
          <ReactSVG src={secondaryIcon} className={"icon"} />
        </div>
      )}
      {type === DROPDOWN_TYPE && (
        <div
          className={classNames("dialog-button__dropdown-arrow", {
            "arrow-color__orange": outline && selectedBgColor === "#ff6f25",
            "arrow-color__green": outline && selectedBgColor === "#00c5ae",
            "arrow-color__blue": outline && selectedBgColor === "#00bcf6",
            "arrow-color__gray": outline && selectedBgColor === "#d1d1d1",
            "arrow-color__pink": outline && selectedBgColor === "#f42862",
            "arrow-color__purple": outline && selectedBgColor === "#6244ca",
            "arrow-color__red": outline && selectedBgColor === "#f52d22",
          })}
        >
          <ReactSVG src={images.ndDropdownArrowIcon} className={"icon"} />
        </div>
      )}
    </div>
  )
}

export default DialogButton
