import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { View, ScrollView } from "react-native"
import ReactDOM from "react-dom"

import { images } from "@utils/imageUtils"
import { TEEUP_LIST_MODES } from "@utils/teeupUtils"
import EmptyView from "../ui/emptyView"
import { ViewStyles } from "./styles"
import "./index.scss"
import SlimScroll from "@ui/slimScroll"
import TeeupTooltip from "./TeeupItem/TeeupTooltip"
import { selectTeeupSearch } from "@selectors/common"
import { setTeeupFilter } from "@actions/commonActions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

const TeeupListView = ({
  teeups,
  searchTerm,
  teeupsMode,
  renderItem,
  onCustomScroll,
  scrollEnabled,
  onScrollEndDrag,
  nestedScrollEnabled,
  emptyScrollEnabled,
  onArchivePressed,
  onTrashPressed,
  onMoveToActivePress,
  teeupFilter,
  setTeeupFilter,
  resetSortAndFilter,

  closeSortTooltip,
}) => {
  const scrollRef = useRef()
  const [scrollTop, setScrollTop] = useState(0)
  let empty = teeups.length === 0
  const renderView = ({ style, ...props }) => {
    return (
      <div
        style={{
          ...style,
          paddingRight: 20,
          paddingTop: 10,
          marginRight: 0,
          marginBottom: 0,
        }}
        {...props}
      />
    )
  }

  return (
    <View
      style={[
        ViewStyles.background,
        empty && ViewStyles.backgroundEmpty,
        // teeupsMode == null && {minHeight: '100%'}
      ]}
    >
      {/* {
               ReactDOM.createPortal(
                <>
                    <TeeupTooltip 
                        // isInvited={isInitiallyInvited}
                        // isDroppedOut={isDroppedOut}
                        // isSkippedDeclined={isSkippedDeclined}
                        // isArchivePromptShow={isArchivePromptShow}
                        onArchivePressed={onArchivePressed}
                        // onDeclineInvitation={onDeclineInvitation}
                        // onKeepInTheListPress={onKeepInTheListPress}
                        // onAcceptInvitation={onAcceptInvitation}
                        // isGreyOverlay={isGreyOverlay}
                        // onViewTeeup={onPress}
                        mode={teeupsMode}
                        onMoveToActivePress={onMoveToActivePress}
                        onTrashPressed={onTrashPressed}
                    />
                </>
                ,document.getElementById("modal-root"))
            } */}
      {!empty ? (
        <div
          className={`teeup__list scroll ${
            teeupsMode === TEEUP_LIST_MODES.ARCHIVED && `teeup__list_shorted`
          }`}
        >
          <div className="teeups__item">
            {/* {
                scrollRef?.current && scrollRef?.current?.clientHeight < scrollRef?.current?.scrollHeight && (
                    <div className="scrollbar" >
                        <div className="scroll__thumb" style={{height: scrollRef?.current?.scrollHeight}}/>
                        {/* <div className="scroll__thumb" style={{height: 100*scrollRef?.current?.clientHeight/scrollRef?.current?.scrollHeight + '%', top: scrollTop + '%'}}/> */}
            {/* </div> */}
            {/* <div className="scrollable__container" ref={scrollRef} onMouseOver={() => setScrollTop((scrollRef?.current?.scrollTop/scrollRef?.current?.scrollHeight)*100)} onScrollCapture={() => {setScrollTop((scrollRef?.current?.scrollTop/scrollRef?.current?.scrollHeight)*100);}}> */}

            {teeups.map((teeuup, index) => renderItem({ item: teeuup, index }))}
            {/* </div> */}
          </div>
        </div>
      ) : searchTerm ? (
        <EmptyView
          title={"No TeeUps Match\nthe Search or Filter"}
          imageSrc={images.noTeeupsInSearch}
        />
      ) : teeupsMode === TEEUP_LIST_MODES.ARCHIVED ? (
        <EmptyView
          title={"No Archived TeeUps"}
          imageSrc={images.noArchivedTeeups}
        />
      ) : teeupsMode === TEEUP_LIST_MODES.SKIPPED ? (
        <EmptyView
          title={"No TeeUps in Trash"}
          imageSrc={images.noTeeupsInTrash}
        />
      ) : teeupFilter ? (
        <EmptyView
          title={"No TeeUps Matching the Search or Filter"}
          imageSrc={images.NoTeeupsMatching}
          setTeeupFilter={setTeeupFilter}
          resetSortAndFilter={resetSortAndFilter}
          noTeeupsMatching
        />
      ) : (
        <EmptyView
          title={"It’s kinda lonely here…"}
          subtitle={"Join a TeeUp or start one of your own"}
          imageSrc={images.homeIcon}
        />
      )}
      {/* <Gradient
                style={ViewStyles.shadowTop}
                {...ViewProps.gradientShadow}
            /> */}
    </View>
  )
}

TeeupListView.propTypes = {
  teeups: PropTypes.array,
  searchTerm: PropTypes.string,
  teeupsMode: PropTypes.string,
  listHeader: PropTypes.node,
  refreshing: PropTypes.bool,
  onRefresh: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  scrollEnabled: PropTypes.bool,
  testID: PropTypes.string,
  onMomentumScrollBegin: PropTypes.func,
  onMomentumScrollEnd: PropTypes.func,
}

TeeupListView.defaultProps = {
  teeups: [],
  listHeader: null,
  refreshing: false,
}

const mapStateToProps = (state) => {
  return {
    teeupFilter: selectTeeupSearch(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTeeupFilter: bindActionCreators(setTeeupFilter, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeeupListView)
