import React from "react"
import { View, Text, TouchableOpacity, Animated, Image } from "react-native"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
// import ReactNativeHapticFeedback from 'react-native-haptic-feedback'

import {
  selectTeeupReactionsBySuggestionId,
  selectTeeupPeople,
  selectTeeupParticipants,
  selectDecidedGameplanByType,
  selectTeeupGoingPeopleCount,
} from "@selectors/teeups"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import actionTypes from "@actions/actionTypes"
import Toast from "@ui/toast"
import Icon from "@ui/icon"
import { Units } from "@theme"
import { images } from "@utils/imageUtils"
import { getCalendarFormat } from "@utils/dateUtils"
import {
  isNewSuggestion,
  getGameplanIcon,
  GAMEPLAN_OPTIONS,
} from "@utils/gamePlanUtils"

import { GameplansRowStyles } from "../gameplansStyles"
import { SectionHeaderStyles } from "./suggestionListStyles"
import { gameplanOptionStatusIds } from "@configs/mappings"
import DraggableSuggestionRow from "../draggableSuggestionRow"
import strings from "@i18n"

const hasSelectedSuggestion = (suggestions) => {
  let selectedSuggestionIndex = false
  suggestions.map(({ selected }, index) => {
    if (selected) {
      selectedSuggestionIndex = index
    }
  })
  return selectedSuggestionIndex
}

const iconHitSlop = { top: 5, bottom: 5, left: 5, right: 5 }

class SuggestionListHeader extends React.Component {
  constructor(props) {
    super(props)
    this._promptAnimValue = new Animated.Value(0)
    this._promptAnimActiveValue = new Animated.Value(0)
    this._promptAnimation = null
    this._dropActive = false
  }

  componentDidUpdate(prevProps) {
    const { isDragging } = this.props

    if (isDragging !== prevProps.isDragging) {
      if (this._promptAnimation) {
        this._promptAnimation.stop()
        this._promptAnimValue.setValue(0)
      }
      if (isDragging) {
        this._promptAnimActiveValue.setValue(0)
        this._dropActive = false
        this._promptAnimation = Animated.timing(this._promptAnimValue, {
          toValue: 1,
          duration: 600,
          delay: 0,
          useNativeDriver: true,
        })
        this._promptAnimation.start()
      }
    }
  }

  measureInWindow(func) {
    this._contentRef.measureInWindow(func)
  }

  animateDropActive(active) {
    if (this._dropActive === active) {
      return
    }

    this._dropActive = active
    Animated.timing(this._promptAnimActiveValue, {
      toValue: active ? 1 : 0,
      duration: 0,
      useNativeDriver: true,
    }).start()

    // ReactNativeHapticFeedback.trigger('impactLight', {
    //     enableVibrateFallback: true,
    //     ignoreAndroidSystemSettings: true,
    // })
  }

  animateChangeGameplan() {
    Animated.parallel([
      Animated.timing(this._promptAnimValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }),
    ]).start()
  }

  handleDecideSuggestion(suggestionId, isDecided) {
    if (!suggestionId) {
      return
    }

    const {
      permissions: { allowDecideGameplan },
    } = this.props

    if (allowDecideGameplan) {
      // globalDispatch({
      //     type: actionTypes.MARK_AS_DECIDED,
      //     payload: { suggestionId, isDecided },
      // })
    } else {
      Toast.show(strings("teeup.toastMsg.forbiddenDecideGP"), {
        bottomOffset: 8,
        textAlign: "center",
      })
    }
  }

  renderLockIcon = () => {
    const { suggestion = {}, pollStatus } = this.props
    const { id, type } = suggestion
    const noSuggestion = _.isEmpty(suggestion)
    const suggestionType = this.props.type || type
    const disabled =
      suggestionType === GAMEPLAN_OPTIONS.startsWhen ||
      suggestionType === GAMEPLAN_OPTIONS.whenWorks ||
      noSuggestion
    let decided = suggestion.decided
    if (
      suggestionType === GAMEPLAN_OPTIONS.startsWhen &&
      suggestion.currentPeopleCount >= suggestion.peopleCount
    ) {
      decided = true
    }

    if (
      pollStatus === gameplanOptionStatusIds.SCHEDULED &&
      suggestionType === GAMEPLAN_OPTIONS.whenWorks
    ) {
      decided = true
    }

    return (
      <TouchableOpacity
        style={SectionHeaderStyles.lockIcon}
        hitSlop={Units.hitSlop(iconHitSlop)}
        onPress={() => this.handleDecideSuggestion(id, !decided)}
        disabled={disabled}
      >
        {/* <Image
                    source={
                        decided
                            ? images.suggestionIconDecided
                            : images.suggestionIconUndecided
                    }
                /> */}
      </TouchableOpacity>
    )
  }

  renderSuggestionRow = () => {
    const {
      suggestions,
      people,
      type,
      suggestionMessages,
      newSuggestions,
      permissions,
      recommendations,
      testID,
      isTeeupCancelled,
      draggingSuggestionId,
      sectionIndex,
      suggestion,
      index,
      isMultizone,
      handleOnLongPress,
      isActionSheetActive,
    } = this.props
    const selectedSuggestionIndex = hasSelectedSuggestion(suggestions)

    // not passing the 3rd param to highlight my suggestion as well as others
    let isNew = isNewSuggestion(newSuggestions, suggestion)

    let name = ""
    if (suggestion.createdBy && people[suggestion.createdBy]) {
      const user = people[suggestion.createdBy]
      name = user?.username || user?.name
    }

    let commentsCount
    if (
      suggestionMessages &&
      suggestion.id &&
      suggestionMessages[suggestion.id]
    ) {
      commentsCount = suggestionMessages[suggestion.id].length
    }
    const recommendation =
      recommendations && recommendations[suggestion.recommendationId]

    return (
      <View style={SectionHeaderStyles.activeSuggestionContainer}>
        <DraggableSuggestionRow
          type={type}
          key={"" + index}
          name={name}
          timestamp={getCalendarFormat(suggestion.timestamp)}
          commentsCount={commentsCount}
          suggestion={suggestion}
          isNew={isNew}
          initiateStatusUpdate
          canSelectGameplan={permissions.allowSelectGameplan}
          canDecideGameplan={permissions.allowDecideGameplan}
          canChangeGameplanOptions={permissions.allowChangeGameplanOptions}
          recommendation={recommendation}
          handleOnLongPress={handleOnLongPress}
          isTimeZoneEnabled={isMultizone}
          testID={`${testID}-suggestion-${index}`}
          isTeeupCancelled={isTeeupCancelled}
          suggestionIndex={index}
          selectedSuggestionIndex={selectedSuggestionIndex}
          isRowExpanded
          isDragging={draggingSuggestionId === suggestion.id}
          sectionIndex={sectionIndex}
          renderLockIcon={this.renderLockIcon}
          customStyle={SectionHeaderStyles.activeSuggestionCustomStyle}
          customContentStyle={SectionHeaderStyles.activeSuggestionCustomStyle}
          showShadow={false}
          renderPlaceholder={this.renderSuggestionPlaceholder}
          isActionSheetActive={isActionSheetActive}
          isFromSuggestionListHeader
        />
      </View>
    )
  }

  renderSuggestionPlaceholder = () => {
    const {
      type,
      highlighterRef,
      onTooltipShow = () => {},
      headerTemplateKey,
    } = this.props

    const headerTemplate = (
      <TouchableOpacity
        key={headerTemplateKey}
        ref={(ref) => {
          ref && highlighterRef.current.setRef(headerTemplateKey, ref)
        }}
        collapsable={false}
        // onPress={onTooltipShow}
        style={SectionHeaderStyles.emptySuggestionHeader}
      >
        <View>
          <View
            style={[
              GameplansRowStyles.gamePlanWrapper,
              { borderBottom: "1px solid #f3f3f3", paddingBottom: 0 },
            ]}
          >
            <Image
              style={{ width: 16, height: 16 }}
              source={
                type === "where"
                  ? images.iconPinGamePlan
                  : images.iconClockGamePlan
              }
            />
            <Text style={GameplansRowStyles.gamePlanText}>Game Plan</Text>
          </View>
        </View>
        <View style={SectionHeaderStyles.divider}></View>
        <Text style={SectionHeaderStyles.suggestionPlaceholderText}>
          No Game Plan yet
        </Text>
        <View style={SectionHeaderStyles.placeholderEmptyAreaContainer}>
          <View
            style={[
              SectionHeaderStyles.placeholderEmptyArea,
              SectionHeaderStyles.placeholderEmptyAreaFirst,
            ]}
          ></View>
          <View
            style={[
              SectionHeaderStyles.placeholderEmptyArea,
              SectionHeaderStyles.placeholderEmptyAreaSecond,
            ]}
          ></View>
        </View>
      </TouchableOpacity>
    )
    highlighterRef.current.setViewObj(headerTemplateKey, headerTemplate)
    return headerTemplate
  }

  renderHeader = () => {
    const { suggestion } = this.props
    const noSelectedSuggestion = _.isEmpty(suggestion)
    if (noSelectedSuggestion) {
      return this.renderSuggestionPlaceholder()
    } else {
      return this.renderSuggestionRow()
    }
  }

  render() {
    const {
      isDragging,
      isSuggestionInPast,
      dropTargetPermissionText,
      suggestion,
      onAddSuggestion,
      closeSidebar,
    } = this.props
    const dropPrompt = dropTargetPermissionText
      ? dropTargetPermissionText
      : isSuggestionInPast
      ? "gameplans.pastSuggestion"
      : "gameplans.dropToChange"
    // ? strings('gameplans.pastSuggestion')
    // : strings('gameplans.dropToChange')
    const noSelectedSuggestion = _.isEmpty(suggestion)
    return (
      <View
        style={[
          SectionHeaderStyles.container,
          closeSidebar && { position: "sticky", top: "49px" },
        ]}
        ref={(ref) => {
          this._contentRef = ref
        }}
      >
        {!isDragging && this.renderHeader()}
      </View>
    )
  }
}

const mapStateToProps = (state, props) => {
  const type = props.type || props.suggestion.type
  const decidedGameplan = selectDecidedGameplanByType(type)(state)
  const decidedGameplanId = decidedGameplan.id || -1
  const goingPeopleCount = selectTeeupGoingPeopleCount(state)

  if (props.suggestion && props.suggestion.id) {
    const suggestionId = props.suggestion.id
    const reactions = selectTeeupReactionsBySuggestionId(suggestionId)(state)
    const people = selectTeeupPeople(state)
    const participants = selectTeeupParticipants(state)
    const activeTeeupId = selectActiveTeeupId(state)
    return {
      reactions,
      people,
      participants,
      decidedGameplanId,
      activeTeeupId,
      goingPeopleCount,
    }
  } else {
    return {
      reactions: [],
      people: selectTeeupPeople(state),
      participants: [],
      decidedGameplanId,
    }
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  SuggestionListHeader
)

SuggestionListHeader.defaultProps = {
  suggestion: {},
}

SuggestionListHeader.propTypes = {
  reactions: PropTypes.array,
  gameplans: PropTypes.array,
  suggestionsList: PropTypes.array,
  permissions: PropTypes.object,
  suggestion: PropTypes.object,
  people: PropTypes.object,
  participants: PropTypes.array,
  type: PropTypes.string,
  isMultizone: PropTypes.bool,
  testID: PropTypes.string,
  isTeeupCancelled: PropTypes.bool,
  activeTeeupId: PropTypes.number,
  isDragging: PropTypes.bool,
  goingPeopleCount: PropTypes.number,
  pollStatus: PropTypes.number,
  currentUser: PropTypes.object.isRequired,
  highlighterRef: PropTypes.object,
  onTooltipShow: PropTypes.func,
  headerTemplateKey: PropTypes.string,
  suggestions: PropTypes.array,
  suggestionMessages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  newSuggestions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  recommendations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  draggingSuggestionId: PropTypes.number,
  sectionIndex: PropTypes.number,
  userId: PropTypes.number,
  index: PropTypes.number,
  handleOnLongPress: PropTypes.func,
  isSuggestionInPast: PropTypes.bool,
  dropTargetPermissionText: PropTypes.string,
  isActionSheetActive: PropTypes.bool,
}

const NoplanPart = () => (
  <View style={SectionHeaderStyles.noplanWrapper}>
    <Text style={SectionHeaderStyles.noplanText}>
      {strings.emptySuggestionList.noPlan}
    </Text>
  </View>
)
