import React, { useEffect } from "react"
import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"
import { useTeeUpPage, useReactions } from "../../../TeeUpPageContext"
import GamePlanNoDecision from "./GamePlanNoDecision"
import GamePlanDecided from "./GamePlanDecided"
import GamePlanSuggestion from "./GamePlanSuggestion"
import GamePlanSuggestButton from "./GamePlanSuggestButton"

import "./gamePlanSuggestionList.scss"

const GamePlanSuggestionList = ({ decided, config, plan }) => {
  const { closeInteractionArea, openSuggestWhereModal } = useTeeUpPage()
  const { upVote, downVote, userVote, onUpVote, onDownVote } = useReactions(
    decided?.id
  )

  const onSuggestClick = () => {
    openSuggestWhereModal()
    console.log("Suggest is clicked")
  }

  return (
    <div className="game-plan-suggestion-list">
      <div className="game-plan-suggestion-list__header">
        <div className="game-plan-suggestion-list__header-title">
          <ReactSVG src={config.icon} />
          <span>{`${config.type} game plan`}</span>
        </div>
        <div className="game-plan-suggestion-list__header-box">
          {decided ? (
            <GamePlanDecided
              overview={config.getOverview(decided)}
              details={config.getDetails(decided)}
              upVote={upVote?.reactedBy?.length}
              downVote={downVote?.reactedBy?.length}
              userVote={userVote}
              onUpVote={onUpVote}
              onDownVote={onDownVote}
            />
          ) : (
            <GamePlanNoDecision />
          )}
        </div>
        <div
          className="game-plan-suggestion-list__header-close"
          onClick={closeInteractionArea}
        >
          <ReactSVG src={images.ndCloseXIcon} />
        </div>
      </div>
      <div className="game-plan-suggestion-list__content">
        <div className="game-plan-suggestion-list__content-suggestions">
          {plan?.suggestions?.map((suggestion) => (
            <GamePlanSuggestion
              overview={config.getOverview(suggestion)}
              details={config.getDetails(suggestion)}
              suggestion={suggestion}
              key={suggestion.id}
            />
          ))}
        </div>
        <div className="game-plan-suggestion-list__content-suggest-button">
          <GamePlanSuggestButton type={config.type} onClick={onSuggestClick} />
        </div>
      </div>
    </div>
  )
}

export default GamePlanSuggestionList
