import { StyleSheet } from "react-native"
import { AppColors, Units, AppFonts } from "@theme/"

export const styles = StyleSheet.create({
  chatContainer: {
    transform: [{ scaleY: -1 }],
    flexShrink: 0,
  },
  flex: { flex: 1 },
  row: { flexDirection: "row" },
  mentionsContainer: {
    paddingTop: Units.responsiveValue(16),
    backgroundColor: AppColors.brand.white,
    overflowY: "auto",
  },
  mentionsMessage: {
    marginTop: Units.responsiveValue(4),
  },
  emptyComponent: {
    backgroundColor: AppColors.brand.white,
    alignItems: "center",
    justifyContent: "center",
    marginTop: Units.responsiveValue(4),
    height: "80%",
  },
  mentionsImage: {
    width: Units.rem(10),
    height: Units.rem(10),
  },
  mentionsTitle: {
    color: AppColors.brand.darkGrey3,
    fontSize: Units.responsiveValue(24),
    marginBottom: Units.responsiveValue(12),
    fontFamily: AppFonts.family.medium,
  },
  mentionsText: {
    ...AppFonts.bigMedium,
    color: AppColors.brand.darkGrey2,
    paddingHorizontal: Units.responsiveValue(40),
    textAlign: "center",
    letterSpacing: -Units.responsiveValue(0.35),
    fontSize: 15,
    maxWidth: "470px",
  },
  listPadding: {
    // paddingBottom: 50,
  },
})
