import React, { Component } from "react"
import {
  Text,
  View,
  TouchableOpacity,
  Animated,
  StyleSheet,
  Image,
  TouchableWithoutFeedback,
  BackHandler,
} from "react-native"
import PropTypes from "prop-types"
// import Icon from 'react-native-vector-icons/FontAwesome5'

// import { transparentModalOptions } from '@utils/navUtils'
import { images } from "@utils/imageUtils"
import { Units, AppColors, AppFonts } from "@theme"

const MenuItem = ({
  title,
  onPress,
  icon,
  image,
  cancel,
  iconStyle,
  isSelected,
  textStyle = {},
}) => (
  <TouchableOpacity style={styles.buttonContainer} onPress={onPress}>
    {!!image && (
      <View style={styles.menuItemIcon}>
        <Image
          style={[styles.image, iconStyle]}
          source={image}
          resizeMode="contain"
        />
      </View>
    )}
    {!!icon && (
      <View style={styles.menuItemIcon}>
        {/* <Icon
                    style={iconStyle}
                    name={icon}
                    size={Units.rem(1)}
                    color={AppColors.brand.blackTwo}
                /> */}
      </View>
    )}
    <Text style={[styles.text, cancel && styles.textCancel, textStyle]}>
      {title}
    </Text>
    {isSelected && (
      <View style={styles.menuItemSelectedIcon}>
        <Image
          style={[styles.image, iconStyle]}
          source={images.selectedPink}
          resizeMode="contain"
        />
      </View>
    )}
    <View />
  </TouchableOpacity>
)

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  icon: PropTypes.number,
  image: PropTypes.string,
  iconStyle: PropTypes.object,
  textStyle: PropTypes.object,
  cancel: PropTypes.bool,
  isSelected: PropTypes.bool,
}

class MenuButtonModal extends Component {
  constructor(props) {
    super(props)

    this.animatedValue = new Animated.Value(0)
    this.animatedHeight = new Animated.Value(props.contentHeight)
    this.animationDuration = 200

    this.performAnimations({ appear: true })
  }

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleBackPress
    )
  }

  componentWillUnmount() {
    this.backHandler.remove()
  }

  handleBackPress = () => {
    this.closeMenu()
    return true
  }

  closeMenu = () => {
    this.performAnimations({
      appear: false,
      onAnimationComplete: this.props.onCloseMenu,
    })
  }

  handleMenuPress = (callback) => () => {
    this.performAnimations({
      appear: false,
      onAnimationComplete: () => {
        this.props.onCloseMenu()
        if (callback) {
          callback()
        }
      },
    })
  }

  performAnimations = ({ appear, onAnimationComplete }) => {
    this.animatedValue.setValue(appear ? 0 : 1)
    Animated.parallel([
      Animated.timing(this.animatedValue, {
        duration: this.animationDuration,
        toValue: appear ? 1 : 0,
      }),
      Animated.timing(this.animatedHeight, {
        duration: this.animationDuration,
        toValue: appear ? 0 : this.props.contentHeight,
      }),
    ]).start(onAnimationComplete)
  }

  render() {
    const {
      buttons = [],
      options = { title: null, hideCancelButton: true },
      disableDim = false,
      customStyles = {},
      fromProfile,
    } = this.props

    const backgroundColor = disableDim
      ? AppColors.transparent
      : AppColors.brand.grey40

    return (
      <Animated.View
        style={[
          styles.overlayBackground,
          fromProfile && { width: "calc(33% - 12px)" },
          {
            opacity: this.animatedValue,
            backgroundColor,
          },
          customStyles,
        ]}
      >
        <TouchableWithoutFeedback
          style={styles.fullScreen}
          onPress={this.closeMenu}
        >
          <Animated.View
            style={[
              styles.container,
              {
                transform: [{ translateY: this.animatedHeight }],
              },
            ]}
          >
            <View style={styles.menuContainer}>
              {options.title && (
                <Text style={styles.modalTitle}>{options.title}</Text>
              )}

              {buttons.map((buttonItem) => {
                const {
                  title,
                  icon,
                  image,
                  callback,
                  iconStyle,
                  isSelected,
                  textStyle,
                } = buttonItem

                return (
                  <MenuItem
                    key={title}
                    title={title}
                    icon={icon}
                    image={image}
                    onPress={this.handleMenuPress(callback)}
                    iconStyle={iconStyle}
                    isSelected={isSelected}
                    textStyle={textStyle}
                  />
                )
              })}
              {!options.hideCancelButton && (
                <MenuItem title={"Cancel"} onPress={this.closeMenu} cancel />
              )}
              <View style={styles.bottomSpace} />
            </View>
          </Animated.View>
        </TouchableWithoutFeedback>
      </Animated.View>
    )
  }
}

MenuButtonModal.propTypes = {
  buttons: PropTypes.array.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  options: PropTypes.object,
  disableDim: PropTypes.bool,
}

const styles = StyleSheet.create({
  overlayBackground: {
    ...StyleSheet.absoluteFillObject,
    position: "fixed",
    backgroundColor: "transparent",
    width: "29%",
    left: 70,
    flex: 1,
  },
  fullScreen: {
    flex: 1,
  },
  modalTitle: {
    paddingVertical: Units.rem(1),
    paddingHorizontal: Units.rem(1),
  },
  container: {
    flex: 1,
    justifyContent: "flex-end",
    height: "100%",
  },
  menuContainer: {
    backgroundColor: AppColors.brand.white,
    borderColor: AppColors.brand.pink,
    borderTopWidth: 3,

    elevation: 1,
    shadowColor: AppColors.brand.black,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowRadius: 6,
    shadowOpacity: 0.26,
  },
  bottomSpace: {
    backgroundColor: AppColors.brand.white,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: Units.rem(1),
    paddingHorizontal: Units.rem(1),
  },
  image: {
    height: Units.rem(1),
    width: Units.rem(1),
  },
  text: {
    ...AppFonts.bigDemibold,
    color: AppColors.brand.black,
  },
  textCancel: {
    color: AppColors.brand.pink,
  },
  menuItemIcon: {
    position: "absolute",
    left: Units.rem(1.5),
    top: 0,
    bottom: 0,
    justifyContent: "center",
  },
  menuItemSelectedIcon: {
    position: "absolute",
    right: Units.rem(1.5),
    top: 0,
    bottom: 0,
    justifyContent: "center",
  },
})

export default MenuButtonModal
