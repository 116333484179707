import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

const ViewStyles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: AppColors.brand.lightGrey,
  },
  buttonClusters: {
    paddingTop: Units.rem(0.75),
  },
  buttonContainer: {
    backgroundColor: AppColors.brand.white,
    height: 50,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: Units.rem(1),
    borderBottomWidth: 1,
    borderColor: AppColors.brand.lightGrey,
  },
  buttonTitle: {
    flex: 1,
    flexBasis: "30%",
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  buttonValue: {
    flexShrink: 5,
    marginRight: Units.rem(0.75),
    color: AppColors.brand.darkGrey2,
    ...AppFonts.biggerMedium,
  },
  section: {
    justifyContent: "center",
    height: Units.responsiveValue(40),
    paddingHorizontal: Units.responsiveValue(17),
    backgroundColor: AppColors.brand.lightGrey,
  },
  sectionText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.darkGrey2,
  },
  sectionHeaderWrapper: {
    height: Units.responsiveValue(50),
    paddingLeft: Units.responsiveValue(17),
    justifyContent: "center",
  },
  sectionHeaderText: {
    ...AppFonts.biggerBold,
    letterSpacing: -Units.responsiveValue(0.4),
    lineHeight: Units.responsiveValue(22),
    color: AppColors.brand.black2,
  },
  teeupImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  teeupImageWrapper: {
    height: Units.responsiveValue(250),
    justifyContent: "center",
    alignItems: "center",
  },
  teeupImageLoadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  teeupWithoutImage: {
    width: 18,
    tintColor: AppColors.brand.pink,
  },
  icon: {
    width: 18,
    marginRight: Units.rem(0.625),
  },
})

const RenameStyles = StyleSheet.create({
  inputRow: {
    marginTop: Units.rem(0.75),
    paddingHorizontal: Units.rem(1),
    backgroundColor: AppColors.brand.white,
  },
  input: {
    height: 56,
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
})

const PermissionStyles = StyleSheet.create({
  orgs: {},
  numOrgsWrapper: {
    height: Units.responsiveValue(48),
    width: "100%",
    justifyContent: "flex-end",
    paddingBottom: Units.rem(0.5),
  },
  numOrgsText: {
    paddingHorizontal: Units.rem(1),
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerBold,
  },
  orgsList: {
    paddingHorizontal: Units.rem(0.75),
    paddingTop: Units.responsiveValue(14),
    paddingBottom: Units.responsiveValue(7),
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: AppColors.brand.white,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: AppColors.brand.darkGreyBackground,
    alignItems: "center",
  },
  orgItem: {
    marginHorizontal: Units.rem(0.25),
    marginVertical: Units.rem(0.375),
    alignItems: "center",
  },
  orgName: {
    marginTop: Units.rem(0.125),
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallMedium,
    lineHeight: Units.responsiveValue(16),
    letterSpacing: -Units.responsiveValue(0.2),
  },
  addOrg: {
    margin: Units.rem(0.125),
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: AppColors.brand.grey2,
    justifyContent: "center",
    alignItems: "center",
  },
  addOrgText: {
    marginTop: Units.rem(0.125),
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallMedium,
  },
  permissionRow: {
    paddingHorizontal: Units.rem(1),
    minHeight: Units.responsiveValue(55),
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: AppColors.brand.darkGreyBackground,
    flexDirection: "row",
  },
  permissionRowText: {
    flex: 1,
  },
  permissionRowLabel: {
    paddingVertical: Units.rem(0.25),
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  permissionRowLabelDisabled: {
    opacity: 0.5,
  },
  permissionRowDescription: {
    paddingBottom: Units.rem(0.5),
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallishMedium,
  },
  permissions: {
    paddingBottom: Units.rem(1),
  },
  permissionsContent: {
    backgroundColor: AppColors.brand.white,
  },
  permissionsHint: {
    paddingHorizontal: Units.rem(1),
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerBold,
  },
  permissionsHintWrapper: {
    height: Units.responsiveValue(55),
    width: "100%",
    justifyContent: "flex-end",
    borderBottomWidth: 1,
    borderColor: AppColors.brand.darkGreyBackground,
    paddingBottom: Units.rem(0.5),
  },
  addIcon: {
    color: AppColors.brand.warmGrey2,
    size: 24,
  },
  doneButton: {
    color: AppColors.brand.pink,
    fontFamily: "greycliffcf-demibold-salt",
    cursor: "pointer",
  },
})

const EditOrgsStyles = StyleSheet.create({
  header: {
    height: Units.rem(0.75),
  },
  footer: {
    height: Units.rem(0.75),
  },
  row: {
    paddingLeft: Units.rem(1),
    paddingRight: Units.rem(1.25),
    paddingVertical: Units.rem(0.75),
    backgroundColor: AppColors.brand.white,
    borderBottomWidth: 1,
    borderColor: AppColors.brand.lightGrey,
    flexDirection: "row",
    alignItems: "center",
  },
  userName: {
    marginHorizontal: Units.rem(1),
    flex: 1,
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  checkedCircle: {
    width: Units.rem(1.5),
    height: Units.rem(1.5),
    borderRadius: Units.rem(0.75),
    backgroundColor: AppColors.brand.pink,
    justifyContent: "center",
    alignItems: "center",
  },
  uncheckedCircle: {
    width: Units.rem(1.5),
    height: Units.rem(1.5),
    borderRadius: Units.rem(0.75),
    borderWidth: 1,
    borderColor: AppColors.brand.grey217,
  },
})

const StateSettingsStyles = StyleSheet.create({
  content: {
    paddingVertical: Units.rem(0.75),
  },
  whiteBg: {
    backgroundColor: AppColors.brand.white,
  },
  row: {
    backgroundColor: AppColors.brand.white,
    height: 56,
    paddingHorizontal: 17,
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: AppColors.brand.lightGrey,
  },
  rowText: {
    flex: 1,
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  rowValue: {
    color: AppColors.brand.pink,
    ...AppFonts.biggerMedium,
  },
  pickerItem: {
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerMedium,
    fontSize: Units.rem(1.5),
  },
  description: {
    paddingHorizontal: Units.rem(1),
    paddingVertical: Units.rem(0.5),
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallishMedium,
  },
})

const CalOptionsStyles = StyleSheet.create({
  content: {
    paddingVertical: Units.rem(0.75),
  },
  row: {
    backgroundColor: AppColors.brand.white,
    height: 56,
    paddingHorizontal: 17,
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: AppColors.brand.lightGrey,
  },
  rowText: {
    flex: 1,
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  rowValue: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.biggerMedium,
  },
  arrowMenu: {
    marginLeft: Units.rem(0.5),
  },
  description: {
    paddingHorizontal: Units.rem(1),
    paddingTop: Units.rem(0.5),
    paddingBottom: Units.rem(1.25),
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallishMedium,
  },
})

const PickerLineStyles = StyleSheet.create({
  button: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: Units.responsiveValue(56),
    paddingHorizontal: Units.responsiveValue(17),
    backgroundColor: AppColors.listItemBackground,
  },
  text: {
    ...AppFonts.biggerMedium,
  },
  buttonTitleEnabled: {
    color: AppColors.brand.black2,
  },
  buttonTitleDisabled: {
    color: AppColors.brand.warmGrey2,
  },
  rightPart: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  picker: {
    backgroundColor: AppColors.transparent,
    width: Units.responsiveValue(87),
    position: "relative",
    paddingRight: Units.responsiveValue(14),
    color: AppColors.brand.darkGrey2,
    ...AppFonts.biggerMedium,
  },
  pickerEnabledValue: {
    color: AppColors.brand.darkGrey2,
  },
  pickerDisabledValue: {
    color: AppColors.brand.grey2,
  },
  disabledOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
})

const ImagePreviewStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.brand.black,
  },
  image: {
    height: "100%",
    width: "100%",
    flex: 1,
    resizeMode: "contain",
    justifyContent: "center",
    alignItems: "center",
  },
  overlayWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  footerButtonsWrapper: {
    position: "absolute",
    bottom: Units.responsiveValue(46),
    left: 0,
    right: 0,
    height: Units.responsiveValue(48),
    flexDirection: "row",
    justifyContent: "center",
  },
  removeButtonWrapper: {
    height: Units.responsiveValue(48),
    width: Units.responsiveValue(124),
    backgroundColor: AppColors.brand.black,
    borderWidth: Units.responsiveValue(2),
    borderColor: AppColors.brand.pink,
    justifyContent: "center",
    alignItems: "center",
    marginRight: Units.responsiveValue(16),
    borderRadius: Units.rem(0.5),
    flexDirection: "row",
  },
  changeButtonWrapper: {
    height: Units.responsiveValue(48),
    width: Units.responsiveValue(124),
    backgroundColor: AppColors.brand.pink,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: Units.rem(0.5),
    flexDirection: "row",
  },
  buttonText: {
    ...AppFonts.mediumDemibold,
    letterSpacing: -Units.responsiveValue(0.35),
    lineHeight: Units.responsiveValue(22),
    color: AppColors.brand.white,
    paddingLeft: Units.responsiveValue(12),
  },
})

export {
  ViewStyles,
  RenameStyles,
  PermissionStyles,
  EditOrgsStyles,
  StateSettingsStyles,
  CalOptionsStyles,
  PickerLineStyles,
  ImagePreviewStyles,
}
