import React from "react"
import { ReactSVG } from "react-svg"
import ReactDOM from "react-dom"
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Keyboard,
  BackHandler,
} from "react-native"
import { debounce, find, differenceWith, isEqual } from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
// import { Navigation } from 'react-native-navigation'
import { differenceBy } from "lodash"
import tagIcon from "../../assets/images/tag.svg"
import { sections, findContactsByText } from "@utils/contacts"
import { batchVerifyAndGetUserInfo } from "../../actions/batchVerifyAndGetUserInfo"
import {
  contactTypes,
  getInviteesWithoutDuplicates,
  prepareManualContact,
  formatContact,
  userTypes,
} from "@utils/contactUtils"

import {
  selectRecommendedInvitees,
  selectPreviousTeeupsInvitees,
  selectMostTeeupedWithUsersInvitees,
  selectInvitees,
} from "../../selectors/invitees"
import closeIcon from "../../assets/images/close.svg"
import AddManualContactsContainer from "./AddContacts"

import {
  selectUserId,
  selectContactsSyncedAt,
  selectContactsSyncSettings,
  selectMechanismsPhone,
  selectMechanismsEmail,
  selectUserVerifiedCountryCode,
} from "@selectors/user"

import {
  selectAllContactsWithoutInvitedToTeeup,
  selectAllContactsWithoutInvitedToGroup,
  selectContactsByTagsWithoutInvitedToTeeup,
  selectContactsByTagsWithoutInvitedToGroup,
  selectAllContacts,
  selectBookContacts,
  selectContactsByTags,
  selectTags,
} from "@selectors/contacts"
import Invitees from "./Invitees"
import { selectConnectionRequests } from "@selectors/requests"
// import {
//     showActionsheet,
//     showBatchSelectContacts,
//     showUserTags,
//     routeIndividualChat,
// } from '@nav'
import Toast from "../ui/toast"
import AddInviteesView from "./AddInviteesView"

import styles from "./AddInviteesStyles"
import MyContactItem from "../Contacts/MyContactItem"
import "./index.scss"

const showToastAboutDuplicates = () => {
  Toast.show("This user is already invited. Duplicate was removed", {
    bottomOffset: 8,
  })
}

const handleSearchLogic = (
  props,
  state,
  newSearchText,
  newSelectedTag,
  sortByName
) => {
  // Searching logic:
  // 1) if any tags selected, filter by tags
  // 2) if any search text, search by text
  const { selectedTag, searchText, expandedContactItemId } = state

  const { contactsByTags, contacts, tags, combinedPeople } = props

  let updates = {}
  updates.expandedContactItemId = expandedContactItemId

  let usedSearchText = searchText
  let usedSelectedTag = selectedTag

  if (newSearchText !== null) {
    // Searched via text
    updates.searchText = newSearchText
    usedSearchText = newSearchText
  }
  if (!newSearchText && searchText) {
    updates.expandedContactItemId = {}
  }

  if (newSelectedTag) {
    // Searched via tag. Even if toggling tag off, newSelectedTag will have a value

    if (selectedTag && newSelectedTag.id === selectedTag.id) {
      // Unselected
      usedSelectedTag = null
    } else {
      // New tag selected
      usedSelectedTag = newSelectedTag
    }

    updates.selectedTag = usedSelectedTag
  }

  if (selectedTag && !find(tags, (tag) => tag.id === selectedTag.id)) {
    usedSelectedTag = null
    updates.selectedTag = usedSelectedTag
  }

  let foundContacts = combinedPeople ? combinedPeople : contacts

  if (sortByName) {
    updates.selectedTag = null
  }

  if (!sortByName && usedSelectedTag) {
    foundContacts = contactsByTags[usedSelectedTag.id]
    // foundContacts = usedSelectedTag.id == 'previousTeeups' ? contactsByTags[usedSelectedTag.id][0].users : contactsByTags[usedSelectedTag.id]
  }

  if (usedSearchText) {
    const expression = new RegExp(usedSearchText, "gi")
    foundContacts = findContactsByText(foundContacts, usedSearchText)
    foundContacts.forEach((contact) => {
      if (contact?.phoneNumbers?.length) {
        contact.phoneNumbers.forEach((phone) => {
          if (phone?.number.match(expression)?.length) {
            updates.expandedContactItemId = {
              ...updates.expandedContactItemId,
              [contact.id]: true,
            }
          }
        })
      }
      if (contact?.emails?.length) {
        contact.emails.forEach((email) => {
          if (email?.email.match(expression)?.length) {
            updates.expandedContactItemId = {
              ...updates.expandedContactItemId,
              [contact.id]: true,
            }
          }
        })
      }
    })
  }

  updates.foundContacts = foundContacts

  return updates
}

export class AddInviteesContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showInvitees: true,
      isSearchEnabled: false,
      searchText: "",
      foundContacts: props.combinedPeople || props.contacts || [],
      invitees: props.invitees,
      loadingContacts: props.skipContactsLoading ? false : true,
      sectionOpenItemCount: {
        [sections.FREQUENT]: 2,
        [sections.PREVIOUS]: 2,
        [sections.RECOMMENDED]: 2,
      },
      expandedContactItemId: {},
      selectedTag: null,
      isMenuOpened: false,
      isMoreMenuOpened: false,
      manualInvitees: props.invitees.filter(
        (invite) => invite?.userType === userTypes.MANUAL
      ),
      isModalOpened: false,
      modalContent: null,
      isContactFilterOpened: false,
      currentTag: {},
    }

    this.contactsFetched = false // Only once per opening this screen
    this.manualContactId = 0
    this.searchRef = React.createRef()
    this._searchUsers = debounce(this.searchUsers, 250)

    // this.navigationEventListener = Navigation.events().bindComponent(this)
  }

  static options() {
    return {
      popGesture: false,
    }
  }

  componentDidAppear() {
    if (!this.contactsFetched) {
      // Only after screen appeared, to speed up appearance
      this.contactsFetched = true
      this.handleRefetchContacts()
    }
    this.setState({ loadingContacts: false })
  }

  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", () =>
      this.setState({ isKeyboardOpen: true })
    )
    this.keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", () =>
      this.setState({ isKeyboardOpen: false })
    )
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleAndroidBackPress
    )
  }

  // static getDerivedStateFromProps(nextProps, state) {
  //     return handleSearchLogic(nextProps, state, null, null)
  // }

  componentDidUpdate(prevProps) {
    const { invitees, manualInvitees } = this.state
    const { setInvitees, contacts } = this.props
    if (setInvitees) setInvitees(invitees)

    const currentManualInvitees = invitees.filter(
      (invite) => invite?.userType === userTypes.MANUAL
    )
    if (manualInvitees.length !== currentManualInvitees.length) {
      this.setState({
        manualInvitees: currentManualInvitees,
      })
    }

    if (prevProps.contacts.length !== contacts.length)
      this.setState({ foundContacts: contacts })
    if (differenceWith(prevProps.contacts, contacts, isEqual).length > 0)
      this.setState({ foundContacts: contacts })
  }

  handleCancel = () => null

  handleAdd = () => {
    this.props.onDone(this.state.invitees)
    this.props.onClose()
  }

  handleSearchTextChange = (searchText) => {
    this._searchUsers(searchText)
  }

  searchUsers = (searchText) => {
    this.handleSearch(searchText)
  }

  handleResetSearchText = () => {
    this.searchRef.current.clear()
    this.handleSearch("")
  }

  handleToggleContact =
    ({ data }) =>
    () => {
      this.setState(({ invitees }) => {
        const diffInvitees = differenceBy(invitees, [data], "id")
        const isInvited = diffInvitees.length !== invitees.length

        return {
          showInvitees: true,
          invitees: isInvited ? diffInvitees : invitees.concat([{ ...data }]),
        }
      })
    }

  handleViewMore = (section) => () => {
    const { sectionOpenItemCount } = this.state
    const showMoreCount = 8
    const count = sectionOpenItemCount[section]
    const nextCount = count + showMoreCount
    this.setState({
      sectionOpenItemCount: {
        ...sectionOpenItemCount,
        [section]: nextCount,
      },
    })
  }

  handleAddInvitees = ({ addedInvitees, removedInvitees }) => {
    this.setState(({ invitees }) => ({
      invitees: differenceBy(invitees, removedInvitees, "id").concat(
        addedInvitees
      ),
    }))
  }

  openPreviousTeeupParticipants = (teeup) => () => {
    const componentName = "previousTeeupParticipants"
    const componentId = `${componentName}.${teeup.title}`
    const closeComponent = () => "Navigation.pop(componentId)"
    // Navigation.push(this.props.componentId, {
    //     component: {
    //         id: componentId,
    //         name: componentName,
    //         options: {
    //             topBar: {
    //                 visible: false,
    //                 drawBehind: true,
    //                 animate: false,
    //             },
    //         },
    //         passProps: {
    //             invitees: this.state.invitees,
    //             teeup,
    //             onDone: ({ addedInvitees, removedInvitees }) => {
    //                 this.handleAddInvitees({
    //                     addedInvitees,
    //                     removedInvitees,
    //                 })
    //                 closeComponent()
    //             },
    //             onClose: closeComponent,
    //         },
    //     },
    // })
  }

  onContactsScrolled = (scrolledUp) => {
    const { isSearchEnabled, isKeyboardOpen, showInvitees } = this.state

    if (isSearchEnabled && isKeyboardOpen) {
      this.searchRef.current.blur()
    }

    if (showInvitees !== scrolledUp) {
      this.setState({ showInvitees: scrolledUp })
    }
  }

  handleAddManualContacts = async ({ contactType, data }) => {
    const { invitees, manualInvitees } = this.state
    const { alreadyInvitedIds = [] } = this.props
    const userPhoneNumbers = this.props.userPhoneNumbers.map((number) =>
      number.value.replace(/\s+/g, "")
    )
    const userEmails = this.props.userEmails.map((email) =>
      email.value.toLowerCase()
    )
    const manualInviteesValues = manualInvitees.map((invite) => invite?.value)
    const updatedData = [...manualInviteesValues, ...data]
    const newUniqueData = [...new Set(updatedData)]

    if (updatedData.length !== newUniqueData.length) {
      showToastAboutDuplicates()
    }
    const contactMechanismValues = newUniqueData
      .map((value) => value.replace(/\s+/g, "").toLowerCase())
      .filter((value) => {
        if (
          (contactType === contactTypes.PHONE &&
            userPhoneNumbers.includes(value)) ||
          (contactType === contactTypes.EMAIL && userEmails.includes(value))
        ) {
          Toast.show(
            `You can't add yourself in TeeUp. Contact was removed from the list`,
            {
              bottomOffset: 8,
            }
          )
          return false
        }
        if (value.trim().length === 0) {
          return false
        }
        return true
      })
    let formattedInvitees = invitees
    try {
      let { users, contacts, rest } = await batchVerifyAndGetUserInfo(
        contactMechanismValues
      )
      if (manualInvitees.length) {
        this.manualContactId = Math.min(
          ...manualInvitees.map((invite) => invite.id)
        )
      }

      const formattedManualContacts = rest.map((value) => {
        // use negative id to avoid duplication to user id
        const alreadyAddedInvite = manualInvitees.find(
          (invite) => invite.value === value
        )
        if (!alreadyAddedInvite) {
          this.manualContactId--
        }
        return alreadyAddedInvite
          ? alreadyAddedInvite
          : prepareManualContact({
              manualContactId: this.manualContactId,
              contactType,
              value,
            })
      })
      formattedInvitees = formattedInvitees.concat(
        users,
        contacts.map((contact) => formatContact(contact)),
        formattedManualContacts
      )
      this.setState({
        manualInvitees: [...formattedManualContacts],
      })
    } catch (err) {
      console.warn("Error batchVerifyAndGetUserInfo: ", err.message)
    }

    this.setState({
      invitees: getInviteesWithoutDuplicates({
        formattedInvitees,
        alreadyInvitedIds,
        showToastAboutDuplicates,
      }),
    })
  }

  openAddContacts = (contactType) => {
    const { verifiedCountryCode, setModalContent } = this.props
    const content = (
      <>
        <AddManualContactsContainer
          onDone={(contacts) => {
            this.handleAddManualContacts(contacts)
            // this.setState({ isModalOpened: false })
          }}
          onClose={() => setModalContent(null)}
          contactType={contactType}
        />
      </>
    )
    setModalContent(content)
    // this.setState({ isModalOpened: true, isMenuOpened: false, modalContent: content })

    // Navigation.push(this.props.componentId, {
    //     component: {
    //         id: componentId,
    //         name: componentName,
    //         passProps: {
    //             contactType,
    //             defaultCountry: verifiedCountryCode,
    //             onDone: (contacts) => {
    //                 this.handleAddManualContacts(contacts)
    //                 closeComponent()
    //             },
    //             onClose: closeComponent,
    //         },
    //     },
    // })
  }

  openMenu = () => {
    if (!this.state.isMenuOpened) {
      this.setState({ isMenuOpened: true })
    }
  }

  closeMenu = () => {
    this.setState({ isMenuOpened: false })
  }

  openMoreMenu = (callback) => {
    this.setState({ isMoreMenuOpened: true }, () => {
      callback()
    })
  }

  closeMoreMenu = () => {
    this.setState({ isMoreMenuOpened: false })
  }

  handleToggleExpand = (id) => () => {
    this.setState(({ expandedContactItemId }) => ({
      expandedContactItemId: {
        ...expandedContactItemId,
        [id]: !expandedContactItemId[id],
      },
    }))
  }

  handleAndroidBackPress = () => {
    const { onHeaderLeft } = this.props
    onHeaderLeft ? onHeaderLeft() : this.handleCancel()
    return true
  }

  componentWillUnmount() {
    const { isTeeupInvite, setInvitees } = this.props

    // if (isTeeupInvite) {
    //     setInvitees([]);
    // }
  }

  handleChangeContactType = ({ id, selectedContact, selectedIndex, data }) => {
    // Change default contact type, i.e. if default is phone number, but you want to invite via email
    let invitees = [...this.state.invitees]
    const { contactType, label, value } = selectedContact

    let updatedContact = {
      ...data,
      contactType,
      contactLabel: label ? label : contactType,
      value,
      selectedIndex,
    }
    // contactActions.changeContactDefaultType(updatedContact)

    let alreadyInvited = false
    for (let i = 0, len = invitees.length; i < len; i++) {
      let invitee = invitees[i]
      if (invitee.id === id) {
        invitees[i] = updatedContact
        alreadyInvited = true
        break
      }
    }

    if (!alreadyInvited) {
      invitees.push(updatedContact)
    }

    this.setState({ invitees })
  }

  handleSearchEnabled = () =>
    this.setState({ isSearchEnabled: true }, () =>
      this.searchRef.current.focus()
    )

  handleSearchDisable = () =>
    !this.state.searchText && this.setState({ isSearchEnabled: false })

  handleTagRender = (tag) => {
    const { value, id, onPress } = tag
    const { selectedTag } = this.state
    const isTagSelected = selectedTag ? id === selectedTag.id : false
    const onPressEvent = onPress ? onPress : this.handleTagTouch

    const selectedStyle = {
      wrapper: isTagSelected ? styles.selectedWrapperStyle : {},
      image: isTagSelected ? styles.selectedImageStyle : {},
      text: isTagSelected ? styles.selectedTextStyle : {},
    }

    const classes = isTagSelected ? "svg-wrapper tag-selected" : "svg-wrapper"
    return (
      <TouchableOpacity key={`${value} + ${id}`} onPress={onPressEvent(tag)}>
        <View style={[styles.tagWrapper, selectedStyle.wrapper]}>
          <ReactSVG src={tagIcon} className={classes} />
          <Text style={[styles.tagText, selectedStyle.text]}>{value}</Text>
        </View>
      </TouchableOpacity>
    )
  }

  handleTagTouch = (selectedTag) => () => {
    this.handleSearch(null, selectedTag)
  }

  handleSearch = (newSearchText, newSelectedTag, sortByName) => {
    this.setState(
      handleSearchLogic(
        this.props,
        this.state,
        newSearchText,
        newSelectedTag,
        sortByName
      )
    )
  }

  sendDirectMessage = (item) => {
    // routeIndividualChat({ user: item })
  }

  // My contacts stuff
  renderMyContactItem = ({ item, isInvitees }, noHover) => {
    const {
      setContent,
      close,
      myContacts,
      handleOnInviteesDone,
      fromCreateTeeup,
    } = this.props
    const { selectedTag, isMoreMenuOpened, invitees } = this.state

    return (
      <MyContactItem
        item={item}
        isInvitees={isInvitees}
        onRemoveInvitee={this.handleToggleContact({ data: item })}
        selectedTags={selectedTag ? [selectedTag] : []}
        sendDirectMessage={this.sendDirectMessage}
        onRefetchContacts={this.handleRefetchContacts}
        connectUsers={this.connectUsers}
        isMoreMenuOpened={isMoreMenuOpened}
        openMoreMenu={this.openMoreMenu}
        closeMoreMenu={this.closeMoreMenu}
        setContent={setContent}
        close={close}
        myContacts={myContacts}
        invitees={invitees}
        noHover={fromCreateTeeup || noHover}
        handleOnInviteesDone={handleOnInviteesDone}
        onDone={() => this.setState({ invitees: [] })}
      />
    )
  }

  connectUsers = async (userId, message) => {
    if (!this.state.loading) {
      this.setState({ loading: true })
        // requestActions
        // .sendRequest(userId, message, requestTypes.inviteToConnect)
        .then((error) => {
          if (error) {
            Toast.show(error)
          } else {
            this.handleRefetchContacts()
            this.setState({ loading: false })
            Toast.show("Connection request sent!")
          }
        })
        .finally(this.setState({ loading: false }))
    }
  }

  handleSelectPress = () => {
    const { foundContacts } = this.state

    // showBatchSelectContacts({
    //     contactList: foundContacts,
    //     onTagsChange: this.handleTagsChange,
    //     onRefetchContacts: this.handleRefetchContacts,
    // })
  }

  onSettingsPress = () => {
    // showUserTags({
    //     pushToComponentId: this.props.componentId,
    // })
  }

  handleTagsChange = () => {
    this.handleRefetchContacts()
  }

  handleRefetchContacts = async () => {
    const { contactSyncedAt } = this.props
    let allowSynchronization =
      this.props.contactSyncSettings.allowSynchronization

    if (!contactSyncedAt && !allowSynchronization) {
      // const res = await settingsActions.postSettings({
      //     contacts: {
      //         ...this.props.contactSyncSettings,
      //         ...this.props.contactSyncSettings,
      //         allowSynchronization: true,
      //     },
      // })
      // allowSynchronization = res.contacts.allowSynchronization
      // console.log(res)
    }

    // contactActions.resyncContactsBook()
  }

  openContactsFilterModal = () => {
    this.setState({ isContactFilterOpened: true })
  }

  closeContactsFilterModal = () => {
    this.setState({ isContactFilterOpened: false })
  }

  rememberSelectedTag = (currentTag) => {
    this.setState({ currentTag })
  }

  render() {
    const {
      invitees,
      searchText,
      foundContacts,
      expandedContactItemId,
      sectionOpenItemCount,
      isSearchEnabled,
      selectedTag,
      showInvitees,
      loadingContacts,
      isMenuOpened,
      isModalOpened,
      modalContent,
      isContactFilterOpened,
    } = this.state

    const {
      viewHeader,
      isInitInvitationScreen,
      myContacts,
      onHeaderLeft,
      lightTheme,
      onClose,
      showButtons,
      tags,
      isTeeupInvite,
      handleOnInviteesDone,
      fromContact,
      fromCreateTeeup,
      openContactsFilterModal,
      closeOptionWithAnimations,
      inviteToNewTeeup,
      combinedPeople,
      noHover,
      fromTeeupTooltip,
    } = this.props
    const isSearching = Boolean(searchText || selectedTag)
    // const additionalStyle = isTeeupInvite ? {top: 5} : fromCreateTeeup ? {top: 0} : myContacts ? {right: 15, top: 0} : {};
    const additionalStyle = isTeeupInvite
      ? {
          top: fromTeeupTooltip ? "45px" : 5,
          left: fromTeeupTooltip && "calc((100vw - 660px)/2 + 670px)",
        }
      : fromCreateTeeup
      ? { top: 0, right: "-360px" }
      : myContacts
      ? { left: "calc((100vw - 608px)/2 + 624px)", top: 0 }
      : {}

    const buttons = [
      {
        title: "Phone numbers",
        image: require("../../assets/images/phoneCopy.png"),
        callback: () => this.openAddContacts("phone"),
      },
      {
        title: "Email Addresses",
        image: require("../../assets/images/envelope3x.png"),
        callback: () => this.openAddContacts("email"),
      },
    ]
    let searchResultsText =
      (foundContacts ? foundContacts.length : 0) + " Results for "
    if (searchText) {
      searchResultsText += '"' + searchText + '"'
    }

    if (selectedTag) {
      searchResultsText +=
        (searchText ? " with " : "") +
        'people tagged "' +
        selectedTag.value +
        '"'
    }

    return (
      <>
        <AddInviteesView
          isMenuOpened={isMenuOpened}
          showInvitees={showInvitees}
          viewHeader={viewHeader}
          invitees={invitees}
          searchText={searchText}
          // foundContacts={combinedPeople ? combinedPeople : foundContacts}
          foundContacts={foundContacts}
          sectionOpenItemCount={sectionOpenItemCount}
          expandedContactItemId={expandedContactItemId}
          userTags={tags}
          isSearchEnabled={isSearchEnabled}
          onOpenMenu={this.openMenu}
          onToggleContact={this.handleToggleContact}
          onToggleExpand={this.handleToggleExpand}
          onChangeContactType={this.handleChangeContactType}
          onDone={myContacts ? this.handleSelectPress : this.handleDone}
          onAdd={this.handleAdd}
          onClose={onHeaderLeft ? null : this.handleCancel}
          onBack={onHeaderLeft}
          doneTitle={
            myContacts ? "Select" : isInitInvitationScreen ? "Next" : "Done"
          }
          onSearchTextChange={this.handleSearchTextChange}
          onResetSearchText={this.handleResetSearchText}
          onViewMore={this.handleViewMore}
          onOpenPreviousTeeupParticipants={this.openPreviousTeeupParticipants}
          loadingContacts={loadingContacts}
          onContactsScrolled={this.onContactsScrolled}
          onSearchEnable={this.handleSearchEnabled}
          onSearchDisable={this.handleSearchDisable}
          onTagRender={this.handleTagRender}
          searchRef={this.searchRef}
          testID={"AddInviteesView"}
          sortingType={selectedTag ? selectedTag.id : null}
          searchResultsText={searchResultsText}
          isSearching={isSearching}
          myContacts={myContacts}
          renderMyContactItem={myContacts ? this.renderMyContactItem : null}
          lightTheme={lightTheme}
          onSettingsPress={this.onSettingsPress}
          showButtons={showButtons}
          closeContent={onClose}
          isTeeupInvite={isTeeupInvite}
          fromContact={fromContact}
          fromCreateTeeup={fromCreateTeeup}
          openPhone={() => this.openAddContacts("phone")}
          openEmail={() => this.openAddContacts("email")}
          isContactFilterOpened={this.isContactFilterOpened}
          openContactsFilterModal={this.openContactsFilterModal}
          noHover={noHover}
        />
        {isMenuOpened && (
          <div className="addContact" onClick={this.closeMenu}>
            <div className="addContact__content">
              {buttons.map((button) => (
                <div className="addContact__button" onClick={button.callback}>
                  <img
                    className="addContact__image"
                    src={button.image}
                    alt=""
                  />
                  <span className="addContact__text">{button.title}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        {isModalOpened && (
          <div className="addinvitees__overlay">
            <div
              className="addinvitees__modal"
              onClick={(event) => event.stopPropagation()}
            >
              {modalContent}
            </div>
          </div>
        )}
        {invitees.length > 0 && (
          <Invitees
            renderMyContactItem={this.renderMyContactItem}
            invitees={invitees}
            additionalStyles={additionalStyle}
            handleOnInviteesDone={handleOnInviteesDone}
            onDone={() => this.setState({ invitees: [] })}
            inviteToNewTeeup={inviteToNewTeeup}
            closeOptionWithAnimations={closeOptionWithAnimations}
            noHover
            fromCreateTeeup={fromCreateTeeup}
          />
        )}
      </>
    )
  }
}

// AddInviteesContainer.defaultProps = {
//     isInitInvitationScreen: false,
//     invitees: [],
// }

// AddInviteesContainer.propTypes = {
//     componentId: PropTypes.string.isRequired,
//     userId: PropTypes.number.isRequired,
//     invitees: PropTypes.array.isRequired,
//     onDone: PropTypes.func,
//     contacts: PropTypes.array.isRequired,
//     userPhoneNumbers: PropTypes.array.isRequired,
//     userEmails: PropTypes.array.isRequired,
//     viewHeader: PropTypes.string,
//     isInitInvitationScreen: PropTypes.bool,
//     alreadyInvitedIds: PropTypes.array,
//     myContacts: PropTypes.bool, // Means this screen is used for My Contacts
//     requests: PropTypes.array.isRequired,
//     onHeaderLeft: PropTypes.func,
//     lightTheme: PropTypes.bool,
//     tags: PropTypes.array,
//     skipContactsLoading: PropTypes.bool,
//     verifiedCountryCode: PropTypes.string,
//     contactSyncedAt: PropTypes.string,
//     contactSyncSettings: PropTypes.object,
// }

const mapStateToProps = (state, passedProps) => {
  const { isTeeupInvite, isGroupInvite, alreadyInvitedIds } = passedProps
  const userId = selectUserId(state)
  const nonBookContacts =
    alreadyInvitedIds && isTeeupInvite
      ? selectAllContactsWithoutInvitedToTeeup(state)
      : alreadyInvitedIds && isGroupInvite
      ? selectAllContactsWithoutInvitedToGroup(state)
      : selectAllContacts(state)
  const contacts = [...selectBookContacts(state), ...nonBookContacts]
  // const contacts = selectAllContacts(state)
  // const contactsByTags =
  //    true
  //         ? selectContactsByTagsWithoutInvitedToTeeup(state)
  //         : false
  //         ? selectContactsByTagsWithoutInvitedToGroup(state)
  //         : selectContactsByTags(state)
  const contactsByTags = selectContactsByTags(state)
  const tags = selectTags(state)
  // const userPhoneNumbers = selectMechanismsPhone(state)
  // const userEmails = selectMechanismsEmail(state)
  const requests = selectConnectionRequests(state)
  const verifiedCountryCode = selectUserVerifiedCountryCode(state)
  const contactSyncedAt = selectContactsSyncedAt(state)
  const contactSyncSettings = selectContactsSyncSettings(state)
  const userPhoneNumbers = []
  const userEmails = []

  return {
    userId,
    contacts,
    contactsByTags,
    tags,
    userPhoneNumbers,
    userEmails,
    requests,
    verifiedCountryCode,
    contactSyncedAt,
    contactSyncSettings,
    userPhoneNumbers,
    userEmails,
  }
}

// const mapStateToProps = (state) => ({
//     contactsByTags: selectInvitees(state),
//     contacts: selectRecommendedInvitees(state),
//     userPhoneNumbers: [],
//     userEmails: [],
//     tags: selectTags(state),
//   });

export default connect(mapStateToProps, null)(AddInviteesContainer)
