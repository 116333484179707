import axios from "axios"
import jwtDecode from "jwt-decode"

import { setItem, getItem } from "@utils/localStorage"
import { unixSecondsFromNow } from "@utils/dateUtils"
import endpoints from "@config/endpoints"
import { store } from "./index"
import actionTypes from "@actions/actionTypes"
import { fetchUserInfo } from "@actions/userActions"
import { loginProviders } from "@configs/enums"

class ApiService {
  init() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })

    const userInfo = getItem("userInfo")
    if (userInfo) {
      this.attachHeaders({
        Authorization: `Bearer ${userInfo.accessToken}`,
      })

      this.checkSession(userInfo)
    }
  }

  attachHeaders = (headers) => {
    Object.assign(this.client.defaults.headers, headers)
  }

  createSession = (userInfo) => {
    setItem("userInfo", userInfo)
    // const decoded = jwtDecode(userInfo.accessToken)
    // console.log(jwtDecode(userInfo.accessToken))
    // console.log(unixSecondsFromNow(decoded.exp), 'unixSecondsFromNow')

    this.attachHeaders({
      Authorization: `Bearer ${userInfo.accessToken}`,
    })
  }

  async checkSession(savedUserInfo) {
    const { accessToken, refreshToken } = savedUserInfo
    const decoded = jwtDecode(accessToken)

    const minRefreshSeconds = 60 * 60 * 10
    let expiresInSeconds = unixSecondsFromNow(decoded.exp)
    let userInfo = savedUserInfo

    const { loginType = loginProviders.email } = savedUserInfo

    if (
      loginType === loginProviders.email &&
      expiresInSeconds < minRefreshSeconds
    ) {
      // less than 10 hours

      const rememberMe = getItem("rememberMe")

      if (!rememberMe) {
        await localStorage.clear()
        return
      }

      const body = {
        refreshToken,
      }

      const response = await this.client.put(endpoints.token, body)

      userInfo = response.data
      userInfo.loginType = savedUserInfo.loginType
      this.createSession(userInfo)
    } else if (expiresInSeconds < minRefreshSeconds) {
      await localStorage.clear()

      return
    }

    store.dispatch({
      type: actionTypes.SET_USER_INFO,
      payload: userInfo,
    })

    fetchUserInfo()
  }
}

const api = new ApiService()

export default api
