import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import ContactBookContainer from "@components/ContactBook/ContactBookContainer"
import ContactBookInviteToTeeup from "@components/ContactBook/ContactBookInviteToTeeup"
import InvitationSent from "./InvitationSent"

import { images } from "@utils/imageUtils"
import { formatInvitees } from "../../utils/contactUtils"

import { selectDraftInvitees } from "@selectors/invitees"

import {
  fetchTeeups,
  addTeeupInvitees,
  gotTeeupParts,
  fetchTeeupUsersById,
} from "@actions/teeupActions"
import { getContacts } from "@actions/createTeeupActions"
import { fetchRequests } from "@actions/requestActions"

import { withRouter } from "react-router"

import "./index.scss"

const ContactsPageNd = ({
  fetchTeeups,
  getContacts,
  fetchRequests,
  draftInvitees,
  gotTeeupParts,
}) => {
  const [activeTeeup, setActiveTeeup] = useState({})
  const [invitees, setInvitees] = useState(draftInvitees)

  //hover or select effects
  const [activeHoverButton, setActiveHoverButton] = useState(0)
  const [activeInviteesTab, setActiveInviteesTab] = useState(1)
  const [isInviteToTeeupOpen, setIsInviteToTeeupOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isInvitationSent, setIsInvitationSent] = useState(false)
  //---

  useEffect(() => {
    fetchTeeups() // load teeups
    getContacts() // load contacts
    fetchRequests() 
  }, [])

  useEffect(() => {
    if (activeTeeup.id) {
      (async () => {
        setLoading(true)

        const formattedInvitees = formatInvitees(invitees)
        const activeTeeupId = activeTeeup.id

        await addTeeupInvitees(activeTeeupId, formattedInvitees)
        const teeupUsers = await fetchTeeupUsersById(activeTeeupId)

        gotTeeupParts([teeupUsers])
        setLoading(false)
        setIsInviteToTeeupOpen(false)
        setIsInvitationSent(true)
        setInvitees([])
      })()
    }
  }, [activeTeeup])

  const openInviteToTeeupModal = () => {
    setIsInviteToTeeupOpen(true)
  }

  const closeInviteToTeeupModal = () => {
    setIsInviteToTeeupOpen(false)
  }

  return (
    <div
      className="contacts-bookNd"
      style={{
        overflowY: (isInviteToTeeupOpen || isInvitationSent) && "hidden"
      }}
    >
      {isInviteToTeeupOpen && (
        <ContactBookInviteToTeeup
          closeInviteToTeeupModal={closeInviteToTeeupModal}
          setActiveTeeup={setActiveTeeup}
          loading={loading}
          resetInvitees={() => setInvitees([])}
        />
      )}
      {isInvitationSent && (
        <InvitationSent close={() => setIsInvitationSent(false)} />
      )}
      <div className="contacts-bookNd__content">
        <h3 className="contacts-bookNd__title">Contact Book</h3>
        <ContactBookContainer
          activeInviteesTab={activeInviteesTab}
          setActiveInviteesTab={setActiveInviteesTab}
          setInvitees={setInvitees}
          invitees={invitees}
          fromContactsBook={true}
          openInviteToTeeupModal={openInviteToTeeupModal}
        />

        {invitees.length > 0 && (
          <div className="contacts-book-invitees">
            <h3 className="contacts-book-invitees__title">
              {invitees.length} person selected
            </h3>
            <div className="contacts-book-invitees__content">
              {invitees.length > 0 &&
                invitees.map(({ avatar, firstName, name, userType, label, emails, id, value }) => {
                    return (
                      <div
                        className="inviteNd-invitees__item"
                        key={avatar + name}
                      >
                        {avatar ? (
                          <div
                            className="inviteNd-invitees__avatar"
                            style={{ backgroundImage: `url(${avatar})` }}
                          ></div>
                        ) : (
                          <div className="inviteNd-invitees__avatar inviteNd-invitees__avatar--manual">
                            {name ? name[0] : label[0]}
                          </div>
                        )}
                        <h5 className="inviteNd-invitees__name">{name ? name : label}</h5>
                        <div
                          className="inviteNd-invitees__cross--wrapper"
                          onClick={() => {
                            setInvitees(invitees.filter((item) => item.id !== id))
                          }}
                        >
                          {/* <img
                            src={images.ndInviteesCross}
                            className="inviteNd-invitees__cross"
                          /> */}
                          <div className="inviteNd-invitees__cross"></div>
                        </div>
                      </div>
                    )
              })}
              <div className="contacts-book-invitees__buttons">
                <div
                  className="contacts-book-invitees__button"
                  onClick={() => setIsInviteToTeeupOpen(true)}
                  onMouseOver={() => setActiveHoverButton(1)}
                  onMouseLeave={() => setActiveHoverButton(0)}
                >
                  <img
                    src={activeHoverButton === 1 ? images.ndInviteBlankActive : images.ndInviteBlank}
                    style={{ marginRight: 4 }}
                    alt="Invite"
                  />
                  Invite
                </div>
                <div
                  className="contacts-book-invitees__button"
                  onMouseOver={() => setActiveHoverButton(2)}
                  onMouseLeave={() => setActiveHoverButton(0)}
                >
                  <img
                    src={activeHoverButton === 2 ? images.ndInviteTagActive : images.ndInviteTag}
                    style={{ marginRight: 4 }}
                    alt="Tag"
                  />
                  Tag
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const draftInvitees = selectDraftInvitees(state)

  return {
    draftInvitees,
  }
}

export default connect(mapStateToProps, {
  fetchTeeups,
  getContacts,
  fetchRequests,
  gotTeeupParts,
})(withRouter(ContactsPageNd))
