import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"

export const styles = StyleSheet.create({
  flex: { flex: 1 },
  row: { flexDirection: "row" },
  nudgesContainer: {
    paddingVertical: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.white,
    marginTop: Units.responsiveValue(3),
  },
  nudgeItemWrapper: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: Units.responsiveValue(16),
    marginHorizontal: Units.responsiveValue(16),
  },
  responseTextWrapper: {
    marginLeft: Units.responsiveValue(8),
    marginBottom: Units.responsiveValue(8),
    flexDirection: "row",
    alignItems: "flex-end",
  },
  avatar: { marginRight: Units.responsiveValue(8) },
  avatarStyle: {
    position: "relative",
    top: -Units.responsiveValue(3),
    left: Units.responsiveValue(5),
    marginRight: Units.responsiveValue(2),
  },
  userStatusIcon: {
    top: Units.responsiveValue(22),
    left: Units.responsiveValue(22),
  },
  suggestionNudge: { marginTop: Units.responsiveValue(8) },
  nudgeIcon: {
    marginRight: Units.responsiveValue(4),
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
  },
  nudgeAnswerStatusRow: {
    marginTop: 4,
  },
  nudgeAnswerUsername: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.darkGrey2,
  },
  nudgeAnswerStatus: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.black2,
  },
  currentUserAnswerTitle: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.warmGrey2,
  },
  currentUserAnswerStatus: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.warmGrey2,
  },
  nudgeAnswer: {
    paddingTop: Units.responsiveValue(8),
    marginVertical: Units.responsiveValue(8),
    borderRadius: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.white,
  },
  nudgeAnswerStatusImage: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
    alignItems: "center",
    justifyContent: "center",
  },
  respondButton: {
    alignSelf: "flex-start",
    paddingVertical: Units.responsiveValue(5),
    paddingHorizontal: Units.responsiveValue(24),
    borderRadius: Units.responsiveValue(15),
    marginTop: Units.responsiveValue(16),
    borderColor: AppColors.brand.pink,
    borderWidth: Units.responsiveValue(1),
  },
  respondButtonTitle: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.pink,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  emptyComponent: {
    backgroundColor: AppColors.brand.white,
    alignItems: "center",
    justifyContent: "center",
    marginTop: Units.responsiveValue(4),
    height: "80%",
  },
  nudgesImage: {
    width: Units.rem(10),
    height: Units.rem(10),
  },
  nudgesTitle: {
    color: AppColors.brand.darkGrey3,
    fontSize: Units.responsiveValue(24),
    fontFamily: AppFonts.family.medium,
  },
  nudgesText: {
    ...AppFonts.bigMedium,
    color: AppColors.brand.darkGrey2,
    paddingTop: Units.responsiveValue(7),
    paddingHorizontal: Units.responsiveValue(33),
    textAlign: "center",
    letterSpacing: -Units.responsiveValue(0.78),
    maxWidth: "470px",
  },
  avatarImageStyle: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  listPadding: {
    paddingBottom: 50,
  },
})

export const nudgeItemContentWrapper = (isCreatedByCurrentUser) => ({
  flex: 1,
  padding: 12,
  maxWidth: "450px",
  borderRadius: 8,
  backgroundColor:
    AppColors.brand[isCreatedByCurrentUser ? "lightBlue4" : "lightGrey"],
})

export const nudgeTitle = (isCreatedByCurrentUser) => ({
  ...AppFonts.smallDemibold,
  color: AppColors.brand[isCreatedByCurrentUser ? "lightBlue116" : "darkGrey2"],
})

export const nudgeType = (isCreatedByCurrentUser) => ({
  ...AppFonts.biggerMedium,
  color: AppColors.brand[isCreatedByCurrentUser ? "darkBlue" : "black2"],
  letterSpacing: -Units.responsiveValue(0.34),
  lineHeight: Units.responsiveValue(22),
})

export const nudgeTimestamp = (isCreatedByCurrentUser) => ({
  alignSelf: "flex-end",
  ...AppFonts.smallDemibold,
  color: AppColors.brand[isCreatedByCurrentUser ? "lightBlue116" : "warmGrey2"],
  paddingTop: Units.responsiveValue(8),
  marginRight: Units.responsiveValue(8),
})

export const nudgeAnswerContent = (position, length) => ({
  flexDirection: "row",
  alignItems: "center",
  ...(position !== 0 && {
    borderTopWidth: 1,
    borderColor: AppColors.brand.grey243,
  }),
  ...(position !== length - 1 && {
    paddingBottom: 8,
  }),
})
