import React, { useState, useEffect } from "react"
import ReactTooltip from "react-tooltip"
import ReactDOM from "react-dom"
import "./index.scss"

const StatusTooltip = ({ tooltipText, tooltipWidth }) => {
  const [isOpen, setIsOpen] = useState(true)

  const hideTooltip = () => {
    setIsOpen(false)
    ReactTooltip.hide()
  }

  return (
    <div className="status-tooltip-wrapper">
      <ReactTooltip
        className="extraClassTeeup"
        id="status-tooltip"
        backgroundColor=" #222"
        place="top"
        effect="solid"
        afterHide={() => setIsOpen(true)}
        delay-hide={10000}
      >
        {tooltipText}
      </ReactTooltip>
    </div>
  )
}

export default StatusTooltip
