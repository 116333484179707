import React from "react"
import { TouchableOpacity, Image } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

import { changeSettings } from "@actions/settingsActions"
import notificationActions from "@actions/notificationActions"
import {
  selectSoundSettings,
  selectNotificationLevel,
  selectUserNotificationSettings,
} from "@selectors/user"
// import { checkNotificationsStatus } from '@utils/permissionUtils'
import { openNotificationSettings } from "@utils/linkUtils"
import { notificationLevels } from "@configs/enums"
import strings from "@i18n"
// import { push } from '@nav'
import { images } from "@utils/imageUtils"
import { AppColors } from "@theme/"
import NotificationSettingsView from "./NotificationSettingsView"
import closeIcon from "../../../assets/images/close.svg"
import NotificationLevel from "./NotificationLevel"
import { SectionListStyles } from "../../gamePlansCard/suggestionsList/suggestionListStyles"
import { getRealNotificationKeys } from "@utils/settingsUtils"

class NotificationSettingsContainer extends React.Component {
  componentDidMount() {
    this.checkNotifications()
  }

  state = {
    content: null,
  }

  closeNotificationLevel = () => {
    this.setState({ content: null })
  }

  openNotificationLevel = () => {
    this.setState({ content: <NotificationLevel /> })
  }

  checkNotifications = async () => {
    try {
      // await checkNotificationsStatus()
      this.props.startUserNotifications()
    } catch (e) {
      // console.log('NotificationSettingsContainer error: ', e)
    }
  }

  static options() {
    return {
      popGesture: false,
    }
  }

  toggleNotification = (notificationKey, enabled) => {
    const { notifications } = this.props
    const newNotifications = { ...notifications }
    getRealNotificationKeys(notificationKey).forEach((realKey) => {
      newNotifications[realKey] = enabled
    })

    changeSettings({ notifications: newNotifications })
    // settingsActions.postSettings({ notifications: newNotifications })
  }

  onToggleSoundSettings = (applicationSoundEnabled) => {
    const formattedSettings = {
      applicationSoundEnabled,
    }

    changeSettings(formattedSettings)
  }

  openSystemSettings = () => {
    openNotificationSettings()
  }

  toggleDisableAll = (disableAll) => {
    const { notifications } = this.props
    const formattedSettings = {
      notifications: {
        ...notifications,
        disableAll,
      },
    }

    changeSettings(formattedSettings)
    // settingsActions.postSettings(formattedSettings)
  }

  toggleSoundSettings = (applicationSoundEnabled) => {
    const formattedSettings = {
      applicationSoundEnabled,
    }

    changeSettings(formattedSettings)
    // settingsActions.postSettings(formattedSettings)
  }

  linkToScreen =
    (screenName, topbarTitle, props = {}) =>
    () => {
      const options = {
        topBar: {
          title: {
            text: topbarTitle,
          },
          backButton: {
            icon: images.back,
            color: AppColors.brand.pink,
            visible: true,
            showTitle: false,
          },
        },
      }
      // push(this.props.componentId, screenName, false, props, options)
    }

  getNotificationLevelText = () => {
    const { notificationLevel } = this.props

    switch (notificationLevel) {
      case notificationLevels.muteEverything: {
        return strings.notificationsSettings.muteEverything.title
      }
      case notificationLevels.importantOnly: {
        return strings.notificationsSettings.importantOnly.title
      }
      case notificationLevels.conversation: {
        return strings.notificationsSettings.conversation.title
      }
      case notificationLevels.everything: {
        return strings.notificationsSettings.everything.title
      }
    }
  }

  render() {
    const { content } = this.state
    const {
      applicationSoundEnabled,
      closeOption,
      notifications,
      notificationDisabled,
    } = this.props
    const notificationLevel = this.getNotificationLevelText()

    return (
      <>
        {/* <div className="settings__top"> 
                    <TouchableOpacity
                        onPress={closeOption}
                    >
                        <Image
                            source={images.back}
                            style={SectionListStyles.cancelBtnImage}
                        />
                    </TouchableOpacity>
                    <span>Notification Settings</span>
                    <div></div>
                </div> */}
        {content && (
          <div
            className="settings__modal settings__modal-content"
            style={{ animationName: "none" }}
            onClick={(event) => event.stopPropagation()}
          >
            <div className="settings__top">
              <img
                onClick={this.closeNotificationLevel}
                src={closeIcon}
                alt=""
                style={{ width: 20, height: 20 }}
              />
              <span>Notification Level</span>
              <div></div>
            </div>
            {content}
          </div>
        )}
        <NotificationSettingsView
          applicationSoundEnabled={applicationSoundEnabled}
          notificationLevel={notificationLevel}
          openNotificationLevel={this.openNotificationLevel}
          onToggleSoundSettings={this.onToggleSoundSettings}
          openSystemSettings={this.openSystemSettings}
          linkToScreen={this.linkToScreen}
          toggleNotification={this.toggleNotification}
          notifications={notifications}
          toggleDisableAll={this.toggleDisableAll}
          toggleSoundSettings={this.toggleSoundSettings}
          notificationDisabled={notificationDisabled}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const applicationSoundEnabled = selectSoundSettings(state)
  const notificationLevel = selectNotificationLevel(state)
  const notificationSettings = selectUserNotificationSettings(state)

  return {
    applicationSoundEnabled,
    notificationLevel,
    notifications: notificationSettings,
    notificationDisabled: notificationSettings.disableAll,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startUserNotifications: notificationActions.startUserNotifications,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSettingsContainer)

NotificationSettingsContainer.propTypes = {
  notificationLevel: PropTypes.string,
  applicationSoundEnabled: PropTypes.bool,
  startUserNotifications: PropTypes.func.isRequired,
}
