import React from "react"
import {
  View,
  TouchableWithoutFeedback,
  Animated,
  BackHandler,
} from "react-native"
import PropTypes from "prop-types"
// import navigationConfig from '@nav/NavigationConfig'
import { ModalStyles } from "./styles"

class DialogOverlayWrapper extends React.Component {
  // constructor(props) {
  //     super(props)
  // }

  // static options() {
  //     return {
  //         statusBar: {
  //             ...navigationConfig.darkStatusBar,
  //         },
  //     }
  // }

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleAndroidBackPress
    )
  }

  componentWillUnmount() {
    if (this.backHandler) {
      this.backHandler.remove()
    }
  }

  handleAndroidBackPress = () => {
    this.props.handleClose()
    return true
  }
  render() {
    const { handleClose, children, opacity } = this.props

    return (
      <Animated.View
        style={[ModalStyles.overlayContainer, opacity && { opacity }]}
      >
        <TouchableWithoutFeedback
          style={ModalStyles.flex}
          onPress={handleClose}
        >
          <View style={ModalStyles.dialogWrapper}>
            <TouchableWithoutFeedback>
              <View style={ModalStyles.dialogContainer}>{children}</View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Animated.View>
    )
  }
}

DialogOverlayWrapper.propTypes = {
  handleClose: PropTypes.func,
  opacity: PropTypes.number,
  children: PropTypes.element,
}

export default DialogOverlayWrapper
