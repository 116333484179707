import React, { memo } from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"
// import * as Animatable from 'react-native-animatable'

import styles from "./messageTimestampStyles"

const MessageTimestamp = ({
  isTimestampsVisible,
  isInitialMessage,
  timestamp,
  timestampDate,
}) => (
  <View>
    {!!timestampDate && (
      <Text numberOfLines={1} style={styles.messageTimestampDate}>
        {timestampDate}
      </Text>
    )}
    <Text numberOfLines={1} style={styles.messageTimestamp}>
      {timestamp}
    </Text>
  </View>
)

MessageTimestamp.propTypes = {
  timestampDate: PropTypes.string,
  timestamp: PropTypes.string,
  isTimestampsVisible: PropTypes.bool,
  isInitialMessage: PropTypes.bool,
}

export default memo(MessageTimestamp)
