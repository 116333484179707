import React, { useEffect, useState } from "react"

import ProfilePagePublicInfo from "../ProfilePagePublicInfo"
import ProfilePageTags from "../ProfilePageTags"

import './index.scss'

const ProfilePageAbout = ({ userInfo }) => {
  return (
    <div className="profile-about">
      {userInfo && (
        <>
          <div className="profile-about-item">
            <h4 className="profile-about-item__title">About</h4>
            <div className="profile-about-item__line"></div>
            {userInfo.profile.about ? userInfo.profile.about : "No information"}
          </div>
          <ProfilePagePublicInfo userInfo={userInfo} />
          <ProfilePageTags userInfo={userInfo} />
        </>
      )}
    </div>
  )
}

export default ProfilePageAbout;
