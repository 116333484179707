import actionTypes from "../actions/actionTypes"

const initialState = {
  isModalOpen: false,
  draftTeeup: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLOSE_CREATE_TEEUP:
      return {
        ...state,
        isModalOpen: false,
        draftTeeup: null,
      }
    case actionTypes.OPEN_CREATE_TEEUP:
      return {
        ...state,
        isModalOpen: true,
        draftTeeup: action.payload,
      }
    default:
      return state
  }
}
