import React, { useEffect, useState } from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

import useInPersonTabState from "../../Hooks/useInPersonTabState"
import { images } from "@utils/imageUtils"

import './index.scss'

const SearchField = () => {
  const {
    isLocationSelected,
    setIsLocationSelected,
    setMapCenter,
    setActivePlace,
    activePlace
  } = useInPersonTabState()
  const [temporaryPlace, setTemporaryPlace] = useState('')

  const handleSelect = (address) => {
    geocodeByAddress(address)
    .then((results) => {
      setActivePlace(results[0].formatted_address)
      
      return getLatLng(results[0])
    })
    .then((latLng) => {
      setIsLocationSelected(true)
      setMapCenter({
        lat: latLng.lat,
        lng: latLng.lng
      })
    })
    .catch((error) => console.error("Error", error))
  }

  return (
    <div className="suggest-in-person__search-field">
      <PlacesAutocomplete
        value={temporaryPlace}
        onChange={(temporaryPlace) => {
          setTemporaryPlace(temporaryPlace)
        }}
        onSelect={async (value, placeId, suggestion) => {
          handleSelect(suggestion.description)
        }}
      >
        {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="suggest-in-person__search--wrapper">
              <img src={images.ndSuggestLocation} alt="suggest location" />
              <input
                type="text"
                className="suggest-in-person__search"
                {...getInputProps({ placeholder: "Search here for a location..." })}
              />
              <div className="suggest-in-person-places__list">
                {suggestions.map((suggestion) => (
                  <div
                    className="suggest-in-person-places__item"
                    {...getSuggestionItemProps(suggestion)}
                  >
                    <div className="suggest-in-person-places__icon">
                      <img
                        src={images.ndSuggestSearchItem}
                        alt="search item"
                      />
                    </div>

                    <div className="suggest-in-person-places__info">
                      <h5 className="suggest-in-person-places__name">
                        {suggestion.formattedSuggestion.mainText}
                      </h5>
                      {suggestion.formattedSuggestion.secondaryText}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        }
      </PlacesAutocomplete>
    </div>
  )
}

export default SearchField

