import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom"

import ContactBookInviteToTeeup from "../../../components/ContactBook/ContactBookInviteToTeeup";
import InvitationSent from "pages/ContactPageNd/InvitationSent";
import TagUser from "@components/TagUser";

import { images } from "@utils/imageUtils";

import {
  addTeeupInvitees,
  gotTeeupParts,
  fetchTeeupUsersById,
} from "@actions/teeupActions"

import './index.scss'

const dropdownItem = [
  {
    title: "Invite User to TeeUp",
    image: images.ndProfileInviteUser
  },
  {
    title: "Edit Tags",
    image: images.ndProfileTagUser
  },
  // {
  //   title: "Block User",
  //   image: images.ndProfileBlockUser
  // }
]

const ProfileDropDown = ({ close, userInfo, setIsTagUser }) => {
  const [activeTab, setActiveTab] = useState(-1)
  const [activeTeeup, setActiveTeeup] = useState({})
  const [loading, setLoading] = useState(false)
  const [isInvitationSent, setIsInvitationSent] = useState(false)

  useEffect(() => {
    if (activeTeeup.id) {
      (async () => {
        setLoading(true)

        const formattedInvitees = [{ id: userInfo.profile.id }]
        const activeTeeupId = activeTeeup.id

        await addTeeupInvitees(activeTeeupId, formattedInvitees)
        const teeupUsers = await fetchTeeupUsersById(activeTeeupId)

        gotTeeupParts([teeupUsers])
        setLoading(false)
        setActiveTab(-1)
        setIsInvitationSent(true)
      })()
    }
  }, [activeTeeup])

  const changeUserTags = () => {
    setIsTagUser(true)
  }
  
  return (
    <div
      className="profile-dropdown"
      onClick={(e) => e.stopPropagation()}
    >
      {activeTab === 0 && ReactDOM.createPortal(
        <ContactBookInviteToTeeup
          closeInviteToTeeupModal={() => {
            setActiveTab(-1)
            close()
          }}
          setActiveTeeup={setActiveTeeup}
          loading={loading}
          resetInvitees={() => {}}
        />,
        document.getElementById("modal-root")
      )}
      {activeTab === 1 && ReactDOM.createPortal(
        <TagUser
          close={() => {
            setActiveTab(-1)
            close()
          }}
          selectedPeople={[userInfo]}
          fromProfile={true}
          userInfo={userInfo}
          changeUserTags={changeUserTags}
        />,
        document.getElementById("modal-root")
      )}
      {isInvitationSent && (
        <InvitationSent close={() => setIsInvitationSent(false)} />
      )}
      <div className="profile-dropdown__content">
        {dropdownItem.map((item, index) => (
          <div
            key={index}
            className="profile-dropdown__item"
            onClick={() => setActiveTab(index)}
          >
            <img 
              src={item.image}
              alt="Invite"
              className="profile-dropdown__item--icon"
            />
            <p className="profile-dropdown__item--text">{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfileDropDown
