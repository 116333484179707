import React, { useState, useMemo } from "react"

import { SuggestModalHeader, SuggestModalWrapper } from "../components"
import GeneralTimeTab from "./GeneralTimeTab/GeneralTimeTab"
import { GeneralTimeProvider } from "./GeneralTimeTab/GeneralTimeContext/GeneralTimeContext"
import TimeSlotsTab from "./TimeSlotsTab/TimeSlotsTab"
import TimeWheelTab from "./TimeWheelTab/TimeWheelTab"
import Modal from "@components/Modal"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

const SUGGEST_WHEN_TABS = [
  { id: "generalTime", name: "General Time" },
  { id: "timeWheel", name: "Time Wheel" },
  { id: "timeSlots", name: "Time Slots" },
]

const SuggestWhenModal = () => {
  const [activeTab, setActiveTab] = useState(SUGGEST_WHEN_TABS[0].id)

  const { closeSuggestWhenModal } = useTeeUpPage()

  const handleTabClick = (tabId) => {
    setActiveTab(tabId)
  }

  const handleClose = () => {
    closeSuggestWhenModal()
  }

  const content = useMemo(() => {
    switch (activeTab) {
      case "timeWheel":
        return <TimeWheelTab />
      case "timeSlots":
        return <TimeSlotsTab />
      case "generalTime":
      default:
        return (
          <GeneralTimeProvider>
            <GeneralTimeTab />
          </GeneralTimeProvider>
        )
    }
  }, [activeTab])

  return (
    <Modal>
      <SuggestModalWrapper>
        <SuggestModalHeader
          title="Suggest When"
          tabs={SUGGEST_WHEN_TABS}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          handleClose={handleClose}
        />
        {content}
      </SuggestModalWrapper>
    </Modal>
  )
}

export default SuggestWhenModal
