import { useDispatch, useSelector } from "react-redux"

import { selectMapCenter, selectIsLocationSelected, selectActivePlace } from "@selectors/suggest"
import actionTypes from "@actions/actionTypes"

const useInPersonTabState = () => {
  const dispatch = useDispatch()
  const mapCenter  = useSelector(selectMapCenter)
  const isLocationSelected = useSelector(selectIsLocationSelected)
  const activePlace = useSelector(selectActivePlace)

  const setMapCenter = (payload) => {
    dispatch({ type: actionTypes.SET_SUGGEST_IN_PERSON_MAP_CENTER, payload })
  }

  const setIsLocationSelected = (payload) => {
    dispatch({ type: actionTypes.SET_SUGGEST_IN_PERSON_IS_LOCATION_SELECTED, payload })
  }

  const setActivePlace = (payload) => {
    dispatch({ type: actionTypes.SET_SUGGEST_IN_PERSON_ACTIVE_PLACE, payload })
  }

  return {
    mapCenter,
    isLocationSelected,
    activePlace,
    setMapCenter,
    setIsLocationSelected,
    setActivePlace
  }
}

export default useInPersonTabState;
