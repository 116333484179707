const CalendarState = {
  calendarEventsByDay: {},
  teeupEventsByDay: {
    noExactDate: [],
  },
  calendarEvents: {},
  teeupEvents: {},
  calendarDays: [],
  avaliableCalendars: [],
  calendarPermissions: null,
  suggestWeek: [],
  isBeforeCalendarDate: false,
  isDisplayedCalendar: false,
}

export default CalendarState
