import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

const ViewStyles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  scrollContainerTag: {
    flexGrow: 0,
    // paddingLeft: Units.responsiveValue(12),
    flexDirection: "row",
  },
  buttonTag: {
    paddingHorizontal: Units.responsiveValue(8),
    paddingVertical: Units.responsiveValue(3),
    marginRight: Units.responsiveValue(8),
    borderRadius: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonTitleTag: {
    fontFamily: "greycliffcf-demibold-salt",
    color: "#222222",
    lineHeight: Units.responsiveValue(13),
    letterSpacing: -Units.responsiveValue(0.3),
    fontSize: 12,
  },
  closeTag: {
    tintColor: AppColors.brand.warmGrey2,
    height: Units.responsiveValue(10),
    width: Units.responsiveValue(7),
    marginLeft: Units.responsiveValue(6),
  },
  background: {
    flex: 1,
    // backgroundColor: AppColors.brand.whiteGrey,
    overflow: "auto",
    // height: '95vh',
    // minHeight: '95vh',
    height: "100%",
  },
  header: {
    paddingHorizontal: Units.rem(1),
  },
  headerTitle: {
    ...AppFonts.header20,
    marginRight: Units.responsiveValue(18),
    lineHeight: Units.responsiveValue(21),
    letterSpacing: -Units.responsiveValue(0.4),
    height: "27px",
    textTransform: "capitalize",
  },
  listHeader: {
    paddingBottom: Units.responsiveValue(8),
    borderBottomWidth: 1,
    borderBottomColor: "#d6d6d6",
    textTransform: "capitalize",
  },
  shadow: {
    shadowColor: AppColors.brand.black,
    shadowOffset: { height: 1 },
    shadowOpacity: 0.24,
    shadowRadius: 4,
    backgroundColor: AppColors.brand.whiteGrey,
  },
  backgroundEmpty: {
    paddingTop: Units.rem(1.25),
    backgroundColor: AppColors.brand.white,
    paddingHorizontal: Units.rem(1.5),
    minHeight: "auto",
    height: "370px",
    paddingVertical: "60px",
    width: "640px",
    // marginLeft: 'auto',
    // marginRight: 'auto',
    marginLeft: "-10px",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
  container: {
    // flex: 1,
    flexDirection: "column",
    flex: 1,
    paddingTop: Units.rem(1.5),
  },
  listContainer: {
    marginBottom: "15vh",
  },
  flex: {
    flex: 1,
    justifyContent: "center",
  },
  contentContainer: {
    paddingBottom: Units.rem(6.5),
  },
  buttonsContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  splitter: {
    width: Units.rem(2),
  },
  itemSeparatorStyle: {
    height: Units.responsiveValue(1),
    backgroundColor: AppColors.brand.lightGrey,
  },
  padding: {
    paddingTop: Units.rem(1),
  },
  buttonTitle: {
    color: AppColors.brand.white,
    ...AppFonts.bigDemibold,
  },
  shadowTop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    opacity: 0.08,
    height: Units.rem(0.5),
  },
  shadowBottom: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.08,
    transform: [{ scaleY: -1 }],
    height: Units.rem(0.5),
  },
  emptyListContainer: {
    // paddingBottom: Units.responsiveValue(isIOS ? 150 : 0),
    // bottom: Units.responsiveValue(isIOS ? 0 : 30),
  },
  sortImage: {
    width: Units.responsiveValue(10),
    height: Units.responsiveValue(10),
    marginRight: Units.responsiveValue(8),
  },
  sortTitle: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(21),
    letterSpacing: -Units.responsiveValue(0.24),
    color: AppColors.brand.warmGrey3,
    whiteSpace: "nowrap",
    fontSize: "13px",
  },
})

// TODO
const ViewProps = {
  createTeeupButton: {
    title: "Create TeeUp",
    color: AppColors.brand.pink,
    accesoryStyle: {
      tintColor: AppColors.brand.white,
    },
    small: true,
    style: {
      paddingTop: Units.rem(0.25),
      paddingBottom: Units.rem(0.5),
      marginTop: Units.rem(1.5),
      marginBottom: Units.rem(1.5),
      shadowColor: "black",
      shadowOffset: { height: 2 },
      shadowOpacity: 0.24,
      shadowRadius: 4,
      borderRadius: Units.rem(2),
    },
  },
  logoutButton: {
    title: "Logout",
    color: AppColors.brand.pink,
    style: {
      marginTop: Units.rem(1.5),
      shadowColor: "black",
      shadowOffset: { height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 8,
      borderRadius: Units.rem(2),
    },
  },
  gradientShadow: {
    colors: ["black", "white"],
  },

  archive: {
    type: "ionicicons",
    name: "ios-archive",
    size: Units.responsiveValue(28),
    color: AppColors.brand.white,
    style: {
      alignSelf: "center",
    },
  },
  unArchive: {
    type: "font-awesome5",
    name: "box-open",
    size: Units.responsiveValue(28),
    color: AppColors.brand.white,
    style: {
      alignSelf: "center",
    },
  },
  trash: {
    type: "font-awesome5",
    name: "trash",
    size: Units.responsiveValue(28),
    color: AppColors.brand.white,
    style: {
      alignSelf: "center",
    },
  },
}

export { ViewStyles, ViewProps }
