/**
 * @prettier
 * @flow
 * */

import React from "react"
import { View, StyleSheet } from "react-native"
// import { WheelPicker } from 'react-native-wheel-picker-simple'
import WheelPicker from "react-simple-wheel-picker"

import {
  hourTo24Format,
  hourTo12Format,
  pickerDateArray,
  getHoursArray,
  getFiveMinutesArray,
  getAmArray,
} from "./Utils"
import {
  generateMinutesArray,
  getDateWithTimezone,
  getTimeWithTimezone,
} from "@utils/dateUtils"
import Toast from "@ui/toast"
import "./index.scss"

const millisecondsPerDay = 1000 * 60 * 60 * 24
const HOUR = 60

// type Props = {
//     initDate: string,
//     hours: Array<number>,
//     minutes: Array<string>,
//     minuteInterval?: number,
//     onDateSelected: Date => void,
//     startDate: string,
//     minimumDate: string,
//     daysCount: number,
//     days: Array<number>,
//     hideDate?: boolean,
//     hideHours?: boolean,
//     hideMinutes?: boolean,
//     hideAM?: boolean,
//     format24?: boolean,
//     timezone?: string,
// }

// type State = {
//     selectedDate: Date,
//     daysAfterSelectedDate: number,
//     initDayInex: number,
//     initHourInex: number,
//     initMinuteInex: number,
//     initAmInex: number,
// }

export default class DatePicker extends React.PureComponent {
  constructor(props) {
    super(props)
    const {
      startDate,
      minutes,
      minuteInterval,
      timezone,
      minutesPicker,
      hoursPicker,
    } = props
    let minutesArray
    if (!!minutes) {
      minutesArray = minutes
    } else if (!!minuteInterval) {
      minutesArray = generateMinutesArray(minuteInterval)
    } else {
      minutesArray = getFiveMinutesArray()
    }
    const selectedDate = this.props.initDate
      ? new Date(this.props.initDate)
      : new Date()
    const selectedDateWithTZ = getDateWithTimezone(selectedDate, timezone)
    const time12format = hourTo12Format(hoursPicker)
    const time24format = selectedDateWithTZ.hours()
    const millisBetween =
      selectedDateWithTZ.milliseconds() -
      getTimeWithTimezone(new Date(), timezone)
    let millisBetweenStartDate
    let daysStartDate = 0
    if (startDate) {
      millisBetweenStartDate =
        getTimeWithTimezone(new Date(startDate), timezone) -
        getTimeWithTimezone(new Date(), timezone)
      daysStartDate = millisBetweenStartDate / millisecondsPerDay
    }
    const days = millisBetween / millisecondsPerDay
    const daysAfterSelectedDate = Math.round(daysStartDate)
    const initDayInex = startDate
      ? Math.round(days) - Math.round(daysStartDate)
      : Math.round(days)
    const initHourInex = this.props.format24
      ? time24format
      : Number(time12format[0]) - 1
    const minutesCount = minutesArray.length
    const initMinuteInex = Math.round(minutesPicker / (HOUR / minutesCount))

    const initAmInex = time12format[1] === "AM" ? 0 : 1

    this.state = {
      daysAfterSelectedDate,
      initDayInex,
      selectedDate,
      selectedDateWithTZ,
      initHourInex,
      initMinuteInex,
      initAmInex,
      minutes: minutesArray,
    }
  }

  getCutoffHours() {
    const { miminumDate, format24, timezone } = this.props

    const selectedDateWithTZ = getDateWithTimezone(miminumDate, timezone)
    const time12format = hourTo12Format(selectedDateWithTZ.hours())
    const time24format = selectedDateWithTZ.hours()

    const initHourIndex = format24 ? time24format : Number(time12format[0]) - 1

    return initHourIndex
  }

  render() {
    const {
      format24,
      backgroundColor,
      hideDate,
      hideHours,
      hideMinutes,
      hideAM,
    } = this.props
    return (
      <View style={[styles.container, { backgroundColor }]}>
        {!hideDate && this.renderDays()}
        {!hideHours && this.renderHours()}
        {!hideMinutes && this.renderMinutes()}
        {!format24 && !hideAM && this.renderAm()}
      </View>
    )
  }

  renderDays() {
    const { startDate, days, daysCount } = this.props
    const { initDayInex } = this.state
    // return (
    //     <WheelPicker
    //         style={styles.dateWheelPicker}
    //         {...this.props}
    //         data={days || pickerDateArray(startDate, daysCount)}
    //         onItemSelected={this.onDaySelected}
    //         initPosition={initDayInex}
    //     />
    // )
  }

  renderHours() {
    const { hours, format24 } = this.props
    const { initHourInex } = this.state
    const fullData = hours || getHoursArray(format24)
    const formattedHours = fullData.map((item, index) => ({
      id: String(index),
      value: item,
    }))

    return (
      <div className="wheel-picker">
        <WheelPicker
          data={formattedHours}
          selectedID={formattedHours[initHourInex].id}
          height={175}
          itemHeight={40}
          onChange={(target) => {
            this.onHourSelected(+target.id)
            this.setState({ initHourInex: +target.id })
          }}
          color="#76767666"
          activeColor="#222"
          backgroundColor="#fff"
          width={50}
          shadowColor="transparent"
        />
      </div>
    )
  }

  renderMinutes() {
    const { minutes, initMinuteInex } = this.state
    const formattedMinutes = minutes.map((item, index) => ({
      id: String(index),
      value: item,
    }))

    return (
      <div className="wheel-picker">
        <WheelPicker
          data={formattedMinutes}
          onChange={(target) => {
            this.onMinuteSelected(+target.id)
            this.setState({ initMinuteInex: +target.id })
          }}
          selectedID={formattedMinutes[initMinuteInex].id}
          color="#76767666"
          activeColor="#222"
          backgroundColor="#fff"
          height={175}
          itemHeight={40}
          width={50}
          shadowColor="transparent"
        />
      </div>
    )
  }

  renderAm() {
    const { initAmInex } = this.state
    const formattedAm = getAmArray().map((item, index) => ({
      id: String(index),
      value: item,
    }))

    return (
      <div className="wheel-picker">
        <WheelPicker
          data={formattedAm}
          onChange={(target) => {
            this.onAmSelected(+target.id)
            this.setState({ initAmInex: +target.id })
          }}
          selectedID={formattedAm[initAmInex].id}
          color="#76767666"
          activeColor="#222"
          backgroundColor="#fff"
          height={175}
          itemHeight={40}
          width={50}
          shadowColor="transparent"
        />
      </div>
    )
  }

  onDaySelected = (position) => {
    let selectedDate = this.state.selectedDateWithTZ
    const daysAfterSelectedDate = this.state.daysAfterSelectedDate
    const hours = selectedDate.hours()
    const minutes = selectedDate.minutes()

    const { timezone, startDate, days, daysCount } = this.props
    const data = days || pickerDateArray(startDate, daysCount)
    if (data[position] === "Today") {
      selectedDate = getDateWithTimezone(new Date(), timezone)
    } else {
      selectedDate = getDateWithTimezone(new Date(), timezone).add(
        startDate ? daysAfterSelectedDate + position : position,
        "days"
      )
    }
    selectedDate.setHours(hours)
    selectedDate.setMinutes(minutes)
    this.onDateSelected(selectedDate)
  }

  onHourSelected = (position) => {
    const selectedDate = this.state.selectedDateWithTZ
    const { hours, format24 } = this.props
    const data = hours || getHoursArray(format24)

    if (this.props.format24) {
      selectedDate.hours(Number(data[position]))
    } else {
      const time12format = hourTo12Format(selectedDate.hours()) ////?
      const newTime12Format = `${data[position]} ${time12format[1]}`
      const selectedHour24format = hourTo24Format(newTime12Format)

      selectedDate.hours(selectedHour24format)
    }

    this.onDateSelected(selectedDate)
  }

  onMinuteSelected = (position) => {
    const selectedDate = this.state.selectedDateWithTZ
    const { minutes } = this.state
    selectedDate.minutes(Number(minutes[position]))
    this.onDateSelected(selectedDate)
  }

  onAmSelected = (position) => {
    const selectedDate = this.state.selectedDateWithTZ
    const time12format = hourTo12Format(selectedDate.hours())
    const newTime12Format = `${time12format[0]} ${getAmArray()[position]}`
    const selectedHour24format = hourTo24Format(newTime12Format)
    selectedDate.hours(selectedHour24format)
    this.onDateSelected(selectedDate)
  }

  onDateSelected = (selectedDateWithTZ) => {
    this.setState(
      (state) => ({ selectedDateWithTZ }),
      this.props.onDateSelected(selectedDateWithTZ)
    )
    // if (this.props.onDateSelected) {
    //     this.props.onDateSelected(selectedDateWithTZ)
    // }
  }
}

let styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
  },
  wheelPicker: {
    height: 150,
    width: null,
    flex: 1,
  },
  dateWheelPicker: {
    height: 150,
    width: null,
    flex: 3,
  },
})
