import React, { useState, useRef } from "react"
import { View, Animated, Text, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"
import sortBy from "lodash/sortBy"
import ReactTooltip from "react-tooltip"
// import ParsedText from 'react-native-parsed-text'

import { animate0to1 } from "@utils/animateUtils"
import { getUsernameOrName } from "@utils/contactUtils"
import { getCalendarFormat } from "@utils/dateUtils"
import DraggableSuggestionRow from "@components/gamePlansCard/draggableSuggestionRow"
import styles, {
  InitialMessageStyles,
  StyledGameplanWrapper,
  StyledThreeDots,
} from "./statusMessageStyles"

import {
  setInitialMessageAnimatedHeight,
  setInitialMessageCollapsedHeight,
  setInitialMessageHeightCalculated,
  setInitialMessageExpandedHeight,
  getInitialMessageOptions,
} from "@utils/messagesUtils"

import threeDotsIcon from "../../../../assets/images/three-dots.svg"
import { images } from "@utils/imageUtils"
import { connect } from "react-redux"

const getWrapper = (isAnimated) => (isAnimated ? Animated.View : View)

const InitialMessage = ({
  teeupTitle,
  item,
  senders,
  people,
  suggestions,
  permissions,
  expanded,
  onPress,
  handleOnLongPress,
  isTeeupCancelled,
  isMyMessage,
  isAnimated = false,
  onLayout,
  passedRef,
  style,
  animatedStyle,
  isSuggestionOpen,
  newDesign,
  ...otherProps
}) => {
  const [expandedStartMessage, setExpandedStartMessage] = useState(false)
  const [expandedStartSuggestions, setExpandedStartSuggestions] =
    useState(false)
  let teeupCreator = {
    id: 0,
    avatar: null,
    name: "",
  }
  if (senders[0]) {
    teeupCreator = senders[0]
  }

  let invitationMessage = ""
  if (item.text) invitationMessage = item.text.trim()

  let inviteesNames = []
  if (item.invitees)
    inviteesNames = item.invitees
      .map((invitee) => {
        const inviteeId = invitee.senderId || invitee.id
        if (invitee.senderId === item.senderId) {
          return null
        }
        let name = people[inviteeId]
          ? people[inviteeId].username || people[inviteeId].name
          : "Unknown"

        if (!name || name.length === 0) {
          name = "Unknown"
        }
        return name
      })
      .filter((item) => item)

  let inviteesText = null

  if (inviteesNames.length) {
    inviteesNames.slice(0, inviteesNames.length / 2).forEach((name, index) => {
      if (index === 0 || inviteesNames.length === 1)
        return (inviteesText = name)
      if (index === inviteesNames.length / 2 - 1)
        return (inviteesText += ` and ${name}`)
      return (inviteesText += `, ${name}`)
    })
  }
  const GameplanOption = ({ gameplan, user, testID }) => {
    const [threeDots, setThreeDots] = useState(false)
    const gameplanRef = useRef(null)
    return (
      <StyledGameplanWrapper
        onMouseOver={() => setThreeDots(true)}
        onMouseLeave={() => {
          ReactTooltip.hide(gameplanRef.current)
          setThreeDots(false)
        }}
      >
        <DraggableSuggestionRow
          testID={testID}
          draggable={false}
          handleOnLongPress={(props) =>
            handleOnLongPress(null, gameplan)(props)
          }
          name={user.username || user.name || "Unknown"}
          timestamp={getCalendarFormat(gameplan.timestamp)}
          suggestion={suggestions[gameplan.id]}
          fullContainer
          canSelectGameplan={permissions.allowSelectGameplan}
          canDecideGameplan={permissions.allowDecideGameplan}
          canChangeGameplanOptions={permissions.allowChangeGameplanOptions}
          isTeeupCancelled={isTeeupCancelled}
          showShadow
          fromInitialGameplans
        />
        {threeDots && (
          <StyledThreeDots
            src={threeDotsIcon}
            alt=""
            ref={gameplanRef}
            className="three-dots"
            isMyMessage={isMyMessage}
            data-for="message-tooltip"
            data-tip={gameplan.id}
            onClick={() => ReactTooltip.show(gameplanRef.current)}
          />
        )}
      </StyledGameplanWrapper>
    )
  }
  const Wrapper = getWrapper(isAnimated)
  const wrapperProps = isAnimated
    ? {
        style: [styles.mainContainer, animatedStyle],
      }
    : {
        style: [styles.mainContainer, style],
        onLayout: onLayout,
        ref: passedRef,
        ...otherProps,
      }
  const gameplansSortedByOptionId = sortBy(
    item.gameplans,
    ({ optionId }) => optionId
  )

  return (
    <>
      <Wrapper {...wrapperProps}>
        {gameplansSortedByOptionId.length > 0 && (
          <View
            style={[InitialMessageStyles(isMyMessage).box, { marginTop: 2 }]}
            onClick={() =>
              setExpandedStartSuggestions(!expandedStartSuggestions)
            }
          >
            {expandedStartSuggestions ? (
              <View style={InitialMessageStyles(isMyMessage).section}>
                <Text
                  style={[
                    InitialMessageStyles(isMyMessage).headingText,
                    // InitialMessageStyles(isMyMessage).boldText,
                    { fontSize: "12px" },
                  ]}
                >
                  And kicked off the discussion with suggestions
                  {/* Suggestions */}
                </Text>
                {gameplansSortedByOptionId.map((gameplan, index) => {
                  const user = people[gameplan.createdBy] || {}
                  return (
                    <GameplanOption
                      key={"" + index}
                      gameplan={gameplan}
                      user={user}
                      testID={`teeupSuggestionInitial-${index}`}
                    />
                  )
                })}
                <img
                  src={images.expandStartMessageArrow}
                  style={{
                    transform: expandedStartSuggestions && "rotate(180deg)",
                    width: "14px",
                    height: "14px",
                    marginTop: "16px",
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setExpandedStartSuggestions(!expandedStartSuggestions)
                  }
                />
              </View>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    fontSize: "12px",
                    color: isMyMessage
                      ? "rgb(8, 95, 142)"
                      : "rgb(118, 118, 118)",
                    fontFamily: "greycliffcf-demibold-salt",
                  }}
                >
                  And kicked off the discussion with suggestions
                </p>
                <img
                  src={images.expandStartMessageArrow}
                  style={{
                    transform: expandedStartSuggestions && "rotate(180deg)",
                    width: "14px",
                    height: "14px",
                    marginLeft: "22px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setExpandedStartSuggestions(!expandedStartSuggestions)
                  }
                />
              </div>
            )}
          </View>
        )}
        <View
          style={[
            InitialMessageStyles(isMyMessage).box,
            {
              minWidth:
                isSuggestionOpen && document.documentElement.clientWidth <= 1366
                  ? "280px"
                  : "295px",
            },
            newDesign && {
              backgroundColor: isMyMessage ? "#e0e8ee" : "white",
              borderWidth: "1px",
              borderColor: isMyMessage ? "transparent" : "#e3e5e8",
              color: "#222222",
            },
          ]}
          onClick={() => setExpandedStartMessage(!expandedStartMessage)}
        >
          <Text
            style={[
              invitationMessage
                ? InitialMessageStyles(isMyMessage).headingText
                : InitialMessageStyles(isMyMessage).headingOnBodyText,
              {
                fontSize: "12px",
              },
              newDesign && {
                color: "#222",
              },
            ]}
          >
            {isMyMessage ? "You" : getUsernameOrName(teeupCreator)}
            {" started this conversation about "}
            {/* <Text style={InitialMessageStyles(isMyMessage).boldText}>{teeupTitle}</Text> */}
          </Text>
          {!!invitationMessage ? (
            <>
              <Text
                style={[
                  InitialMessageStyles(isMyMessage).boldText,
                  {
                    fontSize: "12px",
                    color: isMyMessage
                      ? "rgb(8, 95, 142)"
                      : "rgb(118, 118, 118)",
                    fontFamily: "greycliff-cf-bold",
                  },
                  newDesign && {
                    color: "#222",
                  },
                ]}
              >
                {teeupTitle}:
              </Text>
              <Text
                // childrenProps={{ allowFontScaling: false }}
                // style={InitialMessageStyles.bodyText}
                // parse={[
                //     {
                //         type: 'url',
                //         renderText: renderLink,
                //         onPress: openUrl,
                //     },
                // ]}
                style={[
                  InitialMessageStyles(isMyMessage).headingText,
                  {
                    fontSize: "17px",
                    marginTop: "6px",
                    paddingBottom: expandedStartMessage && "8px",
                    color: isMyMessage
                      ? "rgb(8, 95, 142)"
                      : "rgb(118, 118, 118)",
                  },
                  newDesign && {
                    color: "#222",
                  },
                ]}
              >
                {item.text}
              </Text>
            </>
          ) : (
            <Text
              style={[
                InitialMessageStyles(isMyMessage).headingText,
                {
                  fontSize: "12px",
                  paddingBottom: expandedStartMessage && "8px",
                },
                newDesign && {
                  color: "#222",
                },
              ]}
            >
              <span style={{ fontFamily: "greycliff-cf-bold" }}>
                {teeupTitle}
              </span>{" "}
              and invited{" "}
              <span style={{ fontFamily: "greycliff-cf-bold" }}>
                {inviteesNames.length}
              </span>{" "}
              people.
            </Text>
          )}
          {expandedStartMessage && !!inviteesText && (
            <View
              style={
                (InitialMessageStyles(isMyMessage).section,
                {
                  borderTop: isMyMessage
                    ? "2px solid rgb(8, 95, 142)"
                    : "2px solid rgb(227 227 227)",
                  paddingTop: "8px",
                })
              }
            >
              <Text
                style={[
                  InitialMessageStyles(isMyMessage).headingText,
                  InitialMessageStyles(isMyMessage).boldText,
                  {
                    fontSize: "12px",
                    color: isMyMessage
                      ? "rgb(8, 95, 142)"
                      : "rgb(118, 118, 118)",
                  },
                  newDesign && {
                    color: "#222",
                  },
                ]}
              >
                Invitees
              </Text>
              <Text
                style={[
                  InitialMessageStyles(isMyMessage).bodyText,
                  {
                    fontSize: "17px",
                    fontFamily: "greycliff-cf-medium",
                    marginTop: "2px",
                  },
                  newDesign && {
                    color: "#222",
                  },
                ]}
              >
                {inviteesText}
              </Text>
            </View>
          )}
          <img
            src={images.expandStartMessageArrow}
            alt=""
            style={{
              transform: expandedStartMessage && "rotate(180deg)",
              width: "14px",
              height: "14px",
              position: "absolute",
              right: "12px",
              bottom: "12px",
              cursor: "pointer",
            }}
            onClick={() => setExpandedStartMessage(!expandedStartMessage)}
          />
        </View>
      </Wrapper>
    </>

    // <Wrapper {...wrapperProps}>
    //   <View style={InitialMessageStyles(isMyMessage).box}>
    //     <Text
    //       style={
    //         invitationMessage
    //           ? InitialMessageStyles(isMyMessage).headingText
    //           : InitialMessageStyles(isMyMessage).headingOnBodyText
    //       }
    //     >
    //       {isMyMessage ? "You" : getUsernameOrName(teeupCreator)}
    //       {" started this conversation about "}
    //       <Text style={InitialMessageStyles(isMyMessage).boldText}>{teeupTitle}</Text>
    //     </Text>
    //     {!!invitationMessage && (
    //       <Text
    //       // childrenProps={{ allowFontScaling: false }}
    //       // style={InitialMessageStyles.bodyText}
    //       // parse={[
    //       //     {
    //       //         type: 'url',
    //       //         renderText: renderLink,
    //       //         onPress: openUrl,
    //       //     },
    //       // ]}
    //       >
    //         {item.text}
    //       </Text>
    //     )}
    //     {!!expanded && (
    //       <>
    //         {!!inviteesText && (
    //           <View style={InitialMessageStyles(isMyMessage).section}>
    //             <Text
    //               style={[
    //                 InitialMessageStyles(isMyMessage).headingText,
    //                 InitialMessageStyles(isMyMessage).boldText,
    //               ]}
    //             >
    //               Invitees
    //             </Text>
    //             <Text style={InitialMessageStyles(isMyMessage).bodyText}>
    //               {inviteesText}
    //             </Text>
    //           </View>
    //         )}
    //         {gameplansSortedByOptionId.length > 0 && (
    //           <View style={InitialMessageStyles(isMyMessage).section}>
    //             <Text
    //               style={[
    //                 InitialMessageStyles(isMyMessage).headingText,
    //                 InitialMessageStyles(isMyMessage).boldText,
    //               ]}
    //             >
    //               Suggestions
    //             </Text>
    //             {gameplansSortedByOptionId.map((gameplan, index) => {
    //               const user = people[gameplan.createdBy] || {};
    //               return (
    //                 <GameplanOption
    //                   key={'' + index}
    //                   gameplan={gameplan}
    //                   user={user}
    //                   testID={`teeupSuggestionInitial-${index}`}
    //                 />
    //               );
    //             })}
    //           </View>
    //         )}
    //       </>
    //     )}
    //     <TouchableOpacity
    //       style={InitialMessageStyles(isMyMessage).expandToggle}
    //       onPress={onPress}
    //     >
    //       <Text style={InitialMessageStyles(isMyMessage).expandToggleText}>
    //         Show {expanded ? "less" : "more"}
    //       </Text>
    //     </TouchableOpacity>
    //   </View>
    // </Wrapper>
  )
}

InitialMessage.propTypes = {
  teeupTitle: PropTypes.string,
  item: PropTypes.object,
  senders: PropTypes.array,
  people: PropTypes.object,
  suggestions: PropTypes.object,
  permissions: PropTypes.object,
  expanded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onPress: PropTypes.func,
  isTeeupCancelled: PropTypes.bool,
  isAnimated: PropTypes.bool,
  onLayout: PropTypes.func,
  handleOnLongPress: PropTypes.func,
  passedRef: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  animatedStyle: PropTypes.object,
}

class InitialMessageContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    const initialMessageOptions = getInitialMessageOptions()
    this.state = {
      collapsedHeight: initialMessageOptions.collapsedHeight || 0,
      expandedHeight: initialMessageOptions.expandedHeight || 0,
      heightCalculated: initialMessageOptions.heightCalculated || false,
      animatedHeight:
        initialMessageOptions.animatedHeight || new Animated.Value(0),
    }
  }

  setHeightCalculated = (callback) => {
    const { collapsedHeight, expandedHeight } = this.state

    if (collapsedHeight && expandedHeight) {
      setInitialMessageHeightCalculated(true)
      this.setState(
        {
          heightCalculated: true,
        },
        callback
      )
    }
  }

  onLayout = () => {
    if (!this._expandedRef) return
    this._expandedRef.measure((x, y, width, height) => {
      if (height) {
        setInitialMessageExpandedHeight(height)
        this.setState({ expandedHeight: height }, () =>
          this.setHeightCalculated()
        )
      }
    })
  }

  onCollapsedLayout = () => {
    if (!this._collapsedRef) return

    this._collapsedRef.measure((x, y, width, height) => {
      if (height) {
        setInitialMessageCollapsedHeight(height)
        this.setState({ collapsedHeight: height }, () =>
          this.setHeightCalculated()
        )
      }
    })
  }

  onPress = () => {
    const { collapsedHeight, expandedHeight } = this.state

    if (!collapsedHeight || !expandedHeight) {
      this.onLayout()
      this.onCollapsedLayout()

      setTimeout(() => {
        this.setHeightCalculated(this.animateAndTriggerOnPress)
      }, 10)
    } else {
      this.animateAndTriggerOnPress()
    }
  }

  animateAndTriggerOnPress = () => {
    const { expanded } = this.props
    if (expanded) {
      this.state.animatedHeight.setValue(0)
    } else {
      this.state.animatedHeight.setValue(1)
    }
    setInitialMessageAnimatedHeight(this.state.animatedHeight)
    this.props.onPress()
  }

  render() {
    const {
      collapsedHeight,
      expandedHeight,
      heightCalculated,
      animatedHeight,
    } = this.state

    const animatedStyle = {
      height: animate0to1(animatedHeight, [collapsedHeight, expandedHeight]),
    }

    return (
      <>
        {heightCalculated && (
          <InitialMessage
            {...this.props}
            onPress={this.onPress}
            animatedStyle={animatedStyle}
          />
        )}

        {!heightCalculated && (
          <InitialMessage
            {...this.props}
            onPress={this.onPress}
            expanded={false}
            onLayout={this.onCollapsedLayout}
            passedRef={(ref) => (this._collapsedRef = ref)}
          />
        )}
        {!heightCalculated && (
          <InitialMessage
            {...this.props}
            expanded={true}
            onLayout={this.onLayout}
            passedRef={(ref) => (this._expandedRef = ref)}
            style={styles.waitingForLayout}
            pointerEvents={"none"}
          />
        )}
      </>
    )
  }
}

InitialMessageContainer.propTypes = {
  expanded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onPress: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    isSuggestionOpen: state.common.isSuggestionOpen,
  }
}

export default connect(mapStateToProps, null)(InitialMessageContainer)
