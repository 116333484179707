import React from "react"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"
import { IconContext } from "react-icons"

import I18n from "@i18n"
import { reactionTypes } from "@configs/mappings"
import {
  teeupListNudgeTypes,
  teeupUserStatusPriorities,
  teeupUserStatus,
  teeupUserStatusKeys,
} from "@configs/enums"
import { AppColors } from "@theme"
import { userStatusForNudgesImages } from "@utils/teeupUtils"

const iconStyles = {
  type: "font-awesome5",
  size: 12,
  color: AppColors.brand.thumbYellow,
  solid: true,
}

export const SuggestionRespondButtonProps = [
  {
    id: reactionTypes.upvote,
    title: I18n.nudgeResponded.worksForMe,
    icon: (
      <IconContext.Provider value={iconStyles}>
        <FaThumbsUp />
      </IconContext.Provider>
    ),
  },
  {
    id: reactionTypes.downvote,
    title: I18n.nudgeResponded.doesntWork,
    icon: (
      <IconContext.Provider value={iconStyles}>
        <FaThumbsDown />
      </IconContext.Provider>
    ),
  },
]

export const AreYouGoingRespondButtonProps = [
  {
    id: teeupUserStatusPriorities.going,
    title: teeupUserStatus.going,
    image: userStatusForNudgesImages(teeupUserStatusKeys.going),
  },
  {
    id: teeupUserStatusPriorities.mightgo,
    title: teeupUserStatus.mightgo,
    image: userStatusForNudgesImages(teeupUserStatusKeys.mightgo),
  },
  {
    id: teeupUserStatusPriorities.notgoing,
    title: teeupUserStatus.notgoing,
    image: userStatusForNudgesImages(teeupUserStatusKeys.notgoing),
  },
]

export const WhereAreYouRespondButtonProps = [
  {
    id: teeupUserStatusPriorities.onmyway,
    title: teeupUserStatus.onmyway,
    image: userStatusForNudgesImages(teeupUserStatusKeys.onmyway),
  },
  {
    id: teeupUserStatusPriorities.runningLate,
    title: teeupUserStatus.runningLate,
    image: userStatusForNudgesImages(teeupUserStatusKeys.runningLate),
  },
  {
    id: teeupUserStatusPriorities.arrived,
    title: teeupUserStatus.arrived,
    image: userStatusForNudgesImages(teeupUserStatusKeys.arrived),
  },
  {
    id: teeupUserStatusPriorities.backedOut,
    title: teeupUserStatus.backedOut,
    image: userStatusForNudgesImages(teeupUserStatusKeys.backedOut),
  },
]

export const getButtonsForResponse = (subTypeId) => {
  switch (subTypeId) {
    case teeupListNudgeTypes.suggestion:
      return SuggestionRespondButtonProps
    case teeupListNudgeTypes.areYouGoing:
      return AreYouGoingRespondButtonProps
    case teeupListNudgeTypes.whereAreYou:
      return WhereAreYouRespondButtonProps
  }
}
