import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"

export const styles = StyleSheet.create({
  alphabetIndexContainer: {
    position: "fixed",
    right: 15,
    top: 275,
    bottom: 0,
  },
  alphabetIndexLetter: {
    fontSize: Units.rem(1),
    color: AppColors.brand.pink,
    fontFamily: "greycliffcf-bold-salt",
    height: Units.rem(1.5),
    cursor: "pointer",
  },
  activeLettercontainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderRadius: Units.responsiveValue(25),
    width: Units.responsiveValue(50),
    height: Units.responsiveValue(50),
    zIndex: 2,
    right: Units.responsiveValue(50),
    backgroundColor: AppColors.brand.blue3,
  },
  activeLetter: {
    fontFamily: "greycliffcf-extrabold-salt",
    color: AppColors.brand.white,
    alignSelf: "center",
    textAlign: "center",
  },
  alphabetContainer: (letterCount, height) => ({
    width: Units.rem(1.25),
    height: letterCount * height,
  }),
  alphabetLetter: {
    textAlign: "center",
    width: Units.rem(1.25),
    height: Units.rem(1.25),
    fontSize: Units.rem(0.75),
    color: AppColors.brand.pink,
    ...AppFonts.smallBold,
  },
})
