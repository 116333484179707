import React from "react"
import { View, Text, Image, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"
import { userTypes } from "@utils/contactUtils"
import { getContactInitials } from "@utils/dataUtils"
import { styles, icons } from "./contactAvatarStyles"
import cross from "../../../assets/images/bold-cross.svg"

export const ContactAvatar = ({
  avatar,
  firstName,
  lastName,
  name,
  contactType,
  userType,
  contactLabel,
  onRemoveInvitee,
  value,
}) => {
  return (
    <TouchableOpacity
      style={styles.contactContainer}
      disabled={!onRemoveInvitee}
      onPress={onRemoveInvitee}
    >
      {avatar ? (
        <Image
          source={{ uri: avatar }}
          style={[styles.contactCircle, styles.contactPhoto]}
        />
      ) : (
        <View style={[styles.contactCircle, styles.contactDefault]}>
          <Text style={styles.contactDefaultText}>
            {getContactInitials({
              firstName,
              lastName,
              name,
              value,
            })}
          </Text>
        </View>
      )}
      {onRemoveInvitee && (
        <View style={[styles.iconWrapper, styles.removeIconWrapper]}>
          <img src={cross} />
        </View>
      )}
      <View style={styles.contactInfo}>
        <Text style={styles.contactName} numberOfLines={1} ellipsizeMode="tail">
          {contactLabel || firstName || lastName || name}
        </Text>
        {!!(
          (!userType || userType === userTypes.MANUAL) &&
          contactType &&
          contactLabel
        ) && <Text style={styles.contactLabel}>{contactType}</Text>}
      </View>
      {(!userType || userType === userTypes.MANUAL) && contactType && (
        <View style={[styles.iconWrapper, styles.contactLabelIconWrapper]}>
          <Image
            style={[
              styles.icon,
              contactType === "phone" ? styles.iconPhone : null,
            ]}
            {...icons[contactType]}
          />
        </View>
      )}
    </TouchableOpacity>
  )
}

ContactAvatar.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  contactLabel: PropTypes.string,
  onRemoveInvitee: PropTypes.func,
  contactType: PropTypes.string,
  userType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.string,
}

ContactAvatar.defaultProps = {
  firstName: "",
  lastName: "",
  name: "",
}
