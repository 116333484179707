import React from "react"
import PropTypes from "prop-types"
import { Image } from "react-native"
import { View, SectionList, Text, TouchableOpacity } from "react-native"

import { Units } from "@theme/"
import { images } from "@utils/imageUtils"

import { CardStyles } from "./teeupStyles"
import { GameplansStyles } from "./gameplansStyles"
import { ReactSVG } from "react-svg"

const GameplansCardView = ({
  visible,
  sections,
  extraData,
  renderHeader,
  renderItem,
  onClickGameplanOptions,
  onClickMore,
  isTeeupDisabled,
  testID,
  draggingSuggestion,
  draggingSuggestionId,
  sectionListRef,
}) => {
  return (
    <View
      style={CardStyles.cardContent}
      pointerEvents="box-none"
      testId={testID}
    >
      <View
        style={[
          GameplansStyles.titleContainer,
          !visible && GameplansStyles.titlePadding,
        ]}
        pointerEvents="box-none"
      >
        <View
          style={[
            GameplansStyles.titleRow,
            visible && GameplansStyles.titlePadding,
          ]}
          pointerEvents="box-none"
        >
          <View pointerEvents="none">
            <Text style={GameplansStyles.title}>Game Plan</Text>
          </View>
          {/* <div className="gameplansCardViewDots">
                        <ReactSVG src={images.threeDotsIconSuggestion} />
                    </div> */}
          {/* <CardActionButton
                        onClickGameplanOptions={onClickGameplanOptions}
                        onClickMore={onClickMore}
                        isTeeupDisabled={isTeeupDisabled}
                    /> */}
        </View>
      </View>

      <SectionList
        style={GameplansStyles.sectionList}
        sections={sections}
        extraData={extraData}
        keyExtractor={(a) => a}
        renderSectionHeader={renderHeader}
        renderItem={renderItem}
        draggingSuggestionId={draggingSuggestionId}
        scrollEnabled={!draggingSuggestion}
        stickySectionHeadersEnabled
        bounces={false}
        ref={sectionListRef}
      />
    </View>
  )
}

const CardActionButton = ({
  onClickMore,
  onClickGameplanOptions,
  isTeeupDisabled,
}) => {
  if (onClickGameplanOptions) {
    return (
      <TouchableOpacity
        disabled={isTeeupDisabled}
        onPress={onClickMore}
        hitSlop={Units.hitSlop()}
      >
        <Image source={images.dotsOnCard} />
      </TouchableOpacity>
    )
  }

  return null
}

CardActionButton.propTypes = {
  onClickGameplanOptions: PropTypes.func,
  onClickMore: PropTypes.func,
  isTeeupDisabled: PropTypes.bool,
}

GameplansCardView.propTypes = {
  sections: PropTypes.array.isRequired,
  // extraData: PropTypes.object,
  onClickGameplanOptions: PropTypes.func,
  renderHeader: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  onClickMore: PropTypes.func,
  isTeeupDisabled: PropTypes.bool,
  testID: PropTypes.string,
  draggingSuggestion: PropTypes.any,
  draggingSuggestionId: PropTypes.any,
  sectionListRef: PropTypes.func,
  visible: PropTypes.bool,
}

export default GameplansCardView
