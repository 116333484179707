import React, { Component } from "react"
import { connect } from "react-redux"
// import Collapsible from 'react-native-collapsible'
import PropTypes from "prop-types"
import { View, Text } from "react-native"
import { bindActionCreators } from "redux"
import assign from "lodash/assign"

import { getDateWithTimezone } from "@utils/dateUtils"
import { GAMEPLAN_OPTIONS, getSuggestionValue } from "@utils/gamePlanUtils"
import * as teeupActions from "@actions/teeupActions"
// import teeupCardActions from '@actions/teeupCardActions'
import actionTypes from "@actions/actionTypes"
// import {
//     routeSetupGameplan,
//     routeGameplanOptions,
//     showActionsheet,
//     showTimezonesSummary,
//     showSuggestionList,
// } from '@nav'
import { images } from "@utils/imageUtils"
import { isNewSuggestion, promoteToGameplan } from "@utils/gamePlanUtils"
import {
  isMultizoneTeeup,
  isTeeupDisabled,
  isTeeupCancelled,
  isOrganiser,
} from "@utils/teeupUtils"
import {
  selectPermissions,
  selectSuggestionMessages,
  selectTeeupGameplans,
  selectTeeupPeople,
  selectTeeupRecommendationsIds,
  selectTeeupRecommendations,
  selectDecidedGameplans,
  selectTeeupsParticipants,
} from "@selectors/teeups"
// import {
//     selectDraggingSuggestion,
//     selectDraggingSuggestionId,
// } from '@selectors/teeupCards'
import { selectNewSuggestions } from "@selectors/localData"
import { selectUserId } from "@selectors/user"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import Toast from "@ui/toast"
import { AppColors } from "@theme/"
import { gameplanOptionStatusIds } from "@configs/mappings"
import { toggleSuggestionDetails } from "@actions/commonActions"

import { RecommendationStyles } from "./gameplansStyles"
import GameplansCardView from "./gameplansCardView"
import SuggestionSectionHeader from "./suggestionSectionHeader"
// import SuggestionSectionContent from './suggestionSectionContent'
// import SuggestionWhenWorksSectionContent from './whenWorksSectionContent'
import RecommendationList from "./RecommendationList"
// import StartsWhenCarousel from './StartsWhenCarousel'
import suggestionDragGlobal from "./suggestionDragGlobal"
import SuggestionListContainer from "./suggestionsList/suggestionListContainer"
import GameplanContainer from "../CreateTeeUp/gameplanContainer"
import ReactDOM from "react-dom"
import SuggestionPopup from "./SuggestionPopup"

import I18n from "@i18n"

const formatSections = (
  gameplans,
  recommendationsItems,
  recommendationsIds
) => {
  let sections = gameplans.map((gameplan, index) => ({
    ...gameplan,
    data: [index],
    index,
  }))
  let recommendations = recommendationsIds
    ? recommendationsIds.map(
        (recommendationId) => recommendationsItems[recommendationId]
      )
    : []

  if (recommendations.length > 0) {
    sections.push({
      index: gameplans.length,
      title: "Possibilities for your TeeUp",
      data: [gameplans.length],
      recommendations,
    })
  }

  return sections
}

class GameplansCardContainer extends Component {
  static collapsedHeight = 192
  static collapsedHeightSmallBlock = 154

  static getDerivedStateFromProps(nextProps, state) {
    let updates = null

    if (nextProps.gameplans !== state.sections) {
      updates = {
        sections: formatSections(
          nextProps.gameplans,
          nextProps.recommendations,
          nextProps.recommendationsIds
        ),
      }
    }

    if (state.expanded !== nextProps.expanded) {
      if (!updates) {
        updates = {}
      }

      updates.expanded = nextProps.expanded

      if (!nextProps.expanded) {
        updates.activeSectionIndex = null
      }
    }

    return updates
  }

  constructor(props) {
    super(props)

    const { gameplans, recommendations, recommendationsIds } = props

    this.state = {
      sections: formatSections(gameplans, recommendations, recommendationsIds),
      activeSectionIndex: null,
      expanded: false,
      isSuggestionPopupOpened: false,
      currentSuggestion: {},
      currentSuggestionData: {},
    }

    this._sectionHeaderRefs = []
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      draggingSuggestion,
      draggingSuggestionId,
      previewOn,
      expanded,
      startTouch,
      toggleSuggestionDetails,
    } = this.props
    const hidden = previewOn || expanded || startTouch
    const prevHidden =
      prevProps.previewOn || prevProps.expanded || prevProps.startTouch

    if (!draggingSuggestionId && hidden && !prevHidden) {
      this._sectionList.scrollToLocation({
        animated: false,
        itemIndex: 0,
        sectionIndex: 0,
        viewOffset: 0,
        viewPosition: 0,
      })
    }

    if (
      draggingSuggestionId &&
      draggingSuggestionId !== prevProps.draggingSuggestionId
    ) {
      const forChat = draggingSuggestion.props.forChat
      const dropSectionIndex =
        this.findSectionIndexWithSuggestionId(draggingSuggestionId)
      if (dropSectionIndex >= 0) {
        if (forChat) {
          this._sectionList.scrollToLocation({
            animated: false,
            itemIndex: 0,
            sectionIndex: dropSectionIndex,
            viewOffset: 0,
            viewPosition: 1,
          })
        }
        suggestionDragGlobal.dropRef = this._sectionHeaderRefs[dropSectionIndex]
      }
    }

    if (
      prevState.isSuggestionPopupOpened !== this.state.isSuggestionPopupOpened
    ) {
      if (this.state.isSuggestionPopupOpened) toggleSuggestionDetails(false)
    }
  }

  findSectionIndexWithSuggestionId(suggestionId) {
    const { sections } = this.state
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].suggestions.find(({ id }) => id === suggestionId)) {
        return i
      }
    }

    return -1
  }

  promoteToGameplan(suggestion) {
    const { permissions, decidedGameplans, activeTeeup } = this.props
    const activeTeeupId = activeTeeup.id
    const { type } = suggestion
    const decidedGameplanId = decidedGameplans[type]
      ? decidedGameplans[type].id
      : -1

    promoteToGameplan({
      suggestion,
      decidedGameplanId,
      permissions,
      activeTeeupId,
    })
  }

  markAsDecided(suggestion, isDecided) {
    const { permissions } = this.props
    const { id: suggestionId } = suggestion
    if (permissions.allowDecideGameplan) {
      // globalDispatch({
      //     type: actionTypes.MARK_AS_DECIDED,
      //     payload: {
      //         suggestionId,
      //         isDecided,
      //     },
      // })
    } else {
      Toast.show(I18n.t("teeup.toastMsg.forbiddenDecideGP"), {
        bottomOffset: 8,
        textAlign: "center",
      })
    }
  }

  handleOnLongPress = (suggestion) => {
    const { id, selected, decided } = suggestion
    if (!id) {
      return
    }

    // const buttonsForUnselected = [
    //     {
    //         title: I18n.t('gameplans.moveToGameplan'),
    //         iconStyle: { tintColor: AppColors.brand.darkGrey2 },
    //         image: images.makeGameplan,
    //         callback: () => this.promoteToGameplan(suggestion),
    //     },
    // ]
    // const buttonsForSelected = [
    //     {
    //         title: decided
    //             ? I18n.t('gameplans.markNotDecided')
    //             : I18n.t('gameplans.markDecided'),
    //         iconStyle: { tintColor: AppColors.brand.darkGrey2 },
    //         image: images.lockOutline,
    //         callback: () => this.markAsDecided(suggestion, !decided),
    //     },
    // ]
    // showActionsheet(selected ? buttonsForSelected : buttonsForUnselected)
  }

  handleOnSectionPress = (section, fromSuggestionModal) => {
    // this.props.openSuggestions()
    this.props.showSuggestionList(
      <SuggestionListContainer
        closeSuggestions={this.props.closeSuggestions}
        gameplanIndex={section.index}
        showSuggestionList={this.props.showSuggestionList}
        handleOnSuggestPress={this.handleOnSuggestPress}
        handleOpenSuggestion={this.props.handleOpenSuggestion}
        fromSuggestionModal={fromSuggestionModal}
        closeSidebar={this.props.closeSidebar}
      />
    )
    // this.props.showGameplanOptions(
    // <SuggestionListContainer closeSuggestions={this.props.closeSuggestions} gameplanIndex={section.index} />
    // )

    // showSuggestionList({ gameplanIndex: section.index })
  }

  handleOnSuggestPress = (section) => {
    const { allowSelectGameplan } = this.props.permissions
    this.props.showGameplanOptions(
      <GameplanContainer
        {...section}
        suggestions={[]}
        closeCreate={() => this.props.closeSuggestions()}
        allowPutOnGameplan={
          allowSelectGameplan && !section?.suggestions?.length
        }
      />
    )
  }

  // onAddSuggestion = (gameplan) => {
  //     const { allowSelectGameplan } = this.props.permissions
  // routeSetupGameplan({
  //     ...gameplan,
  //     suggestions: [],
  //     allowPutOnGameplan:
  //         allowSelectGameplan && !gameplan.suggestions.length,
  // })
  // }

  onAddSuggestion = (gameplan) => {
    const { allowSelectGameplan } = this.props.permissions

    this.setState({
      isSuggestionsVisible: false,
      gameplanContent: (
        <GameplanContainer
          {...gameplan}
          suggestions={[]}
          closeCreate={() => {
            this.setState({ isSuggestionsVisible: true })
            this.props.FromModal && this.props.closeSuggestions()
          }}
          allowPutOnGameplan={
            allowSelectGameplan && !gameplan?.suggestions?.length
          }
        />
      ),
    })
  }

  suggestRecommendation = (recommendedItem) => {
    const { userId, activeTeeup } = this.props
    this.props.teeupActions.updateGameplan({
      teeupId: activeTeeup.id,
      title: "where",
      userId,
      value: recommendedItem.header,
      value2: undefined,
      recommendationId: recommendedItem.id,
    })
  }

  onSelectRecommendation = (item) => {
    const buttons = [
      {
        title: "Suggest this place",
        image: images.suggestOutline,
        callback: () => this.suggestRecommendation(item),
        iconStyle: RecommendationStyles.overlayButtonImage,
      },
      {
        title: "Add to new Game Plan row",
        image: images.plus,
        callback: () => {},
        iconStyle: RecommendationStyles.overlayButtonImage,
      },
    ]

    // showActionsheet(buttons)
  }

  showTimezoneView = (suggestion) => {
    const { participants, people, activeTeeup } = this.props
    const teeupParticipants = participants[activeTeeup.id]
    // showTimezonesSummary({
    //     participants:
    //         Object.keys(teeupParticipants).length > 0
    //             ? Object.values(teeupParticipants)
    //             : [],
    //     people,
    //     suggestion,
    //     suggestionValues: getSuggestionValue(
    //         suggestion,
    //         GAMEPLAN_OPTIONS.whenWorks
    //     ),
    // })
  }

  collapsableRenderHeader = ({ section }) => {
    const {
      people,
      permissions,
      expanded,
      newSuggestions,
      activeTeeup,
      userId,
      draggingSuggestionId,
      currentUser,
      handleOpenSuggestion,
    } = this.props
    if (Object.prototype.hasOwnProperty.call(section, "recommendations")) {
      if (expanded && section.recommendations) {
        return (
          <View style={RecommendationStyles.mainHeaderContainer}>
            <Text style={RecommendationStyles.mainHeaderText}>
              {section.title}
            </Text>
          </View>
        )
      }

      return null
    }

    let isDragging = !!section.suggestions.find(
      ({ id }) => id === draggingSuggestionId
    )

    let isActive = this.state.activeSectionIndex === section.index
    let suggestion

    if (section.type === GAMEPLAN_OPTIONS.startsWhen) {
      suggestion = assign({}, section.suggestions[0])
      if (suggestion) {
        suggestion.userAvailability = section.availability.find(
          (availability) => availability.userId === userId
        )
      } else {
        suggestion = {
          optionId: section.optionId,
          peopleCount: section.peopleCount,
          startDate: section.startDate,
          type: GAMEPLAN_OPTIONS.startsWhen,
        }
      }
      suggestion.currentPeopleCount = section.currentPeopleCount
    } else {
      suggestion =
        section.suggestions.find((suggestion) => suggestion.selected) || {}
    }

    let suggestionsCount = section.suggestions.length
    let newSuggestionsCount = section.suggestions.filter((suggestion) =>
      isNewSuggestion(newSuggestions, suggestion, userId)
    ).length
    let isMultizone = isMultizoneTeeup(people)
    let name = ""
    if (suggestion && suggestion.createdBy && people[suggestion.createdBy]) {
      name = people[suggestion.createdBy].name
    }
    const sortedSuggestions = [...section.suggestions]

    return (
      <SuggestionSectionHeader
        // TODO: select active suggestion. In reducer or here??
        suggestionsList={sortedSuggestions.sort(
          (a, b) =>
            getDateWithTimezone(a.date).valueOf() -
            getDateWithTimezone(b.date).valueOf()
        )}
        gameplans={this.props.gameplans}
        showTimezoneView={() => this.showTimezoneView(section)}
        pollStatus={section.statusId}
        sourceType={section.sourceType}
        suggestion={suggestion}
        suggestionsCount={suggestionsCount}
        newSuggestionsCount={newSuggestionsCount}
        type={section.type}
        title={section.title}
        icon={section.icon}
        isActive={isActive}
        onPress={() => this.handleOnSectionPress(section)}
        onLongPress={() => this.handleOnLongPress(suggestion)}
        name={name}
        permissions={permissions}
        isMultizone={isMultizone}
        isTeeupCancelled={isTeeupCancelled(activeTeeup)}
        ref={(ref) => (this._sectionHeaderRefs[section.index] = ref)}
        isDragging={isDragging}
        currentUser={currentUser}
        cardExpanded={expanded}
        isHeaderCollapsed={this.state.activeSectionIndex === section.index}
        onAddSuggestion={() => {
          this.handleOnSuggestPress(section)
          this.handleOnSectionPress(section, true)
        }}
        handleOpenSuggestion={handleOpenSuggestion}
        isSuggestionPopupOpened={this.state.isSuggestionPopupOpened}
        currentSuggestion={this.state.currentSuggestion}
        currentSuggestionData={this.state.currentSuggestionData}
        toggleSuggestionPopup={this.toggleSuggestionPopup}
        setCurrentSuggestion={this.setCurrentSuggestion}
        setCurrentSuggestionData={this.setCurrentSuggestionData}
      />
    )
  }

  collapsableRenderItem = ({ item, section, index }) => {
    const gameplan = section
    let {
      suggestionMessages,
      people,
      newSuggestions,
      permissions,
      expanded,
      recommendations,
      activeTeeup,
      draggingSuggestionId,
    } = this.props

    const isRowExpanded = this.state.activeSectionIndex === section.index

    if (Object.prototype.hasOwnProperty.call(section, "recommendations")) {
      if (expanded && section.recommendations) {
        const numberOfPeople = Object.keys(people).length
        return (
          <RecommendationList
            recommendations={section.recommendations}
            onSelectRecommendation={this.onSelectRecommendation}
            numberOfPeople={numberOfPeople}
          />
        )
      }
      return null
    }

    if (gameplan.type === GAMEPLAN_OPTIONS.startsWhen) {
      const { suggestions } = gameplan
      let availability, startDate
      if (suggestions[0]) {
        availability = suggestions[0].availability
        startDate = suggestions[0].startDate
      } else {
        availability = gameplan.startDate
        startDate = gameplan.availability
      }

      return (
        <View
          key={section.title + index}
          collapsed={!isRowExpanded || !expanded}
        >
          {/* {availability && (
                        <StartsWhenCarousel
                            startDate={startDate}
                            availability={availability}
                        />
                    )} */}
        </View>
      )
    }

    const showCloseButton =
      section.statusId === gameplanOptionStatusIds.POLLING || !section.statusId
    return (
      <View key={`${section.index} + ${index}`} collapsed={!isRowExpanded}>
        {gameplan.type === GAMEPLAN_OPTIONS.whenWorks ? (
          // <SuggestionWhenWorksSectionContent
          //     // suggestions={gameplan.suggestions}
          //     gameplan={gameplan}
          //     newSuggestions={newSuggestions}
          //     people={people}
          //     type={gameplan.type}
          //     onAddSuggestion={() => this.onAddSuggestion(gameplan)}
          //     isDisabled={isTeeupDisabled(activeTeeup)}
          //     suggestionMessages={suggestionMessages}
          //     item={item}
          //     permissions={permissions}
          //     recommendations={recommendations}
          //     handleOnLongPress={this.handleOnLongPress}
          //     testID={`teeupGameplans-${index}`}
          //     isTeeupCancelled={isTeeupCancelled(activeTeeup)}
          //     isRowExpanded={isRowExpanded}
          //     draggingSuggestionId={draggingSuggestionId}
          //     sectionIndex={section.index}
          //     isShowClosePollButton={showCloseButton}
          // />
          <View></View>
        ) : (
          <View></View>
          // <SuggestionSectionContent
          //     suggestions={gameplan.suggestions}
          //     newSuggestions={newSuggestions}
          //     people={people}
          //     type={gameplan.type}
          //     onAddSuggestion={() => this.onAddSuggestion(gameplan)}
          //     isDisabled={isTeeupDisabled(activeTeeup)}
          //     suggestionMessages={suggestionMessages}
          //     item={item}
          //     permissions={permissions}
          //     recommendations={recommendations}
          //     handleOnLongPress={this.handleOnLongPress}
          //     testID={`teeupGameplans-${index}`}
          //     isTeeupCancelled={isTeeupCancelled(activeTeeup)}
          //     isRowExpanded={isRowExpanded}
          //     draggingSuggestionId={draggingSuggestionId}
          //     sectionIndex={section.index}
          // />
        )}
      </View>
    )
  }

  handleClickMore = () => {
    const buttons = [
      {
        title: "Game Plan Options",
        image: images.fix,
        callback: this.showGameplanOptions,
      },
    ]
    // showActionsheet(buttons)
  }

  showGameplanOptions = () => {
    const { gameplans } = this.props
    // routeGameplanOptions({
    //     gameplans,
    // })
  }

  toggleSuggestionPopup = (isOpened) => {
    this.setState({ isSuggestionPopupOpened: isOpened })
  }

  setCurrentSuggestion = (suggestion) => {
    this.setState({
      currentSuggestion: suggestion,
    })
  }
  setCurrentSuggestionData = (currentSuggestionData) => {
    this.setState({
      currentSuggestionData: currentSuggestionData,
    })
  }

  render() {
    const {
      newSuggestions,
      activeTeeup,
      userId,
      draggingSuggestion,
      draggingSuggestionId,
      visible,
    } = this.props
    const { sections, isSuggestionPopupOpened, currentSuggestion } = this.state
    const { organisers = [] } = activeTeeup

    const isTeeupOrganiser = isOrganiser(userId, organisers)

    return (
      <>
        <GameplansCardView
          visible={visible}
          sections={sections}
          extraData={newSuggestions}
          renderHeader={this.collapsableRenderHeader}
          renderItem={this.collapsableRenderItem}
          onClickGameplanOptions={
            isTeeupOrganiser ? this.showGameplanOptions : null
          }
          onClickMore={this.handleClickMore}
          onDragSuggestion={this.handleDragSuggestion}
          isTeeupDisabled={isTeeupDisabled(activeTeeup)}
          testID={"teeupGameplans"}
          draggingSuggestion={draggingSuggestion}
          draggingSuggestionId={draggingSuggestionId}
          sectionListRef={(ref) => (this._sectionList = ref)}
        />
        {/* {isSuggestionPopupOpened && ReactDOM.createPortal(
                    <SuggestionPopup 
                        currentSuggestion={currentSuggestion}
                        toggleSuggestionPopup={this.toggleSuggestionPopup}
                        setCurrentSuggestion={this.setCurrentSuggestion}
                    />
                    , document.getElementById('modal-root')
                )} */}
      </>
    )
  }
}

GameplansCardContainer.defaultProps = {
  gameplans: [],
  people: {},
  suggestionMessages: {},
  suggestions: {},
  newSuggestions: {},
}

const mapStateToProps = (state) => {
  const gameplans = selectTeeupGameplans(state)
  const people = selectTeeupPeople(state)
  const permissions = selectPermissions(state)
  const suggestionMessages = selectSuggestionMessages(state)
  const newSuggestions = selectNewSuggestions(state)
  // const newSuggestions = []

  const recommendationsIds = selectTeeupRecommendationsIds(state)
  const recommendations = selectTeeupRecommendations(state)

  const userId = selectUserId(state)
  const activeTeeup = selectActiveTeeup(state)
  // const draggingSuggestion = selectDraggingSuggestion(state)
  const draggingSuggestion = {}
  const participants = selectTeeupsParticipants(state)
  const decidedGameplans = selectDecidedGameplans(state)

  // const draggingSuggestionId = selectDraggingSuggestionId(state)
  const draggingSuggestionId = -1

  const currentUser = people[userId] || {}

  return {
    gameplans,
    participants,
    people,
    permissions,
    suggestionMessages,
    newSuggestions,
    recommendationsIds,
    recommendations,
    userId,
    activeTeeup,
    decidedGameplans,
    draggingSuggestion,
    draggingSuggestionId,
    currentUser,
  }
}

const mapDispatchToProps = (dispatch) => ({
  teeupActions: bindActionCreators(teeupActions, dispatch),
  toggleSuggestionDetails: bindActionCreators(
    toggleSuggestionDetails,
    dispatch
  ),
  // teeupCardActions: bindActionCreators(teeupCardActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameplansCardContainer)

GameplansCardContainer.propTypes = {
  expanded: PropTypes.bool,
  gameplans: PropTypes.array,
  participants: PropTypes.object,
  // toggleExpansion: PropTypes.func.isRequired,
  suggestionMessages: PropTypes.object,
  item: PropTypes.object,
  people: PropTypes.object,
  // newSuggestions: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  recommendations: PropTypes.object,
  recommendationsIds: PropTypes.array,
  userId: PropTypes.number.isRequired,
  teeupActions: PropTypes.shape({
    updateGameplan: PropTypes.func.isRequired,
  }),
  activeTeeup: PropTypes.object,
  teeup: PropTypes.object,
  previewOn: PropTypes.bool,
  startTouch: PropTypes.object,
  draggingSuggestion: PropTypes.any,
  draggingSuggestionId: PropTypes.any,
  decidedGameplans: PropTypes.object,
  teeupCardActions: PropTypes.shape({
    updateDraggingSuggestion: PropTypes.func.isRequired,
  }),
  currentUser: PropTypes.object.isRequired,
  visible: PropTypes.bool,
}
