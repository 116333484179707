import React, { useEffect, useState } from "react"

import "./index.scss"
import { images } from "@utils/imageUtils"
import ProgressBar from "../components/ProgressBar"
import { ReactComponent as Logo } from "../../assets/images/logoWhite.svg"

const LoadingPage = ({ setIsSingInError }) => {
  const [completed, setCompleted] = useState(10);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCompleted((prevCompleted) => Math.min(prevCompleted + 10, 100))
    }, 1000)

    const timer = setInterval(() => {
      setSeconds(seconds + 1)
    }, 1000)
    if (seconds === 10) {
      clearInterval(timer)
      setIsSingInError(true)
    }

    return function cleanup() {
      clearInterval(intervalId)
      clearInterval(timer)
    }
  }, [])

  return (
    <div className="loading_page">
      {/* <img src={ images.logoWhite } alt="" /> */}
      <Logo style={{ fill: "#ffffff" }} />
      <ProgressBar completed={completed} />
    </div>
  )
}

export default LoadingPage
