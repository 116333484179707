import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

const HighlightsButtonStyles = StyleSheet.create({
  badgesContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  pinnedCountWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    borderWidth: Units.responsiveValue(1),
    borderRadius: Units.responsiveValue(12),
    height: Units.responsiveValue(24),
    marginLeft: Units.responsiveValue(4),
    paddingHorizontal: Units.responsiveValue(9),
    borderColor: "rgba(195, 209, 217, 1)",
  },
  pinImage: {
    tintColor: AppColors.brand.blueGrey2,
    height: 14,
    width: 14,
  },
  pinnedCount: {
    marginLeft: Units.responsiveValue(6),
    color: AppColors.brand.blueGrey2,
    ...AppFonts.mediumDemibold,
  },
  pinnedCountWrapperExpanded: {
    borderColor: AppColors.brand.grey193,
  },
  pinnedCountWrapperZero: {
    paddingHorizontal: Units.responsiveValue(16),
  },
  pinImageExpanded: {
    tintColor: AppColors.brand.grey134,
  },
  pinnedCountExpanded: {
    color: AppColors.brand.grey134,
  },
  pinkIcon: {
    tintColor: AppColors.brand.pink,
  },
})

export default HighlightsButtonStyles
