/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"
import { ReactSVG } from "react-svg"
import PropTypes from "prop-types"
import { View, Text, TouchableOpacity, Image } from "react-native"
import chevron from "../../assets/images/chevron.svg"
import tick from "../../assets/images/tick.svg"

import { isToday, isTomorrow, addDayTimestamp } from "../../utils/dateUtils"
import {
  getGameplanValue,
  GAMEPLAN_OPTIONS,
  getGameplanIcon,
} from "../../utils/gamePlanUtils"
// import Icon from '@ui/icon'
import { GameplanOptionSubrowStyles } from "./createTeeupStyles"
import { images } from "../../utils/imageUtils"
// import I18n from '@i18n'
import { RowStyles } from "./createTeeupStyles"

const GameplanRow = ({
  gameplan,
  onGameplanRowClick,
  index,
  onDecidePress,
}) => {
  const { type, suggestions, startDate, peopleCount } = gameplan
  let value = getGameplanValue(suggestions, type, true)
  const getPlaceholderTitle = () => {
    if (type === GAMEPLAN_OPTIONS.when) return "When"
    if (type === GAMEPLAN_OPTIONS.where) return "Where"
    return null
  }
  const isDecided = suggestions && suggestions[0]?.decided
  const isSelected = suggestions && suggestions[0]?.selected

  let adjustedTitle = `Suggest ${getPlaceholderTitle()}`

  let hasValue = value && !value.empty

  let content = (
    <View style={RowStyles.suggestionButton}>
      <Text
        style={[
          RowStyles.buttonTitle,
          !hasValue && RowStyles.buttonTitlePlaceholder,
        ]}
        numberOfLines={hasValue ? 1 : undefined}
      >
        {!hasValue
          ? adjustedTitle
          : isSelected
          ? value.full
          : "Edit Suggestion"}
      </Text>
    </View>
  )

  if (type === GAMEPLAN_OPTIONS.startsWhen) {
    let date
    if (isToday(startDate)) {
      date = "Today"
    } else if (isTomorrow(startDate)) {
      date = "Tomorrow"
    } else {
      date = addDayTimestamp(startDate)
    }

    content = (
      <View>
        <View
          style={[
            GameplanOptionSubrowStyles.middle,
            RowStyles.rowAdditionalMargin,
          ]}
        >
          <Text style={GameplanOptionSubrowStyles.title}>
            {`Starts ${date} when`}
          </Text>
          <Text style={GameplanOptionSubrowStyles.title}>
            {`${peopleCount} people can come`}
          </Text>
        </View>
      </View>
    )
  }

  if (type === GAMEPLAN_OPTIONS.whenWorks) {
    let timesCount = 0
    if (gameplan.dates) {
      gameplan.dates.forEach((d) => {
        timesCount += d.time ? d.time.length : 0
      })
    }
    content = (
      <View>
        <View
          style={[
            GameplanOptionSubrowStyles.middle,
            RowStyles.rowAdditionalMargin,
          ]}
        >
          <Text style={GameplanOptionSubrowStyles.title}>
            {"whenWorksLabel"}
            {/* {I18n.t('whenWorksLabel')} */}
          </Text>
          <Text style={GameplanOptionSubrowStyles.title}>
            {/* {I18n.t('pollingDatesAndTime', {
                            dates: gameplan.dates?.length || 0,
                            time: timesCount,
                        })} */}
            {"pollingDatesAndTime"}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <TouchableOpacity
      style={RowStyles.suggestionLineContainer}
      onPress={() => onGameplanRowClick(gameplan, index)}
    >
      {/* <TouchableOpacity onPress={onDecidePress}>
                <ReactSVG src={tick} />
            </TouchableOpacity> 
            */}
      <View style={RowStyles.suggestionContent}>
        <View style={RowStyles.suggestionTitleHeader}>
          <Text style={RowStyles.suggestionTitle}>
            <ReactSVG src={gameplan.icon} className="createTeeup_icon" />
            {` ${type} · ${
              suggestions && suggestions.length
                ? suggestions.length + " suggestion"
                : "no suggestions yet"
            }`}
          </Text>
        </View>
        {content}
      </View>
    </TouchableOpacity>
  )
}

GameplanRow.propTypes = {
  gameplan: PropTypes.object.isRequired,
  onGameplanRowClick: PropTypes.func.isRequired,
  onDecidePress: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

export default GameplanRow
