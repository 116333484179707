import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"

export const styles = StyleSheet.create({
  container: {},
  splitter: {
    height: Units.responsiveValue(16),
  },
  // header: {
  //     backgroundColor: AppColors.brand.lightGrey,
  //     color: AppColors.brand.darkGrey2,
  //     paddingTop: Units.responsiveValue(22),
  //     paddingBottom: Units.responsiveValue(8),
  //     ...AppFonts.mediumDemibold,
  //     paddingHorizontal: Units.responsiveValue(16),
  // },
  header: {
    backgroundColor: AppColors.brand.lightGrey,
    color: AppColors.brand.darkGrey2,
    paddingTop: Units.responsiveValue(22),
    paddingBottom: Units.responsiveValue(8),
    ...AppFonts.biggerMedium,
    paddingHorizontal: Units.responsiveValue(16),
    paddingLeft: 0,
  },
  headerBold: {
    ...AppFonts.biggerBold,
    color: AppColors.brand.black2,
  },
  descriptionIcon: {
    height: 11,
    width: 11,
    tintColor: "#f42862",
    marginHorizontal: 5,
  },
  calendarIcons: {
    height: 16,
    width: 14,
    cursor: "pointer",
  },
  ask: {
    fontFamily: "greycliff-cf-medium",
    fontSize: "15px",
    letterSpacing: "-0.3px",
    color: "#767676",
  },
  sectionHeaderContainer: {
    backgroundColor: AppColors.brand.grey243,
    paddingTop: Units.responsiveValue(13),
    paddingBottom: Units.responsiveValue(10),
    paddingHorizontal: Units.responsiveValue(16),
  },
  sectionHeaderText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.darkGrey2,
  },
  contentContainer: {
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: Units.responsiveValue(17),
    paddingBottom: Units.responsiveValue(19),
    paddingHorizontal: Units.responsiveValue(16),
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderTopColor: AppColors.brand.lightGrey,
  },
  timezoneContainer: {
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: Units.responsiveValue(14),
    paddingBottom: Units.responsiveValue(14),
    paddingHorizontal: Units.responsiveValue(16),
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderTopColor: AppColors.brand.lightGrey,
  },
  timezoneIconContainer: {
    width: Units.responsiveValue(22),
    height: Units.responsiveValue(22),
    marginRight: Units.responsiveValue(3.5),
  },
  messageContainer: {
    padding: Units.responsiveValue(16),
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
  },
  text: {
    flex: 1,
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.black2,
  },
  buttonImage: {
    width: Units.responsiveValue(7),
    height: Units.responsiveValue(12),
    tintColor: AppColors.brand.warmGrey2,
  },
  value: {
    color: AppColors.brand.warmGrey2,
    ...AppFonts.biggerDemibold,
    marginRight: Units.responsiveValue(8),
  },
  timezonesSelectContainer: {
    backgroundColor: AppColors.brand.white,
    flex: 1,
    paddingTop: Units.responsiveValue(14),
  },
  searchBarContainer: {
    padding: Units.responsiveValue(12),
    paddingTop: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.grey243,
  },
  searchBarHeader: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.darkGrey2,
    letterSpacing: -Units.responsiveValue(0.34),
    marginBottom: Units.responsiveValue(8),
    marginLeft: Units.responsiveValue(4),
  },
  selectetTimezoneContainer: {
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "flex-start",
    paddingTop: Units.responsiveValue(14),
    paddingBottom: Units.responsiveValue(14),
    paddingHorizontal: Units.responsiveValue(16),
  },
  selectetTimezoneInfo: {
    flex: 1,
    height: Units.responsiveValue(42),
  },
  selectedTZtext: {
    color: AppColors.brand.grey157,
  },
  selectedTZsubtext: {
    ...AppFonts.smallishMedium,
  },
  selectedTZicon: {
    tintColor: AppColors.brand.grey157,
  },
})
