import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units, AppSizes } from "@theme"

export const CalendarStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  rightSideContainer: {
    // marginLeft: Units.responsiveValue(12),
    marginRight: Units.responsiveValue(8),
  },
  alignRight: {
    alignItems: "flex-end",
  },
  alignLeft: {
    alignItems: "flex-start",
  },

  button: {
    flexDirection: "row",
    marginBottom: Units.responsiveValue(AppSizes.additionalBottomSpace),
    backgroundColor: AppColors.brand.pink,
    paddingVertical: Units.responsiveValue(14),
    paddingBottom: Units.responsiveValue(14),
    borderRadius: Units.responsiveValue(8),
    alignItems: "center",
    justifyContent: "center",
  },
  buttonWrapper: {
    backgroundColor: AppColors.brand.white,
    paddingTop: Units.responsiveValue(12),
    paddingBottom: Units.responsiveValue(12 + AppSizes.additionalBottomSpace),
    paddingHorizontal: Units.responsiveValue(16),
  },
  buttonText: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.white,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.4),
  },
  calendarHeaderWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
    paddingHorizontal: Units.responsiveValue(4),
    paddingVertical: Units.responsiveValue(4),
    // ...(isComingUp
    //     ? {}
    //     : {
    //           borderBottomColor: AppColors.brand.grey2,
    //           borderBottomWidth: Units.responsiveValue(1),
    //       }),
  },
  calendarViewPickerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: Units.responsiveValue(28),
    position: "relative",
    zIndex: 1,
    // width: Units.responsiveValue(isComingUp ? 92 : 80),
    // width: Units.responsiveValue(80),
    marginVertical: Units.responsiveValue(6),
    borderRadius: Units.responsiveValue(14),
    paddingTop: Units.responsiveValue(8),
    paddingBottom: Units.responsiveValue(7),
    // paddingLeft: Units.responsiveValue(isComingUp ? 9 : 12),
    paddingLeft: Units.responsiveValue(12),
    marginLeft: 5,
    paddingRight: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.grey243,
  },
  calendar: {
    // height: 664,
    height: "100%",
    // width: 1025,
    width: "100%",
    // transform: 'translate(5px)',
    borderRadius: 10,
  },
  viewPickerText: {
    flex: 1,
    fontFamily: "greycliffcf-demibold-salt",
    textTransform: "uppercase",
    textAlign: "center",
    color: AppColors.brand.black,
    // marginRight: Units.responsiveValue(isComingUp ? 0 : 8),
    marginRight: Units.responsiveValue(8),
  },
  caretDown: {
    width: Units.responsiveValue(8),
    height: Units.responsiveValue(5),
    marginTop: Units.responsiveValue(2),
    resizeMode: "stretch",
    tintColor: AppColors.brand.black,
  },
  goTodayBtnContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: Units.responsiveValue(28),
    width: Units.responsiveValue(28),
    marginVertical: Units.responsiveValue(6),
    marginHorizontal: Units.responsiveValue(7),
    borderRadius: Units.responsiveValue(14),
    paddingLeft: Units.responsiveValue(0.7),
    paddingBottom: Units.responsiveValue(0.5),
    backgroundColor: AppColors.brand.grey243,
  },
  goTodayIcon: {
    height: Units.responsiveValue(17),
    width: Units.responsiveValue(17),
  },
  settingsBtnContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: Units.responsiveValue(28),
    width: Units.responsiveValue(28),
    marginVertical: Units.responsiveValue(6),
    marginHorizontal: Units.responsiveValue(7),
    borderRadius: Units.responsiveValue(14),
    paddingLeft: Units.responsiveValue(1.8),
    paddingTop: Units.responsiveValue(1),
    backgroundColor: AppColors.brand.grey243,
  },
  settingsIcon: {
    height: Units.responsiveValue(18),
    width: Units.responsiveValue(19),
  },
  currentMonthContainer: {
    alignItems: "center",
    paddingBottom: Units.responsiveValue(4.5),
    paddingTop: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.white,
  },
  currentMonthText: {
    ...AppFonts.mediumExtraBold,
    textTransform: "uppercase",
    color: AppColors.brand.warmGrey2,
  },
  closeButton: {
    width: Units.rem(1),
    height: Units.rem(1),
  },
  headerContainer: {
    alignItems: "center",
  },
  conflictContainer: {
    backgroundColor: "white",
  },
  conflictTitle: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.darkGrey2,
  },
  conflictDate: {
    ...AppFonts.headerSmallBold,
    color: "black",
  },
  stubNavigationButton: {
    paddingHorizontal: Units.rem(1.5),
  },
  navigationHeaderContainer: {
    flexDirection: "row",
    width: AppSizes.screen.width - Units.rem(1.5),
    height: AppSizes.navbarHeight,
    alignItems: "center",
  },
  loaderContainer: {
    position: "fixed",
    // top: Units.responsiveValue(51),
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: "center",
    backgroundColor: "rgba(240, 240, 240,0.75)",
  },
  fromProfile: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderBottomColor: "#F0F2F5",
    // borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,
    paddingBottom: 7,
    height: 44,
  },
})

export const itemWrapper = (fromProfile, calendarWidth, comingUpWidth) => ({
  flex: 1,
  justifyContent: "space-evenly",
  minWidth: fromProfile ? calendarWidth : comingUpWidth,
  marginRight: 50,
  scrollSnapAlign: "start",
})
