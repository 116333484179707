import React, { useEffect, useState } from "react"

import cn from 'classnames'

import { isCooeUser } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"
import { getContactName, escapeRegExp } from "@utils/dataUtils"
import styles, { getExpandedStyles } from "./styles"

import "./index.scss"

const MAX_TAGS_COUNT = 3
const noOp = () => {}

const renderTags = (tags) => {
  const tagsCount = tags.length
  if (tagsCount === 0) return null
  const tagsData = tags.map((tag, index) => {
    if (index === MAX_TAGS_COUNT) {
      const tagsLeft = tagsCount - index
      return (
        <p key={`${tag} + ${index}`} className="tagNd">
          {`+${tagsLeft}`}
        </p>
      )
    } else if (index > MAX_TAGS_COUNT) return null
    return (
      <div className="tagNd__wrapper" key={`${tag} + ${index}`}>
        <img className="tagNd__image" src={images.tag} />
        <p className="tagNd">
          {" "}
          {tag.value}
          {index !== tagsCount - 1 ? ", " : null}
        </p>
      </div>
    )
  })
  return (
    <div className="tagNd__content">
      {tagsData}
    </div>
  )
}

const ContactItemNd = ({
  searchText,
  invited,
  expanded,
  data,
  onToggleContact,
  onToggleExpand,
  onChangeContactType,
  fromContactsBook,
  openInviteToTeeupModal,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(data && data.selectedIndex)
  const [isHover, setIsHover] = useState(false)
  const [buttonIndex, setButtonIndex] = useState(0)

  const getParsedProps = () => ({
    parse: [
      {
        pattern: new RegExp(escapeRegExp(searchText), "gi"),
        style: styles.matchingContact,
      },
    ],
    selectable: true,
    childrenProps: {
      allowFontScaling: false,
    },
  })


  const cooeUser = isCooeUser(data)
  const hasExtraContact = data.contactList && data.contactList.length > 0
  const label = data.label || getContactName(data) || ""

  return (
    <>
      {!data ? null : (
      <div
        className={cn("contact-itemNd", {
          "from-invite": !fromContactsBook,
          "invited": !fromContactsBook && invited,
        })}
        onClick={() => {
          !fromContactsBook && onToggleContact({ data })
        }}
        onMouseOver={() => {
          setIsHover(true)
        }}
        onMouseLeave={() => {
          setIsHover(false)
        }}
        style={{
          boxShadow: fromContactsBook && isHover && "0 1px 0 0 #e3e5e8",
          border: fromContactsBook && isHover && "solid 1px rgba(89, 95, 130, 0.5)",
        }}
      >
        {data.avatar ? (
          <img src={data.avatar} className="contact-itemNd__avatar" />
        ) : (
          <div className="contact-itemNd__avatar--empty">
            {label[0]}
          </div>
        )}
        <div className="contact-itemNd__content">
          <h5
            className="contact-itemNd__name"
            {...(searchText ? getParsedProps(false) : {})}
          >
            {label}
          </h5>
          <div className="contact-itemNd__tags">
            {data.tags ? renderTags(data.tags) : null}
          </div>
        </div>
        <div
          className="contact-itemNd__checkbox"
          onClick={() => {
            !fromContactsBook && onToggleContact({ data })
          }}
        >
          {/* {!fromContactsBook && isHover && !invited && <img src={images.ndCheckbox} className="contact-itemNd__chose" />}
          {!fromContactsBook && invited && <img src={images.ndCheckboxActive} className="contact-itemNd__chose" />} */}

          {fromContactsBook && isHover && (
            <div className="contact-itemNd__buttons">
              <div
                className="contact-itemNd__button"
                onClick={() => {
                  openInviteToTeeupModal()
                  onToggleContact({ data })
                }}
                onMouseOver={() => setButtonIndex(1)}
                onMouseLeave={() => setButtonIndex(0)}
              >
                <img
                  src={buttonIndex === 1 ? images.ndInviteBlankActive : images.ndInviteBlank}
                  style={{ marginRight: 4 }}
                />
                Invite
              </div>
              <div
                className="contact-itemNd__button"
                onMouseOver={() => setButtonIndex(2)}
                onMouseLeave={() => setButtonIndex(0)}
              >
                <img
                  src={buttonIndex === 2 ? images.ndInviteTagActive : images.ndInviteTag}
                  style={{ marginRight: 4 }}
                />
                Tag
              </div>
              <div
                className="contact-itemNd__button"
                onClick={() => onToggleContact({ data })}
                onMouseOver={() => setButtonIndex(3)}
                onMouseLeave={() => setButtonIndex(0)}
              >
                <img
                  src={buttonIndex === 3
                    ? invited ? images.ndInviteSelectedActive : images.ndInviteSelectActive
                    : invited ? images.ndInviteSelected : images.ndInviteSelect
                  }
                  style={{ marginRight: 4 }}
                />
                {invited ? "Unselect" : "Select"}
              </div>
            </div>
          )}
          {fromContactsBook && invited && !isHover && (
            <img
              className="contact-itemNd__selected"
              src={images.ndInviteSelected}
            />
          )}
        </div>
      </div>
      )}
    </>
  )

}

export default ContactItemNd
