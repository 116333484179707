import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { FcGoogle } from "react-icons/fc"
import { FaFacebook, FaTwitter, FaApple } from "react-icons/fa"
import { IconContext } from "react-icons"

import "./index.scss"
import { loginProviders } from "@config/enums"
import CustomTemplateSign from "pages/components/CustomTemplateSign/index"
import { Link } from "react-router-dom"
import { TEMPLATE_TYPES } from "pages/components/CustomTemplateSign/constants"

const SingleSignInPage = () => {
  const { loginWithPopup } = useAuth0()

  const handleGoogleLogin = (event) => {
    event.preventDefault()
    loginWithPopup({
      connection: loginProviders.google,
    })
  }

  const handleFbLogin = (event) => {
    event.preventDefault()

    loginWithPopup({
      connection: loginProviders.fb,
    })
  }

  const handleTwitterLogin = (event) => {
    event.preventDefault()

    loginWithPopup({
      connection: loginProviders.twitter,
    })
  }

  const handleAppleLogin = (event) => {
    event.preventDefault()

    loginWithPopup({
      connection: loginProviders.apple,
    })
  }

  return (
    <CustomTemplateSign type={TEMPLATE_TYPES.signinSocial}>
      <div className="single_sign_in__container">
        <button
          className="single_sign_in__button"
          style={{ borderColor: "#19B7EA" }}
          onClick={handleTwitterLogin}
        >
          <IconContext.Provider
            value={{ className: "single_sign_in__button_icon tw_color" }}
          >
            <FaTwitter />
          </IconContext.Provider>
          <span className="single_sign_in__button_title">
            Log in with Twitter
          </span>
        </button>

        <button
          className="single_sign_in__button"
          style={{ borderColor: "#4285F4" }}
          onClick={handleGoogleLogin}
        >
          <IconContext.Provider
            value={{ className: "single_sign_in__button_icon" }}
          >
            <FcGoogle />
          </IconContext.Provider>
          <span className="single_sign_in__button_title">
            Log in with Google
          </span>
        </button>
        <button
          className="single_sign_in__button"
          style={{ borderColor: "#1877F2" }}
          onClick={handleFbLogin}
        >
          <IconContext.Provider
            value={{ className: "single_sign_in__button_icon fb_color" }}
          >
            <FaFacebook />
          </IconContext.Provider>
          <span className="single_sign_in__button_title fb_color">
            Log in with Facebook
          </span>
        </button>

        <button
          className="single_sign_in__button"
          style={{ borderColor: "#000000" }}
          onClick={handleAppleLogin}
        >
          <IconContext.Provider
            value={{ className: "single_sign_in__button_icon" }}
          >
            <FaApple />
          </IconContext.Provider>
          <span className="single_sign_in__button_title">
            Log in with Apple
          </span>
        </button>
        <Link to="/sign-in-email">
          <span className="single_sign_in__label sign_with_email">
            Log in with Email
          </span>
        </Link>
      </div>
    </CustomTemplateSign>
  )
}

export default SingleSignInPage
