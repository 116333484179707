import React, { useState, useEffect, createRef } from "react"
import { Link, useHistory } from "react-router-dom"

import { images } from "@utils/imageUtils"
import { loginProviders } from "@config/enums"
import { useAuth0 } from "@auth0/auth0-react"
import { validateEmail } from "@utils/dataUtils"

import { PreviewStyles } from "./PreviewStyles"


const SignUpPreview = () => {
  const [email, setEmail] = useState("")
  const [isButtonDisable, setIsButtonDisable] = useState(true)
  const [isInputIncorrect, setIsInputIncorrect] = useState(false)

  const inputRef = createRef()

  const history = useHistory()

  useEffect(() => {
    setIsButtonDisable(!validateEmail(email))
  }, [email])

  const focusInput = () => {
    inputRef.current.focus();
    setIsInputIncorrect(true)
  }


  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const { loginWithRedirect } = useAuth0()

  const handleGoogleLogin = (event) => {
    event.preventDefault()

    loginWithRedirect({
      connection: loginProviders.google,
    })
  }

  const handleFacebookLogin = (event) => {
    event.preventDefault()

    loginWithRedirect({
      connection: loginProviders.fb,
    })
  }

  const handleTwitterLogin = (event) => {
    event.preventDefault()

    loginWithRedirect({
      connection: loginProviders.twitter,
    })
  }

  const handleAppleLogin = (event) => {
    event.preventDefault()

    loginWithRedirect({
      connection: loginProviders.apple,
    })
  }

  return (
    <div className="preview__signUp" style={PreviewStyles.SignUpWrapper}>
      <div style={PreviewStyles.SignUp}>
        <img src={images.logoPreview} />
        <h3 style={PreviewStyles.SignUpTitle}>Sign up to start planning</h3>
        <div
          className="preview__socialNetwork"
          style={PreviewStyles.SignUpSocial}
        >
          <button
            className="preview_signUp__socialBtn"
            style={{
              boxSizing: "border-box",
              backgroundColor: "#fff",
              border: "solid 1px #000",
              borderRadius: 6,
              width: 50,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleAppleLogin}
          >
            <img src={images.logoPreviewApple} />
          </button>
          <button
            className="preview_signUp__socialBtn"
            style={{
              boxSizing: "border-box",
              backgroundColor: "#fff",
              border: "solid 1px #4285f4",
              borderRadius: 6,
              width: 50,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleGoogleLogin}
          >
            <img src={images.logoPreviewGoogle} />
          </button>
          <button
            className="preview_signUp__socialBtn"
            style={{
              boxSizing: "border-box",
              backgroundColor: "#fff",
              border: "solid 1px #19b7ea",
              borderRadius: 6,
              width: 50,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleTwitterLogin}
          >
            <img src={images.logoPreviewTwitter} />
          </button>
          <button
            className="preview_signUp__socialBtn"
            style={{
              boxSizing: "border-box",
              backgroundColor: "#fff",
              border: "solid 1px #1877f2",
              borderRadius: 6,
              width: 50,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleFacebookLogin}
          >
            <img src={images.logoPreviewFacebook} />
          </button>
        </div>
        <p className="preview__signUp_or">or</p>
        <input
          type="text"
          ref={inputRef}
          className={ !isInputIncorrect ? "preview__signUp_input" : "preview__signUp_input_incorrect"}
          placeholder="Email"
          spellcheck="false"
          value={email}
          onChange={(e) => handleChange(e)}
        />
        <button
          type="botton"
          // disabled={isButtonDisable}
          className="preview__signUp_button"
          onClick={() => {
            isButtonDisable
              ? focusInput()
              : history.push({
                  pathname: "/sign-up",
                  state: { emailFromPreview: email },
              })
          }}
        >
          {/* <Link
            to={{
              pathname: "/sign-up",
              state: { emailFromPreview: email },
            }}
            
          > */}
            Sign up
          {/* </Link> */}
        </button>
      </div>
      <div style={PreviewStyles.SignIn}>
        Already have an account?
        <Link to="/sign-in" className="preview__signIn">
          Sign in
        </Link>
      </div>
    </div>
  )
}

export default SignUpPreview
