import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"

export const EventsStyles = StyleSheet.create({
  eventBlockContainer: {
    position: "absolute",
    paddingBottom: Units.responsiveValue(1),
    paddingLeft: Units.responsiveValue(1),
  },
  calendarEventContainer: {
    flex: 1,

    overflow: "hidden",
  },
  basicCalendarEventContainer: {
    backgroundColor: AppColors.brand.lightBlue6,
  },
  conflictedCalendarEventContainer: {
    backgroundColor: AppColors.brand.lightRed,
  },
  calendarEventLine: {
    height: Units.responsiveValue(4),
    width: "100%",
    marginBottom: Units.responsiveValue(4),
  },
  basicCalendarEventLine: {
    backgroundColor: AppColors.brand.blue3,
  },
  conflictedCalendarEventLine: {
    backgroundColor: AppColors.brand.red3,
  },
  calendarEventText: {
    ...AppFonts.smallDemibold,
    letterSpacing: -Units.responsiveValue(0.2),
    paddingLeft: Units.responsiveValue(4),
  },
  basicCalendarEventText: {
    color: AppColors.brand.blue3,
  },
  conflictedCalendarEventText: {
    color: AppColors.brand.red3,
  },
  teeupEventContainer: {
    flex: 1,
    flexDirection: "row",
    paddingLeft: Units.responsiveValue(4),
    paddingTop: Units.responsiveValue(4),
    paddingBottom: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.white,
    overflow: "hidden",
  },
  basicTeeupEventContainer: {
    borderWidth: 1,
    borderColor: AppColors.brand.pink,
  },
  conflictedTeeupEventContainer: {
    borderWidth: 1,
    borderColor: AppColors.brand.red,
  },
  teeupEventIcon: {
    marginRight: Units.responsiveValue(4),
    width: Units.responsiveValue(9),
    height: Units.responsiveValue(8),
    tintColor: AppColors.brand.pink,
  },
  teeupEventText: {
    ...AppFonts.smallBold,
    letterSpacing: -Units.responsiveValue(0.1),
    color: AppColors.brand.black,
  },
})
