import React from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"
// import { WheelPicker } from 'react-native-wheel-picker-simple'
import WheelPicker from "react-simple-wheel-picker"
import { generateHoursArray, generateMinutesArray } from "@utils/dateUtils"

import { DurationStyles } from "./timePickerOverlayStyles"

const hoursArray = generateHoursArray({ maxHour: 7 })
const minutesInterval = 5
const minutesArray = generateMinutesArray(minutesInterval)
const formattedHoursArray = hoursArray.map((item, index) => ({
  id: String(index + 1),
  value: item,
}))
const formattedMinutesArray = minutesArray.map((item, index) => ({
  id: String(index + 1),
  value: item,
}))

const DurationPicker = ({
  durationHoursIndex,
  durationMinutesIndex,
  onDurationHoursSelected,
  onDurationMinutesSelected,
}) => {
  const activeMinuteItem = formattedMinutesArray.find(
    (item) => item.id === durationMinutesIndex
  )
  const activeMinuteId = activeMinuteItem ? activeMinuteItem.id : 1

  return (
    <View style={DurationStyles.container}>
      <View style={DurationStyles.durationBorderContainer} />
      <View style={DurationStyles.wheelWithHoursContainer}>
        <View style={DurationStyles.wheelContainer}>
          <WheelPicker
            selectedID={formattedHoursArray[durationHoursIndex].id}
            data={formattedHoursArray}
            height={120}
            itemHeight={25}
            width={40}
            onChange={(target) => onDurationHoursSelected(target.value)}
            color="#ccc"
            activeColor="#333"
            backgroundColor="#fff"
          />
        </View>
        <View style={DurationStyles.durationHoursContainer}>
          <Text style={DurationStyles.durationHoursText}>h</Text>
        </View>
      </View>
      <View style={DurationStyles.wheelWithHoursContainer}>
        <View style={DurationStyles.wheelContainer}>
          <WheelPicker
            selectedID={activeMinuteId}
            data={formattedMinutesArray}
            height={120}
            width={40}
            itemHeight={25}
            onChange={(target) =>
              onDurationMinutesSelected(target.id - 1, minutesInterval)
            }
            color="#ccc"
            activeColor="#333"
            backgroundColor="#fff"
          />
        </View>

        <View style={DurationStyles.durationHoursContainer}>
          <Text style={DurationStyles.durationHoursText}>min</Text>
        </View>
      </View>
    </View>
  )
}

DurationPicker.propTypes = {
  // durationVal: PropTypes.string,
  // durationHoursIndex: PropTypes.number,
  // durationMinutesIndex: PropTypes.number,
  onDurationHoursSelected: PropTypes.func,
  onDurationMinutesSelected: PropTypes.func,
  showDurationPicker: PropTypes.bool,
}

export default DurationPicker
