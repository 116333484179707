import React from "react"
import classnames from "classnames"

import "./quickSelect.scss"

// TODO find out does this exist somewhere, or is it being fetched. in the mean time for development leave it as is.
const QUICK_SELECT_OPTIONS = [
  { id: "today", title: "Today" },
  { id: "tomorrow", title: "Tomorrow" },
  { id: "this-weekend", title: "This Weekend" },
  { id: "next-weekend", title: "Next Weekend" },
  { id: "this-week", title: "This Week" },
  { id: "next-week", title: "Next Week" },
  { id: "this-month", title: "This Month" },
  { id: "next-month", title: "Next Month" },
]

const QuickSelect = ({ selected }) => {
  return (
    <div className="quick-select">
      {QUICK_SELECT_OPTIONS.map(({ id, title }) => (
        <div
          key={id}
          className={classnames("quick-select__option", {
            selected: selected === id,
          })}
        >
          {title}
        </div>
      ))}
    </div>
  )
}

export default QuickSelect
