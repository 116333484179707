import React, { useState, useEffect } from "react"
import { View, Text, TextInput, TouchableOpacity, Keyboard } from "react-native"
import PropTypes from "prop-types"
import { AppColors } from "@theme/"
import { styles } from "./styles"

const TextPromptDialog = (props) => {
  const {
    title,
    leftButtonText,
    rightButtonText,
    leftButtonPress,
    rightButtonPress,
    componentId,
    onClose,
    maxLength,
    multilineText,
    textInputPlaceholder,
    value,
    disabledIfEmpty,
  } = props
  const [text, setText] = useState(value ? value : "")
  const [textHeight, setTextHeight] = useState(36)
  const [textLength, setTextLength] = useState(0)
  const isDoneButtonDisabled = disabledIfEmpty && text.length === 0

  useEffect(() => {
    if (maxLength > 0 && text?.length > 0) {
      setTextLength(text.length)
    }
  }, [text])

  const handleLeftButtonPress = () => {
    onClose(componentId)
    leftButtonPress()
  }

  const handleRightButtonPress = () => {
    onClose(componentId)
    rightButtonPress(text)
  }

  const renderTextInput = () => {
    return (
      <View style={styles.textInputWithSymbolCounter}>
        <View style={[styles.textInputContainer, { minHeight: textHeight }]}>
          <TextInput
            style={[styles.textInput, { outline: "none" }]}
            multiline={multilineText}
            onChangeText={setText}
            textAlignVertical={"top"} //for Android
            placeholder={textInputPlaceholder}
            maxLength={maxLength}
            onContentSizeChange={(e) =>
              setTextHeight(e.nativeEvent.contentSize.height)
            }
            defaultValue={value}
          />
        </View>
        {maxLength && (
          <View style={styles.symbolCounterWrapper}>
            <Text style={styles.symbolCounterText}>
              {textLength}/{maxLength}
            </Text>
          </View>
        )}
      </View>
    )
  }

  return (
    <View style={styles.overlay}>
      <View style={styles.overlayContainer}>
        <View style={styles.modalContainer}>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>{title}</Text>
          </View>
          {renderTextInput()}
          <View style={styles.buttonsContainer}>
            <TouchableOpacity
              style={styles.buttonWrapper}
              onPress={handleLeftButtonPress}
            >
              <Text style={styles.leftButtonText}>{leftButtonText}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.buttonWrapper,
                styles.rightButtonWrapper,
                isDoneButtonDisabled && styles.rightButtonDisabledWrapper,
              ]}
              onPress={handleRightButtonPress}
              disabled={isDoneButtonDisabled}
            >
              <Text style={styles.rightButtonText}>{rightButtonText}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  )
}

TextPromptDialog.options = {
  statusBar: {
    backgroundColor: AppColors.transparentOverlay,
    style: "light",
  },
}

TextPromptDialog.defaultProps = {
  leftButtonText: "Cancel",
  rightButtonText: "Done",
  leftButtonPress: () => {},
  rightButtonPress: () => {},
  multilineText: true,
  textInputPlaceholder: "",
  disabledIfEmpty: false,
}

TextPromptDialog.propTypes = {
  componentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  textInputPlaceholder: PropTypes.string,
  leftButtonPress: PropTypes.func,
  rightButtonPress: PropTypes.func,
  maxLength: PropTypes.number,
  multilineText: PropTypes.bool,
  value: PropTypes.string,
  disabledIfEmpty: PropTypes.bool,
}
export default TextPromptDialog
