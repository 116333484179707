import React, { useState, useEffect } from "react"
import cancel from "../../../assets/images/cancel.png"
import { images } from "@utils/imageUtils"
import cn from "classnames"
import { validateEmail } from "@utils/dataUtils"

import { useAuth0 } from "@auth0/auth0-react"
import { FcGoogle } from "react-icons/fc"
import { FaFacebook, FaTwitter, FaApple } from "react-icons/fa"
import { IconContext } from "react-icons"
import { loginProviders } from "@config/enums"
import { useTopLevelContext } from "contexts/TopLevelContext"

const CreateAccount = ({
  error,
  handleAddEmail,
  defaultEmail,
  handleClickVerify,
  setCurrentEmail,
  isPreview,
  emailFromPreview,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [email, setEmail] = useState(defaultEmail)

  const [isSignUpWithEmail, setIsSignUpWithEmail] = useState(false)

  const { setIsRegistrationInProgress } = useTopLevelContext()

  useEffect(() => {
    setIsButtonDisabled(!validateEmail(email))
  }, [email])

  const clearEmail = () => {
    setEmail("")
  }

  const { loginWithRedirect } = useAuth0()

  const handleGoogleLogin = (event) => {
    event.preventDefault()
    setIsRegistrationInProgress(true)

    loginWithRedirect({
      connection: loginProviders.google,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.google,
      },
    })
  }

  const handleFbLogin = (event) => {
    event.preventDefault()
    setIsRegistrationInProgress(true)

    loginWithRedirect({
      connection: loginProviders.fb,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.fb,
      },
    })
  }

  const handleTwitterLogin = (event) => {
    event.preventDefault()
    setIsRegistrationInProgress(true)

    loginWithRedirect({
      connection: loginProviders.twitter,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.twitter,
      },
    })
  }

  const handleAppleLogin = (event) => {
    event.preventDefault()
    setIsRegistrationInProgress(true)

    loginWithRedirect({
      connection: loginProviders.apple,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.apple,
      },
    })
  }

  const emailSignupStyles = {
    color: "#f53263",
    border: "2px solid #f53263",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "18px",
    fontFamily: "greycliff-cf-medium",
  }

  return (
    <>
      {/* <img style={{ marginBottom: "9px" }} src={images.cooeModal} />
      <span className="forgot__title">Create an Account</span> */}
      {/* <p className="forgot__subtitle" style={{ marginTop: "32px" }}>
        Please select a sign up method to begin creating your Coo-e account.
      </p> */}
      {!isSignUpWithEmail && !isPreview ? (
        <div style={{ marginTop: 0 }} className="single_sign_in__container">
          <button
            className="single_sign_in__button"
            style={{
              borderColor: "#19B7EA",
              boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)",
            }}
            onClick={handleTwitterLogin}
          >
            <IconContext.Provider
              value={{ className: "single_sign_in__button_icon tw_color" }}
            >
              <FaTwitter />
            </IconContext.Provider>
            <span className="single_sign_in__button_title">
              Sign up with Twitter
            </span>
          </button>

          <button
            className="single_sign_in__button"
            style={{
              borderColor: "#4285F4",
              boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)",
            }}
            onClick={handleGoogleLogin}
          >
            <IconContext.Provider
              value={{ className: "single_sign_in__button_icon" }}
            >
              <FcGoogle />
            </IconContext.Provider>
            <span className="single_sign_in__button_title">
              Sign up with Google
            </span>
          </button>
          <button
            className="single_sign_in__button"
            style={{
              borderColor: "#1877F2",
              boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)",
            }}
            onClick={handleFbLogin}
          >
            <IconContext.Provider
              value={{ className: "single_sign_in__button_icon fb_color" }}
            >
              <FaFacebook />
            </IconContext.Provider>
            <span className="single_sign_in__button_title fb_color">
              Sign up with Facebook
            </span>
          </button>

          <button
            className="single_sign_in__button"
            style={{
              borderColor: "#000000",
              boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)",
            }}
            onClick={handleAppleLogin}
          >
            <IconContext.Provider
              value={{ className: "single_sign_in__button_icon" }}
            >
              <FaApple />
            </IconContext.Provider>
            <span className="single_sign_in__button_title">
              Sign up with Apple
            </span>
          </button>

          <span
            style={emailSignupStyles}
            className="single_sign_in__button"
            onClick={() => setIsSignUpWithEmail(true)}
          >
            Sign Up with Email
          </span>
        </div>
      ) : (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleAddEmail(email)
              handleClickVerify(email)
            }}
          >
            <label htmlFor="email" className="forgot__label">
              Email
            </label>
            <div style={{ position: "relative" }}>
              <input
                id="email"
                placeholder="Enter email address"
                value={email}
                onChange={({ target }) => {
                  setEmail(target.value)
                  setCurrentEmail(target.value)
                }}
                className={cn("login__input forgot__input", {
                  "forgot__input--error": error,
                })}
                type="text"
              />
            </div>
            <span className="forgot__error">{error}</span>
          </form>
          <button
            disabled={isButtonDisabled}
            style={{ marginTop: 40 }}
            className={cn("forgot__button", {
              "forgot__button--disabled": isButtonDisabled,
            })}
            onClick={() => {
              handleAddEmail(email)
              handleClickVerify(email)
            }}
          >
            Continue
          </button>
        </>
      )}
    </>
  )
}

export default CreateAccount
