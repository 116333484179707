import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { ActivityIndicator } from "react-native"
import { connect } from "react-redux"
import "./confirmUsers.scss"
import { images } from "@utils/imageUtils"
import MyContactItem from "@components/Contacts/MyContactItem"
import { differenceBy } from "lodash"
import { formatInvitees } from "../../utils/contactUtils"
import {
  addTeeupInvitees,
  fetchTeeupUsersById,
  gotTeeupParts,
} from "@actions/teeupActions"
import InvitationSentModal from "./InvitationsSentModal"

const ConfirmUsersModal = (props) => {
  const {
    closeConfirmUsersModal,
    invitees,
    openModal,
    activeTeeup,
    openSentInvitationsModal,
  } = props

  const [loading, setLoading] = useState(false)
  // const [invitationsSent, setInvitationsSent] = useState(false);

  // useEffect(() => {
  //   console.log(invitationsSent);
  // }, [invitationsSent])

  const handleToggleContact =
    ({ data }) =>
    () => {
      this.setState(({ invitees }) => {
        const diffInvitees = differenceBy(invitees, [data], "id")
        const isInvited = diffInvitees.length !== invitees.length

        return {
          showInvitees: true,
          invitees: isInvited ? diffInvitees : invitees.concat([{ ...data }]),
        }
      })
    }

  const handleOnInviteesDone = async () => {
    setLoading(true)
    const { participants, closeOption, gotTeeupParts } = props
    const formattedInvitees = formatInvitees(invitees)
    const activeTeeupId = activeTeeup.id

    await addTeeupInvitees(activeTeeupId, formattedInvitees)
    const teeupUsers = await fetchTeeupUsersById(activeTeeupId)
    gotTeeupParts([teeupUsers])
    setLoading(false)
  }

  const backToSelectTeeupModal = () => {
    closeConfirmUsersModal()
    openModal()
  }

  const sendInvitation = async () => {
    await handleOnInviteesDone()
    openSentInvitationsModal()
    closeConfirmUsersModal()
  }

  return (
    <div className="confirmUsers__wrapper">
      <div className="confirmUsers__container ">
        <p>Invite to TeeUp</p>
        <img
          src={images.cross}
          className="closeConfirmUsers__img"
          onClick={() => closeConfirmUsersModal()}
        />
        <div className="usersToConfirm">
          {invitees.map((item) => (
            <MyContactItem
              key={item.id}
              setContent={() => {}}
              item={item}
              invitees={invitees}
              onRemoveInvitee={handleToggleContact({ data: item })}
              noHover
            />
          ))}

          {/* { invitationsSent && ReactDOM.createPortal(
            <InvitationSentModal setInvitationsSent={setInvitationsSent}/>
          ,document.getElementById('modal-root'))
          } */}
        </div>
        <div className="confirmUsers__bottom">
          <div className="confirmUsers__buttons">
            {loading ? (
              <>
                <button
                  className="bottom__btn disabled-back"
                  onClick={() => backToSelectTeeupModal()}
                >
                  Back
                </button>
                <button
                  className="bottom__btn red disabled-send"
                  onClick={() => sendInvitation()}
                >
                  {" "}
                  <ActivityIndicator color="#9d9d9d" /> Sending Invitations
                </button>
              </>
            ) : (
              <>
                <button
                  className="bottom__btn"
                  onClick={() => backToSelectTeeupModal()}
                >
                  Back
                </button>
                <button
                  className="bottom__btn red"
                  onClick={() => sendInvitation()}
                >
                  Send Invitations
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, { gotTeeupParts })(ConfirmUsersModal)
