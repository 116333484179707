import React from "react"
import PropTypes from "prop-types"
import { View, Text } from "react-native"

import { EventsStyles as styles } from "./styles"

const CalendarEventBlock = ({ title, hasConflist = false }) => {
  return (
    <View
      style={[
        styles.calendarEventContainer,
        hasConflist
          ? styles.conflictedCalendarEventContainer
          : styles.basicCalendarEventContainer,
      ]}
    >
      <View
        style={[
          styles.calendarEventLine,
          hasConflist
            ? styles.conflictedCalendarEventLine
            : styles.basicCalendarEventLine,
        ]}
      />
      <Text
        style={[
          styles.calendarEventText,
          hasConflist
            ? styles.conflictedCalendarEventText
            : styles.basicCalendarEventText,
        ]}
        numberOfLines={1}
        ellipsizeMode={"clip"}
      >
        {title || "(Unnamed event)"}
      </Text>
    </View>
  )
}

CalendarEventBlock.propTypes = {
  title: PropTypes.string,
  hasConflist: PropTypes.bool,
}

export default CalendarEventBlock
