import React, { useEffect } from "react"

import useOnlineTabState from "../../Hooks/useOnlineTabState"
import { apps } from "../apps"

import './index.scss'

const MeetingLink = ({ pasteText, setPasteText }) => {
  const { activeApp, setActiveApp, setActiveButtonId, addSuggestDetails } = useOnlineTabState()

  useEffect(() => {
    if (pasteText.includes('https://')
      && activeApp.name
      && !pasteText.includes(activeApp.name)) {
      //determine whether it is a link or message and the correct link for the selected app or not
      const filteredApps = apps.filter(item => pasteText.includes(item.name))

      filteredApps.length === 0
        ? setActiveApp(apps[apps.length - 1])
        : setActiveApp(filteredApps[0])
    }
  }, [pasteText])
  
  const handleDeletePasteLink = () => {
    setPasteText(null)
    addSuggestDetails(null)
    setActiveButtonId(1)
  }

  return (
    <div className="suggest-online-details-info">
      <h5 className="suggest-online-details-info__title">
        {pasteText.includes("https://")
          ? "Meeting Link"
          : "Meeting Message"}
      </h5>
      {pasteText}
      <div
        className="suggest-online-details--clear meeting-info"
        onClick={() => handleDeletePasteLink()}
      ></div>
    </div>
  )
}

export default MeetingLink
