import { StyleSheet, Platform } from "react-native"
import { Units, AppColors, AppFonts, AppSizes } from "@theme/"

const isIOS = Platform.OS === "ios"

export const styles = StyleSheet.create({
  overlay: {
    zIndex: 5,
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: AppColors.transparentOverlay,
  },
  overlayContainer: {
    flex: 1,
    paddingTop:
      AppSizes.statusBarHeight +
      AppSizes.navbarHeight +
      Units.responsiveValue(32),
    alignItems: "center",
    paddingHorizontal: Units.responsiveValue(32),
  },
  modalContainer: {
    backgroundColor: AppColors.brand.white,

    borderRadius: 8,
    paddingHorizontal: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(8),
    width: "100%",
  },
  titleWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: AppColors.brand.lightGrey230,
    paddingBottom: Units.responsiveValue(8),
  },
  title: {
    ...AppFonts.biggerBold,
    color: AppColors.brand.black2,
    textAlign: "center",
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.34),
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonWrapper: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: Units.responsiveValue(5),
    paddingHorizontal: Units.responsiveValue(24),
  },
  leftButtonText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.pink,
    lineHeight: Units.responsiveValue(18),
  },
  rightButtonText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.white,
    lineHeight: Units.responsiveValue(18),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  rightButtonWrapper: {
    backgroundColor: AppColors.brand.pink,
    borderRadius: Units.responsiveValue(15),
  },
  rightButtonDisabledWrapper: {
    opacity: 0.4,
  },
  textInputWithSymbolCounter: {
    marginTop: Units.responsiveValue(16),
    marginBottom: Units.responsiveValue(24),
  },
  textInputContainer: {
    borderWidth: 1,
    borderColor: AppColors.brand.warmGrey2,
    borderRadius: 8,
    minHeight: Units.responsiveValue(36),
    paddingHorizontal: Units.responsiveValue(8),
    justifyContent: "center",
  },
  textInput: {
    flex: isIOS ? 0 : 1,
    ...AppFonts.biggerMedium,
    color: AppColors.brand.warmGrey2,
    letterSpacing: Units.responsiveValue(-0.4),
    lineHeight: Units.responsiveValue(20),
    paddingVertical: Units.responsiveValue(8),
  },
  symbolCounterWrapper: {
    paddingTop: Units.responsiveValue(4),
    width: "100%",
    alignItems: "flex-end",
  },
  symbolCounterText: {
    ...AppFonts.extraSmallMedium,
    color: AppColors.brand.grey193,
    lineHeight: Units.responsiveValue(12),
  },
})
