import React, { useCallback } from "react"
import { View } from "react-native"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { ReactSVG } from "react-svg"

import { selectActiveTab } from "@selectors/common"
import { setActiveTab } from "@actions/commonActions"
import TabBarItem from "./TabBarItem/TabBarItem"
import styles from "./TabBar.styles"

import "./tabBar.scss"
import classNames from "classnames"

const TabBar = ({ tabs, activeTab, setActiveTab, newDesign }) => {
  const handleTabSelect = useCallback((id) => {
    setActiveTab(id)
  }, [])

  if (newDesign) {
    return (
      <div className="tab-bar">
        {tabs.map(({ id, ndIcon, title }) => (
          <div
            className={classNames("tab", { active: id === activeTab })}
            onClick={() => handleTabSelect(id)}
            key={id}
          >
            <ReactSVG src={ndIcon} />
            <span>{title}</span>
          </div>
        ))}
      </div>
    )
  }

  return (
    <View style={styles.container}>
      {tabs.map((tab) => (
        <TabBarItem
          {...tab}
          key={tab.id}
          active={activeTab === tab.id}
          onSelect={handleTabSelect}
          activeTab={activeTab}
        />
      ))}
    </View>
  )
}

const mapStateToProps = (state) => ({
  activeTab: selectActiveTab(state),
})

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: bindActionCreators(setActiveTab, dispatch),
})

TabBar.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      icon: PropTypes.node,
      activeIcon: PropTypes.node,
      title: PropTypes.string.isRequired,
    })
  ),
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default connect(mapStateToProps, mapDispatchToProps)(TabBar)
