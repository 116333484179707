import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import "./index.scss"
import Calendar from "../me/Calendar"
import { useDispatch, useSelector } from "react-redux"
import { selectActiveTeeups } from "@selectors/teeups"
import { fetchTeeups } from "@actions/teeupActions"

const MeView = ({ setContent, close, openCalendarSettings, activeScreen }) => {
  const teeupsState = useSelector(selectActiveTeeups)
  const dispatch = useDispatch()

  const [width, setWidth] = useState(0)
  const [isVisible, setIsVisible] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    if (!teeupsState.length) {
      dispatch(fetchTeeups())
    }
  }, [dispatch, teeupsState])

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 300)
    if (ref.current) {
      setWidth(ref.current.clientWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  useEffect(() => {
    function updateSize() {
      if (ref.current) {
        setWidth(ref.current.clientWidth)
      }
    }
    window.addEventListener("resize", updateSize)
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  const getCalendarWidth = () => {
    if (width > 0) return width
    return 1000
  }

  return (
    <div className="page" style={{ paddingLeft: 0, paddingTop: 12 }}>
      {isVisible ? (
        <Calendar
          refVal={ref}
          openCalendarSettings={openCalendarSettings}
          calendarWidth={getCalendarWidth()}
          setContent={setContent}
          close={close}
          activeScreen={activeScreen}
        />
      ) : (
        <>
          {/* <div className="me__calendar-small"></div> */}
          <div className="me__calendar"></div>
        </>
      )}
    </div>
  )
}

MeView.propTypes = {
  setContent: PropTypes.func,
  close: PropTypes.func,
  openCalendarSettings: PropTypes.func,
  activeScreen: PropTypes.number,
}

export default MeView
