import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.brand.lightGrey,
  },
  headerContainer: {
    paddingTop: Units.responsiveValue(15),
    paddingBottom: Units.responsiveValue(10),
    paddingLeft: Units.responsiveValue(16),
    backgroundColor: AppColors.brand.lightGrey,
  },
  headerText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.blackTwo,
  },
  tagList: {
    flexGrow: 0,
  },
})

export default styles
