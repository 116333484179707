import React from "react"
import { View, StyleSheet } from "react-native"
import PropTypes from "prop-types"
import { IconContext } from "react-icons"
import { MdCheck } from "react-icons/md"

import { AppColors, Units } from "@theme"

const styles = StyleSheet.create({
  checkbox: {
    width: Units.rem(1.5),
    height: Units.rem(1.5),
    borderRadius: Units.rem(1.5) / 2,
    borderWidth: 1,
    borderColor: AppColors.brand.grey217,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxActive: {
    backgroundColor: AppColors.brand.pink,
    borderWidth: 0,
  },
})

const CheckBox = ({ isActive }) => (
  <View style={[styles.checkbox, isActive && styles.checkboxActive]}>
    {isActive && (
      <IconContext.Provider
        value={{ color: AppColors.brand.white, size: Units.rem(1) }}
      >
        <MdCheck />
      </IconContext.Provider>
    )}
  </View>
)

CheckBox.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

export default CheckBox
