import React, { Component } from "react"
import {
  View,
  Text,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  Alert,
} from "react-native"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { differenceBy } from "lodash"
import strings from "@i18n"

import { selectUserId } from "@selectors/user"
import {
  getUserTags,
  createUserTag,
  deleteUserTag,
  addTagsToUsers,
  removeTagsToUsers,
} from "@actions/userActions"
import { getContacts } from "@actions/createTeeupActions"
import Topbar from "@ui/topbar"
import styles from "./styles"
import SelectedTags from "./SelectedTags"
import CreateTagInput from "./CreateTagInput"
import TagItem from "./TagItem"
import { getContactId } from "@utils/contactUtils"

const isIOS = Platform.OS === "ios"
const MAX_TAG_LENGTH = 30
const isCooeUser = (user) => user && (!!user.username || !!user.avatar)

class UserTags extends Component {
  constructor(props) {
    super(props)
    this.defaultTitle = props.title || "User Tags"
    this.initialUserstags = props.selectedTags || []
    this.state = {
      title: this.defaultTitle,
      newTagValue: "",
      tags: [],
      selectedTags: this.initialUserstags,
      deletedTagIds: {},
      isEditMode: false,
      isCreateTagMode: false,
    }
  }

  async componentDidMount() {
    const { userId } = this.props
    const response = await getUserTags(userId)
    this.setState({ tags: response.data })
  }

  handleSubmitNewTag = async () => {
    const { userId, onTagsChange, editTagsFromHeader } = this.props
    const { tags, newTagValue } = this.state
    if (newTagValue.length > MAX_TAG_LENGTH) return
    const newTag = await createUserTag(userId, newTagValue)
    if (!editTagsFromHeader) {
      this.handleToggleSelectedTag(newTag)()
    }
    const newTags = tags.concat([newTag])
    this.setState({ tags: newTags }, () => {
      if (onTagsChange) {
        onTagsChange(newTags)
      }
    })
  }

  handleDeleteTags = async () => {
    const { userId, onTagsChange } = this.props
    const { tags, selectedTags, deletedTagIds } = this.state
    const tagIds = Object.keys(deletedTagIds).filter((id) => deletedTagIds[id])
    await Promise.all(tagIds.map(async (tagId) => deleteUserTag(userId, tagId)))
    const newTags = tags.filter((tag) => !deletedTagIds[tag.id])
    const newSelectedTags = selectedTags.filter((tag) => !deletedTagIds[tag.id])
    this.setState(
      { tags: newTags, selectedTags: newSelectedTags, deletedTagIds: {} },
      () => {
        if (onTagsChange) {
          onTagsChange(newTags)
        }
      }
    )
  }

  handleToggleCreateTagMode = () => {
    const isCreateTagMode = !this.state.isCreateTagMode
    if (isCreateTagMode) {
      this.setState({ newTagValue: "" })
    }
    this.setState({ isCreateTagMode })
  }

  handleToggleSelectedTag = (tag) => () => {
    const { usersToTag } = this.props
    this.setState(({ selectedTags }) => {
      const diff = differenceBy(selectedTags, [tag], "id")
      if (!usersToTag) {
        tag.count = 0
      } else if (!tag.count) {
        tag.count = 1
      }
      const returnedTags =
        selectedTags.length === diff.length ? selectedTags.concat([tag]) : diff
      return {
        selectedTags: returnedTags,
      }
    })
  }

  handleAddDeletedTag = (tag) => () => {
    this.setState(({ deletedTagIds }) => ({
      deletedTagIds: {
        ...deletedTagIds,
        [tag.id]: tag.id,
      },
    }))
  }

  handleChangeCreateTagText = (text) => {
    if (text.length > MAX_TAG_LENGTH) return
    this.setState({ newTagValue: text })
  }

  handleCloseComponent = () => {
    this.props.onClose()
  }

  handleTagUsers = (selectedTags) => {
    const { userId, onTagUsersEnd } = this.props
    const userIdsToTag = this.getUserIds()
    const contactIdsToTag = this.getContactIds()

    const selectedTagIds = selectedTags.map((tag) => tag.id)
    const body = {
      userIds: userIdsToTag,
      contactIds: contactIdsToTag,
      tagIds: selectedTagIds,
    }

    addTagsToUsers(userId, body, onTagUsersEnd)
  }

  handleRemoveTags = (selectedTags) => {
    const { userId, onTagUsersEnd } = this.props
    const userIdsToTag = this.getUserIds()
    const contactIdsToTag = this.getContactIds()

    const selectedTagIds = selectedTags.map((tag) => tag.id)
    const body = {
      userIds: userIdsToTag,
      contactIds: contactIdsToTag,
      tagIds: selectedTagIds,
    }

    removeTagsToUsers(userId, body, onTagUsersEnd)
  }

  handleLeftPress = () => {
    const { isEditMode, selectedTags } = this.state
    if (isEditMode) {
      return this.setState({
        title: this.defaultTitle,
        isEditMode: false,
        deletedTagIds: {},
      })
    }

    const initialTagsIds = this.initialUserstags.map((tag) => tag.id)
    const selectedTagsIds = selectedTags.map((tag) => tag.id)

    const addedTags = selectedTags.filter(
      (tag) => !initialTagsIds.includes(tag.id)
    )
    if (addedTags.length > 0) {
      this.handleTagUsers(addedTags)
    }

    const removedTags = this.initialUserstags.filter(
      (tag) => !selectedTagsIds.includes(tag.id)
    )
    if (removedTags.length > 0) {
      this.handleRemoveTags(removedTags)
    }
    this.props.getContacts()
    return this.handleCloseComponent()
  }

  handleRightPress = async () => {
    const { isEditMode } = this.state

    if (isEditMode) {
      try {
        await this.handleDeleteTags()
      } catch (e) {
        /* eslint-disable-next-line */
        // console.log('handleDeleteTags: ', e)
        Alert.alert("Tags deleting error.")
      }
      return this.setState({
        title: this.defaultTitle,
        isEditMode: false,
      })
    }

    return this.setState({ title: "Edit Tags", isEditMode: true })

    // switch (title) {
    //     case 'Tag Users':
    //         return this.handleTagUsers()
    //     case 'Edit Tags': {
    //         await this.handleDeleteTags()
    //         return this.setState({ title: 'User Tags', isEditMode: false })
    //     }
    //     case this.props.title || 'User Tags':
    //         return this.setState({ title: 'Edit Tags', isEditMode: true })
    //     default:
    //         return null
    // }
  }

  renderHeader = () => {
    const { taggingUsersEnabled } = this.props
    const { title } = this.state
    switch (title) {
      case this.defaultTitle:
        if (!taggingUsersEnabled) return null
        return (
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Select a tag or create one</Text>
          </View>
        )

      default:
        return null
    }
  }

  renderItem = ({ item: tag }) => {
    const { taggingUsersEnabled } = this.props
    const { isEditMode } = this.state
    return (
      <TagItem
        tag={tag}
        isTaggingEnabled={taggingUsersEnabled}
        isEditMode={isEditMode}
        onTagItemPress={this.handleToggleSelectedTag(tag)}
        onDeletePress={this.handleAddDeletedTag(tag)}
      />
    )
  }

  keyExtractor = (item) => `userTag-${item.id}`

  getUserIds = () => {
    const { usersToTag } = this.props

    if (!usersToTag) {
      return []
    }
    return usersToTag
      .filter((contact) => isCooeUser(contact) || contact.type === "contact")
      .map((contact) => getContactId(contact))
  }

  getContactIds = () => {
    const { usersToTag } = this.props

    if (!usersToTag) {
      return []
    }

    return usersToTag
      .filter((contact) => !isCooeUser(contact) && contact.type !== "contact")
      .map((contact) => contact.id)
  }

  getLeftButtonLabel = () => {
    const { isEditMode } = this.state
    return isEditMode ? strings.systemButtons.cancel : null
  }

  getLeftButtonIcon = () => "back"

  getRightButtonLabel = () => {
    const { isEditMode } = this.state
    if (isEditMode) {
      return "Done"
    }
    return null
  }

  getRightButtonIcon = () => {
    const { isEditMode } = this.state
    if (isEditMode) {
      return null
    }
    return "pencil"
  }

  render() {
    const {
      title,
      isEditMode,
      isCreateTagMode,
      tags,
      selectedTags,
      deletedTagIds,
      newTagValue,
    } = this.state
    const { taggingUsersEnabled } = this.props
    const ListHeaderComponent = this.renderHeader()

    const filteredTagsData = tags.filter((tag) => !deletedTagIds[tag.id])
    return (
      <KeyboardAvoidingView
        style={styles.container}
        behavior={isIOS ? "padding" : null}
      >
        <Topbar
          title={title}
          borderBottomWidth={2}
          leftButtonLabel={this.getLeftButtonLabel()}
          leftButtonIcon={this.getLeftButtonIcon()}
          onLeftPress={this.handleLeftPress}
          rightButtonLabel={this.getRightButtonLabel()}
          rightButtonIcon={this.getRightButtonIcon()}
          onRightPress={this.handleRightPress}
        />
        {taggingUsersEnabled && (
          <SelectedTags
            selectedTags={selectedTags}
            onUnselectTag={this.handleToggleSelectedTag}
          />
        )}
        <FlatList
          style={styles.tagList}
          data={filteredTagsData}
          ListHeaderComponent={ListHeaderComponent}
          // stickyHeaderIndices={ListHeaderComponent ? [0] : null}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          bounces={false}
        />
        {isEditMode ? null : (
          <CreateTagInput
            isCreateTagMode={isCreateTagMode}
            onToggleCreateTagMode={this.handleToggleCreateTagMode}
            onChangeText={this.handleChangeCreateTagText}
            onBlur={this.handleToggleCreateTagMode}
            onSubmitEditing={this.handleSubmitNewTag}
            currentTagValue={newTagValue}
          />
        )}
      </KeyboardAvoidingView>
    )
  }
}

UserTags.propTypes = {
  userId: PropTypes.number.isRequired,
  // componentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  // isModal: PropTypes.bool.isRequired,
  title: PropTypes.string,
  selectedTags: PropTypes.array,
  usersToTag: PropTypes.array,
  onTagsChange: PropTypes.func,
  onTagUsersEnd: PropTypes.func,
  editTagsFromHeader: PropTypes.bool,
  taggingUsersEnabled: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
  const userId = selectUserId(state)
  return {
    userId,
    taggingUsersEnabled: "usersToTag" in ownProps,
  }
}

export default connect(mapStateToProps, { getContacts })(UserTags)
