import React, { useEffect, useState } from "react"
import { View, Text, Image } from "react-native"
import PropTypes from "prop-types"

import cn from "classnames"
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import PhoneInputNd from "./PhoneInputNd"
import styles from "./styles"
import teeUp from "../../../assets/images/teeUps.svg"


const AddContacts = ({
  handleDone,
  isPhoneContact,
  disableDoneButton,
  contactList,
  handleTextChange,
  defaultCountry,
  getInitPhoneNumber,
  setCurrentCheckbox,
  currentCheckbox,
  error,
  setError,
  setContactType,
}) => {
  const [emailInput, setEmailInput] = useState("")

  return (
    <div className={cn("inviteNd-by", {
      "inviteNd-by--error": error,
    })}>
      <h3 className="inviteNd-by__title">Invite by phone or email</h3>
      {contactList.map(({ value, id, existedUser, countryCode }, i) => {
        const isLastRow = i === contactList.length - 1
        
        return (
          <div>
            {isPhoneContact ? (
              <PhoneInputNd
                value={value}
                index={id}
                handleTextChange={handleTextChange}
                isLastRow={isLastRow}
                defaultCountry={defaultCountry}
                getInitPhoneNumber={getInitPhoneNumber}
                countryCode={countryCode}
                setError={setError}
                error={error}
              />
              ) : (
                <input
                  type="text"
                  className={cn("inviteNd-by__input", {
                    "inviteNd-by__input--error": error,
                  })}
                  placeholder="Add email address"
                  value={emailInput}
                  onChange={(e) => {
                    e.preventDefault()
                    setError(false)
                    setEmailInput(e.target.value)
                    handleTextChange(id)(e.target.value)
                  }}
                /> 
            )}
          </div>
        )
      })}
      <div className="inviteNd-by__choice--container">
        <div
          className="inviteNd-by__choice"
          onClick={() => {
            setContactType("phone")
            setError(false)
            setCurrentCheckbox(1)
          }}
        >
          <div className="inviteNd-by__checkbox">
            {currentCheckbox === 1 &&
              <div className="inviteNd-by__checkbox--active"></div>
            }
          </div>
          Phone
        </div>
        
        <div
          className="inviteNd-by__choice"
          onClick={() => {
            setContactType("email")
            setError(false)
            setCurrentCheckbox(2)}
          }
        >
          <div className="inviteNd-by__checkbox">
            {currentCheckbox === 2 &&
              <div className="inviteNd-by__checkbox--active"></div>
            }
          </div>
          Email
        </div>

        {error && (
          <div className="inviteNd-by__error">
            {currentCheckbox === 1
              ? "Please add correct phone number"
              : "Please add correct email address"}
          </div>
        )}
      </div>
      <button
        className={cn("inviteNd-by__button", {
          "inviteNd-by__button--error": error,
        })}
        onClick={() => {
          if (disableDoneButton) {
            setError(true)
          } else {
            currentCheckbox === 1
              ? handleDone("phone")
              : handleDone("email")
            setEmailInput("")
          }
        }}
        // disabled={disableDoneButton}
      >
        {currentCheckbox === 1
          ? "Add Phone"
          : "Add Email"}
      </button>
    </div>
  )
}

const AlreadyExistedUser = ({ username, isCooe, isPhoneContact }) => (
  <View style={styles.userWrapper}>
    <Text style={styles.existedUser}>{username}</Text>
    {isCooe && <Image style={styles.cooeIcon} source={teeUp} />}
  </View>
)
AlreadyExistedUser.propTypes = {
  username: PropTypes.string.isRequired,
  isCooe: PropTypes.bool,
  isPhoneContact: PropTypes.bool,
}
export default AddContacts
