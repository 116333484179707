import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

// import {
//     selectUnansweredNudgesForCurrentUser,
//     selectTeeupMentions,
// } from '@selectors/teeup'
import {
  selectActiveTeeup,
  selectVisibleTeeupPinnedMessages,
} from "@selectors/activeTeeup"
// import teeupActions from '@actions/teeupActions'

import { highlightTypes } from "@configs/enums"
import { View, Text, TouchableOpacity, Image } from "react-native"

import { Units } from "@theme/"
import { images } from "@utils/imageUtils"
import styles from "./styles"

const BUTTON_TYPES = {
  nudges: "nudges",
  mentions: "mentions",
  pinboard: "pinboard",
}

const BUTTON_ICONS = {
  nudges: images.nudge,
  mentions: images.mention,
  pinboard: images.thumbtack,
}

const getButtonType = (hasUnreadNudges, hasUnreadMentions) => {
  if (hasUnreadNudges) {
    return BUTTON_TYPES.nudges
  }
  if (hasUnreadMentions) {
    return BUTTON_TYPES.mentions
  }
  return BUTTON_TYPES.pinboard
}

class HighlightsButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      highlightsButtonType: getButtonType(
        props.hasUnreadNudges,
        props.hasUnreadMentions
      ),
    }
  }

  componentDidUpdate(prevProps) {
    const { unansweredNudgesCount, setHasUnreadNudges } = this.props

    if (unansweredNudgesCount > prevProps.unansweredNudgesCount) {
      setHasUnreadNudges({
        hasUnreadNudges: true,
      })
    }
  }

  onPress = () => {
    const { onPressPinnedIcon, hasUnreadNudges, hasUnreadMentions } = this.props
    const highlightsButtonType = getButtonType(
      hasUnreadNudges,
      hasUnreadMentions
    )
    let highlightType
    switch (highlightsButtonType) {
      case BUTTON_TYPES.nudges:
        highlightType = highlightTypes.nudges
        break
      case BUTTON_TYPES.mentions:
        highlightType = highlightTypes.mentions
        break

      default:
        highlightType = highlightTypes.pinboard
        break
    }

    onPressPinnedIcon(highlightType, hasUnreadNudges, hasUnreadMentions)
  }

  render() {
    const {
      pinnedMessagesCount,
      expanded,
      hasUnreadNudges,
      hasUnreadMentions,
    } = this.props
    const highlightsButtonType = getButtonType(
      hasUnreadNudges,
      hasUnreadMentions
    )

    const showMessageCount =
      !!pinnedMessagesCount && highlightsButtonType === BUTTON_TYPES.pinboard

    return (
      <View style={styles.badgesContainer}>
        <TouchableOpacity hitSlop={Units.hitSlop()} onPress={this.onPress}>
          <View
            style={[
              styles.pinnedCountWrapper,
              expanded ? styles.pinnedCountWrapperExpanded : null,
              !showMessageCount && styles.pinnedCountWrapperZero,
            ]}
          >
            <Image
              style={[
                styles.pinImage,
                expanded ? styles.pinImageExpanded : null,
                highlightsButtonType === BUTTON_TYPES.nudges ||
                highlightsButtonType === BUTTON_TYPES.mentions
                  ? styles.pinkIcon
                  : null,
              ]}
              source={BUTTON_ICONS[highlightsButtonType]}
            />
            {showMessageCount && (
              <Text
                style={[
                  styles.pinnedCount,
                  expanded ? styles.pinnedCountExpanded : null,
                ]}
              >
                {pinnedMessagesCount}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

HighlightsButton.propTypes = {
  pinnedMessagesCount: PropTypes.number,
  unansweredNudgesCount: PropTypes.number,
  mentionsCount: PropTypes.number,
  expanded: PropTypes.bool,
  onPressPinnedIcon: PropTypes.func,
  setHasUnreadNudges: PropTypes.func,
  hasUnreadNudges: PropTypes.bool,
  hasUnreadMentions: PropTypes.bool,
}

const mapStateToProps = (state) => {
  // const unansweredNudges = selectUnansweredNudgesForCurrentUser(state)
  // const mentions = selectTeeupMentions(state)
  const pinnedMessages = selectVisibleTeeupPinnedMessages(state)
  const activeTeeup = selectActiveTeeup(state)

  const unansweredNudges = []
  const mentions = []

  return {
    unansweredNudgesCount: unansweredNudges.length,
    mentionsCount: mentions.length,
    pinnedMessagesCount: pinnedMessages.length,
    nudgeType: activeTeeup.nudgeType,
    hasUnreadMentions: activeTeeup.hasUnreadMentions,
    hasUnreadNudges: activeTeeup.hasUnreadNudges,
  }
}

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//         {
//             ...teeupActions,
//         },
//         dispatch
//     )

export default connect(mapStateToProps, null)(HighlightsButton)
