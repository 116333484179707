import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Text,
  ScrollView,
  View,
  TouchableOpacity,
  TextInput,
  Image,
} from "react-native"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { setHelpPopup } from "@actions/commonActions"
// import ClickableRow from '@ui/settingsClickableRow'
import { AddButton, ArrowButton } from "@ui/settingsClickableRow/actionButtons"
import { userContactTypes } from "@utils/contactUtils"
import { selectHelpPopup } from "@selectors/common"
import strings from "@i18n"
import { confirmAlert } from "react-confirm-alert"
import { deleteContactMechanism } from "@actions/contactMechanismsActions"
import PhoneInput from "../../AddInvitees/AddContacts/phoneInput"

import EditContactScreenContainer from "../EditContactScreen"

import { Units } from "@theme"
import { images } from "@utils/imageUtils"
import { styles } from "./AccountDetailsStyles"

const getContactMethodIcon = (contactMethod) => {
  if (contactMethod.isVerified) {
    return images.cmVerified
  }

  if (contactMethod.isVerificationPending) {
    return images.cmPending
  }
  return images.cmUnverified
}

const deleteContact = (contact) => {
  confirmAlert({
    title: "Remove Contact Mechanism",
    message: `Are you sure you want to remove ${contact.value}`,
    buttons: [
      {
        label: "No",
        onClick: () => {},
      },
      {
        label: "Yes",
        onClick: async () => {
          await deleteContactMechanism(contact.id)
        },
      },
    ],
  })
}

const ClickableRow = ({ primaryText, secondaryText, onDelete }) => {
  return (
    <div className="clickable__row">
      {primaryText[0] && (
        <span className="clickable__title">{primaryText}</span>
      )}
      <div className="clickable__wrapper">
        {secondaryText && (
          <span className="clickable__value_accountDetails_value">
            {secondaryText[0].text ? secondaryText[0].text : "Not Set"}
          </span>
        )}
        <span className="clickable__action" onClick={onDelete}>
          Delete
        </span>
      </div>
    </div>
  )
}

const renderInputRow = (
  type,
  value,
  onRemove,
  onChangeText,
  isVerified,
  onChooseCountry,
  phoneRef,
  primaryEmail,
  unverifiedPressed,
  id,
  defaultCountry,
  editMode,
  setEditMode,
  onSave,
  countryCodeEl
) => {
  const showRemoveButton = !(value === primaryEmail)
  return (
    <View style={[styles.rowContainer, { zIndex: 1 }]} key={id}>
      {showRemoveButton && (
        <TouchableOpacity hitSlop={Units.hitSlop()} onPress={onRemove}>
          <Image style={styles.image} source={images.removeItem} />
        </TouchableOpacity>
      )}
      {/* //  : (
            //     <View style={styles.image} />
            // )} */}
      {type === "phone" ? (
        <PhoneInput
          value={value}
          index={id}
          handleTextChange={onChangeText}
          // isLastRow={isLastRow}
          defaultCountry={defaultCountry}
          disabled={!onChangeText}
          inputStyle={{ width: "250px" }}
          countryCodeEl={countryCodeEl}
          // getInitPhoneNumber={getInitPhoneNumber}
          // countryCode={countryCode}
        />
      ) : (
        <TextInput
          style={styles.contentText}
          onChangeText={(text) => onChangeText()(text)}
          placeholder="Add email"
          value={value}
          editable={!!onChangeText}
        />
      )}
      {isVerified === true && (
        <View style={styles.verifiedStyle}>
          <Text style={styles.verifiedTextStyle}>Verified</Text>
          <Image
            style={styles.verifiedIconStyle}
            source={images.checkWhiteIcon}
            resizeMode="contain"
          />
        </View>
      )}
      {isVerified === false && (
        <TouchableOpacity
          style={styles.unverifiedStyle}
          onPress={() => unverifiedPressed(value, id)}
        >
          <Text style={styles.verifiedTextStyle}>Unverified</Text>
        </TouchableOpacity>
      )}
      {editMode && (
        <div
          className="clickable__buttonsWrapper"
          style={{ marginLeft: "auto", maxWidth: "135px" }}
        >
          <button
            className="clickable__button clickable__button_cancel"
            onClick={() => onRemove()}
          >
            Cancel
          </button>
          <button
            className="clickable__button clickable__button_done"
            onClick={onSave}
          >
            Done
          </button>
        </div>
      )}
    </View>
  )
}

const AccountDetailsView = ({
  contactMechanisms,
  primaryEmail,
  primaryPhone,
  isEditOpened,
  content,
  setContent,
  getTitleScreen,
  closeEdit,
  addContact,
  activateInput,
  onChangeInputValue,
  deleteInput,
  inputValue,
  loading,
  defaultCountry,
  country,
  phoneRef,
  onChooseCountry,
  contactTypeHandler,
  contactType,
  onSave,
  popup,
  setPopup,
  modal,
}) => {
  // const { contactMechanisms, primaryEmail, primaryPhone, closeOption, country } = this.props
  const [editMode, setEditMode] = useState(true)

  useEffect(() => {
    if (sessionStorage["PopupShown"] != "yes") {
      setPopup(true)
    }
  }, [])

  const countryCodeEl = document.getElementsByClassName("selected-flag")[0]
  return (
    <View style={styles.container}>
      {isEditOpened ? (
        <div
          className="settings__modal settings__modal-content"
          style={{ animationName: "none", width: "100%", borderRadius: "8px" }}
          onClick={(event) => event.stopPropagation()}
        >
          {content}
        </div>
      ) : (
        <>
          {/* <Text style={styles.secondaryTopText}>
                    {strings.contactMethods.primaryContactMethodsPurpose}
                </Text> */}
          {/* <Text style={styles.primaryTopText}>
                    {strings.contactMethods.primaryContactMethodsTitle}
                </Text> */}
          {/* <div className="settings__rowWrapper">
                    <ClickableRow
                        primaryText={[strings.contactMethods.primaryTitle.phone]}
                        secondaryText={primaryPhone.value && [primaryPhone.value]}
                        actionButton={<ArrowButton />}
                        onPress={() => setContent(<EditContactScreenContainer onClose={closeEdit} title={getTitleScreen("phone")} contactType="phone" />)}
                    />
                    <ClickableRow
                        primaryText={[strings.contactMethods.primaryTitle.email]}
                        secondaryText={primaryEmail.value && [primaryEmail.value]}
                        actionButton={<ArrowButton />}
                        onPress={() => setContent(<EditContactScreenContainer onClose={closeEdit} title={getTitleScreen("email")} contactType="email" />)}
                    />
                </div> */}
          {/* <Text style={styles.secondaryTopText}>
                    {strings.contactMethods.additionalContactMethodsPurpose}
                </Text> */}
          {/* <View style={styles.splitter} /> */}

          <Text style={styles.ask}>
            People who have these email address and phone numbers will see you
            as a Coo·e user
          </Text>

          <div className="settings__rowWrapper">
            {contactMechanisms
              .filter((item) => item.isVerified)
              .map((cm) => {
                const type =
                  cm.typeid === userContactTypes.EMAIL ? "email" : "phone"
                return (
                  <ClickableRow
                    key={cm.value}
                    primaryText={[
                      strings[`contactMethods.additionalTitle.${type}`],
                    ]}
                    secondaryText={[
                      { text: cm.value, icon: getContactMethodIcon(cm) },
                    ]}
                    // actionButton={<ArrowButton />}
                    // onPress={() => setContent(<EditContactScreenContainer onClose={closeEdit} title={getTitleScreen(type)} contactType={type} />)}
                    onDelete={() => deleteContact(cm)}
                  />
                )
              })}
          </div>

          {activateInput &&
            renderInputRow(
              contactType,
              inputValue,
              deleteInput,
              onChangeInputValue,
              null,
              onChooseCountry,
              phoneRef,
              () => {},
              null,
              null,
              country ? country : defaultCountry,
              editMode,
              setEditMode,
              onSave,
              countryCodeEl && countryCodeEl
            )}

          <View style={styles.splitter} />

          <div className="settings-connection-details">
            <button
              className="settings-connection-details-button"
              // onClick={() => setContent(<EditContactScreenContainer onClose={closeEdit} title={getTitleScreen("phone")} contactType="phone" />)}>
              onClick={() => {
                contactTypeHandler("phone")
                addContact()
                setTimeout(() => {
                  addContact()
                }, 0)
              }}
            >
              Add Mobile Phone
            </button>
            <button
              className="settings-connection-details-button"
              // onClick={() => setContent(<EditContactScreenContainer onClose={closeEdit} title={getTitleScreen("email")} contactType="email" />)}>
              onClick={() => {
                contactTypeHandler("email")
                addContact()
              }}
            >
              Add Email
            </button>
          </div>

          {popup &&
            ReactDOM.createPortal(
              <>
                <div
                  className="settings__connectionDetails_popup_wrapper"
                  onClick={(e) => {
                    e.stopPropagation()
                    setPopup(false)
                    sessionStorage["PopupShown"] = "yes"
                  }}
                >
                  <div
                    className="settings__connectionDetails_popup"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span className="settings__connectionDetails_popup_heading">
                      Set up Connection Details
                    </span>
                    <img
                      src={images.helpPopupDetails}
                      className="settings__connectionDetails_popup_image"
                      alt=""
                    />
                    <span className="settings__connectionDetails_popup_text">
                      Adding phone numbers and emails will allow you to view all
                      invitations.
                    </span>
                    <span className="settings__connectionDetails_popup_text">
                      It will also allow others to see you as a Coo·e user.
                    </span>
                    <button
                      className="settings__connectionDetails_popup_button"
                      onClick={(e) => {
                        e.stopPropagation()
                        setPopup(false)
                        sessionStorage["PopupShown"] = "yes"
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </>,
              document.getElementById("modal-root")
            )}

          {modal && (
            <div
              className="settings__modal settings__modal-content"
              style={{
                animationName: "none",
                width: "100%",
                borderRadius: "8px",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              {modal}
            </div>
          )}
          {/* <div className="settings__rowWrapper">
                    <ClickableRow
                        primaryText={[strings.contactMethods.addNewTitle.phone]}
                        // actionButton={<AddButton />}
                        onPress={() => setContent(<EditContactScreenContainer onClose={closeEdit} title={getTitleScreen("phone")} contactType="phone" />)}
                    />
                    <ClickableRow
                        primaryText={[strings.contactMethods.addNewTitle.email]}
                        // actionButton={<AddButton />}
                        onPress={() => setContent(<EditContactScreenContainer onClose={closeEdit} title={getTitleScreen("email")} contactType="email" />)}
                    />
                </div> */}
        </>
      )}
    </View>
  )
}

AccountDetailsView.propTypes = {
  contactMechanisms: PropTypes.array,
  primaryEmail: PropTypes.object,
  primaryPhone: PropTypes.object,
  // linkToScreen: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const popup = selectHelpPopup(state)

  return {
    popup,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPopup: bindActionCreators(setHelpPopup, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsView)
