import React, { useState } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { View, Text, TouchableOpacity, StyleSheet } from "react-native"
import { dateToFormatTimezone } from "@utils/dateUtils"
import DetailGroup from "@ui/detailGroup"
import Avatar from "@ui/avatar"
import { AppColors, AppFonts, Units } from "@theme"

const syncDotRadius = 4

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Units.responsiveValue(12),
    paddingBottom: Units.responsiveValue(12),
    paddingTop: Units.responsiveValue(4),
    height: "auto",
  },
  syncLineWrapper: {
    width: "100%",
    height: Units.responsiveValue(4),
    justifyContent: "center",
  },
  syncLine: {
    width: "100%",
    height: Units.responsiveValue(2),
    backgroundColor: AppColors.brand.lightBlue61,
    justifyContent: "center",
    zIndex: 1,
  },
  syncLineDot: {
    position: "absolute",
    backgroundColor: AppColors.brand.lightBlue61,
    width: syncDotRadius * 2,
    height: syncDotRadius * 2,
    borderRadius: syncDotRadius,
    zIndex: 3,
  },
  timezoneRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  secondTimezoneRow: {
    paddingBottom: Units.responsiveValue(4),
  },
  additionalAvatars: {
    ...AppFonts.extraSmallMedium,
    lineHeight: Units.responsiveValue(18),
    color: AppColors.brand.grey169,
    paddingLeft: Units.responsiveValue(2),
  },
  firstAbbrevWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  firstAbbrev: {
    ...AppFonts.header20,
    color: AppColors.brand.black2,
    letterSpacing: -Units.responsiveValue(0.4),
    lineHeight: Units.responsiveValue(24),
    paddingRight: Units.responsiveValue(8),
  },
  secondAbbrevWrapper: { flexDirection: "row" },
  secondAbbrev: {
    ...AppFonts.extraSmallBold,
    color: AppColors.brand.grey169,
    letterSpacing: -Units.responsiveValue(0.4),
    fontSize: "10px",
    fontFamily: "greycliff-cf-bold",
  },
  timezoneOffset: {
    ...AppFonts.extraSmallBold,
    color: AppColors.brand.lightBlue22,
  },
  timeWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  time: {
    ...AppFonts.xlBold,
    color: AppColors.brand.black2,
  },
  timeAbbrev: {
    ...AppFonts.extraSmallBold,
    color: AppColors.brand.black2,
    paddingLeft: Units.responsiveValue(3),
    paddingTop: Units.responsiveValue(3),
    fontSize: "10px",
    fontFamily: "greycliff-cf-bold",
  },
  date: {
    ...AppFonts.extraSmallBold,
    color: AppColors.brand.grey169,
    textTransform: "uppercase",
    fontSize: "10px",
    fontFamily: "greycliff-cf-bold",
  },
  peopleWrapper: {
    width: "100%",
    borderRadius: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.grey243,
    paddingHorizontal: Units.responsiveValue(8),
    paddingVertical: Units.responsiveValue(8),
    marginTop: Units.responsiveValue(8),
  },
  peopleNameWrapper: {
    paddingLeft: Units.responsiveValue(8),
    justifyContent: "center",
  },
  peopleName: {
    ...AppFonts.mediumDemibold,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.3),
    color: AppColors.brand.blackTwo,
  },
  peopleCountry: {
    ...AppFonts.extraSmallDemibold,
    lineHeight: Units.responsiveValue(18),
    letterSpacing: -Units.responsiveValue(0.15),
    color: AppColors.brand.grey169,
  },
})

const syncLineCenterDot = (leftPosition) => ({
  position: "absolute",
  left: leftPosition,
  backgroundColor: AppColors.brand.white,
  width: syncDotRadius * 2,
  height: syncDotRadius * 2,
  borderRadius: syncDotRadius,
  borderWidth: 1,
  borderColor: AppColors.brand.lightBlue61,
  zIndex: 2,
})

const timezoneAvatarMargin = (index) => ({
  marginLeft: index === 0 ? 0 : -Units.responsiveValue(10),
  zIndex: 1000 - index,
})

const timezoneWrapper = (isLastItem) => ({
  flex: 1,
  paddingTop: Units.responsiveValue(4),
  marginBottom: Units.responsiveValue(isLastItem ? 0 : 16),
})

const peopleRow = (isLastItem) => ({
  flexDirection: "row",
  paddingBottom: Units.responsiveValue(isLastItem ? 0 : 8),
})

const calculateTimezonePosition = (layoutWidth, timezoneOffset) => {
  const segmentSize = (layoutWidth - syncDotRadius * 2) / 96
  const midPoint = layoutWidth / 2

  if (timezoneOffset > 12) {
    return {
      left: midPoint + timezoneOffset * segmentSize + 24 * segmentSize,
    }
  } else if (timezoneOffset >= 0 && timezoneOffset <= 12) {
    const timeZoneShift = timezoneOffset === 0 ? syncDotRadius / 2 : 0
    return {
      left: midPoint + timezoneOffset * 3 * segmentSize - timeZoneShift,
    }
  } else if (timezoneOffset < 0 && timezoneOffset >= -12) {
    return {
      right: midPoint - timezoneOffset * 3 * segmentSize - syncDotRadius,
    }
  } else {
    return {
      right:
        layoutWidth -
        timezoneOffset * segmentSize -
        24 * segmentSize -
        syncDotRadius,
    }
  }
}
const sortTimezonesByOffsetAndNames = (zones) => {
  const zonesSortedByNames = zones.map((zone) => {
    const zonesSortIndexes = zone.names
      .map((val, ind) => {
        return { ind, val }
      })
      .sort((a, b) => {
        return a.val.localeCompare(b.val)
      })
      .map((obj) => obj.ind)
    zone.names = zonesSortIndexes.map((index) => zone.names[index])
    zone.avatars = zonesSortIndexes.map((index) => zone.avatars[index])
    zone.participantsIds = zonesSortIndexes.map(
      (index) => zone.participantsIds[index]
    )

    return zone
  })
  return zonesSortedByNames.sort((a, b) => {
    const { timezone: firstOffset } = a
    const { timezone: secondOffset } = b
    if (a.isMyTZ || b.isMyTZ) {
      return 1
    }

    if (Math.abs(firstOffset) === Math.abs(secondOffset)) {
      return secondOffset - firstOffset
    }

    return Math.abs(firstOffset) - Math.abs(secondOffset)
  })
}

const SyncUpBlock = (props) => {
  const {
    isExpanded,
    setExpandedGroupIndex,
    formattedTimezones,
    linkParticipantDetail,
    people,
  } = props
  const [layoutWidth, setLayoutWidth] = useState(0)
  let sortedFormattedTimezones = _.assign({}, formattedTimezones)
  const sortedZones = sortTimezonesByOffsetAndNames(
    sortedFormattedTimezones.zones
  )
  sortedFormattedTimezones.zones = sortedZones

  const handleSyncUpLayout = ({ nativeEvent }) => {
    setLayoutWidth(nativeEvent.layout.width)
  }

  const renderTimeZonesDots = (timeZonesArray) => {
    return timeZonesArray.map((timezone, index) => {
      const positionStyles = calculateTimezonePosition(layoutWidth, timezone)
      return <View style={[styles.syncLineDot, positionStyles]} key={index} />
    })
  }

  const renderTimeZone = () => {
    const myTimezone = sortedFormattedTimezones.zones.find(
      (item) => !!item.isMyTZ
    )
    const myTimezoneAbbreviation = myTimezone?.abbreviation
    return sortedFormattedTimezones.zones.map((timezone, index) => {
      let {
        abbreviation,
        timezone: timezoneOffset,
        avatars,
        isMyTZ,
        country,
        time,
        timezoneName,
      } = timezone
      const isLastItem = index === formattedTimezones.zones.length - 1
      const timezoneCountryAndAbbrevText = `${country} (${myTimezoneAbbreviation} `
      timezoneOffset = isMyTZ ? 0 : timezoneOffset
      const isPositiveOffset = timezoneOffset >= 0
      const timezoneOffsetText = isPositiveOffset
        ? `+${timezoneOffset}`
        : `${timezoneOffset}`
      const [timeValue, timeAbbrev] = time.split(" ")
      const day = dateToFormatTimezone(new Date(), timezoneName, "ddd MMM DD")
      const additionalAvatarsCount = avatars.length - 9
      return (
        <View style={timezoneWrapper(isLastItem)} key={index}>
          <View style={styles.timezoneRow}>
            <View style={styles.firstAbbrevWrapper}>
              <Text style={styles.firstAbbrev}>{abbreviation}</Text>
              {!isExpanded &&
                avatars
                  .slice(0, 9)
                  .map((avatar, index) => (
                    <Avatar
                      key={"A" + index}
                      style={timezoneAvatarMargin(index)}
                      imageUrl={avatar}
                      size={20}
                    />
                  ))}
              {additionalAvatarsCount > 0 && (
                <Text style={styles.additionalAvatars}>
                  +{additionalAvatarsCount}
                </Text>
              )}
            </View>
            <View style={styles.timeWrapper}>
              <Text style={styles.time}>{timeValue}</Text>
              <Text style={styles.timeAbbrev}>{timeAbbrev}</Text>
            </View>
          </View>
          <View style={[styles.timezoneRow, styles.secondTimezoneRow]}>
            <View style={styles.secondAbbrevWrapper}>
              <Text style={styles.secondAbbrev}>
                {timezoneCountryAndAbbrevText}
              </Text>
              <Text
                style={[
                  styles.timezoneOffset,
                  { fontSize: "10px", fontFamily: "greycliff-cf-bold" },
                ]}
              >
                {timezoneOffsetText}
              </Text>
              <Text style={styles.secondAbbrev}>)</Text>
            </View>
            <View>
              <Text style={styles.date}>{day}</Text>
            </View>
          </View>
          <View
            style={styles.syncLineWrapper}
            onLayout={index === 0 ? handleSyncUpLayout : () => {}}
          >
            <View
              style={syncLineCenterDot(layoutWidth / 2 - syncDotRadius / 2)}
            />
            {renderTimeZonesDots([isMyTZ ? 0 : timezoneOffset])}
            <View style={styles.syncLine} />
          </View>
          {isExpanded && rednderExpandedTimeZone(timezone)}
        </View>
      )
    })
  }

  const rednderExpandedTimeZone = (timezone) => {
    const { avatars, names, participantsIds } = timezone
    return (
      <View style={styles.peopleWrapper}>
        {names.map((name, index) => {
          const currentUserId = participantsIds[index]
          const currentUserData = people[currentUserId]
          return (
            <TouchableOpacity
              key={"A_expand" + index}
              onPress={() => linkParticipantDetail(currentUserId)}
              style={peopleRow(index === names.length - 1)}
            >
              <Avatar imageUrl={avatars[index]} size={40} />
              <View style={styles.peopleNameWrapper}>
                <Text style={styles.peopleName}>{name}</Text>
                <Text style={styles.peopleCountry}>
                  {currentUserData?.primaryLocation}
                </Text>
              </View>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  return (
    <DetailGroup
      title="SYNC UP"
      secondaryTitle={`${formattedTimezones.zones.length} timezones`}
      expanded={isExpanded}
      onExpand={() => setExpandedGroupIndex(isExpanded ? -1 : 1)}
      noPadding={true}
      showDivider
    >
      <View style={styles.container}>{renderTimeZone()}</View>
    </DetailGroup>
  )
}

SyncUpBlock.propTypes = {
  isExpanded: PropTypes.bool,
  setExpandedGroupIndex: PropTypes.func,
  linkParticipantDetail: PropTypes.func,
  people: PropTypes.object,
}

export default SyncUpBlock
