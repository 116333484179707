import React, { useState, useRef } from "react"
import Modal from "react-modal"
import { ReactSVG } from "react-svg"
import { connect } from "react-redux"

import usePassword, { PASSWORD_REQUIREMENTS } from "../usePassword"
import StyledInput from "../../components/StyledInput/StyledInput"
import cn from "classnames"
import { images } from "@utils/imageUtils"

const modalStyles = {
  content: {
    width: 300,
    height: 260,
    borderRadius: "14px",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxSizing: "border-box",
    padding: "28px 40px",
  },
  overlay: {
    backgroundColor: "#00000080",
  },
}

const CreatePassword = ({
  resetPassErrorMessage,
  onClickContinueBtn = () => {},
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [passwordShown, setPassWordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const {
    password,
    confirmPassword,
    changePassword: setPassword,
    changeConfirmPassword: setConfirmPassword,
    clearStatus,
    failedRequirements,
    isValid,
    visiblePasswordError,
    visiblePasswordErrorDetail,
    visibleConfirmPasswordError,
  } = usePassword()

  const confirmInputRef = useRef()
  const closeModal = () => {
    setIsModalOpened(false)
  }

  return (
    <>
      {/* <img style={{ marginBottom: "9px" }} src={images.cooeModal} /> */}
      <span className="forgot__title">Create a Password</span>
      <p className="forgot__subtitle" style={{ marginBottom: 20 }}>
        Protect your account with a strong password for best security.
      </p>
      <span
        onClick={() => setIsModalOpened(true)}
        className="signup__requirements"
      >
        Password Requirements
      </span>
      <Modal
        isOpen={isModalOpened}
        onRequestClose={closeModal}
        style={modalStyles}
        ariaHideApp={false}
      >
        <span className="requirements__title">Your password must</span>
        {PASSWORD_REQUIREMENTS.map((req) => {
          const failedRule = failedRequirements.includes(req.type)

          return (
            <div key={`req-${req.type}`} className="requirements__item">
              {failedRule ? (
                <ReactSVG
                  className={cn("requirements__icon", {
                    "requirements__icon--error": failedRule,
                  })}
                  src={images.exclamationSign}
                />
              ) : (
                <img
                  className="requirements__icon"
                  src={images.suggestionIconDecided}
                  alt="requirements icon"
                />
              )}
              <span className="requirements__text">{req.text}</span>
            </div>
          )
        })}
        <span onClick={closeModal} className="requirements__got">
          Got it
        </span>
      </Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onClickContinueBtn(password)
        }}
      >
        <div style={{ position: "relative" }}>
          <StyledInput
            type={passwordShown ? "text" : "password"}
            value={password}
            setValue={setPassword}
            placeholder="Create New Password"
            isValid={!visiblePasswordError}
            validationError={visiblePasswordErrorDetail}
            hasIcon={true}
          />
          {password && (
            <img
              src={images.showPasswordImg}
              style={{
                position: "absolute",
                right: "13px",
                top: "25%",
                cursor: "pointer",
              }}
              onClick={() => setPassWordShown(!passwordShown)}
              alt="show psw icon"
            />
          )}
        </div>
        <div style={{ position: "relative" }}>
          <StyledInput
            type={confirmPasswordShown ? "text" : "password"}
            value={confirmPassword}
            setValue={setConfirmPassword}
            placeholder="Confirm Password"
            isValid={!visibleConfirmPasswordError}
            validationError={visibleConfirmPasswordError}
            hasIcon={true}
          />
          {confirmPassword && (
            <img
              src={images.showPasswordImg}
              style={{
                position: "absolute",
                right: "13px",
                top: "25%",
                cursor: "pointer",
              }}
              onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
              alt="show confirm psw icon"
            />
          )}
        </div>
        <span className="reset_password--error">{resetPassErrorMessage}</span>
        <button
          disabled={!isValid}
          style={{ marginTop: 40 }}
          className={cn("reset_password_button", {
            "reset_password_button--disabled": !isValid,
          })}
          onClick={() => onClickContinueBtn(password)}
        >
          Continue
        </button>
      </form>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    resetPassErrorMessage: state.user.resetPassErrorMessage,
  }
}

export default connect(mapStateToProps)(CreatePassword)
