import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import propTypes from "prop-types"
import get from "lodash/get"
import moment from "moment"
import { withRouter } from "react-router"
import PropTypes from "prop-types"

import { images } from "@utils/imageUtils"
import {
  selectUserInfo,
  selectUserInterests,
  selectMechanismsLocations,
  selectContactMechanisms,
  selectCalendarSyncSettings,
} from "@selectors/user"
import { selectAvaliableCalendars } from "@selectors/calendar"
import { selectAllContacts } from "@selectors/contacts"
import { selectGroups } from "@selectors/group"
import { selectTeeups, selectArchivedTeeups } from "@selectors/teeups"
import { selectAcceptedConnectionRequests } from "@selectors/requests"
// import { getSpecificCalendarByIdWithCalendars } from '@utils/calendarSyncUtils'
import * as contactActions from "@actions/contactActions"
import interestActions from "@actions/interestActions"
import { getDefaultTimezone } from "@utils/dateUtils"

import MeView from "../components/me/meView"
import { selectMatchings } from "@selectors/community"
import { userContactTypes } from "@utils/contactUtils"
import MoreContainer from "../components/more/moreContainer"
import CalendarSettingsContainer from "../components/more/calendarSettings"
import closeIcon from "../assets/images/close.svg"

class CalendarPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      content: null,
      animation: "",
    }
  }

  setContent = (content) => {
    this.setState({ isOpen: true, content })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { isOpen, content, animation } = this.state

    return (
      <MeView
        handleSettingsOpen={this.handleSettingsOpen}
        isOpen={isOpen}
        setContent={this.setContent}
        close={this.close}
        openCalendarSettings={this.onPressTimezone}
        // activeScreen={this.props.location.state.activeScreen}
      />
    )
  }
}

CalendarPage.propTypes = {
  userInfo: propTypes.object,
  amountOfInterests: propTypes.number,
  amountOfGroups: propTypes.number,
  amountOfContacts: propTypes.number,
  amountOfTeeups: propTypes.number,
  amountOfConnections: propTypes.number,
  amountOfLocations: propTypes.number,
  matchings: propTypes.arrayOf(propTypes.shape({})),
  getInterests: propTypes.func,
  contacts: propTypes.object,
  contactsLastSynced: propTypes.string,
  calendarTitle: propTypes.string,
  timeZone: propTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  const amountOfInterests = selectUserInterests(state).length
  const amountOfGroups = selectGroups(state).length
  const amountOfContacts = selectAllContacts(state).length
  const teeups = selectTeeups(state)
  const archivedTeeups = selectArchivedTeeups(state)
  const amountOfTeeups = teeups.length + archivedTeeups.length
  const amountOfConnections = selectAcceptedConnectionRequests(state).length
  const amountOfLocations = selectMechanismsLocations(state).length
  const matchings = selectMatchings(state)
  const contacts = selectContactMechanisms(state)
  const calendarSyncSettings = selectCalendarSyncSettings(state)
  const timeZone =
    get(calendarSyncSettings, "customTimezone") || `${getDefaultTimezone()}`
  let calendarTitle = null
  // if (calendar && syncTeeups) {
  //     let cal = getSpecificCalendarByIdWithCalendars(calendar, calendars)
  //     if (cal) {
  //         calendarTitle = cal.title
  //     }
  // }

  return {
    calendarTitle,
    amountOfInterests,
    amountOfGroups,
    amountOfContacts,
    amountOfTeeups,
    amountOfConnections,
    amountOfLocations,
    matchings,
    contacts,
    timeZone,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...interestActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CalendarPage))
