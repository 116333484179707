import React from "react"
import { TouchableOpacity, Image } from "react-native"
import PropTypes from "prop-types"
// import { push } from '@nav'
import { images } from "@utils/imageUtils"
import { AppColors } from "@theme/"
import closeIcon from "../../../assets/images/close.svg"
import { SectionListStyles } from "../../gamePlansCard/suggestionsList/suggestionListStyles"

import PoliciesAndServicesView from "./policiesAndServicesView"

class PoliciesAndServicesContainer extends React.Component {
  state = {
    content: null,
    title: "",
  }

  closeContent = () => {
    this.setState({ content: null, title: "" })
  }

  render() {
    const { closeOption, handleAction } = this.props
    const { content, title } = this.state

    return (
      <>
        {/* <div className="settings__top"> 
                <TouchableOpacity
                    onPress={closeOption}
                >
                    <Image
                        source={images.back}
                        style={SectionListStyles.cancelBtnImage}
                    />
                </TouchableOpacity>
                <span>Policies &amp; Services</span>
                <div></div>
            </div> */}
        {content && (
          <div
            className="settings__modal settings__modal-content"
            style={{ animationName: "none", width: "100%" }}
            onClick={(event) => event.stopPropagation()}
          >
            <div
              className="settings__top"
              style={{ justifyContent: "flex-start" }}
            >
              <img
                onClick={this.closeContent}
                src={closeIcon}
                alt="close icon"
                style={{ width: 20, height: 20, marginRight: "40px" }}
              />
              <span>{title}</span>
              <div></div>
            </div>
            {content}
          </div>
        )}
        {!content && <PoliciesAndServicesView handleAction={handleAction} />}
      </>
    )
  }
}
PoliciesAndServicesContainer.propTypes = {
  componentId: PropTypes.string,
}

export default PoliciesAndServicesContainer
