import {
  PROFILE_PAGE_SET_IS_TAG_USER,
  PROFILE_PAGE_SET_USER_INFO,
  PROFILE_PAGE_SET_IS_THREE_DOTS_OPEN
} from "./ProfilePageActions";

export const ProfilePageReducer = (state, { type, payload }) => {
  switch (type) {
    case PROFILE_PAGE_SET_USER_INFO: 
      return {
        ...state,
        userInfo: payload,
      }
    case PROFILE_PAGE_SET_IS_TAG_USER:
      return {
        ...state,
        isTagUser: payload
      }
    case PROFILE_PAGE_SET_IS_THREE_DOTS_OPEN:
      return {
        ...state, 
        isThreeDotsOpen: payload
      }
    default: 
      return state
  }
}
