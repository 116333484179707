import React, { Component } from "react"
import Props from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash"

import { selectActiveTeeup } from "@selectors/activeTeeup"
// import teeupActions from '@actions/teeupActions'
import { images } from "@utils/imageUtils"
import { AppColors, AppSizes, Units, AppFonts } from "@theme"
import I18n from "@i18n"
import TeeupPermissionsView from "./teeupPermissionsView"
import TeeupEditOrgsContainer from "./teeupEditOrgsContainer"
import closeIcon from "../../assets/images/close.svg"

class TeeupPermissionsContainer extends Component {
  static options() {
    return {
      topBar: {
        title: {
          text: I18n.orgsPermissions,
          fontSize: AppSizes.isIPhoneSE
            ? Units.rem(1.315)
            : AppFonts.navbar.fontSize,
        },
        noBorder: true,
        leftButtons: [
          {
            id: "close",
            text: I18n.systemButtons.close,
            icon: images.back,
            color: AppColors.brand.pink,
          },
        ],
        rightButtons: [],
      },
      popGesture: false,
    }
  }

  constructor(props) {
    super(props)
    const {
      teeup: { settings },
    } = props
    this.state = {
      settings,
      isEditOrgsOpened: false,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { settings: prevSettings } = prevProps.teeup
    const { settings } = this.props.teeup
    if (!_.isEqual(prevSettings, settings)) {
      this.setState({ settings })
    }
  }

  navigationButtonPressed({ buttonId }) {
    // switch (buttonId) {
    //     case 'close':
    //         pop(this.props.componentId)
    //         break
    //     default:
    // }
  }

  handleUpdateTeeupSettings = () => {
    const {
      teeup: { id: teeupId },
      isOrganiser,
    } = this.props
    const { settings } = this.state

    if (isOrganiser) {
      this.props.updateTeeup({
        teeupId,
        teeup: { settings },
      })
    }
  }

  handleClickEditOrgs = () => {
    this.setState(() => ({ isEditOrgsOpened: true }))
  }

  closeEditOrgs = () => {
    this.setState(() => ({ isEditOrgsOpened: false }))
  }

  handleChangeSettings = (key, value) => {
    const { settings } = this.state
    const newSettings = {
      ...settings,
      [key]: value,
    }
    this.setState({ settings: newSettings }, () => {
      this.handleUpdateTeeupSettings()
    })
  }

  render() {
    const {
      teeup: { organisers, createdby },
      isOrganiser,
      isOwner,
    } = this.props
    const { settings, isEditOrgsOpened } = this.state

    return (
      <>
        {isEditOrgsOpened ? (
          <TeeupEditOrgsContainer closeEditOrgs={this.closeEditOrgs} />
        ) : (
          <TeeupPermissionsView
            settings={settings}
            organisers={organisers}
            ownerId={createdby}
            editablePermissions={isOrganiser}
            editableOrganizersList={isOwner}
            onClickEditOrgs={this.handleClickEditOrgs}
            onChangeSettings={this.handleChangeSettings}
          />
        )}
      </>
    )
  }
}

TeeupPermissionsContainer.propTypes = {
  componentId: Props.string,
  teeup: Props.object,
  isOrganiser: Props.bool,
  isOwner: Props.bool,
  updateTeeup: Props.func.isRequired,
}

const mapStateToProps = (state) => {
  const teeup = selectActiveTeeup(state)
  return {
    teeup,
  }
}

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//         {
//             ...teeupActions,
//         },
//         dispatch
//     )

export default connect(mapStateToProps, null)(TeeupPermissionsContainer)
