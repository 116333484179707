export const PreviewStyles = {
  //MAIN STYLES

  Preview: {
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 999,
  },
  PreviewWrapper: {
    height: "max-content",
    minHeight: 489,
    width: 714,
    display: "flex",
    gap: 8,
  },

  //DOWLOAD BUTTONS STYLES

  DownloadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 265,
    margin: "50px auto 0",
  },
  DownloadText: {
    fontFamily: "greycliffcf-demibold-salt",
    margin: "0 auto 10px",
    letterSpacing: "-0.32px",
    color: "#222",
  },
  ButtonsWraper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto",
  },
  AppStoreButtons: {
    display: "inline-block",
    height: 40,
  },
  GoogleButton: {
    height: 58,
  },

  //CARD STYLES

  CardWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 347,
    flexGrow: 1,
    backgroundColor: "#fc766c",
    borderRadius: 4,
  },
  CardGameplan: {
    boxSizing: "border-box",
    padding: "16px 14px",
    borderRadius: 2,
    backdropFilter: "blur(50px)",
    boxShadow: "0 -3px 6px 0 rgba(0, 0, 0, 0.04)",
    backgroundColor: "#fff",
    // position: "absolute",
    // bottom: 2,
    // left: 2,
    // right: 2,
    minHeight: 310,
    width: 341,
    margin: "16px 2px 3px",
  },
  CardAvatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
    border: "solid 4px #fff",
    margin: "24px auto 0",
    overflow: "hidden",
    backgroundColor: "#8b8b8b",
  },
  CardOrganizer: {
    marginTop: 8,
    fontFamily: "greycliffcf-bold-salt",
    fontSize: 17,
    letterSpacing: "-0.34px",
    textAlign: "center",
    color: "#fff",
  },
  CardTitle: {
    marginBottom: 12,
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: "18px",
    letterSpacing: "-0.36px",
    textAlign: "left",
    color: "#222",
  },
  // CardGameplanItem: {
  //   marginBottom: 8,
  //   boxSizing: "border-box",
  //   height: 64,
  //   width: "100%",
  //   padding: "12px 29px 12px 12px",
  //   borderRadius: 8,
  //   backgroundColor: "#f5f5f5",
  //   display: "flex",
  //   alignItems: "center",
  // },
  CardPeopleCount: {
    padding: "8px 0 8px",
    fontFamily: "greycliffcf-bold-salt",
    fontSize: 17,
    letterSpacing: "-0.23px",
    textAlign: "left",
    color: "#222",
  },
  CardIconWrapper: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  CardPeopleJoin: {
    marginLeft: 16,
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 17,
    letterSpacing: "-0.4px",
    textAlign: "left",
    lineHeight: 1.29,
    color: "#212121",
  },

  //SIGNUP STYLES

  SignUpWrapper: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 8,
    boxSizing: "border-box",
  },
  SignUp: {
    width: "100%",
    height: 390,
    borderRadius: 4,
    backgroundColor: "#fff",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    padding: "0 62px",
    flex: "1 1 auto",
  },
  SignIn: {
    width: "100%",
    height: 82,
    borderRadius: 4,
    backgroundColor: "#fff",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "greycliffcf-medium-salt",
    fontSize: 16,
    letterSpacing: "-0.32px",
    color: "#222",
  },
  SignUpTitle: {
    marginTop: 24,
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 18,
    letterSpacing: "-0.36px",
    textAlign: "center",
    lineHeight: 1.29,
    color: "#000",
  },
  SignUpSocial: {
    display: "flex",
    gap: 12,
    justifyContent: "space-between",
    marginTop: 24,
    cursor: "pointer",
  },
}
