import React from "react"
import propTypes from "prop-types"
import { TouchableOpacity, View, Text, Image } from "react-native"
import { v4 as uuidv4 } from "uuid"

import { images } from "@utils/imageUtils"
import { DEFAULT_SORT, SORT_KEYS } from "@utils/teeupUtils"
import { styles } from "./styles"

const sortButtons = {
  default: DEFAULT_SORT,
  createdatDesc: {
    id: "createdatDesc",
    sort: SORT_KEYS.createdat,
    desc: true,
    title: "Newest first",
  },
  createdatAsc: {
    id: "createdatAsc",
    sort: SORT_KEYS.createdat,
    desc: false,
    title: "Oldest first",
  },
  updatedat: {
    id: "updatedat",
    sort: SORT_KEYS.startsWhen,
    desc: false,
    title: "Happening Soonest",
  },
  unscheduled: {
    id: "unscheduled",
    sort: SORT_KEYS.noExactWhen,
    desc: false,
    title: "Unscheduled",
  },
}

const DEFAULT_TITLE = "Recent activity"

const SortByContent = ({ onSortButtonPress, teeupSorting }) =>
  Object.values(sortButtons).map((sortButton = {}) => (
    <TouchableOpacity
      key={`${sortButton ? sortButton.id : uuidv4()}`}
      onPress={onSortButtonPress(sortButton)}
    >
      <View style={styles.sortButtonLine}>
        <Text style={styles.buttonTitle}>
          {sortButton.title || DEFAULT_TITLE}
        </Text>
        {((!teeupSorting && !sortButton) ||
          (teeupSorting &&
            sortButton &&
            teeupSorting.id === sortButton.id)) && (
          <Image style={styles.checkIcon} source={images.checkWhiteIcon} />
        )}
      </View>
    </TouchableOpacity>
  ))

SortByContent.propTypes = {
  onSortButtonPress: propTypes.func.isRequired,
  teeupSorting: propTypes.object,
}

export default SortByContent
