import React, { useState, useRef } from "react"
import {
  PanResponder,
  View,
  Text,
  TouchableOpacity,
  Image,
  Animated,
} from "react-native"
import _ from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Linkify from "linkifyjs/react"
import ReactTooltip from "react-tooltip"
import { toggleSuggestionDetails } from "@actions/commonActions"

import { selectTeeupPeople } from "@selectors/teeups"
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { selectTeeupReactionsBySuggestionId } from "@selectors/teeups"
// import { hasConflictingEvents } from '@selectors/calendar'
import {
  selectNewSuggestions,
  selectRemovedSuggestions,
} from "@selectors/localData"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { selectUserTimezone } from "@selectors/user"
// import { selectSuggestionsWithDetails } from '@selectors/localData'
import { capitalizeFirstLetter } from "@utils/dataUtils"
// import { openMap } from '@utils/linkUtils'
import { images } from "@utils/imageUtils"
// import teeupActions from '@actions/teeupActions'
import {
  getSuggestionValue,
  getGameplanIcon,
  GAMEPLAN_OPTIONS,
  locationTypeIds,
  isPastSuggestion,
} from "@utils/gamePlanUtils"
import { getCurrentTimezone, getTimezoneAbbreviation } from "@utils/dateUtils"
import { EVENT_TYPES, getEventWithTime } from "@utils/calendarUtils"
import { AppColors, Units } from "@theme/"
import threeDotsIcon from "../../assets/images/three-dots.svg"
// import { showSuggestionChat, routeCalendarConflict } from '@nav'
import Icon from "@ui/icon"
import {
  GameplansRowStyles,
  IconsProps,
  draggableContainerInner,
  messageTypesStyles,
  rowReorderImage,
} from "./gameplansStyles"
import ReactionButton from "./reactionButton"
// import RecommendedItem from './RecommendedItem'
// import suggestionDragGlobal from '../suggestionDragGlobal'
import strings from "@i18n"
import { IconContext } from "react-icons"
import "./svgStyles.scss"
import { ReactComponent as LocationSVG } from "../../assets/images/location.svg"
import { ReactComponent as ClockSVG } from "../../assets/images/clock.svg"
import GamePlanTooltip from "./GamePlanTooltip"

import ReactDOM from "react-dom"
import SuggestionPopup from "./SuggestionPopup"
import { bindActionCreators } from "redux"
import { selectBeforeCalendarDate } from "../../selectors/calendar"

// Util functions used to measure component overflowness
function measureHeightAsync(component) {
  return new Promise((resolve) => {
    component.measure((x, y, w, h) => {
      resolve(h)
    })
  })
}

function nextFrameAsync() {
  return new Promise((resolve) => requestAnimationFrame(() => resolve()))
}

const onSuggestionChat = (props) => {
  // showSuggestionChat({ suggestionParams: props })
}

const TypeIcon = ({
  type,
  selected,
  decided,
  isNew,
  isTeeupCancelled,
  isMyMessage,
  inactive,
}) => {
  let iconColor = AppColors.brand.warmGrey2
  if (isNew) {
    iconColor = AppColors.brand.pink
  } else if (isTeeupCancelled) {
    iconColor = AppColors.brand.warmGrey2
  } else if (selected) {
    // iconColor = AppColors.brand.blue2
    iconColor = "rgb(157,157,157)"
    if (decided) {
      // iconColor = AppColors.brand.green
      iconColor = "#767676"
    }
  }

  if (isMyMessage && !inactive) {
    // iconColor = AppColors.brand.darkBlue
    iconColor = "rgb(116,169,198)"
  }

  return (
    <IconContext.Provider value={{ color: iconColor }}>
      {getGameplanIcon({ type })}
    </IconContext.Provider>
  )
}

TypeIcon.propTypes = {
  type: PropTypes.string,
  selected: PropTypes.bool,
  decided: PropTypes.bool,
  isNew: PropTypes.bool,
  isTeeupCancelled: PropTypes.bool,
  isMyMessage: PropTypes.bool,
  inactive: PropTypes.bool,
}

class DraggableSuggestionRowContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this._rippleOpacity = new Animated.Value(props.isDragged ? 1 : 0)
    this._rippleScale = new Animated.Value(1)
    this._draggingOverlayOpacity = new Animated.Value(1)
    this._rowOpacity = new Animated.Value(1)
    this._rowScale = new Animated.Value(1)
    this._rippleAnimation = null
    this.markSeen(props)
    this.markRemovedSeen(props)

    this._panResponder = PanResponder.create({
      onShouldBlockNativeResponder: () => true,
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => false,
      onMoveShouldSetPanResponder: (evt, gestureState) => {
        const { dx, dy } = gestureState
        return dx > 2 || dx < -2 || dy > 2 || dy < -2
      },
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => {
        const { dx, dy } = gestureState
        return dx > 2 || dx < -2 || dy > 2 || dy < -2
      },
      onPanResponderTerminationRequest: () => false,
      onPanResponderGrant: props.onTouchStart,
      onPanResponderMove: props.onTouchMove,
      onPanResponderEnd: props.onTouchEnd,
    })
    this.state = {
      shouldShowDetails: false,
      measured: true,
      threeDotsSuggestions: false,
      displayNew: true,
    }
    this.messageRef = React.createRef()
    this.decidedRef = React.createRef()
  }

  async componentDidMount() {
    const { shouldMeasureOverflow } = this.props
    this._isMounted = true

    if (shouldMeasureOverflow) {
      await this.measureOverflow()
    }
  }

  measureOverflow = async () => {
    this.setState({ measured: false })
    await nextFrameAsync()

    if (!this._isMounted) {
      return
    }

    // Get the height of the text with no restriction on number of lines
    const fullHeight = await measureHeightAsync(this._text)
    this.setState({ measured: true })
    await nextFrameAsync()

    if (!this._isMounted) {
      return
    }

    // Get the height of the text now that number of lines has been set
    const limitedHeight = await measureHeightAsync(this._text)

    if (fullHeight > limitedHeight) {
      this.setState({ shouldShowDetails: true })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setTextRef = (text) => {
    this._text = text
  }

  componentDidUpdate(prevProps, prevState) {
    this.markSeen(this.props)
    this.markRemovedSeen(this.props)
    const {
      suggestionsWithDetails,
      suggestion: { id, value, value2, type },
    } = this.props
    const { shouldShowDetails } = this.state
    const isSuggestionDetails = suggestionsWithDetails.find(
      (suggestion) => suggestion === id
    )
    // save suggestion id, if suggestion have long details
    if (
      id &&
      !isSuggestionDetails &&
      prevState.shouldShowDetails !== shouldShowDetails &&
      shouldShowDetails &&
      (value || value2) &&
      (type === GAMEPLAN_OPTIONS.when || type === GAMEPLAN_OPTIONS.where)
    ) {
      // teeupActions.setShowSuggestionDetails(id)
    }
    if (prevProps.isDragging && !this.props.isDragging) {
      this._draggingOverlayOpacity.setValue(1)
    }
  }

  showRipple(show = true) {
    if (this._rippleAnimation) {
      this._rippleAnimation.stop()
    }
    if (show) {
      this._rippleOpacity.setValue(0)
      this._rippleScale.setValue(0)
      this._rippleAnimation = Animated.parallel([
        Animated.timing(this._rippleOpacity, {
          toValue: 1,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(this._rippleScale, {
          toValue: 1,
          duration: 100,
          useNativeDriver: true,
        }),
      ])
      this._rippleAnimation.start()
    } else {
      this._rippleAnimation = Animated.timing(this._rippleOpacity, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      })
      this._rippleAnimation.start()
    }
  }

  setOnDropPoint() {
    this._rowOpacity.setValue(1)
  }

  animateSelectSuggestion() {
    Animated.timing(this._draggingOverlayOpacity, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start()
  }

  animateDismiss() {
    Animated.parallel([
      Animated.timing(this._rowOpacity, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }),
      Animated.timing(this._rowScale, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }),
    ]).start()
  }

  isPast = () => isPastSuggestion(this.props.suggestion, "day")

  isWithdrawn = () => {
    const { suggestion } = this.props
    return suggestion ? suggestion.isWithdrawn : false
  }

  markSeen = (props) => {
    // const {
    //     isNew,
    //     suggestion,
    //     activeTeeupId,
    //     isRowExpanded,
    //     initiateStatusUpdate,
    // } = props
    // if (isNew && initiateStatusUpdate) {
    //     if (
    //         suggestion &&
    //         activeTeeupId &&
    //         isRowExpanded &&
    //         !this.newTimeoutForNew
    //     ) {
    //         this.newTimeoutForNew = setTimeout(() => {
    //             teeupActions.removeSuggestionNewStatus(
    //                 suggestion.id,
    //                 activeTeeupId
    //             )
    //             this.newTimeoutForNew = 0
    //         }, 5000)
    //     }
    // }
  }

  markRemovedSeen = (props) => {
    const {
      isRemoved,
      suggestion,
      activeTeeupId,
      isRowExpanded,
      initiateStatusUpdate,
    } = props
    // if (isRemoved && initiateStatusUpdate) {
    //     if (
    //         suggestion &&
    //         activeTeeupId &&
    //         isRowExpanded &&
    //         !this.newTimeoutForRemoved
    //     ) {
    //         this.newTimeoutForRemoved = setTimeout(() => {
    //             teeupActions.removeSuggestionRemovedStatus(
    //                 suggestion.id,
    //                 activeTeeupId
    //             )
    //             this.newTimeoutForRemoved = 0
    //         }, 5000)
    //     }
    // }
  }

  showDayCalendar = () => {
    // const { suggestion, teeupName } = this.props
    // const passedEvent = {
    //     ...getEventWithTime(suggestion),
    //     type: EVENT_TYPES.teeup,
    //     name: teeupName,
    // }
    // routeCalendarConflict({
    //     selectedDate: this.props.suggestion.startDate,
    //     conflictedEvents: [passedEvent],
    // })
  }

  renderStatusIndicator() {
    const {
      // isNew,
      // isRemoved,
      suggestion: { decided, selected },
      suggestion,
      isTeeupCancelled,
      newSuggestions,
      removedSuggestions,
    } = this.props

    const isPast = this.isPast()
    const isWithdrawn = this.isWithdrawn()

    const inactive = isPast || isWithdrawn

    let isNew = undefined
    const isNewArr = Object.keys(newSuggestions).map((key, index) => {
      if (key.includes(suggestion.id) === true) {
        setTimeout(() => {
          this.setState({ displayNew: false })
        }, 15000)
        isNew = true
      } else {
        isNew = false
      }
    })

    let isRemoved = undefined
    const isRemovedArr = Object.keys(removedSuggestions).map(function (
      key,
      index
    ) {
      key.includes(suggestion.id) ? (isRemoved = true) : (isRemoved = false)
    })
    if (inactive) {
      return (
        <></>
        // <View
        //     style={[
        //         GameplansRowStyles.markContainer,
        //         !isTeeupCancelled &&
        //             GameplansRowStyles.selectedMarkContainer,
        //     ]}
        // >
        //     <Text style={GameplansRowStyles.newMarkGameplan}>
        //         New
        //     </Text>
        // </View>
      )
    } else if (selected) {
      if (decided) {
        return (
          // <View
          //     style={[
          //         GameplansRowStyles.markContainer,
          //         !isTeeupCancelled &&
          //             GameplansRowStyles.decidedMarkContainer,
          //     ]}
          // >
          //     <Text style={GameplansRowStyles.decidedMark}>
          //         {strings.gameplan}
          //     </Text>
          //     <Icon {...IconsProps.lockIcon} />
          // </View>
          <View
            style={[
              GameplansRowStyles.markContainer,
              !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
            ]}
          >
            <Text style={GameplansRowStyles.selectedMarkGameplan}>
              {strings.gameplan}
            </Text>
            {/* <Text style={GameplansRowStyles.selectedMark}>
                            {strings.gameplan}
                        </Text> */}
          </View>
        )
      }
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
          ]}
        >
          <Text style={GameplansRowStyles.selectedMarkGameplan}>
            {strings.gameplan}
          </Text>
          {/* <Text style={GameplansRowStyles.selectedMark}>
                        {strings.gameplan}
                    </Text> */}
        </View>
      )
    } else if (isRemoved) {
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.isRemovedMarkContainer,
          ]}
        >
          <Text style={GameplansRowStyles.removedMark}>Removed</Text>
        </View>
      )
    } else if (isNew && this.state.displayNew) {
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.isNewMarkContainer,
          ]}
        >
          <Text style={GameplansRowStyles.newMarkGameplan}>
            {/* {selected ? 'New Game Plan' : 'New'} */}
            New
          </Text>
        </View>
      )
    } else if (
      (selected && isNew && this.state.displayNew) ||
      (isNew && this.state.displayNew)
    ) {
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
          ]}
        >
          <Text style={GameplansRowStyles.newMarkGameplan}>New</Text>
        </View>
      )
    } else {
      return (
        <></>
        // <View
        //     style={[
        //         GameplansRowStyles.markContainer,
        //         !isTeeupCancelled &&
        //             GameplansRowStyles.selectedMarkContainer,
        //     ]}
        // >
        //     <Text style={GameplansRowStyles.selectedMarkGameplan}>
        //         {strings.gameplan}
        //     </Text>
        // </View>
      )
    }

    return null
  }

  renderStatusIndicatorForList() {
    const {
      // isNew,
      // isRemoved,
      suggestion: { decided, selected },
      suggestion,
      isTeeupCancelled,
      newSuggestions,
      removedSuggestions,
    } = this.props

    const isPast = this.isPast()
    const isWithdrawn = this.isWithdrawn()

    const inactive = isPast || isWithdrawn

    let isNew = undefined
    const isNewArr = Object.keys(newSuggestions).map((key, index) => {
      if (key.includes(suggestion.id) === true) {
        setTimeout(() => {
          this.setState({ displayNew: false })
        }, 15000)
        isNew = true
      } else {
        isNew = false
      }
    })

    let isRemoved = undefined
    const isRemovedArr = Object.keys(removedSuggestions).map(function (
      key,
      index
    ) {
      key.includes(suggestion.id) ? (isRemoved = true) : (isRemoved = false)
    })
    if (inactive) {
      return (
        <></>
        // <View
        //     style={[
        //         GameplansRowStyles.markContainer,
        //         !isTeeupCancelled &&
        //             GameplansRowStyles.selectedMarkContainer,
        //     ]}
        // >
        //     <Text style={GameplansRowStyles.newMarkGameplan}>
        //         New
        //     </Text>
        // </View>
      )
    } else if (selected) {
      if (decided) {
        return (
          // <View
          //     style={[
          //         GameplansRowStyles.markContainer,
          //         !isTeeupCancelled &&
          //             GameplansRowStyles.decidedMarkContainer,
          //     ]}
          // >
          //     <Text style={GameplansRowStyles.decidedMark}>
          //         {strings.gameplan}
          //     </Text>
          //     <Icon {...IconsProps.lockIcon} />
          // </View>
          <View
            style={[
              GameplansRowStyles.markContainer,
              !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
            ]}
          >
            <Text style={GameplansRowStyles.selectedMarkGameplan}>
              {strings.gameplan}
            </Text>
            {/* <Text style={GameplansRowStyles.selectedMark}>
                        {strings.gameplan}
                    </Text> */}
          </View>
        )
      }
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
          ]}
        >
          <Text style={GameplansRowStyles.selectedMarkGameplan}>
            {strings.gameplan}
          </Text>
          {/* <Text style={GameplansRowStyles.selectedMark}>
                        {strings.gameplan}
                    </Text> */}
        </View>
      )
    } else if (isRemoved) {
      return (
        // <View
        //     style={[
        //         GameplansRowStyles.markContainer,

        //         !isTeeupCancelled &&
        //             GameplansRowStyles.isRemovedMarkContainer,
        //     ]}
        // >
        <Text
          style={[
            GameplansRowStyles.removedMark,
            GameplansRowStyles.newMarkGameplanList,
          ]}
        >
          Removed
        </Text>
        // </View>
      )
    } else if (isNew && this.state.displayNew) {
      return (
        // <View
        //     style={[
        //         GameplansRowStyles.markContainer,
        //         !isTeeupCancelled &&
        //             GameplansRowStyles.isNewMarkContainer,
        //     ]}
        // >
        <Text
          style={[
            GameplansRowStyles.newMarkGameplan,
            GameplansRowStyles.newMarkGameplanList,
          ]}
        >
          {/* {selected ? 'New Game Plan' : 'New'} */}
          New
        </Text>
        // </View>
      )
    } else if (
      (selected && isNew && this.state.displayNew) ||
      (isNew && this.state.displayNew)
    ) {
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
          ]}
        >
          <Text style={GameplansRowStyles.newMarkGameplan}>New</Text>
        </View>
      )
    } else {
      return (
        <></>
        // <View
        //     style={[
        //         GameplansRowStyles.markContainer,
        //         !isTeeupCancelled &&
        //             GameplansRowStyles.selectedMarkContainer,
        //     ]}
        // >
        //     <Text style={GameplansRowStyles.selectedMarkGameplan}>
        //         {strings.gameplan}
        //     </Text>
        // </View>
      )
    }

    return null
  }

  renderSuggestionLines() {
    const {
      showHints,
      isTimeZoneEnabled,
      suggestion,
      isMyMessage,
      hasConflict,
      currentUserTimezone,
      fromSuggestionChat,
      suggestionsWithDetails,
      createSuggestion,
      fromConversation,
      fromInitialGameplans,
      isFromSuggestionListHeader,
      fromSuggestionPopup,
      newDesign,
    } = this.props
    const { measured } = this.state

    const {
      id,
      locationType,
      isCustomDate,
      isCustomTime,
      isFreestyleValues = false,
    } = suggestion
    const isPast = this.isPast()
    const isWithdrawn = this.isWithdrawn()

    const isShowSuggestionDetails = suggestionsWithDetails.find(
      (suggestion) => suggestion === id
    )

    const type = this.props.type ? this.props.type : suggestion.type
    const suggestionValues = getSuggestionValue(suggestion, type)
    const {
      startTimeFormatted = "",
      endTimeFormatted = "",
      isOnlyStartTime = false,
    } = suggestionValues
    const isSuggestionWithoutTime =
      !startTimeFormatted && !endTimeFormatted && !isTimeZoneEnabled

    const inactive = isPast || isWithdrawn

    const suggestionTextSyle = [
      GameplansRowStyles.suggestionText,
      !inactive &&
        (isMyMessage
          ? GameplansRowStyles.suggestionTextActiveMy
          : GameplansRowStyles.suggestionTextActive),
      newDesign && {
        fontSize: "15px",
        fontWeight: 600,
        letterSpacing: "-0.35px",
        color: "#212121",
      },
    ]

    const isOnline = locationType === locationTypeIds?.online

    const isCollapseSuggestionDetails =
      suggestion &&
      (type === GAMEPLAN_OPTIONS.when ||
        (type === GAMEPLAN_OPTIONS.where && isOnline)) &&
      isShowSuggestionDetails &&
      fromSuggestionChat

    const linesRestrictForLine1 = {
      numberOfLines: isCollapseSuggestionDetails ? 2 : undefined,
    }
    let linesRestrictForLine2 = {
      numberOfLines: createSuggestion ? 4 : 2,
    }
    const linkOptions = {
      className: "message-link",
    }

    let line1 = null
    if (suggestionValues.line1) {
      const isOnlineIcon = images.socialIcons.find(
        (item) => item.name === suggestionValues?.line1
      )

      line1 = (
        <View style={GameplansRowStyles.suggestContainer}>
          {!!isOnlineIcon && (
            <Image
              style={[
                GameplansRowStyles.suggestIcon,
                { height: 16, width: 16 },
              ]}
              source={isOnlineIcon?.enabled}
            />
          )}
          <Text
            // style={[
            //     suggestionTextSyle,
            //     GameplansRowStyles.suggestionLine1,
            // ]}
            style={suggestionTextSyle}
            {...(measured ? linesRestrictForLine2 : {})}
            // {...(measured ? linesRestrictForLine1 : {})}
            selectable={fromSuggestionChat}
            ref={this.setTextRef}
          >
            <Linkify options={linkOptions}>{suggestionValues.line1}</Linkify>
          </Text>
        </View>
      )
    } else if (showHints) {
      line1 = (
        <Text
          style={GameplansRowStyles.suggestionPlaceholder}
          numberOfLines={1}
        >
          {suggestionValues.placeholder1}
        </Text>
      )
    } else if (!suggestionValues.line2) {
      line1 = (
        <Text
          style={GameplansRowStyles.suggestionPlaceholder}
          numberOfLines={1}
        >
          {suggestionValues.placeholder}
        </Text>
      )
    }

    if (isCollapseSuggestionDetails) {
      linesRestrictForLine2 = {
        numberOfLines: 2,
      }
    }

    let line2 = suggestionValues.line2 ? (
      <Text
        style={suggestionTextSyle}
        {...(measured ? linesRestrictForLine2 : {})}
        selectable={fromSuggestionChat}
        ref={this.setTextRef}
      >
        <Linkify options={linkOptions}>{suggestionValues.line2}</Linkify>
      </Text>
    ) : showHints ? (
      <Text style={GameplansRowStyles.suggestionPlaceholder} numberOfLines={1}>
        {suggestionValues.placeholder2}
      </Text>
    ) : null

    if (suggestion && type === GAMEPLAN_OPTIONS.when) {
      // Add timezone abbr to exact time
      let timezoneView
      if (
        !suggestion.isCustomTime &&
        !suggestion.isCustomDate &&
        !isFreestyleValues &&
        (!isSuggestionWithoutTime || isOnlyStartTime)
      ) {
        const timezoneName = currentUserTimezone || getCurrentTimezone()
        let timezoneAbbr
        if (timezoneName) timezoneAbbr = getTimezoneAbbreviation(timezoneName)

        timezoneView = timezoneAbbr && (
          <Text
            style={[suggestionTextSyle, GameplansRowStyles.timezoneText]}
            numberOfLines={1}
          >
            {timezoneAbbr}
          </Text>
        )
      }

      // Add globe or conflict icon
      let multizoneView
      if (hasConflict) {
        multizoneView = (
          <TouchableOpacity
            onPress={this.showDayCalendar}
            style={GameplansRowStyles.multizoneIcon}
            hitSlop={Units.hitSlop()}
          >
            <Image source={images.calendarAlert} />
          </TouchableOpacity>
        )
      } else if (
        (suggestion.isTimeZoneEnabled || isTimeZoneEnabled) &&
        !suggestion.isCustomTime &&
        !suggestion.isCustomDate &&
        !isFreestyleValues
      ) {
        multizoneView = (
          <View style={GameplansRowStyles.multizoneIcon}>
            <Image
              source={images.globe}
              style={[
                GameplansRowStyles.iconGray,
                GameplansRowStyles.iconSmall,
              ]}
            />
          </View>
        )
      }

      let inActiveIcon = isPast
        ? images.clockIcon
        : isWithdrawn
        ? images.delete
        : null

      let inActiveView = inActiveIcon && (
        <View style={GameplansRowStyles.inActiveIcon}>
          <Image
            source={inActiveIcon}
            style={[
              GameplansRowStyles.iconInactive,
              GameplansRowStyles.iconSmall,
            ]}
            resizeMode="contain"
          />
        </View>
      )

      line2 =
        line2 && startTimeFormatted && endTimeFormatted ? (
          <View style={GameplansRowStyles.suggestionOverlapWrapper}>
            <Text style={suggestionTextSyle}>{`${startTimeFormatted} - `}</Text>
            <View style={GameplansRowStyles.suggestionOverlapWrapper}>
              <Text style={suggestionTextSyle}>{endTimeFormatted}</Text>
              {timezoneView}

              {inActiveIcon ? inActiveView : multizoneView}
            </View>
          </View>
        ) : (
          line2
        )

      let baseView = line2 || line1
      baseView = (
        <View style={GameplansRowStyles.multizoneIconContainer}>
          {baseView}
          {startTimeFormatted && endTimeFormatted ? null : (
            <>
              {timezoneView}
              {multizoneView}
            </>
          )}
        </View>
      )

      if (line2) {
        line2 = baseView
      } else {
        line1 = baseView
      }
    }

    return (
      <>
        {!fromSuggestionPopup ? (
          <View style={{ paddingRight: "4px" }}>
            {line1}
            {line2}
          </View>
        ) : (
          <div style={{ width: "230px", flexWrap: "wrap" }}>
            {line1}
            {line2}
          </div>
        )}
      </>
    )
  }

  handleOpenDirection = () => {
    // const { suggestedPlace } = this.props
    // if (!suggestedPlace) {
    //     return
    // }
    // const { location, details } = suggestedPlace
    // if (!location) {
    //     return
    // }
    // const { lat, lng } = location
    // openMap(`@${lat},${lng}`, details)
  }

  handleSuggestionPress = () => {
    const { onPress, goToSuggestion, suggestion } = this.props
    return onPress
      ? onPress()
      : goToSuggestion
      ? goToSuggestion(suggestion)
      : onSuggestionChat(this.props)
  }

  render() {
    const {
      name,
      reactions,
      onPress,
      customStyle,
      customContentStyle,
      createSuggestion,
      suggestedItem,
      customReactionId,
      disableReaction,
      handleOnLongPress,
      suggestion,
      recommendation,
      previewMode,
      draggable,
      testID,
      isTeeupCancelled,
      suggestionIndex,
      isDragging,
      isDragged,
      isMyMessage,
      // isNew,
      // isRemoved,
      messageType,
      forChat,
      fullContainer,
      contentRef,
      fromSuggestionChat,
      renderLockIcon,
      showShadow,
      renderPlaceholder,
      suggestedPlace,
      newSuggestions,
      removedSuggestions,
      suggestionFromList,
      isFromSuggestionListHeader,
      people,
      activeTeeupId,
      fromSuggestionPopup,
      newDesign,
      isTemporalCustom,
    } = this.props

    let sections = []

    reactions.map(({ reactionId, reactedBy }) => {
      if (reactedBy) {
        let reaction = reactionId
        let data = reactedBy.map((userId) => {
          return { name: people[userId].name, avatar: people[userId].avatar }
        })
        if (!data.length) {
          reaction = ""
        }
        sections.push({ reaction, data })
      }
    })

    const isPast = this.isPast()
    const isWithdrawn = this.isWithdrawn()
    const { id, selected, decided } = suggestion
    const type = this.props.type ? this.props.type : suggestion.type
    const isDraggable = draggable && !forChat && !isPast && !isWithdrawn
    const showActionSheet =
      !isTeeupCancelled && !fromSuggestionChat && !previewMode
    const noSuggestion = _.isEmpty(suggestion)
    const disableLinkingToSuggestionChat =
      (noSuggestion || suggestedItem) && !onPress

    let isNew = undefined
    const isNewArr = Object.keys(newSuggestions).map((key, index) => {
      if (key.includes(suggestion.id) === true) {
        setTimeout(() => {
          this.setState({ displayNew: false })
        }, 15000)
        isNew = true
      } else {
        isNew = false
      }
    })

    let isRemoved = undefined
    const isRemovedArr = Object.keys(removedSuggestions).map(function (
      key,
      index
    ) {
      key.includes(suggestion.id) ? (isRemoved = true) : (isRemoved = false)
    })
    const bordered = selected || decided || isNew || isRemoved

    const inactive = isPast || isWithdrawn
    const isSuggestionPressDisabled =
      isTeeupCancelled || disableLinkingToSuggestionChat || isDragging
    const isShowReactionButtons =
      (!createSuggestion && !noSuggestion) || previewMode

    return (
      <>
        {!fromSuggestionPopup ? (
          <TouchableOpacity
            testID={testID}
            activeOpacity={1}
            disabled={isSuggestionPressDisabled}
            onPress={this.handleSuggestionPress}
            ref={isDraggable && contentRef}
            onMouseOver={() => {
              !isFromSuggestionListHeader &&
                this.setState({ ...this.state, threeDotsSuggestions: true })
            }}
            onMouseLeave={() => {
              // if(!shouldShowMessageAction) {
              //     return;
              // }
              ReactTooltip.hide(this.messageRef.current)
              this.setState({ ...this.state, threeDotsSuggestions: false })
            }}
          >
            {this.state.threeDotsSuggestions &&
              (suggestionFromList || isFromSuggestionListHeader) && (
                <img
                  src={threeDotsIcon}
                  ref={this.messageRef}
                  data-tip={id}
                  data-for="message-tooltip"
                  className="three-dots-list-suggestions"
                  onClick={() => ReactTooltip.show(this.messageRef.current)}
                  alt=""
                />
              )}
            <Animated.View
              style={[
                suggestionIndex === 0 || forChat
                  ? GameplansRowStyles.background
                  : GameplansRowStyles.backgroundWithPaddingTop,
                {
                  opacity: this._rowOpacity,
                  transform: [
                    {
                      scale: this._rowScale,
                    },
                  ],
                },
                fromSuggestionChat && { paddingTop: 0 },
              ]}
            >
              <View
                style={[
                  GameplansRowStyles.draggableContainer,
                  forChat && { marginHorizontal: 0 },
                  !forChat &&
                    !isDragging &&
                    showShadow &&
                    GameplansRowStyles.draggableContainerShadow,
                  forChat &&
                    (isMyMessage
                      ? GameplansRowStyles.forChatContainerMy
                      : GameplansRowStyles.forChatContainer),
                  forChat &&
                    newDesign && {
                      borderColor: isMyMessage ? "transparent" : "#e3e5e8",
                    },
                  fullContainer && GameplansRowStyles.fullContainer,
                  customStyle,
                  isRemoved && {
                    borderColor: "rgb(253,123,56)",
                    borderWidth: "1pt",
                  },
                  inactive && GameplansRowStyles.draggableContainerGrey,
                  isNew &&
                    this.state.displayNew && {
                      transition: "0.6s all",
                      borderColor: "rgb(244,40,98)",
                      borderWidth: "1pt",
                    },
                  selected && {
                    borderColor: newDesign
                      ? isMyMessage
                        ? "transparent"
                        : "#e3e5e8"
                      : "rgb(0,188,246)",
                    borderWidth: "1pt",
                    ...(newDesign && {
                      backgroundColor: isMyMessage ? "#e0e8ee" : "white",
                    }),
                  },
                  newDesign &&
                    !selected && {
                      backgroundColor: isMyMessage ? "#e0e8ee" : "white",
                    },
                  isTemporalCustom && { maxWidth: "345px" },
                ]}
              >
                <View
                  style={[
                    forChat &&
                      messageType &&
                      (isMyMessage
                        ? messageTypesStyles[`${messageType}My`]
                        : messageTypesStyles[messageType]),
                    draggableContainerInner({
                      bordered,
                      decided,
                      isDraggable,
                      isTeeupCancelled,
                      isNew,
                      isRemoved,
                      selected,
                      forChat,
                      fullContainer,
                    }),
                    customContentStyle,
                  ]}
                >
                  {(isDraggable || isWithdrawn) && (
                    <View
                      style={GameplansRowStyles.rowReorder}
                      {...(isDraggable && this._panResponder.panHandlers)}
                    >
                      <Image
                        source={images.reorderDots}
                        style={rowReorderImage(bordered)}
                      />
                    </View>
                  )}
                  {!isDraggable && !forChat && !isWithdrawn && (
                    <View style={GameplansRowStyles.rowReorder} />
                  )}

                  <View
                    style={[
                      GameplansRowStyles.draggableContentContainer,
                      // suggestionFromList ? {minWidth: 250} : {minWidth: 300}
                    ]}
                  >
                    <View {...(isDraggable && this._panResponder.panHandlers)}>
                      {isFromSuggestionListHeader && (
                        <View
                          style={[
                            GameplansRowStyles.gamePlanWrapper,
                            {
                              borderBottom: "1px solid #f3f3f3",
                              marginBottom: 5,
                              width: "100%",
                            },
                          ]}
                        >
                          {/* {type === "where" ? <LocationSVG className="suggestionSVG" /> : <ClockSVG className="suggestionSVG" />} */}
                          <Image
                            style={{ width: 16, height: 16 }}
                            source={
                              type === "where"
                                ? images.iconPinGamePlan
                                : images.iconClockGamePlan
                            }
                          />
                          <Text style={GameplansRowStyles.gamePlanText}>
                            Game Plan
                          </Text>
                          <div
                            style={{
                              marginLeft: "auto",
                              position: "relative",
                              right: "0",
                              width: "20px",
                            }}
                          >
                            {ReactDOM.createPortal(
                              <GamePlanTooltip activeTeeupId={activeTeeupId} />,
                              document.getElementById("modal-root")
                            )}
                            <img
                              src={images.contactMenuIcon}
                              style={{ marginLeft: "80%" }}
                              alt=""
                            />
                            <img
                              src={threeDotsIcon}
                              data-tip={id}
                              data-for="decided-tooltip"
                              className="reactions-view"
                              ref={this.decidedRef}
                              onClick={() =>
                                ReactTooltip.show(this.decidedRef.current)
                              }
                              alt=""
                            />
                          </div>
                        </View>
                      )}
                    </View>
                    <View
                      style={[
                        GameplansRowStyles.topContainer,
                        { paddingBottom: "4px" },
                      ]}
                    >
                      {
                        !newDesign &&
                          !isFromSuggestionListHeader &&
                          !suggestionFromList &&
                          (type === "where" ? (
                            <LocationSVG
                              className={
                                isMyMessage && !inactive
                                  ? "suggestionSVG--mine"
                                  : "suggestionSVG"
                              }
                            />
                          ) : (
                            <ClockSVG
                              className={
                                isMyMessage && !inactive
                                  ? "suggestionSVG--mine"
                                  : "suggestionSVG"
                              }
                            />
                          ))
                        // <img
                        //     style={{width: 11, height: 11}}
                        //     src={type === "where" ? images.location : images.clock}
                        // />
                      }
                      {!isFromSuggestionListHeader && !suggestionFromList && (
                        <Text
                          style={[
                            isMyMessage && !inactive
                              ? GameplansRowStyles.myUsername
                              : GameplansRowStyles.username,
                            !isFromSuggestionListHeader &&
                              !suggestionFromList && { marginLeft: "5px" },
                            newDesign && {
                              color: "#a9b0b9",
                              textTransform: "capitalize",
                              fontSize: "10px",
                              fontWeight: "800",
                              letterSpacing: "-0.2px",
                              marginLeft: 0,
                              marginRight: "5px",
                            },
                          ]}
                        >
                          {newDesign
                            ? `${type.toUpperCase()} - @${name.toUpperCase()}`
                            : `@${capitalizeFirstLetter(name)}`}
                        </Text>
                      )}
                      {!isFromSuggestionListHeader &&
                        !suggestionFromList &&
                        this.renderStatusIndicator()}
                    </View>
                    <View style={GameplansRowStyles.contentContainerAlignTop}>
                      {(suggestionFromList || isFromSuggestionListHeader) && (
                        <Image
                          source={images.dots}
                          style={{ width: 6, heigth: "100%", marginRight: 10 }}
                        />
                      )}
                      {isFromSuggestionListHeader ||
                        (suggestionFromList &&
                          this.renderStatusIndicatorForList())}
                      <View
                        style={GameplansRowStyles.titleContainer}
                        testID={`${testID}-name`}
                        {...(isDraggable && this._panResponder.panHandlers)}
                      >
                        {(isFromSuggestionListHeader || suggestionFromList) && (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "1px",
                            }}
                          >
                            <Text
                              style={[
                                isMyMessage && !inactive
                                  ? GameplansRowStyles.myUsername
                                  : GameplansRowStyles.username,
                                isFromSuggestionListHeader &&
                                  suggestionFromList && { marginLeft: "5px" },
                              ]}
                            >
                              @{capitalizeFirstLetter(name)}
                            </Text>
                          </View>
                        )}
                        <TouchableOpacity
                          activeOpacity={1}
                          disabled={isSuggestionPressDisabled}
                          onPress={this.props.toggleSuggestionPopup}
                          // onPress={this.handleSuggestionPress}
                        >
                          {this.renderSuggestionLines()}
                        </TouchableOpacity>
                      </View>
                      {suggestedPlace && suggestedPlace.location && (
                        <TouchableOpacity
                          onPress={this.handleOpenDirection}
                          style={GameplansRowStyles.mapButton}
                        >
                          <Image source={images.directions} />
                        </TouchableOpacity>
                      )}
                      {isShowReactionButtons && (
                        <ReactionButton
                          suggestionId={id}
                          isMyMessage={isMyMessage}
                          reactions={reactions}
                          previewMode={previewMode}
                          customReactionId={customReactionId}
                          disableReaction={disableReaction}
                          inactive={inactive}
                          sections={sections}
                          newDesign={newDesign}
                        />
                      )}
                      {/* {showActionSheet && (
                                        <TouchableOpacity
                                            // onPress={() =>
                                            //     handleOnLongPress(this.props)
                                            // }
                                            style={
                                                GameplansRowStyles.actionSheetButton
                                            }
                                            disabled={isDragging}
                                        >
                                            <Image
                                                source={images.suggestionMore}
                                            />
                                        </TouchableOpacity>
                                    )} */}
                    </View>
                  </View>
                  {isDraggable && (
                    <Animated.View
                      pointerEvents="none"
                      style={[
                        GameplansRowStyles.rippleOverlay,
                        {
                          opacity: this._rippleOpacity,
                          transform: [
                            {
                              scaleX: this._rippleScale,
                            },
                          ],
                        },
                      ]}
                    />
                  )}
                </View>
                {isDragging && isDraggable && (
                  <Animated.View
                    style={[
                      GameplansRowStyles.draggingOverlay({
                        selected,
                      }),
                      {
                        opacity: this._draggingOverlayOpacity,
                      },
                    ]}
                    pointerEvents="none"
                  >
                    {renderPlaceholder && renderPlaceholder()}
                  </Animated.View>
                )}
                {isDragged && (
                  <View style={GameplansRowStyles.draggedIndicator}>
                    <View
                      style={[
                        GameplansRowStyles.draggedIndicatorInner,
                        // forChat &&
                        //     messageType &&
                        //     GameplansRowStyles[messageType](
                        //         isMyMessage
                        //     ),
                        fullContainer && GameplansRowStyles.fullContainer,
                      ]}
                    />
                  </View>
                )}
              </View>
              {/* {recommendation && (
                        <View
                            style={
                                GameplansRowStyles.suggestionRecommendationsContainer
                            }
                        >
                            <Text
                                style={
                                    GameplansRowStyles.suggestionRecommendationsHeader
                                }
                            >
                                DEAL FOR YOUR TEEUP:
                            </Text>
                            <RecommendedItem item={recommendation} />
                        </View>
                    )} */}
            </Animated.View>
          </TouchableOpacity>
        ) : (
          <>
            <TouchableOpacity
              activeOpacity={1}
              disabled={isSuggestionPressDisabled}
              onPress={this.props.toggleSuggestionPopup}
              // onPress={this.handleSuggestionPress}
            >
              {this.renderSuggestionLines()}
            </TouchableOpacity>

            <ReactionButton
              suggestionId={id}
              isMyMessage={isMyMessage}
              reactions={reactions}
              previewMode={previewMode}
              customReactionId={customReactionId}
              disableReaction={disableReaction}
              inactive={inactive}
              sections={sections}
            />
          </>
        )}
      </>
    )
  }
}

DraggableSuggestionRowContent.defaultProps = {
  suggestion: {},
  initiateStatusUpdate: false,
  previewMode: false,
  draggable: true,
  forChat: false,
  showShadow: true,
  fromSuggestionChat: false,
}

DraggableSuggestionRowContent.propTypes = {
  name: PropTypes.string,
  reactions: PropTypes.array,
  showHints: PropTypes.bool,
  onPress: PropTypes.func,
  renderPlaceholder: PropTypes.func,
  // customStyle: PropTypes.object,
  customStyle: PropTypes.number,
  // customContentStyle: PropTypes.object,
  createSuggestion: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  suggestedItem: PropTypes.bool,
  disableReaction: PropTypes.bool,
  handleOnLongPress: PropTypes.func,
  suggestion: PropTypes.object,
  people: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  participants: PropTypes.array,
  type: PropTypes.string,
  customReactionId: PropTypes.number,
  isNew: PropTypes.bool,
  isRemoved: PropTypes.bool,
  isPast: PropTypes.bool,
  isWithdrawn: PropTypes.bool,
  isTimeZoneEnabled: PropTypes.bool,
  recommendation: PropTypes.object,
  decidedGameplanId: PropTypes.number,
  testID: PropTypes.string,
  isTeeupCancelled: PropTypes.bool,
  activeTeeupId: PropTypes.number,
  previewMode: PropTypes.bool,
  draggable: PropTypes.bool,
  isRowExpanded: PropTypes.bool,
  initiateStatusUpdate: PropTypes.bool,
  suggestionIndex: PropTypes.number,
  isDragging: PropTypes.bool,
  isDragged: PropTypes.bool,
  forChat: PropTypes.bool,
  fromSuggestionChat: PropTypes.bool,
  shouldMeasureOverflow: PropTypes.bool,
  isMyMessage: PropTypes.bool,
  messageType: PropTypes.string,
  fullContainer: PropTypes.bool,
  contentRef: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchMove: PropTypes.func,
  onTouchEnd: PropTypes.func,
  hasConflict: PropTypes.bool,
  locationType: PropTypes.number,
  renderLockIcon: PropTypes.func,
  goToSuggestion: PropTypes.func,
  showShadow: PropTypes.bool,
  teeupName: PropTypes.string,
  currentUserTimezone: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object,
  ]),
  suggestionsWithDetails: PropTypes.array,
  suggestedPlace: PropTypes.object,
  isBeforeCalendarDate: PropTypes.bool,
}

class DraggableSuggestionRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSuggestionPopupOpened: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { toggleSuggestionDetails } = this.props
    if (
      prevState.isSuggestionPopupOpened !== this.state.isSuggestionPopupOpened
    ) {
      if (this.state.isSuggestionPopupOpened) {
        toggleSuggestionDetails(false)
      }
    }
  }
  getOwnProps() {
    const ownProps = { ...this.props }
    delete ownProps.reactions
    delete ownProps.people
    delete ownProps.participants
    delete ownProps.decidedGameplanId
    delete ownProps.activeTeeupId
    delete ownProps.rowRef

    return ownProps
  }

  toggleSuggestionPopup = () => {
    this.setState({
      isSuggestionPopupOpened: !this.state.isSuggestionPopupOpened,
    })
  }

  render() {
    const { rowRef, onAddSuggestion, isExclusive, ...otherProps } = this.props
    const { isSuggestionPopupOpened } = this.state

    return (
      <>
        <div onClick={() => this.toggleSuggestionPopup()}>
          <DraggableSuggestionRowContent
            ref={(ref) => {
              if (rowRef) {
                rowRef(ref)
              }
              this._row = ref
            }}
            contentRef={(ref) => (this._rowContent = ref)}
            {...otherProps}
            toggleSuggestionPopup={this.toggleSuggestionPopup}
          />
        </div>
        {isSuggestionPopupOpened &&
          ReactDOM.createPortal(
            <SuggestionPopup
              {...otherProps}
              draggableSuggestionRow={
                <DraggableSuggestionRowContent
                  ref={(ref) => {
                    if (rowRef) {
                      rowRef(ref)
                    }
                    this._row = ref
                  }}
                  contentRef={(ref) => (this._rowContent = ref)}
                  {...otherProps}
                  fromSuggestionPopup
                />
              }
              currentSuggestion={otherProps.suggestion}
              currentSuggestionData={otherProps.suggestion}
              toggleSuggestionPopup={this.toggleSuggestionPopup}
              onAddSuggestion={onAddSuggestion}
            />,
            document.getElementById("modal-root")
          )}
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const activeTeeup = selectActiveTeeup(state)
  const activeTeeupId = activeTeeup.id
  const teeupName = activeTeeup.name
  const type = props?.type || props?.suggestion?.type
  // const decidedGameplan = selectDecidedGameplanByType(type)(state)
  const decidedGameplan = {}
  const decidedGameplanId = decidedGameplan.id || -1
  // const currentUserTimezone = selectUserTimezone(state)
  const currentUserTimezone = getCurrentTimezone()
  // const suggestionsWithDetails = selectSuggestionsWithDetails(state)
  const suggestionsWithDetails = []
  const newSuggestions = selectNewSuggestions(state)
  const removedSuggestions = selectRemovedSuggestions(state)
  const { suggestion } = props
  let hasConflict = null

  const suggestionId = suggestion ? suggestion.id : null
  const reactions = selectTeeupReactionsBySuggestionId(suggestionId)(state)
  const people = selectTeeupPeople(state)
  const isBeforeCalendarDate = selectBeforeCalendarDate(state)

  return {
    reactions,
    decidedGameplanId,
    activeTeeupId,
    teeupName,
    hasConflict,
    currentUserTimezone,
    suggestionsWithDetails,
    newSuggestions,
    removedSuggestions,
    people,
    isBeforeCalendarDate,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSuggestionDetails: bindActionCreators(
      toggleSuggestionDetails,
      dispatch
    ),
  }
}

DraggableSuggestionRow.defaultProps = {
  isActionSheetActive: false,
}

DraggableSuggestionRow.propTypes = {
  rowRef: PropTypes.func,
  currentUserTimezone: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object,
  ]),
  suggestionsWithDetails: PropTypes.array,
  isActionSheetActive: PropTypes.bool,
  removedSuggestions: PropTypes.array,
  isExclusive: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraggableSuggestionRow)
