import React, { useMemo, memo, useCallback } from "react"
import classnames from "classnames"

import { WEEK_DAYS } from "./constants"

const CalendarDays = ({ day, handleDateClick, selectedDates }) => {
  const firstDayOfMonth = new Date(day.getFullYear(), day.getMonth(), 1)
  const weekdayOfFirstDay = firstDayOfMonth.getDay()

  const today = useMemo(() => {
    const t = new Date()
    t.setHours(0, 0, 0, 0)
    return t
  }, [])

  const currentDays = useMemo(() => {
    const days = Array(weekdayOfFirstDay).fill("")

    const firstDay = 1
    const month = day.getMonth()
    const year = day.getFullYear()

    const date = new Date(year, month, firstDay)

    while (date.getMonth() === month) {
      const d = date.getDate()
      const m = date.getMonth()
      const y = date.getFullYear()
      const anotherDate = new Date(y, m, d)
      days.push(anotherDate)
      date.setDate(date.getDate() + 1)
    }

    return days
  }, [day, weekdayOfFirstDay])

  const isDayInPast = useCallback(
    (day) =>
      day && typeof today === "object" && today?.getTime() > day?.getTime(),
    [today]
  )

  const isSelectedDay = useCallback(
    (day) => {
      if (!day) {
        return false
      }

      return !!selectedDates.find((d) => d.getTime() === day.getTime())
    },
    [selectedDates]
  )

  const handleClick = (d) => () => {
    // prevent click in two scenarios:
    // 1. day does not exist
    // 2. day is in the past
    if (!d || isDayInPast(d)) {
      return
    }

    handleDateClick(d)
  }

  return (
    <div className="calendar-days__dates">
      {WEEK_DAYS.map((day) => (
        <div className="calendar-days__row-item calendar-days__header-item">
          {day}
        </div>
      ))}
      {currentDays.map((day) => {
        return (
          <div
            key={day}
            onClick={handleClick(day)}
            className={classnames("calendar-days__date", {
              empty: !day,
              past: isDayInPast(day),
              selected: isSelectedDay(day),
            })}
          >
            {day ? day.getDate() : ""}
          </div>
        )
      })}
    </div>
  )
}

export default memo(CalendarDays)
