import React, { useState } from "react"
import { useSelector } from "react-redux"

import { selectUserInfo } from "@selectors/user"
import { selectTeeupPeopleByTeeupId } from "@selectors/teeups"

import "./index.scss"
import DialogButton from "@components/ui/DialogButton"
import UserStatusModal from "@components/teeupUserResponseDialogs/userStatusModal"
import { useTeeUpPage } from "../../TeeUpPageContext"

const TeeupSelectStatus = ({
  title,
  imageUrl,
  status,
  buttonList,
  handleStatusClick,
  triggerButton,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { teeup } = useTeeUpPage()

  const user = useSelector(selectUserInfo)
  const people = useSelector(selectTeeupPeopleByTeeupId(teeup.id))

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const handleClick = (id) => () => {
    setIsOpen(false)
    handleStatusClick(id)
  }

  return (
    <>
      {/* TODO leave it here for now, one day when the time let's us we will refactor the old one and use this potentially */}
      {/* {isOpen && (
        <>
          <div className="select-status-backdrop" />
          <div className="select-status-modal">
            <div onClick={toggleModal} className="close-button">
              <ReactSVG src={images.ndCloseIcon} className={"icon"} />
            </div>
            <div className="top-part">
              <div
                className="select-status-modal__image"
                style={{
                  backgroundImage: `url(${imageUrl ? imageUrl : ""})`,
                }}
              />
              <span className="select-status-modal__title">{title}</span>
            </div>
            <div className="bottom-part">
              {Object.values(buttonList).map(
                ({ label, bgColor, color, id, icon, secondaryIcon }) => {
                  return (
                    <DialogButton
                      key={id}
                      label={label}
                      selectedBgColor={bgColor}
                      selectedColor={color}
                      selected={id === status}
                      onClick={handleClick(id)}
                      icon={icon}
                      secondaryIcon={secondaryIcon}
                    />
                  )
                }
              )}
            </div>
          </div>
        </>
      )} */}

      {isOpen && (
        <UserStatusModal
          handleSetPeopleStatus={handleStatusClick}
          teeup={teeup}
          onClose={() => setIsOpen(false)}
        />
      )}
      {triggerButton && (
        <div className="select-status-button">
          <DialogButton
            label={triggerButton.label}
            selectedBgColor={triggerButton.bgColor}
            selectedColor={triggerButton.color}
            selected
            onClick={toggleModal}
            icon={triggerButton.icon}
            type={"dropdown"}
            outline={triggerButton.outline}
            additionalCount={people[user.id]?.additionalCount || 0}
          />
        </div>
      )}
    </>
  )
}

export default TeeupSelectStatus
