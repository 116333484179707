import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { IconContext } from "react-icons"
import { FaRegCopy } from "react-icons/fa"
import { useParams } from "react-router-dom"
import copy from "copy-to-clipboard"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import "./index.scss"
import api from "../../api"
import logo from "../../assets/images/logo.svg"
import { setShowSideBar } from "@actions/commonActions"

const VerifyPage = ({ setShowSideBar }) => {
  const { token } = useParams()
  const [tempPassword, setTempPassword] = useState("")

  const [email, setEmail] = useState("")
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  useEffect(() => {
    setShowSideBar(false)
    api.init()

    if (token) {
      api.client
        .post("/tmp-passwords", {
          token,
        })
        .then((response) => {
          const { password, email } = response.data
          setEmail(email)
          setTempPassword(password)
        })
        .catch((err) => console.log("fetch error", err))
    }

    return function () {
      setShowSideBar(true)
    }
  }, [])

  const copyToClipboard = () => {
    const copied = copy(tempPassword)

    if (copied) {
      setCopiedToClipboard(true)
      setTimeout(() => setCopiedToClipboard(false), 5000)
    }
  }

  return (
    <>
      {copiedToClipboard && (
        <div className="copied_message">
          <p>Copied to Clipboard!</p>
        </div>
      )}
      <div className="verify_page">
        <div className="logo_wrapper">
          <img className="logo" src={logo} alt="coo-e" />
        </div>
        <span className="password_reset">Password Reset</span>
        <span className="message">
          Your temporary password for <br />
          <span className="email">{email}</span>
          &nbsp;is:
        </span>
        <div className="btn_container">
          <input className="temp_password" value={tempPassword} disabled />
          <button className="copy_btn" onClick={copyToClipboard}>
            <IconContext.Provider value={{ className: "copy_icon" }}>
              <FaRegCopy />
            </IconContext.Provider>
            <span className="copy_text">Copy to Clipboard</span>
          </button>
          <span className="message message2">
            Please copy the password and sign in, after which you will be asked
            to set a new password
          </span>
          <button className="signin_btn">
            <Link className="singin_text" to="/sign-in">
              Sign In
            </Link>
          </button>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setShowSideBar: bindActionCreators(setShowSideBar, dispatch),
})

export default connect(null, mapDispatchToProps)(VerifyPage)
