import React from "react"
import PropTypes from "prop-types"
import { View, TouchableOpacity, Image } from "react-native"
import { Units } from "@theme/"
import { images } from "@utils/imageUtils"

import { CloseButtonStyles as styles } from "./styles"

const CloseButton = ({ onClose }) => {
  return (
    <View style={styles.closeButtonPosition}>
      <TouchableOpacity
        hitSlop={Units.hitSlop()}
        style={styles.closeButton}
        onPress={onClose}
      >
        <Image
          style={styles.closeIcon}
          source={images.ndCloseIcon}
          resizeMode="contain"
        />
      </TouchableOpacity>
    </View>
  )
}

CloseButton.propTypes = {
  onClose: PropTypes.func,
}

export default CloseButton
