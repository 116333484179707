import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { View, Animated, Text } from "react-native"
import { SectionHeaderStyles, SectionListStyles } from "./suggestionListStyles"
// import ReactNativeHapticFeedback from 'react-native-haptic-feedback'
import I18n from "@i18n"

class ActiveSuggestionDropZone extends React.Component {
  constructor(props) {
    super(props)
    this._promptAnimValue = new Animated.Value(0)
    this._promptAnimActiveValue = new Animated.Value(0)
    this._promptAnimation = null
    this._dropActive = false
  }

  componentDidUpdate(prevProps) {
    const { isActiveSuggestionDragging } = this.props

    if (isActiveSuggestionDragging !== prevProps.isActiveSuggestionDragging) {
      if (this._promptAnimation) {
        this._promptAnimation.stop()
        this._promptAnimValue.setValue(0)
      }
      if (isActiveSuggestionDragging) {
        this._promptAnimActiveValue.setValue(0)
        this._dropActive = false
        this._promptAnimation = Animated.timing(this._promptAnimValue, {
          toValue: 1,
          duration: 600,
          delay: 0,
          useNativeDriver: true,
        })
        this._promptAnimation.start()
      }
    }
  }

  measureInWindow(func) {
    this._contentRef.measureInWindow(func)
  }

  animateDropActive(active) {
    if (this._dropActive === active) {
      return
    }

    this._dropActive = active
    Animated.timing(this._promptAnimActiveValue, {
      toValue: active ? 1 : 0,
      duration: 0,
      useNativeDriver: true,
    }).start()

    // ReactNativeHapticFeedback.trigger('impactLight', {
    //     enableVibrateFallback: true,
    //     ignoreAndroidSystemSettings: true,
    // })
  }

  animateChangeGameplan() {
    Animated.parallel([
      Animated.timing(this._promptAnimValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }),
    ]).start()
  }

  render() {
    const { isActiveSuggestionDragging, dropTargetPermissionText } = this.props
    const dropPrompt = dropTargetPermissionText
      ? dropTargetPermissionText
      : "dragToRemove"
    // : I18n.t('gameplans.dragToRemove')
    return (
      <React.Fragment>
        <View
          ref={(ref) => {
            this._contentRef = ref
          }}
          style={SectionListStyles.activeSuggestionDropZoneStubContainer}
        />
        {isActiveSuggestionDragging && (
          <Animated.View
            style={[
              SectionListStyles.activeSuggestionDropZoneDraggingOverlay(
                dropTargetPermissionText
              ),
              {
                opacity: this._promptAnimValue,
              },
            ]}
          >
            <Animated.View
              style={[
                SectionHeaderStyles.draggingOverlay,
                {
                  opacity: this._promptAnimActiveValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0],
                  }),
                },
              ]}
            >
              <Text style={SectionHeaderStyles.draggingOverlayText}>
                {dropPrompt}
              </Text>
            </Animated.View>
            <Animated.View
              style={[
                SectionHeaderStyles.draggingOverlayActive,
                {
                  opacity: this._promptAnimActiveValue,
                },
              ]}
            >
              <Text style={SectionHeaderStyles.draggingOverlayActiveText}>
                {dropPrompt}
              </Text>
            </Animated.View>
          </Animated.View>
        )}
      </React.Fragment>
    )
  }
}

export default connect(null, null, null, { forwardRef: true })(
  ActiveSuggestionDropZone
)

ActiveSuggestionDropZone.propTypes = {
  isActiveSuggestionDragging: PropTypes.bool,
  dropTargetPermissionText: PropTypes.string,
}
