import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"

export const styles = StyleSheet.create({
  container: {
    width: "100%",
    // paddingHorizontal: Units.responsiveValue(16),
    marginBottom: Units.responsiveValue(16),
  },
  wrapper: {
    borderRadius: 10,
    backgroundColor: AppColors.brand.white,
    flex: 1,
    elevation: 1,
    shadowOffset: { height: 1 },
    shadowColor: "black",
    shadowOpacity: 0.16,
    shadowRadius: 3,
  },
  headerWrapper: {
    height: Units.responsiveValue(42),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: Units.responsiveValue(12),
    justifyContent: "space-between",
  },
  leftHeaderWrapper: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  rightHeaderWrapper: {
    minWidth: Units.responsiveValue(120),
    paddingHorizontal: Units.responsiveValue(4),
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  rightHeaderActionWrapper: {
    width: Units.responsiveValue(32),
    height: Units.responsiveValue(32),
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: Units.responsiveValue(4),
    flexDirection: "row",
  },
  arrowIcon: {
    marginHorizontal: Units.responsiveValue(4),
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
  },
  titleText: {
    ...AppFonts.mediumExtraBold,
    letterSpacing: -Units.responsiveValue(0.3),
    color: AppColors.brand.darkGrey2,
  },
  secondaryTitleText: {
    ...AppFonts.smallMedium,
    lineHeight: Units.responsiveValue(12),
    letterSpacing: -Units.responsiveValue(0.12),
    color: AppColors.brand.grey157,
    paddingLeft: Units.responsiveValue(8),
    paddingTop: Units.responsiveValue(3),
  },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: AppColors.brand.grey243,
  },
  rightHeaderActionText: {
    ...AppFonts.smallish,
    color: AppColors.brand.pink,
    lineHeight: Units.responsiveValue(18),
    paddingLeft: Units.responsiveValue(4),
  },
})
