import React from "react"
import { connect } from "react-redux"

import { setSideBarContent, setPeopleSideBar } from "@actions/commonActions"
import SuggestionListContainer from "../../../gamePlansCard/suggestionsList/suggestionListContainer"
import { images } from "@utils/imageUtils"
import GameplanContainer from "../../../CreateTeeUp/gameplanContainer"
import {
  selectPermissions,
  selectSuggestionMessages,
  selectTeeupGameplans,
  selectTeeupPeople,
  selectTeeupRecommendationsIds,
  selectTeeupRecommendations,
  selectDecidedGameplans,
  selectTeeupsParticipants,
} from "@selectors/teeups"
import { hideSuggestionListFooter } from "@actions/commonActions"

const SuggestModal = ({
  isOpen,
  closeSuggestions,
  FromModal,
  setPeopleSideBar,
  setSideBarContent,
  setIsOpen,
  gameplans,
  permissions,
  handleOpenSuggestion,
  hideSuggestionListFooter,
}) => {
  const handleOnSectionPress = (e, index) => {
    const { allowSelectGameplan } = permissions

    e.stopPropagation()
    setPeopleSideBar(true)
    setIsOpen(false)
    setSideBarContent([
      <GameplanContainer
        {...gameplans[index]}
        // {...section}
        suggestions={[]}
        closeCreate={() => closeSuggestions()}
        allowPutOnGameplan={
          allowSelectGameplan && !gameplans[index]?.suggestions?.length
        }
      />,
      <SuggestionListContainer
        closeSuggestions={closeSuggestions}
        FromModal={FromModal}
        gameplanIndex={index}
        blockFunction
        handleOpenSuggestion={handleOpenSuggestion}
      />,
    ])
    // setSideBarContent(<SuggestionListContainer closeSuggestions={closeSuggestions} FromModal={FromModal} gameplanIndex={index} />)
  }

  return (
    <span
      className={`suggesttoolbar__wrapper_boxshadow ${
        isOpen === false && "suggesttoolbar__none"
      }`}
    >
      <div className="suggesttoolbar__tooltip_wrapper">
        <div
          className="suggesttoolbar__tooltip_item"
          onClick={(e) => {
            handleOnSectionPress(e, 0)
            handleOpenSuggestion()
            hideSuggestionListFooter()
          }}
        >
          <img src={images.suggestClock} alt="" />
          <span>Suggest When</span>
        </div>
        <div
          className="suggesttoolbar__tooltip_item"
          onClick={(e) => {
            handleOnSectionPress(e, 1)
            handleOpenSuggestion()
            hideSuggestionListFooter()
          }}
        >
          <img src={images.suggestMarker} alt="" />
          <span>Suggest Where</span>
        </div>
      </div>
      <div className="suggesttoolbar__tooltip_triangle"></div>
    </span>
  )
}

const mapStateToProps = (state) => {
  const gameplans = selectTeeupGameplans(state)
  const permissions = selectPermissions(state)
  return {
    gameplans,
    permissions,
  }
}

const mapMethodsToProps = {
  setPeopleSideBar,
  setSideBarContent,
  hideSuggestionListFooter,
}

export default connect(mapStateToProps, mapMethodsToProps)(SuggestModal)
