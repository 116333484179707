import { StyleSheet } from "react-native"
import { Units } from "@theme"

export default StyleSheet.create({
  messageAvatar: {
    width: Units.responsiveValue(40),
    marginRight: Units.responsiveValue(6),
  },
  avatarAccesory: {
    top: Units.responsiveValue(18),
  },
})
