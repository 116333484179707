import React from "react"
import "./index.scss"

const DropDown = ({ isOpened, buttons }) => {
  if (!isOpened) return false

  return (
    <div className="custom__dropdown">
      {buttons.map((button) => (
        <span
          key={button.title}
          onClick={button.callback}
          className="custom__dropdown-item"
        >
          {button.title}
        </span>
      ))}
    </div>
  )
}

export default DropDown
