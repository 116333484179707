import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"

export default StyleSheet.create({
  suggestionContainer: {
    flexDirection: "row",
    overflow: "hidden",
    borderRadius: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.white,
    paddingHorizontal: Units.responsiveValue(12),
    paddingVertical: Units.responsiveValue(11),
    marginBottom: Units.responsiveValue(7),
  },
  suggestionBar: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    width: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.pink,
  },
  suggestionIcon: {
    marginTop: Units.responsiveValue(3),
    marginRight: Units.responsiveValue(3),
    color: AppColors.brand.darkGrey2,
  },
  suggestionLine: {
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.darkGrey2,
  },
})

const commentedSuggestion = (isMyMessage) => ({
  alignItems: isMyMessage ? "flex-end" : "flex-start",
})

const messageSenderText = (isMyMessage, newDesign) => ({
  ...AppFonts.smallishMedium,
  marginBottom: Units.responsiveValue(4),
  color: newDesign
    ? "#a9b0b9"
    : isMyMessage
    ? AppColors.brand.lightBlue7
    : AppColors.brand.grey157,
})

export { commentedSuggestion, messageSenderText }
