import endpoints from "@config/endpoints"
import api from "api"
import { store } from "index"
import actionTypes from "./actionTypes"

const getContacts = () => ({
  type: actionTypes.GET_CONTACTS,
})

const fetchBookContacts = () => {
  api.client
    .get(endpoints.fetchContacts())
    .then((response) => {
      store.dispatch(gotBookContacts(response.data.data))
    })
    .catch((error) => {
      console.log("fetchUserTeeupsUsers error ")
      console.log(error)
    })
}

const gotBookContacts = (contacts) => ({
  type: actionTypes.GOT_BOOK_CONTACTS,
  payload: { contacts },
})

const addTagsToUsers = (userId, body, onTagUsersEnd) => ({
  type: actionTypes.ADD_TAGS_TO_USERS,
  payload: { userId, body, onTagUsersEnd },
})

const removeTagsFromUsers = (userId, body, onTagUsersEnd) => ({
  type: actionTypes.REMOVE_TAGS_FROM_USERS,
  payload: { userId, body, onTagUsersEnd },
})

const initiateSendEmailsAndSMS = (invitees, name, teeupUrl, type) => ({
  type: actionTypes.INITIATE_SEND_INVITES,
  payload: { invitees, name, teeupUrl, type },
})

const initiateEmitSocketInvitees = (invited) => ({
  type: actionTypes.INITIATE_EMIT_SOCKET_INVITES,
  payload: { invited },
})

const changeContactDefaultType = (updatedContact) => ({
  type: actionTypes.CONTACT_CHANGE_DEFAULT_TYPE,
  payload: { updatedContact },
})

const resyncContactsBook = () => ({
  type: actionTypes.RESYNC_CONTACTS_BOOK,
})

export {
  getContacts,
  addTagsToUsers,
  removeTagsFromUsers,
  initiateSendEmailsAndSMS,
  initiateEmitSocketInvitees,
  resyncContactsBook,
  changeContactDefaultType,
  fetchBookContacts,
}
