import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"

export const overlayStyles = StyleSheet.create({
  titleContainer: {
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    height: Units.responsiveValue(125),
    backgroundColor: AppColors.brand.white,
    paddingVertical: Units.responsiveValue(16),
    paddingHorizontal: Units.responsiveValue(16),
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  subtitle: {
    marginTop: Units.responsiveValue(16),
    ...AppFonts.middleExtraBold,
    color: AppColors.brand.grey112,
    textTransform: "uppercase",
  },
  bringingContainer: {
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: Units.responsiveValue(24),
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    backgroundColor: "#f0f2f5",
  },
  amountButtonContainer: {
    marginBottom: Units.responsiveValue(16),
  },
  doneButtonBackground: {
    marginTop: Units.responsiveValue(48),
    backgroundColor: AppColors.brand.pink,
  },
  extraText: {
    ...AppFonts.middleExtraBold,
    color: AppColors.brand.grey157,
  },
})
