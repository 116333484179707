import React from "react"
import PropTypes from "prop-types"
import { View } from "react-native"

import { EVENT_TYPES } from "@utils/calendarUtils"
import CalendarEventBlock from "./calendarEventBlock"
import TeeupEventBlock from "./teeupBlock"
import { EventsStyles as styles } from "./styles"

const EventBlock = ({ event, positionStyles, hasConflict }) => {
  const props = {
    title: event.name,
    hasConflist: event.hasConflict || hasConflict,
  }

  return (
    <View style={[styles.eventBlockContainer, positionStyles]}>
      {event.type === EVENT_TYPES.calendar ? (
        <CalendarEventBlock {...props} />
      ) : (
        <TeeupEventBlock {...props} />
      )}
    </View>
  )
}

EventBlock.propTypes = {
  title: PropTypes.string,
  positionStyles: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  event: PropTypes.shape({
    name: PropTypes.string,
    hasConflict: PropTypes.bool,
    type: PropTypes.string,
  }),
  hasConflict: PropTypes.bool,
}

export default EventBlock
