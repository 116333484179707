import React, { useEffect, useState } from "react"

import { images } from "@utils/imageUtils"

import './index.scss'

const publicInfoIcons = [
  {
    id: 2,
    image: images.ndProfilePublicInfoEmail,
    alt: 'public info email'
  },
  {
    id: 3,
    image: images.ndProfilePublicInfoPhone,
    alt: 'public info phone'
  }
]

const ProfilePagePublicInfo = ({ userInfo }) => {

  return (
    <div className="profile-about-item">
      <h4 className="profile-about-item__title">Public Information</h4>
      <div className="profile-about-item__line"></div>

      <div className="profile-about-item__button">
        <img
          src={images.ndProfileAddContact}
          alt="add contact"
          style={{ marginRight: 5 }}  
        />
        Add Contact
      </div>
    
      <div className="profile-about-public">
        {userInfo.contactMechanisms.length
          ? userInfo.contactMechanisms.map(item => {
            const icon = publicInfoIcons.find(i => i.id === item.typeId)
          
            return (
              <div className="profile-about-public__item" key={item.id}>
                <img
                  className="profile-about-public__item--icon"
                  src={icon.image}
                  alt={icon.alt}
                />
                <p className="profile-about-public__item--text">
                  {item.value}
                </p>
              </div>
            )
          })
          : "No Information"}
      </div>
    </div>
  )
}

export default ProfilePagePublicInfo
