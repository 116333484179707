import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"
import { images } from "@utils/imageUtils"
import {
  teeupStatus,
  teeupUserStatus,
  teeupUserStatusKeys,
  teeupStatusKeys,
} from "@configs/enums"

const statusButtonHeight = Units.responsiveValue(44)

const ViewStyles = StyleSheet.create({
  teeupTitleWrapper: {
    flex: 1,
    alignItems: "center",
  },
  topTitle: {
    ...AppFonts.extraSmallExtrabold,
    fontSize: Units.responsiveValue(14),
    color: AppColors.brand.grey157,
    marginBottom: Units.responsiveValue(12),
    marginTop: Units.responsiveValue(14),
    textAlign: "center",
  },
  placeholderWrapper: {
    flex: 1,
    backgroundColor: AppColors.brand.lightGrey,
    justifyContent: "center",
    alignItems: "center",
  },
  placeholderTitle: {
    ...AppFonts.xlDemibold,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.56),
    color: AppColors.brand.darkGrey3,
  },
  placeholderText: {
    ...AppFonts.mediumMedium,
    lineHeight: Units.responsiveValue(18),
    letterSpacing: -Units.responsiveValue(0.35),
    textAlign: "center",
    color: AppColors.brand.darkGrey2,
    marginTop: Units.responsiveValue(12),
    paddingHorizontal: Units.responsiveValue(40),
  },
  pollLineTitle: {
    ...AppFonts.mediumDemibold,
    letterSpacing: -Units.responsiveValue(0.3),
    color: AppColors.brand.darkGrey2,
  },
  respondButtonTitle: (isAlreadyVotedOnOrganizerPoll) => ({
    ...AppFonts.mediumDemibold,
    letterSpacing: -Units.responsiveValue(0.3),
    lineHeight: Units.responsiveValue(20),
    color:
      AppColors.brand[!isAlreadyVotedOnOrganizerPoll ? "white" : "warmGrey"],
  }),
  checkMarkImage: {
    color: AppColors.brand.warmGrey,
    marginLeft: Units.responsiveValue(5),
  },
  pollLine: {
    flexDirection: "row",
    height: Units.responsiveValue(48),
    justifyContent: "space-between",
    paddingHorizontal: Units.responsiveValue(16),
    marginVertical: Units.responsiveValue(12),
    backgroundColor: AppColors.brand.white,
    alignItems: "center",
  },
  pollRespondButton: ({ isAlreadyVotedOnOrganizerPoll }) => ({
    flexDirection: "row",
    alignItems: "center",
    height: Units.responsiveValue(32),
    paddingHorizontal: Units.responsiveValue(16),
    borderRadius: Units.responsiveValue(16),
    backgroundColor:
      AppColors.brand[!isAlreadyVotedOnOrganizerPoll ? "pink" : "grey2"],
  }),
  background: {
    flex: 1,
    // backgroundColor: AppColors.backdropBackground,
  },
  container: {
    flex: 1,
  },
  hoverComponent: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
  },
  flex: {
    flex: 1,
  },
  padding: {
    paddingTop: Units.rem(1),
  },
  backdropContainer: {},
  backdropRowContainer: {
    backgroundColor: AppColors.brand.white20,
    paddingHorizontal: Units.rem(1),
    paddingBottom: Units.rem(0.6875),
    paddingTop: Units.rem(0.9375),
    marginBottom: 1,
  },
  backdropRowText: {
    ...AppFonts.bigDemibold,
    color: "white",
  },
  backdropTitle: {
    opacity: 0.7,
    ...AppFonts.mediumDemibold,
    color: "white",
    paddingHorizontal: Units.rem(1),
    paddingBottom: Units.rem(0.5),
    paddingTop: Units.rem(0.5),
  },
  navButton: {
    tintColor: AppColors.brand.pink,
    marginHorizontal: Units.rem(1),
  },
  stubNavButton: {
    width: Units.responsiveValue(48),
    height: Units.responsiveValue(48),
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: AppColors.brand.grey40,
    zIndex: 1,
  },
  flexRow: {
    flexDirection: "row",
  },
  buttonGroup: {
    marginTop: Units.rem(0.9375),
    justifyContent: "flex-end",
  },
  opacity30: {
    opacity: 0.3,
  },
  opacity40: {
    opacity: 0.4,
  },
  statusSelector: {
    paddingVertical: Units.rem(1),
  },
  statusButtonContainer: {
    width: "100%",
    alignItems: "center",
    borderWidth: 0,
    borderColor: "transparent",
  },
  statusButtonWrapper: {
    width: 190,
  },
  statusButton: (selected, bgColor) => ({
    height: statusButtonHeight,
    borderWidth: 1,
    borderColor: AppColors.brand.greyLight,
    borderRadius: statusButtonHeight / 2,
    backgroundColor: selected ? bgColor : AppColors.brand.white,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginVertical: Units.responsiveValue(6),
    paddingLeft: Units.responsiveValue(18),
  }),
  statusButtonContent: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusButtonText: (selected, expanded) => ({
    paddingLeft: Units.responsiveValue(6),
    ...(expanded ? AppFonts.smallestBold : AppFonts.middle),
    textTransform: "uppercase",
    color: selected ? AppColors.brand.white : AppColors.brand.darkGrey2,
  }),
  myStatusButtonText: {
    flex: 1,
    ...AppFonts.extraSmallExtrabold,
    textTransform: "uppercase",
    color: AppColors.brand.white,
    textAlign: "left",
  },
  statusButtonRight: (selected) => ({
    height: Units.responsiveValue(44),
    justifyContent: "center",
    paddingLeft: Units.responsiveValue(10),
    paddingRight: Units.responsiveValue(18),
    borderLeftWidth: 1,
    borderLeftColor: selected
      ? AppColors.brand.white
      : AppColors.brand.warmGrey2_50,
  }),
  statusAddUserButton: (selected) => ({
    width: Units.responsiveValue(15),
    height: Units.responsiveValue(15),
    transform: [{ scaleX: -1 }],
    color: selected ? AppColors.brand.white : AppColors.brand.grey157,
  }),
  statusSetUserButton: {
    ...AppFonts.mediumBold,
    fontSize: Units.responsiveValue(12),
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
    color: AppColors.brand.white,
  },
  leftImage: (selected, color) => ({
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
    tintColor: selected ? AppColors.brand.white : color,
  }),
  rightImage: {
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
    tintColor: AppColors.brand.white,
    marginRight: 10,
    marginLeft: 12,
  },
  rightCount: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.white,
    marginRight: 10,
    marginLeft: 6,
  },
  nudgeDecidedIcon: {
    tintColor: AppColors.brand.pink,
    height: Units.responsiveValue(13),
    width: Units.responsiveValue(13),
  },
  teeupHeaderTitle: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: Units.responsiveValue(0),
  },
  chavronImage: {
    height: Units.responsiveValue(21),
    width: Units.responsiveValue(10),
    tintColor: AppColors.brand.white,
  },
})

const ViewProps = {
  gradientBackground: {
    colors: [AppColors.bgGradient21, AppColors.bgGradient22],
  },
  planningIcon: {
    name: "lock",
    type: "material-icons",
    size: Units.rem(1.125),
    color: "white",
  },
  closeIcon: {
    name: "close",
    type: "material-icons",
    size: Units.rem(1.5),
    color: "white",
  },
  mailIcon: {
    name: "envelope-open",
    type: "font-awesome",
    size: Units.rem(1),
    color: "white",
  },
  checkmark: {
    type: "material-icons",
    name: "check",
    size: Units.rem(1),
    color: "white",
  },
}

const UserStatusButtonProps = [
  {
    title: teeupUserStatus.joined,
    titleColor: AppColors.brand.darkGrey2,
    refTitle: teeupUserStatus.onmyway,
    topTitle: "TEEUP REACTION",
    image: images.joinedBig,
    color: AppColors.status.user.joined,
    border: AppColors.brand.grey2,
    right: true,
    testID: "teeupUserJoinedBtn",
    status: teeupUserStatusKeys.joined,
  },
  {
    title: teeupUserStatus.coolIdea,
    refTitle: teeupUserStatus.onmyway,
    image: images.snowflake,
    color: AppColors.status.user.coolIdea,
    right: false,
    testID: "teeupUserCoolideaBtn",
    status: teeupUserStatusKeys.coolIdea,
  },
  {
    title: teeupUserStatus.invited,
    refTitle: teeupUserStatus.onmyway,
    image: images.envelope,
    color: AppColors.status.user.invited,
    right: true,
    testID: "teeupUserInvitedBtn",
    status: teeupUserStatusKeys.invited,
  },
  {
    title: teeupUserStatus.going,
    refTitle: teeupUserStatus.onmyway,
    image: images.arrowRight,
    color: AppColors.status.user.going,
    right: true,
    testID: "teeupGoingBtn",
    status: teeupUserStatusKeys.going,
  },
  {
    title: teeupUserStatus.mightgo,
    refTitle: teeupUserStatus.onmyway,
    image: images.question,
    color: AppColors.status.user.mightgo,
    right: true,
    testID: "teeupMightGoBtn",
    status: teeupUserStatusKeys.mightgo,
  },
  {
    title: teeupUserStatus.notgoing,
    refTitle: teeupUserStatus.onmyway,
    image: images.times,
    color: AppColors.status.user.notgoing,
    right: true,
    testID: "teeupUserNotGoingBtn",
    status: teeupUserStatusKeys.notgoing,
  },
  {
    title: teeupUserStatus.onmyway,
    refTitle: teeupUserStatus.onmyway,
    image: images.rocket,
    color: AppColors.status.user.onmyway,
    right: false,
    testID: "teeupUserOnMyWayBtn",
    status: teeupUserStatusKeys.onmyway,
  },
  {
    title: teeupUserStatus.attended,
    refTitle: teeupUserStatus.onmyway,
    image: images.flagCheckered,
    color: AppColors.status.user.attended,
    right: true,
    testID: "teeupUserAttendedBtn",
    status: teeupUserStatusKeys.attended,
  },
  {
    topTitle: "HEADS UP",
    title: teeupUserStatus.onmyway,
    refTitle: teeupUserStatus.going,
    image: images.arrowRight,
    color: AppColors.status.user.onmyway,
    right: true,
    testID: "teeupUserGoingBtn",
    status: teeupUserStatusKeys.onmyway,
  },
  {
    title: teeupUserStatus.runningLate,
    refTitle: teeupUserStatus.droppedout,
    image: images.runningLateOrange,
    color: AppColors.status.user.runningLate,
    right: true,
    testID: "teeupUserRunningLateBtn",
    status: teeupUserStatusKeys.runningLate,
  },
  {
    title: teeupUserStatus.arrived,
    refTitle: teeupUserStatus.onmyway,
    image: images.flag,
    color: AppColors.status.user.arrived,
    right: true,
    testID: "teeupUserArrivedBtn",
    status: teeupUserStatusKeys.arrived,
  },
  {
    title: teeupUserStatus.backedOut,
    refTitle: teeupUserStatus.droppedout,
    image: images.backedoutRed,
    color: AppColors.status.user.backedOut,
    right: false,
    testID: "teeupUserBackedoutBtn",
    status: teeupUserStatusKeys.backedOut,
  },
]

// going: 'Going',
// mightgo: 'Might Go',
// notgoing: 'Not Going',
// invited: 'Invited',
// joined: 'Joined',
// onmyway: 'On My Way',
// arrived: 'Arrived',
// attended: 'Attended',
// skipped: 'Declined',
// droppedout: 'Dropped out',
// coolIdea: 'Cool Idea',
// runningLate: 'Running Late',
// backedOut: 'Backed Out',

const TeeupStatusButtonProps = [
  {
    title: teeupStatus.planning,
    refTitle: teeupUserStatus.onmyway,
    image: images.mapSigns,
    color: AppColors.brand.orange,
    testID: "teeupPlanningBtn",
    status: teeupStatusKeys.planning,
  },
  {
    title: teeupStatus.allset,
    refTitle: teeupUserStatus.onmyway,
    image: images.thumbsUp,
    color: AppColors.brand.green,
    testID: "teeupAllsetBtn",
    status: teeupStatusKeys.allset,
  },
  {
    title: teeupStatus.happening,
    refTitle: teeupUserStatus.onmyway,
    image: images.bolt,
    color: AppColors.brand.blue2,
    testID: "teeupHappeningBtn",
    status: teeupStatusKeys.happening,
  },
  {
    title: teeupStatus.ended,
    refTitle: teeupUserStatus.onmyway,
    image: images.hourglassEnd,
    color: AppColors.brand.warmGrey,
    testID: "teeupEndedBtn",
    status: teeupStatusKeys.ended,
  },
  {
    title: teeupStatus.cancelled,
    refTitle: teeupUserStatus.onmyway,
    image: images.times,
    color: AppColors.brand.red,
    testID: "teeupCancelledBtn",
    status: teeupStatusKeys.cancelled,
  },
]

const ButtonProps = {
  cancel: {
    title: "",
    leftImage: images.close,
    color: AppColors.brand.blue,
    testID: "teeupChoicesCancelBtn",
  },
  // User statuses
  going: {
    title: teeupUserStatus.going,
    refTitle: teeupUserStatus.onmyway,
    image: images.arrowRight,
    color: AppColors.status.user.going,
    right: true,
    testID: "teeupUserGoingBtn",
  },
  invited: {
    title: teeupUserStatus.invited,
    refTitle: teeupUserStatus.onmyway,
    image: images.envelope,
    color: AppColors.status.user.invited,
    right: true,
    testID: "teeupUserInvitedBtn",
  },
  joined: {
    title: teeupUserStatus.joined,
    titleColor: AppColors.brand.darkGrey2,
    refTitle: teeupUserStatus.onmyway,
    image: images.joinedBig,
    color: AppColors.brand.white,
    border: AppColors.brand.grey2,
    right: true,
    testID: "teeupUserJoinedBtn",
  },
  notgoing: {
    title: teeupUserStatus.notgoing,
    refTitle: teeupUserStatus.onmyway,
    image: images.times,
    color: AppColors.status.user.notgoing,
    right: true,
    testID: "teeupUserNotGoingBtn",
  },
  mightgo: {
    title: teeupUserStatus.mightgo,
    refTitle: teeupUserStatus.onmyway,
    image: images.question,
    color: AppColors.status.user.mightgo,
    right: true,
    testID: "teeupMightGoBtn",
  },
  onmyway: {
    title: teeupUserStatus.onmyway,
    refTitle: teeupUserStatus.onmyway,
    image: images.rocket,
    color: AppColors.status.user.onmyway,
    right: true,
    testID: "teeupUserOnMyWayBtn",
  },
  arrived: {
    title: teeupUserStatus.arrived,
    refTitle: teeupUserStatus.onmyway,
    image: images.flag,
    color: AppColors.status.user.arrived,
    right: true,
    testID: "teeupUserArrivedBtn",
  },
  attended: {
    title: teeupUserStatus.attended,
    refTitle: teeupUserStatus.onmyway,
    image: images.flagCheckered,
    color: AppColors.status.user.attended,
    right: true,
    testID: "teeupUserAttendedBtn",
  },
  // Teeup statuses
  planning: {
    title: teeupStatus.planning,
    refTitle: teeupUserStatus.onmyway,
    image: images.mapSigns,
    color: AppColors.brand.orange,
    testID: "teeupPlanningBtn",
  },
  allset: {
    title: teeupStatus.allset,
    refTitle: teeupUserStatus.onmyway,
    image: images.thumbsUp,
    color: AppColors.brand.green,
    testID: "teeupAllsetBtn",
  },
  happening: {
    title: teeupStatus.happening,
    refTitle: teeupUserStatus.onmyway,
    image: images.bolt,
    color: AppColors.brand.blue2,
    testID: "teeupHappeningBtn",
  },
  cancelled: {
    title: teeupStatus.cancelled,
    refTitle: teeupUserStatus.onmyway,
    image: images.times,
    color: AppColors.brand.red,
    testID: "teeupCancelledBtn",
  },
  ended: {
    title: teeupStatus.ended,
    refTitle: teeupUserStatus.onmyway,
    image: images.hourglassEnd,
    color: AppColors.brand.warmGrey,
    testID: "teeupEndedBtn",
  },
}

const HeaderStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: Units.rem(0.5),
    alignItems: "center",
  },
  title: {
    flex: 1,
    color: "white",
    ...AppFonts.header22,
    textAlign: "center",
    textAlignVertical: "center",

    lineHeight: Units.rem(1.75),
    letterSpacing: -Units.rem(0.0375),
  },
  extraPadding: {
    padding: Units.rem(0.5),
  },
})

const CardStyles = StyleSheet.create({
  fullFlex: {
    flex: 1,
  },
  skeletonContainer: {
    overflow: "hidden",
  },
  card: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    shadowColor: "black",
    shadowOffset: { height: 3, width: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 6,
  },
  cardContent: {
    paddingHorizontal: 9,
    flex: 1,
    marginTop: Units.responsiveValue(12),
    paddingBottom: 26,
  },
  grabber: {
    flex: 1,
    alignItems: "center",
    paddingTop: Units.responsiveValue(8),
    backgroundColor: AppColors.brand.white,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  grabberIndicator: {
    width: Units.responsiveValue(32),
    height: Units.responsiveValue(4),
    borderRadius: Units.responsiveValue(2),
    backgroundColor: AppColors.brand.grey193,
  },
  blocker: {
    height: Units.responsiveValue(40),
    borderTopWidth: 1,
    borderTopColor: AppColors.brand.grey2,
    backgroundColor: AppColors.brand.lightGrey,
  },
  linearGradient: {
    position: "absolute",
    top: Units.responsiveValue(-6),
    left: 0,
    right: 0,
    width: "100%",
    height: Units.responsiveValue(12),
    paddingLeft: 12,
    paddingRight: 12,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
})

export {
  ViewStyles,
  ViewProps,
  HeaderStyles,
  ButtonProps,
  UserStatusButtonProps,
  TeeupStatusButtonProps,
  CardStyles,
}
