import React, { useState } from "react"
import { useParams } from "react-router-dom"

import ProfilePageHeader from "./ProfilePageHeader"
import ProfilePageAbout from "./ProfilePageAbout"
import ProfilePageSharedTeeups from "./ProfilePageSharedTeeups"

import useProfilePageSharedTeeUps from "./ProfilePageContext/useProfilePageSharedTeeUps"
import useProfilePageUser from "./ProfilePageContext/useProfilePageUser"

import './index.scss'

const ProfilePage = () => {
  const { id } = useParams()
  const { sharedTeeUps } = useProfilePageSharedTeeUps(id) // shared teeups hook
  const { userInfo, setIsTagUser, isThreeDotsOpen, setIsThreeDotsOpen } = useProfilePageUser(id) // user info with tags hook

  const [activeScreen, setActiveScreen] = useState(0)

  return (
    <div
      className="profile__wrapper"
      onClick={() => isThreeDotsOpen && setIsThreeDotsOpen(false)} //close modal three dots
    >
      <div className="profile">
        {userInfo && (
          <>
            <ProfilePageHeader
              userInfo={userInfo}
              activeScreen={activeScreen}
              setActiveScreen={setActiveScreen}
              setIsTagUser={setIsTagUser}
            />
            {activeScreen === 1 ? (
              <ProfilePageAbout userInfo={userInfo} />
            ) : (
              <ProfilePageSharedTeeups
                sharedTeeUps={sharedTeeUps}
                currentUserId={userInfo.profile.id}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ProfilePage
