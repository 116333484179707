import React, { useState, useEffect, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import CreatePassword from "./components/CreatePassword"
import CreateAccount from "./components/CreateAccount"
import NameDisplay from "./components/NameDisplay"
import Success from "../components/Success"
import { images } from "@utils/imageUtils"
import "./index.scss"
import logo from "../../assets/images/teeUpsPink.svg"
import { getCountryCodeFromPhoneNumber } from "@utils/contactUtils"
import {
  register,
  validateInvitation,
  sendBetaCode,
} from "@actions/loginActions"
import { fetchUserInfo, setUserInfo } from "@actions/userActions"
import { selectToken, selectUserInfo } from "@selectors/user"
import {
  createUserCM,
  getUserCM,
  changeEmailVerificationRequest,
  deleteUserCM,
} from "@actions/loginActions"
import api from "../../api"
import VerifyCode from "../VerifyCode"

import * as RNLocalize from "react-native-localize"
import { v4 as uuidv4 } from "uuid"
import CustomTemplateSign from "pages/components/CustomTemplateSign/index"
import { TEMPLATE_TYPES } from "pages/components/CustomTemplateSign/constants"

let userInfo = {}
const deviceId = uuidv4()

const SignUp = ({ setUserInfo, token, location }) => {
  const [step, setStep] = useState(1)
  const [isPreview, setIsPreview] = useState(false)
  const history = useHistory()

  const params = history.location.state

  const [currentEmail, setCurrentEmail] = useState(params?.defaultEmail)

  // const [isEmptyName, setIsEmptyName] = useState(false)

  useEffect(() => {
    if (location.state) {
      setIsPreview(true)
    }
  }, [])

  function handleAddEmail(email) {
    userInfo.email = email
    // nextStep();
  }

  const handleAddPassword = (password) => {
    userInfo.password = password
    nextStep()
  }

  const handleAddNames = (fullName, name) => {
    userInfo.name = fullName
    userInfo.nickname = name

    if (params) {
      const { userId, phoneNumber, deviceId } = params
      userInfo.id = userId
      userInfo.deviceId = deviceId

      if (phoneNumber) {
        userInfo.countryCode = getCountryCodeFromPhoneNumber(phoneNumber)
        userInfo.phoneNumber = phoneNumber
      }
    } else {
      const { userId, phoneNumber } = userInfo
      // userInfo.id = userId;
      userInfo.deviceId = deviceId

      if (phoneNumber) {
        userInfo.countryCode = getCountryCodeFromPhoneNumber(phoneNumber)
        userInfo.phoneNumber = phoneNumber
      }
    }

    // if (phoneNumber) {
    //   userInfo.countryCode = getCountryCodeFromPhoneNumber(phoneNumber);
    //   userInfo.phoneNumber = phoneNumber;
    // }

    register(userInfo).then((response) => {
      userInfo = {
        userId: response.data.id,
        ...response.data,
        loginType: "email", // TODO: change when add social login
      }
    })
    // .catch((error) => {
    //   const { status, message } = error.response.data

    //   if (status === 409) {
    //     alert(message);
    //     // setIsEmptyName(true)
    //   }
    //   // } else {
    //   //   // alert('Something went wrong!');
    //   //   // setIsEmptyName(true)
    //   //   // alert(message);
    //   // }
    // })
    nextStep()
  }

  const handleRedirect = () => {
    api.createSession(userInfo)
    setUserInfo(userInfo)
    fetchUserInfo(true)
  }

  const handleRedirectAddContact = () => {
    api.createSession(userInfo)
    setUserInfo(userInfo)
    fetchUserInfo()
    history.push("/contact")
  }

  const nextStep = () => setStep(step + 1)
  const previousStep = () => setStep(step - 1)

  // const [editMode, setEditMode] = useState(false)
  // const [value, setValue] = useState('');
  // const [popup, setPopup] = useState(false)
  const [error, setError] = useState("")
  const [contactMechanismVerificationId, setContactMechanismVerificationId] =
    useState()
  // const [addedCM, setAddedCM] = useState()

  const handleSignUp = (email) => {
    api.client
      .post("/users", {
        deviceId: deviceId,
        email: email,
        timezone: RNLocalize.getTimeZone(),
      })
      .then((response) => {
        userInfo = { ...userInfo, ...response.data.user }
        setContactMechanismVerificationId(response.data.contactMechanism.id)
      })
    nextStep()
  }

  const onDoneHandler = async (email) => {
    await createUserCM(7, email, token)
      .then((res) => {
        // setAddedCM(res.data)
        changeEmailVerificationRequest(res.data.id, token)
          .then((response) => {
            setContactMechanismVerificationId(
              response.data.contactMechanismVerificationId
            )
          })
          .catch((error) => {
            console.log("error", error)
            setError("The contact is invalid")
          })
      })
      .catch((error) => {
        console.log("CM error", error)
      })
  }

  return (
    <CustomTemplateSign type={TEMPLATE_TYPES.signup}>
      <div className="signup__page" style={{ position: "relative" }}>
        {/* <div
          className="signup__progress"
          style={{ width: `${step * 25}%` }}
        ></div> */}
        <div className="signup__content">
          {step === 1 && (
            <CreateAccount
              isPreview={isPreview}
              emailFromPreview={
                location.state ? location.state.emailFromPreview : null
              }
              handleAddEmail={handleAddEmail}
              defaultEmail={
                isPreview
                  ? params?.defaultEmail
                  : location.state
                  ? location.state.emailFromPreview
                  : null
              }
              handleClickVerify={token ? onDoneHandler : handleSignUp}
              // handleClickVerify={onDoneHandler}
              setCurrentEmail={setCurrentEmail}
            />
          )}
          {step === 2 && (
            // <VerifyCode {...verificationInfo} verifyEmail nextStep={nextStep}/>
            <VerifyCode
              emailVerification
              // type={'email'}
              contactMechanismVerification={contactMechanismVerificationId}
              accessToken={token}
              nextStep={nextStep}
              previousStep={previousStep}
              value={currentEmail}
            />
          )}
          {step === 3 && (
            <CreatePassword onClickContinueBtn={handleAddPassword} />
          )}
          {step === 4 && <NameDisplay handleAddNames={handleAddNames} />}
          {/* {isEmptyName && (
          <ErrorInName closeSelect={() => setIsEmptyName(false)} />
        )} */}
          {step === 5 && (
            <Success
              image={images.successCreate}
              title="Great! Just one more step"
              subtitle="Your account is active, but we suggest that you set up contact methods now so your friends can easily invite you to make plans."
              buttonText="Setup Contact Methods"
              onClick={handleRedirectAddContact}
              secondaryButton={
                <span onClick={handleRedirect} className="signup__later">
                  I’ll do this later
                </span>
              }
            />
          )}
        </div>
      </div>
    </CustomTemplateSign>
  )
}

const mapStateToProps = (state) => ({
  token: state.signUpWithInvitation.token,
  userInfo: selectUserInfo(state),
})

const mapDispatchToProps = (dispatch) => ({
  setUserInfo: bindActionCreators(setUserInfo, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
