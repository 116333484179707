import React from "react"
import { Text, Image, View } from "react-native"
import Props from "prop-types"

import { images } from "@utils/imageUtils"
import styles from "./styles"

const Tag = ({ text }) => (
  <View style={styles.container}>
    <Image style={styles.image} source={images.tag} />
    <Text style={styles.text}>{text}</Text>
  </View>
)

Tag.propTypes = {
  text: Props.string.isRequired,
}

export default Tag
