import React from "react"
import ApiCalendar from "react-google-calendar-api"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import "./index.scss"
import { resetCalendar } from "@actions/calendarActions"
import { selectUserEmailCalendar } from "@selectors/calendar"

const SyncCalendarButton = ({ email, resetCalendar }) => {
  const handleAddRemoveCalendar = (event) => {
    event.preventDefault()
    if (ApiCalendar.sign) {
      ApiCalendar.handleSignoutClick()
      resetCalendar()
    } else {
      ApiCalendar.handleAuthClick()
    }
  }

  return (
    <button className="calendar_btn" onClick={handleAddRemoveCalendar}>
      <span className="primary_text">
        {" "}
        {email ? "Remove " : "Select "} Primary Calendar
      </span>
      <span className="secondary_text calendar_email"> {email || ""} </span>
      <span className="secondary_text">
        External Calendar you want to insert your TeeUps
      </span>
    </button>
  )
}

const mapStateToProps = (state) => ({
  email: selectUserEmailCalendar(state),
})

const mapDispatchToProps = (dispatch) => ({
  resetCalendar: bindActionCreators(resetCalendar, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SyncCalendarButton)
