import React from "react"
import logo from "../../../assets/images/teeUpsPink.svg"
import { images } from "@utils/imageUtils"

import "./index.scss"

const Success = ({
  image,
  title,
  subtitle,
  buttonText,
  onClick,
  secondaryButton,
}) => {
  const logoStyles = {
    height: "30px",
    width: "32px",
    marginBottom: "9px",
  }

  return (
    <div className="success__container">
      {/* <img style={logoStyles} src={images.cooeModal} /> */}
      <div className="success__content">
        <img
          className="success__image"
          src={image}
          alt="email verified thumb"
        />
        <span className="success__title">{title}</span>
        <span className="success__subtitle">{subtitle}</span>
        <button className="forgot__button" onClick={onClick}>
          {buttonText}
        </button>
        {secondaryButton}
      </div>
    </div>
  )
}

export default Success
