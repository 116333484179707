import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

const ViewStyles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: AppColors.brand.background,
  },
  topBarTitle: {
    ...AppFonts.headerSmallDemibold,
    color: AppColors.brand.blackTwo,
    lineHeight: Units.responsiveValue(26),
    letterSpacing: -Units.responsiveValue(0.76),
  },
  primaryText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.darkGrey2,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  secondaryText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.darkGrey2,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  inputContainer: {
    marginTop: Units.responsiveValue(25),
  },
  input: {
    height: Units.rem(3),
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    justifyContent: "center",
    color: AppColors.brand.black,
    lineHeight: Units.responsiveValue(25),
  },
  inputLabel: {
    ...AppFonts.biggerDemibold,
    paddingHorizontal: 0,
    paddingTop: 0,
    color: AppColors.brand.black2,
    minHeight: Units.responsiveValue(30),
  },
  disabledInputContainer: {
    marginTop: Units.rem(0.5),
    flex: 0,
  },
  disabledInputWrapper: {
    backgroundColor: AppColors.brand.white,
  },
  disabledInputStyle: {
    height: Units.rem(3),
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: AppColors.brand.white,
    color: AppColors.brand.darkGrey2,
  },
  disabledPhoneInput: {
    color: AppColors.brand.darkGrey2,
    height: Units.rem(3),
  },
  buttonRow: {
    flexDirection: "row",
    marginTop: Units.rem(1),
    justifyContent: "space-between",
  },
  singleButtonRow: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    marginTop: Units.rem(2),
  },
  button: {
    shadowColor: "black",
    shadowOffset: { height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 8,
    borderRadius: Units.rem(2),
    width: "48%",
    height: Units.rem(3),
    justifyContent: "center",
    alignItems: "center",
  },
  tryAgainButton: {
    borderRadius: Units.rem(2),
    paddingHorizontal: Units.responsiveValue(20),
    height: Units.rem(3),
    justifyContent: "center",
    alignItems: "center",
  },
  outlineButton: {
    borderWidth: 2,
    borderColor: AppColors.brand.pink,
    borderRadius: Units.rem(2),
    width: "48%",
    height: Units.rem(3),
    justifyContent: "center",
    alignItems: "center",
  },
  blockButton: {
    shadowColor: "black",
    shadowOffset: { height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 8,
    borderRadius: Units.rem(2),
    marginTop: Units.rem(1),
    height: Units.rem(3.5),
    paddingHorizontal: Units.rem(1),
  },
  buttonTitle: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.white,
  },
  buttonTitleWhite: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.pink,
  },
  blockButtonTitle: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.white,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  errorText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.white,
    textAlign: "center",
    paddingTop: Units.rem(0.25),
  },
  footerButton: {
    alignItems: "center",
    paddingTop: Units.responsiveValue(20),
  },
  footerButtonText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.pink,
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: AppColors.brand.black50,
  },
  flex: {
    flex: 1,
  },
  scrollContent: {
    paddingHorizontal: Units.responsiveValue(24),
    paddingTop: Units.responsiveValue(20),
    paddingBottom: Units.responsiveValue(32),
    backgroundColor: AppColors.brand.grey243,
    justifyContent: "space-between",
    height: "100%",
  },
})

const ViewProps = {
  buttonStyles: {
    color: AppColors.brand.pink,
    style: ViewStyles.button,
    styleTitle: ViewStyles.buttonTitle,
  },
  outlineButtonStyles: {
    color: "transparent",
    style: ViewStyles.outlineButton,
    styleTitle: ViewStyles.buttonTitleWhite,
  },
  blockButtonStyles: {
    color: AppColors.brand.pink,
    style: ViewStyles.blockButton,
    styleTitle: ViewStyles.blockButtonTitle,
  },
  tryAgainButtonStyles: {
    color: AppColors.brand.pink,
    style: ViewStyles.tryAgainButton,
    styleTitle: ViewStyles.buttonTitle,
  },
}

export { ViewStyles, ViewProps }
