import { teeupUserStatusKeys, teeupStatusKeys } from "@configs/enums"

import { images } from "@utils/imageUtils"

const PLANNING_BTN = {
  id: teeupStatusKeys.planning,
  label: "Planing",
  bgColor: "#ff6f25",
  outline: true,
}

const ALLSET_BTN = {
  id: teeupStatusKeys.allset,
  label: "All set",
  bgColor: "#00c5ae",
  outline: true,
}

const HAPPENING_BTN = {
  id: teeupStatusKeys.happening,
  label: "Happening now",
  bgColor: "#00bcf6",
  outline: true,
}

const ENDED_BTN = {
  id: teeupStatusKeys.ended,
  label: "Ended",
  color: "#939393",
  bgColor: "#d1d1d1",
  outline: true,
}

export const TeeupStateButtonMap = {
  [teeupStatusKeys.planning]: PLANNING_BTN,
  [teeupStatusKeys.allset]: ALLSET_BTN,
  [teeupStatusKeys.happening]: HAPPENING_BTN,
  [teeupStatusKeys.ended]: ENDED_BTN,
}

export const TeeupStateButtonList = [...Object.values(TeeupStateButtonMap)]

// =======

const GOING_BTN = {
  id: teeupUserStatusKeys.going,
  label: "I'm going",
  icon: images.ndArrowIcon,
  secondaryIcon: images.ndPlusUserIcon,
  bgColor: "#00c5ae",
}
const MIGHTGO_BTN = {
  id: teeupUserStatusKeys.mightgo,
  label: "Might go",
  icon: images.ndQuestionMarkIcon,
  secondaryIcon: images.ndPlusUserIcon,
  bgColor: "#ff6f25",
}

const NOTGOING_BTN = {
  id: teeupUserStatusKeys.notgoing,
  label: "Not going",
  icon: images.ndXIcon,
  bgColor: "#f52d22",
}

const ONMYWAY_BTN = {
  id: teeupUserStatusKeys.onmyway,
  label: "On my way",
  icon: images.ndRocketIcon,
  secondaryIcon: images.ndPlusUserIcon,
  bgColor: "#6244ca",
}
const RUNNINGLATE_BTN = {
  id: teeupUserStatusKeys.runningLate,
  label: "Running late",
  icon: images.ndRunningManIcon,
  secondaryIcon: images.ndPlusUserIcon,
  bgColor: "#ff6f25",
}

const ARRIVED_BTN = {
  id: teeupUserStatusKeys.arrived,
  label: "Arrived",
  icon: images.ndFlagIcon,
  secondaryIcon: images.ndPlusUserIcon,
  bgColor: "#00bcf6",
}

export const INVITED_BTN = {
  id: teeupUserStatusKeys.invited,
  label: "Invited",
  icon: images.ndEnvelopIcon,
  bgColor: "#a9b0b9",
}

export const JOINED_BTN = {
  id: teeupUserStatusKeys.joined,
  label: "change status",
  bgColor: "#f42862",
  outline: true,
}

export const MyStatusButtonMap = {
  [teeupUserStatusKeys.going]: GOING_BTN,
  [teeupUserStatusKeys.mightgo]: MIGHTGO_BTN,
  [teeupUserStatusKeys.notgoing]: NOTGOING_BTN,
  [teeupUserStatusKeys.onmyway]: ONMYWAY_BTN,
  [teeupUserStatusKeys.runningLate]: RUNNINGLATE_BTN,
  [teeupUserStatusKeys.arrived]: ARRIVED_BTN,
}

export const MyStatusButtonList = [...Object.values(MyStatusButtonMap)]

export const AllUserStatusButtons = {
  ...MyStatusButtonMap,
  [teeupUserStatusKeys.joined]: JOINED_BTN,
  [teeupUserStatusKeys.invited]: INVITED_BTN,
}
