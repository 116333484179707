import React from "react"
import propTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import { View } from "react-native"

import { selectTeeupSuggestions } from "@selectors/teeups"
import {
  selectActiveTeeup,
  selectVisibleTeeupPinnedMessages,
} from "@selectors/activeTeeup"
import {
  pinUnpinMessage,
  pinUnpinSuggestion,
} from "../../../../../middlewares/backendMiddleware"
import PinnedMessagesScreenComponent from "./PinnedMessagesScreenComponent"
import { styles } from "./PinnedMessagesScreenStyles"

class PinnedMessagesScreenContainer extends React.Component {
  unpinMessage = (message) => () => {
    const { isSuggestion, id, conversationTypeId } = message
    if (isSuggestion) {
      pinUnpinSuggestion({
        isPinned: false,
        suggestionId: id,
      })
    } else {
      pinUnpinMessage({
        isPinned: false,
        messageId: id,
      })
    }
  }

  handleChatItemRender = (props) => (
    <View style={styles.chatItempart}>
      {this.props.renderChatItem({
        ...props,
        onUnpinMessage: this.unpinMessage(props.item),
        item: {
          ...props.item,
          senderId: props.item.pinnedById,
          ...(props.item.isSuggestion
            ? { suggestionSenderId: props.item.senderId }
            : {}),
        },
        disableReply: true,
        onPinboardScreen: true,
        onSuggestionCommentPress: this.onSuggestionCommentPress,
      })}
    </View>
  )

  onSuggestionCommentPress = () => {
    // routeBackToTeeup()
  }

  render() {
    const { messages, suggestions, insideTabContainer, newDesign } = this.props

    return (
      <PinnedMessagesScreenComponent
        insideTabContainer={insideTabContainer}
        messages={messages}
        onMessageItemRender={this.handleChatItemRender}
        suggestions={suggestions}
        newDesign={newDesign}
      />
    )
  }
}
PinnedMessagesScreenContainer.propTypes = {
  messages: propTypes.arrayOf(propTypes.shape({})).isRequired,
  teeupId: propTypes.number.isRequired,

  renderChatItem: propTypes.func,
  suggestions: propTypes.shape({}),
  insideTabContainer: propTypes.bool,
}

const mapStateToProps = (state) => {
  const messages = selectVisibleTeeupPinnedMessages(state)
  const teeup = selectActiveTeeup(state)
  const suggestions = selectTeeupSuggestions(state) // selector for updating suggestion messages
  return {
    messages,
    teeupId: teeup.id,
    suggestions,
  }
}

export default connect(mapStateToProps, null)(PinnedMessagesScreenContainer)
