import React, { useState } from "react"

import "./index.scss"
import FirstVerificationStep from "./FirstVerificationStep"
import VerifyCode from "../VerifyCode"

let propsForVerify = {
  type: "email",
  id: -1,
  userId: -1,
  deviceId: null,
  value: "",
}

const VerifyInvitation = () => {
  const [step, setStep] = useState(1)

  const moveToVerifyCode = (state) => {
    propsForVerify = state
    setStep(2)
  }

  const previousStep = () => setStep(step - 1)

  return (
    <>
      {step === 1 ? (
        <FirstVerificationStep moveToNextStep={moveToVerifyCode} />
      ) : (
        <VerifyCode
          previousStep={previousStep}
          {...propsForVerify}
          firstVerStep
        />
      )}
    </>
  )
}

export default VerifyInvitation
