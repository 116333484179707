import React, { Component } from "react"
import { View, TouchableOpacity, Image, Text, TextInput } from "react-native"
import PropTypes from "prop-types"
// import { Navigation } from 'react-native-navigation'
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

// import { showAddInvitees } from '@nav'
import { selectActiveTeeups } from "@selectors/teeups"
import { selectSoundSettings } from "@selectors/user"
import { AppColors } from "@theme"
// import { updateTeeupUserStatusToJoined } from '@middlewares/backendMiddleware'
// import {
//     addTeeupInvitees,
//     triggerTeeupInvitationSMSAndEmail,
// } from '@apis/backend'
import { images } from "@utils/imageUtils"
// import { sounds } from '@utils/soundUtils'
import { formatInvitees } from "@utils/contactUtils"
import { inviteToTeeup } from "@middlewares/backendMiddleware"
import { addTeeupInvitees, inviteManual } from "@actions/teeupActions"
import * as contactActions from "@actions/contactActions"
import { updateUserStatus } from "@actions/requestActions"

// import navigationConfig from '@nav/NavigationConfig'
import styles from "./styles"
import Header from "./Header"
import InviteeList from "../InviteeList"
import AddInvitees from "../AddInvitees"
import closeIcon from "../../assets/images/close.svg"
import { teeupUserStatusPriorities } from "../../config/enums"

class InviteToTeeup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      invitees: props.invitees || [],
      inviteMessage: "",
      content: null,
    }
  }
  static options() {}

  handleClose = () => {
    this.props.closeInvite()
  }

  handleSend = () => {
    try {
      const { teeupId, updateUserStatus } = this.props
      const { invitees } = this.state
      const statusId = teeupUserStatusPriorities.joined

      this.setState({ loading: true })

      const formattedInvitees = formatInvitees(invitees)
      updateUserStatus(teeupId, statusId)
      addTeeupInvitees(teeupId, formattedInvitees)
      // setTimeout(() => inviteManual(teeupId, formattedInvitees), 2000)
      console.log("here")
      inviteManual(teeupId, formattedInvitees)
      // setTimeout(() => inviteToTeeup(formattedInvitees), 5000)
      // this.props.initiateEmitSocketInvitees(formattedInvitees)
      this.handleClose()
    } catch (error) {
      console.log(error)
      this.setState({ loading: false })
    }
  }

  handleChangeInvitees = (invitees) =>
    this.setState({ invitees, content: null })

  close = () => this.setState({ content: null })

  handleAddInvitees = async () => {
    const props = {
      invitees: this.state.invitees,
      onDone: this.handleChangeInvitees,
      alreadyInvitedIds: this.props.alreadyInvitedIds,
      isTeeupInvite: true,
      onClose: this.close,
      showButtons: true,
    }

    this.setState({
      content: (
        <div>
          <AddInvitees {...props} />
        </div>
      ),
    })
  }

  handleInviteMessageChange = (inviteMessage) =>
    this.setState({ inviteMessage })

  render() {
    const { invitees, loading, content } = this.state
    return (
      <View style={styles.container}>
        {content ? (
          content
        ) : (
          <>
            <Header
              loading={loading}
              onClose={this.handleClose}
              disableSend={!invitees.length}
              onSend={this.handleSend}
            />
            <View style={styles.body}>
              {invitees.length ? (
                <View style={styles.inviteesContainer}>
                  <View style={styles.inviteesHeader}>
                    <Text style={styles.inviteesCount}>
                      {invitees.length > 1
                        ? `${invitees.length} people`
                        : `${invitees.length} person`}
                    </Text>
                    <TouchableOpacity onPress={this.handleAddInvitees}>
                      <Text style={styles.inviteesEdit}>Edit</Text>
                    </TouchableOpacity>
                  </View>
                  <InviteeList
                    invitees={invitees}
                    onPressRow={this.handleAddInvitees}
                  />
                </View>
              ) : (
                <TouchableOpacity
                  style={styles.rowItem}
                  onPress={this.handleAddInvitees}
                >
                  <Text style={styles.bodyText}>Add invitees</Text>
                  <Image source={images.chevron} style={styles.chevron} />
                </TouchableOpacity>
              )}
              <View style={styles.textInputWrapper}>
                <TextInput
                  placeholder="Invitation message (optional)"
                  placeholderTextColor={AppColors.brand.warmGrey2}
                  style={styles.textInput}
                  onChangeText={this.handleInviteMessageChange}
                  multiline
                />
              </View>
            </View>
          </>
        )}
      </View>
    )
  }
}

InviteToTeeup.propTypes = {
  invitees: PropTypes.array,
  teeupId: PropTypes.number,
  teeups: PropTypes.array.isRequired,
  // refreshActiveTeeup: PropTypes.func.isRequired,
  // initiateEmitSocketInvitees: PropTypes.func.isRequired,
  alreadyInvitedIds: PropTypes.array,
  applicationSoundEnabled: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const teeups = selectActiveTeeups(state)
  const applicationSoundEnabled = selectSoundSettings(state)
  return {
    teeups,
    applicationSoundEnabled,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUserStatus: bindActionCreators(updateUserStatus, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteToTeeup)
