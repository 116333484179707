import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    //backgroundColor: AppColors.brand.lightGrey,
  },
  rowContainer: {
    flexDirection: "row",
    // marginTop: Units.responsiveValue(24),
    padding: Units.responsiveValue(11),
    alignItems: "center",
    marginTop: 8,
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    // borderTopColor: AppColors.brand.lightGrey,
    // borderTopWidth: 1,
  },
  primaryTopText: {
    ...AppFonts.biggerBold,
    color: AppColors.brand.black,
    // paddingHorizontal: Units.responsiveValue(16.5),
    paddingVertical: Units.responsiveValue(10),
  },
  ask: {
    fontFamily: "greycliff-cf-medium",
    fontSize: "13px",
    letterSpacing: "-0.17px",
    color: "#9d9d9d",
    marginTop: "10px",
    marginBottom: "10px",
  },
  secondaryTopText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.darkGrey2,
    paddingHorizontal: Units.responsiveValue(16.5),
    paddingVertical: Units.responsiveValue(9),
  },
  contentContainer: {
    backgroundColor: AppColors.brand.white,
    padding: Units.responsiveValue(16),
    borderTopWidth: 1,
    // borderTopColor: AppColors.brand.lightGrey,
  },
  splitter: {
    height: Units.responsiveValue(16),
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: Units.responsiveValue(8),
  },
  contentTitle: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.blackTwo,
  },
  chevronStyle: {
    tintColor: AppColors.brand.warmGrey2,
    height: Units.responsiveValue(12),
    width: Units.responsiveValue(7),
  },
  contactRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  contentText: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.darkGrey2,
    marginRight: Units.rem(0.25),
  },
  locationItem: {
    marginTop: Units.responsiveValue(8),
  },
  locationTitle: {
    ...AppFonts.mediumBold,
    color: AppColors.brand.darkGrey2,
  },
  descAndDist: {
    ...AppFonts.medium,
    color: AppColors.brand.darkGrey2,
  },
})
