import React from "react"
import Props from "prop-types"
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ActivityIndicator,
} from "react-native"

import { images } from "@utils/imageUtils"
import { requestStatusId } from "@utils/contactUtils"
import styles from "./styles"

const getDisabledRequestButton = (buttonImage, buttonText) => {
  return (
    <View style={[styles.button, styles.disabledButton]}>
      <Image style={styles.buttonImage} source={buttonImage} />
      <Text style={[styles.buttonText, styles.disabledButtonText]}>
        {buttonText}
      </Text>
    </View>
  )
}

const cooeUserActions = (onConnect, requestLoading, request, isConnected) => {
  if (requestLoading) {
    return (
      <View style={[styles.button, styles.outlineButton]}>
        <ActivityIndicator />
      </View>
    )
  }

  if (isConnected) {
    const buttonImage = images.connectDisabled
    const buttonText = "Connected"

    return getDisabledRequestButton(buttonImage, buttonText)
  }

  if (request.id && request.statusId === requestStatusId.pending) {
    const buttonImage = images.connectPending
    const buttonText = "Request sent"

    return getDisabledRequestButton(buttonImage, buttonText)
  }

  return (
    <TouchableOpacity
      onPress={onConnect}
      style={[styles.button, styles.outlineButton]}
    >
      <Image style={styles.buttonImage} source={images.connect} />
      <Text style={[styles.buttonText, styles.outlineButtonText]}>Connect</Text>
    </TouchableOpacity>
  )
}

const SocialButtons = ({
  visible,
  onConnect,
  onInvite,
  requestLoading,
  request,
  isCooeUser,
  isConnected,
}) =>
  visible && (
    <View style={styles.buttons}>
      {
        // don't shown button for non cooe users
        isCooeUser &&
          cooeUserActions(onConnect, requestLoading, request, isConnected)
      }

      <TouchableOpacity style={styles.button} onPress={onInvite}>
        <Image style={styles.buttonImage} source={images.inviteButton} />
        <Text style={styles.buttonText}>Invite to TeeUp</Text>
      </TouchableOpacity>
    </View>
  )

SocialButtons.propTypes = {
  visible: Props.bool.isRequired,
  onConnect: Props.func.isRequired,
  onInvite: Props.func.isRequired,
  requestLoading: Props.bool,
  request: Props.object,
  isCooeUser: Props.bool.isRequired,
}

export default SocialButtons
