import React from "react"
import { connect } from "react-redux"
import { Platform, TouchableOpacity, Image } from "react-native"
import PropTypes from "prop-types"
import closeIcon from "../../../assets/images/close.svg"
import { images } from "@utils/imageUtils"
import { SectionListStyles } from "../../gamePlansCard/suggestionsList/suggestionListStyles"

import {
  selectAvaliableCalendars,
  selectCalendarPermissions,
  selectUserEmailCalendar,
} from "@selectors/calendar"
import { selectCalendarSyncSettings } from "@selectors/user"
import { changeSettings } from "@actions/settingsActions"
import {
  gotAvaliableCalendars,
  setCalendarPermissions,
} from "@actions/calendarActions"
import TimezoneSelect from "./timezoneSelect"
import {
  getCalendars,
  getSpecificCalendarByIdWithCalendars,
  handleAskCalendarPermissions,
  getCalendarEvents,
} from "@utils/calendarSyncUtils"
import { getDefaultTimezone } from "@utils/dateUtils"

import CalendarSettingsView from "./calendarSettingsView"

const noOp = () => {}

class CalendarSettingsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.calendarSyncSettings,
      defaultTimezone: getDefaultTimezone(),
      content: null,
    }
  }

  static options() {
    return {
      popGesture: false,
    }
  }

  componentDidMount() {
    const { syncTeeups } = this.props.calendarSyncSettings
    this.handleCheckPermissions(syncTeeups, true)
  }

  navigationButtonPressed({ buttonId }) {
    switch (buttonId) {
      case "backButton": {
        const { onCloseModal, componentId } = this.props
        onCloseModal(componentId)
        break
      }
      default:
    }
  }

  handleLoadUserCalendars = async () => {
    try {
      const calendars = await getCalendars()
      gotAvaliableCalendars(calendars)
    } catch (error) {
      gotAvaliableCalendars([])
      // console.log('CalendarSettingsContainer error: ', { error })
    }
  }

  handleCheckPermissions = async (syncTeeups, getCalendars) => {
    setCalendarPermissions(null)
    const permission = await handleAskCalendarPermissions(
      getCalendars ? this.handleLoadUserCalendars : noOp,
      !syncTeeups
    )
    setCalendarPermissions(permission)
  }

  onCalendarSelected = (componentId, calendar) => {
    this.onChange(calendar.id, "calendar")
    // pop(componentId)
  }

  onAdditionalSelected = (componentId, calendars) => {
    this.onChange(calendars, "additionalCalendars")
    // pop(componentId)
  }

  closeSelect = () => {
    this.setState({ content: null })
  }

  onTimezoneSelected = (componentId, timezoneName) => {
    const { defaultTimezone } = this.state
    const { customTimezone } = this.props.calendarSyncSettings
    if (timezoneName !== customTimezone) {
      this.onChange(
        defaultTimezone === timezoneName ? null : timezoneName,
        "customTimezone"
      )
    }
    if (componentId) this.setState({ content: null })
  }

  onCustomTimezone = () => {
    const {
      calendarSyncSettings: { customTimezone },
      componentId,
    } = this.props
    const { defaultTimezone } = this.state
    const props = {
      onSelected: this.onTimezoneSelected,
      selectedTimezone: customTimezone || defaultTimezone,
      tzAutodetected: !customTimezone,
      defaultTimezone: defaultTimezone,
      travelTimezone: defaultTimezone,
    }

    this.setState({ content: <TimezoneSelect {...props} /> })
  }

  onCalendarSelect = () => {
    const { componentId, calendarSyncSettings } = this.props
    // routeCalendarSelect(componentId, {
    //     onCalendarSelected: this.onCalendarSelected,
    //     calendarSelectedId: calendarSyncSettings.calendar,
    // })
  }

  onAdditionalCalendarsSelect = () => {
    const { componentId, calendarSyncSettings } = this.props
    // routeAdditionalCalendarSelect(componentId, {
    //     onCalendarSelected: this.onAdditionalSelected,
    //     calendarSelectedId: calendarSyncSettings.calendar,
    //     additionalCalendarsIds: calendarSyncSettings.additionalCalendars,
    // })
  }

  onChange = (value, settingName) => {
    const formattedSettings = {
      calendarSync: {
        ...this.props.calendarSyncSettings,
        [settingName]: value,
      },
    }
    if (settingName === "syncTeeups" && value) {
      this.handleCheckPermissions(true, false)
    } else if (
      (settingName === "calendar" &&
        value !== this.props.calendarSyncSettings.calendar) ||
      (settingName === "additionalCalendars" &&
        value.join() !==
          this.props.calendarSyncSettings.additionalCalendars.join())
    ) {
      getCalendarEvents({})
    }
    changeSettings(formattedSettings)
  }

  componentWillUnmount() {
    const { calendar, syncOnlyAllSet, syncTeeups } =
      this.props.calendarSyncSettings
    if (
      this.state.calendar !== calendar ||
      this.state.syncOnlyAllSet !== syncOnlyAllSet ||
      this.state.syncTeeups !== syncTeeups
    ) {
      // Modified some settings related to calendar stuff. re-sync calendar teeup events
      // resyncCalendarTeeupEvents()
    }
  }

  componentDidUpdate(prevProps) {
    const { syncTeeups } = this.props.calendarSyncSettings

    if (prevProps.calendarSyncSettings.syncTeeups !== syncTeeups) {
      if (syncTeeups) {
        // Option toggled ON, resync all teeups into calendar
      } else {
        // Option toggled OFF, remove all teeups from calendar
      }
    }
  }

  render() {
    const {
      calendars,
      calendarSyncSettings: {
        calendar,
        additionalCalendars,
        customTimezone,
        syncOnlyAllSet,
        syncTeeups,
      },
      closeOption,
      email,
    } = this.props

    const { defaultTimezone, content } = this.state

    let calendarTitle = "Select"
    if (calendar) {
      let cal = getSpecificCalendarByIdWithCalendars(calendar, calendars)
      if (cal) {
        calendarTitle = cal.title
      }
    }

    return (
      <>
        {/* <div className="settings__top"> 
                    <TouchableOpacity
                        onPress={closeOption}
                    >
                        <Image
                            source={images.back}
                            style={SectionListStyles.cancelBtnImage}
                        />
                    </TouchableOpacity>
                    <span>Calendar &amp; Time-zones</span>
                    <span>Primary and display calendars</span>
                </div> */}
        {content && (
          <div
            className="settings__modal settings__modal-content"
            style={{ animationName: "none" }}
            onClick={(event) => event.stopPropagation()}
          >
            <div className="settings__top">
              {/* <img onClick={this.closeSelect} src={closeIcon}  alt="" style={{width: 20, height: 20}} /> */}
              <TouchableOpacity onPress={this.closeSelect}>
                <Image
                  source={images.back}
                  style={SectionListStyles.cancelBtnImage}
                />
              </TouchableOpacity>
              <span>Select Your Time Zone</span>
              <div></div>
            </div>
            {content}
          </div>
        )}
        <CalendarSettingsView
          calendarTitle={calendarTitle}
          additionalCalendarsAmount={additionalCalendars && additionalCalendars.length}
          syncTeeups={syncTeeups}
          syncOnlyAllSet={syncOnlyAllSet}
          customTimezone={customTimezone || `${defaultTimezone} (detected)`}
          tzAutodetected={!customTimezone}
          onChange={this.onChange}
          onCalendarSelect={this.onCalendarSelect}
          onAdditionalCalendarsSelect={this.onAdditionalCalendarsSelect}
          onCustomTimezone={this.onCustomTimezone}
          email={email}
          calendars={calendars}
        />
      </>
    )
  }
}
CalendarSettingsContainer.propTypes = {
  calendars: PropTypes.array,
  calendarSyncSettings: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
}

const mapStateToProps = (state) => {
  const calendarSyncSettings = selectCalendarSyncSettings(state)
  const calendars = selectAvaliableCalendars(state)
  const permission = selectCalendarPermissions(state)
  const email = selectUserEmailCalendar(state)

  return {
    calendarSyncSettings,
    calendars,
    permission,
    email,
  }
}

export default connect(mapStateToProps)(CalendarSettingsContainer)
