import { StyleSheet } from "react-native"
import * as defaultStyle from "../../style"
import { AppFonts, AppColors } from "@theme"

const STYLESHEET_ID = "stylesheet.calendar.header"

export default function (theme = {}) {
  const appStyle = { ...defaultStyle, ...theme }
  return StyleSheet.create({
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: "center",
    },
    roundBorder: {
      borderRadius: "34px",
      border: "solid 1px #e3e5e8",
    },
    monthText: {
      fontFamily: "greycliffcf-bold-salt",
      color: "#222222",
      margin: 10,
    },
    arrow: {
      padding: 10,
      ...appStyle.arrowStyle,
    },
    arrowImage: {
      tintColor: appStyle.arrowColor,
    },
    disabledArrowImage: {
      tintColor: appStyle.disabledArrowColor,
    },
    week: {
      marginTop: 7,
      flexDirection: "row",
      justifyContent: "space-around",
    },
    dayHeader: {
      marginTop: 2,
      marginBottom: 0,
      width: 32,
      textAlign: "center",
      fontSize: 11,
      fontFamily: "greycliffcf-bold-salt",
      color: "#222222",
    },
    ...(theme[STYLESHEET_ID] || {}),
  })
}
