import {
  getTimezoneOffset,
  getCurrentTimezone,
  getTimezoneAbbreviation,
  getCountryForTimezone,
  dateToFormatTimezone,
  getTimezonesForCountry,
} from "./dateUtils"
import { contactTypes, parsedPhoneNumber } from "./contactUtils"
import ct from "countries-and-timezones"

let currentUserPhone = null
const US_COUNTRY_CODE = 1
export const minTime = 15
export const maxIndex = 1
export const getTimezones = () => ct.getAllTimezones()
export const addMeAsFirst = (participants, userInfo) => {
  participants = participants.filter(
    (participantId) => participantId !== userInfo.id
  )
  participants.unshift(userInfo.id)

  return participants
}

export const formatHours = () => {
  let hourElements = []
  let hourIndexes = []
  for (let i = 0; i < 24; i++) {
    let isAm = i < 12

    let hour = isAm ? i : i - 12
    let a = isAm ? "AM" : "PM"

    let line1 = "" + (hour === 0 ? 12 : hour)
    let line2 = a

    const { type } = checkIfWorkingHour(i)
    const { showIcon } = checkIfHourIconNeeded(i)

    hourElements.push({
      line1,
      line2,
      type,
      showIcon,
    })

    hourIndexes.push(i)
  }

  let items = [...hourIndexes]
  for (let i = 0; i < 365; i++) {
    items.push(...hourIndexes)
  }

  // let items = [...hourIndexes, ...hourIndexes] // fake items that refer to zones. should help pretending this is actually a carousel

  return { hourElements, hourIndexes, items }
}

export const PART_OF_THE_DAY = {
  Daytime: "Daytime",
  Night: "Night",
  InBetween: "InBetween",
}

export const checkIfWorkingHour = (hour) => {
  if (hour > 8 && hour < 17) {
    return { type: PART_OF_THE_DAY.Daytime, isWorking: true }
  } else if (hour > 20 || hour < 6) {
    return { type: PART_OF_THE_DAY.Night, isWorking: false }
  } else {
    return { type: PART_OF_THE_DAY.InBetween, isWorking: false }
  }
}

export const checkIfHourIconNeeded = (hour) => {
  if (hour === 21) {
    return { showIcon: PART_OF_THE_DAY.Night }
  }
  if (hour === 6) {
    return { showIcon: PART_OF_THE_DAY.Daytime }
  }
  return { showIcon: null }
}

export const formatTitle = (zone) => {
  let title = zone.names.reduce((value, name, index) => {
    // Show max 2 names
    if (index > maxIndex) {
      return value
    }

    if (index !== 0) {
      value += " and "
    }

    if (index === maxIndex) {
      value += zone.names.length - maxIndex + " more"
    } else {
      value += name
    }

    return value
  }, "")

  return title
}

export const formatInviteesForTimezones = (invitees) => {
  const currentTimezone = getCurrentTimezone()
  let formattedPeople = {}
  const formattedParticipants = invitees.map((invitee) => {
    let participant = { ...invitee }
    if (!participant.timezone) {
      if (participant.contactType === contactTypes.PHONE) {
        try {
          const phoneObject = parsedPhoneNumber(participant.value)
          const usePhoneTimezone = () => {
            if (phoneObject.country) {
              const timezones = getTimezonesForCountry(phoneObject.country)
              participant.timezone = timezones[0].name
            }
          }
          if (currentUserPhone && currentUserPhone !== "unknown") {
            const { countryCallingCode, nationalNumber } =
              parsedPhoneNumber(currentUserPhone)
            if (
              countryCallingCode === phoneObject.countryCallingCode &&
              (phoneObject.countryCallingCode !== US_COUNTRY_CODE ||
                (phoneObject.countryCallingCode === US_COUNTRY_CODE &&
                  phoneObject.nationalNumber.slice(0, 3) ===
                    nationalNumber.slice(0, 3)))
            ) {
              participant.timezone = currentTimezone
            } else usePhoneTimezone()
          } else usePhoneTimezone()
        } catch (error) {
          if (error) {
            console.log("Error ", error.message)
          } else {
            throw error
          }
        }
      }
    }
    // participant.timezone = 'America/New_York'
    formattedPeople[participant.id] = participant
    return participant.id
  })
  return { formattedPeople, formattedParticipants }
}

const timezoneOffsetByMyTimezone = (myZoneShiftExact, initialOffset) => {
  const timezoneDifference = Math.abs(
    Math.abs(myZoneShiftExact) - Math.abs(initialOffset)
  )

  if (
    (myZoneShiftExact > 0 && initialOffset > 0) ||
    (myZoneShiftExact < 0 && initialOffset < 0)
  ) {
    return myZoneShiftExact > initialOffset
      ? -timezoneDifference
      : timezoneDifference
  } else {
    const timezoneSummary = Math.abs(myZoneShiftExact) + Math.abs(initialOffset)

    return myZoneShiftExact > 0 && initialOffset < 0
      ? -timezoneSummary
      : timezoneSummary
  }
}

export const formatZones = (
  participants,
  people,
  userInfo,
  startDate,
  endDate,
  isCalculateOffsetByMyTimezone
) => {
  participants = addMeAsFirst(participants, userInfo)

  let myZoneShift = 0
  let myZoneShiftExact = 0
  let zonesMapping = {}
  let peopleWithUnknownTimeZones = []

  for (let i = 0, len = participants.length; i < len; i++) {
    let participant =
      participants.length === 1 ? userInfo : people[participants[i]]

    if (!participant) {
      continue
    }

    // In case we need to show contacts (non-coo-e users here)
    // if (participant.cooeUser) {
    //     participant = participant.cooeUser
    // }

    let zoneMapping

    let timezoneName = participant.timezone
    let name = participant.name || participant.label
    let participantId = participant.id
    const isMyTZ = participant.id === userInfo.id
    if (participant.id === userInfo.id) {
      name = "You"
      timezoneName = userInfo.timezone || getCurrentTimezone()
    }

    // If user doesn't have a timezone, don't include him in this view at all to not confuse others
    if (!timezoneName) {
      peopleWithUnknownTimeZones.push(participant)
      continue
    }

    let timezoneAbbreviation = getTimezoneAbbreviation(timezoneName)

    if (zonesMapping[timezoneAbbreviation]) {
      zoneMapping = zonesMapping[timezoneAbbreviation]
      zoneMapping.avatars.push(participant.avatar)
      zoneMapping.names.push(name)
      zoneMapping.participantsIds.push(participantId)
      zoneMapping.count++
      zoneMapping.isMyTZ = zoneMapping.isMyTZ || isMyTZ
    } else {
      let timezone = getTimezoneOffset(timezoneName) / -60

      // unparsed required for identification of 30 minutes timezones
      const timezoneUnparsed = timezone
      timezone = parseInt(timezoneUnparsed)
      let exactTimezone = Math.round(timezoneUnparsed * 2) / 2

      if (participant.id === userInfo.id) {
        myZoneShift = timezone
        myZoneShiftExact = exactTimezone
      }
      if (!isMyTZ && isCalculateOffsetByMyTimezone) {
        timezone = timezoneOffsetByMyTimezone(myZoneShiftExact, timezone)
      }
      let country = getCountryForTimezone(timezoneName) || timezoneName

      zonesMapping[timezoneAbbreviation] = {
        names: [name],
        participantsIds: [participantId],
        timezone,
        count: 1,
        timezoneName,
        country,
        abbreviation: timezoneAbbreviation,
        avatars: [participant.avatar],
        timezoneUnparsed,
        isMyTZ,
      }
    }
  }

  let zones = Object.keys(zonesMapping).map((key) => {
    let zone = zonesMapping[key]
    const { timezoneName } = zone

    zone.maxIndex = maxIndex
    zone.title = formatTitle(zone)
    if (startDate) {
      let time = dateToFormatTimezone(startDate, timezoneName)
      if (endDate) {
        time += "-" + dateToFormatTimezone(endDate, timezoneName)
      }

      zone.time = time
      zone.day = dateToFormatTimezone(startDate, timezoneName, "ddd")
    }

    return zone
  })

  return { zones, myZoneShift, myZoneShiftExact, peopleWithUnknownTimeZones }
}
