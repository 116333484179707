import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

const ViewProps = {
  navButtonColor: AppColors.brand.pink,
  avatarSize: Units.responsiveValue(64),
}

const ViewStyles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.brand.lightGrey,
    flex: 1,
    height: "94%",
  },
  tabbar: {
    backgroundColor: AppColors.brand.white,
  },
  userContainer: {
    flexDirection: "column",
    paddingHorizontal: Units.rem(1),
    paddingBottom: 11,
    backgroundColor: AppColors.brand.white,
  },
  userContainerInformation: {
    flexDirection: "column",
    marginLeft: Units.rem(0.875),
    flex: 1,
    marginTop: 6,
  },
  detailsBackground: {
    width: "100%",
    height: 44,
  },
  detailsBackgroundImage: {
    width: "100%",
    height: "100%",
  },
  nameText: {
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.blackTwo,
  },
  statusText: {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: "15px",
    letterSpacing: "-0.28px",
    color: "#767676",
  },
  linkText: {
    color: AppColors.brand.pink,
    ...AppFonts.mediumBold,
    marginTop: Units.rem(0.25),
  },
  flex: {
    flex: 1,
  },

  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  listHeader: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.mediumDemibold,
    paddingTop: Units.rem(0.75),
    paddingLeft: Units.responsiveValue(16),
    paddingBottom: Units.responsiveValue(9),
  },
  customSuggestionStyles: {
    marginHorizontal: Units.responsiveValue(16),
  },
  avatarAccesory: {
    top: Units.responsiveValue(44),
  },
})

const EmptyTabStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.warmGrey2,
    paddingBottom: Units.responsiveValue(8),
  },
})

export { ViewStyles, ViewProps, EmptyTabStyles }
