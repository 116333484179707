/**
 * App Theme - Colors
 *
 * React Native Starter App
 * https://github.com/mcnamee/react-native-starter-app
 */

const app = {
  background: "#E9EBEE",
  cardBackground: "#FFFFFF",
  backdropBackground: "rgb(61, 65, 86)",
  backdropCard: "rgb(107, 113, 142)",
  card1: "rgb(216, 239, 244)",
  card2: "rgb(243, 249, 252)",
  card1Grey: "rgb(235, 235, 235)",
  card2Grey: "rgb(249, 249, 249)",
  listItemBackground: "#FFFFFF",
  bgGradient1: "rgb(255, 145, 112)",
  bgGradient2: "rgb(244, 40, 98)",
  bgGradient21: "rgb(60, 177, 222)",
  bgGradient22: "rgb(60, 204, 184)",
  navbarButton: "rgb(244, 40, 98)",
  transparentOverlay: "rgba(4, 4, 15, 0.4)",
  transparent: "transparent",
  dialogBackgroundOverlay: "#000000AA",
}

const brand = {
  brand: {
    yellowBorder: "rgb(250, 170, 0)",
    thumbYellow: "rgb(255, 181, 32)",
    yellowBG: "rgb(255, 222, 155)",
    card2Grey5: "rgba(249, 249, 249, .50)",
    greyStatus_13: "rgba(61, 65, 86, 0.13)",
    darkGreyBackground: "rgb(228, 228, 228)",
    message: "rgb(196, 203, 206)",
    pinnedMessage: "rgb(243, 252, 254)",
    background: "rgb(65, 65, 65)",
    greyLight: "rgb(245, 246, 246)",
    secondary: "#17233D",
    grey: "rgb(224, 224, 224)",
    orange: "rgb(255, 111, 37)",
    orange2: "rgb(245, 166, 35)",
    orange3: "rgb(253, 123, 56)",
    green: "rgb(0, 197, 174)",
    green2: "rgb(23, 201, 180)",
    blue: "rgb(0, 145, 255)",
    blueTwo: "rgb(0, 122, 255)",
    darkBlue: "rgb(8, 95, 142)",
    darkBlue2: "rgb(74, 144, 226)",
    lightDarkBlue: "rgb(93, 153, 186)",
    blueThree: "rgb(25, 183, 234)",
    white20: "rgba(255, 255, 255, 0.2)",
    white12: "rgba(255, 255, 255, 0.12)",
    lightBlue: "rgb(177, 213, 219)",
    activeBlue: "rgb(65,155,249)",
    blue2: "rgb(0, 188, 246)",
    whiteGrey: "rgb(235, 235, 235)",
    shadowGrey: "rgb(137, 138, 141)",
    coolGrey: "rgb(137, 152, 158)",
    darkGrey: "rgb(62, 219, 147)",
    warmGrey: "rgb(127, 127, 127)",
    blueGrey: "rgb(89, 95, 130)",
    blueGrey2: "rgb(113, 131, 138)",
    warmGrey2: "rgb(157, 157, 157)",
    warmGrey3: "rgb(147, 147, 147)",
    warmGrey4: "#999999",
    blackTwo: "rgb(33, 33, 33)",
    red: "rgb(245, 45, 34)",
    pink: "rgb(244, 40, 98)",
    pink03: "rgba(244, 40, 98, 0.3)",
    pinkLight: "#F992AF",
    pinkLight2: "rgb(255, 235, 241)",
    pinkLight3: "rgb(251, 169, 192)",
    pinkBk: "#FFEBF1",
    pink70: "rgba(244, 40, 98, 0.7)",
    pink_15: "rgba(244, 40, 98, 0.15)",
    pink_16: "rgba(244, 40, 98, 0.16)",
    pink_40: "rgba(244, 40, 98, 0.4)",
    white: "rgb(255,255,255)",
    white95: "rgba(255, 255, 255, 0.95)",
    white70: "rgba(255, 255, 255, 0.7)",
    white50: "rgba(255, 255, 255, 0.5)",
    white100: "rgba(0, 0, 0, 0)",
    blueGray: "rgb(96, 106, 127)",
    blueGrayLight: "rgb(107, 120, 151)",
    blueGrayLightest: "rgb(107, 120, 151)",
    black: "rgb(0, 0, 0)",
    black2: "rgb(34, 34, 34)",
    black3: "#595f82",
    black10: "rgba(0, 0, 0, .1)",
    black25: "rgba(0, 0, 0, .25)",
    black30: "rgba(0, 0, 0, .3)",
    black40: "rgba(0, 0, 0, .4)",
    black50: "rgba(0, 0, 0, .5)",
    grey2: "rgb(225, 225, 225)",
    grey2_50: "rgba(225, 225, 225, 0.5)",
    purple: "rgb(82, 39, 166)",
    purple2: "rgb(89, 95, 130)",
    darkGrey2: "rgb(118, 118, 118)",
    darkGrey3: "rgb(74, 74, 74)",
    charcoalGrey: "rgb(69, 76, 82)",
    lightGrey: "rgb(240, 240, 240)",
    lightGrey_80: "rgba(240, 240, 240, 0.8)",
    lightGrey2: "rgb(226, 229, 231)",
    lightGrey3: "rgb(140, 152, 157)",
    lightGrey4: "rgb(242, 242, 242)",
    lightGrey5: "rgb(234, 234, 234)",
    lightGrey6: "rgb(202, 202, 202)",
    lightGrey230: "rgb(230, 230, 230)",
    lightBlue2: "rgb(224, 235, 241)",
    lightBlue3: "rgb(205, 242, 253)",
    lightBlue4: "rgb(225, 247, 254)",
    lightBlue5: "rgb(202, 234, 245)",
    lightBlue6: "rgb(191, 234, 246)",
    lightBlue7: "rgba(8, 95, 142, 0.5)",
    lightBlue22: "rgb(22, 192, 246)",
    lightBlue61: "rgb(61, 205, 245)",
    lightBlue116: "rgb(116, 169, 197)",
    grey3: "rgb(115, 132, 140)",
    grey4: "rgb(173, 177, 180)",
    superwhiteGrey: "rgb(250, 250, 250)",
    lightGreen: "rgb(0, 201, 174)",
    washedBlue: "#576085",
    blue3: "rgb(0, 134, 221)",
    red2: "rgb(255, 59, 48)",
    red3: "rgb(221, 33, 24)",
    red4: "rgb(224, 64, 100)",
    lightRed: "rgb(255, 201, 199)",
    blueEmerald: "rgb(0, 197, 174)",
    grey40: "rgba(4, 4, 14, 0.4)",
    grey51: "rgb(51, 51, 51)",
    grey62: "rgb(62, 62, 62)",
    grey77: "rgb(77, 77, 77)",
    grey105: "rgb(105, 105, 105)",
    grey112: "rgb(112, 112, 112)",
    grey134: "rgb(134, 134, 134)",
    grey138: "rgb(138, 138, 138)",
    grey142: "rgb(142, 142, 142)",
    grey144: "rgb(144, 144, 144)",
    grey151: "rgb(151, 151,151)",
    grey155: "rgb(155, 155, 155)",
    grey157: "rgb(157, 157, 157)",
    grey165: "rgb(165, 165, 165)",
    grey168: "rgb(168, 168, 168)",
    grey169: "rgb(169, 173, 178)",
    grey171_100: "rgba(171, 169, 162, 1)",
    grey185: "rgb(185, 187, 190)",
    grey193: "rgb(193, 193, 193)",
    grey196_40: "rgba(196, 203, 206, .4)",
    grey197: "rgba(197, 197, 197, 0.3)",
    grey204: "rgb(204, 204, 204)",
    grey203: "rgb(203, 203, 203)",
    grey209: "rgb(209, 209, 209)",
    grey212: "rgb(212, 212, 212)",
    grey214: "rgb(214, 214, 214)",
    grey216: "rgb(216, 216, 216)",
    grey217: "rgb(217, 217, 217)",
    grey227: "rgb(227, 227, 227)",
    grey230: "rgb(230, 240, 242)",
    grey243: "rgb(243, 243, 243)",
    grey247: "rgb(247, 247, 247)",
    grey251: "rgb(251, 251, 251)",
    grey244: "rgb(244, 244, 244)",
    grey238: "rgb(238, 238, 238)",
    lightWhite: "rgb(250, 250, 248)",
    darkGrey2_75: "rgba(118, 118, 118, .75)",
    darkGrey2_90: "rgba(118, 118, 118, .9)",
    darkGrey2_12: "rgba(118, 118, 118, 0.12)",
    black2_38: "rgba(34, 34, 34, .38)",
    black2_08: "rgba(34, 34, 34, .8)",
    black2_54: "rgba(34, 34, 34, .54)",
    blue2_20: "rgba(0, 188, 246, 0.2)",
    blue2_40: "rgba(0, 188, 246, 0.4)",
    darkPink: "rgb(162, 0, 56)",
    greyBlue_20: "rgba(115, 170, 180, 0.2)",
    greyBlue2_50: "rgba(183, 214, 219, 0.5)",
    green_40: "rgba(0, 197, 174, 0.4)",
    darkGrey2_20: "rgba(118, 118, 118, .2)",
    white_24: "rgba(0, 0, 0, .12)",
    warmGrey2_15: "rgba(157, 157, 157, 0.15)",
    warmGrey2_35: "rgba(157, 157, 157, 0.35)",
    warmGrey2_40: "rgba(157, 157, 157, 0.4)",
    warmGrey2_50: "rgba(157, 157, 157, 0.5)",
    purpleBlue: "rgb(60, 68, 111)",
    lightYellow: "rgb(255, 249, 240)",
    lightYellow2: "rgb( 255, 242, 221)",
    lightOrange: "rgb(255, 235, 203)",
    lightPink: "rgb(254, 224, 232)",
    listItemNoImageBg: "#fc766c",
    blurredBackgrond: "rgba(116,116,116,0.5)",
    infiniteBackground: "#0D0415",
    teeupListBackground: "#EBEBEB",
  },
}

const status = {
  teeup: {
    planning: brand.brand.orange,
    allset: brand.brand.green,
    happeningSoon: brand.brand.purple,
    happening: brand.brand.blue,
    ended: brand.brand.warmGrey,
    cancelled: brand.brand.red,
    droppedout: brand.brand.warmGrey,
  },
  user: {
    going: brand.brand.green,
    notgoing: brand.brand.red,
    mightgo: brand.brand.orange,
    invited: brand.brand.warmGrey2,
    joined: brand.brand.warmGrey,
    onmyway: brand.brand.purple,
    arrived: brand.brand.blue2,
    coolIdea: brand.brand.blue2,
    attended: brand.brand.darkGrey2,
    droppedout: brand.brand.darkGrey2,
    runningLate: brand.brand.orange,
    backedOut: brand.brand.red,
  },
}

const text = {
  textPrimary: "#222222",
  textSecondary: "#777777",
  headingPrimary: brand.brand.primary,
  headingSecondary: brand.brand.primary,
}

const borders = {
  border: "#D0D1D5",
}

const tabbar = {
  tabbar: {
    background: "#ffffff",
    iconDefault: brand.brand.blackLight,
    iconSelected: brand.brand.black,
  },
}

export default {
  ...app,
  ...brand,
  ...text,
  ...borders,
  ...tabbar,
  status,
}
