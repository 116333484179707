import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units, AppSizes } from "@theme"

export default StyleSheet.create({
  templateMessageContainer: {
    width: AppSizes.screen.widthThreeQuarters,
    backgroundColor: AppColors.brand.lightBlue4,
    paddingLeft: Units.responsiveValue(3),
    paddingTop: Units.responsiveValue(7),
    paddingBottom: Units.responsiveValue(10),
    flexDirection: "row",
  },
  descriptionWrapper: {
    flex: 1,
    marginLeft: Units.responsiveValue(12),
    minWidth: Units.responsiveValue(150),
    marginRight: Units.responsiveValue(8),
  },
  uploadText: {
    ...AppFonts.smallishMedium,
    lineHeight: Units.responsiveValue(21),
    color: AppColors.brand.darkBlue,
  },
  fileText: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    ...AppFonts.biggerMedium,
    lineHeight: Units.responsiveValue(21),
    color: AppColors.brand.darkBlue,
  },
  fileSendFailWrapper: {
    width: Units.responsiveValue(48),
    height: Units.responsiveValue(48),
    alignItems: "center",
    paddingTop: Units.responsiveValue(8),
  },
  retryText: {
    ...AppFonts.biggerMedium,
    lineHeight: Units.responsiveValue(21),
    color: AppColors.brand.pink,
  },
})
