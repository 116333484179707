import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"

const Styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  clickableRowStyles: {
    alignItems: "center",
    paddingHorizontal: Units.responsiveValue(9),
    paddingVertical: Units.responsiveValue(12),
    // backgroundColor: AppColors.brand.white,
    // borderBottomWidth: Units.responsiveValue(1),
    borderColor: AppColors.brand.lightGrey,
  },
  leftBlockWrapper: {
    flex: 1,
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  detector: {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: "17px",
    lineHeight: 0.76,
    letterSpacing: "-0.34px",
    color: "#222222",
  },
  calendarText: {
    fontFamily: "greycliff-cf-medium",
    fontSize: "15px",
    lineHeight: 1.47,
    letterSpacing: "-0.3px",
    color: "#222222",
  },
  rightBlockWrapper: {
    width: Units.responsiveValue(74),
    justifyContent: "center",
    alignItems: "flex-end",
  },
  iconBlockWrapper: {
    width: Units.responsiveValue(18),
    height: Units.responsiveValue(18),
    marginRight: Units.responsiveValue(10),
    paddingTop: Units.responsiveValue(4),
  },
  icon: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(14),
    tintColor: AppColors.brand.black3,
  },
  separatorPadding: {
    height: Units.responsiveValue(4),
  },
  textPrimary: {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 13,
    lineHeight: "21px",
    letterSpacing: "-0.26px",
    color: "#212121",
  },
  textSecondary: {
    ...AppFonts.biggerMedium,
    letterSpacing: -Units.responsiveValue(0.22),
    color: AppColors.brand.grey157,
  },
  textDetails: {
    ...AppFonts.smallishMedium,
    letterSpacing: -Units.responsiveValue(0.17),
    color: AppColors.brand.grey157,
  },
  textIcon: {
    marginLeft: Units.responsiveValue(4),
    width: Units.responsiveValue(17),
    height: Units.responsiveValue(17),
  },
  arrowButtonImage: {
    width: Units.responsiveValue(7),
    height: Units.responsiveValue(14),
    tintColor: AppColors.brand.grey214,
  },
  plusButtonImage: {
    width: Units.responsiveValue(21),
    height: Units.responsiveValue(21),
  },
})

export const selectedButtonImage = (color) => ({
  width: Units.responsiveValue(17),
  height: Units.responsiveValue(17),
  tintColor: color,
})

export default Styles
