import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import "./index.scss"
import cn from "classnames"
import { useHistory } from "react-router-dom"
import { images } from "@utils/imageUtils"
import cancel from "../../assets/images/cancel.png"
import {
  sendPasswordRecoveryLink,
  recoveryRequest,
} from "@actions/loginActions"
import VerifyCode from "./VerifyCode/"
import { validateEmailPhone } from "@utils/dataUtils"

let propsForVerify = {
  type: "email",
  id: -1,
  userId: -1,
  deviceId: null,
  value: "",
}

const MAX_PHONE_EMAIL_SIZE = 64

const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [isSent, setIsSent] = useState(false)
  const [step, setStep] = useState(1)
  const [contanctVerification, setContactVerification] = useState()
  const history = useHistory()

  const clearInput = () => {
    setEmail("")
  }

  const previousStep = () => setStep(step - 1)

  const handleChangeEmailPhone = (event) => {
    const { value } = event.target
    setEmail(value)
  }

  const handleClickVerify = (event) => {
    event.preventDefault()
    setError("")

    const isValid = validateEmailPhone(email)

    if (isValid) {
      let value = { email: email }
      if (email.split("")[0] === "+") {
        value = { phone: email }
      }
      const deviceId = uuidv4()

      recoveryRequest(value, deviceId)
        .then((response) => {
          setContactVerification(response.data.contactMechanismVerification.id)
          propsForVerify = { ...propsForVerify, value: value.email }
          setStep(2)
        })
        .catch((error) => {
          const { status } = error.response

          if (status === 404) {
            setError(
              `We couldn’t find a Coo-e account associated with ${email}`
            )
          } else {
            setError("Invalid contact address")
          }
        })
      // .then((response) => {
      //   const { status, message, result } = response.data;

      //   if (status === 409) {
      //     setError(message)
      //   } else {
      //     const state = {
      //       id: result[key].id,
      //       value: email || phone,
      //       deviceId,
      //       userId: result['userId'],
      //       type
      //     };

      //     moveToNextStep(state)
      //   }
      // });
      // })
      // .catch((error) => {
      //   const { message } = error.response.data;
      //   if (message) {
      //     setError(message)
      //   } else {
      //     setError('Please enter a valid email address');
      //   }
      // })
    } else {
      setError("Invalid value. Please enter a correct one")
    }
  }

  const handleClickSendRecoveryLink = (event) => {
    event.preventDefault()

    if (!email.trim().length) {
      return
    }

    sendPasswordRecoveryLink(email)
      .then(() => {
        setIsSent(true)
      })
      .catch((error) => {
        const { status } = error.response

        if (status === 404) {
          setError(`We couldn’t find a Coo-e account associated with ${email}`)
        } else {
          setError("Invalid email address")
        }
      })
  }

  const metricPhoneEmail = () => {
    const size = email.length
    if (size >= 0.85 * MAX_PHONE_EMAIL_SIZE) return "high"
    else if (size >= 0.7 * MAX_PHONE_EMAIL_SIZE) return "medium"
    else return ""
  }

  return (
    <>
      {step === 1 ? (
        <div className="forgot__page">
          <div className="forgot__content">
            <img
              className="forgot__icon"
              src={images.cooeModal}
              alt="cooe icon"
            />
            {!isSent ? (
              <>
                <span className="forgot__title">Account Recovery</span>
                <p className="forgot__subtitle">
                  First, let's find your account by entering your email address
                  or phone number
                </p>

                <form onSubmit={handleClickVerify}>
                  <div className="forgot__input--wrapper">
                    <label htmlFor="input" className="forgot__label">
                      Email or Phone
                    </label>
                    <div className="forgot__input--field">
                      <input
                        id="input"
                        value={email}
                        onChange={handleChangeEmailPhone}
                        placeholder="Enter an email or phone number"
                        className={cn("login__input forgot__input", {
                          "forgot__input--error": error,
                        })}
                        type="text"
                        maxLength={MAX_PHONE_EMAIL_SIZE}
                      />
                      {email.trim().length > 0 && (
                        <img
                          onClick={clearInput}
                          className="forgot__cancel"
                          src={cancel}
                          alt=""
                        />
                      )}
                    </div>
                    <p
                      className={`forgot__counter ${metricPhoneEmail()}`}
                    >{`${email.length}/${MAX_PHONE_EMAIL_SIZE}`}</p>
                    <p className="forgot__error">{error}</p>
                  </div>
                  <button
                    className={cn("forgot__button", {
                      "forgot__button--disabled": email.trim().length === 0,
                    })}
                    disabled={email.trim().length === 0}
                    onClick={handleClickVerify}
                  >
                    Send me a recovery code
                  </button>
                </form>
              </>
            ) : (
              <>
                <span className="forgot__title">Recovery Link Sent</span>
                <p className="forgot__subtitle">
                  {`We sent a password recovery link to ${email}. Please locate the email and click the link to reset your password.`}
                </p>
                <div
                  className="forgot__button"
                  onClick={() => history.push("/sign-in")}
                >
                  Return Home
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <VerifyCode
          forgotPassword
          resendCodeFn={handleClickVerify}
          contanctVerification={contanctVerification}
          email={email}
          previousStep={previousStep}
          {...propsForVerify}
        />
      )}
    </>
  )
}

export default ForgotPassword
