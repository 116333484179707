import React, { Component } from "react"
import { View } from "react-native"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactDOM from "react-dom"
// import { dismissModal } from '@nav'

import { selectUserId } from "@selectors/user"
import { selectContacts } from "@selectors/contacts"
import {
  getFormattedPhoneBook,
  CONTACT_PERMISSION_DENIED,
} from "@utils/contactUtils"
import * as contactActions from "@actions/contactActions"
// import { syncContacts } from '@apis/backend'
import EmptyView from "@ui/emptyView"
import ViewWithHeader from "@ui/viewWithHeader"
import { images } from "@utils/imageUtils"
import ContactsContainer from "@components/AddInvitees"
import styles from "./styles"

class Contacts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredContacts: null,
      searchText: "",
      tags: [],
      selectedTags: [],
      loading: false,
      skipContactsLoading: false,
      isContactFilterOpened: false,
    }
  }

  // onClose = () => {
  //     dismissModal(this.props.componentId)
  // }

  handleSyncContacts = async () => {
    // TODO: call proper RESYNC_CONTACTS_BOOK action
    const { userId } = this.props
    let phoneBook
    try {
      phoneBook = await getFormattedPhoneBook(true)
    } catch (err) {
      if (err === CONTACT_PERMISSION_DENIED) {
        return
      }
    }

    this.setState({ loading: true, skipContactsLoading: true })

    // await syncContacts({ userId, contacts: phoneBook }, userId)

    this.handleRefetchContacts().then(() => {
      this.setState({ loading: false })
    })
  }

  handleRefetchContacts = async () => {
    await contactActions.getContacts()
  }

  render() {
    const {
      componentId,
      isContactsSynced,
      setContent,
      close,
      fromContact,
      invitees,
      handleOnInviteesDone,
      combinedPeople,
      cooEVerse,
      fromCreateTeeup,
      setInvitees,
      inviteToNewTeeup,
      closeOptionWithAnimations,
      fromTeeupTooltip,
      isTeeupInvite,
    } = this.props
    const { loading, skipContactsLoading, isContactFilterOpened } = this.state

    if (!isContactsSynced) {
      return (
        <ViewWithHeader
          lightTheme
          rightButton={<View style={styles.stubRightButton}></View>}
          title={"My Contacts"}
          onBack={this.onClose}
        >
          <EmptyView
            title={"No connections yet!"}
            subtitle={"Sync your contacts to locate your friends"}
            imageSrc={images.syncContacts}
            btn={{
              title: "Sync your Contacts",
              // action: this.handleSyncContacts,
            }}
            loading={loading}
          />
        </ViewWithHeader>
      )
    }

    return (
      <ContactsContainer
        invitees={invitees || []}
        myContacts
        componentId={componentId}
        viewHeader={cooEVerse ? "Coo-e-verse Connections" : "Contact Book"}
        onHeaderLeft={this.onClose}
        lightTheme
        skipContactsLoading={skipContactsLoading}
        setContent={setContent}
        close={close}
        handleOnInviteesDone={handleOnInviteesDone}
        fromContact={fromContact}
        combinedPeople={combinedPeople}
        fromCreateTeeup={fromCreateTeeup}
        fromTeeupTooltip={fromTeeupTooltip}
        setInvitees={setInvitees}
        inviteToNewTeeup={inviteToNewTeeup}
        closeOptionWithAnimations={closeOptionWithAnimations}
        isTeeupInvite={isTeeupInvite}
      />
    )
  }
}

Contacts.propTypes = {
  userId: PropTypes.number.isRequired,
  isContactsSynced: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { isContactsSynced } = selectContacts(state)
  const userId = selectUserId(state)
  return {
    userId,
    isContactsSynced: true,
  }
}

export default connect(mapStateToProps)(Contacts)
