import React from "react"
import PropTypes from "prop-types"
import { View, Text, StyleSheet, Image } from "react-native"
import DetailGroup from "@ui/detailGroup"
import { AppColors, AppFonts, AppSizes, Units } from "@theme"
import { teeupStatusKeys, teeupUserStatusKeys } from "@configs/enums"
import { UserStatusButtonProps } from "../../teeupStyles"

const blockWidth = (360 - 8) / 3
const blockBorderRadius = 4

const styles = StyleSheet.create({
  wrapper456: {
    flexDirection: "row",
    // width: 3 * blockWidth + 8,
    height: Units.responsiveValue(56),
    alignItems: "center",
    // backgroundColor: AppColors.brand.grey243,
    borderTopRightRadius: blockBorderRadius,
    borderBottomLeftRadius: blockBorderRadius,
    borderBottomRightRadius: blockBorderRadius,
  },
  countText: {
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.grey77,
    letterSpacing: -Units.responsiveValue(0.5),
    lineHeight: Units.responsiveValue(24),
  },
  status: {
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: Units.responsiveValue(10),
    height: Units.responsiveValue(10),
    marginRight: Units.responsiveValue(2),
  },
})

const container = (showSecondRow) => ({
  flexDirection: "column",
  marginHorizontal: Units.responsiveValue(4),
  marginBottom: Units.responsiveValue(showSecondRow ? 4 : 0),
  flexWrap: "nowrap",
  justifyContent: "center",
  backgroundColor: AppColors.brand.white,
  borderRadius: showSecondRow ? 0 : blockBorderRadius,
  overflow: "hidden",
})

const wrapper1 = (showSecondRow) => ({
  width: blockWidth,
  height: Units.responsiveValue(showSecondRow ? 52 : 48),
  alignItems: "center",
  backgroundColor: showSecondRow
    ? AppColors.brand.grey243
    : AppColors.brand.white,
  borderTopLeftRadius: blockBorderRadius,
  borderTopRightRadius: blockBorderRadius,
  borderBottomLeftRadius: showSecondRow ? 0 : blockBorderRadius,
  borderBottomRightRadius: showSecondRow ? 0 : blockBorderRadius,
  overflow: "hidden",
})

const wrapper23 = (showSecondRow) => ({
  // width: blockWidth * 2 + 8,
  height: Units.responsiveValue(52),
  backgroundColor: showSecondRow
    ? AppColors.brand.grey243
    : AppColors.brand.white,
})

const wrapper23Inner = (showSecondRow) => ({
  flexDirection: "row",
  width: "100%",
  height: "100%",
  backgroundColor: AppColors.brand.white,
  borderBottomLeftRadius: showSecondRow ? blockBorderRadius : 0,
  overflow: "hidden",
})

const statBlock = ({ hasLeftMargin, isSmaller = false, isWhite }) => ({
  width: "109px",
  // width: isSmaller ? blockWidth - 4 : blockWidth,
  marginLeft: hasLeftMargin ? Units.responsiveValue(4) : 0,
  height: 44,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: isWhite ? AppColors.brand.white : AppColors.brand.grey243,
  borderRadius: blockBorderRadius,
  overflow: "hidden",
})

const statBlockInner = (isDimmed) => ({
  alignItems: "center",
  justifyContent: "center",
  opacity: isDimmed ? 0.5 : 1,
})

const statusText = (color) => ({
  ...AppFonts.smallestExtrabold,
  letterSpacing: -Units.responsiveValue(0.22),
  textAlign: "center",
  color,
})

const userStatusImage = (userStatus) => {
  const props = UserStatusButtonProps.find((prop) => prop.status === userStatus)
  if (!props) {
    return null
  }
  return props.image
}

const userStatusColor = (userStatus) => {
  const props = UserStatusButtonProps.find((prop) => prop.status === userStatus)
  if (!props) {
    return null
  }
  return props.color
}

const StatsBlock = ({ blockStyleParam, count, status, text, isDimmed }) => {
  const statusColor = userStatusColor(status)
  return (
    <View style={statBlock(blockStyleParam)}>
      <View style={statBlockInner(isDimmed)}>
        <Text style={styles.countText}>{count || "-"}</Text>
        <View style={styles.status}>
          <Image
            source={userStatusImage(status)}
            resizeMode="contain"
            style={[styles.image, { tintColor: statusColor }]}
          />
          <Text style={statusText(statusColor)}>{text}</Text>
        </View>
      </View>
    </View>
  )
}

StatsBlock.propTypes = {
  blockStyleParam: PropTypes.object,
  count: PropTypes.number,
  status: PropTypes.string,
  text: PropTypes.string,
  isDimmed: PropTypes.bool,
}

const StatsBlocks = (props) => {
  const {
    participantsCount,
    joinedCount,
    goingCount,
    arrivedCount,
    onMyWayCount,
    mightGoCount,
    notGoingCount,
    runningLateCount,
    backedOutCount,
    teeupStatus,
  } = props

  const totalGoingCount =
    goingCount + arrivedCount + onMyWayCount + runningLateCount // || backedOutCount > 0
  const isHappeningNowOrEnded = [
    teeupStatusKeys.happening,
    teeupStatusKeys.ended,
  ].includes(teeupStatus)
  const showSecondRow = totalGoingCount > goingCount || isHappeningNowOrEnded

  return (
    <DetailGroup
      title="INFO"
      secondaryTitle={`${participantsCount} invited / ${joinedCount} joined`}
      noPadding={true}
    >
      <View style={container(showSecondRow)}>
        {/* <View style={wrapper1(showSecondRow)}> */}
        {/* <StatsBlock
                        blockStyleParam={{ hasLeftMargin: false }}
                        count={totalGoingCount}
                        status={teeupUserStatusKeys.going}
                        text={showSecondRow ? 'TOTAL GOING' : 'GOING'}
                        isDimmed={totalGoingCount === 0}
                    /> */}
        {/* </View> */}
        <View style={wrapper23(showSecondRow)}>
          <View style={wrapper23Inner(showSecondRow)}>
            <StatsBlock
              blockStyleParam={{ hasLeftMargin: false }}
              count={totalGoingCount}
              status={teeupUserStatusKeys.going}
              text={showSecondRow ? "TOTAL GOING" : "GOING"}
              isDimmed={totalGoingCount === 0}
            />
            <StatsBlock
              blockStyleParam={{ hasLeftMargin: true }}
              count={mightGoCount}
              status={teeupUserStatusKeys.mightgo}
              text="MIGHT GO"
              isDimmed={mightGoCount === 0 || isHappeningNowOrEnded}
            />
            <StatsBlock
              blockStyleParam={{ hasLeftMargin: true }}
              count={notGoingCount}
              status={teeupUserStatusKeys.notgoing}
              text="NOT GOING"
              isDimmed={notGoingCount === 0 || isHappeningNowOrEnded}
            />
          </View>
        </View>
        {showSecondRow > 0 && (
          <View style={styles.wrapper456}>
            <StatsBlock
              blockStyleParam={{
                hasLeftMargin: false,
                isSmaller: true,
                // isWhite: true,
              }}
              count={onMyWayCount}
              status={teeupUserStatusKeys.onmyway}
              text="ON MY WAY"
              isDimmed={onMyWayCount === 0}
            />
            <StatsBlock
              blockStyleParam={{
                hasLeftMargin: true,
                isSmaller: false,
                // isWhite: true,
              }}
              count={runningLateCount}
              status={teeupUserStatusKeys.runningLate}
              text="RUNNING LATE"
              isDimmed={runningLateCount === 0}
            />
            <StatsBlock
              blockStyleParam={{
                hasLeftMargin: true,
                isSmaller: true,
                // isWhite: true,
              }}
              count={arrivedCount}
              status={teeupUserStatusKeys.arrived}
              text="ARRIVED"
              isDimmed={arrivedCount === 0}
            />
          </View>
        )}
        {false && backedOutCount > 0 && (
          <View style={statBlock}>
            <Text style={styles.countText}>{backedOutCount}</Text>
            <Text style={statusText(AppColors.status.user.backedOut)}>
              BACKED OUT
            </Text>
          </View>
        )}
      </View>
    </DetailGroup>
  )
}

StatsBlocks.propTypes = {
  participantsCount: PropTypes.number,
  joinedCount: PropTypes.number,
  goingCount: PropTypes.number,
  arrivedCount: PropTypes.number,
  onMyWayCount: PropTypes.number,
  mightGoCount: PropTypes.number,
  notGoingCount: PropTypes.number,
  runningLateCount: PropTypes.number,
  backedOutCount: PropTypes.number,
  teeupStatus: PropTypes.string,
}

export default StatsBlocks
