import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import _ from "lodash"

import { selectCalendarForWeek } from "../../../selectors/calendar"
import { getEventsLayoutForDay } from "@utils/calendarUtils"
import {
  generateDaysOfTheWeek,
  getDateWithTimezone,
  composeDatetime,
} from "@utils/dateUtils"
import { Units } from "../../../theme/"

import WeekCalendarTimetableView from "./WeekCalendarTimetableView"
import { openCreateTeeup } from "@actions/createTeeupActions"
import { locationTypeIds } from "@utils/gamePlanUtils"

const ROW_HEIGHT = Units.responsiveValue(50)

const PADDING_LEFT = 56

const daysOfTheWeek = generateDaysOfTheWeek()
const dayValuesBase = daysOfTheWeek.reduce((acc, cur) => {
  acc[cur] = {}
  return acc
}, {})

class WeekCalendarTimetableContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      totalWidth: 0,
      eventsList: [],
      ...dayValuesBase,
    }
    this.scrollViewContainer = React.createRef()
  }

  static getDerivedStateFromProps = (props, state) => {
    if (
      (props.eventsList && !_.isEmpty(props.eventsList)) ||
      props.eventsList !== state.eventsList
    ) {
      const dayValues = { ...dayValuesBase }
      const conflict = []
      Object.keys(props.eventsList).forEach((day) => {
        const { columnsAmount, mappedEventsList, conflictPosition } =
          getEventsLayoutForDay(props.eventsList[day])
        const weekday = getDateWithTimezone(day).format("dddd")
        dayValues[weekday] = { columnsAmount, mappedEventsList }
        conflict.push(conflictPosition)
      })

      return {
        ...dayValues,
        conflictPosition: _.min(conflict),
      }
    }
    return null
  }

  componentDidMount() {
    const { initScrollToConflict, scrollOffset } = this.props
    const { conflictPosition } = this.state
    let initOffset
    if (scrollOffset) {
      initOffset = scrollOffset
    } else if (initScrollToConflict && conflictPosition > 0) {
      initOffset = conflictPosition - 1
    } else {
      initOffset = 8
    }

    // setTimeout(() => {
    //     this.scrollViewContainer.current?.scrollTo({
    //         y: initOffset * ROW_HEIGHT,
    //         animated: false,
    //     })
    // }, 1)
  }

  // componentDidUpdate(prevProps) {
  //     const { scrollOffset } = this.props
  //     if (prevProps.scrollOffset !== scrollOffset) {
  //         setTimeout(() => {
  //             this.scrollViewContainer.current?.scrollTo({
  //                 y: scrollOffset * ROW_HEIGHT,
  //                 animated: false,
  //             })
  //         }, 1)
  //     }
  // }

  calculateBlocksWidth = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout
    this.setState({ totalWidth: width })
  }

  handleCreateTeeupFromCalendar = (calendarEvent) => async () => {
    const { id, attendees, startDate, endDate, location, recurrence } =
      calendarEvent
    const { currectUserId, selectedDate } = this.props

    const re = /↵|\n|\r/g
    const formattedLocation = location ? location.split(re) : null

    if (recurrence === "daily") {
      //workaround, because repeatable events have the same ID and the date of the latest repeatable event is applied
      startDate = composeDatetime(selectedDate, startDate)
      endDate = composeDatetime(selectedDate, endDate)
    }
    const whenSuggestion = {
      type: "when",
      optionId: 1,
      isCustomDate: false,
      isCustomTime: false,
      startDate,
      endDate,
      createdBy: currectUserId,
    }

    let whereSuggestion
    if (location || formattedLocation) {
      whereSuggestion = {
        type: "where",
        optionId: 2,
        isCustomDate: true,
        isCustomTime: true,
        locationTypeId: locationTypeIds.realWorld,
        createdBy: currectUserId,
        selected: false,
      }
    }
    const draftTeeup = {
      name: calendarEvent.name,
      message: calendarEvent.summary,
      whereSuggestions: whereSuggestion ? [whereSuggestion] : [],
      whenSuggestions: whenSuggestion ? [whenSuggestion] : [],
    }

    this.props.openCreateTeeup(draftTeeup)
  }

  handleSelectTimeSquare = ({ event, hour, meridiem }) => {
    const { weekdays, hasNewSuggestionProps, changeDate } = this.props
    const { totalWidth } = this.state
    const {
      nativeEvent: { pageX },
    } = event
    if (pageX <= PADDING_LEFT || !hasNewSuggestionProps) return null
    const itemWidth = totalWidth / 7
    const weekDaysArrayPosition = Math.floor((pageX - PADDING_LEFT) / itemWidth)
    const currentDay = weekdays[weekDaysArrayPosition]
    const hours =
      meridiem === "PM"
        ? hour === 12
          ? hour
          : hour + 12
        : hour === 12
        ? 0
        : hour

    const newSelectedStartDate = currentDay.set("hour", hours)

    const newStartDate = newSelectedStartDate.format()

    changeDate(newStartDate)
  }

  changeDuration = (obj) => {
    const { length, direction } = obj
    const {
      hasNewSuggestionProps,
      onDurationChange,
      changeDate,
      selectedDate,
      duration,
    } = this.props
    if (!hasNewSuggestionProps) return null

    const currentDay = getDateWithTimezone(selectedDate)
    let newDuration = duration

    let addAmount = length * 60
    if (direction === "top") addAmount *= -1
    newDuration += addAmount

    let newStartDate = currentDay.format()

    if (direction === "top") {
      const newSelectedStartDate = currentDay.add(length, "hours")
      newStartDate = newSelectedStartDate.format()
    }

    onDurationChange(newDuration)
    changeDate(newStartDate)
  }

  onScroll = ({ nativeEvent }) => {
    if (this.props.setOffset) {
      this.props.setOffset(nativeEvent.contentOffset.y / ROW_HEIGHT)
    }
  }

  render() {
    const { totalWidth, ...eventsList } = this.state
    const {
      weekdays,
      hasNewSuggestionProps,
      onPickTimerangeFromCalendar,
      duration,
      selectedDate,
    } = this.props
    return (
      <WeekCalendarTimetableView
        onSelectTimeSquare={this.handleSelectTimeSquare}
        hasNewSuggestionProps={hasNewSuggestionProps}
        onPickTimerangeFromCalendar={onPickTimerangeFromCalendar}
        totalWidth={totalWidth}
        calculateBlocksWidth={this.calculateBlocksWidth}
        onDurationChanged={this.changeDuration}
        eventsList={eventsList}
        weekdays={weekdays}
        scrollViewRef={this.scrollViewContainer}
        onScroll={this.onScroll}
        duration={duration}
        selectedDate={selectedDate}
        onCreateTeeup={this.handleCreateTeeupFromCalendar}
      />
    )
  }
}

WeekCalendarTimetableContainer.propTypes = {
  eventsList: PropTypes.object,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  weekdays: PropTypes.array,
  initScrollToConflict: PropTypes.bool,
  scrollOffset: PropTypes.number,
  setOffset: PropTypes.func,
  hasNewSuggestionProps: PropTypes.bool,
  onPickTimerangeFromCalendar: PropTypes.func,
  changeDate: PropTypes.func,
  onDurationChange: PropTypes.func,
  duration: PropTypes.number,
}

WeekCalendarTimetableContainer.defaultProps = {
  initScrollToConflict: false,
}

const mapStateToProps = (state, ownProps) => {
  const calendar = selectCalendarForWeek(state)(ownProps.selectedDate)

  return {
    eventsList: calendar,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openCreateTeeup }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekCalendarTimetableContainer)
