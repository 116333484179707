import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    //backgroundColor: AppColors.brand.lightGrey,
  },
  contentContainer: {
    marginTop: Units.responsiveValue(12),
    backgroundColor: AppColors.brand.white,
  },
  rowContainer: {
    flexDirection: "row",
    // marginTop: Units.responsiveValue(24),
    padding: Units.responsiveValue(18),
    alignItems: "center",
    //borderTopColor: AppColors.brand.lightGrey,
    borderTopWidth: 1,
  },
  stopContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.brand.red2,
    height: Units.responsiveValue(22),
    width: Units.responsiveValue(22),
    borderRadius: Units.responsiveValue(22),
  },
  stopContent: {
    backgroundColor: AppColors.brand.white,
    height: Units.responsiveValue(1),
    width: Units.responsiveValue(11),
  },
  image: {
    height: Units.responsiveValue(22),
    width: Units.responsiveValue(22),
    marginRight: 10,
  },
  contentText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.blackTwo,
    flex: 1,
    paddingLeft: Units.responsiveValue(18),
    // paddingBottom: Units.responsiveValue(4),
  },
  contentTextInput: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.blackTwo,
  },
  verifiedStyle: {
    width: Units.rem(5.5),
    height: Units.rem(2),
    borderRadius: Units.rem(1),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.brand.green,
  },
  unverifiedStyle: {
    width: Units.rem(5.5),
    height: Units.rem(2),
    borderRadius: Units.rem(1),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.brand.warmGrey2,
  },
  verifiedTextStyle: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.white,
  },
  verifiedIconStyle: {
    width: 12,
    height: 12,
    marginLeft: Units.rem(0.25),
  },
  disable: {
    opacity: 0,
  },
  locationContainer: {
    //backgroundColor: AppColors.brand.lightGrey,
  },
  locationRowContainer: {
    borderRadius: "8px",
    flexDirection: "row",
    backgroundColor: AppColors.brand.white,
    marginTop: Units.responsiveValue(12),
    paddingHorizontal: Units.responsiveValue(15),
  },
  locationHomeRowContainer: {
    flexDirection: "row",
  },
  locationRowTopLeftContainer: {
    marginRight: Units.responsiveValue(16),
    marginTop: Units.responsiveValue(16),
    alignItems: "center",
    justifyContent: "flex-start",
  },
  labelContainer: {
    paddingHorizontal: Units.responsiveValue(8),
    width: Units.responsiveValue(90),
    alignItems: "center",
  },
  labelText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.blackTwo,
    textAlign: "center",
  },
  descriptionContainer: {
    justifyContent: "center",
    paddingLeft: Units.responsiveValue(16),
    flex: 1,
  },
  descriptionText: {
    marginTop: Units.responsiveValue(8),
    ...AppFonts.mediumMedium,
    color: AppColors.brand.darkGrey2,
  },
  locationRowBottomContainer: {
    flexDirection: "row",
    borderTopWidth: 1,
    //borderColor: AppColors.brand.lightGrey,
  },
  distanceLabelContainer: {
    paddingLeft: Units.responsiveValue(16),
    width: Units.responsiveValue(150),
    paddingTop: Units.responsiveValue(11),
    paddingBottom: Units.responsiveValue(15),
  },
  distanceValueContainer: {
    paddingLeft: Units.responsiveValue(16),
    justifyContent: "center",
  },
  whiteBackground: {
    backgroundColor: AppColors.brand.white,
  },
  buttonsContainer: {
    borderColor: AppColors.brand.pink,
    borderWidth: 1,
    flexDirection: "row",
    borderRadius: Units.responsiveValue(4),
    marginVertical: Units.responsiveValue(8),
    marginRight: Units.responsiveValue(8),
  },
  button: {
    height: Units.responsiveValue(32),
    width: Units.responsiveValue(56),
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: AppColors.brand.pink,
    fontSize: Units.responsiveValue(17),
  },
  leftButton: {
    borderRightWidth: 1,
    borderColor: AppColors.brand.pink,
  },
  distanceContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  addItemRow: {
    marginTop: 24,
  },
})

export const saveButton = {
  fontFamily: "greycliffcf-medium-salt",
  fontSize: 16,
  color: "blue",
  cursor: "pointer",
}
