import _ from "lodash"
import actionTypes from "./actionTypes"
import endpoints from "../config/endpoints"
import { userTypes } from "../config/enums"
import api from "../api"
import * as teeUpActions from "./teeupActions"
import { formatInvitees, formatContacts } from "../utils/contactUtils"
import {
  mergeListItemsById,
  mergeListObjectItemsById,
  mapContactsToTags,
} from "../utils/contacts"
import { GAMEPLAN_OPTIONS, formatSuggestionOut } from "@utils/gamePlanUtils"
import { setTeeupActive } from "./activeTeeupActions"
import { store } from "../index"
import { selectAnyTeeupById } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { selectInvitees } from "@selectors/invitees"
import { setTitle } from "@actions/commonActions"
import { inviteToTeeup } from "@middlewares/backendMiddleware"
import { push } from "connected-react-router"
import { fetchBookContacts } from "@actions/contactActions"

export const closeCreateTeeup = () => ({
  type: actionTypes.CLOSE_CREATE_TEEUP,
})

export const openCreateTeeup = (draftTeeup = null) => ({
  type: actionTypes.OPEN_CREATE_TEEUP,
  payload: draftTeeup,
})

export const setDraftInvitees = (invitees) => ({
  type: actionTypes.SET_DRAFT_INVITEES,
  invitees,
})

export const setContacts = (invitees) => ({
  type: actionTypes.SET_CONTACTS,
  invitees,
})

export const getContacts = () => {
  return (dispatch) => {
    api.client
      .get(endpoints.teeup.getInviteeSuggestions)
      .then((res) => {
        const { data } = res
        dispatch(setContacts(data))
        getContactsWithTags()
        return data
      })
      .catch((err) => {
        console.log("error", err)
      })
  }
}

export const fetchUserTags = (userId) => {
  let url = endpoints.user.tags(userId)
  return api.client
    .get(url)
    .then((res) => {
      const { data } = res

      return data
    })
    .catch((err) => {
      console.log("error", err)
    })
}

export const getContactsWithTags = async (callback) => {
  const formatCooeUser = (user) => ({
    ...user,
    type:
      user.usertype !== userTypes.draft &&
      user.usertype !== userTypes.draftTellUs
        ? "cooeUser"
        : "contact",
  })
  const userId = selectUserId(store.getState())

  try {
    fetchBookContacts()
    let inviteeSuggestions = selectInvitees(store.getState())

    let tagsData = await fetchUserTags(userId)
    let tags = tagsData && tagsData.data ? tagsData.data : {}

    // Just in case backend fails
    let recent = []
    let frequent = []

    if (!_.isEmpty(inviteeSuggestions)) {
      frequent = inviteeSuggestions.mostTeeupedWithUsers.map(formatCooeUser)

      inviteeSuggestions.previousTeeups.forEach(
        ({ users, createdat, updatedat }) => {
          users.forEach((user) => {
            user = formatCooeUser(user)
            user.createdAt = createdat
            user.updatedAt = updatedat
            recent.push(user)
          })
        }
      )
      recent = mergeListItemsById(recent)
    }

    const allContacts = mergeListObjectItemsById({
      contactList: [],
      recent,
      frequent,
    })

    let byTags = mapContactsToTags(allContacts)

    tags.forEach((tag) => {
      if (!byTags[tag.id]) {
        byTags[tag.id] = []
      }
    })

    const recentTag = {
      count: recent.length,
      id: -100,
      value: "Recent",
    }

    const frequentTag = {
      count: frequent.length,
      id: -101,
      value: "Frequent",
    }

    tags.unshift(recentTag)
    tags.unshift(frequentTag)

    byTags[recentTag.id] = recent
    byTags[frequentTag.id] = frequent

    store.dispatch({
      type: actionTypes.GOT_CONTACTS,
      payload: {
        allContacts,
        recent,
        frequent,
        byTags,
        tags,
      },
    })

    // TODO: make allContacts an object mapping, and allContactsIndexes to not copy all structures all the time
    // TODO: add recent/frequent as normal custom tags

    //check callback, not all getContacts require promise callback
    callback && callback()
  } catch (error) {
    console.log("getContacts error", error)

    //check callback, not all getContacts require promise callback
    callback && callback(error)
  }
}

export const createTeeup = (
  userId,
  name,
  messages,
  invited,
  gamePlans,
  manuals,
  otherData
) => {
  return (dispatch) => {
    const formattedInvited = formatInvitees(invited)
    let gameplansFormatted = []

    gamePlans.forEach((gameplan) => {
      const { type, suggestions, availability, startDate, peopleCount } =
        gameplan
      let formattedGameplan = {
        icon: gameplan.icon,
        title: gameplan.title,
        type: gameplan.type,
        isPrimary: gameplan.isPrimary || false,
      }

      formattedGameplan.suggestions = []

      if (type === GAMEPLAN_OPTIONS.startsWhen) {
        const startsWhenStartDate = startDate
        const startsWhenPeopleCount = peopleCount
        let startsWhenAvailability = []
        if (availability && availability.length > 0) {
          startsWhenAvailability = [...availability]
        }
        formattedGameplan.suggestions = [
          {
            startDate: startsWhenStartDate,
            peopleCount: startsWhenPeopleCount,
            availability: startsWhenAvailability,
          },
        ]
      }
      if (type === GAMEPLAN_OPTIONS.whenWorks) {
        const updatedSuggestions = _.cloneDeep(suggestions)
        formattedGameplan.suggestions = updatedSuggestions.map(
          (suggestion) => ({
            ...suggestion,
            date: suggestion.date.parseZone
              ? suggestion.date.parseZone() // for moment format
              : suggestion.date,
          })
        )
      } else {
        if (suggestions && suggestions.length > 0) {
          let suggestion = formatSuggestionOut(suggestions[0], type, true)
          formattedGameplan.suggestions = [suggestion]
        }
      }
      gameplansFormatted.push(formattedGameplan)
    })

    api.client
      .post(endpoints.teeup.createTeeup, {
        ownerId: userId,
        name,
        messages,
        invited: formattedInvited,
        gameplanOptions: gameplansFormatted,
        ...otherData,
      })
      .then(async (res) => {
        const { data } = res
        const teeup = {}
        teeup.name = name
        teeup.invited = invited
        teeup.id = data.teeupId
        teeup.url = data.teeupUrl
        teeup.photo = otherData.photo
        teeup.createdat = new Date()
        teeup.ownerId = userId
        teeup.createdby = userId
        teeup.status = "planning"
        teeup.gameplans = gamePlans
        teeup.userStatus = "joined"

        const teeupExists = selectAnyTeeupById(Number(teeup.id))(
          store.getState()
        )
        if (!teeupExists) {
          store.dispatch(teeUpActions.createTeeup(teeup))
        }

        api.client.post(endpoints.teeup.inviteManual(data.teeupId), manuals)
        setTeeupActive(teeup)
        store.dispatch(setTitle(name))
        store.dispatch(push(`/teeUps/${teeup.id}`))
        const teeupUsers = await teeUpActions.fetchTeeupUsersById(
          Number(teeup.id)
        )
        store.dispatch(
          teeUpActions.updateGameplan(
            Number(teeup.id),
            await teeUpActions.fetchGameplanOptions(Number(teeup.id))
          )
        )
        store.dispatch(teeUpActions.gotTeeupParts([teeupUsers]))
      })
      .catch((err) => {
        console.log("error", err)
      })
  }
}
