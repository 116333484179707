import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { createUserTag, getUserTags, addTagsToUsers, removeTagsToUsers } from "@actions/userActions"
import { postSettings } from "../../actions/settingsActions"
import { resyncContactsBook } from "@actions/contactActions"
import { getContacts } from "@actions/createTeeupActions"

import { selectUserId, selectContactsSyncedAt, selectContactsSyncSettings } from "@selectors/user"

import './index.scss'

// const isCooeUser = (user) => user && (!!user.username || !!user.avatar)

const TagUser = ({
    close,
    selectedPeople,
    fromProfile,
    changeUserTags
  }) => {
  const [seachValue, setSeachValue] = useState('')
  const [tags, setTags] = useState(null)
  const [closeExpandModal, setCloseExpandModal] = useState(true)
  const [tagsToTag, setTagsToTag] = useState([])

  const contactSyncSettings = useSelector(selectContactsSyncSettings)
  const contactSyncedAt = useSelector(selectContactsSyncedAt)
  const userId = useSelector(selectUserId)

  useEffect(() => {
    if (fromProfile) {
      (async () => {
        const userTags = await getUserTags(selectedPeople[0].profile.id)
  
        setTags(userTags.data)
      })()
    }
  }, [])

  const filteredTags = useMemo(() => {
    if (tags) {
      if (seachValue.length < 1) {
        return tags
      }
  
      return tags.filter(item => item.value.includes(seachValue))
    }
  }, [seachValue, tags])

  const handleRefetchContacts = async () => {
    let allowSynchronization = contactSyncSettings.allowSynchronization

    if (!contactSyncedAt && !allowSynchronization) {
      const res = await postSettings({
        contacts: {
          ...contactSyncSettings,
          ...contactSyncSettings,
          allowSynchronization: true,
        },
      })
      allowSynchronization = res.contacts.allowSynchronization
    }
    resyncContactsBook()
  }

  const handleTagUsers = (selectedTags) => {
    if (fromProfile) {
      const userIdToTag = selectedPeople[0].profile.id;
      const selectedTagIds = [...selectedTags].map((tag) => tag.id)

      const body = {
        userIds: [userIdToTag],
        contactIds: [],
        tagIds: selectedTagIds,
      }

      addTagsToUsers(userId, body, handleRefetchContacts)
    }
  }

  const handleRemoveTag = (selectedTag) => {
    if (fromProfile) {
      const userIdToTag = selectedPeople[0].profile.id;

      const body = {
        userIds: [userIdToTag],
        contactIds: [],
        tagIds: [selectedTag.id],
      }

      removeTagsToUsers(userId, body, handleRefetchContacts)
      changeUserTags()
    }
  }
  
  const handleAddTagPress = async () => {
    if (fromProfile) {
      if (!tagsToTag.length) {
        handleTagUsers([await createUserTag(userId, seachValue)])
        changeUserTags()
      }
      if (tagsToTag.length) {
        handleTagUsers(tagsToTag)
        changeUserTags()
      }
    }
  }

  return (
    <div
      className="tag-user__wrapper"
      onClick={() => close()}
    >
      <div
        className="tag-user"
        onClick={(e) => {
          e.stopPropagation()
          setCloseExpandModal(true)
        }}
      >
        <h3 className="tag-user__title">Tag User</h3>
        <div
          className="tag-user__cross"
          onClick={() => close()}
        >
          <div
            className="tag-user__cross--icon"
          ></div>
        </div>
        <div
          className="tag-user__search"
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="text"
            className="tag-user__input"
            value={seachValue}
            onChange={(e) => {
              setSeachValue(e.target.value)
              setCloseExpandModal(false)
            }}
            placeholder="Add tag"
            onFocus={() => setCloseExpandModal(false)}
          />
          {seachValue.length > 0 && (
            <button
              className="tag-user__button"
              type="button"
              onClick={() => {
                handleAddTagPress()
                setCloseExpandModal(true)
                setSeachValue('')
              }}
            >
              Add Tag
            </button>
          )}
        </div>
        {!closeExpandModal && filteredTags && (
          <div className="tag-user__expanded-tags">
            {filteredTags.map(item => (
              <div
                className="tag-user__expanded-tags--item"
                key={item.id}
                onClick={() => {
                  setTagsToTag([item])
                  setSeachValue(item.value)
                  setCloseExpandModal(true)
                }}
              >
                {item.value}
              </div>
            ))}
          </div>
        )}
        <div className="tag-user__users">
          {fromProfile && (
            selectedPeople.map(item => (
              <div
                className="tag-user-item"
                key={item.tags.value}
              >
                <div
                  className="tag-user-item__avatar"
                  style={{ backgroundImage: `url(${item.profile.avatar})` }}
                ></div>
                <div className="tag-user-item__content">
                  <h4 className="tag-user-item__title">
                    {item.profile.name}
                  </h4>
                  <div className="tag-user-item__tags">
                    {item.tags.map(tag => (
                      <div className="tag-user-item__tag" key={tag.id}>
                        {tag.value}
                        <div
                          className="tag-user-item__delete"
                          onClick={() => handleRemoveTag(tag)}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        </div>
    </div>
  )
}

export default TagUser
