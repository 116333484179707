import React, { useState, useEffect } from "react"
import api from "../../api"
import endpoints from "@config/endpoints"

import moment from "moment"

import SignUpPreview from "./SignUpPreview"
import { images } from "@utils/imageUtils"

import { useParams } from "react-router-dom"

import { PreviewStyles } from "./PreviewStyles"
import "./index.scss"

const PreviewPage = () => {
  const [teeUpInfo, setTeeUpInfo] = useState(null)

  const { slug } = useParams()

  useEffect(() => {
    const previewUrl = endpoints.teeup.preview(slug)

    api.client.get(previewUrl).then((response) => {
      setTeeUpInfo(response.data.preview)
    })
  }, [])

  const getGamePlanWhen = () => {
    const gamePlanStartDate = moment(new Date(teeUpInfo.gameplan.when.startDate)).format("ddd MMM D h:mm A")
    const gamePlanEndDate = moment(new Date(teeUpInfo.gameplan.when.endDate)).format("h:mm A")

    return `${gamePlanStartDate} - ${gamePlanEndDate}`
  }

  return (
    <div className="preview" style={PreviewStyles.Preview}>
      <div className="preview__container">
        <div className="preview__wrapper" style={PreviewStyles.PreviewWrapper}>
          <div className="card__wrapper" style={PreviewStyles.CardWrapper}>
            <div className="preview__photo" style={PreviewStyles.CardAvatar}>
              {teeUpInfo && (
                <img
                  src={teeUpInfo.organizer.avatar}
                  style={{ width: 100, height: 100 }}
                />
              )}
            </div>
            {teeUpInfo && (
              <h3
                className="card__organizer"
                style={PreviewStyles.CardOrganizer}
              >
                {teeUpInfo.organizer.name} is organaizing:
              </h3>
            )}
            <div className="card__content" style={PreviewStyles.CardGameplan}>
              {teeUpInfo && (
                <>
                  <h3
                    className="card__organizer"
                    style={PreviewStyles.CardTitle}
                  >
                    {teeUpInfo.organizer.name}'s mitting with
                    {teeUpInfo.inviter.name}'s team
                  </h3>

                  <div className="preview__gameplan-item">
                    <div style={PreviewStyles.CardIconWrapper}>
                      <img src={images.iconWhenPreview} alt="When" />
                    </div>
                    <p style={PreviewStyles.CardPeopleJoin}>
                      {teeUpInfo.gameplan.when
                        ? getGamePlanWhen()
                        : "No Suggestions yet"}
                    </p>
                  </div>

                  <div className="preview__gameplan-item">
                    <div style={PreviewStyles.CardIconWrapper}>
                      <img src={images.iconWherePreview} alt="Where" />
                    </div>
                    <p style={PreviewStyles.CardPeopleJoin}>
                      {teeUpInfo.gameplan.where
                        ? teeUpInfo.gameplan.googlePlaceId
                          ? `${teeUpInfo.gameplan.where.value}, ${teeUpInfo.gameplan.where.details}`
                          : `${teeUpInfo.gameplan.where.value}`
                        : "No Suggestions yet"}
                    </p>
                  </div>

                  <h3
                    className="card__people"
                    style={PreviewStyles.CardPeopleCount}
                  >
                    {teeUpInfo.participantCount} people
                  </h3>
                  <div className="preview__gameplan-item">
                    <div style={PreviewStyles.CardIconWrapper}>
                      <img
                        src={images.iconPeoplePreview}
                        alt="People"
                        style={{ width: 17 }}
                      />
                    </div>
                    <p style={PreviewStyles.CardPeopleJoin}>Join to see</p>
                  </div>
                </>
              )}
            </div>
          </div>

          <SignUpPreview />
        </div>
        <div
          className="preview__download"
          style={PreviewStyles.DownloadContainer}
        >
          <h3 style={PreviewStyles.DownloadText}>Get the app</h3>
          <div className="preview__buttons" style={PreviewStyles.ButtonsWraper}>
            <a
              href="https://apps.apple.com/us/app/coo-e-coordinator/id1507948580"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={images.appStore}
                alt="Download on the App Store"
                style={PreviewStyles.AppStoreButtons}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.app.cooe&gl=UA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={images.googlePlay}
                alt="Get it on Google Play"
                style={PreviewStyles.GoogleButton}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewPage
