import React from "react"

import { ProfilePageProvider } from "./ProfilePageContext";
import ProfilePage from "./ProfilePage";

const ProfilePageWithContext = () => {
  return (
    <ProfilePageProvider>
      <ProfilePage />
    </ProfilePageProvider>
  )
}

export default ProfilePageWithContext

