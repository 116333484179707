import React, { useState } from "react"

import "./index.scss"
import ChangePassword from "./ChangePassword/"
import ChangeEmail from "./ChangeEmail/"
import {
  FaTwitter,
  FaApple,
  FaGoogle,
  FaFacebookF,
  FaRegEnvelope,
} from "react-icons/fa"
import { IconContext } from "react-icons"
import { images } from "@utils/imageUtils"

const LoginAccounts = ({ data }) => {
  const [content, setContent] = useState(false)

  let identities = data?.identities[0].title
  const accessToken = data?.accessToken
  return (
    <div className="forgot__page" style={{ flexDirection: "column" }}>
      <div className="forgot__content">
        {!content ? (
          <>
            <img className="forgot__icon" src={images.cooeModal} />
            <div className="forgot__loginAccounts_block">
              <div className="forgot__loginAccounts_block_wrapper">
                <IconContext.Provider
                  value={{ className: "forgot__loginAccounts_icon" }}
                >
                  <FaRegEnvelope />
                </IconContext.Provider>
                <div className="forgot__loginAccounts_block_info">
                  <div className="forgot__loginAccounts_block_info_title">
                    {identities}
                  </div>
                  <div className="forgot__loginAccounts_block_info_actions">
                    <button
                      className="forgot__loginAccounts_block_info_action"
                      style={{ marginRight: 10 }}
                      onClick={() =>
                        setContent(
                          <ChangePassword
                            data={data}
                            accessToken={accessToken}
                          />
                        )
                      }
                    >
                      Change Password
                    </button>
                    <button
                      className="forgot__loginAccounts_block_info_action"
                      onClick={() =>
                        setContent(<ChangeEmail accessToken={accessToken} />)
                      }
                    >
                      Change Email
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="forgot__loginAccounts_block">
              <div className="forgot__loginAccounts_block_wrapper">
                <IconContext.Provider
                  value={{ className: "forgot__loginAccounts_icon" }}
                >
                  <FaGoogle />
                </IconContext.Provider>
                <div className="forgot__loginAccounts_block_info">
                  <div className="forgot__loginAccounts_block_info_title">
                    username@gmail.com
                  </div>
                  <div className="forgot__loginAccounts_block_info_actions">
                    <button
                      className="forgot__loginAccounts_block_info_action"
                      style={{ marginRight: 10 }}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="forgot__loginAccounts_block">
              <div className="forgot__loginAccounts_block_wrapper">
                {/* <img src="" alt="" className="forgot__loginAccounts_block_icon" /> */}
                <IconContext.Provider
                  value={{ className: "forgot__loginAccounts_icon" }}
                >
                  <FaTwitter />
                </IconContext.Provider>
                <div className="forgot__loginAccounts_block_info">
                  <div className="forgot__loginAccounts_block_info_title">
                    Not Linked
                  </div>
                  <div className="forgot__loginAccounts_block_info_actions">
                    <button
                      className="forgot__loginAccounts_block_info_action"
                      style={{ marginRight: 10 }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="forgot__loginAccounts_block">
              <div className="forgot__loginAccounts_block_wrapper">
                <IconContext.Provider
                  value={{ className: "forgot__loginAccounts_icon" }}
                >
                  <FaFacebookF />
                </IconContext.Provider>
                <div className="forgot__loginAccounts_block_info">
                  <div className="forgot__loginAccounts_block_info_title">
                    username
                  </div>
                  <div className="forgot__loginAccounts_block_info_actions">
                    <button
                      className="forgot__loginAccounts_block_info_action"
                      style={{ marginRight: 10 }}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="forgot__loginAccounts_block">
              <div className="forgot__loginAccounts_block_wrapper">
                <IconContext.Provider
                  value={{ className: "forgot__loginAccounts_icon" }}
                >
                  <FaApple />
                </IconContext.Provider>
                <div className="forgot__loginAccounts_block_info">
                  <div className="forgot__loginAccounts_block_info_title">
                    username@gmail.com
                  </div>
                  <div className="forgot__loginAccounts_block_info_actions">
                    <button
                      className="forgot__loginAccounts_block_info_action"
                      style={{ marginRight: 10 }}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>{content}</>
        )}
      </div>
    </div>
  )
}

export default LoginAccounts
