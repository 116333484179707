import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export default StyleSheet.create({
  buttons: {
    flexDirection: "row",
    width: "100%",
    marginTop: Units.responsiveValue(16),
    marginHorizontal: Units.responsiveValue(-6),
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    borderRadius: 4,
    padding: Units.responsiveValue(10),
    marginHorizontal: Units.responsiveValue(6),
    flex: 1,
    backgroundColor: AppColors.brand.pink,
  },
  buttonImage: {
    marginRight: Units.responsiveValue(8),
    width: 20,
    height: 16,
  },
  buttonText: {
    fontFamily: "greycliffcf-demibold-salt",
    color: "white",
  },
  disabledButton: {
    // backgroundColor: AppColors.brand.lightGrey,
  },
  disabledButtonText: {
    color: AppColors.brand.warmGrey2,
  },
  outlineButton: {
    borderColor: AppColors.brand.washedBlue,
    borderWidth: 1,
    backgroundColor: "transparent",
  },
  outlineButtonText: {
    color: AppColors.brand.washedBlue,
  },
})
