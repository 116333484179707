import React from "react"

import TeeupSelectStatus from "../TeeupSelectStatus"
import { useTeeUpPage } from "../../TeeUpPageContext"
import {
  TeeupStateButtonMap,
  TeeupStateButtonList,
  MyStatusButtonList,
  AllUserStatusButtons,
} from "./statusButtons"
import { updateTeeupStatus } from "@middlewares/backendMiddleware"
import DropDown from "@components/TeeUpsOverview/DropDown"

import "./index.scss"

const TeeupStatus = () => {
  const { teeup, user } = useTeeUpPage()

  const handleTeeupStateClick = (statusId) => {
    // todo implement call and everything else
    console.log("handleTeeupStateClick ID >>>>", statusId, user.id)
    updateTeeupStatus(user.id, statusId)
  }

  const handleMyStatusClick = (id) => {
    // todo implement call and everything else
    console.log("handleTeeupStateClick ID >>>>", id)
  }

  return (
    <div className="teeup-status">
      <div className="teeup-status__teeup-state">
        <span className="teeup-status__label">Teeup Stage</span>
        {/* <TeeupSelectStatus
          title="MY SHARED STATUS"
          imageUrl={teeup?.photo || ""}
          status={teeup?.status || ""}
          buttonList={TeeupStateButtonList}
          handleStatusClick={handleTeeupStateClick}
          triggerButton={TeeupStateButtonMap[teeup?.status]}
        /> */}

        <DropDown teeUp={teeup} title={teeup.name} imgUrl={teeup.photo} />
      </div>
      <div className="teeup-status__my-status">
        <span className="teeup-status__label">My Status</span>
        <TeeupSelectStatus
          title="MY SHARED STATUS"
          imageUrl={user?.avatar || ""}
          status={teeup?.userStatus}
          buttonList={MyStatusButtonList}
          handleStatusClick={handleMyStatusClick}
          triggerButton={AllUserStatusButtons[teeup?.userStatus]}
        />
      </div>
    </div>
  )
}

export default TeeupStatus
