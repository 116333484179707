import React from "react"
import { View, Text, Image, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"
// import Collapsible from 'react-native-collapsible'
// import ParsedText from 'react-native-parsed-text'

import { isCooeUser } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"
import { isSyncedContact } from "@utils/contactUtils"
import { getContactName, escapeRegExp } from "@utils/dataUtils"
import ContactType from "./ContactType"
import CheckBox from "../../ui/checkbox"
import { Units } from "@theme"
import styles, { getExpandedStyles } from "./styles"

const MAX_TAGS_COUNT = 3
const noOp = () => {}

const renderTags = (tags) => {
  const tagsCount = tags.length
  if (tagsCount === 0) return null
  const tagsData = tags.map((tag, index) => {
    if (index === MAX_TAGS_COUNT) {
      const tagsLeft = tagsCount - index
      return (
        <Text key={`${tag} + ${index}`} style={styles.tagTitle}>
          {`+${tagsLeft}`}
        </Text>
      )
    } else if (index > MAX_TAGS_COUNT) return null
    return (
      <View style={styles.row} key={`${tag} + ${index}`}>
        <Image style={styles.userTagImage} source={images.tag} />
        <Text style={styles.tagTitle}>
          {" "}
          {tag.value}
          {index !== tagsCount - 1 ? ", " : null}
        </Text>
      </View>
    )
  })
  return (
    <View testID={"contact-tags"} style={styles.row}>
      {tagsData}
    </View>
  )
}

class ContactItem extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: props.data && props.data.selectedIndex,
    }
  }

  getParsedProps = () => ({
    parse: [
      {
        pattern: new RegExp(escapeRegExp(this.props.searchText), "gi"),
        style: styles.matchingContact,
      },
    ],
    selectable: true,
    childrenProps: {
      allowFontScaling: false,
    },
  })

  handleChangeContactType = (selectedIndex) => {
    const { data, onChangeContactType } = this.props
    const item = data.contactList[selectedIndex]
    this.setState({ selectedIndex })
    onChangeContactType({
      userType: data.userType,
      id: data.id,
      selectedContact: item,
      selectedIndex,
      data: data,
    })
  }

  render() {
    const {
      invited,
      searchText,
      expanded,
      data,
      onToggleContact,
      onToggleExpand,
      testID,
    } = this.props
    const { selectedIndex } = this.state

    if (!data) {
      return null
    }
    const cooeUser = isCooeUser(data)
    const hasExtraContact = data.contactList && data.contactList.length > 0
    const label = data.label || getContactName(data) || ""

    return (
      <>
        <View style={styles.container} testID={testID}>
          <TouchableOpacity
            style={styles.contactInfo}
            onPress={onToggleContact}
            testID={`${testID}-mainClickableArea`}
          >
            {data.avatar ? (
              <Image source={{ uri: data.avatar }} style={styles.avatar} />
            ) : (
              <View style={[styles.avatar, styles.inviteeIcon]}>
                <Text style={styles.inviteeInitial}>{label[0]}</Text>
              </View>
            )}
            <View style={styles.flexFull}>
              <View
                style={
                  isSyncedContact(data)
                    ? styles.contactLineColumn
                    : styles.contactLineRow
                }
              >
                <Text
                  style={styles.titleText}
                  testID={`${testID}-inviteeInitial`}
                  {...(searchText ? this.getParsedProps(false) : {})}
                >
                  {label}
                </Text>
                <View style={[styles.row, styles.contactTypeLine]}>
                  <ContactType
                    isCooeUser={cooeUser}
                    contactType={data.contactType}
                    label={data.contactLabel}
                  />
                  {hasExtraContact && (
                    <Image
                      style={getExpandedStyles(expanded)}
                      source={images.roundedTriangle}
                    />
                  )}
                </View>
              </View>
              {!hasExtraContact && data.username && (
                <Text style={styles.username}>@{data.username}</Text>
              )}
              {data.tags ? renderTags(data.tags) : null}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onToggleContact}
            style={styles.checkboxContainer}
            hitSlop={Units.hitSlop()}
            testID={`${testID}-secondaryClickableArea`}
          >
            <CheckBox isActive={invited} testID={`${testID}-invitedCheckbox`} />
          </TouchableOpacity>
        </View>
        {/* {hasExtraContact ? (
                    <Collapsible
                        collapsed={!expanded}
                        style={styles.extraContactsContainer}
                        testID={`${testID}-contactOptions`}
                    >
                        {data.contactList.map((item, index) => (
                            <TouchableOpacity
                                key={`contactInfo${index}`}
                                onPress={
                                    !cooeUser
                                        ? () =>
                                              this.handleChangeContactType(
                                                  index
                                              )
                                        : noOp
                                }
                                style={[
                                    styles.extraContactButton,
                                    index === 0 && styles.noBorder,
                                ]}
                                disabled={cooeUser}
                                testID={`${testID}-contactOption-${index}`}
                            >
                                <View style={styles.extraContact}>
                                    <ContactType
                                        contactType={item.contactType}
                                        label={item.label}
                                    />
                                    <ParsedText
                                        style={styles.extraContactValue}
                                        {...(searchText
                                            ? this.getParsedProps()
                                            : {})}
                                    >
                                        {item.value}
                                    </ParsedText>
                                </View>
                                {!cooeUser &&
                                invited &&
                                index === selectedIndex ? (
                                    <View
                                        style={styles.selectedIconContainer}
                                        testID={`${testID}-contact-method-${index}-selected`}
                                    >
                                        <Image
                                            source={require('../assets/selected.png')}
                                            style={styles.selectedIcon}
                                        />
                                    </View>
                                ) : null}
                            </TouchableOpacity>
                        ))}
                    </Collapsible>
                ) : null} */}
      </>
    )
  }
}

ContactItem.propTypes = {
  searchText: PropTypes.string,
  invited: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onToggleContact: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func,
  onChangeContactType: PropTypes.func,
  testID: PropTypes.string,
}

export default ContactItem
