import React, { useState } from "react"
import PropTypes from "prop-types"
import { View, Text, Platform } from "react-native"
// import { WheelPicker } from 'react-native-wheel-picker-simple'
import WheelPicker from "react-simple-wheel-picker"

import { DatePicker } from "@ui/scrollablePicker"
import { generateHoursArray, generateMinutesArray } from "@utils/dateUtils"
import { AppColors } from "@theme"
import DisabledDurationWheel from "./disabledDurationWheel"
import { ViewStyles, TimeDurationStyles } from "./timePickerOverlayStyles"

const hoursArray = generateHoursArray({ maxHour: 25 })
const formattedHoursArray = hoursArray.map((item, index) => ({
  id: String(index + 1),
  value: item,
}))
const minutesInterval = 5
const minutesArray = generateMinutesArray(minutesInterval)
const formattedMinutesArray = minutesArray.map((item, index) => ({
  id: String(index),
  value: item,
}))

const TimeDurationPicker = ({
  startDate,
  startTime,
  onStartTimeSelected,
  timezone,
  durationHoursIndex,
  durationMinutesIndex,
  onDurationHoursSelected,
  onDurationMinutesSelected,
  isDurationActive,
  onDurationSwitch,
  minutesPicker,
  hoursPicker,
}) => {
  const activeMinuteItem = formattedMinutesArray.find(
    (item) => Number(item.id) === durationMinutesIndex
  )
  const activeMinuteId = activeMinuteItem ? activeMinuteItem.id : 1

  return (
    <View style={TimeDurationStyles.container}>
      <View style={TimeDurationStyles.pickerContainer}>
        <View
          style={[
            TimeDurationStyles.rowContainer,
            ViewStyles.rowContainerBorder,
          ]}
        >
          <Text style={ViewStyles.rowPickerTitle}>Time</Text>
        </View>
        <View style={TimeDurationStyles.wheelContainer}>
          <DatePicker
            timezone={timezone}
            initDate={new Date(startTime)}
            minutesPicker={minutesPicker}
            hoursPicker={hoursPicker}
            mode={"time"}
            minuteInterval={5} // note: for some reason it uses 5-min intervals anyway
            onDateSelected={onStartTimeSelected}
            hideDate={true}
            startDate={new Date(startDate)}
            format24={false}
            style={
              Platform.OS === "ios"
                ? TimeDurationStyles.dateWheelContainer
                : TimeDurationStyles.dateWheelContainerAndroid
            }
          />
        </View>
      </View>
      <View style={TimeDurationStyles.pickerContainer}>
        <View
          style={[
            TimeDurationStyles.rowContainer,
            ViewStyles.rowContainerBottomBorder,
          ]}
        >
          <View>
            <Text style={TimeDurationStyles.durationPickerTitle}>Duration</Text>
          </View>
          {/* <Switch
                        circleStyle={TimeDurationStyles.switchCircle}
                        style={TimeDurationStyles.switchStyle}
                        backgroundActive={AppColors.brand.pink}
                        value={isDurationActive}
                        onSyncPress={onDurationSwitch}
                    /> */}
        </View>
        {!isDurationActive ? (
          <DisabledDurationWheel
            durationHoursIndex={durationHoursIndex}
            durationMinutesIndex={durationMinutesIndex}
          />
        ) : (
          <View
            style={[
              ViewStyles.multyPickerContainer,
              TimeDurationStyles.wheelContainer,
            ]}
          >
            {/* <View style={ViewStyles.multiPickerWheelContainer, {width: '40%', position:'relative'}}> */}
            <View style={ViewStyles.multiPickerWheelContainer}>
              <View style={TimeDurationStyles.durationWheelContainer}>
                <div className="wheel-picker">
                  <WheelPicker
                    selectedID={formattedHoursArray[durationHoursIndex].id}
                    data={formattedHoursArray}
                    height={175}
                    width={50}
                    itemHeight={40}
                    onChange={(target) => onDurationHoursSelected(target.value)}
                    color="#76767666"
                    activeColor="#222"
                    backgroundColor="#fff"
                    shadowColor="transparent"
                  />
                </div>
              </View>
              <View
                style={[
                  TimeDurationStyles.durationHoursContainer,
                  // {left: '40%'}
                ]}
              >
                <Text style={TimeDurationStyles.durationHoursText}>h</Text>
              </View>
            </View>
            {/* <View style={ViewStyles.multiPickerWheelContainer, {width: '60%'}}> */}
            <View style={ViewStyles.multiPickerWheelContainer}>
              <View style={TimeDurationStyles.durationWheelContainer}>
                <div className="wheel-picker">
                  <WheelPicker
                    selectedID={formattedMinutesArray[activeMinuteId].id}
                    data={formattedMinutesArray}
                    height={175}
                    width={50}
                    itemHeight={40}
                    onChange={(target) =>
                      onDurationMinutesSelected(target.id, minutesInterval)
                    }
                    color="#76767666"
                    activeColor="#222"
                    backgroundColor="#fff"
                    shadowColor="transparent"
                  />
                </div>
              </View>

              <View style={TimeDurationStyles.durationHoursContainer}>
                <Text style={TimeDurationStyles.durationHoursText}>min</Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

TimeDurationPicker.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onStartTimeSelected: PropTypes.func,
  timezone: PropTypes.string,
  onDurationHoursSelected: PropTypes.func,
  onDurationMinutesSelected: PropTypes.func,
  isDurationActive: PropTypes.bool,
  onDurationSwitch: PropTypes.func,
}

export default TimeDurationPicker
