import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme/"

const styles = StyleSheet.create({
  container: { flex: 1 },
  flatListContainer: { flex: 1 },
  listContentContainer: {
    flexGrow: 1,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyText: {
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.warmGrey2,
  },
  stubRightButton: {
    height: Units.rem(3.5),
    width: Units.rem(3.5),
  },
})

export default styles
