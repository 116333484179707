import React from "react"
import isEmpty from "lodash/isEmpty"
import propTypes from "prop-types"
import { View, Platform, StyleSheet } from "react-native"
// import MapView, { PROVIDER_GOOGLE, Marker } from 'react-native-maps'

// import backend from '@apis/backend'
import { calculateDeltasFromDistanceInMeters, DELTAS } from "@utils/location"
import { images } from "@utils/imageUtils"
import { Units, AppSizes } from "@theme"

const METERS_MULTIPLIER = 1000
const DEFAULT_RADIUS_IN_KM = 2

export default class MapPartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mapLoading: true,
      layoutFinished: false,
      markerData: {},
      mapRegion: {},
      googlePlaceId: props.googlePlaceId || null,
    }
    this.mapRef = React.createRef()
  }

  componentDidMount() {
    this.getMapCenterAndMarker()
  }

  componentDidUpdate() {
    const { mapRegion } = this.state
    const { coordinates } = this.props
    if (
      !isEmpty(mapRegion) &&
      !isEmpty(coordinates) &&
      (mapRegion?.latitude !== coordinates.lat ||
        mapRegion?.longitude !== coordinates.lng)
    ) {
      this.getMapCenterAndMarker()
    }
  }

  getMapCenterAndMarker = async () => {
    const { coordinates, googlePlaceId, details, placeName } = this.props
    let markerData, mapRegion, placeCords
    if (!isEmpty(coordinates)) {
      placeCords = coordinates
    } else if (googlePlaceId) {
      // const response = await backend.findByPlaceId(googlePlaceId)
      const response = []
      const item = response.results[0]
      placeCords = item.geometry.location
    }
    if (placeCords) {
      markerData = {
        location: {
          latitude: placeCords.lat,
          longitude: placeCords.lng,
        },
        title: placeName,
        description: details,
      }
      mapRegion = {
        latitude: placeCords.lat,
        longitude: placeCords.lng,
        ...DELTAS,
      }
      this.setState({ markerData, mapRegion }, this.animateToRegion)
    }
  }

  animateToRegion = () => {
    const { mapRegion, mapLoading } = this.state
    const distanceInMeters = DEFAULT_RADIUS_IN_KM * METERS_MULTIPLIER
    const { latitudeDelta, longitudeDelta } =
      calculateDeltasFromDistanceInMeters({
        distanceInMeters,
        currentLatitude: mapRegion.latitude,
      })
    !mapLoading &&
      latitudeDelta &&
      longitudeDelta &&
      this.mapRef.current.animateToRegion(
        {
          ...mapRegion,
          latitudeDelta,
          longitudeDelta,
        },
        1000
      )
  }

  handleMapLoad = () => {
    setTimeout(() => this.setState({ mapLoading: false }), 500)
  }

  handleLayout = () => {
    setTimeout(
      () => this.setState({ layoutFinished: true }, this.animateToRegion),
      500
    )
  }

  render() {
    const { mapLoading, layoutFinished, markerData, mapRegion } = this.state
    // const mapProvider = Platform.OS === 'android' ? PROVIDER_GOOGLE : null
    const isMarkerShown =
      layoutFinished &&
      !mapLoading &&
      !isEmpty(markerData) &&
      !isEmpty(mapRegion)
    if (isEmpty(mapRegion)) return null
    return (
      <View style={styles.container}>
        {/* <MapView
                    ref={this.mapRef}
                    provider={mapProvider}
                    initialRegion={mapRegion}
                    onMapReady={this.handleMapLoad}
                    style={styles.map}
                    onLayout={this.handleLayout}
                >
                    {isMarkerShown && (
                        <Marker
                            image={images.mapMarker}
                            coordinate={markerData.location}
                            title={markerData.title}
                            description={markerData.description}
                        />
                    )}
                </MapView> */}
        HEre must be map
      </View>
    )
  }
}
MapPartComponent.propTypes = {
  coordinates: propTypes.shape({
    lat: propTypes.number,
    lng: propTypes.number,
  }),
  placeName: propTypes.string.isRequired,
  details: propTypes.string,
  googlePlaceId: propTypes.string,
}

const styles = StyleSheet.create({
  container: {
    marginTop: Units.responsiveValue(64),
    minHeight: 1,
    width: AppSizes.screen.width,
    flex: 1,
  },
  map: {
    minHeight: 1,
    width: AppSizes.screen.width,
    flex: 1,
  },
})
