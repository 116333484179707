import React from "react"
import PropTypes from "prop-types"

import { View, Text, Image } from "react-native"
import { images } from "@utils/imageUtils"
// import i18n from '@i18n'

import { CriticalMassBarStyles } from "./participantsStyles"

const ParticipantsCriticalMassBar = ({ currentAmount, criticalMass }) => {
  const needed = criticalMass - currentAmount
  const progress =
    currentAmount > criticalMass
      ? 100
      : ((currentAmount / criticalMass) * 100).toFixed(2)
  const massAchieved = criticalMass <= currentAmount
  return (
    <View style={CriticalMassBarStyles.mainContainer}>
      <View style={CriticalMassBarStyles.progressbarContainer}>
        <View style={CriticalMassBarStyles.progressEmpty} />
        <View
          style={[
            CriticalMassBarStyles.progressFill,
            { width: `${progress}%` },
          ]}
        />
      </View>
      <View style={CriticalMassBarStyles.infoContainer}>
        <Text style={CriticalMassBarStyles.text}>
          {criticalMass || criticalMass === 0
            ? `It’s On with %${criticalMass}`
            : ""}
        </Text>
        {massAchieved ? (
          <Image
            source={images.checkWhiteIcon}
            style={[CriticalMassBarStyles.checkIcon]}
          />
        ) : (
          <Text style={CriticalMassBarStyles.text}>
            {needed || needed === 0 ? `%${needed} more needed!` : ""}
          </Text>
        )}
      </View>
    </View>
  )
}

ParticipantsCriticalMassBar.propTypes = {
  currentAmount: PropTypes.number,
  criticalMass: PropTypes.number,
}

export default ParticipantsCriticalMassBar
