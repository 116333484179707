import { useState, useEffect } from "react"
import Validator from "password-validator"
import { store } from "../../index"
import actionTypes from "@actions/actionTypes"

export const PASSWORD_RULES = {
  LENGTH: "min",
  NUMBER: "digits",
  UPCASE: "uppercase",
  LOWCASE: "lowercase",
  SYMBOL: "symbols",
  PREVIOUS: "Your new password must be different from your previous password",
}
export const PASSWORD_REQUIREMENTS = [
  {
    type: PASSWORD_RULES.LENGTH,
    text: "Have at least 6 characters",
    description: "Must be at least 6 characters",
  },
  {
    type: PASSWORD_RULES.NUMBER,
    text: "At least one number",
    description: "Must have at least one number",
  },
  {
    type: PASSWORD_RULES.UPCASE,
    text: "At least one uppercase letter",
    description: "Must have at least one uppercase letter",
  },
  {
    type: PASSWORD_RULES.LOWCASE,
    text: "At least one lowercase letter",
    description: "Must have at least one lowercase letter",
  },
  {
    type: PASSWORD_RULES.SYMBOL,
    text: "At least one symbol",
    description: "Must have at least one symbol",
  },
]

export const CHANGE_PASSWORD_REQUIREMENTS = [
  {
    type: PASSWORD_RULES.LENGTH,
    text: "Have at least 6 characters",
    description: "Must be at least 6 characters",
  },
  {
    type: PASSWORD_RULES.NUMBER,
    text: "Contain at least one number",
    description: "Must have at least one number",
  },
  {
    type: PASSWORD_RULES.UPCASE,
    text: "At least one uppercase letter",
    description: "Must have at least one uppercase letter",
  },
  {
    type: PASSWORD_RULES.LOWCASE,
    text: "At least one lowercase letter",
    description: "Must have at least one lowercase letter",
  },
  {
    type: PASSWORD_RULES.SYMBOL,
    text: "At least one symbol",
    description: "Must have at least one symbol",
  },
]

const schema = new Validator()
schema
  .is()
  .min(6)
  .has()
  .digits()
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .symbols()

const usePassword = (initialPassword = "") => {
  const [password, setPassword] = useState(initialPassword)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordDirty, setPasswordDirty] = useState(false)
  const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false)
  const [failedRequirements, setFailedRequirements] = useState([])
  const [passwordError, setPasswordError] = useState(null)
  const [passwordErrorDetail, setPasswordErrorDetail] = useState(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)

  useEffect(() => {
    if (!password) {
      setFailedRequirements(Object.values(PASSWORD_RULES))
    } else {
      setFailedRequirements(schema.validate(password, { list: true }))
    }
  }, [password])

  useEffect(() => {
    if (!failedRequirements.length) {
      setPasswordError(null)
      setPasswordErrorDetail(null)
    } else {
      setPasswordError("Please enter a valid password")

      const firstViolation = PASSWORD_REQUIREMENTS.find(
        (req) => req.type === failedRequirements[0]
      )
      setPasswordErrorDetail(firstViolation.description)
    }
  }, [failedRequirements])

  useEffect(() => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match")
    } else {
      setConfirmPasswordError(null)
    }
  }, [password, confirmPassword])

  const changePassword = (val) => {
    setPasswordDirty(true)
    setPassword(val)
    store.dispatch({
      type: actionTypes.SET_RESET_PASS_ERROR,
      payload: null,
    })
  }

  const changeConfirmPassword = (val) => {
    setConfirmPasswordDirty(true)
    setConfirmPassword(val)
    store.dispatch({
      type: actionTypes.SET_RESET_PASS_ERROR,
      payload: null,
    })
  }

  const clearStatus = () => {
    setPasswordDirty(false)
    setConfirmPasswordDirty(false)
  }

  return {
    password,
    confirmPassword,
    changePassword,
    changeConfirmPassword,
    clearStatus,
    failedRequirements,
    isValid: !passwordError && !confirmPasswordError,
    visiblePasswordError: passwordDirty && passwordError,
    visiblePasswordErrorDetail: passwordDirty && passwordErrorDetail,
    visibleConfirmPasswordError: confirmPasswordDirty && confirmPasswordError,
  }
}

export default usePassword
