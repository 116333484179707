/**
 * Created by gintaras on 6/19/17.
 */
import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Platform,
  View,
  Text,
  TextInput,
  StyleSheet,
  Image,
  TouchableOpacity,
} from "react-native"
import { images } from "@utils/imageUtils"

import { AppFonts, Units, AppColors } from "@theme"

// components
import PhoneInputField from "@ui/phoneInputField"

const InputField = ({
  field,
  validate,
  label,
  secure,
  value,
  keyValue,
  onChooseCountry,
  phoneRef,
  inputRef,
  withoutDelay,
  customContainerStyle,
  customInputStyle,
  customLabelStyle,
  customMaskSymbolStyle,
  customInputWrapperStyle,
  customValidationErrorStyle,
  customInputWrapperErrorStyle,
  showValidateIcon,
  isValid,
  validationError,
  maskSymbol,
  showInfoIcon,
  onInfoIconClick,
  ...otherProps
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const debounceDelay = withoutDelay ? 0 : 500
  let onChangeText = _.debounce((value) => {
    // Debounce to reduce calls
    validate(value, field)
  }, debounceDelay)

  if (keyValue === "phoneNumber") {
    return (
      <PhoneInputField
        label={label}
        onChangePhoneNumber={onChangeText}
        onPressFlag={onChooseCountry}
        refFunc={phoneRef}
        value={value}
      />
    )
  }

  const hasError = !!value && !isValid && !!validationError

  return (
    <View style={[styles.container, customContainerStyle]}>
      {label ? (
        <Text style={[styles.label, customLabelStyle]}>{label}</Text>
      ) : null}
      <View style={[styles.inputWrapper, customInputWrapperStyle]}>
        {!!maskSymbol && (
          <Text style={[styles.maskSymbol, customMaskSymbolStyle]}>
            {maskSymbol}
          </Text>
        )}
        <TextInput
          style={[
            styles.input,
            customInputStyle,
            hasError && customInputWrapperErrorStyle,
            showValidateIcon && !!value && !isValid && styles.invalidInput,
            maskSymbol && styles.inputWithMaskSymbol,
          ]}
          onChangeText={onChangeText}
          autoComplete="off"
          secureTextEntry={secure}
          ref={inputRef}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...otherProps}
        />
        {showValidateIcon && !!value && (
          <View style={styles.validationIcon}>
            <Image source={isValid ? images.check : images.warning} />
          </View>
        )}
        {showInfoIcon && (
          <TouchableOpacity
            style={styles.validationIcon}
            onPress={() => onInfoIconClick(value)}
          >
            <Image
              source={
                isValid ? images.infoValidationCheck : images.infoValidation
              }
              style={isValid ? {} : styles.infoValidationIcon(value, isFocused)}
            />
          </TouchableOpacity>
        )}
      </View>
      {hasError && (
        <Text style={[styles.errorMessage, customValidationErrorStyle]}>
          {validationError}
        </Text>
      )}
    </View>
  )
}

InputField.propTypes = {
  field: PropTypes.object, // can be whatever
  validate: PropTypes.func,
  withoutDelay: PropTypes.bool,
  keyValue: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  secure: PropTypes.bool,
  onChooseCountry: PropTypes.func,
  phoneRef: PropTypes.func,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  customInputStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  customContainerStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  customLabelStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  customMaskSymbolStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  customInputWrapperStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  customValidationErrorStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  customInputWrapperErrorStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  showValidateIcon: PropTypes.bool,
  isValid: PropTypes.bool,
  validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maskSymbol: PropTypes.string,
  showInfoIcon: PropTypes.bool,
  onInfoIconClick: PropTypes.func,
}

InputField.defaultProps = {
  customContainerStyle: {},
  customInputStyle: {},
  customLabelStyle: {},
  customMaskSymbolStyle: {},
  customInputWrapperStyle: {},
  customValidationErrorStyle: {},
  customInputWrapperErrorStyle: {},
  showValidateIcon: false,
  isValid: true,
  validationError: "",
  maskSymbol: null,
  showInfoIcon: false,
  onInfoIconClick: () => {},
}

const fontStyles =
  Platform.OS === "ios" ? AppFonts.headerDemibold : AppFonts.headerMedium

const styles = StyleSheet.create({
  container: {
    marginTop: Units.rem(0.5),
    width: "100%",
  },
  label: {
    ...AppFonts.bigDemibold,
    color: AppColors.brand.white,
    paddingHorizontal: Units.rem(1.5),
    paddingVertical: Units.rem(0.5),
  },
  errorMessage: {
    fontFamily: "greycliffcf-demibold-salt",
    color: AppColors.brand.white,
    paddingTop: Units.rem(0.25),
    lineHeight: Units.responsiveValue(19),
  },
  inputWrapper: {
    backgroundColor: AppColors.brand.white,
    borderRadius: 6,
    overflow: "hidden",
  },
  input: {
    fontFamily: "greycliffcf-demibold-salt",
    backgroundColor: AppColors.brand.white,
    borderRadius: 6,
    paddingHorizontal: Units.rem(1.5),
    paddingTop: Units.rem(0.9375),
    paddingBottom: Units.rem(1),
    color: AppColors.brand.black,
  },
  maskSymbol: {
    ...fontStyles,
    position: "absolute",
    left: Units.rem(1.5),
    bottom: Units.rem(0.67),
    color: AppColors.brand.black,
    zIndex: 1,
  },
  inputWithMaskSymbol: {
    paddingLeft: Units.rem(2.9),
  },
  invalidInput: {
    color: AppColors.brand.red,
  },
  validationIcon: {
    position: "absolute",
    right: Units.rem(1.125),
    bottom: Units.rem(0.84375),
  },
  infoValidationIcon: (value, isFocused) => ({
    tintColor: !value
      ? AppColors.brand.grey157
      : isFocused
      ? AppColors.brand.orange
      : AppColors.brand.red,
  }),
})

export default InputField
