import { StyleSheet } from "react-native"
import { AppColors, AppFonts, AppSizes, Units } from "@theme"

export default StyleSheet.create({
  messageContainer: {
    flexDirection: "row",
    // justifyContent: isMyMessage ? 'flex-end' : 'flex-start',
    justifyContent: "flex-end",
    paddingHorizontal: Units.responsiveValue(7),
  },
  repliedMessageBar: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    width: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.pink,
  },
  replyMessageSender: {
    ...AppFonts.smallishBold,
    color: AppColors.brand.warmGrey2,
    letterSpacing: Units.responsiveValue(-0.2),
  },
  replyMessageText: {
    marginTop: Units.responsiveValue(6),
    color: AppColors.brand.black2,
    ...AppFonts.mediumMedium,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: Units.responsiveValue(-0.3),
  },
  senderContainer: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: isMyMessage ? 'flex-end' : 'flex-start',
    justifyContent: "flex-start",
    paddingHorizontal: Units.responsiveValue(7),
    marginBottom: Units.responsiveValue(5),
  },
  senderText: {
    ...AppFonts.smallishMedium,
    marginLeft: Units.responsiveValue(3),
    // color: isMyMessage
    //     ? AppColors.brand.lightBlue7
    //     : AppColors.brand.grey157,
    color: AppColors.brand.grey157,
  },
  generalIcon: {
    flexShrink: 0,
    marginTop: Units.responsiveValue(15),
    marginLeft: Units.responsiveValue(9),
  },
  attachmentContainer: {
    flex: 1,
    flexBasis: 0,
    paddingVertical: Units.responsiveValue(12),
    marginHorizontal: Units.responsiveValue(5),
  },
  attachmentText: {
    flexWrap: "wrap",
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.darkGrey2,
  },
  attachmentIconWrapper: {
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "center",
    minWidth: Units.responsiveValue(40),
    backgroundColor: AppColors.brand.pinkBk,
  },
  imageThumb: {
    width: Units.responsiveValue(60),
    height: Units.responsiveValue(60),
  },
})

export const repliedMessageContainer = (isAttachment) => ({
  overflow: "hidden",
  // width: isAttachment ? AppSizes.screen.widthTwoThirds : 'auto',
  boxSizing: "border-box",
  flexDirection: isAttachment ? "row" : "column",
  borderRadius: Units.responsiveValue(8),
  backgroundColor: AppColors.brand.white,
  paddingHorizontal: isAttachment ? 0 : Units.responsiveValue(14),
  paddingVertical: isAttachment ? 0 : Units.responsiveValue(11),
  marginBottom: Units.responsiveValue(7),
  marginHorizontal: Units.responsiveValue(7),
})
