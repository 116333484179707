import React from "react"
import PropTypes from "prop-types"
import { ZoneStyles as styles } from "./timezonesStyles"
import { images } from "@utils/imageUtils"
import { View, Image } from "react-native"

const Timeline = ({
  darkTheme,
  durationWidth,
  duration,
  halfDurationAdditionalPadding,
}) => {
  const renderTimelineWithoutDuration = () => (
    <>
      <Image
        source={images.triangle}
        style={darkTheme ? styles.triangleDarkTheme : styles.triangle}
      />

      {darkTheme ? (
        <View style={styles.splitterDarkTheme} />
      ) : (
        <View style={styles.splitter}>
          <View style={styles.innerSplitter} />
        </View>
      )}

      <Image
        source={images.triangle}
        style={
          darkTheme ? styles.triangleDarkThemeBottom : styles.triangleBottom
        }
      />
    </>
  )
  const renderTimeline = () => {
    if (darkTheme) {
      if (durationWidth) {
        return (
          <View
            style={[
              styles.flex,
              { width: durationWidth },
              duration % 30 !== 0 && styles.durationMargin,
            ]}
          >
            <View
              style={[
                styles.durationSplitterLeftBorderSmall,
                styles.durationSplitterBorderDarkThemeSmall,
              ]}
            />
            <View
              style={[
                styles.durationSplitterBorderDarkThemeSmall,
                styles.durationSplitterRightBorderSmall,
              ]}
            />
          </View>
        )
      } else {
        return renderTimelineWithoutDuration()
      }
    } else {
      if (duration) {
        return (
          <View
            style={[
              styles.durationSplitter,
              durationWidth && {
                width: durationWidth,
              },
              duration % 30 !== 0 && styles.durationMargin,
              { width: `${durationWidth + 6}px` },
            ]}
          >
            <View
              style={[
                styles.durationSplitterBorder,
                styles.durationSplitterLeftBorder,
              ]}
            >
              <Image source={images.triangle} style={styles.triangle} />
              <View style={styles.durationSplitterInner} />
            </View>
            <View
              style={[
                styles.durationSplitterBorder,
                styles.durationSplitterRightBorder,
              ]}
            >
              <Image source={images.triangle} style={styles.triangle} />
              <View style={styles.durationSplitterInner} />
            </View>
          </View>
        )
      } else {
        return renderTimelineWithoutDuration()
      }
    }
  }

  return (
    <View
      style={[
        styles.splitterContainer,
        halfDurationAdditionalPadding,
        { marginTop: "0px", marginLeft: "0px" },
      ]}
      pointerEvents="none"
    >
      {renderTimeline()}
    </View>
  )
}

Timeline.propTypes = {
  darkTheme: PropTypes.bool,
  durationWidth: PropTypes.number,
  duration: PropTypes.number,
  halfDurationAdditionalPadding: PropTypes.object,
}

export default Timeline
