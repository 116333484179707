import { images } from "@utils/imageUtils"
import React from "react"
import { ReactSVG } from "react-svg"
import classnames from "classnames"

import "./index.scss"

const Checkbox = ({ checked, value, onChange, children }) => {
  return (
    <label className="checkbox-wrapper">
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span
        className={classnames("checkbox", { "checkbox--active": checked })}
        // This element is purely decorative so we hide it for screen readers
        aria-hidden="true"
      >
        {checked && (
          <ReactSVG src={images.ndCheckmarkCheckbox} className="checkmark" />
        )}
      </span>
      {children}
    </label>
  )
}

export default Checkbox
