const size = `400x90`

export const unsplashApi = {
  random: `https://source.unsplash.com/collection/175083/${size}`,
  randomSmaller: `https://source.unsplash.com/collection/175083/300x200`,
  dark: `https://source.unsplash.com/${size}/?dark`,
  // randomRemote: (w = width, h = Math.round(height / 2)) =>
  //     `https://source.unsplash.com/collection/175083/${w}x${h}?randomextra=` +
  //     new Date().getTime(),
}
