import { StyleSheet } from "react-native"
import { AppColors, Units } from "@theme"

export default StyleSheet.create({
  templateMessageContainer: {
    backgroundColor: AppColors.brand.lightBlue4,
    paddingHorizontal: Units.responsiveValue(14),
    paddingVertical: Units.responsiveValue(11),
    marginBottom: Units.responsiveValue(7),
    justifyContent: "center",
    alignItems: "center",
  },
})
