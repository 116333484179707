import React, { useRef, useState, useEffect } from "react"
import cn from "classnames"
import { NavLink } from "react-router-dom"

import "./index.scss"

const SideBarMenuItem = ({
  itemId,
  isSideBarOpen,
  focusCounter,
  onPress,
  image,
  imageActive,
  text,
  path,
  isNotLink
}) => {
  const infoRef = useRef()

  if (isNotLink) {
    return (
      <div
        className={cn("sidebar-item", {
          "sidebar-item-focus": focusCounter === itemId,
        })}
        ref={infoRef}
        data-tip={text}
        data-for="information-tooltip"
        onClick={() => onPress()}
      >
        <img
          src={focusCounter === itemId ? imageActive : image}
          className="sidebar-item__icon"
          alt="Inbox"
        />
        <h4
          className={cn("sidebar-item__title", {
            "sidebar-item__title-focus": focusCounter === itemId,
          })}
        >
          {text}
        </h4>
      </div>
    )
  }

  return (
    <NavLink
      to={path}
      className={cn("sidebar-item", {
        "sidebar-item-focus": focusCounter === itemId,
      })}
      onClick={() => onPress()}
    >
      <img
        src={focusCounter === itemId ? imageActive : image}
        className="sidebar-item__icon"
        alt="Inbox"
      />
      <h4
        className={cn("sidebar-item__title", {
          "sidebar-item__title-focus": focusCounter === itemId,
        })}
      >
        {text}
      </h4>
    </NavLink>
  )
}

export default SideBarMenuItem
