import React, { useState, useRef } from "react"
import ReactDOM from "react-dom"
import { Text, View, TouchableOpacity } from "react-native"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"

import { reactionTypes } from "@configs/mappings"
// import teeupActions from '@actions/teeupActions'
import { isTeeupDisabled } from "@utils/teeupUtils"
import { AppColors, Units } from "@theme"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"

import {
  GameplansRowStyles,
  reactionContainer,
  activeReactionContainer,
} from "./gameplansStyles"
import { IconContext } from "react-icons"
import * as teeupActions from "@actions/teeupActions"
import ReactionsTooltip from "./ReactionsTooltip/"
import threeDotsIcon from "../../assets/images/three-dots.svg"

const getIcon = (iconID) => {
  switch (iconID) {
    case reactionTypes.upvote:
      return <FaThumbsUp />
    case reactionTypes.downvote:
      return <FaThumbsDown />
    default:
      return null
  }
}

export const ReactionButtonView = React.forwardRef(
  ({
    disableReaction,
    teeup,
    previewMode = false,
    isMyMessage = false,
    inSuggestion,
    inactive,
    isInNudge = false,
    sections,
    suggestionId,
    id,
    reacted,
    reactedCustomId,
    onReaction,
    number,
    newDesign,
  }) => {
    const intId = Number(id)

    let checkReaction = false

    if (intId === reactedCustomId || (!reactedCustomId && intId === reacted)) {
      checkReaction = true
    }

    let liked = []
    let disliked = []

    const disabled = disableReaction || isTeeupDisabled(teeup)

    let expression = sections?.map((item) => {
      if (item.reaction === 2) {
        liked = item.data
      } else if (item.reaction === 3) {
        disliked = item.data
      }
    })

    const [reactionsTooltip, setReactionsTooltip] = useState(false)
    const [threeDotsReaction, setThreeDotsReaction] = useState(false)

    const reactionsRef = useRef()

    return (
      <>
        {reactionsTooltip &&
          threeDotsReaction &&
          ReactDOM.createPortal(
            <>
              <ReactionsTooltip
                sections={intId === 2 ? liked : disliked}
                reactionId={intId}
              />
            </>,
            document.getElementById("modal-root")
          )}
        <TouchableOpacity
          style={[
            reactionContainer(isMyMessage, inSuggestion, inactive),
            checkReaction &&
              activeReactionContainer(isMyMessage, inactive, newDesign),
            disabled && { opacity: 0.4 },
            isInNudge && GameplansRowStyles.activeReactionNudgeContainer,
            { position: "relative" },
          ]}
          disabled={disabled || previewMode || inactive}
          onPress={() => {
            onReaction(id)
          }}
          onMouseOver={(e) => {
            setThreeDotsReaction(true)
          }}
          onMouseLeave={(e) => {
            e.stopPropagation()
            ReactTooltip.hide(reactionsRef.current)
            setThreeDotsReaction(false)
          }}
        >
          {threeDotsReaction && suggestionId && (
            <img
              src={threeDotsIcon}
              ref={reactionsRef}
              data-tip={id}
              data-for="reactions-tooltip"
              className="reactions-view"
              onMouseOver={(e) => {
                e.stopPropagation()
                ReactTooltip.show(reactionsRef.current)
                setReactionsTooltip(true)
              }}
              alt=""
            />
          )}
          <IconContext.Provider
            value={{
              color: inactive
                ? "rgb(157,157,157)"
                : AppColors.brand.thumbYellow,
              marginTop: Units.responsiveValue(4),
              // color: inactive
              // ? AppColors.brand.warmGrey2
              // : AppColors.brand.thumbYellow,  marginTop: Units.responsiveValue(4),
            }}
          >
            {getIcon(id)}
          </IconContext.Provider>
          <Text
            style={[
              GameplansRowStyles.activeReactionCountText,
              (!inactive || !isInNudge) && isMyMessage
                ? GameplansRowStyles.activeReactionCountTexActiveMy
                : GameplansRowStyles.activeReactionCountTexActive,

              GameplansRowStyles.activeReactionCountTextMy,
              !checkReaction &&
                !isInNudge &&
                GameplansRowStyles.reactionCountText,
              inactive && GameplansRowStyles.inActiveReactionCountText,
            ]}
          >
            {previewMode && id === reactionTypes.upvote ? 1 : number[id]}
          </Text>
        </TouchableOpacity>
      </>
    )
  }
)
