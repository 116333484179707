import React, { useEffect, useState } from "react"

import TeeupItem from "../../../components/teeupList/TeeupItem"

import { setTeeupActive } from "@actions/activeTeeupActions"

import get from "lodash/get"
import find from "lodash/find"

import './index.scss'
import useProfilePage from "../ProfilePageContext/useProfilePageSharedTeeUps"

const ProfilePageSharedTeeups = ({ sharedTeeUps, currentUserId }) => {
  const {
    notifications,
    userId,
    teeupParticipants,
    teeupPeople,
    teeupInvitations,
    teeupGameplans,
  } = useProfilePage()

  const renderItem = ({ item, index }) => {
    const { id, name } = item

    const participants = get(teeupParticipants, id, [])

    const teeups = sharedTeeUps.filter(
      (teeup) => teeup.status !== "ended" &&
      participants.includes(currentUserId, userId)
    )

    const notificationsArr = notifications.map(
      (notification) => notification.id
    )
    return (
      <TeeupItem
        key={`teeupItem-${id}`}
        teeup={item}
        currentUserId={userId}
        invitation={find(teeupInvitations, ["teeupId", id], null)}
        participants={participants}
        people={get(teeupPeople, id, {})}
        gameplans={get(teeupGameplans, id, [])}
        onPress={() => setTeeupActive(item)}
        name={name}
        testID={`teeup-${id}`}
        isLast={index + 1 === teeups.length}
        notificationsArr={notificationsArr}
        preventRedirecting
        preventHover
      />
    )
  }

  return (
    <div className="profile-teeups">
      {sharedTeeUps && sharedTeeUps.map((item, index) => {
        const participants = get(teeupParticipants, item.id, [])

        if (participants.length
            && participants.includes(currentUserId)
            && participants.includes(userId)) {
          return renderItem({ item, index }) 
        }
      })}
    </div>
  )
}

export default ProfilePageSharedTeeups;