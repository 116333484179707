import React, { memo, useEffect, useCallback, useMemo } from "react"
import { ReactSVG } from "react-svg"
import { groupBy } from "lodash"

import SelectTimeOptionButton from "./SelectTimeOptionButton"
import { images } from "@utils/imageUtils"

import "./selectTime.scss"
import TimeByTimeZone from "./TimeByTimeZone"
import useGeneralTimeTab from "../GeneralTimeContext/useGeneralTimeTab"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

const SELECT_TIME_OPTIONS = [
  {
    id: "dayTime",
    name: "Day Time",
    timeRange: "7AM - 7PM",
    start: 7,
    end: 19,
  },
  {
    id: "earlyMorning",
    name: "Early Morning",
    timeRange: "12AM - 6AM",
    start: 0,
    end: 6,
  },
  {
    id: "morning",
    name: "Morning",
    timeRange: "6AM - 12PM",
    start: 6,
    end: 12,
  },
  {
    id: "afternoon",
    name: "Afternoon",
    timeRange: "12PM - 6PM",
    start: 12,
    end: 18,
  },
  {
    id: "evening",
    name: "Evening",
    timeRange: "6PM - 9PM",
    start: 18,
    end: 21,
  },
  { id: "night", name: "Night", timeRange: "9PM - 12AM", start: 21, end: 0 },
]

const SelectTime = () => {
  const { daysSelected, setTime, time } = useGeneralTimeTab()
  const { people, user } = useTeeUpPage()

  const handleSelectTime = useCallback(
    (newTime) => () => {
      if (time && time.id === newTime.id) {
        setTime(null)
        return
      }
      setTime(newTime)
    },
    [setTime, time]
  )

  const peopleByZones = useMemo(
    () =>
      groupBy(
        people,
        (person) => {
          return person.timezone
        },
        [people]
      ),
    [people]
  )

  return (
    <div className="select-time">
      <div className="select-time__options">
        {SELECT_TIME_OPTIONS.map((option) => (
          <SelectTimeOptionButton
            key={option.id}
            name={option.name}
            timeRange={option.timeRange}
            handleTimeSelect={handleSelectTime(option)}
            selected={
              time && time.start === option.start && time.end === option.end
            }
          />
        ))}
      </div>
      {time && peopleByZones && (
        <div className="select-time__for-others">
          <div className="select-time__for-others__header">
            <ReactSVG
              src={images.ndGlobeAmericasIcon}
              className="select-time__for-others__header__globe-icon"
            />
            see what <span>{`Your ${time?.name}`}</span> means for others
          </div>
          {Object.values(peopleByZones).map((zonePeople) => (
            <TimeByTimeZone
              people={zonePeople}
              time={{ start: time.start, end: time.end }}
              you={user.timezone === zonePeople[0].timezone}
              date={daysSelected.length ? daysSelected[0] : new Date()}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default memo(SelectTime)
