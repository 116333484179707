import React from "react"
import { View, Text, TouchableOpacity, Image, Dimensions } from "react-native"
import { connect } from "react-redux"
import { openCreateTeeup } from "../../actions/createTeeupActions"
import { images } from "@utils/imageUtils"
import ViewStyles from "./styles"

const CreateTeeUpButton = ({ open, isDetailsOpen, currentWindowParams }) => {
  const windowWidth = Dimensions.get("window").width

  return (
    <TouchableOpacity style={ViewStyles.button} onPress={() => open()}>
      <View style={ViewStyles.row}>
        {/* <Image source={images.increment} style={ViewStyles.addIcon} /> */}
        <Image source={images.newCreateTeeupPlus} style={ViewStyles.addIcon} />
        {(isDetailsOpen && windowWidth <= 1425) ||
        currentWindowParams.width <= 1125 ? (
          <></>
        ) : (
          <Text style={ViewStyles.addText}>Create TeeUp</Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const mapMethodsToProps = {
  open: openCreateTeeup,
}

export default connect(null, mapMethodsToProps)(CreateTeeUpButton)
