import React from "react"

import "./suggestModalContent.scss"
import "./index.scss"

const SuggestModalContent = ({ type = "where", children }) => {
  return (
    <div
      className={
        type === "where"
          ? "suggest-modal__content"
          : "suggest-modal__content-when"
      }
    >
      {children}
    </div>
  )
}

export default SuggestModalContent
