import React, { useState, useEffect } from "react"
import { TouchableOpacity, Image } from "react-native"
import { images } from "@utils/imageUtils"
import { connect } from "react-redux"
import closeIcon from "../../assets/images/close.svg"
import SwitchBlock from "./SwitchBlock"
import { updateTeeup } from "../../middlewares/backendMiddleware"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { SectionListStyles } from "../gamePlansCard/suggestionsList/suggestionListStyles"

const ProgressionSettings = ({
  settings,
  close,
  teeupId,
  enableUseAutoHappening = false,
  enableUseAutoEnded = false,
}) => {
  const [options, setOptions] = useState(settings)

  useEffect(() => {
    setOptions(settings)
  }, [settings])

  const handleSwitch = (field) => {
    updateTeeup({
      teeupId,
      teeup: { settings: { ...options, [field]: !options[field] } },
    })
  }

  return (
    <>
      <div className="settings__top">
        <TouchableOpacity onPress={close}>
          <Image
            source={images.back}
            style={SectionListStyles.cancelBtnImage}
          />
        </TouchableOpacity>
        <span>TeeUp Progression Settings</span>
        <div></div>
      </div>
      <SwitchBlock
        onChange={(value) => {
          setOptions({ ...options, allowAutoScheduled: !value })
          handleSwitch("allowAutoScheduled")
        }}
        sectionName="All set"
        title='Set TeeUp state to "Scheduled" when enough people are going'
        conditionName="Minimum needed"
        conditionValue={options.criticalMassAmount + " people"}
        initialValue={options.allowAutoScheduled}
      />
      <SwitchBlock
        onChange={(value) => {
          setOptions({ ...options, allowNotifyBeforeHappening: !value })
          handleSwitch("allowNotifyBeforeHappening")
        }}
        sectionName="Happening now"
        title="Notify participants before the TeeUp is set to 'Happening Now'"
        conditionName="When to notify"
        conditionValue={options.whenToNotifyBeforeHappening + "h before"}
        initialValue={options.allowNotifyBeforeHappening}
        isDisable={!enableUseAutoHappening}
      />
      <SwitchBlock
        onChange={(value) => {
          setOptions({ ...options, allowAutoEnded: !value })
          handleSwitch("allowAutoEnded")
        }}
        sectionName="Ended"
        title="Set to ended after a period of time"
        conditionName="Time to wait"
        conditionValue={options.timeBeforeAutoEnded + " hours"}
        initialValue={options.allowAutoEnded}
        isDisable={!enableUseAutoEnded}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  settings: selectActiveTeeup(state).settings,
})

export default connect(mapStateToProps, null)(ProgressionSettings)
