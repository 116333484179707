import React from "react"
import PropTypes from "prop-types"
import {
  images,
  FastImageWithDisabled,
  ImageWithDisabled,
} from "@utils/imageUtils"
import { View, Text } from "react-native"
import { Units } from "@theme"
import { AvatarStyles as styles } from "./styles"

const roundedStyleFromSize = (size) => {
  return size
    ? {
        width: Units.responsiveValue(size),
        height: Units.responsiveValue(size),
        borderRadius: Units.responsiveValue(size / 2),
      }
    : null
}

const setBorderColor = (color) => {
  return color ? { backgroundColor: color } : null
}

const getCrownIconDimentions = (size) => {
  const baseSize = size <= 48 ? size : 48
  const positioning = size <= 48 ? 0 : Units.responsiveValue(1)
  return {
    top: -Units.responsiveValue(baseSize / 6) + positioning,
    left: -Units.responsiveValue(baseSize / 6) + positioning,
    height: Units.responsiveValue(baseSize / 2),
    width: Units.responsiveValue(baseSize / 2),
  }
}

const Avatar = ({
  imageUrl,
  icon,
  initials,
  size,
  avatarStyle,
  style,
  contentStyle,
  innerContentStyle,
  borderWidth = 2,
  borderColor,
  isOwner,
  isOrganiser,
  isFollowing,
  isYou,
  disabled,
  accesory,
  hiddenCount,
  participantDetails,
  fromMentions,
}) => {
  let image
  if (imageUrl) {
    image = FastImageWithDisabled(
      { uri: imageUrl || "" },
      [styles.avatar, avatarStyle],
      { resizeMode: "cover", style: [styles.avatar, avatarStyle] },
      disabled
    )
  } else if (icon) {
    image = ImageWithDisabled(
      icon,
      [styles.avatar, avatarStyle],
      { resizeMode: "contain", style: [styles.avatar, avatarStyle] },
      disabled
    )
  }

  return (
    <View
      style={[
        styles.container,
        style,
        participantDetails && { marginTop: -30 },
        fromMentions && { marginVertical: 8, marginLeft: 16, marginRight: 8 },
      ]}
    >
      <View
        style={[
          styles.roundedContainer,
          contentStyle,
          roundedStyleFromSize(size),
          setBorderColor(borderColor),
        ]}
      >
        <View
          style={[
            styles.imageContainer,
            !image &&
              (disabled
                ? styles.imageContainerTextDisabled
                : styles.imageContainerText),
            roundedStyleFromSize(size),
            innerContentStyle,
          ]}
        >
          {image ? image : <Text style={styles.initials}>{initials}</Text>}
        </View>
        {isYou && (
          <View
            style={[
              styles.youRibbon,
              disabled ? styles.youRibbonDisabled : null,
              { height: Units.responsiveValue(size / 3) },
            ]}
          >
            <Text style={styles.youRibbonText}>You</Text>
          </View>
        )}
        {hiddenCount && (
          <View style={styles.hiddenCountContainer}>
            <Text style={styles.hiddenCount}>+{hiddenCount}</Text>
          </View>
        )}
      </View>
      {!hiddenCount && accesory}
      {!hiddenCount &&
        (isOwner || isOrganiser) &&
        ImageWithDisabled(
          images.organizer,
          [styles.absolute, !disabled && getCrownIconDimentions(size)],
          disabled ? { style: [getCrownIconDimentions(size)] } : {},
          disabled
        )}
      {!hiddenCount &&
        isFollowing &&
        ImageWithDisabled(
          images.uCopy,
          [styles.absolute, styles.followingRibbon],
          {},
          disabled
        )}
    </View>
  )
}

Avatar.propTypes = {
  imageUrl: PropTypes.string,
  icon: PropTypes.string,
  initials: PropTypes.string,
  size: PropTypes.number,
  avatarStyle: PropTypes.object,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  contentStyle: PropTypes.object,
  innerContentStyle: PropTypes.object,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
  isOwner: PropTypes.bool,
  isFollowing: PropTypes.bool,
  isYou: PropTypes.bool,
  isOrganiser: PropTypes.bool,
  disabled: PropTypes.bool,
  accesory: PropTypes.object,
}

Avatar.defaultProps = {
  isOrganiser: false,
}

export default Avatar
