import { StyleSheet } from "react-native"
import { AppFonts, AppColors, Units } from "@theme"

export const styles = StyleSheet.create({
  flex: { flex: 1 },
  row: { flexDirection: "row", alignItems: "center" },
  leftPartContainer: {
    // width: '45%',
    width: "224px",
    minHeight: Units.responsiveValue(128),
    position: "relative",
    // height: '100%',
    // marginLeft: Units.responsiveValue(16),
  },

  container: {
    backgroundColor: "white",
    borderBottomColor: AppColors.brand.grey217,
    borderBottomWidth: 1,
    borderRadius: Units.responsiveValue(8),
    marginBottom: Units.responsiveValue(12),
    // position: "relative",
    // boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)",
    // border: "2px solid red",
    // width: "90%",
  },
  contentContainer: {
    // paddingTop: Units.responsiveValue(10),
    // paddingBottom: Units.responsiveValue(7),
    flexDirection: "row",
  },
  noMargin: {
    marginHorizontal: 0,
  },
  sideBar: {
    alignItems: "center",
    width: Units.responsiveValue(6),
    backgroundColor: AppColors.brand.pink,
    borderTopLeftRadius: Units.responsiveValue(8),
    borderBottomLeftRadius: Units.responsiveValue(8),
    borderRightWidth: Units.responsiveValue(2),
    borderRightColor: "#f0f2f5",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
  },
  leftContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  noImageBg: {
    backgroundColor: AppColors.brand.listItemNoImageBg,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    // maxHeight: Units.responsiveValue(48),
    borderTopLeftRadius: Units.responsiveValue(8),
    borderBottomLeftRadius: Units.responsiveValue(8),

    zIndex: 1,
  },
  organizerContainer: {
    position: "absolute",
    right: Units.responsiveValue(10),
    top: Units.responsiveValue(10),
  },
  organizerIcon: {
    width: Units.responsiveValue(28),
    height: Units.responsiveValue(28),
  },
  content: {
    minHeight: 130,
    //position: "relative",
    flexGrow: 1,

    paddingHorizontal: Units.responsiveValue(12),
    paddingVertical: Units.responsiveValue(10),

    backgroundColor: AppColors.brand.white,
  },

  hasUpdateText: {
    color: AppColors.brand.pink,
  },
  thumbnailImage: {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: Units.responsiveValue(8),
    borderBottomLeftRadius: Units.responsiveValue(8),
    overflow: "hidden",
    backgroundColor: AppColors.brand.grey,
    zIndex: 1,
  },
  nameMargin: {
    marginBottom: Units.responsiveValue(0),
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nameText: {
    flex: 1,
    flexWrap: "wrap",
    ...AppFonts.biggerBold,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.4),
    color: AppColors.brand.black2,
    maxWidth: "300px",
    paddingTop: 4,
    paddingBottom: 4,
  },
  nameInactiveText: {
    color: AppColors.brand.darkGrey2,
  },
  mediumText: {
    flex: 1,
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 13,
    lineHeight: Units.responsiveValue(16),
    letterSpacing: -Units.responsiveValue(0.26),
    paddingBottom: 2,
    color: "#9d9d9d",
  },
  usersLine: {
    flexDirection: "row",
    alignItems: "center",
    alignContent: "flex-start",
    justifyContent: "flex-start",
    marginTop: Units.responsiveValue(6.5),
  },
  countText: {
    ...AppFonts.mediumMedium,
    lineHeight: AppFonts.sizes.medium,
    color: AppColors.brand.warmGrey2,
  },
  arrowIcon: {
    resizeMode: "contain",
    height: Units.responsiveValue(10),
    width: Units.responsiveValue(10),
    marginLeft: Units.responsiveValue(8),
    marginRight: Units.responsiveValue(2),
  },
  withBorders: {
    borderTopWidth: 0,
  },
  selectedTeeupStyle: {
    backgroundColor: "#F5F8FA",
    // backgroundColor: "#F5F5F5"
  },
  actionButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    // borderTopWidth: 1,
    // borderTopColor: 'rgb(217, 217, 217)',
    // marginHorizontal: 10,
    // paddingVertical: 15
    marginRight: "8px",
  },
  actionButton: {
    alignItems: "center",
    justifyContent: "center",
    // width: 150,
    flexDirection: "row",
    marginRight: "8px",
  },
  actionButtonImage: {
    tintColor: "#9D9D9D",
    resizeMode: "contain",
    width: 18,
    // width: 23,
    height: 14,
    // height: 18
  },
  actionButtonText: {
    color: "#9D9D9D",
    fontFamily: "greycliff-cf-bold",
    fontSize: 12,
    //    fontSize: 15,
    paddingLeft: 5,
  },
  grayout: {
    opacity: 0.5,
    filter: "grayscale(1)",
  },
})
