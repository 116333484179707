import React, { useEffect, useState } from "react"

import { images } from "@utils/imageUtils"

import './index.scss'

const InvitationSent = ({ close }) => {
  return (
    <div
      className="contacts-book-invitations__wrapper"
      onClick={() => close()}
    >
      <div
        className="contacts-book-invitations"
        onClick={(e) => e.stopPropagation()}
      >
        <h4 className="contacts-book-invitations__title">Invitations Sent</h4>
        <img src={images.ndInvitationsSent} alt="Invitations Sent"/>
        <button
          type="button"
          className="contacts-book-invitations__button"
          onClick={() => close()}
        >
          ok
        </button>
      </div>
    </div>
  )
}

export default InvitationSent
