import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native"
import { ViewStyles } from "./editUserProfileStyles"
import ViewWithHeader from "@ui/viewWithHeader"
// import { routeBack } from '@nav'
import { images } from "@utils/imageUtils"
import { contactTypes } from "@utils/contactUtils"
import strings from "@i18n"

const ChooseContactMethod = (props) => {
  const {
    type,
    contactMechanisms,
    selectedMechId,
    onSave,
    componentId,
    onClose,
    selectEmailOrPhone,
  } = props
  const isEmail = type === contactTypes.EMAIL
  const [selectedId, setSelectedId] = useState(selectedMechId)
  const [currentContactMechanisms, setCurrentContactMechanisms] = useState([])
  const [emailOrPhone, setEmailOrPhone] = useState("")

  useEffect(() => {
    let map = new Map()
    contactMechanisms.forEach((mechanism) => map.set(mechanism.id, mechanism))
    const uniqueContactMechanisms = []
    map.forEach((mechanism) => uniqueContactMechanisms.push(mechanism))
    setCurrentContactMechanisms(uniqueContactMechanisms)
  }, [contactMechanisms])

  return (
    <ViewWithHeader
      headerContainerStyle={[
        ViewStyles.navigationHeaderContainer,
        { justifyContent: "space-between" },
      ]}
      titleView={
        <View style={ViewStyles.headerContainer}>
          <Text style={ViewStyles.headerTitle}>
            {strings.editProfile.editProfile}
          </Text>
          <Text style={ViewStyles.headerSubtitle}>
            {isEmail
              ? strings.editProfile.selectEmail
              : strings.editProfile.selectPhone}
          </Text>
        </View>
      }
      onCancel={onClose}
      rightButton={
        <TouchableOpacity
          style={ViewStyles.stubNavigationButton}
          onPress={() => {
            onSave(selectedId)
            selectEmailOrPhone(emailOrPhone)
            onClose()
          }}
        >
          <Text style={ViewStyles.saveButtonText}>
            {strings.systemButtons.save}
          </Text>
        </TouchableOpacity>
      }
    >
      <ScrollView style={ViewStyles.chooseContactContainer}>
        {currentContactMechanisms.map((item) => (
          <TouchableOpacity
            style={ViewStyles.chooseContactRow}
            onPress={() => {
              setSelectedId(item.id)
              setEmailOrPhone(item.value)
            }}
            key={item.id}
          >
            <View style={ViewStyles.chooseContactImageWithText}>
              <View style={ViewStyles.chooseContactRowImage}>
                <Image
                  source={
                    isEmail ? images.editProfileEmail : images.editProfilePhone
                  }
                />
              </View>
              <Text style={ViewStyles.chooseContactRowText}>{item.value}</Text>
            </View>
            <View style={ViewStyles.outerSelector}>
              {selectedId === item.id && (
                <View style={ViewStyles.innerSelector} />
              )}
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </ViewWithHeader>
  )
}

ChooseContactMethod.propTypes = {
  type: PropTypes.string,
  contactMechanisms: PropTypes.array,
  selectedMechId: PropTypes.number,
  onSave: PropTypes.func,
  componentId: PropTypes.string,
}

export default ChooseContactMethod
