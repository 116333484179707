import React, { useCallback, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import cn from "classnames"
import LoginAccounts from "../LoginAccounts/"
import { images } from "@utils/imageUtils"
import {
  recoveryRequestVerify,
  changeEmailVerificationVerify,
} from "@actions/loginActions"
import { store } from "../../../index"
import { logOut } from "@actions/loginActions"
import ChangePasswordSuccess from "../../ChangePassword/ChangePasswordSuccess"

const VerifyCode = ({
  type,
  value,
  contanctVerification,
  contactMechanismVerificationId,
  accessToken,
  resendCodeFn,
  previousStep,
  cmData,
  email,
  deleteUserCM,
  tokenAccess,
  forgotPassword,
}) => {
  const [code, setCode] = useState("")
  const [counter, setCounter] = useState(60)
  const [error, setError] = useState("")
  const [verified, setVerified] = useState(false)
  const [data, setData] = useState()
  const history = useHistory()

  const handleChangeCode = useCallback((val) => {
    setCode(val)
  })

  const key = type === "email" ? "email1" : "phoneNumber"

  const contact = value || email

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  const handleVerifyCode = () => {
    !contactMechanismVerificationId &&
      recoveryRequestVerify(code, contanctVerification)
        .then((response) => {
          setVerified(true)
          setData(response.data)
          //   if (response.data[key]) {
          //     setVerified(true);
          //     // console.log('token', response.data['token'])
          //   } else {
          //     setError('Please Enter Valid Verification Code:')
          //   }
        })
        .catch((error) => {
          console.log("error", error)
          setError("The code is invalid")
        })

    contactMechanismVerificationId &&
      changeEmailVerificationVerify(
        code,
        contactMechanismVerificationId,
        2,
        accessToken
      )
        .then(() => {
          // logOut()(store.dispatch)
          setVerified(true)
          // createUserCM(2, emailValue)
          //   .then((res) => {
          //     console.log(res.data, 'CMMMMMM')
          //   })
          //   .catch((error) => {
          //     console.log('CM error', error)
          //   })
          // setVerified(true);
          // setData(response.data)
          //   if (response.data[key]) {
          //     setVerified(true);
          //     // console.log('token', response.data['token'])
          //   } else {
          //     setError('Please Enter Valid Verification Code:')
          //   }
        })
        .catch((error) => {
          console.log("error", error)
          setError("The code is invalid")
        })
  }

  const resendCode = (e) => {
    resendCodeFn(e)
    setCounter(60)
    // sendBetaCode({[key]:value}, deviceId)
  }

  const cancelVerification = async () => {
    !forgotPassword &&
      (await deleteUserCM(cmData.id, tokenAccess, "/login")
        .then(() => {
          console.log("success")
        })
        .catch((error) => {
          console.log("error", error)
        }))
    previousStep()
    // deleteUserCM(createdCM ,token, '/recovery')
  }

  // const navigateToCreateAccount =() => {
  //   const defaultEmail = (type === 'email' ? value : '');
  //   const phoneNumber = (type === 'phone' ? value : '');

  //   history.push('/sign-up', { userId, defaultEmail, phoneNumber, deviceId });
  // };

  return (
    <div className="forgot__page" style={{ flexDirection: "column" }}>
      <div className="forgot__content">
        {!verified ? (
          <>
            <img className="forgot__icon" src={images.cooeModal} />
            <span className="forgot__title">Enter Verification Code</span>
            <p className="forgot__subtitle__recovery">
              We sent a recovery code to
              <br />
              <span style={{ color: "#222222" }}>
                {contact.slice(0, 1) +
                  "x".repeat(contact.length - 6) +
                  contact
                    .replace(/ /g, "")
                    .slice(contact.length - 5, contact.length)}
              </span>
              . Please type the code and verify it to recover your account.
            </p>
            <div className="forgot__input--wrapper">
              <label htmlFor="input" className="forgot__label">
                Recovery Code
              </label>
              <div style={{ position: "relative", marginTop: 4 }}>
                <input
                  id="input"
                  value={code}
                  onChange={({ target }) => handleChangeCode(target.value)}
                  className={cn("login__input forgot__input", {
                    "forgot__input--error": error,
                  })}
                  type="text"
                />
              </div>
              <span className="forgot__error">{error}</span>
            </div>
            <div
              style={{
                width: 327,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <button
                className="verify__resend verify__resend--recovery"
                onClick={cancelVerification}
                style={{ width: 88, height: 48, margin: 0 }}
              >
                Cancel
              </button>
              <button
                disabled={code.length !== 6}
                className={cn("forgot__button", {
                  "forgot__button--disabled": code.length !== 6,
                })}
                style={{ width: 227 }}
                onClick={handleVerifyCode}
              >
                Verify
              </button>
            </div>
            <span style={{ marginTop: 30 }} className="verify__text">
              Didn't receive the code?
            </span>
            <button
              disabled={counter > 0}
              className={cn("verify__resend verify__resend--recovery", {
                "verify__resend--disabled verify__resend--disabled--recovery ":
                  counter > 0,
              })}
              onClick={(e) => resendCode(e)}
            >
              {(counter > 0 ? `(${counter})` : "") + " " + "Resend code"}
            </button>
          </>
        ) : contactMechanismVerificationId ? (
          <ChangePasswordSuccess email />
        ) : (
          <LoginAccounts
            data={data}
            contanctVerification={contanctVerification}
          />
        )}
      </div>
    </div>
  )
}

export default VerifyCode
