import { StyleSheet } from "react-native"
import { AppColors, AppFonts, Units } from "@theme"

export default StyleSheet.create({
  attachmentSender: {
    marginTop: Units.responsiveValue(10),
    marginLeft: Units.responsiveValue(10),
  },
  messageSenderText: {
    color: AppColors.brand.coolGrey,
    ...AppFonts.smallishDemibold,
  },
})
