import React from "react"
import propTypes from "prop-types"
import { connect } from "react-redux"

import { teeupUserStatusKeys } from "@configs/enums"
import {
  selectTeeupPeople,
  // selectStartWhenTeeupsGameplan,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
// import { routeSetupStartsWhenAvailability } from '@nav'

import StartsWhenCarouselComponent from "./StartsWhenCarouselComponent"

class StartsWhenCarouselContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDragged: false,
    }
    this.scrollRef = React.createRef()
  }

  handleContentSizeChange = (width) => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollTo({ x: width / 2, animated: true })
    }
  }

  handleScrollBeginDrag = () => {
    this.setState({ isDragged: true })
  }
  handleScrollEndDrag = () => {
    this.setState({ isDragged: false })
  }

  handleSetAvailability = () => {
    // routeSetupStartsWhenAvailability()
  }

  render() {
    const { startDate, people, userId, startsWhenGameplan } = this.props
    const { isDragged } = this.state
    return (
      <StartsWhenCarouselComponent
        startDate={startDate}
        isDragged={isDragged}
        people={people}
        userId={userId}
        scrollRef={this.scrollRef}
        onContentSizeChange={this.handleContentSizeChange}
        onScrollBeginDrag={this.handleScrollBeginDrag}
        onScrollEndDrag={this.handleScrollEndDrag}
        onSetAvailability={this.handleSetAvailability}
        startsWhenGameplan={startsWhenGameplan}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const teeupPeople = { ...selectTeeupPeople(state) }
  const userId = selectUserId(state)
  // const startsWhenGameplan = selectStartWhenTeeupsGameplan(state)
  const startsWhenGameplan = {}

  if (startsWhenGameplan && startsWhenGameplan.availability) {
    startsWhenGameplan.availability.forEach((user) => {
      if (teeupPeople[user.userId]) {
        teeupPeople[user.userId] = {
          ...teeupPeople[user.userId],
          ...user,
        }
      }
    })
  }
  const currentUserInTeeup = teeupPeople[userId] || {}
  const peopleArr = [...Object.values(teeupPeople)]
  const goingPeople = peopleArr.filter(
    (user) => user.status === teeupUserStatusKeys.going && user.id !== userId
  )
  if (
    currentUserInTeeup &&
    currentUserInTeeup.status === teeupUserStatusKeys.going
  )
    goingPeople.unshift(currentUserInTeeup)
  const mightGoPeople = peopleArr.filter(
    (user) => user.status === teeupUserStatusKeys.mightgo && user.id !== userId
  )
  if (
    currentUserInTeeup &&
    currentUserInTeeup.status === teeupUserStatusKeys.mightgo
  )
    mightGoPeople.unshift(currentUserInTeeup)
  const notAvailable = peopleArr.filter(
    (user) =>
      user.status !== teeupUserStatusKeys.mightgo &&
      user.status !== teeupUserStatusKeys.going &&
      user.id !== userId
  )
  let people = [...goingPeople, ...mightGoPeople, ...notAvailable]
  if (
    currentUserInTeeup &&
    currentUserInTeeup.status !== teeupUserStatusKeys.mightgo &&
    currentUserInTeeup.status !== teeupUserStatusKeys.going
  )
    people.unshift(currentUserInTeeup)
  return {
    people,
    userId,
    startsWhenGameplan,
  }
}

StartsWhenCarouselContainer.propTypes = {
  availability: propTypes.arrayOf(propTypes.shape({})).isRequired,
  people: propTypes.shape({}).isRequired,
  userId: propTypes.number.isRequired,
  startDate: propTypes.string.isRequired,
  startsWhenGameplan: propTypes.object,
}

export default connect(mapStateToProps, null)(StartsWhenCarouselContainer)
